import React from 'react';
import { Text } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  values?: Record<string, any>;
  label: string;
};
export const SubmitHintText = ({ values, label }: Props) => (
  <Text m="0" textStyle="ds.body3" color="ds.gray.200" align="center">
    <MIFormattedText label={label} values={values} />
  </Text>
);
