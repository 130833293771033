import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { VStack } from '@melio/billpay-design-system';
import { generatePath, useHistory } from 'react-router-dom';
import analytics from 'src/services/analytics';
import { getPartialBillId } from 'src/utils/bills';
import { getOrgId } from 'src/redux/user/selectors';
import { DELIVERY_METHOD_ORIGIN, DELIVERY_TYPE } from 'src/utils/consts';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { withNavigator } from 'src/hoc';
import { MIFormattedCurrency } from 'src/utils/formatting';
import { getBill, getIsLoading, getPayment } from 'src/redux/payBillWizard/selectors';
import { DeliveryMethodType } from 'src/utils/types';
import { selectDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { PayBillProps, withPayBillData } from '../hoc/withPayBillData';
import { VirtualCardDeliveryMethodOption } from './delivery-method-options/VirtualCardDeliveryMethodOption';
import { ACHDeliveryMethodOption } from './delivery-method-options/ACHDeliveryMethodOption';
import locations from '../../locations';

const eventPage = 'pay-bill';

const VirtualCardRecoveryFlowPage = ({ goExit }: PayBillProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const orgId = useSelector(getOrgId);
  const payment = useSelector(getPayment);
  const bill = useSelector(getBill);
  const isBillDataLoading = useSelector(getIsLoading);
  const vendorName = bill.vendor.companyName;
  const values = {
    amount: <MIFormattedCurrency value={payment.amount} />,
    companyName: vendorName,
  };
  const titleValues = { vendorName };
  const achDeliveryMethod = bill.getDeliveryMethodByType(DELIVERY_TYPE.ACH);
  const virtualCardDeliveryMethod = bill.getDeliveryMethodByType(DELIVERY_TYPE.VIRTUAL_CARD);

  const navigateCreateDeliveryMethod = (url: string) => {
    const redirectUrl = generatePath(locations.edit.confirm, {
      orgId,
      billId: bill.id,
      paymentId: payment.id,
    });
    const exitUrl = history.location.pathname;

    history.push({
      pathname: url,
      state: {
        origin: DELIVERY_METHOD_ORIGIN.PAY_BILL,
        exitUrl,
        redirectUrl,
      },
    });
  };

  const selectDeliveryMethod = (deliveryMethod: DeliveryMethodType) => {
    dispatch(selectDeliveryMethodAction(deliveryMethod));

    history.push(
      generatePath(locations.edit.confirm, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      })
    );
  };

  const onVirtualCardSelected = () => {
    analytics.track(eventPage, 'select-delivery-method', {
      type: 'virtual-card',
      partialBillId: getPartialBillId(bill),
      vendorId: bill.vendor.id,
      paymentId: payment.id,
    });

    selectDeliveryMethod(virtualCardDeliveryMethod);
  };

  const onAchSelected = () => {
    const eventType = achDeliveryMethod.id ? 'select-delivery-method' : 'create-delivery-method';

    analytics.track(eventPage, eventType, {
      type: 'ach',
      partialBillId: getPartialBillId(bill),
      vendorId: bill.vendor.id,
      paymentId: payment.id,
    });

    if (achDeliveryMethod.id) {
      selectDeliveryMethod(achDeliveryMethod);
    } else {
      navigateCreateDeliveryMethod(
        generatePath(locations.deliveryMethod.ach, {
          orgId,
          vendorId: bill.vendorId,
        })
      );
    }
  };

  if (isBillDataLoading) {
    return <QBDTLoader />;
  }

  return (
    <QBDTStepLayout
      headerLabel="qbo.header.title"
      headerLabelValues={values}
      title="bills.pay.virtualCardRecovery.heading"
      titleValues={titleValues}
      relativeStep={2 / 5}
      goExit={goExit}
    >
      <VStack spacing="ds.xl">
        <VirtualCardDeliveryMethodOption
          onClick={onVirtualCardSelected}
          vendorName={vendorName}
          deliveryMethod={virtualCardDeliveryMethod}
        />

        <ACHDeliveryMethodOption onClick={onAchSelected} vendorName={vendorName} deliveryMethod={achDeliveryMethod} />
      </VStack>
    </QBDTStepLayout>
  );
};

const VirtualCardRecoveryFlowPageWithHocs = compose(withNavigator(), withPayBillData())(VirtualCardRecoveryFlowPage);

export { VirtualCardRecoveryFlowPageWithHocs as VirtualCardRecoveryPage };
