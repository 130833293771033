import { DashboardMenuAction } from 'src/billpay/qbdt/pages/dashboard/components/DashboardTable/columns/actions-cells/types';
import { DashboardActionItemsEnum } from 'src/billpay/qbdt/pages/dashboard/components/DashboardTable/columns/actions-cells/consts';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import locations from 'src/billpay/qbdt/pages/locations';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';

type Props = {
  billId: string;
  paymentId?: string;
};
export const useEditPaymentMenuAction = ({
  billId,
  paymentId,
}: Props): { editPaymentMenuAction: DashboardMenuAction } => {
  const location = useLocation();
  const history = useHistory();

  const orgId = useOrgId();

  const exitUrl = `${location.pathname}${location.search}`;

  const goEditPayment = () => {
    history.push(
      generatePath(locations.edit.funding, {
        orgId,
        billId,
        paymentId,
      }),
      {
        exitUrl,
      }
    );
  };

  const editPaymentMenuAction = {
    id: DashboardActionItemsEnum.Edit,
    label: 'paymentDashboard.columns.actions.edit',
    onClick: goEditPayment,
  };

  return {
    editPaymentMenuAction,
  };
};
