import React from 'react';
import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { SelectBillingMethodPage } from 'src/billpay/qbdt/pages/billing-fee/SelectBillingMethodPage';
import { BillingFeeSuccessPage } from 'src/billpay/qbdt/pages/billing-fee/BillingFeeSuccessPage';
import { billingFee } from './locations';

export function BillingFeeRouter() {
  return (
    <Switch>
      <SmartRoute exact path={billingFee.add}>
        <SelectBillingMethodPage />
      </SmartRoute>
      <SmartRoute exact path={billingFee.success}>
        <BillingFeeSuccessPage />
      </SmartRoute>
    </Switch>
  );
}
