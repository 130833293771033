import { RecordOf } from 'immutable';
import React from 'react';
import { PaymentInfoSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSection';
import { PaymentInfoSectionDivider } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSectionDivider';
import { BILL_STATUS } from 'src/utils/consts';
import { AccountType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { isVendorRequest } from 'src/utils/bills';
import { ViewPaymentDeductionDateInfo } from './fields/ViewPaymentDeductionDateInfo';
import { ViewPaymentDeliveryDateInfo } from '../common-fields/ViewPaymentDeliveryDateInfo/ViewPaymentDeliveryDateInfo';
import { ViewPaymentFundingSourceInfo } from './fields/ViewPaymentFundingSourceInfo/ViewPaymentFundingSourceInfo';

type Props = {
  bill: BillType;
  payment: PaymentType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: RecordOf<AccountType>;
};

export const PayFromInfoSection = ({ bill, payment, deliveryMethod, fundingSource }: Props) => {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const payFromLabel = isBillPaid ? 'viewBillPaymentActivity.paidFromTitle' : 'viewBillPaymentActivity.payFromTitle';

  const isSharedVendor = isVendorRequest(bill);

  return (
    <PaymentInfoSection
      label={payFromLabel}
      testId="billpay-view-title-container"
      contentTestId="billpay-view-activity-container"
    >
      <ViewPaymentFundingSourceInfo fundingSource={fundingSource} bill={bill} />
      <PaymentInfoSectionDivider />
      <ViewPaymentDeductionDateInfo bill={bill} payment={payment} deliveryMethod={deliveryMethod} />

      {isSharedVendor && (
        <>
          <PaymentInfoSectionDivider />
          <ViewPaymentDeliveryDateInfo
            bill={bill}
            payment={payment}
            deliveryMethod={deliveryMethod}
            fundingSource={fundingSource}
          />
        </>
      )}
    </PaymentInfoSection>
  );
};
