import { put, takeEvery, all, call } from 'redux-saga/effects';

import { UPDATE_ORGANIZATION_PREFERENCE } from './actionTypes';
import {
  updateOrganizationPreferenceSuccessAction,
  updateOrganizationPreferenceFailedAction,
  updateOrganizationPreferenceAction,
} from './actions';
import organizationPreferencesApi from '../../services/api/organizationPreferences';

function* updateOrganizationPreference({
  orgId,
  key,
  value,
  resolve,
  reject,
}: ReturnType<typeof updateOrganizationPreferenceAction>) {
  try {
    yield call(organizationPreferencesApi.updateOrganizationPreference, orgId, key, value);
    yield put(updateOrganizationPreferenceSuccessAction(key, value));
    resolve();
  } catch (e) {
    yield put(updateOrganizationPreferenceFailedAction());
    reject();
  }
}

export function* organizationSagas() {
  yield all([takeEvery(UPDATE_ORGANIZATION_PREFERENCE, updateOrganizationPreference)]);
}
