import React from 'react';
import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import locations from './locations';
import { DashboardPage } from './DashboardPage';
import { DashboardEntry } from './DashboardEntry';

export const DashboardRouter = () => (
  <Switch>
    <SmartRoute path={locations.entry} exact>
      <DashboardEntry />
    </SmartRoute>
    <SmartRoute path={locations.dashboard} exact>
      <DashboardPage />
    </SmartRoute>
  </Switch>
);
