import React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import MIInlineLink from 'src/components/common/MIInlineLink';
import { ThreeDotsMenu } from 'src/components/common/tree-dots-menu/ThreeDotsMenu';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import {
  MethodOptionDescription,
  MethodOptionOnTopDescription,
  MethodOptionLabel,
  MethodOptionIcon,
  MethodOptionImage,
} from '../components/MethodElements';

type SettingsMethodsListItemProps = {
  id: string;
  icon?: string;
  imageSrc?: string;
  label: string;
  labelValues?: Record<string, any>;
  description: string;
  descriptionValues?: Record<string, any>;
  onTopDescription?: string | null;
  disabled?: boolean;
  actionOptions: ActionOption[];
  onClick?: (event: any) => void;
  isEmptyList?: boolean;
  isExpired?: boolean;
  hint: string;
  hintValues?: Record<string, any>;
  origin: string;
};

export const SettingsMethodsListItem: React.FC<SettingsMethodsListItemProps> = ({
  id,
  icon,
  imageSrc,
  label,
  labelValues = {},
  description,
  descriptionValues = {},
  onTopDescription,
  disabled,
  onClick,
  actionOptions,
  isEmptyList,
  isExpired,
  hint,
  hintValues,
  origin,
}) => {
  const showActionOptions = actionOptions && !!actionOptions.length;

  return (
    <SettingsMethodsListItemContainer disabled={disabled} data-testid={`radio-select-method-${id}`}>
      <SettingsMethodsItemContainer isEmptyList={isEmptyList}>
        {icon && <MethodOptionIcon className={icon} disabled={disabled} />}
        {imageSrc && <MethodOptionImage src={imageSrc} alt="option-icon" isExpired={isExpired} />}
        <MethodInfoContainer>
          <MethodOptionLabel disabled={disabled}>
            {onTopDescription && <MethodOptionOnTopDescription>{onTopDescription}</MethodOptionOnTopDescription>}
            {label && <MIFormattedText label={label} values={labelValues} />}
            {hint && (
              <PaymentInfoAdditionalText isExpired={isExpired}>
                <MIFormattedText label={hint} values={hintValues} />
              </PaymentInfoAdditionalText>
            )}
          </MethodOptionLabel>
          {description && (
            <MethodOptionDescription disabled={disabled} isExpired={isExpired} origin={origin}>
              <MIFormattedText label={description} values={descriptionValues} />
            </MethodOptionDescription>
          )}
        </MethodInfoContainer>
      </SettingsMethodsItemContainer>
      {isEmptyList && <StyledMIInlineLink label="settings.paymentMethods.hint" onClick={onClick} />}
      {showActionOptions && <ThreeDotsMenu actions={actionOptions} testId="settings-item-dots-menu" />}
    </SettingsMethodsListItemContainer>
  );
};

const SettingsMethodsListItemContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  border-radius: 0.8rem;
  border: ${(props) => `0.1rem solid ${props.theme.colors.border.grey}`};
  background-color: ${(props) => props.theme.colors.white.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1.5rem;
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        box-shadow: none;
      }
    `}
`;

const SettingsMethodsItemContainer = styled.div<{ isEmptyList?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 1.5rem 0;
  ${(props) => props.theme.components?.SettingsMethodsListItem?.SettingsMethodsItemContainer}
`;

const MethodInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

const PaymentInfoAdditionalText = styled.span<{ isExpired?: boolean }>`
  color: ${(props) => (props.isExpired ? props.theme.text.color.red : props.theme.text.color.label)};
  font-weight: ${(props) => props.theme.text.weight.regular};
  white-space: nowrap;
`;

export default SettingsMethodsListItem;
