import React from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Text } from '@melio/billpay-design-system';
import { ModalContentContainer, ModalMessage } from 'src/components/common/ModalMessage';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
};
export const DeleteBillingMethodModal = ({ onClose, onSubmit, isLoading }: Props) => (
  <StyledModal
    onCloseClick={onClose}
    titleComponent={<MIFormattedText label="settings.billing.billingFee.deleteModal.title" />}
    contentSubTitle={
      <Flex direction="column">
        <Text textStyle="ds.body1" m="0" mb="ds.2xl">
          <MIFormattedText label="settings.billing.billingFee.deleteModal.text" />
        </Text>
        <Box h="0.1rem" w="100%" bg="ds.gray.400" />
      </Flex>
    }
    buttonComponent={
      <Flex justify="end">
        <Button variant="secondary" size="md" onClick={onClose} mr="ds.lg" bg="ds.white" disabled={isLoading}>
          <MIFormattedText label="settings.billing.billingFee.deleteModal.cancelButton" />
        </Button>
        <Button variant="destructive" size="md" onClick={onSubmit} isLoading={isLoading}>
          <MIFormattedText label="settings.billing.billingFee.deleteModal.deleteButton" />
        </Button>
      </Flex>
    }
  />
);

const StyledModal = styled(ModalMessage)`
  ${ModalContentContainer} {
    padding: 4rem;
  }
`;
