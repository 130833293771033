import React from 'react';
import { Box, Tooltip } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

export const VirtualCardInfoIcon = () => (
  <Tooltip
    placement="bottom"
    label={<MIFormattedText label="payBillPaymentActivity.deliveryMethod.virtualCardTooltip" />}
  >
    <Box
      as="i"
      fontSize="1.6rem"
      ml="ds.sm"
      position="relative"
      top="ds.xs"
      color="ds.gray.400"
      data-testid="virtual-card-info-icon"
    >
      <i className="icon-info-hollow-icon" />
    </Box>
  </Tooltip>
);
