import entry from './entry/locations';
import { payLocations, editLocations } from './pay/locations';
import view from './view/locations';
import fundingSource from './funding-source/locations';
import batchPay from './batch-pay/locations';
import settings from './settings/locations';
import deliveryMethod from './delivery-method/locations';
import sync from './sync/locations';
import dashboard from './dashboard/locations';
import { billingFee } from './settings/components/billing/locations';
import entryErrors from './entry-errors/locations';

const baseUrl = '/quickbooks-desktop';

const LOCATIONS = {
  base: [baseUrl],
  entry,
  pay: payLocations,
  edit: editLocations,
  view,
  fundingSource,
  batchPay,
  settings,
  deliveryMethod,
  sync,
  billingFee,
  dashboard,
  entryErrors,
};

export default LOCATIONS;
