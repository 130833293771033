import React, { useEffect } from 'react';
import { OrganizationType, PaymentType } from 'src/utils/types';
import { Box, Flex } from '@melio/billpay-design-system';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { VendorReceive, PaymentInfo } from '../components/ACHDepositedPage';
import { useInvoiceNumberType } from '../../hooks/useInvoiceNumberType';

type Props = {
  organization: OrganizationType | undefined;
  payment: PaymentType | undefined;
};

export const ACHDepositedPage = ({ organization, payment }: Props) => {
  const amount = payment?.amount || 0;
  const companyName = organization?.companyName || '';
  const { deliveryMethod, deliveryEta } = payment || {};
  const { invoiceNumber, invoiceNumberType } = useInvoiceNumberType(payment);
  const digits = deliveryMethod?.bankAccount ? getAccountNumber4digits(deliveryMethod.bankAccount) : '';

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'ach-already-deposited', { OriginDeliveryType: 'ach' });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" w="100%" gap="ds.2xl">
          <PaymentInfo
            amount={amount}
            companyName={companyName}
            invoiceNumber={invoiceNumber}
            invoiceNumberType={invoiceNumberType}
          />
          <Box h="ds.sm" bg="ds.gray.800" w="full" />
          <VendorReceive digits={digits} depositedDate={deliveryEta} />
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
