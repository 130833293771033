import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import { achFeeFFDefaults } from 'src/billpay/qbdt/hooks/useAchFee/defaults';
import { DELIVERY_TYPE, FUNDING_TYPE } from 'src/utils/consts';
import { AchFeesFeatureFlag, FundingAndDeliveryProps } from 'src/billpay/qbdt/hooks/useAchFee/types';
import { OrgPreferencesTypeKeys } from 'src/utils/types';
import useUpdateOrganizationPreference from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { useCallback } from 'react';

export function orgPrefKey(type: AchFeesFeatureFlag['type']) {
  if (type === 'after') {
    return OrgPreferencesTypeKeys.qbdtAchFeeNotificationSeenAfter;
  }

  return OrgPreferencesTypeKeys.qbdtAchFeeNotificationSeen;
}

export function useAchFee() {
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();
  const [achFees] = featureFlags.useFeature(FeatureFlags.ACHFees, achFeeFFDefaults);

  const markAchFeeNotificationSeen = useCallback(
    (fundingSourceType: FUNDING_TYPE, deliveryMethodType: DELIVERY_TYPE) => {
      if (achFees.enabled && fundingSourceType === 'ach' && deliveryMethodType === 'ach') {
        updateOrganizationPreference({ key: orgPrefKey(achFees.type), value: 'yes' });
      }
    },
    [achFees.enabled, updateOrganizationPreference]
  );

  return { achFees, markAchFeeNotificationSeen };
}

export function shouldTreatNotification({
  selectedFundingSource,
  selectedDeliveryMethod,
  batchFundingSources,
}: FundingAndDeliveryProps) {
  if (selectedFundingSource !== undefined && selectedFundingSource?.fundingType !== FUNDING_TYPE.ACH) {
    return false;
  }

  if (selectedDeliveryMethod !== undefined && selectedDeliveryMethod !== DELIVERY_TYPE.ACH) {
    return false;
  }

  if (batchFundingSources !== undefined && !batchFundingSources.find((fs) => fs.fundingType === FUNDING_TYPE.ACH)) {
    return false;
  }

  return true;
}
