import React, { useCallback } from 'react';
import styled from 'styled-components';
import { MicroDepositState, MicroDepositActions } from 'src/pages/settings/hooks/microDepositsCommon';
import { MIFormattedText } from 'src/utils/formatting';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { getMicrodepositMask } from 'src/utils/currency-utils';
import { isEnterPressed } from 'src/utils/events';
import MINotification from 'src/components/common/MINotification';
import { MITextInput } from '../common/MITextInput';

type Props = MicroDepositState & MicroDepositActions;

function onKeyPressed(onSubmit, event: React.KeyboardEvent<any>) {
  if (isEnterPressed(event)) {
    onSubmit();
  }
}
export function VerifyMicroDepositsDialog(props: Props) {
  const {
    amount1,
    amount2,
    onChange,
    validationErrors,
    serverError,
    onSubmit,
    dismiss,
    isLoading,
    companyName,
  } = props;

  const keyPressed = useCallback((event) => onKeyPressed(onSubmit, event), [onSubmit]);
  return (
    <ModalMessage
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText label="settings.microDeposits.verifyDialog.title" />
          </div>
          <div className="text">
            <MIFormattedText label="settings.microDeposits.verifyDialog.subtitle" values={{ companyName }} />
          </div>
        </>
      }
      contentComponent={
        <>
          <SumsFormContainer onKeyDown={keyPressed}>
            <TextInputContainer>
              <MITextInput
                id="amount1"
                label="settings.microDeposits.verifyDialog.sum1Label"
                placeholder="settings.microDeposits.verifyDialog.placeholder"
                value={amount1}
                autoFocus
                required
                onChange={onChange}
                errorMessage={validationErrors.amount1}
                errorMessageIcon={<ErrorMessageIcon className="icon-warning-icon" size={1.6} />}
                mask={getMicrodepositMask()}
              />
            </TextInputContainer>
            <TextInputContainer>
              <MITextInput
                id="amount2"
                label="settings.microDeposits.verifyDialog.sum2Label"
                placeholder="settings.microDeposits.verifyDialog.placeholder"
                value={amount2}
                required
                onChange={onChange}
                errorMessage={validationErrors.amount2}
                errorMessageIcon={<ErrorMessageIcon className="icon-warning-icon" size={1.6} />}
                mask={getMicrodepositMask()}
              />
            </TextInputContainer>
          </SumsFormContainer>
          {serverError && (
            <ErrorMessage>
              <ErrorMessageText>
                <MIFormattedText label={serverError} />
              </ErrorMessageText>
              <ErrorMessageNotification>
                <MINotification
                  label={serverError}
                  icon={<ErrorMessageIcon className="icon-warning-icon" size={2} />}
                  type="error"
                />
              </ErrorMessageNotification>
            </ErrorMessage>
          )}
        </>
      }
      buttonLabel="settings.microDeposits.verifyDialog.confirm"
      onButtonClick={onSubmit}
      onCloseClick={dismiss}
      isLoading={isLoading}
    />
  );
}

const SumsFormContainer = styled.form`
  display: flex;
`;

const TextInputContainer = styled.div`
  width: 100%;
  &:first-child {
    margin-right: 2rem;
  }
  .input-container {
    margin-bottom: 0;
  }
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  margin-top: 2rem;
  ${(props) => props.theme.text.fontType.hint};
`;

const ErrorMessageIcon = styled.i`
  display: none;
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageIcon}
`;

const ErrorMessageText = styled.div`
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageText}
`;

const ErrorMessageNotification = styled.div`
  display: none;
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageNotification}
`;
