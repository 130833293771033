import { PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';
import { fetchRequest, postRequest } from '../../../services/api/api';

type BatchBillsParams = {
  isBillsGroupedByVendor: boolean;
  orgId: string;
  billIds: string[];
  createOrigin: PAYMENT_CREATE_FLOW_ORIGIN;
  fundingSourceId?: number;
  scheduledDate?: string;
  paymentsInfo: {
    [billId: string]: {
      deliveryMethodId: number;
      deliveryPreference: string;
    };
  };
};

const BILLS_IDS_SEPARATOR = ',';

export default {
  async groupedList({
    orgId,
    billIds,
    fundingSourceId,
    scheduledDate,
    isBillsGroupedByVendor,
    createOrigin,
  }: BatchBillsParams) {
    const url = isBillsGroupedByVendor
      ? `/orgs/${orgId}/qbdt/batch/list/grouped`
      : `/orgs/${orgId}/qbdt/batch/list/flatten`;
    const params = {
      orgId,
      billIds: billIds?.join(BILLS_IDS_SEPARATOR),
      fundingSourceId,
      scheduledDate,
      createOrigin,
    };
    const { batchList } = await fetchRequest(url, params);

    return { items: batchList };
  },
  createBatchBulkPayments({ orgId, payments }) {
    const url = `/orgs/${orgId}/qbdt/batch/create`;
    return postRequest(url, {
      payments,
    });
  },
};
