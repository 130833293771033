import React, { useMemo } from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardColumnsEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import { DashboardPaymentMethodCell } from '../common-cells/DashboardPaymentMethodCell/DashboardPaymentMethodCell';
import { DashboardScheduledActionCell } from '../actions-cells/actions-scheduled/DashboardScheduledActionsCell';
import { useDashboardTableVariant, DASHBOARD_TABLE_VARIANT } from '../hooks/useDashboardTableVariant';
import { DashboardVendorNameCell } from '../common-cells/DashboardVendorNameCell/DashboardVendorNameCell';
import { DashboardInvoiceNumberCell } from '../common-cells/DashboardInvoiceNumberCell';
import { DashboardDueDateCell } from '../common-cells/DashboardDueDateCell';
import { DashboardAmountCell } from '../common-cells/DashboardAmountCell';
import { DashboardDeductedDateCell } from '../common-cells/DashboardDeductedDateCell';
import { DashboardDeliveryEtaCell } from '../common-cells/DashboardDeliveryEtaCell';
import { DashboardDeliveryMethodCell } from '../common-cells/DashboardDeliveryMethodCell';
import { DashboardScheduledStatusCell } from './DashboardScheduledStatusCell';
import { ColumnItem } from '../../types';

type ResponseType = [ColumnItem<DashboardListItemType>[]];

export function useScheduledColumns(): ResponseType {
  const dashboardVariant = useDashboardTableVariant();
  const scheduledColumns = useMemo(() => {
    let columns = [
      {
        Header: <MIFormattedText label="paymentDashboard.columns.vendor.title" />,
        accessor: DashboardColumnsEnum.VendorName,
        Cell: DashboardVendorNameCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.invoiceNumber.title" />,
        accessor: DashboardColumnsEnum.InvoiceNumber,
        Cell: DashboardInvoiceNumberCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.dueDate.title" />,
        accessor: DashboardColumnsEnum.DueDate,
        Cell: DashboardDueDateCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.scheduledStatus.title" />,
        accessor: DashboardColumnsEnum.Status,
        Cell: DashboardScheduledStatusCell,
        width: '14.3rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.paymentMethod.title" />,
        accessor: DashboardColumnsEnum.FundingType,
        Cell: DashboardPaymentMethodCell,
        width: '13.3rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.deductionDate.title" />,
        accessor: DashboardColumnsEnum.ScheduledDate,
        Cell: DashboardDeductedDateCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.deliveryMethod.title" />,
        accessor: DashboardColumnsEnum.DeliveryType,
        Cell: DashboardDeliveryMethodCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.deliveryEta.title" />,
        accessor: DashboardColumnsEnum.DeliveryEta,
        Cell: DashboardDeliveryEtaCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.amount.title" />,
        accessor: DashboardColumnsEnum.PaymentAmount,
        Cell: DashboardAmountCell,
        isNumeric: true,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.actions.title" />,
        accessor: DashboardColumnsEnum.Actions,
        width: '18rem',
        Cell: DashboardScheduledActionCell,
        isNumeric: true,
        isOrderingDisabled: true,
      },
    ];

    if (dashboardVariant === DASHBOARD_TABLE_VARIANT.LIMITED) {
      columns = columns.filter(
        ({ accessor }) => ![DashboardColumnsEnum.DeliveryType, DashboardColumnsEnum.FundingType].includes(accessor)
      );
    }

    return columns;
  }, [dashboardVariant]);

  return [scheduledColumns];
}
