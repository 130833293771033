type IconStyleParams = {
  isActive: boolean;
};

export const iconStyle = ({ isActive }: IconStyleParams) => ({
  pl: 'ds.md',
  h: 'ds.lg',
  color: isActive ? 'ds.black' : 'ds.gray.300',
  svg: {
    display: 'inline',
    height: '5px',
  },
});
