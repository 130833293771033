import React from 'react';
import { close } from 'src/billpay/qbdt/services/qbdt';
import ErrorPage from 'src/components/layout/ErrorLayoutPage';
import noPaymentCreated from 'src/images/qbo/no-payment-created-icon.png';
import analytics from 'src/services/analytics';

const BillNotFound = () => {
  const goExit = () => {
    analytics.track('pay-bill-confirm', 'close-integration');
    close();
  };
  return (
    <ErrorPage
      illustration={noPaymentCreated}
      title="bills.view.error.notMelioBillTitle"
      subtitle="bills.view.error.notMelioBillSubtitle"
      buttonAction={goExit}
      buttonLabel="bills.view.error.buttonTextBillNoMelioFound"
    />
  );
};

export default BillNotFound;
