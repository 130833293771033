import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Flex, Icon, Link, Text } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { ReactComponent as BillingFeeIcon } from 'src/images/icons/billing-fee.svg';
import { billingFeeAnalytics } from 'src/billpay/qbdt/pages/settings/components/billing/events';
import { billingFee } from '../../../components/billing/locations';

export const EmptyBillingMethodCard = () => {
  const orgId = useOrgId();
  const history = useHistory();

  const onAddMethod = () => {
    billingFeeAnalytics.addMethod();
    history.push(generatePath(billingFee.add, { orgId }));
  };

  return (
    <Flex
      w="full"
      boxSizing="border-box"
      p="ds.xl"
      border="1px"
      borderColor="ds.gray.400"
      borderStyle="solid"
      borderRadius="ds.lg"
      fontWeight="ds.medium"
    >
      <Icon as={BillingFeeIcon} w="4rem" h="4rem" />
      <Flex direction="column" ml="ds.xl" flexGrow={1}>
        <Text textStyle="ds.body2" color="ds.gray.100" m="0" mb="ds.sm" fontWeight="ds.medium">
          <MIFormattedText label="settings.billing.billingFee.emptyMethodTitle" />
        </Text>
        <Text textStyle="ds.body2" color="ds.gray.300" m="0" fontWeight="ds.medium">
          <MIFormattedText label="settings.billing.billingFee.emptyMethodSubtitle" />
        </Text>
      </Flex>
      <Box as={Link} ml="1rem" textStyle="ds.body2" fontWeight="ds.medium" onClick={onAddMethod}>
        <MIFormattedText label="settings.billing.billingFee.addMethod" />
      </Box>
    </Flex>
  );
};
