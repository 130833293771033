import React from 'react';
import { Box, Menu } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  onClick: () => void;
  testId?: string;
};

export const AddDeliveryMethodMenuItem = ({ testId, onClick }: Props) => (
  <Menu.MenuItem as={Box} onClick={onClick} data-testid={testId} w="auto" color="ds.blue.200">
    <MIFormattedText label="regularBatchPayments.addAnotherDeliveryMethod" />
  </Menu.MenuItem>
);
