import React from 'react';
import { useSelector } from 'react-redux';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { getOrgId } from 'src/redux/user/selectors';
import { withRouter, generatePath, RouteComponentProps } from 'react-router-dom';
import { parseQueryString, convertStringToUrlObject } from '../utils/query-utils';

function getNavigate(props, orgId) {
  return (url, shouldReplaceCurrent = false, state = null) => {
    const parsedUrl = convertStringToUrlObject(url);

    parsedUrl.pathname = generatePath(parsedUrl.pathname, {
      orgId,
      ...props.match.params,
    });

    if (shouldReplaceCurrent) {
      return props.history.replace(parsedUrl, state);
    }

    return props.history.push(parsedUrl, state);
  };
}

function getPathWithParams(props) {
  return (url, params) => generatePath(url, { ...props.match.params, ...params });
}

export type NavigationProperties<P> = RouteComponentProps<P> & {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, unknown>) => void;
  locationState: Record<string, any>;
  basePath: string;
  pathWithParams: string;
  params: Record<string, unknown>;
  query: Record<string, string>;
};
export type ComponentWithoutNavigationProps<P> = React.ComponentClass<Omit<P, keyof NavigationProperties<any>>>;

function withNavigator() {
  return function <P>(Component: React.ComponentType<P>): ComponentWithoutNavigationProps<P> {
    return withRouter<RouteComponentProps<any>, any>((oProps) => {
      const props = { ...oProps };
      const orgId = useSelector(getOrgId);
      const navigate = getNavigate(oProps, orgId);
      const pathWithParams = getPathWithParams(oProps);

      const query = parseQueryString(oProps.location.search);
      return (
        <Component
          {...props}
          locationState={{ ...oProps.location.state }}
          basePath={oProps.location.pathname}
          navigate={navigate}
          pathWithParams={pathWithParams}
          params={props.match.params}
          query={query}
        >
          {props.children}
        </Component>
      );
    }) as any;
  };
}
export type NavigationState = {
  preservedState?: Record<string, any>;
  redirectUrl?: string;
  exitUrl: string;
} & Record<string, any>;

function withPreservedStateNavigator() {
  return function <P>(Component: React.ComponentType<P>) {
    return withRouter<NavigationProperties<P>, any>((oProps) => {
      const props = { ...oProps };
      const navigate = getNavigate(oProps, null);

      const query = parseQueryString(oProps.location.search);

      const handleNavigation = (url, shouldReplaceCurrent = false, state: any = null) => {
        const { preservedState, redirectUrl, exitUrl } = oProps.location.state || {};
        navigate(url, shouldReplaceCurrent, {
          ...state,
          preservedState,
          redirectUrl,
          exitUrl,
        });
      };

      const navigateWithPreservedState = (dataToAdd) => {
        const { preservedState, redirectUrl } = oProps.location.state || {};
        navigate(redirectUrl, false, { ...preservedState, ...dataToAdd });
      };

      const navigateToExitWithPreservedState = (dataToAdd) => {
        const { preservedState, exitUrl } = oProps.location.state || {};

        if (preservedState?.closeAppOnExit) {
          close();
        } else {
          navigate(exitUrl, false, { ...preservedState, ...dataToAdd });
        }
      };

      return (
        <Component
          {...props}
          locationState={{ ...oProps.location.state }}
          basePath={oProps.location.pathname}
          navigate={handleNavigation}
          navigateWithPreservedState={
            oProps.location.state && oProps.location.state.redirectUrl ? navigateWithPreservedState : null
          }
          navigateToExitWithPreservedState={
            oProps.location.state &&
            (oProps.location.state.exitUrl || oProps.location.state.preservedState?.closeAppOnExit)
              ? navigateToExitWithPreservedState
              : null
          }
          query={query}
        >
          {props.children}
        </Component>
      ) as any;
    });
  };
}

export { withNavigator, withPreservedStateNavigator };
