import React from 'react';
import MIForm from 'src/components/common/MIForm';
import { BusinessType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { useBusinessTypeOptions } from 'src/utils/useBusinessTypeOptions';
import { OnSelectNaicsCode } from 'src/billpay/qbdt/components/CompanyIndustryAutocomplete';

type Props = {
  isLoading?: boolean;
  businessType: BusinessType | null;
  validationErrors: Record<string, any>;
  onResetNaicsCode: () => void;
  onSelectNaicsCode: OnSelectNaicsCode;
  onSelectBusinessType: (value: BusinessType) => void;
};

const ComplianceInfoForm = (props: Props) => {
  const {
    isLoading,
    validationErrors,
    businessType,
    onSelectNaicsCode,
    onResetNaicsCode,
    onSelectBusinessType,
  } = props;
  const { businessTypeOptions } = useBusinessTypeOptions();

  if (isLoading) {
    return null;
  }

  const complianceInfoFormControls = () => {
    const complianceInfoForm = [
      {
        id: 'businessType',
        label: 'bills.pay.confirm.completeComplianceInfo.businessType.inputTitle',
        placeholder: 'bills.pay.confirm.completeComplianceInfo.businessType.emptyLabel',
        selected: businessType,
        onChange: ({ value }) => {
          onSelectBusinessType(value);
        },
        required: true,
        options: businessTypeOptions,
        errorMessage: validationErrors.businessType,
        controlType: CONSTS.FORM_CONTROL_TYPE.SELECT,
        initialState: false,
      },
      {
        id: 'companyIndustry',
        label: 'bills.pay.confirm.completeComplianceInfo.companyIndustry.inputTitle',
        placeholder: 'bills.pay.confirm.completeComplianceInfo.companyIndustry.emptyLabel',
        onSelectNaicsCode,
        onReset: onResetNaicsCode,
        required: true,
        errorMessage: validationErrors.companyIndustry,
        controlType: CONSTS.FORM_CONTROL_TYPE.INDUSTRY,
        initialState: false,
      },
    ];

    return complianceInfoForm.filter((form) => !form.initialState);
  };

  return (
    <MIForm
      formControls={complianceInfoFormControls()}
      mode={CONSTS.SCREEN_MODE.EDIT}
      formType={CONSTS.FORM_TYPE.WIZARD}
    />
  );
};

export default ComplianceInfoForm;
