import React, { useMemo } from 'react';
import { Flex } from '@melio/billpay-design-system';
import { MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import { isRppsVendor } from 'src/utils/vendor/vendor-directory';
import { ActionsMenu } from 'src/billpay/qbdt/pages/batch-pay/table/components/ActionsCell/ActionsMenu';

type Props = {
  rowHovered: boolean;
  row: MainTableRowType;
};

export const ActionsCell = ({ rowHovered, row }: Props) => {
  const stopPropagation = (event) => event.stopPropagation();
  const { id, vendor } = row;
  const isDirectPayment = isRppsVendor(vendor);
  const vendorId = vendor.id;

  return useMemo(
    () => (
      <Flex
        onClick={stopPropagation}
        justify="center"
        align="center"
        sx={{
          '& > span:focus': {
            outline: 'none',
          },
        }}
      >
        <ActionsMenu row={row} />
      </Flex>
    ),
    [row, id, isDirectPayment, vendorId, rowHovered, vendor]
  );
};
