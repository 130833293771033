import React from 'react';
import { Flex, Icon, Text, Link } from '@melio/billpay-design-system';
import { OrganizationType, PaymentType, VirtualCardType } from 'src/utils/types';
import { ReactComponent as ErrorIllustration } from 'src/images/general/error-icon.svg';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { CONSTS } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  organization?: OrganizationType;
  payment?: PaymentType;
  virtualCard?: VirtualCardType;
  onIssueNewCard: VoidFunction;
};

export const VirtualCardExpiredPage = ({ organization, payment, virtualCard, onIssueNewCard }: Props) => {
  const payorName = organization?.companyName;
  const amount = payment?.amount;
  const virtualCardEndDate = virtualCard?.endDate;
  const site = useSiteContext();
  const { analyticsProperties } = useStateChangedAnalytics({
    payment,
    virtualCard,
    paymentState: VirtualCardPaymentState.Expired,
  });
  const supportEmail = site.config.support.email;

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" justify="center" align="start" gap="ds.xl">
          <Icon as={ErrorIllustration} w="ds.2xl" h="ds.2xl" />
          <Flex direction="column" justify="center" align="start" gap="ds.md">
            <ContentTitle
              label="vendor.unilateral.virtualCardDetails.cardExpired.title"
              values={{
                endDate: <MIFormattedDate date={virtualCardEndDate} />,
              }}
            />
            <Text textStyle="ds.body2" mb="0" color="ds.gray.100">
              <MIFormattedText
                label="vendor.unilateral.virtualCardDetails.cardExpired.text"
                values={{
                  payorName,
                  amount: <MIFormattedCurrency value={amount || 0} />,
                }}
              />
            </Text>
          </Flex>
          <MIButton
            testId="issue-new-virtual-card-button"
            label="vendor.unilateral.virtualCardDetails.cardExpired.submit"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={onIssueNewCard}
            analyticsProperties={analyticsProperties}
            fullWidth
          />
          <Text textStyle="ds.body2" color="ds.gray.100" alignSelf="center" m="0">
            <MIFormattedText
              label="vendor.unilateral.virtualCardDetails.paymentCanceled.helpInfo"
              values={{
                contactSupport: (
                  <Link href={`mailto:${supportEmail}`}>
                    <MIFormattedText label="bills.actions.contactSupport" />
                  </Link>
                ),
              }}
            />
          </Text>
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
