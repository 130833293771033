import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryOptionType } from 'src/utils/types';
import { PaymentDeliveryOptions } from 'src/components/common/PaymentDeliveryOptions';
import { DeliveryWrapper } from './DeliveryWrapper';

type Props = {
  showLabel: boolean;
  label: string;
  values: Record<string, any>;
  deliveryOptions: DeliveryOptionType[];
  deliveryPreference?: string;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, type?: string) => void;
};

const DeliveryFastOptionsView = ({
  deliveryOptions,
  showLabel,
  label,
  values,
  deliveryPreference,
  fundingSourceType,
  onSelectDeliveryOption,
}: Props) => (
  <DeliveryWrapper deliveryOptions={deliveryOptions}>
    <Box textStyle="ds.body2" mt="ds.sm">
      <PaymentDeliveryOptions
        fundingSourceType={fundingSourceType}
        deliveryOptions={deliveryOptions}
        deliveryPreference={deliveryPreference}
        onSelectDeliveryOption={onSelectDeliveryOption}
      />
    </Box>

    {showLabel && (
      <>
        <Box
          bgColor="ds.gray.600"
          my="ds.lg"
          mx="calc(-1 * var(--chakra-space-ds-xl))"
          w="calc(100% + 2 * var(--chakra-space-ds-xl))"
          h="0.1rem"
        />
        <Box textStyle="ds.body3" color="ds.gray.200">
          <MIFormattedText label={label} values={values} />
        </Box>
      </>
    )}
  </DeliveryWrapper>
);

export { DeliveryFastOptionsView };
