import { fetchRequest, postRequest } from 'src/services/api/api';
import analytics from 'src/services/analytics';

import adapter from './adapter';
import { QBDTSDKError } from './sdk';
import { debug, fundingSourceTypeToAccountType } from './util';
import { deleteBillPayment } from './entities/billPayment';
import { CanCloseResponse, ENTITY_MAPPING, EntityType } from './adapter/types';
import { removeExtensionDef, verifyExtensionDefs } from './entities/dataExtension';
import { QBDTAccountType, QBDTDataExtAssignToObject, QBDTTxnType } from './types';
import { createAccount, getAccount, getAccounts } from './entities/account';
import { getQbdtVendor } from './entities/vendor';
import { getMelioIdActionErrorEventParams } from './sync/sync-utils';

export { createAccount, getAccounts } from './entities/account';

export * from './sync';

export type SelectedBillsResponse = {
  entry: string;
  entities: Array<{
    txnId: string;
    entityType: EntityType;
  }>;
};

export async function getSelectedBills() {
  try {
    const selectedBills = await adapter.getSelectedBills();

    // >> DEBUG
    debug('getSelectedBills', selectedBills);
    // << DEBUG
    const selectedBillsEntities = selectedBills?.response.map((i) => ({
      txnId: i.TxnId,
      entityType: ENTITY_MAPPING[i.TxnType],
    }));

    return {
      entry: selectedBills?.entry,
      entities: selectedBillsEntities || [],
    } as SelectedBillsResponse;
  } catch (e) {
    return {
      entry: '',
      entities: [],
    };
  }
}

export async function reportPaymentSummary(summary) {
  const response = await adapter.reportPaymentSummary(summary);

  // >> DEBUG
  debug('reportPaymentSummary', summary, response);
  // << DEBUG

  return response;
}

export function close() {
  if (isQBDTMac()) {
    adapter.macWindowClose();
  } else {
    window.close();
  }
}

export async function getBankAccounts() {
  return getAccounts(QBDTAccountType.Bank);
}

export async function getCreditCardAccounts() {
  return getAccounts(QBDTAccountType.CreditCard);
}

export async function getVendorInfo(vendorExternalId: string) {
  return getQbdtVendor(vendorExternalId);
}
export async function validateFundingSourceExternalId(orgId, fundingSourceId) {
  const { externalId, fundingType, cardAccount } = await fetchRequest(
    `/orgs/${orgId}/qbdt/funding-source/${fundingSourceId}/external-reference`
  );

  if (externalId) {
    try {
      const account = await getAccount(externalId);

      // An account on QBDT can change its type, so we verify this as well
      return (
        ((fundingType === 'ach' || cardAccount === 'debit') && account.AccountType === QBDTAccountType.Bank) ||
        (fundingType === 'card' && cardAccount === 'credit' && account.AccountType === QBDTAccountType.CreditCard)
      );
    } catch (err) {
      if (err instanceof QBDTSDKError && err.code === '500') {
        return false;
      }

      throw err;
    }
  }

  return false;
}

export function linkAccount(orgId, fundingSourceId, externalId) {
  return postRequest(`/orgs/${orgId}/qbdt/funding-source/${fundingSourceId}/external-reference/${externalId}`);
}

export async function createAndLinkAccount(orgId, fundingSourceId, fundingSourceType, fundingSourceDisplayName) {
  const account = await createAccount(fundingSourceDisplayName, fundingSourceTypeToAccountType(fundingSourceType));
  await linkAccount(orgId, fundingSourceId, account.ListID);

  return account.ListID;
}

async function deleteMelioIdFromBill(txnId: string) {
  try {
    await removeExtensionDef(QBDTDataExtAssignToObject.Bill, txnId);
  } catch (error) {
    const eventParams = {
      ...getMelioIdActionErrorEventParams(error),
      externalBillId: txnId,
    };
    analytics.track('pay-bill', 'remove-melio-id-from-bill-failed', eventParams);
  }
}

export async function deletePayment(externalPaymentId: string) {
  const res = await deleteBillPayment(externalPaymentId);
  await Promise.all(
    res.LinkedTxn.filter((txn) => txn.TxnType === QBDTTxnType.Bill).map((txn) => deleteMelioIdFromBill(txn.TxnID))
  );
}

export async function startSession() {
  const res = await adapter.initialize();
  debug('QBDT initalize', res);
  await verifyExtensionDefs();
}

export async function getCompanyFileGuid() {
  const response = await adapter.getCompanyFileGuid();
  // >> DEBUG
  debug('getCompanyFileGuid', response);
  // << DEBUG

  return response;
}

export type CanCloseOptions = {
  message?: string;
  title?: string;
};
export async function setCanClose(canClose: boolean, options?: CanCloseOptions) {
  const value: CanCloseResponse = {
    Icon: 'Exclamation',
    Button: 'YesNo',
    Title: options?.title || 'Scheduled Online Payments',
    Message: options?.message || 'Scheduled Online Payment is in progress. Are you sure you want to close?',
    CanClose: canClose ? 'true' : 'false',
  };
  await adapter.setCanClose(value);
}

export function isQBDTMac() {
  return navigator?.userAgent?.indexOf('Macintosh') > -1;
}
