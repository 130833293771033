import { deleteRequest, fetchRequest, postRequest, putRequest } from './api';

export default {
  getVendors({ orgId, start = 0, limit = 0, filters = {}, shouldMelioMeVendorHasDeliveryMethod = false }) {
    const url = `/orgs/${orgId}/vendors`;
    return fetchRequest(url, {
      start,
      limit,
      shouldMelioMeVendorHasDeliveryMethod,
      ...filters,
    });
  },

  validateVendorName(orgId, params) {
    const url = `/orgs/${orgId}/vendors/validateVendorName`;
    return postRequest(url, params);
  },

  createVendor(orgId, params) {
    const url = `/orgs/${orgId}/vendors`;
    return postRequest(url, params);
  },

  createOwnedVendor(orgId) {
    const url = `/orgs/${orgId}/vendors/create-owned`;
    return postRequest(url);
  },

  getVendorById({ orgId, id }) {
    const url = `/orgs/${orgId}/vendors/${id}`;
    return fetchRequest(url);
  },

  editVendorById({ orgId, id, params }) {
    const url = `/orgs/${orgId}/vendors/${id}`;
    return putRequest(url, params);
  },

  updateTrustedVendor(params) {
    const url = `/orgs/${params.orgId}/vendors/${params.vendorId}/trust`;
    return putRequest(url, { isTrusted: params.isTrusted });
  },

  deleteVendorById(orgId, id) {
    const url = `/orgs/${orgId}/vendors/${id}`;
    return deleteRequest(url);
  },

  getHandleSuggestion(orgId, id) {
    const url = `/orgs/${orgId}/vendors/${id}/handle-suggestion`;
    return fetchRequest(url);
  },

  setHandle(orgId, id, params) {
    const url = `/orgs/${orgId}/vendors/${id}/handle`;
    return postRequest(url, params);
  },

  getVendorsDeliveryMethods(orgId) {
    const url = `/orgs/${orgId}/vendors/delivery-methods`;
    return fetchRequest(url);
  },

  createVendorsBatch({ orgId, data }) {
    const url = `/orgs/${orgId}/vendors/create-batch`;
    return postRequest(url, data).then((res) => res.vendors);
  },

  updateVendorsBatch({ orgId, data }) {
    const url = `/orgs/${orgId}/vendors/update-batch`;
    return putRequest(url, data).then((res) => res.vendors);
  },

  getOwnedVendorExists({ orgId, id }) {
    const url = `/orgs/${orgId}/vendors/${id}/ownedVendor`;
    return fetchRequest(url).then((res) => res.result);
  },

  checkVendorPaymentPreferences({ orgId, id }) {
    const url = `/orgs/${orgId}/vendors/${id}/payment-preferences`;
    return fetchRequest(url);
  },

  getIntuitAcctNum(orgId, id) {
    const url = `/orgs/${orgId}/vendors/${id}/vendor-intuit-acct-num`;
    return fetchRequest(url);
  },

  getVendorMCCCode(orgId, vendorId) {
    const url = `/orgs/${orgId}/vendor-traits/mcc-code?vendorId=${vendorId}`;

    return fetchRequest(url);
  },

  updateVendorMCCCode({ orgId, vendorId, mccCode }) {
    const url = `/orgs/${orgId}/vendor-traits/mcc-code`;

    return putRequest(url, { vendorId, mccCode });
  },
};
