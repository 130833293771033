import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import MIInlineLink from 'src/components/common/MIInlineLink';
import { CONSTS } from './consts';
import { PaymentType } from './types';
import paymentApi from '../services/api/payments';

const emailVendorOnPaymentSchedule = (payment: PaymentType, orgId: string, contactEmail: string) => {
  if (
    payment.deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.CHECK &&
    moment(payment.scheduledDate).isAfter(moment()) &&
    !!contactEmail
  ) {
    paymentApi.sendEditCheckAddress(orgId, payment.id);
  }
};

const renderBlockedForPaymentDialog = (site: any, onCancelAction: () => void) => (
  <site.components.Dialog
    type={CONSTS.DIALOG_TYPE.CONFIRM}
    variant={CONSTS.DIALOG_VARIANTS.WARNING}
    title="bills.form.blockedForPaymentDialog.title"
    subtitle="bills.form.blockedForPaymentDialog.subtitle"
    subtitleValues={{
      supportEmail: (
        <StyledMIInlineLink
          target="_self"
          text={site.config.support.email}
          to={`mailto:${site.config.support.email}`}
        />
      ),
    }}
    cancelButtonText="dialogs.buttons.close"
    onCancelAction={onCancelAction}
  />
);

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: ${(props) => props.theme.text.size.regular};
`;

export { emailVendorOnPaymentSchedule, renderBlockedForPaymentDialog };
