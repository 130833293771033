import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Flex } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { RecordOf } from 'immutable';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import { useLocationState } from 'src/utils/hooks';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchPageLayout } from 'src/billpay/qbdt/pages/batch-pay/components/layout/BatchPageLayout';
import styled from 'styled-components';
import BatchTable from 'src/billpay/qbdt/pages/batch-pay/components/BatchTable';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { useCreateBatchPayments } from 'src/billpay/qbdt/pages/batch-pay/hooks/useCreateBatchPayments';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { NotificationCardUnreadyBills } from 'src/billpay/qbdt/pages/batch-pay/components/notificationCards/NotificationCardUnreadyBills';
import batchPaymentsLocation from 'src/billpay/qbdt/pages/batch-pay/locations';
import { useDebitFee } from 'src/billpay/qbdt/hooks/useDebitFee';
import { useCheckFee } from 'src/billpay/qbdt/hooks/useCheckFee';
import { NotificationDM as CheckFeeNotification } from 'src/billpay/qbdt/hooks/useCheckFee/NotificationDM';
import { useAmexVerification } from 'src/billpay/qbdt/hooks/useAmexVerification';
import { QBDTStepLayout, QBDTLayoutPageFooter, QBDTHeader } from 'src/billpay/qbdt/components';
import { AccountType } from 'src/utils/types';
import { AmexVerificationContextProvider } from 'src/billpay/qbdt/context/AmexVerificationContext';
import { FeatureFlags } from 'src/utils/feature-flags';
import MIButton from 'src/components/common/MIButton';
import { CONSTS, DELIVERY_TYPE } from 'src/utils/consts';
import { isCompanyInfoDone } from 'src/utils/company';
import { isComplianceInfoDone } from 'src/utils/compliance-utils';
import analytics from 'src/services/analytics';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { isFastDeliveryType } from 'src/utils/delivery-methods';
import { Notification as AchFeeNotification } from 'src/billpay/qbdt/hooks/useAchFee/Notification';
import { NotificationCardLimitBills } from './components/notificationCards/NotificationCardLimitBills';
import useGetValidFundingSources from './table/hooks/useGetValidFundingSources';
import { useAddFundingSourceRedirect } from './hooks/useAddFundingSourceRedirect';
import { useBatchSettings } from './hooks/useBatchSettings';
import { NotificationCardAlreadyScheduledBills } from './components/notificationCards/NotificationCardAlreadyScheduledBills';
import { CheckDelaysNotificationCard } from './components/notificationCards/CheckDelaysNotificationCard';
import { BillsExceedAmountLimitCard } from './components/notificationCards/BillsExceedAmountLimitCard';
import { useOnBatchExit } from './hooks/useOnBatchExit';
import { useScheduleBatchPayments } from './hooks/useScheduleBatchPayments';
import { useComplianceLimitations } from '../pay/confirm/hooks/useComplianceLimitations';

export const PayBatchBillPage = () => {
  const [isCheckDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);

  const [isCheckDelaysNotificationShown, setIsCheckDelaysNotificationShown] = useState(true);

  const companyInfo = useSelector(getCompanyInfo);

  const [isBillsOverLimit] = useLocationState('isBillsOverLimit', false);
  const [billsOverLimitAmount] = useLocationState('billsOverLimitAmount', 0);

  const history = useHistory();
  const orgId = useOrgId();

  const {
    createBatchPaymentData: { error },
  } = useCreateBatchPayments();

  const allBillsReady = useSelector(regularBatchPaymentsStore.selectors.allBillsReady);

  const [validFundingSources] = useGetValidFundingSources();

  const [isSyncing, setIsSynced] = useState(false);

  const debitFee = useDebitFee();
  const checkFee = useCheckFee();
  const { limitations, isComplianceLimitationsLoading } = useComplianceLimitations();
  const { renderAmexModal, openAmexModal } = useAmexVerification();
  const isCheckFeePromotions = checkFee.isCheckFeeOpenWithPromotions(checkFee.checkFeeFlag);

  const isLoading = isSyncing || isComplianceLimitationsLoading;

  const { billsList } = useBatchBillsList();
  const batchActions = useStoreActions(regularBatchPaymentsStore);

  useAddFundingSourceRedirect();

  const { batchSettings, changeBatchSettings } = useBatchSettings();
  const { refreshRequired, billIds, hasScheduledPaymentsForBills, billsExceedAmountLimitCount } = batchSettings;

  const { onBatchExit } = useOnBatchExit();

  const { scheduleBatchPayments } = useScheduleBatchPayments();

  useEffect(() => {
    // get number of free checks if avilable
    if (isCheckFeePromotions) checkFee.getFreeChecks({ isBatch: true });
  }, [isCheckFeePromotions]);

  useEffect(() => {
    if (hasScheduledPaymentsForBills) {
      analytics.trackAction('some-bills-already-scheduled-card-shown');
    }
  }, [hasScheduledPaymentsForBills]);

  const ids = isEmpty(billIds) ? [] : billIds;

  useEffect(() => {
    if (refreshRequired) {
      (async () => {
        const fundingSourceId = validFundingSources[0]?.id;
        const billIds = ids;

        await changeBatchSettings(
          { billIds, fundingSourceId, refreshRequired: false },
          { refreshEffectiveDate: true, refreshBillsList: true }
        );
      })();
    }
  }, [refreshRequired, ids, validFundingSources]);

  const hasDebitModal = () => {
    const debitBill = billsList.find((bill) => bill?.fee?.debit);

    if (!debitBill) return false;

    const debitFundingSource = validFundingSources.find((fs) => fs.id === debitBill?.payment?.fundingSourceId);

    return debitFundingSource && debitFee.shouldDisplayModal(debitFundingSource as RecordOf<AccountType>);
  };

  const batchSubmit = async ({ skipDebitCheck = false }: any = {}) => {
    // fee modal
    if (!skipDebitCheck && hasDebitModal()) {
      debitFee.openModal();

      return undefined;
    }

    const shouldRedirectToLegalInfoPage = allBillsReady && !isCompanyInfoDone(companyInfo, limitations);
    const shouldRedirectToCompleteComplianceInfoPage =
      allBillsReady && limitations && !isComplianceInfoDone(limitations);

    if (shouldRedirectToLegalInfoPage) {
      history.push(generatePath(batchPaymentsLocation.batchCompleteLegalInfo, { orgId }));

      return undefined;
    }

    if (shouldRedirectToCompleteComplianceInfoPage) {
      history.push(generatePath(batchPaymentsLocation.batchCompleteComplianceInfo, { orgId }));

      return undefined;
    }

    setIsSynced(true);

    await scheduleBatchPayments({
      onNoPaymentsScheduled: () => {
        setIsSynced(false);
      },
      onCreateBatchValidationError: () => {
        setIsSynced(false);
      },
    });

    return undefined;
  };

  // custom submit for debit fee modal
  const onDebitFeeNext = () => batchSubmit({ skipDebitCheck: true });
  const handleOnCloseAlreadyScheduledBillsNotification = () => {
    batchActions.settings.update({
      hasScheduledPaymentsForBills: false,
    });
  };

  const handleOnCloseBillsExceedAmountLimitNotification = () => {
    batchActions.settings.update({
      billsExceedAmountLimitCount: 0,
    });
  };

  const handleOnCloseCheckDelaysNotification = () => {
    setIsCheckDelaysNotificationShown(false);
  };

  const handleAmexMCCCodeChange = ({ vendorId, mccCode }) => {
    batchActions.updatePayments.setVendorMCCCode({
      vendorId,
      mccCode,
    });
  };

  if (isLoading) {
    return <QBDTLoader />;
  }

  const shouldShowChecksDelaysWarning =
    isCheckDelaysEnabled &&
    isCheckDelaysNotificationShown &&
    billsList.some(
      (item) =>
        item.payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK &&
        !isFastDeliveryType(item.payment.deliveryPreference)
    );

  const shouldShowBillExceedAmountLimitNotification = billsExceedAmountLimitCount > 0;

  return (
    <StyledQBDTStepLayout
      goExit={onBatchExit}
      contentWrapperMode="batchList"
      qboFooter={<Footer isSubmitDisabled={isEmpty(ids)} goExit={onBatchExit} createBatchPayment={batchSubmit} />}
      innerSize={100}
      bgColor="white"
    >
      <BatchPageLayout>
        <checkFee.NotificationAppliedFee
          batchData={billsList}
          batchFundingSources={validFundingSources}
          styles={{ mb: 0, mt: 'ds.xl' }}
        />
        <AchFeeNotification batchFundingSources={validFundingSources} className="batch" />
        <StyledCheckFeeNotification batchData={billsList} batchFundingSources={validFundingSources} />
        {hasScheduledPaymentsForBills && (
          <NotificationCardAlreadyScheduledBills onClose={handleOnCloseAlreadyScheduledBillsNotification} />
        )}
        {shouldShowBillExceedAmountLimitNotification && (
          <BillsExceedAmountLimitCard
            onClose={handleOnCloseBillsExceedAmountLimitNotification}
            billsCount={billsExceedAmountLimitCount}
          />
        )}
        {error && !allBillsReady && <NotificationCardUnreadyBills />}
        {isBillsOverLimit && <NotificationCardLimitBills billsAmount={billsOverLimitAmount} />}
        {shouldShowChecksDelaysWarning && (
          <CheckDelaysNotificationCard onClose={handleOnCloseCheckDelaysNotification} />
        )}
        <AmexVerificationContextProvider
          value={{
            openAmexModal,
          }}
        >
          <BatchTable />
        </AmexVerificationContextProvider>
        <debitFee.Modal onNext={onDebitFeeNext} />
        {renderAmexModal({ onCodeChange: handleAmexMCCCodeChange })}
      </BatchPageLayout>
    </StyledQBDTStepLayout>
  );
};

const StyledQBDTStepLayout = styled(QBDTStepLayout)<{ bgColor: string }>`
  ${QBDTLayoutPageFooter} {
    position: sticky;
    bottom: 0;
  }

  ${QBDTHeader} {
    background-color: ${({ bgColor }) => bgColor};
  }
`;

const StyledCheckFeeNotification = styled(CheckFeeNotification)`
  margin: 0;
  margin-top: 2rem;
`;

const Footer = ({ goExit, createBatchPayment, isSubmitDisabled }) => (
  <Flex justify="flex-end" backgroundColor="ds.gray.100" align="center" px="ds.lg" py="ds.xl" gap="ds.lg">
    <StyledCancelButton
      label="batchPayment.buttons.cancel"
      testId="button-batchPayment.buttons.cancel"
      variant={CONSTS.BUTTON_VARIANT.SECONDARY}
      onClick={goExit}
    />
    <MIButton
      label="batchPayment.buttons.scheduleAllPayments"
      testId="button-batchPayment.buttons.scheduleAllPayments"
      variant={CONSTS.BUTTON_VARIANT.PRIMARY}
      onClick={createBatchPayment}
      disabled={isSubmitDisabled}
    />
  </Flex>
);

const StyledCancelButton = styled(MIButton)`
  color: var(--chakra-colors-ds-white);
  border-color: var(--chakra-colors-ds-white);
  height: 3.6rem;
  padding: 0.6rem 2.4rem;
`;
