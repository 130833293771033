export const FONT_WEIGHT = {
  BOLD: 'bold',
  SEMI: 600,
  NORMAL: 'normal',
};

const textStyles = {
  h1: {
    fontSize: '4.8rem',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '6.4rem',
  },
  h2: {
    fontSize: '4rem',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '5.2rem',
  },
  h3: {
    fontSize: '3.4rem',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '4.2rem',
  },
  h4: {
    fontSize: '2.4rem',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '3.2rem',
  },
  h5: {
    fontSize: '2.4rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '3.2rem',
  },
  h6: {
    fontSize: '2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2.8rem',
  },
  h7: {
    fontSize: '1.6rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2.4rem',
  },
  h8: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2rem',
  },
  body1: {
    fontSize: '2.4rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '3.2rem',
  },
  body2: {
    fontSize: '2.4rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '3.2rem',
  },
  body3: {
    fontSize: '2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2.8rem',
  },
  body4: {
    fontSize: '2rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2.8rem',
  },
  body5: {
    fontSize: '1.6rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2.4rem',
  },
  body6: {
    fontSize: '1.6rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2.4rem',
  },
  body7: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2rem',
  },
  body8: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2rem',
  },
  body9: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.8rem',
  },
  body10: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.8rem',
  },
  caption: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.6rem',
    textTransform: 'capitalize',
  },
  note: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.4rem',
  },
  noteSemi: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.4rem',
  },
  leftMenu: {
    fontSize: '0.8rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.2rem',
  },
  badge: {
    fontSize: '0.8rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.2rem',
    textTransform: 'uppercase',
  },
  toastAction: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '1.6rem',
    textTransform: 'uppercase',
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.6rem',
    textTransform: 'uppercase',
    letterSpacing: '0.04rem',
  },
  bodyLinkButton1: {
    fontSize: '1.6rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2.4rem',
    color: 'primary.300',
  },
  bodyLinkButton2: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2rem',
    color: 'primary.300',
  },
  bodyLinkButton3: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.8rem',
    color: 'primary.300',
  },
  bodyLinkButton4: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.4rem',
    color: 'primary.300',
  },
  addAnotherDeliverMethod: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.8rem',
    color: 'blue.600',
  },
  addAnotherPaymentMethod: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '2rem',
    color: 'blue.600',
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
