import { CONSTS } from 'src/utils/consts';
import { CheckType, DeliveryMethodType } from 'src/utils/types';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { getFormattedAddress } from 'src/utils/formatting';

export const getDeliveryMethodLabel = (method?: DeliveryMethodType) => {
  let label = '';
  switch (method?.deliveryType) {
    case CONSTS.DELIVERY_TYPE.ACH:
      label = 'regularBatchPayments.deliveryMethodLabels.bank';
      break;
    case CONSTS.DELIVERY_TYPE.CHECK:
      label = 'regularBatchPayments.deliveryMethodLabels.check';
      break;
    case CONSTS.DELIVERY_TYPE.VIRTUAL:
      label = 'regularBatchPayments.deliveryMethodLabels.virtual';
      break;
    case CONSTS.DELIVERY_TYPE.VIRTUAL_CARD:
      label = 'regularBatchPayments.deliveryMethodLabels.virtualCard';
      break;
    case CONSTS.DELIVERY_TYPE.RPPS:
      label = 'regularBatchPayments.deliveryMethodLabels.direct';
      break;
    default:
      label = 'regularBatchPayments.deliveryMethodLabels.chooseDM';
      break;
  }

  return { label, values: {} };
};

export const getDeliveryMethodDescription = (method?: DeliveryMethodType) => {
  let label = '';
  let values = {};
  if (method?.deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
    const digits = getAccountNumber4digits(method.bankAccount);
    label = 'regularBatchPayments.deliveryMethodLabels.bankDescription';
    values = { digits };
  } else if (method?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
    const displayName = getFormattedAddress(method.paperCheck as CheckType);
    values = { displayName };
    label = 'regularBatchPayments.deliveryMethodLabels.checkDescription';
  } else if (method?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
    label = 'regularBatchPayments.deliveryMethodLabels.virtualDescription';
  }

  return { label, values };
};
