import React from 'react';
import { Text } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  label: string;
  values?: Record<string, any>;
};

export const DeliveryMethodSubTitle = ({ label, values }: Props) => (
  <Text textStyle="ds.body2" fontWeight="ds.medium" color="ds.gray.200" m="0">
    <MIFormattedText label={label} values={values} />
  </Text>
);
