import { Box } from '@melio/billpay-design-system';
import React from 'react';

type Props = {
  my?: string;
  h?: string;
};

export const Divider = ({ my, h }: Props) => (
  <Box my={my || 'ds.2xl'} h={h || 'ds.sm'} backgroundColor="ds.gray.800" w="100%" />
);
