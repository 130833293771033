import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CONSTS } from 'src/utils/consts';
import { useBreak } from 'src/hoc';
import styled, { css } from 'styled-components';
import { zendeskService } from 'src/services/zendesk';
import { getFaqPanel } from 'src/redux/payBillWizard/selectors';
import { setFaqPanel } from 'src/redux/payBillWizard/actions';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import analytics from 'src/services/analytics';
import MIButton from 'src/components/common/MIButton';
import { FAQAccordion } from './FAQAccordion';

type Props = {
  onCloseCallback?: () => void;
};
const eventPage = 'qbdt';

export const FAQDrawer = ({ onCloseCallback }: Props) => {
  const dispatch = useDispatch();
  const { isOpen, focusedQuestion } = useSelector(getFaqPanel);
  const openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    zendeskService.show();
  };
  const { isMobile } = useBreak();
  const site = useSiteContext();

  useEffect(() => {
    if (isOpen && focusedQuestion) {
      const toElement = document.getElementById(`faq-${focusedQuestion}`);

      if (toElement) {
        toElement.click();
        setTimeout(() => {
          toElement.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    }
  }, [isOpen]);

  const onClose = () => {
    onCloseCallback?.(); // cb from component
    dispatch(setFaqPanel({ isOpen: false })); // closing from redux
  };

  return (
    <DrawerContainer isDrawerOpen={isOpen}>
      <Drawer className={isOpen ? 'open' : ''}>
        <Close>
          <CloseAction onClick={onClose}>
            <i className="icon-close-icon" />
          </CloseAction>
        </Close>
        <TitleContainer>
          <MIFormattedText label="qbo.drawer.title" values={{ br: <br /> }} />
        </TitleContainer>
        <DrawerContentContainer>
          <SupportTeamLabel>
            <MIFormattedText label="qbo.drawer.contactLabel" />
          </SupportTeamLabel>
          <ContactContainer>
            <ContactLabel>
              <MIFormattedText label="qbo.drawer.emailLabel" />
              <MIFormattedText
                label="qbo.drawer.supportEmailAddress"
                values={{ supportEmail: site.config.support.email }}
              />
            </ContactLabel>
            <StartChatInline>
              <MIFormattedText label="qbo.drawer.orLabel" />
              <SupportLink onClick={openSupportChat}>
                <MIFormattedText label="qbo.drawer.chatLabel" />
              </SupportLink>
            </StartChatInline>
          </ContactContainer>
          <ButtonWrapper>
            <MIButton
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              label="qbo.launcher.startChat"
              onClick={openSupportChat}
              iconClass="icon-support-icon"
              fullWidth={isMobile}
            />
          </ButtonWrapper>
        </DrawerContentContainer>
        <FAQAccordion />
      </Drawer>
    </DrawerContainer>
  );
};

const DrawerContainer = styled.div<{ isDrawerOpen?: boolean }>`
  z-index: ${(props) => props.theme.zIndex.overlay};
  position: ${(props) => (props.isDrawerOpen ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.dark.translucent5};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${devices.nonDesktop} {
    ${({ isDrawerOpen }) =>
      !isDrawerOpen &&
      css`
        height: 0;
      `}
  }
`;

const Drawer = styled.div`
  width: 56.5rem;
  padding: 2rem 3rem 3rem;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.opaque};
  position: fixed;
  z-index: 1;
  top: 0;
  right: -56.5rem;
  overflow-x: hidden;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;

  &.open {
    right: 0;

    @media ${devices.mobile} {
      width: 100%;
    }
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4.2rem 1.6rem 0 1.6rem;
  }
`;

const Close = styled.div`
  font-size: 1.8rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: ${(props) => props.theme.text.size.title};
  }
`;

const CloseAction = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.text.color.label};

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;

const TitleContainer = styled.div`
  color: rgba(37, 37, 37, 1);
  font-weight: ${(props) => props.theme.text.weight.normal};
  font-size: 2.4rem;
  line-height: 3.2rem;
`;
const ButtonWrapper = styled.div`
  display: none;
  @media ${devices.mobile}, ${devices.phablet} {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    width: 100%;
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

const DrawerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;

const SupportTeamLabel = styled.div`
  color: rgba(73, 73, 73, 1);
  font-weight: ${(props) => props.theme.text.weight.normal};
  font-size: 1.4rem;
  line-height: 3.1rem;
  margin-top: 0.5rem;
  @media ${devices.mobile}, ${devices.phablet} {
    line-height: 2.4rem;
  }
`;

const ContactContainer = styled.div`
  font-size: 1.4rem;
  line-height: 1.7rem;
  @media ${devices.mobile}, ${devices.phablet} {
    line-height: 2.4rem;
  }
`;

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
`;

const StartChatInline = styled.span`
  @media ${devices.mobile}, ${devices.phablet} {
    display: none;
  }
`;

const ContactLabel = styled.span``;
