import React from 'react';
import styled from 'styled-components';
import { RecordOf } from 'immutable';

import { CONSTS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { AccountType, OptionalDeliveryMethodsType } from 'src/utils/types';
import { ReturnType as CheckFeeReturnType } from 'src/billpay/qbdt/hooks/useCheckFee/types';
import { OrganizationBillingFee } from 'src/services/api/smb-billing-fee';
import { PaymentRegularFeeCheck } from './PaymentRegularFeeCheck';

type PaymentRegularFeeTotalProps = {
  feeType?: string;
  textValues: Record<string, any>;
  feeText: React.ReactNode;
  showFeeHint: boolean;
  fundingSource?: RecordOf<AccountType>;
  deliveryMethodType?: OptionalDeliveryMethodsType | undefined;
  checkFee?: CheckFeeReturnType;
  isEdit?: boolean | undefined;
  organizationBillingFees: OrganizationBillingFee[];
};

const PaymentRegularFeeTotal = ({
  feeType,
  textValues,
  feeText,
  showFeeHint,
  fundingSource,
  deliveryMethodType,
  checkFee,
  isEdit,
  organizationBillingFees,
}: PaymentRegularFeeTotalProps) => {
  const isCheckDM = deliveryMethodType === CONSTS.DELIVERY_TYPE.CHECK;

  if (isCheckDM && checkFee) {
    const { hasAchCheckFee, hasCheckZeroFeeUI, hasPromotions } = checkFee.getPaymentFeeValues({
      fundingSource,
      fee: textValues?.finalAmount,
      feeType,
    });

    if (hasAchCheckFee || hasCheckZeroFeeUI) {
      return (
        <PaymentRegularFeeCheck
          textValues={textValues}
          checkFee={checkFee}
          isEdit={isEdit}
          hasPromotions={hasPromotions}
          organizationBillingFees={organizationBillingFees}
        />
      );
    }
  }

  return (
    <TotalFee data-testid="total-fee">
      {feeText}
      {showFeeHint && (
        <FeeHint>
          <MIFormattedText label="bills.pay.confirm.feeHint" values={textValues} />
        </FeeHint>
      )}
    </TotalFee>
  );
};

export { PaymentRegularFeeTotal };

const TotalFee = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.text.size.subTitle};
  height: 3rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFee}
`;

const FeeHint = styled.span`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.label};
  align-self: center;
  margin-left: 1rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeHint}
`;
