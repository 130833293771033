import React from 'react';
import { Flex, Text, VStack } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { CardFieldsProps } from 'src/pages/vendor/virtual-card-details/components/CardFields/CardFields';
import { ISSUER_ADDRESS, ISSUER_ZIP_CODE } from 'src/utils/consts';

export const CardFieldsMobile = ({ exp, cvc, number, onClick }: CardFieldsProps) => (
  <Flex direction="column" gap="ds.md" w="100%">
    <Flex
      direction="column"
      align="start"
      gap="ds.sm"
      onClick={() => onClick('cardNumber', number.split(' ').join(''))}
    >
      <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
        <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.cardNumber" />
      </Text>
      <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
        {number}
      </Text>
    </Flex>
    <Flex width="100%" align="start" justify="space-between">
      <Flex direction="column" align="start" gap="ds.sm" onClick={() => onClick('expiryDate', exp)}>
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.expiryDate" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          {exp}
        </Text>
      </Flex>
      <Flex direction="column" align="start" gap="ds.sm" onClick={() => onClick('cvc', cvc)}>
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.cvc" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          {cvc}
        </Text>
      </Flex>
      <VStack gap="ds.sm">
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0" onClick={() => onClick('zipCode', ISSUER_ZIP_CODE)}>
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.zipCode" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.zipCode" />
        </Text>
      </VStack>
    </Flex>
    <Flex direction="column" align="start" gap="ds.sm" onClick={() => onClick('issuerAddress', ISSUER_ADDRESS)}>
      <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
        <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.issuerAddress" />
      </Text>
      <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
        <MIFormattedText label="vendor.unilateral.virtualCardDetails.issuerAddress" />
      </Text>
    </Flex>
    <Text textStyle="ds.body3" color="ds.gray.200">
      <MIFormattedText label="vendor.unilateral.virtualCardDetails.issuerAddressHint" />
    </Text>
  </Flex>
);
