import React from 'react';
import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';

import locations from '../locations';
import { BillLimitAmountErrorPage } from './BillLimitAmountErrorPage';

export const EntryErrorsRouter = () => (
  <Switch>
    <SmartRoute path={locations.entryErrors.billAmountLimitError} exact>
      <BillLimitAmountErrorPage />
    </SmartRoute>
  </Switch>
);
