import * as React from 'react';
import styled from 'styled-components';

type Props = {
  envName: string;
};

const EnvRibbon = ({ envName }: Props) =>
  envName !== 'production' ? (
    <RibbonWrapper>
      <Ribbon>
        <EnvName>{envName}</EnvName>
      </Ribbon>
    </RibbonWrapper>
  ) : null;

export default EnvRibbon;

const RibbonWrapper = styled.div`
  width: 15rem;
  height: 15rem;
  position: absolute;
  overflow: hidden;
  top: 0px;
  z-index: 9999;
  pointer-events: none;
  right: 0px;
`;

const Ribbon = styled.div`
  position: absolute;
  padding: 2px 0px;
  font-family: sans-serif !important;
  font-weight: bold;
  font-size: 1.2rem !important;
  z-index: 9999;
  pointer-events: auto;
  transform: rotate(45deg);
  top: 2.7rem !important;
  right: -5.8rem !important;
  background-image: none !important;
  box-shadow: none !important;
  background-color: ${(props) => props.theme.text.color.green} !important;
`;

const EnvName = styled.div`
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  text-align: center;
  width: 200px;
  line-height: 20px;
  display: inline-block;
  padding: 2px 0px;
  border-width: 1px 0px;
  border-color: rgba(255, 255, 255, 0.7);
  text-shadow: none !important;
  border-style: none !important;
  text-transform: uppercase !important;
`;
