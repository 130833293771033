import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from '@melio/billpay-design-system';
import paymentCanceledIcon from 'src/images/qbo/payment-canceled.svg';
import analytics from 'src/services/analytics';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { Footer } from 'src/pages/vendor/components/VendorFlowLayout';
import { StyledQBDTSuccessLayout } from 'src/pages/funding-sources/components/common';
import { useLocationState } from 'src/utils/hooks';
import { WizardIllustration } from 'src/billpay/qbdt/components';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';

export const PaymentCanceledPage = () => {
  const site = useSiteContext();

  const [companyName] = useLocationState('companyName');
  const [companyEmail] = useLocationState('email');

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'payment-cancelled');
  }, []);

  const supportEmail = site.config.support.email;

  return (
    <PaymentCancelledLayout
      illustration={paymentCanceledIcon}
      title="vendor.pushToDebit.paymentCanceled.title"
      text="vendor.pushToDebit.paymentCanceled.description"
      textValues={{
        companyName,
        companyEmail: <Link href={`mailto:${companyEmail}`}>{companyEmail}</Link>,
        supportEmail: <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>,
      }}
      hideHeader
      FooterComponent={<Footer />}
    />
  );
};

const PaymentCancelledLayout = styled(StyledQBDTSuccessLayout)`
  ${WizardIllustration} {
    height: 12rem;
    width: 12rem;
  }
`;
