import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import paymentsApi from 'src/services/api/payments';
import { getOrgId } from 'src/redux/user/selectors';
import errorTracker from 'src/utils/error-tracking';
import { convertPaymentFeeApiObj } from 'src/utils/payments';
import { getFundingSourceType } from 'src/utils/funding-sources';
import { hasNewFeeApi, defaultFeeObject } from 'src/utils/fee';
import { AccountType, PaymentType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { RecordOf } from 'immutable';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';
import { logger } from 'src/services/loggers';

type UsePaymentFeeParams = {
  payment: PaymentType;
  fundingSource: RecordOf<AccountType>;
};

export const usePaymentFee = ({ payment, fundingSource }: UsePaymentFeeParams) => {
  const orgId = useSelector(getOrgId);
  const [fee, setFee] = useState<PaymentFeeInfo | null>(null);

  const { deliveryType } = payment.deliveryMethod || {};

  useEffect(() => {
    getFeeHandler();
  }, [
    payment.deliveryMethodId,
    payment.organizationId,
    payment.fundingSourceId,
    payment.amount,
    payment.scheduledDate,
    payment.deliveryPreference,
  ]);

  const getFeeHandler = () => {
    if (
      hasNewFeeApi({
        deliveryType,
        fundingSourceType: getFundingSourceType(fundingSource),
      })
    )
      return getFeeApi();

    return getFee();
  };

  const getFeeApi = async () => {
    try {
      const { fees } = await paymentsApi.getPaymentFeeApi(orgId, {
        paymentId: payment.id,
        paymentOrigin: CONSTS.DATA_ORIGIN.INTUIT,
      });

      // convert current fee to component sturcture
      setFee(convertPaymentFeeApiObj(fees) as any);
    } catch (e) {
      setFee(defaultFeeObject as any);
    }
  };

  const getFee = () => {
    paymentsApi
      .getPaymentFee({
        orgId: payment.organizationId,
        fundingSourceId: payment.fundingSourceId,
        amount: payment.amount,
        scheduledDate: payment.scheduledDate,
        deliveryMethodId: payment.deliveryMethodId,
        deliveryPreference: payment.deliveryPreference,
        allowDoubleFee: true,
      })
      .then((res) => {
        setFee(res.fee);
      })
      .catch((err) => {
        logger.error('usePaymentFee.getFee(): failed get Fee', err);
        errorTracker.capture(err);
        setFee(null);
      });
  };

  return {
    fee,
  };
};
