import { putRequest } from './api';

export default {
  updateOrganizationPreference(orgId: string, key: string, value: boolean | string | null): Promise<void> {
    const url = `/orgs/${orgId}/organization-preferences`;
    return putRequest(url, { key, value });
  },

  updateServiceFeesPreference(orgId: string, value: boolean): Promise<void> {
    const url = `/orgs/${orgId}/organization-preferences/service-fees`;
    return putRequest(url, { value: String(value) });
  },
};
