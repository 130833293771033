import { CARD_TYPES, FUNDING_TYPE } from 'src/utils/consts';
import { deleteRequest, fetchRequest, postRequest } from './api';

type GetEntityByExternalIdParams = {
  orgId: string;
  externalEntityId: string;
  entityType?: 'payment' | 'bill';
};

type CreatePaymentExternalReferenceParams = {
  orgId: string;
  paymentId: string;
  externalPaymentEntityId: string;
};

export enum QBDTIntent {
  CONNECT = 'QBDTConnect',
  CONNECT_APPCENTER_REDIRECT = 'QBDTConnectAppcenterRedirect',
}
export type AuthQuery = {
  intent: QBDTIntent;
  realmId: string;
  appType: 'billpay' | 'default' | 'qbdt';
  registrationOrigin: string;
  [key: string]: string;
};
export type QBDTCheckRequest = {
  authQuery: AuthQuery;
};
export enum CheckStatus {
  KNOWN = 'known',
  UNKNOWN = 'unknown',
  MATCHED = 'matched',
  REJECTED = 'rejected',
  REAUTH = 'reauth',
}

export type GetPaymentForSyncResponse = {
  billExternalId?: string;
  paymentExternalId?: string;
  fundingSourceExternalId?: string;
  vendorExternalId?: string;
  bill: {
    id: string;
    totalAmount: number;
  };
  vendor: {
    id: string;
    companyName: string;
  };
  payment: {
    id: string;
    amount: number;
    scheduledDate: string;
  };
  fundingSource: {
    fundingType: FUNDING_TYPE;
    cardAccount?: {
      cardType: CARD_TYPES;
    };
  };
  billPaymentEntities: {
    billId: string;
    amount: number;
    billExternalId: string;
  }[];
};

type GetPaymentForSyncParams = {
  orgId: string;
  paymentId: string;
};

export type QBDTCheckResponse =
  | {
      status: CheckStatus.KNOWN | CheckStatus.UNKNOWN | CheckStatus.REAUTH;
      authUrl: string;
    }
  | {
      status: CheckStatus.MATCHED;
      orgId: string;
      companyInfoMissing: boolean;
    }
  | {
      status: CheckStatus.REJECTED;
    };
export function qbdtCheckAuthentication(params: QBDTCheckRequest): Promise<QBDTCheckResponse> {
  return fetchRequest('/qbdt/check-authorization', params.authQuery).then((res) => ({
    status: res.status,
    orgId: res.orgId,
    authUrl: res.authUrl,
    companyInfoMissing: res.companyInfoMissing,
  }));
}

export function logoutAndUnlink({ orgId }) {
  const url = `/orgs/${orgId}/qbdt/unlink`;
  return postRequest(url, {});
}

export function qbdtDeletePaymentById(orgId, id, params = {}) {
  const url = `/orgs/${orgId}/qbdt/payment/${id}`;
  return deleteRequest(url, params);
}

export function getEntityByExternalId({ orgId, externalEntityId, entityType }: GetEntityByExternalIdParams) {
  let requestParams = {};
  if (entityType) {
    requestParams = {
      entityType,
    };
  }

  return fetchRequest(`/orgs/${orgId}/qbdt/external-reference/${externalEntityId}`, requestParams);
}

export function createPaymentExternalReference({
  orgId,
  paymentId,
  externalPaymentEntityId,
}: CreatePaymentExternalReferenceParams) {
  return postRequest(`/orgs/${orgId}/qbdt/payment/${paymentId}/external-reference/${externalPaymentEntityId}`);
}

export function getInternalBillDownloadUrl(orgId: string, billId: string) {
  const url = `/orgs/${orgId}/qbdt/internal-bill/${billId}/invoice`;
  return fetchRequest(url) as Promise<{
    downloadUrl: string;
  }>;
}

export function batchDeleteBills(orgId: number, externalBillsIds: string[]) {
  return postRequest(`/orgs/${orgId}/qbdt/bills/batch-delete`, externalBillsIds);
}

export function getUnpaidBillsExternalIds(orgId: number) {
  return fetchRequest(`/orgs/${orgId}/qbdt/bills/external-unpaid`);
}

export function getPaymentForSync({ orgId, paymentId }: GetPaymentForSyncParams): Promise<GetPaymentForSyncResponse> {
  return fetchRequest(`/orgs/${orgId}/qbdt/payment/${paymentId}`).then(({ sync }) => sync);
}

export type SyncBillEntityDataItem = {
  vendor: {
    companyName: string;
    contactPhone?: string;
    contactEmail?: string;
  };
  vendorExternalId: string;
  bill: {
    balance: number;
    totalAmount: number;
    invoiceDate: string;
    dueDate: string;
    currency: string;
    files: string[];
    invoiceNumber: string;
    note: string;
  };
  billExternalId: string;
};

type SyncBillEntityParams = {
  orgId: number;
  data: SyncBillEntityDataItem;
};

type SyncBillEntitiesParams = {
  orgId: number;
  data: SyncBillEntityDataItem[];
};

export function syncBillEntity({ orgId, data }: SyncBillEntityParams) {
  return postRequest(`/orgs/${orgId}/qbdt/sync/bill`, data);
}

export function syncBillEntities({ orgId, data }: SyncBillEntitiesParams) {
  return postRequest(`/orgs/${orgId}/qbdt/sync/bills`, data);
}
