import { RecordOf } from 'immutable';
import { DeliveryMethodType, CheckType, AddressType, BankType, AccountType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import locations from 'src/utils/locations';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { CheckDeliveryMethodModelType } from './components/CheckDeliveryMethodForm';
import { CheckDeliveryMethodState } from './CheckDeliveryMethod';

export const getCheckDeliveryMethodModel = (checkDeliveryMethod?: RecordOf<DeliveryMethodType>): CheckType => ({
  printName: checkDeliveryMethod?.paperCheck?.printName || '',
  googlePlaceId: checkDeliveryMethod?.paperCheck?.googlePlaceId || CONSTS.ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID,
  addressLine1: checkDeliveryMethod?.paperCheck?.addressLine1 || '',
  addressLine2: checkDeliveryMethod?.paperCheck?.addressLine2 || '',
  addressLat: checkDeliveryMethod?.paperCheck?.addressLat || NaN,
  addressLng: checkDeliveryMethod?.paperCheck?.addressLng || NaN,
  aptNumber: checkDeliveryMethod?.paperCheck?.aptNumber || '',
  countryCode: checkDeliveryMethod?.paperCheck?.countryCode || CONSTS.COUNTRY.US,
  city: checkDeliveryMethod?.paperCheck?.city || '',
  state: checkDeliveryMethod?.paperCheck?.state || '',
  zipCode: checkDeliveryMethod?.paperCheck?.zipCode || '',
  formattedAddress: checkDeliveryMethod?.paperCheck?.formattedAddress || '',
});

export const getManualAddressFromModel = (model: CheckDeliveryMethodModelType): AddressType => ({
  addressLine1: model.addressLine1?.value,
  addressLine2: model.addressLine2?.value,
  city: model.city?.value,
  state: model.state?.value,
  zipCode: model.zipCode?.value,
  googlePlaceId: model.googlePlaceId?.value || CONSTS.ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID,
  aptNumber: model.aptNumber?.value,
  addressLat: model.addressLat?.value || NaN,
  addressLng: model.addressLng?.value || NaN,
  countryCode: model.countryCode?.value || CONSTS.COUNTRY.US,
  formattedAddress: model.formattedAddress?.value,
});

export const getStateByManualAddress = (validatedAddressObj: ManualAddressType): string => {
  if (validatedAddressObj && validatedAddressObj.is_valid) {
    if (validatedAddressObj.diff.length === 0) {
      return CheckDeliveryMethodState.NEW;
    }

    return CheckDeliveryMethodState.CONFIRM;
  } else if (validatedAddressObj && !validatedAddressObj.is_valid && validatedAddressObj.isPoBoxInvalid) {
    return CheckDeliveryMethodState.INVALID_PO_BOX;
  } else if (validatedAddressObj && !validatedAddressObj.error && !validatedAddressObj.is_valid) {
    return CheckDeliveryMethodState.INVALID_ADDRESS;
  } else if (validatedAddressObj && validatedAddressObj.error) {
    return CheckDeliveryMethodState.NEW_UNVERIFIED;
  }

  return CheckDeliveryMethodState.NEW;
};

export const getEventPageName = (ownedVendorId: string, vendorId: string) =>
  parseInt(ownedVendorId, 10) === parseInt(vendorId, 10) ? 'vendor-company-delivery-method' : 'vendor-delivery-method';

export const checkBankAccount = (
  { routingNumber, accountNumber }: BankType,
  userFundingSources: RecordOf<AccountType>[] = []
) => {
  const hasFundingSources = userFundingSources.some((accountType: RecordOf<AccountType>) => {
    const bankAccount = accountType.bankAccount || {};

    return bankAccount.routingNumber === routingNumber && bankAccount.accountNumber === accountNumber;
  });

  return hasFundingSources
    ? {
        accountNumber: 'inputErrors.deliveryMethodAch.tryToPayHimself',
      }
    : {};
};

export const getDeliveryMethodActionRedirectInfo = (vendorId, deliveryMethod) => {
  const { id, deliveryType } = deliveryMethod;
  const isEdit = !!id;
  const locationPrefix = locations.Vendors.deliveryMethods[deliveryType];

  const url = isEdit
    ? locationPrefix.edit.url({
        id: vendorId,
        deliveryMethodId: deliveryMethod.id,
      })
    : locationPrefix.create.url({ id: vendorId });

  return {
    actionUrl: url,
  };
};
