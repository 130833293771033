import React from 'react';
import styled from 'styled-components';
import { Box, VStack } from '@melio/billpay-design-system';
import { Modal, ModalSize, ModalTitle } from 'src/theme/ds';
import MIButton from 'src/components/common/MIButton';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useForm } from 'src/ui/form';
import { getOrgId } from 'src/redux/user/selectors';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getDeliveryMethodActionRedirectInfo } from 'src/pages/vendor/delivery-methods/utils';
import AreaLoader from 'src/components/common/AreaLoader';
import { DeliveryMethodType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import ValidationError from 'src/ui/ValidationError';
import { pushNotification } from 'src/services/notifications/notificationService';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useQueryState } from 'src/utils/hooks';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { EditVendorForm } from './EditVendorForm';
import VendorDeliveryMethodsList from '../../../settings/vendors/components/VendorDeliveryMethodsList';

type Props = {
  dismiss: VoidFunction;
};

const EditVendorModal = ({ dismiss }: Props) => {
  const location = useLocation();
  const [navigate] = useHistoryWithOrgId();
  const [vendorId] = useQueryState<string>('vendorId');

  const orgId = useSelector(getOrgId);
  const vendor = useSelector(vendorsStore.selectors.byId(vendorId));
  const isLoading = useSelector(vendorsStore.selectors.fetch.status(vendorId))?.loading;
  const isUpdating = useSelector(vendorsStore.selectors.update.status(vendorId))?.loading;

  const vendorActions = useStoreActions(vendorsStore);

  const showVendorDeliveryMethods = vendor && !isRppsVendor(vendor);

  const handleSaveClick = async (params) => {
    const validationErrors = getValidationErrors('vendor', params);
    if (!isValidationOk(validationErrors)) {
      throw new ValidationError({ validationErrors });
    }

    const hasChanged = checkIfVendorChanged(params);
    if (!hasChanged) {
      dismiss();

      return;
    }

    try {
      await vendorActions.update({ orgId, ...params });
      pushNotification({
        type: CONSTS.NOTIFICATION_VARIANT.SUCCESS,
        msg: 'paymentDashboard.editVendor.success',
        textValues: {
          vendorName: params.companyName,
        },
      });
      dismiss();
    } catch (e) {
      // TODO: handle error
    }
  };

  const handleEditDeliveryMethodClick = (deliveryMethod: DeliveryMethodType) => {
    const { actionUrl } = getDeliveryMethodActionRedirectInfo(vendor.id, deliveryMethod);
    const redirectUrl = `${location.pathname}${location.search}`;

    navigate({
      path: actionUrl,
      state: {
        redirectUrl,
        exitUrl: redirectUrl,
      },
    });
  };

  const checkIfVendorChanged = (newVendorValues) => {
    const nameChanged = newVendorValues.companyName !== vendor.companyName;
    const emailChanged = newVendorValues.contactEmail !== vendor.contactEmail;

    return nameChanged || emailChanged;
  };

  const [vendorMV, actions]: any[] = useForm(vendor, {
    submit: handleSaveClick,
  });

  const loading = isLoading || !vendor?.createdAt;

  return (
    <Modal id="edit-vendor-dalog" size={ModalSize.Large} onCloseClick={dismiss}>
      <VStack spacing="ds.xl" align="flex-start" color="ds.gray.100">
        <ModalTitle label="paymentDashboard.editVendor.title" />
        {loading && (
          <Box w="full" pt="20rem" pb="10rem" color="yellow" position="relative">
            <AreaLoader />
          </Box>
        )}
        {!loading && <EditVendorForm vendorMV={vendorMV} />}
      </VStack>
      {!loading && (
        <>
          {showVendorDeliveryMethods && (
            <StyledDeliveryMethodsList vendor={vendor} goEditDeliveryMethod={handleEditDeliveryMethodClick} />
          )}

          <PrimaryButton
            label="paymentDashboard.editVendor.save"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            isProcessing={isUpdating}
            onClick={actions.submit}
          />
        </>
      )}
    </Modal>
  );
};

const StyledDeliveryMethodsList = styled(VendorDeliveryMethodsList)`
  padding: 0;

  > div {
    color: var(--chakra-colors-ds-gray-200);
  }
`;

const PrimaryButton = styled(MIButton)`
  width: 10rem;
  padding: 0;
  align-self: flex-end;
`;

export { EditVendorModal };
