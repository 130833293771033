import React, { useMemo } from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

export enum PaymentInfoHintVariantsEnum {
  Default = 'default',
  Error = 'error',
}

type Props = {
  hint: string;
  hintValues?: Record<string, any>;
  testId?: string;
  variant?: PaymentInfoHintVariantsEnum;
};

export const PaymentInfoHint = ({ hint, hintValues, testId, variant = PaymentInfoHintVariantsEnum.Default }: Props) => {
  const variantStyles = useMemo(() => {
    if (variant === PaymentInfoHintVariantsEnum.Error) {
      return {
        color: 'ds.red.300',
        fontWeight: 'ds.semi',
      };
    }

    return {
      color: '#99999C',
    };
  }, [variant]);

  return (
    <Box width="full" flex="1" textStyle="ds.body3" mt="0.1rem" data-testid={testId} {...variantStyles}>
      <MIFormattedText label={hint} values={hintValues} />
    </Box>
  );
};
