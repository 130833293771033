const dashboard = '/quickbooks-desktop/orgs/:orgId/dashboard';
const entry = '/quickbooks-desktop/orgs/:orgId/dashboard/entry';

const LOCATIONS = {
  base: [entry, dashboard],
  entry,
  dashboard,
};

export default LOCATIONS;
