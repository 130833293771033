import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import financialAccountsApi from 'src/services/api/financialAccounts';
import analytics from 'src/services/analytics';
import { DB_ANALYTICS_TRAITS, SERVER_RESPONSE_CODES } from 'src/utils/consts';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { AddCardAccountSuccessParams, AddCardHolderDetailsLocationState } from '../AddCardHolderDetailsPage';

type Params = {
  eventPage: string;
  onSuccess({ id, responseCode, cardAccount }: AddCardAccountSuccessParams): void;
  onError(error: any): void;
};

export const useBasisTheoryAddCardAccount = ({ eventPage, onSuccess, onError }: Params) => {
  const orgId = useOrgId();
  const { token, cardBin, expiration, digits } = useLocation<AddCardHolderDetailsLocationState>().state;

  const addCardAccountApiCall = ({ firstName, lastName, address, city, state, zipCode }) =>
    financialAccountsApi
      .createCardAccount(
        orgId,
        {
          cardBin,
          token,
          expiration,
          card4digits: digits,
          firstName,
          lastName,
          address,
          city,
          state,
          zipCode,
        },
        { catchCall: true }
      )
      .then(({ accounts, code }) => {
        const success = code === SERVER_RESPONSE_CODES.OK;
        const cardAccount = get(accounts, '0.cardAccount', null);
        const id = get(accounts, '0.id');

        if (success) {
          const cardTypeEventLabel = cardAccount ? cardAccount.cardType.toLowerCase() : null;
          analytics.track(eventPage, 'done-adding-new-card');
          analytics.trackMqlEvent('added-funding', 'mql');

          if (cardTypeEventLabel) {
            analytics.trackMqlEvent(`added-funding-${cardTypeEventLabel}`, 'mql');
          }

          analytics.setTraits({ [DB_ANALYTICS_TRAITS.ADDED_FUNDING]: true });
          analytics.setFundingSourceTraits();
        } else {
          analytics.track(eventPage, 'adding-new-card-error', { code });
        }

        onSuccess({
          id,
          responseCode: code,
          cardAccount,
        });
      })
      .catch((e) => {
        analytics.track(eventPage, 'failed-adding-new-card');
        onError(e.code);
      });

  return { addCardAccountApiCall };
};
