import React from 'react';
import { useSelector } from 'react-redux';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { Flex, HStack, Icon, Text, VStack } from '@melio/billpay-design-system';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as SuccessIllustration } from 'src/images/general/action-done-icon.svg';
import { ReactComponent as CalendarIllustration } from 'src/images/get-pro/calendar.svg';
import { ReactComponent as MasterCardLogo } from 'src/images/icons/mastercard.svg';
import { DeliveryMethodType, PaymentType, VirtualCardType } from 'src/utils/types';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  payment?: PaymentType;
  deliveryMethod?: DeliveryMethodType;
  virtualCard?: VirtualCardType;
};

export const VirtualCardProcessedPage = ({ virtualCard, payment, deliveryMethod }: Props) => {
  useStateChangedAnalytics({ payment, virtualCard, paymentState: VirtualCardPaymentState.Processed });
  const virtualCardDeliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.originalDeliveryMethodId));
  const clearedDate = virtualCard?.authorizationDate || virtualCard?.postedDate;
  const card4digits =
    virtualCard?.accountNumber4digits ||
    (virtualCard?.cardNumber && virtualCard.cardNumber.slice(virtualCard.cardNumber.length - 4));

  const cardEmail =
    deliveryMethod?.virtualCardAccount?.accountEmail || virtualCardDeliveryMethod.virtualCardAccount?.accountEmail;

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" justify="center" align="start" gap="ds.xl" width="100%">
          <Icon as={SuccessIllustration} w="ds.2xl" h="ds.2xl" />
          <ContentTitle label="vendor.unilateral.virtualCardDetails.virtualCardProcessed.title" />
          <Text textStyle="ds.body2" mb="0" mt="ds.md" color="ds.gray.100">
            <MIFormattedText
              label="vendor.unilateral.virtualCardDetails.virtualCardProcessed.text"
              values={{
                date: <MIFormattedDate date={clearedDate} />,
              }}
            />
          </Text>
        </Flex>
        <Divider h="ds.xs" my="ds.2xl" />
        <VStack gap="ds.xl" w="100%" align="start">
          <Text color="ds.gray.200" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.unilateral.virtualCardDetails.newDeliveryMethodProcessed.receivedPayment" />
          </Text>
          <HStack gap="ds.lg">
            <Icon as={MasterCardLogo} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.text" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedText
                  label="vendor.unilateral.virtualCardDetails.virtualCardProcessed.cardData"
                  values={{
                    digits: card4digits,
                    email: cardEmail,
                  }}
                />
              </Text>
            </Flex>
          </HStack>
          <HStack gap="ds.lg">
            <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardDetails.virtualCardProcessed.processedOn" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedDate date={clearedDate} />
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
