import { EventMappingsTree } from 'src/services/analytics/types';
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';

export const BATCH_PAGE_EVENT = 'pay-bills-batch-payments';

const basicProperties = {
  batchBillIds: 'batchBillIds',
  batchBillCount: 'batchBillCount',
  batchBillTotalAmount: 'batchBillTotalAmount',
};

export const allProperties = {
  ...basicProperties,
  fundingSourceId: 'fundingSourceId',
  isAllAtOnceOption: 'isAllAtOnceOption',
  deductionDate: 'deductionDate',
};

const MAPPING: EventMappingsTree = {
  '/quickbooks-desktop/orgs/*/bills/pay/batch': {
    'page.view': [BATCH_PAGE_EVENT, 'batch-payments-table-page-load'],
    'regularBatchPayments.cancel': [BATCH_PAGE_EVENT, 'cancel-click'],
    'regularBatchPayments.back': [BATCH_PAGE_EVENT, 'back-click'],
    'regularBatchPayments.walkthrough.combine-bills.shown': [BATCH_PAGE_EVENT, 'walkthough-bulk_shown', {}],
    'regularBatchPayments.walkthrough.combine-bills.closed': [BATCH_PAGE_EVENT, 'walkthough-bulk_closed', {}],
    'regularBatchPayments.walkthrough.combine-bills.next': [BATCH_PAGE_EVENT, 'walkthough-bulk_next', {}],
    'regularBatchPayments.walkthrough.select-payment-method.shown': [BATCH_PAGE_EVENT, 'walkthough-add-pm_shown', {}],
    'regularBatchPayments.walkthrough.select-payment-method.closed': [BATCH_PAGE_EVENT, 'walkthough-add-pm_closed', {}],
    'regularBatchPayments.walkthrough.select-payment-method.next': [BATCH_PAGE_EVENT, 'walkthough-add-pm_next', {}],
    'regularBatchPayments.walkthrough.select-payment-method.back': [BATCH_PAGE_EVENT, 'walkthough-add-pm_back', {}],
    'regularBatchPayments.walkthrough.set-deduction-date.shown': [
      BATCH_PAGE_EVENT,
      'walkthough-deduction-date_shown',
      {},
    ],
    'regularBatchPayments.walkthrough.set-deduction-date.closed': [
      BATCH_PAGE_EVENT,
      'walkthough-deduction-date_closed',
      {},
    ],
    'regularBatchPayments.walkthrough.set-deduction-date.next': [
      BATCH_PAGE_EVENT,
      'walkthough-deduction-date_next',
      {},
    ],
    'regularBatchPayments.walkthrough.set-deduction-date.back': [
      BATCH_PAGE_EVENT,
      'walkthough-deduction-date_back',
      {},
    ],
    'some-bills-already-scheduled-card-shown': [BATCH_PAGE_EVENT, 'some-bills-already-scheduled-card-shown'],
    'regularBatchPayments.removeBillId': [
      BATCH_PAGE_EVENT,
      'remove-bill-from-batch',
      {
        billIds: 'billIds',
        vendorId: 'vendorId',
        isBulk: 'isBulk',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'regularBatchPayments.toggleBulk': [
      BATCH_PAGE_EVENT,
      'bulk-toggle-clicked',
      {
        newToggleState: 'newToggleState',
      },
    ],
    'regularBatchPayments.settings.paymentMethod.choose': [BATCH_PAGE_EVENT, 'choose-global-payment-method', {}],
    'regularBatchPayments.settings.paymentMethod.selected': [
      BATCH_PAGE_EVENT,
      'global-payment-method-selected',
      {
        fundingSourceId: 'fundingSourceId',
        fundingSourceType: 'fundingSourceType',
      },
    ],
    'regularBatchPayments.settings.paymentMethod.addNew': [BATCH_PAGE_EVENT, 'add-new-global-payment-method', {}],
    'regularBatchPayments.settings.deductionDateType.choose': [
      BATCH_PAGE_EVENT,
      'global-deduction-date-type-choose',
      {},
    ],
    'regularBatchPayments.settings.deductionDateType.selected': [
      BATCH_PAGE_EVENT,
      'update-deduction-date-type',
      {
        deductionDateType: 'deductionDateType',
      },
    ],
    'regularBatchPayments.settings.deductionDate.choose': [BATCH_PAGE_EVENT, 'global-deduction-date-choose', {}],
    'regularBatchPayments.settings.deductionDate.selected': [BATCH_PAGE_EVENT, 'update-deduction-date', {}],
    'bulk-grouping-click': [
      BATCH_PAGE_EVENT,
      'bulk-grouping-click',
      {
        groupingState: 'groupingState',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'regularBatchPayments.createPayments.fastAchExposure': [BATCH_PAGE_EVENT, 'fast-ach-exposure', basicProperties],
    'regularBatchPayments.createPayments.expressCheckExposure': [
      BATCH_PAGE_EVENT,
      'express-check-exposure',
      basicProperties,
    ],
    'regularBatchPayments.loadBills': [
      BATCH_PAGE_EVENT,
      'load_batch_payments_table',
      {
        ...basicProperties,
        noDeliveryMethodBillIds: 'noDeliveryMethodBillIds',
        noDeliveryMethodBillCount: 'noDeliveryMethodBillCount',
        vendorsCount: 'vendorsCount',
        orderBy: 'orderBy',
        orderDirection: 'orderDirection',
        bulkPaymentEligible: 'bulkPaymentEligible',
      },
    ],
    'regularBatchPayments.createPayments': [
      BATCH_PAGE_EVENT,
      'schedule-payments',
      {
        ...allProperties,
        failedBillIds: 'failedBillIds',
        failedBillCount: 'failedBillCount',
        scheduledBillIds: 'scheduledBillIds',
        scheduledBillCount: 'scheduledBillCount',
        FastACHBillIds: 'FastACHBillIds',
        FastACHBillCount: 'FastACHBillCount',
        ExpressCheckBillsIds: 'ExpressCheckBillsIds',
        ExpressCheckBillsCount: 'ExpressCheckBillsCount',
      },
    ],
    'regularBatchPayments.cells.actions.viewBillDetails': [
      BATCH_PAGE_EVENT,
      'view-bill-details-click',
      {
        billId: 'billId',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'regularBatchPayments.cells.actions.addMemoToVendor': [
      BATCH_PAGE_EVENT,
      'add-memo-to-vendor-click',
      {
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'regularBatchPayments.addMemo.button': [
      BATCH_PAGE_EVENT,
      'save-memo-to-vendor-click',
      {
        billIds: 'billIds',
        vendorId: 'vendorId',
      },
    ],
    'open-select-funding-source': [
      BATCH_PAGE_EVENT,
      'open-select-funding-source',
      {
        fromTable: 'fromTable',
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'add-payment-method-click': [
      BATCH_PAGE_EVENT,
      'add-payment-method-click',
      {
        fromTable: 'fromTable',
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'change-payment-method': [
      BATCH_PAGE_EVENT,
      'change-payment-method',
      {
        fromTable: 'fromTable',
        billIds: 'billIds',
        vendorId: 'vendorId',
        fundingSourceType: 'fundingSourceType',
        fundingSourceId: 'fundingSourceId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'open-scheduled-date-calendar-from-table': [
      BATCH_PAGE_EVENT,
      'open-scheduled-date-calendar-from-table',
      {
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'open-select-delivery-method': [
      BATCH_PAGE_EVENT,
      'open-select-delivery-method',
      {
        fromTable: 'fromTable',
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'select-date': [
      BATCH_PAGE_EVENT,
      'select-date',
      {
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'toggle-fast-option': [
      BATCH_PAGE_EVENT,
      'toggle-fast-option',
      {
        type: 'type',
        toggle: 'toggle',
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    'sort-bills': [
      BATCH_PAGE_EVENT,
      'sort-bills',
      {
        orderBy: 'orderBy',
        orderDirection: 'orderDirection',
      },
    ],
    'batchPayment.buttons.cancel': [BATCH_PAGE_EVENT, 'cancel-click'],
    ChangeDeliveryMethod: [
      BATCH_PAGE_EVENT,
      'select-delivery-method',
      {
        fromTable: 'fromTable',
        billIds: 'billIds',
        vendorId: 'vendorId',
        deliveryMethodId: 'deliveryMethodId',
        deliveryMethodType: 'deliveryMethodType',
        virtual: 'virtual',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    AddDeliveryMethodClick: [
      BATCH_PAGE_EVENT,
      'add-select-delivery-method',
      {
        fromTable: 'fromTable',
        billIds: 'billIds',
        vendorId: 'vendorId',
        payBillFlowUuid: 'payBillFlowUuid',
      },
    ],
    FailedBillsListModal: [
      BATCH_PAGE_EVENT,
      'some-failed-create-payments',
      {
        toggle: 'toggle',
      },
    ],
    AllFailedBillsListModal: [
      BATCH_PAGE_EVENT,
      'all-failed-create-payments',
      {
        toggle: 'toggle',
      },
    ],
    ...mapApiSlice('[REGULAR BATCH PAYMENTS] CREATE', BATCH_PAGE_EVENT, 'schedule-batch-payments', {
      request: {
        payBillFlowUuids: 'payload.payBillFlowUuids',
      },
      success: {
        finalToggleState: 'meta.identifier.finalToggleState',
        bulkPaymentEligible: 'meta.identifier.bulkPaymentEligible',
        payBillFlowUuids: 'meta.identifier.payBillFlowUuids',
      },
      failure: {
        payments: 'meta.identifier.payments',
        paymentsCount: 'meta.identifier.payments.length',
        error: 'error',
        payBillFlowUuids: 'meta.identifier.payBillFlowUuids',
      },
      validationError: {
        validation: 'error.validationErrors.validationFailReasons',
      },
    }),
  },
  '/quickbooks-desktop/orgs/*/bills/pay/batch/success': {
    'page.view': [BATCH_PAGE_EVENT, 'success-page-load'],
    'sync-failed-notification-shown': [BATCH_PAGE_EVENT, 'sync-failed-notification-shown'],
  },
  '/quickbooks-desktop/orgs/*/bills/pay/batch/bills-already-scheduled': {
    'page.view': [BATCH_PAGE_EVENT, 'bills-already-scheduled-page-load'],
  },
  '/quickbooks-desktop/orgs/*/bills/pay/batch/complete-legal-info': {
    ...mapApiSlice('[USER] SET_COMPANY_INFO', 'complete-legal-info', 'continue'),
    ...mapApiSlice('[PAYMENTS] SEND_BATCH_PAYMENTS', BATCH_PAGE_EVENT, 'schedule-batch-payments', {
      failure: {
        paid: 'payload.paid.length',
        payments: 'meta.identifier.payments.length',
      },
    }),
  },
};

export default MAPPING;
