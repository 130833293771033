import React from 'react';
import { MethodsListWrapper } from '../MethodsListWrapper/MethodsListWrapper';
import { FundingSourceItem } from './FundingSourceItem';

export const FundingSourcesList = () => (
  <MethodsListWrapper listWidth="9.8rem" description="entry.batch.landingPage.fundingSourcesDescription">
    <FundingSourceItem />
    <FundingSourceItem />
    <FundingSourceItem />
  </MethodsListWrapper>
);
