import { RecordOf } from 'immutable';
import React from 'react';
import styled from 'styled-components';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { BILL_STATUS, DELIVERY_TYPE, PAYMENT_APPROVAL_STATUS } from 'src/utils/consts';
import { PaymentInfoHint } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoHint';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import { getBillPaidColor } from '../../utils/getBillPaidColor';

type Props = {
  payment: PaymentType;
  bill: BillType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

export const ViewPaymentDeductionDateInfo = ({ payment, bill, deliveryMethod }: Props) => {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const deductionDateLabel = isBillPaid
    ? 'viewBillPaymentActivity.deductionDate.paidLabel'
    : 'viewBillPaymentActivity.deductionDate.unpaidLabel';

  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isUnitaleral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const hint = isApprovalRequired && !isUnitaleral ? 'viewBillPaymentActivity.deductionDate.approvalRequiredHint' : '';

  return (
    <PaymentFieldContainer>
      <PaymentFieldIcon color={getBillPaidColor(isBillPaid)}>
        <ScheduledIcon />
      </PaymentFieldIcon>
      <PaymentFieldInfo>
        <PaymentFieldLabel>
          <MIFormattedText label={deductionDateLabel} />
        </PaymentFieldLabel>
        <PaymentFieldContent color={getBillPaidColor(isBillPaid)}>
          <MIFormattedDate date={payment.scheduledDate} />
          {!isBillPaid && hint && <PaymentInfoHint hint={hint} />}
        </PaymentFieldContent>
      </PaymentFieldInfo>
    </PaymentFieldContainer>
  );
};

const ScheduledIcon = styled.i.attrs({ className: 'icon-scheduled-icon' })``;
