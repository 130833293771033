import { Icon } from '@melio/billpay-design-system';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { isVendorRequest } from 'src/utils/bills';
import {
  isPaymentCompleted,
  isPaymentFailed,
  isPaymentScheduled,
  isVirtualCardPaymentExpired,
} from 'src/utils/payments';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';
import { PaymentStatusIcon } from './components/PaymentStatusIcon';
import { PaymentStatusVariantsEnum, PaymentStatusView } from './components/PaymentStatusView';
import { ReactComponent as ErrorIcon } from './icons/error-outline.svg';
import { ReactComponent as ScheduledIcon } from './icons/payment-scheduled.svg';

export const PaymentStatus = () => {
  const { bill, payment } = useViewPaymentContext();
  const isVendorRequestBill = isVendorRequest(bill);
  const noDeliveryMethods = isEmpty(bill.vendor.deliveryMethods);
  const isVirtualCardExpired = isVirtualCardPaymentExpired(payment);

  if (isPaymentScheduled(payment)) {
    return (
      <PaymentStatusView
        label="bills.status.paymentScheduled"
        renderIcon={(color) => <Icon as={ScheduledIcon} h="ds.xl" w="ds.xl" color={color} />}
        variant={PaymentStatusVariantsEnum.Pending}
      />
    );
  }

  if (isPaymentFailed(payment) && isVendorRequestBill && noDeliveryMethods) {
    return (
      <PaymentStatusView
        label="bills.status.cantSchedulePayment.title"
        renderIcon={(color) => <PaymentStatusIcon icon="icon-notification-icon" color={color} />}
        variant={PaymentStatusVariantsEnum.Failure}
        description="bills.status.cantSchedulePayment.description"
        descriptionValues={{ vendorName: bill.vendor.companyName }}
      />
    );
  }

  if (isPaymentFailed(payment)) {
    const failedLabel = isVirtualCardExpired ? 'bills.status.virtualCardExpired' : 'bills.status.paymentFailed';
    return (
      <PaymentStatusView
        label={failedLabel}
        renderIcon={(color) => <Icon as={ErrorIcon} h="ds.xl" w="ds.xl" color={color} />}
        variant={PaymentStatusVariantsEnum.Failure}
      />
    );
  }

  if (isPaymentCompleted(payment)) {
    return (
      <PaymentStatusView
        label="bills.status.paymentPaid"
        renderIcon={(color) => <PaymentStatusIcon icon="icon-full-checkmark-icon" color={color} />}
        variant={PaymentStatusVariantsEnum.Success}
      />
    );
  }

  return null;
};
