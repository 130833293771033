import { CONSTS } from 'src/utils/consts';

type GetEstimatedDeliverySpeedLabelProps = {
  paymentAmount: number;
  deliveryType: string;
  fundingSourceType?: string;
};

export const getEstimatedDeliverySpeedLabel = ({
  deliveryType,
  fundingSourceType,
  paymentAmount,
}: GetEstimatedDeliverySpeedLabelProps) => {
  if (deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
    if (fundingSourceType === CONSTS.FUNDING_TYPE.ACH) {
      return paymentAmount >= CONSTS.RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD
        ? { label: 'bills.pay.date.deliveryOptions.subTitle.achOver100K' }
        : { label: 'bills.pay.date.deliveryOptions.subTitle.achBelow100K' };
    }

    if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT) {
      return { label: 'bills.pay.date.deliveryOptions.subTitle.achDebit' };
    }

    if (fundingSourceType === CONSTS.CARD_TYPES.CREDIT) {
      return { label: 'bills.pay.date.deliveryOptions.subTitle.achCredit' };
    }
  }

  if (deliveryType === CONSTS.DELIVERY_TYPE.RPPS) {
    if (fundingSourceType === CONSTS.FUNDING_TYPE.ACH) {
      return { label: 'bills.pay.date.deliveryOptions.subTitle.rppsAch' };
    } else if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT || fundingSourceType === CONSTS.CARD_TYPES.CREDIT) {
      return { label: 'bills.pay.date.deliveryOptions.subTitle.rppsCreditOrDebit' };
    }
  }

  if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
    if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT || fundingSourceType === CONSTS.CARD_TYPES.CREDIT) {
      return { label: 'bills.pay.date.deliveryOptions.subTitle.virtualCardCreditOrDebit' };
    }

    return { label: 'bills.pay.date.deliveryOptions.subTitle.virtualCardACH' };
  }

  return { label: `bills.pay.date.deliveryOptions.subTitle.${deliveryType}` };
};
