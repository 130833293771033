import { compose, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { IntlConfig } from 'react-intl';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { createTranslationService } from '../services/translation-service';
import DEFAULT_VALUES from '../locale/default-values';
import { flattenMessages } from '../locale';
import { asyncPromiseMiddleware } from '../helpers/redux/asyncPromiseMiddleware';
import { reduxAnalyticsMiddleware } from '../services/analytics/reduxAnalyticsMiddleware';
import { notificationMiddleware } from '../services/notifications/notificationMiddleware';

export function setupStore(site, history) {
  const locale = 'en-US';

  const intl = createTranslationService(
    {
      locale,
      messages: flattenMessages(site.messages[locale]),
    } as IntlConfig,
    DEFAULT_VALUES
  );

  const sagaMiddleware = createSagaMiddleware({
    context: { intl, site },
  });

  const initialState = {};

  const composeEnhancers =
    process.env.REACT_APP_ENV !== 'production'
      ? // eslint-disable-next-line no-underscore-dangle
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        reduxAnalyticsMiddleware,
        notificationMiddleware(intl),
        asyncPromiseMiddleware
      )
    )
  );

  sagaMiddleware.run(rootSaga);
  return {
    store,
    persistor: persistStore(store),
    intl,
  };
}
