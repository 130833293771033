import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { BusinessType } from './types';

const businessTypeOptions = [
  {
    label: 'companyInfo.businessTypes.partnership',
    value: BusinessType.Partnership,
  },
  {
    label: 'companyInfo.businessTypes.soleProprietorship',
    value: BusinessType.SoleProprietorship,
  },
  {
    label: 'companyInfo.businessTypes.LLC',
    value: BusinessType.LimitedLiabilityCompany,
  },
  {
    label: 'companyInfo.businessTypes.corporation',
    value: BusinessType.Corporation,
  },
  {
    label: 'companyInfo.businessTypes.trust',
    value: BusinessType.Trust,
  },
  {
    label: 'companyInfo.businessTypes.nonProfitCorporation',
    value: BusinessType.NonProfit,
  },
  {
    label: 'companyInfo.businessTypes.municipal',
    value: BusinessType.Municipal,
  },
  {
    label: 'companyInfo.businessTypes.municipality',
    value: BusinessType.Municipality,
  },
  {
    label: 'companyInfo.businessTypes.nonGovernmentalOrganization',
    value: BusinessType.NonGovernmentalOrganization,
  },
];

export const useBusinessTypeOptions = () => {
  const intl = useIntl();

  const businessTypes = useMemo(
    () =>
      businessTypeOptions.map(({ label, value }) => ({
        value,
        label: intl.formatMessage({ id: label }),
      })),
    []
  );

  return {
    businessTypeOptions: businessTypes,
  };
};
