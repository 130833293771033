import { escapeXMLString } from 'src/billpay/qbdt/services/qbdt/util';
import { mapQBDTAccount } from '../typeMapper';
import { QBDTAccount, QBDTAccountType } from '../types';
import { executeSDKRequest, getSingleEntity, QBDTSDKError } from '../sdk';

export const ACCOUNT_BANK_DEFAULT_NAME = 'Default Bank Account';
export const ACCOUNT_CREDIT_DEFAULT_NAME = 'Default Credit Card Account';
export const ACCOUNT_FEES_DEFAULT_NAME = 'Melio Fees';
export const ACCOUNT_CREATED_DEFAULT_DESC = 'Created by Melio';
export const MELIO_FEES_ACCOUNT_NUMBER = '6666546';

/**
 * Get account by ID
 * @param accountId [string] The account ID to fetch
 */
export function getAccount(accountId: string) {
  return getSingleEntity(`<AccountQueryRq><ListID>${accountId}</ListID></AccountQueryRq>`, mapQBDTAccount);
}

export async function getAccounts(...types: QBDTAccountType[]): Promise<QBDTAccount[]> {
  const query: string[] = [];
  if (types) {
    query.push(...types.map((type) => `<AccountType>${type}</AccountType>`));
  }

  try {
    const response = await executeSDKRequest(`<AccountQueryRq>${query.join('')}</AccountQueryRq>`);
    const responseAccounts: Element[] = Array.from(response.querySelectorAll('AccountRet'));
    return responseAccounts.map((doc) => mapQBDTAccount(doc));
  } catch (error: any) {
    if (error instanceof QBDTSDKError && error.code === '1') {
      return [];
    }

    throw error;
  }
}

export async function getBankAccount(preferredAccountId) {
  const accounts = await getAccounts(QBDTAccountType.Bank);

  const preferred = accounts.find((account) => account.ListID === preferredAccountId);
  if (preferred) {
    return preferred;
  }

  if (accounts.length > 0) {
    return accounts[0];
  }

  return createDefaultBankAccount();
}

function getDefaultAccountNumber(displayName: string) {
  const digits = displayName.replace(/[^\d]/g, '');
  return `111${digits.substr(-4)}`;
}
export async function createAccount(
  name: string,
  type: QBDTAccountType,
  desc = ACCOUNT_CREATED_DEFAULT_DESC,
  accountNumber?: string
) {
  const result = await executeSDKRequest(`
    <AccountAddRq>
      <AccountAdd>
        <Name>${escapeXMLString(name)}</Name>
        <AccountType>${type}</AccountType>
        <AccountNumber>${accountNumber || getDefaultAccountNumber(name)}</AccountNumber>
        <Desc>${escapeXMLString(desc)}</Desc>
      </AccountAdd>
    </AccountAddRq>
  `);

  return mapQBDTAccount(result.querySelector('AccountRet'));
}

export function createDefaultBankAccount() {
  return createAccount(ACCOUNT_BANK_DEFAULT_NAME, QBDTAccountType.Bank);
}

export function createDefaultCreditCardAccount() {
  return createAccount(ACCOUNT_CREDIT_DEFAULT_NAME, QBDTAccountType.CreditCard);
}

export async function getCreditCardAccount(preferredAccountId) {
  const accounts = await getAccounts(QBDTAccountType.CreditCard);

  const preferred = accounts.find((account) => account.ListID === preferredAccountId);
  if (preferred) {
    return preferred;
  }

  if (accounts.length > 0) {
    return accounts[0];
  }

  return createDefaultCreditCardAccount();
}

export async function getAPAccount() {
  const accounts = await getAccounts(QBDTAccountType.AccountsPayable);

  return accounts[0];
}

export async function getFeeExpenseAccount() {
  const expenseAccounts = await getAccounts(QBDTAccountType.Expense);

  const account = expenseAccounts.find(
    (a) => a.Name === ACCOUNT_FEES_DEFAULT_NAME || a.AccountNumber === MELIO_FEES_ACCOUNT_NUMBER
  );

  return (
    account ||
    createAccount(
      ACCOUNT_FEES_DEFAULT_NAME,
      QBDTAccountType.Expense,
      ACCOUNT_CREATED_DEFAULT_DESC,
      MELIO_FEES_ACCOUNT_NUMBER
    )
  );
}
