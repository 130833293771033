export enum QBDTSDKSeverity {
  INFO = 'Info',
  WARNING = 'Warn',
  ERROR = 'Error',
}

export enum QBDTAccountType {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  Bank = 'Bank',
  CostOfGoodsSold = 'CostOfGoodsSold',
  CreditCard = 'CreditCard',
  Equity = 'Equity',
  Expense = 'Expense',
  FixedAsset = 'FixedAsset',
  Income = 'Income',
  LongTermLiability = 'LongTermLiability',
  NonPosting = 'NonPosting',
  OtherAsset = 'OtherAsset',
  OtherCurrentAsset = 'OtherCurrentAsset',
  OtherCurrentLiability = 'OtherCurrentLiability',
  OtherExpense = 'OtherExpense',
  OtherIncome = 'OtherIncome',
}

export enum QBDTSpecialAccountType {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  CondenseItemAdjustmentExpenses = 'CondenseItemAdjustmentExpenses',
  CostOfGoodsSold = 'CostOfGoodsSold',
  DirectDepositLiabilities = 'DirectDepositLiabilities',
  Estimates = 'Estimates',
  ExchangeGainLoss = 'ExchangeGainLoss',
  InventoryAssets = 'InventoryAssets',
  ItemReceiptAccount = 'ItemReceiptAccount',
  OpeningBalanceEquity = 'OpeningBalanceEquity',
  PayrollExpenses = 'PayrollExpenses',
  PayrollLiabilities = 'PayrollLiabilities',
  PettyCash = 'PettyCash',
  PurchaseOrders = 'PurchaseOrders',
  ReconciliationDifferences = 'ReconciliationDifferences',
  RetainedEarnings = 'RetainedEarnings',
  SalesOrders = 'SalesOrders',
  SalesTaxPayable = 'SalesTaxPayable',
  UncategorizedExpenses = 'UncategorizedExpenses',
  UncategorizedIncome = 'UncategorizedIncome',
  UndepositedFunds = 'UndepositedFunds',
}

export enum QBDTCashFlowSpecification {
  None = 'None',
  Operating = 'Operating',
  Investing = 'Investing',
  Financing = 'Financing',
  NotApplicable = 'NotApplicable',
}

export enum QBDTTxnType {
  ARRefundCreditCard = 'ARRefundCreditCard',
  Bill = 'Bill',
  BillPaymentCheck = 'BillPaymentCheck',
  BillPaymentCreditCard = 'BillPaymentCreditCard',
  BuildAssembly = 'BuildAssembly',
  Charge = 'Charge',
  Check = 'Check',
  CreditCardCharge = 'CreditCardCharge',
  CreditCardCredit = 'CreditCardCredit',
  CreditMemo = 'CreditMemo',
  Deposit = 'Deposit',
  Estimate = 'Estimate',
  InventoryAdjustment = 'InventoryAdjustment',
  Invoice = 'Invoice',
  ItemReceipt = 'ItemReceipt',
  JournalEntry = 'JournalEntry',
  LiabilityAdjustment = 'LiabilityAdjustment',
  Paycheck = 'Paycheck',
  PayrollLiabilityCheck = 'PayrollLiabilityCheck',
  PurchaseOrder = 'PurchaseOrder',
  ReceivePayment = 'ReceivePayment',
  SalesOrder = 'SalesOrder',
  SalesReceipt = 'SalesReceipt',
  SalesTaxPaymentCheck = 'SalesTaxPaymentCheck',
  Transfer = 'Transfer',
  VendorCredit = 'VendorCredit',
  YTDAdjustment = 'YTDAdjustment',
}

export enum QBDTLinkType {
  AMTTYPE = 'AMTTYPE',
  QUANTYPE = 'QUANTYPE',
}

export enum QBDTDataExtType {
  AMTTYPE = 'AMTTYPE',
  DATETIMETYPE = 'DATETIMETYPE',
  INTTYPE = 'INTTYPE',
  PERCENTTYPE = 'PERCENTTYPE',
  PRICETYPE = 'PRICETYPE',
  QUANTYPE = 'QUANTYPE',
  STR1024TYPE = 'STR1024TYPE',
  STR255TYPE = 'STR255TYPE',
}

export enum QBDTDataExtAssignToObject {
  Account = 'Account',
  ARRefundCreditCard = 'ARRefundCreditCard',
  Bill = 'Bill',
  BillPaymentCheck = 'BillPaymentCheck',
  BillPaymentCreditCard = 'BillPaymentCreditCard',
  BuildAssembly = 'BuildAssembly',
  Charge = 'Charge',
  Check = 'Check',
  Company = 'Company',
  CreditCardCharge = 'CreditCardCharge',
  CreditCardCredit = 'CreditCardCredit',
  CreditMemo = 'CreditMemo',
  Customer = 'Customer',
  Deposit = 'Deposit',
  Employee = 'Employee',
  Estimate = 'Estimate',
  InventoryAdjustment = 'InventoryAdjustment',
  Invoice = 'Invoice',
  Item = 'Item',
  ItemReceipt = 'ItemReceipt',
  JournalEntry = 'JournalEntry',
  OtherName = 'OtherName',
  PurchaseOrder = 'PurchaseOrder',
  ReceivePayment = 'ReceivePayment',
  SalesOrder = 'SalesOrder',
  SalesReceipt = 'SalesReceipt',
  SalesTaxPaymentCheck = 'SalesTaxPaymentCheck',
  Vendor = 'Vendor',
  VendorCredit = 'VendorCredit',
}

export enum QBDTTxnDelType {
  ARRefundCreditCard = 'ARRefundCreditCard',
  Bill = 'Bill',
  BillPaymentCheck = 'BillPaymentCheck',
  BillPaymentCreditCard = 'BillPaymentCreditCard',
  BuildAssembly = 'BuildAssembly',
  Charge = 'Charge',
  Check = 'Check',
  CreditCardCharge = 'CreditCardCharge',
  CreditCardCredit = 'CreditCardCredit',
  CreditMemo = 'CreditMemo',
  Deposit = 'Deposit',
  Estimate = 'Estimate',
  InventoryAdjustment = 'InventoryAdjustment',
  Invoice = 'Invoice',
  ItemReceipt = 'ItemReceipt',
  JournalEntry = 'JournalEntry',
  PurchaseOrder = 'PurchaseOrder',
  ReceivePayment = 'ReceivePayment',
  SalesOrder = 'SalesOrder',
  SalesReceipt = 'SalesReceipt',
  SalesTaxPaymentCheck = 'SalesTaxPaymentCheck',
  TimeTracking = 'TimeTracking',
  TransferInventory = 'TransferInventory',
  VehicleMileage = 'VehicleMileage',
  VendorCredit = 'VendorCredit',
}

export type QBDTLinkedTxn = {
  TxnID: string;
  TxnType: QBDTTxnType;
  TxnDate: string;
  LinkType: QBDTLinkType;
  Amount: number;
};

export type QBDTExpenseLineRet = {
  TxnLineID: string;
  AccountRef: QBDTAccountRef;
  Amount: number;
};

export type QBDTVendorRef = {
  ListID?: string;
  FullName?: string;
};

export type QBDTAccountRef = {
  ListID?: string;
  FullName?: string;
};

export type QBDTBill = {
  TxnID: string;
  TimeCreated: Date;
  TimeModified: Date;
  EditSequence: string;
  TxnNumber: string;
  TxnDate: string;
  DueDate: string;
  AmountDue: number;
  IsPaid: boolean;
  OpenAmount: number;
  VendorRef?: QBDTVendorRef;
  APAccountRef?: QBDTAccountRef;
  MelioId?: string;
  LinkedTxn: QBDTLinkedTxn[];
  ExpenseLineRet?: QBDTExpenseLineRet[];
  RefNumber: string;
  Memo: string;
};

export type QBDTPreferences = {
  ClosingDate: string | undefined;
};

export type QBDTVendor = {
  ListID: string;
  TimeCreated: Date;
  TimeModified: Date;
  EditSequence: string;
  Name: string;
  IsActive: boolean;
  IsVendorEligibleFor1099: boolean;
  Balance: number;
  Phone: string;
  Email: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Address: QBDTAddress;
  NameOnCheck: string;
  CompanyName: string;
};

export type QBDTAccount = {
  ListID: string;
  TimeCreated: Date;
  TimeModified: Date;
  EditSequence: string;
  Name: string;
  FullName: string;
  IsActive: boolean;
  Sublevel: number;
  AccountType: QBDTAccountType;
  SpecialAccountType: QBDTSpecialAccountType;
  AccountNumber: string;
  Desc: string;
  Balance: number;
  TotalBalance: number;
  CashFlowClassification: QBDTCashFlowSpecification;
};

export type QBDTBillPaymentCheck = {
  TxnType: QBDTTxnType.BillPaymentCheck;
  TxnID: string;
  TimeCreated: Date;
  TimeModified: Date;
  EditSequence: string;
  TxnNumber: number;
  PayeeEntityRef?: QBDTVendorRef;
  APAccountRef?: QBDTAccountRef;
  TxnDate?: string;
  BankAccountRef?: QBDTAccountRef;
  Amount: number;
  IsToBePrinted: boolean;
  LinkedTxn: QBDTLinkedTxn[];
};

export type QBDTBillPaymentCreditCard = {
  TxnType: QBDTTxnType.BillPaymentCreditCard;
  TxnID: string;
  TimeCreated: Date;
  TimeModified: Date;
  EditSequence: string;
  TxnNumber: number;
  PayeeEntityRef: QBDTVendorRef;
  APAccountRef: QBDTAccountRef;
  TxnDate: string;
  CreditCardAccountRef: QBDTAccountRef;
  Amount: number;
  LinkedTxn: QBDTLinkedTxn[];
};

export type QBDTDataExtDef = {
  OwnerID: string;
  DataExtID: number;
  DataExtName: string;
  DataExtType: QBDTDataExtType;
  AssignToObject: QBDTDataExtAssignToObject;
  DataExtListRequire: boolean;
  DataExtTxnRequire: boolean;
  DataExtFormatString: string;
};

export type QBDTAddress = {
  Addr1: string;
  Addr2: string;
  City: string;
  State: string;
  PostalCode: string;
  Country: string;
};
export type QBDTCompany = {
  CompanyName: string;
  LegalCompanyName: string;
  Address: QBDTAddress;
  LegalAddress: QBDTAddress;
  Phone: string;
  Email: string;
  EIN: string;
  SSN: string;
};

export enum BillingFeeTypeEnum {
  AchToCheck = 'ach_to_check',
}

export type RetryOptions = {
  retries?: number;
  retrySleep?: number;
};

export type BillsData = {
  qbdtBill: QBDTBill;
  melioBillId: string;
  amount: number;
}[];
