import React, { ReactNode } from 'react';
import { Flex, Icon } from '@melio/billpay-design-system';
import { ReactComponent as CloseButton } from 'src/images/general/close-icon.svg';
import { close } from 'src/billpay/qbdt/services/qbdt';

type Props = {
  children: ReactNode;
};

export const LandingPageOverlay = ({ children }: Props) => {
  const closeButton = (
    <Flex
      position="absolute"
      right="1rem"
      top="1rem"
      w="4"
      h="4"
      justify="center"
      align="center"
      cursor="pointer"
      onClick={close}
    >
      <Icon as={CloseButton} color="ds.gray.200" />
    </Flex>
  );

  return (
    <Flex bgColor="ds.gray.800" h="full" justify="center" align="center" position="relative">
      {closeButton}
      {children}
    </Flex>
  );
};
