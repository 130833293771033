import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, VStack } from '@melio/billpay-design-system';
import useFeesParams from 'src/billpay/qbdt/pages/batch-pay/components/fees/hooks/useFeesParams';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { MIFormattedText } from 'src/utils/formatting';
import { TotalAmount } from './TotalAmount';
import { TotalFees } from './TotalFees';

const Title = () => {
  const totalAmount = useSelector(regularBatchPaymentsStore.selectors.totalAmount);
  const { feesData } = useFeesParams();

  return useMemo(
    () => (
      <Flex w="full" direction="row" justify="space-between">
        <Box color="ds.gray.100" textStyle="ds.h5">
          <MIFormattedText label="regularBatchPayments.schedulePayments" />
        </Box>

        <VStack spacing="ds.sm" alignItems="flex-end">
          <Box textStyle="ds.body4Semi" color="ds.gray.200" textTransform="uppercase">
            <MIFormattedText label="regularBatchPayments.totalPaymentsAmount" />
          </Box>

          <TotalAmount amount={totalAmount} />

          <TotalFees fees={feesData} />
        </VStack>
      </Flex>
    ),
    [totalAmount, feesData]
  );
};

export { Title };
