import { useApi } from 'src/hoc/useApi';
import deliveryMethods, {
  ShiftVirtualCardToACHRequestParams,
  ShiftVirtualCardToACHResponse,
} from 'src/services/api/deliveryMethods';
import { logger } from 'src/services/loggers';

export const useShiftSUVCtoACH = (token: string) => {
  const [apiCall, , isProcessing] = useApi<[ShiftVirtualCardToACHRequestParams], ShiftVirtualCardToACHResponse>(
    deliveryMethods.shiftVirtualCardToAch
  );

  const shiftVirtualCardToAch = async (
    deliveryMethod: ShiftVirtualCardToACHRequestParams['deliveryMethod'],
    achDeliveryMethodId?: string
  ) => {
    try {
      const { newDeliveryMethod } = await apiCall({
        token,
        deliveryMethod,
        achDeliveryMethodId,
      });

      return newDeliveryMethod;
    } catch (error) {
      logger.error('useShiftSUVCtoACH.shiftVirtualCardToAch(): failure', error, {
        extra: {
          params: {
            token,
            deliveryMethod,
          },
        },
      });

      throw error;
    }
  };

  return {
    shiftVirtualCardToAch,
    isProcessing,
  };
};
