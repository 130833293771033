import styled, { css } from 'styled-components';
import MIInlineLink from 'src/components/common/MIInlineLink';

const AddAnotherMethodOption = styled(MIInlineLink)`
  font-size: 1.4rem;
  line-height: 2.1rem;
  width: 100%;
  padding: 1rem 2rem;
  text-align: left;
  height: inherit;
`;

const AddAnotherRadioMethodOption = styled(AddAnotherMethodOption)`
  padding: 0;
  ${(props) => props.theme.components?.MethodsList?.AddAnotherRadioMethodOption}
`;

const MethodOptionIcon = styled.i<{ disabled?: boolean }>`
  height: 3.4rem;
  width: 3.4rem;
  color: ${(props) => (props.disabled ? props.theme.colors.dark.translucent3 : props.theme.colors.icons.card)};
  border: none;
  font-size: 3.4rem;
  margin-right: 2rem;
  ${(props) => props.theme.components?.MethodElements?.MethodIcon}
`;

const MethodOptionImage = styled.img`
  height: 3.4rem;
  width: 3.4rem;
  margin-right: 2rem;
  ${(props) =>
    props.isExpired &&
    css`
      filter: grayscale(100%);
      opacity: 0.3;
    `}
  ${(props) => props.theme.components?.MethodElements?.MethodImage}
`;

const MethodOptionDescription = styled.div<{
  disabled?: boolean;
  isExpired?: boolean;
}>`
  color: ${(props) => {
    if (props.disabled && !props.isExpired) {
      return props.theme.colors.dark.translucent3;
    }

    return props.theme.text.color.subtitle;
  }};
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-overflow: ellipsis;
  text-align: left;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme.components?.MethodElements?.MethodOptionDescription}
`;

const MethodOptionOnTopDescription = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  text-overflow: ellipsis;
  text-align: left;
  ${(props) => props.theme.text.fontType.hint};
`;

const MethodOptionLabel = styled.div<{ disabled?: boolean }>`
  line-height: 2.2rem;
  color: ${(props) => (props.disabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.main)};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.components?.MethodElements?.MethodOptionLabel}
`;

export {
  MethodOptionIcon,
  MethodOptionImage,
  MethodOptionDescription,
  MethodOptionOnTopDescription,
  MethodOptionLabel,
  AddAnotherMethodOption,
  AddAnotherRadioMethodOption,
};
