import { useEffect } from 'react';
import { useSiteContext } from 'src/hoc/withSiteContext';

export default function SiteTitle() {
  const site = useSiteContext();
  useEffect(() => {
    document.title = site.title;
  }, [site]);

  return null;
}
