import sumBy from 'lodash/sumBy';
import { RecordOf } from 'immutable';
import first from 'lodash/first';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { CONSTS } from './consts';
import { getCheckDepositedDate, getLatestPayment } from './payments';
import { BillType, BillStatusType, DeliveryMethodType, AccountType, PaymentType, AddressType } from './types';
import { convertToDisplayAddress } from './address';
import { encodeQuery } from './query-utils';

function getBillTag(bill: RecordOf<BillType> | BillType) {
  const latestPayment = getLatestPayment(bill.payments);
  return getBillPaymentTag(bill, latestPayment);
}

function getBillPaymentTag(bill: RecordOf<BillType> | BillType, payment?: PaymentType) {
  const paymentStatus = get(payment, 'status');
  const approvalStatus = get(payment, 'approvalDecisionStatus');
  const isBillPaid = bill.status === CONSTS.BILL_STATUS.PAID;

  if (paymentStatus === CONSTS.PAYMENT_STATUS.FAILED) {
    if (approvalStatus === CONSTS.PAYMENT_APPROVAL_STATUS.DECLINED) {
      return CONSTS.PAYMENT_APPROVAL_STATUS.DECLINED;
    }

    return CONSTS.PAYMENT_STATUS.FAILED;
  } else if (approvalStatus === CONSTS.PAYMENT_APPROVAL_STATUS.PENDING) {
    return CONSTS.PAYMENT_APPROVAL_STATUS.PENDING;
  } else if (isBillPaid) {
    if (payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      const checkDepositedDate = getCheckDepositedDate(payment.transactions);
      return checkDepositedDate && CONSTS.TAG_VARIANT.DEPOSITED;
    } else if (payment?.metadata?.achDeposited) {
      return CONSTS.TAG_VARIANT.ACH_DEPOSITED;
    }
  }

  return bill.status;
}

export const getPartialBillId = (bill: Partial<BillType>) => `${bill?.id || 0}-${bill?.payments?.length || 0}`;

const getCardLabel = (type) =>
  type === CONSTS.CARD_TYPES.CREDIT
    ? 'bills.form.paymentActivity.scheduledBill.scheduleMethodCreditCard'
    : 'bills.form.paymentActivity.scheduledBill.scheduleMethodDebitCard';

function getFundingSourceLabel(fundingSource: RecordOf<AccountType> | null) {
  return get(fundingSource, 'fundingType') === CONSTS.FUNDING_TYPE.ACH
    ? 'bills.form.paymentActivity.scheduledBill.scheduleMethodAch'
    : getCardLabel(get(fundingSource, 'cardAccount.cardType', CONSTS.CARD_TYPES.CREDIT));
}

export function getFormattedCheckSerial(payment: PaymentType) {
  const checkTransactions = (payment?.transactions || []).filter(
    (transaction) =>
      transaction.transactionType === CONSTS.TRANSACTION_TYPES.CHECK &&
      transaction.transactionDirection === CONSTS.TRANSACTION_DIRECTIONS.CREDIT &&
      transaction.status === CONSTS.TRANSACTION_STATUSES.COMPLETED
  );

  if (checkTransactions.length === 0) {
    return '';
  }

  // Assuming there is 1 or more check credit transaction per payment, showing the last
  const rawTransactionData = checkTransactions[checkTransactions.length - 1].rawData || {};

  if (!rawTransactionData.checkSerialNumber) {
    return '';
  }

  return ` #${rawTransactionData.checkSerialNumber}`;
}

function isManuallyPaid(billStatus: BillStatusType, hasManualPayment: boolean) {
  return billStatus === CONSTS.BILL_STATUS.PAID && hasManualPayment;
}

const getBillsDefaultFilters = (status: BillStatusType) => {
  const statusFilterParamsMap = {
    [CONSTS.BILL_STATUS.UNPAID]: {
      status: CONSTS.BILL_STATUS.UNPAID,
      sorting: 'payment.status,dueDate',
      start: CONSTS.PAGINATION.DEFAULT_START,
      limit: CONSTS.PAGINATION.DEFAULT_LIMIT,
    },
    [CONSTS.BILL_STATUS.SCHEDULED]: {
      status: CONSTS.BILL_STATUS.SCHEDULED,
      sorting: 'payment.scheduledDate',
      start: CONSTS.PAGINATION.DEFAULT_START,
      limit: CONSTS.PAGINATION.DEFAULT_LIMIT,
    },
    [CONSTS.BILL_STATUS.PAID]: {
      status: CONSTS.BILL_STATUS.PAID,
      sorting: 'payment.scheduledDate:desc',
      start: CONSTS.PAGINATION.DEFAULT_START,
      limit: CONSTS.PAGINATION.DEFAULT_LIMIT,
    },
  };

  return statusFilterParamsMap[status];
};

const getBillsSearchPath = (
  status: BillStatusType,
  baseSearch = '',
  excludeFields: string[] = [],
  start: number = CONSTS.PAGINATION.DEFAULT_START,
  limit: number = CONSTS.PAGINATION.DEFAULT_LIMIT
) => {
  const defaultFilters = getBillsDefaultFilters(status);
  const billFilters = {
    ...defaultFilters,
    start,
    limit,
  };
  return encodeQuery(billFilters, excludeFields, baseSearch);
};

const getDisplayAddress = (bill: BillType): string | null => {
  const deliveryMethods = get(bill, 'vendor.deliveryMethods');
  if (!deliveryMethods) {
    return null;
  }

  const checkDeliveryMethod = first<DeliveryMethodType>(deliveryMethods.filter((dm) => dm.deliveryType === 'check'));
  if (!checkDeliveryMethod || !checkDeliveryMethod.paperCheck) {
    return null;
  }

  return convertToDisplayAddress(checkDeliveryMethod.paperCheck as AddressType);
};

const productReceivedOptions = [
  {
    id: '1',
    label: 'categoryList.yes',
  },
  {
    id: '0',
    label: 'bills.form.receivedProduct.no',
  },
];

const isBillAmountRequiresGoodsConfirmation = (amount: string) =>
  Number(amount) >= CONSTS.RISK_BILL_AMOUNT.REQUIRES_GOODS_CONFIRMATION_10K;

const getDefaultMemo = (invoiceNumber: string) => {
  if (invoiceNumber) {
    return `Invoice no. ${invoiceNumber}`;
  }

  return '';
};

const isBillHasPartialPayments = (bill: BillType): boolean => {
  if (isNil(bill)) {
    return false;
  }

  const billAmount = convertCurrencyToNumber(bill.totalAmount);

  return !isEmpty(bill.payments) && bill.payments?.every((p) => p && p?.amount && p.amount < billAmount);
};

const getBillBalance = (bill: BillType, excludePaymentIds: string[]) => {
  const filteredPayments = bill.payments.filter((payment) => !excludePaymentIds.includes(payment.id));
  return bill.totalAmount - (bill.externallyPaid || 0) - sumBy(filteredPayments, 'amount');
};

const isVendorRequest = (bill: BillType) => !!bill.vendor?.ownedById;

export {
  isVendorRequest,
  isManuallyPaid,
  getBillsDefaultFilters,
  getBillsSearchPath,
  getFundingSourceLabel,
  getDisplayAddress,
  productReceivedOptions,
  isBillAmountRequiresGoodsConfirmation,
  getBillTag,
  getDefaultMemo,
  isBillHasPartialPayments,
  getBillPaymentTag,
  getBillBalance,
};
