import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import {
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  FULL_STORY_MASK_RULE_CLASS,
  SERVER_RESPONSE_CODES,
  SINGLE_SELECT_FLAVOR,
  TIMEOUTS,
} from 'src/utils/consts';
import { CardAccountType } from 'src/utils/types';
import analytics from 'src/services/analytics';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { WizardFormRow } from 'src/components/layout/WizardElements';
import US_STATES from 'src/utils/us-states';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import locations from 'src/billpay/qbdt/pages/locations';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import useRefreshFundingSources from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import CardFormInput from './components/CardFormInput';
import { useBasisTheoryAddCardAccount } from './hooks/useBasisTheoryAddCardAccount';
import { AddCardSuccessModal } from './components/AddCardSuccessModal';
import { AddCardErrorModal } from './components/AddCardErrorModal';
import { useAddFundingSourceNavigator } from '../hooks/useAddFundingSourceNavigator';
import { InitialAddFundingSourceLocationState } from '../types';
import { LinkIntuitAccountLocationState } from '../LinkIntuitAccountToFundingSourcePage';

export type AddCardHolderDetailsLocationState = InitialAddFundingSourceLocationState & {
  token: string;
  digits: string;
  cardBin: string;
  expiration: string;
};

export type AddCardAccountSuccessParams = {
  responseCode: any;
  cardAccount: CardAccountType;
  id?: number | null;
};

const initialIsFieldSubmittedState = {
  firstName: false,
  lastName: false,
  address: false,
  city: false,
  state: false,
  zipCode: false,
};

const eventPage = 'add-card';

type Props = {
  selectFundingSourceForBatchFlow: ({ newFundingSourceId }: { newFundingSourceId: number }) => Promise<void>;
};

const CARD_ALREADY_EXIST_ERROR_CODE = 'RSC05';

export const AddCardHolderDetailsPage = ({ selectFundingSourceForBatchFlow }: Props) => {
  const locationState = useLocation<AddCardHolderDetailsLocationState>().state;
  const history = useHistory();
  const { navigateOnExit } = useAddFundingSourceNavigator();
  const dispatch = useDispatch();
  const orgId = useOrgId();
  const { refreshFundingSources } = useRefreshFundingSources();

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [newCardDetails, setNewCardDetails] = useState<{ cardAccount: CardAccountType | null; id: number | null }>({
    cardAccount: null,
    id: null,
  });
  const [isFieldSubmittedState, setIsFieldSubmitted] = useState<Record<string, any>>(initialIsFieldSubmittedState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const onSuccess = async ({ id, responseCode, cardAccount }) => {
    await refreshFundingSources();
    setNewCardDetails({ id, cardAccount });
    setErrorCode(responseCode);
    setIsLoading(false);

    if (responseCode === SERVER_RESPONSE_CODES.OK) {
      setTimeout(() => goNext(id), TIMEOUTS.CLOSE_MODAL);
    }
  };

  const onError = (error: any) => {
    setErrorCode(error);
    setIsLoading(false);
  };

  const { addCardAccountApiCall } = useBasisTheoryAddCardAccount({ eventPage, onSuccess, onError });

  const getValidationErrors = () =>
    Object.entries(formState).reduce((acc, [key, value]) => {
      if (!value) {
        acc[key] = true;
      }

      return acc;
    }, {});

  const handleSubmitCardHolderDetails = async () => {
    const validationErrors = getValidationErrors();
    if (Object.values(validationErrors).length > 0) {
      setIsFieldSubmitted({ ...initialIsFieldSubmittedState, ...validationErrors });
      analytics.track(eventPage, 'add-card-account-submit-validation-error', isFieldSubmittedState);
      return;
    }

    analytics.track(eventPage, 'add-card-account-submit');
    setIsLoading(true);
    addCardAccountApiCall(formState);
  };

  const handleCloseErrorModal = () => {
    if (errorCode === CARD_ALREADY_EXIST_ERROR_CODE) {
      history.push(generatePath(locations.fundingSource.card, { orgId }), locationState);
    } else {
      setErrorCode(null);
    }
  };

  const handleCloseSuccessModal = () => goNext(newCardDetails.id);

  const goExit = () => {
    navigateOnExit();
  };

  const goNext = (id: number | null) => {
    const origin = locationState?.preservedState?.origin;
    if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS && id) {
      selectFundingSourceForBatchFlow({
        newFundingSourceId: id,
      });
    }

    if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL && id) {
      dispatch(selectFundingSourceAction(id));
    }

    const nextPageLocationState: LinkIntuitAccountLocationState = {
      ...locationState,
      fundingSourceId: id,
      origin,
    };
    history.push(generatePath(locations.fundingSource.linkAccount, { orgId }), nextPageLocationState);
  };

  const onChange = ({ value, id }: { value: string; id: string }) => {
    setFormState((prevState) => ({ ...prevState, [id]: value }));
    setIsFieldSubmitted((prevState) => ({ ...prevState, [id]: false }));
  };

  const { firstName, lastName, address, city, state, zipCode } = formState;

  return (
    <QBDTStepLayout
      title="onboarding.fundingSources.card.title"
      goExit={goExit}
      onSubmit={handleSubmitCardHolderDetails}
      nextLabel="onboarding.fundingSources.card.save"
      isLoading={isLoading}
      hideHeader
    >
      {errorCode === SERVER_RESPONSE_CODES.OK && (
        <AddCardSuccessModal onClose={handleCloseSuccessModal} cardAccount={newCardDetails.cardAccount} />
      )}
      {errorCode && errorCode !== SERVER_RESPONSE_CODES.OK && (
        <AddCardErrorModal onClose={handleCloseErrorModal} errorCode={errorCode} />
      )}
      <div className={FULL_STORY_MASK_RULE_CLASS}>
        <WizardFormRow>
          <CardFormInput
            value={firstName}
            label="onboarding.fundingSources.card.firstName.label"
            required
            id="firstName"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.firstName.errorMessage"
            validationTest="string"
            returnVal={onChange}
            submitValidation={isFieldSubmittedState.firstName}
            autoFocus
          />
          <CardFormInput
            value={lastName}
            label="onboarding.fundingSources.card.lastName.label"
            required
            id="lastName"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.lastName.errorMessage"
            validationTest="string"
            returnVal={onChange}
            submitValidation={isFieldSubmittedState.lastName}
          />
        </WizardFormRow>
        <CardFormInput
          value={address}
          label="onboarding.fundingSources.card.address.label"
          id="address"
          placeHolder=""
          errorMessage="onboarding.fundingSources.card.address.errorMessage"
          validationTest="string"
          wizardColumn={false}
          returnVal={onChange}
          required
          submitValidation={isFieldSubmittedState.address}
        />
        <WizardFormRow>
          <CardFormInput
            value={city}
            label="onboarding.fundingSources.card.city.label"
            id="city"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.city.errorMessage"
            validationTest="string"
            required
            returnVal={onChange}
            submitValidation={isFieldSubmittedState.city}
          />
          <MISingleSelect
            id="state"
            value={state}
            label="onboarding.fundingSources.card.state.label"
            options={US_STATES}
            onChange={onChange}
            placeholder=""
            errorMessage={isFieldSubmittedState.state ? 'onboarding.fundingSources.card.state.errorMessage' : ''}
            required
            flavor={SINGLE_SELECT_FLAVOR.DEFAULT}
          />
        </WizardFormRow>
        <CardFormInput
          value={zipCode}
          label="onboarding.fundingSources.card.zipcode.label"
          required
          id="zipCode"
          placeHolder=""
          errorMessage="onboarding.fundingSources.card.zipcode.errorMessage"
          validationTest="string"
          wizardColumn={false}
          returnVal={onChange}
          submitValidation={isFieldSubmittedState.zipCode}
        />
      </div>
    </QBDTStepLayout>
  );
};
