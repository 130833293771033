import * as React from 'react';
import styled, { css } from 'styled-components';
import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import qboLogo from '../../images/qbo/logo-qbo-footer.svg';

const commonStyles = css`
  display: flex;
  align-items: center;
`;

const QBOLayoutContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  position: relative;
`;

const SettingsLink = styled(SmartLink)`
  ${commonStyles};
  margin-right: 3.6rem;
  text-decoration: none;
  border: none;
  color: ${(props) => props.theme.colors.text};
  &:active {
    color: ${(props) => props.theme.colors.text};
  }
  @media ${devices.mobile} {
    display: none;
  }
`;

const navIconStyles = css`
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.text.color.label};
  cursor: pointer;
  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 2.8rem;
    width: 2.8rem;
    height: 2.8rem;
  }
`;

const Close = styled.div`
  ${navIconStyles};
  @media ${devices.mobile} {
    color: ${(props) => props.theme.colors.brand};
  }
`;

const Back = styled.div`
  ${navIconStyles};
  padding-right: 1.4rem;
  @media ${devices.mobile} {
    display: none;
  }
`;
const BackMobile = styled.div`
  display: none;
  @media ${devices.mobile} {
    display: block;
    ${navIconStyles};
    color: ${(props) => props.theme.colors.brand};
    min-width: 7.6rem;
  }
  ${(props) => props.theme?.components?.QBOElements?.BackMobile}
`;

const QBOFooterContainer = () => {
  const site = useSiteContext();
  return (
    <QBOFooter>
      <MelioFooterLogo>
        <QBOLogo src={qboLogo} alt="qboLogo" />
      </MelioFooterLogo>
      <MelioFooterText>
        <MIFormattedText
          label="qbo.footer.poweredBy"
          values={{
            evolve: <strong>Evolve Bank & Trust</strong>,
            melio: <strong>Melio</strong>,
          }}
        />
      </MelioFooterText>
      <FooterSettingsTextWrap>
        <FooterSettingsText href={site.config.agreementLinks.termsOfService} target="_blank" rel="noopener noreferrer">
          <MIFormattedText label="qbo.footer.termsOfService" />
        </FooterSettingsText>
        <FooterSettingsText href={site.config.agreementLinks.privacyPolicy} target="_blank" rel="noopener noreferrer">
          <MIFormattedText label="qbo.footer.privacyPolicy" />
        </FooterSettingsText>
      </FooterSettingsTextWrap>
    </QBOFooter>
  );
};

QBOFooterContainer.defaultProps = {};

const QBOLogo = styled.img``;

const QBOFooter = styled.div`
  ${commonStyles};
  color: rgba(113, 113, 115);
  font-size: 1rem;
  width: 100%;
  padding: 0 1.6rem;
  box-sizing: border-box;
  margin-top: auto;
  height: 4rem;
  background-color: rgba(236, 237, 240);

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1.6rem 1.2rem;
    height: auto;
    min-height: 8.8rem;
    background-color: #f4f5f8;
  }
  ${(props) => props.theme?.components?.QBOFooterContainer?.QBOFooter}
`;

const MelioFooterLogo = styled.div`
  padding: 0.5rem 1.6rem 0 0.8rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    padding: 0;
    margin-bottom: 0.8rem;
  }
`;

const FooterSettingsTextWrap = styled.div`
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
    flex-direction: row;
    > a {
      margin: 0;
    }
    > a:last-child {
      margin-left: 1.5rem;
    }
  }
`;

const FooterSettingsText = styled.a`
  text-decoration: none;
  color: rgba(28, 130, 210);
  margin-left: 1.6rem;
  &:hover {
    text-decoration: underline;
  }
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
  }
`;

const MelioFooterText = styled.a`
  line-height: 1.4rem;
  letter-spacing: -0.01rem;
  strong {
    font-weight: 600;
  }
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    text-align: center;
    margin-bottom: 0.8rem;
  }
`;

export { QBOLayoutContainer, QBOFooterContainer, SettingsLink, Close, Back, BackMobile };
