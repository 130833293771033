import React, { PropsWithChildren } from 'react';
import { Box } from '@melio/billpay-design-system';

type Props = {
  translucent: boolean;
};

export const BrandLogoContainer = ({ translucent, children }: PropsWithChildren<Props>) => (
  <Box opacity={translucent ? 0.2 : 1} borderColor="white">
    {children}
  </Box>
);
