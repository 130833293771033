import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useDashboardListParams } from '../../../hooks/useDashboardListParams';
import { DASHBOARD_FILTER_ITEMS } from '../consts';
import { DashboardFilterMenuItems, FiltersChangeInfo } from '../types';
import { getFieldValueFromMenuOptions, removeSelectionFromFilterOptions, getDashboardFilterFields } from './utils';

export const useDashboardFilter = () => {
  const vendors = useSelector(vendorsStore.selectors.allList);
  const [listParams, { setFilters, resetFilters }] = useDashboardListParams();

  const [filterMenuItems, setFilterMenuItems] = useState<DashboardFilterMenuItems>(
    getDashboardFilterFields({
      filtersValuesByFieldName: listParams.filters,
      tabName: listParams.filters.status,
      vendors,
    })
  );

  useEffect(() => {
    if (listParams.filters && vendors.length) {
      setFilterMenuItems(
        getDashboardFilterFields({
          filtersValuesByFieldName: listParams.filters,
          tabName: listParams.filters.status,
          vendors,
        })
      );
    }
  }, [vendors, listParams.filters]);

  const clearAllFilters = () => {
    setFilterMenuItems(removeSelectionFromFilterOptions({ filterMenuItems }));
    resetFilters();
  };

  const handleFiltersChange = ({ fieldName, options = [] }: FiltersChangeInfo) => {
    if (fieldName) {
      setFilterMenuItems({ ...filterMenuItems, [fieldName]: { ...filterMenuItems[fieldName], options } });
      setFilters({
        ...listParams.filters,
        [fieldName]: getFieldValueFromMenuOptions(options),
      });
    } else {
      clearAllFilters();
    }
  };

  const filterMenuItemsList = DASHBOARD_FILTER_ITEMS.filter((filterItemName) => filterMenuItems[filterItemName]).map(
    (fieldName) => ({
      fieldName,
      ...filterMenuItems[fieldName],
    })
  );

  return { handleFiltersChange, filterMenuItemsList };
};
