import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '@melio/billpay-design-system';
import moment from 'moment';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { RecordOf } from 'immutable';
import analytics from 'src/services/analytics';
import { withNavigator } from 'src/hoc';
import { CONSTS } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { chakra } from 'src/theme/ds';
import { getBill, getSelectedFundingSource, getPayment } from 'src/redux/payBillWizard/selectors';
import { DeliveryDateFormat } from 'src/utils/dates';
import { selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { PaymentDeliveryOptions } from 'src/components/common/PaymentDeliveryOptions';
import AreaLoader from 'src/components/common/AreaLoader';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/billpay/qbdt/pages/locations';

import { withPayBillData, PayBillProps } from './hoc/withPayBillData';
import { QBDTStepLayout } from '../../components';

const eventPage = 'pay-bill';

const getFundingSourceType = (fundingSource?: RecordOf<AccountType>): string | undefined =>
  fundingSource?.fundingType === CONSTS.FUNDING_TYPE.CARD
    ? fundingSource?.cardAccount?.cardType
    : fundingSource?.fundingType;

const FastPaymentOfferPage = ({ onNext, onPrev, goExit, deliveryOptionsDates, isLoading }: PayBillProps) => {
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const fundingSourceType = getFundingSourceType(selectedFundingSource);
  const deliveryOptions = deliveryOptionsDates?.deliveryOptions;
  const deliveryPreference = payment?.deliveryPreference;
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();

  useEffect(() => {
    if (deliveryOptionsDates) {
      const { deliveryOptions } = deliveryOptionsDates;
      if (deliveryOptions.length <= 1) {
        historyPush({
          path: locations.edit.confirm,
          params: { billId: bill.id, paymentId: payment.id },
        });
        return;
      }

      const deliveryType = payment?.deliveryMethod?.deliveryType;

      if (deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
        analytics.track(eventPage, 'express-check-shown');
        return;
      }

      if (deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
        analytics.track(eventPage, 'fast-ach-shown', { fundingSourceType });
      }
    }
  }, [deliveryOptionsDates, bill.id, payment.id, deliveryPreference, fundingSourceType, historyPush]);

  const onSelectDeliveryOption = (scheduledDate, deliveryDate, maxDeliveryDate, type) => {
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryDate, maxDeliveryDate, type));

    analytics.track(eventPage, `delivery-selected-${type || 'regular'}`, {
      fundingSourceType,
      fundingSourceId: selectedFundingSource?.id,
    });
  };

  const getSubtitle = () => {
    const { dueDate } = bill;
    const deliveryDate = deliveryOptionsDates?.deliveryOptions[0].deliveryDate;
    const isPastDueDate = moment(deliveryDate).isAfter(dueDate);
    if (payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      return !isPastDueDate
        ? 'bills.pay.fastPayment.subtitle.fastCheckPastDueDate'
        : 'bills.pay.fastPayment.subtitle.fastCheck';
    }

    return !isPastDueDate
      ? 'bills.pay.fastPayment.subtitle.fastAchPastDueDate'
      : 'bills.pay.fastPayment.subtitle.fastAch';
  };

  const subTitleValues = {
    dueDate: <MIFormattedDate date={bill.dueDate} />,
    deliveryDate: (
      <DeliveryDateFormat
        date={deliveryOptionsDates?.deliveryOptions[0].deliveryDate}
        maxDate={deliveryOptionsDates?.deliveryOptions[0].maxDeliveryDate}
      />
    ),
  };

  if (isLoading) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <QBDTStepLayout
      title="bills.pay.fastPayment.title"
      subtitle={getSubtitle()}
      subTitleValues={subTitleValues}
      goExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      relativeStep={4 / 5}
    >
      <InfoContainer>
        <InfoItemContainer>
          <InfoItemText>
            <MIFormattedText label="bills.pay.date.paymentDeliveryDate" />
          </InfoItemText>
          <SelectDeliveryDateContainer>
            {deliveryOptions && (
              <PaymentDeliveryOptions
                fundingSourceType={fundingSourceType}
                deliveryOptions={deliveryOptions}
                deliveryPreference={deliveryPreference}
                onSelectDeliveryOption={onSelectDeliveryOption}
              />
            )}
          </SelectDeliveryDateContainer>
        </InfoItemContainer>
      </InfoContainer>
    </QBDTStepLayout>
  );
};

export default compose(withNavigator(), withPayBillData())(FastPaymentOfferPage);

const InfoContainer = chakra(Flex, {
  baseStyle: {
    p: '5',
    backgroundColor: 'ds.white',
    border: '1px solid #ededed',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 'lg',
  },
});

const SelectDeliveryDateContainer = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 1.6rem;
  ${(props) => props.theme.text.fontType.medium};
`;

const InfoItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const InfoItemText = chakra('div', {
  baseStyle: {
    textStyle: 'ds.body3Semi',
    textTransform: 'uppercase',
    color: '#717173',
  },
});
