import { PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';
import deliveryApi from '../services/api/delivery';

export type InitialProcessingDatesParams = {
  scheduledDate?: Date | null;
  orgId: string;
  deliveryMethodId: number;
  fundingSourceId: number;
  amount: number | null | undefined;
  dueDate: string | Date;
  paymentId?: string;
  payBillFlowUUID?: string;
  createOrigin?: PAYMENT_CREATE_FLOW_ORIGIN;
};

async function getInitialProcessingDates({
  scheduledDate,
  orgId,
  deliveryMethodId,
  fundingSourceId,
  amount,
  dueDate,
  paymentId,
  payBillFlowUUID,
  createOrigin = PAYMENT_CREATE_FLOW_ORIGIN.QBDT_WINDOWS,
}: InitialProcessingDatesParams) {
  const minScheduledDateRequest = deliveryApi.getDeliveryTime({
    orgId,
    scheduledDate: new Date(),
    deliveryMethodId,
    fundingSourceId,
    amount,
    paymentId,
    payBillFlowUUID,
    createOrigin,
  });

  let deliveryDateRequest = minScheduledDateRequest;
  if (scheduledDate) {
    deliveryDateRequest = deliveryApi.getDeliveryTime({
      orgId,
      scheduledDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID,
      createOrigin,
    });
  } else if (dueDate) {
    deliveryDateRequest = deliveryApi.getLatestProcessingDate({
      orgId,
      dueDate: new Date(dueDate),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID,
      createOrigin,
    });
  }

  const [
    { suggestedScheduledDate: minScheduledDate },
    { suggestedScheduledDate, deliveryDate, maxDeliveryDate, deliveryOptions },
  ] = await Promise.all([minScheduledDateRequest, deliveryDateRequest]);
  return {
    suggestedScheduledDate,
    deliveryDate,
    maxDeliveryDate,
    minScheduledDate,
    deliveryOptions,
  };
}

export { getInitialProcessingDates };
