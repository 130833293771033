import React, { useEffect, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Flex, Link } from '@melio/billpay-design-system';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-js/types/elements/elements';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { RetrievedTokenizedData } from 'src/billpay/qbdt/components/basis-theory/types';
import { OrganizationType, PaymentType } from 'src/utils/types';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import { useLinkCard } from 'src/pages/vendor/shift-vendor-to-debit/hooks';
import { BUTTON_VARIANT } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useApi } from 'src/hoc/useApi';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { AddDebitCardHolderDetailsLocationState } from '../types';
import {
  AddDebitCardPageSubtitle,
  AddDebitCardPageTitle,
  DesktopSubmitContainer,
  LinkCardView,
  MobileSubmitContainer,
  PaymentInfo,
  UserSupport,
  SubmitHintText,
  ValidateDebitCardDetailsErrorModal,
} from '../components/AddDebitCardPage';
import { addCardAccountDeliveryMethod } from '../api/addCardAccountDeliveryMethod';
import locations from '../locations';

type Props = {
  organization?: OrganizationType;
  payment?: PaymentType;
  token: string;
};
export const AddDebitCardPage = ({ organization, payment, token }: Props) => {
  const { config } = useSiteContext();
  const { privacyPolicy, termsOfService } = config.agreementLinks;
  const companyName = organization?.companyName || '';

  const [onValidateCard, , isCardValidating] = useApi(addCardAccountDeliveryMethod.validateCardAccountWithToken);

  const history = useHistory();
  const [isValidationError, setIsValidationError] = useState<boolean>(false);

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'add-debit-card', {
      OriginDeliveryType: payment?.originalDeliveryMethod?.deliveryType,
    });
  }, []);

  const onLinkCard = async ({ id, expirationMonth, expirationYear, last4Digits, cardBin }: RetrievedTokenizedData) => {
    try {
      await onValidateCard({
        cardToken: id,
        token,
        cardBin,
      });

      const locationState: AddDebitCardHolderDetailsLocationState = {
        cardToken: id,
        digits: last4Digits,
        expiration: `${expirationMonth}/${expirationYear.toString().slice(-2)}`,
        cardBin,
      };
      history.push(generatePath(locations.confirmCardHolder, { token }), locationState);
    } catch {
      setIsValidationError(true);
    }
  };

  const handleHideErrorMessageAlert = () => {
    setIsValidationError(false);
  };

  const cardNumberElementRef = useRef<CardNumberElement>(null);
  const cardVerificationCodeElementRef = useRef<CardVerificationCodeElement>(null);
  const cardExpirationDateElementRef = useRef<CardExpirationDateElement>(null);

  const {
    handleLinkCard,
    attemptedToLink,
    isCardLinking,
    cardBrand,
    handleCardBrandChange,
    handleErrorStateChange,
    handleReady,
    areFieldsLoading,
  } = useLinkCard({
    cardExpirationDateElementRef,
    cardVerificationCodeElementRef,
    cardNumberElementRef,
    onError: undefined,
    onLinkCard,
  });

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack>
        <PaymentInfo companyName={companyName} amount={payment?.amount || 0} />
        <Divider my="ds.2xl" />
        <Flex direction="column" gap="ds.sm" mb="ds.2xl">
          <AddDebitCardPageTitle />
          <AddDebitCardPageSubtitle />
        </Flex>
        {isValidationError && <ValidateDebitCardDetailsErrorModal onCloseModal={handleHideErrorMessageAlert} />}
        <LinkCardView
          handleLinkCard={handleLinkCard}
          cardBrand={cardBrand}
          cardExpirationDateElementRef={cardExpirationDateElementRef}
          cardVerificationCodeElementRef={cardVerificationCodeElementRef}
          cardNumberElementRef={cardNumberElementRef}
          areFieldsLoading={areFieldsLoading}
          attemptedToLink={attemptedToLink}
          handleCardBrandChange={handleCardBrandChange}
          handleErrorStateChange={handleErrorStateChange}
          handleReady={handleReady}
        />
        <DesktopSubmitContainer>
          <MIButton
            testId="link-card"
            label="vendor.pushToDebit.addDebitCard.submit"
            isProcessing={isCardValidating || isCardLinking}
            onClick={handleLinkCard}
            variant={BUTTON_VARIANT.PRIMARY}
            fullWidth
            disabled={areFieldsLoading}
          />
          <SubmitHintText label="vendor.pushToDebit.addDebitCard.submitHint" />
        </DesktopSubmitContainer>
      </VendorFlowContentCard>
      <UserSupport />
      <Footer />
      <MobileSubmitContainer>
        <MIButton
          testId="link-card"
          label="vendor.pushToDebit.addDebitCard.submit"
          isProcessing={isCardValidating || isCardLinking}
          onClick={handleLinkCard}
          variant={BUTTON_VARIANT.PRIMARY}
          fullWidth
          disabled={areFieldsLoading}
        />
        <SubmitHintText
          label="vendor.pushToDebit.addDebitCard.submitHintMobile"
          values={{
            TermsOfServiceLink: (...chunks) => (
              <Link color="ds.gray.100" href={termsOfService} textDecoration="underline">
                {React.Children.toArray(chunks)}
              </Link>
            ),
            PrivacyPolicyLink: (...chunks) => (
              <Link color="ds.gray.100" href={privacyPolicy} textDecoration="underline">
                {React.Children.toArray(chunks)}
              </Link>
            ),
            companyName,
          }}
        />
      </MobileSubmitContainer>
    </VendorFlowLayout>
  );
};
