import React from 'react';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';
import { CONSTS } from 'src/utils/consts';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';

type Props = {
  amount: number;
  fastFeeAmount?: number;
  disabled: boolean;
  checkFeeObject?: Record<string, any>;
};

export const AmountCell = ({ amount, disabled, fastFeeAmount, checkFeeObject }: Props) => {
  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();

  return (
    <Flex direction="column" justify="center">
      <Tooltip
        placement="top"
        label={<MIFormattedCurrency value={amount} currency={CONSTS.CURRENCY_SIGN.USD} />}
        isDisabled={!isTruncated}
      >
        <Box textStyle="ds.body3Semi" color={disabled ? '#C2C2C3' : 'ds.gray.100'} isTruncated ref={ref}>
          <MIFormattedCurrency value={amount} currency={CONSTS.CURRENCY_SIGN.USD} />
        </Box>
      </Tooltip>
      {checkFeeObject?.free && (
        <Flex justify="flex-end" textStyle="ds.body4" color="#717173">
          <Box>
            <MIFormattedText label="general.free" />
          </Box>
        </Flex>
      )}

      {checkFeeObject?.amount && (
        <Flex justify="flex-end" textStyle="ds.body4" color="#717173">
          <Box>+</Box>
          <Box isTruncated>
            <MIFormattedCurrency value={checkFeeObject.amount} currency={CONSTS.CURRENCY_SIGN.USD} />
          </Box>
        </Flex>
      )}

      {fastFeeAmount && (
        <Flex justify="flex-end" textStyle="ds.body4" color="#717173">
          <Box>+</Box>
          <Box isTruncated>
            <MIFormattedCurrency value={fastFeeAmount} currency={CONSTS.CURRENCY_SIGN.USD} />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
