import React from 'react';
import { Flex, HStack, Icon, Text, VStack } from '@melio/billpay-design-system';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as CalendarIllustration } from 'src/images/get-pro/calendar.svg';
import { ReactComponent as MasterCardLogo } from 'src/images/icons/mastercard.svg';
import { PaymentType } from 'src/utils/types';

type Props = {
  vendorEmail: string;
  deliveryEta: PaymentType['deliveryEta'];
};
export const VendorReceive = ({ vendorEmail, deliveryEta }: Props) => (
  <VStack gap="ds.xl" w="full" align="start">
    <Text color="ds.gray.200" textStyle="ds.body2Medium" m="0">
      <MIFormattedText label="vendor.pushToDebit.virtualCard.notCleared.vendorReceiveTitle" />
    </Text>
    <HStack gap="ds.lg">
      <Icon as={MasterCardLogo} w="3rem" h="auto" alignSelf="start" />
      <Flex direction="column" align="start">
        <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.text" />
        </Text>
        <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0" data-testid="p2d-virtual-card-vendor-email">
          {vendorEmail}
        </Text>
      </Flex>
    </HStack>
    <HStack gap="ds.lg">
      <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
      <Flex direction="column" align="start">
        <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.deliveryETA" />
        </Text>
        <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
          <MIFormattedDate date={deliveryEta} />
        </Text>
      </Flex>
    </HStack>
  </VStack>
);
