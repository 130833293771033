import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useUpdateUserPreference from 'src/modules/users/hooks/useUpdateUserPreferences';
import { getUserPreferences } from 'src/redux/user/selectors';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import analytics from 'src/services/analytics';
import { WalkthroughPopoverProps } from '../WalkthroughPopover';

export enum WalkthroughStep {
  COMBINE_BILLS = 'combine-bills',
  SELECT_PAYMENT_METHOD = 'select-payment-method',
  SET_DEDUCTION_DATE = 'set-deduction-date',
}

type Result = {
  getPopoverProps: (step: WalkthroughStep) => WalkthroughPopoverProps;
};

const defaultPopoverProps: WalkthroughPopoverProps = {
  isOpen: false,
  title: '',
  message: '',
  stepLabel: '',
  stepLabelValues: {},
  ctaLabel: '',
  hasBackButton: false,
  onBackClick: () => null,
  onCloseClick: () => null,
  onNextClick: () => null,
};

const WALKTHROUGH_STEP_COPY_MAPPING = {
  [WalkthroughStep.COMBINE_BILLS]: {
    title: 'regularBatchPayments.walkthrough.steps.combineBills.title',
    message: 'regularBatchPayments.walkthrough.steps.combineBills.message',
  },
  [WalkthroughStep.SELECT_PAYMENT_METHOD]: {
    title: 'regularBatchPayments.walkthrough.steps.selectPaymentMethod.title',
    message: 'regularBatchPayments.walkthrough.steps.selectPaymentMethod.message',
  },
  [WalkthroughStep.SET_DEDUCTION_DATE]: {
    title: 'regularBatchPayments.walkthrough.steps.setDeductionDate.title',
    message: 'regularBatchPayments.walkthrough.steps.setDeductionDate.message',
  },
};

const useWalkthroughState = (): Result => {
  const { updateUserPreference } = useUpdateUserPreference();
  const {
    qbdtBatchBulkPaymentsWalkthroughStepOneSeen: isFirstSeen,
    qbdtBatchBulkPaymentsWalkthroughStepTwoSeen: areRestSeen,
  } = useSelector(getUserPreferences);

  const [isBillsListLoaded, setIsBillsListLoaded] = useState<boolean>(false);
  const shouldFirstBeSeen = useSelector(regularBatchPaymentsStore.selectors.isBillsGroupingPossible);

  const steps = useRef<WalkthroughStep[]>([]);
  const [visibleStepIdx, setVisibleStepIdx] = useState<number | null>(null);

  const { isBillListLoading, billsList } = useBatchBillsList();

  useEffect(() => {
    if (!isBillListLoading && billsList.length > 0 && !isBillsListLoaded) {
      steps.current = getWalkthroughSteps(isFirstSeen, areRestSeen, shouldFirstBeSeen);

      setIsBillsListLoaded(true);
      setVisibleStepIdx(steps.current.length ? 0 : null);
    }
  }, [isBillsListLoaded, isBillListLoading, billsList, isFirstSeen, areRestSeen, shouldFirstBeSeen]);

  useEffect(() => {
    if (visibleStepIdx !== null) {
      const visibleStep = steps.current[visibleStepIdx];

      analytics.trackAction(`regularBatchPayments.walkthrough.${visibleStep}.shown`);
    }
  }, [visibleStepIdx]);

  const getWalkthroughSteps = (
    isFirstSeen: boolean,
    areRestSeen: boolean,
    shouldFirstBeSeen: boolean
  ): WalkthroughStep[] => {
    const result: WalkthroughStep[] = [];

    if (!isFirstSeen && shouldFirstBeSeen) {
      result.push(WalkthroughStep.COMBINE_BILLS);
    }

    if (!areRestSeen) {
      result.push(WalkthroughStep.SELECT_PAYMENT_METHOD);
      result.push(WalkthroughStep.SET_DEDUCTION_DATE);
    }

    return result;
  };

  const handleCloseClick = () => {
    if (visibleStepIdx !== null) {
      const visibleStep = steps.current[visibleStepIdx];

      analytics.trackAction(`regularBatchPayments.walkthrough.${visibleStep}.closed`);

      if (visibleStep === WalkthroughStep.COMBINE_BILLS && steps.current.length > 1 && !areRestSeen) {
        updateUserPreference({
          key: 'qbdtBatchBulkPaymentsWalkthroughStepTwoSeen',
          value: true,
        });
      }

      setVisibleStepIdx(null);
    }
  };

  const handleNextClick = () => {
    if (visibleStepIdx !== null) {
      const visibleStep = steps.current[visibleStepIdx];

      analytics.trackAction(`regularBatchPayments.walkthrough.${visibleStep}.next`);

      if (visibleStepIdx === steps.current.length - 1) {
        setVisibleStepIdx(null);
      } else {
        setVisibleStepIdx(visibleStepIdx! + 1);
      }
    }
  };

  const handleBackClick = () => {
    if (visibleStepIdx) {
      const visibleStep = steps.current[visibleStepIdx];

      analytics.trackAction(`regularBatchPayments.walkthrough.${visibleStep}.back`);

      setVisibleStepIdx(visibleStepIdx - 1);
    }
  };

  const getPopoverProps = (step: WalkthroughStep): WalkthroughPopoverProps => {
    const stepIdx = steps.current.findIndex((st) => st === step);

    if (stepIdx === -1) {
      return defaultPopoverProps;
    }

    const isOpen = stepIdx === visibleStepIdx;
    const { title, message } = WALKTHROUGH_STEP_COPY_MAPPING[step];
    const hasBackButton = step !== steps.current[0];
    const stepLabel = steps.current.length > 1 ? 'regularBatchPayments.walkthrough.stepLabel' : '';
    const stepLabelValues = steps.current.length > 1 ? { some: stepIdx + 1, total: steps.current.length } : {};

    let ctaLabel;
    if (stepIdx < steps.current.length - 1) {
      ctaLabel = 'general.next';
    } else if (step === WalkthroughStep.COMBINE_BILLS) {
      ctaLabel = 'general.gotIt';
    } else {
      ctaLabel = 'general.done';
    }

    return {
      isOpen,
      title,
      message,
      stepLabel,
      stepLabelValues,
      ctaLabel,
      hasBackButton,
      onBackClick: handleBackClick,
      onNextClick: handleNextClick,
      onCloseClick: handleCloseClick,
    };
  };

  useEffect(() => {
    if (visibleStepIdx !== null) {
      const visibleStep = steps.current[visibleStepIdx];

      if (!isFirstSeen && visibleStep === WalkthroughStep.COMBINE_BILLS) {
        updateUserPreference({
          key: 'qbdtBatchBulkPaymentsWalkthroughStepOneSeen',
          value: true,
        });
      }

      if (
        !areRestSeen &&
        [WalkthroughStep.SELECT_PAYMENT_METHOD, WalkthroughStep.SET_DEDUCTION_DATE].includes(visibleStep)
      ) {
        updateUserPreference({
          key: 'qbdtBatchBulkPaymentsWalkthroughStepTwoSeen',
          value: true,
        });
      }
    }
  }, [visibleStepIdx, isFirstSeen, areRestSeen, updateUserPreference]);

  return { getPopoverProps };
};

export { useWalkthroughState };
