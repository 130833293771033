import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Flex } from '@melio/billpay-design-system';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  amount?: string;
  currency?: string;
};

const TotalAmount = ({ amount = '0', currency = 'USD' }: Props) => {
  const intl = useIntl();
  const formattedAmount = intl.formatNumber(Number(amount || 0), {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const [mainPart, fractionalPart] = formattedAmount.split('.');

  return (
    <Flex align="flex-start" color="ds.gray.100">
      <Box as="span" textStyle="ds.h5" className={FULL_STORY_MASK_RULE_CLASS}>
        {mainPart}
      </Box>
      <Box as="span" textStyle="ds.body3Semi" className={FULL_STORY_MASK_RULE_CLASS}>
        {fractionalPart}
      </Box>
    </Flex>
  );
};

export { TotalAmount };
