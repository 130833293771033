import React from 'react';
import styled from 'styled-components';
import { InlineSelectField } from 'src/ui/form/InlineSelectField';
import { InlineTextInputField } from 'src/ui/form/InlineTextInputField';
import { FormRow } from 'src/ui/form/FormElements';
import US_STATES from 'src/utils/us-states';

type Props = {
  submit: any;
  printNameLabel: string;
  model: any;
};

const NewCheckOrCardDeliveryMethodForm = ({ submit, printNameLabel, model }: Props) => (
  <FormContainer onSubmit={submit}>
    <RowContainer>
      <InlineTextInputField label={printNameLabel} model={model?.printName} required autoFocus />
    </RowContainer>
    <RowContainer>
      <InlineTextInputField label="vendors.addDeliveryMethodByLink.addressLine1" model={model?.addressLine1} required />
    </RowContainer>
    <RowContainer>
      <InlineTextInputField label="vendors.addDeliveryMethodByLink.addressLine2" model={model?.addressLine2} />
    </RowContainer>
    <RowContainer>
      <InlineTextInputField label="vendors.addDeliveryMethodByLink.city" model={model?.city} required />
      <InlineSelectField
        id="state"
        label="vendors.addDeliveryMethodByLink.state"
        options={US_STATES}
        model={model?.state}
        required
      />
    </RowContainer>
    <RowContainer>
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.zipCode"
        model={model?.zipCode}
        required
        type="tel"
      />
    </RowContainer>
    <button type="submit" hidden />
  </FormContainer>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem;
`;

const RowContainer = styled(FormRow)`
  margin-top: 2rem;
  align-items: flex-start;
`;

export default NewCheckOrCardDeliveryMethodForm;
