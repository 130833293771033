import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { CONSTS } from 'src/utils/consts';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import paymentStore from 'src/modules/payments/payment-store';
import { getJWTPayload } from 'src/helpers/jwt';
import analytics from 'src/services/analytics';
import locations from 'src/pages/vendor/edit-check-adress/locations';
import shiftToAchLocations from 'src/pages/vendor/shift-vendor-to-ach/locations';
import { PaymentType } from 'src/utils/types';

export type EditCheckAddressProps = {
  token: string;
  id?: string;
};

export type EditCheckAddressState = {
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
};

const tryShiftToAchFlag = 'shiftToAch';
export const paymentStatusPropsMap = {
  [CONSTS.PAYMENT_STATUS.COMPLETED]: {
    path: locations.checkDeposited,
    analyticProps: { flow: 'check-deposited' },
  },
  [CONSTS.PAYMENT_STATUS.IN_PROGRESS]: {
    path: locations.checkSent,
    analyticProps: { flow: 'in-progress' },
  },
  [CONSTS.PAYMENT_STATUS.SCHEDULED]: {
    path: locations.checkNotSentChangeAddress,
    analyticProps: { flow: 'payment-schedule' },
  },
  [CONSTS.PAYMENT_STATUS.BLOCKED]: {
    path: locations.checkBlocked,
    analyticProps: { flow: 'payment-blocked' },
  },
  fallback: {
    path: locations.invalidToken,
    analyticProps: {},
  },
};

const ANALYTICS_TAG_NAME = 'useEditCheckAddressRedirect';

export function useEditCheckAddressRedirect(props: EditCheckAddressProps) {
  const { token, id } = props;
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const isShiftToAch = searchParams.get(tryShiftToAchFlag) === 'true';
  const paymentActions = useStoreActions(paymentStore);
  const { paymentId } = getJWTPayload(token);

  useEffect(() => {
    if (token) {
      paymentActions
        .fetchEmailToVendorDetails({ token })
        .then((res) => {
          const payment = res.payload?.payment;
          const deliveryMethod = payment?.deliveryMethod;
          const paymentStatus = payment?.status;
          const organizationId = payment?.organization?.id;
          const vendorEmail = payment?.vendor?.contactEmail;

          const paymentStatusProps = paymentStatusPropsMap[paymentStatus] || paymentStatusPropsMap.fallback;

          const commonAnalyticProps = {
            ...analytics.getExtraProperties(),
            organizationId,
            paymentId,
            vendorId: id,
            vendorEmail,
            siteConfig: analytics.siteConfig,
            ...paymentStatusProps.analyticProps,
          };

          if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              ...commonAnalyticProps,
              flow: 'voided-check',
            });
            history.push(generatePath(locations.switchedToACH, { token, id }));
          } else if (paymentStatus === CONSTS.PAYMENT_STATUS.SCHEDULED && isShiftToAch) {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              ...commonAnalyticProps,
            });
            history.push(
              generatePath(shiftToAchLocations.index, {
                token,
                id: deliveryMethod.id,
              })
            );
          } else {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              ...commonAnalyticProps,
            });
            history.push(generatePath(paymentStatusProps.path, { token, id }));
          }
        })
        .catch((err) => {
          if (err?.error?.code === 'PMT17') {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              origin: 'check-cancelled',
            });
          }

          history.push(generatePath(paymentStatusPropsMap.fallback.path, { token, id }));
        });
    }

    return undefined;
  }, [token, history, id, paymentActions]);

  return undefined;
}
