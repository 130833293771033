import React from 'react';
import { useQueryString } from 'src/utils/hooks';
import { EntryErrorPage } from './EntryErrorPage';

export const QBDTAuthErrorPage = () => {
  const query = useQueryString();
  const errorMessage = query.error || '';

  return <EntryErrorPage errors={errorMessage as string} />;
};
