import React from 'react';
import { Box, Checkbox, Table } from '@melio/billpay-design-system';
import analytics from 'src/services/analytics';
import { DashboardOrderArrow } from '../columns/components/DashboardOrderArrow/DashboardOrderArrow';
import { getOrderParams } from '../utils';
import { DashboardListItemType } from '../../../types';
import { BaseTableFilters, ColumnItem, OrderParams } from '../types';

type Props = {
  selectionEnabled?: boolean;
  isAllSelected: boolean;
  isIntederminate: boolean;
  onAllCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filters: BaseTableFilters;
  onChangeOrder: (orderParams: OrderParams) => void;
  columns: ColumnItem<DashboardListItemType>[];
};

export const DashboardTableHeader = ({
  selectionEnabled,
  isAllSelected,
  isIntederminate,
  onAllCheckboxChange,
  filters,
  onChangeOrder,
  columns,
}: Props) => {
  const { orderBy, orderDirection } = filters;

  const onHeaderClick = ({ accessor, isOrderingDisabled }) => {
    if (isOrderingDisabled) {
      return;
    }

    const newOrderParams = getOrderParams({
      orderBy,
      orderDirection,
      accessor,
    });
    analytics.trackAction('sort-by', { sorting: newOrderParams });
    onChangeOrder(newOrderParams);
  };

  return (
    <Table.Header>
      <Table.Row>
        {selectionEnabled && (
          <Table.HeaderCell w="2rem">
            <Checkbox
              data-testid="data-table-header-checkbox"
              isChecked={isAllSelected}
              isIndeterminate={isIntederminate}
              onChange={onAllCheckboxChange}
            />
          </Table.HeaderCell>
        )}
        {columns.map(({ Header, width, isNumeric, isOrderingDisabled, accessor }) => (
          <Table.HeaderCell
            data-testid={`data-table-header-${accessor}`}
            key={accessor}
            onClick={() => onHeaderClick({ accessor, isOrderingDisabled })}
            isNumeric={isNumeric}
            w={width}
          >
            <Box
              __css={headerStyle({
                accessor,
                isOrderingDisabled,
                orderBy,
              })}
            >
              {Header}
              {isOrderingDisabled ? null : (
                <DashboardOrderArrow accessor={accessor} orderBy={orderBy} orderDirection={orderDirection} />
              )}
            </Box>
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
};

type HeaderStyleParams = {
  accessor: string;
  isOrderingDisabled?: boolean;
  orderBy?: string;
};

export const headerStyle = ({ accessor, isOrderingDisabled, orderBy }: HeaderStyleParams) => ({
  cursor: isOrderingDisabled ? 'inherit' : 'pointer',
  fontWeight: accessor === orderBy ? 'ds.semi' : 'inherit',
  color: accessor === orderBy ? 'black' : 'inherit',
  _hover: {
    color: isOrderingDisabled ? 'inherit' : 'ds.black',
    '.order-arrow': {
      color: 'ds.black',
    },
  },
});
