const batchBaseUrl = '/quickbooks-desktop/orgs/:orgId/bills/pay/batch';

const LOCATIONS = {
  base: batchBaseUrl,
  billsAlreadyScheduled: `${batchBaseUrl}/bills-already-scheduled`,
  batch: `${batchBaseUrl}`,
  batchSuccess: `${batchBaseUrl}/success`,
  batchCompleteLegalInfo: `${batchBaseUrl}/complete-legal-info`,
  batchCompleteComplianceInfo: `${batchBaseUrl}/complete-compliance-info`,
  batchError: `${batchBaseUrl}/error`,
  addDeliveryMethod: `${batchBaseUrl}/delivery-method`,
  addFundingSource: `${batchBaseUrl}/funding-source`,
  firstFundingSource: `${batchBaseUrl}/add-first-funding-source`,
};

export default LOCATIONS;
