import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import { BulkHeader } from '../header-bulk';

type BatchPageLayoutProps = {
  children: React.ReactNode;
};

const BatchPageLayout: React.FC<BatchPageLayoutProps> = ({ children }) => (
  <Flex h="100%" direction="column" boxSizing="border-box" alignItems="center" p="0">
    <BulkHeader />
    <Box px="ds.xl">{children}</Box>
  </Flex>
);

export { BatchPageLayout };
