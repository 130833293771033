import React from 'react';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { Flex, HStack, Icon, Text, VStack } from '@melio/billpay-design-system';
import { ReactComponent as InfoIllustration } from 'src/images/general/info-icon.svg';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as BankAccountIllustration } from 'src/images/general/bank-account-icon.svg';
import { ReactComponent as CalendarIllustration } from 'src/images/get-pro/calendar.svg';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryMethodType, OrganizationType, PaymentType, VirtualCardType } from 'src/utils/types';
import { VirtualCardPaymentState, useStateChangedAnalytics } from 'src/pages/vendor/virtual-card-details/hooks';
import { Divider } from '../../virtual-delivery-method/components/Divider';

type Props = {
  payment?: PaymentType;
  deliveryMethod?: DeliveryMethodType;
  organization?: OrganizationType;
  virtualCard?: VirtualCardType;
};

export const NewDeliveryMethodForPaymentPage = ({ payment, deliveryMethod, organization, virtualCard }: Props) => {
  const { deliveryEta } = payment || {};
  const payorName = organization?.companyName;
  useStateChangedAnalytics({
    payment,
    paymentState: VirtualCardPaymentState.NewDeliveryMethod,
    virtualCard,
  });

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" justify="center" align="start">
          <Icon as={InfoIllustration} w="ds.2xl" h="ds.2xl" mb="ds.xl" />
          <ContentTitle label="vendor.unilateral.virtualCardDetails.newDeliveryMethodForPayment.title" />
          <Text textStyle="ds.body2" mb="0" mt="ds.md" color="ds.gray.100">
            <MIFormattedText
              label="vendor.unilateral.virtualCardSuccess.text"
              values={{
                payorName,
              }}
            />
          </Text>
        </Flex>
        <Divider h="ds.xs" my="ds.2xl" />
        <VStack gap="ds.xl" w="100%" align="start">
          <Text color="ds.gray.200" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.title" />
          </Text>
          <HStack gap="ds.lg">
            <Icon as={BankAccountIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="payBillPaymentActivity.deliveryMethod.achLabel" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedText
                  label="vendor.unilateral.virtualCardDetails.newDeliveryMethodForPayment.vendorReceive.bankAccount"
                  values={{
                    digits: getAccountNumber4digits(deliveryMethod?.bankAccount) || '',
                  }}
                />
              </Text>
            </Flex>
          </HStack>
          <HStack gap="ds.lg">
            <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardDetails.virtualCardSuccess.vendorReceive.title" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedDate date={deliveryEta} />
              </Text>
              <Text color="ds.gray.300" textStyle="ds.body3" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.deliveryTimeHelperText" />
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
