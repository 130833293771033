import React, { useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react';
import errorTracking from 'src/utils/error-tracking';
import { initContextHeader } from 'src/services/api/api';
import { GlobalStyle } from 'src/theme/global-theme';
import { qbdtWindowsSite } from 'src/sites/qbdtWindowsSite';
import { qbdtMacSite } from 'src/sites/qbdtMacSite';
import { Site } from 'src/sites/site';
import { isQBDTMac } from 'src/billpay/qbdt/services/qbdt';

const SiteContext = React.createContext<Site>(null as any);

export function getSite() {
  if (isQBDTMac()) {
    return qbdtMacSite;
  }

  return qbdtWindowsSite;
}

function getImgPaths(includedPaths: Array<string>) {
  const requireContext = require.context('../images', true, /\.(png|jpe?g|svg|gif)$/);

  return requireContext
    .keys()
    .filter((filePath) => includedPaths.some((path) => filePath.includes(path)))
    .map(requireContext) as string[];
}

function preloadImages(paths) {
  const imgPaths = getImgPaths(paths);

  imgPaths.forEach((imgPath) => {
    const img = new Image();

    img.src = imgPath;
  });
}
type SiteProviderProps = {
  site: Site;
  children: React.ReactNode;
};

const setSiteContextName = (site) => {
  errorTracking.setSiteContext(site);
  initContextHeader(site);
};

export function SiteProvider({ site, children }: SiteProviderProps) {
  const firstRender = useRef<boolean>();
  if (!firstRender.current) {
    firstRender.current = true;
    setSiteContextName(getSite().name);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    preloadImages(site.preloadImages);
  }, [site]);

  return (
    <SiteContext.Provider value={site}>
      <ThemeProvider theme={site.theme}>
        <ChakraProvider resetCSS={false} theme={site.chakraTheme}>
          {children}
        </ChakraProvider>
        <GlobalStyle />
      </ThemeProvider>
    </SiteContext.Provider>
  );
}
export { SiteContext };
