import React from 'react';
import { Box, Flex, HStack, VStack, Tooltip } from '@melio/billpay-design-system';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { FeeDataType } from 'src/billpay/qbdt/pages/batch-pay/components/fees/hooks/useFeesParams';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type TotalFeesProps = {
  fees: Record<string, FeeDataType>;
};

type FeeItemProps = { fee: FeeDataType };

const FeeItem = ({ fee }: FeeItemProps) => (
  <Flex justify="flex-end" textStyle={fee.style} color="ds.gray.200">
    <HStack spacing="ds.sm">
      {fee.tooltip && (
        <Tooltip placement="left" label={<MIFormattedText label={fee.tooltip} />}>
          <Box as="i" className="icon-info-hollow-icon" fontSize="1.3rem" color="ds.gray.200" cursor="pointer" />
        </Tooltip>
      )}
      <Box>
        <MIFormattedText label={fee.labelBulk || fee.label} />
      </Box>
    </HStack>
    <Flex justify="flex-end" ml="ds.md" minW="7.3rem">
      {fee.plus && <Box as="span">+</Box>}
      <Box as="span" className={FULL_STORY_MASK_RULE_CLASS}>
        <MIFormattedCurrency value={fee.amount?.toFixed(2)} currency={CONSTS.CURRENCY_SIGN.USD} />
      </Box>
    </Flex>
  </Flex>
);

const TotalFees = ({ fees }: TotalFeesProps) => (
  <VStack align="flex-end">
    {fees.fast.amount > 0 && <FeeItem fee={fees.fast} />}
    {fees.cards.amount > 0 && <FeeItem fee={fees.cards} />}
    {fees.checks.amount > 0 && <FeeItem fee={fees.checks} />}
    {fees.ach.amount > 0 && <FeeItem fee={fees.ach} />}
    <FeeItem fee={fees.total} />
  </VStack>
);

export { TotalFees };
