import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedCurrency } from 'src/utils/formatting';

type SubAmountCellType = {
  amount: number;
};

export const SubAmountCell = ({ amount }: SubAmountCellType) => (
  <Box textStyle="ds.body3" isTruncated>
    <MIFormattedCurrency value={amount} />
  </Box>
);
