import { generatePath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import { getPartialBillId } from 'src/utils/bills';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';

export const useRetryPayment = () => {
  const location = useLocation();
  const [historyPush] = useHistoryWithOrgId();
  const { bill, payment, eventPage } = useViewPaymentContext();

  const orgId = useSelector(getOrgId);

  const onRetryPayment = () => {
    analytics.track(eventPage, 'retry-payment', {
      billId: bill.id,
      partialBillId: getPartialBillId(bill),
      paymentId: payment.id,
      fundingSourceId: payment.fundingSourceId,
    });

    const exitUrl = `${location.pathname}${location.search}`;

    historyPush({
      path: generatePath(locations.edit.funding, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      }),
      state: {
        exitUrl,
      },
    });
  };

  return {
    onRetryPayment,
  };
};
