import React from 'react';
import styled from 'styled-components';
import { isBefore, addDays } from 'date-fns';
import moment from './moment-business-days';
import { MIFormattedDate } from './formatting';

function isBusinessDay(date) {
  return moment(date).isBusinessDay();
}

function isSameDay(date) {
  return moment(date).isSame(moment(), 'day');
}

function isTomorrow(date) {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  return moment(date).isSame(moment(tomorrow), 'day');
}

// eslint-disable-next-line react/prop-types
function DeliveryDateFormat({ date, maxDate }) {
  if (!maxDate || moment(date).isSameOrAfter(moment(maxDate), 'day')) {
    return <MIFormattedDate date={date} />;
  }

  const toDate = moment(maxDate);
  let toDateValue: React.ReactElement | string = <MIFormattedDate date={toDate} />;
  let fromDateValue = <MIFormattedDate date={date} />;

  if (moment(date).isSame(toDate, 'year')) {
    fromDateValue = <MIFormattedDate year={undefined} date={date} />;
    if (moment(date).isSame(toDate, 'month')) {
      toDateValue = moment(toDate).format('D, YYYY');
    }
  }

  return (
    <>
      {fromDateValue}
      {' - '}
      {toDateValue}
    </>
  );
}

// eslint-disable-next-line react/prop-types
function DeliveryDate({ date, maxDate }) {
  const formattedDate = DeliveryDateFormat({ date, maxDate });

  return (
    <>
      <DeliveryDateContainer data-testid="delivery-date">{formattedDate}</DeliveryDateContainer>
    </>
  );
}

const DeliveryDateContainer = styled.span`
  white-space: nowrap;
  ${(props) => props.theme.text.fontType.regular};
`;

const getUTCDate = (date) => {
  if (!date) return undefined;

  const d = new Date(date);

  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
};

type Interval = {
  startDate: Date;
  endDate: Date;
};

const getIntervals = (initialDate: Date, intervalDays: number) => {
  let startDate = new Date(initialDate);
  const intervals: Interval[] = [];
  const now = new Date();
  while (isBefore(startDate, now)) {
    let endDate = addDays(startDate, intervalDays);
    endDate = isBefore(endDate, now) ? endDate : now;
    intervals.push({ startDate, endDate });
    startDate = endDate;
  }

  return intervals;
};

export { isBusinessDay, isSameDay, isTomorrow, DeliveryDate, DeliveryDateFormat, getUTCDate, getIntervals };
