import React from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { Badge, Tooltip } from '@melio/billpay-design-system';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { ScheduledBillDashboardStatusesEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import paymentStore from 'src/modules/payments/payment-store';
import { PaymentType } from 'src/utils/types';

type Props = {
  item: DashboardListItemType;
};

export const DashboardScheduledStatusCell = ({ item }: Props) => {
  const { statusInDashboard } = item.metadata;
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(item.paymentId));

  if (statusInDashboard === ScheduledBillDashboardStatusesEnum.PendingVendorDetails) {
    return (
      <Tooltip label={<MIFormattedText label="paymentDashboard.columns.scheduledStatus.unilateralTooltip" />}>
        <Badge variant="darkGray">
          <MIFormattedText label="paymentDashboard.columns.scheduledStatus.unilateral" />
        </Badge>
      </Tooltip>
    );
  }

  if (statusInDashboard === ScheduledBillDashboardStatusesEnum.BankUnverified) {
    return (
      <Tooltip
        label={
          <MIFormattedText
            label="paymentDashboard.columns.scheduledStatus.unverifiedBankAccountTooltip"
            values={{
              paymentScheduledDate: <MIFormattedDate date={payment?.scheduledDate} />,
            }}
          />
        }
      >
        <Badge variant="darkGray">
          <MIFormattedText label="paymentDashboard.columns.scheduledStatus.unverifiedBankAccount" />
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Badge variant="info">
      <MIFormattedText label="paymentDashboard.columns.scheduledStatus.scheduled" />
    </Badge>
  );
};
