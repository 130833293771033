import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Menu, Tooltip, VStack } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { Portal } from 'src/core/ds/portal';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import { BatchDateOption, BatchDateOptionMap } from 'src/billpay/qbdt/pages/batch-pay/consts';
import { useBatchSettings } from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchSettings';
import analytics from 'src/services/analytics';
import { MenuButtonContent } from './MenuButtonContent';
import { WalkthroughPopover, WalkthroughPopoverProps } from './WalkthroughPopover';
import { WalkthroughStep } from './hooks/useWalkthroughState';

type Props = {
  getPopoverProps: (step: WalkthroughStep) => WalkthroughPopoverProps;
};

const menuDateOptions: { label: string; value: BatchDateOption }[] = [
  {
    label: 'regularBatchPayments.paymentsSettings.setDeductionDate.sameDate',
    value: BatchDateOption.ALL_AT_ONCE,
  },
  {
    label: 'regularBatchPayments.paymentsSettings.setDeductionDate.arriveByDueDate',
    value: BatchDateOption.BY_DUE_DATE,
  },
];

const DeductionDateMenu = ({ getPopoverProps }: Props) => {
  const { isBillListLoading, billsList } = useBatchBillsList();
  const { batchSettings, changeBatchSettings } = useBatchSettings();

  const isMultipleDeductionDate = useSelector(regularBatchPaymentsStore.selectors.isMultipleDeductionDate);
  const isMultipleFundingSource = useSelector(regularBatchPaymentsStore.selectors.isMultipleFundingSource);

  const menuDateOptionDefault = menuDateOptions.find((option) => option.value === batchSettings.dateOption);

  const handleMenuOpen = () => {
    analytics.trackAction('regularBatchPayments.settings.deductionDateType.choose');
  };

  const handleDateOptionSelected = (dateOption: BatchDateOption) => {
    analytics.trackAction('regularBatchPayments.settings.deductionDateType.selected', {
      deductionDateType: BatchDateOptionMap[dateOption],
    });

    if (
      batchSettings.dateOption !== dateOption ||
      (dateOption === BatchDateOption.ALL_AT_ONCE && isMultipleDeductionDate)
    ) {
      const scheduledDate = dateOption === BatchDateOption.BY_DUE_DATE ? null : batchSettings.scheduledDate;

      changeBatchSettings({ dateOption, scheduledDate }, { refreshEffectiveDate: true, refreshBillsList: true });
    }
  };

  const isDisabled = isMultipleFundingSource || isBillListLoading || !billsList.length;
  const tooltipLabel = isMultipleFundingSource ? (
    <MIFormattedText label="regularBatchPayments.paymentsSettings.setDeductionDate.tooltip" />
  ) : null;
  const renderLabel = () =>
    isMultipleDeductionDate ? (
      <MIFormattedText label="regularBatchPayments.paymentsSettings.setDeductionDate.multipleDates" />
    ) : (
      <MIFormattedText label={menuDateOptionDefault?.label} />
    );

  return (
    <Tooltip label={tooltipLabel} placement="top" shouldWrapChildren>
      <VStack spacing="ds.sm" align="flex-start">
        <Box textStyle="ds.body2" color="ds.gray.200" noOfLines={1}>
          <MIFormattedText label="regularBatchPayments.paymentsSettings.setDeductionDate.message" />
        </Box>
        <Menu autoSelect={false} isLazy autoWidth size="sm" onOpen={handleMenuOpen}>
          {({ isOpen }) => (
            <>
              <WalkthroughPopover {...getPopoverProps(WalkthroughStep.SET_DEDUCTION_DATE)}>
                <Menu.MenuButton as={Box} pointerEvents={isDisabled ? 'none' : 'auto'}>
                  <MenuButtonContent isOpen={isOpen} isDisabled={isDisabled} renderLabel={renderLabel} />
                </Menu.MenuButton>
              </WalkthroughPopover>

              <Portal>
                <Menu.MenuList display={isOpen ? 'block' : 'none'} maxH="40vh" overflow="auto">
                  {menuDateOptions.map(({ value, label }) => (
                    <Menu.MenuItem
                      key={value}
                      bg={menuDateOptionDefault?.value === value ? '#EEEEEE' : 'ds.white'}
                      onClick={() => handleDateOptionSelected(value)}
                    >
                      <MIFormattedText label={label} />
                    </Menu.MenuItem>
                  ))}
                </Menu.MenuList>
              </Portal>
            </>
          )}
        </Menu>
      </VStack>
    </Tooltip>
  );
};

export { DeductionDateMenu };
