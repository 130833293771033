import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { DashboardTabStatusEnum } from '../consts';
import locations from '../locations';
import { RedirectQuery } from './useGetDashboardItemQueryParams';

export type RedirectParams = {
  highlightedItemIds?: string[];
  paymentId: string;
  redirectQuery?: RedirectQuery;
  state?: Record<string, unknown>;
};

export const useRedirectToDashboardUnpaidTab = () => {
  const history = useHistory();
  const orgId = useOrgId();

  const redirectToDashboardUnpaidTab = useCallback(() => {
    const dashboardBasePath = generatePath(locations.dashboard, {
      orgId,
    });
    history.push(`${dashboardBasePath}?status=${DashboardTabStatusEnum.Unpaid}`);
  }, [history, orgId]);

  return {
    redirectToDashboardUnpaidTab,
  };
};
