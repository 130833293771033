import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Flex, Icon, Link } from '@melio/billpay-design-system';
import { DeliveryMethodCardWrapper } from 'src/pages/vendor/virtual-delivery-method/components/DeliveryMethodCardWrapper';
import { DeliveryMethodCardContentWrapper } from 'src/pages/vendor/virtual-delivery-method/components/DeliveryMethodCardContentWrapper';
import { DeliveryMethodCardTitle } from 'src/pages/vendor/virtual-delivery-method/components/DeliveryMethodCardTitle';
import { DeliveryMethodSubTitle } from 'src/pages/vendor/virtual-delivery-method/components/DeliveryMethodCardSubTitle';
import { ReactComponent as RightArrowIcon } from 'src/images/general/right-arrow.svg';
import { ReactComponent as CardWithDollarIcon } from 'src/images/general/card-with-dollar-icon.svg';
import { ReactComponent as CardIcon } from 'src/images/general/card-icon.svg';
import { useBreak } from 'src/hoc';
import analytics from 'src/services/analytics';
import { PaymentType } from 'src/utils/types';
import { getCommonAnalyticsParams } from 'src/pages/vendor/shift-vendor-to-debit/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { FeeHint } from './FeeHint';
import locations from '../../locations';

type Props = {
  isEligibleToVirtualCard: boolean;
  token: string;
  payment?: PaymentType;
};

export const DeliveryOptions = ({ isEligibleToVirtualCard, token, payment }: Props) => {
  const { isDesktop } = useBreak();
  const history = useHistory();

  const handleDebitCardClick = () => {
    analytics.track(pushToDebitEventPage, 'DeliveryMethod-Chose', {
      ...getCommonAnalyticsParams(payment),
      deliveryMethodType: 'debit',
    });

    history.push(generatePath(locations.addDebitCard, { token }));
  };

  const handleVirtualCardClick = () => {
    analytics.track(pushToDebitEventPage, 'DeliveryMethod-Chose', {
      ...getCommonAnalyticsParams(payment),
      deliveryMethodType: 'virtual-card',
    });

    history.push(generatePath(locations.addVirtualCard, { token }));
  };

  const handleTellMeMoreAboutVirtualCardClick = () => {
    analytics.track(pushToDebitEventPage, 'MoreInformation-Learned', {
      ...getCommonAnalyticsParams(payment),
      infoType: 'virtual-card',
    });

    history.push(generatePath(locations.learnMoreAboutVirtualCard, { token }));
  };

  return (
    <Flex direction="column" gap="ds.md">
      <DeliveryMethodCardWrapper onClick={handleDebitCardClick} testId="vendor-push-to-debit-debit-card">
        <Icon as={CardWithDollarIcon} w="ds.2xl" h="ds.2xl" />
        <DeliveryMethodCardContentWrapper>
          <DeliveryMethodCardTitle label="vendor.pushToDebit.selectDeliveryMethod.deliveryOptions.debitCard.name" />
          <DeliveryMethodSubTitle label="vendor.pushToDebit.selectDeliveryMethod.deliveryOptions.debitCard.description" />
          <FeeHint label="vendor.pushToDebit.selectDeliveryMethod.deliveryOptions.debitCard.feeHint" />
        </DeliveryMethodCardContentWrapper>
        {isDesktop && (
          <Flex align="center" justify="center">
            <Icon as={RightArrowIcon} w="ds.xl" height="ds.xl" />
          </Flex>
        )}
      </DeliveryMethodCardWrapper>
      {isEligibleToVirtualCard && (
        <DeliveryMethodCardWrapper onClick={handleVirtualCardClick} testId="vendor-push-to-debit-virtual-card">
          <Icon as={CardIcon} w="ds.2xl" h="ds.2xl" />
          <DeliveryMethodCardContentWrapper>
            <DeliveryMethodCardTitle label="vendor.pushToDebit.selectDeliveryMethod.deliveryOptions.virtualCard.name" />
            <DeliveryMethodSubTitle
              label="vendor.pushToDebit.selectDeliveryMethod.deliveryOptions.virtualCard.description"
              values={{
                link: (...chunks) => (
                  <Box
                    as={Link}
                    onClick={handleTellMeMoreAboutVirtualCardClick}
                    data-testid="push-to-debit-virtual-card-learn-more"
                  >
                    {React.Children.toArray(chunks)}
                  </Box>
                ),
              }}
            />
            <FeeHint label="vendor.pushToDebit.selectDeliveryMethod.deliveryOptions.virtualCard.feeHint" />
          </DeliveryMethodCardContentWrapper>
          {isDesktop && (
            <Flex align="center" justify="center">
              <Icon as={RightArrowIcon} w="ds.xl" height="ds.xl" />
            </Flex>
          )}
        </DeliveryMethodCardWrapper>
      )}
    </Flex>
  );
};
