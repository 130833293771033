import React, { PropsWithChildren } from 'react';
import { featureFlags } from '@melio/shared-web';
import { Flex, Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryOptionType } from 'src/utils/types';
import { FeatureFlags } from 'src/utils/feature-flags';

type Props = {
  deliveryOptions: DeliveryOptionType[];
};

const DeliveryWrapper = ({ deliveryOptions, children }: PropsWithChildren<Props>) => {
  const [isUpdatedDeliveryBlock] = featureFlags.useFeature(FeatureFlags.DatePickerDeliverySpeedChanges, false);
  const isFastOptionAvailable = deliveryOptions && deliveryOptions.length > 1;

  let label = 'bills.pay.date.estimatedDelivery';
  if (isFastOptionAvailable && isUpdatedDeliveryBlock) {
    label = 'bills.pay.date.chooseArrival';
  } else if (isFastOptionAvailable) {
    label = 'bills.pay.date.deliverySpeed';
  }

  return (
    <Flex
      pt="ds.lg"
      pb="ds.xl"
      px="ds.xl"
      borderTop="1px solid"
      borderColor="ds.gray.600"
      flexDir="column"
      align="flex-start"
    >
      <Box textStyle="ds.body2Medium" color="ds.gray.200">
        <MIFormattedText label={label} />
      </Box>
      {children}
    </Flex>
  );
};

export { DeliveryWrapper };
