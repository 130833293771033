import { persistReducer, createTransform } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import unionWith from 'lodash/unionWith';

import { CLEAR_STATE } from 'src/redux/user/actionTypes';
import { PayBillWizardState } from './types';
import {
  BEGIN_REGULAR_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
  SELECT_FUNDING_SOURCE,
  SELECT_PAYMENT_DATES,
  UPDATE_PAYMENT_MEMO,
  SELECT_DELIVERY_METHOD,
  ADD_NEW_DELIVERY_METHOD,
  UPDATE_BILL,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  END_PAY_BILL_FLOW,
  SET_URL_TO_BACK,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_ERROR,
  UPDATE_PAYMENT,
  FETCH_BILL,
  FETCH_BILL_SUCCESS,
  FETCH_BILL_FAILED,
  RESET_ERROR,
  SET_FEE,
  RETRY_FAILED_PAYMENT_SUCCESS,
  RETRY_FAILED_PAYMENT_ERROR,
  RETRY_FAILED_PAYMENT,
  SET_FAQ_PANEL,
  SET_COMPLIANCE_LIMITATIONS,
} from './actionTypes';
import { BillRecord } from '../../pages/bill/records';
import { PaymentRecord } from '../../pages/payment/records';

export const initialState: PayBillWizardState = {
  isLoading: false,
  bill: BillRecord(),
  payment: PaymentRecord(),
  errorCode: null,
  urlToBack: '',
  redirectUrl: null,
  exitUrl: null,
  fee: undefined,
  faqPanel: {
    isOpen: false,
    focusedQuestion: undefined,
  },
};

const recordsTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState, key) => {
    if (key === 'bill') {
      return BillRecord(outboundState as any);
    }

    if (key === 'payment') {
      return PaymentRecord(outboundState as any);
    }

    return outboundState;
  }
);

const persistConfig = {
  key: 'payBillWizard',
  storage: storageSession,
  transforms: [recordsTransform],
};

const payBillWizardReducer = (state: PayBillWizardState = initialState, action) => {
  switch (action.type) {
    case BEGIN_REGULAR_PAY_BILL_FLOW:
      return {
        ...state,
        isLoading: true,
        redirectUrl: action.redirectUrl,
        exitUrl: action.exitUrl,
      };
    case BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS:
      return {
        ...state,
        bill: action.bill,
        payment: action.payment,
        isLoading: false,
      };
    case BEGIN_REGULAR_PAY_BILL_FLOW_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SELECT_FUNDING_SOURCE:
      return {
        ...state,
        payment: state.payment.merge({
          fundingSourceId: action.id,
        }),
      };
    case SELECT_PAYMENT_DATES:
      return {
        ...state,
        payment: state.payment.merge({
          scheduledDate: action.scheduledDate,
          deliveryEta: action.deliveryEta,
          maxDeliveryEta: action.maxDeliveryEta,
          deliveryPreference: action.deliveryPreference,
        }),
      };
    case ADD_NEW_DELIVERY_METHOD:
      return {
        ...state,
        bill: state.bill.merge({
          vendor: {
            ...state.bill.vendor,
            deliveryMethods: unionWith(
              [action.deliveryMethod],
              state.bill.vendor?.deliveryMethods || [],
              (a, b) => a.id === b.id
            ),
          },
        }),
      };
    case SELECT_DELIVERY_METHOD:
      return {
        ...state,
        payment: state.payment.merge({
          deliveryMethodId: action.deliveryMethod?.id,
          deliveryMethod: action.deliveryMethod,
        }),
      };
    case UPDATE_PAYMENT_MEMO:
      return {
        ...state,
        payment: state.payment.merge({ note: action.memo }),
      };
    case UPDATE_BILL:
      return {
        ...state,
        bill: state.bill.merge({ goodsReceived: action.goodsReceived }),
      };
    case FETCH_BILL:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BILL_SUCCESS:
      return {
        ...state,
        bill: action.bill,
        isLoading: false,
      };
    case FETCH_BILL_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        isLoading: false,
      };
    case CREATE_PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.errorCode,
      };

    case UPDATE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };
    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        isLoading: false,
      };
    case UPDATE_PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.errorCode,
      };

    case RETRY_FAILED_PAYMENT:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };
    case RETRY_FAILED_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        isLoading: false,
      };
    case RETRY_FAILED_PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.errorCode,
      };

    case END_PAY_BILL_FLOW:
      return { ...initialState };
    case SET_URL_TO_BACK:
      return {
        ...state,
        urlToBack: action.urlToBack,
      };
    case RESET_ERROR:
      return {
        ...state,
        errorCode: null,
      };
    case SET_FEE:
      return {
        ...state,
        fee: action.payload,
      };
    case SET_FAQ_PANEL:
      return {
        ...state,
        faqPanel: action.faqPanel,
      };
    case CLEAR_STATE:
      return {
        ...initialState,
      };
    case SET_COMPLIANCE_LIMITATIONS:
      return {
        ...state,
        complianceLimitations: action.payload,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, payBillWizardReducer);
