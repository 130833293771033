import React from 'react';
import styled from 'styled-components';

type Props = {
  onEdit?: (() => void) | null;
  testId?: string;
};

export const PaymentFieldEdit = ({ onEdit, testId }: Props) => {
  if (!onEdit) {
    return null;
  }

  return <EditLink data-testid={testId} onClick={onEdit} />;
};

// TODO: replace with Chakra UI
const EditLink = styled.i.attrs({ className: 'icon-edit-icon' })`
  font-size: 2.4rem;
  color: #8d9096;
  margin-right: 0.4rem;
  cursor: pointer;
`;
