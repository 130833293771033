import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Menu, VStack } from '@melio/billpay-design-system';
import { Portal } from 'src/core/ds/portal';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { getOrgId } from 'src/redux/user/selectors';
import batchPaymentsLocations from 'src/billpay/qbdt/pages/batch-pay/locations';
import { useBatchSettings } from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchSettings';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import useGetValidFundingSources from 'src/billpay/qbdt/pages/batch-pay/table/hooks/useGetValidFundingSources';
import FundingSourceIcon from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/FundingSourceIcon';
import FundingSourceMenuItem from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/FundingSourceMenuItem';
import { AddFundingSourceMenuItem } from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/AddFundingSourceMenuItem';
import { MIFormattedText } from 'src/utils/formatting';
import {
  getPaymentMethodIcon,
  getPaymentMethodLabel,
} from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/utils';
import { AccountType } from 'src/utils/types';
import analytics from 'src/services/analytics';
import { CONSTS } from 'src/utils/consts';
import { MenuButtonContent } from './MenuButtonContent';
import { WalkthroughPopover, WalkthroughPopoverProps } from './WalkthroughPopover';
import { WalkthroughStep } from './hooks/useWalkthroughState';

type Props = {
  getPopoverProps: (step: WalkthroughStep) => WalkthroughPopoverProps;
};

const SelectPaymentMethod = ({ getPopoverProps }: Props) => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);

  const { isBillListLoading, billsList } = useBatchBillsList();
  const { batchSettings, changeBatchSettings } = useBatchSettings();

  const [filteredFundingSources] = useGetValidFundingSources();
  const fundingSourceDefault = filteredFundingSources.find((fs) => fs.id === batchSettings.fundingSourceId);

  const isMultipleFundingSource = useSelector(regularBatchPaymentsStore.selectors.isMultipleFundingSource);

  const handleMenuOpen = () => {
    analytics.trackAction('regularBatchPayments.settings.paymentMethod.choose');
  };

  const handleFundingSourceSelected = (fundingSourceId: number) => {
    const fundingSource = filteredFundingSources.find((fs) => fs.id === fundingSourceId);

    const fundingType = fundingSource?.fundingType;
    const cardAccount = fundingSource?.cardAccount;

    analytics.trackAction('regularBatchPayments.settings.paymentMethod.selected', {
      fundingSourceId,
      fundingSourceType: fundingType === CONSTS.FUNDING_TYPE.ACH ? fundingType : cardAccount?.cardType,
    });

    if (fundingSourceId !== fundingSourceDefault?.id || isMultipleFundingSource) {
      changeBatchSettings({ fundingSourceId }, { refreshEffectiveDate: true, refreshBillsList: true });
    }
  };

  const handleAddFundingSourceClick = () => {
    analytics.trackAction('regularBatchPayments.settings.paymentMethod.addNew');

    const batchPaymentsUrl = generatePath(batchPaymentsLocations.batch, {
      orgId,
    });
    const addFundingSourceUrl = generatePath(batchPaymentsLocations.addFundingSource, { orgId });

    history.push(addFundingSourceUrl, {
      redirectUrl: batchPaymentsUrl,
      applyToAllBills: true,
    });
  };

  const { icon, imageSrc } = getPaymentMethodIcon(fundingSourceDefault || ({} as AccountType));
  const { label, values, nickname } = getPaymentMethodLabel(fundingSourceDefault || ({} as AccountType));

  const isDisabled = isBillListLoading || !billsList.length;
  const renderIcon = isMultipleFundingSource
    ? undefined
    : () => <FundingSourceIcon imageSrc={imageSrc} icon={icon} size="2.4rem" />;
  const renderLabel = () =>
    isMultipleFundingSource ? (
      <MIFormattedText label="regularBatchPayments.paymentsSettings.selectPaymentMethod.multipleMethods" />
    ) : (
      nickname || <MIFormattedText label={label} values={values} />
    );

  return (
    <VStack spacing="ds.sm" align="flex-start" minH="6rem">
      <Box textStyle="ds.body2" color="ds.gray.200" noOfLines={1}>
        <MIFormattedText label="regularBatchPayments.paymentsSettings.selectPaymentMethod.message" />
      </Box>
      {batchSettings.fundingSourceId && (
        <Menu autoSelect={false} isLazy autoWidth size="sm" onOpen={handleMenuOpen}>
          {({ isOpen }) => (
            <>
              <WalkthroughPopover {...getPopoverProps(WalkthroughStep.SELECT_PAYMENT_METHOD)}>
                <Menu.MenuButton as={Box} pointerEvents={isDisabled ? 'none' : 'auto'}>
                  <MenuButtonContent
                    isOpen={isOpen}
                    isDisabled={isDisabled}
                    renderIcon={renderIcon}
                    renderLabel={renderLabel}
                  />
                </Menu.MenuButton>
              </WalkthroughPopover>

              <Portal>
                <Menu.MenuList display={isOpen ? 'block' : 'none'} maxH="40vh" overflow="auto">
                  {filteredFundingSources.map((fundingSource) => (
                    <FundingSourceMenuItem
                      key={fundingSource.id}
                      fundingSource={fundingSource}
                      onClick={handleFundingSourceSelected}
                      isSelected={!isMultipleFundingSource && batchSettings.fundingSourceId === fundingSource.id}
                    />
                  ))}
                  <AddFundingSourceMenuItem onClick={handleAddFundingSourceClick} />
                </Menu.MenuList>
              </Portal>
            </>
          )}
        </Menu>
      )}
    </VStack>
  );
};

export { SelectPaymentMethod };
