import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import QBOLink from 'src/theme/ds/link';
import { setFaqPanel } from 'src/redux/payBillWizard/actions';
import profileStore from 'src/modules/profile/profile-store';
import analytics from 'src/services/analytics';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { ModalContentContainer } from 'src/components/common/ModalMessage/ModalMessage.styles';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';
import { useApi } from 'src/hoc/useApi';
import userApi from 'src/services/api/user';
import organizationApi from 'src/services/api/organizations';
import { pushNotification } from 'src/services/notifications/notificationService';
import { validateMultipleFields } from 'src/utils/form-utils';
import vendorsApi from 'src/services/api/vendors';
import MIButton from 'src/components/common/MIButton';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { mapAllowedMCCsToOptions } from './utils';
import { BATCH_PAGE_EVENT } from '../../pages/batch-pay/analytics/event-mapping';

const FALLBACK_OPTION_VALUE = '-1';

export type CodeChangeProps = {
  vendorId: number;
  mccCode: string;
};

type Props = {
  orgId: number;
  billIds: number[];
  vendorId: number;
  vendorName: string;
  analyticsProps: Record<string, any>;
  isBatch?: boolean;
  onSubmit?: () => void;
  onClose: (isBatch?: boolean) => void;
  onCodeChange: (props: CodeChangeProps) => void;
};

const Modal = ({
  orgId,
  billIds,
  vendorId,
  vendorName,
  analyticsProps,
  isBatch,
  onSubmit,
  onClose,
  onCodeChange,
}: Props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(profileStore.selectors.profile);

  const [industries, setIndustries] = useState([]);
  const [subIndustries, setSubIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});

  const [getAllowedMCCList, , isMCCListLoading] = useApi(organizationApi.getAllowedMCCList);
  const [updateVendorMCCCode, , isVendorMCCCodeUpdating] = useApi(vendorsApi.updateVendorMCCCode);

  useEffect(() => {
    loadAllowedMCCs();
    trackAmexModalOpenBackendEvent();

    const eventPage = isBatch ? BATCH_PAGE_EVENT : 'pay-bill';
    analytics.track(eventPage, 'amex-industry-modal_open', analyticsProps);
  }, []);

  const loadAllowedMCCs = async () => {
    try {
      const { list: allowedMCCs } = await getAllowedMCCList(orgId, {
        cardNetwork: 'amex',
      });
      const { mccIndustries, mccSubIndustries } = mapAllowedMCCsToOptions(allowedMCCs);

      setIndustries(mccIndustries);
      setSubIndustries(mccSubIndustries);
    } catch (error) {
      // error while loading MCCs list;
    }
  };

  const trackAmexModalOpenBackendEvent = () => {
    try {
      billIds.forEach((billId) => {
        userApi.trackEvent(currentUser.id, 'analytics-mcc-restriction-on-billpay-scheduleBill', {
          table: 'bill',
          id: billId.toString(),
          key: 'batch',
          value: !!isBatch,
        });
      });
    } catch (error) {
      // error while sending backend analytics;
    }
  };

  const handleSubmit = async () => {
    const errors = validateForm({
      industry: selectedIndustry,
      code: selectedCode,
    });

    setValidationErrors(errors);

    if (!isEmpty(errors)) {
      analytics.track('pay-bill', 'amex-industry-modal_click-error', {
        errors,
        selectedCategory: selectedIndustry,
        selectedSubCategory: selectedCode,
        ...analyticsProps,
      });

      return;
    }

    analytics.track('pay-bill', 'amex-industry-modal_click-save', {
      selectedCategory: selectedIndustry,
      selectedSubCategory: selectedCode,
      ...analyticsProps,
    });

    handleCodeChange(selectedCode);
  };

  const handleCodeChange = async (mccCode: string) => {
    try {
      await updateVendorMCCCode({ orgId, vendorId, mccCode });

      if (onCodeChange) {
        onCodeChange({ vendorId, mccCode });
      }

      if (onSubmit) {
        onSubmit();
      }

      setTimeout(() => {
        pushNotification({
          type: CONSTS.NOTIFICATION_VARIANT.SUCCESS,
          msg: 'amexVerification.toast',
          textValues: { vendorName },
        });
      });

      onClose(isBatch);
    } catch (error) {
      // error while updating vendor's MCC
    }
  };

  const handleIndustryChange = ({ value: industry }) => {
    const code = '';
    const errors = validateForm({ industry, code });

    setSelectedCode(code);
    setSelectedIndustry(industry);
    setValidationErrors(errors);
  };

  const handleSubIndustryChange = ({ value: code }) => {
    const errors = validateForm({ code, industry: selectedIndustry });

    setSelectedCode(code);
    setValidationErrors(errors);

    analytics.track('pay-bill', 'amex-industry-modal_select-category', {
      selectedCategory: selectedIndustry,
      selectedSubCategory: selectedCode,
      ...analyticsProps,
    });
  };

  const handleLearnMoreClick = () => dispatch(setFaqPanel({ isOpen: true, focusedQuestion: 17 }));

  const validateForm = ({ industry, code }: { industry?: string; code?: string } = {}) =>
    validateMultipleFields(
      [
        { name: 'industry', value: industry, isRequired: true },
        {
          name: 'code',
          value: code,
          customCheck: () => {
            if (industry && !code) {
              return 'amexVerification.modal.form.code.empty';
            }

            return false;
          },
        },
      ],
      'amexVerification.modal.form'
    );

  const subIndustryOptions = selectedIndustry ? [...subIndustries[selectedIndustry]] : [];

  subIndustryOptions.push({
    value: FALLBACK_OPTION_VALUE,
    label: 'amexVerification.modal.form.fallbackOption',
  });

  const renderCloseLink = (...chunks) => (
    <QBOLink
      onClick={() => {
        onClose(isBatch);
      }}
      textDecoration="none !important"
      cursor="pointer"
      color="#0077C5"
      fontWeight="500"
    >
      {React.Children.toArray(chunks)}
    </QBOLink>
  );

  const renderEmptyState = (
    <EmptyStateContainer>
      <MIFormattedText
        label="amexVerification.modal.form.emptyState.message"
        values={{
          link: renderCloseLink,
        }}
      />
    </EmptyStateContainer>
  );

  const renderOption = ({ children, ...props }) => {
    const { data, innerRef, innerProps } = props;

    if (data.value === FALLBACK_OPTION_VALUE) {
      const modifiedProps = { ...innerProps, onClick: () => null };

      return (
        <CustomOptionContainer ref={innerRef} {...modifiedProps}>
          <MIFormattedText
            label={data.label}
            values={{
              link: renderCloseLink,
            }}
          />
        </CustomOptionContainer>
      );
    }

    return components.Option && <components.Option {...(props as any)}>{children}</components.Option>;
  };

  const renderForm = (
    <div>
      <MISingleSelect
        id="industry"
        value={selectedIndustry}
        label="amexVerification.modal.form.industry.label"
        placeholder="amexVerification.modal.form.industry.placeholder"
        errorMessage={validationErrors.industry}
        onChange={handleIndustryChange}
        options={industries}
        menuPosition="fixed"
        isProcessing={isMCCListLoading}
        isDisabled={isMCCListLoading}
        isSearchable={false}
        required
        noOptionsComponent={renderEmptyState}
        overrideStyles={singleSelectStyles}
      />
      <MISingleSelect
        id="code"
        value={selectedCode}
        label="amexVerification.modal.form.code.label"
        placeholder="amexVerification.modal.form.code.placeholder"
        errorMessage={validationErrors.code}
        onChange={handleSubIndustryChange}
        options={subIndustryOptions}
        menuPosition="fixed"
        isDisabled={!selectedIndustry}
        isSearchable={false}
        required
        components={{ Option: renderOption }}
        overrideStyles={singleSelectStyles}
      />
    </div>
  );

  return (
    <StyledModal
      alignLeft
      titleComponent={<MIFormattedText label="amexVerification.modal.title" values={{ vendorName }} />}
      contentSubTitle={
        <>
          <MIFormattedText label="amexVerification.modal.description" />
          <LearnMoreLink onClick={handleLearnMoreClick}>
            <MIFormattedText label="amexVerification.modal.learnMore" />
          </LearnMoreLink>
        </>
      }
      buttonComponent={
        <ButtonsContainer>
          <MIButton
            label={isBatch ? 'amexVerification.modal.batchSubmit' : 'amexVerification.modal.submit'}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            isProcessing={isVendorMCCCodeUpdating}
            onClick={handleSubmit}
          />
        </ButtonsContainer>
      }
      contentComponent={renderForm}
      onCloseClick={() => {
        onClose(isBatch);
      }}
    />
  );
};

export { Modal };

const singleSelectStyles = {
  menuList: {
    padding: '0',
  },
};

const StyledModal = styled(ModalMessage)`
  ${ModalContentContainer} {
    padding: 4rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LearnMoreLink = styled.span`
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
  ${(props) => props.theme.text.fontType.medium};
  margin-left: 0.5rem;
  font-size: 1.6rem;
`;

const EmptyStateContainer = styled.div`
  padding: 4rem 0;
  color: #8d9096;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const CustomOptionContainer = styled.div`
  box-sizing: border-box;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: normal
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: default;
  color: #393A3D;
  border-top: 1px solid #E9E9E9;
`;
