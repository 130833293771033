import { StatusInDashboardType } from 'src/billpay/qbdt/pages/dashboard/types';
import { ActionEventPropertiesType } from '../types';

type Params = {
  billId: string;
  paymentId?: string;
  status: StatusInDashboardType;
};

type Response = {
  eventProperties: ActionEventPropertiesType;
};

export const useActionCellEventProperties = ({ billId, paymentId, status }: Params): Response => ({
  eventProperties: {
    billId,
    paymentId,
    status,
    isPartial: false,
  },
});
