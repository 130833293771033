import React from 'react';
import { RecordOf } from 'immutable';
import { CONSTS } from 'src/utils/consts';
import { AccountType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { PaymentInfoSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSection';
import { PaymentInfoSectionDivider } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSectionDivider';
import { ConfirmPaymentDeliveryDateInfo } from '../common-fields/ConfirmPaymentDeliveryDateInfo/ConfirmPaymentDeliveryDateInfo';
import { ConfirmPaymentDeliveryMethodInfo } from './fields/ConfirmPaymentDeliveryMethodInfo';
import { ActivityActionsType } from '../../types';

type Props = {
  bill: RecordOf<BillType>;
  fundingSource: RecordOf<AccountType>;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
} & Pick<ActivityActionsType, 'onEditDeliveryMethod'>;

export const ConfirmVendorSection = ({ bill, onEditDeliveryMethod, fundingSource, payment, deliveryMethod }: Props) => {
  const isVirtualDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;

  return (
    <PaymentInfoSection
      label="payBillPaymentActivity.receiveTitle"
      testId="billpay-view-title-container"
      contentTestId="billpay-view-activity-container"
    >
      {!isVirtualDeliveryMethod && (
        <>
          <ConfirmPaymentDeliveryMethodInfo
            deliveryMethod={deliveryMethod}
            onEdit={onEditDeliveryMethod}
            bill={bill}
            payment={payment}
          />
          <PaymentInfoSectionDivider />
        </>
      )}
      <ConfirmPaymentDeliveryDateInfo
        bill={bill}
        payment={payment}
        deliveryMethod={deliveryMethod}
        fundingSource={fundingSource}
        onEdit={
          isVirtualDeliveryMethod && payment?.status !== CONSTS.PAYMENT_STATUS.FAILED ? onEditDeliveryMethod : null
        }
      />
    </PaymentInfoSection>
  );
};
