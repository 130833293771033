import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import QBDTJoinService from 'src/pages/vendor/components/QBDTJoinService';
import styled from 'styled-components';
import AreaLoader from 'src/components/common/AreaLoader';
import doneImage from 'src/images/qbo/check-circle.svg';
import qbLogo from 'src/images/accounting-software/quickbooks-logo-horz.png';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import paymentStore from 'src/modules/payments/payment-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import { UnilateralPaymentProps } from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { devices } from 'src/theme/AppDevices';
import { getJWTPayload } from 'src/helpers/jwt';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import vendorsLocations from 'src/pages/vendor/locations';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { CONSTS } from 'src/utils/consts';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { PaymentType } from 'src/utils/types';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';
import { useInvoiceNumber } from '../../hooks/useInvoiceNumber';
import { isBulkPayment } from '../../utils';

type Props = UnilateralPaymentProps;

const getSubtitle = ({ payment, invoiceNumber }: { payment?: PaymentType; invoiceNumber?: string }) => {
  let subtitleLabelSuffix = invoiceNumber ? 'regular' : 'withoutInvoice';
  if (isBulkPayment(payment)) {
    subtitleLabelSuffix = 'bulkPayment';
  }

  return `vendors.addDeliveryMethodByLink.success.subtitle.${subtitleLabelSuffix}`;
};

export const QBOAddDeliveryMethodSuccessPage = (props: Props) => {
  const history = useHistory();
  const { token } = props;
  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentStore.selectors.byId(paymentId));
  const { invoiceNumber } = useInvoiceNumber(payment);
  const paymentActions = useStoreActions(paymentStore);
  useEffect(() => {
    if (!payment) {
      paymentActions.fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' }).catch(() => {
        history.push(generatePath(vendorsLocations.unilateral.invalidToken, { token }));
      });
    }

    if (payment) {
      vendorUnilateralAnalytics.trackSuccessPageLoaded({
        paymentId: payment.id,
        vendorId: payment.vendorId,
        vendorEmail: payment?.vendor?.contactEmail,
        isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
      });
    }
  }, [paymentActions, token, history, payment]);
  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id));
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  const utmCampaign = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH ? 'unilateral_ach' : 'unilateral_check';
  const subtitle = getSubtitle({ payment, invoiceNumber });

  return (
    <QBOLayoutPage contentWrapperMode="success" innerSize={60}>
      <QBLogo src={qbLogo} />
      <Content>
        <Header>
          <DoneIcon src={doneImage} />
          <Title testId="success-title">
            <MIFormattedText label="vendors.addDeliveryMethodByLink.success.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label={subtitle}
              values={{
                totalAmount: <MIFormattedCurrency value={payment?.amount} />,
                companyName: organization?.companyName,
                invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </Header>
        <Separator />
        <QBDTJoinService labelPrefix="vendors.addDeliveryMethodByLink.success.joinService" utmCampaign={utmCampaign} />
      </Content>
    </QBOLayoutPage>
  );
};

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.darkGrey};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white.opaque};
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: 2rem;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.text.color.main};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  font-size: ${({ theme }) => theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.text.color.main};
  ${({ theme }) => theme.text.fontType.medium};
  margin-bottom: 4rem;
`;

const Header = styled.div`
  padding-bottom: 4.4rem;
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.white.lightBlue};
  height: 0.4rem;
  margin: 0 -0.5rem 4rem -0.5rem;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-right: 0;
    margin-left: 0;
  }
`;
