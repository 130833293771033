import api from 'src/services/api/bills';
import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';
import { name } from './consts';

type Params = {
  orgId: string;
  id: string;
  isPaid: boolean;
  intuitAccountId?: string;
  actionType: 'paid' | 'unpaid';
  createOrigin?: PAYMENT_CREATE_FLOW_ORIGIN;
};

const markAsPaidApi = (params: Params) =>
  api
    .markAsPaid({
      orgId: params.orgId,
      id: params.id,
      isPaid: params.isPaid,
      intuitAccountId: params.intuitAccountId,
      createOrigin: params.createOrigin,
    })
    .then((response) => response.object);

const markAsPaidSlice = createUpdateSlice({
  storeName: name,
  api: markAsPaidApi,
  actionName: 'MARK_AS_PAID',
});

export default markAsPaidSlice;
