import { Flex } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';

export const PaymentFieldInfo = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    flex: 1,
    width: 'full',
  },
});
