import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import { ReactComponent as ChevronUpIcon } from 'src/images/regular-batch-payments/menu-arrow-up.svg';
import { ReactComponent as ChevronDownIcon } from 'src/images/regular-batch-payments/menu-arrow-down.svg';

type Props = {
  isOpen: boolean;
  isDisabled: boolean;
  renderLabel: () => React.ReactNode;
  renderIcon?: () => React.ReactNode;
};

const MenuButtonContent = ({ isOpen, isDisabled, renderIcon, renderLabel }: Props) => (
  <Flex
    color="ds.gray.100"
    pb="ds.sm"
    borderBottom="1px"
    borderColor="ds.gray.400"
    cursor={isDisabled ? 'auto' : 'pointer'}
    opacity={isDisabled ? 0.4 : 1}
  >
    {renderIcon && (
      <Box mr="ds.sm" w="2.4rem" h="2.4rem">
        {renderIcon()}
      </Box>
    )}
    <Box mr="ds.sm" textStyle="ds.body1" fontWeight="500" noOfLines={1}>
      {renderLabel()}
    </Box>
    <Flex w="2.4rem" h="2.4rem" align="center" justify="center">
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </Flex>
  </Flex>
);

export { MenuButtonContent };
