import { postRequest } from 'src/services/api/api';
import { QBDTAccount, QBDTVendor } from 'src/billpay/qbdt/services/qbdt/types';
import { createBill, sampleBillFromVendor } from 'src/billpay/qbdt/services/qbdt/entities/bill';
import { getAccount, getAPAccount, getFeeExpenseAccount } from 'src/billpay/qbdt/services/qbdt/entities/account';
import { syncMelioVendor } from './sync-vendor';
import { syncPayment } from './sync-payment';

async function findAPAccountForFeeBill(
  qbdtVendor: QBDTVendor
): Promise<{
  qbdtAPAccount: QBDTAccount;
  qbdtExpenseAccount: QBDTAccount;
}> {
  const qbdtBill = await sampleBillFromVendor(qbdtVendor);

  let qbdtAPAccount;
  if (qbdtBill && qbdtBill.APAccountRef?.ListID) {
    qbdtAPAccount = await getAccount(qbdtBill.APAccountRef?.ListID);
  } else {
    qbdtAPAccount = await getAPAccount();
  }

  let qbdtExpenseAccount;
  if (qbdtBill && qbdtBill?.ExpenseLineRet?.[0]?.AccountRef?.ListID) {
    qbdtExpenseAccount = await getAccount(qbdtBill.ExpenseLineRet[0].AccountRef.ListID);
  } else {
    qbdtExpenseAccount = await getFeeExpenseAccount();
  }

  return {
    qbdtAPAccount,
    qbdtExpenseAccount,
  };
}

export async function syncInternalMelioPayment(orgId, melioPayment) {
  const melioBill = melioPayment?.bill;
  if (!melioBill?.internalBill) {
    throw Error(`Payment is not related to internal bill. PaymentId: ${melioPayment.id}`);
  }

  const qbdtVendor = await syncMelioVendor(orgId, melioPayment.vendor, melioBill.internalBill);

  const { qbdtAPAccount, qbdtExpenseAccount } = await findAPAccountForFeeBill(qbdtVendor);
  const qbdtBill = await createBill(melioBill, qbdtVendor, qbdtAPAccount, qbdtExpenseAccount);
  await postRequest(`/orgs/${orgId}/qbdt/bill/${melioBill.id}/external-reference/${qbdtBill.TxnID}`);

  await syncPayment({ orgId, paymentId: melioPayment.id });
}
