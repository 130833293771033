import { useEffect } from 'react';

export const useOnEnterPressed = ({ onEnterPressed }) => {
  useEffect(() => {
    const keyPressEventListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onEnterPressed();
      }
    };

    window.addEventListener('keypress', keyPressEventListener);

    return () => {
      window.removeEventListener('keypress', keyPressEventListener);
    };
  }, [onEnterPressed]);
};
