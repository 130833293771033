import { useIntl } from 'react-intl';
import { CardPreviewProps } from 'src/pages/vendor/virtual-card-details/types';

export const formatCardNumber = (number: string) =>
  number
    .replace(/\W/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .trimEnd();

export const useCardPreviewData = ({ amount, cardDetails }: CardPreviewProps) => {
  const intl = useIntl();
  const formattedAmount = intl.formatNumber(amount, {
    style: 'currency',
    currency: 'USD',
  });

  const [wholeAmountPart, decimalAmountPart] = formattedAmount.split('.');
  const formattedCardNumber = formatCardNumber(cardDetails.number);

  return {
    cardAmountParts: {
      wholeAmountPart,
      decimalAmountPart,
    },
    cardNumber: formattedCardNumber,
    exp: cardDetails.exp,
    cvc: cardDetails.cvc,
  };
};
