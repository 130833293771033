import analytics from 'src/services/analytics';
import { uuid } from 'src/utils/uuid';

const PAYBILL_FLOW_TAGNAME = 'paybill-flow-tag-name';
const TRACK_PAY_BILL_FLOW_KEY = 'payBillFlowUuid';
export const startTrackBillPayFlow = () => {
  analytics.setExtraProperties(PAYBILL_FLOW_TAGNAME, { [TRACK_PAY_BILL_FLOW_KEY]: uuid() });
};
export const stopTrackingBillPayFlow = () => {
  analytics.removeExtraProperties(PAYBILL_FLOW_TAGNAME);
};
export const getPayBillFlowUUID = () => analytics.getExtraProperties()[TRACK_PAY_BILL_FLOW_KEY] as string | undefined;
