import React from 'react';
import { Text, Flex, Box, Link } from '@melio/billpay-design-system';
import { OrganizationType, PaymentType, VirtualCardType } from 'src/utils/types';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { pushNotification } from 'src/services/notifications';
import { NOTIFICATION_VARIANT } from 'src/utils/consts';
import { format } from 'date-fns';
import { DATE_FORMATS } from 'src/utils/date-fns';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { NotificationKeyType } from 'src/pages/vendor/virtual-card-details/types';
import { notificationByField } from 'src/pages/vendor/virtual-card-details/consts';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useStateChangedAnalytics, VirtualCardPaymentState } from 'src/pages/vendor/virtual-card-details/hooks';
import { CardDetails, CardFields, CardProcessingSteps } from 'src/pages/vendor/virtual-card-details/components';
import analytics from 'src/services/analytics';

type Props = {
  payment?: PaymentType;
  virtualCard?: VirtualCardType;
  organization?: OrganizationType;
};

export const VirtualCardDetailsPage = ({ payment, virtualCard, organization }: Props) => {
  const site = useSiteContext();
  const { analyticsProperties } = useStateChangedAnalytics({
    payment,
    virtualCard,
    paymentState: VirtualCardPaymentState.Valid,
  });

  const handleCopyFieldClick = (fieldName: NotificationKeyType, value?: string) => {
    const notification = notificationByField[fieldName];

    if (value) {
      analytics.trackAction('vendors.virtualCardDetails.valid.cta', {
        ...analyticsProperties,
        cardFieldName: fieldName,
      });
      navigator.clipboard.writeText(value);
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: notification,
      });
    }
  };

  const amount = payment?.amount || 0;

  const cardDetails = virtualCard && {
    number: virtualCard.cardNumber,
    cvc: virtualCard.cvv,
    exp:
      virtualCard.expirationDate &&
      format(new Date(virtualCard.expirationDate), DATE_FORMATS.monthNumberSlashShortYear),
  };

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Text textStyle="ds.h5" color="ds.black" m="0">
          {organization?.companyName}
        </Text>
        <Text textStyle="ds.body1" color="ds.gray.100" mb="0" mt="ds.sm">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.sent" />
        </Text>
        <Flex my="ds.xl" gap="ds.xl" direction="column" w="100%" align="center">
          {cardDetails && <CardDetails cardDetails={cardDetails} amount={amount} />}
          {cardDetails && <CardFields {...cardDetails} onClick={handleCopyFieldClick} />}
        </Flex>
        <Box py="ds.lg" px="ds.md" backgroundColor="ds.gray.800" borderRadius="ds.lg">
          <Text align="center" textStyle="ds.body3" color="ds.gray.100">
            <MIFormattedText
              label="vendor.unilateral.virtualCardDetails.estimate"
              values={{
                date: <MIFormattedDate date={virtualCard?.endDate} />,
              }}
            />
          </Text>
        </Box>
        <Divider my="ds.2xl" h="ds.xs" />
        <CardProcessingSteps />
        <Text textStyle="ds.body2" align="center" color="ds.gray.200" mb="0" mt="ds.xl">
          <MIFormattedText
            label="vendor.unilateral.virtualCardDetails.footerNote"
            values={{
              link: (...chunks) => (
                <Link href={`mailto:${site.config.support.email}`}>{React.Children.toArray(chunks)}</Link>
              ),
            }}
          />
        </Text>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
