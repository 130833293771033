import { BANK_ACCOUNT_TYPE } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { fetchRequest, postRequest, putRequest, deleteRequest } from './api';

export type ShiftVirtualCardToACHRequestParams = {
  token: string;
  deliveryMethod: {
    bankAccount: {
      accountType: BANK_ACCOUNT_TYPE;
      routingNumber: string;
      accountNumber: string;
    };
  };
  achDeliveryMethodId?: string;
};

export type ShiftVirtualCardToACHResponse = {
  newDeliveryMethod: DeliveryMethodType;
};

export default {
  getDeliveryMethods(orgId, vendorId) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods`;
    return fetchRequest(url);
  },

  getDeliveryMethodById({ orgId, vendorId, id }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;
    return fetchRequest(url);
  },

  getDeliveryMethodStatus({ orgId, vendorId, id }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}/status`;
    return fetchRequest(url);
  },

  editDeliveryMethodById(orgId, vendorId, id, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;
    return putRequest(url, params);
  },

  addDeliveryMethod({ orgId, vendorId, params }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods`;
    return postRequest(url, params);
  },

  replaceDeliveryMethodWithToken(params) {
    const url = '/delivery-method/create-delivery-method';
    return postRequest(url, params);
  },

  getDeliveryMethodACHWithToken(params) {
    const url = '/delivery-method/delivery-method-ach';
    return fetchRequest(url, params);
  },

  updateDeliveryMethodACHWithToken(params) {
    const url = '/delivery-method/delivery-method-ach';
    return putRequest(url, params);
  },

  addPlaidAccount(orgId, vendorId, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/plaid/add-accounts`;
    return postRequest(url, params);
  },

  deleteDeliveryMethodById(orgId, vendorId, id, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;
    return deleteRequest(url, params);
  },

  copyFromOwnedVendor({ orgId, vendorId, token }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/copy-from-owned-vendor`;
    return postRequest(url, { token });
  },

  verifyMicroDeposits({ orgId, vendorId, params }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/verify-micro-deposit`;
    return postRequest(url, params);
  },

  getVerificationStatus({ orgId, vendorId, deliveryMethodId }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${deliveryMethodId}/verification-status`;
    return fetchRequest(url);
  },

  getDeliveryMethodRemindersEmailsStatus(orgId, vendorId, deliveryMethodId) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${deliveryMethodId}/reminders-emails-status`;
    return fetchRequest(url).then((res) => res.status);
  },

  checkCardWithToken(params) {
    const url = '/delivery-method/check-card';
    return postRequest(url, params);
  },

  addCardAccountWithToken(params) {
    const url = '/delivery-method/add-card-account';
    return postRequest(url, params);
  },

  shiftVirtualCardToAch(params: ShiftVirtualCardToACHRequestParams): Promise<ShiftVirtualCardToACHResponse> {
    const url = '/delivery-method/shift-virtual-card-to-ach-payment-status-collected';
    return postRequest(url, params);
  },
};
