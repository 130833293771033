import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import {
  NewDeliveryMethodForPaymentPage,
  NewDeliveryMethodProcessedPage,
  PaymentCanceledPage,
  VirtualCardDetailsPage,
  VirtualCardExpiredPage,
  VirtualCardProcessedPage,
} from './pages';
import { useVirtualCardData, VirtualCardPaymentState } from './hooks';
import locations from './locations';

type Props = {
  token: string;
  virtualCardId: string;
};

const VirtualCardDetailsPageContainer = ({ token, virtualCardId }: Props) => {
  const history = useHistory();

  const handleInvalidToken = useCallback(() => {
    history.push(generatePath(locations.invalidToken, { token, id: virtualCardId }));
  }, [history, token, virtualCardId]);

  const {
    virtualCardPaymentState,
    payment,
    deliveryMethod,
    virtualCard,
    organization,
    isPaymentLoading,
    issueNewCard,
  } = useVirtualCardData({
    token,
    virtualCardId,
    onInvalidToken: handleInvalidToken,
  });

  if (isPaymentLoading || virtualCardPaymentState === VirtualCardPaymentState.Unknown) {
    return <QBDTLoader />;
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Expired) {
    return (
      <VirtualCardExpiredPage
        organization={organization}
        payment={payment}
        virtualCard={virtualCard}
        onIssueNewCard={issueNewCard}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Cancelled) {
    return <PaymentCanceledPage organization={organization} payment={payment} virtualCard={virtualCard} />;
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.NewDeliveryMethod) {
    return (
      <NewDeliveryMethodForPaymentPage
        organization={organization}
        deliveryMethod={deliveryMethod}
        payment={payment}
        virtualCard={virtualCard}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Processed) {
    return <VirtualCardProcessedPage virtualCard={virtualCard} deliveryMethod={deliveryMethod} payment={payment} />;
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.NewDeliveryMethodProcessed) {
    return (
      <NewDeliveryMethodProcessedPage deliveryMethod={deliveryMethod} payment={payment} virtualCard={virtualCard} />
    );
  }

  return <VirtualCardDetailsPage payment={payment} virtualCard={virtualCard} organization={organization} />;
};

export { VirtualCardDetailsPageContainer };
