import React from 'react';
import { Flex, Icon, Text } from '@melio/billpay-design-system';
import { ReactComponent as CheckMarkIcon } from 'src/images/icons/v-black.svg';
import { ReactComponent as GreenCheckMarkIcon } from 'src/images/icons/v.svg';
import { MIFormattedText } from 'src/utils/formatting';
import { useBreak } from 'src/hoc';

type Props = {
  title: string;
  text: string;
};

export const Advantage = ({ title, text }: Props) => {
  const { isDesktop } = useBreak();

  return (
    <Flex gap="ds.md">
      <Icon as={isDesktop ? CheckMarkIcon : GreenCheckMarkIcon} w="ds.lg" h="ds.lg" p="ds.sm" />
      <Flex direction="column" gap="ds.sm">
        <Text textStyle="ds.body1Semi" m="0">
          <MIFormattedText label={title} />
        </Text>
        <Text textStyle="ds.body2" m="0">
          <MIFormattedText label={text} />
        </Text>
      </Flex>
    </Flex>
  );
};
