import { QBDTAccountType } from 'src/billpay/qbdt/services/qbdt/types';

export type BaseResponse = {
  status: number;
};

export enum EntityType {
  Bill = 'Bill',
  BillPaymentCheck = 'BillPaymentCheck',
  BillPaymentCreditCard = 'BillPaymentCreditCard',
}

export const ENTITY_MAPPING = {
  Bill: EntityType.Bill,
  BILL_PAYMENT_CHECK: EntityType.BillPaymentCheck,
  ENTER_BILLS: EntityType.Bill,
  BILL_PAYMENT_CREDITCARD: EntityType.BillPaymentCreditCard,
};
export type getSelectedBillsResponse = BaseResponse & {
  entry: string;
  response: Array<{ [id: string]: EntityType } | { TxnId: string; TxnType: EntityType }>;
};

export type reportPaymentSummaryResponse = BaseResponse;

export enum BillPaymentType {
  CreditCard = 'CreditCard',
  Check = 'Check',
}

export type reportPaymentSummaryRequest = {
  [txnId: string]: {
    success: boolean;
    billPayment: string;
    billPaymentType: BillPaymentType;
    accountId: string;
    accountType: QBDTAccountType.Bank | QBDTAccountType.CreditCard;
    amount: string;
  };
};

export type CompanyFileGuidResponse = BaseResponse & {
  response: {
    companyguid: string;
  };
};
export type CanCloseResponse = {
  CanClose: 'true' | 'false';
  Message: string;
  Icon: 'Exclamation';
  Title: string;
  Button: 'YesNo';
};
export type Adapter = {
  getSelectedBills(): Promise<getSelectedBillsResponse | null>;
  reportPaymentSummary(data: reportPaymentSummaryRequest): Promise<reportPaymentSummaryResponse>;
  executeSDKRequest(data: string): Promise<string>;
  initialize(): Promise<BaseResponse>;
  getCompanyFileGuid(): Promise<CompanyFileGuidResponse>;
  getApiVersion(): Promise<string>;
  setCanClose(value: CanCloseResponse): Promise<void>;
  macWindowClose(): Promise<void>;
};
