import React from 'react';
import { Flex, HStack, Icon, Text } from '@melio/billpay-design-system';
import { ReactComponent as CalendarIllustration } from 'src/images/get-pro/calendar.svg';
import { ReactComponent as MasterCardLogo } from 'src/images/icons/mastercard.svg';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  digits: string;
};

export const VendorReceiveInfo = ({ digits }: Props) => (
  <Flex direction="column" justify="center" align="start" gap="ds.xl">
    <Text color="ds.gray.200" textStyle="ds.body2">
      <MIFormattedText label="vendor.pushToDebit.addDebitCard.success.vendorReceive.title" />
    </Text>
    <Flex direction="column" justify="center" align="start" gap="ds.xl">
      <HStack gap="ds.lg">
        <Icon as={MasterCardLogo} w="3rem" h="auto" alignSelf="start" />
        <Flex direction="column" align="start">
          <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.addDebitCard.success.vendorReceive.transferTo" />
          </Text>
          <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
            <MIFormattedText
              label="vendor.pushToDebit.addDebitCard.success.vendorReceive.debitCard"
              values={{
                digits,
              }}
            />
          </Text>
        </Flex>
      </HStack>
      <HStack gap="ds.lg">
        <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
        <Flex direction="column" align="start">
          <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.addDebitCard.success.vendorReceive.deliveryOn" />
          </Text>
          <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.addDebitCard.success.vendorReceive.deliveryTime" />
          </Text>
        </Flex>
      </HStack>
    </Flex>
  </Flex>
);
