import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import { MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import analytics from 'src/services/analytics';

export const useRemoveBillSubRow = (batchItemId: string, balance: number) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const { getSingleBatchItem } = useBatchBillsList();
  const orgId = useSelector(getOrgId);
  const parentRow = getSingleBatchItem(batchItemId) as MainTableRowType;
  const { deliveryMethodId, scheduledDate, fundingSourceId, createOrigin } = parentRow.payment;

  const updateDeliveryOptions = async () => {
    const newAmount = parentRow.totalAmount && parentRow.totalAmount - balance;

    if (deliveryMethodId && fundingSourceId) {
      await actions.updatePaymentsDeliveryOptions({
        batchItemId: parentRow.id,
        orgId,
        deductionDate: scheduledDate,
        deliveryMethodId,
        fundingSourceId,
        amount: newAmount,
        payBillFlowUUID: parentRow.payBillFlowUUID,
        createOrigin,
      });
    } else {
      await actions.updatePayments.setAmount({
        batchItemId: parentRow.id,
        amount: newAmount,
      });
    }
  };

  const removeSubRow = async () => {
    await actions.settings.removeBillFromPayment({
      batchItemId,
      parentId: parentRow.id,
    });

    await updateDeliveryOptions();

    analytics.trackAction('regularBatchPayments.removeBillId', {
      billIds: [batchItemId],
      vendorId: parentRow.payment.vendorId,
      isBulk: false,
      payBillFlowUuid: parentRow.payBillFlowUUID,
    });
  };

  return { removeSubRow };
};
