import { sub, isAfter } from 'date-fns';
import sum from 'lodash/sum';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import { logger } from 'src/services/loggers';
import { BILL_MAX_AMOUNT } from 'src/utils/consts';
import { fetchRequest, postRequest } from 'src/services/api/api';
import { batchDeleteBills } from 'src/services/api/qbdt';
import analytics from 'src/services/analytics';
import { getIntervals } from 'src/utils/dates';
import { BillType, OrganizationPreferencesType, Override, PaymentType } from 'src/utils/types';
import { getBatchBillsModifiedSince } from '../entities/bill';
import { syncPayment } from './sync-payment';
import { syncBills } from './sync-bill';
import { syncInternalMelioPayment } from './sync-internal-payment';
import { QBDTBill } from '../types';
import { removeIncorrectlySyncedUnpaidPaidBill } from './recheck-unpaid-bills-sync';
import { SDK_STATUS_CODES } from '../constants';
import { QBDTSDKError } from '../sdk';

const isQbdtBillNotFoundError = (error: unknown) => {
  const regex = new RegExp(
    'The query request has not been fully completed. There was a required element \\S+ that could not be found in QuickBook'
  );

  return error instanceof QBDTSDKError && error.code === SDK_STATUS_CODES.OBJECT_NOT_FOUND && regex.test(error.message);
};

const MAX_LAST_SYNC_MONTHS = 3;

function getDefaultLastSyncDate() {
  return sub(new Date(), { months: MAX_LAST_SYNC_MONTHS });
}

async function getLastSyncDate(orgId) {
  const { lastSync } = await fetchRequest(`/orgs/${orgId}/qbdt/sync`);

  return lastSync ? new Date(lastSync) : getDefaultLastSyncDate();
}

async function setLastSyncDate(orgId, date) {
  await postRequest(`/orgs/${orgId}/qbdt/sync`, { date });
}

function isBillValidToSync(bill: QBDTBill) {
  return (
    !bill.IsPaid &&
    bill.AmountDue > 0 &&
    bill.AmountDue < BILL_MAX_AMOUNT &&
    isAfter(new Date(bill.DueDate), getDefaultLastSyncDate())
  );
}

type SyncInternalPaymentsParams = {
  orgId: string;
  notSyncedInternalPayments: PaymentType[];
  onPaymentSyncSuccess: (index: number) => void;
};

async function syncInternalPaymentsFromMelioToQbdt({
  orgId,
  notSyncedInternalPayments,
  onPaymentSyncSuccess,
}: SyncInternalPaymentsParams) {
  const startInternalPaymentsSyncTimestamp = performance.now();
  let failedToSyncInternalPaymentsCount = 0;
  const errors: string[] = [];

  for (let idx = 0; idx < notSyncedInternalPayments.length; idx += 1) {
    const payment = notSyncedInternalPayments[idx];
    try {
      // eslint-disable-next-line no-await-in-loop
      await syncInternalMelioPayment(orgId, payment);
      onPaymentSyncSuccess(idx);
    } catch (error: any) {
      failedToSyncInternalPaymentsCount += 1;
      analytics.trackAction('internal-payment-sync-failed', {
        errorMessage: error.message,
        paymentId: payment.id,
      });
      errors.push(`Failed to sync payment ${payment.id} from Melio. Reason: ${error.message}`);
    }
  }

  const internalPaymentsSyncDuration = performance.now() - startInternalPaymentsSyncTimestamp;

  return {
    internalPaymentsSyncDuration,
    internalPaymentsSyncErrors: errors,
    failedToSyncInternalPaymentsCount,
  };
}

type SyncRegularPaymentsParams = {
  orgId: string;
  notSyncedRegularPayments: PaymentType[];
  onPaymentSyncSuccess: (index: number) => void;
};

async function syncRegularPaymentsFromMelioToQbdt({
  orgId,
  notSyncedRegularPayments,
  onPaymentSyncSuccess,
}: SyncRegularPaymentsParams) {
  const startRegularSyncTimestamp = performance.now();
  let failedToSyncRegularPaymentsCount = 0;
  const errors: string[] = [];

  for (let idx = 0; idx < notSyncedRegularPayments.length; idx += 1) {
    const payment = notSyncedRegularPayments[idx];
    try {
      // eslint-disable-next-line no-await-in-loop
      await syncPayment({ orgId, paymentId: payment.id });

      onPaymentSyncSuccess(idx);
    } catch (error: any) {
      failedToSyncRegularPaymentsCount += 1;
      analytics.trackAction('regular-payment-sync-failed', {
        errorMessage: error.message,
        paymentId: payment.id,
      });

      if (!isQbdtBillNotFoundError(error)) {
        errors.push(`Failed to sync payment ${payment.id} from Melio. Reason: ${error.message}`);
      }
    }
  }

  const regularPaymentsSyncDuration = performance.now() - startRegularSyncTimestamp;
  return {
    regularPaymentsSyncDuration,
    regularPaymentsSyncErrors: errors,
    failedToSyncRegularPaymentsCount,
  };
}

type SyncBillType = Override<
  BillType,
  {
    accountingPlatformEntity?: any; // TODO: set proper type
  }
>;

type SyncPaymentType = Override<
  PaymentType,
  {
    accountingPlatformEntity?: any;
    bill: SyncBillType;
  }
>;

type SyncAllPaymentsParams = {
  orgId: string;
  onStepProgress: (progress: number) => void;
};

async function syncAllPaymentsFromMelioToQbdt({ orgId, onStepProgress }: SyncAllPaymentsParams) {
  const lastSyncDate = getDefaultLastSyncDate();

  const { payments } = (await fetchRequest(`/orgs/${orgId}/qbdt/sync/payments`, {
    timestamp: lastSyncDate,
  })) as {
    payments: SyncPaymentType[];
  };

  const notSyncedInternalPayments = payments.filter(
    (payment) => payment.bill?.internalBill && !payment.bill.accountingPlatformEntity
  );
  const notSyncedRegularPayments = payments.filter(
    (payment) => !payment.bill?.internalBill && !payment.accountingPlatformEntity
  );
  const notSyncedPaymentsCount = notSyncedInternalPayments.length + notSyncedRegularPayments.length;

  const {
    failedToSyncInternalPaymentsCount,
    internalPaymentsSyncDuration,
    internalPaymentsSyncErrors,
  } = await syncInternalPaymentsFromMelioToQbdt({
    orgId,
    notSyncedInternalPayments,
    onPaymentSyncSuccess: (paymentIndex) => {
      const paymentIndexRelativeToAllPayments = paymentIndex + 1;
      // calculate progress (from 0 to 1)
      const syncAllPaymentsProgress = paymentIndexRelativeToAllPayments / notSyncedPaymentsCount;
      onStepProgress(syncAllPaymentsProgress);
    },
  });

  const {
    failedToSyncRegularPaymentsCount,
    regularPaymentsSyncDuration,
    regularPaymentsSyncErrors,
  } = await syncRegularPaymentsFromMelioToQbdt({
    orgId,
    notSyncedRegularPayments,
    onPaymentSyncSuccess: (paymentIndex) => {
      const paymentIndexRelativeToAllPayments = paymentIndex + notSyncedInternalPayments.length + 1;
      // calculate progress (from 0 to 1)
      const syncAllPaymentsProgress = paymentIndexRelativeToAllPayments / notSyncedPaymentsCount;
      onStepProgress(syncAllPaymentsProgress);
    },
  });

  const internalPaymentsCount = notSyncedInternalPayments.length;
  const syncedInternalPaymentsCount = internalPaymentsCount - failedToSyncInternalPaymentsCount;
  const regularPaymentsCount = notSyncedRegularPayments.length;
  const syncedRegularPaymentsCount = regularPaymentsCount - failedToSyncRegularPaymentsCount;
  analytics.trackAction('end-payments-sync', {
    internalPaymentsSyncDuration,
    internalPaymentsCount,
    failedToSyncInternalPaymentsCount,
    syncedInternalPaymentsCount,
    regularPaymentsSyncDuration,
    regularPaymentsCount,
    failedToSyncRegularPaymentsCount,
    syncedRegularPaymentsCount,
  });

  return {
    syncAllPaymentsErrors: [...internalPaymentsSyncErrors, ...regularPaymentsSyncErrors],
  };
}

type SyncQbdtBillsToMelioParams = {
  orgId: number;
  onStepProgress?: (progress: number) => void;
};

const SYNC_INTERVAL_DAYS_DURATION = 5;

export async function syncQbdtBillsToMelio({
  orgId,
  organizationPreferences,
  onStepProgress,
}: SyncQbdtBillsToMelioParams & { organizationPreferences: OrganizationPreferencesType }) {
  const isRecheckUnpaidBillsEnabled = featureFlags.defaultClient.getVariant(FeatureFlags.RecheckUnpaidBills, false);

  if (isRecheckUnpaidBillsEnabled) {
    await removeIncorrectlySyncedUnpaidPaidBill({ orgId, organizationPreferences });
  }

  return batchSyncQbdtBillsToMelio({ orgId, onStepProgress });
}

export async function batchSyncQbdtBillsToMelio({ orgId, onStepProgress }: SyncQbdtBillsToMelioParams) {
  const lastSyncDate = await getLastSyncDate(orgId);

  const startModifiedBillsSyncTimestamp = performance.now();
  const modifiedBillsToSync = {};
  let failedToSyncBillsCount = 0;
  const intervals = getIntervals(lastSyncDate, SYNC_INTERVAL_DAYS_DURATION);

  const errors: string[] = [];

  for await (const [intervalIndex, interval] of Object.entries(intervals)) {
    // Sync bills from QBDT to Melio
    for await (const { entities, totalCount } of getBatchBillsModifiedSince(interval.startDate, interval.endDate)) {
      try {
        if (totalCount === 0) {
          break;
        }

        if (modifiedBillsToSync[intervalIndex] !== undefined) {
          modifiedBillsToSync[intervalIndex] = totalCount;
        }

        const qbdtBills = entities.filter((bill) => isBillValidToSync(bill));
        if (qbdtBills.length > 0) {
          await syncBills({ orgId, qbdtBills });
        }

        const markedAsPaidQBDTBills = entities.filter((bill) => bill.IsPaid);
        if (markedAsPaidQBDTBills.length > 0) {
          const externalBillsIds = markedAsPaidQBDTBills.map((bill) => bill.TxnID);
          await batchDeleteBills(orgId, externalBillsIds);
        }
      } catch (error: any) {
        failedToSyncBillsCount += entities.length || 0;
        const txnIds = entities.map((bill) => bill.TxnID);
        logger.error(
          `syncBills.batchSyncQbdtBillsToMelio(): Failed to sync bills ${txnIds?.join(',')}. Reason: ${error.message}`
        );
        analytics.trackAction('bills-sync-failed', {
          errorMessage: error.message,
          txnIds,
        });
        errors.push(`Failed to sync bills ${txnIds} from QBDT. Reason: ${error.message}`);
      }
    }

    const stepProgress = parseInt(intervalIndex, 10) / intervals.length;
    onStepProgress?.(stepProgress);
    await setLastSyncDate(orgId, new Date(interval.endDate));
  }

  const modifiedBillsSyncDuration = performance.now() - startModifiedBillsSyncTimestamp;
  const syncedBillsCount = sum(Object.values(modifiedBillsToSync)) - failedToSyncBillsCount;
  analytics.trackAction('end-modified-bills-sync', {
    totalCount: modifiedBillsToSync,
    duration: modifiedBillsSyncDuration,
    failedToSyncBillsCount,
    syncedBillsCount,
  });

  return {
    syncBillsErrors: errors,
  };
}

type ReportProgressParams = {
  globalStepProgressStart: number;
  globalStepProgressEnd: number;
  stepProgress: number;
};

export async function syncAll({ orgId, setProgress, organizationPreferences }) {
  const reportProgress = ({ globalStepProgressStart, globalStepProgressEnd, stepProgress }: ReportProgressParams) => {
    const globalStepProgressDiff = globalStepProgressEnd - globalStepProgressStart;
    const globalStepProgressChange = globalStepProgressDiff * stepProgress;
    setProgress(globalStepProgressStart + globalStepProgressChange);
  };

  const SYNC_PAYMENTS_STEP_PROGRESS_START = 0.4;
  const SYNC_PAYMENTS_STEP_PROGRESS_END = 0.6;

  const { syncAllPaymentsErrors } = await syncAllPaymentsFromMelioToQbdt({
    orgId,
    onStepProgress: (stepProgress) => {
      reportProgress({
        globalStepProgressStart: SYNC_PAYMENTS_STEP_PROGRESS_START,
        globalStepProgressEnd: SYNC_PAYMENTS_STEP_PROGRESS_END,
        stepProgress,
      });
    },
  });

  const SYNC_BILLS_STEP_PROGRESS_START = SYNC_PAYMENTS_STEP_PROGRESS_END;
  const SYNC_BILLS_STEP_PROGRESS_END = 1;

  const { syncBillsErrors } = await syncQbdtBillsToMelio({
    orgId,
    organizationPreferences,
    onStepProgress: (stepProgress) => {
      reportProgress({
        globalStepProgressStart: SYNC_BILLS_STEP_PROGRESS_START,
        globalStepProgressEnd: SYNC_BILLS_STEP_PROGRESS_END,
        stepProgress,
      });
    },
  });

  return [...syncAllPaymentsErrors, ...syncBillsErrors];
}
