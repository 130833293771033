import React from 'react';
import { LandingPageOverlay } from 'src/billpay/qbdt/pages/entry/LandingPage/LandingPageModal/components/LandingPageOverlay';
import { LandingPageModal } from 'src/billpay/qbdt/pages/entry/LandingPage/LandingPageModal/LandingPageModal';

type Props = {
  onConfirm: () => void;
};

export const LandingPage = ({ onConfirm }: Props) => (
  <LandingPageOverlay>
    <LandingPageModal onContinue={onConfirm} />
  </LandingPageOverlay>
);
