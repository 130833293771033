import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CONSTS } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import { useSiteContext } from 'src/hoc/withSiteContext';
import {
  MicroDepositActions,
  microDepositsEventParamsType,
  MicroDepositState,
  RESOLUTIONS,
  trackMicroDepositsEvent,
} from 'src/hooks/settings/microDepositsCommon';
import DoneIllustration from 'src/images/general/action-done-icon.svg';
import { zendeskService } from 'src/services/zendesk';
import MILink from 'src/components/common/MILink';
import { VerifyMicroDepositsModal } from 'src/components/micro-deposits/VerifyMicroDepositsModal';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import QBOMIDialog, { SubtitleContainer, TextsContainer, TitleContainer } from 'src/components/common/QBOMIDialog';
import EmailLink from 'src/components/common/EmailLink';
import { BillType } from 'src/utils/types';
import { microDepositsEventActions, MicroDepositsEventPageType } from 'src/components/micro-deposits/consts';
import { getPartialBillId } from 'src/utils/bills';

type Props = {
  onVerifyFinished: (isSuccess?: boolean) => void;
  eventPage: MicroDepositsEventPageType;
  verifyingId: string | number;
  bill?: RecordOf<BillType>;
} & MicroDepositState &
  MicroDepositActions;

interface IBindHandlers {
  onVerifyFinished: () => void;
  eventPage: MicroDepositsEventPageType;
  dismiss: () => void;
  params: microDepositsEventParamsType;
}

const openSupportChat = () => {
  analytics.track('micro-deposits-unable-to-verify', 'support-open-start-chat');
  zendeskService.show();
};

function bindHandlers({ onVerifyFinished, eventPage, dismiss, params }: IBindHandlers) {
  return {
    handleCancel: () => {
      trackMicroDepositsEvent(eventPage, microDepositsEventActions.CLOSE_MODAL, params);
      dismiss();
      onVerifyFinished();
    },

    handleDismiss: () => {
      trackMicroDepositsEvent(eventPage, microDepositsEventActions.VERIFY_FINISH, params);
      dismiss();
      onVerifyFinished();
    },
  };
}

export function VerifyMicroDeposits(props: Props) {
  const { onVerifyFinished, dismiss, eventPage, resolution, verifyingId, bill } = props;

  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(verifyingId));

  const areDepositsNotSentYet = fundingSource?.bankAccount?.depositsNotSent;

  const digits = getAccountNumber4digits(fundingSource.bankAccount);

  const analyticsParams: microDepositsEventParamsType = {
    fundingSourceID: verifyingId,
    partialBillId: bill && getPartialBillId(bill),
  };

  const { handleCancel, handleDismiss } = useMemo(
    () =>
      bindHandlers({
        onVerifyFinished,
        eventPage,
        dismiss,
        params: analyticsParams,
      }),
    [onVerifyFinished, dismiss, eventPage]
  );
  const companyInfo = useSelector(getCompanyInfo);
  const companyName = companyInfo?.companyName;

  const site = useSiteContext();

  if (areDepositsNotSentYet) {
    trackMicroDepositsEvent(eventPage, microDepositsEventActions.CANNOT_VERIFY_YET, analyticsParams);

    return (
      <StyledMIDialog
        type={CONSTS.DIALOG_TYPE.CONFIRM}
        variant={CONSTS.DIALOG_VARIANTS.SUCCESS}
        title="settings.microDeposits.depositsNotSentYetDialog.title"
        subtitle="settings.microDeposits.depositsNotSentYetDialog.subtitle"
        okButtonText="settings.microDeposits.depositsNotSentYetDialog.ok"
        onOkAction={handleDismiss}
        onCancelAction={handleCancel}
        minHeight="36.8rem"
        showCancel={false}
        subtitleValues={{
          digits,
        }}
      />
    );
  }

  switch (resolution) {
    case RESOLUTIONS.SUCCESS: {
      return (
        <StyledMIDialog
          type={CONSTS.DIALOG_TYPE.CONFIRM}
          variant={CONSTS.DIALOG_VARIANTS.SUCCESS}
          title="settings.microDeposits.verifyModalSuccess.paymentMethods.title"
          subtitle="settings.microDeposits.verifyModalSuccess.paymentMethods.subtitle"
          okButtonText="settings.microDeposits.verifyModalSuccess.paymentMethods.ok"
          onOkAction={handleDismiss}
          onCancelAction={handleCancel}
          minHeight="36.8rem"
          image={DoneIllustration}
          showCancel={false}
        />
      );
    }
    case RESOLUTIONS.SUPPORT: {
      return (
        <StyledMIDialog
          type={CONSTS.DIALOG_TYPE.CONFIRM}
          variant={CONSTS.DIALOG_VARIANTS.SUCCESS}
          title="settings.microDeposits.verifyDialogLimitReached.title"
          subtitle="settings.microDeposits.verifyDialogLimitReached.subtitle"
          subtitleValues={{
            supportEmail: <StyledEmailLink email={site.config.support.email} />,
            chatWithUs: (
              <StyledChatLink to={openSupportChat} label="settings.microDeposits.verifyDialogLimitReached.chatWithUs" />
            ),
          }}
          okButtonText="settings.microDeposits.verifyDialogLimitReached.dismiss"
          onOkAction={handleDismiss}
          onCancelAction={handleCancel}
          showCancel={false}
          minHeight="36.8rem"
        />
      );
    }

    default: {
      return <VerifyMicroDepositsModal {...props} companyName={companyName} dismiss={handleCancel} digits={digits} />;
    }
  }
}

const StyledEmailLink = styled(EmailLink)`
  color: ${(props) => props.theme.colors.intuit.active};
  :hover,
  :active {
    color: ${(props) => props.theme.colors.intuit.hover};
  }
`;

const StyledMIDialog = styled(QBOMIDialog)`
  ${TitleContainer} {
    font-size: 2.4rem;
    margin-bottom: 0.8rem;
  }

  ${SubtitleContainer} {
    font-weight: 400;
  }

  ${TextsContainer} {
    margin-bottom: 4rem;
  }

  .hint {
    margin-top: 1.2rem;
  }
`;

const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.colors.primary.opaque};
  text-decoration: none;
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;

const StyledChatLink = styled(ChatLink)`
  color: ${(props) => props.theme.colors.intuit.active};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2.4rem;
  :hover,
  :active {
    color: ${(props) => props.theme.colors.intuit.hover};
  }
`;
