import { QBDTSDKError } from '../sdk';

export function getMelioIdActionErrorEventParams(error: unknown) {
  if (error instanceof QBDTSDKError) {
    return {
      code: error.code,
      message: error.message,
      stack: error.stack,
    };
  } else if (error instanceof Error) {
    return {
      message: error.message,
      stack: error.stack,
    };
  }

  return {};
}
