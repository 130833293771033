import analytics from 'src/services/analytics';
import { CONSTS, PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';
import { convertStart, convertLimit } from 'src/utils/pagination-utils';
import { BillStatusType, BillType, DeliveryMethodType, DeliveryOptionType } from 'src/utils/types';
import { fetchRequest, postRequest, putRequest, deleteRequest } from './api';

export type FilterType = {
  start?: number;
  limit?: number;
  [key: string]: any;
};

type MarkAsPaidParams = {
  orgId: string;
  id: string;
  isPaid: boolean;
  intuitAccountId?: string | null;
  paymentId?: number | null;
  createOrigin?: PAYMENT_CREATE_FLOW_ORIGIN;
  amount?: number | null;
};

export type InternalBill = {
  id: number;
  status: BillStatusType;
  invoiceDate: string;
  totalAmount: number;
};

export type GetInternalBillsResponse = {
  objects: InternalBill[];
  totalCount: number;
};

export type GetInternalBillsParams = {
  orgId: number;
  filters: {
    start: number;
    limit: number;
  };
};

export default {
  getBills({ orgId, filters = {}, scope = undefined }: { orgId: string; filters: FilterType; scope?: string }) {
    const url = `/orgs/${orgId}/bills`;
    const params = {
      ...filters,
      scope,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  getBillList({ orgId, filters = {}, scope = undefined }: { orgId: string; filters: FilterType; scope?: string }) {
    const url = `/orgs/${orgId}/bills/billList`;
    const params = {
      ...filters,
      scope,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },
  getInternalBills({ orgId, filters = {} }: { orgId: string | number; filters: FilterType }) {
    const url = `/orgs/${orgId}/bills/internal`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  getBillById({ orgId, id }) {
    const url = `/orgs/${orgId}/bills/${id}`;
    return fetchRequest(url);
  },

  getVendorBillById({ orgId, id }) {
    const url = `/orgs/${orgId}/bills/vendor/${id}`;
    return fetchRequest(url);
  },

  generatePdfInvoice(orgId, billId) {
    const url = `/orgs/${orgId}/bills/${billId}/pdf-invoice`;

    return fetchRequest(url);
  },

  generateVendorFeePdfInvoice(orgId, internalBillId) {
    const url = `/orgs/${orgId}/bills/${internalBillId}/vendor-fee-pdf-invoice`;

    return fetchRequest(url);
  },

  sendPdfInvoice(orgId, internalBillId) {
    const url = `/orgs/${orgId}/bills/${internalBillId}/send-pdf-invoice`;

    return fetchRequest(url);
  },

  createBill(orgId, params) {
    const url = `/orgs/${orgId}/bills`;

    analytics.setTraits({ [CONSTS.DB_ANALYTICS_TRAITS.CREATE_A_BILL]: true });
    return postRequest(url, params);
  },

  editBillById(orgId, id, params, scope) {
    const url = `/orgs/${orgId}/bills/${id}`;
    return putRequest(url, { ...params, scope });
  },

  deleteBillById(orgId, id) {
    const url = `/orgs/${orgId}/bills/${id}`;
    return deleteRequest(url);
  },

  markAsPaid({ orgId, id, isPaid, intuitAccountId, paymentId, createOrigin, amount }: MarkAsPaidParams) {
    const url = `/orgs/${orgId}/bills/${id}/mark-as-paid`;
    return postRequest(url, {
      isPaid,
      intuitAccountId: intuitAccountId || null,
      amount: amount || null,
      paymentId: paymentId || null,
      createOrigin,
    });
  },

  batchMarkAsPaid({ orgId, items, intuitAccountId, createOrigin }) {
    const url = `/orgs/${orgId}/bills/batch-mark-as-paid`;
    return postRequest(url, { items, intuitAccountId, createOrigin });
  },

  getBillsStatusCount(orgId) {
    const url = `/orgs/${orgId}/bills/bills-status-count`;
    return fetchRequest(url);
  },
  setClassifications({ orgId, billIds, classification }) {
    const url = `/orgs/${orgId}/bills/classification`;
    return putRequest(url, { bills: billIds, classification });
  },
  getVendorBills({ orgId, filters = {} }: { orgId: string; filters: FilterType }) {
    const url = `/orgs/${orgId}/bills/vendor-bills`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  // TODO: consider changing it to payment request approval/revoke actions instead of "create bill from request"
  createBillFromRequest(orgId, params) {
    const url = `/orgs/${orgId}/bills/from-request`;
    return postRequest(url, params);
  },
};
export type DefaultDateType = {
  billId: number;
  defaultFundingSourceId?: number;
  defaultDates?: {
    actualDeliveryDays: number;
    deliveryDate: string;
    maxDeliveryDate: string;
    suggestedScheduledDate: string;
  };
  defaultDeliveryMethod?: DeliveryMethodType;
};

export type BatchFetchBillResult = {
  billList: Record<
    string,
    {
      defaultDate: DefaultDateType;
      deliveryOptions: DeliveryOptionType[];
      bill: BillType;
    }
  >;
  totalCount: number;
};
