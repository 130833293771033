import { VendorType } from 'src/utils/types';
import vendorsApi from 'src/services/api/vendors';
import { postRequest } from 'src/services/api/api';
import { QBDTVendor } from 'src/billpay/qbdt/services/qbdt/types';
import { MELIO_STATUS_CODES } from 'src/billpay/qbdt/services/qbdt/constants';
import {
  createQbdtVendor,
  getQbdtVendor,
  getQbdtVendorByName,
  qbdtVendorToMelio,
} from 'src/billpay/qbdt/services/qbdt/entities/vendor';

function isVendorEqual(melioVendor: VendorType, qbdtVendor: QBDTVendor) {
  return (
    melioVendor.companyName === qbdtVendor.Name &&
    melioVendor.contactEmail === qbdtVendor.Email &&
    melioVendor.contactPhone === qbdtVendor.Phone &&
    melioVendor.contactName === [qbdtVendor.FirstName, qbdtVendor.LastName].join(' ').trim()
  );
}

async function LinkQbdtVendorToMelioVendorByName(orgId, melioVendor) {
  const qbdtVendorByName = await getQbdtVendorByName(melioVendor.companyName);
  if (!qbdtVendorByName) {
    // We have no QBDT vendor by ID and not by Name
    const newQbdtVendor = await createQbdtVendor(melioVendor);
    await postRequest(`/orgs/${orgId}/qbdt/vendor/${melioVendor.id}/external-reference/${newQbdtVendor.ListID}`);
    return newQbdtVendor;
  }

  // We have no QBDT vendor by ID, but there is a name match. Point our vendor to it.
  await postRequest(`/orgs/${orgId}/qbdt/vendor/${melioVendor.id}/external-reference/${qbdtVendorByName.ListID}`);
  return qbdtVendorByName;
}

export async function syncMelioVendor(orgId, melioVendor, internalBill) {
  if (!melioVendor.accountingPlatformEntity) {
    // There is no accounting platform entity for this vendor
    return LinkQbdtVendorToMelioVendorByName(orgId, melioVendor);
  }

  // There is an accounting platform entity for this vendor
  const qbdtVendor = await getQbdtVendor(melioVendor.accountingPlatformEntity.externalId);
  if (!qbdtVendor) {
    // We have no QBDT vendor matching this Melio vendor
    return LinkQbdtVendorToMelioVendorByName(orgId, melioVendor);
  } else if (!isVendorEqual(melioVendor, qbdtVendor) && !internalBill) {
    // Some fields were updated, but we only update upstream to Melio if this is *not* an internal bill
    // TODO: Handle name change collisions
    await vendorsApi.editVendorById({
      orgId,
      id: melioVendor.id,
      params: {
        companyName: qbdtVendor.Name,
        contactName: [qbdtVendor.FirstName, qbdtVendor.LastName].join(' ').trim(),
        contactEmail: qbdtVendor.Email,
        contactPhone: qbdtVendor.Phone,
      },
    });
  }

  return qbdtVendor;
}

export async function syncQbdtVendor(orgId, qbdtVendor: QBDTVendor, stack: string[] = []) {
  if (stack.includes(qbdtVendor.ListID)) {
    throw Error(`Vendor sync loop when resolving conflict for ${qbdtVendor.ListID}. Loop: ${stack.join(',')}`);
  }

  const postVendor = async () => {
    await postRequest(`/orgs/${orgId}/qbdt/sync/vendor`, {
      vendor: qbdtVendorToMelio(qbdtVendor),
      externalId: qbdtVendor.ListID,
    });
  };

  try {
    await postVendor();
  } catch (error: any) {
    if (
      error?.code === MELIO_STATUS_CODES.ERR_VENDOR_NAME_CONFLICT ||
      error?.code === MELIO_STATUS_CODES.ERR_VENDOR_RENAME_CONFLICT_EXTERNAL_ID
    ) {
      const otherQbdtVendor = await getQbdtVendor(error.responseData.otherExternalId);

      // This part is for next case
      // 1. User had vendor with name `Nike` and pay for it via Melio
      // 2. User renamed the vendor from `Nike` to `Nike Store` in QBDT Windows app
      // 3. User created one more vendor with name `Nike` in QBDT Windows app
      // 4. User is trying to pay new vendor `Nike`
      // If there is such situation we'll try to set name `Nike Store` to vendor who initially was `Nike`
      // to resolve the name conflict
      if (
        otherQbdtVendor &&
        error.responseData.otherVendorId &&
        error.code === MELIO_STATUS_CODES.ERR_VENDOR_NAME_CONFLICT
      ) {
        await vendorsApi.editVendorById({
          orgId,
          id: error.responseData.otherVendorId,
          params: {
            companyName: otherQbdtVendor.Name,
          },
        });
      } else if (otherQbdtVendor) {
        await syncQbdtVendor(orgId, otherQbdtVendor, [...stack, otherQbdtVendor.ListID]);
      } else {
        await postRequest(`/orgs/${orgId}/qbdt/sync/vendor/clear`, {
          externalId: error.responseData.otherExternalId,
        });
      }

      await postVendor();
    } else {
      throw error;
    }
  }
}
