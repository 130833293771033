import { useBreakpointValue } from '@chakra-ui/react';

export enum DASHBOARD_TABLE_VARIANT {
  LIMITED = 'limited',
  FULL = 'full',
}

export const useDashboardTableVariant = () =>
  useBreakpointValue({
    sm: DASHBOARD_TABLE_VARIANT.LIMITED,
    md: DASHBOARD_TABLE_VARIANT.LIMITED,
    lg: DASHBOARD_TABLE_VARIANT.LIMITED,
    xl: DASHBOARD_TABLE_VARIANT.FULL,
    '2xl': DASHBOARD_TABLE_VARIANT.FULL,
  });
