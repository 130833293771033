import { createSlice } from '@reduxjs/toolkit';
import { name } from './consts';

const initialState = {
  viewPaymentUrls: {
    exitUrl: null,
  },
};

type SliceState = {
  viewPaymentUrls: {
    exitUrl: string | null;
  };
};

type SetExitUrlAction = {
  payload: string;
};

type SliceReducers = {
  setExitUrl: (state: SliceState, action: SetExitUrlAction) => void;
};

const createViewPaymentUrlsSlice = () => {
  const slice = createSlice<SliceState, SliceReducers>({
    name: `[${name.toUpperCase()}] VIEW_PAYMENT_URLS`,
    initialState,
    reducers: {
      setExitUrl(state, { payload }) {
        state.viewPaymentUrls.exitUrl = payload;
      },
    },
  });
  return {
    ...slice,
    initialState,
    selectors: {
      getExitUrl: (state) => state[name].viewPaymentUrls.exitUrl,
    },
    dispatchers: {
      setExitUrl: (dispatch) => (params) => dispatch(slice.actions.setExitUrl(params)),
    },
  };
};

const viewPaymentUrlsSlice = createViewPaymentUrlsSlice();

export { viewPaymentUrlsSlice };
