import { RecordOf } from 'immutable';
import React, { useMemo } from 'react';
import { generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { convertStringToUrlObject } from 'src/utils/query-utils';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import compact from 'lodash/compact';
import head from 'lodash/head';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { CONSTS, DELIVERY_METHOD_ORIGIN } from 'src/utils/consts';
import pickBy from 'lodash/pickBy';
import { CheckType, EditableDeliveryMethodType } from 'src/utils/types';
import { selectNewDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import analytics from 'src/services/analytics';
import locations from 'src/utils/locations';
import EditCheckAddressWithAutocompleteDeliveryMethodPage from './components/EditCheckAddressWithAutocompleteDeliveryMethodPage';
import { getEventPageName } from './utils';

const EditCheckAddressWithAutocompleteDeliveryMethodPageContainer = () => {
  const location = useLocation<{
    check?: RecordOf<CheckType>;
    exitUrl?: string;
    redirectUrl?: string;
    origin: DELIVERY_METHOD_ORIGIN;
    preservedState?: any;
    fundingSource: any;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch<{
    orgId: string;
    id?: string;
    deliveryMethodId: string;
  }>();
  const vendorId = match.params?.id;
  const deliveryMethodId = match.params?.deliveryMethodId;
  const check = location.state?.check;
  const deliveryMethodActions = getDeliveryMethodActions(dispatch);
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const isUpdating = useSelector(deliveryMethodsStore.selectors.update.status(deliveryMethodId))?.loading || false;
  const isCreating = useSelector(deliveryMethodsStore.selectors.create.status())?.loading;
  const { redirectUrl, exitUrl } = location.state;
  useFetchVendor(vendorId);
  const eventPage = useMemo(() => getEventPageName(ownedVendorId, vendorId as string), [ownedVendorId, vendorId]);

  const goNext = (newDeliveryMethod) => {
    const nextStepPath = head(compact([redirectUrl])) || '';
    const parsedNextUrl = convertStringToUrlObject(generatePath(nextStepPath, { orgId }));
    history.push({
      ...parsedNextUrl,
      state: {
        ...location.state?.preservedState,
        ...(location.state?.origin === CONSTS.DELIVERY_METHOD_ORIGIN.BATCH_PAY_BILLS ? { batchActionMerge: true } : {}),
        newDeliveryMethod,
      },
    });
  };
  const goUnilateralFlow = () => {
    const nextStepPath = locations.Vendors.deliveryMethods.virtual.create.url({
      orgId,
      id: vendorId,
    });
    history.push({
      pathname: nextStepPath,
      state: {
        ...location.state?.preservedState,
        redirectUrl,
        exitUrl,
        origin: location.state?.origin,
        changeDeliveryMethodType: true,
      },
    });
  };

  const goExit = () => {
    analytics.track(eventPage, 'exit');
    const nextStepPath = head(compact([exitUrl, redirectUrl]));
    const parsedNextUrl = convertStringToUrlObject(generatePath(nextStepPath || '', { orgId }));
    history.push({
      ...parsedNextUrl,
      state: {
        ...location.state?.preservedState,
      },
    });
  };
  // TODO: replace analytics events with events-mapping (Yotam/Dima).
  const onSuccess = async (paperCheck: CheckType, isVerified: boolean) => {
    const deliveryMethod: EditableDeliveryMethodType = {
      paperCheck,
      isVerified,
      deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
    };
    let response;
    if (deliveryMethodId && vendorId) {
      analytics.track(eventPage, 'edit-delivery-method', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
      });
      response = await deliveryMethodActions.edit({
        orgId,
        vendorId,
        id: deliveryMethodId,
        deliveryMethod,
      });
      analytics.track(eventPage, 'edit-delivery-method-success', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
      });
    } else {
      if (ownedVendorId && ownedVendorId?.toString() === vendorId?.toString()) {
        analytics.setTraits({
          [CONSTS.DB_ANALYTICS_TRAITS.ADDED_DELIVERY_METHOD]: true,
        });
      }

      analytics.track(eventPage, 'add-delivery-method', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
      });
      response = await deliveryMethodActions.create({
        orgId,
        vendorId,
        params: deliveryMethod,
      });
      analytics.track(eventPage, 'add-delivery-method-success', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
      });
    }

    const newDeliveryMethod = response.payload;
    if (location.state?.origin === CONSTS.DELIVERY_METHOD_ORIGIN.PAY_BILL) {
      dispatch(selectNewDeliveryMethodAction(newDeliveryMethod));
    }

    goNext(newDeliveryMethod);
  };

  const checkObj = useMemo(() => (check?.toJS ? pickBy(check?.toJS()) : {}), [check]);

  const flowType = CONSTS.DELIVERY_METHOD_CHECK_FLOW.CONFIRM;

  return (
    <EditCheckAddressWithAutocompleteDeliveryMethodPage
      check={checkObj}
      onSuccess={onSuccess}
      goExit={goExit}
      vendorId={vendorId as string}
      isLoading={isUpdating || isCreating}
      goUnilateralFlow={goUnilateralFlow}
      flowType={flowType}
    />
  );
};

export default EditCheckAddressWithAutocompleteDeliveryMethodPageContainer;
