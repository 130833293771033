import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import locations from 'src/pages/locations';
import { MicroDepositsVerifyPage } from 'src/pages/funding-sources/MicroDepositsVerifyPage';

export const MicroDepositsVerifyPageContainer = () => {
  const history = useHistory();
  const { id, token } = useParams<{ id: string; token: string }>();

  const handleSuccess = () => history.push(generatePath(locations.microDepositsVerify.success, { id }));

  const handleBlocked = () => history.push(generatePath(locations.microDepositsVerify.blocked, { id }));

  return (
    <MicroDepositsVerifyPage
      fundingSourceId={parseFloat(id)}
      token={token}
      onSuccess={handleSuccess}
      onBlocked={handleBlocked}
    />
  );
};
