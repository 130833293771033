import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { DashboardTabStatusEnum } from '../../consts';
import { useDashboardColumns } from '../DashboardTable/columns/useDashboardColumns';
import { useDashboardListParams } from '../../hooks/useDashboardListParams';
import { tableEmptyStateWrapperStyles } from './styles';
import { DashboardTable } from '../DashboardTable/DashboardTable';
import { DashboardEmptyState } from '../DashboardEmptyState/DashboardEmptyState';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';
import { useDashboardListItems } from './hooks/useDashboardListItems';
import { DashboardTabStateEnum, useDashboardTabState } from './hooks/useDashboardTabStatus';

type Props = {
  status: DashboardTabStatusEnum;
};

export const DashboardTab = ({ status }: Props) => {
  const [listParams, { setOrder, setPage }] = useDashboardListParams();
  const [columns] = useDashboardColumns(status);
  const { dashboardListItems, isLoading, totalCount } = useDashboardListItems(listParams);

  const selectionEnabled = status === DashboardTabStatusEnum.Unpaid;

  const getDisabledCheckboxTooltip = () => 'paymentDashboard.columns.checkbox.failedPaymentTooltip';

  const search = listParams?.filters?.search;

  const tabState = useDashboardTabState({
    totalCount,
    isLoading,
    search,
  });

  if (isLoading) {
    return <QBDTLoader />;
  }

  if (tabState === DashboardTabStateEnum.NoSearchResults) {
    return (
      <Box __css={tableEmptyStateWrapperStyles}>
        <NoSearchResults term={search} />
      </Box>
    );
  }

  if (tabState === DashboardTabStateEnum.Empty) {
    return (
      <Box __css={tableEmptyStateWrapperStyles}>
        <DashboardEmptyState tabStatus={status} />
      </Box>
    );
  }

  return (
    <DashboardTable
      columns={columns}
      filters={listParams.filters}
      items={dashboardListItems}
      totalItems={totalCount}
      onChangeOrder={setOrder}
      onChangePage={setPage}
      selectionEnabled={selectionEnabled}
      getDisabledCheckboxTooltip={getDisabledCheckboxTooltip}
    />
  );
};
