import React from 'react';
import { RecordOf } from 'immutable';
import { MultiLineSelectBox, SmartIcon } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryMethodType } from 'src/utils/types';
import { getDeliveryMethodInfo } from 'src/pages/vendor/records';

type Props = {
  onClick: () => void;
  vendorName: string;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

export const VirtualCardDeliveryMethodOption = ({ onClick, vendorName, deliveryMethod }: Props) => (
  <MultiLineSelectBox onClick={onClick} data-testid="virtual-card-reissue-option">
    <MultiLineSelectBox.TopContentWrapper>
      <SmartIcon type="mastercard" width="2.4rem" height="2.4rem" />
      <MultiLineSelectBox.HeadingWrapper>
        <MultiLineSelectBox.Title>
          <MIFormattedText label="bills.pay.virtualCardRecovery.virtualCardOption.title" />
        </MultiLineSelectBox.Title>
        <MultiLineSelectBox.Description>
          <MIFormattedText label="bills.pay.virtualCardRecovery.virtualCardOption.subtitle" />
        </MultiLineSelectBox.Description>
      </MultiLineSelectBox.HeadingWrapper>
    </MultiLineSelectBox.TopContentWrapper>

    <MultiLineSelectBox.Separator />

    <MultiLineSelectBox.Info data-testid="virtual-card-option-info">
      {deliveryMethod &&
        getDeliveryMethodInfo({
          vendorName,
          deliveryMethod,
        })}
    </MultiLineSelectBox.Info>
  </MultiLineSelectBox>
);
