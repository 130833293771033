import * as React from 'react';
import styled from 'styled-components';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';
import { devices } from 'src/theme/AppDevices';

type Props = {
  onDone: () => any;
  onCancel: () => any;
  doneLabel: string;
  cancelLabel: string;
  isDisabled?: boolean;
  className?: string;
  isProcessing?: boolean;
  titleLabel?: string;
};

const FloatedButtons = ({
  className = '',
  onDone,
  onCancel,
  doneLabel,
  cancelLabel,
  isDisabled = false,
  isProcessing = false,
}: Props) => (
  <FloatEditButtonsContainer className={className}>
    {onCancel && (
      <MIButton
        size={CONSTS.BUTTON_SIZE.SMALL}
        variant={CONSTS.BUTTON_VARIANT.CANCEL}
        onClick={onCancel}
        label={cancelLabel}
        disabled={isDisabled}
      />
    )}
    <MIButton
      size={CONSTS.BUTTON_SIZE.SMALL}
      variant={CONSTS.BUTTON_VARIANT.PRIMARY}
      onClick={onDone}
      label={doneLabel}
      disabled={isDisabled}
      isProcessing={isProcessing}
    />
  </FloatEditButtonsContainer>
);

const FloatEditButtonsContainer = styled.div`
  height: 7rem;
  width: 100%;
  background-color: white;
  box-shadow: 0 -0.4rem 1.5rem 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1011;

  box-sizing: border-box;

  @media ${devices.desktop}, ${devices.tablet} {
    padding: 0 2.3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    top: 0;
    justify-content: space-between;
    padding: 0 1.6rem 0 0;
  }
  ${(props) => props.theme?.components?.MIFloatedEditDoneButtons?.ButtonsContainer}
`;

export default FloatedButtons;
