import { all, takeEvery, put, call } from 'redux-saga/effects';
import { createAction, createReducer, PayloadActionCreator } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import { ValidationErrors } from 'src/utils/types';
import { SagaAction } from 'src/helpers/redux/types';

export const ON_FAILURE = 'ON_FAILURE';
export const ON_SUCCESS = 'ON_SUCCESS';
export const ON_REQUEST = 'ON_REQUEST';
export const getValidationFailureActionName = (name: string) => `${name}_VALIDATION_FAILURE`;

export type Reducer<P, S> =
  | undefined
  | ((state: S, action: { type: string; payload: P; error?: any; meta?: any }) => any);

export type CreateApiCallSliceOptions<Payload, State, Result> = {
  api: (payload: Payload) => Promise<Result>;
  validate?: (payload: Payload, changes?: Partial<Payload>) => Promise<null | ValidationErrors<Payload>>;
  name: string;
  initialState?: State;
  reducers: {
    ON_SUCCESS?: Reducer<Result, State>;
    ON_FAILURE?: Reducer<any, State>;
    ON_REQUEST?: Reducer<Payload, State>;
    [key: string]: Reducer<any, State>;
  };
  sagas?: {
    ON_SUCCESS?: SagaAction;
    ON_FAILURE?: SagaAction;
    ON_REQUEST?: SagaAction;
    [key: string]: undefined | SagaAction;
  };
  selectors?: { [key: string]: (state: any, props?: any) => any };
};
export function createApiCallSlice<P, S, R = P>(options: CreateApiCallSliceOptions<P, S, R>) {
  const { name, api, validate, initialState, sagas, reducers, ...rest } = options;
  const successAction = createAction(`${name}_SUCCESS`, (payload, identifier, meta) => ({
    payload,
    meta: { ...meta, identifier },
  }));
  const failureAction = createAction(`${name}_FAILURE`, (payload, error, meta) => ({
    payload,
    error,
    meta: { ...meta, identifier: payload },
  }));
  const failureValidationAction = createAction(getValidationFailureActionName(name), (payload, error, meta) => ({
    payload,
    error,
    meta: { ...meta, identifier: payload },
  }));
  type PrepareAction = (
    ...args: any[]
  ) => {
    payload: P;
    meta: any;
  };
  type ActionType = {
    success?: any;
    failure?: any;
  } & PayloadActionCreator<ReturnType<PrepareAction>['payload'], string, PrepareAction>;

  const requestAction: ActionType = createAction<PrepareAction>(`${name}_REQUEST`, (payload, meta) => ({
    payload,
    meta: {
      ...meta,
      $async: {
        success: {
          type: successAction.type,
          resolution: 'resolve',
          meta: { identifier: payload },
        },
        failure: {
          type: failureAction.type,
          resolution: 'reject',
          meta: { identifier: payload },
        },
        validation: {
          type: failureValidationAction.type,
          resolution: 'reject',
          meta: { identifier: payload },
        },
      },
    },
  }));
  requestAction.success = successAction;
  requestAction.failure = failureAction;

  function* onRequested(action) {
    let data;
    const { $async, ...meta } = action.meta;
    try {
      if (validate) {
        const validationErrors = yield call(validate, action.payload);
        if (validationErrors) {
          return yield put(failureValidationAction(action.payload, { validationErrors }, meta));
        }
      }

      data = yield call(api, action.payload);
    } catch (e: any) {
      const validationErrors =
        e.responseData?.validationErrors &&
        mapValues(e.responseData.validationErrors, (e) => `serverErrors.${action.type}.${e}`);
      return yield put(failureAction(action.payload, { message: e.message, code: e.code, validationErrors }, meta));
    }

    return yield put(successAction(data, action.payload, meta));
  }
  const reducer = createReducer(initialState as S, (builder) => {
    const { ON_FAILURE, ON_SUCCESS, ON_REQUEST, ...restReducers } = reducers || {};
    if (ON_REQUEST) {
      restReducers[requestAction.type] = ON_REQUEST;
    }

    if (ON_SUCCESS) {
      restReducers[successAction.type] = ON_SUCCESS;
    }

    if (ON_FAILURE) {
      restReducers[failureAction.type] = ON_FAILURE;
      restReducers[failureValidationAction.type] = ON_FAILURE;
    }

    forEach(restReducers, (reducer: any, event) => builder.addCase(event, reducer));
  });

  return {
    *saga(): any {
      const { ON_FAILURE, ON_SUCCESS, ON_REQUEST, ...restSagas } = sagas || {};
      if (ON_REQUEST) {
        restSagas[requestAction.type] = ON_REQUEST;
      }

      if (ON_FAILURE) {
        restSagas[failureAction.type] = ON_FAILURE;
        restSagas[failureValidationAction.type] = ON_FAILURE;
      }

      if (ON_SUCCESS) {
        restSagas[successAction.type] = ON_SUCCESS;
      }

      const effects = mapValues(restSagas, (saga: any, event: any) => takeEvery(event, saga));
      yield all({
        [requestAction.type]: takeEvery(requestAction, onRequested),
        ...effects,
      });
    },
    reducer,
    actions: requestAction,
    initialState,
    dispatchers: (dispatch) => (params, meta) => dispatch(requestAction(params, meta)),
    ...rest,
  };
}
