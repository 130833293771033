import React from 'react';
import styled from 'styled-components';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import { close } from 'src/billpay/qbdt/services/qbdt';
import ErrorIllustration from 'src/images/qbo/bill-amount-limit-error.svg';
import { WizardIllustration } from 'src/billpay/qbdt/components/layout/WizardElement';

export function BillLimitAmountErrorPage() {
  return (
    <SyncErrorLayout
      illustration={ErrorIllustration}
      title="bills.pay.billAmountLimitError.title"
      text="bills.pay.billAmountLimitError.subtitle"
      buttonLabel="bills.pay.billAmountLimitError.okButton"
      buttonAction={close}
      hideHeader
    />
  );
}

const SyncErrorLayout = styled(QBDTSuccessLayout)`
  ${WizardIllustration} {
    height: 12rem;
    width: 12rem;
  }
`;
