import * as React from 'react';
import { CONSTS } from 'src/utils/consts';
import { FieldType, VendorType, FormType, ScreenMode } from 'src/utils/types';
import MIForm from 'src/components/common/MIForm';

type Props = {
  vendor: VendorType;
  mode?: ScreenMode;
  onChange: (arg0: FieldType) => void;
  validationErrors: Record<string, string>;
  formType?: FormType;
};

const VendorForm = ({
  vendor,
  validationErrors,
  onChange,
  mode = CONSTS.SCREEN_MODE.EDIT,
  formType = CONSTS.FORM_TYPE.WIZARD,
}: Props) => {
  const vendorFormControls = [
    {
      id: 'companyName',
      label: 'vendors.create.name.inputTitle',
      value: vendor.companyName,
      onChange,
      required: true,
      autoFocus: true,
      disabled: true,
      errorMessage: validationErrors.companyName,
      controlType: CONSTS.FORM_CONTROL_TYPE.TEXT,
      autocomplete: CONSTS.FORM_AUTOCOMPLETE.OFF,
    },
    {
      id: 'contactEmail',
      label: 'vendors.create.email.inputTitle',
      value: vendor.contactEmail,
      onChange,
      errorMessage: validationErrors.contactEmail,
      controlType: CONSTS.FORM_CONTROL_TYPE.TEXT,
      emptyLabel: 'vendors.form.contactEmailEmpty',
      autocomplete: CONSTS.FORM_AUTOCOMPLETE.OFF,
      privateData: true,
    },
  ];

  return <MIForm formType={formType} formControls={vendorFormControls} mode={mode} />;
};

export default VendorForm;
