import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Icon } from '@melio/billpay-design-system';
import { useBreak } from 'src/hoc';
import { ReactComponent as LeftArrowIcon } from 'src/images/general/left-arrow.svg';

type Props = {
  showBack?: boolean;
  children: React.ReactNode;
  trackAnalyticsOnGoBack?: VoidFunction;
};
export const VendorFlowContentCard = ({ showBack, children, trackAnalyticsOnGoBack }: Props) => {
  const history = useHistory();
  const { isDesktop } = useBreak();

  const handleGoBack = () => {
    if (trackAnalyticsOnGoBack) {
      trackAnalyticsOnGoBack();
    }

    history.goBack();
  };

  return (
    <Flex
      boxSizing="border-box"
      position="relative"
      p={isDesktop ? 'ds.2xl' : 'ds.xl'}
      mt="ds.xl"
      align="center"
      justify="center"
      direction="column"
      backgroundColor="ds.white"
      borderRadius="ds.lg"
      borderColor="ds.gray.500"
      borderWidth="0.1rem"
      borderStyle="solid"
      width={isDesktop ? '53rem' : '34.3rem'}
      mb={isDesktop ? '0' : 'ds.2xl'}
    >
      {showBack && (
        <Icon
          data-testid="unilateral-go-back"
          as={LeftArrowIcon}
          position="absolute"
          top="ds.xl"
          left="ds.xl"
          w="ds.xl"
          h="ds.xl"
          cursor="pointer"
          onClick={handleGoBack}
        />
      )}
      {children}
    </Flex>
  );
};
