import React, { useEffect, useState } from 'react';
import { Flex, Icon, Menu } from '@melio/billpay-design-system';

import { chakra } from 'src/theme/ds';
import analytics from 'src/services/analytics';
import { MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as ChevronDownIcon } from './icons/chevron-down.svg';
import { FiltersChangeInfo, MenuFilterItem } from './types';
import { DashboardFilterMenuList } from './components/DashboardFilterMenuList';
import { DashboardFilterSelectedOptions } from './components/DashboardFilterSelectedOptions';

type Props = {
  currentTabStatus: string;
  filterMenuItems: MenuFilterItem[];
  onChange: (change: FiltersChangeInfo) => void;
};

export const DashboardFilter = ({ currentTabStatus, filterMenuItems, onChange }: Props) => {
  const [hoveredMenuItemLabel, setHoveredMenuItemLabel] = useState<string>();

  useEffect(() => {
    if (currentTabStatus) {
      setHoveredMenuItemLabel(undefined);
    }
  }, [currentTabStatus]);

  const handleFiltersClick = (isOpen: boolean) => {
    analytics.trackAction('dashboard-list-filters-action', {
      clickTarget: !isOpen ? 'open' : 'close',
      status: currentTabStatus,
    });
  };

  return (
    <FiltersContainer>
      <StyledMenu isLazy closeOnSelect={false} onClose={() => setHoveredMenuItemLabel(undefined)}>
        {({ isOpen }) => (
          <>
            <FiltersButton as={FiltersButton} onClick={() => handleFiltersClick(isOpen)}>
              <Flex alignItems="center">
                <MIFormattedText label="paymentDashboard.filters.mainButtonTitle" />
                <Icon w="2.4rem" h="2.4rem" as={ChevronDownIcon} color="ds.gray.200" ml="ds.md" />
              </Flex>
            </FiltersButton>
            {isOpen ? (
              <DashboardFilterMenuList
                menuItems={filterMenuItems}
                onChange={onChange}
                onMenuItemHover={(label) => setHoveredMenuItemLabel(label)}
                hoveredMenuItemLabel={hoveredMenuItemLabel}
              />
            ) : null}
          </>
        )}
      </StyledMenu>
      <DashboardFilterSelectedOptions menuItems={filterMenuItems} onChange={onChange} />
    </FiltersContainer>
  );
};

const FiltersContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    gap: 'ds.lg',
  },
});

const FiltersButton = chakra(Menu.MenuButton, {
  baseStyle: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    gap: 'ds.md',
    p: '0.6rem 2rem',
    pr: 'ds.md',
    rounded: '2rem',
    border: '2px solid',
    borderColor: 'ds.gray.200',
    textStyle: 'ds.body1Medium',
    bgColor: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    zIndex: '1',
    _hover: {
      bgColor: 'ds.gray.500',
    },
  },
});

const StyledMenu = chakra(Menu, {
  baseStyle: {
    border: '1px solid',
    borderColor: 'ds.gray.400',
    boxShadow: 'ds.md',
  },
});
