import React from 'react';
import { Box, Flex, Icon, Link } from '@melio/billpay-design-system';
import { ReactComponent as CardIcon } from 'src/images/general/card-icon.svg';
import { ReactComponent as RightArrowIcon } from 'src/images/general/right-arrow.svg';
import { MIFormattedText } from 'src/utils/formatting';
import { useBreak } from 'src/hoc';
import { generatePath, useHistory } from 'react-router-dom';
import locations from 'src/pages/vendor/virtual-delivery-method/locations';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';
import { DeliveryMethodCardWrapper } from './DeliveryMethodCardWrapper';
import { DeliveryMethodCardContentWrapper } from './DeliveryMethodCardContentWrapper';
import { DeliveryMethodCardTitle } from './DeliveryMethodCardTitle';
import { DeliveryMethodSubTitle } from './DeliveryMethodCardSubTitle';

type Props = {
  token: string;
  payment?: PaymentType;
};

export const VirtualCardDeliveryMethodCard = ({ token, payment }: Props) => {
  const { isDesktop } = useBreak();
  const history = useHistory();

  const cardClickHandler = () => {
    vendorUnilateralAnalytics.trackSelectDeliveryMethod({
      paymentId: payment?.id,
      vendorEmail: payment?.vendor?.contactEmail,
      vendorId: payment?.vendorId,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
      option: DELIVERY_TYPE.VIRTUAL_CARD,
    });

    history.push(generatePath(locations.addVirtualCardMethod, { token }));
  };

  const learnMoreClickHandler = () => history.push(generatePath(locations.virtualCardLearnMore, { token }));

  return (
    <DeliveryMethodCardWrapper onClick={cardClickHandler} testId="unilateral-delivery-method-virtual-card">
      <Icon as={CardIcon} w="ds.2xl" h="ds.2xl" />
      <DeliveryMethodCardContentWrapper>
        <DeliveryMethodCardTitle label="vendor.unilateral.deliveryMethods.virtualCard.title" />
        <DeliveryMethodSubTitle label="vendor.unilateral.deliveryMethods.virtualCard.text" />
        <Box data-testid="what-single-use-card" as={Link} onClick={learnMoreClickHandler} textStyle="ds.body2" m="0">
          <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.hint" />
        </Box>
      </DeliveryMethodCardContentWrapper>
      {isDesktop && (
        <Flex align="center" justify="center">
          <Icon as={RightArrowIcon} w="2.4rem" height="2.4rem" />
        </Flex>
      )}
    </DeliveryMethodCardWrapper>
  );
};
