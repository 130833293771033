import React from 'react';
import { Box, Table, Tooltip } from '@melio/billpay-design-system';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { ReceiptStatus } from '../../../components/consts';

type Props = {
  status: ReceiptStatus;
  invoiceDate: string;
  totalAmount: number;
  onIconClick: VoidFunction;
};

const BillingFeeHistoryTableRow = ({ status, invoiceDate, totalAmount, onIconClick }: Props) => {
  const tooltipLabel =
    status === ReceiptStatus.Requested
      ? 'settings.billing.tooltip.receipt.success'
      : 'settings.billing.tooltip.receipt.regular';
  const iconClassName = status === ReceiptStatus.Requested ? 'icon-checkmark-icon' : 'icon-email-icon';
  const iconSX =
    status === ReceiptStatus.Requested
      ? {
          width: '1.6rem',
          height: '1.6rem',
          border: '1px solid',
          borderColor: 'ds.gray.100',
          borderRadius: '50%',
        }
      : {};

  return (
    <Table.Row>
      <Table.Cell>{invoiceDate}</Table.Cell>
      <Table.Cell isNumeric>
        <MIFormattedCurrency value={totalAmount} />
      </Table.Cell>
      <Table.Cell>
        <Tooltip placement="top" label={<MIFormattedText label={tooltipLabel} />}>
          <Box as="i" sx={iconSX} className={iconClassName} fontSize="1.6rem" cursor="pointer" onClick={onIconClick} />
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  );
};

export { BillingFeeHistoryTableRow };
