import * as React from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import { useBreak } from 'src/hoc';
import partnersIcon from 'src/images/outside/trusted.png';
import partnersIconMobile from 'src/images/outside/trustedMobile.png';

type Props = {
  showPartners?: boolean;
};

const OutsideFooter = ({ showPartners = true }: Props) => {
  const site = useSiteContext();
  const device = useBreak();

  return (
    <OutsideFooterContainer>
      {showPartners && (
        <React.Fragment>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.trustedBy" />
          <PartnersImage src={device.isMobile || device.isPhablet ? partnersIconMobile : partnersIcon} alt="partners" />
        </React.Fragment>
      )}
      <SupportContainer>
        <BoldText>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.support" />
        </BoldText>
        <FooterLink href={`mailto:${site.config.support.email}`}>{site.config.support.email}</FooterLink>
      </SupportContainer>
      <LinksContainer>
        <FooterLink href={site.config.agreementLinks.privacyPolicy}>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.privacyPolicy" />
        </FooterLink>
        <TextSeparator>|</TextSeparator>
        <FooterLink href={site.config.agreementLinks.userAgreement}>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.termsOfService" />
        </FooterLink>
      </LinksContainer>
    </OutsideFooterContainer>
  );
};

export default OutsideFooter;

const OutsideFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.background.wizard};
  }

  @media ${devices.mobile} {
    margin-bottom: 5rem;
  }
`;

const FooterLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.subtitle};
`;

const SupportContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.2rem;
`;

const LinksContainer = styled.div`
  display: flex;
`;

const TextSeparator = styled.span`
  margin: 0 1rem;
`;

const PartnersImage = styled.img`
  width: 45rem;
  margin: 2rem 0;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 32.5rem;
  }
`;

const BoldText = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-right: 0.8rem;
`;
