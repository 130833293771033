import React from 'react';
import { RecordOf } from 'immutable';
import { FAILED_PAYMENT_TYPE, DELIVERY_TYPE, PAYMENT_APPROVAL_STATUS } from 'src/utils/consts';
import { MIFormattedDate } from 'src/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { AccountType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { PaymentInfoHint } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoHint';

type Props = {
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: RecordOf<AccountType>;
};

const DeliveryDateInfoHint = ({ payment, deliveryMethod, fundingSource }: Props) => {
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const paymentIsFailedToDeliver = payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;
  const date = <MIFormattedDate year={undefined} date={payment.scheduledDate} />;
  let hint;
  let hintValues = {};
  if (isMicrodeposit && isApprovalRequired) {
    hint = 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountApprovalRequiredHint';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    hintValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isUnilateral && paymentIsFailedToDeliver) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralFailedToDeliver';
    hintValues = {
      date: <MIFormattedDate year={undefined} date={payment.deliveryEta} />,
    };
  } else if (isApprovalRequired && isUnilateral) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralApprovalRequiredHint';
    hintValues = {
      date,
    };
  } else if (isMicrodeposit) {
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    hint = 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountHint';
    hintValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isApprovalRequired) {
    hint = 'payBillPaymentActivity.deliveryDate.approvalRequiredHint';
    hintValues = {
      date,
    };
  } else if (isUnilateral) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralHint';
    hintValues = {
      date,
    };
  } else if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH) {
    hint = 'payBillPaymentActivity.deliveryDate.achHint';
  }

  if (hint) {
    return <PaymentInfoHint hint={hint} hintValues={hintValues} testId="delivery-date-info-hint" />;
  }

  return null;
};

export default DeliveryDateInfoHint;
