import config from 'src/config';

import bridge from './bridge';
import http from './http';

// eslint-disable-next-line import/no-mutable-exports
let adapter = bridge;
if (config.qbdtAdapter === 'http') {
  adapter = http;
}

export default adapter;
