import React, { PropsWithChildren } from 'react';
import { chakra } from '@chakra-ui/system';
import analytics from 'src/services/analytics';
import { VStack } from '@melio/billpay-design-system';

type Props = {
  id?: string;
  size?: ModalSize;
  w?: string;
  onCloseClick?: (event: MouseEvent) => void;
};

export enum ModalSize {
  Custom = 'custom',
  Regular = 'regular',
  Large = 'large',
}

const getModalWidth = ({ size, w }: { size: ModalSize; w?: string }) => {
  switch (size) {
    case ModalSize.Regular:
      return '57.6rem';
    case ModalSize.Large:
      return '67.4rem';
    case ModalSize.Custom:
      return w || '57.6rem';
    default:
      return '57.6rem';
  }
};

const getCloseButtonSX = ({ size }: { size: ModalSize }) => {
  if (ModalSize.Custom === size) {
    return {
      w: '2.4rem',
      h: '2.4rem',
      fontSize: '2.4rem',
    };
  }

  return {};
};

export const Modal = ({ id, size = ModalSize.Regular, w, onCloseClick, children }: PropsWithChildren<Props>) => {
  const doOnClose = (event) => {
    onCloseClick && onCloseClick(event);
    analytics.trackAction(`modal-close${id ? `-${id}` : ''}`);
  };

  const modalWidth = getModalWidth({ size, w });
  const closeSX = getCloseButtonSX({ size });

  return (
    <ModalWrapper data-testid={id}>
      <Backdrop onClick={doOnClose} />
      <ModalContainer w={modalWidth} data-testid="modal-message-container" onClick={(event) => event.stopPropagation()}>
        {onCloseClick && (
          <CloseButtonWrapper onClick={doOnClose} data-testid="modal-close" sx={closeSX}>
            <i className="icon-close-icon" />
          </CloseButtonWrapper>
        )}

        <ModalContainerContent spacing="ds.2xl">{children}</ModalContainerContent>
      </ModalContainer>
    </ModalWrapper>
  );
};

const ModalWrapper = chakra('div', {
  baseStyle: {
    top: 0,
    left: 0,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'full',
    height: 'full',
    zIndex: 9999,
  },
});

const Backdrop = chakra('div', {
  baseStyle: {
    position: 'absolute',
    width: 'full',
    height: 'full',
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  },
});

const ModalContainer = chakra('div', {
  baseStyle: {
    position: 'relative',
    backgroundColor: 'ds.white',
    maxHeight: 'calc(100vh - 8rem)',
    overflowY: 'auto',
    zIndex: 1,
    border: '1px solid',
    borderColor: 'ds.gray.600',
    borderRadius: 'ds.lg',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    padding: 'ds.2xl',
  },
});

const ModalContainerContent = chakra(VStack, {
  baseStyle: {
    alignItems: 'initial',
  },
});

const CloseButtonWrapper = chakra('div', {
  baseStyle: {
    position: 'absolute',
    fontSize: '2rem',
    width: 'ds.xl',
    height: 'ds.xl',
    top: 'ds.xl',
    right: 'ds.xl',
    float: 'right',

    i: {
      cursor: 'pointer',
    },

    color: 'ds.gray.300',
    _hover: {
      color: 'ds.gray.100',
    },
  },
});
