import React from 'react';
import styled from 'styled-components';
import { CONSTS } from 'src/utils/consts';
import MIEnhancedDropDown from 'src/components/common/DropDown/MIEnhancedDropDown';
import { MITextInput } from 'src/components/common/MITextInput';
import { Expandable } from 'src/utils/types';
import { InlineFieldGroup } from './InlineFieldGroup';
import { ModelViewField } from './use-form';

export type InlineSelectFieldProps = {
  model?: ModelViewField<string>;
  value?: string;
  label: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (change: Expandable<{ value: string }>) => any;
  options: { label: string; value: string }[];
  errorMessage?: string;
  viewOnly?: boolean;
  createLabel?: string;
  allowCustom?: boolean;
  privateData?: boolean;
};

export function InlineSelectField(props: InlineSelectFieldProps) {
  const { model, id, label, options, value, viewOnly, disabled, onChange, required, privateData, ...rest } = props;
  const innerId = id || model?.id;
  const innerOnChange = onChange || model?.onChange;
  return (
    <InlineFieldGroup>
      <FormSelect>
        {viewOnly ? (
          <MITextInput
            label={label}
            value={model?.value || value}
            id={innerId}
            size={CONSTS.TEXT_INPUT_SIZE.INLINE}
            required={required}
            viewOnly
            disabled={disabled}
            privateData={privateData}
          />
        ) : (
          <MIEnhancedDropDown
            {...rest}
            label={label}
            id={innerId}
            onChange={innerOnChange}
            size={CONSTS.TEXT_INPUT_SIZE.INLINE}
            options={options}
            value={model?.value || value}
            required={required}
            errorMessage={model?.error}
            privateData={privateData}
          />
        )}
      </FormSelect>
    </InlineFieldGroup>
  );
}

const FormSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
