import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Image, HStack, Box, Button, VStack } from '@melio/billpay-design-system';
import { getOrgId } from 'src/redux/user/selectors';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { pushNotification } from 'src/services/notifications/notificationService';
import { useDashboardListParams } from 'src/billpay/qbdt/pages/dashboard/hooks/useDashboardListParams';
import { DashboardTabStatusEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import ErrorIcon from 'src/billpay/qbdt/pages/dashboard/images/error.svg';
import { Modal } from 'src/theme/ds';
import * as qbdtAPI from 'src/services/api/qbdt';
import * as qbdtService from 'src/billpay/qbdt/services/qbdt';
import analytics from 'src/services/analytics';
import { logger } from 'src/services/loggers';

type Props = {
  dismiss: () => void;
  companyName: string;
  totalAmount: string;
  paymentId?: string;
  billIds?: string[];
};

export const CancelPaymentDialog = ({ dismiss, companyName, totalAmount, paymentId, billIds }: Props) => {
  const orgId = useSelector(getOrgId);
  const [, { setStatus }] = useDashboardListParams();
  const [loading, setLoading] = useState<boolean>(false);

  const analyticsData = {
    paymentId,
    billIds: billIds?.join(', '),
  };

  const handleCancelPaymentClick = async () => {
    analytics.trackAction('cancel-payment-submit', analyticsData);

    setLoading(true);

    try {
      const res = await qbdtAPI.qbdtDeletePaymentById(orgId, paymentId);
      if (res?.paymentExternalId) {
        logger.warn(`CancelPaymentDialog.handleCancelPaymentClick(): paymentExternalId is not present. ${paymentId}`);
        await qbdtService.deletePayment(res.paymentExternalId);
      }

      analytics.trackAction('cancel-payment-success', analyticsData);

      setLoading(false);
      dismiss();

      pushNotification({
        type: CONSTS.NOTIFICATION_VARIANT.SUCCESS,
        msg: 'paymentDashboard.cancelPayment.toastMessage',
      });

      setStatus(DashboardTabStatusEnum.Unpaid);
    } catch (error: any) {
      analytics.trackAction('cancel-payment-failure', { ...analyticsData, reason: error?.message });

      setLoading(false);
      dismiss();
    }
  };

  return (
    <Modal id="cancel-payment-dialog" onCloseClick={dismiss}>
      <VStack spacing="ds.xl" align="flex-start" color="ds.black">
        <Image src={ErrorIcon} width="ds.2xl" height="ds.2xl" />

        <VStack spacing="ds.md" align="flex-start">
          <Box textStyle="ds.h5">
            <MIFormattedText label="paymentDashboard.cancelPayment.dialogTitle" />
          </Box>

          <Box textStyle="ds.body1" fontWeight="ds.regular">
            <MIFormattedText
              label="paymentDashboard.cancelPayment.dialogSubTitle"
              values={{
                companyName,
                amount: <MIFormattedCurrency value={totalAmount} />,
              }}
            />
          </Box>

          <Box textStyle="ds.body1" fontWeight="ds.regular" pt="ds.xl">
            <MIFormattedText label="general.areYouSure" />
          </Box>
        </VStack>

        <HStack w="full" spacing="ds.xl" justify="flex-end" boxSizing="border-box" p="2rem 0.4rem 0.4rem">
          <Button variant="secondary" size="md" onClick={dismiss} minW="11.8rem" isDisabled={loading}>
            <MIFormattedText label="general.no" />
          </Button>

          <Button variant="primary" size="md" onClick={handleCancelPaymentClick} minW="11.8rem" isLoading={loading}>
            <MIFormattedText label="general.yes" />
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
