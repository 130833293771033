import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { ORDER_DIRECTION } from '../../../consts';
import { iconStyle } from './styles';
import { ReactComponent as ArrowUpIcon } from '../../images/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../images/arrow-down.svg';

type Props = {
  accessor: string;
  orderBy?: string;
  orderDirection?: ORDER_DIRECTION;
};

export const DashboardOrderArrow = ({ accessor, orderBy, orderDirection }: Props) => {
  const isActive = accessor === orderBy;
  const arrowUpTestId = `table-order-arrow-up-${accessor}${isActive ? '-active' : ''}`;
  const arrowDownTestId = `table-order-arrow-down-${accessor}${isActive ? '-active' : ''}`;

  return (
    <Box className="order-arrow" as="span" __css={iconStyle({ isActive })}>
      {accessor !== orderBy || orderDirection === ORDER_DIRECTION.DESC ? (
        <ArrowDownIcon data-testid={arrowDownTestId} />
      ) : (
        <ArrowUpIcon data-testid={arrowUpTestId} />
      )}
    </Box>
  );
};
