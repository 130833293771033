import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import unionBy from 'lodash/unionBy';
import { Box, Flex } from '@melio/billpay-design-system';

import { chakra } from 'src/theme/ds';
import { debounce } from 'src/utils/debounce';
import { ExtendedSelectOption } from 'src/utils/types';
import { MIFormattedText } from 'src/utils/formatting';

import { CheckboxSelect } from './CheckboxSelect';
import { MultiSelectSearchInput } from './MultiSelectSearchInput';

const SEARCH_DEBOUNCE_TIME = 250;

enum SelectType {
  Radio,
  Checkbox,
}

type Props = {
  options: ExtendedSelectOption[];
  type?: SelectType;
  searchable: boolean;
  searchPlaceholder?: string;
  clearLabelMessage?: string;
  noResultMessage?: string;
  onChange: (value: ExtendedSelectOption[]) => void;
  onSearch?: VoidFunction;
};

const MultiSelect = ({
  options,
  type = SelectType.Checkbox,
  searchable,
  searchPlaceholder = 'paymentDashboard.filters.menuSearch.placeholder',
  clearLabelMessage = 'paymentDashboard.filters.clearLabelMessage',
  noResultMessage = 'paymentDashboard.filters.noResultsMessage',
  onChange,
  onSearch,
}: Props) => {
  const intl = useIntl();
  const [filteredOptions, setFilteredOptions] = useState<ExtendedSelectOption[]>(options);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hasSelectedOptions, setHasSelectedOptions] = useState<boolean>(false);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const normalizedQuery = query?.toLowerCase()?.trim();

    if (!normalizedQuery) {
      // no search query: show all options
      setFilteredOptions(options);
      onSearch?.();
      return;
    }

    const foundOptions = options.filter(({ label, values }) => {
      const formatedOptionLabel = intl.formatMessage({ id: label }, values);

      if (!formatedOptionLabel) {
        return false;
      }

      return formatedOptionLabel.toLowerCase().trim().includes(normalizedQuery);
    });

    setFilteredOptions(foundOptions);
    onSearch?.();
  };

  useEffect(() => {
    handleSearch(searchQuery);
    setHasSelectedOptions(options.some(({ value }) => value));
  }, [options]);

  const handleSelection = (updatedOptions) => {
    onChange(unionBy(updatedOptions, options, ({ label, values }) => (values ? values?.vendorName : label)));
  };

  const handleClearAll = () =>
    hasSelectedOptions && handleSelection(options.map((option) => ({ ...option, selected: false, value: false })));

  const debouncedHandleSearch = debounce(handleSearch, SEARCH_DEBOUNCE_TIME);

  return (
    <MultiSelectWrapper>
      <MultiSelectContentWrapper pt={searchable ? '0.7rem' : 0}>
        {searchable && (
          <MultiSelectSearchInput
            placeholder={intl.formatMessage({ id: searchPlaceholder })}
            onChange={debouncedHandleSearch}
          />
        )}
        {type === SelectType.Checkbox && <CheckboxSelect options={filteredOptions} onChange={handleSelection} />}
        {!filteredOptions.length && (
          <NoResultMessage>
            <MIFormattedText label={noResultMessage} />
          </NoResultMessage>
        )}
      </MultiSelectContentWrapper>
      <Divider />
      <ClearLabel
        color={hasSelectedOptions ? 'ds.blue.100' : 'ds.gray.300'}
        cursor={hasSelectedOptions ? 'pointer' : 'initial'}
        onClick={handleClearAll}
      >
        <MIFormattedText label={clearLabelMessage} />
      </ClearLabel>
    </MultiSelectWrapper>
  );
};

export { MultiSelect };

const MultiSelectWrapper = chakra(Box, {
  baseStyle: {
    bgColor: 'ds.white',
    border: '1px solid',
    borderColor: 'ds.gray.400',
    boxShadow: 'ds.md',
  },
});

const MultiSelectContentWrapper = chakra(Box, {
  baseStyle: {
    w: '22.3rem',
  },
});

const NoResultMessage = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'ds.2xl',
    color: 'ds.gray.200',
    textStyle: 'ds.body2',
  },
});

const Divider = chakra(Box, {
  baseStyle: {
    h: '1px',
    w: 'full',
    bgColor: 'ds.gray.600',
  },
});

const ClearLabel = chakra(Flex, {
  baseStyle: {
    flexDir: 'column',
    justifyContent: 'center',
    w: 'calc(22.3rem - (1.4rem * 2))',
    p: '1.2rem 1.4rem',
    textStyle: 'ds.body2',
    userSelect: 'none',
  },
});
