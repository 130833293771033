import * as React from 'react';

import { isLoginFromForest } from '../../utils/user-utils';
import config from '../../config';
import analytics from '../../services/analytics';

class Segment extends React.Component {
  constructor(props) {
    super(props);
    if (this.isSegmentAllowed && window.analytics && window.analytics.load) {
      window.analytics.load(config.services.segment.key);
    }
  }

  componentDidUpdate() {
    if (!this.isSegmentAllowed) {
      analytics.reset(false);
    }
  }

  get isSegmentAllowed() {
    return !isLoginFromForest() && config.services.segment.shouldLoad;
  }

  render() {
    return null;
  }
}

export default Segment;
