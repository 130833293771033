import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import analytics from 'src/services/analytics';

type Props = {
  eventPage: string;
};

const useUpdateVendorEmail = ({ eventPage }: Props) => {
  const orgId = useSelector(getOrgId);
  const vendorActions = useStoreActions(vendorsStore);

  const [loading, setLoading] = useState(false);

  const updateVendorEmail = async ({ id, email }: { id: number; email: string }) => {
    analytics.track(eventPage, 'update-vendor-email', { vendorId: id });

    let success = false;

    setLoading(true);
    try {
      await vendorActions.update({ orgId, id, contactEmail: email });
      success = true;
      analytics.track(eventPage, 'update-vendor-email-success', { vendorId: id });
    } catch (e: any) {
      analytics.track(eventPage, 'update-vendor-email-failure', { vendorId: id, reason: e?.message || '' });
    } finally {
      setLoading(false);
    }

    return { success };
  };

  const updateVendorEmailsBatch = async (values: { id: number; email: string }[]) => {
    const vendorIds = values.map(({ id }) => id);
    const data = values.reduce((acc, { id, email }) => ({ ...acc, [id]: { contactEmail: email } }), {});

    analytics.track(eventPage, 'update-vendor-email-batch', { vendorIds });

    setLoading(true);
    try {
      await vendorActions.updateBatch({ orgId, data });
      analytics.track(eventPage, 'update-vendor-email-batch-success', { vendorIds });
    } catch (e: any) {
      analytics.track(eventPage, 'update-vendor-email-batch-failure', { vendorIds, reason: e?.message || '' });
    } finally {
      setLoading(false);
    }
  };

  return { loading, updateVendorEmail, updateVendorEmailsBatch };
};

export { useUpdateVendorEmail };
