import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { MITextInput } from 'src/components/common/MITextInput';
import { MIFormattedText } from 'src/utils/formatting';
import { getOrgId } from 'src/redux/user/selectors';
import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import { PaymentType, FieldType, BillType } from 'src/utils/types';
import { emailVendorOnPaymentSchedule } from 'src/utils/vendors';
import { isEnterPressed } from 'src/utils/events';
import { CONSTS } from 'src/utils/consts';
import vendorApi from 'src/services/api/vendors';

type Props = {
  dismiss: () => void;
  onSuccess: () => void;
  payment: PaymentType;
  bill: BillType;
  eventPage: string;
};

const CollectVendorEmailModalMessage = ({ dismiss, onSuccess, payment, bill, eventPage }: Props) => {
  const orgId = useSelector(getOrgId);
  const [contactEmail, changeContactEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    contactEmail: null,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const vendorName = bill.vendor.companyName;
  const pendingApproval = payment.approvalDecisionStatus === CONSTS.PAYMENT_APPROVAL_STATUS.PENDING;

  const handleCollectVendorEmailModalCancel = () => {
    analytics.track(eventPage, 'vendor-email-modal-closed');
    dismiss();
  };

  const handleCollectVendorEmailModalSubmit = () => {
    analytics.track(eventPage, 'vendor-email-modal-save');
    let validationErrors;

    if (isEmpty(contactEmail)) {
      validationErrors = {
        contactEmail: 'inputErrors.vendor.contactEmail.any.empty',
      };
      setValidationErrors(validationErrors);
      analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
    } else {
      validationErrors = getValidationErrors('vendor', { contactEmail }, ['contactEmail']);
      setIsProcessing(true);
      setValidationErrors(validationErrors);
      if (isValidationOk(validationErrors)) {
        vendorApi
          .editVendorById({
            orgId,
            id: bill.vendor.id,
            params: { contactEmail },
          })
          .then(() => {
            emailVendorOnPaymentSchedule(payment, orgId, contactEmail);
            setIsProcessing(false);
            dismiss();
            onSuccess();
            analytics.track(eventPage, 'vendor-email-modal-save-success');
          });
      } else {
        setIsProcessing(false);
        analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
      }
    }
  };

  const onChange = ({ value }: FieldType) => {
    changeContactEmail(value);
  };

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      handleCollectVendorEmailModalSubmit();
    }
  };

  return (
    <ModalMessage
      titleComponent={
        <React.Fragment>
          <div className="title">
            <MIFormattedText
              label="bills.pay.collectVendorEmailModal.title"
              values={{
                vendorName: <StyledVendorName>{vendorName}</StyledVendorName>,
              }}
            />
          </div>
          <div className="text">
            <MIFormattedText label="bills.pay.collectVendorEmailModal.subTitle" values={{ vendorName }} />
          </div>
          <PendingNotification className="text">
            {pendingApproval && <MIFormattedText label="bills.pay.collectVendorEmailModal.pending" />}
          </PendingNotification>
        </React.Fragment>
      }
      contentComponent={
        <FormContainer onKeyDown={onKeyPressed}>
          <MITextInput
            id="contactEmail"
            label="bills.pay.collectVendorEmailModal.emailLabel"
            placeholder="bills.pay.collectVendorEmailModal.emailPlaceholder"
            value={contactEmail}
            autoFocus
            required
            type="email"
            onChange={onChange}
            errorMessage={validationErrors.contactEmail}
            privateData
          />
        </FormContainer>
      }
      buttonLabel="bills.pay.collectVendorEmailModal.sendEmail"
      onButtonClick={handleCollectVendorEmailModalSubmit}
      onCloseClick={handleCollectVendorEmailModalCancel}
      isLoading={isProcessing}
    />
  );
};

export default CollectVendorEmailModalMessage;

const FormContainer = styled.div`
  width: 100%;

  #contactEmail {
    font-size: ${(props) => props.theme.text.size.regular};
  }
`;

const StyledVendorName = styled.span`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};

  ${(props) => props.theme?.pages?.PayBillSuccessPage?.StyledVendorName}
`;

const PendingNotification = styled.div`
  margin-top: 1rem;
`;
