import React from 'react';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { Flex, HStack, Icon, Text, VStack } from '@melio/billpay-design-system';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import { ReactComponent as SuccessIllustration } from 'src/images/general/action-done-icon.svg';
import { ReactComponent as BankAccountIllustration } from 'src/images/general/bank-account-icon.svg';
import { ReactComponent as CalendarIllustration } from 'src/images/get-pro/calendar.svg';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryMethodType, PaymentType, VirtualCardType } from 'src/utils/types';
import { useStateChangedAnalytics, VirtualCardPaymentState } from 'src/pages/vendor/virtual-card-details/hooks';

type Props = {
  payment?: PaymentType;
  deliveryMethod?: DeliveryMethodType;
  virtualCard?: VirtualCardType;
};

export const NewDeliveryMethodProcessedPage = ({ payment, deliveryMethod, virtualCard }: Props) => {
  const { deliveryEta } = payment || {};
  useStateChangedAnalytics({
    payment,
    virtualCard,
    paymentState: VirtualCardPaymentState.NewDeliveryMethodProcessed,
  });

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" justify="center" align="start" gap="ds.xl">
          <Icon as={SuccessIllustration} w="ds.2xl" h="ds.2xl" />
          <ContentTitle label="vendor.unilateral.virtualCardDetails.newDeliveryMethodProcessed.title" />
        </Flex>
        <Divider h="ds.xs" my="ds.2xl" />
        <VStack gap="ds.xl" w="100%" align="start">
          <Text color="ds.gray.200" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.unilateral.virtualCardDetails.newDeliveryMethodProcessed.receivedPayment" />
          </Text>
          <HStack gap="ds.lg">
            <Icon as={BankAccountIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="payBillPaymentActivity.deliveryMethod.achLabel" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedText
                  label="vendor.unilateral.virtualCardDetails.newDeliveryMethodForPayment.vendorReceive.bankAccount"
                  values={{
                    digits: getAccountNumber4digits(deliveryMethod?.bankAccount) || '',
                  }}
                />
              </Text>
            </Flex>
          </HStack>
          <HStack gap="ds.lg">
            <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardDetails.newDeliveryMethodProcessed.deliveryOn" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedDate date={deliveryEta} />
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
