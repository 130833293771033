import React from 'react';
import { useIntl } from 'react-intl';
import Dialog from 'src/components/common/QBOMIDialog';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/utils/consts';

type Props = {
  errorCode: string;
  onCloseModal: () => void;
};

export const ValidateCardDetailsErrorModal = ({ errorCode, onCloseModal }: Props) => {
  const intl = useIntl();
  const hasMessage = !!intl.messages[`server.${errorCode}`];
  const subtitle = hasMessage ? `server.${errorCode}` : 'server.ERR';

  return (
    <Dialog
      type={DIALOG_TYPE.ALERT}
      variant={DIALOG_VARIANTS.ERROR}
      title="onboarding.fundingSources.card.notSupportedTitle"
      subtitle={subtitle}
      cancelButtonText="dialogs.buttons.close"
      onCancelAction={onCloseModal}
    />
  );
};
