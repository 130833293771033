import { Box, VStack, Text, Link } from '@melio/billpay-design-system';
import React from 'react';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';

type Props = {
  amount: number;
  vendorEmail: string;
  organizationEmail: string;
  organizationName: string;
};

export const PaymentDetails = ({ amount, organizationEmail, vendorEmail, organizationName }: Props) => (
  <VStack gap="ds.xl" align="start" width="100%">
    <Text textStyle="ds.body2" fontWeight="ds.medium" textTransform="uppercase" color="ds.gray.200" m="0">
      <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.paymentDetails" />
    </Text>
    <VStack gap="ds.md" align="start">
      <Text textStyle="ds.body2" color="ds.gray.100" m="0">
        <MIFormattedText
          label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.amount"
          values={{
            amount: <MIFormattedCurrency value={amount} />,
          }}
        />
      </Text>
      <Box>
        <Text textStyle="ds.body2" color="ds.gray.100" m="0">
          <MIFormattedText
            label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.deliverTo"
            values={{
              vendorEmail,
            }}
          />
        </Text>
        <Text textStyle="ds.body2" color="ds.gray.200" m="0">
          <MIFormattedText
            label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.changeEmail"
            values={{
              link: (
                <Link href={`mailto:${organizationEmail}`}>
                  <MIFormattedText
                    label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.contactTo"
                    values={{
                      payorName: organizationName,
                    }}
                  />
                </Link>
              ),
            }}
          />
        </Text>
      </Box>
      <Text textStyle="ds.body2" color="ds.gray.100" m="0">
        <MIFormattedText label="vendor.pushToDebit.virtualCard.confirm.deliveryETA" />
      </Text>
    </VStack>
  </VStack>
);
