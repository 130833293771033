import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import { useDispatch } from 'react-redux';
import { featureFlags } from '@melio/shared-web';

import { MIFormattedText } from 'src/utils/formatting';
import { AccountType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { setFaqPanel } from 'src/redux/payBillWizard/actions';
import { FeatureFlags } from 'src/utils/feature-flags';

import { isCheckFeeOpen, featureFlagValues as checkFlagValues } from './utils';

const STORAGE_NOTICE = 'qb-check-notification-notice';

/**
 * Temp code until fee in check
 * Shows notification of future fee in select delivery method
 */
type Props = {
  selectedFundingSource?: RecordOf<AccountType> | undefined;
  batchData?: Record<string, any>;
  batchFundingSources?: AccountType[];
  className?: string;
};

const NotificationDM = ({ selectedFundingSource, batchData, batchFundingSources, className }: Props) => {
  const dispatch = useDispatch();
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlags.CheckFees, checkFlagValues.CLOSED);
  const [notificationFlag] = featureFlags.useFeature(FeatureFlags.CheckFeeNotification, false);
  const notificationFirstTime = !localStorage.getItem(STORAGE_NOTICE);
  const isBatch = !!batchData;
  const [isOpen, setIsOpen] = useState(notificationFlag && notificationFirstTime);

  const hasBatchCheckDM = useMemo(
    () =>
      isBatch
        ? batchData?.some((bill: any) => {
            const fundingSource = batchFundingSources?.find((fs) => fs.id === bill.payment?.fundingSourceId);
            const isCheck = bill.payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;

            const isAch = fundingSource?.fundingType === CONSTS.FUNDING_TYPE.ACH;

            return isCheck && isAch;
          })
        : false,
    [batchData]
  );

  const isACH = isBatch ? hasBatchCheckDM : selectedFundingSource?.fundingType === CONSTS.FUNDING_TYPE.ACH;
  const shouldShowNshowotification = isOpen && !isCheckFeeOpen(checkFeeFlag) && isACH;

  useEffect(() => {
    if (shouldShowNshowotification) {
      localStorage.setItem(STORAGE_NOTICE, 'visible');
    }
  }, [shouldShowNshowotification]);

  if (!shouldShowNshowotification) return null;

  const openLearnMore = () => {
    dispatch(setFaqPanel({ isOpen: true, focusedQuestion: 16 }));
  };

  const onClose = () => setIsOpen(false);

  const values = {
    learnMoreLink: (
      <LearnMoreLink onClick={openLearnMore}>
        <MIFormattedText label="deliveryMethods.notDefinedInfo.check.learnMoreLink" />
      </LearnMoreLink>
    ),
  };

  return (
    <StyledCard className={className}>
      <QBONotificationCard
        type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
        subtitle={{
          label: 'deliveryMethods.notDefinedInfo.check.notification',
          values,
        }}
        onClose={onClose}
      />
    </StyledCard>
  );
};

export { NotificationDM };

const StyledCard = styled.div`
  margin: 0 0 3.5rem 0;
  a {
    cursor: pointer;
  }
`;

const LearnMoreLink = styled.a`
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
`;
