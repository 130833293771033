import React, { useState } from 'react';
import { CardNumberElement } from '@basis-theory/basis-theory-react';
import { CardNumberElement as CardNumberElementType } from '@basis-theory/basis-theory-js/types/elements/elements';
import { useIntl } from 'react-intl';
import { Flex } from '@melio/billpay-design-system';
import { BasisTheoryCardBrandType } from 'src/billpay/qbdt/components/basis-theory/brands-logos/types';
import { FieldErrors } from 'src/billpay/qbdt/components/basis-theory/fields/common/FieldErrors';
import { FieldInputWrapper } from 'src/billpay/qbdt/components/basis-theory/fields/common/FieldInputWrapper';
import { FieldTitle } from 'src/billpay/qbdt/components/basis-theory/fields/common/FieldTitle';
import { ELEMENT_COMMON_STYLE } from 'src/billpay/qbdt/components/basis-theory/fields/consts';
import { useErrorValidations } from 'src/billpay/qbdt/components/basis-theory/fields/hooks/useErrorValidations';
import { CardFieldProps } from 'src/billpay/qbdt/components/basis-theory/types';
import { useHandleCardNumberChange } from './hooks/useHandleCardNumberChange';

type Props = CardFieldProps<CardNumberElementType> & {
  onCardBrandChange(cardBrand: BasisTheoryCardBrandType): void;
};

export const CardNumberField = ({ onCardBrandChange, onError, attemptedToLink, elementRef, onReady }: Props) => {
  const [focus, setFocus] = useState(false);
  const intl = useIntl();

  const onBlur = () => {
    setFocus(false);
  };

  const { handleChange: onChange, handleBlur, shouldShowError, errors, empty } = useErrorValidations({
    onError,
    onBlur,
    attemptedToLink,
  });

  const { handleChange } = useHandleCardNumberChange({
    onChange,
    onCardBrandChange,
  });

  const handleFocus = () => {
    setFocus(true);
  };

  const placeholder = intl.formatMessage({ id: 'linkCard.cardNumberPlaceholder' });

  return (
    <Flex direction="column" w="full">
      <FieldTitle id="cn" label="linkCard.cardNumberTitle" />
      <FieldInputWrapper error={shouldShowError} focus={focus}>
        <CardNumberElement
          id="card_number"
          ref={elementRef}
          onReady={onReady}
          style={ELEMENT_COMMON_STYLE}
          iconPosition="none"
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FieldInputWrapper>
      {shouldShowError && (
        <FieldErrors
          testId="card-number-errors"
          fieldErrors={errors}
          emptyLabel="linkCard.errors.cardNumber.empty"
          invalidLabel="linkCard.errors.cardNumber.invalid"
          empty={empty}
        />
      )}
    </Flex>
  );
};
