import React from 'react';
import { Link } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { BatchBillType } from 'src/modules/regular-batch-payments/types/store-types';
import { InvoiceNumberCell } from './SubCells/InvoiceNumberCell';
import { MainRowType } from '../withCellWrapper';
import { useExpandRow } from '../hooks/useExpandRow';

type BillCellProps = { bills: BatchBillType[]; row: MainRowType };

export const BillsCell = ({ bills, row }: BillCellProps) => {
  const isBulkPayment = bills?.length > 1;

  const { toggleRowExpand } = useExpandRow({
    row,
    onClick: (row as any)?.getToggleRowExpandedProps()?.onClick,
  });

  if (isBulkPayment) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link onClick={toggleRowExpand} data-testid={`bulk-row-bills-link_${row.id}`}>
        <MIFormattedText
          label={
            row.isExpanded
              ? 'regularBatchPayments.cells.bills.hideBills'
              : 'regularBatchPayments.cells.bills.billsCount'
          }
          values={{ billsCount: bills.length }}
        />
      </Link>
    );
  }

  return <InvoiceNumberCell invoiceNumber={bills[0].invoiceNumber} />;
};
