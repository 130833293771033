type UseDashboardTablePaginationParams = {
  totalItems: number;
  limit: string;
  start: string;
};

export const useDashboardTablePagination = ({ totalItems, limit, start }: UseDashboardTablePaginationParams) => {
  const showPagination = totalItems > Number(limit);
  const currentPage = Number(start) / Number(limit);
  const pageCount = Math.ceil(totalItems / Number(limit));

  const lastRequestedItemIndex = Number(start) + Number(limit);
  const itemFromIndex = Number(start) + 1;
  const itemToIndex = lastRequestedItemIndex > totalItems ? totalItems : lastRequestedItemIndex;

  return {
    showPagination,
    currentPage,
    pageCount,
    itemFromIndex,
    itemToIndex,
  };
};
