import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { CONSTS } from 'src/utils/consts';
import { CheckType } from 'src/utils/types';
import vendorsStore from 'src/modules/vendors/vendors-store';
import WarningIcon from 'src/images/general/warning-icon.svg';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import ManualAddressConfirmPage from 'src/components/layout/ManualAddressConfirmPage';
import { MIFormattedText } from 'src/utils/formatting';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import CheckDeliveryMethodForm from './components/CheckDeliveryMethodForm';
import useManualAddress from './hooks/useManualAddress';
import QBREditCheckAddressModal from './components/editCheckAddressModal/QBREditCheckAddressModal';

type Props = {
  title?: string;
  titleValues?: Record<string, any>;
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  nextLabel: string;
  goExit: () => void;
  onPrev?: () => void;
  isLoading?: boolean;
  vendorId: string;
  selectDeliveryMethodPath: string;
  onSuccess: (paperCheck: CheckType, isVerified: boolean, checkDeliveryMethodId?: string) => Promise<void>;
};

export enum CheckDeliveryMethodState {
  NEW = 'New',
  NEW_UNVERIFIED = 'NewUnverified',
  EDIT = 'Edit',
  CONFIRM = 'Confirm',
  INVALID_ADDRESS = 'InvalidAddress',
  INVALID_PO_BOX = 'InvalidPOBox',
}

const CheckDeliveryMethod = ({
  title,
  titleValues,
  headerLabelValues,
  headerLabel,
  goExit,
  isLoading,
  vendorId,
  onSuccess,
  nextLabel,
  selectDeliveryMethodPath,
  onPrev,
}: Props) => {
  const site = useSiteContext();
  const { WizardElements } = site.components;
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const ownedVendorId = useSelector(getOwnedVendorId);
  const vendorActions = useStoreActions(vendorsStore);
  const isCreatingDeliveryMethod = useSelector(deliveryMethodsStore.selectors.isCreating);
  const printName = useSelector(vendorsStore.selectors.qboVendorInfo.printName(vendorId));
  const checkModelOverwrite = useMemo(() => ({ printName }), [printName]);
  const { state, actions } = useManualAddress({
    vendorId,
    onSuccess,
    ownedVendorId,
    checkModelOverwrite,
  });
  const {
    address,
    selectedAddressId,
    whitePageValidationErrors,
    isAddressLoading,
    validatedAddressObj,
    checkDeliveryMethodMV,
    currentState,
  } = state;
  const { onSelectedAddressId, onConfirmAddress, submit, onEditAddress, onEditInvalidAddress, onCloseModal } = actions;

  const fetchVendor = useCallback(async () => {
    await vendorActions.fetch({ orgId, id: vendorId });
  }, [vendorActions, vendorId, orgId]);

  useEffect(() => {
    fetchVendor();
  }, [fetchVendor, vendorId, orgId]);

  const invalidAddressFlow = {
    onSuccessModal: onConfirmAddress,
    secondaryButtonLabel: 'manualAddress.correctInvalid',
    secondaryButtonVariant: CONSTS.BUTTON_VARIANT.CANCEL,
  };

  const CHECK_DELIVERY_CONTENT = {
    [CheckDeliveryMethodState.NEW]: <CheckDeliveryMethodForm submit={submit} model={checkDeliveryMethodMV} />,
    [CheckDeliveryMethodState.CONFIRM]: (
      <>
        <WizardElements.WizardFormRow>
          <WizardElements.WizardStepSubTitle>
            <MIFormattedText label="manualAddress.confirm.subtitle" />
          </WizardElements.WizardStepSubTitle>
        </WizardElements.WizardFormRow>
        <ManualAddressConfirmPage
          addressToConfirm={validatedAddressObj}
          addressValidationErrors={whitePageValidationErrors}
          originalAddress={address}
          onAddressSelect={onSelectedAddressId}
          onEditAddress={onEditAddress}
          selectedAddressId={selectedAddressId}
        />
      </>
    ),
    [CheckDeliveryMethodState.INVALID_ADDRESS]: (
      <QBREditCheckAddressModal
        address={address}
        printName={checkDeliveryMethodMV.printName.value}
        onCloseModal={onCloseModal}
        icon={WarningIcon}
        subtitle="manualAddress.invalidAddress.subtitle"
        PopUpFlow={invalidAddressFlow}
      />
    ),
    [CheckDeliveryMethodState.INVALID_PO_BOX]: (
      <QBREditCheckAddressModal
        address={address}
        printName={checkDeliveryMethodMV.printName.value}
        onCloseModal={onCloseModal}
        icon={WarningIcon}
        subtitle="manualAddress.invalidAddress.invalidPOBoxSubtitle"
        PopUpFlow={invalidAddressFlow}
      />
    ),
  };

  const NEXT_HANDLER_BY_STATE = {
    [CheckDeliveryMethodState.INVALID_ADDRESS]: onConfirmAddress,
    [CheckDeliveryMethodState.CONFIRM]: onConfirmAddress,
  };

  const PREV_HANDLER_BY_STATE = {
    [CheckDeliveryMethodState.INVALID_ADDRESS]: onEditInvalidAddress,
    [CheckDeliveryMethodState.CONFIRM]: onEditAddress,
  };

  const onNext = async () => {
    const nextHandler = NEXT_HANDLER_BY_STATE[currentState];
    if (nextHandler) {
      await nextHandler();
    } else {
      await submit();
    }
  };

  const handlePrev = () => {
    const prevHandler = PREV_HANDLER_BY_STATE[currentState];
    if (prevHandler) {
      prevHandler();
    } else if (onPrev) {
      onPrev();
    } else {
      history.push(generatePath(selectDeliveryMethodPath, { orgId, vendorId }));
    }
  };
  const TITLE = {
    [CheckDeliveryMethodState.CONFIRM]: 'manualAddress.confirm.title',
    [CheckDeliveryMethodState.NEW]: title,
    [CheckDeliveryMethodState.INVALID_ADDRESS]: 'manualAddress.invalidTitle',
  };

  return (
    <QBDTStepLayout
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={TITLE[currentState]}
      titleValues={titleValues}
      onNext={onNext}
      goExit={goExit}
      onPrev={handlePrev}
      isLoading={isLoading || isAddressLoading || isCreatingDeliveryMethod}
      nextLabel={nextLabel}
      ctaVariant={CONSTS.BUTTON_VARIANT.PRIMARY}
    >
      {CHECK_DELIVERY_CONTENT[currentState]}
    </QBDTStepLayout>
  );
};

export default CheckDeliveryMethod;
