export enum FeatureFlags {
  CheckFees = 'check-fees-billpay',
  CheckFeesLimitDate = 'qb-check-fee-limit-date',
  CheckFeeNotification = 'qb-check-fee-notification',
  CheckDelaysWarning = 'qbdt-us-holidays-warning',
  Dashboard = 'qbdt-dashboard',
  DatePickerDeliverySpeedChanges = 'qbdt-date-picker-delivery-speed-changes',
  BasicTheory = 'qbdt-basic-theory',
  RecheckUnpaidBills = 'qbdt-recheck-unpaid-bills',
  ZendeskMessaging = 'zendesk-messaging-in-intuit',
  ZendeskMessagingAuthentication = 'zendesk-messaging-authentication-in-intuit',
  ACHFees = 'qbdt-ach-fees',
}
