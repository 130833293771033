import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';

import analytics from 'src/services/analytics';
import { AccountType } from 'src/utils/types';
import vendorsApi from 'src/services/api/vendors';
import { getOrgId } from 'src/redux/user/selectors';
import { isAMEXCard } from 'src/utils/card';
import { useApi } from 'src/hoc/useApi';

import { Modal, CodeChangeProps } from './Modal';
import { BATCH_PAGE_EVENT } from '../../pages/batch-pay/analytics/event-mapping';

type AmexModalProps = {
  isBatch?: boolean;
  onSubmit?: () => void;
  onCodeChange?: ({ vendorId, mccCode }: CodeChangeProps) => void;
};

export type AmexModalVerificationData = {
  billIds: number[];
  vendorId: number;
  vendorName: string;
};

const useAmexVerification = () => {
  const orgId = useSelector(getOrgId);

  const [verificationData, setVerificationData] = useState<AmexModalVerificationData | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [MCCCodes, setMCCCodes] = useState({});

  const [getMCCCode, , isLoading] = useApi(vendorsApi.getVendorMCCCode);

  const setVendorMCCCodes = useCallback(
    ({ vendorId, mccCode }) => {
      if (!vendorId || !mccCode) {
        throw new Error();
      }

      setMCCCodes({ ...MCCCodes, [vendorId]: mccCode });
    },
    [MCCCodes]
  );

  const shouldDisplayAmexVerification = useCallback(
    async (fundingSource: RecordOf<AccountType> | undefined | null, vendorId: number): Promise<boolean> => {
      if (!isAMEXCard(fundingSource) || !!MCCCodes[vendorId]) {
        return false;
      }

      if (vendorId) {
        const { mccCode } = await getMCCCode(orgId, vendorId);

        return !mccCode;
      }

      return false;
    },
    [orgId, MCCCodes, getMCCCode]
  );

  const handleAmexModalOpen = ({ billIds, vendorId, vendorName }: AmexModalVerificationData) => {
    setVerificationData({ billIds, vendorId, vendorName });
    setIsOpen(true);
  };

  const handleAmexModalClose = (isBatch?: boolean) => {
    const eventPage = isBatch ? BATCH_PAGE_EVENT : 'pay-bill';

    analytics.track(eventPage, 'amex-industry-modal_close', analyticsProps);

    setIsOpen(false);
    setVerificationData(null);
  };

  const analyticsProps = {
    billIds: verificationData?.billIds,
    vendorId: verificationData?.vendorId,
  };

  const renderAmexModal = ({ isBatch, onSubmit, onCodeChange }: AmexModalProps) => {
    if (!isOpen) return null;

    const handleCodeChange = ({ vendorId, mccCode }: CodeChangeProps) => {
      setVendorMCCCodes({ vendorId, mccCode });

      if (onCodeChange) {
        onCodeChange({ vendorId, mccCode });
      }
    };

    return (
      <Modal
        orgId={orgId}
        billIds={verificationData?.billIds || []}
        vendorId={verificationData?.vendorId as number}
        vendorName={verificationData?.vendorName || ''}
        analyticsProps={analyticsProps}
        isBatch={isBatch}
        onSubmit={onSubmit}
        onClose={handleAmexModalClose}
        onCodeChange={handleCodeChange}
      />
    );
  };

  return {
    renderAmexModal,
    openAmexModal: handleAmexModalOpen,
    closeAmexModal: handleAmexModalClose,
    amexLoading: isLoading,
    shouldDisplayAmexVerification,
  };
};

export { useAmexVerification };
