import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CONSTS } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { getNameParts } from 'src/utils/funding-sources';

type Params = {
  fundingSource: AccountType;
};

const useGetFundingSourceDisplayName = () => {
  const intl = useIntl();
  const getFundingSourceDisplayName = useCallback(
    ({ fundingSource }: Params): string => {
      if (!fundingSource) {
        return '';
      }

      let label = 'bills.pay.fundingSource.achLabel';
      if (fundingSource.fundingType === CONSTS.FUNDING_TYPE.CARD) {
        label =
          fundingSource.cardAccount?.cardType === CONSTS.CARD_TYPES.CREDIT
            ? 'bills.pay.fundingSource.creditLabel'
            : 'bills.pay.fundingSource.debitLabel';
      }

      const nameParts = getNameParts(fundingSource);
      return intl.formatMessage({ id: label }, nameParts);
    },
    [intl]
  );

  return { getFundingSourceDisplayName };
};

export default useGetFundingSourceDisplayName;
