import React from 'react';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import { Box, HStack } from '@melio/billpay-design-system';
import { generatePath, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import { MIFormattedText } from 'src/utils/formatting';
import { zendeskService } from 'src/services/zendesk';
import locations from 'src/pages/locations';
import { FAQDrawer } from 'src/billpay/qbdt/components/layout/FAQDrawer';
import { setFaqPanel, setUrlToBackAction } from 'src/redux/payBillWizard/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { EVENT_PAGE } from '../../consts';

const DashboardHeader = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const location = useLocation();

  const handleSupportClick = () => {
    analytics.track(EVENT_PAGE, 'support-open-start-chat');

    zendeskService.show();
  };

  const handleFAQClick = () => {
    analytics.track(EVENT_PAGE, 'need-help-open');

    dispatch(setFaqPanel({ isOpen: true }));
  };

  const handleFAQClose = () => {
    analytics.track(EVENT_PAGE, 'need-help-close');
  };

  const handleSettingsClick = () => {
    analytics.track(EVENT_PAGE, 'settings');

    dispatch(setUrlToBackAction(`${location.pathname}${location.search}`));
  };

  const handleCloseClick = () => {
    analytics.track(EVENT_PAGE, 'close-integration');

    close();
  };

  return (
    <>
      <HStack
        w="full"
        justifyContent="space-between"
        align="center"
        px="ds.lg"
        py="1rem"
        bg="ds.white"
        boxSizing="border-box"
      >
        <Box textStyle="ds.body2Semi" color="ds.gray.100">
          <MIFormattedText label="paymentDashboard.title" />
        </Box>
        <HStack align="center" spacing="ds.xl" textStyle="ds.body2" color="ds.gray.200">
          <Button iconClassName="icon-support-icon-streak" label="qbo.header.support" onClick={handleSupportClick} />

          <Button iconClassName="icon-help-icon" label="qbo.header.help" onClick={handleFAQClick} />

          <Link to={generatePath(locations.qbdt.settings.base, { orgId })} onClick={handleSettingsClick}>
            <Button iconClassName="icon-settings-icon" label="qbo.header.settings" />
          </Link>

          <Box
            ml="3rem!important"
            as="i"
            className="icon-close-icon"
            cursor="pointer"
            fontSize="2.4rem"
            onClick={handleCloseClick}
          />
        </HStack>
      </HStack>
      <Box position="relative">
        <FAQDrawer onCloseCallback={handleFAQClose} />
      </Box>
    </>
  );
};

const Link = styled(SmartLink)`
  text-decoration: none;
`;

const Button = ({
  iconClassName,
  label,
  onClick,
}: {
  iconClassName: string;
  label: string;
  onClick?: VoidFunction;
}) => (
  <HStack align="center" spacing="ds.md" cursor="pointer" onClick={onClick}>
    <Box as="i" className={iconClassName} fontSize="2.4rem" color="ds.gray.200" />
    <Box color="ds.gray.200">
      <MIFormattedText label={label} />
    </Box>
  </HStack>
);

export { DashboardHeader };
