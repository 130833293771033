import React from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/modules/bills/bills-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { FAILED_PAYMENT_TYPE } from 'src/utils/consts';
import useSchedulePaymentAction from 'src/billpay/qbdt/pages/dashboard/hooks/useSchedulePaymentAction';
import { UnpaidBillDashboardStatusesEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardActionsCell } from '../DashboardActionsCell/DashboardActionsCell';
import { DashboardActionItemsEnum } from '../consts';
import { useCancelPaymentMenuAction } from '../actions-common/useCancelPaymentMenuAction/useCancelPaymentMenuAction';
import { useViewPaymentMenuAction } from '../actions-common/useViewPaymentMenuAction';
import { useActionCellEventProperties } from '../hooks/useActionCellEventProperties';

type Props = {
  item: DashboardListItemType;
};

export const DashboardUnpaidActionCell = ({ item }: Props) => {
  const { vendorId, paymentId, metadata, id } = item;
  // TODO: dashboard - fix in terms of https://linear.app/meliopayments/issue/QBDT-506/web-adapt-table-for-bulk-payments
  const vendor = useSelector(vendorsStore.selectors.byId(vendorId));
  const billId = item.billIds[0];
  const bill = useSelector(billsStore.selectors.byId(billId));

  const canUserRetry = metadata?.canUserRetry;
  const isFailedToDeliver = metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;

  const [goSchedulePayment] = useSchedulePaymentAction({
    billId,
  });

  const { eventProperties } = useActionCellEventProperties({
    billId,
    paymentId,
    status: metadata.statusInDashboard,
  });

  const { viewPaymentMenuAction, goViewPayment } = useViewPaymentMenuAction({
    billId,
    paymentId,
  });

  const scheduleAction = {
    label: 'paymentDashboard.columns.actions.schedule',
    id: DashboardActionItemsEnum.Schedule,
    onClick: goSchedulePayment,
  };

  const onRetryFailedPayment = () => {
    goViewPayment();
  };

  const retryAction = {
    label: 'paymentDashboard.columns.actions.retryPayment',
    onClick: onRetryFailedPayment,
    id: DashboardActionItemsEnum.Retry,
  };

  const { cancelPaymentDialog, cancelPaymentMenuAction } = useCancelPaymentMenuAction({
    billIds: item.billIds,
    paymentId,
    companyName: vendor?.companyName,
    totalAmount: bill?.totalAmount,
  });

  const getActions = () => {
    const isFailed = metadata.statusInDashboard === UnpaidBillDashboardStatusesEnum.Failed;

    if (canUserRetry) {
      return [retryAction, ...(isFailedToDeliver ? [] : [cancelPaymentMenuAction])];
    }

    if (isFailed) {
      return [viewPaymentMenuAction]; // TODO: dashboard - investigate what actions should be here
    }

    return [scheduleAction];
  };

  const actions = getActions();

  return (
    <>
      {cancelPaymentDialog}
      <DashboardActionsCell eventProperties={eventProperties} actions={actions} itemId={id} />
    </>
  );
};
