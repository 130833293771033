import React, { useEffect } from 'react';
import analytics from 'src/services/analytics';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useQueryState } from 'src/utils/hooks';
import { useDashboardListParams } from './useDashboardListParams';
import { EditVendorModal } from '../components/EditVendorModal';
import { dashboardStore } from '../redux-store/dashboard-store';

export function useEditVendorModal() {
  const [listParams] = useDashboardListParams();
  const [vendorId, setVendorId] = useQueryState<string | undefined>('vendorId');
  const orgId = useSelector(getOrgId);
  const showVendorModal = !!vendorId;
  const vendorActions = useStoreActions(vendorsStore);
  const loadStatus = useSelector(dashboardStore.selectors.list?.status(listParams));

  const reset = () => {
    analytics.trackAction('vendor-modal-close');
    setVendorId(undefined);
  };

  useEffect(() => {
    if (showVendorModal && loadStatus && !loadStatus.loading) {
      initialVendor();
    }
  }, [showVendorModal, loadStatus]);

  const initialVendor = async () => {
    await vendorActions.fetch({
      orgId,
      id: vendorId,
    });
  };

  const vendorModal = showVendorModal ? <EditVendorModal dismiss={reset} /> : null;

  return [vendorModal];
}
