import React, { useState } from 'react';
import styled from 'styled-components';
import { formatCheckPrintName } from 'src/utils/delivery-methods';
import { getMaxlength } from '@melio/sizzers-js-common';
import { FieldType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { MITextInput } from 'src/components/common/MITextInput';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';

type Props = {
  onNext: (arg0: string) => void;
  goExit: () => void;
  printName: string;
  isLoading: boolean;
  validationErrors: Record<'printName', string>;
};

const EditCheckNameDeliveryMethodPage = ({ onNext, goExit, printName, isLoading, validationErrors }: Props) => {
  const [currentPrintName, changeCurrentPrintName] = useState(formatCheckPrintName(printName));
  const [isInvalidChar, setIsInvalidChar] = useState(false);
  const errorMessage = isInvalidChar
    ? 'inputErrors.deliveryMethodCheck.printName.any.invalidChar'
    : validationErrors.printName;

  const onChange = ({ value }: FieldType) => {
    const isInvalid = CONSTS.REGEX.CHECK_PRINT_NAME.test(value);
    setIsInvalidChar(isInvalid);
    if (isInvalid) {
      const formattedValue = value.replace(CONSTS.REGEX.CHECK_PRINT_NAME, '');
      changeCurrentPrintName(formattedValue);
    } else {
      changeCurrentPrintName(value);
    }
  };

  const onSubmit = () => {
    onNext(formatCheckPrintName(currentPrintName));
  };

  return (
    <QBDTStepLayout
      title="vendors.deliveryMethods.check.name.title"
      goExit={goExit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      hideHeader
    >
      <TextInputContainer>
        <MITextInput
          id="printName"
          value={currentPrintName}
          label="vendors.deliveryMethods.check.name.label"
          autoFocus
          onChange={onChange}
          required
          errorMessage={errorMessage}
          autocomplete="off"
          notices={['vendors.deliveryMethods.check.name.notice']}
          maxlength={getMaxlength('deliveryMethodCheck', 'printName')}
        />
      </TextInputContainer>
    </QBDTStepLayout>
  );
};

export default EditCheckNameDeliveryMethodPage;

const TextInputContainer = styled.div`
  & input {
    text-transform: uppercase;
  }
`;
