import paymentsApi from 'src/services/api/payments';
import { addCardAccountDeliveryMethod } from 'src/pages/vendor/shift-vendor-to-debit/api/addCardAccountDeliveryMethod';
import { useApi } from 'src/hoc/useApi';
import { CreateCardAccountWithTokenRequest } from '../types';

type Props = {
  paymentId: string;
  onSuccess: VoidFunction;
  onFailure: (errorCode?: string) => void;
};

export const useCreateCardAccount = ({ paymentId, onSuccess, onFailure }: Props) => {
  const [createCardAccount, , isCardAccountCreating] = useApi(addCardAccountDeliveryMethod.createCardAccountWithToken);
  const [updatePayment, , isPaymentUpdating] = useApi<
    [
      {
        id: string;
        deliveryMethodId: string;
        token: string;
      }
    ],
    void
  >(paymentsApi.updatePaymentByIdWithToken);

  const handleCreateCardAccount = async (params: CreateCardAccountWithTokenRequest) => {
    try {
      const { deliveryMethod } = await createCardAccount(params);
      await updatePayment({
        id: paymentId,
        deliveryMethodId: deliveryMethod.id!,
        token: params.token,
      });

      onSuccess();
    } catch (error: any) {
      onFailure(error?.code);
    }
  };

  return {
    handleCreateCardAccount,
    isProcessing: isCardAccountCreating || isPaymentUpdating,
  };
};
