import React, { useEffect } from 'react';
import { VendorFlowLayout, VendorFlowContentCard, Footer } from 'src/pages/vendor/components/VendorFlowLayout';
import { OrganizationType, PaymentType } from 'src/utils/types';
import {
  PaymentInfo,
  VendorReceive,
} from 'src/pages/vendor/shift-vendor-to-debit/components/AddVirtualCardNotClearedPage';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { useInvoiceNumberType } from '../../hooks/useInvoiceNumberType';

type Props = {
  payment?: PaymentType;
  organization?: OrganizationType;
};

export const AddVirtualCardNotClearedPage = ({ payment, organization }: Props) => {
  const organizationName = organization?.companyName || '';
  const vendorEmail = payment?.vendor?.contactEmail || '';
  const amount = payment?.amount || 0;
  const { invoiceNumber, invoiceNumberType } = useInvoiceNumberType(payment);
  const virtualCardDeliveryEta = payment?.lastCreatedVirtualCard?.createdAt;

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'virtual-card-not-cleared', {
      OriginDeliveryType: payment?.originalDeliveryMethod?.deliveryType,
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <PaymentInfo
          amount={amount}
          vendorEmail={vendorEmail}
          organizationName={organizationName}
          deliveryEta={virtualCardDeliveryEta}
          invoiceNumber={invoiceNumber}
          invoiceNumberType={invoiceNumberType}
        />
        <Divider h="1px" my="ds.2xl" />
        <VendorReceive vendorEmail={vendorEmail} deliveryEta={virtualCardDeliveryEta} />
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
