import { featureFlags } from '@melio/shared-web';
import config from 'src/config';
import { supportApi } from 'src/services/api/support';
import analytics from 'src/services/analytics';
import qbIcon from 'src/images/icons/qb-icon.png';
import { UserContextType } from 'src/utils/types';
import { isLoginFromForest } from 'src/utils/user-utils';
import { FeatureFlags } from 'src/utils/feature-flags';

type DataParams = {
  isLoggedInAs: boolean;
  canContactSupport: boolean;
  user: Partial<UserContextType>;
};

type WindowWithZendesk = Window &
  typeof globalThis & {
    $zopim: any;
    Forethought: any;
    zE: (
      instance: 'messenger' | 'messenger:on',
      action: 'open' | 'hide' | 'close' | 'loginUser',
      callback?: (data?: any) => void
    ) => void;
  };

const eventPage = 'pay-bill';
const { widgetApiKey } = config.services.zendesk as { widgetApiKey: string };

enum ChatOption {
  Zendesk,
  ZendeskMessaging,
}

enum OpenFailureReason {
  NOT_ALLOWED = 'Not allowed.',
  INITIALIZATION_FAILED = 'Initialization failed.',
  ZENDESK_IS_MISSING = 'Zendesk is missing.',
  FORETHROUGH_IS_MISSING = 'Forethrough is missing.',
}

enum InitializationFailureReason {
  TOO_MANY_ATTEMPTS = 'Too many attempts.',
  NOT_ALLOWED = 'Not allowed.',
  ZENDESK_DESIGN_INIT_ERROR = 'Zendesk design initialization error.',
  ZENDESK_DATA_INIT_ERROR = 'Zendesk data initialization error.',
}

enum AuthenticationFailureReason {
  TOKEN_REQUEST_ERROR = 'Failed to get zendesk token',
  ZENDESK_UNDEFINED = 'Zendesk (zE) is undefined',
}

const zendeskService = {
  attempts: 100,
  enabled: true,
  allowed: true,

  chatOption: ChatOption.Zendesk,
  zendeskInjected: false,
  zendeskMessagingInjected: false,
  forethoughtInjected: false,

  forethroughScript: null as HTMLScriptElement | null,

  resolveChatOption() {
    const isZendeskMessagingEnabled = featureFlags.defaultClient.getVariant(FeatureFlags.ZendeskMessaging, false);

    return isZendeskMessagingEnabled ? ChatOption.ZendeskMessaging : ChatOption.Zendesk;
  },

  initialize(data: DataParams) {
    analytics.track(eventPage, 'zendesk-initialize-triggered');

    this.chatOption = this.resolveChatOption();

    switch (this.chatOption) {
      case ChatOption.ZendeskMessaging:
        this.injectZendeskMessaging(data);
        break;
      case ChatOption.Zendesk:
      default: {
        this.injectZendesk(data);
        this.injectForethrough(data);
      }
    }
  },

  isChatDisabled({ isLoggedInAs, canContactSupport }: DataParams) {
    return isLoginFromForest() || isLoggedInAs || !canContactSupport;
  },

  onChatDisabled({ isLoggedInAs, canContactSupport }: DataParams) {
    this.enabled = false;
    this.allowed = false;

    analytics.track(eventPage, 'zendesk-initialization-failed', {
      reason: InitializationFailureReason.NOT_ALLOWED,
      isLoggedInAs,
      isLoginFromForest: isLoginFromForest(),
      canContactSupport,
    });
  },

  injectZendesk(data: DataParams) {
    // script already loaded
    if (this.zendeskInjected) return;

    analytics.track(eventPage, 'zendesk-inject-triggered');
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetApiKey}`;
    script.onload = () => this.initializeZendesk(data);

    this.zendeskInjected = true;

    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);
    document.body.appendChild(script);
  },

  injectZendeskMessaging(data: DataParams) {
    // script already loaded
    if (this.zendeskMessagingInjected) return;

    analytics.track(eventPage, 'zendesk-inject-triggered');
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetApiKey}`;
    script.onload = () => this.initializeZendeskMessaging(data);

    this.zendeskMessagingInjected = true;

    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);
    document.body.appendChild(script);
  },

  injectForethrough(data: DataParams) {
    // script already loaded
    if (this.forethoughtInjected) return;

    analytics.track(eventPage, 'forethrough-inject-triggered');
    const script = document.createElement('script');
    script.src = 'https://solve-widget.forethought.ai/embed.js';
    script.type = 'application/javascript';
    script.setAttribute('data-api-key', '74ba8f52-1c1e-4b60-8b1e-bc74bcdd4a0d');
    script.setAttribute('data-ft-current_page_url', 'window.location.href');
    script.setAttribute('data-ft-verbose', 'false');
    script.setAttribute('data-ft-name', `${data.user.firstName} ${data.user.lastName}`);
    script.setAttribute('data-ft-email', data.user.email || '');
    script.setAttribute('data-ft-workflow', 'Melio Routing');
    script.setAttribute('data-ft-tag', '');
    script.setAttribute('data-ft-tag_2', '');
    script.setAttribute('data-ft-dummy', '');

    // hide forethought UI
    script.onload = () => {
      analytics.track(eventPage, 'forethrough-inject-succeed');
      (window as WindowWithZendesk).Forethought?.('widget', 'hide');
    };

    // hiding forethougt button after closing its window
    window.addEventListener('message', (e) => {
      if (e.data.event === 'forethoughtWidgetClosed') {
        (window as WindowWithZendesk).Forethought?.('widget', 'hide');
      }
    });

    this.forethoughtInjected = true;
    this.forethroughScript = script;

    document.body.appendChild(script);
  },

  initializeZendesk(data: DataParams) {
    const waitForZopim = setInterval(() => {
      const zendesk = (window as WindowWithZendesk)?.$zopim;
      this.attempts -= 1;

      if (this.attempts === 0) {
        this.enabled = false;

        analytics.track(eventPage, 'zendesk-initialization-failed', {
          reason: InitializationFailureReason.TOO_MANY_ATTEMPTS,
        });

        clearInterval(waitForZopim);

        return;
      }

      if (!zendesk || !zendesk.livechat) {
        return;
      }

      analytics.track(eventPage, 'zendesk-initialization-succeed');
      // update design for QBO chat
      this.setZendeskDesign();

      if (data) {
        this.setZendeskData(data);
      }

      clearInterval(waitForZopim);
    }, 200);
  },

  initializeZendeskMessaging(data: DataParams) {
    const waitForZE = setInterval(() => {
      const zendesk = (window as WindowWithZendesk)?.zE;
      this.attempts -= 1;

      if (this.attempts === 0) {
        this.enabled = false;

        analytics.track(eventPage, 'zendesk-initialization-failed', {
          reason: InitializationFailureReason.TOO_MANY_ATTEMPTS,
        });

        clearInterval(waitForZE);

        return;
      }

      if (!zendesk) {
        return;
      }

      if (data) {
        const isDisabled = this.isChatDisabled(data);
        if (isDisabled) {
          this.onChatDisabled(data);

          clearInterval(waitForZE);
          return;
        }
      }

      analytics.track(eventPage, 'zendesk-initialization-succeed');

      clearInterval(waitForZE);

      const shouldAuthenticateUser = featureFlags.defaultClient.getVariant(
        FeatureFlags.ZendeskMessagingAuthentication,
        false
      );

      if (shouldAuthenticateUser) {
        zendesk('messenger:on', 'open', () => {
          this.logUserIntoZendesk();
        });

        // always start with closed state
        // to login user when they open the chat
        zendesk('messenger', 'close');
      }
    }, 200);
  },

  async getZendeskToken() {
    const { token } = await supportApi.getZendeskToken();

    return token;
  },

  logUserIntoZendesk() {
    const zendesk = (window as WindowWithZendesk)?.zE;
    if (!zendesk) {
      analytics.track(eventPage, 'zendesk-login-user-error', {
        reason: AuthenticationFailureReason.ZENDESK_UNDEFINED,
        message: '',
      });

      return;
    }

    zendesk('messenger', 'loginUser', async (callback: (token: string) => void) => {
      try {
        // zendesk reruns this function on 401 (e.g. when the token expires during chatting),
        // so adding the new token getting here to handle the token expiration
        const zendeskMessagingToken = await this.getZendeskToken();

        callback(zendeskMessagingToken);
      } catch (error: any) {
        analytics.track(eventPage, 'zendesk-login-user-error', {
          reason: AuthenticationFailureReason.TOKEN_REQUEST_ERROR,
          message: error?.message || '',
        });
      }
    });
  },

  setZendeskDesign() {
    try {
      const zendesk = (window as WindowWithZendesk)?.$zopim;

      zendesk(() => {
        const { livechat } = zendesk;

        // change chat UI for QBO
        livechat.window.setTitle('Bill Pay Support');
        livechat.concierge.setAvatar(qbIcon);

        // hide widget floating button by default (we also hide button with css, located in snippet)
        livechat.button.hide();

        livechat.window.onHide(() => {
          // zendesk is showing the floating button again when closing the chat
          // force hide it again after closing
          livechat.button.hide();
        });
      });
    } catch (error: any) {
      // disable chat if cannot set the design
      this.enabled = false;

      analytics.track(eventPage, 'zendesk-initialization-failed', {
        reason: InitializationFailureReason.ZENDESK_DESIGN_INIT_ERROR,
        message: error?.message || '',
      });
    }
  },

  setZendeskData(data: DataParams) {
    analytics.track(eventPage, 'zendesk-set-data-triggered');
    const { user } = data;
    const isDisabled = this.isChatDisabled(data);

    if (isDisabled) {
      this.onChatDisabled(data);
      return;
    }

    try {
      // sending user data to support
      const zendesk = (window as WindowWithZendesk)?.$zopim;
      const { email, firstName, lastName, orgId, id, registrationFlow } = user;

      zendesk(() => {
        const { livechat } = zendesk;

        livechat.setName(`${firstName} ${lastName}`);
        livechat.setEmail(email);

        // custom tags
        livechat.addTags(`orgId-${orgId}`);
        livechat.addTags(`userId-${id}`);

        if (registrationFlow) livechat.addTags(registrationFlow);
      });
    } catch (error: any) {
      // disable chat if cannot send user data
      this.enabled = false;

      analytics.track(eventPage, 'zendesk-initialization-failed', {
        reason: InitializationFailureReason.ZENDESK_DATA_INIT_ERROR,
        message: error?.message || '',
      });
    }
  },

  show() {
    switch (this.chatOption) {
      case ChatOption.ZendeskMessaging:
        this.showZendeskMessaging();
        break;
      case ChatOption.Zendesk:
      default:
        this.showZendesk();
    }
  },

  showZendesk() {
    if (!this.enabled || !(window as WindowWithZendesk)?.$zopim || !(window as WindowWithZendesk)?.Forethought) {
      let reason;

      if (!this.allowed) {
        reason = OpenFailureReason.NOT_ALLOWED;
      } else if (!(window as WindowWithZendesk)?.$zopim) {
        reason = OpenFailureReason.ZENDESK_IS_MISSING;
      } else if (!(window as WindowWithZendesk)?.Forethought) {
        reason = OpenFailureReason.FORETHROUGH_IS_MISSING;
      } else {
        reason = OpenFailureReason.INITIALIZATION_FAILED;
      }

      analytics.track(eventPage, 'zendesk-open-failed', {
        reason,
      });

      return;
    }

    const { livechat } = (window as WindowWithZendesk).$zopim;
    const forethought = (window as WindowWithZendesk).Forethought;

    if (livechat.isChatting()) {
      // show zendesk chat if there is an active session
      livechat.window.show();
      analytics.track(eventPage, 'zendesk-open-success');
    } else {
      // showing forethought at start - they will open zendesk in their flow
      // we first open it and then show to avoid floating button in the screen
      forethought('widget', 'open');
      forethought('widget', 'show');
      analytics.track(eventPage, 'forethought-open-success');
    }
  },

  showZendeskMessaging() {
    const zendesk = (window as WindowWithZendesk)?.zE;

    if (!this.enabled || !zendesk) {
      let reason: OpenFailureReason;

      if (!this.allowed) {
        reason = OpenFailureReason.NOT_ALLOWED;
      } else if (!zendesk) {
        reason = OpenFailureReason.ZENDESK_IS_MISSING;
      } else {
        reason = OpenFailureReason.INITIALIZATION_FAILED;
      }

      analytics.track(eventPage, 'zendesk-open-failed', {
        reason,
      });

      return;
    }

    zendesk('messenger', 'open');
    analytics.track(eventPage, 'zendesk-open-success');
  },

  hide() {
    switch (this.chatOption) {
      case ChatOption.ZendeskMessaging:
        this.hideZendeskMessaging();
        break;
      case ChatOption.Zendesk:
      default:
        this.hideZendesk();
    }
  },

  hideZendesk() {
    const zendesk = (window as WindowWithZendesk)?.$zopim;
    const forethought = (window as WindowWithZendesk)?.Forethought;

    if (zendesk) zendesk.livechat.window.hide();

    if (forethought) forethought('widget', 'hide');
  },

  hideZendeskMessaging() {
    const zendesk = (window as WindowWithZendesk)?.zE;

    if (zendesk) zendesk('messenger', 'hide');
  },

  disable() {
    this.enabled = false;

    this.hide();
  },
};

export { zendeskService };
