import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import AreaLoader from 'src/components/common/AreaLoader';
import { InvalidAddDeliveryMethodPage } from 'src/pages/vendor/shift-vendor-to-ach/InvalidAddDeliveryMethodPage';
import CheckSentInfoPage from 'src/pages/vendor/edit-check-adress/CheckSentInfoPage';
import CheckDepositedSuccessPage from 'src/pages/vendor/edit-check-adress/CheckDepositedSuccessPage';
import CheckChangeAddressPage from 'src/pages/vendor/edit-check-adress/CheckChangeAddressPage';
import CheckAddressChangedPage from 'src/pages/vendor/edit-check-adress/CheckAddressChangedPage';
import CheckBlockedPage from 'src/pages/vendor/edit-check-adress/CheckBlockedPage';
import { VendorPushToDebitRouter } from 'src/pages/vendor/shift-vendor-to-debit/VendorPushToDebitRouter';
import SwitchedToACHAlreadyPage from 'src/pages/vendor/edit-check-adress/SwitchedToACHAlreadyPage';
import { useEditCheckAddressRedirect } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressRedirect';
import { ShiftVendorToAchRouter } from 'src/pages/vendor/shift-vendor-to-ach/ShiftVendorToAchRouter';
import { VendorUnilateralRouter } from 'src/pages/vendor/virtual-delivery-method/VendorUnilateralRouter';
import { VirtualCardDetailsRouter } from './virtual-card-details/VirtualCardDetailsRouter';
import { ShiftVirtualCardToACHRouter } from './shift-virtual-card-to-ach/ShiftVirtualCardToACHRouter';
import locations from './locations';

const EditCheckAddressRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const id = match.params?.id || '';
  useEditCheckAddressRedirect({ token, id });

  return (
    <Switch>
      <SmartRoute path={locations.editCheckAddress.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkSent} exact>
        <CheckSentInfoPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkDeposited} exact>
        <CheckDepositedSuccessPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkNotSentChangeAddress} exact>
        <CheckChangeAddressPage token={token} id={id} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkNotSendAddressSaved} exact>
        <CheckAddressChangedPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.switchedToACH} exact>
        <SwitchedToACHAlreadyPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkBlocked} exact>
        <CheckBlockedPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.invalidToken} exact>
        <InvalidAddDeliveryMethodPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

export function VendorsRouter() {
  return (
    <Switch>
      <SmartRoute path={locations.virtualCardDetails.base}>
        <VirtualCardDetailsRouter />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.base}>
        <VendorUnilateralRouter />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.base}>
        <ShiftVendorToAchRouter />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCardDetailsToAch.base}>
        <ShiftVirtualCardToACHRouter />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.base}>
        <EditCheckAddressRouter />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.base}>
        <VendorPushToDebitRouter />
      </SmartRoute>
    </Switch>
  );
}
