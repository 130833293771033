import React, { useMemo, useState } from 'react';
import { Box, Flex, HStack } from '@melio/billpay-design-system';
import { mapProps } from 'recompose';
import { convertStart, convertLimit } from 'src/utils/pagination-utils';
import { MIFormattedText } from 'src/utils/formatting';

type PaginationBarProps = {
  start: number;
  limit: number;
  total: number;
  onPageChange: (p: number) => void;
};

type ChevronProps = {
  direction: ChevronDirection;
  isActive: boolean;
  onClick: VoidFunction;
};

enum ChevronDirection {
  LEFT = '1',
  RIGHT = '2',
}

const Chevron = ({ direction, isActive, onClick }: ChevronProps) => (
  <Flex
    color={isActive ? 'ds.gray.200' : 'ds.gray.500'}
    cursor={isActive ? 'pointer' : 'auto'}
    sx={{
      ':hover': {
        color: isActive ? 'ds.green.200' : 'ds.gray.500',
      },
    }}
    onClick={onClick}
    w="2rem"
    h="2rem"
    align="center"
    justify="center"
    pointerEvents={isActive ? 'auto' : 'none'}
  >
    <Box
      as="i"
      fontSize="1.6rem"
      className={direction === ChevronDirection.LEFT ? 'icon-prev-page-icon' : 'icon-next-page-icon'}
    />
  </Flex>
);

const PaginationBar = ({ total, limit, start, onPageChange }: PaginationBarProps) => {
  const [currentPage, setCurrentPage] = useState(start / limit + 1);
  const totalPagesCount = useMemo(() => Math.floor((total - 1) / limit) + 1, [total, limit]);

  const handlePrevPageClick = () => {
    const page = currentPage - 1;

    setCurrentPage(page);
    onPageChange(page);
  };

  const handleNextPageClick = () => {
    const page = currentPage + 1;

    setCurrentPage(page);
    onPageChange(page);
  };

  const hasPrev = () => currentPage > 1;

  const hasNext = () => currentPage < totalPagesCount;

  return (
    totalPagesCount > 1 && (
      <HStack w="full" spacing="ds.sm" align="center" justify="flex-end">
        <Chevron isActive={hasPrev()} onClick={handlePrevPageClick} direction={ChevronDirection.LEFT} />

        <Box textStyle="ds.body3" fontWeight="ds.medium" color="ds.gray.100">
          <MIFormattedText
            label="settings.billing.feeHistory.pagination"
            values={{
              start: start + 1,
              end: hasNext() ? start + limit : total,
              total,
            }}
          />
        </Box>

        <Chevron isActive={hasNext()} onClick={handleNextPageClick} direction={ChevronDirection.RIGHT} />
      </HStack>
    )
  );
};

const BillingFeeHistoryTablePaginationBar = mapProps(({ start, limit, ...restProps }: PaginationBarProps) => ({
  start: convertStart(start),
  limit: convertLimit(limit),
  ...restProps,
}))(PaginationBar);

export { BillingFeeHistoryTablePaginationBar };
