import React from 'react';
import { Flex, Icon, Text } from '@melio/billpay-design-system';
import { ReactComponent as FirstStepIcon } from 'src/images/virtual-card/process-steps/first.svg';
import { ReactComponent as SecondStepIcon } from 'src/images/virtual-card/process-steps/second.svg';
import { ReactComponent as ThirdStepIcon } from 'src/images/virtual-card/process-steps/third.svg';
import { MIFormattedText } from 'src/utils/formatting';

const steps = [
  {
    StepIcon: FirstStepIcon,
    title: 'vendor.unilateral.virtualCardDetails.processCard.first.title',
    subtitle: 'vendor.unilateral.virtualCardDetails.processCard.first.subtitle',
  },
  {
    StepIcon: SecondStepIcon,
    title: 'vendor.unilateral.virtualCardDetails.processCard.second.title',
    subtitle: 'vendor.unilateral.virtualCardDetails.processCard.second.subtitle',
  },
  {
    StepIcon: ThirdStepIcon,
    title: 'vendor.unilateral.virtualCardDetails.processCard.third.title',
    subtitle: 'vendor.unilateral.virtualCardDetails.processCard.third.subtitle',
  },
];

export const CardProcessingSteps = () => (
  <Flex direction="column" gap="ds.xl">
    <Text m="0" textStyle="ds.h5" color="ds.black" align="center" alignSelf="start">
      <MIFormattedText label="vendor.unilateral.virtualCardDetails.processCard.sectionTitle" />
    </Text>
    <Flex direction="column" gap="ds.xl">
      {steps.map(({ StepIcon, title, subtitle }) => (
        <Flex gap="ds.md" align="center" w="100%" key={title}>
          <Icon as={StepIcon} w="ds.3xl" h="ds.3xl" />
          <Flex gap="ds.md" direction="column" align="start">
            <Text m="0" textStyle="ds.body1Semi" color="ds.gray.100">
              <MIFormattedText label={title} />
            </Text>
            <Text m="0" textStyle="ds.body2" color="ds.black">
              <MIFormattedText label={subtitle} />
            </Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  </Flex>
);
