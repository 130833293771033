import { useEffect } from 'react';
import analytics from 'src/services/analytics';
import { useModal } from 'src/helpers/react/useModal';
import { useLocationState } from 'src/utils/hooks';
import { getPartialBillId } from 'src/utils/bills';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useCheckVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useCheckVendorEmail';

import { BatchPayFlowSuccessModal } from '../components/BatchFlowSuccess';
import { CollectVendorEmailsModal as CollectVendorEmailsDialog } from '../components/CollectVendorEmailsModal';

import { DashboardActionConfirmFlowEnum, DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP } from '../consts';
import { BATCH_PAY_FLOW_PAGE_EVENT } from '../event-mapping';

const useBatchPayFlowSuccess = () => {
  const [dashboardActionConfirmFlow, setDashboardActionConfirmFlow] = useLocationState(
    DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP
  );

  const [scheduledPayments] = useLocationState('scheduledPayments', []);

  const batchActions = useStoreActions(regularBatchPaymentsStore);
  const { selectedBills } = useBatchBillsList();

  const { filterVendorIdsWithMissingEmail } = useCheckVendorEmail();
  const vendorIds = scheduledPayments.map(({ vendorId }) => vendorId);
  const vendorIdsWithMissingEmail = filterVendorIdsWithMissingEmail(vendorIds);

  useEffect(() => {
    if (dashboardActionConfirmFlow === DashboardActionConfirmFlowEnum.BatchPay) {
      showBatchPayFlowSuccess();

      analytics.track(BATCH_PAY_FLOW_PAGE_EVENT, 'success-batch-payment-modal', {
        partialBillIds: (selectedBills || []).map((bill) => getPartialBillId(bill)),
        paymentIds: (scheduledPayments || []).map(({ id }) => id),
      });

      setDashboardActionConfirmFlow(null);
    }
  }, [dashboardActionConfirmFlow]);

  const handleBatchPayFlowSuccessModalDismiss = (isFlowFinished: boolean) => {
    if (isFlowFinished) {
      batchActions.settings.resetStore();
    }
  };

  const handleCollectVendorEmailsModalDismiss = () => {
    batchActions.settings.resetStore();
  };

  const handleNotifyVendorsClick = () => {
    showCollectVendorEmailsModal();
  };

  const [BatchPayFlowSuccess, showBatchPayFlowSuccess] = useModal(BatchPayFlowSuccessModal, {
    onDismiss: handleBatchPayFlowSuccessModalDismiss,
    onNotifyVendorsClick: handleNotifyVendorsClick,
  });

  const [CollectVendorEmailsModal, showCollectVendorEmailsModal] = useModal(CollectVendorEmailsDialog, {
    eventPage: BATCH_PAY_FLOW_PAGE_EVENT,
    vendorIds: vendorIdsWithMissingEmail,
    onDismiss: handleCollectVendorEmailsModalDismiss,
  });

  return { BatchPayFlowSuccess, CollectVendorEmailsModal };
};

export { useBatchPayFlowSuccess };
