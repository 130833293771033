import React, { ReactElement } from 'react';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import styled from 'styled-components';
import MILink from '../../components/common/MILink';
import { MIFieldOrEmpty } from '../../utils/formatting';

type Props = {
  address: string | ReactElement[][];
  printName?: string;
  onEditAddress?: () => void;
  editLink?: boolean;
};

const ManualAddressRow = ({ printName, address, editLink, onEditAddress }: Props) => (
  <Container>
    <AddressRow className={FULL_STORY_MASK_RULE_CLASS}>
      {printName && (
        <React.Fragment>
          <Name>
            <MIFieldOrEmpty value={printName} label="" />
          </Name>
          <Comma />
        </React.Fragment>
      )}
      {address}
    </AddressRow>
    {editLink && onEditAddress && (
      <Container>
        <MILink plain to={onEditAddress} label="manualAddress.edit" />
      </Container>
    )}
  </Container>
);

export default ManualAddressRow;

const Comma = styled.span`
  &:after {
    content: ',';
    margin-right: 0.5rem;
  }
`;

const Name = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const Container = styled.div`
  ${(props) => props.theme?.components?.ManualAddressRow?.Container}
`;

const AddressRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 37rem;
  flex-wrap: wrap;
  white-space: normal;
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: 2.5rem;
  margin: 1rem 0;
  cursor: default;

  ${(props) => props.theme?.components?.ManualAddressRow?.AddressRow}
`;
