const colors = {
  primary: {
    100: '#4516CC',
    200: '#5F30E6',
    300: '#7849FF',
    400: '#E4DBFF',
    500: '#F1EDFF',
  },
  grey: {
    100: '#717173',
    200: '#6B6C72',
    300: '#C2C2C3',
    400: '#D8D8D8',
    500: '#EEEEEE',
    600: '#FAFAFA',
    700: '#ECEEF1',
    800: '#F4F5F8',
    900: '#FBFBFD',
  },
  black: '#212124',
  overlay: '#21212499',
  white: '#FFFFFF',
  green: {
    100: '#009F5C',
    200: '#00B976',
    300: '#00D28F',
    400: '#CCF6E9',
    500: '#E5FBF4',
  },
  red: {
    100: '#9D0000',
    200: '#D52B1E',
    300: '#D0021B',
    400: '#F6CCD1',
    500: '#FAE6E8',
  },
  orange: {
    300: '#FF602A',
    400: '#FFDFD4',
    500: '#FFEFEA',
  },
  yellow: {
    300: '#EAB719',
    400: '#FBF1D1',
    500: '#FDF8E8',
  },
  blue: {
    100: '#1C82D2',
    200: '#0077C5',
    300: '#0491FF',
    400: '#CDE9FF',
    500: '#E6F4FF',
    600: '#0077C5',
  },
};

export type Colors = typeof colors;
export default colors;
