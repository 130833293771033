import React from 'react';
import { Box, Flex, Table } from '@melio/billpay-design-system';
import { BaseTableFilters, ColumnItem, OrderParams } from './types';
import { useSelection } from './hooks/useSelection';
import { DashboardPagination } from '../DashboardPagination/DashboardPagination';
import { DashboardListItemType } from '../../types';
import { useDashboardTablePagination } from './hooks/useDashboardTablePagination';
import { DashboardTableHeader } from './DashboardTableHeader/DashboardTableHeader';
import { DashboardTableRow } from './DashboardTableRow/DashboardTableRow';
import { useScrollToHighlightedItem } from './hooks/useScrollToHighlightedItem';

type Props = {
  columns: ColumnItem<DashboardListItemType>[];
  items: DashboardListItemType[];
  filters: BaseTableFilters;
  totalItems: number;
  onChangeOrder: (orderParams: OrderParams) => void;
  onChangePage: (pageIndex: number) => void;
  setSelectedIds?: (ids: string[]) => void;
  selectionEnabled?: boolean;
  getDisabledCheckboxTooltip?: (id?: string) => string;
};

export function DashboardTable({
  columns,
  items,
  onChangeOrder,
  totalItems,
  filters,
  onChangePage,
  selectionEnabled,
  getDisabledCheckboxTooltip = () => '',
}: Props) {
  const { showPagination, currentPage, pageCount, itemFromIndex, itemToIndex } = useDashboardTablePagination({
    totalItems,
    limit: filters.limit,
    start: filters.start,
  });

  const {
    selectedIds,
    selectableItemsIds,
    isAllSelected,
    isIntederminate,
    onAllCheckboxChange,
    onCheckboxChange,
  } = useSelection(items);

  useScrollToHighlightedItem(items);

  return (
    <Flex direction="column">
      <Table>
        <DashboardTableHeader
          selectionEnabled={selectionEnabled}
          isAllSelected={isAllSelected}
          isIntederminate={isIntederminate}
          onAllCheckboxChange={onAllCheckboxChange}
          filters={filters}
          onChangeOrder={onChangeOrder}
          columns={columns}
        />
        <Table.Body>
          {items.map((item) => (
            <DashboardTableRow
              key={item.id}
              item={item}
              columns={columns}
              getDisabledCheckboxTooltip={getDisabledCheckboxTooltip}
              onCheckboxChange={onCheckboxChange}
              selectableItemsIds={selectableItemsIds}
              selectedIds={selectedIds}
              selectionEnabled={!!selectionEnabled}
            />
          ))}
        </Table.Body>
      </Table>
      {showPagination && (
        <Box display="flex" justifyContent="flex-end" mt="6" mb="8">
          <DashboardPagination
            pageIndex={currentPage}
            pageCount={pageCount}
            goToPage={onChangePage}
            itemFromIndex={itemFromIndex}
            itemToIndex={itemToIndex}
            totalCount={totalItems}
          />
        </Box>
      )}
    </Flex>
  );
}
