export const iconStyle = {
  svg: {
    display: 'inline',
    h: 14,
    position: 'relative',
    top: 0.5,
  },
};

export const disabledMenuStyle = {
  pointerEvents: 'none',
};

export const containerStyle = {
  width: 'full',
  flex: 1,
  mt: 'ds.xl',
  mb: 28,
  borderRadius: 'lg',
  overflowY: 'overlay',
  filter: 'drop-shadow(0px 5px 15px rgba(33, 33, 36, 0.1))',
  minHeight: '5rem',
  overflowX: 'auto',
};
