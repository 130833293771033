import { useCallback } from 'react';
import { SelectedBillsResponse } from 'src/billpay/qbdt/services/qbdt';
import { BillType } from 'src/utils/types';
import locations from 'src/billpay/qbdt/pages/locations';
import { generatePath } from 'react-router-dom';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN, BATCH_MAX_LIMIT } from 'src/utils/consts';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import useGetValidFundingSources from '../../batch-pay/table/hooks/useGetValidFundingSources';

export const useGenerateBatchUrl = () => {
  const orgId = useOrgId();
  const [validFundingSources] = useGetValidFundingSources();
  const hasFundingSource = !!validFundingSources.length;

  const generateBatchUrl = useCallback(
    (
      syncedBills: BillType[],
      selectedBillsResponse: SelectedBillsResponse
    ): { url: string; locationState?: unknown } => {
      const batchUrl = generatePath(locations.batchPay.batch, { orgId });
      const { entities } = selectedBillsResponse;

      if (hasFundingSource) {
        return {
          url: batchUrl,
          locationState: {
            isBillsOverLimit: entities.length > BATCH_MAX_LIMIT,
            billsOverLimitAmount: entities.length,
          },
        };
      }

      const addFundingSourceUrl = generatePath(locations.batchPay.firstFundingSource, {
        orgId,
      });

      const numberOfBills = syncedBills.length;
      const totalAmount = syncedBills.reduce((sum, currentBill) => parseFloat(currentBill.totalAmount) + sum, 0);

      const locationState = {
        preservedState: {
          origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS_FIRST_FS,
        },
        redirectUrl: batchUrl,
        numberOfBills,
        totalAmount,
        isBillsOverLimit: entities.length > BATCH_MAX_LIMIT,
        billsOverLimitAmount: entities.length,
      };

      return {
        url: addFundingSourceUrl,
        locationState,
      };
    },
    [hasFundingSource, orgId]
  );

  return { generateBatchUrl };
};
