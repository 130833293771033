import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card-details/hooks';
import { DeliveryMethodType } from 'src/utils/types';
import locations from 'src/pages/vendor/locations';

type Props = {
  virtualCardPaymentState: VirtualCardPaymentState;
  achDeliveryMethod?: DeliveryMethodType;
};

export const getInitialUrl = ({ virtualCardPaymentState, achDeliveryMethod }: Props) => {
  if (virtualCardPaymentState === VirtualCardPaymentState.Processed) {
    return locations.shiftVirtualCardDetailsToAch.cardProcessed;
  }

  if (
    [
      VirtualCardPaymentState.NewDeliveryMethodProcessed,
      VirtualCardPaymentState.NewDeliveryMethod,
      VirtualCardPaymentState.NewDeliveryMethodScheduled,
    ].includes(virtualCardPaymentState)
  ) {
    return locations.shiftVirtualCardDetailsToAch.success;
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Cancelled) {
    return locations.shiftVirtualCardDetailsToAch.paymentCanceled;
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Valid) {
    if (achDeliveryMethod) {
      return locations.shiftVirtualCardDetailsToAch.currentBankAccount;
    }

    return locations.shiftVirtualCardDetailsToAch.newBankAccount;
  }

  return locations.shiftVirtualCardDetailsToAch.invalidToken;
};
