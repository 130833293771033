import { chakra } from 'src/theme/ds';

export const PaymentFieldIcon = chakra('i', {
  baseStyle: {
    fontSize: '3.2rem',
    boxSizing: 'border-box',
    color: 'grey.200',
    '> img': {
      height: '3.2rem',
    },
  },
});
