import React, { useRef, useCallback, useState } from 'react';
import { Flex, Box } from '@melio/billpay-design-system';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-js/types/elements/elements';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { SecurityComplianceMessage } from 'src/billpay/qbdt/components/basis-theory/SecurityComplianceMessage';
import config from 'src/config';
import { useOnEnterPressed } from 'src/helpers/react/useOnEnterPressed';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { RetrievedTokenizedData } from '../types';
import { BasisTheoryCardBrandType } from '../brands-logos/types';
import { BrandsLogos } from '../brands-logos/BrandsLogos';
import { CardDetailsForm } from '../CardDetailsForm';
import { LinkCardViewLoader } from './LinkCardViewLoader';
import { BasisTheoryCardsBrands } from '../brands-logos/consts';

type Props = {
  onLinkCard(tokenizedData: RetrievedTokenizedData): void;
  onError?(error: unknown): void;
  showLinkCardButtonSpinner?: boolean;
  showComplianceMessage?: boolean;
};

export const LinkCardView = ({ onLinkCard, onError, showLinkCardButtonSpinner, showComplianceMessage }: Props) => {
  const [areFieldsLoading, setFieldsLoading] = useState(true);
  const [isCardLinking, setIsLinkingCard] = useState(false);
  const [cardBrand, setCardBrand] = useState<BasisTheoryCardBrandType>(BasisTheoryCardsBrands.unknown);
  const [attemptedToLink, setAttemptedToLink] = useState(false);
  const errorRef = useRef(true);
  const cardNumberElementRef = useRef<CardNumberElement>(null);
  const cardVerificationCodeElementRef = useRef<CardVerificationCodeElement>(null);
  const cardExpirationDateElementRef = useRef<CardExpirationDateElement>(null);
  const { bt } = useBasisTheory(config.services.basisTheory.key, { elements: true });
  const handleLinkCard = useCallback(async () => {
    setAttemptedToLink(true);

    if (errorRef.current) {
      return;
    }

    setIsLinkingCard(true);

    let tokenizedData;
    let cardNumberTokenizedData;
    try {
      tokenizedData = await bt?.tokenize({
        type: 'card',
        data: {
          number: cardNumberElementRef.current,
          expiration_month: cardExpirationDateElementRef.current?.month() || null,
          expiration_year: cardExpirationDateElementRef.current?.year() || null,
          cvc: cardVerificationCodeElementRef.current,
        },
        mask: {
          number: '{{ data.number | slice: 0, 6 }}XXXXXX{{ data.number | last4 }}',
          expiration_month: '{{ data.expiration_month }}',
          expiration_year: '{{ data.expiration_year }}',
        },
      });

      cardNumberTokenizedData = await bt?.tokenize({
        type: 'card_number',
        data: cardNumberElementRef.current,
      });
    } catch (e) {
      onError?.(e);
    }

    if (tokenizedData && cardNumberTokenizedData) {
      const {
        id,
        data: { number, expiration_month: expirationMonth, expiration_year: expirationYear },
      } = tokenizedData;

      const { id: cardNumberId, fingerprint: cardNumberFingerprint } = cardNumberTokenizedData;

      setIsLinkingCard(false);

      onLinkCard({
        id,
        expirationMonth,
        expirationYear,
        last4Digits: number.slice(-4),
        cardBin: number.slice(0, 6),
        cardNumberIdentifiers: {
          token: cardNumberId,
          fingerprint: cardNumberFingerprint,
        },
      });
    }
  }, [bt, onError, onLinkCard]);

  useOnEnterPressed({ onEnterPressed: handleLinkCard });

  const handleReady = useCallback(() => {
    setFieldsLoading(false);
  }, []);

  const handleCardBrandChange = useCallback((cardBrand: BasisTheoryCardBrandType) => {
    setCardBrand(cardBrand);
  }, []);

  const handleErrorStateChange = useCallback((hasError: boolean) => {
    errorRef.current = hasError;
  }, []);

  return (
    <Flex height="full" justify="center" align="center" direction="column">
      <BasisTheoryProvider bt={bt}>
        <Flex width="full" justifyContent="center" mb={showComplianceMessage ? 'ds.2xl' : 0}>
          {areFieldsLoading ? <LinkCardViewLoader /> : null}
          <Flex width="45rem" minHeight="35rem" direction="column">
            <Box display={areFieldsLoading ? 'none' : 'initial'}>
              <Box mb="ds.2xl">
                <BrandsLogos selectedBrand={cardBrand} />
              </Box>
              <CardDetailsForm
                cardNumberElementRef={cardNumberElementRef}
                cardExpirationDateElementRef={cardExpirationDateElementRef}
                cardVerificationCodeElementRef={cardVerificationCodeElementRef}
                onCardBrandChange={handleCardBrandChange}
                onReady={handleReady}
                onError={handleErrorStateChange}
                attemptedToLink={attemptedToLink}
              />
              <Flex width="full" justify="center">
                <MIButton
                  testId="link-card"
                  label="linkCard.button"
                  isProcessing={showLinkCardButtonSpinner || isCardLinking}
                  onClick={handleLinkCard}
                  variant={BUTTON_VARIANT.PRIMARY}
                />
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </BasisTheoryProvider>
      {showComplianceMessage ? <SecurityComplianceMessage /> : null}
    </Flex>
  );
};
