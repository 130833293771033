import map from 'lodash/map';
import { all, call, put, select } from 'redux-saga/effects';
import regularBatchPaymentStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { getOrgId, getValidFundingSources } from 'src/redux/user/selectors';
import { VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES } from 'src/utils/consts';
import { isFundingSourceAndDeliveryMethodIncompatible } from 'src/billpay/qbdt/pages/batch-pay/utils';
import { AccountType } from 'src/utils/types';
import { DELETE_FUNDING_SOURCE_SUCCESS, VERIFY_FUNDING_SOURCE_FAILED } from 'src/redux/user/actionTypes';
import { BatchItemType } from '../regular-batch-payments/types/store-types';
import { regularBatchUpdatePaymentsSlice } from '../regular-batch-payments/regular-batch-update-payments-slice';
import { regularBatchUpdateDeliveryOptionsByDueDateSlice } from '../regular-batch-payments/regular-batch-update-delivery-options-slice-due-date';
import { regularBatchUpdateDeliveryOptionsSlice } from '../regular-batch-payments/regular-batch-update-delivery-options-slice';
import { regularBatchUpdateDeductionDatesSlice } from '../regular-batch-payments/regular-batch-update-deduction-dates-slice';

export const updateRegularBatchPaymentSaga = {
  *[DELETE_FUNDING_SOURCE_SUCCESS](action) {
    const { deletedFundingSourceId } = action;
    const batchItems: BatchItemType[] = yield select(regularBatchPaymentStore.selectors.allBills);
    yield all(
      map(batchItems, (batchItem) =>
        call(removeFundingSourceFromBillInBatch, {
          deletedFundingSourceId,
          batchItem,
        })
      )
    );
  },
  *[VERIFY_FUNDING_SOURCE_FAILED](action) {
    const { verifiedFundingSourceId, errorCode } = action;
    const batchItems: BatchItemType[] = yield select(regularBatchPaymentStore.selectors.allBills);
    if (errorCode === VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS) {
      yield all(
        map(batchItems, (batchItem) =>
          call(removeFundingSourceFromBillInBatch, {
            deletedFundingSourceId: verifiedFundingSourceId,
            batchItem,
          })
        )
      );
    }
  },
};

type RemoveFundingSourceFromBillInBatch = {
  batchItem: BatchItemType;
  deletedFundingSourceId: number;
};

function* removeFundingSourceFromBillInBatch({
  deletedFundingSourceId,
  batchItem,
}: RemoveFundingSourceFromBillInBatch) {
  const { fundingSourceId } = batchItem.payment;
  const batchItemId = batchItem.id;

  if (fundingSourceId === deletedFundingSourceId) {
    const fundingSources = yield select(getValidFundingSources);
    const validFundingSources = fundingSources.filter((fs) => fs.id !== deletedFundingSourceId);
    const newFundingSource = validFundingSources[0];
    if (newFundingSource) {
      yield call(setFundingSourceForBillInBatch, {
        batchItem,
        newFundingSource,
      });
    } else {
      yield put(
        regularBatchUpdatePaymentsSlice.actions.setPaymentsMethod({
          batchItemId,
          ...batchItem.payment,
          fundingSourceId: null,
          deliveryOptions: null,
        })
      );
    }
  }
}

type SetFundingSourceForBillInBatch = {
  batchItem: BatchItemType;
  newFundingSource: AccountType;
};

function* setFundingSourceForBillInBatch({ batchItem, newFundingSource }: SetFundingSourceForBillInBatch) {
  const orgId = yield select(getOrgId);
  const { id: newFundingSourceId } = newFundingSource;
  const batchItemId = batchItem.id;
  const { deliveryMethodId, deliveryMethod, amount, scheduledDate } = batchItem.payment;
  const { isScheduledDateSelectedByUser, dueDate } = batchItem;

  if (!deliveryMethodId) {
    yield put(
      regularBatchUpdateDeductionDatesSlice.actions({
        batchItemId,
        orgId,
        scheduledDate: new Date(),
        fundingSourceId: newFundingSourceId,
      })
    );
    return;
  }

  const areFundingSourceAndDeliveryMethodIncompatible = isFundingSourceAndDeliveryMethodIncompatible({
    deliveryMethod,
    fundingSource: newFundingSource,
  });

  if (areFundingSourceAndDeliveryMethodIncompatible) {
    yield put(
      regularBatchUpdatePaymentsSlice.actions.setPaymentsMethod({
        batchItemId,
        fundingSourceId: newFundingSourceId,
        deliveryMethod: null,
        deliveryMethodType: null,
        deliveryPreference: null,
        deliveryOptions: null,
      })
    );

    return;
  }

  if (isScheduledDateSelectedByUser) {
    yield put(
      regularBatchUpdateDeliveryOptionsSlice.actions({
        batchItemId,
        orgId,
        deductionDate: scheduledDate,
        deliveryMethodId,
        fundingSourceId: newFundingSourceId,
        payBillFlowUUID: batchItem.payBillFlowUUID,
        amount,
        // we want to reset delivery speed cell on funding source change.
        deliveryPreference: null,
      })
    );

    return;
  }

  yield put(
    regularBatchUpdateDeliveryOptionsByDueDateSlice.actions({
      batchItemId,
      orgId,
      dueDate,
      deliveryMethodId,
      fundingSourceId: newFundingSourceId,
      payBillFlowUUID: batchItem.payBillFlowUUID,
      amount,
      // we want to reset delivery speed cell on funding source change.
      deliveryPreference: null,
      scheduledDate: null,
    })
  );
}
