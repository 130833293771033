import React, { useCallback } from 'react';
import analytics from 'src/services/analytics/index';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import { ButtonVariantType, ButtonSizeType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { useSiteContext } from 'src/hoc/withSiteContext';

type Props = {
  label: string;
  analyticsProperties?: Record<string, any>;
  values?: Record<string, any>;
  variant?: ButtonVariantType;
  size?: ButtonSizeType;
  small?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  isProcessing?: boolean;
  iconClass?: string | null;
  textTransform?: string;
  logo?: string;
  onClick?: (event: Event) => void | Promise<void>;
  className?: string;
  testId?: string | null;
  type?: string;
};

const renderButton = (ButtonType, buttonProps, site, color = 'white') => (
  <ButtonType {...buttonProps} data-testid={buttonProps.testId || `button-${buttonProps.label}`}>
    {buttonProps.iconClass && <InlineIcon className={buttonProps.iconClass} />}
    {buttonProps.logo && <Image src={buttonProps.logo} alt="logo" />}
    <MIFormattedText label={buttonProps.label} values={buttonProps.values} />
    {buttonProps.processing && (
      <site.components.Loader
        context="button"
        color={color}
        height={buttonProps.size === CONSTS.BUTTON_SIZE.SMALL ? 4 : 5}
      />
    )}
  </ButtonType>
);

const Image = styled.img`
  padding: 0 1rem;
`;

const buttonHeight = css`
  ${(props: any) => {
    switch (props.size) {
      case CONSTS.BUTTON_SIZE.TINY:
        return 'height: 3.2rem; line-height: 1.8rem;';
      case CONSTS.BUTTON_SIZE.VERY_SMALL:
        return 'height: 3.6rem; line-height: 2.2rem;';
      case CONSTS.BUTTON_SIZE.SMALL:
        return 'height: 4.2rem; line-height: 2rem;';
      case CONSTS.BUTTON_SIZE.NORMAL:
      default:
        return 'height: 5rem; line-height: 2rem;';
    }
  }}
`;

const Button = styled.button<{
  size: string;
  textTransform?: string;
  fullWidth?: boolean;
  iconClass?: string;
  processing?: boolean;
}>`
  border-radius: 0.6rem;
  border: solid 0.2rem transparent;
  cursor: pointer;
  font-size: ${(props) => (props.size === CONSTS.BUTTON_SIZE.TINY ? '1.2rem' : props.theme.text.size.button)};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${buttonHeight}
  outline: none;
  padding: ${(props) => (props.size === CONSTS.BUTTON_SIZE.TINY ? '0 1.8rem' : '0 2.4rem')};
  position: relative;
  text-transform: ${(props) => (props.textTransform ? props.textTransform : 'none')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  overflow: ${(props) => (props.size === CONSTS.BUTTON_SIZE.TINY ? 'unset' : 'hidden')};
  display: ${(props) => (props.iconClass ? 'flex' : '')};
  align-items: ${(props) => (props.iconClass ? 'center' : '')};
  white-space: ${(props) => (props.size === CONSTS.BUTTON_SIZE.TINY ? 'nowrap' : 'unset')};
  &:hover {
    box-shadow: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: auto;
  }
  ${(props) => props.theme?.components?.MIButton?.MIButton}
`;

const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.brand};
  box-shadow: ${(props) => props.theme.buttons.boxShadow};
  color: ${(props) => props.theme.colors.white.opaque};

  &:hover {
    background-color: ${(props) => props.theme.colors.brand};
  }

  &:disabled {
    color: ${(props) => (props.processing ? 'transparent' : 'rgba(33, 33, 36, 0.3)')};
    background-color: ${(props) => (props.processing ? props.theme.colors.brand : 'rgba(238, 238, 238, 1)')};
  }

  &:active {
    background-color: ${(props) => (props.processing ? props.theme.colors.brand : 'rgba(108, 65, 230, 1)')};
  }
  ${(props) => props.theme?.components?.MIButton?.PrimaryButton}
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  border: solid 0.2rem ${(props) => props.theme.colors.brand};
  box-shadow: none;
  color: ${(props) => props.theme.colors.brand};

  &:hover {
    background-color: ${(props) => (props.processing ? 'transparent' : 'rgba(120, 73, 255, 0.1)')};
  }

  &:disabled {
    color: ${(props) => (props.processing ? 'transparent' : 'rgba(33, 33, 36, 0.3)')};
    background-color: transparent;
    border: solid 0.2rem ${(props) => (props.processing ? props.theme.colors.brand : 'rgba(33, 33, 36, 0.3)')};
  }

  &:active {
    background-color: ${(props) => (props.processing ? 'transparent' : 'rgba(120, 73, 255, 0.2)')};
  }
  ${(props) => props.theme?.components?.MIButton?.SecondaryButton}
`;

const TertiaryButton = styled(Button)`
  background-color: rgba(238, 238, 238, 1);
  box-shadow: none;
  color: rgba(33, 33, 36, 1);

  &:hover {
    background-color: rgba(222, 222, 222, 1);
  }

  &:disabled {
    background-color: rgba(238, 238, 238, 1);
    color: ${(props) => (props.processing ? 'transparent' : 'rgba(33, 33, 36, 0.3)')};
  }

  &:active {
    background-color: ${(props) => (props.processing ? 'transpaarent' : 'rgba(219, 219, 219, 1)')};
  }
  ${(props) => props.theme?.components?.MIButton?.TertiaryButton}
`;

const DestructiveButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.destructive};
  box-shadow: ${(props) => props.theme.buttons.boxShadow};
  color: ${(props) => props.theme.colors.white.opaque};

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    color: ${(props) => (props.processing ? 'transparent' : 'rgba(33, 33, 36, 0.3)')};
    background-color: ${(props) => (props.processing ? props.theme.colors.destructive : 'rgba(238, 238, 238, 1)')};
  }

  &:active {
    background-color: ${(props) => (props.processing ? props.theme.colors.destructive : 'rgba(194, 2, 26, 1)')};
  }
  ${(props) => props.theme?.components?.MIButton?.DestructiveButton}
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  margin-right: 0.7rem;
  border: ${(props) => `0.2rem solid ${props.theme.colors.text}`};

  &:hover {
    color: ${(props) => (props.processing ? 'transparent' : props.theme.colors.text)};
    background: ${(props) => props.theme.colors.border.darkGrey};
  }

  &:disabled {
    color: ${(props) => (props.processing ? 'transparent' : props.theme.colors.white.opaque)};
  }

  &:active {
    color: ${(props) => (props.processing ? 'transparent' : props.theme.colors.text)};
  }
`;

const UndoButton = styled(Button)`
  background-color: transparent;
  height: inherit;
  padding: 0;
  color: ${(props) => props.theme.colors.white.opaque};

  &:hover {
    color: ${(props) => props.theme.text.color.highlight};
  }

  &:disabled {
    color: ${(props) => (props.processing ? 'transparent' : props.theme.colors.dark.translucent3)};
  }

  &:active {
    color: ${(props) => (props.processing ? 'transparent' : 'rgba(92, 53, 203, 1)')};
  }
  ${(props) => props.theme?.components?.MIButton?.UndoButton}
`;

const EmptyButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.text.color.main};
  border: solid 0.1rem ${(props) => props.theme.text.color.main};
  box-shadow: none;

  &:disabled {
    opacity: 0.5;
  }
  ${(props) => props.theme?.components?.MIButton?.EmptyButton}
`;

const NakedButton = styled(Button)`
  background-color: transparent;
  color: #0077c5;

  box-shadow: none;

  &:disabled {
    opacity: 0.5;
  }
`;

const RemoveButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary.destructive};
  &:hover {
    color: ${(props) => props.theme.colors.failure.opaque};
  }

  &:active {
    color: ${(props) => props.theme.colors.failure.opaque};
  }
  ${(props) => props.theme?.components?.MIButton?.RemoveButton}
`;

const PayButton = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.colors.teal.translucent2};

  &:hover {
    background-color: ${(props) => props.theme.colors.teal.translucent2};
  }

  &:disabled {
    color: ${(props) => (props.processing ? 'transparent' : 'rgba(33, 33, 36, 0.3)')};
    background-color: ${(props) => (props.processing ? 'rgba(0, 210, 143, 1)' : 'rgba(238, 238, 238, 1)')};
  }

  &:active {
    background-color: ${(props) => (props.processing ? 'rgba(0, 210, 143, 1)' : 'rgba(2, 197, 135, 1)')};
  }
  ${(props) => props.theme?.components?.MIButton?.PayButton}
`;

const IntuitButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  background-color: ${(props) => props.theme.colors.intuit.active};

  &:hover {
    background-color: ${(props) => props.theme.colors.intuit.hover};
  }
  ${(props) => props.theme?.components?.MIButton?.IntuitButton}
`;

const InlineIcon = styled.i`
  font-size: 2.2rem;
  margin-right: 0.5rem;
  margin-top: -0.4rem;
  ${(props) => props.theme?.components?.MIButton?.InlineIcon}
`;

const MIButton = ({
  label,
  analyticsProperties,
  values,
  variant,
  size,
  disabled,
  fullWidth,
  isProcessing,
  onClick,
  iconClass,
  textTransform,
  logo,
  className,
  testId,
  type,
  small,
}: Props) => {
  const shouldBeDisabled = disabled || isProcessing;

  const onClickWithEvent = useCallback(
    (event) => {
      analytics.trackAction(label, analyticsProperties);
      return onClick && onClick(event);
    },
    [onClick, label]
  );
  const buttonProps = {
    disabled: shouldBeDisabled,
    size: small ? CONSTS.BUTTON_SIZE.SMALL : size,
    fullWidth,
    processing: isProcessing,
    onClick: onClickWithEvent,
    label,
    values,
    iconClass,
    textTransform,
    logo,
    className,
    testId,
    type,
  };
  const site = useSiteContext();

  if (variant === CONSTS.BUTTON_VARIANT.PRIMARY) {
    return renderButton(PrimaryButton, buttonProps, site);
  }

  if (variant === CONSTS.BUTTON_VARIANT.PAY) {
    return renderButton(PayButton, buttonProps, site);
  }

  if (variant === CONSTS.BUTTON_VARIANT.SECONDARY) {
    return renderButton(SecondaryButton, buttonProps, site, 'primary');
  }

  if (variant === CONSTS.BUTTON_VARIANT.TERTIARY) {
    return renderButton(TertiaryButton, buttonProps, site, 'dark');
  }

  if (variant === CONSTS.BUTTON_VARIANT.DESTRUCTIVE) {
    return renderButton(DestructiveButton, buttonProps, site);
  }

  if (variant === CONSTS.BUTTON_VARIANT.CANCEL) {
    return renderButton(CancelButton, buttonProps, site, 'primary');
  }

  if (variant === CONSTS.BUTTON_VARIANT.UNDO) {
    return renderButton(UndoButton, buttonProps, site);
  }

  if (variant === CONSTS.BUTTON_VARIANT.REMOVE) {
    return renderButton(RemoveButton, buttonProps, site);
  }

  if (variant === CONSTS.BUTTON_VARIANT.EMPTY) {
    return renderButton(EmptyButton, buttonProps, site);
  }

  if (variant === CONSTS.BUTTON_VARIANT.INTUIT) {
    return renderButton(IntuitButton, buttonProps, site);
  }

  if (variant === CONSTS.BUTTON_VARIANT.NAKED) {
    return renderButton(NakedButton, buttonProps, site);
  }

  return renderButton(Button, buttonProps, site, 'dark');
};

MIButton.defaultProps = {
  variant: CONSTS.BUTTON_VARIANT.TERTIARY,
  disabled: false,
  fullWidth: false,
  isProcessing: false,
  iconClass: null,
  size: CONSTS.BUTTON_SIZE.NORMAL,
  values: {},
  className: '',
  testId: null,
  type: 'button',
  analyticsProperties: {},
};

export default MIButton;
