import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { GoogleCombinedAddressType, FieldType, TaxIdType, BusinessType } from 'src/utils/types';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import { devices } from 'src/theme/AppDevices';
import { CONSTS } from 'src/utils/consts';
import { getErrorCode } from 'src/redux/payBillWizard/selectors';
import { resetErrorAction } from 'src/redux/payBillWizard/actions';
import failedToLoad from 'src/images/qbo/failed-to-load-002-icon.png';
import analytics from 'src/services/analytics';
import ErrorPage from 'src/components/layout/ErrorLayoutPage';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { OnSelectNaicsCode } from 'src/billpay/qbdt/components/CompanyIndustryAutocomplete';
import LegalInfoForm from './LegalInfoForm';

type Props = {
  legalCompanyName: string;
  companyName: string;
  taxId?: string;
  taxIdType?: TaxIdType;
  validationErrors: Record<string, any>;
  onLegalInfoChange: (field: FieldType) => void;
  isLoading: boolean;
  onAddressChange: (
    address: GoogleCombinedAddressType,
    type?: typeof CONSTS.ADDRESS_NAME_TYPE[keyof typeof CONSTS.ADDRESS_NAME_TYPE]
  ) => void;
  address: GoogleCombinedAddressType;
  goExit: () => void;
  handleOnSubmit: () => void;
  contactFirstName: string;
  contactLastName: string;
  phone?: string;
  dateOfBirth?: string;
  businessType: BusinessType;
  companyAddress: GoogleCombinedAddressType;
  openLegalAddressInput?: boolean;
  onSelectNaicsCode: OnSelectNaicsCode;
  onResetNaicsCode: () => void;
  onDateOfBirthChange: (value: string) => void;
};

const PayBillCompleteLegalInfoPage = ({
  legalCompanyName,
  taxId,
  taxIdType,
  address,
  isLoading,
  goExit,
  validationErrors,
  onLegalInfoChange,
  onAddressChange,
  handleOnSubmit,
  companyName,
  contactFirstName,
  contactLastName,
  companyAddress,
  openLegalAddressInput,
  phone,
  dateOfBirth,
  businessType,
  onSelectNaicsCode,
  onResetNaicsCode,
  onDateOfBirthChange,
}: Props) => {
  const errorCode = useSelector(getErrorCode);
  const intl = useIntl();
  const site = useSiteContext();
  const dispatch = useDispatch();

  const hideErrorAlert = () => {
    analytics.track('legal-info', 'hide-error-alert');
    dispatch(resetErrorAction());
  };

  if (errorCode) {
    analytics.track('bill-error', 'payment-cannot-be-approved');
    return (
      <ErrorPage
        illustration={failedToLoad}
        title="bills.pay.confirm.error.approvingPaymentTitle"
        subtitle="bills.pay.confirm.error.approvingPaymentSubtitle"
        buttonAction={goExit}
        buttonLabel="bills.pay.confirm.error.approvingPaymentCTA"
      />
    );
  }

  return (
    <QBOLayoutPage
      title={
        isLoading ? 'bills.pay.confirm.completeLegalInfo.titleLoading' : 'bills.pay.confirm.completeLegalInfo.title'
      }
      subtitle={
        isLoading
          ? 'bills.pay.confirm.completeLegalInfo.subtitleLoading'
          : 'bills.pay.confirm.completeLegalInfo.subtitle'
      }
      subTitleValues={{ companyName }}
      goExit={goExit}
      onNext={handleOnSubmit}
      innerSize={65}
      nextLabel="bills.pay.confirm.completeLegalInfo.action"
      isLoading={isLoading}
      fullWidthCTA
    >
      {errorCode && (
        <site.components.Dialog
          type={CONSTS.DIALOG_TYPE.ALERT}
          variant={CONSTS.DIALOG_VARIANTS.ERROR}
          title="bills.pay.confirm.error.title"
          subtitle={intl.messages[`server.${errorCode}`] ? `server.${errorCode}` : 'server.Network Error'}
          onCancelAction={hideErrorAlert}
          cancelButtonText="bills.pay.confirm.error.CTA"
        />
      )}
      <LegalInfoContainer>
        <FormWrapper>
          <LegalInfoForm
            legalCompanyName={legalCompanyName}
            address={address}
            onChange={onLegalInfoChange}
            validationErrors={validationErrors}
            taxId={taxId}
            contactFirstName={contactFirstName}
            contactLastName={contactLastName}
            companyAddress={companyAddress}
            openLegalAddressInput={openLegalAddressInput}
            phone={phone}
            taxIdType={taxIdType}
            dateOfBirth={dateOfBirth}
            businessType={businessType}
            onSelectNaicsCode={onSelectNaicsCode}
            onResetNaicsCode={onResetNaicsCode}
            onAddressChange={onAddressChange}
            onDateOfBirthChange={onDateOfBirthChange}
            companyFormPage={CONSTS.COMPANY_FORM_PAGE.ONBOARDING}
            isLoading={isLoading}
          />
        </FormWrapper>
      </LegalInfoContainer>
    </QBOLayoutPage>
  );
};

export default PayBillCompleteLegalInfoPage;

const LegalInfoContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 45rem;
  padding-top: 0.7rem;

  @media ${devices.desktop} {
    justify-content: space-between;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2.4rem 0 2rem;
  }
`;

const FormWrapper = styled.div``;
