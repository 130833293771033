import * as React from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import flatMap from 'lodash/flatMap';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import assign from 'lodash/assign';
import analytics from 'src/services/analytics';
import styled from 'styled-components';
import MIInputLabel from 'src/components/common/MIInputLabel';
import { MIFormattedText } from 'src/utils/formatting';
import { SingleSelectFlavor } from 'src/utils/types';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import pickBy from 'lodash/pickBy';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { Site } from 'src/sites/site';
import QuickbooksLoader from 'src/pages/quickbooks/components/QuickbooksLoader';
import MINotices from 'src/components/common/MINotices';

export type Option = {
  [key: string]: any;
  value: string;
  label?: string;
  options: Option[];
};

// FIXME meta flow definition is not correct.
// It should have ActionMeta type https://github.com/JedWatson/react-select/blob/master/src/types.js#L83
export type SelectFieldType = {
  id: string;
  value: any;
  meta: 'select-option' | 'deselect-option' | 'remove-value' | 'pop-value' | 'set-value' | 'clear' | 'create-option';
};
type OnChange = (changeValue: SelectFieldType) => void;

// FIXME meta flow definition is not correct.
// It should have InputActionMeta type https://github.com/JedWatson/react-select/blob/master/src/types.js#L93
export type OnInputChange = (value: {
  id: string;
  value: string;
  meta: 'set-value' | 'input-change' | 'input-blur' | 'menu-close';
}) => void;

export type FormatOptionContext = {
  context: 'menu' | 'value';
  inputValue: string;
  selectValue?: Option | Option[] | null;
};

export type overrideStyles = {
  option?: any;
  group?: any;
  groupHeading?: any;
  menuList?: any;
};

type Props = {
  id: string;
  value: any;
  label: string;
  placeholder?: string;
  noOptionsLabel?: string;
  noOptionsComponent?: React.ReactNode;
  options: Option[];
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isProcessing?: boolean;
  required?: boolean;
  createLabel?: string;
  allowCustom?: boolean;
  flavor?: SingleSelectFlavor;
  notices?: Array<string>;
  errorMessage?: string | null;
  site: Site;
  onChange?: OnChange;
  onInputChange?: OnInputChange;
  formatOptionLabel?: (option: Option, formatOptions: FormatOptionContext) => any;
  formatGroupLabel?: (group: Option) => any;
  formatCreateLabel?: (inputValue: string) => any;
  filterOption?: () => void;
  testId?: string | null;
  inputMaxLength?: number;
  isAutoFocus?: boolean;
  overrideStyles?: overrideStyles;
  isValidNewOption?: (inputValue: string, selectValue: Option, selectOptions: Option[]) => boolean;
  privateData?: boolean;
  components?: any;
  hideInput?: boolean;
  menuPosition?: string;
};

type State = {
  inputValue: string;
};

function fontSizeByFlavor(flavor: SingleSelectFlavor) {
  switch (flavor) {
    case CONSTS.SINGLE_SELECT_FLAVOR.INLINE:
      return '1.6rem';
    case CONSTS.SINGLE_SELECT_FLAVOR.TABLE:
      return '1.4rem';
    default:
      return '2.3rem';
  }
}

function borderWidthByFlavor(flavor: SingleSelectFlavor) {
  switch (flavor) {
    case CONSTS.SINGLE_SELECT_FLAVOR.INLINE:
      return '0.1rem';
    case CONSTS.SINGLE_SELECT_FLAVOR.TABLE:
      return '0';
    default:
      return '0.2rem';
  }
}

function optionColorsByState(state, colors, text) {
  if (state.isDisabled)
    return {
      background: colors.white.veryLightGrey,
      color: text.color.light,
      '&:hover': {},
      '&:active': { background: colors.white.whiteSmoke },
    };

  return {
    background: state.isSelected ? colors.white.whiteSmoke : 'white',
    color: 'black',
    '&:hover': {
      background: colors.white.whiteSmoke,
    },
    '&:active': state.isSelected ? { background: colors.white.whiteSmoke } : {},
  };
}

function heightByFlavor(flavor: SingleSelectFlavor) {
  switch (flavor) {
    case CONSTS.SINGLE_SELECT_FLAVOR.INLINE:
      return '3.5rem';
    case CONSTS.SINGLE_SELECT_FLAVOR.TABLE:
      return '3.5rem';
    default:
      return '4rem';
  }
}

// Configure the Select styles here since we pass them to
// the react-select component
const selectStyleTemplate = (
  flavor: SingleSelectFlavor,
  isSelectInvalid: boolean,
  theme: any,
  overrideStyles?: { [key: string]: React.CSSProperties }
) => {
  const { text, colors } = theme;
  const fontSize = fontSizeByFlavor(flavor);
  const lineHeight = 'normal';
  const fontWeight = text.weight.regular;
  const height = heightByFlavor(flavor);

  const minHeight = flavor === CONSTS.SINGLE_SELECT_FLAVOR.DEFAULT ? '4.5rem' : 'unset';
  const maxHeight = flavor === CONSTS.SINGLE_SELECT_FLAVOR.INLINE ? '3.2rem' : 'unset';

  return {
    // The main control component
    control: (base, state) => {
      const borderWidth = borderWidthByFlavor(flavor);
      const errorBottomBorder = `${borderWidth} solid ${text.color.red}`;
      const grayBottomBorder = isSelectInvalid
        ? errorBottomBorder
        : `${borderWidth} solid ${state.isDisabled ? text.color.readonly : text.color.light}`;
      const blackBottomBorder = `${borderWidth} solid black`;
      const borderBottom = state.selectProps.menuIsOpen ? blackBottomBorder : grayBottomBorder;
      const themeTemplate: any = theme?.components?.MISingleSelect?.controlStyleTemplate || (() => ({}));
      return {
        ...base,
        '&:hover': {
          grayBottomBorder,
        },
        border: 'none',
        borderBottom,
        borderRadius: '0',
        height,
        minHeight,
        maxHeight,
        maxWidth: 'unset',
        background: 'transparent',
        boxShadow: 'none',
        ...themeTemplate({ ...state, isSelectInvalid, theme }),
      };
    },

    // Text placeholder when nothing is selected
    placeholder: (base) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.placeholderStyleTemplate || (() => ({}));

      return {
        ...base,
        color: text.color.readonly,
        fontSize,
        fontWeight,
        marginLeft: 0,
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...themeTemplate({ isSelectInvalid, theme }),
      };
    },

    // The dropdown indicator (down arrow)

    dropdownIndicator: (base, state) => {
      const { hasValue, selectProps } = state;

      return {
        ...base,
        fontSize: '1.8rem',
        display: hasValue && selectProps.isClearable ? 'none' : 'flex',
        color:
          (selectProps.menuIsOpen || hasValue || selectProps.placeholder) && !state.isDisabled
            ? 'black'
            : text.color.label,

        '&:hover': {
          color: 'black',
        },
      };
    },

    // A separator for the indicators when something is selected
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),

    // The X button to clear the selection
    clearIndicator: (base) => ({
      ...base,
      color: 'black',
    }),

    // The input box (for the user to type)
    input: (base) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.inputStyleTemplate || (() => ({}));
      return {
        ...base,
        fontSize,
        fontWeight,
        marginLeft: 0,
        input: {
          fontWeight,
          lineHeight,
        },
        ...themeTemplate({
          isSelectInvalid,
          theme,
        }),
      };
    },

    // The group header label.
    groupHeading: (base) => ({
      ...base,
      padding: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      cursor: 'default',
      ...overrideStyles?.groupHeading,
    }),
    // The group container.
    group: (base) => ({
      ...base,
      padding: 0,
      ...overrideStyles?.group,
    }),

    // List item, the options when the menu is open
    option: (base, state) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.optionStyleTemplate || (() => ({}));
      return {
        ...base,
        ...optionColorsByState(state, colors, text),
        minHeight: '5rem',
        padding: '1.6rem 2rem',
        fontSize: '1.4rem',
        fontWeight: 'normal',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        cursor: 'default',
        ...themeTemplate({
          isSelectInvalid,
          theme,
        }),
        ...overrideStyles?.option,
      };
    },

    // The list of items
    menuList: (base) => ({
      ...base,
      padding: '0.8rem 0',
      border: 'none',
      borderRadius: '0.8rem',
      boxShadow: `0 0 1rem 0 ${colors.dark.translucent2}`,
      ...overrideStyles?.menuList,
    }),

    // When a single value is selected (how it appears in the control)
    singleValue: (base, state) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.singleValueStyleTemplate || (() => ({}));
      return {
        ...base,
        fontSize,
        fontWeight,
        lineHeight,
        margin: 0,
        color: state.isDisabled ? text.color.readonly : 'black',
        ...themeTemplate({
          ...state,
          isSelectInvalid,
          theme,
        }),
      };
    },

    valueContainer: (base) => ({
      ...base,
      padding: 0,
    }),
  };
};

const DropdownIndicator = () => (props) => {
  if (props.selectProps.isProcessing) {
    return (
      <StyledIndicator>
        <QuickbooksLoader context="button" />
      </StyledIndicator>
    );
  }

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className="icon-large-dropdown-arrow" />
      </components.DropdownIndicator>
    )
  );
};

function getSelectFlattenOptions(options: Option[]): Option[] {
  return flatMap(options, (option) => (option?.options ? option.options : option)) as Option[];
}

export function getSelectOptionObject(options: Option[], value): Option | null {
  if (!value) return null;

  const flattenOptions = getSelectFlattenOptions(options);
  return flattenOptions.find((option: Option) => option.value === value.toString()) || null;
}

class MISingleSelect extends React.PureComponent<Props, State> {
  static defaultProps = {
    placeholder: 'select.defaultPlaceholder',
    noOptionsLabel: 'select.noOptions',
    flavor: CONSTS.SINGLE_SELECT_FLAVOR.DEFAULT,
    isSearchable: true,
    isDisabled: false,
    required: false,
    isClearable: false,
    isProcessing: false,
    allowCustom: false,
    notices: [],
    errorMessage: null,
    createLabel: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onInputChange: () => {},
    formatOptionLabel: null,
    formatGroupLabel: null,
    formatCreateLabel: (inputValue: string) => inputValue,
    filterOption: undefined,
    testId: null,
    inputMaxLength: undefined,
    overrideStyles: {},
    isValidNewOption: null,
    components: {},
    hideInput: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      inputValue: '',
    };
  }

  selectRef = React.createRef<HTMLSelectElement | any>();

  handleChange = (newValue: any, actionMeta: any) => {
    if (this.props.onChange) {
      this.props.onChange({
        id: this.props.id,
        meta: actionMeta,
        ...newValue,
      });

      analytics.trackAction(`option-changed-${this.props.id}`, {
        option: newValue,
      });

      this.setState({ inputValue: '' }, () => this.selectRef.current?.blur());
    }
  };

  handleInputChange = (newInputValue: any, actionMeta: any) => {
    const { id, onInputChange } = this.props;

    // only set the input when the action that caused the
    // change equals to "input-change" and ignore the other
    // ones like: "set-value", "input-blur", and "menu-close"
    if (actionMeta.action === 'input-change') {
      if (onInputChange) {
        onInputChange({
          id,
          value: newInputValue,
          meta: actionMeta,
        });
      }

      this.setState({ inputValue: newInputValue });
    }
  };

  noOptionsMessage = () => {
    const { noOptionsLabel, noOptionsComponent } = this.props;

    return noOptionsComponent || <MIFormattedText label={noOptionsLabel || ''} />;
  };

  formatCreateLabel = (inputValue: string) => {
    const { createLabel } = this.props;

    return (
      <CreateLabel data-testid={createLabel}>
        <i className="icon-plus-icon" />
        {createLabel ? (
          <MIFormattedText label={createLabel} values={{ value: inputValue }} />
        ) : (
          <React.Fragment>{inputValue}</React.Fragment>
        )}
      </CreateLabel>
    );
  };

  isValidNewOption = (inputValue: string, selectValue: Option[], selectOptions: Option[]): boolean => {
    const flatOptions = getSelectFlattenOptions(selectOptions);

    return !(
      !inputValue ||
      selectValue.some((option) => option.label === inputValue) ||
      flatOptions.some((option) => option.label === inputValue)
    );
  };

  render() {
    const {
      id,
      label,
      value,
      flavor,
      placeholder,
      isSearchable,
      isClearable,
      isDisabled,
      required,
      allowCustom,
      options,
      notices,
      errorMessage,
      site,
      formatOptionLabel,
      filterOption,
      testId,
      inputMaxLength,
      isAutoFocus,
      formatGroupLabel,
      formatCreateLabel,
      createLabel,
      overrideStyles,
      isValidNewOption,
      privateData,
      components,
      menuPosition,
      isProcessing,
      hideInput,
    } = this.props;
    const { inputValue } = this.state;

    // NOTICE! the pickBy here is buggy! using pickBy with single parameter means that
    // only truthy fields will be picked. meaning that if we want to override with falsy
    // values - we can't. We have another step after initializing this props so please move
    // new props there.
    const obsoleteSelectProps = pickBy({
      options,
      value: isObject(value) ? value : getSelectOptionObject(options, value),
      styles: selectStyleTemplate(
        flavor || MISingleSelect.defaultProps.flavor,
        !!errorMessage,
        site.theme,
        overrideStyles
      ),
      placeholder: placeholder ? <MIFormattedText label={placeholder} /> : '',
      components: { DropdownIndicator: DropdownIndicator(), ...components },
      isClearable,
      isDisabled,
      isProcessing,
      menuPosition,
      required,
      inputValue:
        !inputMaxLength || inputValue.length <= inputMaxLength ? inputValue : inputValue.substr(0, inputMaxLength),
      // We need to controll close and blur events manually to make the feature above works properly on mobile devices.
      // "entering a new vendor, the value should stay in the field (and later be the new vendor name) - after the focus change, not only on click on "+""
      // Without it we have race condition for select and blur events
      closeMenuOnSelect: false,
      blurInputOnSelect: false,
      noOptionsMessage: this.noOptionsMessage,
      formatCreateLabel: createLabel ? this.formatCreateLabel : formatCreateLabel,
      onChange: this.handleChange,
      onInputChange: this.handleInputChange,
      formatOptionLabel,
      formatGroupLabel,
      filterOption,
      isValidNewOption: isValidNewOption || this.isValidNewOption,
      classNamePrefix: 'select',
    });
    const selectProps = assign({}, obsoleteSelectProps, {
      isSearchable: isUndefined(isSearchable) ? MISingleSelect.defaultProps.isSearchable : isSearchable,
    });
    const size = flavor === CONSTS.SINGLE_SELECT_FLAVOR.DEFAULT ? 'wizard' : 'inline';
    const selectTestId = testId || `input-${id}`;
    const selectClassName = classNames({
      [FULL_STORY_MASK_RULE_CLASS]: privateData,
    });

    return (
      <SelectWrapper flavor={flavor} data-testid={selectTestId} hideInput={hideInput}>
        <MIInputLabel inputId={id} label={label} errorMessage={errorMessage} size={size} required={required} />
        {allowCustom ? (
          <CreatableSelect ref={this.selectRef} autoFocus={isAutoFocus} className={selectClassName} {...selectProps} />
        ) : (
          <Select ref={this.selectRef} className={selectClassName} {...selectProps} />
        )}

        <MINotices testId={`${selectTestId}-notices`} size={size} notices={notices} errorMessage={errorMessage} />
      </SelectWrapper>
    );
  }
}

// This wraps the "Create custom" option
// Adjust the icon's font to look on par with the select
// I do this by changing the i element, and I use "em" for size
// because it may come in different sizes
const CreateLabel = styled.span`
  i {
    vertical-align: text-bottom;
    font-size: 1.4em;
    margin-right: 1.2rem;
  }
`;

const SelectWrapper = styled.div<{ flavor?: SingleSelectFlavor }>`
  max-width: 100%;
  flex-grow: 1;
  margin-bottom: ${(props) => (props.flavor === CONSTS.SINGLE_SELECT_FLAVOR.DEFAULT ? '3rem' : '0')};
  display: ${({ hideInput }) => (hideInput ? 'none' : 'block')};
  ${(props) => props.theme?.components?.MISingleSelect?.selectWrapper}
`;

const StyledIndicator = styled.div`
  svg {
    left: auto;
    right: 0;
  }
`;

export default withSiteContext()(MISingleSelect);
