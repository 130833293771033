import React from 'react';
import { useSelector } from 'react-redux';
import paymentsStore from 'src/modules/payments/payment-store';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardTableRangeDate } from '../components/DashboardTableRangeDate';

type Props = {
  item: DashboardListItemType;
};

export const DashboardDeliveryEtaCell = ({ item }: Props) => {
  const payment = useSelector(paymentsStore.selectors.byId(item.paymentId));
  return <DashboardTableRangeDate fromDate={payment?.deliveryEta} toDate={payment?.maxDeliveryEta} />;
};
