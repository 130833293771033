import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';

const SandboxIndicator = () => (
  <SandboxIndicationContainer>
    <MIFormattedText label="onboarding.fundingSources.card.sandboxIndication" />
  </SandboxIndicationContainer>
);

export default SandboxIndicator;

const SandboxIndicationContainer = styled.div`
  background-color: ${(props) => props.theme.text.color.label};
  color: ${(props) => props.theme.colors.white.opaque};
  font-size: 1.5rem;
  line-height: 3.2rem;
  height: 3.2rem;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  position: relative;

  @media ${devices.mobile} {
    margin-top: 4rem;
  }
`;
