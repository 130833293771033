import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverAnchor, VStack, HStack } from '@chakra-ui/react';
import { Box, Button } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = PropsWithChildren<WalkthroughPopoverProps>;

export type WalkthroughPopoverProps = {
  isOpen: boolean;
  title: string;
  message: string;
  stepLabel: string;
  stepLabelValues: Record<string, any>;
  ctaLabel: string;
  hasBackButton: boolean;
  onCloseClick: VoidFunction;
  onNextClick: VoidFunction;
  onBackClick: VoidFunction;
};

const WalkthroughPopover = ({
  children,
  isOpen,
  title,
  message,
  stepLabel,
  stepLabelValues,
  ctaLabel,
  hasBackButton,
  onCloseClick,
  onNextClick,
  onBackClick,
}: Props) => (
  <PopoverContainer>
    <Box __css={popoverStyle}>
      <Popover isOpen={isOpen} placement="bottom">
        <PopoverAnchor>
          <Box>{children}</Box>
        </PopoverAnchor>
        <PopoverContent onClick={(e) => e.stopPropagation()}>
          <PopoverArrow />
          <PopoverBody w="32rem" padding="2rem" position="relative" boxSizing="border-box">
            <Box
              as="i"
              className="icon-close-icon"
              onClick={onCloseClick}
              color="ds.gray.200"
              fontSize="2.4rem"
              cursor="pointer"
              position="absolute"
              right="ds.xl"
              top="2.2rem"
            />

            <VStack spacing="ds.lg" align="flex-start" color="ds.gray.100">
              <Box textStyle="ds.h6" mr="3.2rem">
                <MIFormattedText label={title} />
              </Box>
              <Box textStyle="ds.body2">
                <MIFormattedText label={message} />
              </Box>

              <HStack w="full" justify="space-between" align="center">
                <Box textStyle="ds.body2" color="ds.gray.300">
                  <MIFormattedText label={stepLabel} values={stepLabelValues} />
                </Box>
                <HStack spacing="ds.sm">
                  {hasBackButton && (
                    <Button size="md" variant="tertiary" onClick={onBackClick} bgColor="white">
                      <MIFormattedText label="general.back" />
                    </Button>
                  )}
                  <Button size="md" variant="primary" onClick={onNextClick}>
                    <MIFormattedText label={ctaLabel} />
                  </Button>
                </HStack>
              </HStack>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  </PopoverContainer>
);

const PopoverContainer = styled.div`
  position: relative;
  margin-top: 0;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.regular};
  .chakra-popover__popper {
    z-index: 1;
  }
  .chakra-popover__content {
    outline: none;
  }
  .chakra-popover__body {
    border: 1px solid var(--chakra-colors-ds-gray-500);
    border-radius: var(--chakra-radii-ds-md);
    box-shadow: var(--chakra-shadows-ds-md);
    background-color: var(--chakra-colors-white);
  }
`;

const arrowStyle: React.CSSProperties = {
  height: '11.3px!important',
  width: '11.3px!important',
  boxSizing: 'border-box!important' as any,
  backgroundColor: 'var(--chakra-colors-white)!important',
};

const popoverStyle: Record<string, React.CSSProperties> = {
  '[data-popper-placement*="bottom"] .chakra-popover__arrow-positioner': {
    height: '11.3px!important',
    width: '11.3px!important',
    // zIndex could be number or Globals in React types, but string is also valid
    zIndex: '10001!important' as React.CSSProperties['zIndex'],
  },

  '[data-popper-placement*="bottom"] .chakra-popover__arrow': {
    ...arrowStyle,
    top: '-1.5px!important',
    boxShadow: '-0.2rem -0.2rem 0.2rem var(--chakra-colors-ds-gray-700)!important',
    borderTop: '1px solid var(--chakra-colors-ds-gray-500)!important',
    borderLeft: '1px solid var(--chakra-colors-ds-gray-500)!important',
  },

  '[data-popper-placement*="bottom"] .chakra-popover__content': {
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },

  '[data-popper-placement*="top"] .chakra-popover__arrow-positioner': {
    height: '11.3px!important',
    width: '11.3px!important',
    // zIndex could be number or Globals in React types, but string is also valid
    zIndex: '10001!important' as React.CSSProperties['zIndex'],
  },

  '[data-popper-placement*="top"] .chakra-popover__arrow': {
    ...arrowStyle,
    top: '1.5px!important',
    boxShadow: '0.2rem 0.2rem 0.2rem var(--chakra-colors-ds-gray-700)!important',
    borderBottom: '1px solid var(--chakra-colors-ds-gray-500)!important',
    borderRight: '1px solid var(--chakra-colors-ds-gray-500)!important',
  },

  '[data-popper-placement*="top"] .chakra-popover__content': {
    marginBottom: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
};

export { WalkthroughPopover };
