import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';
import { NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';

type LabelValues = {
  label: string;
  values?: Record<string, any>;
};

type Props = {
  type: NOTIFICATION_CARD_TYPES;
  title?: LabelValues;
  showIcon?: boolean;
  subtitle?: LabelValues;
  testId?: string;
  onClose?: () => void;
};

export const QBDTNotificationCard = ({ type, title, showIcon = true, subtitle, onClose, testId }: Props) => {
  const iconClass = getIcon(type);
  const color = getColor(type);

  return (
    <Container borderColor={color} data-testId={testId}>
      {showIcon && <Icon className={iconClass} color={color} />}
      <TextContainer className="notification-card-text-container">
        <NotificationTitle title={title} type={type} color={color} />
        <NotificationSubtitle subtitle={subtitle} />
      </TextContainer>
      {!!onClose && <CloseButton className="icon-close-icon" onClick={onClose} />}
    </Container>
  );
};

type NotificationTitleProps = {
  color: string;
} & Pick<Props, 'title' | 'type'>;

// TODO: Replace with colors from DS
const NotificationTitle = ({ title, type, color }: NotificationTitleProps) => (
  <Box color={type === NOTIFICATION_CARD_TYPES.ERROR ? color : '#393a3d'} textStyle="body7">
    <MIFormattedText label={title?.label} values={title?.values} />
  </Box>
);

// TODO: Replace with colors from DS
const NotificationSubtitle = ({ subtitle }: Pick<Props, 'subtitle'>) => (
  <Box color="#6b6c72" textStyle="body8">
    <MIFormattedText label={subtitle?.label} values={subtitle?.values} />
  </Box>
);

const getIcon = (type: NOTIFICATION_CARD_TYPES) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.INFO:
      return 'icon-intuit-info-icon';
    case NOTIFICATION_CARD_TYPES.SUCCESS:
      return 'icon-full-checkmark-icon';
    case NOTIFICATION_CARD_TYPES.WARNING:
    default:
      return 'icon-warning-icon';
  }
};

const getColor = (type: NOTIFICATION_CARD_TYPES) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.ERROR:
      return 'red.200';
    case NOTIFICATION_CARD_TYPES.WARNING:
      return 'yellow.300';
    case NOTIFICATION_CARD_TYPES.INFO:
      return 'blue.500';
    case NOTIFICATION_CARD_TYPES.SUCCESS:
    default:
      return 'green.100';
  }
};

const Container = chakra(Flex, {
  baseStyle: {
    borderRadius: 'lg',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    boxSizing: 'border-box',
    padding: '4',
    backgroundColor: 'ds.white',
  },
});

const TextContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const Icon = chakra('i', {
  baseStyle: {
    fontSize: '2.4rem',
    ml: '-0.3rem',
    mt: '-0.3rem',
    mr: '0.7rem',
  },
});

const CloseButton = chakra('i', {
  baseStyle: {
    color: 'ds.color.200',
    fontSize: '2.4rem',
    cursor: 'pointer',
    ml: 'auto',
    alignSelf: 'start',
  },
});
