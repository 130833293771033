import React, { useEffect } from 'react';
import analytics from 'src/services/analytics';

export type AuthWithAppcenterPageProps = {
  appcenterUrl: string;
  realmId: string;
};

export function AuthWithAppcenterPageRedirect({ appcenterUrl, realmId }: AuthWithAppcenterPageProps) {
  useEffect(() => {
    analytics.track('qbdt-entry', 'redirect-to-appcenter-action', { realmId });
    window.location.href = appcenterUrl;
  }, []);

  return <></>;
}
