import React from 'react';
import { Checkbox, Table, Tooltip } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { DashboardListItemType } from '../../../types';
import { useDashboardHighlightedItems } from '../../../hooks/useDashboardHighlightedItems';
import { ColumnItem } from '../types';

type Props = {
  item: DashboardListItemType;
  selectionEnabled: boolean;
  selectedIds: string[];
  selectableItemsIds: string[];
  getDisabledCheckboxTooltip: (id?: string) => string;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  columns: ColumnItem<DashboardListItemType>[];
};

export const DashboardTableRow = ({
  item,
  selectableItemsIds,
  selectedIds,
  selectionEnabled,
  getDisabledCheckboxTooltip,
  onCheckboxChange,
  columns,
}: Props) => {
  const { highlightedItemIds } = useDashboardHighlightedItems();

  const getHighlightedClass = (itemId: string) => (highlightedItemIds?.includes(itemId) ? 'highlighted' : undefined);

  const rowId = `data-table-row-${item.id}`;

  return (
    <Table.Row id={rowId} data-testid={rowId} key={item.id} className={getHighlightedClass(item.id)}>
      {selectionEnabled && (
        <Table.Cell key={`checkbox_${item.id}`} w="ds.md" overflow="initial">
          <Tooltip
            placement="bottom-start"
            data-testid={`data-table-cell-checkbox-tooltip-${item.id}`}
            label={<MIFormattedText label={getDisabledCheckboxTooltip(item.id)} />}
            shouldWrapChildren
            isDisabled={selectableItemsIds.includes(item.id)}
          >
            <Checkbox
              data-testid={`data-table-cell-checkbox-${item.id}`}
              isChecked={(selectedIds || []).includes(item.id)}
              onChange={(event) => onCheckboxChange(event, item.id)}
              isDisabled={!selectableItemsIds.includes(item.id)}
            />
          </Tooltip>
        </Table.Cell>
      )}
      {columns.map(({ Cell, width, isNumeric, accessor }) => (
        <Table.Cell
          data-testid={`data-table-cell-${accessor}-${item.id}`}
          key={`${accessor}_${item.id}`}
          w={width}
          isNumeric={isNumeric}
          overflow="visible"
        >
          {Cell ? <Cell item={item} /> : item[accessor]}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};
