import { RecordOf } from 'immutable';
import React from 'react';
import { VStack } from '@melio/billpay-design-system';
import { BILL_STATUS, DELIVERY_TYPE } from 'src/utils/consts';
import { BillType, PaymentType, DeliveryMethodType, AccountType } from 'src/utils/types';
import { PaymentInfoSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSection';
import { PaymentInfoSectionDivider } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSectionDivider';
import { ViewPaymentDeliveryDateInfo } from '../common-fields/ViewPaymentDeliveryDateInfo/ViewPaymentDeliveryDateInfo';
import { ViewPaymentDeliveryMethodInfo } from './fields/ViewPaymentDeliveryMethodInfo';

type Props = {
  bill: BillType;
  payment: PaymentType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: RecordOf<AccountType>;
};

export const VendorInfoSection = ({ bill, payment, deliveryMethod, fundingSource }: Props) => {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isVirtualDeliveryMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const receiveLabel = isBillPaid ? 'viewBillPaymentActivity.receivedTitle' : 'viewBillPaymentActivity.receiveTitle';

  return (
    <PaymentInfoSection label={receiveLabel}>
      <VStack spacing="5" alignItems="initial">
        {!isVirtualDeliveryMethod && (
          <>
            <ViewPaymentDeliveryMethodInfo deliveryMethod={deliveryMethod} bill={bill} payment={payment} />
            <PaymentInfoSectionDivider />
          </>
        )}
        <ViewPaymentDeliveryDateInfo
          bill={bill}
          payment={payment}
          deliveryMethod={deliveryMethod}
          fundingSource={fundingSource}
        />
      </VStack>
    </PaymentInfoSection>
  );
};
