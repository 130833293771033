import { useCallback } from 'react';
import { useQueryState } from 'src/utils/hooks';

type HighlightedItemsReturn = {
  removeHighlightedIds: () => void;
  hasHighlightParam: boolean;
  highlightedItemIds: string[];
};

export const useDashboardHighlightedItems = (): HighlightedItemsReturn => {
  const [highlightedItemIdsParam, setHighlightedItemIds] = useQueryState<string | undefined>('highlightedItemIds');
  const highlightedItemIds = highlightedItemIdsParam?.split(',') || [];

  const hasHighlightParam = !!highlightedItemIds.length;

  const removeHighlightedIds = useCallback(() => {
    if (hasHighlightParam) {
      setHighlightedItemIds(undefined);
    }
  }, [hasHighlightParam, setHighlightedItemIds]);

  return {
    hasHighlightParam,
    highlightedItemIds,
    removeHighlightedIds,
  };
};
