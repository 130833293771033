import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { BillsInfoPopover } from './BillsInfoPopover';
import { BillInfo } from './types';

type Props = {
  billsInfo: BillInfo[];
};

export const BulkPaymentInvoiceNumberCell = ({ billsInfo }: Props) => (
  <BillsInfoPopover billsInfo={billsInfo}>
    <Box data-testid="bill-invoice-number" color="ds.blue.200" fontWeight="ds.medium" cursor="pointer">
      <MIFormattedText label="bills.view.numBills" values={{ number: billsInfo.length }} />
    </Box>
  </BillsInfoPopover>
);
