import React from 'react';
import { Box, Flex, Menu } from '@melio/billpay-design-system';
import analytics from 'src/services/analytics';
import { MIFormattedText } from 'src/utils/formatting';
import { MainActionWrapper } from './MainActionWrapper';
import { mainActionLabelStyle, menuButtonStyle } from './styles';
import { ReactComponent as ArrowDownIcon } from '../../images/arrow-down.svg';
import { DashboardMenuAction } from '../types';

type Props = {
  itemId: string;
  eventProperties: {
    billId: string;
    status: string;
  };
  actions?: DashboardMenuAction[];
};

export const DashboardActionsCell = ({ itemId, eventProperties, actions = [] }: Props) => {
  const [mainAction, ...menuActions] = actions;
  const showActionsMenu = menuActions.length > 0;

  const handleActionClick = (action: DashboardMenuAction) => {
    analytics.trackAction('action-click', {
      ...eventProperties,
      action: action.id,
      fromRow: false,
    });
    action.onClick();
  };

  if (actions.length === 0) {
    return null;
  }

  return (
    <Flex justifyContent="flex-end" align="center" mr={showActionsMenu ? '-ds.sm' : '0'}>
      <MainActionWrapper tooltip={mainAction.tooltip}>
        <Box
          as="span"
          __css={mainActionLabelStyle}
          onClick={() => handleActionClick(mainAction)}
          data-testid={`dashboard-main-action-${itemId}`}
        >
          <MIFormattedText label={mainAction.label} />
        </Box>
      </MainActionWrapper>

      {showActionsMenu && (
        <Menu>
          <Menu.MenuButton
            as={Flex}
            ml="3"
            data-testid={`dashboard-actions-list-button-${itemId}`}
            __css={menuButtonStyle}
          >
            <ArrowDownIcon />
          </Menu.MenuButton>
          <Menu.MenuList>
            {menuActions.map((action) => (
              <Menu.MenuItem
                key={action.label}
                onClick={() => handleActionClick(action)}
                data-testid={`dashboard-actions-${action.label}`}
              >
                <MIFormattedText label={action.label} />
              </Menu.MenuItem>
            ))}
          </Menu.MenuList>
        </Menu>
      )}
    </Flex>
  );
};
