import { postRequest } from 'src/services/api/api';
import {
  ValidateCardAccountWithTokenRequest,
  CreateCardAccountWithTokenRequest,
  CreateCardAccountWithTokenResponse,
  ValidateCardAccountWithTokenResponse,
} from '../types';

export const addCardAccountDeliveryMethod = {
  validateCardAccountWithToken(
    params: Omit<ValidateCardAccountWithTokenRequest, 'digits' | 'expiration'>
  ): Promise<ValidateCardAccountWithTokenResponse> {
    const url = '/delivery-method/push-to-debit/validate-card';
    return postRequest(url, params);
  },

  createCardAccountWithToken(params: CreateCardAccountWithTokenRequest): Promise<CreateCardAccountWithTokenResponse> {
    const url = '/delivery-method/create-card-account';
    return postRequest(url, params);
  },
};
