import * as React from 'react';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import { useBreak } from 'src/hoc';
import { devices } from 'src/theme/AppDevices';
import Logo from 'src/images/Logo';

const OutsideHeader = () => {
  const device = useBreak();
  const goLearnMore = () => {
    analytics.track('unilateral', 'learn-more');
    window.open('https://www.meliopayments.com/request');
  };

  return (
    <OutsideHeaderContainer>
      <LogoContainer onClick={goLearnMore}>
        <Logo fill="white" size={device.isMobile || device.isPhablet ? 'mobile' : 'regular'} isFull />
      </LogoContainer>
    </OutsideHeaderContainer>
  );
};

export default OutsideHeader;

const OutsideHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 5rem 0;
  background-color: ${(props) => props.theme.colors.primary.opaque};
  height: 15rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0 2rem 0;
    height: 7.7rem;
    align-items: center;
  }
`;

const LogoContainer = styled.a`
  cursor: pointer;
  height: 4.2rem;
  @media ${devices.mobile}, ${devices.phablet} {
    height: 3.5rem;
  }
`;
