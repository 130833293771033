import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { orgPrefKey, shouldTreatNotification, useAchFee } from 'src/billpay/qbdt/hooks/useAchFee/index';
import { FundingAndDeliveryProps } from 'src/billpay/qbdt/hooks/useAchFee/types';
import { getOrganizationPreferences } from 'src/redux/organization/selectors';

/**
 * Temp code until fee in check
 * Shows notification of future fee in select delivery method
 */
type Props = FundingAndDeliveryProps & {
  className?: string;
};

const Notification = ({ selectedFundingSource, selectedDeliveryMethod, batchFundingSources, className }: Props) => {
  const organizationPreferences = useSelector(getOrganizationPreferences);
  const { achFees } = useAchFee();

  if (!achFees.enabled) {
    return null;
  }

  if (!shouldTreatNotification({ selectedFundingSource, selectedDeliveryMethod, batchFundingSources })) {
    return null;
  }

  if (organizationPreferences?.[orgPrefKey(achFees.type)] === 'yes') {
    return null;
  }

  const label =
    achFees.type === 'after'
      ? 'deliveryMethods.notDefinedInfo.ach.feesNotificationAfter'
      : 'deliveryMethods.notDefinedInfo.ach.futureFeesNotification';

  return (
    <StyledCard className={className}>
      <QBONotificationCard
        type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
        subtitle={{
          label,
          values: {
            freeUntilDateShort: achFees.freeUntilShortLabel,
          },
        }}
      />
    </StyledCard>
  );
};

export { Notification };

const StyledCard = styled.div`
  margin: 0 0 3.5rem 0;

  a {
    cursor: pointer;
  }

  .batch {
    margin-top: 3.5rem;
  }
`;
