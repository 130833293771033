import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { MicroDepositsVerifyPageContainer } from 'src/pages/funding-sources/MicroDepositsVerifyPageContainer';
import React from 'react';
import locations from 'src/pages/locations';
import { MicroDepositsLinkExpiredPage } from 'src/pages/funding-sources/MicroDepositsLinkExpiredPage';
import { MicroDepositsAlreadyVerifiedPage } from 'src/pages/funding-sources/MicroDepositsAlreadyVerifiedPage';
import { MicroDepositsBlockedPageNew as MicroDepositsBlockedPage } from 'src/pages/funding-sources/MicroDepositsBloсkedPageNew';
import { MicroDepositsCannotVerifyYetPage } from 'src/pages/funding-sources/MicroDepositsCannotVerfiyYetPage';
import { MicroDepositsSuccessPage } from 'src/pages/funding-sources/MicroDepositsSuccessPage';

export const MicroDepositsVerifyRouter = () => (
  <Switch>
    <SmartRoute path={locations.microDepositsVerify.verify} exact>
      <MicroDepositsVerifyPageContainer />
    </SmartRoute>
    <SmartRoute path={locations.microDepositsVerify.success} exact>
      <MicroDepositsSuccessPage />
    </SmartRoute>
    <SmartRoute path={locations.microDepositsVerify.blocked} exact>
      <MicroDepositsBlockedPage />
    </SmartRoute>
    <SmartRoute path={locations.microDepositsVerify.expired} exact>
      <MicroDepositsLinkExpiredPage />
    </SmartRoute>
    <SmartRoute path={locations.microDepositsVerify.alreadyVerified} exact>
      <MicroDepositsAlreadyVerifiedPage />
    </SmartRoute>
    <SmartRoute path={locations.microDepositsVerify.cannotVerifyYet} exact>
      <MicroDepositsCannotVerifyYetPage />
    </SmartRoute>
  </Switch>
);
