import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import useGetValidFundingSources from 'src/billpay/qbdt/pages/batch-pay/table/hooks/useGetValidFundingSources';
import { FundingSourceCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/FundingSourceCell';
import analytics from 'src/services/analytics';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types/store-types';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useSelectFundingSourceForBatch } from '../../hooks/useSelectFundingSourceForBatchFlow';
import { getBillIdsFromBatchItem } from '../../utils';

type PaymentMethodCellProps = {
  batchItemId: string;
  payment: BatchPaymentType;
  dueDate: Date;
  isScheduledDateSelectedByUser: boolean;
};

export const PaymentMethodCell: React.FC<PaymentMethodCellProps> = memo(
  ({ batchItemId, payment, dueDate, isScheduledDateSelectedByUser }) => {
    const [validFundingSources] = useGetValidFundingSources();

    const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchItemId));
    const { fundingSourceId } = payment;

    const { selectFundingSourceForBatchFlow } = useSelectFundingSourceForBatch({
      batchItemId,
      applyToAllBills: false,
    });

    const onChange = async (newFundingSourceId: number) => {
      if (fundingSourceId === newFundingSourceId) return;

      const newFundingSource = validFundingSources.find((fs) => fs.id === newFundingSourceId);

      await selectFundingSourceForBatchFlow({
        newFundingSourceId,
      });

      analytics.trackAction('change-payment-method', {
        fromTable: 'yes',
        billIds: getBillIdsFromBatchItem(batchItem),
        vendorId: payment.vendor.id,
        fundingSourceType: newFundingSource?.fundingType,
        fundingSourceId: newFundingSourceId,
        payBillFlowUuid: batchItem?.payBillFlowUUID,
      });
    };

    return (
      <FundingSourceCell
        batchItemId={batchItemId}
        fundingSources={validFundingSources}
        fundingSourceId={payment?.fundingSourceId}
        setCurrentFundingSourceId={onChange}
        payment={payment}
        dueDate={dueDate}
        isScheduledDateSelectedByUser={isScheduledDateSelectedByUser}
      />
    );
  }
);
