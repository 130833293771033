import React from 'react';
import { Text } from '@melio/billpay-design-system';
import { useBreak } from 'src/hoc';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  label: string;
};

export const DeliveryMethodCardTitle = ({ label }: Props) => {
  const { isDesktop } = useBreak();
  return (
    <Text textStyle={isDesktop ? 'ds.body2' : 'ds.body1'} fontWeight="ds.medium" color="ds.gray.100" m="0">
      <MIFormattedText label={label} />
    </Text>
  );
};
