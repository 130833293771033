import { Record } from 'immutable';
import { UserContextType, UserPreferencesType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';

export const UserContextRecord = Record<UserContextType>(
  {
    email: '',
    isIntuitLinked: false,
    isEmailVerified: false,
    id: null,
    isGuest: false,
    name: '',
    dateOfBirth: '',
    orgId: '',
    orgName: '',
    userPreferences: null,
    organizations: [],
    registrationOrigin: CONSTS.REGISTRATION_ORIGIN.APP,
    registrationFlow: CONSTS.REGISTRATION_FLOW.REGULAR,
    firstName: '',
    lastName: '',
  },
  'UserContextRecord'
);

export const UserPreferencesRecord = Record<UserPreferencesType>(
  {
    accountingSoftwareType: null,
    sendBillDueEmailReminder: true,
    billQbSyncedNotification: true,
    allowCompaniesSwitcher: false,
    getPaidProLayout: false,
    qbDashboardSeen: true,
    showQbDashboardInternalEntrypoints: false,
    qbExistingDebitCardFeeModal: false,
    qbExistingCheckFeeModal: false,
    qbdtBatchBulkPaymentsWalkthroughStepOneSeen: false,
    qbdtBatchBulkPaymentsWalkthroughStepTwoSeen: false,
    checkFeesNotificationCount: null,
    hideBatchPaymentsHolidaysNotification: false,
    hideSinglePayHolidaysNotification: false,
    qbdtDashboardSeen: false,
  },
  'UserPreferencesRecord'
);
