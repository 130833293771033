import React, { useEffect, useState } from 'react';
import { Text, Image, Flex, Box, Link, VStack } from '@melio/billpay-design-system';
import { useHistory, generatePath } from 'react-router-dom';
import { useBreak } from 'src/hoc';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import POSTerminal from 'src/images/virtual-card/pos-terminal.png';
import MIButton from 'src/components/common/MIButton';
import {
  UnilateralPaymentActions,
  UnilateralPaymentProps,
  UnilateralPaymentState,
} from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { CONSTS } from 'src/utils/consts';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';

import { VendorFlowLayout, VendorFlowContentCard, Footer } from '../../components/VendorFlowLayout';
import { Divider } from '../components/Divider';
import locations from '../locations';

type Props = {
  state: UnilateralPaymentState;
  actions: UnilateralPaymentActions;
} & UnilateralPaymentProps;

export const AddVirtualCardDeliveryMethodPage = ({ token, state, actions }: Props) => {
  const { isDesktop } = useBreak();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);

  const { payment, organization } = state;

  const confirmClickHandler = async () => {
    setIsProcessing(true);
    await actions.onSubmit(
      {
        ...(payment?.deliveryMethod || {}),
        deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL_CARD,
      },
      false
    );
    history.push(generatePath(locations.addVirtualCardMethodSuccess, { token }));
  };

  const changeMethodClickHandler = () => history.push(generatePath(locations.addMethod, { token }));

  const payorName = organization?.companyName || '';
  const payorEmail = organization?.userOrganizations?.[0]?.user?.email || '';

  useEffect(() => {
    vendorUnilateralAnalytics.trackVisitVirtualCardOptInPageLoaded({
      paymentId: payment?.id,
      vendorEmail: payment?.vendor?.contactEmail,
      vendorId: payment?.vendorId,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
    });
  }, []);

  const trackGoBackAnalytics = () => {
    vendorUnilateralAnalytics.trackGoBack({
      paymentId: payment?.id,
      vendorId: payment?.vendorId,
      vendorEmail: payment?.vendor?.contactEmail,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
    });
  };

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack trackAnalyticsOnGoBack={trackGoBackAnalytics}>
        <VStack gap="ds.lg">
          <Text textStyle="ds.h5" fontWeight="ds.semi" align="center" m="0">
            <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.title" />
          </Text>
          <Text textStyle={isDesktop ? 'ds.body2' : 'ds.body1'} align="center" m="0">
            <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.text" />
          </Text>
        </VStack>
        <Divider h="ds.xs" my="ds.xl" />
        <VStack gap="ds.xl" align="start" width="100%">
          <Text textStyle="ds.body2" fontWeight="ds.medium" textTransform="uppercase" color="ds.gray.200" m="0">
            <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.paymentDetails" />
          </Text>
          <VStack gap="ds.md" align="start">
            <Text textStyle="ds.body2" color="ds.gray.100" m="0">
              <MIFormattedText
                label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.amount"
                values={{
                  amount: <MIFormattedCurrency value={payment?.amount || 0} />,
                }}
              />
            </Text>
            <Box>
              <Text textStyle="ds.body2" color="ds.gray.100" m="0">
                <MIFormattedText
                  label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.deliverTo"
                  values={{
                    vendorEmail: payment?.vendor?.contactEmail || '',
                  }}
                />
              </Text>
              <Text textStyle="ds.body2" color="ds.gray.200" m="0">
                <MIFormattedText
                  label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.changeEmail"
                  values={{
                    link: (
                      <Link href={`mailto:${payorEmail}`}>
                        <MIFormattedText
                          label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.contactTo"
                          values={{
                            payorName,
                          }}
                        />
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Box>
            <Text textStyle="ds.body2" color="ds.gray.100" m="0">
              <MIFormattedText
                label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.paymentETA"
                values={{
                  date: <MIFormattedDate date={payment?.deliveryEta} />,
                }}
              />
            </Text>
          </VStack>
        </VStack>
        <Flex
          direction="column"
          justify="center"
          align="center"
          p="ds.xl"
          borderRadius="ds.md"
          bg="ds.gray.800"
          mt="ds.xl"
        >
          <Flex
            direction={isDesktop ? 'row' : 'column-reverse'}
            align={isDesktop ? 'center' : 'start'}
            justify={isDesktop ? 'start' : 'center'}
            width="100%"
            gap="ds.sm"
          >
            <Text textStyle="ds.body1Semi" color="ds.gray.100" m="0">
              <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.whyPOS" />
            </Text>
            <Text
              textTransform="uppercase"
              textStyle="ds.body3Semi"
              color="ds.gray.100"
              bg="ds.yellow.200"
              px="ds.sm"
              py="ds.xs"
              borderRadius="ds.md"
              width="fit-content"
              m="0"
            >
              <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.posNote" />
            </Text>
          </Flex>
          <Text textStyle="ds.body2" color="ds.gray.100" mt="ds.md" mb="0">
            <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.descriptionPOS" />
          </Text>
          <Image
            src={POSTerminal}
            my="ds.xl"
            w="100%"
            objectFit="cover"
            height={isDesktop ? 'auto' : '20rem'}
            borderRadius="ds.md"
          />
          <VStack gap="ds.md">
            {isDesktop && (
              <>
                <MIButton
                  testId="unilateral-confirm-and-receive-button"
                  label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.confirm"
                  variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                  onClick={confirmClickHandler}
                  isProcessing={isProcessing}
                  fullWidth
                />
                <MIButton
                  testId="unilateral-change-delivery-method-button"
                  label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.changeMethod"
                  variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                  onClick={changeMethodClickHandler}
                  disabled={isProcessing}
                  fullWidth
                />
              </>
            )}
            <Text textStyle="ds.body3" align="center" m="0">
              <MIFormattedText
                label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.agreement"
                values={{
                  payorName,
                }}
              />
            </Text>
          </VStack>
        </Flex>
      </VendorFlowContentCard>
      <Footer />
      {!isDesktop && (
        <VStack
          gap="ds.md"
          direction="column"
          bg="ds.white"
          p="ds.xl"
          boxSizing="border-box"
          width="100%"
          position="sticky"
          bottom="0"
        >
          <MIButton
            label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.confirm"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={confirmClickHandler}
            fullWidth
            isProcessing={isProcessing}
          />
          <MIButton
            label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.changeMethod"
            variant={CONSTS.BUTTON_VARIANT.SECONDARY}
            onClick={changeMethodClickHandler}
            disabled={isProcessing}
            fullWidth
          />
          <Text textStyle="ds.body3" align="center" m="0">
            <MIFormattedText
              label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.agreement"
              values={{
                payorName,
              }}
            />
          </Text>
        </VStack>
      )}
    </VendorFlowLayout>
  );
};
