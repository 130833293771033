import { useCallback } from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { dashboardStore } from '../redux-store/dashboard-store';
import { useIsBillsSyncing } from '../sync/hooks/useIsBillsSyncing';
import { DashboardListParams } from '../types';
import { trackBillListLoaded } from '../utils';

type Params = {
  silent?: boolean; // passing `true` here will prevent `loading` param of qbDashboardListItemsStore from setting to `true` during the API call
};

export const useLoadDashboardList = ({ silent }: Params = { silent: false }) => {
  const dashboardListActions = useStoreActions(dashboardStore);
  const isSyncing = useIsBillsSyncing();

  const loadDashboardList = useCallback(
    async (listParams: DashboardListParams) => {
      dashboardListActions.dashboardSelection.setSelection([]);

      const listResult = await dashboardListActions.list({
        ...listParams,
        silent,
      });

      trackBillListLoaded(listResult, isSyncing);
    },
    [dashboardListActions, silent, isSyncing]
  );

  return { loadDashboardList };
};
