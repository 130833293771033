import get from 'lodash/get';
import { ModelView } from 'src/ui/form';
import { GoogleCombinedAddressType, AddressType, LegalAddressType, AddressTypeFromApi } from './types';
import { CONSTS } from './consts';

const STREET_NUMBER_COMPONENT_TYPE_NAME = 'street_number';
const STREET_COMPONENT_TYPE_NAME = 'route';
const CITY_COMPONENT_TYPE_NAME = 'locality';
const CITY_FALLBACK_COMPONENT_TYPE_NAME = 'sublocality';
const STATE_COMPONENT_TYPE_NAME = 'administrative_area_level_1';
const ZIP_CODE_COMPONENT_TYPE_NAME = 'postal_code';
const COUNTRY_CODE_COMPONENT_TYPE_NAME = 'country';

export function getGoogleAddress(googleAddress: GoogleCombinedAddressType) {
  const streetNumberComponent = googleAddress.addressComponents.find((c) =>
    c.types.includes(STREET_NUMBER_COMPONENT_TYPE_NAME)
  );
  const streetComponent = googleAddress.addressComponents.find((c) => c.types.includes(STREET_COMPONENT_TYPE_NAME));
  const cityComponent =
    googleAddress.addressComponents.find((c) => c.types.includes(CITY_COMPONENT_TYPE_NAME)) ||
    googleAddress.addressComponents.find((c) => c.types.includes(CITY_FALLBACK_COMPONENT_TYPE_NAME));
  const stateComponent = googleAddress.addressComponents.find((c) => c.types.includes(STATE_COMPONENT_TYPE_NAME));
  const zipComponent = googleAddress.addressComponents.find((c) => c.types.includes(ZIP_CODE_COMPONENT_TYPE_NAME));
  const countryCodeComponent = googleAddress.addressComponents.find((c) =>
    c.types.includes(COUNTRY_CODE_COMPONENT_TYPE_NAME)
  );
  const streetNumber = streetNumberComponent ? get(streetNumberComponent, 'long_name', '') : '';
  const streetName = streetComponent ? get(streetComponent, 'long_name', '') : '';
  const formattedAddress = get(googleAddress, 'formattedAddress', '');
  let addressLine1 = formattedAddress ? formattedAddress.split(',')[0] : '';
  const cityFromString = formattedAddress ? formattedAddress.split(', ')[1] : '';

  if (streetNumberComponent && streetComponent) {
    addressLine1 = `${streetNumber} ${streetName}`;
  }

  return {
    streetNumberComponent,
    streetComponent,
    cityComponent,
    stateComponent,
    zipComponent,
    countryCodeComponent,
    addressLine1,
    cityFromString,
  };
}

function convertToServerAddress(googleAddress: GoogleCombinedAddressType): AddressType {
  const address = getGoogleAddress(googleAddress);
  return {
    addressLine1: address.addressLine1,
    addressLine2: null,
    city: address.cityComponent ? address.cityComponent.long_name : address.cityFromString,
    state: address.stateComponent ? address.stateComponent.short_name : null,
    zipCode: address.zipComponent ? address.zipComponent.long_name : '',
    countryCode: address.countryCodeComponent ? address.countryCodeComponent.short_name : null,
    googlePlaceId: googleAddress.placeId || CONSTS.ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID,
    addressLat: googleAddress.geometry.lat,
    addressLng: googleAddress.geometry.lng,
    formattedAddress: googleAddress.formattedAddress,
    aptNumber: googleAddress.aptNumber,
  };
}

function convertToServerLegalAddress(googleAddress: GoogleCombinedAddressType): LegalAddressType {
  const address = getGoogleAddress(googleAddress);

  return {
    legalAddressLine1: address.addressLine1,
    legalAddressLine2: null,
    legalCity: address.cityComponent ? address.cityComponent.long_name : address.cityFromString,
    legalState: address.stateComponent ? address.stateComponent.short_name : null,
    legalZipCode: address.zipComponent ? address.zipComponent.long_name : null,
    legalCountryCode: address.countryCodeComponent ? address.countryCodeComponent.short_name : null,
    legalGooglePlaceId: googleAddress.placeId,
    legalAddressLat: googleAddress.geometry.lat,
    legalAddressLng: googleAddress.geometry.lng,
    legalAptNumber: googleAddress.aptNumber,
    legalFormattedAddress: googleAddress.formattedAddress,
  };
}

function convertToGoogleAddress(serverAddress: AddressType): GoogleCombinedAddressType {
  return {
    formattedAddress: serverAddress.formattedAddress || serverAddress.addressLine1,
    geometry: {
      lat: serverAddress.addressLat,
      lng: serverAddress.addressLng,
    },
    aptNumber: serverAddress.aptNumber,
    placeId: serverAddress.googlePlaceId,
    name: serverAddress.addressLine1,
    addressComponents: [
      { long_name: serverAddress.city, types: [CITY_COMPONENT_TYPE_NAME] },
      { short_name: serverAddress.state, types: [STATE_COMPONENT_TYPE_NAME] },
      {
        short_name: serverAddress.countryCode,
        types: [COUNTRY_CODE_COMPONENT_TYPE_NAME],
      },
      {
        long_name: serverAddress.zipCode,
        types: [ZIP_CODE_COMPONENT_TYPE_NAME],
      },
    ],
  };
}

function convertLegalAddressToGoogleAddress(serverAddress: LegalAddressType): GoogleCombinedAddressType {
  const placeId = !serverAddress.legalGooglePlaceId ? 'synced-from-qbo' : serverAddress.legalGooglePlaceId;
  return {
    formattedAddress: serverAddress.legalFormattedAddress || serverAddress.legalAddressLine1,
    geometry: {
      lat: serverAddress.legalAddressLat,
      lng: serverAddress.legalAddressLng,
    },
    aptNumber: serverAddress.legalAptNumber,
    placeId,
    name: serverAddress.legalAddressLine1,
    addressComponents: [
      { long_name: serverAddress.legalCity, types: [CITY_COMPONENT_TYPE_NAME] },
      {
        short_name: serverAddress.legalState,
        types: [STATE_COMPONENT_TYPE_NAME],
      },
      {
        short_name: serverAddress.legalCountryCode,
        types: [COUNTRY_CODE_COMPONENT_TYPE_NAME],
      },
      {
        long_name: serverAddress.legalZipCode,
        types: [ZIP_CODE_COMPONENT_TYPE_NAME],
      },
    ],
  };
}
function convertCompanyAddressToGoogleAddress(serverAddress: AddressType): GoogleCombinedAddressType {
  const placeId = !serverAddress.googlePlaceId ? 'synced-from-qbo' : serverAddress.googlePlaceId;

  return {
    formattedAddress: serverAddress.formattedAddress || serverAddress.addressLine1,
    geometry: {
      lat: serverAddress.addressLat,
      lng: serverAddress.addressLng,
    },
    aptNumber: serverAddress.aptNumber,
    placeId,
    name: serverAddress.addressLine1,
    addressComponents: [
      { long_name: serverAddress.city, types: [CITY_COMPONENT_TYPE_NAME] },
      {
        short_name: serverAddress.state,
        types: [STATE_COMPONENT_TYPE_NAME],
      },
      {
        short_name: serverAddress.countryCode,
        types: [COUNTRY_CODE_COMPONENT_TYPE_NAME],
      },
      {
        long_name: serverAddress.zipCode,
        types: [ZIP_CODE_COMPONENT_TYPE_NAME],
      },
    ],
  };
}

function convertToDisplayAddress(address: AddressType) {
  const fields = ['addressLine1', 'addressLine2', 'city', 'state', 'zipCode', 'countryCode'];
  const values = fields.map((field) => address[field]).filter((value) => value);

  return values.join(', ');
}

function convertToDisplayAddressForm(address: AddressTypeFromApi) {
  return {
    addressLine1: address.line1,
    addressLine2: address.line2,
    city: address.city,
    state: address.countryCode,
    zipCode: address.postalCode,
  };
}

const whitePagesAddressKeys = {
  street_line_1: 'addressLine1',
  street_line_2: 'addressLine2',
  city: 'city',
  state_code: 'state',
  postal_code: 'zipCode',
  country_code: 'countryCode',
};

function convertPaperCheck<T>(model: ModelView<T>): T {
  return Object.keys(model).reduce((field, key) => {
    field[key] = model[key].value;
    return field;
  }, {} as T);
}

function convertWhitePageAddress(model: ModelView<AddressType>) {
  const originalAddress = Object.keys(model).reduce((address, key) => {
    address[key] = model[key].value;
    return address;
  }, {});

  return originalAddress;
}

function getFullAddress(address: AddressType) {
  let formattedAddress = '';
  if (address instanceof Object) {
    const refinedVal = address;
    formattedAddress = Object.values(whitePagesAddressKeys)
      .filter((key) => refinedVal[key])
      .map((key) => refinedVal[key])
      .join(', ');
  }

  return {
    ...address,
    formattedAddress,
    geometry: CONSTS.DEFAULT_LOCATION,
    googlePlaceId: CONSTS.ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID,
    aptNumber: undefined,
  };
}

const isPOBox = (addressLine1?: string | null): boolean => {
  if (!addressLine1) {
    return false;
  }

  const poBoxesString = [
    'po box',
    'p.o box',
    'p.o.box',
    'p.o. box',
    'p o box',
    ' po ',
    'p.o',
    'p o b',
    'p o b.',
    ' pob ',
    'pobox',
    'post office box',
    'postal service box',
    'post office bin',
    'p.o. bin',
  ];

  return poBoxesString.some((poBoxString) => addressLine1.toLocaleLowerCase().includes(poBoxString));
};

export {
  whitePagesAddressKeys,
  convertToServerAddress,
  convertToGoogleAddress,
  convertToServerLegalAddress,
  convertLegalAddressToGoogleAddress,
  convertCompanyAddressToGoogleAddress,
  convertToDisplayAddress,
  convertToDisplayAddressForm,
  convertWhitePageAddress,
  getFullAddress,
  convertPaperCheck,
  isPOBox,
};
