import { RecordOf } from 'immutable';
import React from 'react';
import classNames from 'classnames';
import maxBy from 'lodash/maxBy';
import {
  BILL_STATUS,
  DELIVERY_TYPE,
  FULL_STORY_MASK_RULE_CLASS,
  PAYMENT_DELIVER_STATUS,
  PAYMENT_STATUS,
} from 'src/utils/consts';
import { BillType, DeliveryMethodType, PaymentType, VendorType } from 'src/utils/types';

import { getFormattedCheckSerial } from 'src/utils/bills';
import { getDeliveryMethodInfo } from 'src/pages/vendor/records';
import { isSharedVendor } from 'src/pages/vendor/utils';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import { MIFormattedText } from 'src/utils/formatting';
import { getBillPaidColor } from '../../utils/getBillPaidColor';
import { DeliveryMethodIcon } from './DeliveryMethodIcon';
import { DeliveryMethodInfoHint } from './DeliveryMethodInfoHint';

type Props = {
  bill: BillType;
  payment: PaymentType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

export const ViewPaymentDeliveryMethodInfo = ({ deliveryMethod, bill, payment }: Props) => {
  const { deliveryType } = deliveryMethod;
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isRPPSDeliveryMethod = deliveryType === DELIVERY_TYPE.RPPS;
  const isCheckDeliveryMethod = deliveryType === DELIVERY_TYPE.CHECK;

  const { label, labelValues } = getLabel({ deliveryMethod, bill, payment });

  const deliveryMethodInfo = getDeliveryMethodInfo({
    deliveryMethod,
    isRPPSDeliveryMethod,
    vendorName: bill?.vendor?.companyName || '',
    isSharedVendor: isSharedVendor(bill?.vendor as VendorType),
  });

  const paymentInfoTextClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: isCheckDeliveryMethod,
  });

  return (
    <PaymentFieldContainer>
      <DeliveryMethodIcon isBillPaid={isBillPaid} deliveryMethod={deliveryMethod} />
      <PaymentFieldInfo>
        <PaymentFieldLabel data-testid="view-payment-delivery-method-label">
          <MIFormattedText label={label} values={labelValues || {}} />
        </PaymentFieldLabel>
        <PaymentFieldContent
          color={getBillPaidColor(isBillPaid)}
          className={paymentInfoTextClassName}
          data-testid="view-payment-delivery-method-description"
        >
          {deliveryMethodInfo}
          <DeliveryMethodInfoHint payment={payment} deliveryMethod={deliveryMethod} />
        </PaymentFieldContent>
      </PaymentFieldInfo>
    </PaymentFieldContainer>
  );
};

const getLabel = ({ deliveryMethod, bill, payment }: Props): { label: string; labelValues?: Record<string, any> } => {
  const { deliveryType } = deliveryMethod;
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isACHDeliveryMethod = deliveryType === DELIVERY_TYPE.ACH;
  const isRPPSDeliveryMethod = deliveryType === DELIVERY_TYPE.RPPS;
  const isCheckDeliveryMethod = deliveryType === DELIVERY_TYPE.CHECK;
  const isDebitCardDeliveryMethod = deliveryType === DELIVERY_TYPE.CARD;
  if (isCheckDeliveryMethod && isBillPaid) {
    const formattedCheckSerial = getFormattedCheckSerial(payment);
    return {
      label: 'viewBillPaymentActivity.deliveryMethod.paidBillPaperCheckLabel',
      labelValues: { formattedCheckSerial },
    };
  }

  if (isACHDeliveryMethod && isBillPaid) {
    return { label: 'viewBillPaymentActivity.deliveryMethod.paidBillAchLabel' };
  }

  if (isCheckDeliveryMethod) {
    return { label: 'viewBillPaymentActivity.deliveryMethod.paperCheckLabel' };
  }

  if (isRPPSDeliveryMethod) {
    return { label: 'viewBillPaymentActivity.deliveryMethod.rppsLabel' };
  }

  if (isDebitCardDeliveryMethod) {
    return { label: 'viewBillPaymentActivity.deliveryMethod.debitCardLabel' };
  }

  const isVirtualCardDeliveryMethod = deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;
  const virtualCardInfo = maxBy(payment.virtualCards, 'createdAt');
  const isPaymentFailed = payment.status === PAYMENT_STATUS.FAILED;
  const isPaymentIsCompletedAndCleared =
    payment.status === PAYMENT_STATUS.COMPLETED && payment.deliverStatus === PAYMENT_DELIVER_STATUS.CLEARED;
  const shouldShowLabelWithVirtualCardInfo =
    isVirtualCardDeliveryMethod && virtualCardInfo && (isPaymentFailed || isPaymentIsCompletedAndCleared);

  if (shouldShowLabelWithVirtualCardInfo) {
    return {
      label: 'viewBillPaymentActivity.deliveryMethod.virtualCardLabelWithDetails',
      labelValues: {
        card4digits: virtualCardInfo.accountNumber4digits,
      },
    };
  }

  if (isVirtualCardDeliveryMethod) {
    return { label: 'viewBillPaymentActivity.deliveryMethod.virtualCardLabel' };
  }

  return { label: 'viewBillPaymentActivity.deliveryMethod.achLabel' };
};
