import { put, takeEvery, all, call, select, getContext } from 'redux-saga/effects';
import { TakeableChannel } from 'redux-saga';
import { RecordOf } from 'immutable';
import { getDefaultMemo } from 'src/utils/bills';
import { getDeliveryMethodForPayment } from 'src/redux/utils';
import {
  getPayBillFlowUUID,
  startTrackBillPayFlow,
  stopTrackingBillPayFlow,
} from 'src/billpay/qbdt/pages/pay/utils/trackPayBillFlow';
import { logger } from 'src/services/loggers';
import billsApi from '../../services/api/bills';
import paymentApi from '../../services/api/payments';
import { BillRecord } from '../../pages/bill/records';
import {
  BEGIN_REGULAR_PAY_BILL_FLOW,
  SELECT_NEW_DELIVERY_METHOD,
  CREATE_PAYMENT,
  UPDATE_PAYMENT,
  FETCH_BILL,
  RETRY_FAILED_PAYMENT,
  SELECT_FUNDING_SOURCE,
  END_PAY_BILL_FLOW,
} from './actionTypes';
import {
  BeginRegularPayBillFlowType,
  SelectNewDeliveryMethodType,
  CreatePaymentType,
  UpdatePaymentType,
  RetryFailedPaymentType,
} from './types';
import { AccountType, BillType, PaymentType } from '../../utils/types';
import {
  beginRegularPayBillFlowSuccessAction,
  beginRegularPayBillFlowFailedAction,
  addNewDeliveryMethodAction,
  selectDeliveryMethodAction,
  createPaymentSuccessAction,
  createPaymentErrorAction,
  updatePaymentSuccessAction,
  updatePaymentErrorAction,
  retryFailedPaymentSuccessAction,
  retryFailedPaymentErrorAction,
  fetchBillSuccessAction,
  fetchBillFailedAction,
} from './actions';
import { getBill, getPayment } from './selectors';
import { getOrgId, getFundingSources } from '../user/selectors';
import { PaymentRecord } from '../../pages/payment/records';
import { CONSTS, DELIVERY_TYPE } from '../../utils/consts';
import { isBankAccountBlocked } from '../../utils/funding-sources';

function* getDefaultFundingSource() {
  const orgId = yield select(getOrgId);
  const fundingSources = yield select(getFundingSources);

  const filters = {
    start: 0,
    limit: 1,
    sorting: 'createdAt',
  };
  const { objects: payments } = yield call(paymentApi.getPayments as any, {
    orgId,
    filters,
  });
  const lastCreatedPayment = payments[0];

  const fundingSourceId = lastCreatedPayment?.fundingSourceId;
  let lastCreatedPaymentFundingSource = fundingSourceId ? fundingSources.find((fs) => fs.id === fundingSourceId) : null;

  if (isBankAccountBlocked(lastCreatedPaymentFundingSource)) {
    lastCreatedPaymentFundingSource = null;
  }

  return lastCreatedPaymentFundingSource;
}

function buildPaymentRecordFromExistingPayment(payment, payBillFlowUUID) {
  return PaymentRecord({
    ...payment,
    originDeliveryPreference: payment.deliveryPreference,
    payBillFlowUUID,
  });
}

function* buildPaymentRecord(billRecord: RecordOf<BillType>, paymentId?: string) {
  const payBillFlowUUID = getPayBillFlowUUID();
  if (paymentId) {
    const paymentFromBill = billRecord.payments.find((p) => ((p.id as unknown) as number) === +paymentId);
    if (paymentFromBill) {
      return buildPaymentRecordFromExistingPayment(paymentFromBill, payBillFlowUUID);
    }
  }

  const site = yield getContext('site');
  const fundingSource = yield call(getDefaultFundingSource);
  const fundingSourceId = fundingSource?.id || null;

  const deliveryMethods = billRecord?.vendor?.deliveryMethods || [];
  const deliveryMethod = getDeliveryMethodForPayment({
    deliveryMethods,
    fundingSource,
  });
  const deliveryMethodId = deliveryMethod?.id || null;

  const isDirectPayment = deliveryMethod?.deliveryType === DELIVERY_TYPE.RPPS;
  const note = isDirectPayment ? null : getDefaultMemo(billRecord.invoiceNumber);

  return PaymentRecord(
    Object.assign(
      {},
      {
        billId: billRecord.id,
        vendorId: billRecord.vendorId.toString(),
        amount: billRecord.totalAmount,
        currency: billRecord.currency,
        createOrigin: site.createOrigin.pay.payment,
        note,
        payBillFlowUUID,
      },
      fundingSourceId ? { fundingSourceId } : {},
      deliveryMethod ? { deliveryMethod } : {},
      deliveryMethodId ? { deliveryMethodId } : {}
    )
  );
}

function* beginRegularPayBillFlow({ id, paymentId }: BeginRegularPayBillFlowType) {
  const orgId = yield select(getOrgId);
  let bill;
  let billRecord;
  let paymentRecord;

  startTrackBillPayFlow();
  try {
    if (paymentId) {
      const { object: payment } = yield call(paymentApi.getPaymentById, orgId, paymentId);
      bill = payment?.bills?.find((bill) => bill.id === +id);
      billRecord = BillRecord({ ...bill, vendor: payment.vendor });
      const payBillFlowUUID = getPayBillFlowUUID();
      paymentRecord = buildPaymentRecordFromExistingPayment(payment, payBillFlowUUID);
    } else {
      ({ object: bill } = yield call(billsApi.getBillById, {
        orgId,
        id,
      }));

      billRecord = BillRecord(bill);
      paymentRecord = yield call(buildPaymentRecord, billRecord, paymentId);
    }

    yield put(beginRegularPayBillFlowSuccessAction(billRecord, paymentRecord));
  } catch (e) {
    logger.error('payBillWizardSaga.beginRegularPayBillFlow(): failed', e);
    yield put(beginRegularPayBillFlowFailedAction());
  }
}

function* selectNewDeliveryMethod({ deliveryMethod }: SelectNewDeliveryMethodType) {
  yield put(addNewDeliveryMethodAction(deliveryMethod));
  yield put(selectDeliveryMethodAction(deliveryMethod));
}

function* createPayment({ resolve, reject }: CreatePaymentType) {
  const [payment, orgId] = yield all([select(getPayment), select(getOrgId)]);

  try {
    const paymentObj = yield call(paymentApi.createPayment, orgId, payment);

    const paymentRecord = PaymentRecord(paymentObj.object);
    yield put(createPaymentSuccessAction(paymentRecord));
    resolve(paymentRecord);
  } catch (e: any) {
    yield put(createPaymentErrorAction(e.code));
    reject(e);
  }
}

function* retryFailedPayment({ resolve, reject }: RetryFailedPaymentType) {
  const [payment, orgId] = yield all([select(getPayment), select(getOrgId)]);

  try {
    let paymentObj;
    if (payment?.metadata?.failedType === CONSTS.FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER) {
      paymentObj = yield call(paymentApi.retryFailedToDeliver, orgId, payment);
    } else {
      paymentObj = yield call(paymentApi.retryFailedToCollect, orgId, payment);
    }

    yield put(retryFailedPaymentSuccessAction(PaymentRecord(paymentObj.payment)));
    resolve();
  } catch (e: any) {
    yield put(retryFailedPaymentErrorAction(e.code));
    reject(e);
  }
}

function* updatePayment({ resolve, reject }: UpdatePaymentType) {
  const [payment, orgId] = yield all([select(getPayment), select(getOrgId)]);

  try {
    const paymentObj = yield call(paymentApi.editPaymentById, orgId, payment);

    yield put(updatePaymentSuccessAction(PaymentRecord(paymentObj.object)));
    resolve();
  } catch (e: any) {
    yield put(updatePaymentErrorAction(e.code));
    reject(e);
  }
}

function* fetchBill({ id }) {
  const orgId = yield select(getOrgId);

  try {
    const { object: bill } = yield call(billsApi.getBillById, {
      orgId,
      id,
    });
    const billRecord = BillRecord(bill);
    yield put(fetchBillSuccessAction(billRecord));
  } catch (e) {
    yield put(fetchBillFailedAction());
  }
}

function* selectFundingSource({ id }) {
  const [fundingSources, payment, bill]: [RecordOf<AccountType>[], PaymentType, BillType] = yield all([
    select(getFundingSources),
    select(getPayment),
    select(getBill),
  ]);

  const fundingSource = fundingSources.find((fs) => fs.id === id);
  const deliveryMethods = bill?.vendor?.deliveryMethods || [];
  const deliveryMethod = getDeliveryMethodForPayment({
    deliveryMethods,
    fundingSource,
    currentDeliveryMethodId: payment.deliveryMethodId,
  });

  if (deliveryMethod?.id !== payment.deliveryMethodId) {
    yield put(selectDeliveryMethodAction(deliveryMethod));
  }
}

function* endPayBillFlow() {
  yield call(stopTrackingBillPayFlow);
}

function* watchBeginRegularPayBillFlow() {
  yield takeEvery(BEGIN_REGULAR_PAY_BILL_FLOW, beginRegularPayBillFlow);
}

function* watchSelectNewDeliveryMethod() {
  yield takeEvery(SELECT_NEW_DELIVERY_METHOD, selectNewDeliveryMethod);
}

function* watchCreatePayment() {
  yield takeEvery(CREATE_PAYMENT, createPayment);
}

function* watchUpdatePayment() {
  yield takeEvery(UPDATE_PAYMENT, updatePayment);
}

function* watchRetryFailedPayment() {
  yield takeEvery(RETRY_FAILED_PAYMENT, retryFailedPayment);
}

function* watchFetchBill() {
  yield takeEvery((FETCH_BILL as unknown) as TakeableChannel<unknown>, fetchBill);
}

function* watchSelectFundingSource() {
  yield takeEvery((SELECT_FUNDING_SOURCE as unknown) as TakeableChannel<unknown>, selectFundingSource);
}

function* watchEndPayBillFlow() {
  yield takeEvery(END_PAY_BILL_FLOW, endPayBillFlow);
}

export default function* payBillFlowSagas() {
  yield all([
    watchBeginRegularPayBillFlow(),
    watchSelectNewDeliveryMethod(),
    watchCreatePayment(),
    watchUpdatePayment(),
    watchFetchBill(),
    watchRetryFailedPayment(),
    watchSelectFundingSource(),
    watchEndPayBillFlow(),
  ]);
}
