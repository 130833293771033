import React from 'react';
import { Flex, Icon } from '@melio/billpay-design-system';
import { generatePath, useHistory } from 'react-router-dom';
import { ReactComponent as BankAccountIcon } from 'src/images/general/bank-account-icon.svg';
import { ReactComponent as RightArrowIcon } from 'src/images/general/right-arrow.svg';
import { useBreak } from 'src/hoc';
import locations from 'src/pages/vendor/virtual-delivery-method/locations';
import { PaymentType } from 'src/utils/types';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { DeliveryMethodCardWrapper } from './DeliveryMethodCardWrapper';
import { DeliveryMethodCardContentWrapper } from './DeliveryMethodCardContentWrapper';
import { DeliveryMethodCardTitle } from './DeliveryMethodCardTitle';
import { DeliveryMethodSubTitle } from './DeliveryMethodCardSubTitle';

type Props = {
  token: string;
  payment?: PaymentType;
};

export const BankAccountDeliveryMethodCard = ({ token, payment }: Props) => {
  const { isDesktop } = useBreak();
  const history = useHistory();

  const cardClickHandler = () => {
    vendorUnilateralAnalytics.trackSelectDeliveryMethod({
      paymentId: payment?.id,
      vendorEmail: payment?.vendor?.contactEmail,
      vendorId: payment?.vendorId,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
      option: DELIVERY_TYPE.ACH,
    });

    history.push(generatePath(locations.addBankAccountMethod, { token }));
  };

  return (
    <DeliveryMethodCardWrapper onClick={cardClickHandler} testId="unilateral-delivery-method-bank-account">
      <Icon as={BankAccountIcon} w="ds.2xl" h="ds.2xl" />
      <DeliveryMethodCardContentWrapper>
        <DeliveryMethodCardTitle label="vendor.unilateral.deliveryMethods.ACH.title" />
        <DeliveryMethodSubTitle label="vendor.unilateral.deliveryMethods.ACH.text" />
      </DeliveryMethodCardContentWrapper>
      {isDesktop && (
        <Flex align="center" justify="center">
          <Icon as={RightArrowIcon} w="ds.xl" height="ds.xl" />
        </Flex>
      )}
    </DeliveryMethodCardWrapper>
  );
};
