import { useIntl } from 'react-intl';
import { isBulkPayment } from '../utils';

export const useInvoiceNumber = (payment) => {
  const intl = useIntl();
  const invoiceNumber = isBulkPayment(payment)
    ? intl.formatMessage({
        id: 'vendors.addDeliveryMethodByLink.bulkPaymentInvoiceNumber',
      })
    : payment?.bill?.invoiceNumber;

  return { invoiceNumber };
};
