import { useState, useCallback } from 'react';

type ReturnType<P extends any[], R> = [(...params: P) => Promise<R>, R | null, boolean, any, () => void];

export function useApi<P extends any[], R>(
  api: (...params: P) => Promise<R>,
  throwError = true,
  initialLoading = false
): ReturnType<P, R> {
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState(null);
  const [result, setResult] = useState<R | null>(null);

  const resetState = useCallback(() => {
    setLoading(false);
    setError(null);
    setResult(null);
  }, []);

  const onApiCall = useCallback(
    (...params: P) => {
      setLoading(true);
      return api(...params)
        .then((value) => {
          setLoading(false);
          setResult(value);
          return value;
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
          if (throwError) {
            throw error;
          } else {
            return error;
          }
        });
    },
    [api]
  );
  return [onApiCall, result, loading, error, resetState];
}
