/* eslint-disable max-len */
// @flow
import * as React from 'react';

const SIZES_FULL = {
  regular: {
    width: '11.2rem',
    height: '4.2rem',
    viewBox: '0 0 92 42',
  },
  mobile: {
    width: '8.8rem',
    height: '3.5rem',
    viewBox: '0 0 92 42',
  },
  qb: {
    width: '7rem',
    height: '2.8rem',
    viewBox: '0 0 92 42',
  },
};

const SIZES_SHORT = {
  regular: {
    width: '3.4rem',
    height: '4rem',
    viewBox: '0 0 34 42',
  },
  mobile: {
    width: '3.4rem',
    height: '4rem',
    viewBox: '0 0 34 42',
  },
  qb: {
    width: '3.4rem',
    height: '4rem',
    viewBox: '0 0 34 42',
  },
};

const COLORS = {
  melio: 'rgba(114, 68, 246, 1)',
  dark: 'rgba(33, 33, 36, 1)',
  light: 'rgba(153, 153, 156, 1)',
  white: 'rgba(255,255,255,1)',
  qb: 'rgba(113, 113, 115)',
};

type Props = {
  isFull?: boolean,
  size?: 'regular' | 'mobile' | 'qb',
  fill?: 'melio' | 'dark' | 'light' | 'white' | 'qb',
};

const Logo = ({ size, fill, isFull }: Props) => {
  const passedProps = {
    size: size || 'regular',
    fill: fill || 'melio',
  };

  return isFull ? <FullLogo {...passedProps} /> : <ShortLogo {...passedProps} />;
};

Logo.defaultProps = {
  isFull: true,
  size: 'regular',
  fill: 'melio',
};

export default Logo;

type FullLogoProps = {
  size: 'regular' | 'mobile' | 'qb',
  fill: 'melio' | 'dark' | 'light' | 'white' | 'qb',
};

const FullLogo = ({ size, fill }: FullLogoProps) => {
  const style1 = {
    fill: COLORS[fill],
    transition: '150ms',
  };
  const svgProps = SIZES_FULL[size];

  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        transform="translate(81.243902, 31.195652)"
        style={style1}
        d="M2.10162602,3.44171739 C2.62894309,3.92563043 3.48487805,3.92563043 4.01219512,3.44171739 L5.76839024,1.834 C6.50739837,1.15682609 5.98313821,0.000304347826 4.93920325,0.000304347826 L1.17461789,0.000304347826 C0.130682927,0.000304347826 -0.393577236,1.15682609 0.345430894,1.834 L2.10162602,3.44171739 Z"
        id="Fill-1"
      />
      <path
        transform="translate(64.430894, 0.000000)"
        style={style1}
        d="M4.39430894,0.362630435 C3.86699187,-0.121282609 3.01105691,-0.121282609 2.48373984,0.362630435 L0.727544715,1.97034783 C-0.0114634146,2.64752174 0.512796748,3.80404348 1.55749593,3.80404348 L5.32131707,3.80404348 C6.36525203,3.80404348 6.8895122,2.64752174 6.15050407,1.97034783 L4.39430894,0.362630435 Z"
        id="Fill-4"
      />
      <path
        style={style1}
        d="M14.902439,28.1521739 C13.8470407,28.1521739 12.9918699,27.3007609 12.9918699,26.25 L12.9918699,15.2173913 C12.9918699,12.6996739 10.9353333,10.6521739 8.40650407,10.6521739 C5.8776748,10.6521739 3.82113821,12.6996739 3.82113821,15.2173913 L3.82113821,26.25 C3.82113821,27.3007609 2.96596748,28.1521739 1.91056911,28.1521739 C0.855170732,28.1521739 0,27.3007609 0,26.25 L0,15.2173913 C0,10.6027174 3.77146341,6.84782609 8.40650407,6.84782609 C13.0415447,6.84782609 16.8130081,10.6027174 16.8130081,15.2173913 L16.8130081,26.25 C16.8130081,27.3007609 15.9578374,28.1521739 14.902439,28.1521739"
        id="Fill-7"
      />
      <path
        style={style1}
        d="M27.8943089,28.1521739 C26.8389106,28.1521739 25.9837398,27.3007609 25.9837398,26.25 L25.9837398,15.2173913 C25.9837398,12.6996739 23.9272033,10.6521739 21.398374,10.6521739 C18.8695447,10.6521739 16.8130081,12.6996739 16.8130081,15.2173913 L16.8130081,26.25 C16.8130081,27.3007609 15.9578374,28.1521739 14.902439,28.1521739 C13.8470407,28.1521739 12.9918699,27.3007609 12.9918699,26.25 L12.9918699,15.2173913 C12.9918699,10.6027174 16.7633333,6.84782609 21.398374,6.84782609 C26.0334146,6.84782609 29.804878,10.6027174 29.804878,15.2173913 L29.804878,26.25 C29.804878,27.3007609 28.9497073,28.1521739 27.8943089,28.1521739"
        id="Fill-9"
      />
      <path
        style={style1}
        d="M37.6937789,15.2173913 L50.7697138,15.2173913 C49.9917301,12.4546739 47.4972911,10.6521739 44.1790146,10.6521739 C41.1901203,10.6521739 38.6398927,12.5604348 37.6937789,15.2173913 M44.1790146,28.1521739 C38.2791772,28.1521739 33.4798276,23.373913 33.4798276,17.5 C33.4798276,11.626087 38.2791772,6.84782609 44.1790146,6.84782609 C50.259974,6.84782609 54.8453398,11.263913 54.8453398,17.1195652 C54.8453398,18.1703261 53.9901691,19.0217391 52.9347707,19.0217391 L37.4721528,19.0217391 C38.1691285,22.0675 40.9111772,24.3478261 44.1790146,24.3478261 C46.0162179,24.3478261 47.7433724,23.6348913 49.0425593,22.3421739 C49.7892098,21.5995652 50.9982179,21.5995652 51.7441041,22.3421739 C52.4907545,23.0855435 52.4907545,24.2892391 51.7441041,25.0318478 C49.7242504,27.0443478 47.0364618,28.1521739 44.1790146,28.1521739"
        id="Fill-11"
      />
      <path
        style={style1}
        d="M60.2276423,28.1521739 C59.1722439,28.1521739 58.3170732,27.3007609 58.3170732,26.25 L58.3170732,1.90217391 C58.3170732,0.851413043 59.1722439,0 60.2276423,0 C61.2830407,0 62.1382114,0.851413043 62.1382114,1.90217391 L62.1382114,26.25 C62.1382114,27.3007609 61.2830407,28.1521739 60.2276423,28.1521739"
        id="Fill-13"
      />
      <path
        style={style1}
        d="M67.8699187,28.1521739 C66.8145203,28.1521739 65.9593496,27.3007609 65.9593496,26.25 L65.9593496,8.75 C65.9593496,7.69923913 66.8145203,6.84782609 67.8699187,6.84782609 C68.9253171,6.84782609 69.7804878,7.69923913 69.7804878,8.75 L69.7804878,26.25 C69.7804878,27.3007609 68.9253171,28.1521739 67.8699187,28.1521739"
        id="Fill-15"
      />
      <path
        style={style1}
        d="M83.9186992,10.6521739 C80.1258374,10.6521739 77.0406504,13.7238043 77.0406504,17.5 C77.0406504,21.2761957 80.1258374,24.3478261 83.9186992,24.3478261 C87.711561,24.3478261 90.796748,21.2761957 90.796748,17.5 C90.796748,13.7238043 87.711561,10.6521739 83.9186992,10.6521739 M83.9186992,28.1521739 C78.0188618,28.1521739 73.2195122,23.373913 73.2195122,17.5 C73.2195122,11.626087 78.0188618,6.84782609 83.9186992,6.84782609 C89.8185366,6.84782609 94.6178862,11.626087 94.6178862,17.5 C94.6178862,23.373913 89.8185366,28.1521739 83.9186992,28.1521739"
        id="Fill-17"
      />
    </svg>
  );
};

type ShortLogoProps = {
  size: 'regular' | 'mobile' | 'qb',
  fill: 'melio' | 'dark' | 'light' | 'white' | 'qb',
};

const ShortLogo = ({ size, fill }: ShortLogoProps) => {
  const style1 = {
    fill: COLORS[fill],
    transition: '150ms',
  };
  const svgProps = SIZES_SHORT[size];
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
        <path
          style={style1}
          d="M3.83870968,7.48387097 C2.71885223,7.48387097 1.81105991,8.40407879 1.81105991,9.53925207 L1.81105991,30.2026834 C1.81105991,31.3378567 2.71885223,32.2580645 3.83870968,32.2580645 C4.95856713,32.2580645 5.86635945,31.3378567 5.86635945,30.2026834 L5.86635945,9.53925207 C5.86635945,8.40407879 4.95856713,7.48387097 3.83870968,7.48387097"
        />
        <path
          style={style1}
          d="M21.8341014,28.1473023 C17.3519905,28.1473023 13.7055585,24.4345715 13.7055585,19.8709677 C13.7055585,15.307364 17.3519905,11.5946332 21.8341014,11.5946332 C26.3162122,11.5946332 29.9626443,15.307364 29.9626443,19.8709677 C29.9626443,24.4345715 26.3162122,28.1473023 21.8341014,28.1473023 M30.4366837,11.1119749 C28.1388361,8.77234827 25.0837421,7.48387097 21.8341014,7.48387097 C18.5844607,7.48387097 15.5293667,8.77234827 13.2315191,11.1119749 C10.9336715,13.4516015 9.66820276,16.5622426 9.66820276,19.8709677 C9.66820276,23.1796928 10.9336715,26.290334 13.2315191,28.6299606 C15.5293667,30.9695872 18.5844607,32.2580645 21.8341014,32.2580645 C25.0837421,32.2580645 28.1388361,30.9695872 30.4366837,28.6299606 C32.7345312,26.290334 34,23.1796928 34,19.8709677 C34,16.5622426 32.7345312,13.4516015 30.4366837,11.1119749"
        />
        <path
          style={style1}
          d="M4.86053967,0.491712779 C4.22620319,-0.16390426 3.19777125,-0.16390426 2.56343477,0.491712779 L0.452639231,2.67350404 C-0.435527767,3.59154417 0.193479498,5.16129032 1.44957551,5.16129032 L5.97439893,5.16129032 C7.23044166,5.16129032 7.85950221,3.59154417 6.97133521,2.67350404 L4.86053967,0.491712779 Z"
        />
        <path
          style={style1}
          d="M24.2232469,34.8387097 L19.6984234,34.8387097 C18.4423274,34.8387097 17.8133202,36.3809702 18.7014872,37.2830215 L20.9638989,39.5806086 C21.5144601,40.1397971 22.4071569,40.1397971 22.9577714,39.5806086 L25.2201831,37.2830215 C26.1083501,36.3809702 25.4792896,34.8387097 24.2232469,34.8387097"
        />
      </svg>
    </div>
  );
};

ShortLogo.defaultProps = {};
