import React, { useMemo } from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  label: string;
};
export const HeaderCell: React.FC<Props> = ({ label }) =>
  useMemo(
    () => (
      <Box isTruncated>
        <MIFormattedText label={label} />
      </Box>
    ),
    [label]
  );
