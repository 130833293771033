import sortBy from 'lodash/sortBy';
import { RecordOf } from 'immutable';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { AccountType } from './types';
import { CONSTS } from './consts';
import { Permissions } from './permissions';
import { getAccountNumber4digits } from './bank-account';

const isManualBankAccountNotVerified = (fundingSource?: RecordOf<AccountType> | null | AccountType) =>
  !!(
    fundingSource &&
    fundingSource.fundingType === CONSTS.FUNDING_TYPE.ACH &&
    fundingSource.origin === CONSTS.FUNDING_SOURCE_ORIGIN.MANUAL &&
    !fundingSource.isVerified
  );

const canVerifyManualBankAccount = (fundingSource?: RecordOf<AccountType> | null | AccountType) =>
  !!(
    fundingSource &&
    isManualBankAccountNotVerified(fundingSource) &&
    fundingSource.bankAccount &&
    fundingSource.bankAccount.canVerify &&
    !fundingSource.bankAccount.isBlocked
  );

const isBankAccountBlocked = (fundingSource?: RecordOf<AccountType> | null | AccountType) =>
  !!(fundingSource && fundingSource.bankAccount && fundingSource.bankAccount.isBlocked);

const isSandboxIndicator = (site: any) => site.config.services.tabapay.url.indexOf('sandbox') !== -1;

const sortFundingSourceSelectOptions = (
  list: {
    methods: any[];
    componentType: any;
  }[]
) => sortBy(list, (listItem) => (listItem.methods.length === 0 ? 1 : 0));

const getModifyFundingSourceOptions = (
  permissions: Permissions,
  modifyActions: Record<string, any>,
  disabled: boolean,
  origin?: string,
  isExpired?: boolean
) => {
  const actionOptions: ActionOption[] = [];
  if (modifyActions) {
    if (origin === CONSTS.FUNDING_SOURCE_ORIGIN.MANUAL && !isExpired) {
      actionOptions.push({
        label: 'settings.paymentMethods.view',
        action: () => modifyActions.view(),
      });
    }

    if (permissions.fundingSources.delete()) {
      actionOptions.push({
        label: 'settings.paymentMethods.delete',
        action: () => modifyActions.delete(),
        negative: true,
      });
    }
  }

  return actionOptions;
};

const getNameParts = (fundingSource: AccountType) => {
  if (fundingSource?.bankAccount) {
    return {
      displayName: fundingSource.displayName || '',
      institutionName: fundingSource?.plaidAccount?.plaidItem?.institutionName || undefined,
      identifier: getAccountNumber4digits(fundingSource.bankAccount),
    };
  } else if (fundingSource?.cardAccount) {
    return {
      displayName: '',
      institutionName: fundingSource.cardAccount.network,
      identifier: fundingSource.cardAccount.card4digits,
    };
  }

  return {
    displayName: '',
    institutionName: '',
    identifier: '',
  };
};

const getFundingSourceType = (fundingSource): string | undefined =>
  fundingSource?.fundingType === CONSTS.FUNDING_TYPE.CARD
    ? fundingSource?.cardAccount?.cardType
    : fundingSource?.fundingType;

const isUnverifiedAchLimitExceeded = (fundingSources: RecordOf<AccountType>[] | AccountType[]) =>
  fundingSources.filter((fs) => fs.fundingType === CONSTS.FUNDING_TYPE.ACH && !fs.isVerified).length >=
  CONSTS.FUNDING_SOURCE_LIMIT.ACH_UNVERIFIED;

const isDebitCard = (fundingSource: RecordOf<AccountType> | AccountType) => {
  const isCard = fundingSource?.fundingType === CONSTS.FUNDING_TYPE.CARD;
  const isDebitType = fundingSource?.cardAccount?.cardType === CONSTS.CARD_TYPES.DEBIT;

  return isCard && isDebitType;
};

export {
  isManualBankAccountNotVerified,
  isBankAccountBlocked,
  canVerifyManualBankAccount,
  isSandboxIndicator,
  sortFundingSourceSelectOptions,
  getModifyFundingSourceOptions,
  getNameParts,
  getFundingSourceType,
  isUnverifiedAchLimitExceeded,
  isDebitCard,
};
