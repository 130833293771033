import * as React from 'react';
import styled, { css } from 'styled-components';
import { History } from 'history';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import { zendeskService } from 'src/services/zendesk';
import { GlobalState } from 'src/redux/types';
import { getOrgId } from 'src/redux/user/selectors';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import qboLogo from 'src/images/qbo/logo-qbo-footer-with-jpm-no-svb.svg';
import { FAQDrawer } from 'src/billpay/qbdt/components/layout/FAQDrawer';
import analytics from 'src/services/analytics';
import { withNavigator, withBreak } from 'src/hoc';
import { setUrlToBackAction, setFaqPanel } from 'src/redux/payBillWizard/actions';
import locations from 'src/billpay/qbdt/pages/locations';
import { generatePath } from 'react-router-dom';

const commonStyles = css`
  display: flex;
  align-items: center;
`;

const QBDTLayoutContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  position: relative;
`;

type HeaderMapDispatchToProps = {
  setUrlToBack: (url: string) => void;
  setIsFaqOpen: (isOpen: boolean) => void;
};

type HeaderMapStateToProps = {
  orgId: string;
};

type HeaderProps = {
  goExit?: () => void;
  onPrev?: () => void | null;
  headerLabel?: string;
  headerLabelValues?: Record<string, string>;
  hideSettings?: boolean;
  history: History;
  device: Record<string, any>;
} & HeaderMapDispatchToProps &
  HeaderMapStateToProps;

type State = {
  isHeaderClosed: boolean;
};

const eventPage = 'qbo';

class QBDTHeaderContainerClass extends React.Component<HeaderProps, State> {
  static defaultProps = {
    onPrev: null,
    headerLabel: '',
    headerLabelValues: {},
    hideSettings: false,
  };

  constructor(props: HeaderProps) {
    super(props);

    this.state = {
      isHeaderClosed: false,
    };
  }

  onPrev = () => {
    const { onPrev } = this.props;
    analytics.track(`${eventPage}-pay-bill-flow`, 'view-prev-page');
    if (onPrev) {
      onPrev();
    }
  };

  onSettingsClick = () => {
    this.props.setUrlToBack(this.props.history.location.pathname + this.props.history.location.search);
    analytics.track(eventPage, 'settings');
  };

  onDrawerClose = () => {
    analytics.track(eventPage, 'need-help-close');
  };

  openDrawer = () => {
    const { setIsFaqOpen } = this.props;

    analytics.track(eventPage, 'need-help-open');
    setIsFaqOpen(true);
  };

  openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    zendeskService.show();
  };

  closeIframe = () => {
    const { goExit } = this.props;
    analytics.track(`${eventPage}-pay-bill-flow`, 'close-integration');
    this.setState({ isHeaderClosed: true });
    if (goExit) {
      goExit();
    }
  };

  render() {
    const { onPrev, goExit, headerLabel, orgId, headerLabelValues, hideSettings } = this.props;
    const { isHeaderClosed } = this.state;
    return (
      <React.Fragment>
        <QBDTHeader isBottomBorder={headerLabel}>
          <HeaderContent>
            {onPrev && (
              <Back onClick={this.onPrev} data-testid="back-button">
                <i className="icon-back-icon" />
              </Back>
            )}
            {headerLabel && !isHeaderClosed && (
              <HeaderText data-testid="qbo-header-text">
                <MIFormattedText label={headerLabel} values={headerLabelValues} />
              </HeaderText>
            )}
          </HeaderContent>
          <HeaderButtonContent flexEnd={!onPrev}>
            {onPrev && (
              <BackMobile onClick={this.onPrev}>
                <i className="icon-back-arrow" />
              </BackMobile>
            )}
            <ButtonContainer>
              <ButtonText>
                <HelpText onClick={this.openSupportChat}>
                  <HeaderContentIcon className="icon-support-icon-streak" />
                  <ButtonText>
                    <MIFormattedText label="qbo.header.support" />
                  </ButtonText>
                </HelpText>
              </ButtonText>

              <HelpText onClick={this.openDrawer}>
                <HeaderContentIcon className="icon-help-icon" />
                <ButtonText>
                  <MIFormattedText label="qbo.header.help" />
                </ButtonText>
              </HelpText>
              {!hideSettings && (
                <SettingsLink
                  to={generatePath(locations.settings.base, {
                    orgId,
                  })}
                  preserveState
                  onClick={this.onSettingsClick}
                >
                  <HeaderContentIcon className="icon-settings-icon" />
                  <ButtonText>
                    <MIFormattedText label="qbo.header.settings" />
                  </ButtonText>
                </SettingsLink>
              )}
              {goExit && (
                <Close data-testid="qbo-close-page" onClick={this.closeIframe}>
                  <i className="icon-close-icon" />
                </Close>
              )}
            </ButtonContainer>
          </HeaderButtonContent>
        </QBDTHeader>
        <FAQDrawer onCloseCallback={this.onDrawerClose} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState): HeaderMapStateToProps => ({
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): HeaderMapDispatchToProps => ({
  setUrlToBack(url: string) {
    dispatch(setUrlToBackAction(url));
  },
  setIsFaqOpen(isOpen: boolean) {
    dispatch(setFaqPanel({ isOpen }));
  },
});

const QBDTHeaderContainer = compose(
  withBreak(),
  connect(mapStateToProps, mapDispatchToProps),
  withNavigator()
)(QBDTHeaderContainerClass);

const QBDTHeader = styled.div<{ isBottomBorder?: string }>`
  ${commonStyles};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  height: 5rem;
  background-color: rgba(244, 245, 248);
  color: ${(props) => props.theme.text.color.main};
  border-bottom: ${(props) => (props.isBottomBorder ? '0.2rem solid rgba(216, 216, 216)' : 'none')};
  font-size: 1.4rem;
  padding: 0 1.4rem;
`;

const HeaderContent = styled.div`
  ${commonStyles};
`;
const HeaderButtonContent = styled.div<{ flexEnd?: boolean }>`
  ${commonStyles};
  display: flex;
`;

const HeaderText = styled.div`
  strong {
    font-weight: 600;
  }
`;

const HeaderContentIcon = styled.i`
  color: rgba(107, 108, 114, 1);
  font-size: 2.4rem;
  margin-right: 1rem;
`;

const HelpText = styled.div`
  ${commonStyles};
  cursor: pointer;
  margin-right: 2rem;
`;
const ButtonText = styled.div`
  @media ${devices.mobile} {
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
`;

const SettingsLink = styled(SmartLink)`
  ${commonStyles};
  margin-right: 3.6rem;
  text-decoration: none;
  border: none;
  color: ${(props) => props.theme.colors.text};
  &:active {
    color: ${(props) => props.theme.colors.text};
  }
`;

const navIconStyles = css`
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.text.color.label};
  cursor: pointer;
`;

const Close = styled.div`
  ${navIconStyles};
`;

const Back = styled.div`
  ${navIconStyles};
  padding-right: 1.4rem;
`;
const BackMobile = styled.div`
  display: none;
  ${(props) => props.theme?.components?.QBOElements?.BackMobile}
`;

const QBDTFooterContainer = ({ className }: { className?: string }) => {
  const site = useSiteContext();
  return (
    <QBDTFooter className={className}>
      <MelioFooterLogo>
        <QBOLogo src={qboLogo} alt="qboLogo" />
      </MelioFooterLogo>
      <MelioFooterText>
        <MIFormattedText label="qbo.footer.poweredBy" />
      </MelioFooterText>
      <FooterSettingsTextWrap>
        <FooterSettingsText href={site.config.agreementLinks.termsOfService} target="_blank" rel="noopener noreferrer">
          <MIFormattedText label="qbo.footer.termsOfService" />
        </FooterSettingsText>
        <FooterSettingsText href={site.config.agreementLinks.privacyPolicy} target="_blank" rel="noopener noreferrer">
          <MIFormattedText label="qbo.footer.privacyPolicy" />
        </FooterSettingsText>
      </FooterSettingsTextWrap>
    </QBDTFooter>
  );
};

QBDTFooterContainer.defaultProps = {};

const QBOLogo = styled.img`
  height: 2.4rem;
`;

const QBDTFooter = styled.div`
  ${commonStyles};
  color: rgba(113, 113, 115);
  font-size: 1rem;
  width: 100%;
  padding: 0 1.6rem;
  box-sizing: border-box;
  margin-top: auto;
  height: 4rem;
  background-color: rgba(236, 237, 240);
  ${(props) => props.theme?.components?.QBOFooterContainer?.QBOFooter}
`;

const MelioFooterLogo = styled.div`
  padding-right: 1.6rem;
`;

const FooterSettingsTextWrap = styled.div`
  display: flex;
`;

const FooterSettingsText = styled.a`
  text-decoration: none;
  color: rgba(28, 130, 210);
  margin-left: 1.6rem;
  &:hover {
    text-decoration: underline;
  }
`;

const MelioFooterText = styled.a`
  line-height: 1.4rem;
  letter-spacing: -0.01rem;
  strong {
    font-weight: 600;
  }
`;

export {
  QBDTLayoutContainer,
  QBDTHeaderContainer,
  QBDTFooterContainer,
  QBDTHeader,
  HeaderContent,
  SettingsLink,
  Close,
  Back,
  BackMobile,
};
