import { Box, Center } from '@melio/billpay-design-system';
import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  image: React.FC;
  title: string;
  description: string;
  descriptionValues?: Record<string, any>;
};

const DashboardEmptyStateContent = ({ image: Icon, title, description, descriptionValues }: Props) => (
  <Center h="full">
    <Box textAlign="center" w="68rem">
      <Icon />
      <Box textStyle="ds.h6" color="ds.gray.100" mb="ds.sm" mt="2.4rem" data-testid="empty-state-title">
        <MIFormattedText label={title} />
      </Box>
      <Box textStyle="ds.body1" color="ds.gray.200" data-testid="empty-state-description">
        <MIFormattedText label={description} values={descriptionValues} />
      </Box>
    </Box>
  </Center>
);

export default DashboardEmptyStateContent;
