import axios, { CancelToken } from 'axios';
import { useCallback } from 'react';
import { useApi } from 'src/hoc/useApi';
import { CONSTS } from 'src/utils/consts';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { DashboardItemMetadataParams, getDashboardItemMetadata } from '../api/dashboard-api';
import { ITEMS_PER_PAGE, DashboardTabStatusEnum } from '../consts';
import { getDashboardItemId } from '../utils';

export type RedirectQuery = {
  start: number;
  status: DashboardTabStatusEnum;
  limit: number;
  highlightedItemIds?: string;
};

export const DEFAULT_DASHBOARD_REDIRECT_PARAMS: RedirectQuery = {
  start: CONSTS.PAGINATION.DEFAULT_START,
  status: DashboardTabStatusEnum.Unpaid,
  limit: ITEMS_PER_PAGE,
};

const CANCEL_METADATA_REQUEST_TIMEOUT = 5000;

type GetDashboardItemQueryParams = {
  highlightedItemIds?: string[];
  paymentId: string;
  withoutTimeout?: boolean;
};

export const useGetDashboardItemQueryParams = () => {
  const orgId = useOrgId();
  const [getDashboardItemMetadataRequest, , isFetchingRedirectParams] = useApi(getDashboardItemMetadata);

  const getDashboardItemQueryParams = useCallback(
    async ({
      highlightedItemIds,
      paymentId,
      withoutTimeout = false,
    }: GetDashboardItemQueryParams): Promise<RedirectQuery> => {
      if (!paymentId) {
        return DEFAULT_DASHBOARD_REDIRECT_PARAMS;
      }

      const { cancelTimeoutId, cancelToken } = getCancelByTimeout(withoutTimeout);

      const requestParams = getMetadataRequestParams({
        paymentId,
        orgId,
        cancelToken,
      });

      if (!requestParams) {
        return DEFAULT_DASHBOARD_REDIRECT_PARAMS;
      }

      try {
        const dashboardItemMetadata = await getDashboardItemMetadataRequest(requestParams);
        const highlightedItemIdsParam = highlightedItemIds
          ? highlightedItemIds.join(',')
          : getDashboardItemId(dashboardItemMetadata.billIds, paymentId);

        return {
          start: dashboardItemMetadata.start,
          status: dashboardItemMetadata.status,
          highlightedItemIds: highlightedItemIdsParam,
          limit: ITEMS_PER_PAGE,
        };
      } catch {
        return DEFAULT_DASHBOARD_REDIRECT_PARAMS;
      } finally {
        clearTimeout(cancelTimeoutId);
      }
    },
    [orgId, getDashboardItemMetadataRequest]
  );

  return {
    getDashboardItemQueryParams,
    isFetchingRedirectParams,
  };
};

type GetRequestParamsProps = {
  billIds?: string[];
  paymentId?: string;
  orgId: string;
  cancelToken: CancelToken;
};

const getMetadataRequestParams = ({
  billIds,
  paymentId,
  orgId,
  cancelToken,
}: GetRequestParamsProps): DashboardItemMetadataParams | null => {
  const isPaymentIdValid = paymentId && parseInt(paymentId || '', 10);

  if (!isPaymentIdValid) {
    return null;
  }

  return {
    orgId,
    params: {
      pageSize: ITEMS_PER_PAGE,
      paymentId,
      billIds,
    },
    options: {
      cancelToken,
    },
  };
};

const getCancelByTimeout = (disabled = false) => {
  // For users that have a lot of payments this request might take significant time to load
  // pagination params. It's a workaround to cancel request if it takes more than 5 secs
  // and redirect user to the first page. It's short term solution - in long term perspective
  // we'll need to optimize the request on api side
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const cancelTimeoutId = setTimeout(() => {
    if (!disabled) {
      source.cancel();
    }
  }, CANCEL_METADATA_REQUEST_TIMEOUT);

  return {
    cancelToken: source.token,
    cancelTimeoutId,
  };
};
