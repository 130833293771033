import * as React from 'react';
import { compose } from 'recompose';
import { Flex } from '@melio/billpay-design-system';
import { withPreservedStateNavigator } from 'src/hoc';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import { generatePath } from 'react-router-dom';
import { getOrgId } from 'src/redux/user/selectors';
import { useSelector } from 'react-redux';
import { QBDTStepLayout, WizardOrLine } from 'src/billpay/qbdt/components';
import SelectItemCard from 'src/components/common/SelectItemCard';

export type Option = {
  id: string;
  label: string;
  description: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: string;
};

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateToExitWithPreservedState: () => void | null | undefined;
  locationState: Record<string, any>;
};

const eventPage = 'bank-add-variants';

const SelectBankAccountVerificationTypePageContainer = ({ navigate, navigateToExitWithPreservedState }: Props) => {
  const orgId = useSelector(getOrgId);
  const onPlaidClick = () => {
    analytics.track(eventPage, 'add-plaid');
    navigate(generatePath(locations.fundingSource.bankPlaid, { orgId }));
  };

  const onManualClick = () => {
    analytics.track(eventPage, 'add-manual');
    navigate(generatePath(locations.fundingSource.bankManual, { orgId }));
  };

  const goExit = () => {
    analytics.track(eventPage, 'exit');
    navigateToExitWithPreservedState();
  };

  return (
    <QBDTStepLayout
      title="onboarding.fundingSources.bank.verification.title"
      subtitle="onboarding.fundingSources.bank.verification.paymentSubtitle"
      goExit={goExit}
      innerSize={50}
      hideHeader
    >
      <Flex align="center" direction="column">
        <SelectItemCard
          id="plaid"
          testId="verification-type-plaid"
          onClick={onPlaidClick}
          label="onboarding.fundingSources.bank.verification.plaid.label"
          description="onboarding.fundingSources.bank.verification.plaid.description"
        />
        <WizardOrLine />
        <SelectItemCard
          id="manual"
          testId="verification-type-manual"
          onClick={onManualClick}
          label="onboarding.fundingSources.bank.verification.manual.label"
          description="onboarding.fundingSources.bank.verification.manual.description"
        />
      </Flex>
    </QBDTStepLayout>
  );
};

export default compose(withPreservedStateNavigator())(SelectBankAccountVerificationTypePageContainer);
