import React from 'react';
import { Flex, Icon, Box } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { generatePath, useHistory } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'src/images/general/check-icon.svg';
import { ReactComponent as RightArrowIcon } from 'src/images/general/right-arrow.svg';
import { useBreak } from 'src/hoc';
import locations from 'src/pages/vendor/virtual-delivery-method/locations';
import { CheckDelaysIcon } from 'src/components/common/CheckDelaysIcon';
import { MIFormattedText } from 'src/utils/formatting';
import { FeatureFlags } from 'src/utils/feature-flags';
import { PaymentType } from 'src/utils/types';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { DeliveryMethodCardWrapper } from './DeliveryMethodCardWrapper';
import { DeliveryMethodCardContentWrapper } from './DeliveryMethodCardContentWrapper';
import { DeliveryMethodCardTitle } from './DeliveryMethodCardTitle';
import { DeliveryMethodSubTitle } from './DeliveryMethodCardSubTitle';

type Props = {
  token: string;
  payment?: PaymentType;
};

export const PaperCheckDeliveryMethodCard = ({ token, payment }: Props) => {
  const { isDesktop } = useBreak();
  const history = useHistory();
  const [isCheckDelaysEnabled] = featureFlags.useAnonymousFeature(FeatureFlags.CheckDelaysWarning, false);

  const cardClickHandler = () => {
    vendorUnilateralAnalytics.trackSelectDeliveryMethod({
      paymentId: payment?.id,
      vendorEmail: payment?.vendor?.contactEmail,
      vendorId: payment?.vendorId,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
      option: DELIVERY_TYPE.VIRTUAL_CARD,
    });

    history.push(generatePath(locations.addPaperCheckMethod, { token }));
  };

  return (
    <DeliveryMethodCardWrapper onClick={cardClickHandler} testId="unilateral-delivery-method-paper-check">
      <Icon as={CheckIcon} w="ds.2xl" h="ds.2xl" />
      <DeliveryMethodCardContentWrapper>
        <DeliveryMethodCardTitle label="vendor.unilateral.deliveryMethods.paperCheck.title" />
        <DeliveryMethodSubTitle label="vendor.unilateral.deliveryMethods.paperCheck.text" />
        {isCheckDelaysEnabled && <CheckDelayMessage />}
      </DeliveryMethodCardContentWrapper>
      {isDesktop && (
        <Flex align="center" justify="center">
          <Icon as={RightArrowIcon} w="ds.xl" height="ds.xl" />
        </Flex>
      )}
    </DeliveryMethodCardWrapper>
  );
};

const CheckDelayMessage = () => (
  <Flex alignItems="center">
    <Box as="span" textStyle="ds.body2" color="ds.gray.200">
      <MIFormattedText label="checkDelaysWarning.labels.unilateral.check" />
    </Box>
    <Box as="span" ml="ds.sm">
      <CheckDelaysIcon
        tooltipLabel="checkDelaysWarning.tooltipLabels.default"
        sx={{
          position: 'relative',
          top: 'ds.xs',
        }}
      />
    </Box>
  </Flex>
);
