import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${(props) => (props.theme as any).background.default};
    font-family: ${(props) => (props.theme as any).fontFamily};
  }
  body > span {
    font-family: ${(props) => (props.theme as any).fontFamily} !important;
  }
  ${(props) =>
    Object.keys((props.theme as any).icons)
      .map(
        (className) =>
          `.icon-${className}:before {
      content: "${(props.theme as any).icons[className]}";
     }`
      )
      .join('\n')}
`;
export { GlobalStyle };
