import React from 'react';
import { Box } from '@melio/billpay-design-system';
import {
  getPaymentMethodIcon,
  getPaymentMethodLabel,
} from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/utils';
import { MIFormattedText } from 'src/utils/formatting';
import { AccountType } from 'src/utils/types';
import FundingSourceIcon from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/FundingSourceIcon';

type Props = {
  fundingSource: AccountType;
};

const FundingSourceLabel = ({ fundingSource }: Props) => {
  const { icon, imageSrc } = getPaymentMethodIcon(fundingSource);
  const { label, values, nickname } = getPaymentMethodLabel(fundingSource);

  return (
    <>
      <FundingSourceIcon imageSrc={imageSrc} icon={icon} />
      <Box ml="ds.lg">{nickname || <MIFormattedText label={label} values={values} />}</Box>
    </>
  );
};

export default FundingSourceLabel;
