import { Box, HStack } from '@melio/billpay-design-system';
import React from 'react';
import SpinnerLoader from 'src/pages/quickbooks/components/SpinnerLoader';
import { MIFormattedText } from 'src/utils/formatting';

export const SyncIndicator = () => (
  <HStack spacing="ds.md">
    <SpinnerLoader size={2.4} />
    <Box textStyle="ds.body2" color="ds.gray200">
      <MIFormattedText label="paymentDashboard.sync.indicator" />
    </Box>
  </HStack>
);
