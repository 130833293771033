import {
  replaceVirtualDeliveryMethodSlice,
  copyFromOwnedVendorWithUnilateralToken,
  updateWithUnilateralToken,
} from 'src/modules/delivery-methods/delivery-methods-slice';
import { CONSTS } from 'src/utils/consts';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import api from 'src/services/api/payments';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { dashboardStore } from 'src/billpay/qbdt/pages/dashboard/redux-store/dashboard-store';
import {
  fetchPaymentDetailsWithToken,
  updatePaymentWithToken,
  fetchEmailToVendorDetails,
  retryFailedToDeliverWithToken,
} from './payments-slice';

const name = 'payments';

export const PAY_STATUS_CONSTS = {
  ALL_PASSED: 'allPassed',
  ALL_FAILED: 'allFailed',
  SOME_FAILED: 'someFailed',
};

export type ApprovalDecisionType = {
  loading: boolean;
  error: any;
  approvalStatus: string;
};

const approvalDecisionReducers = {
  [ON_REQUEST]: (state, action) => {
    state.approvalDecision.loading = true;
    state.approvalDecision.error = null;
    state.approvalDecision.approvalStatus = action.payload.status;
  },
  [ON_FAILURE]: (state, action) => {
    state.approvalDecision.loading = false;
    state.approvalDecision.error = action.error;
  },
  [ON_SUCCESS]: (state) => {
    state.approvalDecision.loading = false;
    state.approvalDecision.error = null;
  },
};

const approvePaymentSlice = createApiCallSlice({
  name: `[${name.toUpperCase()}] PAYMENT_APPROVED`,
  api: api.approvePayment,
  initialState: {
    approvalDecision: {
      loading: false,
      error: undefined,
      approvalStatus: undefined,
    },
    batchPayments: {},
    deliveryMethods: {},
    meta: {},
    byId: {},
  },
  reducers: approvalDecisionReducers,
});

const declinePaymentSlice = createApiCallSlice({
  name: `[${name.toUpperCase()}] PAYMENT_DECLINED`,
  api: api.declinePayment,
  reducers: approvalDecisionReducers,
});

const persistConfig = {
  whitelist: [],
};

const paymentStore = createRestfulSlice({
  name,
  api: {
    list: (params) => api.list(params).then(({ objects, totalCount }) => ({ items: objects, totalCount })),
  },
  initialState: {},
  persistConfig,
  selectors: {
    approvalDecisionStatus: (state) => state[name].approvalDecision,
    byId: (paymentId) => (state) => state[name].byId[paymentId],
    payment(paymentId) {
      const paymentSelectors = {
        filesUrls(state) {
          return state[name].meta[paymentId]?.filesUrls;
        },
        deliveryDates(state) {
          return state[name].meta[paymentId]?.deliveryDates;
        },
        deliveryMethodExist(state) {
          return state[name].meta[paymentId]?.deliveryMethodExist;
        },
      };
      return paymentSelectors;
    },
    validation(paymentId) {
      const validationData = {
        isPaymentLoading(state) {
          return state[name].meta[paymentId]?.loading;
        },
        errorData(state) {
          return state[name].meta[paymentId]?.error;
        },
      };
      return validationData;
    },
  },
  slices: {
    approvePaymentSlice,
    declinePaymentSlice,
    fetchPaymentDetailsWithToken,
    fetchEmailToVendorDetails,
    updatePaymentWithToken,
    retryFailedToDeliverWithToken,
  },
  extraReducers: {
    [replaceVirtualDeliveryMethodSlice.actions.success](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [copyFromOwnedVendorWithUnilateralToken.actions.success](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [updateWithUnilateralToken.actions.success](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [dashboardStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        if (item.payment) {
          state.byId[item.payment.id] = item.payment;
        }
      });
    },
  },
});

function virtualDeliveryMethodReplaceSuccessHandler(state, action) {
  const paymentId = action?.meta?.identifier?.paymentId;

  if (paymentId) {
    const deliveryType = action?.payload?.deliveryType;
    const { achDates, checkDates } = state.meta[paymentId].deliveryDates;
    const dates = deliveryType === CONSTS.DELIVERY_TYPE.CHECK ? checkDates : achDates;
    state.byId[paymentId].deliveryMethodId = action.payload.id;
    state.byId[paymentId].deliveryEta = dates.deliveryDate;
    state.byId[paymentId].maxDeliveryEta = dates.maxDeliveryDate;
  }
}

export function getPaymentsActions(dispatch: any) {
  return {
    approvePayment: (params: any) => dispatch(paymentStore.actions.approvePaymentSlice(params)),
    declinePayment: (params: any) => dispatch(paymentStore.actions.declinePaymentSlice(params)),
  };
}

export default paymentStore;
