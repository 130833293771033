import { useEffect } from 'react';
import analytics from 'src/services/analytics';
import { useModal } from 'src/helpers/react/useModal';
import { useLocationState } from 'src/utils/hooks';
import { BillType, PaymentType } from 'src/utils/types';
import { getPartialBillId } from 'src/utils/bills';
import { useCheckVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useCheckVendorEmail';

import { SinglePayFlowSuccessModal } from '../components/SinglePayFlowSuccess';
import { NotifyVendorModal as NotifyVendorDialog } from '../components/NotifyVendorModal';
import { NotifyVendorSuccessModal as NotifyVendorSuccessDialog } from '../components/NotifyVendorSuccessModal';

import { DashboardActionConfirmFlowEnum, DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP } from '../consts';
import { PAY_FLOW_PAGE_EVENT } from '../event-mapping';

const useSinglePayFlowSuccess = () => {
  const [dashboardActionConfirmFlow, setDashboardActionConfirmFlow] = useLocationState(
    DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP
  );
  const [bill] = useLocationState<BillType>('bill');
  const [payment] = useLocationState<PaymentType>('payment');
  const [isSyncPaymentFailed] = useLocationState<boolean>('isSyncPaymentFailed', false);

  const { getVendorEmail } = useCheckVendorEmail();

  const vendorId = bill?.vendor?.id;
  const vendorEmail = getVendorEmail(vendorId);
  const companyName = bill?.vendor?.companyName;
  const partialBillId = getPartialBillId(bill);

  const analyticsProps = {
    vendorId,
    partialBillId,
  };

  useEffect(() => {
    if (dashboardActionConfirmFlow === DashboardActionConfirmFlowEnum.SinglePay) {
      showSinglePayFlowSuccess();

      analytics.track(PAY_FLOW_PAGE_EVENT, 'success-payment-modal', {
        paymentId: payment?.id,
        vendorEmail,
        ...analyticsProps,
      });

      setDashboardActionConfirmFlow(null);
    }
  }, [dashboardActionConfirmFlow]);

  const handleNotifyVendorClick = () => {
    showNotifyVendorModal();
    analytics.track(PAY_FLOW_PAGE_EVENT, 'notify-my-vendor', {
      screenName: 'success',
      ...analyticsProps,
    });
  };

  const handleNotifyVendorSuccess = (vendorContactEmail: string) => {
    showNotifyVendorSuccessModal();
    analytics.track(PAY_FLOW_PAGE_EVENT, 'EmailSentSuccessPaymentModal-Shown', {
      paymentId: payment?.id,
      vendorId: bill?.vendor?.id,
      vendorEmail: vendorContactEmail,
    });
  };

  const [SinglePayFlowSuccess, showSinglePayFlowSuccess] = useModal(SinglePayFlowSuccessModal, {
    partialBillId,
    payment,
    vendorId,
    companyName,
    onNotifyVendorClick: handleNotifyVendorClick,
    isSyncPaymentFailed,
  });

  const [NotifyVendorModal, showNotifyVendorModal] = useModal(NotifyVendorDialog, {
    eventPage: PAY_FLOW_PAGE_EVENT,
    payment,
    vendorId,
    companyName,
    onSuccess: handleNotifyVendorSuccess,
  });

  const [NotifyVendorSuccessModal, showNotifyVendorSuccessModal] = useModal(NotifyVendorSuccessDialog, {});

  return { SinglePayFlowSuccess, NotifyVendorModal, NotifyVendorSuccessModal };
};

export { useSinglePayFlowSuccess };
