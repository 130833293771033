import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';
import { PAYMENT_APPROVAL_STATUS } from 'src/utils/consts';
import { AccountType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import deliveryApi from 'src/services/api/delivery';

import { chakra } from 'src/theme/ds';
import { VStack } from '@melio/billpay-design-system';
import { ReturnType as CheckFeeReturnType } from 'src/billpay/qbdt/hooks/useCheckFee/types';
import { TotalAmountSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/TotalAmountSection/TotalAmountSection';
import {
  PaymentInfoDividerSizeEnum,
  PaymentInfoSectionDivider,
} from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSectionDivider';
import { PayToSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PayToSection/PayToSection';
import { OrganizationBillingFee } from 'src/services/api/smb-billing-fee';
import PaymentFee from '../../components/PaymentFee';
import { ConfirmPayFromSection } from './sections/ConfirmPayFromSection/ConfirmPayFromSection';
import { ActivityActionsType } from './types';
import { ConfirmVendorSection } from './sections/ConfirmVendorSection/ConfirmVendorSection';
import { ConfirmMemoSection } from './sections/ConfirmMemoSection';

type Props = {
  bill: RecordOf<BillType>;
  fee?: PaymentFeeInfo | null;
  fundingSource: RecordOf<AccountType>;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  activityActions: ActivityActionsType;
  checkFee?: CheckFeeReturnType;
  organizationBillingFees: OrganizationBillingFee[];
};

type ApprovalDecisionStatusResponse = {
  approvalDecisionStatus: PAYMENT_APPROVAL_STATUS;
};

export const QBDTConfirmPaymentInfo = ({
  fee,
  bill,
  fundingSource,
  payment,
  deliveryMethod,
  activityActions,
  checkFee,
  organizationBillingFees,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const [getApprovalDecisionStatus, approvalDecisionStatusResult] = useApi<
    [string, number],
    ApprovalDecisionStatusResponse
  >(deliveryApi.getApprovalDecisionStatus);
  const totalAmount = payment.amount as number;
  const paymentData = payment.merge({
    approvalDecisionStatus: approvalDecisionStatusResult?.approvalDecisionStatus,
  });

  const { onEditFundingSource, onEditDate, onEditDeliveryMethod, onEditNote } = activityActions;

  const isSharedVendor = bill.isVendorRequest();
  const companyName = bill.vendor?.companyName || '';

  useEffect(() => {
    getApprovalDecisionStatus(orgId, totalAmount);
  }, [orgId, totalAmount, getApprovalDecisionStatus]);

  return (
    <Container spacing="5" alignItems="initial">
      <TotalAmountSection totalAmount={totalAmount} />
      <PaymentInfoSectionDivider size={PaymentInfoDividerSizeEnum.Lg} />
      <PayToSection companyName={companyName} invoiceNumber={bill.invoiceNumber} billsInfo={payment.bills} />
      <PaymentInfoSectionDivider />
      <ConfirmPayFromSection
        bill={bill}
        fundingSource={fundingSource}
        payment={paymentData}
        deliveryMethod={deliveryMethod}
        onEditDate={onEditDate}
        onEditFundingSource={onEditFundingSource}
      />
      {!isSharedVendor && (
        <>
          <PaymentInfoSectionDivider />
          <ConfirmVendorSection
            bill={bill}
            fundingSource={fundingSource}
            payment={paymentData}
            deliveryMethod={deliveryMethod}
            onEditDeliveryMethod={onEditDeliveryMethod}
          />
        </>
      )}
      <PaymentInfoSectionDivider />
      <ConfirmMemoSection payment={payment} onEdit={onEditNote} />
      {fee && (
        <>
          <PaymentInfoSectionDivider size={PaymentInfoDividerSizeEnum.Lg} />
          <PaymentFee
            fee={fee}
            checkFee={checkFee}
            fundingSource={fundingSource}
            deliveryMethod={deliveryMethod}
            isEdit={!!payment.id}
            organizationBillingFees={organizationBillingFees}
          />
        </>
      )}
    </Container>
  );
};

const Container = chakra(VStack, {
  baseStyle: {
    p: '5',
    alignItems: 'inherit',
    borderRadius: 'lg',
    boxShadow: '0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: 'ds.white',
    boxSizing: 'border-box',
    textAlign: 'inherit',
  },
});
