import React, { PropsWithChildren } from 'react';
import { chakra } from 'src/theme/ds';
import { Box, VStack } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  label: string;
  testId?: string;
  contentTestId?: string;
  spacing?: '0.6rem' | '1rem';
};

export const PaymentInfoSection = ({
  label,
  testId,
  contentTestId,
  spacing = '1rem',
  children,
}: PropsWithChildren<Props>) => (
  <VStack spacing={spacing} width="full" alignItems="initial" data-testid={testId}>
    <Box textStyle="ds.body2" textTransform="uppercase" fontWeight="500" color="ds.gray.200">
      <MIFormattedText label={label} />
    </Box>
    <SectionContent spacing="5" data-testid={contentTestId}>
      {children}
    </SectionContent>
  </VStack>
);

const SectionContent = chakra(VStack, {
  baseStyle: {
    alignItems: 'initial',
  },
});
