import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/theme/AppDevices';
import { CONSTS, NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import Logo from 'src/images/Logo';
import * as WizardElements from './WizardElement';
import * as QBDTElements from '../QBDTElements';

type Props = {
  illustration?: string | React.ReactNode;
  title: string;
  titleValues?: Record<string, any>;
  text?: string | null;
  textAlign?: string;
  hasLogo?: boolean;
  textValues?: Record<string, any>;
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonValues?: Record<string, any>;
  linkLabel?: string | null;
  linkAction?: () => void;
  isLoading?: boolean;
  hideHeader?: boolean | null;
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  children?: React.ReactNode;
  goExit?: () => void | null;
  className?: string;
  notification?: { label: string; values?: Record<string, any> };
  notificationTitle?: { label: string; values?: Record<string, any> };
  notificationType?: NOTIFICATION_CARD_TYPES;
  FooterComponent?: JSX.Element;
};

const QBDTSuccessLayout = ({
  illustration,
  title,
  titleValues,
  text,
  textValues,
  buttonLabel,
  buttonAction,
  buttonValues,
  linkLabel,
  linkAction,
  isLoading,
  children,
  hasLogo,
  hideHeader,
  headerLabel,
  headerLabelValues,
  goExit,
  textAlign = 'center',
  className,
  notification,
  notificationTitle,
  notificationType = NOTIFICATION_CARD_TYPES.INFO,
  FooterComponent,
}: Props) => (
  <QBDTElements.QBDTLayoutContainer className={className}>
    {!hideHeader && (
      <QBDTElements.QBDTLayoutContainer headerLabel={headerLabel} headerLabelValues={headerLabelValues} />
    )}

    {hideHeader && goExit && <Close onClick={goExit} />}
    <WizardElements.WizardInnerSuccess isCenterVert>
      {illustration &&
        (React.isValidElement(illustration) ? illustration : <WizardElements.WizardIllustration src={illustration} />)}
      {hasLogo && (
        <WizardElements.LogoContainer>
          <Logo fill="light" size="regular" isFull />
        </WizardElements.LogoContainer>
      )}

      <WizardElements.WizardTitle data-testid="wizard-title">
        <MIFormattedText label={title} values={titleValues} />
      </WizardElements.WizardTitle>

      {text && (
        <WizardElements.WizardText textAlign={textAlign} data-testid="wizard-text">
          <MIFormattedText label={text} values={textValues} />
        </WizardElements.WizardText>
      )}

      {(notification || notificationTitle) && (
        <WizardElements.WizardNotification type={notificationType} title={notificationTitle} subtitle={notification} />
      )}

      {children && <WizardElements.WizardActionsContainer>{children}</WizardElements.WizardActionsContainer>}

      <WizardElements.WizardActionsRowContainer>
        {buttonLabel && buttonAction && (
          <WizardElements.WizardButtonContainer>
            <MIButton
              label={buttonLabel}
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={buttonAction}
              isProcessing={isLoading}
              values={buttonValues}
            />
          </WizardElements.WizardButtonContainer>
        )}

        {linkLabel && linkAction && (
          <WizardElements.WizardButtonContainer>
            <MIButton label={linkLabel} variant={CONSTS.BUTTON_VARIANT.SECONDARY} onClick={linkAction} />
          </WizardElements.WizardButtonContainer>
        )}
      </WizardElements.WizardActionsRowContainer>
    </WizardElements.WizardInnerSuccess>

    {FooterComponent || <QBDTElements.QBDTFooterContainer />}
  </QBDTElements.QBDTLayoutContainer>
);

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 1;
  @media ${devices.mobile} {
    color: ${(props) => props.theme.text.color.green};
  }
`;

export default QBDTSuccessLayout;
