import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as SuccessIllustration } from 'src/images/general/action-done-icon.svg';
import analytics from 'src/services/analytics';
import {
  microDepositsEventAction,
  microDepositsEventPage,
  microDepositsEventStatuses,
} from 'src/pages/funding-sources/consts';
import { useParams } from 'react-router-dom';
import { StyledQBDTFooterContainer, StyledQBDTSuccessLayout } from 'src/pages/funding-sources/components/common';

export function MicroDepositsSuccessPage() {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    analytics.track(microDepositsEventPage, microDepositsEventAction, {
      fundingSourceId: id,
      status: microDepositsEventStatuses.VERIFICATION_COMPLETED,
    });
  }, []);

  return (
    <StyledQBDTSuccessLayout
      hideHeader
      illustration={<SuccessIllustrationIcon />}
      FooterComponent={<StyledQBDTFooterContainer />}
      title="fundingSources.microDeposits.success.title"
      text="fundingSources.microDeposits.success.subtitle"
    />
  );
}

const SuccessIllustrationIcon = styled(SuccessIllustration)`
  margin-bottom: 4rem;
`;
