import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { Box, HStack, VStack } from '@melio/billpay-design-system';
import { MIFormattedText, MIFormattedDate, MIFormattedCurrency } from 'src/utils/formatting';
import { BillInfo } from './types';

const MAX_VISIBLE_ROW_NUMBER = 8;

const Cell = ({ style, children }: { style: CSSObject; children: React.ReactNode }) => (
  <Box __css={{ ...regularCellStyle, ...style }}>{children}</Box>
);

const HeaderCell = ({ style, label }: { style: CSSObject; label: string }) => (
  <Box __css={{ ...headerCellStyle, ...style }}>
    <MIFormattedText label={label} />
  </Box>
);

const Header = ({ total }: { total: number }) => (
  <HStack h="1.6rem" spacing="ds.lg">
    <HeaderCell style={invoiceNumberCellStyle} label="bills.view.columns.invoiceNumber" />
    <HeaderCell
      style={total > MAX_VISIBLE_ROW_NUMBER ? dueDateCellSmallStyle : dueDateCellStyle}
      label="bills.view.columns.dueDate"
    />
    <HeaderCell style={amountCellStyle} label="bills.view.columns.amount" />
  </HStack>
);

const Row = ({ value, total }: { value: BillInfo; total: number }) => (
  <HStack minH="3.2rem" spacing="ds.lg">
    <Cell style={invoiceNumberCellStyle}>{value.invoiceNumber || '-'}</Cell>
    <Cell style={total > MAX_VISIBLE_ROW_NUMBER ? dueDateCellSmallStyle : dueDateCellStyle}>
      <MIFormattedDate date={value.dueDate} />
    </Cell>
    <Cell style={amountCellStyle}>
      <MIFormattedCurrency value={value.totalAmount} />
    </Cell>
  </HStack>
);

const RowDivider = () => <Box w="100%" h="0.1rem" minH="0.1rem" bgColor="ds.gray.700" />;

const Body = ({ children }: { children: React.ReactNode }) => (
  <VStack divider={<RowDivider />} maxH="26.8rem" overflowY="scroll" alignItems="flex-start">
    {children}
  </VStack>
);

const BillsInfoTable = ({ data }: { data: BillInfo[] }) => (
  <Box w="28rem">
    <Header total={data.length} />
    <Body>
      {data.map((value, index) => (
        <Row key={`${index}-${value.invoiceNumber}`} value={value} total={data.length} />
      ))}
    </Body>
  </Box>
);

const regularCellStyle: CSSObject = {
  textStyle: 'ds.body3',
  color: 'ds.gray.100',
};

const headerCellStyle = {
  textStyle: 'ds.body3',
  fontWeight: 'ds.medium',
  color: 'ds.gray.300',
};

const invoiceNumberCellStyle: CSSObject = {
  w: '8.4rem',
};

const dueDateCellStyle: CSSObject = {
  w: '9.8rem',
};

const dueDateCellSmallStyle: CSSObject = {
  w: '8.6rem',
};

const amountCellStyle: CSSObject = {
  w: '6.1rem',
  textAlign: 'right',
};

export { BillsInfoTable };
