import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { close } from 'src/billpay/qbdt/services/qbdt';
import scheduleOnlinePayment from './resources/schedule_online_payment.png';
import selectingSchedule from './resources/selecting_schedule.png';

const NoData = () => (
  <Container>
    <Title>
      <MIFormattedText label="entry.sync.noData.title" />
    </Title>
    <Subtitle>
      <MIFormattedText label="entry.sync.noData.subtitle" />
    </Subtitle>
    <ContentGrid>
      <Img src={selectingSchedule} alt="stepImage" />
      <TextItemContainer>
        <TextItem>
          <MIFormattedText label="entry.sync.noData.firstRow" />
        </TextItem>
      </TextItemContainer>
      <TextItemContainer>
        <TextItem>
          <MIFormattedText label="entry.sync.noData.secondRow" />
        </TextItem>
      </TextItemContainer>
      <Img src={scheduleOnlinePayment} alt="stepImage" />
    </ContentGrid>
    <MIButton label="entry.sync.noData.button" variant={BUTTON_VARIANT.PAY} onClick={close} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
`;

const Title = styled.h1`
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  text-align: center;
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0 0 1rem 0;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-weight: normal;
  ${({ theme }) => theme.text.fontType.regular};
  margin: 0 0 4rem 0;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 4rem;
  width: 49.4rem;
  row-gap: 4rem;
  column-gap: 4rem;
`;

const Img = styled.img`
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.2);
  width: 22.7rem;
  height: 13rem;
`;

const TextItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextItem = styled.div`
  ${({ theme }) => theme.text.fontType.medium};
`;

export default NoData;
