import React, { useEffect } from 'react';
import { Flex } from '@melio/billpay-design-system';
import head from 'lodash/head';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import {
  DeliveryOptions,
  SelectDeliveryMethodSubtitle,
  SelectDeliveryMethodPageTitle,
} from 'src/pages/vendor/shift-vendor-to-debit/components/SelectDeliveryMethodPage';
import { OrganizationType, PaymentType } from 'src/utils/types';
import analytics from 'src/services/analytics';
import { getPartialBillId } from 'src/utils/bills';
import { useQuery } from 'src/hooks/useQuery';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';

type Props = {
  payment?: PaymentType;
  organization?: OrganizationType;
  token: string;
};

export const SelectDeliveryMethodPage = ({ payment, organization, token }: Props) => {
  const { amount, isEligibleToVirtualCard, deliveryMethod } = payment || {};
  const { companyName } = organization || {};
  const query = useQuery();

  useEffect(() => {
    const bill = payment?.bill || head(payment?.bills);
    const originalLink = query.get('button_clicked');

    analytics.track(pushToDebitEventPage, 'ChooseDeliveryMethod-Viewed', {
      flow: 'vendor',
      billId: payment?.billId || bill?.id,
      partialBillId: bill && getPartialBillId(bill),
      paymentId: payment?.id,
      vendorId: payment?.vendorId,
      deliveryMethodTypes: isEligibleToVirtualCard ? 'debit_virtual-card' : 'debit',
      originalLink,
      emailTag: 'debit-card-details-request_email',
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" gap="ds.md" width="100%" mb="ds.2xl">
          <SelectDeliveryMethodPageTitle />
          <SelectDeliveryMethodSubtitle
            amount={amount || 0}
            companyName={companyName || ''}
            deliveryType={deliveryMethod?.deliveryType}
          />
        </Flex>
        <DeliveryOptions isEligibleToVirtualCard={!!isEligibleToVirtualCard} token={token} payment={payment} />
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
