export const microDepositsEventActions = {
  VERIFY_CLICK: 'verify-click',
  CANNOT_VERIFY_YET: 'verify-click-cant-verify-yet',
  VERIFY_FINISH: 'verify-finish',
  ACCOUNT_SUBMIT_CONTINUE: 'submit-continue',
  VERIFICATION_FAILED: 'verify-confirmed-failed',
  VERIFICATION_SUCCESS: 'verify-confirmed-success',
  CLOSE_MODAL: 'cancel-dialog',
} as const;

export const microDepositsEventPages = {
  payBill: {
    base: 'pay-bill',
    manual: 'pay-bill-verify-manual-account',
  },
  settings: {
    base: 'settings-payment-methods',
    manual: 'settings-verify-manual-account',
  },
  billingFee: {
    base: 'billing-verify-manual-account',
  },
} as const;

export type MicroDepositsEventActionType = typeof microDepositsEventActions[keyof typeof microDepositsEventActions];

export type MicroDepositsEventPageType =
  | typeof microDepositsEventPages.payBill.base
  | typeof microDepositsEventPages.settings.base
  | typeof microDepositsEventPages.billingFee.base;
