import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { DELIVERY_TYPE, NOTIFICATION_CARD_TYPES, PAYMENT_APPROVAL_STATUS } from 'src/utils/consts';
import { isManualBankAccountNotVerified, canVerifyManualBankAccount } from 'src/utils/funding-sources';
import { AccountRecord } from 'src/records/settings.record';
import { DeliveryMethodRecord } from 'src/records/vendor';
import { MIFormattedDate } from 'src/utils/formatting';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import profileStore from 'src/modules/profile/profile-store';
import { BillType, PaymentType } from 'src/utils/types';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import MIInlineLink from 'src/components/common/MIInlineLink';
import { useFundingSourceMicroDepositState } from 'src/hooks/settings/useFundingSourceMicroDeposits';
import VerifyMicroDeposits from 'src/components/micro-deposits/VerifyMicroDeposits';
import analytics from 'src/services/analytics';
import { microDepositsEventPages } from 'src/components/micro-deposits/consts';
import { getPartialBillId } from 'src/utils/bills';

type Props = {
  className?: string;
  bill: BillType;
  payment: PaymentType;
};

const PaymentProcessingBlockedNotificationCard = ({ className, bill, payment }: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const fundingSource = AccountRecord(
    useSelector(fundingSourcesStore.selectors.byId(payment.fundingSourceId)) || payment.fundingSource
  );
  const deliveryMethod = DeliveryMethodRecord(payment.deliveryMethod);
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource as any);
  const isUnitaleral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const date = <MIFormattedDate year={undefined} date={payment.scheduledDate} />;
  const microDepositEventPage = microDepositsEventPages.payBill.base;

  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [microDepositState, microDepositActions] = useFundingSourceMicroDepositState(
    microDepositEventPage,
    {
      fundingSourceId: fundingSource.id,
    },
    {
      fundingSourceID: fundingSource.id,
      partialBillId: getPartialBillId(bill),
    }
  );

  let subtitle;
  let subtitleValues = {};
  if (isMicrodeposit && isApprovalRequired) {
    subtitle = 'unverifiedBankAccountApprovalRequiredMessage';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    subtitleValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isApprovalRequired && isUnitaleral) {
    subtitle = 'unilateralApprovalRequiredMessage';
    subtitleValues = {
      date,
      vendor: bill.vendor?.companyName || '',
    };
  } else if (isMicrodeposit && canVerifyManualBankAccount(fundingSource as any)) {
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    subtitle = 'unverifiedBankAccountWithActionMessage';
    subtitleValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
      verifyLink: (...chunks) => (
        <VerifyAccountLink
          text={React.Children.toArray(chunks)}
          onClick={(event) => {
            analytics.track('bill-payment', 'verify-manual-account-click');
            event.preventDefault();
            setShowVerifyDialog(true);
          }}
        />
      ),
    };
  } else if (isMicrodeposit) {
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    subtitle = 'unverifiedBankAccountMessage';
    subtitleValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isApprovalRequired) {
    subtitle = 'approvalRequiredMessage';
    subtitleValues = {
      date,
    };
  } else if (isUnitaleral) {
    subtitle = 'unilateralMessage';
    subtitleValues = {
      date,
      vendor: bill.vendor?.companyName || '',
    };
  }

  subtitleValues = {
    ...subtitleValues,
    li: (...chunks) => <ListItem>{React.Children.toArray(chunks)}</ListItem>,
    ul: (...chunks) => <List>{React.Children.toArray(chunks)}</List>,
  };

  const userType = permissions.bills.approve() ? 'admin' : 'contributor';
  return (
    <>
      {showVerifyDialog && (
        <VerifyMicroDeposits
          {...microDepositState}
          {...microDepositActions}
          key={fundingSource.id}
          onVerifyFinished={() => {
            setShowVerifyDialog(false);
          }}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}
      {subtitle && (
        <div className={className}>
          <QBONotificationCard
            type={NOTIFICATION_CARD_TYPES.WARNING}
            title={{
              label: 'viewBillWarningNotificationCard.actionRequiredTitle',
            }}
            subtitle={{
              label: `viewBillWarningNotificationCard.${userType}.${subtitle}`,
              values: subtitleValues,
            }}
          />
        </div>
      )}
    </>
  );
};

const VerifyAccountLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;

const ListItem = styled.li`
  padding-left: 3px;
`;

const List = styled.ul`
  list-style-type: '-';
  padding: 0;
  margin: 0;
  padding-inline-start: 0.8rem;
`;

export default PaymentProcessingBlockedNotificationCard;
