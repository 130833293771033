import styled from 'styled-components';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import { QBDTFooterContainer, WizardInner, WizardText } from 'src/billpay/qbdt/components';
import { devices } from 'src/theme/AppDevices';

export const StyledQBDTSuccessLayout = styled(QBDTSuccessLayout)`
  ${WizardText} {
    font-size: ${(props) => props.theme.text.size.regular};
    line-height: 2.4rem;
    max-width: 50%;
  }

  ${WizardInner} {
    width: 99.2rem;
  }

  @media ${devices.nonDesktop} {
    ${WizardInner} {
      width: auto;
      margin: 0 1.6rem;
      overflow-x: hidden;
    }

    ${WizardText} {
      max-width: 70%;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  @media ${devices.mobile} {
    ${WizardText} {
      width: 80%;
    }
  }
`;

export const StyledQBDTFooterContainer = styled(QBDTFooterContainer)`
  @media ${devices.nonDesktop} {
    flex-direction: column;
    gap: 1rem;
    height: auto;
    padding: 1rem 1.6rem;
  }
`;
