import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deliveryApi from 'src/services/api/delivery';
import { getOrgId } from 'src/redux/user/selectors';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import { catchAsync } from 'src/utils/async';
import { FAILED_PAYMENT_TYPE, PAYMENT_STATUS } from 'src/utils/consts';

type DeliveryDatesResponse = {
  deliveryDate: string;
  maxDeliveryDate: string;
};

export const useSetDeliveryDates = () => {
  const [isProcessingDatesLoading, setIsProcessingDatesLoading] = useState(false);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();

  const isPaymentFailedToDeliver =
    payment.status === PAYMENT_STATUS.FAILED && payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;

  useEffect(() => {
    if (!isPaymentFailedToDeliver) {
      return;
    }

    (async () => {
      setIsProcessingDatesLoading(true);

      const [err, data] = await catchAsync(
        deliveryApi.getClosestDeliveryDates(
          orgId,
          (payment.deliveryMethodId as unknown) as number,
          payment.fundingSourceId,
          payment.amount as number
        )
      );

      if (err) {
        setIsProcessingDatesLoading(false);
        return;
      }

      const { deliveryDate, maxDeliveryDate } = data as DeliveryDatesResponse;

      dispatch(
        selectPaymentDatesAction(new Date(payment.scheduledDate), new Date(deliveryDate), new Date(maxDeliveryDate))
      );

      setIsProcessingDatesLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isProcessingDatesLoading,
  };
};
