import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';

const eventPage = 'pay-bill';

export const useRetryVirtualCardDelivery = () => {
  const history = useHistory();
  const { bill, payment } = useViewPaymentContext();

  const orgId = useSelector(getOrgId);

  const exitUrl = generatePath(locations.view.base, {
    orgId,
    billId: bill.id,
    paymentId: payment.id,
  });

  const onRetryVirtualCardDelivery = () => {
    analytics.track(eventPage, 'retry-virtual-card-payment', {
      paymentId: payment.id,
      billId: bill.id,
    });

    history.push(
      generatePath(locations.edit.virtualCardRecovery, {
        billId: bill.id,
        paymentId: payment.id,
        orgId,
      }),
      { exitUrl }
    );
  };

  return { onRetryVirtualCardDelivery };
};
