import React from 'react';
import { Flex, Text } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { useBreak } from 'src/hoc';

export const WhyINeedPosInfo = () => {
  const { isDesktop } = useBreak();
  return (
    <Flex direction="column" width="100%" gap="ds.md">
      <Flex
        direction={isDesktop ? 'row' : 'column-reverse'}
        align={isDesktop ? 'center' : 'start'}
        justify={isDesktop ? 'start' : 'center'}
        width="100%"
        gap="ds.sm"
      >
        <Text textStyle="ds.body1Semi" color="ds.gray.100" m="0">
          <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.whyPOS" />
        </Text>
        <Text
          textTransform="uppercase"
          textStyle="ds.body3Semi"
          color="ds.gray.100"
          bg="ds.yellow.200"
          px="ds.sm"
          py="ds.xs"
          borderRadius="ds.md"
          width="fit-content"
          m="0"
        >
          <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.posNote" />
        </Text>
      </Flex>
      <Text textStyle="ds.body2" color="ds.gray.100" m="0">
        <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.descriptionPOS" />
      </Text>
    </Flex>
  );
};
