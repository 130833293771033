import React from 'react';
import { RecordOf } from 'immutable';
import { DELIVERY_TYPE, PAYMENT_APPROVAL_STATUS } from 'src/utils/consts';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { PaymentInfoHint } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoHint';

type DeliveryDateInfoHintProps = {
  payment: PaymentType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: RecordOf<AccountType>;
};

const DeliveryDateInfoHint = ({ payment, deliveryMethod, fundingSource }: DeliveryDateInfoHintProps) => {
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnitaleral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  let hint;
  const isBlockedForProcessing = isApprovalRequired || isMicrodeposit || isUnitaleral;
  if (isApprovalRequired && isUnitaleral) {
    hint = 'viewBillPaymentActivity.deliveryDate.unilateralApprovalRequiredHint';
  } else if (isUnitaleral) {
    hint = 'viewBillPaymentActivity.deliveryDate.unilateralHint';
  } else if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH && !isBlockedForProcessing) {
    hint = 'viewBillPaymentActivity.deliveryDate.achHint';
  }

  if (hint) {
    return <PaymentInfoHint hint={hint} />;
  }

  return null;
};

export default DeliveryDateInfoHint;
