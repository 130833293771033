import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Flex, Image, Text } from '@melio/billpay-design-system';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import virtualCardExample from 'src/images/virtual-card/virtual-card-example-p2d.png';
import {
  DesktopSubmitContainer,
  MobileSubmitContainer,
  SubmitHintText,
} from 'src/pages/vendor/shift-vendor-to-debit/components/AddDebitCardPage';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { useBreak } from 'src/hoc';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { AdvantagesList, PageTitle } from '../components/LearnMoreAboutVirtualCardPage';
import locations from '../locations';

type Props = {
  token: string;
};

export const LearnMoreAboutVirtualCardPage = ({ token }: Props) => {
  const history = useHistory();
  const handleReceiveVirtualCard = () => history.push(generatePath(locations.addVirtualCard, { token }));

  const handleGoBack = () => history.goBack();

  const { isDesktop } = useBreak();

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'learn-more-about-virtual-card');
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack={isDesktop}>
        <Flex direction="column" gap="ds.xl" mb="ds.2xl">
          <Image src={virtualCardExample} w="100%" />
          <PageTitle />
          <AdvantagesList />
        </Flex>
        <DesktopSubmitContainer>
          <MIButton
            testId="p2d-learn-more-receive-virtual-card-button"
            label="vendor.pushToDebit.virtualCard.learnMore.submit"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={handleReceiveVirtualCard}
          />
          <SubmitHintText label="vendor.pushToDebit.virtualCard.learnMore.submitHint" />
        </DesktopSubmitContainer>
        {!isDesktop && (
          <Text textStyle="ds.body2" color="ds.gray.200" m="0">
            <MIFormattedText label="vendor.pushToDebit.virtualCard.learnMore.submitHintMobile" />
          </Text>
        )}
      </VendorFlowContentCard>
      <Footer />
      <MobileSubmitContainer>
        <MIButton
          label="vendor.pushToDebit.virtualCard.learnMore.submit"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={handleReceiveVirtualCard}
          fullWidth
        />
        <MIButton
          label="vendor.pushToDebit.virtualCard.learnMore.back"
          variant={CONSTS.BUTTON_VARIANT.SECONDARY}
          onClick={handleGoBack}
          fullWidth
        />
      </MobileSubmitContainer>
    </VendorFlowLayout>
  );
};
