import React from 'react';
import styled from 'styled-components';

type EditElementProps = {
  onEdit?: (() => void) | null;
};

export const EditElement = ({ onEdit }: EditElementProps) => {
  if (!onEdit) {
    return null;
  }

  return <EditLink onClick={onEdit} />;
};

const EditLink = styled.i.attrs({ className: 'icon-edit-icon' })`
  font-size: 2.4rem;
  color: #8d9096;
  margin-right: 0.4rem;
  cursor: pointer;
  ${(props) => props.theme?.components?.PaymentActivity?.EditLink}
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoContainer}
`;
