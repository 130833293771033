import React from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/modules/bills/bills-store';
import paymentStore from 'src/modules/payments/payment-store';
import { MIFormattedCurrency } from 'src/utils/formatting';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';

type Props = {
  item: DashboardListItemType;
};

export const DashboardAmountCell = ({ item }: Props) => {
  // TODO: dashboard - fix in terms of https://linear.app/meliopayments/issue/QBDT-506/web-adapt-table-for-bulk-payments
  const bill = useSelector(billsStore.selectors.byId(item.billIds[0]));
  const payment = useSelector(paymentStore.selectors.byId(item.paymentId));
  const amount = payment ? payment.amount : bill.totalAmount;

  return <MIFormattedCurrency value={Number(amount)} currency="$" />;
};
