import React from 'react';
import { generatePath } from 'react-router-dom';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { CONSTS } from 'src/utils/consts';
import { AccountType, NavigateType, UserContextType } from 'src/utils/types';
import { GlobalState } from 'src/redux/types';
import { withNavigator } from 'src/hoc';
import analytics from 'src/services/analytics';
import { getProfile, getFundingSources, getIsLoading, getIsFundingSourceDeleting } from 'src/redux/user/selectors';
import { getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { microDepositsEventActions } from 'src/components/micro-deposits/consts';
import { trackMicroDepositsEvent } from 'src/hooks/settings/microDepositsCommon';
import { deleteFundingSourceAction } from 'src/redux/user/actions';
import PaymentMethodsSettingsPage from './components/PaymentMethodsSettingsPage';
import locations from '../locations';
import { InitialAddFundingSourceLocationState } from '../funding-source/types';

const eventPage = 'settings-payment-methods';
const eventLocation = 'settings';

export type DeleteFundingSourceErrorData = {
  payments?: number[];
  failedPayments?: number[];
} | null;

type State = {
  deletingId: number | null;
  errorCode: string;
  errorData: DeleteFundingSourceErrorData;
  verifyingId: number | null;
};

type MapStateToProps = {
  fundingSources: RecordOf<AccountType>[];
  isLoading: boolean;
  isFundingSourceDeleting: boolean;
  profile: RecordOf<UserContextType>;
  payBillSelectedFundingSource?: AccountType | null;
};

type MapDispatchToProps = {
  deleteFundingSource: (id: number) => Promise<void>;
};

type Props = {
  navigate: NavigateType;
  params: Record<string, string>;
  query: {
    verifyFundingSourceId?: string;
  };
} & MapStateToProps &
  MapDispatchToProps;

class PaymentMethodsSettingsPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deletingId: null,
      verifyingId: null,
      errorCode: '',
      errorData: null,
    };
  }

  componentDidMount = () => {
    const { query, fundingSources } = this.props;
    const verifyingId = Number(query.verifyFundingSourceId);
    const fundingSourceToVerify = fundingSources.find((fs) => fs.id === verifyingId);

    if (fundingSourceToVerify?.bankAccount?.canVerify) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ verifyingId });
    }
  };

  onDeleteClicked = (id: number) => {
    const { payBillSelectedFundingSource } = this.props;
    const isPayBillSelectedFundingSource = id === payBillSelectedFundingSource?.id;

    analytics.track(eventPage, 'DeletePaymentMethod-Selected', {
      location: eventLocation,
      paymentMethodId: id,
      isPaymentMethodSelected: isPayBillSelectedFundingSource,
    });

    if (isPayBillSelectedFundingSource) {
      this.setState({ errorCode: 'RSC03.1' });
    } else {
      this.setState({ deletingId: id });
    }
  };

  onDeletePaymentMethodCanceled = () => {
    this.setState({ deletingId: null });
  };

  onDeleteFailedError = () => {
    this.setState({ errorCode: '', errorData: null });
  };

  onDeleteConfirmed = () => {
    const { deletingId } = this.state;
    const { deleteFundingSource } = this.props;

    if (deletingId) {
      deleteFundingSource(deletingId)
        .then(() => {
          this.setState({ deletingId: null });

          analytics.track(eventPage, 'DeletePaymentMethod-Deleted', {
            location: eventLocation,
            paymentMethodId: deletingId,
            isSuccess: true,
          });
        })
        .catch((error) => {
          this.setState({
            deletingId: null,
            errorCode: error && error.code ? error.code : '',
            errorData: error?.responseData,
          });

          analytics.track(eventPage, 'DeletePaymentMethod-Deleted', {
            location: eventLocation,
            paymentMethodId: deletingId,
            isSuccess: false,
          });
        });
    }
  };

  onVerifyClicked = (id: number) => {
    trackMicroDepositsEvent(eventPage, microDepositsEventActions.VERIFY_CLICK, {
      fundingSourceID: id,
    });

    this.setState({ verifyingId: id });
  };

  onVerifyFinished = () => {
    this.setState({
      verifyingId: null,
    });
  };

  goAddFundingSources = (type: string) => {
    const { params } = this.props;
    if (type === CONSTS.FUNDING_TYPE.ACH) {
      analytics.track(eventPage, 'add-bank-account');
      this.props.navigate(generatePath(locations.fundingSource.bankSelect, params), false, {
        redirectUrl: generatePath(locations.settings.paymentMethods, params),
        exitUrl: generatePath(locations.settings.paymentMethods, params),
        preservedState: {
          origin: CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.SETTINGS,
        },
      });
    } else {
      analytics.track(eventPage, `add-${type}-card`);
      const locationState: InitialAddFundingSourceLocationState = {
        redirectUrl: generatePath(locations.settings.paymentMethods, params),
        exitUrl: generatePath(locations.settings.paymentMethods, params),
      };
      this.props.navigate(generatePath(locations.fundingSource.card, params), false, locationState);
    }
  };

  goEditFundingSource = (fundingSource: RecordOf<AccountType>) => {
    const { navigate, params } = this.props;
    analytics.track(
      eventPage,
      fundingSource.fundingType === CONSTS.FUNDING_TYPE.ACH ? 'plaid-edit-start' : 'card-edit-start'
    );

    navigate(generatePath(locations.fundingSource.linkAccount, params), false, {
      redirectUrl: generatePath(locations.settings.paymentMethods, params),
      exitUrl: generatePath(locations.settings.paymentMethods, params),
      fundingSourceId: fundingSource.id,
      editMode: true,
    });
  };

  goViewFundingSource = (fundingSource: RecordOf<AccountType>) => {
    analytics.track(eventPage, 'manual-view-start');
    const { navigate, params } = this.props;
    navigate(
      generatePath(locations.fundingSource.bankManualView, {
        ...params,
        id: fundingSource.id,
      })
    );
  };

  modifyActions = (fs) => ({
    view: () => this.goViewFundingSource(fs),
    edit: () => this.goEditFundingSource(fs),
    delete: () => this.onDeleteClicked(fs.id),
  });

  render() {
    const { fundingSources, isLoading, isFundingSourceDeleting } = this.props;
    const { deletingId, errorCode, verifyingId, errorData } = this.state;
    const fundingSourceToDelete = fundingSources.filter((fs) => fs.id === deletingId)[0];

    return (
      <PaymentMethodsSettingsPage
        onDeleteConfirmed={this.onDeleteConfirmed}
        onDeleteFailedError={this.onDeleteFailedError}
        onDeletePaymentMethodCanceled={this.onDeletePaymentMethodCanceled}
        goAddFundingSources={this.goAddFundingSources}
        isSingleLoading={isLoading || isFundingSourceDeleting}
        errorCode={errorCode}
        errorData={errorData}
        deletingId={deletingId}
        fundingSourceToDelete={fundingSourceToDelete}
        fundingSources={fundingSources}
        onVerifyClicked={this.onVerifyClicked}
        onVerifyFinished={this.onVerifyFinished}
        verifyingId={verifyingId}
        modifyActions={this.modifyActions}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  fundingSources: getFundingSources(state),
  isLoading: getIsLoading(state),
  isFundingSourceDeleting: getIsFundingSourceDeleting(state),
  profile: getProfile(state),
  payBillSelectedFundingSource: getSelectedFundingSource(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  deleteFundingSource(id: number) {
    return new Promise((resolve, reject) => {
      dispatch(deleteFundingSourceAction(id, resolve, reject));
    });
  },
});

export default compose(
  withNavigator(),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentMethodsSettingsPageContainer);
