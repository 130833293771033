import { deleteRequest, fetchRequest, postRequest, putRequest } from './api';

// IMPORTANT NOTE:
// since we use fetch fundingSource only once, when app is started
// please don't forget to refresh them when you delete/adde/update them

export default {
  addPlaidAccount(orgId, params) {
    const url = `/orgs/${orgId}/funding-sources/plaid/add-accounts`;
    return postRequest(url, params);
  },

  getFundingSources(orgId, params) {
    const url = `/orgs/${orgId}/funding-sources`;
    return fetchRequest(url, params);
  },
  /**
   * @deprecated
   */
  addCardAccount(orgId, params, options) {
    const url = `/orgs/${orgId}/funding-sources/card/add-accounts`;
    return postRequest(url, params, {}, options);
  },

  setIntuitAccountId(orgId, id, params) {
    const url = `/orgs/${orgId}/funding-sources/${id}/intuit-account-id`;
    return putRequest(url, params);
  },

  deleteFundingSource(orgId, id) {
    const url = `/orgs/${orgId}/funding-sources/${id}`;
    return deleteRequest(url);
  },

  requestMicroDeposit(orgId, params) {
    const url = `/orgs/${orgId}/funding-sources/micro-deposit`;
    return postRequest(url, params);
  },

  verifyMicroDeposits(orgId, params) {
    const url = `/orgs/${orgId}/funding-sources/verify-micro-deposit`;
    return postRequest(url, params);
  },
  getVerificationStatus(orgId, fundingSourceId, params) {
    const url = `/orgs/${orgId}/funding-sources/${fundingSourceId}/verification`;
    return fetchRequest(url, params);
  },
  verifyMicroDepositsWithToken(params) {
    const url = '/funding-source/verify-micro-deposit';
    return postRequest(url, params);
  },
  getMicroDepositsStatusWithToken(params) {
    const url = '/funding-source/micro-deposit-verification-status';
    return fetchRequest(url, params);
  },
  /**
   * @deprecated
   */
  checkCard(orgId, params) {
    const url = `/orgs/${orgId}/funding-sources/card/check`;
    return postRequest(url, params, {});
  },
  createPlaidLinkToken(orgId, params) {
    const url = `/orgs/${orgId}/funding-sources/plaid/create-link-token`;

    return postRequest(url, params);
  },
  validateCard(
    orgId: number,
    params: { token: string; cardBin: number | string; cardNumberFingerprint: string; cardNumberToken: string }
  ) {
    const { cardNumberFingerprint } = params;
    const url = `/orgs/${orgId}/funding-sources/card/validate`;

    return postRequest(url, params, {
      headers: {
        'cardnumber-fingerprint': cardNumberFingerprint,
      },
    });
  },
  createCardAccount(orgId: number, params, options) {
    const url = `/orgs/${orgId}/funding-sources/card/create-account`;

    return postRequest(url, params, {}, options);
  },
};
