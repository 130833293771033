import React from 'react';
import styled from 'styled-components';

type Props = {
  email: string;
  className?: string;
};

const EmailLink = ({ email, className }: Props) => (
  <EmailLinkContainer href={`mailto:${email}`} target="_blank" className={className}>
    {email}
  </EmailLinkContainer>
);

export default EmailLink;

const EmailLinkContainer = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;
