import { EventMappingType } from 'src/services/analytics/types';
import { allProperties } from 'src/billpay/qbdt/pages/batch-pay/analytics/event-mapping';

const PAGE_EVENT = 'regular-batch-payments-inbox-selection';

const MAPPING: Record<any, EventMappingType> = {
  'BatchSettings.button.schedule': [
    PAGE_EVENT,
    'schedule_batch_payments_click',
    {
      ...allProperties,
      optionalBillsIds: 'optionalBillsIds',
      optionalBillsCount: 'optionalBillsCount',
      optionalBillsAmount: 'optionalBillsAmount',
    },
  ],
  'regularBatchPayments.selection.selectAll': [PAGE_EVENT, 'batch_payments_select_all'],
};

export default MAPPING;
