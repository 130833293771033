import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getIsOrganizationPreferencesUpdating } from 'src/redux/organization/selectors';
import { updateOrganizationPreferenceAction } from 'src/redux/organization/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { OrganizationPreferencesType } from 'src/utils/types';

type UpdateOrganizationPreferenceParams = {
  orgId?: string;
  key: keyof OrganizationPreferencesType;
  value: string;
};

const useUpdateOrganizationPreference = () => {
  const currentOrgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const isUpdatingOrganizationPreference = useSelector(getIsOrganizationPreferencesUpdating);

  const updateOrganizationPreference = useCallback(
    async ({ key, value, orgId }: UpdateOrganizationPreferenceParams) =>
      new Promise<void>((resolve, reject) =>
        dispatch(updateOrganizationPreferenceAction(orgId || currentOrgId, key, value, resolve, reject))
      ),
    [currentOrgId, dispatch]
  );

  return { updateOrganizationPreference, isUpdatingOrganizationPreference };
};

export default useUpdateOrganizationPreference;
