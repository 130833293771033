import React from 'react';
import styled, { css } from 'styled-components';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  totalAmount?: string;
  viewOnly?: boolean;
  autoFocus?: boolean;
};

class AmountInput extends React.PureComponent<Props> {
  static defaultProps = {
    validationErrors: {},
    autoFocus: true,
  };

  render() {
    const { totalAmount, viewOnly } = this.props;
    const totalAmountValue =
      viewOnly && totalAmount ? parseFloat(convertCurrencyToNumber(totalAmount)).toFixed(2) : totalAmount;
    return (
      <AmountContainer>
        <AmountInputContainer className={FULL_STORY_MASK_RULE_CLASS}>
          <CurrencySign>{CONSTS.CURRENCY_SIGN.USD}</CurrencySign>
          <AmountViewOnly data-testid="request-total-amount">{totalAmountValue}</AmountViewOnly>
        </AmountInputContainer>
      </AmountContainer>
    );
  }
}

const fontSizeStyles = css`
  font-size: 4.2rem;
`;

const placeholderStyles = css`
  ${fontSizeStyles}
  color: ${(props) => props.theme.text.color.main};
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AmountInputContainer = styled.div`
  display: flex;
  justify-content: center;

  .input-container {
    width: inherit;
    margin-bottom: 0;
  }

  #totalAmount {
    ${fontSizeStyles}
    text-align: left;
    border: 0;
    height: 6.5rem;
    line-height: 6.5rem;
    max-width: 40rem;

    &::placeholder {
      ${placeholderStyles}
    }

    &::-moz-placeholder {
      ${placeholderStyles}
      opacity: 1;
    }
  }
`;

const AmountViewOnly = styled.span`
  text-align: left;
  border: 0;
  height: auto;
  line-height: 4.2rem;
  font-size: 3.2rem;
  max-width: 40rem;
  color: ${(props) => props.theme.text.color.main};
`;

const CurrencySign = styled.span`
  font-size: 3.2rem;
  margin-top: 0.3rem;
  margin-right: 0.2rem;
  color: ${(props) => props.theme.text.color.main};
`;

export default AmountInput;
