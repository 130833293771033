import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, VStack } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';
import analytics from 'src/services/analytics';
import { useHistory } from 'react-router-dom';
import errorIcon from 'src/images/qbo/error-icon.png';
import ErrorPage from 'src/components/layout/ErrorLayoutPage';
import * as qbdtService from 'src/billpay/qbdt/services/qbdt';
import { QBDTStepLayout } from 'src/billpay/qbdt/components/QBDTStepLayout';
import { BillPaymentInfoCard } from 'src/billpay/qbdt/pages/view/BillPaymentInfoCard/BillPaymentInfoCard';
import { BillType, PaymentType } from 'src/utils/types';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { ViewPaymentActions } from './ViewPaymentActions/ViewPaymentActions';
import { ViewPaymentProvider } from './context/ViewPaymentContext';
import { useLoadPayment } from './hooks/useLoadPayment';
import { dashboardStore } from '../dashboard/redux-store/dashboard-store';
import { QBDTLoader } from '../../components/QBDTLoader';
import { ViewPaymentHeading } from './components/ViewPaymentHeading';

type Props = {
  billId: string;
  paymentId: string;
};

const eventPage = 'qbdt-single-bill';

export const ViewPaymentPage = ({ billId, paymentId }: Props) => {
  const [isActionLoading, setIsActionLoading] = useState(false);
  const history = useHistory();
  const dashboardActions = useStoreActions(dashboardStore);
  const exitUrl = useSelector(dashboardStore.selectors.viewPaymentUrls.getExitUrl);

  const { payment, errorCode, loading: isPaymentLoading } = useLoadPayment({
    paymentId,
    eventPage,
  });

  const loading = isActionLoading || isPaymentLoading;
  const initializing = !payment?.id || !payment?.bills?.some((bill) => bill.id.toString() === billId);

  const handleExitClick = () => {
    analytics.track(eventPage, 'close-integration');

    if (exitUrl) {
      dashboardActions.viewPaymentUrls.setExitUrl(undefined);
      history.push(exitUrl);
      return;
    }

    qbdtService.close();
  };

  if (errorCode) {
    return (
      <ErrorPage
        illustration={errorIcon}
        title="bills.view.error.titleNotFound"
        subtitle="bills.view.error.subtitleNotFound"
        buttonAction={handleExitClick}
        buttonLabel="bills.view.error.buttonTextNotFound"
      />
    );
  }

  if (initializing) {
    return <QBDTLoader />;
  }

  const bill = payment?.bills?.find((bill) => bill.id.toString() === billId) as BillType;

  return (
    <ViewPaymentProvider
      value={{
        bill: bill as BillType,
        payment: payment as PaymentType,
        eventPage,
        onActionLoadStart: () => {
          setIsActionLoading(true);
        },
        onActionLoadEnd: () => {
          setIsActionLoading(false);
        },
      }}
    >
      <QBDTStepLayout goExit={handleExitClick} isLoading={false} innerSize={100}>
        {loading && <QBDTLoader />}
        {!loading && (
          <ViewPaymentContainer>
            <ViewPaymentContent spacing="3rem">
              <ViewPaymentHeading />
              <BillPaymentInfoCard />
              <ViewPaymentActions />
            </ViewPaymentContent>
          </ViewPaymentContainer>
        )}
      </QBDTStepLayout>
    </ViewPaymentProvider>
  );
};

const ViewPaymentContainer = chakra(Flex, {
  baseStyle: {
    flexDir: 'column',
    alignItems: 'center',
  },
});

const ViewPaymentContent = chakra(VStack, {
  baseStyle: {
    maxW: '45rem',
    w: '50%',
    flexGrow: '1',
    justifyContent: 'space-around',
    my: 'ds.xl',
    alignItems: 'initial',
  },
});
