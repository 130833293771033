import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { MIFormattedText } from 'src/utils/formatting';
import { isSameDay, isTomorrow, DeliveryDateFormat } from 'src/utils/dates';
import { EXPEDITED_DELIVERY_TYPES, FastACHDeliveryType, FastCheckDeliveryType } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';
import { FeatureFlags } from 'src/utils/feature-flags';
import { getEstimatedDeliverySpeedLabel } from 'src/utils/delivery-speed';
import { PaymentDeliveryOptionPrice } from './PaymentDeliveryOptionPrice';

type Props = {
  deliveryOption: DeliveryOptionType;
  totalAmount: number;
  fundingSourceType?: string;
  freeChecksAvailableCount: string | undefined;
};

const PaymentDeliveryOptionDescription = ({
  deliveryOption,
  fundingSourceType,
  freeChecksAvailableCount,
  totalAmount,
}: Props) => {
  const [isUpdatedDeliveryBlock] = featureFlags.useFeature(FeatureFlags.DatePickerDeliverySpeedChanges, false);

  const isCheck = deliveryOption.type.includes('check');
  const isFastCheck = deliveryOption.type === FastCheckDeliveryType.EXPRESS;

  const renderLegacyDescription = () => {
    const deliveryExplanationLabel = isCheck
      ? 'bills.pay.date.deliveryOptions.deliveryExplanationCheck'
      : 'bills.pay.date.deliveryOptions.deliveryExplanation';

    if (EXPEDITED_DELIVERY_TYPES.includes(deliveryOption.type) && isSameDay(deliveryOption.deliveryDate)) {
      return <MIFormattedText label="bills.pay.date.deliveryOptions.sameDayDeliveryExplanation" />;
    }

    return (
      <Box as="span">
        <MIFormattedText
          label={deliveryExplanationLabel}
          values={{
            date: <DeliveryDateFormat date={deliveryOption.deliveryDate} maxDate={deliveryOption.maxDeliveryDate} />,
            indication: isFastCheck && (
              <Box as="span" fontWeight="ds.semi" color="ds.gray.200">
                <MIFormattedText label="bills.pay.date.deliveryOptions.deliveryExplanationCheckIndication" />
              </Box>
            ),
          }}
        />
      </Box>
    );
  };

  const renderDescription = () => {
    if (isSameDay(deliveryOption.deliveryDate)) {
      return <MIFormattedText label="bills.pay.date.deliveryOptions.description.sameDay" />;
    }

    if (isTomorrow(deliveryOption.deliveryDate)) {
      return <MIFormattedText label="bills.pay.date.deliveryOptions.description.nextDay" />;
    }

    if (deliveryOption.type === FastACHDeliveryType.EXPEDITED) {
      return <MIFormattedText label="bills.pay.date.deliveryOptions.description.sameDay" />;
    }

    const { label } = getEstimatedDeliverySpeedLabel({
      deliveryType: deliveryOption.type,
      fundingSourceType,
      paymentAmount: totalAmount,
    });

    return <MIFormattedText label={label} />;
  };

  return (
    <Box textStyle="ds.body3" mt="0.2rem" color="ds.gray.200" maxW="27.8rem">
      {isUpdatedDeliveryBlock ? renderDescription() : renderLegacyDescription()}
      <PaymentDeliveryOptionPrice deliveryOption={deliveryOption} freeChecksAvailableCount={freeChecksAvailableCount} />
    </Box>
  );
};

export { PaymentDeliveryOptionDescription };
