/* eslint-disable global-require,@typescript-eslint/no-var-requires */
import merge from 'lodash/merge';
import defaults from './default';

let env = {};
if (process.env.REACT_APP_ENV === 'production') {
  env = require('./production').default;
} else if (process.env.REACT_APP_ENV === 'staging') {
  env = require('./staging').default;
} else if (process.env.REACT_APP_ENV === 'alpha') {
  env = require('./alpha').default;
} else if (process.env.REACT_APP_ENV === 'alpha-e2e') {
  env = require('./alpha-e2e').default;
} else if (process.env.REACT_APP_ENV === 'development') {
  env = require('./development').default;
} else if (process.env.REACT_APP_ENV === 'development-e2e') {
  env = require('./development-e2e').default;
} else {
  try {
    /* eslint-disable-next-line import/no-unresolved */
    env = require('./local').default;
  } catch (e) {
    throw new Error('Please specify local.js file. Take a look at local.example.js');
  }
}

const { qbo, qbdt, ...defaultConfig } = merge({}, defaults, env, (window as any).dynEnvConfig);

const config = merge({}, defaultConfig);

export default config;
export { qbo, qbdt };
