import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import paymentStore from 'src/modules/payments/payment-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import paymentsApi from 'src/services/api/payments';
import { useApi } from 'src/hoc/useApi';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { DELIVERY_TYPE, PAYMENT_STATUS, VirtualCardStatus } from 'src/utils/consts';
import { PaymentType, VendorType, VirtualCardType, DeliveryMethodType, OrganizationType } from 'src/utils/types';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';

type Props = {
  token: string;
  virtualCardId: string;
  onInvalidToken: VoidFunction;
};

type Response = {
  virtualCardPaymentState: VirtualCardPaymentState;
  vendor?: VendorType;
  payment?: PaymentType;
  organization?: OrganizationType;
  virtualCard?: VirtualCardType;
  deliveryMethod?: DeliveryMethodType;
  isPaymentLoading?: boolean;
  issueNewCard: VoidFunction;
};

enum VirtualCardPaymentState {
  Unknown = 'UNKNOWN',
  Valid = 'VALID',
  Cancelled = 'CANCELLED',
  Processed = 'PROCESSED',
  NewDeliveryMethod = 'NEW_DELIVERY_METHOD',
  NewDeliveryMethodProcessed = 'NEW_DELIVERY_METHOD_PROCESSED',
  NewDeliveryMethodScheduled = 'NewDeliveryMethodScheduled',
  Expired = 'EXPIRED',
  Recovered = 'RECOVERED',
  RecoverySuccess = 'RECOVERY_SUCCESS',
  Notification = 'NOTIFICATION',
}

const useVirtualCardData = ({ token, virtualCardId, onInvalidToken }: Props): Response => {
  const { paymentId } = getJWTPayload(token);

  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(payment?.vendor?.id));
  const organization: OrganizationType = useSelector(organizationStore.selectors.byId(payment?.organizationId));
  const deliveryMethod = vendor?.deliveryMethods?.find((dm) => dm.id === payment?.deliveryMethod?.id);
  const actualVirtualCard = payment?.lastCreatedVirtualCard;

  const { fetchPaymentDetailsWithToken, retryFailedToDeliverWithToken } = useStoreActions(paymentStore);

  const [virtualCard, setVirtualCard] = useState<VirtualCardType>();
  const [virtualCardPaymentState, setVirtualCardPaymentState] = useState<VirtualCardPaymentState>(
    VirtualCardPaymentState.Unknown
  );

  const [getVirtualCardWithToken] = useApi(paymentsApi.getVirtualCardData);

  useEffect(() => {
    const fetchData = async () => {
      const requestPaymentDetails = fetchPaymentDetailsWithToken({
        token,
        action: 'readVirtualCardDetails',
      });

      const requestVirtualCardDetails = getVirtualCardWithToken({
        token,
        virtualCardId,
      });

      try {
        const [{ virtualCard }] = await Promise.all([requestVirtualCardDetails, requestPaymentDetails]);
        setVirtualCard(virtualCard);
      } catch {
        onInvalidToken();
      }
    };

    fetchData();
  }, [token, virtualCardId, onInvalidToken, getVirtualCardWithToken]);

  useEffect(() => {
    if (payment && virtualCard) {
      if (payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
        if (payment.status === PAYMENT_STATUS.FAILED && virtualCard.status === VirtualCardStatus.CANCELED) {
          setVirtualCardPaymentState(VirtualCardPaymentState.Cancelled);

          return;
        }

        if (payment.status === PAYMENT_STATUS.FAILED && virtualCard.status === VirtualCardStatus.EXPIRED) {
          setVirtualCardPaymentState(VirtualCardPaymentState.Expired);

          return;
        }

        if (payment.status === PAYMENT_STATUS.IN_PROGRESS && virtualCard.status === VirtualCardStatus.EXPIRED) {
          if (actualVirtualCard && actualVirtualCard.id !== virtualCard.id) {
            setVirtualCardPaymentState(VirtualCardPaymentState.Recovered);
          } else {
            setVirtualCardPaymentState(VirtualCardPaymentState.RecoverySuccess);
          }

          return;
        }

        if (virtualCard.status === VirtualCardStatus.POSTED || virtualCard.status === VirtualCardStatus.AUTHORIZED) {
          setVirtualCardPaymentState(VirtualCardPaymentState.Processed);

          return;
        }

        setVirtualCardPaymentState(VirtualCardPaymentState.Valid);

        return;
      }

      setVirtualCardPaymentState(
        payment.status === PAYMENT_STATUS.COMPLETED
          ? VirtualCardPaymentState.NewDeliveryMethodProcessed
          : VirtualCardPaymentState.NewDeliveryMethod
      );
    }
  }, [payment, virtualCard]);

  const issueNewCard = () =>
    retryFailedToDeliverWithToken({ token }).catch(() => {
      onInvalidToken();
    });

  return {
    vendor,
    payment,
    organization,
    virtualCard,
    deliveryMethod,
    virtualCardPaymentState,
    isPaymentLoading,
    issueNewCard,
  };
};

export { useVirtualCardData, VirtualCardPaymentState };
