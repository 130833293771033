import React from 'react';
import { Box, Flex, Icon, Menu, Text, Tooltip } from '@melio/billpay-design-system';
import { Portal } from '@chakra-ui/react';
import analytics from 'src/services/analytics';
import { useDisclosure } from 'src/core/ds/hooks';
import BillDetailsModal from 'src/billpay/qbdt/pages/batch-pay/components/dialog/BillDetailsModal';
import { MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as ThreeDotsIcon } from 'src/images/regular-batch-payments/three-dots.svg';
import { ReactComponent as PreviewIcon } from 'src/images/regular-batch-payments/preview.svg';
import { ReactComponent as RemoveIcon } from 'src/images/regular-batch-payments/remove.svg';
import { BatchBillType } from 'src/modules/regular-batch-payments/types/store-types';
import { useRemoveBillSubRow } from 'src/billpay/qbdt/pages/batch-pay/hooks/useRemoveSubRow';

type Props = {
  rowHovered: boolean;
  row: BatchBillType;
};

export const SubActionsCell = ({ row }: Props) => {
  const { vendor, id, balance } = row;
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'sub-bill-details',
  });

  const { removeSubRow } = useRemoveBillSubRow(id, balance);
  const stopPropagation = (event) => event.stopPropagation();

  const onViewBillDetailsClick = () => {
    onOpen();

    analytics.trackAction('regularBatchPayments.cells.actions.viewBillDetails', {
      billId: id,
      vendorId: vendor.id,
    });
  };

  return (
    <Flex
      onClick={stopPropagation}
      justify="center"
      align="center"
      sx={{
        '& > span:focus': {
          outline: 'none',
        },
      }}
    >
      {isOpen && (
        <BillDetailsModal
          bill={row}
          vendorName={vendor.companyName}
          onClose={onClose}
          analyticsProperties={{
            billId: row.id,
            vendorId: vendor.id,
          }}
        />
      )}
      <Menu placement="bottom-start" size="sm" autoSelect={false} autoWidth>
        <Tooltip
          placement="top-start"
          shouldWrapChildren
          label={<MIFormattedText label="regularBatchPayments.cells.actions.menuTooltip" />}
        >
          <Menu.MenuButton
            as={Flex}
            alignItems="center"
            justifyContent="center"
            sx={{
              '& > span': {
                height: 'ds.lg',
              },
            }}
          >
            <Icon as={ThreeDotsIcon} h="ds.lg" />
          </Menu.MenuButton>
        </Tooltip>
        <Portal>
          <Menu.MenuList minWidth="auto" data-testid={`actions-menu-list-${id}`}>
            <Menu.MenuItem
              as={Box}
              onClick={onViewBillDetailsClick}
              w="auto"
              data-testid={`bulk-row-view-menu-item_${id}`}
            >
              <Icon as={PreviewIcon} h="ds.lg" w="ds.lg" />
              <Text ml="ds.md">
                <MIFormattedText label="regularBatchPayments.cells.actions.viewBillDetails" />
              </Text>
            </Menu.MenuItem>
            <Menu.MenuItem as={Box} onClick={removeSubRow} w="auto" data-testid={`bulk-row-remove-menu-item_${id}`}>
              <Icon as={RemoveIcon} h="ds.lg" w="ds.lg" />
              <Text ml="ds.md">
                <MIFormattedText label="regularBatchPayments.cells.actions.removeFromList" />
              </Text>
            </Menu.MenuItem>
          </Menu.MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};
