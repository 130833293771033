import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardColumnsEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import { DashboardUnpaidActionCell } from '../actions-cells/actions-unpaid/DashboardUnpaidActionCell';
import { DashboardAmountCell } from '../common-cells/DashboardAmountCell';
import { DashboardDueDateCell } from '../common-cells/DashboardDueDateCell';
import { DashboardInvoiceNumberCell } from '../common-cells/DashboardInvoiceNumberCell';
import { DashboardVendorNameCell } from '../common-cells/DashboardVendorNameCell/DashboardVendorNameCell';
import { DashboardUnpaidStatusCell } from './DashboardUnpaidStatusCell';
import { ColumnItem } from '../../types';

type ResponseType = [ColumnItem<DashboardListItemType>[]];

export function useUnpaidColumns(): ResponseType {
  const unpaidColumns: ColumnItem<DashboardListItemType>[] = [
    {
      Header: <MIFormattedText label="paymentDashboard.columns.vendor.title" />,
      accessor: DashboardColumnsEnum.VendorName,
      Cell: DashboardVendorNameCell,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.invoiceNumber.title" />,
      accessor: DashboardColumnsEnum.InvoiceNumber,
      Cell: DashboardInvoiceNumberCell,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.dueDate.title" />,
      accessor: DashboardColumnsEnum.DueDate,
      Cell: DashboardDueDateCell,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.unpaidStatus.title" />,
      accessor: DashboardColumnsEnum.Status,
      Cell: DashboardUnpaidStatusCell,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.amount.title" />,
      accessor: DashboardColumnsEnum.TotalAmount,
      Cell: DashboardAmountCell,
      isNumeric: true,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.actions.title" />,
      accessor: DashboardColumnsEnum.Actions,
      width: '18rem',
      Cell: DashboardUnpaidActionCell,
      isNumeric: true,
      isOrderingDisabled: true,
    },
  ];

  return [unpaidColumns];
}
