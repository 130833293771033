import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useQueryState } from 'src/utils/hooks';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import style from './style';

type Props = {
  item: DashboardListItemType;
};

export const DashboardVendorNameCell = ({ item }: Props) => {
  const [, setVendorId] = useQueryState<string>('vendorId');
  const vendor = useSelector(vendorsStore.selectors.byId(item.vendorId));
  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();

  const handleVendorClick = () => {
    setVendorId(item.vendorId);
  };

  return (
    <Flex>
      <Tooltip placement="right" label={vendor.companyName} isDisabled={!isTruncated}>
        <Box ref={ref} __css={style} onClick={handleVendorClick} data-testid={`vendor-name-cell-${item.id}`}>
          {vendor.companyName}
        </Box>
      </Tooltip>
    </Flex>
  );
};
