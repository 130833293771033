import React from 'react';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { useViewPaymentMenuAction } from '../actions-common/useViewPaymentMenuAction';
import { DashboardActionsCell } from '../DashboardActionsCell/DashboardActionsCell';
import { useActionCellEventProperties } from '../hooks/useActionCellEventProperties';

type Props = {
  item: DashboardListItemType;
};

export const DashboardPaidActionCell = ({ item }: Props) => {
  const { paymentId, metadata, id } = item;

  // TODO: dashboard - fix in terms of https://linear.app/meliopayments/issue/QBDT-506/web-adapt-table-for-bulk-payments
  const billId = item.billIds[0];

  const { viewPaymentMenuAction } = useViewPaymentMenuAction({
    billId,
    paymentId,
  });

  const { eventProperties } = useActionCellEventProperties({
    billId,
    paymentId,
    status: metadata.statusInDashboard,
  });

  const actions = [viewPaymentMenuAction];

  return <DashboardActionsCell actions={actions} eventProperties={eventProperties} itemId={id} />;
};
