import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataTableRowType, DataTableContentState } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import { COMBINE_BILLS_COLUMNS } from 'src/billpay/qbdt/pages/batch-pay/table/combine-bills-columns';
import DataTable from 'src/billpay/qbdt/pages/batch-pay/table/DataTable';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { ExpanderCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/ExpanderCell';

const BatchTable = () => {
  const { isBillsGroupedByVendor } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);

  const isBillsGroupingPossible = useSelector(regularBatchPaymentsStore.selectors.isBillsGroupingPossible);

  const isCombinedBillsToggleEnabled = isBillsGroupingPossible ? isBillsGroupedByVendor : false;

  const { billsList, isBillListLoading } = useBatchBillsList();

  const initialSortBy = useMemo(() => [{ id: 'vendor' }], []);

  const getRowId = useCallback((row) => row.id, []);

  const getColumns = () => [
    {
      id: 'expander',
      Cell: ExpanderCell,
      disableSortBy: true,
      maxWidth: isCombinedBillsToggleEnabled ? 30 : 8,
      minWidth: isCombinedBillsToggleEnabled ? 30 : 8,
    },
    ...COMBINE_BILLS_COLUMNS,
  ];

  return (
    <DataTable<DataTableRowType>
      name="BatchPaymentsTable"
      columns={getColumns()}
      data={billsList}
      getRowId={getRowId}
      initialState={{ sortBy: initialSortBy }}
      contentState={isBillListLoading ? DataTableContentState.LOADING : DataTableContentState.CONTENT}
      autoResetExpanded={false}
    />
  );
};

export default BatchTable;
