import * as React from 'react';
import { useModal } from 'src/helpers/react/useModal';
import { DashboardActionItemsEnum } from '../../consts';
import { CancelPaymentDialog } from './CancelPaymentDialog';

type Response = {
  cancelPaymentMenuAction;
  cancelPaymentDialog: React.ReactNode;
};

type Props = {
  companyName: string;
  totalAmount: string;
  paymentId?: string;
  billIds?: string[];
};

export const useCancelPaymentMenuAction = ({ companyName, totalAmount, paymentId, billIds }: Props): Response => {
  const cancelPaymentDialogProps = {
    companyName,
    totalAmount,
    paymentId,
    billIds,
  };

  const [cancelPaymentDialog, showCancelPaymentDialog] = useModal(CancelPaymentDialog, {
    modalName: 'cancel-payment-dialog',
    ...cancelPaymentDialogProps,
  });

  const cancelPaymentMenuAction = {
    id: DashboardActionItemsEnum.Cancel,
    onClick: showCancelPaymentDialog,
    label: 'paymentDashboard.columns.actions.cancel',
  };

  return { cancelPaymentDialog, cancelPaymentMenuAction };
};
