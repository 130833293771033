import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import { getUserPreferences } from 'src/redux/user/selectors';
import useUpdateUserPreferences from 'src/modules/users/hooks/useUpdateUserPreferences';
import { DashboardFTUMessage } from './DashboardFTUMessage';

const useDashboardFTUMessage = () => {
  const userPreferences = useSelector(getUserPreferences);
  const { updateUserPreference } = useUpdateUserPreferences();

  const [shown, setShown] = useState<boolean>(false);

  useEffect(() => {
    setShown(!userPreferences?.qbdtDashboardSeen);

    if (!userPreferences?.qbdtDashboardSeen) {
      analytics.trackAction('show-ftu-dashboard-state-modal');
    }
  }, [userPreferences]);

  const handleCloseClick = () => {
    updateUserPreference({ key: 'qbdtDashboardSeen', value: true });
    setShown(false);
  };

  const messageComponent = shown ? <DashboardFTUMessage onClose={handleCloseClick} /> : null;

  return [messageComponent];
};

export { useDashboardFTUMessage };
