import { useEffect } from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { dashboardStore } from '../../redux-store/dashboard-store';

export const useSyncBillsStart = () => {
  const dashboardActions = useStoreActions(dashboardStore);

  useEffect(() => {
    dashboardActions.syncBills.startSync();
  }, [dashboardActions.syncBills]);
};
