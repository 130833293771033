import React, { useEffect, useMemo, useState } from 'react';
import billsApi, { GetInternalBillsParams, GetInternalBillsResponse, InternalBill } from 'src/services/api/bills';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { BillingSettingsPage } from 'src/billpay/qbdt/pages/settings/billing/BillingSettingsPage';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { useQueryStateMultiValues } from 'src/utils/hooks';
import { billingFeeApi, OrganizationBillingFee } from 'src/services/api/smb-billing-fee';
import { pushNotification } from 'src/services/notifications/notificationService';
import { NOTIFICATION_VARIANT } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import { ReceiptStatus } from './components/consts';
import { BILLING_ITEMS_DEFAULT_SEARCH_PARAMS } from './consts';

const eventPage = 'settings-billing';

export const BillingSettingsPageContainer = () => {
  const [internalBillsStatuses, setInternalBillsStatuses] = useState<Record<string, ReceiptStatus>>({});

  const [getOrganizationBillingFees, result, isFetchingOrgBillingFee] = useApi<
    [{ orgId: number }],
    { organizationBillingFees: OrganizationBillingFee[] }
  >(billingFeeApi.getBillingFees, true, true);

  const [loadInternalBillsApi, internalBillsResponse, isLoadingInternalBills] = useApi<
    [GetInternalBillsParams],
    GetInternalBillsResponse
  >(billsApi.getInternalBills, true, true);

  const orgId = useOrgId();

  const [queryFilters, setFilters] = useQueryStateMultiValues<{
    start: string;
    limit: string;
  }>(BILLING_ITEMS_DEFAULT_SEARCH_PARAMS);
  const { start, limit } = queryFilters;

  const filters = useMemo(
    () => ({
      start: parseInt(start, 10),
      limit: parseInt(limit, 10),
    }),
    [start, limit]
  );

  const setPage = (page: number) => {
    setFilters({ start: ((page - 1) * filters.limit).toString() });
  };

  useEffect(() => {
    getOrganizationBillingFees({ orgId });
  }, [orgId, getOrganizationBillingFees]);

  useEffect(() => {
    loadInternalBillsApi({
      orgId,
      filters,
    });
  }, [filters, orgId]);

  const reFetchOrganizationBillingFees = () => {
    getOrganizationBillingFees({ orgId });
  };

  const handleGetReceiptClick = async ({ id }: InternalBill) => {
    analytics.track(eventPage, 'get-receipt', { billId: id });

    try {
      setInternalBillsStatuses({ ...internalBillsStatuses, [id]: ReceiptStatus.Loading });

      await billsApi.sendPdfInvoice(orgId, id);

      setInternalBillsStatuses({ ...internalBillsStatuses, [id]: ReceiptStatus.Requested });
      analytics.track(eventPage, 'get-receipt-success', { billId: id });
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: 'settings.billing.notification.getReceipt.success',
      });
    } catch (e: any) {
      setInternalBillsStatuses({ ...internalBillsStatuses, [id]: ReceiptStatus.None });
      analytics.track(eventPage, 'get-receipt-failure', { billId: id, reason: e?.message || '' });
      pushNotification({
        type: NOTIFICATION_VARIANT.ERROR,
        msg: 'settings.billing.notification.getReceipt.failure',
      });
    }
  };

  if ((isLoadingInternalBills && !internalBillsResponse?.objects?.length) || isFetchingOrgBillingFee) {
    return <QBDTLoader />;
  }

  return (
    <BillingSettingsPage
      internalBills={internalBillsResponse?.objects || []}
      internalBillsTotal={internalBillsResponse?.totalCount || 0}
      internalBillsLoading={isLoadingInternalBills}
      internalBillsStatuses={internalBillsStatuses}
      onGetReceiptClick={handleGetReceiptClick}
      filters={filters}
      setPage={setPage}
      organizationBillingFees={result?.organizationBillingFees || []}
      reFetch={reFetchOrganizationBillingFees}
    />
  );
};
