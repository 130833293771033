import React from 'react';
import { MITextInput } from 'src/components/common/MITextInput';
import { CONSTS } from 'src/utils/consts';
import { Expandable } from 'src/utils/types';
import { ModelViewField } from './use-form';
import { InlineFieldGroup } from './InlineFieldGroup';

export type InlineTextInputFieldProps = {
  model?: ModelViewField<string>;
  value?: string;
  label?: string;
  type?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (change: Expandable<{ value: string }>) => any;
  viewOnly?: boolean;
  autoFocus?: boolean;
  className?: string;
  errorMessage?: string;
  placeholder?: string;
  notices?: Array<string>;
  maxlength?: number;
  privateData?: boolean;
};

export function InlineTextInputField(props: InlineTextInputFieldProps) {
  const { model, className, errorMessage, value, id, onChange, ...rest } = props;
  const innerValue = value === undefined ? model?.value : value;
  const innerOnChange = onChange || model?.onChange;
  const innerId = id || model?.id;
  return (
    <InlineFieldGroup className={className}>
      <MITextInput
        {...rest}
        value={innerValue}
        onChange={innerOnChange}
        id={innerId}
        size={CONSTS.TEXT_INPUT_SIZE.INLINE}
        errorMessage={model?.error || errorMessage}
      />
    </InlineFieldGroup>
  );
}
