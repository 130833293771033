import React, { useEffect } from 'react';
import { Box, VStack } from '@melio/billpay-design-system';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import MIButton from 'src/components/common/MIButton';
import { MIFormattedText } from 'src/utils/formatting';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import { Modal, ModalSize } from 'src/theme/ds';
import { AccountType } from 'src/utils/types';
import { BenefitOption } from './BenefitOption';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as TrophyIcon } from './icons/trophy.svg';
import { ReactComponent as CardPaymentIcon } from './icons/card-payment.svg';

type Props = {
  eventPage: string;
  analyticsProperties?: { billId?: number; vendorId?: number; partialBillId?: string };
  hasCTA: boolean;
  fundingSource?: AccountType;
  dismiss: VoidFunction;
  onPayByCardClick?: (fundingSource?: AccountType) => void;
};

const CreditCardBenefitsModal = ({
  eventPage,
  analyticsProperties = {},
  hasCTA,
  fundingSource,
  dismiss,
  onPayByCardClick,
}: Props) => {
  useEffect(() => {
    analytics.track(eventPage, 'credit-card-benefits-modal_open', analyticsProperties);

    return () => {
      analytics.track(eventPage, 'credit-card-benefits-modal_close', analyticsProperties);
    };
  }, []);

  const handlePayByCardClick = () => {
    onPayByCardClick && onPayByCardClick(fundingSource);
    dismiss();
  };

  return (
    <Modal onCloseClick={dismiss} size={ModalSize.Custom} w="53rem">
      <VStack spacing="ds.md">
        <Box w="full" textStyle="ds.h6" color="ds.gray.100" pb="ds.md">
          <MIFormattedText label="creditCardBenefits.title" />
        </Box>
        <BenefitOption
          icon={CalendarIcon}
          title="creditCardBenefits.option.deferPayments.title"
          message="creditCardBenefits.option.deferPayments.message"
        />
        <BenefitOption
          icon={TrophyIcon}
          title="creditCardBenefits.option.earnRewards.title"
          message="creditCardBenefits.option.earnRewards.message"
        />
        <BenefitOption
          icon={CardPaymentIcon}
          title="creditCardBenefits.option.fasterDelivery.title"
          message="creditCardBenefits.option.fasterDelivery.message"
        />
        <Box textStyle="ds.body2" pt="ds.md" color="ds.gray.100">
          <MIFormattedText label="creditCardBenefits.footer" />
        </Box>
      </VStack>
      {hasCTA && (
        <PrimaryButton
          label="creditCardBenefits.cta"
          variant={BUTTON_VARIANT.PRIMARY}
          size={BUTTON_SIZE.NORMAL}
          onClick={handlePayByCardClick}
        />
      )}
    </Modal>
  );
};

const PrimaryButton = styled(MIButton)`
  width: 13.3rem;
  padding: 0;
  align-self: center;
`;

export { CreditCardBenefitsModal };
