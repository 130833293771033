import React from 'react';
import { HStack, Image, Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import CheckIcon from '../images/check-icon.svg';

type Props = {
  label: string;
};

const Item = ({ label }: Props) => (
  <HStack spacing="ds.lg">
    <Image src={CheckIcon} />
    <Box textStyle="ds.body2Semi" color="ds.black">
      <MIFormattedText label={label} />
    </Box>
  </HStack>
);

export { Item };
