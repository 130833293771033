import React from 'react';
import { RecordOf } from 'immutable';
import { sortFundingSourceSelectOptions } from 'src/utils/funding-sources';
import AchPaymentMethod from 'src/components/common/SelectMethods/types/AchPaymentMethod';
import DebitPaymentMethod from 'src/components/common/SelectMethods/types/DebitPaymentMethod';
import CreditPaymentMethod from 'src/components/common/SelectMethods/types/CreditPaymentMethod';
import SettingsMethodsListItem from 'src/components/common/SelectMethods/components/SettingsMethodsListItem';
import RadioSelectMethodOption from 'src/components/common/SelectMethods/components/RadioSelectMethodOption';
import { AccountType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import MethodsList, { MethodListItemType } from '../components/MethodsList';

type Props<T> = {
  value?: T | null;
  fundingSources: T[];
  onAddMethod: (type: string) => void;
  onChange?: (value: T) => void;
  onVerifyClicked?: (id: number) => void;
  modifyFundingSourceActions?: (method: RecordOf<AccountType>) => Record<string, any>;
  blockCCMoneyIn?: boolean;
  isVendorEnableCCPayments?: boolean;
  debitFee?: React.ReactNode | string;
  isBillingFee?: boolean;
  onViewBenefitsClick?: (fundingSource?: AccountType) => void;
};
export const FundingSourcesListLayout: React.FC<Props<RecordOf<AccountType>>> = ({
  value,
  fundingSources,
  onAddMethod,
  onChange,
  onVerifyClicked,
  modifyFundingSourceActions,
  blockCCMoneyIn,
  isVendorEnableCCPayments = true,
  debitFee,
  isBillingFee = false,
  onViewBenefitsClick,
}) => {
  const achMethods = fundingSources.filter((fs) => fs.fundingType === CONSTS.FUNDING_TYPE.ACH);
  const creditMethods = fundingSources.filter(
    (fs) => fs.fundingType === CONSTS.FUNDING_TYPE.CARD && fs.cardAccount.cardType === CONSTS.CARD_TYPES.CREDIT
  );
  const debitMethods = fundingSources.filter(
    (fs) => fs.fundingType === CONSTS.FUNDING_TYPE.CARD && fs.cardAccount.cardType === CONSTS.CARD_TYPES.DEBIT
  );

  let list: MethodListItemType[] = [];

  list.push({
    componentType: AchPaymentMethod,
    methods: achMethods,
    onVerifyClicked,
    isSettings: !onChange,
  });

  if (!blockCCMoneyIn && isVendorEnableCCPayments) {
    list.push({
      componentType: CreditPaymentMethod,
      methods: creditMethods,
      isSettings: !onChange,
      onVerifyClicked: undefined,
      onViewBenefitsClick,
    });
  }

  list.push({
    componentType: DebitPaymentMethod as any,
    methods: debitMethods,
    onVerifyClicked: undefined,
    isSettings: !onChange,
    debitFee,
  });

  list = sortFundingSourceSelectOptions(list);

  return (
    <MethodsList
      value={value}
      list={list}
      onChange={onChange}
      onAddMethod={onAddMethod}
      showAddAnotherOption
      modifyFundingSourceActions={modifyFundingSourceActions}
      optionComponent={onChange ? RadioSelectMethodOption : SettingsMethodsListItem}
      isBillingFee={isBillingFee}
    />
  );
};
