import { useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';

type Props = {
  batchItemId: string;
};

const useHandleBatchItemMemo = ({ batchItemId }: Props) => {
  const batchActions = useStoreActions(regularBatchPaymentsStore);

  const batchItemMemo = useSelector(regularBatchPaymentsStore.selectors.getMemo(batchItemId));
  const [memo, setMemo] = useState(batchItemMemo);

  const updateBatchItemMemo = () => {
    if ((!isEmpty(memo) || !isEmpty(batchItemMemo)) && memo !== batchItemMemo) {
      batchActions.updatePayments.setMemo({ memo, batchItemId });
    }
  };

  return { memo, setMemo, updateBatchItemMemo };
};

export { useHandleBatchItemMemo };
