import React from 'react';
import { Flex, HStack, Icon, Text } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryDateFormat } from 'src/utils/dates';
import { ReactComponent as CheckIllustration } from 'src/images/general/check-icon.svg';
import { ReactComponent as CalendarIllustration } from 'src/images/general/calendar-icon.svg';
import { PaymentType } from 'src/utils/types';

type Props = {
  address: string;
  deliveryEta: PaymentType['deliveryEta'];
  maxDeliveryEta: PaymentType['maxDeliveryEta'];
};
export const VendorReceive = ({ address, maxDeliveryEta, deliveryEta }: Props) => (
  <Flex direction="column" gap="ds.xl" justify="center" align="start" w="100%">
    <Text textStyle="ds.body2" color="ds.gray.100" alignSelf="start" m="0">
      <MIFormattedText label="vendor.pushToDebit.offerExpired.check.vendorReceive.title" />
    </Text>
    <Flex direction="column" justify="center" align="start" gap="ds.xl">
      <HStack gap="ds.lg">
        <Icon as={CheckIllustration} w="3rem" h="auto" alignSelf="start" />
        <Flex direction="column" align="start">
          <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.offerExpired.check.vendorReceive.paperCheckTo" />
          </Text>
          <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0" data-testid="p2d-check-offer-has-expired-address">
            {address}
          </Text>
        </Flex>
      </HStack>
      <HStack gap="ds.lg">
        <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
        <Flex direction="column" align="start">
          <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.offerExpired.check.vendorReceive.deliveryETA" />
          </Text>
          <Text
            color="ds.gray.100"
            textStyle="ds.body1Medium"
            m="0"
            data-testid="p2d-check-offer-has-expired-deliveryEta"
          >
            <DeliveryDateFormat date={deliveryEta} maxDate={maxDeliveryEta} />
          </Text>
        </Flex>
      </HStack>
    </Flex>
  </Flex>
);
