import React, { useEffect, useState } from 'react';
import analytics from 'src/services/analytics';
import userApi from 'src/services/api/user';
import { format, parse } from 'date-fns';
import { DATE_FORMATS } from 'src/utils/date-fns';
import { validateDateOfBirth } from 'src/utils/compliance-validator';
import { isValidationOk } from '@melio/sizzers-js-common';
import { useSelector, useDispatch } from 'react-redux';
import { setProfileAction } from 'src/redux/user/actions';
import { getProfile } from 'src/redux/user/selectors';
import { QBDTAddDateOfBirthPage } from './QBDTAddDateOfBirthPage';

const QBDTAddDateOfBirthPageContainer = () => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const [isUserProcessing, setIsUserProcessing] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(
    profile?.dateOfBirth
      ? format(new Date(profile.dateOfBirth), DATE_FORMATS.monthShortWithLongDateAndYearDashed)
      : null
  );

  useEffect(() => {
    analytics.track('Organization', 'View', {
      PageName: 'action-required-to-reactivate-bill-pay',
      Flow: 'banner',
      Intent: 'submit-details',
    });
  });

  const handleOnSubmit = async () => {
    analytics.track('Organization', 'Click', {
      Flow: 'banner',
      Intent: 'submit-details',
      integrations: { Salesforce: true },
    });
    const dateOfBirthValue = dateOfBirth?.toString();
    const parsedDate = dateOfBirthValue
      ? parse(dateOfBirthValue, DATE_FORMATS.monthShortWithLongDateAndYearDashed, new Date())
      : null;
    const validationErrors = { ...validateDateOfBirth(parsedDate) };

    setValidationErrors(validationErrors);
    setIsUserProcessing(true);
    if (isValidationOk(validationErrors)) {
      analytics.track('Organization', 'Status', {
        Flow: 'banner',
        Intent: 'submit-details',
        Status: 'success',
        integrations: { Salesforce: true },
      });
      try {
        const updatedProfile = await userApi.update({
          id: profile.id,
          dateOfBirth: parsedDate?.toISOString(),
        });
        dispatch(setProfileAction(profile.merge(updatedProfile)));
      } catch (error: any) {
        setIsUserProcessing(false);
      }
    } else {
      analytics.track('Organization', 'Status', {
        Flow: 'banner',
        Intent: 'submit-details',
        Status: 'failure',
        ValidationErrors: { ...validationErrors },
      });
      setIsUserProcessing(false);
    }
  };

  return (
    <QBDTAddDateOfBirthPage
      isLoading={isUserProcessing}
      dateOfBirth={dateOfBirth}
      validationErrors={validationErrors}
      handleOnSubmit={handleOnSubmit}
      onDateOfBirthChange={setDateOfBirth}
    />
  );
};

export default QBDTAddDateOfBirthPageContainer;
