import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RecordOf } from 'immutable';
import { isValidationOk } from '@melio/sizzers-js-common';
import { generatePath, useHistory } from 'react-router-dom';
import companyInfoApi from 'src/services/api/organizations';
import { CompanyInfoRecord } from 'src/records/settings.record';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import { calculateComplianceAnalyticsFields, isAfterGracePeriod } from 'src/utils/compliance-utils';
import { ComplianceValidationErrors, validateBusinessType, validateIndustryType } from 'src/utils/compliance-validator';
import { BusinessType, CompanyInfoType } from 'src/utils/types';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { getComplianceLimitations } from 'src/redux/payBillWizard/selectors';
import { catchAsync } from 'src/utils/async';
import { UpdateIndustryRequestData, industryApi } from 'src/services/api/industry';
import { PayBillCompleteComplianceInfoPage } from './components/PayBillCompleteComplianceInfoPage';
import { useOnBatchExit } from './hooks/useOnBatchExit';
import { useScheduleBatchPayments } from './hooks/useScheduleBatchPayments';
import { OnSelectNaicsCode } from '../../components/CompanyIndustryAutocomplete';

export const PayBatchCompleteComplianceInfoPageContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const limitations = useSelector(getComplianceLimitations);
  const isAfterComplianceGracePeriod = isAfterGracePeriod(limitations);

  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [{ naicsCode, industryText }, setNaicsData] = useState<UpdateIndustryRequestData>({
    naicsCode: null,
    industryText: '',
  });
  const [selectedBusinessType, setSelectedBusinessType] = useState<BusinessType | null>(null);

  useEffect(() => {
    analytics.track('Organization', 'View', {
      PageName: 'action-required-to-reactivate-bill-pay',
      Flow: 'batch-payment',
      Intent: 'pay',
      ...calculateComplianceAnalyticsFields(limitations),
    });
  });

  const onResetNaicsCode = () => {
    setNaicsData({ naicsCode: null, industryText: undefined });
  };

  const onSelectNaicsCode: OnSelectNaicsCode = (naicsData) => {
    setNaicsData(naicsData);
  };

  const onSelectBusinessType = (value: BusinessType) => {
    setSelectedBusinessType(value);
  };

  const setCompanyInfo = (companyInfo: RecordOf<CompanyInfoType>) => {
    dispatch(setCompanyInfoAction(companyInfo));
  };

  const { onBatchExit } = useOnBatchExit();

  const { scheduleBatchPayments } = useScheduleBatchPayments();

  const updateCompanyInfo = async () => {
    const dataToUpdate = companyInfo.merge({
      businessType: selectedBusinessType,
    });
    const validationErrors: ComplianceValidationErrors = {
      ...validateBusinessType(selectedBusinessType),
      ...validateIndustryType(naicsCode),
    };

    setValidationErrors(validationErrors);
    setIsLoading(true);

    if (!isValidationOk(validationErrors)) {
      setIsLoading(false);

      return {
        hasValidationErrors: true,
      };
    }

    const [{ companyInfo: updatedCompanyInfo }] = await Promise.all([
      companyInfoApi.updateCompanyInfo(orgId, dataToUpdate),
      industryApi.updateIndustry(orgId, { naicsCode, industryText }),
    ]);
    const companyInfoRecord = CompanyInfoRecord(updatedCompanyInfo);
    setCompanyInfo(companyInfoRecord as any);

    return undefined;
  };

  const onSubmit = async () => {
    await scheduleBatchPayments({
      onNoPaymentsScheduled: () => {
        setIsLoading(false);
      },
    });
  };

  const handleOnSubmit = async () => {
    analytics.track('Organization', 'Click', {
      PageName: 'action-required-to-reactivate-bill-pay',
      Flow: 'batch-payment',
      Intent: 'submit-details',
      Cta: isAfterComplianceGracePeriod ? 'save-and-continue' : 'save-and-schedule-my-payment',
      ...calculateComplianceAnalyticsFields(limitations),
    });
    const [updateCompanyInfoError, updateCompanyInfoResult] = await catchAsync(updateCompanyInfo());

    if (updateCompanyInfoError) {
      history.push(generatePath(locations.batchPay.batchError, { orgId }));

      return;
    }

    if (updateCompanyInfoResult?.hasValidationErrors) {
      return;
    }

    await onSubmit();
  };

  const handleOnSkip = async () => {
    analytics.track('Organization', 'Click', {
      PageName: 'action-required-to-reactivate-bill-pay',
      Flow: 'batch-payment',
      Intent: 'skip-details-submission',
      Cta: 'skip-and-schedule-my-payment',
      ...calculateComplianceAnalyticsFields(limitations),
    });

    await onSubmit();
  };

  return (
    <PayBillCompleteComplianceInfoPage
      isLoading={isLoading}
      businessType={selectedBusinessType}
      validationErrors={validationErrors}
      isAfterComplianceGracePeriod={isAfterComplianceGracePeriod}
      goExit={onBatchExit}
      handleOnSkip={handleOnSkip}
      handleOnSubmit={handleOnSubmit}
      onSelectBusinessType={onSelectBusinessType}
      onSelectNaicsCode={onSelectNaicsCode}
      onResetNaicsCode={onResetNaicsCode}
    />
  );
};
