import map from 'lodash/map';
import { all, call, put, select } from 'redux-saga/effects';
import regularBatchPaymentStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { getOrgId } from 'src/redux/user/selectors';
import { regularBatchUpdatePaymentsSlice } from '../regular-batch-payments/regular-batch-update-payments-slice';
import { regularBatchUpdateDeliveryOptionsByDueDateSlice } from '../regular-batch-payments/regular-batch-update-delivery-options-slice-due-date';
import { regularBatchUpdateDeliveryOptionsSlice } from '../regular-batch-payments/regular-batch-update-delivery-options-slice';
import { BatchItemType } from '../regular-batch-payments/types/store-types';

const DELIVERY_METHOD_SELECTED = '[PAY_BILL_FLOW] SELECT_NEW_DELIVERY_METHOD';
const DELIVERY_METHOD_CREATED = '[DELIVERYMETHODS] CREATE_SUCCESS';

type UpdateRegularBatchPayment = {
  deliveryMethodId: number;
  batchItem: BatchItemType;
};

function* updateRegularBatchPayment({ deliveryMethodId, batchItem }: UpdateRegularBatchPayment) {
  const orgId = yield select(getOrgId);
  const deliveryMethod = yield select(deliveryMethodsStore.selectors.byId(deliveryMethodId));

  if (!batchItem.payment.fundingSourceId) {
    yield put(
      regularBatchUpdatePaymentsSlice.actions.setPaymentDeliveryMethod({
        batchItemId: batchItem.id,
        deliveryMethod,
      })
    );
    return;
  }

  if (batchItem.isScheduledDateSelectedByUser) {
    yield put(
      regularBatchUpdateDeliveryOptionsSlice.actions({
        batchItemId: batchItem.id,
        orgId,
        ...batchItem.payment,
        deductionDate: batchItem.payment.scheduledDate,
        deliveryMethodId: deliveryMethod.id,
        deliveryMethod,
        payBillFlowUUID: batchItem.payBillFlowUUID,
        // we want to reset delivery speed cell on delivery method change.
        deliveryPreference: null,
      })
    );
    return;
  }

  yield put(
    regularBatchUpdateDeliveryOptionsByDueDateSlice.actions({
      batchItemId: batchItem.id,
      orgId,
      ...batchItem.payment,
      dueDate: batchItem.dueDate,
      deliveryMethodId: deliveryMethod.id,
      deliveryMethod,
      payBillFlowUUID: batchItem.payBillFlowUUID,
      // we want to reset delivery speed cell on delivery method change.
      deliveryPreference: null,
      scheduledDate: null,
    })
  );
}

export const updateRegularBatchPaymentSaga = {
  *[DELIVERY_METHOD_SELECTED](action) {
    const deliveryMethodId = action?.deliveryMethod?.id;
    const batchItems: BatchItemType[] = yield select(regularBatchPaymentStore.selectors.updatePayments.billsToUpdate);
    yield all(map(batchItems, (batchItem) => call(updateRegularBatchPayment, { deliveryMethodId, batchItem })));
  },
  *[DELIVERY_METHOD_CREATED](action) {
    const deliveryMethodId = action?.payload?.id;
    const batchItems: BatchItemType[] = yield select(regularBatchPaymentStore.selectors.updatePayments.billsToUpdate);

    const deliveryMethod = yield select(deliveryMethodsStore.selectors.byId(deliveryMethodId));

    yield all([
      ...map(batchItems, (batchItem) =>
        put(
          regularBatchUpdatePaymentsSlice.actions.setPaymentDeliveryMethod({
            batchItemId: batchItem.id,
            deliveryMethod,
          })
        )
      ),
      ...map(batchItems, (batchItem) => call(updateRegularBatchPayment, { deliveryMethodId, batchItem })),
    ]);
  },
};
