import { fetchRequest, putRequest } from './api';

export type NaicsAutocompleteSuggestion = {
  title: string;
  subtitle: string;
  naicsCode: number;
};

export type NaicsAutocompleteResponse = {
  suggestions: NaicsAutocompleteSuggestion[];
};

export type UpdateIndustryRequestData = {
  naicsCode: number | null;
  industryText?: string;
};

export const industryApi = {
  getAutocomplete(orgId: number, q: string): Promise<NaicsAutocompleteResponse> {
    return fetchRequest(`/orgs/${orgId}/industry/autocomplete`, { q });
  },
  updateIndustry(orgId: number, data: UpdateIndustryRequestData) {
    return putRequest(`/orgs/${orgId}/industry`, data);
  },
};
