import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { initLoggers } from './services/loggers';
import App from './App';
import { unregister } from './registerServiceWorker';
import errorTracker from './utils/error-tracking';
import { dataDog } from './utils/datadog';

errorTracker.config();
dataDog.init();
initLoggers();

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
