import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';

export const useChangeDeliveryMethod = () => {
  const [historyPush] = useHistoryWithOrgId();
  const { bill, payment, eventPage } = useViewPaymentContext();

  const orgId = useSelector(getOrgId);

  const exitUrl = generatePath(locations.view.base, {
    orgId,
    billId: bill.id,
    paymentId: payment.id,
  });

  const onChangeDeliveryMethod = () => {
    analytics.track(eventPage, 'change-delivery-method', {
      billId: bill.id,
      paymentId: payment.id,
    });

    historyPush({
      path: generatePath(locations.edit.deliveryMethod, {
        billId: bill.id,
        paymentId: payment.id,
        orgId,
      }),
      state: {
        exitUrl,
      },
    });
  };

  return { onChangeDeliveryMethod };
};
