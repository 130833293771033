import React from 'react';
import { Flex, Icon, Box, Text, HStack } from '@melio/billpay-design-system';
import { ReactComponent as MastercardLogo } from 'src/images/icons/mastercard.svg';
import { ReactComponent as JPMorganLogo } from 'src/images/virtual-card/jp-morgan.svg';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { useCardPreviewData } from '../hooks';
import { CardPreviewProps } from '../types';

export const CardDetails = ({ amount, cardDetails }: CardPreviewProps) => {
  const { cardAmountParts, cardNumber, exp, cvc } = useCardPreviewData({
    amount,
    cardDetails,
  });

  return (
    <Flex sx={containerStyle}>
      <Flex justify="space-between" align="center">
        <Box as="span" sx={amountStyle} className={FULL_STORY_MASK_RULE_CLASS}>
          {cardAmountParts.wholeAmountPart}
          <Box as="span" sx={decimalStyle}>
            {cardAmountParts.decimalAmountPart}
          </Box>
        </Box>
        <Icon as={MastercardLogo} width="3.4rem" height="4.rem" />
      </Flex>

      <Flex __css={footerStyle}>
        <Text textStyle="ds.body3Semi" mb="ds.sm">
          {cardNumber}
        </Text>
        <Flex justify="space-between">
          <HStack align="center" gap="ds.sm">
            <Text fontSize="0.6rem" lineHeight="0.6rem">
              <MIFormattedText label="vendor.unilateral.virtualCardDetails.previewCard.validThru" />
            </Text>
            <Text textStyle="ds.body3Semi">{exp}</Text>
          </HStack>
          <HStack align="center" gap="ds.sm">
            <Text fontSize="0.6rem" lineHeight="0.6rem">
              <MIFormattedText label="vendor.unilateral.virtualCardDetails.previewCard.cvc" />
            </Text>
            <Text textStyle="ds.body3Semi">{cvc}</Text>
          </HStack>
          <Icon as={JPMorganLogo} w="7.6rem" h="ds.lg" />
        </Flex>
      </Flex>
    </Flex>
  );
};

const containerStyle = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  w: '24.6rem',
  minH: '15rem',
  p: 'ds.lg',
  background: 'linear-gradient(230.87deg, #00C1BF 0%, #53B700 100%)',
  boxShadow: 'ds.lg',
  boxSizing: 'border-box',
  borderRadius: 'ds.lg',
};

const amountStyle = {
  flexWrap: 'wrap',
  wordBreak: 'break-word',
  fontWeight: 'ds.semi',
  color: 'ds.gray.700',
  textStyle: 'ds.h5',
  mr: 'ds.xs',
};

const decimalStyle = {
  textStyle: 'ds.body4',
  verticalAlign: 'top',
};

const footerStyle = {
  direction: 'column',
  color: 'white',
  fontWeight: '600',
  textStyle: 'ds.body4',
};
