import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import { MITextInput } from 'src/components/common/MITextInput';
import { withNavigator } from 'src/hoc';
import { updatePaymentMemoAction } from 'src/redux/payBillWizard/actions';
import { getBill, getCompanyName, getPayment } from 'src/redux/payBillWizard/selectors';
import { MIFormattedCurrency } from 'src/utils/formatting';
import { VendorEmailInput } from '../../modules/vendor-email/components/VendorEmalInput';
import { useCheckVendorEmail } from '../../modules/vendor-email/hooks/useCheckVendorEmail';
import { useUpdateVendorEmail } from '../../modules/vendor-email/hooks/useUpdateVendorEmail';
import { useValidateVendorEmail } from '../../modules/vendor-email/hooks/useValidateVendorEmail';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

const eventPage = 'pay-bill-memo';

const PayBillMemoPageContainer = ({ onNext, onPrevMemo, goExit }: PayBillProps) => {
  const dispatch = useDispatch();

  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const amount = payment?.amount;
  const companyName = useSelector(getCompanyName);

  const vendorId = bill.vendor.id;

  const { shouldRequestVendorEmailSinglePay } = useCheckVendorEmail();
  const { loading, updateVendorEmail } = useUpdateVendorEmail({ eventPage });
  const { getVendorEmail, setVendorEmail, getValidationError, validateVendorEmail } = useValidateVendorEmail({
    eventPage,
  });

  const handleMemoChange = ({ value }) => {
    dispatch(updatePaymentMemoAction(value));
  };

  const handleEmailChange = ({ id, value }) => {
    setVendorEmail({ id, value });
  };

  const handleSubmit = async () => {
    const email = getVendorEmail(vendorId);

    if (email) {
      const isEmailValid = validateVendorEmail({ id: vendorId, optional: true });

      if (!isEmailValid) {
        return;
      }

      await updateVendorEmail({ id: vendorId, email });
    }

    onNext();
  };

  const isVendorEmailInputShown = shouldRequestVendorEmailSinglePay({
    id: vendorId,
    deliveryType: payment?.deliveryMethod?.deliveryType,
  });

  return (
    <QBDTStepLayout
      headerLabel="qbo.header.title"
      headerLabelValues={{
        amount: <MIFormattedCurrency value={amount} />,
        companyName,
      }}
      title="bills.pay.memo.title"
      goExit={goExit}
      onPrev={onPrevMemo}
      onNext={handleSubmit}
      relativeStep={4 / 5}
      isLoading={loading}
    >
      <MITextInput
        id="memo"
        type="text"
        value={payment.note}
        onChange={handleMemoChange}
        label="bills.pay.memo.inputTitle"
        placeholder="bills.pay.memo.placeholder"
        notices={['bills.pay.memo.hint']}
        autoFocus
        privateData
      />
      {isVendorEmailInputShown && (
        <VendorEmailInput
          vendorId={vendorId}
          value={getVendorEmail(vendorId)}
          error={getValidationError(vendorId)}
          onChange={handleEmailChange}
        />
      )}
    </QBDTStepLayout>
  );
};

const PayBillMemoPageContainerComposed = compose(withNavigator(), withPayBillData())(PayBillMemoPageContainer);

export { PayBillMemoPageContainerComposed as PayBillMemoPageContainer };
