import React from 'react';
import { RecordOf } from 'immutable';
import { FormattedNumber } from 'react-intl';
import { Flex } from '@melio/billpay-design-system';

import { DeliveryMethodType, AccountType, OptionalDeliveryMethodsType } from 'src/utils/types';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { useSiteContext } from 'src/hoc/withSiteContext';
import styled, { css } from 'styled-components';
import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import MIMoney from 'src/components/common/MIMoney';
import { ReturnType as CheckFeeReturnType } from 'src/billpay/qbdt/hooks/useCheckFee/types';
import { OrganizationBillingFee } from 'src/services/api/smb-billing-fee';

import { PaymentRegularFeeTotal } from './PaymentRegularFeeTotal';

type PaymentFeeProps = {
  fee: PaymentFeeInfo;
  checkFee?: CheckFeeReturnType;
  deliveryMethod?: RecordOf<DeliveryMethodType>;
  fundingSource?: RecordOf<AccountType>;
  isEdit?: boolean;
  isExpeditedByPayee?: boolean;
  organizationBillingFees?: OrganizationBillingFee[];
};

const PaymentFee = ({
  fee,
  checkFee,
  deliveryMethod,
  fundingSource,
  isEdit,
  isExpeditedByPayee,
  organizationBillingFees = [],
}: PaymentFeeProps) => {
  const site = useSiteContext();
  const getFee = (fee: PaymentFeeInfo) => {
    const feeReduced = fee.finalAmount < fee.baseAmount;
    const showAmount =
      (site.theme as any).components?.BillPaymentReview?.showTotalAmount || feeReduced || fee.finalAmount === 0;
    const showFeeDescription = fee.baseAmount > 0;
    return {
      feeText: showAmount ? (
        <StyledMIMoney amount={fee.finalAmount} />
      ) : (
        <span className={FULL_STORY_MASK_RULE_CLASS}>
          <FormattedNumber value={fee?.feeStructure?.value} format="percent" />
        </span>
      ),
      showFeeDescription,
      textValues: {
        showAmount,
        baseAmount: fee.baseAmount,
        basePercent: fee?.feeStructure?.value,
        finalAmount: fee.finalAmount,
        savingAmount: fee.promotion.reduction,
        expiration: fee.promotion.expiration && new Date(fee.promotion.expiration),
        promotionName: fee.promotion.promotionName,
      },
      baseAmount: fee.baseAmount,
      savingAmount: fee.promotion.reduction,
      finalAmount: fee.finalAmount,
      showFeeHint: feeReduced,
    };
  };

  const hasValidCheckFS = checkFee?.flagEnabled && checkFee?.isAchFundingType(fundingSource);
  const isVirtualDM = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
  const isAchVirtualDM = !!(hasValidCheckFS && isVirtualDM);

  const notifications = (): React.ReactElement | null => {
    if (isAchVirtualDM && checkFee?.VirtualNotification) return <checkFee.VirtualNotification />;

    return null;
  };

  const { feeText, showFeeDescription, showFeeHint, textValues, savingAmount, baseAmount, finalAmount } =
    (fee && getFee(fee)) || {};

  const getFeeElement = () => {
    if (!isExpeditedByPayee && fee?.fastFee) {
      return <FastFee fee={fee} />;
    }

    if (!isExpeditedByPayee && fee?.fastFeeApi) {
      return <FastFeeApi fee={fee} />;
    }

    if (savingAmount && savingAmount > 0) {
      return (
        <SavingAmountFee
          textValues={textValues}
          baseAmount={baseAmount}
          finalAmount={finalAmount}
          savingAmount={savingAmount}
        />
      );
    }

    return (
      <RegularFee
        feeType={fee?.feeStructure?.feeType}
        textValues={textValues}
        feeText={feeText}
        showFeeDescription={showFeeDescription}
        showFeeHint={showFeeHint}
        fundingSource={fundingSource}
        deliveryMethodType={deliveryMethod?.deliveryType}
        checkFee={checkFee}
        notifications={notifications}
        isAchVirtualDM={isAchVirtualDM}
        isEdit={isEdit}
        organizationBillingFees={organizationBillingFees}
      />
    );
  };

  return <Flex direction="column">{getFeeElement()}</Flex>;
};

export default PaymentFee;

type FastFeeProps = {
  fee: PaymentFeeInfo;
};

const FastFee = ({ fee }: FastFeeProps) => {
  const { feeStructure, fastFee, finalAmount } = fee;
  const { cap } = feeStructure;
  const feeDetailsLabel = cap && Number(fastFee.totalAmount) === Number(cap) ? 'feeDetailsNoPercentage' : 'feeDetails';
  const isCreditCardFeeExist = feeStructure?.type === 'percent' && finalAmount > 0;
  const totalAmount = isCreditCardFeeExist ? fastFee.totalAmount + finalAmount : fastFee.totalAmount;

  return (
    <>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.fee" />
      </SubTitle>
      <FeeItem data-testid="payment-fast-fee-info-item">
        <MIFormattedText label={`bills.pay.confirm.${fastFee.type}.${feeDetailsLabel}`} values={{ fee: fastFee.fee }} />
        <MIFormattedCurrency value={fastFee.totalAmount.toString() || ''} />
      </FeeItem>
      {isCreditCardFeeExist && (
        <FeeItem>
          <MIFormattedText
            label="bills.pay.confirm.creditCardFee"
            values={{
              fee: <FormattedNumber value={feeStructure.value} format="percent" />,
            }}
          />
          <MIFormattedCurrency value={fee.finalAmount.toString() || ''} />
        </FeeItem>
      )}
      <TotalFeeContainer>
        <FeeItem>
          <MIFormattedText label="bills.pay.confirm.totalFee" />
          <MIFormattedCurrency value={totalAmount.toString() || ''} />
        </FeeItem>
        <TotalFeeDescription>
          <MIFormattedText label="bills.pay.confirm.expedited-ach.totalFeeDescription" />
        </TotalFeeDescription>
      </TotalFeeContainer>
    </>
  );
};

type FastFeeApiProps = {
  fee: Record<string, any>;
};

const FastFeeApi = ({ fee }: FastFeeApiProps) => {
  const { feeStructure, fastFeeApi, finalAmount } = fee;
  const feeType = feeStructure?.feeType;
  const fastFeeType = fastFeeApi?.feeType;

  const fastFeeExists = fastFeeType && fastFeeApi?.totalAmount;
  const isCreditCard = feeType === CONSTS.CARD_TYPES.CREDIT;
  const isDebitCard = feeType === CONSTS.CARD_TYPES.DEBIT;
  const isCardFeeExist = (isCreditCard || isDebitCard) && feeStructure?.value;

  return (
    <>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.fee" />
      </SubTitle>
      {fastFeeExists && (
        <FeeItem data-testid="payment-fast-fee-info-item">
          <MIFormattedText
            label={`bills.pay.confirm.${fastFeeApi.feeType}.feeDetails`}
            values={{ fee: (fastFeeApi as any).fee }}
          />
          <MIFormattedCurrency value={fastFeeApi.totalAmount.toString() || ''} />
        </FeeItem>
      )}
      {isCardFeeExist && (
        <FeeItem>
          <MIFormattedText
            label={`bills.pay.confirm.${feeType}CardFee`}
            values={{
              fee: feeStructure?.percent ? <FormattedNumber value={feeStructure?.percent} format="percent" /> : '',
            }}
          />

          <MIFormattedCurrency value={feeStructure.value.toString() || ''} />
        </FeeItem>
      )}
      <TotalFeeContainer>
        <FeeItem>
          <MIFormattedText label="bills.pay.confirm.totalFee" />
          <MIFormattedCurrency value={finalAmount.toString() || ''} />
        </FeeItem>
        <TotalFeeDescription>
          <MIFormattedText label="bills.pay.confirm.expedited-ach.totalFeeDescription" />
        </TotalFeeDescription>
      </TotalFeeContainer>
    </>
  );
};

type SavingAmountProps = {
  textValues: Record<string, any>;
  baseAmount: number;
  savingAmount: number;
  finalAmount: number;
};

const SavingAmountFee = ({ textValues, baseAmount, savingAmount, finalAmount }: SavingAmountProps) => (
  <>
    <SubTitle>
      <MIFormattedText label="bills.pay.confirm.fee" />
    </SubTitle>
    <FeeItem>
      <MIFormattedText label="bills.pay.confirm.feePromoBase" values={textValues} />
      <StyledMIMoney size="small" amount={baseAmount} />
    </FeeItem>
    <FeeItem>
      <div>
        <MIFormattedText label="bills.pay.confirm.feePromoReduction" values={textValues} />
        <FeeDescription>
          <MIFormattedText label="bills.pay.confirm.feePromoReductionHint" values={textValues} />
        </FeeDescription>
      </div>
      <StyledMIMoney size="small" flavor={CONSTS.MIMONEY_FLAVOR.POSITIVE} amount={-savingAmount} />
    </FeeItem>
    <TotalFeeContainer>
      <FeeItem>
        <MIFormattedText label="bills.pay.confirm.totalFee" values={textValues} />
        <StyledMIMoney size="normal" amount={finalAmount} />
      </FeeItem>
    </TotalFeeContainer>
  </>
);

type RegularFeeProps = {
  feeType?: string;
  textValues: Record<string, any>;
  feeText: React.ReactNode;
  showFeeHint: boolean;
  showFeeDescription: boolean;
  fundingSource?: RecordOf<AccountType>;
  deliveryMethodType?: OptionalDeliveryMethodsType | undefined;
  checkFee?: CheckFeeReturnType;
  notifications: () => React.ReactElement | null;
  isAchVirtualDM: boolean;
  isEdit?: boolean | undefined;
  organizationBillingFees: OrganizationBillingFee[];
};

const RegularFee = ({
  textValues,
  showFeeDescription,
  notifications: Notifications,
  isAchVirtualDM,
  ...rest
}: RegularFeeProps) => (
  <>
    <SubTitle>
      <MIFormattedText label="bills.pay.confirm.fee" />
    </SubTitle>
    <Notifications />
    {!isAchVirtualDM && <PaymentRegularFeeTotal textValues={textValues} {...rest} />}
    {showFeeDescription && (
      <FeeDescription>
        <MIFormattedText label="bills.pay.confirm.feeTerm" values={textValues} />
      </FeeDescription>
    )}
  </>
);

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FeeDescription = styled.div`
  ${baseTextStyles}
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeDescription}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;

const FeeItem = styled.div<{ total?: number }>`
  padding-right: 2rem;
  margin: 2rem 0;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => (props.total ? props.theme.text.size.subTitle : props.theme.text.size.regular)};
  font-weight: ${(props) => props.theme.text.weight.regular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalFeeContainer = styled.div`
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  ${FeeItem} {
    margin-bottom: 0.5rem;
  }
`;

const TotalFeeDescription = styled.div`
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFeeDescription}
`;
