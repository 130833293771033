/* eslint-disable no-console */
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import set from 'lodash/set';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import template from 'lodash/template';
import merge from 'lodash/merge';
import slice from 'lodash/slice';
import last from 'lodash/last';
import concat from 'lodash/concat';
import isEqual from 'lodash/isEqual';
import { RecordOf } from 'immutable';
import config from 'src/config';
import { isLoginFromForest } from 'src/utils/user-utils';
import { CONSTS, DB_ANALYTICS_TRAITS, ROLE, MQL_TYPE } from 'src/utils/consts';
import clientApi from 'src/services/api/clientService';
import organizationApi from 'src/services/api/organizations';
import financialAccountsApi from 'src/services/api/financialAccounts';
import clientEvents from 'src/services/clientEvents';
import { zendeskService } from 'src/services/zendesk';
import { CompanyInfoType, CompanyType, RegistrationFlowType } from 'src/utils/types';
import { parseQueryString } from 'src/utils/query-utils';
import MAPPINGS from './event-mappings';
import { EventMappingType, TrackToServerProperties } from './types';
import * as featureFlagsService from '../featureFlags';
import { logger } from '../loggers';

/**
  A wrapper service for Segment event tracking aggregation
  Docs: https://segment.com/docs/sources/website/analytics.js/
*/

const TRACK_PREFIX = 'webapp';

const TRAITS_KEY_TO_DB_FIELDS = {
  first_time_pay: 'firstTimePay',
  added_funding: 'addedFunding',
  create_a_bill: 'createdBill',
  link_accounting_software: 'linkedAccountingSoftware',
  Email_Verified: 'emailVerified',
  is_business_email: 'isBusinessEmail',
  added_delivery_method: 'addedDeliveryMethod',
};

// content of the path * will be stored in {*} as sibling of all other */subpaths
const PARENT = '{*}';
const appendParentPathIfNeeded = (arrPath: string[]) => (last(arrPath) === '*' ? concat(arrPath, PARENT) : arrPath);
const getParentContentIfExist = (treeResult: Record<string, any>): Record<string, EventMappingType> =>
  treeResult?.[PARENT] ? treeResult?.[PARENT] : treeResult;
const getArrayPath = (path: string) => appendParentPathIfNeeded(slice(path?.split('/'), 1));
const MAPPING_TREE = reduce(
  MAPPINGS,
  (acc, value, key) => {
    const mergedValue = { ...get(acc, getArrayPath(key), {}), ...value };
    return set(acc, getArrayPath(key), mergedValue);
  },
  {}
);
const findEvent = (path: string): Record<string, EventMappingType> =>
  getParentContentIfExist(
    reduce(getArrayPath(path), (tree, currPath) => get(tree, [currPath], tree?.['*']), MAPPING_TREE)
  );

const buildEventData = (event: any, properties?: Record<string, any>): EventMappingType =>
  event.map((eventArguments) => {
    if (isObject(eventArguments)) {
      return mapValues(eventArguments, (path) => get(properties, path));
    }

    try {
      return template(eventArguments)(properties);
    } catch (e) {
      return eventArguments;
    }
  });

function getEventMapping(path: string, actionName: string, properties?: Record<string, any>): EventMappingType | null {
  const event = findEvent(path)?.[actionName] || MAPPINGS[CONSTS.EVENT_MAPPING_NAME.DEFAULT][actionName];
  if (event) {
    return buildEventData(event, properties);
  }

  return null;
}

class Analytics {
  shouldReport: boolean;
  shouldPrintEvents: boolean;
  isLoggedInAs: boolean;
  currIdentifyObject: Record<string, any>;
  hasMqlEligibleEventCalled: boolean;
  siteConfig?: string;
  userId?: string;
  email?: string;
  orgId?: string;
  userRole?: ROLE;
  companyName?: string;
  mql?: MQL_TYPE;
  createOrigin?: string;
  companyType?: string;
  isUserInFirm?: boolean;
  extraPropertiesByTags: Record<string, Record<string, any>> = {};
  registrationFlow?: RegistrationFlowType;

  constructor() {
    this.shouldReport = config.analytics.shouldTrackEvents;
    this.shouldPrintEvents = config.analytics.shouldPrintEvents;
    this.isLoggedInAs = false;
    this.currIdentifyObject = {};
    this.hasMqlEligibleEventCalled = false;
    this.siteConfig = 'qbdt';

    featureFlagsService.initialize(this.trackVariant);
  }

  isShouldReport() {
    return this.shouldReport && !this.isLoggedInAs && !isLoginFromForest();
  }

  isShouldPrintEvents() {
    return this.shouldPrintEvents;
  }

  identify(
    user: Record<string, any>,
    options?: Record<string, any>,
    innerOptions: {
      isLoggedInAs?: boolean;
      companyInfo?: RecordOf<CompanyInfoType>;
    } = {}
  ) {
    const companyName = user.orgName ? user.orgName : user.orgId;
    const param = parseQueryString(window.location.search);

    this.siteConfig = 'qbdt';
    this.userId = user.id;
    this.email = user.email;
    this.orgId = user.orgId;
    const currentOrg = (user.organizations || []).find((org) => org.id === user.orgId);
    this.userRole = currentOrg?.userOrganization?.role;
    this.companyName = companyName;
    this.mql = innerOptions.companyInfo?.mql ?? undefined;
    this.createOrigin = currentOrg?.createOrigin;
    this.companyType = innerOptions.companyInfo?.companyType ?? undefined;
    this.isUserInFirm =
      this.companyType === CompanyType.ACCOUNTING_FIRM ||
      (user.organizations || []).some((org) => org.companyType === CompanyType.ACCOUNTING_FIRM);
    if (user.id) {
      this.registrationFlow = user.registrationFlow;
    }

    let traits: Record<string, any> = {
      name: user.email,
      email: user.email,
      Company: companyName,
      organization: companyName,
      Forest_Org_ID: user.orgId,
      registrationFlow: this.registrationFlow,
      guestPayor: user.isGuest,
      userRole: this.userRole,
      companyType: this.companyType,
      createOrigin: this.createOrigin,
      userInFirm: this.isUserInFirm,
      siteConfig: this.siteConfig,
    };

    if (innerOptions.companyInfo?.id) {
      let firstName = innerOptions.companyInfo.contactFirstName;
      let lastName = innerOptions.companyInfo.contactLastName;
      const creditCardFee = innerOptions.companyInfo.billingSetting?.fee?.credit?.value;

      firstName = isEmpty(firstName) ? '' : firstName;
      lastName = isEmpty(lastName) ? '' : lastName;

      if (firstName.length > 0 || lastName.length > 0) {
        traits.name = `${firstName} ${lastName}`;
      }

      traits.firstName = firstName;
      traits.lastName = lastName;

      if (creditCardFee) {
        traits.creditCardFee = creditCardFee;
      }
    }

    if (this.isShouldReport()) {
      clientEvents.identify();
    }

    const callOptions = options ? { ...options } : {};

    if (user.id && !innerOptions.isLoggedInAs && !isEqual(traits, this.currIdentifyObject)) {
      set(callOptions, 'integrations.Salesforce', true);
      this.currIdentifyObject = traits;
    }

    set(callOptions, 'integrations[Facebook App Events]', false);

    if (!user.id && param?.email) {
      this.userId = param.email as string;
      traits.email = param.email;
      this.email = param.email as string;
      traits.organizations = [];
    }

    if (innerOptions.isLoggedInAs) {
      this.isLoggedInAs = true;
    }

    traits = omitBy(traits, isNil);

    if (this.isShouldReport() && window.analytics) {
      window.analytics.identify(user.id, traits, callOptions);
    }

    if (this.isShouldPrintEvents()) {
      logger.log('analytics.identify(): called', this.userId, traits, callOptions);
    }

    this.identifyOrg(this.orgId);
  }

  trackToServer(eventName: string, table: string, key: string, value: any) {
    const properties: TrackToServerProperties = { table, value, key };
    switch (table) {
      case 'users': {
        properties.id = this.userId;
        break;
      }
      case 'organizations': {
        properties.id = this.orgId;
        break;
      }
      default: {
        return;
      }
    }

    clientApi.sendAnalyticsEvent(eventName, this.userId, properties);
  }

  setExtraProperties(tagName: string, properties: Record<string, any>) {
    this.extraPropertiesByTags[tagName] = properties;
  }

  removeExtraProperties(tagName: string) {
    delete this.extraPropertiesByTags[tagName];
  }

  getExtraProperties() {
    const extraPropertiesObjects = Object.values(this.extraPropertiesByTags);
    const extraPropertiesFlattered = Object.assign({}, ...Object.values(extraPropertiesObjects));
    return extraPropertiesFlattered;
  }

  track(
    page: string,
    event: string,
    properties?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) {
    const eventName = `${TRACK_PREFIX}_${page}_${event}`;
    const callOptions = options ? { ...options } : {};

    set(callOptions, 'integrations[Facebook App Events]', false);

    const trackProperties = merge(
      {},
      properties,
      this.userId
        ? {
            organizationId: this.orgId,
            email: this.email,
            userRole: this.userRole,
            companyType: this.companyType,
            createOrigin: this.createOrigin,
            userInFirm: this.isUserInFirm,
            siteConfig: this.siteConfig,
            registrationFlow: this.registrationFlow,
            ...this.getExtraProperties(),
          }
        : {
            siteConfig: this.siteConfig,
            ...this.getExtraProperties(),
          }
    );

    if (this.isShouldReport() && window.analytics) {
      window.analytics.track(eventName, trackProperties, set(callOptions, 'integrations.Intercom', false), callback);
    }

    if (this.isShouldPrintEvents()) {
      logger.log(`analytics.page_track(): ${page}_${event}`, trackProperties);
    }
  }

  trackMqlEligibleEvent(page: string, event: string) {
    if (!this.hasMqlEligibleEventCalled) {
      this.track(page, event);
      this.hasMqlEligibleEventCalled = true;
    }
  }

  trackAction(actionName: string, properties?: Record<string, any>) {
    const trackActionProperties = merge({}, properties, {
      siteConfig: this.siteConfig,
    });
    const event = getEventMapping(window.location.pathname, actionName, trackActionProperties);
    if (event) {
      const [page, category, props] = event;
      this.track(page, category, props);
    }
  }

  trackRoute(properties?: Record<string, any>) {
    const event = getEventMapping(window.location.pathname, 'page.view', properties);
    if (event) {
      const [page, category, props] = event;
      this.page(page, category, props);
    }
  }

  trackVariant = (flagName, variant) => {
    this.track('feature-variant', flagName, { variant });
  };

  trackMqlEvent(
    page: string,
    event: string,
    properties?: Record<string, any>,
    options: Record<string, any> = {},
    callback?: () => void
  ) {
    if (this.mql === CONSTS.MQL_TYPE.QUALIFIED) {
      set(options, 'integrations.Salesforce', true);
      this.track(page, event, properties, options, callback);
    }
  }

  identifyOrg(orgId?: string) {
    const groupId = `org:${orgId}`;
    const props = {
      group_type: 'organization',
      group_value: orgId,
    };
    if (this.isShouldPrintEvents()) {
      logger.log('analytics.group(): called', groupId, props);
    }

    if (this.isShouldReport() && window.analytics) {
      window.analytics.group(groupId, props);
    }
  }

  page(
    category: string,
    name: string,
    properties?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) {
    const categoryName = `${TRACK_PREFIX}_${category}`;
    let screenSize = '';
    try {
      screenSize = `${window.parent.innerWidth}_${window.parent.innerHeight}`;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const callOptions = options ? { ...options } : {};

    set(callOptions, 'integrations[Facebook App Events]', false);

    const commonPageProperties = {
      siteConfig: this.siteConfig,
      email: this.email,
      'screen-size': screenSize,
      registrationFlow: this.registrationFlow,
      ...this.getExtraProperties(),
    };
    const pageProperties = merge({}, properties, commonPageProperties);

    if (this.isShouldReport() && window.analytics) {
      window.analytics.page(categoryName, name, pageProperties, callOptions, callback);
    }

    if (this.isShouldPrintEvents()) {
      logger.log('analytics.page_view(): called', category, name, pageProperties);
    }
  }

  setTraits(traits: Record<string, any>) {
    let innerTraits = cloneDeep(traits);

    if (!isEmpty(innerTraits.contactFirstName)) {
      innerTraits.name = `${innerTraits.contactFirstName} ${innerTraits.contactLastName}`;
    }

    if (!isEmpty(innerTraits.addressLine1)) {
      const addressTraits = {
        address: {
          city: innerTraits.city,
          postalCode: innerTraits.zipCode,
          country: CONSTS.COUNTRY.US,
          street: innerTraits.addressLine1,
          state: innerTraits.state,
        },
      };
      innerTraits = addressTraits;
    }

    innerTraits = omitBy(innerTraits, isEmpty);

    // if received mql (org_mql_decision) form client-events - save it
    const clientEventsMqlDecision = get(traits, 'org_mql_decision', null);
    if (clientEventsMqlDecision) {
      this.mql = clientEventsMqlDecision;
    }

    if (this.isShouldReport() && window.analytics) {
      const options = {};
      set(options, 'integrations[Facebook App Events]', false);

      set(options, 'integrations.Salesforce', false);
      window.analytics.identify(this.userId || '', innerTraits, options);
    }

    if (this.isShouldPrintEvents()) {
      logger.log('analytics.setTraits(): called', this.userId, innerTraits);
    }

    const UPDATEABLE_TRAITS_FIELDS = Object.values(CONSTS.DB_ANALYTICS_TRAITS);
    Object.keys(traits)
      .filter((k) => UPDATEABLE_TRAITS_FIELDS.includes(k as DB_ANALYTICS_TRAITS))
      .forEach((trait) => {
        const changedField = TRAITS_KEY_TO_DB_FIELDS[trait];
        organizationApi.setTraitsToDB(this.orgId, { [changedField]: true });
      });
  }

  setFundingSourceTraits() {
    financialAccountsApi.getFundingSources(this.orgId, { includeDeleted: false }).then(({ fundingSources }) => {
      const sourcesTypes = uniq(map(fundingSources, 'fundingType')).join(',');
      this.setTraits({
        funding_sources_types: sourcesTypes,
      });
    });
  }

  reset(isResetIntercom: boolean) {
    clientEvents.reset();
    if (window.analytics) {
      window.analytics.reset();
    }

    if (isResetIntercom) {
      zendeskService.hide();
    }
  }
}

export default new Analytics();
