import React, { useEffect } from 'react';
import { useSiteContext } from 'src/hoc/withSiteContext';
import styled from 'styled-components';
import { ReactComponent as Automation } from 'src/images/general/automation-icon.svg';
import { useParams } from 'react-router-dom';
import analytics from 'src/services/analytics';
import {
  microDepositsEventAction,
  microDepositsEventPage,
  microDepositsEventStatuses,
} from 'src/pages/funding-sources/consts';
import { StyledQBDTFooterContainer, StyledQBDTSuccessLayout } from 'src/pages/funding-sources/components/common';

export function MicroDepositsLinkExpiredPage() {
  const site = useSiteContext();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    analytics.track(microDepositsEventPage, microDepositsEventAction, {
      fundingSourceId: id,
      status: microDepositsEventStatuses.LINK_EXPIRED,
    });
  }, []);

  return (
    <StyledQBDTSuccessLayout
      hideHeader
      illustration={<AutomationIcon />}
      FooterComponent={<StyledQBDTFooterContainer />}
      title="settings.microDeposits.verifyDialogLinkExpired.title"
      text="settings.microDeposits.verifyDialogLinkExpired.subtitle"
      textValues={{
        supportEmail: (
          <SupportLink href={`mailto:${site.config.support.email}`}>{site.config.support.email}</SupportLink>
        ),
      }}
    />
  );
}

const SupportLink = styled.a`
  text-decoration: none;
  font-weight: 500;
  color: ${(props) => props.theme.colors.intuit.active};
`;

const AutomationIcon = styled(Automation)`
  margin-bottom: 2rem;
`;
