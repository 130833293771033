import React from 'react';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { MIFormattedText } from 'src/utils/formatting';
import { AddressType, ButtonVariantType } from 'src/utils/types';
import AddressRowCard from './AddressRowCard';
import EditCheckAddressModalButtonRow from './EditCheckAddressModalButtonRow';

type Props = {
  icon?: string;
  onCloseModal: () => void;
  loading?: boolean;
  printName?: string;
  address?: AddressType;
  subtitle: string;
  PopUpFlow: {
    secondaryButtonLabel: string;
    onSuccessModal: () => void;
    secondaryButtonVariant: ButtonVariantType;
    invalidSubtitle?: string;
  };
};

const EditCheckAddressModal: React.FC<Props> = ({
  icon,
  onCloseModal,
  loading,
  printName,
  address,
  subtitle,
  PopUpFlow,
}) => (
  <ModalMessage
    icon={icon}
    titleComponent={<MIFormattedText label="manualAddress.invalidTitle" />}
    contentSubTitle={<MIFormattedText label={PopUpFlow.invalidSubtitle || subtitle} />}
    contentComponent={address && printName && <AddressRowCard printName={printName} address={address} />}
    buttonComponent={
      <EditCheckAddressModalButtonRow
        buttonLabel="manualAddress.edit"
        secondaryButtonLabel={PopUpFlow.secondaryButtonLabel}
        secondaryButtonVariant={PopUpFlow.secondaryButtonVariant}
        onButtonClick={onCloseModal}
        onSecondaryButtonClick={PopUpFlow.onSuccessModal}
        isLoading={loading}
      />
    }
    onCloseClick={onCloseModal}
    isLoading={loading}
  />
);

export default EditCheckAddressModal;
