const ACTION_PREFIX = '[ORGANIZATION]';

export const SET_ORGANIZATION_PREFERENCES = `${ACTION_PREFIX} SET_ORGANIZATION_PREFERENCES`;

export const UPDATE_ORGANIZATION_PREFERENCE = `${ACTION_PREFIX} UPDATE_ORGANIZATION_PREFERENCE`;
export const UPDATE_ORGANIZATION_PREFERENCE_SUCCESS = `${ACTION_PREFIX} UPDATE_ORGANIZATION_PREFERENCE_SUCCESS`;
export const UPDATE_ORGANIZATION_PREFERENCE_FAILED = `${ACTION_PREFIX} UPDATE_ORGANIZATION_PREFERENCE_FAILED`;

export const LOAD_FEE_CATALOG_SUCCESS = `${ACTION_PREFIX} LOAD_FEE_CATALOG_SUCCESS`;
export const LOAD_FEE_CATALOG_FAILED = `${ACTION_PREFIX} LOAD_FEE_CATALOG_FAILED`;
