import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useSinglePaymentAnalytics } from 'src/billpay/qbdt/pages/batch-pay/analytics/useSinglePaymentAnalytics';
import { close } from 'src/billpay/qbdt/services/qbdt';

export const useRemoveBillFromList = (batchItemId: string) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const { trackRemoveFromListAction } = useSinglePaymentAnalytics(batchItemId);
  const totalCount = useSelector(regularBatchPaymentsStore.selectors.totalCount);

  const removeBillFromList = async () => {
    await actions.settings.removeBillId({ batchItemId });
    trackRemoveFromListAction();

    if (totalCount === 1) {
      close();
    }
  };

  return { removeBillFromList };
};
