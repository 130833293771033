import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { Box } from '@melio/billpay-design-system';

type TagProps = {
  label: string;
  testId?: string;
};

const Tag = ({ label, testId }: TagProps) => (
  <Box as="span" color="#2CA01C" ml="0.6rem" fontWeight="600" data-testId={testId}>
    <MIFormattedText label={label} />
  </Box>
);

export default Tag;
