import { EventMappingsTree } from 'src/services/analytics/types';

const pageEvent = 'funding-sources';

export const microDepositsEventMapping: EventMappingsTree = {
  '/funding-sources/*/micro-deposits/verify/*': {
    'page.view': [pageEvent, 'micro-payments'],
  },
  '/funding-sources/*/micro-deposits/success': {
    'page.view': [pageEvent, 'micro-payments-success'],
  },
  '/funding-sources/*/micro-deposits/blocked': {
    'page.view': [pageEvent, 'micro-payments-blocked'],
  },
  '/funding-sources/*/micro-deposits/expired': {
    'page.view': [pageEvent, 'micro-payments-link-expired'],
  },
  '/funding-sources/*/micro-deposits/already-verified': {
    'page.view': [pageEvent, 'micro-payments-already-verified'],
  },
  '/funding-sources/*/micro-deposits/cannot-verify': {
    'page.view': [pageEvent, 'micro-payments-cannot-verify-yet'],
  },
};
