import get from 'lodash/get';
import api from 'src/services/api/vendorRequests';
import { createApiCallSlice, ON_FAILURE, ON_SUCCESS, ON_REQUEST } from 'src/helpers/redux/createApiCallSlice';
import { convertToDisplayAddressForm } from 'src/utils/address';

const name = 'vendors';
const stateName = 'qboVendorInfo';
const qboVendorInfo = createApiCallSlice<any, any>({
  api: api.getQBvendorInfo,
  name: '[VENDOR] VENDOR_MANAGEMENT_QB_INFO',
  initialState: {
    [stateName]: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      const { vendorId } = action.payload;
      state[stateName][vendorId] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const vendorId = get(action, 'meta.identifier.vendorId', '');
      delete state[stateName][vendorId];
      if (get(action, 'payload.0.address')) {
        state.byId[vendorId] = {
          ...state.byId[vendorId],
          address: convertToDisplayAddressForm(action.payload[0].address),
          printName: action.payload[0].title,
        };
      }
    },
    [ON_FAILURE](state, action) {
      const { vendorId } = action.payload;
      state[stateName][vendorId] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    value: (state, vendorId) => state[name].byId[vendorId]?.address,
    printName: (vendorId) => (state) => state[name].byId[vendorId]?.printName,
    qboAddress: (vendorId) => (state) => state[name].byId[vendorId]?.address,
    qboVendorLoading: (vendorId) => (state) => state[name][stateName]?.[vendorId]?.loading,
    loading: (state, vendorId) => state[name][stateName]?.[vendorId]?.loading,
  },
});

export { qboVendorInfo };
