import moment from 'moment';
import get from 'lodash/get';
import maxBy from 'lodash/fp/maxBy';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import findIndex from 'lodash/findIndex';
import { isFastDeliveryType } from './delivery-methods';
import { calculateTotalFees, defaultFeeObject } from './fee';

import { CONSTS, PAYMENT_STATUS, PAYMENT_APPROVAL_STATUS, DELIVERY_TYPE, FAILED_PAYMENT_TYPE } from './consts';
import type {
  feeApiItem,
  feeApiFastItem,
  paymentFeeApiType,
  PaymentType,
  TransactionType,
  PaymentApprovalActionType,
} from './types';

const { ACH, CHECK } = CONSTS.DELIVERY_TYPE;

export const getLatestPayment = (payments: Array<PaymentType>): PaymentType | undefined =>
  maxBy<PaymentType>((payment) => moment(payment.createdAt).unix())(payments);

export const getCheckDepositedDate = (transactions: TransactionType[]) => {
  const transaction = (transactions || []).find(
    (t) => t.status === 'SYSTEM' && t.rawData && t.rawData.description === 'Posted Check'
  );

  return get(transaction, 'rawData.line.DateTxn') || get(transaction, 'rawData.transfer.DateTxn');
};

export const getFailedPaymentDate = (payment: PaymentType) => {
  if (payment.riskStatus === CONSTS.RISK_STATUS.DECLINED) {
    const lastDeclinedApprovalAction = flow(
      filter<PaymentApprovalActionType>((action) => action.result === 'declined'),
      maxBy((action) => moment(action.createdAt).unix())
    )(payment.paymentApprovalActions);

    return lastDeclinedApprovalAction?.createdAt;
  }

  const lastFailedTransaction = flow(
    filter<TransactionType>((t) => t.status === CONSTS.TRANSACTION_STATUSES.FAILED),
    maxBy((t) => moment(t.createdAt).unix())
  )(payment.transactions);

  return lastFailedTransaction?.createdAt;
};

export const getDeliveryPreference = (deliveryOptions: any, deliveryPreference?: string) => {
  const id = findIndex(deliveryOptions, ['type', deliveryPreference]);
  const selectedId = id < 0 ? 0 : id;
  return selectedId.toString();
};

export const shouldShowFastPaymentOnRetryPaymentFlow = (
  deliveryType: string,
  deliveryPreference: string,
  deliveryOptions: any
) => {
  const isAlreadyFastPayment = CONSTS.FAST_DELIVERY_TYPES.includes(deliveryPreference);
  const isDeliveryTypeLegitForFastPayment = deliveryType === CHECK || deliveryType === ACH;
  const shouldShowFastPaymentPage = deliveryOptions && deliveryOptions.length > 1 && isDeliveryTypeLegitForFastPayment;

  return !isAlreadyFastPayment && shouldShowFastPaymentPage;
};

export const getPaymentById = (payments: PaymentType[], paymentId: string) =>
  payments.find((payment) => payment.id.toString() === paymentId.toString() || '');

// for get fee api by org (confirm)
export const convertFeeObject = (fees: any = []) => {
  if (!fees?.length) return defaultFeeObject;

  let standartFee: feeApiItem | undefined;
  let fastFee: feeApiFastItem | undefined;

  fees.forEach((fee: any) => {
    const { feeCatalog = {}, amount } = fee || {};

    if (isFastDeliveryType(feeCatalog.feeType)) {
      fastFee = {
        fee: feeCatalog.value,
        totalAmount: amount,
        feeType: feeCatalog.feeType,
      };
    } else if (!standartFee) {
      standartFee = {
        feeType: feeCatalog.feeType,
        valueType: feeCatalog.valueType,
        value: amount,
        cap: feeCatalog.cap,
        percent: feeCatalog.valueType === 'percent' ? feeCatalog.value / 100 : '',
      };
    }
  });

  return {
    finalAmount: calculateTotalFees(fees),
    feeStructure: standartFee,
    fastFeeApi: fastFee,
    promotion: {},
  };
};

// for get fee api by payment id (view paymnet)
export const convertPaymentFeeApiObj = (fees: paymentFeeApiType[] = []) => {
  if (!fees?.length) return defaultFeeObject;

  let standartFee: any;
  let fastFee: feeApiFastItem | undefined;

  fees.forEach((fee: any = {}) => {
    const { feeType, amount, valueType } = fee;

    if (isFastDeliveryType(fee?.feeType)) {
      fastFee = {
        feeType,
        totalAmount: amount,
      };
    } else {
      standartFee = {
        feeType,
        valueType,
        value: amount,
      };
    }
  });

  return {
    finalAmount: calculateTotalFees(fees),
    feeStructure: standartFee,
    fastFeeApi: fastFee,
    promotion: {},
  };
};

export const isPaymentFailed = (payment?: PaymentType) => payment?.status === PAYMENT_STATUS.FAILED;

export const isPaymentScheduled = (payment?: PaymentType) =>
  [PAYMENT_STATUS.SCHEDULED, PAYMENT_STATUS.BLOCKED].includes(payment?.status as PAYMENT_STATUS);

export const isPaymentCompleted = (payment?: PaymentType) =>
  [PAYMENT_STATUS.IN_PROGRESS, PAYMENT_STATUS.COMPLETED].includes(payment?.status as PAYMENT_STATUS);

export const isPaymentFailedToDeliver = (payment?: PaymentType) =>
  payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;

export const isPaymentDeclined = (payment: PaymentType) =>
  payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED;

export const isVirtualCardPayment = (payment: PaymentType): boolean =>
  payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

export const isVirtualCardPaymentExpired = (payment: PaymentType): boolean =>
  isPaymentFailedToDeliver(payment) && isVirtualCardPayment(payment);

export const isPaymentDeletable = (payment?: PaymentType): boolean => !!payment?.metadata?.isDeletable;
