import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DashboardTabStatusEnum } from '../../consts';
import { useDashboardListParams } from '../../hooks/useDashboardListParams';
import { useLoadDashboardList } from '../../hooks/useLoadDashboardList';
import { dashboardStore } from '../../redux-store/dashboard-store';
import { DashboardSyncBillsStatus } from '../types';

const SYNC_INTERVAL = 5000;

export const useUnpaidTabListPollingOnSync = () => {
  const syncStatus = useSelector(dashboardStore.selectors.syncBills.getStatus);
  const [listParams] = useDashboardListParams();
  const { loadDashboardList } = useLoadDashboardList({ silent: true });

  const pollingInterval = useRef<number | null>(null);

  const restartPollingInterval = () => {
    stopPollingInterval();

    pollingInterval.current = window.setInterval(pollDashboardList, SYNC_INTERVAL);
  };

  const stopPollingInterval = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
    }
  };

  const pollDashboardList = () => {
    loadDashboardList(listParams);
  };

  useEffect(() => {
    const isSyncDone = syncStatus === DashboardSyncBillsStatus.Done;
    const isSyncRunning = syncStatus === DashboardSyncBillsStatus.Running;
    const isPollingRunning = pollingInterval.current !== null;
    const isUnpaidTabSelected = listParams.filters.status === DashboardTabStatusEnum.Unpaid;

    // start polling conditions
    if (isSyncRunning && isUnpaidTabSelected) {
      restartPollingInterval();
    }

    // refresh list at the end of the sync
    if (isSyncDone && isPollingRunning && isUnpaidTabSelected) {
      pollDashboardList();
    }

    // stop polling conditions
    if (isSyncDone || !isUnpaidTabSelected) {
      stopPollingInterval();
    }
  }, [listParams, syncStatus]);
};
