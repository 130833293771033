import toInteger from 'lodash/toInteger';
import { CONSTS } from './consts';

const getVisiblePages = (currentPage: number, totalPagesCount: number, visiblePagesCount: number): number[] => {
  const visiblePages = [currentPage];
  const pagesCount = visiblePagesCount > totalPagesCount ? totalPagesCount : visiblePagesCount;

  while (visiblePages.length < pagesCount) {
    const prevPageValue = visiblePages[0] - 1;
    if (prevPageValue > 0) {
      visiblePages.unshift(prevPageValue);
    }

    const nextPageValue = visiblePages[visiblePages.length - 1] + 1;
    if (visiblePages.length < pagesCount && nextPageValue <= totalPagesCount) {
      visiblePages.push(nextPageValue);
    }
  }

  return visiblePages;
};

const convertStart = (start: any): number => {
  const convertedStart = toInteger(start);
  return convertedStart >= 0 ? convertedStart : CONSTS.PAGINATION.DEFAULT_START;
};

const convertLimit = (limit: any): number => {
  const convertedLimit = toInteger(limit);
  return convertedLimit > 0 ? convertedLimit : CONSTS.PAGINATION.DEFAULT_LIMIT;
};
export { getVisiblePages, convertStart, convertLimit };
