import React from 'react';
import sumBy from 'lodash/sumBy';
import styled from 'styled-components';
import { Flex, VStack, HStack } from '@melio/billpay-design-system';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import { useLocationState } from 'src/utils/hooks';
import { BUTTON_SIZE, BUTTON_VARIANT, NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import { useCheckVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useCheckVendorEmail';
import { Modal, ModalSubtitle, ModalTitle } from 'src/theme/ds';
import { ReactComponent as CheckIcon } from '../../images/check-mark.svg';
import { BatchPayFlowSuccessNotification } from './BatchPayFlowSuccessNotification';

type Props = {
  dismiss: (isFlowFinished?: boolean) => void;
  onNotifyVendorsClick: VoidFunction;
};

export const BatchPayFlowSuccessModal = ({ dismiss, onNotifyVendorsClick }: Props) => {
  const [isSyncPaymentsFailed] = useLocationState('isSyncPaymentsFailed', false);
  const [scheduledPayments] = useLocationState('scheduledPayments', []);
  const vendorIds = scheduledPayments.map(({ vendorId }) => vendorId);

  const { shouldRequestVendorEmailsBatchPay } = useCheckVendorEmail();
  const areSomeVendorEmailsMissing = shouldRequestVendorEmailsBatchPay(vendorIds);

  const handleNotifyVendorsClick = () => {
    onNotifyVendorsClick();
    dismiss(false);
  };

  const handleCloseClick = () => {
    dismiss(true);
  };

  const subtitleValues = {
    totalPayments: (
      <MIFormattedText
        label="bills.batchPay.successModal.subtitles.total"
        values={{
          totalPayedBills: scheduledPayments?.length,
        }}
      />
    ),
    total: <MIFormattedCurrency value={sumBy(scheduledPayments, 'amount')} />,
  };

  const notification = {
    label: isSyncPaymentsFailed
      ? 'bills.batchPay.successModal.syncFailedNotification.text'
      : 'bills.batchPay.successModal.notification.text',
  };
  const notificationTitle = {
    label: isSyncPaymentsFailed
      ? 'batchPayment.success.syncFailedNotificationTitle'
      : 'batchPayment.success.notificationTitle',
  };
  const notificationType = isSyncPaymentsFailed ? NOTIFICATION_CARD_TYPES.WARNING : NOTIFICATION_CARD_TYPES.INFO;

  return (
    <Modal onCloseClick={handleCloseClick}>
      <Flex justifyContent="center">
        <CheckIcon />
      </Flex>
      <VStack spacing="3rem">
        <VStack spacing="ds.md" alignItems="center">
          <ModalTitle label="bills.batchPay.successModal.titles.regular" />
          <ModalSubtitle
            label="bills.batchPay.successModal.subtitles.regular"
            values={subtitleValues}
            textAlign="center"
          />
        </VStack>
        <BatchPayFlowSuccessNotification type={notificationType} title={notificationTitle} subtitle={notification} />
        {!areSomeVendorEmailsMissing && (
          <DoneButton
            label="general.done"
            size={BUTTON_SIZE.NORMAL}
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={handleCloseClick}
          />
        )}
        {areSomeVendorEmailsMissing && (
          <HStack spacing="ds.md">
            <SecondaryButton
              label="bills.batchPay.successModal.buttonLabel.goToDashboard"
              size={BUTTON_SIZE.NORMAL}
              variant={BUTTON_VARIANT.SECONDARY}
              onClick={handleCloseClick}
            />
            <PrimaryButton
              label="bills.batchPay.successModal.buttonLabel.notifyVendors"
              size={BUTTON_SIZE.NORMAL}
              variant={BUTTON_VARIANT.PRIMARY}
              onClick={handleNotifyVendorsClick}
            />
          </HStack>
        )}
      </VStack>
    </Modal>
  );
};

const DoneButton = styled(MIButton)`
  width: 10rem;
  padding: 0;
  align-self: center;
`;

const PrimaryButton = styled(MIButton)`
  width: 15.8rem;
  padding: 0;
`;

const SecondaryButton = styled(MIButton)`
  width: 15.8rem;
  padding: 0;
  background-color: white;
`;
