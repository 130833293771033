import { Box } from '@melio/billpay-design-system';
import React from 'react';

type Props = {
  color: string;
  icon: string;
};

export const PaymentStatusIcon = ({ color, icon }: Props) => (
  <Box color={color} fontSize="2.4rem">
    <i className={icon} />
  </Box>
);
