import React, { useEffect } from 'react';
import { Flex, Text } from '@melio/billpay-design-system';
import { VendorFlowLayout, VendorFlowContentCard, Footer } from 'src/pages/vendor/components/VendorFlowLayout';
import { useForm } from 'src/ui/form';
import {
  UnilateralDeliveryMethodType,
  UnilateralPaymentActions,
  UnilateralPaymentState,
} from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { CONSTS, DELIVERY_TYPE } from 'src/utils/consts';
import NewDeliveryMethodForm from 'src/pages/vendor/components/NewDeliveryMethodForm';
import MIButton from 'src/components/common/MIButton';
import { MIFormattedText } from 'src/utils/formatting';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';
import { PaymentInfo } from '../components/PaymentInfo';
import { Divider } from '../components/Divider';

type Props = {
  state: UnilateralPaymentState;
  actions: UnilateralPaymentActions;
  deliveryMethod: UnilateralDeliveryMethodType;
};

export const AddACHDeliveryMethodPage = ({ state, actions, deliveryMethod }: Props) => {
  const { isDeliveryMethodProcessing, organization, payment } = state;

  const { onChange, onSubmit } = actions;

  useEffect(() => {
    onChange({ id: 'deliveryType', value: 'ach' });
  }, []);

  const [deliveryMethodMV, deliveryMethodMVActions] = useForm(deliveryMethod, {
    submit: (value) => {
      const { deliveryType, ...rest } = value;
      if (deliveryType) {
        return onSubmit({ deliveryType, ...rest }, false);
      }

      return Promise.reject(new Error('Delivery type not selected'));
    },
    onChange: ({ modelState }) => modelState,
  });

  const trackGoBackAnalytics = () => {
    vendorUnilateralAnalytics.trackGoBack({
      paymentId: payment?.id,
      vendorId: payment?.vendorId,
      vendorEmail: payment?.vendor?.contactEmail,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
    });
  };

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack trackAnalyticsOnGoBack={trackGoBackAnalytics}>
        <PaymentInfo companyName={organization?.companyName} payment={payment} />
        <Divider />
        <Flex direction="column">
          <ContentTitle label="vendors.addDeliveryMethodByLink.achTitle" />
          <NewDeliveryMethodForm
            submit={deliveryMethodMVActions.submit}
            selectedDeliveryMethod={DELIVERY_TYPE.ACH}
            achModel={deliveryMethodMV.bankAccount}
            checkModel={deliveryMethodMV.paperCheck}
          />
          <MIButton
            label="vendors.addDeliveryMethodByLink.submitCTA"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={deliveryMethodMVActions.submit}
            isProcessing={isDeliveryMethodProcessing}
            fullWidth
          />
          <Text textStyle="ds.body3" color="ds.gray.100" align="center">
            <MIFormattedText
              label="vendor.unilateral.deliveryMethods.ACH.actionHint"
              values={{
                payorName: organization?.companyName || '',
              }}
            />
          </Text>
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
