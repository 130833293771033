// import intuit from 'src/utils/intuit';
// import { isQBDTMac } from 'src/billpay/qbdt/services/qbdt';

// const CHAMPIONS_QBDT_VERSION_NUMBER = 33;

// const isChampionQuickbooksRelease = () => {
//   const version = intuit.getQbdtVersion(navigator?.userAgent);
//   const versionNumber = parseInt(version.split('/')[0], 10);

//   return versionNumber >= CHAMPIONS_QBDT_VERSION_NUMBER;
// };

export const getShouldRedirectToAppCenter = () => {
  const isCypressTestUserAgent = navigator?.userAgent === 'CypressTestUserAgent';
  if (isCypressTestUserAgent) {
    return false;
  }

  // FIXME: Auth process in iframe stopped working for versions before Champion Release
  // so at the moment we redirect all users directly to appcenter.
  // Once we'll figure out what cause it on Intuit side we'll return it back
  // shouldRedirectToAppCenter = isQBDTMac() || isChampionQuickbooksRelease();
  return true;
};
