import analytics from 'src/services/analytics';
import { DELIVERY_TYPE } from 'src/utils/consts';

const page = 'unilateral-payment';

export type CommonAnalyticsProps = {
  paymentId?: string;
  vendorId?: string;
  vendorEmail?: string;
  isEligibleToVirtualCard?: boolean;
};

export const vendorUnilateralAnalytics = {
  trackAddDeliveryMethod: (
    analyticsProps: CommonAnalyticsProps & {
      deliveryMethodsType: 'ach_check_virtual-card' | 'ach_check';
    }
  ) => {
    analytics.track(page, 'add-delivery-method-page-loaded', {
      ...analyticsProps,
      triggerFlow: 'unilateralPayment',
    });
  },

  trackSelectDeliveryMethod: (
    analyticsProps: CommonAnalyticsProps & {
      option: DELIVERY_TYPE.ACH | DELIVERY_TYPE.CHECK | DELIVERY_TYPE.VIRTUAL_CARD;
    }
  ) => {
    const { option, ...rest } = analyticsProps;
    analytics.track(page, `select-delivery-method-${option}`, rest);
  },
  trackVisitVirtualCardOptInPageLoaded: (analyticsProps: CommonAnalyticsProps) =>
    analytics.track('virtual-card', 'payment-opt-in-page-loaded', analyticsProps),
  trackGoBack: (props: CommonAnalyticsProps) => analytics.track(page, 'go-back', props),
  trackSuccessPageLoaded: (props: CommonAnalyticsProps) =>
    analytics.track(page, 'delivery-method-success-page-loaded', props),
  trackSubmitNewDeliveryMethod: (props: CommonAnalyticsProps) =>
    analytics.track(page, 'submit-new-delivery-method-request', props),
  trackSubmitNewDeliveryMethodSuccess: (props: CommonAnalyticsProps) =>
    analytics.track(page, 'submit-new-delivery-method-success', props),
};
