const LOCATIONS = {
  base: [
    '/vendors/delivery-method-virtual/:token',
    '/vendors/unilateral/add-delivery-method/:token',
    '/vendors/unilateral/add-delivery-method/:token/switch-company',
    '/vendors/unilateral/add-delivery-method/:token/invalid-link',
    '/vendors/unilateral/add-delivery-method/:token/success',
  ],
  index: '/vendors/delivery-method-virtual/:token',
  addMethod: '/vendors/unilateral/add-delivery-method/:token',
  addBankAccountMethod: '/vendors/unilateral/add-delivery-method/:token/bank-account',
  addVirtualCardMethod: '/vendors/unilateral/add-delivery-method/:token/virtual-card',
  addPaperCheckMethod: '/vendors/unilateral/add-delivery-method/:token/check',
  virtualCardLearnMore: '/vendors/unilateral/add-delivery-method/:token/virtual-card/learn-more',
  invalidToken: '/vendors/unilateral/add-delivery-method/:token/invalid-link',
  success: '/vendors/unilateral/add-delivery-method/:token/success',
  addVirtualCardMethodSuccess: '/vendors/unilateral/add-delivery-method/:token/virtual-card/success',
};

export default LOCATIONS;
