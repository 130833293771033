import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { Advantage } from 'src/pages/vendor/shift-vendor-to-debit/components/LearnMoreAboutVirtualCardPage/Advantage';

export const AdvantagesList = () => (
  <Flex direction="column" gap="ds.lg">
    <Advantage
      title="vendor.pushToDebit.virtualCard.learnMore.advantages.first.title"
      text="vendor.pushToDebit.virtualCard.learnMore.advantages.first.text"
    />
    <Advantage
      title="vendor.pushToDebit.virtualCard.learnMore.advantages.second.title"
      text="vendor.pushToDebit.virtualCard.learnMore.advantages.second.text"
    />
    <Advantage
      title="vendor.pushToDebit.virtualCard.learnMore.advantages.third.title"
      text="vendor.pushToDebit.virtualCard.learnMore.advantages.third.text"
    />
  </Flex>
);
