export enum QbdtTableVariantsEnum {
  Simple = 'simple',
  QbdtBatch = 'qbdtBatch',
  QbdtBillingFeesHistory = 'qbdtBillingFeesHistory',
}

export enum QbdtTableSizesEnum {
  Md = 'md',
  QbdtBatchMd = 'qbdtBatchMd',
  QbdtBillingFeesHistoryMd = 'qbdtBillingFeesHistoryMd',
}
