import { select, call } from 'redux-saga/effects';
import unionWith from 'lodash/unionWith';
import regularBatchPaymentStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import api from 'src/services/api/vendors';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import paymentStore from 'src/modules/payments/payment-store';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { ADD_NEW_DELIVERY_METHOD, BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS } from 'src/redux/payBillWizard/actionTypes';
import { dashboardStore } from 'src/billpay/qbdt/pages/dashboard/redux-store/dashboard-store';
import { updateQbdtVendorEmail } from 'src/billpay/qbdt/services/qbdt/entities/vendor';
import { logger } from 'src/services/loggers';
import { qboVendorInfo } from './vendor-management-slice';
import vendorsUploadBatchPayments from './vendors-upload-batch';
import vendorUpdateTrustedVendor from './update-trusted-vendor';
import checkVendorPaymentPreferences from './check-vendor-payment-preferences';
import { vendorsUpdateBatchPayments } from './vendors-update-batch';

export const name = 'vendors';

export function* updateVendorEmailInQBDTSaga(vendorId: string, contactEmail: string) {
  try {
    const vendor = yield select((state) => state.vendors.byId[vendorId]);
    if (vendor) {
      const externalId = vendor.accountingPlatformEntity?.externalId;
      yield call(updateQbdtVendorEmail, {
        externalId,
        name: vendor.companyName,
        contactEmail,
      });
    }
  } catch (e) {
    logger.error('vendorsStore.updateVendorEmailInQBDTSaga(): Failed to update vendor in qbdt', e);
  }
}

const vendorsStore = createRestfulSlice<any>({
  name,
  schemaName: 'vendor',
  api: {
    fetch: (params) => api.getVendorById(params).then(({ object }) => object),
    update: ({ orgId, id, ...params }) => api.editVendorById({ orgId, id, params }).then((res) => res.object),
    create: ({ orgId, ...params }) => api.createVendor(orgId, params).then((res) => res.object),
    list: (params) => api.getVendors(params).then((res) => ({ items: res.objects, totalCount: res.totalCount })),
    delete: ({ orgId, id }: any) => api.deleteVendorById(orgId, id),
  },
  extraReducers: {
    [paymentStore.actions.fetchPaymentDetailsWithToken.success](state, { payload }) {
      const { vendor } = payload.payment;
      state.byId[vendor.id] = vendor;
    },
    [paymentStore?.actions.fetchEmailToVendorDetails.success](state: any, { payload }) {
      const { vendor } = payload.payment;
      state.byId[vendor.id] = vendor;
    },
    [ADD_NEW_DELIVERY_METHOD](state, action) {
      const vendorId = action?.deliveryMethod?.vendorId;
      if (state.byId[vendorId]) {
        state.byId[vendorId].deliveryMethods = unionWith(
          [action.deliveryMethod],
          state.byId[vendorId].deliveryMethods || [],
          (a, b) => a.id === b.id
        );
      }
    },
    [regularBatchPaymentStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        // TODO: remove this once 'qbdt-bulk-payment' flag is gone.
        if (item.bill) {
          state.byId[item.bill.vendorId] = item.bill.vendor;
        } else {
          item.payment.bills.forEach((bill) => {
            state.byId[bill.vendorId] = bill.vendor;
          });
        }
      });
    },
    [deliveryMethodsStore.actions.fetchUnilateralRequestDetails.success](state: any, { payload }) {
      const { vendor } = payload;
      state.byId[vendor.id] = vendor;
    },
    '[DELIVERYMETHODS] CREATE_SUCCESS': function (state, { payload }) {
      if (state.byId[payload.vendorId]) {
        state.byId[payload.vendorId].deliveryMethods = unionWith(
          [payload],
          state.byId[payload.vendorId].deliveryMethods || [],
          (a: any, b: any) => a.id === b.id
        );
      }
    },
    // Incoming items brings only vendor's id & name, but we need an email as well.
    [dashboardStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        state.byId[item.vendor.id] = { ...state.byId[item.vendor.id], ...item.vendor };
      });
    },
    [BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS]: (state, action) => {
      const { vendorId, vendor } = action.bill;
      state.byId[vendorId] = vendor;
    },
  },
  extraSagas: {
    '[VENDORS] UPDATE_SUCCESS': function* (action) {
      const vendorId = action.payload.id;
      const contactEmail = action.payload.contactEmail || '';
      yield call(updateVendorEmailInQBDTSaga, vendorId, contactEmail);
    },
  },
  slices: {
    qboVendorInfo,
    createBatch: vendorsUploadBatchPayments,
    updateBatch: vendorsUpdateBatchPayments,
    updateTrustedVendor: vendorUpdateTrustedVendor,
    checkVendorPaymentPreferences,
  },
  selectors: {
    byId: (vendorId) => (state) => state.vendors.byId[vendorId],
    all: (state) => state.vendors.byId || {},
    allList: (state) => Object.values(state.vendors.byId || {}),
  },
});

export default vendorsStore;
