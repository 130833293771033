import { qdbtBatchMdSize } from './sizes/qbdt-batch-md.size';
import { qdbtBillingFeesHistoryMdSize } from './sizes/qbdt-billing-fees-history-md.size';
import { QbdtTableSizesEnum, QbdtTableVariantsEnum } from './Table.consts';
import { qbdtBatchVariant } from './variants/qbdt-batch.variant';
import { qbdtBillingFeesHistoryVariant } from './variants/qbdt-billing-fees-history.variant';

const variants = {
  [QbdtTableVariantsEnum.QbdtBatch]: qbdtBatchVariant,
  [QbdtTableVariantsEnum.QbdtBillingFeesHistory]: qbdtBillingFeesHistoryVariant,
};

const sizes = {
  [QbdtTableSizesEnum.QbdtBatchMd]: qdbtBatchMdSize,
  [QbdtTableSizesEnum.QbdtBillingFeesHistoryMd]: qdbtBillingFeesHistoryMdSize,
};

export const TableTheme = {
  variants,
  sizes,
};
