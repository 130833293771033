import React, { useEffect } from 'react';
import analytics from 'src/services/analytics';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { getPartialBillId } from 'src/utils/bills';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';
import { useChangeDeliveryMethod } from '../hooks/useChangeDeliveryMethod';
import { useRetryPaymentDelivery } from '../hooks/useRetryPaymentDelivery';
import { ActionButtonsWrapper } from './ActionButtonsWrapper';

const eventPage = 'bill';

export const FailedToDeliverActions = () => {
  const { payment, bill } = useViewPaymentContext();

  const { onRetryPaymentDelivery } = useRetryPaymentDelivery();
  const { onChangeDeliveryMethod } = useChangeDeliveryMethod();

  const { deliveryMethod } = payment;
  const missingDeliveryMethod = !deliveryMethod || Boolean(deliveryMethod?.deletedAt);

  const retryAction = missingDeliveryMethod ? onChangeDeliveryMethod : onRetryPaymentDelivery;

  useEffect(() => {
    analytics.track(eventPage, 'ChangeDeliveryMethodOption-Shown', {
      paymentId: payment.id,
      paymentStatus: payment.status,
      billId: bill.id,
      partialBillId: getPartialBillId(bill),
      deliveryMethod: payment.deliveryMethodId,
      flow: 'retry-failed-payment',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActionButtonsWrapper>
      <MIButton
        label="bills.actions.retryPaymentDelivery"
        testId="view-payment-change-delivery-method-action"
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={retryAction}
        fullWidth
      />
    </ActionButtonsWrapper>
  );
};
