import React from 'react';
import { HStack, Box } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';
import { MIFormattedText, MIFormattedDate } from 'src/utils/formatting';

type Props = {
  scheduledDate: Date;
  dueDate?: Date | null;
};

const PaymentDatePickerNotation = ({ scheduledDate, dueDate }: Props) => (
  <HStack px="ds.xl" pb="ds.lg" justify="space-between">
    {scheduledDate && (
      <HStack align="center" spacing="ds.md">
        <Circle borderColor="ds.green.200" bgColor="ds.green.200" />
        <Box color="ds.gray.200" textStyle="ds.body3">
          <MIFormattedText
            label="bills.pay.date.billScheduledDate"
            values={{
              date: <MIFormattedDate date={scheduledDate} />,
            }}
          />
        </Box>
      </HStack>
    )}
    {dueDate && (
      <HStack align="center" spacing="ds.md">
        <Circle borderColor="ds.gray.600" />
        <Box color="ds.gray.200" textStyle="ds.body3">
          <MIFormattedText
            label="bills.pay.date.billDueDate"
            values={{
              date: <MIFormattedDate date={dueDate} />,
            }}
          />
        </Box>
      </HStack>
    )}
  </HStack>
);

const Circle = chakra('div', {
  baseStyle: {
    width: '1rem',
    height: '1rem',
    boxSizing: 'border-box',
    borderRadius: '50%',
    border: '2px solid',
  },
});

export { PaymentDatePickerNotation };
