import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import MIMoney from 'src/components/common/MIMoney';
import { ReturnType as CheckFeeReturnType } from 'src/billpay/qbdt/hooks/useCheckFee/types';
import { OrganizationBillingFee } from 'src/services/api/smb-billing-fee';
import { getBillingMethod } from 'src/billpay/qbdt/pages/settings/components/billing/utils';

type PaymentRegularFeeCheckProps = {
  textValues: Record<string, any>;
  checkFee: CheckFeeReturnType;
  isEdit: boolean | undefined;
  hasPromotions: boolean;
  organizationBillingFees: OrganizationBillingFee[];
};

const PaymentRegularFeeCheck = ({
  textValues,
  checkFee,
  isEdit,
  hasPromotions,
  organizationBillingFees,
}: PaymentRegularFeeCheckProps) => {
  useEffect(() => {
    if (hasPromotions) checkFee.getFreeChecks({ isBatch: false });
  }, [hasPromotions]);

  const { freeChecks } = checkFee;
  const billingMethod = getBillingMethod(organizationBillingFees);

  const getFeeChargeText = () => {
    if (textValues.finalAmount && billingMethod) {
      return 'bills.pay.confirm.checkFee.feeChargeAnotherSource';
    }

    if (textValues.finalAmount) {
      return 'bills.pay.confirm.checkFee.feeCharge';
    }

    return 'bills.pay.confirm.checkFee.noCharge';
  };

  const feeChargeText = getFeeChargeText();

  return (
    <>
      <Box pb="ds.xl" color="ds.gray.100">
        <FeeItem>
          <MIFormattedText label="bills.pay.confirm.checkFee.title" />
          <MIFormattedCurrency value={textValues.finalAmount || ''} />
        </FeeItem>

        {!isEdit && !!freeChecks?.available && (
          <Box color="ds.gray.200" textStyle="ds.body3" pt="0.5rem">
            <MIFormattedText
              label="bills.pay.confirm.checkFee.promotionsLeft"
              values={{
                available: freeChecks.available,
                total: freeChecks.total,
              }}
            />
          </Box>
        )}
      </Box>

      <TotalCheckFeeContainer>
        <FeeItem>
          <Box color="ds.gray.100">
            <MIFormattedText label="bills.pay.confirm.totalFee" values={textValues} />
            <Box textStyle="ds.body3" mt="0.5rem" color="ds.gray.200">
              <MIFormattedText label={feeChargeText} />
            </Box>
          </Box>
          <StyledMIMoney amount={textValues.finalAmount} />
        </FeeItem>
      </TotalCheckFeeContainer>
    </>
  );
};

export { PaymentRegularFeeCheck };

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const FeeItem = styled.div<{ total?: number }>`
  padding-right: 2rem;
  margin-top: 2rem;
  font-size: ${(props) => (props.total ? props.theme.text.size.subTitle : props.theme.text.size.regular)};
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalFeeContainer = styled.div`
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
`;

const TotalCheckFeeContainer = styled(TotalFeeContainer)`
  font-size: 2rem;
`;
