import React, { FC } from 'react';
import { Flex, Icon } from '@melio/billpay-design-system';

const controlButtonStyle = {
  bg: 'transparent',
  border: 'none',
  alignItems: 'center',
  cursor: 'pointer',
  textStyle: 'ds.body3',
  px: 0,
  _active: {
    color: 'ds.black',
  },
  _focus: {
    border: 'none',
    outline: 0,
  },
  _disabled: {
    cursor: 'auto',
  },
};

type Props = {
  disabled: boolean;
  onClick: () => void;
  IconComponent: FC;
};

const PaginationControl = ({ IconComponent, ...props }: Props) => (
  <Flex as="button" sx={controlButtonStyle} {...props}>
    <Icon as={IconComponent} width="ds.lg" height="ds.lg" color={props.disabled ? 'ds.gray.500' : 'ds.gray.200'} />
  </Flex>
);

export default PaginationControl;
