import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { getDeliveryOption, getFastType, isFastDeliveryType } from 'src/utils/delivery-methods';
import analytics from 'src/services/analytics';
import { getBillIdsFromBatchItem } from '../../../utils';

export const useDeliverySpeed = (batchItemId, deliveryPreference, deliveryMethodType, deliveryOptions, payment) => {
  const actions = useStoreActions(regularBatchPaymentsStore);

  const isFastDelivery = useMemo(() => isFastDeliveryType(deliveryPreference), [deliveryPreference]);

  const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchItemId));

  const isDeliverySpeedOptionDisabled = useMemo(() => !deliveryMethodType || deliveryOptions.length <= 1, [
    deliveryMethodType,
    deliveryOptions.length,
  ]);

  const toggleType = useCallback(() => {
    if (isFastDelivery) return null;

    return getFastType(deliveryMethodType);
  }, [deliveryMethodType, isFastDelivery]);

  const onDeliverySpeedChange = useCallback(async () => {
    const selectedDeliveryPreference = toggleType();
    const selectedDeliveryOption = getDeliveryOption(selectedDeliveryPreference || deliveryMethodType, deliveryOptions);
    if (selectedDeliveryOption) {
      await actions.updatePayments.setPaymentByDeliveryOption({
        batchItemId,
        deliveryOption: selectedDeliveryOption,
      });
    }

    analytics.trackAction('toggle-fast-option', {
      type: deliveryMethodType,
      toggle: isFastDelivery ? 'off' : 'on',
      billIds: getBillIdsFromBatchItem(batchItem),
      vendorId: payment?.vendorId,
      payBillFlowUuid: batchItem?.payBillFlowUUID,
    });
  }, [toggleType, deliveryMethodType, deliveryOptions, actions, batchItemId]);

  return [isFastDelivery, isDeliverySpeedOptionDisabled, onDeliverySpeedChange, deliveryOptions];
};
