import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import SelectItemCard from 'src/components/common/SelectItemCard';
import some from 'lodash/some';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import analytics from 'src/services/analytics';
import AreaLoader from 'src/components/common/AreaLoader';
import { NotificationDM as CheckFeeNotification } from 'src/billpay/qbdt/hooks/useCheckFee/NotificationDM';
import { BillType, DeliveryMethodType, AccountType, OptionalDeliveryMethodsType } from 'src/utils/types';
import { CONSTS, DELIVERY_TYPE } from 'src/utils/consts';
import { QBODeliveryMethodsListItem } from 'src/billpay/qbdt/pages/pay/components/QBODeliveryMethodListItem';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import { Notification as AchFeeNotification } from 'src/billpay/qbdt/hooks/useAchFee/Notification';

type Props = {
  onNext: () => void;
  goExit: () => void;
  onPrev: () => void;
  goAddVirtualDeliveryMethod: () => void;
  companyName: string;
  amount: string;
  isLoading?: boolean;
  deliveryMethodOptions: RecordOf<DeliveryMethodType>[];
  selectedDeliveryMethod: string | undefined;
  selectedFundingSource: RecordOf<AccountType> | undefined;
  onDeliveryMethodChange: (value: OptionalDeliveryMethodsType) => void;
  bill: RecordOf<BillType>;
  headerLabel: string;
  relativeStep?: number;
  title: string;
  titleValues?: Record<string, any>;
};

export const PayBillSelectDeliveryMethodPage = ({
  onNext,
  companyName,
  isLoading = false,
  deliveryMethodOptions,
  selectedDeliveryMethod,
  onDeliveryMethodChange,
  goExit,
  onPrev,
  amount,
  bill,
  goAddVirtualDeliveryMethod,
  selectedFundingSource,
  headerLabel,
  relativeStep,
  title,
  titleValues,
}: Props) => {
  const site = useSiteContext();
  const showVirtualDeliveryMethodOption =
    site.config.featureFlags.unilateralPayment &&
    (selectedFundingSource?.isVerified ||
      (!selectedFundingSource?.isVerified &&
        some(bill.vendor?.deliveryMethods, (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL)));
  useEffect(() => {
    analytics.track('pay-bill', 'dm-unilateral-option-shown', {
      shown: showVirtualDeliveryMethodOption,
    });
  }, [showVirtualDeliveryMethodOption]);

  const headerLabelValues = {
    amount: <MIFormattedCurrency value={amount} />,
    companyName,
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  const hasOptionsToSelect = (bill.vendor?.deliveryMethods || []).some((deliveryMethod) =>
    [DELIVERY_TYPE.ACH, DELIVERY_TYPE.CHECK].includes(deliveryMethod.deliveryType)
  );

  return (
    <QBDTStepLayout
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={title}
      titleValues={titleValues}
      goExit={goExit}
      onPrev={onPrev}
      relativeStep={relativeStep}
      onNext={onNext}
      isLoading={isLoading}
      innerSize={50}
      isNextDisabled={!selectedDeliveryMethod}
      isHideActions={!hasOptionsToSelect}
    >
      <CheckFeeNotification selectedFundingSource={selectedFundingSource} />
      <AchFeeNotification
        selectedFundingSource={selectedFundingSource}
        selectedDeliveryMethod={selectedDeliveryMethod}
      />
      <DeliveryMethodsList>
        {deliveryMethodOptions.map((option) => (
          <QBODeliveryMethodsListItem
            key={option.deliveryType}
            deliveryMethod={option}
            companyName={companyName}
            isSelected={selectedDeliveryMethod === option.deliveryType}
            onItemClick={() => onDeliveryMethodChange(option.deliveryType)}
            selectedFundingSource={selectedFundingSource}
          />
        ))}
        {showVirtualDeliveryMethodOption && (
          <VirtualDeliveryMethodContainer>
            <site.components.WizardElements.WizardOrLine />
            <SelectItemCard
              testId="delivery-method-virtual"
              onClick={goAddVirtualDeliveryMethod}
              label="bills.pay.deliveryMethods.unilateralLink"
              description="bills.pay.deliveryMethods.unilateralHint"
            />
          </VirtualDeliveryMethodContainer>
        )}
        <InfoBlock>
          <MIFormattedText label="deliveryMethods.notDefinedInfo.infoText" />
        </InfoBlock>
      </DeliveryMethodsList>
    </QBDTStepLayout>
  );
};

const DeliveryMethodsList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
  ${(props) => props.theme?.pages?.PayBillSelectDeliveryMethodPage?.DeliveryMethodsList}
`;

const VirtualDeliveryMethodContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;

const InfoBlock = styled.div`
  display: none;
  line-height: 1.8rem;
  font-size: 1.2rem;
  align-self: flex-start;
  margin-top: 1.3rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: ${(props) => props.theme.text.color.darkGrey};

  ${(props) => props.theme?.pages?.PayBillSelectDeliveryMethodPage?.InfoBlock}
`;
