import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';

const eventPage = 'pay-bill';

export const useGoEditDate = () => {
  const history = useHistory();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);

  const goEditDate = useCallback(() => {
    const url = payment.id
      ? generatePath(locations.edit.date, {
          billId: bill.id,
          paymentId: payment.id,
          orgId,
        })
      : generatePath(locations.pay.date, { billId: bill.id, orgId });

    analytics.track(eventPage, 'edit-date');

    history.push(url);
  }, [payment.id, orgId, bill.id]);

  return { goEditDate };
};
