import request, { CancelToken } from 'axios';
import axiosRetry from 'axios-retry';
import curry from 'lodash/curry';
import config from 'src/config';
import { ServerError } from 'src/services/api/ServerError';
import errorTracking from 'src/utils/error-tracking';

let siteContextName = '';

// Setup axios-retry with default configuration
axiosRetry(request);

let authToken = null;

export function setAuthToken(token) {
  authToken = token;
}
const handleResponse = curry((request, response) => {
  const { success, data, message, code, json } = response;
  if (!success) {
    throw new ServerError({ ...request, ...response });
  }

  return { ...data, ...json, message, code };
});

const handleException = curry((request, error) => {
  if (error?.response?.status >= 500) {
    errorTracking.captureNetworkError(error);
  }

  if (!request.options.isBypassThrowError) {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.hash = 'unauthorized';
        window.location.reload();
      }

      throw new ServerError({
        ...request,
        ...error.response.data,
        status: error.response.status,
      });
    }

    throw error;
  }
});

const handleNoContent = (response) => {
  if (response.status === 204) {
    return {
      success: true,
      code: 'OK',
    };
  }

  return response.data;
};

type Headers = {
  Pragma: string;
  'x-site-context': string;
  Authorization?: string;
};

function getHeaders(): Headers {
  const headers: Headers = {
    Pragma: 'no-cache',
    'x-site-context': siteContextName,
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return headers;
}

export function initContextHeader(site: string) {
  siteContextName = site;
}

type Options = {
  isBypassThrowError?: boolean;
  catchCall?: boolean;
  cancelToken?: CancelToken;
};

export function fetchRequest(
  url: string,
  params: Record<string, any> | null = {},
  options: Options = { isBypassThrowError: false }
): Promise<any> {
  const req = { url, params, options, method: 'get' };
  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .get(`${config.server.baseUrl}/api${url}`, {
      params,
      ...(options?.cancelToken ? { cancelToken: options.cancelToken } : {}),
    })
    .then((response) => response.data)
    .then(handleResponse(req))
    .catch(handleException(req));
}

export function postRequest(
  url: string,
  params: Record<string, any> = {},
  conf: Record<string, any> = {},
  options: Options = { isBypassThrowError: false }
) {
  const req = { url, params, options, method: 'post' };
  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .post(`${config.server.baseUrl}/api${url}`, params, conf)
    .then((response) => response.data)
    .then((response) =>
      options.catchCall && !response.success
        ? { message: response.message, success: true, code: response.code }
        : response
    )
    .then(handleResponse(req))
    .catch(handleException(req));
}

export function putRequest(url: string, params: Record<string, any> = {}) {
  const req = { url, params, options: {}, method: 'put' };
  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .put(`${config.server.baseUrl}/api${url}`, params)
    .then((response) => response.data)
    .then(handleResponse(req))
    .catch(handleException(req));
}

export function deleteRequest(url: string, params: Record<string, any> = {}) {
  const req = { url, options: {}, method: 'delete' };
  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .delete(`${config.server.baseUrl}/api${url}`, { data: params })
    .then(handleNoContent)
    .then(handleResponse(req))
    .catch(handleException(req));
}
