import api from 'src/services/api/multiOrgs';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';

const name = 'organizations';

type PaymentRequestOrganizationAssignment = {
  loading: boolean;
  error?: Error;
  orgAssignedToPaymentRequest?: number;
};

type AssignPaymentRequestToOrgState = {
  assignPaymentRequestToOrg: PaymentRequestOrganizationAssignment;
};

type GetOrgAssignedToPaymentRequestState = {
  getOrgAssignedToPaymentRequest: PaymentRequestOrganizationAssignment;
};

const assignPaymentRequestToOrgSlice = createApiCallSlice<any, AssignPaymentRequestToOrgState>({
  name: `[${name.toUpperCase()}] ASSIGN_VENDOR_TO_USER_ORG`,
  api: api.assignPaymentRequestToOrg,
  initialState: {
    assignPaymentRequestToOrg: {
      loading: false,
      error: undefined,
      orgAssignedToPaymentRequest: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.assignPaymentRequestToOrg.loading = true;
      delete state.assignPaymentRequestToOrg.error;
    },
    [ON_SUCCESS]: (state, action) => {
      const { paymentRequestOrganizationAssignment } = action.payload;
      state.assignPaymentRequestToOrg.loading = false;
      state.assignPaymentRequestToOrg.orgAssignedToPaymentRequest = paymentRequestOrganizationAssignment.organizationId;
    },
    [ON_FAILURE]: (state, action) => {
      state.assignPaymentRequestToOrg.loading = false;
      state.assignPaymentRequestToOrg.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state[name].assignPaymentRequestToOrg.loading,
    error: (state) => state[name].assignPaymentRequestToOrg.error,
    orgAssignedToPaymentRequest: (state) => state[name].assignPaymentRequestToOrg.orgAssignedToPaymentRequest,
  },
});

const getOrgAssignedToPaymentRequestSlice = createApiCallSlice<any, GetOrgAssignedToPaymentRequestState>({
  name: `[${name.toUpperCase()}] GET_ORG_ASSIGNED_TO_VENDOR`,
  api: api.getOrgAssignedToPaymentRequest,
  initialState: {
    getOrgAssignedToPaymentRequest: {
      loading: false,
      error: undefined,
      orgAssignedToPaymentRequest: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.getOrgAssignedToPaymentRequest.loading = true;
      delete state.getOrgAssignedToPaymentRequest.error;
    },
    [ON_SUCCESS]: (state, action) => {
      const { organizationId } = action.payload;
      state.getOrgAssignedToPaymentRequest.loading = false;
      state.getOrgAssignedToPaymentRequest.orgAssignedToPaymentRequest = organizationId;
    },
    [ON_FAILURE]: (state, action) => {
      state.getOrgAssignedToPaymentRequest.loading = false;
      state.getOrgAssignedToPaymentRequest.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state[name].getOrgAssignedToPaymentRequest.loading,
    error: (state) => state[name].getOrgAssignedToPaymentRequest.error,
    orgAssignedToPaymentRequest: (state) => state[name].getOrgAssignedToPaymentRequest.orgAssignedToPaymentRequest,
  },
});

export { assignPaymentRequestToOrgSlice, getOrgAssignedToPaymentRequestSlice };
