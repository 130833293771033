import React from 'react';
import styled from 'styled-components';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import { devices } from 'src/theme/AppDevices';
import AddDateOfBirthForm from './AddDateOfBirthForm';

type Props = {
  isLoading: boolean;
  dateOfBirth: string | null;
  validationErrors: Record<string, any>;
  handleOnSubmit: () => void;
  onDateOfBirthChange: (value: string) => void;
};

export const QBDTAddDateOfBirthPage = ({
  isLoading,
  dateOfBirth,
  validationErrors,
  handleOnSubmit,
  onDateOfBirthChange,
}: Props) => {
  const layoutTitle = isLoading ? 'guests.addDateOfBirth.titleLoading' : 'guests.addDateOfBirth.title';
  const layoutSubtitle = isLoading ? 'guests.addDateOfBirth.subtitleLoading' : 'guests.addDateOfBirth.subtitle';

  return (
    <QBOLayoutPage
      title={layoutTitle}
      subtitle={layoutSubtitle}
      isLoading={isLoading}
      innerSize={65}
      nextLabel="guests.addDateOfBirth.action"
      onNext={handleOnSubmit}
    >
      <ComplianceInfoContainer>
        <FormWrapper>
          <AddDateOfBirthForm
            isLoading={isLoading}
            dateOfBirth={dateOfBirth}
            validationErrors={validationErrors}
            onDateOfBirthChange={onDateOfBirthChange}
          />
        </FormWrapper>
      </ComplianceInfoContainer>
    </QBOLayoutPage>
  );
};

const ComplianceInfoContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 45rem;
  padding-top: 0.7rem;

  @media ${devices.desktop} {
    justify-content: space-between;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2.4rem 0 2rem;
  }
`;

const FormWrapper = styled.div``;
