export const tableEmptyStateWrapperStyles = {
  width: 'full',
  height: 'full',
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
