import { AddressType } from 'src/utils/types';
import { TAX_ID_TYPE } from 'src/utils/consts';
import organizationApi from 'src/services/api/organizations';
import { getFormattedAddress } from 'src/utils/formatting';
import clientServiceApi from 'src/services/api/clientService';
import analytics from 'src/services/analytics';
import {
  validateCompanyName as validateCompanyNameCompliance,
  validateLegalCompanyName as validateLegalCompanyNameCompliance,
  validateContactPhone as validateContactPhoneCompliance,
  validateTaxIdType as validateTaxIdTypeCompliance,
  validateCompanyBusinessAddress as validateCompanyBusinessAddressCompliance,
} from '@melio/compliance-validator';
import { getCompanyInfo } from '../entities/company';

import { QBDTAddress, QBDTCompany } from '../types';

function getTaxIdType(res: QBDTCompany) {
  if (res.SSN && res.SSN.length > 0) {
    return TAX_ID_TYPE.SSN;
  } else if (res.EIN && res.EIN.length > 0) {
    return TAX_ID_TYPE.EIN;
  }

  return undefined;
}

function mapQBDTAddress(value: QBDTAddress): AddressType {
  return {
    addressLine1: value.Addr1 || '',
    addressLine2: value.Addr2 || '',
    city: value.City || '',
    state: value.State || '',
    zipCode: value.PostalCode || '',
  };
}

export async function syncValidatedCompanyInfo(orgId: number) {
  const res = await getCompanyInfo();

  analytics.track('GetCompanyInfo', 'Collected', {
    orgId,
    email: res.Email,
    companyName: res.CompanyName,
    legalCompanyName: res.LegalCompanyName,
  });

  const companyName = res.CompanyName;
  const legalCompanyName = res.LegalCompanyName;
  const phone = res.Phone;
  const taxIdType = getTaxIdType(res);

  let isValidCompanyAddressSmartyStreets;
  let isValidLegalCompanyAddressSmartyStreets;
  try {
    const companyAddressToValidateWithSmartyStreets = {
      addressLine1: res.Address.Addr1,
      addressLine2: res.Address.Addr2,
      city: res.Address.City,
      state: res.Address.State,
      zipCode: res.Address.PostalCode,
    };
    const legalAddressToValidateWithSmartyStreets = {
      addressLine1: res.LegalAddress.Addr1,
      addressLine2: res.LegalAddress.Addr2,
      city: res.LegalAddress.City,
      state: res.LegalAddress.State,
      zipCode: res.LegalAddress.PostalCode,
    };
    const [companyAddressValidationResult, legalAddressValidationResult] = await Promise.all([
      clientServiceApi.getAddressValidationSmartyStreets(companyAddressToValidateWithSmartyStreets),
      clientServiceApi.getAddressValidationSmartyStreets(legalAddressToValidateWithSmartyStreets),
    ]);
    isValidCompanyAddressSmartyStreets = !!companyAddressValidationResult?.is_valid;
    isValidLegalCompanyAddressSmartyStreets = !!legalAddressValidationResult?.is_valid;
  } catch (e) {
    isValidCompanyAddressSmartyStreets = false;
    isValidLegalCompanyAddressSmartyStreets = false;
  }

  const companyAddress = {
    addressLine1: res.Address.Addr1,
    city: res.Address.City,
    state: res.Address.State,
    zipCode: res.Address.PostalCode,
    countryCode: res.Address.Country,
  };
  const legalAddress = {
    addressLine1: res.LegalAddress.Addr1,
    city: res.LegalAddress.City,
    state: res.LegalAddress.State,
    zipCode: res.LegalAddress.PostalCode,
    countryCode: res.LegalAddress.Country,
  };
  const { isValid: isValidCompanyName } = validateCompanyNameCompliance(companyName);
  const { isValid: isValidLegalCompanyName } = validateLegalCompanyNameCompliance(legalCompanyName);
  const { isValid: isValidContactPhone } = validateContactPhoneCompliance(phone);
  const { isValid: isValidTaxIdType } = validateTaxIdTypeCompliance(taxIdType);
  const { isValid: isValidCompanyAddress } = validateCompanyBusinessAddressCompliance(companyAddress, false);
  const { isValid: isValidLegalAddress } = validateCompanyBusinessAddressCompliance(legalAddress, true);
  const companyInfo = {
    ...(isValidCompanyName && {
      companyName,
    }),
    ...(isValidLegalCompanyName && {
      legalCompanyName,
    }),
    ...(isValidContactPhone && {
      phone,
    }),
    taxId: res[taxIdType || ''],
    ...(isValidTaxIdType && {
      taxIdType,
    }),
    ...(isValidCompanyAddress &&
      isValidCompanyAddressSmartyStreets && {
        addressLine1: res.Address.Addr1,
        addressLine2: res.Address.Addr2,
        city: res.Address.City,
        state: res.Address.State,
        zipCode: res.Address.PostalCode,
        countryCode: res.Address.Country,
        formattedAddress: getFormattedAddress(mapQBDTAddress(res.Address)),
      }),
    ...(isValidLegalAddress &&
      isValidLegalCompanyAddressSmartyStreets && {
        legalAddressLine1: res.LegalAddress.Addr1,
        legalAddressLine2: res.LegalAddress.Addr2,
        legalCity: res.LegalAddress.City,
        legalState: res.LegalAddress.State,
        legalZipCode: res.LegalAddress.PostalCode,
        legalCountryCode: res.LegalAddress.Country,
        legalFormattedAddress: getFormattedAddress(mapQBDTAddress(res.LegalAddress)),
      }),
  };

  return organizationApi.updateCompanyInfo(orgId, companyInfo);
}
