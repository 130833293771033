import React from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { useBreak } from 'src/hoc';
import { devices } from 'src/theme/AppDevices';
import { isSandboxIndicator } from 'src/utils/funding-sources';
import AreaLoader from 'src/components/common/AreaLoader';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';

type Props = {
  goNext: () => void;
  goExit: () => void;
  onError: () => void;
  loadIframe: () => void;
  isLoading: boolean;
  errorCode: string | null | undefined;
  onHideErrorMessageAlert: () => void;
};

const AddCardAccountsPage = ({
  goExit,
  goNext,
  isLoading,
  loadIframe,
  errorCode,
  onHideErrorMessageAlert,
  onError,
}: Props) => {
  const site = useSiteContext();
  const { isDesktop, isPhablet } = useBreak();
  const iFrameWidth = isDesktop || isPhablet ? '700px' : '200px';
  const iFrameHeight = '470px';
  const isSandboxIndicatorShown = isSandboxIndicator(site);
  return (
    <React.Fragment>
      <QBDTStepLayout
        goExit={goExit}
        onNext={goNext}
        isLoading={isLoading}
        title="onboarding.fundingSources.card.iframeTitle"
        innerSize={75}
        isHideActions
        hideHeader
        isSandboxIndicatorShown={isSandboxIndicatorShown}
      >
        {errorCode && (
          <site.components.Dialog
            type={CONSTS.DIALOG_TYPE.ALERT}
            variant={CONSTS.DIALOG_VARIANTS.ERROR}
            title="onboarding.fundingSources.card.notSupportedTitle"
            subtitle={`server.${errorCode}`}
            okButtonText="dialogs.buttons.close"
            cancelButtonText="dialogs.buttons.close"
            onOkAction={onHideErrorMessageAlert}
            onCancelAction={onHideErrorMessageAlert}
          />
        )}
        <IframeContainer className={FULL_STORY_MASK_RULE_CLASS}>
          {isLoading && (
            <LoaderContainer>
              <AreaLoader />
            </LoaderContainer>
          )}
          <TabapayIframe
            isLoading={isLoading}
            onLoad={loadIframe}
            title="taba pay SSO"
            id="sso"
            onError={onError}
            src={site.config.services.tabapay.url}
            frameBorder="0"
            scrolling="no"
            width={iFrameWidth}
            height={iFrameHeight}
          />
          <SecurityDetailsContainer>
            <site.components.MISecurityDetails eventPage="payment-method-credit-card" />
          </SecurityDetailsContainer>
        </IframeContainer>
      </QBDTStepLayout>
    </React.Fragment>
  );
};

AddCardAccountsPage.defaultProps = {};

export default AddCardAccountsPage;

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.LoaderContainer}
`;
const TabapayIframe = styled.iframe<{ isLoading?: boolean }>`
  opacity: ${(props) => (props.isLoading ? '0' : '1')};
  width: 1px;
  min-width: 100%;
  width: 100%;
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.TabapayIframe}
`;

const IframeContainer = styled.div`
  position: relative;
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.IframeContainer}
`;

const SecurityDetailsContainer = styled.div`
  width: 45rem;
  margin: 0 auto;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 34rem;
  }
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.SecurityDetailsContainer}
`;
