import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function getCurrencyMask(prefix = '$') {
  const suffix = '';
  const includeThousandsSeparator = true;
  const thousandsSeparatorSymbol = ',';
  const allowDecimal = true;
  const decimalSymbol = '.';
  const decimalLimit = 2;
  return createNumberMask({
    prefix,
    suffix,
    includeThousandsSeparator,
    thousandsSeparatorSymbol,
    allowDecimal,
    decimalSymbol,
    decimalLimit,
  });
}

export function convertCurrencyToNumber(amount) {
  return amount ? amount.toString().replace(/[$,]/g, '') : null;
}

export function getMicrodepositMask(isNewFlow = false) {
  if (isNewFlow) {
    return ['$', /[0-9]/, '.', /\d/, /\d/];
  }

  return ['$', '0', '.', /\d/, /\d/];
}
