import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import deliveryApi from 'src/services/api/delivery';
import memoize from 'lodash/memoize';
import { hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { actionName } from './consts';
import { updatePaymentFundingSource } from './utils';

export const BATCH_UPDATE_DEDUCTION_DATES = `[${actionName}] UPDATE PAYMENT DEDUCTION DATES`;

const getEffectiveScheduleDate = memoize(deliveryApi.getEffectiveScheduleDate, hashListKey);

type UpdateDeductionDatesPayload = {
  batchItemId: string;
  scheduledDate: Date;
  orgId: string;
  fundingSourceId: number;
};

type Response = { scheduleDate: Date };

export const regularBatchUpdateDeductionDatesSlice = createApiCallSlice<UpdateDeductionDatesPayload, any, Response>({
  name: BATCH_UPDATE_DEDUCTION_DATES,
  initialState: {
    byId: {},
  },
  api: getEffectiveScheduleDate,
  reducers: {
    [ON_REQUEST](state, action) {
      state.byId[action.payload.batchItemId] = {
        ...state.byId[action.payload.batchItemId],
        loading: true,
        error: undefined,
      };
      updatePaymentFundingSource(state, action);
    },
    [ON_SUCCESS](state, action) {
      const { batchItemId, fundingSourceId } = action.meta.identifier as UpdateDeductionDatesPayload;

      const { scheduleDate } = action.payload;
      state.byId[batchItemId].minScheduledDate = scheduleDate;
      state.byId[batchItemId].payment.scheduledDate = scheduleDate;
      state.byId[batchItemId].payment.fundingSourceId = fundingSourceId;
      state.byId[batchItemId] = {
        ...state.byId[batchItemId],
        loading: false,
      };
    },
    [ON_FAILURE](state, action) {
      const { batchItemId } = action.meta.identifier as UpdateDeductionDatesPayload;

      state.byId[batchItemId] = {
        ...state.byId[batchItemId],
        loading: false,
        error: action.error,
      };
    },
  },
});
