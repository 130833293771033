import { useCallback } from 'react';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import locations from '../locations';
import {
  useGetDashboardItemQueryParams,
  RedirectQuery,
  DEFAULT_DASHBOARD_REDIRECT_PARAMS,
} from './useGetDashboardItemQueryParams';
import { useGetDashboardUnpaidItemsPresent } from './useGetDashboardUnpaidItemsPresent';

export type RedirectParams = {
  highlightedItemIds?: string[];
  paymentId: string;
  redirectQuery?: RedirectQuery;
  state?: Record<string, unknown>;
};

export const useRedirectToDashboard = () => {
  const [historyPush] = useHistoryWithOrgId();
  const { getDashboardItemQueryParams, isFetchingRedirectParams: isLoadingMetadata } = useGetDashboardItemQueryParams();
  const { getDashboardUnpaidItemsPresent, loading: isLoadingList } = useGetDashboardUnpaidItemsPresent();

  const getRedirectQuery = useCallback(
    async ({ highlightedItemIds, paymentId, redirectQuery }) => {
      if (redirectQuery || !paymentId) {
        return redirectQuery;
      }

      const unpaidItemsPresent = await getDashboardUnpaidItemsPresent();

      if (unpaidItemsPresent) {
        return DEFAULT_DASHBOARD_REDIRECT_PARAMS;
      }

      return getDashboardItemQueryParams({
        highlightedItemIds,
        paymentId,
      });
    },
    [getDashboardItemQueryParams, getDashboardUnpaidItemsPresent]
  );

  const redirectToDashboard = useCallback(
    async ({ highlightedItemIds, paymentId, redirectQuery, state }: RedirectParams) => {
      const path = locations.dashboard;
      const query = await getRedirectQuery({ highlightedItemIds, paymentId, redirectQuery });

      historyPush({ path, query, state });
    },
    [getRedirectQuery, historyPush]
  );

  return {
    redirectToDashboard,
    isFetchingRedirectParams: isLoadingMetadata || isLoadingList,
  };
};
