import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import paymentsApi from 'src/services/api/payments';
import organizationApi from 'src/services/api/organizations';
import { getOrgId } from 'src/redux/user/selectors';
import errorTracker from 'src/utils/error-tracking';
import { convertFeeObject } from 'src/utils/payments';
import { getFundingSourceType } from 'src/utils/funding-sources';
import { hasNewFeeApi, defaultFeeObject } from 'src/utils/fee';
import { CREDIT_CARD_FEE_PAYMENT, DATA_ORIGIN } from 'src/utils/consts';
import { catchAsync } from 'src/utils/async';
import { getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { logger } from 'src/services/loggers';

export const useConfirmPageFee = () => {
  const payment = useSelector(getPayment);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const orgId = useSelector(getOrgId);
  const [fee, setFee] = useState(null);
  const [isFeeLoading, setIsFeeLoading] = useState(true);
  const { deliveryType } = payment.deliveryMethod || {};

  useEffect(() => {
    getFeeHandler();
  }, []);

  const getFeeHandler = () => {
    setIsFeeLoading(true);

    if (
      hasNewFeeApi({
        deliveryType,
        fundingSourceType: getFundingSourceType(selectedFundingSource),
      })
    ) {
      return getFeeFromNewApi();
    }

    return getFeeFromOldApi();
  };

  const getFeeFromNewApi = async () => {
    const { PAYOR } = CREDIT_CARD_FEE_PAYMENT;

    const [err, data] = await catchAsync(
      organizationApi.getOrganizationFee(orgId, {
        paymentAmount: payment.amount,
        fundingSourceType: getFundingSourceType(selectedFundingSource),
        deliveryMethodType: deliveryType,
        deliveryPreference: payment.deliveryPreference,
        paymentOrigin: DATA_ORIGIN.INTUIT,
        feesPaidBy: PAYOR,
        organizationId: orgId,
        paymentId: payment?.id,
      })
    );

    setIsFeeLoading(false);

    if (err) {
      setFee(defaultFeeObject as any);
      return;
    }

    // convert current fee to component sturcture
    setFee(convertFeeObject(data?.fees) as any);
  };

  const getFeeFromOldApi = async () => {
    const [err, data] = await catchAsync(
      paymentsApi.getPaymentFee({
        orgId,
        fundingSourceId: payment.fundingSourceId,
        amount: payment.amount,
        scheduledDate: payment.scheduledDate,
        deliveryMethodId: payment.deliveryMethodId,
        deliveryPreference: payment.deliveryPreference,
        allowDoubleFee: true,
      })
    );

    setIsFeeLoading(false);

    if (err) {
      logger.error('useConfirmPageFee.getFeeFromOldApi(): failed load fee', err);
      errorTracker.capture(err);
      setFee(null);
      return;
    }

    setFee(data?.fee);
  };

  return {
    isFeeLoading,
    fee,
  };
};
