import { all, fork } from 'redux-saga/effects';
import { zendeskService } from 'src/services/zendesk';
import payBillWizardSagas from './payBillWizard/sagas';
import userSagas from './user/sagas';
import { organizationSagas } from './organization/sagas';
import auth from '../modules/auth/auth-store';
import users from '../modules/users/users-store';
import profile from '../modules/profile/profile-store';
import organizations from '../modules/organizations/organizations-store';
import vendors from '../modules/vendors/vendors-store';
import payments from '../modules/payments/payment-store';
import bills from '../modules/bills/bills-store';
import deliveryMethods from '../modules/delivery-methods/delivery-methods-store';
import navigation from '../modules/navigation/navigation-store';
import fundingSources from '../modules/funding-sources/funding-sources-store';
import intuitBankAccountsStore from '../modules/intuit-bank-accounts/intuit-bank-accounts-store';
import regularBatchPaymentsStore from '../modules/regular-batch-payments/regular-batch-payments-store';
import { dashboardStore } from '../billpay/qbdt/pages/dashboard/redux-store/dashboard-store';

// eslint-disable-next-line require-yield
function* initialSaga() {
  zendeskService.hide();
}

export default function* rootSaga() {
  yield fork(initialSaga);
  yield all([
    payBillWizardSagas(),
    userSagas(),
    organizationSagas(),
    auth.saga(),
    users.saga(),
    profile.saga(),
    organizations.saga(),
    vendors.saga(),
    payments.saga(),
    deliveryMethods.saga(),
    bills.saga(),
    navigation.saga(),
    fundingSources.saga(),
    regularBatchPaymentsStore.saga(),
    intuitBankAccountsStore.saga(),
    dashboardStore.saga(),
  ]);
}
