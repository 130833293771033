import React, { useEffect } from 'react';
import { Flex, Icon, Text } from '@melio/billpay-design-system';
import { ReactComponent as ErrorIllustration } from 'src/images/icons/errorToken.svg';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { MIFormattedText } from 'src/utils/formatting';
import { useSiteContext } from 'src/hoc/withSiteContext';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';

export const InvalidTokenPage = () => {
  const site = useSiteContext();
  const supportEmail = site.config.support.email;

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'invalid-token');
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex w="100%" direction="column" gap="ds.2xl" justify="center" align="center">
          <Icon as={ErrorIllustration} w="10rem" h="10rem" />
          <Text m="0" textStyle="ds.body2" color="ds.gray.100" align="center">
            <MIFormattedText label="vendor.pushToDebit.invalidToken" values={{ email: supportEmail }} />
          </Text>
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
