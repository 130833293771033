import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { PayBillFundingSourcePage } from './/components/PayBillFundingSourcePage';
import PayBillSelectDeliveryMethodPageContainer from './PayBillSelectDeliveryMethodPageContainer';
import PayBillDatePageContainer from './PayBillDatePageContainer';
import { PayBillMemoPageContainer } from './PayBillMemoPageContainer';
import { PayBillConfirmPage } from './confirm/PayBillConfirmPage';
import PayBillSuccessPageContainer from './PayBillSuccessPageContainer';
import FastPaymentOfferPage from './FastPaymentOfferPage';
import EditAchDeliveryMethodPage from '../delivery-method/EditAchDeliveryMethodPage';
import QBDTPayBillCompleteLegalInfoPageContainer from './legal-info/QBDTPayBillCompleteLegalInfoPageContainer';
import { PayBillErrorPage } from './PayBillErrorPage';
import { VirtualCardRecoveryPage } from './virtual-card-recovery';
import { ExpeditePaymentPage } from './ExpeditePaymentPage';
import QBDTPayCompleteComplianceInfoPageContainer from './compliance-info/QBDTPayBillCompleteComplianceInfoPageContainer';

export function QBDTPayWizardRouter() {
  const match = useRouteMatch<{ billId?: string; paymentId?: string }>();
  const flowType = match.path.endsWith('pay') ? 'pay' : 'edit';
  const { billId, paymentId } = match.params;

  return (
    <Switch>
      <SmartRoute path={`${match.path}/funding`} exact>
        <PayBillFundingSourcePage id={billId} paymentId={paymentId} nextStepURL={`${match.url}/delivery-method`} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/delivery-method`}>
        <PayBillSelectDeliveryMethodPageContainer
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/funding`}
          nextStepURL={`${match.url}/date`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/delivery-method-ach`}>
        <EditAchDeliveryMethodPage id={billId} paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/virtual-card-recovery`}>
        <VirtualCardRecoveryPage id={billId} paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/date`}>
        <PayBillDatePageContainer
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/delivery-method`}
          nextStepURL={`${match.url}/memo`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/memo`}>
        <PayBillMemoPageContainer
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/date`}
          nextStepURL={`${match.url}/expedite`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/expedite`}>
        <ExpeditePaymentPage id={billId} prevStepURL={`${match.url}/memo`} nextStepURL={`${match.url}/confirm`} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/confirm`}>
        <PayBillConfirmPage
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/memo`}
          nextStepURL={`${match.url}/success`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/success`}>
        <PayBillSuccessPageContainer id={billId} paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/complete-legal-info`}>
        <QBDTPayBillCompleteLegalInfoPageContainer
          id={billId}
          prevStepURL={`${match.url}/confirm`}
          nextStepURL={`${match.url}/success`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/complete-compliance-info`}>
        <QBDTPayCompleteComplianceInfoPageContainer
          id={billId}
          prevStepURL={`${match.url}/confirm`}
          nextStepURL={`${match.url}/success`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/fast-payment`}>
        <FastPaymentOfferPage
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/funding`}
          nextStepURL={`${match.url}/confirm`}
        />
      </SmartRoute>
      <SmartRoute path={match.path} exact>
        <Redirect to={`${match.url}/funding`} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/error`} exact>
        <PayBillErrorPage
          title="entry.sync.error"
          subtitle="entry.sync.errorSubtitle"
          errors={`error in ${flowType} payment`}
        />
      </SmartRoute>
    </Switch>
  );
}
