import React from 'react';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import styled from 'styled-components';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { ModelView } from 'src/ui/form';
import { CheckType, AddressType } from 'src/utils/types';
import US_STATES from 'src/utils/us-states';
import MIAddressAutocompleteSmartyStreets from 'src/components/common/MIAddressAutocompleteSmartyStreets';

export type CheckDeliveryMethodModelType = ModelView<CheckType>;

type Props = {
  submit: () => Promise<void>;
  model: CheckDeliveryMethodModelType;
};

const CheckDeliveryMethodForm = ({ submit, model }: Props) => {
  const onChangeSuggestedAddress = async (value: AddressType | null) => {
    model.setModelState((prevState) => ({
      ...prevState,
      ...value,
    }));
    model.setValidationErrors({});
  };

  return (
    <FormStyle>
      <FormContainer onSubmit={submit}>
        <FormRow>
          <WizardTextInputField
            label="vendors.deliveryMethods.check.name.label"
            placeholder="vendors.deliveryMethods.check.name.title"
            model={model.printName}
            autocomplete="off"
            required
            autoFocus
            privateData
          />
        </FormRow>
        <FormRow>
          <MIAddressAutocompleteSmartyStreets
            id="addressLine1"
            label="manualAddress.line1Label"
            placeholder="manualAddress.line1Placeholder"
            model={model.addressLine1}
            required
            autocomplete="off"
            onSelected={onChangeSuggestedAddress}
            eventPage="vendor-delivery-method"
          />
        </FormRow>
        <FormRow>
          <WizardTextInputField
            label="manualAddress.line2Label"
            placeholder="manualAddress.line2Placeholder"
            model={model.addressLine2}
            autocomplete="off"
            required
            autoFocus
            privateData
          />
        </FormRow>
        <FormRow>
          <WizardTextInputField
            label="manualAddress.cityLabel"
            model={model.city}
            autocomplete="off"
            required
            autoFocus
            privateData
          />
          <WizardSelectField
            id="state"
            label="manualAddress.stateLabel"
            model={model.state}
            options={US_STATES}
            required
            privateData
          />
        </FormRow>
        <FormRow>
          <WizardTextInputField
            label="manualAddress.zipCodeLabel"
            model={model.zipCode}
            autocomplete="off"
            required
            autoFocus
            privateData
          />
        </FormRow>
      </FormContainer>
    </FormStyle>
  );
};

export default CheckDeliveryMethodForm;

const FormStyle = styled.div`
  width: 100%;
  ${(props) => props.theme?.pages?.EditCheckAddressDeliveryMethodPage?.CheckDeliveryMethodForm}

  .input-container {
    margin-bottom: 0;
  }
`;
