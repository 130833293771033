import React from 'react';
import { useSelector } from 'react-redux';
import AreaLoader from 'src/components/common/AreaLoader';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import paymentStore from 'src/modules/payments/payment-store';
import { getJWTPayload } from 'src/helpers/jwt';
import { CONSTS } from 'src/utils/consts';
import { DefaultErrorPage } from 'src/pages/vendor/components/DefaultErrorPage';
import { PaymentCancelledPage } from 'src/pages/vendor/components/PaymentCancelledPage';

type Props = {
  token: string;
};

export function InvalidLinkPage({ token }: Props) {
  const { paymentId } = getJWTPayload(token);
  const { errorData } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation);
  const payment = useSelector(paymentStore.selectors.byId(paymentId));
  const error = errorData || invalidTokenData;
  const isPaymentProcessedError =
    payment?.status === CONSTS.PAYMENT_STATUS.COMPLETED || payment?.status === CONSTS.PAYMENT_STATUS.FAILED;

  if (!error && !isPaymentProcessedError) {
    return <AreaLoader />;
  }

  switch (error?.code) {
    case 'PMT17':
      return <PaymentCancelledPage token={token} />;
    default:
      return <DefaultErrorPage token={token} />;
  }
}
