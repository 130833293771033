import { parseISO } from 'date-fns';
import { GetPaymentForSyncResponse } from 'src/services/api/qbdt';
import { txnDelete } from './txn';
import { executeSDKRequest, getSingleEntity, QBDTSDKError } from '../sdk';
import {
  QBDTAccount,
  QBDTAccountType,
  QBDTBillPaymentCheck,
  QBDTBillPaymentCreditCard,
  QBDTTxnDelType,
  QBDTTxnType,
  RetryOptions,
  BillsData,
} from '../types';
import { mapQBDTBillPaymentCheck, mapQBDTBillPaymentCreditCard } from '../typeMapper';
import { amountFormat, escapeXMLString, formatQBDTDate, fundingSourceToAccountType } from '../util';
import { SDK_STATUS_CODES } from '../constants';

/**
 * Get bill payment check by ID
 * @param billPaymentCheckId
 */
export function getBillPaymentCheck(billPaymentCheckId: string) {
  return getSingleEntity(
    `<BillPaymentCheckQueryRq>
                <TxnID>${billPaymentCheckId}</TxnID>
                <IncludeLineItems>true</IncludeLineItems>
            </BillPaymentCheckQueryRq>`,
    mapQBDTBillPaymentCheck
  );
}

/**
 * Get bill payment credit card by ID
 * @param billPaymentCreditCardId
 */
export function getBillPaymentCreditCard(billPaymentCreditCardId: string) {
  return getSingleEntity(
    `<BillPaymentCreditCardQueryRq>
                <TxnID>${billPaymentCreditCardId}</TxnID>
                <IncludeLineItems>true</IncludeLineItems>
            </BillPaymentCreditCardQueryRq>`,
    mapQBDTBillPaymentCreditCard
  );
}

type CreateBillPaymentCheckParams = {
  melioPayment: GetPaymentForSyncResponse['payment'];
  billsData: BillsData;
  qbdtBankAccount: QBDTAccount;
  retryOptions?: RetryOptions;
};

export async function createBillPaymentCheck({
  melioPayment,
  billsData,
  qbdtBankAccount,
  retryOptions,
}: CreateBillPaymentCheckParams) {
  // In bulk payment case (where BillPayment can be linked to several Bills)
  // on Melio side we're controlling that grouped Bills will be only for the same vendor.
  // On QBDT side it's conrolled that user can start flow where Bills are linked only to one APAccount.
  // So it's ok to get the info from first bill in the group
  const { qbdtBill } = billsData[0];
  return executeSDKRequest(
    `
    <BillPaymentCheckAddRq>
      <BillPaymentCheckAdd>
        <PayeeEntityRef>
          <ListID>${qbdtBill.VendorRef?.ListID}</ListID>
          <FullName>${escapeXMLString(qbdtBill.VendorRef?.FullName)}</FullName>
        </PayeeEntityRef>
        <APAccountRef>
          <ListID>${qbdtBill.APAccountRef?.ListID}</ListID>
          <FullName>${escapeXMLString(qbdtBill.APAccountRef?.FullName)}</FullName>
        </APAccountRef>
        <TxnDate>${formatQBDTDate(parseISO(melioPayment.scheduledDate))}</TxnDate>
        <BankAccountRef>
          <ListID>${qbdtBankAccount.ListID}</ListID>
          <FullName>${escapeXMLString(qbdtBankAccount.FullName)}</FullName>
        </BankAccountRef>
        <RefNumber></RefNumber>
        ${billsData
          .map(
            ({ qbdtBill, amount }) => `
              <AppliedToTxnAdd>
                <TxnID>${qbdtBill.TxnID}</TxnID>
                <PaymentAmount>${amountFormat(amount)}</PaymentAmount>
              </AppliedToTxnAdd>
          `
          )
          .join('')}
      </BillPaymentCheckAdd>
    </BillPaymentCheckAddRq>
  `,
    retryOptions
  );
}

export async function modifyBillPaymentCheck(
  melioPayment: GetPaymentForSyncResponse['payment'],
  qbdtBillPaymentCheck: QBDTBillPaymentCheck,
  qbdtBankAccount: QBDTAccount
) {
  return executeSDKRequest(`
    <BillPaymentCheckModRq>
      <BillPaymentCheckMod>
        <TxnID>${qbdtBillPaymentCheck.TxnID}</TxnID>
        <EditSequence>${qbdtBillPaymentCheck.EditSequence}</EditSequence>
        <TxnDate>${formatQBDTDate(parseISO(melioPayment.scheduledDate))}</TxnDate>
        <BankAccountRef>
          <ListID>${qbdtBankAccount.ListID}</ListID>
          <FullName>${escapeXMLString(qbdtBankAccount.FullName)}</FullName>
        </BankAccountRef>
      </BillPaymentCheckMod>
    </BillPaymentCheckModRq>
  `);
}

type CreateBillPaymentCreditCardParams = {
  melioPayment: GetPaymentForSyncResponse['payment'];
  billsData: BillsData;
  qbdtCreditCardAccount: QBDTAccount;
  retryOptions?: RetryOptions;
};

export async function createBillPaymentCreditCard({
  melioPayment,
  billsData,
  qbdtCreditCardAccount,
  retryOptions,
}: CreateBillPaymentCreditCardParams) {
  // In bulk payment case (where BillPayment can be linked to several Bills)
  // on Melio side we're controlling that grouped Bills will be only for the same vendor.
  // On QBDT side it's conrolled that user can start flow where Bills are linked only to one APAccount.
  // So it's ok to get the info from first bill in the group
  const { qbdtBill } = billsData[0];
  return executeSDKRequest(
    `
      <BillPaymentCreditCardAddRq>
        <BillPaymentCreditCardAdd>
          <PayeeEntityRef>
            <ListID>${qbdtBill.VendorRef?.ListID}</ListID>
            <FullName>${escapeXMLString(qbdtBill.VendorRef?.FullName)}</FullName>
          </PayeeEntityRef>
          <APAccountRef>
            <ListID>${qbdtBill.APAccountRef?.ListID}</ListID>
            <FullName>${escapeXMLString(qbdtBill.APAccountRef?.FullName)}</FullName>
          </APAccountRef>
          <TxnDate>${formatQBDTDate(parseISO(melioPayment.scheduledDate))}</TxnDate>
          <CreditCardAccountRef>
            <ListID>${qbdtCreditCardAccount.ListID}</ListID>
            <FullName>${escapeXMLString(qbdtCreditCardAccount.FullName)}</FullName>
          </CreditCardAccountRef>
          ${billsData
            .map(
              ({ qbdtBill, amount }) => `
                <AppliedToTxnAdd>
                  <TxnID>${qbdtBill.TxnID}</TxnID>
                  <PaymentAmount>${amountFormat(amount)}</PaymentAmount>
                </AppliedToTxnAdd>
            `
            )
            .join('')}
        </BillPaymentCreditCardAdd>
      </BillPaymentCreditCardAddRq>
    `,
    retryOptions
  );
}

export async function deleteBillPayment(entityId: string) {
  let billPayment: QBDTBillPaymentCheck | QBDTBillPaymentCreditCard;
  try {
    billPayment = await getBillPaymentCheck(entityId);
    await txnDelete(QBDTTxnDelType.BillPaymentCheck, entityId);
  } catch (e: any) {
    if (e.code === SDK_STATUS_CODES.OBJECT_NOT_FOUND) {
      billPayment = await getBillPaymentCreditCard(entityId);
      await txnDelete(QBDTTxnDelType.BillPaymentCreditCard, entityId);
    } else {
      throw e;
    }
  }

  return billPayment;
}

export async function getBillPaymentAny(entityId: string) {
  try {
    return await getBillPaymentCheck(entityId);
  } catch (error: any) {
    if (!(error instanceof QBDTSDKError && error.code === '500')) {
      throw error;
    }
  }

  try {
    return await getBillPaymentCreditCard(entityId);
  } catch (error: any) {
    if (!(error instanceof QBDTSDKError && error.code === '500')) {
      throw error;
    }
  }

  return null;
}

export function equalsBillPaymentForSync(
  qbdtPayment: QBDTBillPaymentCheck | QBDTBillPaymentCreditCard,
  melioPayment,
  melioFundingSource
) {
  const accountType = fundingSourceToAccountType(melioFundingSource);

  if (accountType === QBDTAccountType.Bank && qbdtPayment.TxnType !== QBDTTxnType.BillPaymentCheck) {
    return false;
  }

  if (accountType === QBDTAccountType.CreditCard && qbdtPayment.TxnType !== QBDTTxnType.BillPaymentCreditCard) {
    return false;
  }

  return qbdtPayment.Amount === Number.parseFloat(melioPayment.amount);
}
