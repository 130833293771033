import noop from 'lodash/noop';
import { put, takeEvery, all, call, select, getContext } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { AccountRecord, CompanyInfoRecord } from 'src/records/settings.record';
import { DeliveryMethodRecord } from 'src/records/vendor';
import { zendeskService } from 'src/services/zendesk';
import { logger } from 'src/services/loggers';
import authApi from '../../services/api/auth';
import financialAccountsApi from '../../services/api/financialAccounts';
import deliveryMethodsApi from '../../services/api/deliveryMethods';
import userPreferencesApi from '../../services/api/userPreferences';
import {
  LOAD_FUNDING_SOURCES,
  DELETE_FUNDING_SOURCE,
  LOAD_PROFILE,
  LOAD_DELIVERY_METHODS,
  LOAD_COMPANY_INFO,
  UPDATE_USER_PREFERENCE,
  CHECK_AND_INIT_USER,
  VERIFY_FUNDING_SOURCE,
  CLEAR_USER_INFO,
  CLEAR_STATE,
  CLEAR_USER_INFO_FINISH,
} from './actionTypes';
import {
  loadFundingSourcesSuccessAction,
  loadFundingSourcesFailedAction,
  deleteFundingSourceSuccessAction,
  deleteFundingSourceFailedAction,
  loadProfileSuccessAction,
  loadProfileFailedAction,
  clearUserInfoAction,
  loadDeliveryMethodsSuccessAction,
  loadDeliveryMethodsFailedAction,
  loadCompanyInfoFailedAction,
  loadCompanyInfoSuccessAction,
  updateUserPreferenceSuccessAction,
  updateUserPreferenceFailedAction,
  setProfileAction,
  setUserPreferencesAction,
  checkAndInitUserFinishAction,
  initUserSuccessAction,
  verifyFundingSourceSuccessAction,
  verifyFundingSourceFailedAction,
  loadFundingSourcesAction,
  loadProfileAction,
  deleteFundingSourceAction,
  checkAndInitUserAction,
  loadCompanyInfoAction,
  verifyFundingSourceAction,
  loadDeliveryMethodsAction,
  updateUserPreferenceAction,
} from './actions';
import { UserContextRecord, UserPreferencesRecord } from '../../context/records';
import { getOrgId, getOwnedVendorId, getCompanyInfo, getCanContactSupport } from './selectors';
import organizationApi from '../../services/api/organizations';
import {
  setOrganizationPreferencesAction,
  loadFeeCatalogSuccessAction,
  loadFeeCatalogFailedAction,
} from '../organization/actions';
import { isManualBankAccountNotVerified } from '../../utils/funding-sources';
import { CONSTS, RESTRICTED_ORIGINS } from '../../utils/consts';
import * as featureFlagsService from '../../services/featureFlags';

function* calculateBankAccountStatuses({ orgId, fundingSource }) {
  try {
    if (isManualBankAccountNotVerified(fundingSource)) {
      yield call(financialAccountsApi.getVerificationStatus, orgId, fundingSource.id, {});
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: true,
        isBlocked: false,
      };
      return fundingSource.merge({ bankAccount });
    }

    return fundingSource;
  } catch (e: any) {
    if (e.code === CONSTS.VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.NOT_FOUND) {
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: false,
        isBlocked: false,
      };
      return fundingSource.merge({ bankAccount });
    }

    if (e.code === CONSTS.VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS) {
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: false,
        isBlocked: true,
      };
      return fundingSource.merge({ bankAccount });
    }

    return fundingSource;
  }
}

function* loadFundingSources({ resolve = noop, reject = noop }: Partial<ReturnType<typeof loadFundingSourcesAction>>) {
  const orgId = yield select(getOrgId);
  try {
    const { fundingSources } = yield call(financialAccountsApi.getFundingSources, orgId, {
      includeDeleted: true,
    });
    const fundingSourcesRecords = yield all(
      fundingSources
        .map((fundingSource) => AccountRecord(fundingSource))
        .map((fundingSource) => calculateBankAccountStatuses({ orgId, fundingSource }))
    );

    yield put(loadFundingSourcesSuccessAction(fundingSourcesRecords));
    resolve();
  } catch (e) {
    yield put(loadFundingSourcesFailedAction());
    reject();
  }
}

function* loadProfile({ resolve = noop, reject = noop }: ReturnType<typeof loadProfileAction>) {
  try {
    const { user } = yield call(authApi.check);
    yield put(loadProfileSuccessAction(UserContextRecord(user)));
    resolve();
  } catch (e) {
    yield put(loadProfileFailedAction());
    yield put(clearUserInfoAction());
    reject();
  }
}

function* deleteFundingSource({ id, resolve, reject }: ReturnType<typeof deleteFundingSourceAction>) {
  const orgId = yield select(getOrgId);

  try {
    yield call(financialAccountsApi.deleteFundingSource, orgId, id);
    yield put(deleteFundingSourceSuccessAction(id));
    resolve();
  } catch (e) {
    yield put(deleteFundingSourceFailedAction());
    reject(e);
  }
}

function* verifyFundingSource({
  id,
  token,
  amount1,
  amount2,
  resolve,
  reject,
}: ReturnType<typeof verifyFundingSourceAction>) {
  try {
    const orgId = yield select(getOrgId);
    const { verificationResult } = token
      ? yield call(financialAccountsApi.verifyMicroDepositsWithToken, {
          token,
          id,
          amount1,
          amount2,
        })
      : yield call(financialAccountsApi.verifyMicroDeposits, orgId, {
          id,
          amount1,
          amount2,
        });
    if (verificationResult) {
      yield put(verifyFundingSourceSuccessAction(id));
      resolve();
    } else {
      yield put(
        verifyFundingSourceFailedAction(
          id,
          CONSTS.VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.ERR_VERIFY_MICRO_DEPOSITS
        )
      );
      reject({
        code: CONSTS.VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.ERR_VERIFY_MICRO_DEPOSITS,
      });
    }
  } catch (e: any) {
    yield put(verifyFundingSourceFailedAction(id, e ? e.code : ''));
    reject(e);
  }
}

function* loadDeliveryMethods({
  resolve = noop,
  reject = noop,
}: Partial<ReturnType<typeof loadDeliveryMethodsAction>>) {
  try {
    const orgId = yield select(getOrgId);
    const ownedVendorId = yield select(getOwnedVendorId);
    const { deliveryMethods } = yield call(deliveryMethodsApi.getDeliveryMethods, orgId, ownedVendorId);
    const deliveryMethodsRecords = deliveryMethods.map((deliveryMethod) => DeliveryMethodRecord(deliveryMethod));
    yield put(loadDeliveryMethodsSuccessAction(deliveryMethodsRecords));
    resolve();
  } catch (e) {
    yield put(loadDeliveryMethodsFailedAction());
    reject();
  }
}

function* loadFeeCatalog({ resolve = noop, reject = noop }) {
  try {
    const orgId = yield select(getOrgId);
    const { fees: feeCatalog } = yield organizationApi.getOrganizationFeeCatalog(orgId);

    yield put(loadFeeCatalogSuccessAction(feeCatalog));
    resolve();
  } catch (e) {
    yield put(loadFeeCatalogFailedAction());
    reject();
  }
}

function* loadCompanyInfo({ resolve = noop, reject = noop }: Partial<ReturnType<typeof loadCompanyInfoAction>>) {
  try {
    const orgId = yield select(getOrgId);
    const { localCompanyInfo, organizationPreferences } = yield call(organizationApi.getCompanyInfo, orgId, true);
    const companyInfoRecord = CompanyInfoRecord(localCompanyInfo);
    yield put(setOrganizationPreferencesAction(organizationPreferences));
    yield put(loadCompanyInfoSuccessAction(companyInfoRecord as any));
    resolve();
  } catch (e) {
    yield put(loadCompanyInfoFailedAction());
    reject();
  }
}

function* updateUserPreference({ id, value, resolve, reject }: ReturnType<typeof updateUserPreferenceAction>) {
  try {
    yield call(userPreferencesApi.updateUserPreference, id, value);
    yield put(updateUserPreferenceSuccessAction(id, value));
    resolve();
  } catch (e) {
    yield put(updateUserPreferenceFailedAction());
    reject(e);
  }
}

function* watchLoadCompanyInfo() {
  yield takeEvery(LOAD_COMPANY_INFO, loadCompanyInfo);
}

function* watchDeleteFundingSource() {
  yield takeEvery(DELETE_FUNDING_SOURCE, deleteFundingSource);
}

function* watchVerifyFundingSource() {
  yield takeEvery(VERIFY_FUNDING_SOURCE, verifyFundingSource);
}

function* watchLoadFundingSources() {
  yield takeEvery(LOAD_FUNDING_SOURCES, loadFundingSources);
}

function* watchLoadProfile() {
  yield takeEvery(LOAD_PROFILE, loadProfile);
}

function* watchLoadDeliveryMethods() {
  yield takeEvery(LOAD_DELIVERY_METHODS, loadDeliveryMethods);
}

function* watchUpdateUserPreferenceMethod() {
  yield takeEvery(UPDATE_USER_PREFERENCE, updateUserPreference);
}

function* initUserContext({ user, isLoggedInAs = false, resolve, reject }) {
  try {
    logger.setSessionContext({ userId: user.id, orgId: user.orgId, email: user.email });
    yield all([
      put(setProfileAction(UserContextRecord(user), user.organizations)),
      put(setUserPreferencesAction(UserPreferencesRecord(user.userPreferences))),
    ]);

    yield all([call(loadCompanyInfo, {}), call(loadFundingSources, {}), call(loadFeeCatalog, {})]);

    const [ownedVendorId, companyInfo] = yield all([select(getOwnedVendorId), select(getCompanyInfo)]);
    const canContactSupport = yield select(getCanContactSupport);

    // TODO: temporal solution
    zendeskService.initialize({
      isLoggedInAs,
      user,
      canContactSupport,
    });

    yield call(featureFlagsService.identify, user, companyInfo);

    const intl = yield getContext('intl');
    intl.setDefaultValue('fees', companyInfo.billingSetting.fee);
    if (ownedVendorId) {
      yield call(loadDeliveryMethods, {});
    }

    yield put(initUserSuccessAction(isLoggedInAs));
    resolve({ companyInfo, user });
  } catch (e) {
    yield put(clearUserInfoAction());
    reject(e);
  }
}
function* clearUserContext() {
  yield put({ type: CLEAR_STATE });
  zendeskService.disable();
  Sentry.configureScope((scope) => {
    scope.setUser({ email: '', id: '' });
  });
  yield put({ type: CLEAR_USER_INFO_FINISH });
}

function getCurrentOrgInfo(checkResult, orgId) {
  const orgToSelect = orgId || checkResult.orgId;
  let org = checkResult.organizations.find((org) => org.id === orgToSelect);
  if (!org) {
    [org] = checkResult.organizations;
  }

  return {
    orgId: org.id,
    orgName: org.companyName,
  };
}
function filterOrganizationsByOrigin(orgs, wantedOrigins) {
  if (wantedOrigins.length > 0) {
    return orgs.filter((o) => wantedOrigins.includes(o.createOrigin));
  }

  return orgs.filter((o) => !RESTRICTED_ORIGINS.includes(o.createOrigin));
}
export function* checkAndInitUserInfo({
  orgId,
  origins = [],
  resolve = noop,
  reject = noop,
}: ReturnType<typeof checkAndInitUserAction>) {
  try {
    const response = yield call(authApi.check);
    if (!response || !response.user) {
      yield put(clearUserInfoAction());
      reject();
      return;
    }

    let { user } = response;

    user.organizations = filterOrganizationsByOrigin(user.organizations, origins);

    user = {
      ...user,
      ...getCurrentOrgInfo(user, orgId),
    };

    yield call(initUserContext, { user, isLoggedInAs: false, resolve, reject });
    yield put(checkAndInitUserFinishAction());
  } catch (e: any) {
    logger.error('userSaga.checkAndInitUserInfo(): failed', e);
    yield put(clearUserInfoAction());
    reject(e);
  }
}

function* watchClearUserInfo() {
  yield takeEvery(CLEAR_USER_INFO, clearUserContext);
}

function* watchCheckAndInitUser() {
  yield takeEvery(CHECK_AND_INIT_USER, checkAndInitUserInfo);
}

export default function* payBillFlowSagas() {
  yield all([
    watchLoadFundingSources(),
    watchLoadProfile(),
    watchDeleteFundingSource(),
    watchLoadDeliveryMethods(),
    watchLoadCompanyInfo(),
    watchUpdateUserPreferenceMethod(),
    watchCheckAndInitUser(),
    watchVerifyFundingSource(),
    watchClearUserInfo(),
  ]);
}
