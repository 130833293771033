import React, { useEffect } from 'react';
import analytics from 'src/services/analytics';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { ActionButtonsWrapper } from './ActionButtonsWrapper';
import { useRetryVirtualCardDelivery } from '../hooks/useRetryVirtualCardDelivery';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';

const eventPage = 'pay-bill';

export const ExpiredVirtualCardActions = () => {
  const { onRetryVirtualCardDelivery } = useRetryVirtualCardDelivery();
  const { bill, payment } = useViewPaymentContext();

  useEffect(() => {
    analytics.track(eventPage, 'has-option-to-retry-vc', {
      paymentId: payment.id,
      billId: bill.id,
    });
  }, []);

  return (
    <ActionButtonsWrapper align="center">
      <MIButton
        label="bills.form.paymentActivity.actions.retryPayment"
        testId="view-payment-retry-virtual-card-delivery-action"
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={onRetryVirtualCardDelivery}
      />
    </ActionButtonsWrapper>
  );
};
