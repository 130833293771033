import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@melio/billpay-design-system';
import { encodeQuery } from 'src/utils/query-utils';
import { ReactComponent as BillsEmptyUnpaid } from './images/bills-empty-unpaid.svg';
import { ReactComponent as BillsEmptyScheduled } from './images/bills-empty-scheduled.svg';
import { ReactComponent as BillsEmptyPaid, ReactComponent as BillsEmptySyncing } from './images/bills-empty-paid.svg';

import { DashboardTabStatusEnum } from '../../consts';
import DashboardEmptyStateContent from './components/DashboardEmptyStateContent';
import { useIsBillsSyncing } from '../../sync/hooks/useIsBillsSyncing';

const SYNC_TYPE = 'sync';

const getPageContentByType = (tabStatus: DashboardTabStatusEnum, isSyncing: boolean) => {
  const listEmptyTextMap = {
    [DashboardTabStatusEnum.Unpaid]: {
      image: BillsEmptyUnpaid,
      title: 'paymentDashboard.listEmptyState.unpaid.title',
      description: 'paymentDashboard.listEmptyState.unpaid.description',
      goTo: null,
    },
    [DashboardTabStatusEnum.Scheduled]: {
      image: BillsEmptyScheduled,
      title: 'paymentDashboard.listEmptyState.scheduled.title',
      description: 'paymentDashboard.listEmptyState.scheduled.description',
      goTo: DashboardTabStatusEnum.Unpaid,
    },
    [DashboardTabStatusEnum.Paid]: {
      image: BillsEmptyPaid,
      title: 'paymentDashboard.listEmptyState.paid.title',
      description: 'paymentDashboard.listEmptyState.paid.description',
      goTo: DashboardTabStatusEnum.Unpaid,
    },
    [SYNC_TYPE]: {
      image: BillsEmptySyncing,
      title: 'paymentDashboard.listEmptyState.syncing.title',
      description: 'paymentDashboard.listEmptyState.syncing.description',
      goTo: null,
    },
  };

  const emptyStateType = tabStatus === DashboardTabStatusEnum.Unpaid && isSyncing ? SYNC_TYPE : tabStatus;

  const mapItem = listEmptyTextMap[emptyStateType];

  return (
    mapItem && {
      ...mapItem,
      descriptionValues: {
        link: (value) => (
          <Link
            as={NavLink}
            data-testid="empty-state-navigate-link"
            to={{
              search: encodeQuery({ status: mapItem.goTo }, [], ''),
            }}
          >
            {value}
          </Link>
        ),
      },
    }
  );
};

type Props = {
  tabStatus: DashboardTabStatusEnum;
};

export const DashboardEmptyState = (props: Props) => {
  const isBillsSyncing = useIsBillsSyncing();

  return <DashboardEmptyStateContent {...getPageContentByType(props.tabStatus, isBillsSyncing)} />;
};
