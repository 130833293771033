import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { useBreak } from 'src/hoc';

type Props = {
  children: React.ReactNode;
};

export const DesktopSubmitContainer = ({ children }: Props) => {
  const { isDesktop } = useBreak();

  if (!isDesktop) {
    return null;
  }

  return (
    <Flex direction="column" gap="ds.md" w="100%" align="center">
      {children}
    </Flex>
  );
};
