import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { DeliveryMethodType, PaymentFeeItem, PaymentType } from 'src/utils/types';
import { useApi } from 'src/hoc/useApi';
import paymentsApi from 'src/services/api/payments';
import { DELIVERY_TYPE, PAYMENT_DELIVER_STATUS, PAYMENT_STATUS } from 'src/utils/consts';
import { isVirtualCardCleared } from 'src/utils/virtual-card';
import locations from '../locations';

type Props = {
  token: string;
};

type ApiRequest = { token: string; action: string };
type ApiResponse = {
  payment: PaymentType;
  fee: PaymentFeeItem;
};

const PAYMENT_CANCELLED_ERROR_CODE = 'PMT17';

const checkIsOfferHasExpired = ({
  deliveryMethod,
  payment,
}: {
  deliveryMethod: DeliveryMethodType;
  payment: PaymentType;
}) => deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK && payment.isCheckToP2DOfferExpired;

export const usePushToDebitState = ({ token }: Props) => {
  const history = useHistory();
  const [fetchPaymentDetails, paymentDetailsResponse, , error] = useApi<[ApiRequest], ApiResponse>(
    paymentsApi.getDetailsWithToken
  );
  const payment = paymentDetailsResponse?.payment;
  const organization = payment?.organization;

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const response = await fetchPaymentDetails({
          token,
          action: 'addDebitCardToVendor',
        });

        const { payment } = response;
        const currentDeliveryMethod = payment.deliveryMethod;

        if (payment.status === PAYMENT_STATUS.FAILED) {
          history.push(generatePath(locations.paymentFailed, { token }));
          return;
        }

        if (payment.status === PAYMENT_STATUS.BLOCKED) {
          history.push(generatePath(locations.paymentBlocked, { token }));
          return;
        }

        if (
          currentDeliveryMethod.deliveryType === DELIVERY_TYPE.CHECK &&
          payment.deliverStatus === PAYMENT_DELIVER_STATUS.CLEARED
        ) {
          history.push(generatePath(locations.checkDeposited, { token }));
          return;
        }

        if (currentDeliveryMethod.deliveryType === DELIVERY_TYPE.CARD && payment.status === PAYMENT_STATUS.COMPLETED) {
          history.push(generatePath(locations.addDebitCardCompletedPage, { token }));
          return;
        }

        if (currentDeliveryMethod.deliveryType === DELIVERY_TYPE.CARD) {
          history.push(generatePath(locations.addDebitCardSuccessPage, { token }));
          return;
        }

        if (
          currentDeliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD &&
          payment.status === PAYMENT_STATUS.COMPLETED &&
          isVirtualCardCleared(payment)
        ) {
          history.push(generatePath(locations.addVirtualCardMethodCompleted, { token }));
          return;
        }

        if (
          currentDeliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD &&
          payment.status === PAYMENT_STATUS.COMPLETED
        ) {
          history.push(generatePath(locations.addVirtualCardMethodNotCleared, { token }));
          return;
        }

        if (currentDeliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
          history.push(generatePath(locations.addVirtualCardMethodSuccess, { token }));
          return;
        }

        if (currentDeliveryMethod.deliveryType === DELIVERY_TYPE.ACH && payment.status === PAYMENT_STATUS.COMPLETED) {
          history.push(generatePath(locations.achDeposited, { token }));
          return;
        }

        if (checkIsOfferHasExpired({ payment, deliveryMethod: currentDeliveryMethod })) {
          history.push(generatePath(locations.offerHasExpired, { token }));
        }
      } catch (error: any) {
        if (error?.code === PAYMENT_CANCELLED_ERROR_CODE) {
          const dataToShow = error?.responseData?.validationErrors || {
            companyName: '',
            email: '',
          };
          history.push(generatePath(locations.paymentCanceled, { token }), dataToShow);
          return;
        }

        history.push(generatePath(locations.invalidToken, { token }));
      }
    };

    fetchPaymentData();
  }, []);

  return {
    // loading from useApi become false before payment or error will be defined.
    // Decided to use payment and error presence as loading flag
    isLoading: !payment && !error,
    payment,
    organization,
  };
};
