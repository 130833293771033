import { RefObject, useCallback, useRef, useState } from 'react';
import { useBasisTheory } from '@basis-theory/basis-theory-react';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-js/types/elements/elements';
import { RetrievedTokenizedData } from 'src/billpay/qbdt/components/basis-theory/types';
import { BasisTheoryCardBrandType } from 'src/billpay/qbdt/components/basis-theory/brands-logos/types';
import { BasisTheoryCardsBrands } from 'src/billpay/qbdt/components/basis-theory/brands-logos/consts';
import config from 'src/config';

type Props = {
  cardNumberElementRef: RefObject<CardNumberElement>;
  cardExpirationDateElementRef: RefObject<CardExpirationDateElement>;
  cardVerificationCodeElementRef: RefObject<CardVerificationCodeElement>;
  onLinkCard(tokenizedData: RetrievedTokenizedData): void;
  onError?(error: unknown): void;
};
export const useLinkCard = ({
  cardNumberElementRef,
  cardVerificationCodeElementRef,
  cardExpirationDateElementRef,
  onLinkCard,
  onError,
}: Props) => {
  const { bt } = useBasisTheory(config.services.basisTheory.key, { elements: true });
  const [areFieldsLoading, setFieldsLoading] = useState(true);
  const [attemptedToLink, setAttemptedToLink] = useState(false);
  const [isCardLinking, setIsLinkingCard] = useState(false);
  const [cardBrand, setCardBrand] = useState<BasisTheoryCardBrandType>(BasisTheoryCardsBrands.unknown);
  const errorRef = useRef(true);

  const handleLinkCard = useCallback(async () => {
    setAttemptedToLink(true);

    if (errorRef.current) {
      return;
    }

    setIsLinkingCard(true);

    let tokenizedData;
    let cardNumberTokenizedData;
    try {
      tokenizedData = await bt?.tokenize({
        type: 'card',
        data: {
          number: cardNumberElementRef.current,
          expiration_month: cardExpirationDateElementRef.current?.month() || null,
          expiration_year: cardExpirationDateElementRef.current?.year() || null,
          cvc: cardVerificationCodeElementRef.current,
        },
        mask: {
          number: '{{ data.number | slice: 0, 6 }}XXXXXX{{ data.number | last4 }}',
          expiration_month: '{{ data.expiration_month }}',
          expiration_year: '{{ data.expiration_year }}',
        },
      });

      cardNumberTokenizedData = await bt?.tokenize({
        type: 'card_number',
        data: cardNumberElementRef.current,
      });
    } catch (e) {
      onError?.(e);
    }

    if (tokenizedData && cardNumberTokenizedData) {
      const {
        id,
        data: { number, expiration_month: expirationMonth, expiration_year: expirationYear },
      } = tokenizedData;

      const { id: cardNumberId, fingerprint: cardNumberFingerprint } = cardNumberTokenizedData;

      setIsLinkingCard(false);

      onLinkCard({
        id,
        expirationMonth,
        expirationYear,
        last4Digits: number.slice(-4),
        cardBin: number.slice(0, 6),
        cardNumberIdentifiers: {
          token: cardNumberId,
          fingerprint: cardNumberFingerprint,
        },
      });
    }
  }, [bt, onError, onLinkCard]);

  const handleReady = useCallback(() => {
    setFieldsLoading(false);
  }, []);

  const handleCardBrandChange = useCallback((cardBrand: BasisTheoryCardBrandType) => {
    setCardBrand(cardBrand);
  }, []);

  const handleErrorStateChange = useCallback((hasError: boolean) => {
    errorRef.current = hasError;
  }, []);

  return {
    handleCardBrandChange,
    handleReady,
    handleErrorStateChange,
    handleLinkCard,
    cardBrand,
    areFieldsLoading,
    attemptedToLink,
    isCardLinking,
  };
};
