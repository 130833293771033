import * as React from 'react';
import { RecordOf } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { withSiteContext } from 'src/hoc/withSiteContext';
import MINotificationCard from 'src/components/common/MINotificationCard';
import { CONSTS } from 'src/utils/consts';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import { FieldType, BankType, DeliveryMethodOriginType } from 'src/utils/types';
import { MITextInput } from '../../../../components/common/MITextInput';
import MIPasswordInput from '../../../../components/common/MIPasswordInput';
import { MIFormattedText } from '../../../../utils/formatting';

type Props = {
  onDone: () => void;
  goExit: () => void;
  bank: RecordOf<BankType>;
  onChange: (arg0: FieldType) => void;
  isLoading?: boolean;
  validationErrors: Record<string, string>;
  origin: DeliveryMethodOriginType;
  errorCode: string;
  vendorName: string;
  companyName: string;
  site: any;
  titleValues?: Record<string, any>;
};

class EditBankDeliveryMethodPage extends React.PureComponent<Props> {
  render() {
    const {
      isLoading,
      goExit,
      onDone,
      onChange,
      validationErrors,
      bank,
      origin,
      errorCode,
      vendorName,
      site,
      titleValues,
    } = this.props;
    const { routingNumber, accountNumber } = bank;
    const { SimpleTextFooter, WizardFormRow } = site.components.WizardElements;

    const stepLayoutPagePropsMap = {
      [CONSTS.DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS]: {
        title: isEmpty(vendorName)
          ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
          : 'vendors.deliveryMethods.bank.title',
        titleValues: { vendor: vendorName },
        goExit,
        onSubmit: onDone,
        nextLabel: 'vendors.deliveryMethods.bank.save',
        isLoading,
        footer: (
          <SimpleTextFooter>
            <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
          </SimpleTextFooter>
        ),
        fullWidthCTA: true,
      },
      [CONSTS.DELIVERY_METHOD_ORIGIN.EDIT_PAYMENT]: {
        title: 'bills.pay.editAchDeliveryMethod.title',
        titleValues,
        relativeStep: 1 / 2,
        onSubmit: onDone,
        nextLabel: 'bills.pay.editAchDeliveryMethod.save',
        isLoading,
        goExit,
        fullWidthCTA: true,
      },
    };

    const inputLabelsMap = {
      [CONSTS.DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS]: {
        routingNumber: 'vendors.deliveryMethods.bank.routingNumber',
        accountNumber: 'vendors.deliveryMethods.bank.accountNumber',
      },
      [CONSTS.DELIVERY_METHOD_ORIGIN.EDIT_PAYMENT]: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
    };
    const stepLayoutPageProps =
      stepLayoutPagePropsMap[origin] || stepLayoutPagePropsMap[CONSTS.DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS];
    const inputLabels = inputLabelsMap[origin] || inputLabelsMap[CONSTS.DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS];
    const shouldShowValue = routingNumber === '';

    return (
      <QBDTStepLayout hideHeader {...stepLayoutPageProps}>
        {errorCode && (
          <NotificationCard
            type={CONSTS.NOTIFICATION_CARD_TYPES.ERROR}
            subtitle={{
              label: `server.${errorCode}`,
            }}
          />
        )}
        <WizardFormRow>
          <MITextInput
            id="routingNumber"
            value={routingNumber}
            label={inputLabels.routingNumber}
            onChange={onChange}
            type="tel"
            autoFocus
            required
            errorMessage={validationErrors.routingNumber}
            autocomplete="off"
          />
        </WizardFormRow>

        <WizardFormRow>
          <MIPasswordInput
            id="accountNumber"
            value={accountNumber}
            label={inputLabels.accountNumber}
            onChange={onChange}
            required
            errorMessage={validationErrors.accountNumber}
            shouldShowValue={shouldShowValue}
            autocomplete="off"
            pattern={CONSTS.INPUT_PATTERNS.NUMBERS}
            inputMode="numeric"
          />
          <HiddenInput type="password" autoComplete="new-password" />
        </WizardFormRow>
      </QBDTStepLayout>
    );
  }
}

export default withSiteContext()(EditBankDeliveryMethodPage);

const HiddenInput = styled.input`
  display: none;
`;

const NotificationCard = styled(MINotificationCard)`
  margin: 0 0 4rem;
`;
