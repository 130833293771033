import React from 'react';
import head from 'lodash/head';
import { RecordOf } from 'immutable';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { withNavigator } from 'src/hoc';
import { GlobalState } from 'src/redux/types';
import api from 'src/services/api/delivery';
import locations from 'src/billpay/qbdt/pages/locations';
import analytics from 'src/services/analytics';
import { getInitialProcessingDates } from 'src/utils/delivery';
import { selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import { removeUnsupportedDeliveryOptionsByBill } from 'src/utils/delivery-methods';
import { getFundingSourceType } from 'src/utils/funding-sources';
import {
  AccountType,
  BillType,
  CompanyInfoType,
  DeliveryOptionType,
  PaymentType,
  UserPreferencesType,
} from 'src/utils/types';
import { getBill, getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { getOrgId, getCompanyInfo, getUserPreferences } from 'src/redux/user/selectors';
import { FAST_DELIVERY_TYPES, PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';
import PayBillDatePage from './components/PayBillDatePage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';
import { QBDTLoader } from '../../components/QBDTLoader';

type State = {
  isInnerLoading: boolean;
  deliveryDate?: Date;
  maxDeliveryDate?: Date;
  minScheduledDate?: Date;
  scheduledDate?: Date;
  deliveryOptions?: DeliveryOptionType[];
};

type MapStateToProps = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  orgId: string;
  selectedFundingSource: RecordOf<AccountType> | null;
  companyInfo: RecordOf<CompanyInfoType>;
  userPreferences: RecordOf<UserPreferencesType>;
};

type MapDispatchToProps = {
  selectPaymentDates: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) => void;
};

type Props = PayBillProps & MapStateToProps & MapDispatchToProps;
const eventPage = 'pay-bill';

class PayBillDatePageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInnerLoading: false,
      minScheduledDate: undefined,
      deliveryDate: undefined,
      maxDeliveryDate: undefined,
      scheduledDate: undefined,
      deliveryOptions: undefined,
    };
  }

  componentDidMount() {
    const { payment, navigate, bill, orgId } = this.props;

    if (!this.isNumber(payment.deliveryMethodId)) {
      return navigate(generatePath(locations.pay.deliveryMethod, { billId: bill.id, orgId }));
    }

    return this.loadSuggestedProcessingDate();
  }

  isNumber = (n: any) => !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0);
  getSelectedDeliveryPreference = (options: DeliveryOptionType[]) => {
    const { bill, payment } = this.props;
    const { deliveryType } = bill.getDeliveryMethodById(payment.deliveryMethodId);
    const deliveryPreference = payment?.deliveryPreference;

    const preferredOption = options.find((option) => option.type === deliveryPreference);
    if (preferredOption) {
      return preferredOption.type;
    }

    const regularOption = options.find((option) => !FAST_DELIVERY_TYPES.includes(option.type));
    if (regularOption) {
      return regularOption.type;
    }

    return head(options)?.type || deliveryType;
  };

  async loadSuggestedProcessingDate() {
    const { payment, bill, orgId, companyInfo } = this.props;
    const { deliveryMethodId, fundingSourceId, scheduledDate, amount, payBillFlowUUID } = payment;
    this.setState({ isInnerLoading: true });
    try {
      const {
        suggestedScheduledDate,
        minScheduledDate,
        deliveryDate,
        maxDeliveryDate,
        deliveryOptions,
      } = await getInitialProcessingDates({
        orgId,
        deliveryMethodId: Number.parseInt(deliveryMethodId, 10),
        fundingSourceId,
        scheduledDate,
        amount,
        dueDate: bill.dueDate,
        paymentId: payment.id,
        payBillFlowUUID,
        createOrigin: payment.createOrigin as PAYMENT_CREATE_FLOW_ORIGIN,
      });
      const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
        deliveryOptions,
        bill,
        payment,
        companyInfo,
      });
      if (possibleDeliveryOptions?.length > 1) {
        const { deliveryType } = bill.getDeliveryMethodById(payment.deliveryMethodId);
        if (deliveryType === 'check') {
          analytics.track(
            eventPage,
            possibleDeliveryOptions?.length === 2 ? 'express-check-shown' : 'express-fast-shown'
          );
        } else if (deliveryType === 'ach') {
          const { selectedFundingSource } = this.props;
          const fundingSourceType = getFundingSourceType(selectedFundingSource);
          analytics.track(eventPage, 'fast-ach-shown', { fundingSourceType });
        }
      }

      this.setState({
        isInnerLoading: false,
        scheduledDate: new Date(suggestedScheduledDate),
        deliveryDate,
        maxDeliveryDate: new Date(maxDeliveryDate),
        minScheduledDate: new Date(minScheduledDate),
        deliveryOptions: possibleDeliveryOptions,
      });
      const selectedDeliveryPreference = this.getSelectedDeliveryPreference(possibleDeliveryOptions);
      const paymentDate = getPaymentDate(
        suggestedScheduledDate,
        deliveryDate,
        maxDeliveryDate,
        possibleDeliveryOptions,
        selectedDeliveryPreference
      );

      this.props.selectPaymentDates(
        paymentDate.scheduledDate,
        paymentDate.deliveryDate,
        paymentDate.maxDeliveryDate,
        selectedDeliveryPreference
      );
    } catch (e) {
      this.setState({ isInnerLoading: false });
    }
  }

  isLoading = () => this.props.isLoading || this.state.isInnerLoading;

  onOptionSelected = (scheduledDate, deliveryDate, maxDeliveryDate, type) => {
    this.setState({
      scheduledDate,
      deliveryDate,
      maxDeliveryDate,
    });

    const { selectedFundingSource } = this.props;
    const properties = {
      fundingSourceType: getFundingSourceType(selectedFundingSource),
      fundingSourceId: selectedFundingSource?.id,
    };
    analytics.track(eventPage, `delivery-selected-${type || 'regular'}`, properties);

    this.props.selectPaymentDates(scheduledDate, deliveryDate, maxDeliveryDate, type);
  };

  handleDateSelected = (selectedScheduledDate: Date) => {
    const { orgId, bill, payment, companyInfo } = this.props;
    const fallbackDate = this.state.scheduledDate;

    this.setState({ isInnerLoading: true, scheduledDate: selectedScheduledDate });
    api
      .getDeliveryTime({
        orgId,
        scheduledDate: selectedScheduledDate,
        deliveryMethodId: Number.parseInt(payment.deliveryMethodId, 10),
        fundingSourceId: payment.fundingSourceId,
        amount: payment.amount,
        paymentId: payment.id,
        payBillFlowUUID: payment.payBillFlowUUID,
        createOrigin: payment.createOrigin as PAYMENT_CREATE_FLOW_ORIGIN,
      })
      .then(({ deliveryDate, suggestedScheduledDate, maxDeliveryDate, deliveryOptions }) => {
        const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
          deliveryOptions,
          bill,
          payment,
          companyInfo,
        });
        const selectedDeliveryPreference = this.getSelectedDeliveryPreference(possibleDeliveryOptions);
        this.setState({
          isInnerLoading: false,
          scheduledDate: suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate,
          deliveryOptions: possibleDeliveryOptions,
        });

        const paymentDate = getPaymentDate(
          suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate,
          possibleDeliveryOptions,
          selectedDeliveryPreference
        );
        this.props.selectPaymentDates(
          paymentDate.scheduledDate,
          paymentDate.deliveryDate,
          paymentDate.maxDeliveryDate,
          selectedDeliveryPreference
        );
        analytics.track(eventPage, 'select-date', {
          suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate,
        });
      })
      .catch(() => {
        this.setState({ isInnerLoading: false, scheduledDate: fallbackDate });
      });
  };

  render() {
    const { bill, payment, goExit, onNextSelectDate, onPrevDate, selectedFundingSource } = this.props;
    const { scheduledDate, deliveryDate, maxDeliveryDate, minScheduledDate, deliveryOptions } = this.state;
    const selectedDeliveryMethod = bill.getDeliveryMethodById(payment.deliveryMethodId);

    return (
      <React.Fragment>
        {scheduledDate && deliveryDate && maxDeliveryDate ? (
          <PayBillDatePage
            bill={bill}
            payment={payment}
            selectedFundingSource={selectedFundingSource}
            deliveryPreference={payment.deliveryPreference}
            scheduledDate={scheduledDate}
            deliveryDate={deliveryDate}
            maxDeliveryDate={maxDeliveryDate}
            deliveryOptions={deliveryOptions}
            deliveryMethodType={selectedDeliveryMethod?.deliveryType}
            onSelectDeliveryOption={this.onOptionSelected}
            minScheduledDate={minScheduledDate}
            onNext={onNextSelectDate}
            onPrev={onPrevDate}
            onChange={this.handleDateSelected}
            isLoading={this.isLoading()}
            goExit={goExit}
            title="bills.pay.date.title"
            headerLabel="qbo.header.title"
            subtitle="bills.pay.date.subtitle"
            relativeStep={3 / 5}
            fundingSourceType={getFundingSourceType(selectedFundingSource)}
          />
        ) : (
          <QBDTLoader />
        )}
      </React.Fragment>
    );
  }
}

const getPaymentDate = (
  defaultScheduledDate: Date,
  defaultDeliveryDate: Date,
  defaultMaxDeliveryDate: Date,
  deliveryOptions: DeliveryOptionType[],
  type: string
) => {
  const fastAchOption = deliveryOptions?.find((option) => option.type === type);

  return fastAchOption
    ? {
        scheduledDate: fastAchOption.scheduledDate,
        deliveryDate: fastAchOption.deliveryDate,
        maxDeliveryDate: fastAchOption.maxDeliveryDate,
      }
    : {
        scheduledDate: defaultScheduledDate,
        deliveryDate: defaultDeliveryDate,
        maxDeliveryDate: defaultMaxDeliveryDate,
      };
};

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  payment: getPayment(state),
  bill: getBill(state),
  orgId: getOrgId(state),
  selectedFundingSource: getSelectedFundingSource(state),
  companyInfo: getCompanyInfo(state),
  userPreferences: getUserPreferences(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectPaymentDates(scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, deliveryPreference?: string) {
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryEta, maxDeliveryEta, deliveryPreference));
  },
});

export default compose(
  withNavigator(),
  withPayBillData(),
  connect(mapStateToProps, mapDispatchToProps)
)(PayBillDatePageContainer);
