import React from 'react';
import { useSelector } from 'react-redux';
import { Box, VStack } from '@melio/billpay-design-system';
import { InternalBill } from 'src/services/api/bills';
import { MIFormattedText } from 'src/utils/formatting';
import { EmptyBillingMethodCard } from 'src/billpay/qbdt/pages/settings/billing/components/empty-method-card/EmptyBillingMethodCard';
import { SelectedBillingMethodCard } from 'src/billpay/qbdt/pages/settings/billing/components/selected-method-card/SelectedBillingMethodCard';
import { OrganizationBillingFee } from 'src/services/api/smb-billing-fee';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { getBillingMethod } from '../components/billing/utils';
import { BillingFeeHistoryTable } from './components/history-table/BillingFeeHistoryTable';
import { ReceiptStatus } from '../components/consts';

type Props = {
  internalBills: InternalBill[];
  internalBillsLoading: boolean;
  internalBillsTotal: number;
  internalBillsStatuses: Record<string, ReceiptStatus>;
  onGetReceiptClick: (internalBill: InternalBill) => void;
  filters: {
    start: number;
    limit: number;
  };
  setPage: (v: number) => void;
  organizationBillingFees: OrganizationBillingFee[];
  reFetch: () => void;
};

const BillingSettingsPage = ({ organizationBillingFees, reFetch, ...rest }: Props) => {
  const currentBillingMethod = getBillingMethod(organizationBillingFees);
  const billingMethodFundingSource = useSelector(
    fundingSourcesStore.selectors.byId(currentBillingMethod?.fundingSourceId)
  );

  return (
    <VStack px="3.4rem" py="ds.2xl" spacing="ds.xl">
      <VStack w="full" spacing="ds.lg" align="flex-start">
        <Box textStyle="ds.h6">
          <MIFormattedText label="settings.billing.billingFee.title" />
        </Box>
        {!currentBillingMethod && <EmptyBillingMethodCard />}
        {currentBillingMethod && billingMethodFundingSource && (
          <SelectedBillingMethodCard
            fundingSource={billingMethodFundingSource}
            currentBillingMethodId={currentBillingMethod.id}
            reFetch={reFetch}
          />
        )}
      </VStack>

      <VStack w="full" spacing="ds.lg" align="flex-start">
        <Box textStyle="ds.h6">
          <MIFormattedText label="settings.billing.feeHistory.title" />
        </Box>
        <BillingFeeHistoryTable {...rest} />
      </VStack>
    </VStack>
  );
};

export { BillingSettingsPage };
