import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';

/**
 * MICardTitle - in use only with MICard
 */
type MICardTitleProps = {
  label: string;
  className?: string;
  testId?: string;
  values?: Record<string, any>;
};

const MICardTitle = ({ label, className, testId, values }: MICardTitleProps) => (
  <Title className={className}>
    <MIFormattedText testId={testId} label={label} values={values} />
  </Title>
);

export { MICardTitle };

/**
 * MICard - main
 */
type MICardProps = {
  mode?: 'mainSingleScreen' | 'regular';
  children: React.ReactNode;
  className?: string;
  flex?: boolean;
};

const MICard = ({ className, mode = 'mainSingleScreen', children, flex }: MICardProps) => (
  <Container flex={flex} mode={mode} className={className}>
    {children}
  </Container>
);

export default MICard;

/**
 * MICard - form
 */
type MICardFormProps = {
  children: React.ReactNode;
  onKeyDown?: (event: any) => void;
  className?: string;
  testId?: string;
};

const MICardForm = React.forwardRef(
  (
    {
      children, // eslint-disable-next-line @typescript-eslint/no-empty-function
      onKeyDown = () => {},
      className = '',
      testId,
    }: MICardFormProps,
    ref
  ) => (
    <FormContainer className={className} onKeyDown={onKeyDown} data-testid={testId} ref={ref}>
      {children}
    </FormContainer>
  )
);

/**
 * MICard - Section
 */
type MICardSectionProps = {
  children: React.ReactNode;
  className?: string;
};

const MICardSection = ({ children, className }: MICardSectionProps) => (
  <SectionContainer className={className}>{children}</SectionContainer>
);

/**
 * MICardField
 */
type FieldProps = {
  label: string;
  height?: 'normal' | 'small';
  children: React.ReactNode;
};

const MICardField = ({ label, height = 'normal', children }: FieldProps) => (
  <FieldContainer height={height}>
    <Label>
      <MIFormattedText label={label} />
    </Label>
    <Value data-testid={`${label}-value`}>{children}</Value>
  </FieldContainer>
);

type EditableFieldProps = {
  children: React.ReactNode;
  testId?: string;
};

const MICardEditableField = ({ children, testId }: EditableFieldProps) => (
  <EditableFieldContainer data-testid={testId}>
    <Value>{children}</Value>
  </EditableFieldContainer>
);

const MICardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4.2rem;
`;
export { MICard, MICardForm, MICardSection, MICardField, MICardEditableField, MICardHeader };

/**
 * Styles
 */

const Container = styled.div<{
  mode: string;
  padding?: string;
  flex?: boolean;
}>`
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 0.9rem;
  box-shadow: ${(props) => (props.mode === 'regular' ? '0 0.5rem 1rem 0 rgba(0,0,0,0.1)' : 'none')};
  box-sizing: border-box;
  padding-bottom: 0;
  display: ${(props) => (props.flex ? 'flex' : 'block')};

  @media ${devices.mobile} {
    width: auto;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    background-color: ${(props) => props.theme.colors.white.opaque};
    margin: ${(props) => (props.mode === 'mainSingleScreen' ? '1rem 1rem 2rem 1rem' : '0')};
    padding-bottom: ${(props) => props.padding || '0'};
    ${(props) => props.theme?.components?.MICard?.MICardContainer}
  }

  @media ${devices.phablet} {
    width: auto;
    background-color: ${(props) => props.theme.colors.white.opaque};
    margin: ${(props) => (props.mode === 'mainSingleScreen' ? '4rem 2rem 2rem 2rem' : '0')};
    ${(props) => props.theme?.components?.MICard?.MICardContainer}
  }

  @media ${devices.tablet}, ${devices.desktop} {
    width: 100%;
    margin: ${(props) => (props.mode === 'mainSingleScreen' ? '0 0 4rem 0' : '0')};
  }
  ${(props) => props.theme?.components?.MICard?.Container}
`;

const Title = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: 2.4rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  line-height: 3.2rem;
  ${(props) => props.theme?.components?.MICard?.Title}
`;

const SectionContainer = styled.div`
  padding: 1rem 3.6rem 2rem 3.6rem;

  @media ${devices.mobile} {
    padding: 1rem 1.6rem 2rem 1.6rem;
  }
  ${(props) => props.theme?.components?.MICard?.SectionContainer}
`;

const FormContainer = styled.div`
  padding: 3.4rem 3.6rem;

  @media ${devices.mobile} {
    padding: 3rem 1.6rem;
  }
`;

const FieldContainer = styled.div<{ height: string }>`
  margin: 2.5rem 0 0;

  &:first-of-type {
    margin-top: ${(props) => (props.height === 'small' ? '0' : '2rem')};
  }
  ${(props) => props.theme?.components?.MICard?.FieldContainer}
`;

const EditableFieldContainer = styled.div`
  margin: 1.6rem 0 0;
  &:first-of-type {
    margin-top: 2rem;
  }
  ${(props) => props.theme?.components?.MICard?.EditableFieldContainer}
`;

const Label = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.MICard?.Label}
`;

const Value = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.6rem;
  line-height: 2.5rem;
  word-break: break-word;
  & svg {
    font-size: 1.7rem;
    margin-right: 0.5rem;
    color: ${(props) => props.theme.text.color.red};
  }
  ${(props) => props.theme?.components?.MICard?.Value}
`;
