import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { VirtualCardDetailsPageContainer } from './VirtualCardDetailsPageContainer';
import { InvalidAddDeliveryMethodPage, PosTerminalPage } from './pages';
import locations from '../locations';

export const VirtualCardDetailsRouter = () => {
  const match = useRouteMatch<{ token?: string; id?: string }>();
  const token = match.params?.token || '';
  const virtualCardId = match.params?.id || '';

  return (
    <Switch>
      <SmartRoute path={locations.virtualCardDetails.posTerminal} exact>
        <PosTerminalPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.virtualCardDetails.details} exact>
        <VirtualCardDetailsPageContainer token={token} virtualCardId={virtualCardId} />
      </SmartRoute>
      <SmartRoute path={locations.virtualCardDetails.invalidToken} exact>
        <InvalidAddDeliveryMethodPage token={token} />
      </SmartRoute>
    </Switch>
  );
};
