import { ChangeEvent, InputBlurEvent } from '@basis-theory/basis-theory-js/types/elements/events';
import { FieldError } from '@basis-theory/basis-theory-js/types/elements/shared';
import { useMemo, useRef, useState } from 'react';

type ValidationState = {
  hasError: boolean;
  empty: boolean;
  dirty: boolean;
  complete: boolean;
  errors?: FieldError[];
};

type Props = {
  onBlur(event: InputBlurEvent): void;
  attemptedToLink: boolean;
  onError(hasError: boolean): void;
  onChange?(event: ChangeEvent): void;
};

export const useErrorValidations = ({ onBlur, attemptedToLink, onChange, onError }: Props) => {
  const errorsRef = useRef<FieldError[] | undefined>(undefined);
  const [errorOnBlur, setErrorOnBlur] = useState(false);
  const [validation, setValidation] = useState<ValidationState>({
    hasError: false,
    empty: true,
    dirty: false,
    complete: false,
    errors: undefined,
  });
  const shouldShowError = useMemo(() => (errorOnBlur || attemptedToLink) && validation.hasError, [
    attemptedToLink,
    errorOnBlur,
    validation.hasError,
  ]);

  const handleChange = ({ errors, empty, complete, ...event }: ChangeEvent) => {
    const hasError = !!errors?.length || empty;
    setValidation({
      hasError,
      empty,
      dirty: !validation.dirty ? !empty : true,
      complete,
      errors,
    });
    errorsRef.current = errors;
    onError(hasError);
    onChange?.({ errors, empty, complete, ...event });
  };

  const handleBlur = (event: InputBlurEvent) => {
    onBlur(event);
    setErrorOnBlur(!validation.complete && validation.dirty);
  };

  return {
    handleChange,
    ...validation,
    errorsRef,
    shouldShowError,
    handleBlur,
  };
};
