import React from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import SelectBankAccountVerificationTypePageContainer from './bank/SelectBankAccountVerificationTypePageContainer';
import SetBankPlaidPageContainer from './bank/SetBankPlaidPageContainer';
import LinkIntuitAccountToFundingSourcePage from './LinkIntuitAccountToFundingSourcePage';
import CompleteAddingBankAccountsPageContainer from './bank/CompleteAddingBankAccountsPageContainer';
import SetManuallyBankAccountPageContainer from './bank/SetManuallyBankAccountPageContainer';
import ViewManualBankAccountPage from './bank/ViewManualBankAccountPage';
import AddCardAccountsPageContainer from './card/AddCardAccountsPageContainer';
import SetCardAccountsHolderPageContainer from './card/SetCardAccountsHolderPageContainer';
import { useSelectFundingSourceForBatch } from '../batch-pay/hooks/useSelectFundingSourceForBatchFlow';
import { AddCardDetailsPage } from './card/AddCardDetailsPage';
import { AddCardHolderDetailsPage } from './card/AddCardHolderDetailsPage';
import { InitialAddFundingSourceLocationState } from './types';

export function QBDTFundingSourceRouter() {
  const match = useRouteMatch();
  const locationState = useLocation<InitialAddFundingSourceLocationState>().state;
  const batchItemId = locationState?.preservedState?.batchItemId;
  const applyToAllBills = locationState?.preservedState?.applyToAllBills;
  const [isBasicTheoryEnabled] = featureFlags.useFeature(FeatureFlags.BasicTheory, false);
  const { selectFundingSourceForBatchFlow } = useSelectFundingSourceForBatch({
    batchItemId,
    applyToAllBills,
  });

  return (
    <Switch>
      <SmartRoute path={`${match.path}/bank/select`} exact>
        <SelectBankAccountVerificationTypePageContainer />
      </SmartRoute>
      <SmartRoute path={`${match.path}/bank/plaid`} exact>
        <SetBankPlaidPageContainer selectFundingSourceForBatchFlow={selectFundingSourceForBatchFlow} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/bank/manual`} exact>
        <SetManuallyBankAccountPageContainer selectFundingSourceForBatchFlow={selectFundingSourceForBatchFlow} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/bank/manual/:id`} exact>
        <ViewManualBankAccountPage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/link/account`} exact>
        <LinkIntuitAccountToFundingSourcePage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/bank/complete`} exact>
        <CompleteAddingBankAccountsPageContainer />
      </SmartRoute>
      <SmartRoute path={`${match.path}/card`} exact>
        {isBasicTheoryEnabled ? <AddCardDetailsPage /> : <AddCardAccountsPageContainer />}
      </SmartRoute>
      <SmartRoute path={`${match.path}/card/holder`} exact>
        {isBasicTheoryEnabled ? (
          <AddCardHolderDetailsPage selectFundingSourceForBatchFlow={selectFundingSourceForBatchFlow} />
        ) : (
          <SetCardAccountsHolderPageContainer selectFundingSourceForBatchFlow={selectFundingSourceForBatchFlow} />
        )}
      </SmartRoute>
    </Switch>
  );
}
