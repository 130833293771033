import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import { getOrgId } from 'src/redux/user/selectors';
import batchPaymentsLocations from 'src/billpay/qbdt/pages/batch-pay/locations';
import useGetValidFundingSources from '../table/hooks/useGetValidFundingSources';

const useAddFundingSourceRedirect = () => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);

  const [validFundingSources] = useGetValidFundingSources();

  const redirectToAddFundingSourcePage = () => {
    const batchPaymentsUrl = generatePath(batchPaymentsLocations.batch, {
      orgId,
    });

    const addFundingSourceUrl = generatePath(batchPaymentsLocations.addFundingSource, { orgId });

    history.push(addFundingSourceUrl, {
      redirectUrl: batchPaymentsUrl,
      applyToAllBills: true,
      closeAppOnExit: true,
    });
  };

  useEffect(() => {
    if (!validFundingSources.length) {
      redirectToAddFundingSourcePage();
    }
  }, []);
};

export { useAddFundingSourceRedirect };
