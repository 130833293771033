import { Flex, HStack } from '@melio/billpay-design-system';
import React, { useEffect } from 'react';
import { useCheckVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useCheckVendorEmail';
import MIButton from 'src/components/common/MIButton';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import analytics from 'src/services/analytics';
import { BUTTON_SIZE, BUTTON_VARIANT, NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import { MIFormattedCurrency, MIFormattedDate } from 'src/utils/formatting';
import { PaymentType } from 'src/utils/types';
import styled from 'styled-components';
import { Modal, ModalSubtitle, ModalTextContent, ModalTitle } from 'src/theme/ds';
import { PAY_FLOW_PAGE_EVENT } from '../../event-mapping';
import { ReactComponent as CheckIcon } from '../../images/check-mark.svg';

type Props = {
  partialBillId: string;
  payment: PaymentType;
  companyName: string;
  vendorId?: number;
  dismiss: VoidFunction;
  onNotifyVendorClick: VoidFunction;
  isSyncPaymentFailed?: boolean;
};

const getModalStyleConfig = (shouldUseUpdatedModal) =>
  shouldUseUpdatedModal
    ? {
        align: 'center',
        alignText: 'center',
        alignButton: 'center',
        subtitleLabel: 'bills.pay.successModal.subtitles.enhanced',
        buttonLabel: 'general.done',
        buttonWidth: '10rem',
      }
    : {
        align: 'flex-start',
        alignText: 'inherit',
        alignButton: 'flex-end',
        subtitleLabel: 'bills.pay.successModal.subtitles.regular',
        buttonLabel: 'bills.pay.successModal.buttonLabel.done',
        buttonWidth: '15rem',
      };

export const SinglePayFlowSuccessModal = ({
  partialBillId,
  payment,
  companyName,
  vendorId,
  dismiss,
  onNotifyVendorClick,
  isSyncPaymentFailed,
}: Props) => {
  const { isVendorEmailPresent } = useCheckVendorEmail();
  const hasContactEmail = isVendorEmailPresent(vendorId);

  const analyticsProps = {
    vendorId,
    partialBillId,
  };

  useEffect(() => {
    if (!hasContactEmail) {
      analytics.track(PAY_FLOW_PAGE_EVENT, 'exposed-to-notify-my-vendor-button', {
        screenName: 'success',
        ...analyticsProps,
      });
    }
  }, [hasContactEmail]);

  const onSinglePayFlowDone = () => {
    analytics.track(PAY_FLOW_PAGE_EVENT, 'done', {
      paymentId: payment?.id,
      ...analyticsProps,
    });
  };

  const handleNotifyVendorClick = () => {
    analytics.track(PAY_FLOW_PAGE_EVENT, 'SuccessPaymentModalNotifyVendorButton-Clicked', { flow: 'single-payment' });
    onNotifyVendorClick();
    dismiss();
    onSinglePayFlowDone();
  };

  const onCloseClick = () => {
    analytics.track(PAY_FLOW_PAGE_EVENT, 'SuccessPaymentModal-Closed');
    dismiss();
  };

  const onDoneClick = () => {
    analytics.track(PAY_FLOW_PAGE_EVENT, 'SuccessPaymentModal-Done', {
      paymentId: payment?.id,
      vendorId,
    });
    dismiss();
    onSinglePayFlowDone();
  };

  const onNotifyLaterClick = () => {
    analytics.track(PAY_FLOW_PAGE_EVENT, 'SuccessPaymentModalMaybeLaterButton-Clicked');
    dismiss();
  };

  const config = getModalStyleConfig(hasContactEmail);

  return (
    <Modal onCloseClick={onCloseClick}>
      <Flex justifyContent="center">
        <CheckIcon />
      </Flex>
      <ModalTextContent align={config.align}>
        <ModalTitle label="bills.pay.successModal.titles.regular" textAlign={config.alignText} />
        <ModalSubtitle
          label={config.subtitleLabel}
          values={{
            amount: <MIFormattedCurrency key="amount" value={payment?.amount?.toString() || null} />,
            vendorName: companyName,
            processDate: <MIFormattedDate key="scheduledDate" date={payment?.scheduledDate} />,
          }}
          textAlign={config.alignText}
        />
      </ModalTextContent>
      {isSyncPaymentFailed && (
        <SyncPaymentWarningNotification
          type={NOTIFICATION_CARD_TYPES.WARNING}
          title={{ label: 'bills.pay.successModal.syncFailedNotification.title' }}
          subtitle={{ label: 'bills.pay.successModal.syncFailedNotification.subtitle' }}
        />
      )}
      <Flex justifyContent={config.alignButton}>
        {hasContactEmail && (
          <PrimaryButton
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={onDoneClick}
            label={config.buttonLabel}
            size={BUTTON_SIZE.NORMAL}
            width={config.buttonWidth}
          />
        )}

        {!hasContactEmail && (
          <HStack spacing="ds.lg">
            <TertiaryButton
              variant={BUTTON_VARIANT.TERTIARY}
              onClick={onNotifyLaterClick}
              label="bills.pay.successModal.buttonLabel.maybeLater"
              size={BUTTON_SIZE.NORMAL}
            />

            <PrimaryButton
              variant={BUTTON_VARIANT.PRIMARY}
              onClick={handleNotifyVendorClick}
              label="bills.pay.successModal.buttonLabel.notifyVendor"
              size={BUTTON_SIZE.NORMAL}
            />
          </HStack>
        )}
      </Flex>
    </Modal>
  );
};

const PrimaryButton = styled(MIButton)`
  width: ${({ width }) => width || '15rem'};
  padding: 0;
`;

const TertiaryButton = styled(MIButton)`
  width: 13.7rem;
  padding: 0;
  background-color: white;
`;

const SyncPaymentWarningNotification = styled(QBONotificationCard)`
  margin-top: 4rem;
`;
