import config from 'src/config';
import { consoleLogger } from './console.logger';
import { loggingApi } from './logging.api';
import { dataDogLogger } from './dataDog.logger';

export const initLoggers = () => {
  if (config.logger.mode === 'dataDog') {
    loggingApi.addLogger(dataDogLogger);
  } else {
    loggingApi.addLogger(consoleLogger);
  }

  loggingApi.init();
};

export { loggingApi };
