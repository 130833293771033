import React from 'react';
import { MethodsListWrapper } from '../MethodsListWrapper/MethodsListWrapper';
import { DeliveryMethodItem } from './DeliveryMethodItem';

export const DeliveryMethodsList = () => (
  <MethodsListWrapper listWidth="11.7rem" description="entry.batch.landingPage.deliveryMethodsDescription">
    <DeliveryMethodItem iconType="check" />
    <DeliveryMethodItem iconType="bank" />
    <DeliveryMethodItem iconType="bank" />
  </MethodsListWrapper>
);
