import React from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import AreaLoader from 'src/components/common/AreaLoader';
import MIButton from 'src/components/common/MIButton';
import OutsideLayout from 'src/components/layout/OutsideLayout';
import doneImage from 'src/images/general/check-circle.svg';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import shiftToAchLocations from 'src/pages/vendor/shift-vendor-to-ach/locations';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { useEditCheckAddressStatus } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import { useInvoiceNumberWithPrefix } from '../hooks/useInvoiceNumberWithPrefix';

type Props = {
  token: string;
};

const CheckAddressChangedPage = ({ token }: Props) => {
  const history = useHistory();
  const { payment, isPaymentLoading, organization } = useEditCheckAddressStatus({ token });
  const { invoiceNumber } = useInvoiceNumberWithPrefix(payment);

  const goShiftToAch = () => {
    history.push(generatePath(shiftToAchLocations.index, { id: payment?.vendorId, token }));
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout>
      <BillPaymentBlock>
        <BillInfoContainer>
          <DoneIcon src={doneImage} />
          <Title data-testid="success-title">
            <MIFormattedText label="vendors.editCheckAddress.checkNotSent.success.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.checkNotSent.success.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={payment?.amount} />,
                companyName: organization?.companyName,
                invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
        <ShiftToAchContainer>
          <ShiftToAchTitle>
            <MIFormattedText
              label="vendors.editCheckAddress.checkNotSent.success.upsaleTitle"
              values={{
                companyName: <Label>{organization?.companyName}</Label>,
              }}
            />
          </ShiftToAchTitle>
          <ShiftToAchSubtitle>
            <MIFormattedText label="vendors.editCheckAddress.checkNotSent.success.upsaleSubTitle" />
          </ShiftToAchSubtitle>
          <MIButton
            onClick={goShiftToAch}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            label="vendors.editCheckAddress.checkNotSent.success.upsaleCTA"
            fullWidth
          />
        </ShiftToAchContainer>
      </BillPaymentBlock>
    </OutsideLayout>
  );
};

export default CheckAddressChangedPage;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: 2rem;
`;

const ShiftToAchContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-radius: 0 0 0.6rem 0.6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ShiftToAchTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  font-weight: ${(props) => props.theme.text.weight.bold};
  line-height: 3.2rem;
`;

const ShiftToAchSubtitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  margin: 1.2rem 0 4rem;
`;

const Label = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;
