import React, { useState, useMemo } from 'react';
import { RecordOf } from 'immutable';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Link } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import * as WizardElements from 'src/billpay/qbdt/components/layout/WizardElement';
import { AccountType, BillType, DeliveryOptionType, OptionalDeliveryMethodsType, PaymentType } from 'src/utils/types';
import { PaymentDatePicker } from 'src/billpay/qbdt/pages/pay/components/PaymentDatePicker';
import { MIFormattedCurrency } from 'src/utils/formatting';
import QBONotificationCard, { NotificationCardProps } from 'src/components/qbo/QBONotificationCard';
import AreaLoader from 'src/components/common/AreaLoader';
import { DELIVERY_TYPE, NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import { FeatureFlags } from 'src/utils/feature-flags';
import { setFaqPanel } from 'src/redux/payBillWizard/actions';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  goExit: () => void;
  onSelectDeliveryOption?: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, type?: string) => void;
  deliveryOptions?: DeliveryOptionType[];
  onChange: (date: Date) => void;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  deliveryMethodType: OptionalDeliveryMethodsType;
  minScheduledDate?: Date;
  scheduledDate: Date;
  isLoading?: boolean;
  deliveryPreference?: string;
  headerLabel: string;
  title: string;
  subtitle?: string;
  relativeStep?: number;
  titleValues?: Record<string, any>;
  selectedFundingSource: RecordOf<AccountType> | null;
  fundingSourceType?: string;
};

const PayBillDatePage = ({
  bill,
  payment,
  deliveryDate,
  deliveryMethodType,
  scheduledDate,
  selectedFundingSource,
  onChange,
  deliveryOptions,
  onNext,
  onPrev,
  isLoading = false,
  goExit,
  deliveryPreference,
  minScheduledDate,
  maxDeliveryDate,
  onSelectDeliveryOption,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  subtitle,
  fundingSourceType,
}: Props) => {
  const [isCheckDelaysNotificationShown, setIsCheckDelaysNotificationShown] = useState(true);
  const [isCheckDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);
  const [isUpdatedDeliveryBlock] = featureFlags.useFeature(FeatureFlags.DatePickerDeliverySpeedChanges, false);
  const dispatch = useDispatch();

  const goLearnMoreCheckDelays = () => {
    dispatch(setFaqPanel({ isOpen: true, focusedQuestion: 18 }));
  };

  const sortedDeliveryOptions = useMemo(() => (isUpdatedDeliveryBlock ? deliveryOptions?.reverse() : deliveryOptions), [
    deliveryOptions,
    isUpdatedDeliveryBlock,
  ]);

  const checkDelaysNotificationProps: NotificationCardProps =
    (deliveryOptions || []).length > 1
      ? {
          type: NOTIFICATION_CARD_TYPES.WARNING,
          title: { label: 'checkDelaysWarning.deductionDate.warning.title' },
          subtitle: {
            label: 'checkDelaysWarning.deductionDate.warning.subtitle',
          },
        }
      : {
          type: NOTIFICATION_CARD_TYPES.INFO,
          title: { label: 'checkDelaysWarning.deductionDate.info.title' },
          subtitle: {
            label: 'checkDelaysWarning.deductionDate.info.subtitle',
          },
        };

  if (checkDelaysNotificationProps.subtitle) {
    checkDelaysNotificationProps.subtitle.values = {
      learnMoreLink: (...chunks) => (
        <Box as={Link} onClick={goLearnMoreCheckDelays}>
          {React.Children.toArray(chunks)}
        </Box>
      ),
    };
  }

  const headerLabelValues = {
    amount: <MIFormattedCurrency value={payment.amount || ''} />,
    companyName: bill.vendor.companyName,
  };

  const onDateChange = ({ value }) => onChange(value);

  if (!bill.id) {
    return <AreaLoader />;
  }

  const handleOnCloseHolidayWarning = () => {
    setIsCheckDelaysNotificationShown(false);
  };

  const shouldShowCheckDelaysWarning =
    isCheckDelaysEnabled && isCheckDelaysNotificationShown && deliveryMethodType === DELIVERY_TYPE.CHECK;

  return (
    <QBDTStepLayout
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={title}
      titleValues={titleValues}
      subtitle={subtitle}
      goExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      isLoading={isLoading}
      innerSize={100}
      relativeStep={relativeStep}
    >
      <WizardElements.InnerWizardContentWrapper>
        {shouldShowCheckDelaysWarning && (
          <QBONotificationCardWrapper data-testid="check-delays-page-notification">
            <QBONotificationCard {...checkDelaysNotificationProps} onClose={handleOnCloseHolidayWarning} />
          </QBONotificationCardWrapper>
        )}
        <PaymentDatePicker
          fundingSource={selectedFundingSource}
          deliveryPreference={deliveryPreference}
          deliveryDate={deliveryDate}
          maxDeliveryDate={maxDeliveryDate}
          deliveryMethodType={deliveryMethodType}
          scheduledDate={scheduledDate}
          minDate={minScheduledDate}
          dueDate={new Date(bill.dueDate)}
          onChange={onDateChange}
          deliveryOptions={sortedDeliveryOptions}
          onSelectDeliveryOption={onSelectDeliveryOption}
          fundingSourceType={fundingSourceType}
        />
      </WizardElements.InnerWizardContentWrapper>
    </QBDTStepLayout>
  );
};

const QBONotificationCardWrapper = styled.div`
  max-width: 45rem;
  margin: 0 auto 4rem;
  border-radius: 0.8rem;
`;

export default PayBillDatePage;
