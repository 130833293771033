import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';

import analytics from 'src/services/analytics';
import { MIFormattedText } from 'src/utils/formatting';
import { getOrgId, getUserPreferences } from 'src/redux/user/selectors';
import { getCompleteFeeByDeliveryType, isAchCheckFeeType } from 'src/utils/fee';
import useUpdateUserPreference from 'src/modules/users/hooks/useUpdateUserPreferences';
import { CONSTS } from 'src/utils/consts';
import { getOrganizationFeeCatalog } from 'src/redux/organization/selectors';
import organizationApi from 'src/services/api/organizations';
import { FeatureFlags } from 'src/utils/feature-flags';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';

import {
  NotificationAppliedFeeProps,
  GetNoticeReturn,
  FreeChecksType,
  GetPaymentFeeValuesType,
  GetPaymentFeeValuesTypeReturn,
  fundingSourceType,
  GetFreeChecksType,
  increaseCheckFeePaymentCountType,
  ReturnType,
} from './types';
import { Modal as CheckModal } from './Modal';
import { VirtualNotification as CheckVirtualNotification } from './VirtualNotification';
import { NotificationAppliedFee as CheckNotificationAppliedFee } from './NotificationAppliedFee';
import * as utils from './utils';
import { StyledLink } from './styled';

const MAX_NOTIFICATION_COUNT = 1;

/**
 * Handle check fee modal and fee status
 */
export const useCheckFee = (): ReturnType => {
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlags.CheckFees, utils.featureFlagValues.CLOSED);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [freeChecks, setFreeChecks] = useState<FreeChecksType>(null);
  const orgId = useSelector(getOrgId);
  const catalog = useSelector(getOrganizationFeeCatalog);
  const catalogFee = getCompleteFeeByDeliveryType(catalog, CONSTS.FEE_VARIANTS.ACH_TO_CHECK);
  const checkFeeEnabled = utils.isCheckFeeOpen(checkFeeFlag);
  const checkFeeEnabledWithPromotions = utils.isCheckFeeOpenWithPromotions(checkFeeFlag);
  const batchActions = useStoreActions(regularBatchPaymentsStore);
  const userPreferences = useSelector(getUserPreferences);
  const { updateUserPreference } = useUpdateUserPreference();

  const notificationCount = Number(userPreferences.checkFeesNotificationCount) || 0;

  // get card notices with check fee cases
  const getNotice = useCallback(
    (selectedFundingSource?: fundingSourceType): GetNoticeReturn => {
      const defaultValues = {
        description: 'deliveryMethods.notDefinedInfo.check.desc',
        descriptionValues: undefined,
      };

      // settings page has no funding source
      const isSettingsPage = !selectedFundingSource;
      const isAchFS = isSettingsPage || utils.isAchFundingType(selectedFundingSource);

      if (!isAchFS) {
        // card funding source
        if (checkFeeEnabled) {
          // showing new free message in case of check it not using a valid funding source (ach)
          const openModal = () => {
            setIsModalOpen(true);

            analytics.track('MoreInformation', 'Learned', {
              infoType: 'check-fee',
            });
          };
          const learnMoreLink = (
            <StyledLink onClick={openModal}>
              <MIFormattedText label="deliveryMethods.notDefinedInfo.check.tellMeMoreLink" />
            </StyledLink>
          );

          return {
            description: 'deliveryMethods.notDefinedInfo.check.appliedFee.free',
            descriptionValues: {
              learnMoreLink,
            },
          };
        }

        return defaultValues;
      }

      if (checkFeeEnabled && catalogFee) {
        const openModal = (e) => {
          e.stopPropagation();
          setIsModalOpen(true);

          analytics.track('MoreInformation', 'Learned', {
            infoType: 'check-fee',
          });
        };
        const tellMeMoreLink = (
          <StyledLink onClick={openModal}>
            <MIFormattedText label="deliveryMethods.notDefinedInfo.check.tellMeMoreLink" />
          </StyledLink>
        );

        const promotionsPrefix = checkFeeEnabledWithPromotions ? 'appliedFee' : 'appliedFeeNoPromotions';

        const descriptionPrefix = isSettingsPage ? 'settingsDescription' : 'description';

        return {
          description: `deliveryMethods.notDefinedInfo.check.${promotionsPrefix}.${descriptionPrefix}`,
          descriptionValues: {
            tellMeMoreLink,
            fee: catalogFee,
          },
        };
      }

      return defaultValues;
    },
    [catalogFee, isModalOpen, checkFeeFlag, checkFeeEnabled, checkFeeEnabledWithPromotions]
  );

  // check protection modal
  const Modal = useCallback(() => {
    if (!isModalOpen) return null;

    const closeModal = () => setIsModalOpen(false);

    return <CheckModal onClose={closeModal} />;
  }, [isModalOpen]);

  // confirm notification for virtual card
  const VirtualNotification = useCallback(() => {
    if (!checkFeeEnabled) return null;

    const label = checkFeeEnabledWithPromotions
      ? 'deliveryMethods.notDefinedInfo.virtual.checkFee.notification'
      : 'deliveryMethods.notDefinedInfo.virtual.checkFee.notificationNoPromotions';

    return <CheckVirtualNotification catalogFee={catalogFee} label={label} />;
  }, [checkFeeEnabled, catalogFee]);

  // confirm fee applied notification (only for first 3 payments)
  const NotificationAppliedFee = useCallback(
    (props: NotificationAppliedFeeProps) => {
      const isValidCheckFeePayment = utils.isAchCheckPayment(props);

      if (checkFeeEnabledWithPromotions && isValidCheckFeePayment && notificationCount < MAX_NOTIFICATION_COUNT) {
        return <CheckNotificationAppliedFee fee={catalogFee} orgId={orgId} styles={props.styles} />;
      }

      return null;
    },
    [checkFeeEnabledWithPromotions, catalogFee, notificationCount]
  );

  // right now we support only ACH funding source, this function handles this condtion
  const hasValidFundingType = (selectedFundingSource: fundingSourceType): boolean => {
    const isAchFS = utils.isAchFundingType(selectedFundingSource);

    return checkFeeEnabled && isAchFS;
  };

  // get payment fee component values (confirm)
  const getPaymentFeeValues = useCallback(
    ({ fundingSource, feeType, fee }: GetPaymentFeeValuesType): GetPaymentFeeValuesTypeReturn => {
      const hasValidFS = hasValidFundingType(fundingSource);
      const hasFee = fee > 0;
      const hasAchCheckFee = isAchCheckFeeType(feeType);
      const hasZeroFeeUI = checkFeeEnabledWithPromotions && hasValidFS && !hasFee && !!catalogFee;

      return {
        hasCheckZeroFeeUI: hasZeroFeeUI,
        hasAchCheckFee,
        hasPromotions: checkFeeEnabledWithPromotions,
      };
    },
    [checkFeeEnabledWithPromotions, catalogFee]
  );

  const increaseCheckFeePaymentCount = (props: increaseCheckFeePaymentCountType) => {
    const isValidCheckFeePayment = utils.isAchCheckPayment(props);

    if (checkFeeEnabledWithPromotions && isValidCheckFeePayment && notificationCount < MAX_NOTIFICATION_COUNT + 1) {
      updateUserPreference({
        key: 'checkFeesNotificationCount',
        value: String(notificationCount + 1),
      });
    }
  };

  const getFreeChecks = async ({ isBatch }: GetFreeChecksType) => {
    try {
      const res = await organizationApi.getFreeChecks(orgId);

      if (res?.available && res?.total) {
        setFreeChecks({ available: res.available, total: res.total });

        // update free checks for batch flow
        if (isBatch) {
          batchActions.settings.update({
            freeChecks: res.available,
          });
        }
      }
    } catch (er) {
      // error
    }
  };

  return {
    checkFeeFlag,
    VirtualNotification,
    hasValidFundingType,
    NotificationAppliedFee,
    getNotice,
    getFreeChecks,
    getPaymentFeeValues,
    increaseCheckFeePaymentCount,
    freeChecks,
    Modal,
    catalogFee,
    flagEnabled: checkFeeEnabled,
    ...utils,
  };
};
