import { VendorType } from 'src/utils/types';
import { escapeXMLString } from 'src/billpay/qbdt/services/qbdt/util';
import { logger } from 'src/services/loggers';

import { QBDTVendor } from '../types';
import { mapQBDTVendor } from '../typeMapper';
import { executeSDKRequest, getAllEntities, getSingleEntity, QBDTSDKError } from '../sdk';

/**
 * Get vendor by ID
 * @param vendorId [string] The vendor ID to fetch
 */
export async function getQbdtVendor(vendorId: string) {
  try {
    return await getSingleEntity(`<VendorQueryRq><ListID>${vendorId}</ListID></VendorQueryRq>`, mapQBDTVendor);
  } catch (error) {
    if (error instanceof QBDTSDKError && error.code === '500') {
      return null;
    }

    logger.error(`vendor.getQBDTVendor(): Failed to get ${vendorId} from QBDT`, error);
    throw error;
  }
}

export async function getQbdtVendors(vendorIds: string[]) {
  try {
    return await getAllEntities<QBDTVendor>(
      `<VendorQueryRq>
        ${vendorIds.map((vendorId) => `<ListID>${vendorId}</ListID>`).join('')}
      </VendorQueryRq>`,
      'VendorRet',
      mapQBDTVendor
    );
  } catch (error) {
    logger.error(`vendor.getQbdtVendors(): Failed to get ${vendorIds} from QBDT`, error);
    throw error;
  }
}

export async function getQbdtVendorByName(vendorName: string) {
  try {
    return await getSingleEntity(`<VendorQueryRq><FullName>${vendorName}</FullName></VendorQueryRq>`, mapQBDTVendor);
  } catch (error) {
    if (error instanceof QBDTSDKError && error.code === '500') {
      return null;
    }

    logger.error(`vendor.getQbdtVendorByName(): Failed to get ${vendorName} from QBDT`, error);
    throw error;
  }
}

export async function createQbdtVendor(melioVendor: VendorType) {
  const result = await executeSDKRequest(
    `<VendorAddRq>
        <VendorAdd>
          <Name>${melioVendor.companyName}</Name>
          <CompanyName>${melioVendor.companyName}</CompanyName>
          ${(melioVendor.contactName && `<FirstName>${escapeXMLString(melioVendor.contactName)}</FirstName>`) || ''}
          ${(melioVendor.contactPhone && `<Phone>${melioVendor.contactPhone}</Phone>`) || ''}
          ${(melioVendor.contactEmail && `<Email>${escapeXMLString(melioVendor.contactName)}</Email>`) || ''}
        </VendorAdd>
      </VendorAddRq>`
  );

  return mapQBDTVendor(result.querySelector('VendorRet'));
}

type UpdateQbdtVendorEmailParams = {
  externalId?: string;
  name: string;
  contactEmail: string;
};

export async function updateQbdtVendorEmail({ externalId, name, contactEmail }: UpdateQbdtVendorEmailParams) {
  let qbdtVendor: QBDTVendor | null;
  if (externalId) {
    qbdtVendor = await getQbdtVendor(externalId);
  } else {
    qbdtVendor = await getQbdtVendorByName(name);
  }

  if (!qbdtVendor) {
    logger.error(`vendor.updateQbdtVendorEmail(): Failed to get vendor ${externalId}, ${name} from QBDT`);
    return null;
  }

  const result = await executeSDKRequest(
    `<VendorModRq>
        <VendorMod>
          <ListID>${qbdtVendor.ListID}</ListID>
          <EditSequence>${qbdtVendor.EditSequence}</EditSequence>
          <Email>${contactEmail || ''}</Email>
        </VendorMod>
      </VendorModRq>`
  );

  return mapQBDTVendor(result.querySelector('VendorRet'));
}

export function getVendors(): Promise<QBDTVendor[]> {
  throw Error('Not implemented');
}

export function qbdtVendorToMelio(qbdtVendor: QBDTVendor) {
  return {
    companyName: qbdtVendor.Name,
    contactPhone: qbdtVendor.Phone,
    contactEmail: qbdtVendor.Email,
  };
}
