import React from 'react';
import { Box, HStack, VStack } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import { Elements } from './Elements/Elements';
import { ReactComponent as WizzardImg } from './images/wizzard-img.svg';

type Props = {
  onClose: VoidFunction;
};

const DashboardFTUMessage = ({ onClose }: Props) => (
  <Box w="full" position="fixed" bottom={0} bgColor="white">
    <Box w="full" h="0.5rem" bgGradient="linear(to-r, green.300, #00C1BF)" />

    <HStack px="7.2rem" justify="space-between">
      <VStack spacing="ds.xl" color="ds.gray.100" align="flex-start" my="ds.lg">
        <Box textStyle="ds.h5">
          <MIFormattedText label="paymentDashboard.ftuModal.title" />
        </Box>
        <Elements />
        <MIButton
          variant={BUTTON_VARIANT.PRIMARY}
          size={BUTTON_SIZE.NORMAL}
          onClick={onClose}
          label="paymentDashboard.ftuModal.ctaButton"
        />
      </VStack>

      <WizzardImg />
    </HStack>

    <Box
      as="i"
      className="icon-close-icon"
      onClick={onClose}
      color="ds.gray.200"
      fontSize="2.4rem"
      cursor="pointer"
      position="absolute"
      top="ds.xl"
      right="ds.lg"
    />
  </Box>
);

export { DashboardFTUMessage };
