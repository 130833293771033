import { RecordOf } from 'immutable';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { AccountType } from 'src/utils/types';

type FundingSourceDescriptionProps = {
  fundingSource: RecordOf<AccountType>;
};

const FundingSourceDescription = ({ fundingSource }: FundingSourceDescriptionProps) => {
  let description;
  if (fundingSource?.bankAccount) {
    const displayName = fundingSource ? fundingSource.getDisplayName() : '';
    const institutionName = fundingSource ? fundingSource.getInstitutionName() : '';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    if (institutionName) {
      description = `${displayName} (${institutionName}, ...${cutAccountNumber})`;
    } else {
      description = `${displayName} (...${cutAccountNumber})`;
    }
  } else if (fundingSource?.cardAccount) {
    description = `${fundingSource.cardAccount.network} (...${fundingSource.cardAccount.card4digits})`;
  }

  return description;
};

export default FundingSourceDescription;
