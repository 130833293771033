import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Flex, Image, Text, VStack } from '@melio/billpay-design-system';
import paymentsStore from 'src/modules/payments/payment-store';
import { useBreak } from 'src/hoc';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { getJWTPayload } from 'src/helpers/jwt';
import { MIFormattedText } from 'src/utils/formatting';
import POSTerminalImage from 'src/images/virtual-card/POS.png';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';
import { Footer, VendorFlowLayout, VendorFlowContentCard } from 'src/pages/vendor/components/VendorFlowLayout';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import analytics from 'src/services/analytics';
import locations from '../../locations';

interface Props {
  token: string;
}

const PosTerminalPage = ({ token }: Props) => {
  const history = useHistory();
  const { isDesktop } = useBreak();
  const { paymentId } = getJWTPayload(token);

  const paymentActions = useStoreActions(paymentsStore);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));

  useEffect(() => {
    paymentActions.fetchPaymentDetailsWithToken({ token });
  }, [token]);

  useEffect(() => {
    if (payment) {
      analytics.trackAction('page-viewed', {
        paymentId: payment?.id,
        vendorId: payment?.vendor?.id,
      });
    }
  }, [payment]);

  const viewCardDetails = () => {
    const virtualCardId = payment?.lastCreatedVirtualCard?.id;

    if (virtualCardId) {
      analytics.trackAction('view-card-clicked', {
        paymentId: payment?.id,
        vendorId: payment?.vendor?.id,
      });

      history.push(generatePath(locations.virtualCardDetails.details, { token, id: virtualCardId }));
    }
  };

  const chooseAnotherDeliveryMethod = () => {
    analytics.trackAction('receive-bank-transfer-clicked', {
      paymentId: payment?.id,
      vendorId: payment?.vendor?.id,
    });

    history.push(generatePath(locations.shiftVirtualCardDetailsToAch.index, { token }));
  };

  if (!payment) {
    return <QBDTLoader />;
  }

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" textAlign="center" gap="ds.md" mb="ds.xl">
          <Text textStyle="ds.h6" m="0">
            <MIFormattedText label="vendor.unilateral.virtualCardDetails.posTerminal.title" />
          </Text>
          <Text textStyle="ds.body2" m="0">
            <MIFormattedText label="vendor.unilateral.virtualCardDetails.posTerminal.text" />
          </Text>
        </Flex>
        <Image src={POSTerminalImage} w="100%" />
        {isDesktop && (
          <VStack gap="ds.md" mt="ds.2xl" w="100%">
            <MIButton
              testId="unilateral-pos-terminal-page-submit"
              label="vendor.unilateral.virtualCardDetails.posTerminal.getCardDetails"
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={viewCardDetails}
              fullWidth
            />
            <MIButton
              testId="unilateral-pos-terminal-page-change-method"
              label="vendor.unilateral.virtualCardDetails.posTerminal.receiveBankTransferInstead"
              variant={CONSTS.BUTTON_VARIANT.SECONDARY}
              onClick={chooseAnotherDeliveryMethod}
              fullWidth
            />
          </VStack>
        )}
      </VendorFlowContentCard>
      <Footer />
      {!isDesktop && (
        <VStack
          gap="ds.md"
          direction="column"
          bg="ds.white"
          p="ds.xl"
          boxSizing="border-box"
          width="100%"
          position="sticky"
          bottom="0"
        >
          <MIButton
            label="vendor.unilateral.virtualCardDetails.posTerminal.getCardDetails"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={viewCardDetails}
            fullWidth
          />
          <MIButton
            label="vendor.unilateral.virtualCardDetails.posTerminal.receiveBankTransferInstead"
            variant={CONSTS.BUTTON_VARIANT.SECONDARY}
            onClick={chooseAnotherDeliveryMethod}
            fullWidth
          />
        </VStack>
      )}
    </VendorFlowLayout>
  );
};

export { PosTerminalPage };
