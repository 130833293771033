import React from 'react';
import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { QBDTAuthErrorPage } from './QBDTAuthErrorPage';

import locations from '../locations';
import { QBDTEntryRouter } from './QBDTEntryRouter';

export const QBDTEntryBaseRouter = () => (
  <Switch>
    <SmartRoute path={locations.entry.authError}>
      <QBDTAuthErrorPage />
    </SmartRoute>
    <QBDTEntryRouter />
  </Switch>
);
