import React from 'react';
import { close } from 'src/billpay/qbdt/services/qbdt';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import SuccessIllustration from 'src/images/qbo/success-check.svg';

export const QBDTBillsAlreadyScheduledPage = () => (
  <QBDTSuccessLayout
    illustration={SuccessIllustration}
    title="batchPayment.billsAlreadyScheduled.title"
    text="batchPayment.billsAlreadyScheduled.description"
    buttonLabel="batchPayment.billsAlreadyScheduled.done"
    buttonAction={close}
    hideHeader
  />
);
