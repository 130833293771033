import React, { useCallback, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { CONSTS } from 'src/utils/consts';
import styled from 'styled-components';
import locations from 'src/pages/locations';
import {
  QBDTStepLayout,
  WizardInner,
  WizardStepActionsContainer,
  WizardStepTitle,
  WizardFormRow,
  WizardStepSubTitle,
} from 'src/billpay/qbdt/components';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { MicroDepositPopover } from 'src/pages/funding-sources/components/MicroDepositPopover';
import { MITextInput } from 'src/components/common/MITextInput';
import { useMicroDepositAmountValidation } from 'src/hooks/settings/microDepositsCommon';
import { MicroDepositsErrorMessage } from 'src/pages/funding-sources/components/MicroDepositsErrorMessage';
import { MicroDepositState, MicroDepositActions } from 'src/pages/settings/hooks/microDepositsCommon';

import {
  microDepositsEventAction,
  microDepositsEventPage,
  microDepositsEventStatuses,
} from 'src/pages/funding-sources/consts';
import analytics from 'src/services/analytics';
import { devices } from 'src/theme/AppDevices';
import { useQuery } from 'src/hooks/useQuery';
import { useBreak } from 'src/hoc';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import {
  useFundingSourceMicroDepositState,
  FundingSourceMicroDepositProps,
} from '../settings/hooks/useFundingSourceMicroDeposits';
import { getMicrodepositMask } from '../../utils/currency-utils';

const eventPage = 'micro-deposits-verification-page';

const getContainerSize = ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => {
  const sizeMap = {
    mobile: 100,
    tablet: 70,
    desktop: 50,
  };

  if (isMobile) {
    return sizeMap.mobile;
  }

  if (isTablet) {
    return sizeMap.tablet;
  }

  return sizeMap.desktop;
};

export function MicroDepositsVerifyPage(props: FundingSourceMicroDepositProps & { fundingSourceId: number }) {
  const [state, actions] = useFundingSourceMicroDepositState(eventPage, props, true);

  const history = useHistory();
  const { fundingSourceId: id } = props;
  const digits = getAccountNumber4digits(state?.fundingSource?.bankAccount);

  if (state.isLoading) {
    return <QBDTLoader />;
  }

  if (state.serverError === CONSTS.MICRODEPOSITS_VERIFY_SERVER_ERRORS.EXPIRED_TOKEN) {
    history.push(generatePath(locations.microDepositsVerify.expired, { id }));

    return null;
  }

  if (state?.fundingSource?.isVerified) {
    history.push(generatePath(locations.microDepositsVerify.alreadyVerified, { id }));

    return null;
  }

  if (state?.fundingSource?.bankAccount?.depositsNotSent) {
    history.push(generatePath(locations.microDepositsVerify.cannotVerifyYet, { id }), { digits });

    return null;
  }

  return <VerifyPage actions={actions} fundingSourceId={id} state={state} digits={digits} />;
}

type VerifyPageProps = {
  actions: MicroDepositActions;
  fundingSourceId: number;
  digits: string;
  state: MicroDepositState;
};

const VerifyPage = ({ actions, fundingSourceId, digits, state }: VerifyPageProps) => {
  const { isMobile, isTablet, isPhablet, isDesktop } = useBreak();
  const query = useQuery();

  useEffect(() => {
    const originalSource = query.get('button_clicked');

    analytics.track(microDepositsEventPage, microDepositsEventAction, {
      fundingSourceId,
      status: microDepositsEventStatuses.VERIFY,
      originalSource: originalSource ?? null,
    });
  }, []);

  const containerSize = getContainerSize({
    isMobile,
    isTablet: isTablet || isPhablet,
  });

  const { onSubmit, onChange } = actions;

  const [errors, handleChange, resetErrors] = useMicroDepositAmountValidation(onChange);

  const handleSubmit = useCallback(() => {
    if (errors.amount1 || errors.amount2) {
      return;
    }

    resetErrors();
    onSubmit();
  }, [resetErrors, onSubmit]);

  return (
    <StyledQBDTStepLayout
      title="fundingSources.microDeposits.verify.title"
      subtitle="fundingSources.microDeposits.verify.subtitle"
      onNext={handleSubmit}
      nextLabel="fundingSources.microDeposits.verify.confirm"
      isLoading={state.isFundingSourceVerifying}
      hideHeader
      subTitleValues={{
        digits,
        break: isMobile ? (
          <br />
        ) : (
          <>
            <br />
            <br />
          </>
        ),
      }}
      innerSize={containerSize}
    >
      <StyledWizardFormRow>
        <TextInputContainer>
          <MITextInput
            id="amount1"
            label="fundingSources.microDeposits.verify.sum1Label"
            placeholder="fundingSources.microDeposits.verify.placeholder"
            value={state.amount1}
            autoFocus
            required
            onChange={handleChange}
            errorMessage={state.validationErrors.amount1 || errors.amount1}
            size={CONSTS.TEXT_INPUT_SIZE.INLINE}
            mask={getMicrodepositMask(true)}
          />
        </TextInputContainer>
        <TextInputContainer>
          <MITextInput
            id="amount2"
            label="fundingSources.microDeposits.verify.sum2Label"
            placeholder="fundingSources.microDeposits.verify.placeholder"
            value={state.amount2}
            required
            onChange={handleChange}
            errorMessage={state.validationErrors.amount2 || errors.amount2}
            size={CONSTS.TEXT_INPUT_SIZE.INLINE}
            mask={getMicrodepositMask(true)}
          />
        </TextInputContainer>
      </StyledWizardFormRow>
      {state.serverError && <MicroDepositsErrorMessage serverError={state.serverError} />}
      <MicroDepositPopover isDesktop={isDesktop} />
    </StyledQBDTStepLayout>
  );
};

const TextInputContainer = styled.div`
  width: 100%;

  &:first-child {
    margin-right: 2rem;
  }
`;

const StyledWizardFormRow = styled(WizardFormRow)`
  @media ${devices.nonDesktop} {
    flex-direction: column;
  }
`;

const StyledQBDTStepLayout = styled(QBDTStepLayout)`
  ${WizardStepSubTitle} {
    font-size: 1.6rem;
    line-height: 2.4rem;
    max-width: 50%;
  }

  ${WizardInner} {
    width: 99.2rem;
  }

  ${WizardStepTitle} {
    margin-bottom: 1.6rem;
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }

  ${WizardStepActionsContainer} {
    margin-top: 0;
  }

  @media ${devices.nonDesktop} {
    ${WizardInner} {
      width: auto;
      margin: 0 1.6rem;
      overflow-x: hidden;
    }

    ${WizardStepSubTitle} {
      max-width: 70%;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    ${WizardStepActionsContainer} {
      margin-top: 4.6rem;
    }
  }

  @media ${devices.mobile} {
    ${WizardInner} {
      max-width: 34.3rem;
    }

    ${WizardStepSubTitle} {
      max-width: 100%;
    }
  }
`;
