import React from 'react';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import { DELIVERY_TYPE } from 'src/utils/consts';

type Props = {
  deliveryType: DELIVERY_TYPE;
};

export const DeliveryMethodIcon = ({ deliveryType }: Props) => {
  if (deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
    return <Mastercard width="3.2rem" />;
  }

  if (deliveryType === DELIVERY_TYPE.CHECK) {
    return <i className="icon-check-icon" />;
  }

  return <i className="icon-bank-icon" />;
};
