import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc/index';
import analytics from 'src/services/analytics';
import { CompanyInfoRecord } from 'src/records/settings.record';
import { CONSTS } from 'src/utils/consts';
import userApi from 'src/services/api/user';
import companyInfoApi from 'src/services/api/organizations';
import { AccountLimitations, FieldType, GoogleCombinedAddressType } from 'src/utils/types';
import {
  convertLegalAddressToGoogleAddress,
  convertCompanyAddressToGoogleAddress,
  convertToServerAddress,
  convertToServerLegalAddress,
} from 'src/utils/address';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOrgId, getProfile } from 'src/redux/user/selectors';
import { DATE_FORMATS } from 'src/utils/date-fns';
import {
  validateBusinessType,
  validateCompanyBusinessAddress,
  validateContactFirstName,
  validateContactLastName,
  validateContactPhone,
  validateDateOfBirth,
  validateIndustryType,
  validateLegalCompanyName,
  validateTaxId,
  validateTaxIdType,
} from 'src/utils/compliance-validator';
import { calculateComplianceAnalyticsFields } from 'src/utils/compliance-utils';
import { UpdateIndustryRequestData, industryApi } from 'src/services/api/industry';
import { OnSelectNaicsCode } from 'src/billpay/qbdt/components/CompanyIndustryAutocomplete';
import { getComplianceLimitations } from 'src/redux/payBillWizard/selectors';
import { PayBillProps, withPayBillData } from '../hoc/withPayBillData';
import { QBDTPayBillCompleteLegalInfoPage } from './components/QBDTPayBillCompleteLegalInfoPage';
import { useOnPaymentSubmit } from '../hooks/useOnPaymentSubmit';

const QBDTPayBillCompleteLegalInfoPageContainer = ({ goExit, onNext }: PayBillProps) => {
  const companyInfo = useSelector(getCompanyInfo);
  const profile = useSelector(getProfile);
  const orgId = useSelector(getOrgId);
  const limitations: AccountLimitations = useSelector(getComplianceLimitations);
  const dispatch = useDispatch();
  const { onSubmit } = useOnPaymentSubmit({ onNext });

  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [legalInfoState, setLegalInfoState] = useState({
    legalAddress: convertLegalAddressToGoogleAddress(companyInfo),
    legalCompanyName: companyInfo.legalCompanyName,
    taxId: companyInfo.taxId,
    taxIdType: companyInfo.taxIdType,
    contactFirstName: companyInfo.contactFirstName,
    contactLastName: companyInfo.contactLastName,
    phone: companyInfo.phone,
    companyAddress: convertCompanyAddressToGoogleAddress(companyInfo),
    openLegalAddressInput: false,
    businessType: companyInfo.businessType,
  });
  const [{ naicsCode, industryText }, setNaicsData] = useState<UpdateIndustryRequestData>({
    naicsCode: null,
    industryText: '',
  });
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(
    profile?.dateOfBirth
      ? format(new Date(profile.dateOfBirth), DATE_FORMATS.monthShortWithLongDateAndYearDashed)
      : undefined
  );

  useEffect(() => {
    analytics.track('Organization', 'View', {
      PageName: 'one-last-thing',
      Flow: 'payment',
      Intent: 'pay',
      ...calculateComplianceAnalyticsFields(limitations),
    });
  });

  const onResetNaicsCode = () => {
    setNaicsData({ naicsCode: null, industryText: undefined });
  };

  const onSelectNaicsCode: OnSelectNaicsCode = (naicsData) => {
    setNaicsData(naicsData);
  };

  const onLegalInfoChange = ({ id, value }: FieldType) => {
    setLegalInfoState((prevState) => ({ ...prevState, [id]: value }));
  };

  const onAddressChange = (address: GoogleCombinedAddressType, type = CONSTS.ADDRESS_NAME_TYPE.LEGAL_ADDRESS) => {
    setLegalInfoState((prevState) => ({ ...prevState, [type]: address }));
  };

  const setCompanyInfo = (companyInfo) => {
    dispatch(setCompanyInfoAction(companyInfo));
  };

  const handleOnSubmit = async () => {
    analytics.track('Organization', 'Click', {
      PageName: 'one-last-thing',
      Flow: 'payment',
      Intent: 'submit-details',
      Cta: 'save-and-schedule-my-payment',
      ...calculateComplianceAnalyticsFields(limitations),
    });
    const {
      legalAddress,
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      companyAddress,
      phone,
      openLegalAddressInput,
      businessType,
    } = legalInfoState;

    const address = openLegalAddressInput ? legalAddress : companyAddress;
    const isValidateLegalAddress = openLegalAddressInput;
    const dateOfBirthValue = dateOfBirth?.toString();
    const parsedDate = dateOfBirthValue
      ? parse(dateOfBirthValue, DATE_FORMATS.monthShortWithLongDateAndYearDashed, new Date())
      : null;

    const dataToUpdate = companyInfo.merge({
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      phone,
      businessType,
      ...convertToServerAddress(companyAddress),
      ...convertToServerLegalAddress(address),
    });
    const inputFields = [
      'legalCompanyName',
      'taxId',
      'addressLine1',
      'city',
      'zipCode',
      'taxIdType',
      'contactFirstName',
      'contactLastName',
      'phone',
    ];
    const inputFieldsToValidate =
      isValidateLegalAddress && inputFields?.length
        ? [...inputFields, 'legalZipCode', 'legalAddressLine1', 'legalCity']
        : inputFields;

    let validationErrors = getValidationErrors('companyInfo', dataToUpdate, inputFieldsToValidate);

    validationErrors = {
      ...validateBusinessType(businessType),
      ...validateIndustryType(naicsCode),
      ...validateDateOfBirth(parsedDate),
      ...validateContactFirstName(contactFirstName),
      ...validateContactLastName(contactLastName),
      ...validateContactPhone(phone),
      ...validateLegalCompanyName(legalCompanyName),
      ...validateTaxIdType(taxIdType),
      ...validateTaxId(businessType, taxIdType, taxId),
      ...validateCompanyBusinessAddress(companyAddress, false),
    };

    if (openLegalAddressInput) {
      validationErrors = {
        ...validationErrors,
        ...validateCompanyBusinessAddress(legalAddress, true),
      };
    }

    setValidationErrors(validationErrors);
    setIsPaymentProcessing(true);
    if (isValidationOk(validationErrors)) {
      analytics.track('Organization', 'Status', {
        PageName: 'one-last-thing',
        Flow: 'payment',
        Intent: 'submit-details',
        Status: 'success',
        Integrations: { Salesforce: true },
      });
      try {
        const [{ companyInfo: updatedCompanyInfo }] = await Promise.all([
          companyInfoApi.updateCompanyInfo(orgId, dataToUpdate),
          industryApi.updateIndustry(orgId, { naicsCode, industryText }),
          userApi.update({ id: profile.id, dateOfBirth: parsedDate?.toISOString() }),
        ]);

        const companyInfoRecord = CompanyInfoRecord(updatedCompanyInfo);
        setCompanyInfo(companyInfoRecord);
        await onSubmit();
      } catch (error: any) {
        setIsPaymentProcessing(false);
      }
    } else {
      analytics.track('Organization', 'Status', {
        PageName: 'one-last-thing',
        Flow: 'payment',
        Intent: 'submit-details',
        Status: 'failure',
        ValidationErrors: { ...validationErrors },
      });
      setIsPaymentProcessing(false);
    }
  };

  const {
    legalAddress,
    legalCompanyName,
    taxId,
    taxIdType,
    contactFirstName,
    contactLastName,
    companyAddress,
    openLegalAddressInput,
    phone,
    businessType,
  } = legalInfoState;

  return (
    <QBDTPayBillCompleteLegalInfoPage
      legalCompanyName={legalCompanyName}
      companyName={companyInfo.companyName}
      taxId={taxId}
      contactFirstName={contactFirstName}
      contactLastName={contactLastName}
      companyAddress={companyAddress}
      phone={phone}
      onLegalInfoChange={onLegalInfoChange}
      openLegalAddressInput={openLegalAddressInput}
      taxIdType={taxIdType}
      address={legalAddress}
      dateOfBirth={dateOfBirth}
      businessType={businessType}
      goExit={goExit}
      onAddressChange={onAddressChange}
      validationErrors={validationErrors}
      handleOnSubmit={handleOnSubmit}
      onSelectNaicsCode={onSelectNaicsCode}
      onResetNaicsCode={onResetNaicsCode}
      onDateOfBirthChange={setDateOfBirth}
      isLoading={isPaymentProcessing}
    />
  );
};

export default compose(withNavigator(), withPayBillData())(QBDTPayBillCompleteLegalInfoPageContainer);
