import React from 'react';
import { useSelector } from 'react-redux';
import QBDTJoinService from 'src/pages/vendor/components/QBDTJoinService';
import styled from 'styled-components';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import { MIFormattedText } from 'src/utils/formatting';
import successImage from 'src/images/icons/icons_check.svg';
import qbLogo from 'src/images/accounting-software/quickbooks-logo-horz.png';
import { getAccountNumber4digits } from 'src/utils/bank-account';

const AddDeliveryMethodSuccessPage = () => {
  const { deliveryMethodId } = useSelector(deliveryMethodsStore.selectors.validation);
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId));
  const digits = getAccountNumber4digits(deliveryMethod?.bankAccount);

  return (
    <QBOLayoutPage contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <Content>
        <SuccessImage src={successImage} />

        <Title>
          <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.title" />
        </Title>

        <SubTitle>
          <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.subTitle" values={{ digits }} />
        </SubTitle>

        <Separator />

        <QBDTJoinService labelPrefix="vendors.deliveryMethods.ach.changeSuccess" utmCampaign="shift_vendor_to_ach" />
      </Content>
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
  margin-bottom: 4.2rem;
  height: auto;
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const SuccessImage = styled.img`
  margin-bottom: 2rem;
  width: 4.8rem;
`;

const Separator = styled.div`
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.border.darkGrey};
  margin: 2rem 0 4rem;
  width: 100%;
  margin: 4rem 0;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.text.size.subNav};
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  color: ${(props) => props.theme.text.color.main};
`;

export default AddDeliveryMethodSuccessPage;
