import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';

export const removeIdsFromState = (state, ids) => {
  forEach(state.lists, (list) => {
    list.order = list.order.filter((id) => ids.indexOf(id) === -1);
    if (list.totalCount) {
      list.totalCount = list.order.length;
    }
  });
  forEach(ids, (id) => {
    delete state.byId[id];
  });
};

export const updateItemsInState = (state, items) => {
  const itemsById = keyBy(items, 'id');
  state.byId = merge(state.byId, itemsById);
};
