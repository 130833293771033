import {
  QBDTBillPaymentCheck,
  QBDTBillPaymentCreditCard,
  QBDTDataExtAssignToObject,
  QBDTDataExtType,
  QBDTTxnType,
  RetryOptions,
} from '../types';
import { mapQBDTDataExtDef } from '../typeMapper';
import { executeSDKRequest, getAllEntities, QBDTSDKError } from '../sdk';

export const DATA_EXT_UUID = '1905a91c-a96f-462a-92de-cd38f51f7691';

export enum DATA_EXT_DEF {
  MELIO_ID = 'MelioId',
}

const DEFINED_DATA_EXTENSIONS = {};

async function createDataExtension(name) {
  await executeSDKRequest(`
    <DataExtDefAddRq>
      <DataExtDefAdd> <!-- required -->
        <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
        <DataExtName>${name}</DataExtName>
        <DataExtType>${QBDTDataExtType.STR255TYPE}</DataExtType>
        <AssignToObject>${QBDTDataExtAssignToObject.Bill}</AssignToObject>
        <AssignToObject>${QBDTDataExtAssignToObject.BillPaymentCheck}</AssignToObject>
        <AssignToObject>${QBDTDataExtAssignToObject.BillPaymentCreditCard}</AssignToObject>
        <AssignToObject>${QBDTDataExtAssignToObject.Vendor}</AssignToObject>
      </DataExtDefAdd>
    </DataExtDefAddRq>
  `);

  DEFINED_DATA_EXTENSIONS[name] = true;
}

export function mapDataExtentions(doc: Element) {
  const allExts = Array.from(doc.querySelectorAll('DataExtRet'));
  const onlyMelio = allExts.filter((d) => d.querySelector('OwnerID')?.textContent === `{${DATA_EXT_UUID}}`);
  return onlyMelio.reduce((obj, d) => {
    const name = d.querySelector('DataExtName')?.textContent;
    const value = d.querySelector('DataExtValue')?.textContent;
    if (name) {
      obj[name] = value;
    }

    return obj;
  }, {}) as Record<string, string>;
}

export function getMelioId(doc: Element) {
  const extValues = mapDataExtentions(doc);
  return extValues[DATA_EXT_DEF.MELIO_ID];
}

export async function verifyExtensionDef(name) {
  try {
    if (DEFINED_DATA_EXTENSIONS[name]) return;

    const extensionDefinitions = await getAllEntities(
      `<DataExtDefQueryRq><OwnerID>{${DATA_EXT_UUID}}</OwnerID></DataExtDefQueryRq>`,
      'DataExtDefRet',
      mapQBDTDataExtDef
    );

    if (extensionDefinitions.find((ext) => ext.DataExtName === name)) {
      DEFINED_DATA_EXTENSIONS[name] = true;
    } else {
      await createDataExtension(name);
    }
  } catch (e) {
    if (e instanceof QBDTSDKError && e.code === '1') {
      await createDataExtension(name);
    } else {
      throw e;
    }
  }
}

export async function verifyExtensionDefs() {
  for await (const name of Object.values(DATA_EXT_DEF)) {
    await verifyExtensionDef(name);
  }
}

type AddExtensionDefParams = {
  type: QBDTDataExtAssignToObject;
  entityId: string;
  value: string;
  retryOptions?: RetryOptions;
};

export async function addExtensionDef({ type, entityId, value, retryOptions }: AddExtensionDefParams) {
  switch (type) {
    case QBDTDataExtAssignToObject.Bill:
    case QBDTDataExtAssignToObject.BillPaymentCheck:
    case QBDTDataExtAssignToObject.BillPaymentCreditCard:
      return executeSDKRequest(
        `
          <DataExtAddRq>
            <DataExtAdd>
              <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
              <DataExtName>${DATA_EXT_DEF.MELIO_ID}</DataExtName>
              <TxnDataExtType>${type}</TxnDataExtType>
              <TxnID>${entityId}</TxnID>
              <DataExtValue>${value}</DataExtValue>
            </DataExtAdd>
          </DataExtAddRq>`,
        retryOptions
      );
    case QBDTDataExtAssignToObject.Vendor:
      return executeSDKRequest(
        `
          <DataExtAddRq>
            <DataExtAdd>
              <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
              <DataExtName>${DATA_EXT_DEF.MELIO_ID}</DataExtName>
              <ListDataExtType>${type}</ListDataExtType>
              <ListObjRef>
                <ListID>${entityId}</ListID>
              </ListObjRef>
              <DataExtValue>${value}</DataExtValue>
            </DataExtAdd>
          </DataExtAddRq>`,
        retryOptions
      );
    default:
      throw Error(`Unknown data ext object type ${type}`);
  }
}

export function removeExtensionDef(type: QBDTDataExtAssignToObject, entityId: string) {
  switch (type) {
    case QBDTDataExtAssignToObject.Bill:
    case QBDTDataExtAssignToObject.BillPaymentCheck:
    case QBDTDataExtAssignToObject.BillPaymentCreditCard:
      return executeSDKRequest(`
          <DataExtDelRq>
            <DataExtDel>
              <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
              <DataExtName>${DATA_EXT_DEF.MELIO_ID}</DataExtName>
              <TxnDataExtType>${type}</TxnDataExtType>
              <TxnID>${entityId}</TxnID>
            </DataExtDel>
          </DataExtDelRq>`);
    case QBDTDataExtAssignToObject.Vendor:
      return executeSDKRequest(`
          <DataExtDelRq>
            <DataExtDel>
              <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
              <DataExtName>${DATA_EXT_DEF.MELIO_ID}</DataExtName>
              <ListDataExtType>${type}</ListDataExtType>
              <ListObjRef>
                <ListID>${entityId}</ListID>
              </ListObjRef>
            </DataExtDel>
          </DataExtDelRq>`);
    default:
      throw Error(`Unknown data ext object type ${type}`);
  }
}

export function extensionDefTypeFromBillPayment(qbdtPayment: QBDTBillPaymentCheck | QBDTBillPaymentCreditCard) {
  if (qbdtPayment.TxnType === QBDTTxnType.BillPaymentCheck) {
    return QBDTDataExtAssignToObject.BillPaymentCheck;
  }

  return QBDTDataExtAssignToObject.BillPaymentCreditCard;
}
