import React, { useState, useRef } from 'react';
import { Box } from '@melio/billpay-design-system';
import { useDashboardListParams } from '../../hooks/useDashboardListParams';
import { SearchInput } from './components/SearchInput';

export function DashboardSearchInput() {
  const [listParams, { setSearch }] = useDashboardListParams();
  const [searchValue, setSearchValue] = useState(listParams.filters.search || '');
  const timer = useRef(0);

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => setSearch(searchValue), 400);
  };

  return (
    <Box position="absolute" top="5.6rem" right="2rem">
      <SearchInput
        onSubmit={handleSearch}
        onChange={handleSearch}
        value={searchValue}
        placeholder="paymentDashboard.search.panel.placeholder"
        testId="dashboard-search-field"
      />
    </Box>
  );
}
