/* eslint-disable no-nested-ternary */
import { css } from 'styled-components';
import failedToLoadImage from 'src/images/icons/failed-to-load-002-icon.png';
import { CONSTS } from 'src/utils/consts';
import successImage from '../images/icons/icons_check.svg';
import pendingVerificationImage from '../images/funding-sources/pending-verification-icon.png';
import { devices } from './AppDevices';
import NotFoundPage from '../pages/general/components/QBONotFoundPage';

const moneyFontSize = {
  huge: '9rem',
  extraLarge: '6.4rem',
  large: '3.4rem',
  normal: '2.3rem',
  default: '1.6rem',
  extraSmall: '1.2rem',
};

const buttonHeight = {
  [CONSTS.BUTTON_SIZE.TINY]: 'height: 3.2rem; line-height: 2.4rem',
  [CONSTS.BUTTON_SIZE.VERY_SMALL]: 'height: 3.6rem; line-height: 2rem',
  [CONSTS.BUTTON_SIZE.SMALL]: 'height: 4.2rem; line-height: 3.6rem',
  [CONSTS.BUTTON_SIZE.NORMAL]: 'height: 4.8rem; line-height: 4.8rem',
};
const theme = {
  background: {
    default: 'rgba(244, 245, 248, 1)',
    wizard: 'rgba(244, 245, 248, 1)',
    lightGrey: 'rgba(227, 229, 232, 1)',
  },
  fontFamily: "'Avenir Next forINTUIT', sans-serif;",
  text: {
    color: {
      main: 'rgba(57, 58, 61, 1)',
      label: 'rgba(155, 155, 155, 1)',
      highlight: 'rgba(44, 160, 28, 1)',
      black: 'rgba(0, 0, 0, 1)',
      link: 'rgba(28, 130, 210, 1)',
      green: 'rgba(44, 160, 28, 1)',
      darkGrey: 'rgba(107, 108, 114, 1)',
      arrow: 'rgba(141, 144, 150, 1)',
      lightGrey: 'rgba(212, 215, 220, 1)',
    },
    weight: {
      normal: 500,
      demi: 600,
    },
    size: {
      button: '1.4rem',
      buttonSpace: '0rem',
      wizardTitle: '4.3rem',
      wizardText: '1.4rem',
      wizardStepTitle: '2.4rem',
      wizardStepTitleMobile: '2.4rem',
      errorPageTitle: '1.8rem',
    },
  },
  colors: {
    brand: 'rgba(44, 160, 28, 1)',
    text: 'rgba(57, 58, 61, 1)',
    primary: {
      opaque: 'rgba(44, 160, 28, 1)',
      opaqueDarker: 'rgba(44, 160, 28, 1)',
      translucent3: 'rgba(33, 33, 36, 0.3)',
      translucent2: 'rgba(44, 160, 28, 0.2)',
      translucent1: 'rgba(44, 160, 28, 0.1)',
      active: 'rgba(44, 160, 28, 1)',
    },
    white: {
      veryLightGrey: 'rgba(255, 255, 255, 1)',
    },
    progress: {
      done: 'rgba(44, 160, 28, 1)',
    },
    icons: {
      card: 'rgba(57, 58, 61, 1)',
    },
    success: {
      opaque: 'rgba(44, 160, 28, 1)',
      translucent1: 'rgba(44, 160, 28, 0.1)',
    },
    shadow: {
      lightGrey: 'rgba(212, 215, 220, 1)',
    },
    background: '#F4F5F8',
  },
  icons: {
    'support-icon': '\\e946',
    'plus-icon': '\\e945',
    'minus-icon': '\\e944',
    'checkmark-icon': '\\e943',
    'back-icon': '\\e942',
    'settings-icon': '\\e941',
    'lock-icon': '\\e940',
    'help-icon': '\\e93f',
    'edit-icon': '\\e93e',
    'debit-card-icon': '\\e93d',
    'credit-card-icon': '\\e93d',
    'close-icon': '\\e93c',
    'check-icon': '\\e93b',
    'scheduled-icon': '\\e93a',
    'eta-cal': '\\e93a',
    'bank-icon': '\\e939',
    'bank-transfer': '\\e939',
    'warning-icon': '\\e959',
    'schedule-bill': '\\e95f',
    'prev-icon': '\\e95e',
    'prev-icon-2': '\\e907',
    'prev-page-icon': '\\e907',
    'next-page-icon': '\\e906',
    'first-page-icon': '',
    'last-page-icon': '',
  },
  components: {
    MethodElements: {
      MethodIcon: (props: any) => `
        height: 4rem;
        width: 4rem;
        color: ${props.disabled ? props.theme.colors.dark.translucent3 : 'rgba(107,108,114,1)'};
        font-size: 4rem;
        align-self: flex-start;
      `,
      MethodImage: () => `
        width: 4rem;
        height: inherit;
        max-height:4rem;
      `,
      MethodOptionDescription: (props: any) => `
        line-height: 2rem;
        color: ${props.theme.text.color.label};
        font-size: 1.4rem;
        font-weight: ${props.theme.text.weight.normal};
        img {
          height: 2.4rem;
          margin-top: 1.2rem;
        }
      `,
      MethodOptionLabel: (props: any) => `
        line-height: 2rem;
        color: ${props.disabled ? props.theme.colors.dark.translucent3 : props.theme.colors.text};
        font-size: 1.4rem;
        font-weight: ${props.theme.text.weight.normal};
      `,
    },
    MethodsList: {
      AddAnotherRadioMethodOption: () => `
        margin-bottom: 2.5rem;
        margin-top: -1rem;
      `,
      GroupContainer: () => `
        margin-bottom: 0;
      `,
    },
    SettingsMethodsListItem: {
      SettingsMethodsItemContainer: () => `
        padding: 0;
      `,
    },
    RadioSelectMethodOption: {
      RadioSelectOptionContainer: (props: any) => `
        min-height: 8.4rem;
        justify-content: center;
        box-shadow: ${props.disabled ? 'none' : `0 0.2rem 1rem 0 ${props.theme.colors.shadow.lightGrey}`};
        background-color: ${
          props.disabled
            ? props.theme.colors.white.veryLightGrey
            : props.isSelected
            ? props.theme.colors.primary.translucent1
            : props.theme.colors.white.opaque
        }};
      border-width: 0.1rem;
        padding: ${props.disabled ? '1.9rem' : '2rem'};
        &:hover:not(:active) {
          ${
            props.disabled || !props.isSelected
              ? ''
              : `
            box-shadow: 0 0.2rem 1rem 0 rgba(192, 196, 203, 1);
          `
          }
        };
      `,
    },
    MIButton: {
      MIButton: (props: any) => `
        border-radius: 1.8rem;
        font-size: ${props.theme.text.size.regular};
        font-weight: ${props.theme.text.weight.semiBold};
        line-height: 2rem;
        height: ${props.size !== CONSTS.BUTTON_SIZE.NORMAL ? buttonHeight[props.size] : '3.6rem'};
        padding: 0 2.4rem;
        box-shadow: none;
        text-transform: none;
        @media ${devices.mobile}, ${devices.phablet} {
          border-radius: 2.4rem;
          -webkit-tap-highlight-color: transparent;
        }
      `,
      PrimaryButton: (props: any) => `
        box-shadow: none;
        &:hover {
          background-color: rgba(30, 144, 10, 1);
        }

        &:disabled {
          color: ${props.processing ? 'transparent' : props.theme.colors.white.opaque};
          background-color: rgba(44, 160, 28, 0.4);
        }

        &:active {
          background-color:
            ${props.processing ? props.theme.colors.brand : 'rgba(16, 128, 2, 1)'};
        }
      `,
      SecondaryButton: (props: any) => `
        border: solid 0.2rem rgba(57, 58, 61);
        color: rgba(57, 58, 61);
        &:hover {
          background-color: ${props.processing ? 'transparent' : 'rgba(141, 144, 150, 0.5)'};
        }

        &:disabled {
          color: rgba(57, 58, 61, 0.4);
          border: solid 0.2rem rgba(57, 58, 61, 0.4);
        }

        &:active {
          background-color:
            ${props.processing ? 'transparent' : 'rgba(141, 144, 150)'};
        }
      `,
      PayButton: (props: any) => `
        background-color: rgba(44, 160, 28, 1);
        &:hover {
          background-color: rgba(30, 144, 10, 1);
        }

        &:disabled {
          color: ${props.processing ? 'transparent' : props.theme.colors.white.opaque};
          background-color: rgba(44, 160, 28, 0.4);
        }

        &:active {
          background-color:
            ${props.processing ? props.theme.colors.brand : 'rgba(16, 128, 2, 1)'};
        }
      `,
      InlineIcon: () => `
        font-size: 2.4rem;
        margin-right: 0.8rem;
      `,
      DestructiveButton: () => `
        box-shadow: none;
      `,
    },
    MIAddressAutocompleteSmartyStreets: {
      IconStyles: () => `
        line-height: 7.4rem;
      `,
    },
    MITag: {
      TagContainer: (props: any) => css`
        ${props.theme.text.fontType.hint};
        ${{
          [CONSTS.TAG_VARIANT.ERROR]: css`
            background-color: ${props.theme.colors.white.opaque};
            color: ${props.theme.colors.failure.opaque};
          `,
          [CONSTS.TAG_VARIANT.BRAND]: css`
            background-color: ${props.theme.colors.white.opaque};
            color: ${props.theme.colors.primary.opaque};
          `,
        }[props.variant]}

        @media ${devices.mobile}, ${devices.phablet} {
          ${{
            [CONSTS.TAG_VARIANT.ERROR]: css`
              background-color: ${props.theme.colors.failure.opaque};
              color: ${props.theme.colors.white.opaque};
            `,
            [CONSTS.TAG_VARIANT.BRAND]: css`
              background-color: ${props.theme.colors.white.opaque};
              color: ${props.theme.colors.primary.opaque};
            `,
          }[props.variant]}
        }
      `,
    },
    MIInlineLink: {
      StyledMIInlineButton: (props: any) => `
        color: ${props.theme.text.color.link};
        font-weight: ${props.theme.text.weight.normal};
        min-height: 4rem;
        &:active {
          color: ${props.theme.text.color.link};
        }
      `,
    },
    DatePicker: {
      Container: (props: any) => `
        .small-calendar .react-datepicker__day--disabled {
          color: rgb(204, 204, 204);
        }
        @media ${devices.mobile}, ${devices.phablet} {
          .react-datepicker__current-month {
            font-weight: ${props.theme.text.weight.normal};
          }
          .react-datepicker__close-icon {
            top: 2.9rem;
          }
        }
      `,
    },
    MITextInput: {
      placeholderStyle: () => `
        font-size: 1.6rem;
      `,
      TextInput: (props: any) => `
        height: 3.3rem;
        border-bottom: 0.1rem solid rgba(186,190,197,1);
        line-height: 2rem;
        font-size: 1.6rem;
        padding: inherit;
        font-weight: ${props.theme.text.weight.normal};
        @media ${devices.mobile}, ${devices.phablet} {
          border-color: ${props.error ? props.theme.text.color.error : props.theme.text.color.light};
        }
      `,
      TextInputContainer: () => `
        > label {
          color: rgba(141, 144, 150, 1);
        };
        @media ${devices.mobile}, ${devices.phablet} {
          margin-bottom: 1.6rem;
        }
      `,
    },
    MIInputLabel: {
      InputLabel: (props: any) => `
        height: 2rem;
        color: rgba(141,144,150,1);
        font-size: 1.4rem;
        font-weight: ${props.theme.text.weight.normal};
        line-height: 2rem;
        margin-bottom: 0;
        text-transform: none;
        @media ${devices.mobile}, ${devices.phablet} {
          color: ${props.errorMessage ? props.theme.text.color.error : props.theme.text.color.light};
        }
      `,
    },
    BillPaymentReview: {
      showTotalAmount: true,
      MainBox: (props: any) => `
        align-items: inherit;
        background-color: inherit;
        text-align: inherit;
        border-radius: 0;
        box-shadow: none;

        &.review {
          border-radius: 0.8rem;
          background-color: ${props.theme.colors.white.opaque};
          box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
        }
      `,
      StyledMIMoney: () => `
        line-height: 3rem;
      `,
      BaseContainer: (props: any) => `
        padding: 2rem 1.6rem;
        border-bottom: 0.4rem solid ${props.theme.colors.white.lightBlue};
      `,
      PaymentInfoContainer: () => `
        padding-bottom: 0;
        border-bottom: 0;
      `,
      TotalFee: (props: any) => `
        font-size: 2.6rem;
        line-height: 3.8rem;
        height: inherit;
        color: ${props.theme.text.color.black};
        margin-bottom: 0.8rem;
      `,
      FieldName: (props: any) => `
        color: #6B6C72;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: ${props.theme.text.weight.normal};
      `,
      FeeDescription: (props: any) => `
        font-size: 1.2rem;
        font-weight: ${props.theme.text.weight.normal};
        line-height: 1.6rem;
        color: rgba(141, 144, 150, 1);
      `,
      FieldValue: (props: any) => `
        font-weight: ${props.theme.text.weight.normal};
        line-height: 2rem;
        margin: 0 0 1rem 0;
      `,
      SubTitle: (props: any) => `
        line-height: 2rem;
        font-weight: ${props.theme.text.weight.normal};
        color: rgba(107, 108, 114, 1);
        margin-bottom: 1rem;
        font-size: 1.4rem;
      `,
      SectionSeparator: (props: any) => `
        display:block;
        height: 0.1rem;
        background-color: ${props.theme.colors.white.lightBlue};
        margin-top: 1rem;
      `,
      BoxRow: () => `
        margin-top 0;
      `,
      TotalFeeDescription: ({ theme }: any) => `
        ${theme.text.fontType.medium};
      `,
    },
    EditCheckAddressModalButtonRow: {
      ButtonContainer: `
        flex-direction: column;
        > button {
          margin-bottom: 1rem;
        }
        > button:first-child {
          margin-right: 0;
      `,
    },
    PaymentActivity: {
      showEditIcon: true,
      MainPaymentActivityContainer: () => `
        margin-bottom: 2rem;
      `,
      PaymentIcon: () => `
        margin-right: 1.2rem;
        min-width: inherit;
        font-size: 3.2rem;
        color: rgba(107, 108, 114, 1)};
        > img {
          height: 3.2rem;
        }

        > i {
          font-size: 3.2rem;
          color: rgba(107, 108, 114, 1)};
        }
      `,
      PaymentMethodText: (props: any) => `
        color: #6B6C72;
        font-size: 1.4rem;
        font-weight: ${props.theme.text.weight.normal};
        line-height: 2rem;
        letter-spacing: inherit;
      `,
      PaymentInfoHint: () => `
        margin-left: 4.4rem;
      `,
      PaymentInfoText: ({ theme, balance }: any) => css`
        color: ${theme.text.color.main};
        font-weight: ${theme.text.weight.normal};
        line-height: 2rem;
        ${balance &&
        css`
          color: ${theme.text.color.green};
        `}
      `,
      BlockTitleContainer: (props: any) => `
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: ${props.theme.text.weight.normal};
        color: rgba(107, 108, 114, 1);
        margin-bottom: -1rem;
      `,
      NoteBoxRow: () => `
        margin-bottom: 0;
        @media ${devices.mobile}, ${devices.phablet} {
          align-items: center;
        }
      `,
      baseTextStyles: (props: any) => `
        color: rgba(155, 155, 155, 1);
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: ${props.theme.text.weight.normal};
      `,
      FieldName: (props: any) => `
        font-weight: ${props.theme.text.weight.normal};
      `,
      FieldValue: (props: any) => `
        line-height: 2rem;
        font-weight: ${props.theme.text.weight.normal};
      `,
    },
    MIMoney: {
      Balance: (props: any) => `
        font-weight: ${props.theme.text.weight.regular};
      `,
      IntegerBalance: (props: any) => `
        ${props.flavor === 'negative' ? `color: ${props.theme.text.color.error};` : ''}}
        font-size: ${moneyFontSize[props.size] || moneyFontSize.default};
      `,
      FractionBalance: (props: any) => `
        ${props.flavor === 'negative' ? `color: ${props.theme.text.color.error};` : ''}}
        padding-top: unset;
        padding-left: unset;
        font-size: ${moneyFontSize[props.size] || moneyFontSize.default};
        line-height: ${moneyFontSize[props.size] || moneyFontSize.default};
        font-weight: ${props.theme.text.weight.regular};
      `,
      Dot: () => `
        display: inline;
      `,
    },
    MICard: {
      Title: (props: any) => `
        font-weight: ${props.theme.text.weight.normal};
        font-size: 1.4rem;
        text-transform: uppercase;
        line-height: 2rem;
      `,
      SectionContainer: () => `
        padding: 1rem 3.6rem 4rem 3.6rem;
        flex: 1;
      `,
      FieldContainer: (props: any) => `
        &:first-child {
          margin-top: ${props.height === 'small' ? '0' : '2.8rem'};
        }
      `,
      EditableFieldContainer: () => `
        &:first-child {
          margin-top: 2.8rem;
        }
      `,
      Container: () => `
        @media ${devices.tablet}, ${devices.desktop} {
          margin-bottom: 4rem;
        }
      `,
    },
    MIDotsActionsMenu: {
      Dot: () => `
        height: 0.5rem;
        width: 0.5rem;
        display: inline-block;
        margin-right: 0.3rem;
      `,
      Dots: (props: any) => `
        > div:nth-of-type(2) {
          margin-left: 0;
        }
        @media ${devices.nonMobile} {
          position: absolute;
          right: 0;
          top: 0;
        }

        @media ${devices.nonDesktop} {
          position: relative;
        }

        cursor: pointer;
        line-height: 0;

        [class^=Dot-] {
          background-color: ${props.open ? props.theme.text.color.main : props.theme.text.color.label};
        }

        &:hover [class^=Dot-] {
          background-color: ${props.theme.text.color.main};
        }

        &:after {
          content: '';
          position: absolute;
          left: -0.3rem;
          right: 0;
          top: -1rem;
          bottom: -1rem;
        }
      `,
      StyledMIActionsMenu: () => `
        padding-top: 4rem;
        right: -2rem;
        top: -2rem;

        @media ${devices.nonMobile} {
          padding-top: 4rem;
          right: -2rem;
          top: -2rem;
        }

        @media ${devices.mobile}, ${devices.phablet} {
          top: 50%;
          padding: 2rem 0;
        };
      `,
      Container: (props: any) => `
        width: 2.4rem;
        height: 0.4rem;
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }
        ${
          props.isOpen &&
          `
          @media ${devices.nonMobile} {
            [class^=StyledMIActionsMenu-] {
              z-index: 1;
            }
          }

          @media ${devices.nonMobile}  {
            [class^=Dots-] {
              z-index: 2;
            }
          }
        `
        }

        @media ${devices.nonMobile} {
          position: relative;
      `,
    },
    MIActionsMenu: {
      Menu: (props: any) => `
        border: 0.1rem solid rgba(186,190,197,1) !important;
        box-shadow: 0 0 1rem 0 ${props.theme.colors.dark.translucent1} !important;
        @media ${devices.mobile}, ${devices.phablet} {
          max-width: 90%;
        };
      `,
      ActionRow: (props: any) => `
        color: ${props.theme.colors.dark.opaque};
        letter-spacing: 0.8px;
        line-height: 4.2rem;
        &:hover {
          background-color: rgba(246,249,252,1);
        };
        font-weight: ${props.theme.text.weight.regular};

        @media ${devices.mobile}, ${devices.phablet} {
          border-radius: 3.3rem;
          font-size: ${props.theme.text.size.regular};
          font-weight: ${props.theme.text.weight.semiBold};
          color: ${props.theme.text.color.main};
          line-height: 2rem;
          height: 4.8rem;
          background: ${props.theme.colors.white.opaque};
          text-transform: none;
          margin-bottom: 1.4rem;
          border: solid 0.2rem ${props.theme.text.color.main};

          ${
            props.negative &&
            `
            color: ${props.theme.colors.white.opaque};
            background-color: ${props.theme.colors.destructive};
            border: none;
            &:hover {
              background-color: ${props.theme.colors.destructive};
            };
          `
          };

          ${
            props.cancel &&
            `
            border: none;
          `
          };
        };
      `,
    },
    MIFloatedEditDoneButtons: {
      ButtonsContainer: () => `
        display: none;
      `,
      QbmButtonsContainer: () => `
        height: 6rem;
        width: 100%;
        background-color: white;
        box-shadow: 0 -0.4rem 1.5rem 0 rgba(0,0,0,0.2);
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 1011;
        box-sizing: border-box;
        display: flex;

        @media ${devices.desktop}, ${devices.tablet} {
          padding: 0 2.3rem;
        }

        @media ${devices.mobile}, ${devices.phablet} {
          top: 0;
          justify-content: space-between;
          padding: 2rem;
        }
      `,
    },
    MISingleSelect: {
      controlStyleTemplate: (props: any) => {
        const errorBottomBorder = `0.1rem solid ${props.theme.text.color.red}`;
        const grayBottomBorder = props.isSelectInvalid
          ? errorBottomBorder
          : `0.1rem solid ${props.isDisabled ? props.theme.text.color.readonly : 'rgba(186, 190, 197, 1)'}`;
        const blackBottomBorder = '0.1rem solid black';
        const borderBottom = props.selectProps.menuIsOpen ? blackBottomBorder : grayBottomBorder;

        return {
          height: '3.6rem',
          minHeight: '3.6rem',
          fontWeight: props.theme.text.weight.normal,
          borderBottom,
        };
      },
      placeholderStyleTemplate: (props: any) => ({
        fontSize: '1.6rem',
        fontWeight: props.theme.text.weight.normal,
      }),
      inputStyleTemplate: (props: any) => ({
        fontSize: '1.6rem',
        fontWeight: props.theme.text.weight.regular,
      }),
      singleValueStyleTemplate: (props: any) => ({
        fontSize: '1.6rem',
        fontWeight: props.theme.text.weight.regular,
      }),
      optionStyleTemplate: (props: any) => ({
        fontWeight: props.theme.text.weight.regular,
      }),
      selectWrapper: () => `
        > label {
          color: rgba(141, 144, 150, 1);
        };
        @media ${devices.mobile}, ${devices.phablet} {
            margin-top: -2px;
        }
      })`,
    },
    MIRadioGroup: {
      RadioGroupContainer: () => `
        margin-top: 1rem;
        @media ${devices.mobile}, ${devices.phablet} {
          margin-bottom: 2rem;
        }
      `,
      OptionsContainer: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          flex-direction:  ${props.colDirection ? 'column' : 'row'};
        }
      `,
      OptionContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          margin-bottom: 1rem;
        }
      `,
      RadioButtonLabel: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          font-size: ${props.theme.text.size.sectionTitle};
        }
      `,
      ContentContainer: () => `
        margin: 0.4rem 0 1rem;
        @media ${devices.mobile}, ${devices.phablet} {
          margin: 0;
        }
      `,
      MIRadioGroupContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          margin: 0;
        }
      `,
      OuterCircle: (props: any) => `
        box-shadow: inset 0 0 0 0.3rem ${props.theme.colors.white.opaque};
        border-width: 0.1rem;
        margin-right: 1.2rem;
      `,
    },
    MILink: {
      shareStyle: (props: any) => `
        text-transform: none;
        &:active {
          color: ${props.disabled ? 'rgba(33, 33, 36, 0.3)' : 'rgba(44, 160, 28, 1)'};
        }
      `,
    },
    SingleBillContainer: {
      ActionsContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          justify-content: space-between;
          margin-top: 3rem;
          margin-right: 0;
        }
      `,
      ActionButtonContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          margin: 0;
          max-width: 90%;
        }
      `,
    },
    MILauncher: {
      QBOLauncher: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          height: 4.8rem;
          border-radius: 2.4rem;
        }
      `,
    },
    ModalMessage: {
      TitleContainer: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          margin-bottom: 3.2rem;
          padding: 1rem 0;
          .text {
            font-size: ${props.theme.text.size.sectionTitle};
            line-height: ${props.theme.text.lineHeight.regular};
          }
        }
        text-align: left;
        margin-bottom: 1rem;
      `,
      Icon: () => `
        display: none;
      `,
      SubTitleContainer: () => `
        margin-bottom: 4rem;
        margin-top: 0rem;
      `,
      ContentContainer: `
        margin-bottom: 4rem;
      `,
      ButtonContainer: () => `
        margin-bottom: 0;
        @media ${devices.mobile}, ${devices.phablet} {
          > button {
            ${buttonHeight[CONSTS.BUTTON_SIZE.NORMAL]};
          }
        }
      `,
      ModalContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          height: auto;
          width: calc(100% - 3.2rem);
          border-radius: 8px;
        }
      `,
    },
    AccountSettings: {
      HintContainer: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          font-size: ${props.theme.text.size.sectionTitle};
          font-weight: ${props.theme.text.weight.regular};
          line-height: ${props.theme.text.lineHeight.regular};
          margin-bottom: 3rem;
        }
      `,
    },
    MIAddressAutocomplete: {
      AddressContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          display: flex;
          flex-direction: column;
        }
      `,
      StandaloneSearchBoxContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          position: relative;
          margin-right: 1.5rem;
        }
      `,
      AptNumberContainer: `
        display:none;
      `,
      AddLegalAddress: (props: any) => `
        margin-bottom: 2rem;
        color: ${props.theme.text.color.link};
        cursor:pointer;
        ${props.theme.text.fontType.hint}
        `,
    },
    ManualAddressRow: {
      Container: (props: any) => `
        > button {
          font-weight: ${props.theme.text.weight.normal};
          color:  ${props.theme.text.color.link};
        }
      `,
      AddressRow: () => `
        margin: 0.4rem 0 1rem;
        @media ${devices.mobile}, ${devices.phablet} {
          margin: 0;
        }
      `,
    },
    WizardElements: {
      WizardStepActionsContainer: () => `
        margin-top: 2rem;
      `,
    },
    ConvertedUnilateralPaymentActivity: {
      OutsideBillReviewWrapper: 'background-color: unset;',
      PaymentActivityWrapper: 'margin-top: unset;',
      PaymentInfoText: ({ theme }: any) => `${theme.text.fontType.medium}`,
      AdditionalDescription: ({ theme }: any) => `${theme.text.fontType.small}`,
      PaymentDateContainer: `
        flex-direction: row;
        align-items: center;
      `,
      BlockTitleContainer: ({ theme }: any) => `
        color: ${theme.text.color.darkGrey};
        ${theme.text.fontType.medium}
      `,
      Separator: 'display: none;',
      MainPaymentActivityContainer: `
        padding-top: 2rem;
        > div:first-child {
          margin-bottom: 2.4rem;
      }`,
    },
    VerifyMicroDepositsDialog: {
      ErrorMessageIcon: (props: any) => `
        display: block;
        font-size: ${props.size}rem;
        margin-right: 0.5rem;
      `,
      ErrorMessageText: `
        display: none;
      `,
      ErrorMessageNotification: `
        display: block;
      `,
    },
    MINotification: {
      MINotificationContainer: `
        @media ${devices.desktop}, ${devices.tablet} {
          margin: 0 0 1rem 0;
        }
      `,
    },
    QBONotificationCard: {
      Container: (props: any) => `
        background: ${props.theme.colors.white.opaque};
      `,
    },
  },
  pages: {
    PayBillSuccessPage: {
      successImage,
      scheduledForApproval: successImage,
      failedToLoadImage,
      StyledVendorName: (props: any) => `
        color: ${props.theme.text.color.main};
      `,
    },
    PayBillSuccessPageContainer: {
      showQBContainer: false,
      successImage,
      QBONotificationCardContainer: () => `
        display: block;
      `,
    },
    EditCheckAddressDeliveryMethodPage: {
      CheckDeliveryMethodForm: () => `
        margin-bottom: 2rem;
      `,
    },
    CompleteAddingBankAccountsPage: {
      pendingImage: pendingVerificationImage,
      successImage: pendingVerificationImage,
    },
    AddCardAccountsPage: {
      iFrameHeight: '370px',
      SecurityDetailsContainer: () => `
        width: 40rem;
      `,
      TabapayIframe: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          min-height: 35rem;
        }
      `,
    },
    NotFoundPage,
    PayBillSelectDeliveryMethodPage: {
      DeliveryMethodsList: () => `
        margin-bottom: 4rem;
      `,
    },
    SelectBankAccountVerificationTypePage: {
      Card: () => `
        padding: 2rem;
        min-height: 9.6rem;
      `,
      CardLabelText: () => `
        font-size: 1.4rem;
        font-weigth: 500;
      `,
      OrLineWrapper: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          display: none;
        }
      `,
    },
    Settings: {
      BillingSettingsMICardForm: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          padding-top: 0;
        }
      `,
      InternalBillsTable: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          margin-top: 1rem;
          padding: 0 1.6rem 1rem 1.6rem;
        }
      `,
      MITableHeaderCell: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          background-color: ${props.theme.colors.white.opaque};
          text-transform: uppercase;
          color: ${props.theme.text.color.main};
          box-sizing: content-box;
          margin: 2rem;
        }
      `,
    },
    SetManuallyBankAccountPage: {
      FormFields: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          width: calc(100vw - 3.2rem);
        }
      `,
    },
    ErrorLayoutPage: {
      ErrorContainer: () => `
        color: red;
        @media ${devices.mobile}, ${devices.phablet} {
          display: flex;
          justify-content: center;
          width: 100%;
          > button {
            width: 95%;
          }
        }
      `,
    },
    SetBankAccountsQuickbooksIdPageContainer: {
      AddOptionComponentWrapper: (props: any) => `
        color: ${props.theme.text.color.green};
        font-weight: ${props.theme.text.weight.semiBold};
        i {
          margin-right: 0.8rem;
        }
      `,
    },
  },
};

export { theme };
