import * as React from 'react';
import isNil from 'lodash/isNil';
import { MICardField } from 'src/components/common/MICard';
import { CONSTS } from 'src/utils/consts';
import { MIFieldOrEmpty, MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { GoogleCombinedAddressType } from 'src/utils/types';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { getSelectOptionObject } from 'src/components/common/MISingleSelect';

type Props = {
  formControl: Record<string, any>;
};

const MIViewFormControl = ({ formControl }: Props) => {
  const {
    id,
    controlType,
    addressLabel,
    aptNumberLabel,
    address,
    label,
    value,
    emptyLabel,
    emptyAddressLabel,
    emptyNumberLabel,
    date,
    options,
    formatSelectViewValue,
    selected,
    hideSuite,
    privateData,
  } = formControl;
  const emptyLabelVal = emptyLabel || 'general.emptyLabel';
  const emptyAddressLabelVal = emptyAddressLabel || 'general.emptyLabel';
  const emptyNumberLabelVal = emptyNumberLabel || 'general.emptyLabel';
  let selectValue = '';

  switch (controlType) {
    case CONSTS.FORM_CONTROL_TYPE.ADDRESS:
      return (
        <AddressLabel
          addressLabel={addressLabel}
          aptNumberLabel={aptNumberLabel}
          address={address}
          emptyAddressLabel={emptyAddressLabelVal}
          emptyNumberLabel={emptyNumberLabelVal}
          hideSuite={hideSuite}
          privateData={privateData}
        />
      );
    case CONSTS.FORM_CONTROL_TYPE.SELECT:
      if (!isNil(value)) {
        selectValue = getSelectOptionObject(options, value.toString())?.label || '';

        if (formatSelectViewValue) {
          selectValue = formatSelectViewValue(selectValue);
        }
      }

      return (
        <MICardField key={id} label={label}>
          <MIFieldOrEmpty value={selectValue} label={emptyLabelVal} privateData={privateData} />
        </MICardField>
      );
    case CONSTS.FORM_CONTROL_TYPE.DATE:
      return (
        <MICardField key={id} label={label}>
          <MIFieldOrEmpty value={date} label={emptyLabelVal} type="date" />
        </MICardField>
      );
    case CONSTS.FORM_CONTROL_TYPE.RADIO:
      // eslint-disable-next-line no-case-declarations
      const radioValue = options.find((option) => option.id === selected);
      // eslint-disable-next-line no-case-declarations
      const radioValueLabel = radioValue && radioValue.label ? <MIFormattedText label={radioValue.label} /> : undefined;

      return (
        <MICardField key={id} label={label}>
          <MIFieldOrEmpty value={radioValueLabel} label={emptyLabelVal} />
        </MICardField>
      );
    case CONSTS.FORM_CONTROL_TYPE.CURRENCY:
      return (
        <MICardField key={id} label={label}>
          <MIFormattedCurrency value={convertCurrencyToNumber(value)} />
        </MICardField>
      );

    case CONSTS.FORM_CONTROL_TYPE.TEXT:
    default:
      return (
        <MICardField key={id} label={label}>
          <MIFieldOrEmpty value={value} label={emptyLabelVal} />
        </MICardField>
      );
  }
};

type AddressLabelType = {
  addressLabel: string;
  aptNumberLabel: string;
  address?: GoogleCombinedAddressType;
  emptyAddressLabel: string;
  emptyNumberLabel: string;
  hideSuite: boolean;
  privateData: boolean;
};

const AddressLabel = ({
  addressLabel,
  aptNumberLabel,
  address,
  emptyAddressLabel,
  emptyNumberLabel,
  hideSuite,
  privateData,
}: AddressLabelType) => (
  <>
    <MICardField key={addressLabel} label={addressLabel}>
      <MIFieldOrEmpty value={address && address.formattedAddress} label={emptyAddressLabel} privateData={privateData} />
    </MICardField>
    {!hideSuite && (
      <MICardField key={aptNumberLabel} label={aptNumberLabel}>
        <MIFieldOrEmpty value={address && address.aptNumber} label={emptyNumberLabel} privateData={privateData} />
      </MICardField>
    )}
  </>
);

export default MIViewFormControl;
