import React, { useState } from 'react';
import styled from 'styled-components';
import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Modal, ModalTextContent, ModalTitle, ModalSubtitle } from 'src/theme/ds';
import MIButton from 'src/components/common/MIButton';
import { MITextInput } from 'src/components/common/MITextInput';
import { getOrgId } from 'src/redux/user/selectors';
import { PaymentType, FieldType, BillType } from 'src/utils/types';
import { emailVendorOnPaymentSchedule } from 'src/utils/vendors';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import analytics from 'src/services/analytics';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import { getPartialBillId } from 'src/utils/bills';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  eventPage: string;
  payment: PaymentType;
  vendorId: string;
  companyName: string;
  dismiss: VoidFunction;
  onSuccess: (email: string) => void;
};

const NotifyVendorModal = ({ eventPage, payment, vendorId, companyName, dismiss, onSuccess }: Props) => {
  const orgId = useSelector(getOrgId);
  const vendorActions = useStoreActions(vendorsStore);
  const [bill] = useLocationState<BillType>('bill');

  const [loading, setLoading] = useState(false);
  const [vendorEmail, setVendorEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    contactEmail: null,
  });

  const analyticsProps = {
    vendorId,
    screenName: 'success',
    vendorEmail,
    partialBillId: getPartialBillId(bill),
  };

  const handleChange = ({ value }: FieldType) => {
    setVendorEmail(value);
  };

  // TODO: Tech debt. Use hooks from src/billpay/qbdt/modules/vendor-email/hooks, align analytics.
  const handleSubmitClick = async () => {
    analytics.track(eventPage, 'vendor-email-modal-save');

    let validationErrors;

    if (isEmpty(vendorEmail)) {
      validationErrors = {
        contactEmail: 'inputErrors.vendor.contactEmail.any.empty',
      };
      setValidationErrors(validationErrors);
      analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
    } else {
      validationErrors = getValidationErrors('vendor', { contactEmail: vendorEmail }, ['contactEmail']);
      setValidationErrors(validationErrors);

      if (isValidationOk(validationErrors)) {
        setLoading(true);

        try {
          await vendorActions.update({ orgId, id: vendorId, contactEmail: vendorEmail });

          emailVendorOnPaymentSchedule(payment, orgId, vendorEmail);

          setLoading(false);
          onSuccess(vendorEmail);
          dismiss();
          analytics.track(eventPage, 'vendor-email-modal-save-success');
        } catch (e: any) {
          setLoading(false);
          analytics.track(eventPage, 'vendor-email-modal-save-failure', { reason: e?.message || '' });
        }
      } else {
        analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
      }
    }
  };

  const onCloseClick = () => {
    analytics.track(eventPage, 'vendor-email-modal-close', analyticsProps);
    dismiss();
  };

  return (
    <Modal id="notify-vendor-dialog" onCloseClick={onCloseClick}>
      <ModalTextContent>
        <ModalTitle label="paymentDashboard.notifyVendor.dialogTitle" values={{ companyName }} />
        <ModalSubtitle label="paymentDashboard.notifyVendor.dialogSubTitle" />
      </ModalTextContent>

      <MITextInput
        id="vendorEmail"
        type="email"
        label="paymentDashboard.notifyVendor.dialogInputLabel"
        placeholder="paymentDashboard.notifyVendor.dialogInputPlaceholder"
        value={vendorEmail}
        required
        autoFocus
        privateData
        errorMessage={validationErrors.contactEmail}
        onChange={handleChange}
      />

      <PrimaryButton
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={handleSubmitClick}
        label="paymentDashboard.notifyVendor.dialogCTA"
        size={BUTTON_SIZE.NORMAL}
        isProcessing={loading}
      />
    </Modal>
  );
};

const PrimaryButton = styled(MIButton)`
  width: 21.9rem;
  padding: 0;
  align-self: center;
`;

export { NotifyVendorModal };
