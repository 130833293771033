import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import analytics from 'src/services/analytics';
import { UnpaidBillDashboardStatusesEnum } from '../../../consts';
import { dashboardStore } from '../../../redux-store/dashboard-store';
import { DashboardListItemType } from '../../../types';

type Response = {
  selectedIds: string[];
  selectableItemsIds: string[];
  isAllSelected: boolean;
  isIntederminate: boolean;
  onAllCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
};

const eventName = 'data-table-event';

export const useSelection = (dashboardListItems: DashboardListItemType[]): Response => {
  const dashboardListActions = useStoreActions(dashboardStore);
  const selectedIds: string[] = useSelector(dashboardStore.selectors.dashboardSelection.selectedIds);

  const selectableItemsIds = dashboardListItems
    .filter((item) => item.metadata.statusInDashboard !== UnpaidBillDashboardStatusesEnum.Failed)
    .map((item) => item.id);

  const isAllSelected = selectableItemsIds.length > 0 && selectedIds.length === selectableItemsIds.length;
  const isIntederminate = selectedIds.length > 0 && !isAllSelected;

  const onAllCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (checked) {
        analytics.trackAction(eventName, {
          action: 'select-all',
          id: selectableItemsIds,
        });

        dashboardListActions.dashboardSelection.setSelection(selectableItemsIds);

        return;
      }

      analytics.trackAction(eventName, {
        action: 'deselect-all',
        id: null,
      });
      dashboardListActions.dashboardSelection.setSelection([]);
    },
    [dashboardListActions.dashboardSelection, selectableItemsIds]
  );

  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      const { checked } = event.target;
      if (checked) {
        analytics.trackAction(eventName, { action: 'select', id });
        dashboardListActions.dashboardSelection.setSelection([...selectedIds, id]);
      } else {
        analytics.trackAction(eventName, { action: 'deselect', id });
        dashboardListActions.dashboardSelection.setSelection(selectedIds.filter((i) => i !== id));
      }
    },
    [dashboardListActions.dashboardSelection, selectedIds]
  );

  return {
    selectedIds,
    selectableItemsIds,
    isAllSelected,
    isIntederminate,
    onAllCheckboxChange,
    onCheckboxChange,
  };
};
