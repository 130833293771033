import React from 'react';
import styled from 'styled-components';
import { devices } from 'src/theme/AppDevices';
import MILink from 'src/components/common/MILink';
import MIButton from 'src/components/common/MIButton';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';

type Props = {
  onDone: () => any;
  onCancel: () => any;
  doneLabel: string;
  cancelLabel: string;
  className?: string;
  titleLabel?: string;
  isDisabled?: boolean;
  isProcessing?: boolean;
};

const QboFloatedButtons = ({
  className,
  onDone,
  onCancel,
  doneLabel,
  cancelLabel,
  titleLabel,
  isDisabled,
  isProcessing,
}: Props) => (
  <QbmFloatEditButtonsContainer className={className}>
    {onCancel && (
      <CancelLinkWrapper>
        <BrandLink label={cancelLabel} to={onCancel} />
      </CancelLinkWrapper>
    )}
    {titleLabel && (
      <BillTitle>
        <MIFormattedText label={titleLabel} />
      </BillTitle>
    )}
    <SaveLinkWrapper>
      <BrandLink label={doneLabel} to={onDone} />
    </SaveLinkWrapper>
    <SaveButtonWrapper>
      <MIButton
        size={CONSTS.BUTTON_SIZE.SMALL}
        variant={CONSTS.BUTTON_VARIANT.PRIMARY}
        onClick={onDone}
        label={doneLabel}
        disabled={isDisabled}
        isProcessing={isProcessing}
      />
    </SaveButtonWrapper>
  </QbmFloatEditButtonsContainer>
);

const BillTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => props.theme.text.fontType.regular};
  @media ${devices.desktop}, ${devices.tablet} {
    display: none;
    max-width: 30rem;
  }
`;

const CancelLinkWrapper = styled.div`
  display: none;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    * {
      font-size: 1.6rem;
    }
    * {
      font-weight: ${(props) => props.theme.text.weight.semiBold};
    }
    display: block;
  }
`;

const SaveLinkWrapper = styled.div`
  * {
    font-size: 1.6rem;
  }
  * {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
  display: none;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: block;
  }
`;

const SaveButtonWrapper = styled.div`
  display: none;
  @media ${devices.desktop} {
    display: block;
  }
`;
const QbmFloatEditButtonsContainer = styled.div`
  ${(props) => props.theme?.components?.MIFloatedEditDoneButtons?.QbmButtonsContainer}
`;

const BrandLink = styled(MILink)`
  color: ${(props) => props.theme.colors.brand};
`;

export default QboFloatedButtons;
