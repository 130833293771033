import React from 'react';
import { RecordOf } from 'immutable';
import { Box } from '@melio/billpay-design-system';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { CARD_TYPES, FUNDING_TYPE, CONSTS } from 'src/utils/consts';
import { AccountType, BillType, PaymentType } from 'src/utils/types';
import { getLatestPayment } from 'src/utils/payments';
import { PaymentInfoHint } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoHint';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldEdit } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldEdit';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import { MIFormattedText } from 'src/utils/formatting';
import FundingSourceIcon from './FundingSourceIcon';
import FundingSourceDescription from './FundingSourceDescription';
import Tag from '../../../components/Tag';

type Props = {
  fundingSource: RecordOf<AccountType>;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  onEdit: () => void;
};

export const ConfirmPaymentFundingSourceInfo = ({ fundingSource, bill, onEdit, payment }: Props) => {
  let label = 'payBillPaymentActivity.fundingSource.achLabel';
  if (fundingSource.fundingType === FUNDING_TYPE.CARD) {
    label =
      fundingSource.cardAccount?.cardType === CARD_TYPES.CREDIT
        ? 'payBillPaymentActivity.fundingSource.creditCardLabel'
        : 'payBillPaymentActivity.fundingSource.debitCardLabel';
  }

  const hint = isManualBankAccountNotVerified(fundingSource)
    ? 'payBillPaymentActivity.fundingSource.unverifiedBankAccountHint'
    : '';

  const originPayment = getLatestPayment(bill?.payments);
  const canEdit = payment?.metadata?.failedType !== CONSTS.FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;
  const isUpdated =
    originPayment &&
    payment?.metadata?.failedType &&
    (fundingSource?.id !== originPayment?.fundingSourceId ||
      originPayment?.fundingSource?.plaidAccount?.plaidItemId !== fundingSource?.plaidAccount?.plaidItemId);

  return (
    <PaymentFieldContainer>
      <PaymentFieldIcon>
        <FundingSourceIcon fundingSource={fundingSource} />
      </PaymentFieldIcon>

      <PaymentFieldInfo>
        <PaymentFieldLabel>
          <MIFormattedText label={label} />
        </PaymentFieldLabel>

        <PaymentFieldContent>
          <Box>
            <FundingSourceDescription fundingSource={fundingSource} />
            {isUpdated && <Tag label="payBillPaymentActivity.updated" testId="funding-source-updated-tag" />}
          </Box>
          {hint && <PaymentInfoHint hint={hint} />}
        </PaymentFieldContent>
      </PaymentFieldInfo>

      {canEdit && <PaymentFieldEdit testId="funding-source-edit-icon" onEdit={onEdit} />}
    </PaymentFieldContainer>
  );
};
