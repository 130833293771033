import React from 'react';
import { Box, Center } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as SearchIcon } from './search.svg';

type Props = {
  term?: string;
};

export const NoSearchResults = ({ term = '' }: Props) => (
  <Center h="full" data-testid="dashboard-search-no-results">
    <Box textAlign="center">
      <SearchIcon />
      <Box textStyle="ds.h6" color="ds.gray.100" mt="2.4rem" mb="ds.sm" data-testid="empty-state-title">
        <MIFormattedText label="paymentDashboard.search.noResults.title" values={{ term }} />
      </Box>
      <Box textStyle="ds.body1" color="ds.gray.200" data-testid="empty-state-description">
        <MIFormattedText label="paymentDashboard.search.noResults.description" />
      </Box>
    </Box>
  </Center>
);
