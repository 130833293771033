import React from 'react';
import { useSelector } from 'react-redux';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { FUNDING_TYPE } from 'src/utils/consts';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import ACHMethod from './ACHMethod';
import CardMethod from './CardMethod';

type Props = {
  item: DashboardListItemType;
};

export const DashboardPaymentMethodCell = ({ item }: Props) => {
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(item.fundingSourceId)) || item.fundingSource;

  if (fundingSource?.fundingType === FUNDING_TYPE.ACH) {
    return <ACHMethod fundingSource={fundingSource} />;
  }

  if (fundingSource?.fundingType === FUNDING_TYPE.CARD) {
    return <CardMethod fundingSource={fundingSource} />;
  }

  return null;
};
