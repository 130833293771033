import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { PaymentFieldContent } from '../../field/PaymenFieldContent';
import { PaymentFieldInfo } from '../../field/PaymentFieldInfo';
import { PaymentFieldLabel } from '../../field/PaymentFieldLabel';

const VendorNameField = ({ companyName }: { companyName: string }) => (
  <PaymentFieldInfo>
    <PaymentFieldLabel>
      <MIFormattedText label="bills.pay.confirm.vendorField" />
    </PaymentFieldLabel>
    <PaymentFieldContent data-testid="bill-company-name">{companyName}</PaymentFieldContent>
  </PaymentFieldInfo>
);

export { VendorNameField };
