import { DebugLevelEnum } from './consts';
import { Logger, LoggerMessage, LoggerSessionContext, LoggerWrapper } from './types';

const loggersArray: Logger[] = [];
/**
 * This is the general debug level.
 * It is used to hard-cut logs across all loggers.
 * For example, if the debug level here is WARNING  - and we have 2 loggers,
 * one with debug level of DEBUG and one with debug level of ERROR,
 * then the first will log only WARNING and ERROR, while the second one will log only ERROR
 */
const debugLevel: DebugLevelEnum = DebugLevelEnum.DEBUG;

const shouldLog = (logger: Logger, requestedDebugLevel: DebugLevelEnum) =>
  debugLevel <= requestedDebugLevel && logger.getDebugLevel() <= requestedDebugLevel;

export const loggingApi: LoggerWrapper = {
  init() {
    loggersArray.forEach((logger) => logger.init());
  },
  setSessionContext(value: LoggerSessionContext) {
    loggersArray.forEach((logger) => logger.setSessionContext(value));
  },
  addLogger(logger: Logger) {
    loggersArray.unshift(logger);
  },
  debug(message?: any, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.DEBUG)) {
        return;
      }

      logger.debug(message, ...optionalParams);
    });
  },
  log(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.LOG)) {
        return;
      }

      logger.log(message, ...optionalParams);
    });
  },
  info(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.INFO)) {
        return;
      }

      logger.info(message, ...optionalParams);
    });
  },
  warn(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.WARN)) {
        return;
      }

      logger.warn(message, ...optionalParams);
    });
  },
  error(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.ERROR)) {
        return;
      }

      logger.error(message, ...optionalParams);
    });
  },
};
