import * as React from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/hoc/withSiteContext';

type Props = {
  placement?: 'list' | 'single' | 'wizard' | 'melioMe';
};

const AreaLoader = ({ placement = 'single' }: Props) => {
  const site = useSiteContext();
  return (
    <AreaLoaderContainer placement={placement}>
      <AreaLoaderInner>
        <site.components.Loader color="primary" context="page" />
      </AreaLoaderInner>
    </AreaLoaderContainer>
  );
};

export default AreaLoader;

const backgroundColors = {
  list: 'transparent',
  single: 'transparent',
  wizard: 'rgba(250, 250, 250, 1)',
  melioMe: 'rgba(250, 250, 250, 0.9)',
};

const AreaLoaderContainer = styled.div<{ placement: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: ${(props) => (props.placement === 'list' ? '30vh' : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: ${(props) => backgroundColors[props.placement]};
`;

const AreaLoaderInner = styled.div``;
