import React from 'react';
import { useBreak } from 'src/hoc';
import { formatCardNumber } from '../../hooks';
import { CardDetails, CopyFieldActionType } from '../../types';
import { CardFieldsDesktop } from './CardFieldsDesktop';
import { CardFieldsMobile } from './CardFieldsMobile';

export type CardFieldsProps = CardDetails & {
  onClick: CopyFieldActionType;
};

export const CardFields = ({ exp, cvc, number, onClick }: CardFieldsProps) => {
  const { isDesktop } = useBreak();

  const cardNumber = formatCardNumber(number);

  if (isDesktop) {
    return <CardFieldsDesktop exp={exp} cvc={cvc} number={cardNumber} onClick={onClick} />;
  }

  return <CardFieldsMobile exp={exp} cvc={cvc} number={cardNumber} onClick={onClick} />;
};
