/* eslint-disable max-len */

export default {
  'en-US': {
    bills: {
      pay: {
        successModal: {
          titles: {
            regular: 'Payment scheduled!',
          },
          subtitles: {
            regular: 'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> is scheduled for <sb>{processDate}</sb>.',
            enhanced:
              'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> is scheduled for <sb>{processDate}</sb>. We’ll notify them once it’s on the way.',
          },
          buttonLabel: {
            done: 'I’m done here',
            notifyVendor: 'Notify vendor',
            maybeLater: 'Maybe later',
          },
          syncFailedNotification: {
            title: 'Important note',
            subtitle:
              'Your payment was successfully scheduled, but due to a sync issue it may temporarily appear as unpaid in QuickBooks. You can view the payment under the scheduled tab.',
          },
        },
        virtualCardRecovery: {
          virtualCardOption: {
            title: 'Send a new single-use card',
            subtitle: 'Free. Instant delivery.',
          },
          achOption: {
            title: 'Switch to bank transfer (ACH)',
            subtitle: 'Free. No additional fee. Same day delivery.',
            info: 'Deposit funds into your vendor’s bank account',
          },
          heading: 'How would you like to resend the money to <b>{vendorName}</b>?',
        },
      },
      batchPay: {
        successModal: {
          titles: {
            regular: 'Payments scheduled!',
          },
          subtitles: {
            total: '{totalPayedBills} {totalPayedBills, plural, one {payment} other {payments}}',
            regular: 'You have successfully scheduled <sb>{totalPayments}</sb> with a total of <sb>{total}</sb>.',
          },
          buttonLabel: {
            notifyVendors: 'Notify vendors',
            goToDashboard: 'Go to dashboard',
          },
          notification: {
            text:
              'The bills associated with these payments are now marked as {br}<sb>paid</sb> and have a bill payment in QuickBooks. Note that each {br}payment is processed according to its deduction date. ',
            title: 'What’s next?',
          },
          syncFailedNotification: {
            text:
              'Your payments were successfully scheduled, but due to a sync issue some may temporarily appear as unpaid in QuickBooks. You can view the payments under the scheduled tab.',
            title: 'Important note',
          },
        },
        collectEmailsModal: {
          titles: {
            regular: 'Add your vendor’s email',
          },
          buttonLabel: {
            saveAndNotify: 'Save and notify',
          },
        },
      },
    },
    entry: {
      sync: {
        header: 'Bill Pay is syncing',
        messages: {
          takeUp: 'This can take a few minutes',
        },
        success: 'Sync completed successfully',
        successSubtitle: 'Your bills and payments are all up to date',
        error: 'Sorry, something went wrong',
        errorSubtitle: `We couldn’t sync Bill Pay services but we’re working hard to fix it. {br} You can contact our support for help, or try again later.`,
        errorReport: 'Error report:',
        tryAgainLater: 'Try again later',
        startChat: 'Start chat',
        noData: {
          title: 'Seems like you don’t have any bill payments to sync yet',
          subtitle: 'You can schedule your first payment in QuickBooks Desktop in several ways:',
          firstRow: 'Selecting the new <b>Schedule online payment</b> method on your Pay Bills screen.',
          secondRow: 'Using the new <b>Schedule online payment</b> button at the top right of  any bill’s screen.',
          button: 'Thanks, got it!',
        },
      },
      payBill: {
        alreadyPaid: `Seems like a payment is already scheduled for this bill. {br}
              To view this payment, look for the “view online payment” button on the Bill Payment page`,
      },
      batch: {
        landingPage: {
          heading: 'Schedule online bill payments',
          fundingSourcesDescription: 'Pay your selected bills with a credit card, debit card or bank account',
          deliveryMethodsDescription: 'Vendors can still get a check or bank transfer without needing to sign up',
          footer:
            'Bill Pay is a service powered by {melio} with funds held by Evolve Bank & Trust or J.P Morgan. By clicking “Continue”, you agree to Melio’s {termsOfService} and that you have read and acknowledged Melio’s {privacyPolicy} When you Get Started with Bill Pay, you understand and agree to share your QuickBooks Desktop data with Melio for purposes of the Bill Pay service. You can find the list of data that may be shared in QuickBooks Desktop Data Sharing policy.',
        },
        addFundingSource: {
          header: 'Schedule <b>{numberOfBills}</b> payments (total <b>{amount}</b>)',
        },
      },
      landingPage: {
        heading: 'Send bill payments{br} with QuickBooks Desktop',
        advantages: {
          paymentMethodsVariaty: 'Pay with your bank account or card',
          deliveryMethodsVariaty: 'We’ll send vendors an{br} ACH or mail them a check',
          allDataProtected: 'Your payment and information stay protected',
          newTag: 'New',
        },
        footer:
          'Bill Pay is a service provided by Evolve Bank and powered by {melio}. By clicking “Continue”, you agree to Melio’s {termsOfService} and that you have read and acknowledge Melio’s {privacyPolicy}. When you Get Started with Bill Pay, you understand and agree to share your QuickBooks Online data with Melio for purposes of the Bill Pay service. You can find the list of data that may be shared {QBOnlineData}.Bill Pay is a service powered by {melio} with funds held by Evolve Bank & Trust or J.P Morgan. By clicking “Continue”, you agree to Melio’s {termsOfService} and that you have read and acknowledged Melio’s {privacyPolicy} When you Get Started with Bill Pay, you understand and agree to share your QuickBooks Desktop data with Melio for purposes of the Bill Pay service. You can find the list of data that may be shared in QuickBooks Desktop Data Sharing policy.',
      },
      error: {
        title: 'Sorry, something went wrong',
        subtitle: 'We are temporary unable to connect you to Bill Pay. Please reach out to us at: {email}',
        errorReport: 'Error report:',
      },
      loadWindowObjectsError: {
        title: 'There was a technical issue',
        subtitle: 'We ran into a technical issue and are looking into it. Please wait and try again at a later time.',
      },
    },
    vendors: {
      deliveryMethods: {
        ach: {
          changeSuccess: {
            dialog: {
              subTitle:
                'This payment is being processed with QuickBooks Bill Pay powered by Melio, a simple online payment solution for businesses like yours to send bank transfers and paper checks for free. Not a QuickBooks user yet? Join now and get started.',
            },
            button: 'Join QuickBooks',
          },
        },
      },
    },
    drawer: {
      title: 'Frequently asked questions',
      contactLabel: 'If you still have questions, please contact our support team',
      emailLabel: 'Email ',
      supportEmailAddress: ' {supportEmail} ',
      orLabel: ' or ',
      chatLabel: 'start a chat',
      faq: {
        question1: "How do I view or edit my vendor's delivery information?",
        answer1: `Here's how to view/edit a vendor's delivery method: {br}{br}
          <ol>
          <li>Within Bill Pay powered by Melio, select “Settings”, located on the page header in the upper-right corner.</li>{br}
          <li>Select "Vendors" and locate the appropriate vendor by name by scrolling through the vendor list.</li>{br}
          <li>Select the Vendor, and navigate to "Delivery Methods".</li>{br}
          <li>Press the Three Gray Dots located to the right of the delivery method you want to edit (ACH Bank Transfer or Check Delivery).</li>{br}
          <li>Select "Edit". Then, input the new delivery information if necessary and press "Save"</li>
          </ol>
          `,
        question2: 'How do I void a check?',
        answer2:
          'If you need to void an undeposited check, you can submit your request to our team via the above chat, or via email.  When reaching out, please provide the following information:{br}{br}' +
          '{sp}•{sp} Vendor Name{br}{br}' +
          '{sp}•{sp} Payment Amount{br}{br}' +
          '{sp}•{sp} Check Number{br}{br}' +
          '{sp}•{sp} How you would like us to proceed once the check is voided{br}{br}' +
          'Once voided, we are able to re-send the payment directly to the vendor, either by issuing a new check, or via ACH bank transfer if the vendor accepts electronic payment.  If ACH is possible, we would be able to get the payment to the vendor within one business day.{br}{br}' +
          'When re-sending payment, Melio <b>will not</b> debit the funds from your payment method again - we will simply re-send the funds we already hold.',
        question3: 'Where can I see the check number?',
        answer3: `For any Bill Pay payment sent out via Paper Check, you can view the check number by doing the following:{br}{br}
          <ol>
          <li>Select the "View Online Payment" button within the relevant bill payment in QuickBook Desktop to load the Payment Details in Bill Pay.</li>{br}
          <li>Scroll down to the "Vendor Received" section, and you will see the check number listed.</li>{br}
          </ol>
          Please note that a check number is only generated once the check has been printed and sent out from our (Melio's) bank.`,
        question4: 'When will my payment arrive?',
        answer4: `It depends on which payment and delivery method was selected when scheduling the payment.{br}{br}
        <b>Paying with a bank account</b>{br}{br}
        <ulcf>
        <li>Delivered by ACH: 3 business days (4 business days for payments over $100K)</li>
        <li>Delivered by paper check in the mail: 5-7 business days</li>
        <li>Delivered by fast check (via FedEx): 3 business days</li>
        <li>Delivered by fast ACH: Payments made before 12.00pm E.S.T will be delivered the same day.</li>
        </ulcf>{br}

        <b>Paying with credit or debit card</b>{br}{br}
        <ulcf>
        <li>Delivered by ACH (paying with debit card): Next business day</li>
        <li>Delivered by ACH (paying with credit card): The same business day.</li>
        <li>Delivered by ACH (paying with American Express): 2 business days</li>
        <li>Delivered by paper check in the mail: 5-7 business days</li>
        <li>Delivered by fast check (via FedEx): 3 business days</li>
        </ulcf>{br}

        <sb>Note: These may affect payment processing time</sb>{br}{br}
        <ulcf>
        <li>Payments are only processed once a day before 12.00 pm EST. Any payments submitted after this time will only start processing the following business day.</li>
        <li>Federal Holidays may affect the delivery time</li>
        <li>If you're sending a vendor a request to fill out their payment delivery details, the estimated delivery time will depend on when they fill out their details</li>
        <li>When submitting a payment to a vendor for the first time, we may need a little extra time to review the payment. Schedule these in advance to avoid any delays</li>
        </ulcf>
        `,
        question5: 'How do I verify my bank account?',
        answer5:
          'If you connected your bank account to Bill Pay via the "Verify with deposits" option, you should have received two micro-deposits (less than $1 amounts) from "Melio", 1-2 business days after first linking the account.{br}{br}' +
          'Once you have received those two amounts, you can verify your bank account within your Bill Pay Settings.  To do so, navigate to "Settings", and for the bank account you would like to verify, select the blue "Verify account" link.  Then, enter the two deposit amounts you received.',
        question6: 'Can I make a partial payment?',
        answer6:
          'Currently, Bill Pay does not have a partial payment feature, so it will pull the total bill amount during payment setup.{br}{br}' +
          'A workaround for now may be to create separate bills of lesser amounts, or edit the total bill amount and re-click "Save and Schedule Payment".{br}{br}' +
          'Our Development team is currently working on the Partial Payment feature, and we plan to implement it later this year.',
        question7: 'How can I combine multiple bills from the same vendor into one payment?',
        answer7: `Once you’ve selected bills you want to pay to one or multiple vendors, you’ll see a payments table. By default, bill payments going to the same vendor are combined into one.{br}{br}

        <ol>
          <li>Click <b>Show X bills</b> to view which bill payments will be combined.</li>{br}
          <li>Click <b>Hide X bills</b> to hide them again.</li>{br}
          <li>Continue to schedule payments as usual.</li>
        </ol>{br}

        You can change the setting so that payments aren’t combined. When you see the payments table, simply toggle off <b>Combine into one</b> to undo.
        `,
        question8: 'How can I have my vendor fill out their own ACH or Check information?',
        answer8:
          'As you set up your payment in Bill Pay, you will be prompted to select how your vendor would like to receive their payment.  Here, you can select either ACH bank transfer or Paper Check, and enter the information if you have it already.{br}{br}' +
          'If you would like your vendor to choose their own preferred method, you can select the "Ask vendor for payment details" option.  If you select this option, we will send an email to the vendor with a secure link through which they can choose their preferred method, and enter the ACH or Paper Check information themselves.{br}{br}' +
          'Once your vendor has entered their information, you will be notified via email.',
        question9: 'Can I make an international payment?',
        answer9:
          'Currently, Bill Pay powered by Melio can only facilitate domestic ACH and Paper Check payments within the U.S.{br}{br}' +
          'Our Development team is currently working on an International Payments feature, and we hope to implement it later this year.',
        question10: 'Are there any fees?',
        answer10: `Bill Pay powered by Melio is included in your current QuickBooks Desktop subscription, at no additional cost.{br}{br}

          Standard payments made via bank transfer (ACH) are free. Credit card and debit card payments incur a 2.9% processing fee - which is billed separately by our Bill Pay partner Melio.{br}{br}

          <b>A breakdown of our fees</b>{br}{br}
          {feesTable}{br}

          *Fast ACH is not available when paying by credit card.
          `,
        question11: 'Can I make payments to individuals via Bill Pay powered by Melio?',
        answer11:
          'Bill Pay powered by Melio is a business to business (B2B) payment solution.  Therefore, we are not be able to process payments to non-business individuals, or payments made for personal reasons.{br}{br}' +
          'However, if your vendor is a registered sole proprietor, we will be able to facilitate payment to that individual.',
        question12: 'Can my business use Bill Pay powered by Melio?',
        answer12:
          'Bill Pay is a B2B solution designed to serve small businesses in the United States, including sole proprietorships. Therefore, virtually any business looking to make bill payments to another business can use Bill Pay.{br}{br}' +
          'We are, however, prohibited from working with businesses involved with any of the following:{br}{br}' +
          '{sp}•{sp} Internet gambling{br}{br}' +
          '{sp}•{sp} Multi-level marketing firms or any agents that represent them{br}{br}' +
          '{sp}•{sp} Sales of tobacco, marijuana, pharmaceuticals, or nutraceuticals{br}{br}' +
          '{sp}•{sp} Pornography, obscene materials, or any sexual/adult services{br}{br}' +
          '{sp}•{sp} Weapons, ammunitions, gunpowder, fireworks, and any other explosives{br}{br}' +
          '{sp}•{sp} Toxic, flammable or any radioactive material',
        question13: 'How does Melio process my payment?',
        answer13: `Melio processes the payments made through Bill Pay on QuickBooks' behalf.  There are two steps in the payment process:{br}{br}
          <ol>
          <li>First, Melio collects the funds from your bank account or card.</li>{br}
          <li>Then, Melio's bank sends the payment amount out to your vendor for delivery. For payments sent via Paper Check, Melio's bank prints and sends the check out in the mail.</li>
          </ol>
          `,
        question14: 'Can I see a list of all payments made via Bill Pay powered by Melio?',
        answer14:
          'Currently, it isn\'t possible to see a full list of payments made via Bill Pay powered by Melio within the Bill Pay product.  To view the payment details for a particular bill payment, open the bill payment and click the "View Online Payment" button.  This will take you to Bill Pay, and will display the relevant payment details.',
        question15: 'All the ways to contact Bill Pay’s support team',
        answer15: `
        We're here for you whenever you want to get in touch. Here are all the ways that you can contact Bill Pay’s support team.{br}{br}

        <b>Live Chat</b>{br}
        Use live chat to talk to Bill Pay’s support team (during operating hours).{br}{br}

        <sb>NOTE: Support team operating hours</sb>{br}
        Our support team is available <sb>Monday through Friday, 9AM to 8PM EST. Closed on Federal Holidays.</sb>{br}{br}

        Here's how to find it:{br}
        <ol>
        <li>Enter Online Bill Payments.</li>
        <li>Click the support bubble or <b>Need help?</b></li>
        <li>Our support team will be there to help you.</li>
        </ol>{br}

        <b>Email</b>{br}
        Get in touch at {supportEmail}.
        `,
        question16: 'An important update in check fee',
        answer16: `Since 19 October 2022, there is a $1.50 fee for sending a check.{br}
        {br}<b>Why do checks cost $1.50?</b>{br}{br}
        The fee covers printing, postage and handling. There’s also an additional layer of security when sending checks with Bill Pay - your bank details are never on the check, helping protect you from fraud and check forgery.{br}
        {br}<b>New features to make this change smoother</b>{br}{br}
        <ol>
        <li>Combine payments to the same vendor. This means one check can cover several bills from the same vendor. Plus, you save on check fees.</li>{br}
        <li>Cover your clients’ check fees. You can cover your clients’ check fees by using a separate payment method.</li>
        </ol>
        {br}<b>A breakdown of our check fees</b>{br}{br}
        {checkFeesTable}
        {br}<b>Payments created before 19 October 2022 won’t be affected</b>{br}{br}
        There’s still no charge for check delivery on payments you created before 19 October 2022.{br}{br}
        {br}<b>More about how Bill Pay secures your checks</b>{br}{br}
        
        Bill Pay takes every precaution to help keep your checks secure from issue date until they’re delivered to your vendor.{br}{br}
        <ulcf>
        <li><b>Your info is secure</b> - Your bank details are never on the check, helping protect you from fraud and check forgery.</li>
        <li><b>Continuous monitoring</b> - You get updated when the checks are sent and deposited.</li>
        <li><b>End-to-end handling</b> - Bill Pay prints and sends bounce-proof checks to your vendor, and can handle potential issues. Voiding checks is free.</li>
        </ulcf>
            `,
        question17: "Why is my vendor's industry required for American Express card payments.",
        answer17: `From November 22nd 2021, there is an important change in how Bill Pay powered by Melio processes payments made with an American Express credit card. From this date, you’ll only be able to pay vendors from the American Express list of pre-approved industries:{br}{br}
          <ulcf>
          <li>Education</li>{br}
          <li>Government</li>{br}
          <li>Utilities</li>{br}
          <li>Membership Club</li>{br}
          <li>Professional Services</li>{br}
          <li>Business Services</li>{br}
          <li>Inventory/ Wholesale</li>{br}
          <li>Construction/ Logistics</li>{br}
          </ulcf>
          {br}<b>How this change affects your payments</b>{br}{br}
          From November 22nd, you’ll be asked to choose your vendor’s industry when you pay with your American Express card. Note that you’ll only need to do this once per vendor, and you won’t need to choose your own industry
          If your vendor's industry is not on the permitted industries list, you won’t be able to complete the payment with American Express. You can choose an alternative payment method - either a non-American Express card or bank transfer (ACH).`,
        question18: 'Potential USPS check delivery delays during the holidays',
        answer18: `Paying your vendors with paper checks during the holidays? Keep in mind that the holiday season typically affects USPS shipping and delivery times, and can delay check deliveries to your vendors.{br}{br}
        <b>What causes USPS delays?</b>{br}{br}
        Some contributing factors to these end-of-year shipping delays are unpredictable weather conditions, high volume created by the online shopping rush, and COVID-19-surges.{br}{br}
        <b>How to avoid USPS delivery delays</b>{br}{br}
        To make sure the check gets to your vendor on time, we suggest you plan ahead and schedule your payment in advance. Alternatively, switch to sending your payment via ACH delivery (3 business days if you pay with a bank account, next business day if you pay by credit or debit card).
        `,
        question19: 'How can I pay multiple bills at once?',
        answer19: `
        <ol>
        <li>Select multiple bills and click <b>Pay Selected Bills</b>.</li>{br}
        <li>You’ll see a table with all your payments.{br}
        <b>Note</b>: Bill payments to the same vendor will be combined into one payment. Toggle off <b>Combine into one</b> to undo.{br}{br}

        <b>Tips to get the most out of scheduling payments:</b>{br}{br}
        <ulcf>
          <li>Pay all your bills at once on a set date, or pay each bill on its due date.</li>{br}
          <li>Sort the table by vendor name, deduction date, delivery date or amount by clicking on the column title.</li>{br}
          <li>Select a payment method, deduction date and delivery method for each bill separately. </li>{br}
          <li>Ask a vendor to fill out their payment details if you don’t know their delivery method by clicking +Add method. You can’t schedule the payment if there is missing information for any vendor. </li>{br}
          <li>Add a memo for your vendor by clicking the message icon.</li>{br}
        </ulcf>
        </li>{br}
        <li>Once all of your payments are ready to go, you’ll see the total amount to be paid and the total fees on the top right of your screen. Click <b>Schedule payments</b> to continue.{br}
        You can schedule up to 20 bills at once using the Batch Payment feature.
        </li>
        </ol>
        `,
      },
    },
    fundingSources: {
      option: {
        credit: {
          regular: 'Defer this payment and earn card rewards ({fees_credit_value}% fee). <link>View benefits</link>',
          plural: 'Defer payments and earn card rewards ({fees_credit_value}% fee). <link>View benefits</link>',
          pluralLB: 'Defer payments and earn card rewards ({fees_credit_value}% fee).{br}<link>View benefits</link>',
          amex: 'Limited for vendors in industries approved by AMEX. <link>View benefits</link>',
        },
      },
    },
    creditCardBenefits: {
      title: 'Why pay with a credit card?',
      footer: '2.9% processing fee can be tax-deductible.',
      cta: 'Pay by card',
      option: {
        deferPayments: {
          title: 'Defer payments',
          message: 'Hold onto money longer and improve cash flow when you use a credit card.',
        },
        earnRewards: {
          title: 'Earn rewards',
          message: 'Collect cash back, perks, points, miles and more.',
        },
        fasterDelivery: {
          title: 'Faster delivery',
          message: 'Unlock 1 day delivery and other faster options, so vendors receive payments sooner.',
        },
      },
    },
  },
};
