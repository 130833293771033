import React from 'react';
import { Flex, Box } from '@melio/billpay-design-system';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-js/types/elements/elements';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { useOnEnterPressed } from 'src/helpers/react/useOnEnterPressed';
import { BrandsLogos } from 'src/billpay/qbdt/components/basis-theory/brands-logos/BrandsLogos';
import { LinkCardViewLoader } from 'src/billpay/qbdt/components/basis-theory/link-card-view/LinkCardViewLoader';
import { CardDetailsForm } from 'src/billpay/qbdt/components/basis-theory/CardDetailsForm';
import config from 'src/config';
import { BasisTheoryCardBrandType } from 'src/billpay/qbdt/components/basis-theory/brands-logos/types';

type Props = {
  handleLinkCard: () => Promise<void>;
  cardVerificationCodeElementRef: React.RefObject<CardVerificationCodeElement>;
  cardExpirationDateElementRef: React.RefObject<CardExpirationDateElement>;
  cardNumberElementRef: React.RefObject<CardNumberElement>;
  areFieldsLoading: boolean;
  cardBrand: BasisTheoryCardBrandType;
  handleCardBrandChange: (cardBrand: BasisTheoryCardBrandType) => void;
  handleReady: VoidFunction;
  handleErrorStateChange: (hasError: boolean) => void;
  attemptedToLink: boolean;
};

export const LinkCardView = ({
  attemptedToLink,
  handleReady,
  handleErrorStateChange,
  cardBrand,
  handleCardBrandChange,
  areFieldsLoading,
  handleLinkCard,
  cardVerificationCodeElementRef,
  cardNumberElementRef,
  cardExpirationDateElementRef,
}: Props) => {
  const { bt } = useBasisTheory(config.services.basisTheory.key, { elements: true });
  useOnEnterPressed({ onEnterPressed: handleLinkCard });

  return (
    <Flex height="full" justify="center" align="center" direction="column" width="100%">
      <BasisTheoryProvider bt={bt}>
        <Flex width="full" justify="center" position="relative">
          {areFieldsLoading && <LinkCardViewLoader />}
          <Flex width="100%" minHeight="35rem" direction="column">
            <Box display={areFieldsLoading ? 'none' : 'initial'}>
              <Box mb="ds.2xl">
                <BrandsLogos selectedBrand={cardBrand} />
              </Box>
              <CardDetailsForm
                cardNumberElementRef={cardNumberElementRef}
                cardExpirationDateElementRef={cardExpirationDateElementRef}
                cardVerificationCodeElementRef={cardVerificationCodeElementRef}
                onCardBrandChange={handleCardBrandChange}
                onReady={handleReady}
                onError={handleErrorStateChange}
                attemptedToLink={attemptedToLink}
              />
            </Box>
          </Flex>
        </Flex>
      </BasisTheoryProvider>
    </Flex>
  );
};
