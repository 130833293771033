import React from 'react';
import MIForm from 'src/components/common/MIForm';
import { MASKS } from 'src/utils/compliance-validator';
import { CONSTS } from 'src/utils/consts';

type Props = {
  isLoading?: boolean;
  dateOfBirth: string | null;
  validationErrors: Record<string, any>;
  onDateOfBirthChange: (value: string) => void;
};

const AddDateOfBirthForm = (props: Props) => {
  const { isLoading, dateOfBirth, validationErrors, onDateOfBirthChange } = props;

  if (isLoading) {
    return null;
  }

  const formControls = () => {
    const form = [
      {
        id: 'dateOfBirth',
        label: 'guests.addDateOfBirth.dateOfBirth.inputTitle',
        placeholder: 'guests.addDateOfBirth.dateOfBirth.emptyLabel',
        value: dateOfBirth,
        onChange: ({ value }) => {
          onDateOfBirthChange(value);
        },
        required: true,
        mask: MASKS.dateOfBirth,
        errorMessage: validationErrors.dateOfBirth,
        controlType: CONSTS.FORM_CONTROL_TYPE.TEXT,
        initialState: false,
      },
    ];

    return form.filter((form) => !form.initialState);
  };

  return <MIForm formControls={formControls()} mode={CONSTS.SCREEN_MODE.EDIT} formType={CONSTS.FORM_TYPE.WIZARD} />;
};

export default AddDateOfBirthForm;
