export enum DashboardFilterItemNamesEnum {
  StatusesInDashboard = 'statusesInDashboard',
  Vendors = 'vendors',
  DueDates = 'dueDates',
}

export const DASHBOARD_FILTER_ITEMS = [
  DashboardFilterItemNamesEnum.DueDates,
  DashboardFilterItemNamesEnum.StatusesInDashboard,
  DashboardFilterItemNamesEnum.Vendors,
];
