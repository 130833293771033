import { spacing } from './qboSpacing';
import colors from './qboColors';
import textStyles from './qboTextStyles';
import radii from './qboRadius';
import borders from './qboBorders';
import sizes from './qboSizes';
import shadows from './qboShadows';

export default {
  space: spacing,
  colors,
  textStyles,
  radii,
  borders,
  sizes,
  shadows,
};
