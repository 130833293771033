import debounce from 'lodash/debounce';
import io from 'socket.io-client';
import config from '../config';
import analytics from './analytics';

let socket: any = null;

const clientEvents = {
  reset: () => {
    if (socket) {
      socket.close();
    }
  },

  identify: debounce(() => {
    clientEvents.reset();
    socket = io(config.server.baseUrl, {
      path: '/client-events/socket.io',
      forceNew: true,
    });

    socket.on('traits', (data) => {
      analytics.setTraits(data);
    });

    socket.on('track', (data) => {
      analytics.track(data.page, data.event, data.properties, data.options);
    });
  }, 2500),
};

export default clientEvents;
