import React from 'react';
import styled from 'styled-components';
import { Box, Menu } from '@melio/billpay-design-system';
import { ReactComponent as ChevronUpIcon } from 'src/images/regular-batch-payments/menu-arrow-up.svg';
import { ReactComponent as ChevronDownIcon } from 'src/images/regular-batch-payments/menu-arrow-down.svg';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
};

const ArrowMenuButtonCell = ({ isOpen, children }: Props) => (
  <Menu.MenuButton as={Box} h="full">
    <LabelContainer>
      {children}
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </LabelContainer>
  </Menu.MenuButton>
);

export default ArrowMenuButtonCell;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-right: 0.3rem;
  pointer-events: auto;
`;
