import { useApi } from 'src/hoc/useApi';
import deliveryMethodsAPI from 'src/services/api/deliveryMethods';
import paymentsApi from 'src/services/api/payments';
import { DeliveryMethodType, Override } from 'src/utils/types';
import { logger } from 'src/services/loggers';

type VirtualCardApiRequest = {
  token: string;
  deliveryMethod: Override<
    DeliveryMethodType,
    {
      virtualCardAccount: {
        accountEmail: string;
      };
    }
  >;
  orgId: string;
  vendorId: string;
};

type VirtualCardApiResponse = {
  deliveryMethod: DeliveryMethodType;
};

type UpdatePaymentRequest = {
  id: string;
  deliveryMethodId: string;
  token: string;
};

type Props = {
  paymentId: string;
  onSuccess: VoidFunction;
  onFailure: VoidFunction;
};

export const useShiftToVirtualCard = ({ paymentId, onSuccess, onFailure }: Props) => {
  const [requestVirtualCard, , isVirtualCardCreating] = useApi<[VirtualCardApiRequest], VirtualCardApiResponse>(
    deliveryMethodsAPI.replaceDeliveryMethodWithToken
  );
  const [updatePayment, , isPaymentUpdating] = useApi<[UpdatePaymentRequest], void>(
    paymentsApi.setVirtualCardAsDeliveryMethodByIdWithToken
  );

  const handleRequestVirtualCard = async (params: VirtualCardApiRequest) => {
    try {
      const { deliveryMethod } = await requestVirtualCard(params);
      await updatePayment({
        deliveryMethodId: deliveryMethod.id!,
        id: paymentId,
        token: params.token,
      });
      onSuccess();
    } catch (error) {
      logger.error('useShiftToVirtualCard.handleRequestVirtualCard(): failed', error);
      onFailure();
    }
  };

  return {
    handleRequestVirtualCard,
    isProcessing: isVirtualCardCreating || isPaymentUpdating,
  };
};
