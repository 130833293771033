import React from 'react';
import { Column } from 'react-table';
import { DataTableRowType, MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import { HeaderCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/HeaderCell';
import { PaymentMethodCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/PaymentMethodCell';
import { DeliveryMethodCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/DeliveryMethodCell/DeliveryMethodCell';
import { DeliverySpeedCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/DeliverySpeedCell/DeliverySpeedCell';
import { DeliveryDateCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/DeliveryDateCell';
import { ActionsCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/ActionsCell/ActionsCell';
import { VendorCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/VendorCell/VendorCell';
import { AmountCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/AmountCell';
import { SubActionsCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/SubCells/SubActionsCell';
import { DeductionDateCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/DeductionDateCell';
import { ViewWithDelayedLoader } from 'src/components/common/ViewWithDelayedLoader/ViewWithDelayedLoader';
import { CONSTS } from 'src/utils/consts';

import { BillsCell } from './components/BillsCells';
import { InvoiceNumberCell } from './components/SubCells/InvoiceNumberCell';
import { SubAmountCell } from './components/SubCells/SubAmountCell';
import { CellLoading } from './components/CellLoading';
import { withCellWrapper } from './withCellWrapper';

export const COMBINE_BILLS_COLUMNS: Column<DataTableRowType>[] = [
  {
    id: 'vendor',
    Header: <HeaderCell label="regularBatchPayments.table.columns.vendor" />,
    accessor: (row) => (row as MainTableRowType).vendor?.name,
    Cell: withCellWrapper(
      ({ row }) => (
        <VendorCell
          vendorId={row.original.payment.vendor.id}
          vendorName={row.original.payment.vendor.companyName}
          row={row}
        />
      ),
      ({ row }) => <VendorCell vendorName={row.original.vendor.companyName} />
    ),
    maxWidth: 200,
    minWidth: 80,
  },
  {
    id: 'bill',
    Header: <HeaderCell label="regularBatchPayments.table.columns.bill" />,
    Cell: withCellWrapper(
      ({ row }) => <BillsCell bills={row.original.payment.bills} row={row} />,
      ({ row }) => <InvoiceNumberCell invoiceNumber={row.original.invoiceNumber} />
    ),
    maxWidth: 200,
    minWidth: 100,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.paymentMethod" />,
    id: 'paymentMethod',
    Cell: withCellWrapper(({ row: { original } }) => (
      <PaymentMethodCell
        batchItemId={original.id}
        payment={original.payment}
        dueDate={original.dueDate}
        isScheduledDateSelectedByUser={original.isScheduledDateSelectedByUser}
      />
    )),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 118,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.deductionDate" />,
    id: 'deductionDate',
    accessor: (row) => (row as MainTableRowType).payment?.scheduledDate,
    Cell: withCellWrapper(({ row: { original } }) => (
      <DeductionDateCell
        batchItemId={original.id}
        minScheduledDate={original?.minScheduledDate}
        dueDate={original.dueDate}
        payment={original.payment}
      />
    )),
    maxWidth: 200,
    minWidth: 118,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.deliveryMethod" />,
    id: 'deliveryMethod',
    Cell: withCellWrapper(({ row: { original } }) => (
      <DeliveryMethodCell
        batchItemId={original.id}
        payment={original.payment}
        dueDate={original.dueDate}
        isScheduledDateSelectedByUser={original.isScheduledDateSelectedByUser}
      />
    )),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 118,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.deliverySpeed" />,
    id: 'deliverySpeed',
    Cell: withCellWrapper(({ row: { original } }) => (
      <ViewWithDelayedLoader isLoading={original.isLoadingDates} renderLoader={() => <CellLoading />}>
        <DeliverySpeedCell
          batchItemId={original.id}
          amount={original.totalAmount}
          payment={original.payment}
          deliveryOptions={original.deliveryOptions}
        />
      </ViewWithDelayedLoader>
    )),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 118,
  },
  {
    id: 'deliveryDate',
    accessor: (row) => (row as MainTableRowType).payment?.deliveryEta,
    Header: <HeaderCell label="regularBatchPayments.table.columns.deliveryDate" />,
    Cell: withCellWrapper(({ row: { original } }) => (
      <ViewWithDelayedLoader isLoading={original.isLoadingDates} renderLoader={() => <CellLoading />}>
        <DeliveryDateCell
          deliveryEta={original.payment.deliveryEta}
          maxDeliveryEta={original.payment.maxDeliveryEta}
          dueDate={original.dueDate}
          deliveryMethod={original.payment.deliveryMethod}
          deliveryPreference={original.payment.deliveryPreference}
          fundingSourceId={original.payment.fundingSourceId}
        />
      </ViewWithDelayedLoader>
    )),
    maxWidth: 170,
    minWidth: 118,
  },
  {
    id: 'amount',
    accessor: (row) => (row as MainTableRowType).payment?.amount,
    Header: <HeaderCell label="regularBatchPayments.table.columns.amount" />,
    Cell: withCellWrapper(
      ({ row: { original } }) => (
        <AmountCell
          amount={original.totalAmount}
          disabled={!original.payment.deliveryMethodId}
          fastFeeAmount={original.fee![original.payment.deliveryPreference!]?.amount}
          checkFeeObject={original.fee![CONSTS.FEE_VARIANTS.ACH_TO_CHECK]}
        />
      ),
      ({
        row: {
          original: { balance },
        },
      }) => <SubAmountCell amount={balance} />
    ),
    maxWidth: 500,
    minWidth: 100,
    isNumeric: true,
  },
  {
    id: 'payment-actions-cell',
    Cell: withCellWrapper(
      ({ row: { original }, rowHovered }) => <ActionsCell rowHovered={rowHovered} row={original} />,
      ({ row: { original }, rowHovered }) => <SubActionsCell rowHovered={rowHovered} row={original} />
    ),
    disableSortBy: true,
    maxWidth: 80,
    minWidth: 40,
  },
];
