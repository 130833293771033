import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import analytics from 'src/services/analytics';
import { getIsFundingSourceVerifying } from 'src/redux/user/selectors';
import { verifyFundingSourceAction } from 'src/redux/user/actions';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import api from 'src/services/api/financialAccounts';
import { CONSTS } from 'src/utils/consts';
import { microDepositsEventActions, MicroDepositsEventPageType } from 'src/components/micro-deposits/consts';
import {
  RESOLUTIONS,
  MicroDepositProps,
  State,
  MicroDepositState,
  MicroDepositActions,
  validateInputs,
  parseErrorToState,
  dismiss,
  onChange,
  microDepositsEventParamsType,
  trackMicroDepositsEvent,
} from './microDepositsCommon';

export type FundingSourceMicroDepositProps = {
  fundingSourceId: number | null;
  token?: string;
} & MicroDepositProps;

const MicroDepositErrors = CONSTS.VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES;
const errorToResolutionMap: Record<string, any> = {
  [MicroDepositErrors.INVALID_TOKEN]: RESOLUTIONS.NOT_FOUND,
  [MicroDepositErrors.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS]: RESOLUTIONS.SUPPORT,
  [MicroDepositErrors.ERR_VERIFY_MICRO_DEPOSITS]: null,
  [MicroDepositErrors.NOT_FOUND]: RESOLUTIONS.NOT_FOUND,
};
const { ERR_VERIFY_MICRO_DEPOSITS } = MicroDepositErrors;

function callVerifyApi(fundingSourceId: number, state: State, dispatch: Dispatch<any>, token?: string) {
  const amount1Num = parseFloat(convertCurrencyToNumber(state.amount1));
  const amount2Num = parseFloat(convertCurrencyToNumber(state.amount2));

  return new Promise<void>((resolve, reject) => {
    dispatch(verifyFundingSourceAction(fundingSourceId, token, amount1Num, amount2Num, resolve, reject));
  });
}

async function verifyFundingSource(
  fundingSourceId: number,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>,
  token?: string
) {
  try {
    await callVerifyApi(fundingSourceId, state, dispatch, token);
    setState({
      ...state,
      resolution: RESOLUTIONS.SUCCESS,
    });

    return [true];
  } catch (error) {
    setState({
      ...state,
      ...parseErrorToState(error, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS),
    });

    return [false, error];
  }
}

async function onSubmit(
  eventPage: MicroDepositsEventPageType,
  props: FundingSourceMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>,
  analyticsParams: microDepositsEventParamsType
) {
  trackMicroDepositsEvent(eventPage, microDepositsEventActions.ACCOUNT_SUBMIT_CONTINUE, analyticsParams);

  const [validationOk, validationErrors] = validateInputs(state, setState);

  if (validationOk && props.fundingSourceId) {
    const [success, errorMessage] = await verifyFundingSource(
      props.fundingSourceId,
      state,
      setState,
      dispatch,
      props.token
    );

    trackMicroDepositsEvent(
      eventPage,
      success ? microDepositsEventActions.VERIFICATION_SUCCESS : microDepositsEventActions.VERIFICATION_FAILED,
      {
        ...analyticsParams,
        errorMessage,
      }
    );

    if (success && props.onSuccess) {
      props.onSuccess();
    }

    const error = parseErrorToState(errorMessage, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);
    if (!success && error.resolution === RESOLUTIONS.SUPPORT && props.onBlocked) {
      props.onBlocked();
    }

    if (!success && error.resolution === RESOLUTIONS.NOT_FOUND && props.onInvalidToken) {
      props.onInvalidToken();
    }
  } else {
    analytics.track(eventPage, 'submit-validation-error', validationErrors);
  }
}

export function createActions(
  eventPage: MicroDepositsEventPageType,
  props: FundingSourceMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>,
  analyticsParams: microDepositsEventParamsType
): MicroDepositActions {
  return {
    onChange: onChange(state, setState),
    dismiss: () => dismiss(setState),
    onSubmit: () => onSubmit(eventPage, props, state, setState, dispatch, analyticsParams),
  };
}

async function checkAndUpdateVerificationStatus(props, state, setState) {
  if (props.token) {
    setState({
      ...state,
      isLoading: true,
    });

    try {
      const verificationResult = await api.getMicroDepositsStatusWithToken({
        token: props.token,
        id: props.fundingSourceId,
      });
      setState({
        ...state,
        companyName: verificationResult?.company?.name,
        isLoading: false,
      });
      if (verificationResult.status === 'VERIFIED' && props.onSuccess) {
        props.onSuccess();
      }
    } catch (e) {
      const parsedError = parseErrorToState(e, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);
      setState({
        ...state,
        ...parsedError,
        isLoading: false,
      });
      if (parsedError.resolution === RESOLUTIONS.SUPPORT && props.onBlocked) {
        props.onBlocked();
      }

      if (parsedError.resolution === RESOLUTIONS.NOT_FOUND && props.onInvalidToken) {
        props.onInvalidToken();
      }
    }
  }
}

export function useFundingSourceMicroDepositState(
  eventPage: MicroDepositsEventPageType,
  props: FundingSourceMicroDepositProps,
  analyticsParams: microDepositsEventParamsType
): [MicroDepositState, MicroDepositActions] {
  const [state, setState]: [State, any] = useState({
    amount1: '',
    amount2: '',
    validationErrors: {},
  });
  const isFundingSourceVerify = useSelector(getIsFundingSourceVerifying);
  const isLoading = isFundingSourceVerify;
  const dispatch = useDispatch();

  const enhancedState: MicroDepositState = {
    isLoading,
    ...state,
  };

  const actions = createActions(eventPage, props, state, setState, dispatch, analyticsParams);

  useEffect(() => {
    checkAndUpdateVerificationStatus(props, state, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [enhancedState, actions];
}
