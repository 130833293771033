import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import { FAST_DELIVERY_TYPES } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';

type Props = {
  deliveryOption: DeliveryOptionType;
  freeChecksAvailableCount: string | undefined;
};

const PaymentDeliveryOptionPrice = ({ deliveryOption, freeChecksAvailableCount }: Props) => {
  const isCheckDeliveryOption = deliveryOption.type.includes('check');
  const isFastDeliveryOption = FAST_DELIVERY_TYPES.includes(deliveryOption.type);

  let label;
  let values = {};

  if (isCheckDeliveryOption && isFastDeliveryOption && deliveryOption.amount) {
    // fast Check option with amount
    label = 'bills.pay.date.deliveryOptions.price.dollarCheck'; // 'via FedEx ({fee})',
    values = { fee: <MIFormattedCurrency value={deliveryOption.amount} /> };
  } else if (isCheckDeliveryOption && deliveryOption.amount) {
    // regular Check option with amount
    label = 'bills.pay.date.deliveryOptions.price.dollarUSPSCheck'; // 'via USPS ({fee})',
    values = { fee: <MIFormattedCurrency value={deliveryOption.amount} /> };
  } else if (isCheckDeliveryOption) {
    // fast Check option with no amount (probably won't happen)
    // or regular Check option with no amount
    label =
      Number(freeChecksAvailableCount) > 0
        ? 'bills.pay.date.deliveryOptions.price.freeCheck' // 'via USPS (Free)',
        : 'bills.pay.date.deliveryOptions.price.defaultCheck'; // 'via USPS ($1.50)',
  } else if (isFastDeliveryOption && deliveryOption.amount) {
    // fast ACH option with amount
    label = 'bills.pay.date.deliveryOptions.price.dollar'; // '({fee})'
    values = { fee: <MIFormattedCurrency value={deliveryOption.amount} /> };
  } else if (deliveryOption.amount) {
    // regular ACH option with amount
    label = 'bills.pay.date.deliveryOptions.price.dollar'; // '({fee})'
    values = { fee: <MIFormattedCurrency value={deliveryOption.amount} /> };
  } else {
    // fast ACH option with no amount
    // or non-fast non-check options
    label = 'bills.pay.date.deliveryOptions.price.default'; // '(Free)'
  }

  return (
    <Box as="span" ml="0.3rem">
      <MIFormattedText label={label} values={values} />
    </Box>
  );
};

export { PaymentDeliveryOptionPrice };
