import React, { PropsWithChildren, ReactElement } from 'react';
import { useFlexLayout, useTable, useSortBy, HeaderGroup, useExpanded } from 'react-table';
import { Box, Table } from '@melio/billpay-design-system';
import { chakra } from '@chakra-ui/react';
import { ReactComponent as ArrowDownIcon } from 'src/images/get-pro/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'src/images/get-pro/arrow-up.svg';
import { BatchPaymentsDataTableProperties, DataTableContentState } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import DataTableRow from 'src/billpay/qbdt/pages/batch-pay/table/DataTableRow';
import { LoadingTableCaption } from 'src/billpay/qbdt/pages/batch-pay/table/LoadingTableCaption';
import analytics from 'src/services/analytics';
import {
  QbdtTableSizesEnum,
  QbdtTableVariantsEnum,
} from 'src/billpay/qbdt/theme-extension/components/Table/Table.consts';
import { DATA_TABLE_RENDER } from './consts';
import { iconStyle, containerStyle } from './tableStyles';

function DataTable<T extends Record<string, unknown>>(
  props: PropsWithChildren<BatchPaymentsDataTableProperties<T>>
): ReactElement {
  const { columns, data, contentState } = props;
  const instance = useTable<T>(
    {
      autoResetSortBy: false,
      ...props,
      data,
      columns,
      initialState: {
        sortBy: [
          {
            id: 'dueDate',
          },
        ],
        hiddenColumns: ['dueDate'],
      },
    },
    useFlexLayout,
    useSortBy,
    useExpanded
  );

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = instance;

  const onSort = (orderBy, isDesc) => () => {
    let orderDirection;

    // Some of the weirest sh*t ive ever wrote
    if (isDesc === undefined) {
      orderDirection = 'asc';
    } else if (!isDesc) {
      orderDirection = 'desc';
    } else {
      orderDirection = 'none';
    }

    analytics.trackAction('sort-bills', {
      orderBy,
      orderDirection,
    });
  };

  const tableProps = getTableProps();
  if (tableProps.style) {
    tableProps.style.minWidth = 'unset';
    tableProps.style.overflowY = 'unset';
  }

  return (
    <Box __css={containerStyle}>
      <Table<QbdtTableVariantsEnum, QbdtTableSizesEnum>
        {...tableProps}
        variant={QbdtTableVariantsEnum.QbdtBatch}
        size={QbdtTableSizesEnum.QbdtBatchMd}
        cellPadding="0"
        cellSpacing="0"
      >
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup<T> & { isNumeric?: boolean }) => {
                const columnHeaderProps = column.getHeaderProps(column.getSortByToggleProps({ title: undefined }));
                return (
                  <Table.HeaderCell
                    {...columnHeaderProps}
                    isNumeric={column.isNumeric}
                    style={{
                      ...columnHeaderProps.style,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      flex: column.minWidth,
                    }}
                  >
                    <Box px="ds.lg" onClick={onSort(column.id, column.isSortedDesc)}>
                      {column.render(DATA_TABLE_RENDER.Header)}
                      {column.canSort && (column.isSorted || column.isSortedDesc) && (
                        <chakra.span ml={2} h={4} __css={iconStyle}>
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <ArrowDownIcon aria-label="sorted descending" />
                            ) : (
                              <ArrowUpIcon aria-label="sorted ascending" />
                            ))}
                        </chakra.span>
                      )}
                    </Box>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Header>
        {contentState === DataTableContentState.LOADING ? (
          <LoadingTableCaption />
        ) : (
          <Table.Body {...getTableBodyProps()} data-testid="batch-payment-table-body">
            {rows.map((row) => {
              prepareRow(row);
              return <DataTableRow row={row} key={row.id} />;
            })}
          </Table.Body>
        )}
      </Table>
    </Box>
  );
}

export default DataTable;
