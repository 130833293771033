import { createSlice } from '@reduxjs/toolkit';
import { actionName, name } from './consts';
import { BatchItemType } from './types/store-types';

export const BATCH_UPDATE_PAYMENTS = `[${actionName}] UPDATE PAYMENT`;

const initialState = {
  byId: {},
  billsToUpdate: [],
};

const createRegularBatchUpdatePaymentsSlice = () => {
  const slice = createSlice({
    name: BATCH_UPDATE_PAYMENTS,
    initialState,
    reducers: {
      setMemo(state, { payload }) {
        const { batchItemId, memo } = payload;

        state.byId[batchItemId].payment.note = memo;
      },
      setAmount(state, { payload }) {
        const { batchItemId, amount } = payload;

        state.byId[batchItemId].payment.amount = amount;
      },
      setPaymentScheduledDate(state, { payload }) {
        const { batchItemId, scheduledDate } = payload;
        state.byId[batchItemId].payment.scheduledDate = scheduledDate;
        state.byId[batchItemId].isScheduledDateSelectedByUser = true;
      },
      setPaymentsMethod(state, { payload }) {
        const { batchItemId, deliveryMethod, deliveryOptions, fundingSourceId } = payload;
        state.byId[batchItemId].payment.deliveryMethod = deliveryMethod;
        state.byId[batchItemId].payment.deliveryMethodId = deliveryMethod?.id;
        state.byId[batchItemId].payment.deliveryMethodType = deliveryMethod?.deliveryMethodType;
        state.byId[batchItemId].payment.deliveryPreference = deliveryMethod?.deliveryPreference;
        state.byId[batchItemId].payment.fundingSourceId = fundingSourceId;
        state.byId[batchItemId].deliveryOptions = deliveryOptions;
      },
      setPaymentDeliveryMethod(state, { payload }) {
        const { batchItemId, deliveryMethod } = payload;
        state.byId[batchItemId].payment.deliveryMethod = deliveryMethod;
        state.byId[batchItemId].payment.deliveryMethodId = deliveryMethod?.id;
        state.byId[batchItemId].payment.deliveryMethodType = deliveryMethod?.deliveryMethodType;
        state.byId[batchItemId].payment.deliveryPreference = deliveryMethod?.deliveryPreference;
      },
      setPaymentByDeliveryOption(state, { payload }) {
        const {
          batchItemId,
          deliveryOption: { scheduledDate, deliveryDate, type, maxDeliveryDate, minScheduledDate, fee },
        } = payload;
        state.byId[batchItemId].minScheduledDate = minScheduledDate;
        state.byId[batchItemId].fee = fee;
        state.byId[batchItemId].payment.deliveryPreference = type;
        state.byId[batchItemId].payment.scheduledDate = scheduledDate;
        state.byId[batchItemId].payment.deliveryEta = deliveryDate;
        state.byId[batchItemId].payment.maxDeliveryEta = maxDeliveryDate;
      },
      setBillsToUpdate(state, { payload }) {
        const { billsToUpdate } = payload;
        state.billsToUpdate = billsToUpdate;
      },
      setVendorMCCCode(state, { payload }) {
        const { vendorId, mccCode } = payload;
        const bills: BatchItemType[] = Object.values(state.byId);

        bills.forEach((bill: BatchItemType) => {
          if (bill.payment?.vendor && bill.payment?.vendor?.id === vendorId) {
            bill.payment.vendor.mccCode = mccCode;
          }
        });
      },
    },
  });
  return {
    ...slice,
    initialState,
    selectors: {
      billsToUpdate: (state): BatchItemType[] => state[name].billsToUpdate.map((billId) => state[name].byId[billId]),
    },
    dispatchers: {
      setMemo: (dispatch) => (params) => dispatch(slice.actions.setMemo(params)),
      setAmount: (dispatch) => (params) => dispatch(slice.actions.setAmount(params)),
      setPaymentByDeliveryOption: (dispatch) => (params) => dispatch(slice.actions.setPaymentByDeliveryOption(params)),
      setPaymentScheduledDate: (dispatch) => (params) => dispatch(slice.actions.setPaymentScheduledDate(params)),
      setBillsToUpdate: (dispatch) => (params) => dispatch(slice.actions.setBillsToUpdate(params)),
      setPaymentsMethod: (dispatch) => (params) => dispatch(slice.actions.setPaymentsMethod(params)),
      setPaymentDeliveryMethod: (dispatch) => (params) => dispatch(slice.actions.setPaymentDeliveryMethod(params)),
      setVendorMCCCode: (dispatch) => (params) => dispatch(slice.actions.setVendorMCCCode(params)),
    },
  };
};

export const regularBatchUpdatePaymentsSlice = createRegularBatchUpdatePaymentsSlice();
