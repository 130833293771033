import React from 'react';
import { FAILED_PAYMENT_TYPE } from 'src/utils/consts';
import { isPaymentFailed, isPaymentScheduled, isVirtualCardPaymentExpired } from 'src/utils/payments';
import { useViewPaymentContext } from '../context/ViewPaymentContext';
import { FailedToDeliverActions } from './components/FailedToDeliverActions';
import { PaymentScheduledActions } from './components/PaymentScheduledActions';
import { FailedToCollectActions } from './components/FailedToCollectActions';
import { ExpiredVirtualCardActions } from './components/ExpiredVirtualCardActions';
import { NotRetryableFailedPaymentActions } from './components/NotRetryableFailedPaymentActions';

export const ViewPaymentActions = () => {
  const { payment } = useViewPaymentContext();
  const canUserRetry = payment?.metadata?.canUserRetry;
  const isFailedToDeliver = payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;

  const isFundingSourceSoftDeleted = !!payment.fundingSource.deletedAt;
  const isVirtualCardExpired = isVirtualCardPaymentExpired(payment);
  const notEligibleForRecoveryFlows = isPaymentFailed(payment) && isFundingSourceSoftDeleted;

  if (notEligibleForRecoveryFlows) {
    return null;
  }

  if (canUserRetry && isVirtualCardExpired) {
    return <ExpiredVirtualCardActions />;
  }

  if (canUserRetry) {
    return isFailedToDeliver ? <FailedToDeliverActions /> : <FailedToCollectActions />;
  }

  if (isPaymentFailed(payment)) {
    return <NotRetryableFailedPaymentActions />;
  }

  if (isPaymentScheduled(payment)) {
    return <PaymentScheduledActions />;
  }

  return null;
};
