import * as React from 'react';
import styled from 'styled-components';
import Spinner from '../common/Spinner';

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

const Mask = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
  ${(props) => props.theme?.components?.Layout?.Overlay}
`;

const LoadingOverlay = ({ isLoading, children }: Props) => (
  <React.Fragment>
    <Overlay>
      {isLoading && (
        <Mask>
          <Spinner />
        </Mask>
      )}
      {children}
    </Overlay>
  </React.Fragment>
);

LoadingOverlay.defaultProps = {};

export default LoadingOverlay;
