import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Menu, Box, Text, Icon, Flex } from '@melio/billpay-design-system';
import { Portal } from '@chakra-ui/react';
import { useModal } from 'src/helpers/react/useModal';
import analytics from 'src/services/analytics';
import { MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import BillDetailsModal from 'src/billpay/qbdt/pages/batch-pay/components/dialog/BillDetailsModal';
import { useDisclosure } from 'src/core/ds/hooks';
import { MIFormattedText } from 'src/utils/formatting';
import { isRppsVendor } from 'src/utils/vendor/vendor-directory';
import { ReactComponent as ThreeDotsIcon } from 'src/images/regular-batch-payments/three-dots.svg';
import { ReactComponent as PreviewIcon } from 'src/images/regular-batch-payments/preview.svg';
import { ReactComponent as RemoveIcon } from 'src/images/regular-batch-payments/remove.svg';
import { ReactComponent as NoteIcon } from 'src/images/regular-batch-payments/note-add.svg';
import { useRemoveBillFromList } from 'src/billpay/qbdt/pages/batch-pay/hooks/useRemoveBillFromList';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { VendorMemoModal as VendorMemoDialog } from 'src/billpay/qbdt/pages/batch-pay/components/dialog/VendorMemoModal';
import { getBillIdsFromBatchItem } from '../../../utils';

type Props = {
  row: MainTableRowType;
};

export const ActionsMenu = ({ row }: Props) => {
  const { id, vendor, subRows = [], payment } = row;
  const isDirectPayment = isRppsVendor(vendor);
  const { isBillsGroupedByVendor } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);

  const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(id));

  const bill = payment.bills[0];

  const isSingleBillBatchItem = !isBillsGroupedByVendor || !subRows.length;

  const { isOpen: isOpenBillDetails, onOpen: onOpenBillDetails, onClose: onCloseBillDetails } = useDisclosure({
    id: 'bill-details',
  });

  const [VendorMemoModal, showVendorMemoModal] = useModal(VendorMemoDialog, {
    vendorId: payment.vendorId,
    batchItemId: id,
  });

  const { removeBillFromList } = useRemoveBillFromList(row.id);

  const onViewBillDetailsClick = () => {
    onOpenBillDetails();

    analytics.trackAction('regularBatchPayments.cells.actions.viewBillDetails', {
      billIds: getBillIdsFromBatchItem(batchItem),
      vendorId: batchItem?.payment?.vendorId,
      payBillFlowUuid: batchItem?.payBillFlowUUID,
    });
  };

  const onAddMemoClick = () => {
    showVendorMemoModal();

    analytics.trackAction('regularBatchPayments.cells.actions.addMemoToVendor', {
      billIds: getBillIdsFromBatchItem(batchItem),
      vendorId: batchItem?.payment?.vendorId,
      payBillFlowUuid: batchItem?.payBillFlowUUID,
    });
  };

  return (
    <>
      <Portal>{VendorMemoModal}</Portal>
      {isOpenBillDetails && (
        <BillDetailsModal
          bill={bill}
          vendorName={vendor.companyName}
          onClose={onCloseBillDetails}
          analyticsProperties={{
            billId: bill?.id,
            vendorId: payment.vendorId,
          }}
        />
      )}
      <Menu placement="bottom-start" size="sm" autoSelect={false} autoWidth>
        {({ isOpen }) => (
          <>
            <Tooltip
              placement="top-start"
              shouldWrapChildren
              label={<MIFormattedText label="regularBatchPayments.cells.actions.menuTooltip" />}
            >
              {/* sx required as tooltip has shouldWrapChildren property, and it's wrapping each child in span tag */}
              <Menu.MenuButton
                as={Flex}
                alignItems="center"
                justifyContent="center"
                sx={{
                  '& > span': {
                    height: 'ds.lg',
                  },
                }}
              >
                <Icon as={ThreeDotsIcon} h="ds.lg" />
              </Menu.MenuButton>
            </Tooltip>
            <Portal>
              <Menu.MenuList
                minWidth="auto"
                display={isOpen ? 'block' : 'none'}
                data-testid={`actions-menu-list-${id}`}
              >
                {isSingleBillBatchItem && (
                  <Menu.MenuItem
                    as={Box}
                    onClick={onViewBillDetailsClick}
                    w="auto"
                    data-testid={`bulk-row-view-menu-item_${id}`}
                  >
                    <Icon as={PreviewIcon} h="ds.lg" w="ds.lg" />
                    <Text ml="ds.md">
                      <MIFormattedText label="regularBatchPayments.cells.actions.viewBillDetails" />
                    </Text>
                  </Menu.MenuItem>
                )}
                <Menu.MenuItem
                  as={Box}
                  onClick={removeBillFromList}
                  w="auto"
                  data-testid={`bulk-row-remove-menu-item_${id}`}
                >
                  <Icon as={RemoveIcon} h="ds.lg" w="ds.lg" />
                  <Text ml="ds.md">
                    <MIFormattedText label="regularBatchPayments.cells.actions.removeFromList" />
                  </Text>
                </Menu.MenuItem>
                {!isDirectPayment && (
                  <Menu.MenuItem
                    as={Box}
                    onClick={onAddMemoClick}
                    w="auto"
                    data-testid={`bulk-row-add-memo-menu-item_${id}`}
                  >
                    <Icon as={NoteIcon} h="ds.lg" w="ds.lg" />
                    <Text ml="ds.md">
                      <MIFormattedText label="regularBatchPayments.cells.actions.AddMemoToVendorMenu" />
                    </Text>
                  </Menu.MenuItem>
                )}
              </Menu.MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </>
  );
};
