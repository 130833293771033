import { datadogLogs } from '@datadog/browser-logs';
import config from 'src/config';
import { DebugLevelEnum } from './consts';
import { Logger, LoggerSessionContext } from './types';

const { logger } = datadogLogs;
let sessionContext: LoggerSessionContext = {
  userId: '',
  orgId: '',
  email: '',
};

export const dataDogLogger: Logger = {
  init() {
    const { clientToken, site, service } = config.services.dataDog;

    datadogLogs.init({
      clientToken,
      site,
      service,
      env: process.env.REACT_APP_ENV,
      sampleRate: 100,
      forwardErrorsToLogs: true,
      useCrossSiteSessionCookie: true,
    });
  },
  getDebugLevel(): DebugLevelEnum {
    return DebugLevelEnum.INFO;
  },
  setSessionContext(value: LoggerSessionContext) {
    sessionContext = value;
  },
  debug(message?: any, ...optionalParams: any[]): void {
    logger.debug(message, { ...sessionContext, optionalParams });
  },
  log(message?: any, ...optionalParams: any[]): void {
    logger.log(message, { ...sessionContext, optionalParams });
  },
  info(message?: any, ...optionalParams: any[]): void {
    logger.info(message, { ...sessionContext, optionalParams });
  },
  warn(message?: any, ...optionalParams: any[]): void {
    logger.warn(message, { ...sessionContext, optionalParams });
  },
  error(message?: any, ...optionalParams: any[]): void {
    logger.error(message, { ...sessionContext, optionalParams });
  },
};
