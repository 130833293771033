import { createListSlice } from 'src/helpers/redux/restListSlice';

import { dashboardListName, name } from './consts';
import { getDashboardList } from '../api/dashboard-api';
import { DashboardListItemType, DashboardListItemTypeResponse } from '../types';

export const dashboardListItemsSlice = createListSlice<DashboardListItemTypeResponse, DashboardListItemType>({
  storeName: name,
  api: (params) => getDashboardList(params),
  actionName: 'qbdtDashboardList',
  listHashFunc: () => dashboardListName,
  resultMapFunction: (
    _state,
    _currItem,
    { id, vendorId, paymentId, billIds, deliveryMethodId, fundingSourceId, fundingSource, metadata }
  ) => ({
    id,
    vendorId,
    paymentId,
    billIds,
    deliveryMethodId,
    fundingSourceId,
    fundingSource,
    metadata,
  }),
});
