import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { FieldType } from 'src/utils/types';
import analytics from 'src/services/analytics';

type Props = {
  eventPage: string;
  initialValues?: Record<string, string>;
};

const getInitialValidationErrors = (initialValues) => {
  const ids = Object.keys(initialValues);
  const result = ids.reduce((acc, cur) => {
    acc[cur] = { contactEmail: null };
    return acc;
  }, {});

  return result;
};

const useValidateVendorEmail = ({ eventPage, initialValues = {} }: Props) => {
  const [vendorEmails, setVendorEmails] = useState(initialValues);
  const [validationErrors, setValidationErrors] = useState(getInitialValidationErrors(initialValues));

  const validateVendorEmail = ({ id, optional }: { id: number; optional: boolean }): boolean => {
    const enteredEmail = vendorEmails[id] || '';

    let vendorValidationError;
    if (!optional && isEmpty(enteredEmail)) {
      vendorValidationError = {
        contactEmail: 'inputErrors.vendor.contactEmail.any.empty',
      };
    } else {
      vendorValidationError = getValidationErrors('vendor', { contactEmail: enteredEmail }, ['contactEmail']);
    }

    setValidationErrors({ ...validationErrors, [id]: vendorValidationError });
    const isOk = isValidationOk(vendorValidationError);

    if (!isOk) {
      analytics.track(eventPage, 'vendor-email-validation-error', { vendorId: id, ...validationErrors });
    }

    return isOk;
  };

  const validateVendorEmailsBatch = ({ ids, optional }: { ids: number[]; optional: boolean }) => {
    const errors = {};
    const results = ids.map((id) => {
      const enteredEmail = vendorEmails[id] || '';
      let vendorValidationError;

      if (!optional && isEmpty(enteredEmail)) {
        vendorValidationError = {
          contactEmail: 'inputErrors.vendor.contactEmail.any.empty',
        };
      } else {
        vendorValidationError = getValidationErrors('vendor', { contactEmail: enteredEmail }, ['contactEmail']);
      }

      errors[id] = { ...vendorValidationError };

      const isOk = isValidationOk(vendorValidationError);

      if (!isOk) {
        analytics.track(eventPage, 'vendor-email-validation-error', { vendorId: id, ...vendorValidationError });
      }

      return isOk;
    });

    setValidationErrors({ ...validationErrors, ...errors });

    return results.every((isOk) => isOk);
  };

  const getVendorEmail = (vendorId: number) => vendorEmails[vendorId] || '';

  const setVendorEmail = ({ id, value }: FieldType) => {
    setVendorEmails({ ...vendorEmails, [id]: value });
  };

  const getValidationError = (vendorId: number): string => validationErrors[vendorId]?.contactEmail;

  const isSmthEntered = (vendorIds: number[]) => vendorIds.some((vendorId) => !isEmpty(vendorEmails[vendorId]));

  const getEnteredEmails = (vendorIds: number[]) =>
    vendorIds
      .filter((vendorId) => !isEmpty(vendorEmails[vendorId]))
      .map((vendorId) => ({ id: vendorId, email: vendorEmails[vendorId] }));

  return {
    getVendorEmail,
    setVendorEmail,
    getValidationError,
    validateVendorEmail,
    validateVendorEmailsBatch,
    isSmthEntered,
    getEnteredEmails,
  };
};

export { useValidateVendorEmail };
