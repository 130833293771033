import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  inputId?: string;
  label: string;
  required?: boolean;
  size?: 'inline' | 'wizard';
  errorMessage?: string | null;
  labelValues?: Record<string, any>;
};

const MIInputLabel = ({ inputId, label, required, size = 'wizard', errorMessage, labelValues }: Props) => {
  if (!label) return null;

  return (
    <InputLabel errorMessage={errorMessage} size={size} htmlFor={inputId}>
      <MIFormattedText label={label} values={labelValues} />
      {!required && (
        <Optional>
          <MIFormattedText label="input.optional" />
        </Optional>
      )}
    </InputLabel>
  );
};

export default MIInputLabel;

const InputLabel = styled.label<{
  errorMessage?: string | null;
  size?: 'inline' | 'wizard';
}>`
  min-height: 1.7rem;
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.02rem;
  line-height: 1.8rem;
  display: block;
  text-align: left;
  margin-bottom: ${(props) => (props.size === 'inline' ? '0' : '0.8rem')};

  color: ${(props) => {
    if (props.errorMessage) {
      return props.theme.text.color.error;
    }

    return props.theme.text.color.label;
  }};
  ${(props) => props.theme?.components?.MIInputLabel?.InputLabel}
`;

const Optional = styled.span`
  font-size: 1.2rem;
  text-transform: none;
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.MIInputLabel?.Optional}
`;
