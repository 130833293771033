import * as React from 'react';
import MIEnhancedDropDown from 'src/components/common/DropDown/MIEnhancedDropDown';
import { MITextInput } from 'src/components/common/MITextInput';
import DatePicker from 'src/components/form/DatePicker';
import MISingleSelect from 'src/components/common/MISingleSelect';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { WizardFormRow } from 'src/components/layout/WizardElements';
import { MICardEditableField } from 'src/components/common/MICard';
import { CONSTS } from 'src/utils/consts';
import { FormType } from 'src/utils/types';
import MIRadioGroup from 'src/components/form/MIRadioGroup';
import { CompanyIndustryAutocomplete } from 'src/billpay/qbdt/components/CompanyIndustryAutocomplete';

type Props = {
  formControl: any;
  formType: FormType;
};

const inputWrappersMap = {
  [CONSTS.FORM_TYPE.WIZARD]: WizardFormRow,
  [CONSTS.FORM_TYPE.DETAILS]: MICardEditableField,
};

const MIEditFormControl = ({ formControl, formType = CONSTS.FORM_TYPE.WIZARD }: Props) => {
  const InputWrapper = inputWrappersMap[formType];
  const inputSize =
    formType === CONSTS.FORM_TYPE.WIZARD ? CONSTS.TEXT_INPUT_SIZE.WIZARD : CONSTS.TEXT_INPUT_SIZE.INLINE;
  const addressMode =
    formType === CONSTS.FORM_TYPE.WIZARD
      ? CONSTS.ADDRESS_FORM_CONTROL_MODE.INLINE
      : CONSTS.ADDRESS_FORM_CONTROL_MODE.FORM;

  let formControlComponent;

  switch (formControl.controlType) {
    case CONSTS.FORM_CONTROL_TYPE.ADDRESS:
      formControlComponent = <MIAddressAutocomplete {...formControl} mode={addressMode} />;
      break;
    case CONSTS.FORM_CONTROL_TYPE.SELECT:
      formControlComponent = <MISingleSelect {...formControl} />;
      break;
    case CONSTS.FORM_CONTROL_TYPE.DROP_DOWN:
      formControlComponent = <MIEnhancedDropDown {...formControl} />;
      break;
    case CONSTS.FORM_CONTROL_TYPE.DATE:
      formControlComponent = <DatePicker {...formControl} />;
      break;
    case CONSTS.FORM_CONTROL_TYPE.RADIO:
      formControlComponent = <MIRadioGroup {...formControl} size={inputSize} />;
      break;
    case CONSTS.FORM_CONTROL_TYPE.TEL:
      formControl.type = formControl.controlType;
      formControlComponent = <MITextInput {...formControl} size={inputSize} />;
      break;
    case CONSTS.FORM_CONTROL_TYPE.INDUSTRY:
      formControlComponent = <CompanyIndustryAutocomplete {...formControl} size={inputSize} />;
      break;
    case CONSTS.FORM_CONTROL_TYPE.TEXT:
    case CONSTS.FORM_CONTROL_TYPE.CURRENCY:
    default:
      formControlComponent = <MITextInput {...formControl} size={inputSize} />;
      break;
  }

  return <InputWrapper>{formControlComponent}</InputWrapper>;
};

export default MIEditFormControl;
