import React, { useState, useCallback } from 'react';
import analytics from 'src/services/analytics';
import isPlainObject from 'lodash/isPlainObject';

export type UseModalOptions = {
  onDismiss?: (result: any) => any;
  modalName?: string;
} & Record<string, any>;

export type ModalShowCallback = (params?: { [key: string]: any }) => void;
export type UseModalReturn = [React.ReactNode | null | false, ModalShowCallback, boolean];

export function useModal(Component, options: UseModalOptions): UseModalReturn {
  const [state, setState] = useState({ showing: false, params: {} });
  const { modalName, ...props } = options;
  const actionName = modalName || Component.displayName;
  const { onDismiss } = options;

  const showDialog = useCallback(
    (params = {}) => {
      analytics.trackAction(actionName, { toggle: 'open' });
      setState({
        showing: true,
        params: isPlainObject(params) ? params : {},
      });
    },
    [actionName]
  );

  const dismiss = useCallback(
    (result) => {
      analytics.trackAction(actionName, { toggle: 'close' });
      setState({
        showing: false,
        params: {},
      });
      onDismiss && onDismiss(result);
    },
    [actionName, onDismiss]
  );

  const DialogComponent = state.showing && (
    <Component dismiss={dismiss} {...props} {...state.params}>
      {props.children}
    </Component>
  );
  return [DialogComponent, showDialog, state.showing];
}
