import moment from 'moment-timezone';

import { CONSTS, PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';

import { fetchRequest } from './api';

export type GetDeliveryTimeParams = {
  orgId: string;
  scheduledDate: Date;
  deliveryMethodId: number;
  fundingSourceId: number;
  amount: number | null | undefined;
  paymentId?: string;
  payBillFlowUUID?: string;
  createOrigin?: PAYMENT_CREATE_FLOW_ORIGIN;
};

type GetLatestProcessingDateParams = {
  orgId: string;
  dueDate: Date;
  deliveryMethodId: number;
  fundingSourceId: number;
  amount: number | null | undefined;
  paymentId?: string;
  payBillFlowUUID?: string;
  createOrigin?: PAYMENT_CREATE_FLOW_ORIGIN;
};

export default {
  getLatestProcessingDate({
    orgId,
    dueDate,
    deliveryMethodId,
    fundingSourceId,
    amount,
    paymentId,
    payBillFlowUUID,
    createOrigin = PAYMENT_CREATE_FLOW_ORIGIN.QBDT_WINDOWS,
  }: GetLatestProcessingDateParams) {
    const url = `/orgs/${orgId}/delivery/date/latest`;
    const params = {
      dueDate: this.dateInCentral(dueDate).toISOString(),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentOrigin: CONSTS.DATA_ORIGIN.INTUIT,
      paymentId,
      payBillFlowUUID,
      createOrigin,
    };

    return fetchRequest(url, params).then((data) => {
      const { dates } = data;
      return {
        suggestedScheduledDate: dates[0].scheduledDate,
        actualDeliveryDays: dates[0].totalDays,
        deliveryDate: dates[0].deliveryDate,
        maxDeliveryDate: dates[0].maxDeliveryDate,
        deliveryOptions: dates,
      };
    });
  },

  getDeliveryTime({
    orgId,
    scheduledDate,
    deliveryMethodId,
    fundingSourceId,
    amount,
    paymentId,
    payBillFlowUUID,
    createOrigin = PAYMENT_CREATE_FLOW_ORIGIN.QBDT_WINDOWS,
  }: GetDeliveryTimeParams) {
    const url = `/orgs/${orgId}/delivery/date`;
    const params = {
      scheduledDate: this.dateInCentral(scheduledDate).toISOString(),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentOrigin: CONSTS.DATA_ORIGIN.INTUIT,
      paymentId,
      payBillFlowUUID,
      createOrigin,
    };

    return fetchRequest(url, params).then((data) => {
      const { dates } = data;
      return {
        suggestedScheduledDate: dates[0].scheduledDate,
        actualDeliveryDays: dates[0].totalDays,
        deliveryDate: dates[0].deliveryDate,
        maxDeliveryDate: dates[0].maxDeliveryDate,
        deliveryOptions: dates,
      };
    });
  },

  dateInCentral(date: Date) {
    const m = moment(date);

    return moment
      .tz('US/Central')
      .year(m.year())
      .month(m.month())
      .date(m.date())
      .hour(9)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();
  },

  getApprovalDecisionStatus(orgId: string, amount: number) {
    const url = `/orgs/${orgId}/delivery/approval-decision-status`;
    const params = { amount };

    return fetchRequest(url, params);
  },

  getClosestDeliveryDates(orgId: string, deliveryMethodId: number, fundingSourceId: number, amount: number) {
    const url = `/orgs/${orgId}/delivery/closest-delivery-dates`;
    const params = {
      deliveryMethodId,
      fundingSourceId,
      amount,
    };

    return fetchRequest(url, params);
  },

  getExpeditedAchDeliveryDates(orgId: string, deliveryMethodId: number, fundingSourceId: number, amount: number) {
    const url = `/orgs/${orgId}/delivery/get-expedited-ach-delivery-dates`;
    const params = {
      deliveryMethodId,
      fundingSourceId,
      amount,
    };

    return fetchRequest(url, params);
  },

  getEffectiveScheduleDate({
    orgId,
    scheduledDate,
    fundingSourceId,
  }: {
    orgId: string;
    scheduledDate: Date;
    fundingSourceId: number;
  }) {
    const url = `/orgs/${orgId}/delivery/effective-schedule-date`;
    const params = {
      scheduledDate,
      fundingSourceId,
    };

    return fetchRequest(url, params);
  },
};
