import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCheckFee } from 'src/billpay/qbdt/hooks/useCheckFee';
import { PaymentDeliveryOption } from 'src/components/common/PaymentDeliveryOption';
import MIRadioGroup, { Option, OuterCircle } from 'src/components/form/MIRadioGroup';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { CONSTS, ExpeditedDeliveryType, FastCheckDeliveryType, FAST_DELIVERY_TYPES } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';
import styled from 'styled-components';

type Props = {
  deliveryOptions: DeliveryOptionType[];
  deliveryPreference?: string;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, type?: string) => void;
};

const PaymentDeliveryOptions = ({
  deliveryOptions,
  onSelectDeliveryOption,
  deliveryPreference,
  fundingSourceType,
}: Props) => {
  const bill = useSelector(getBill);
  const checkFee = useCheckFee();
  const isCheckFeePromotions = checkFee.isCheckFeeOpenWithPromotions(checkFee.checkFeeFlag);

  useEffect(() => {
    if (isCheckFeePromotions) {
      checkFee.getFreeChecks({ isBatch: false });
    }
  }, [isCheckFeePromotions]);

  const options: Option<string>[] = useMemo(() => {
    const expeditedACHDeliveryDate = deliveryOptions.find(
      (deliveryOption) => deliveryOption.type === ExpeditedDeliveryType.EXPEDITED_ACH
    )?.deliveryDate;

    const mappedDeliveryOptions = deliveryOptions.map((option, index) => ({
      id: index.toString(),
      'data-testid': `delivery-option-${option.type}`,
      component: (
        <PaymentDeliveryOption
          fundingSourceType={fundingSourceType}
          isFast={
            CONSTS.EXPEDITED_DELIVERY_TYPES.includes(option.type) ||
            (option.type === FastCheckDeliveryType.EXPRESS &&
              !deliveryOptions.find((item) => item.type === FastCheckDeliveryType.OVERNIGHT))
          }
          deliveryOption={option}
          totalAmount={bill.totalAmount}
          freeChecksAvailableCount={checkFee.freeChecks?.available}
          expeditedACHDeliveryDate={expeditedACHDeliveryDate}
        />
      ),
    }));

    return mappedDeliveryOptions;
  }, [deliveryOptions, fundingSourceType, checkFee.freeChecks?.available]);

  const handleSelectDeliveryOption = (selectedId) => {
    const { scheduledDate, deliveryDate, maxDeliveryDate, type } = deliveryOptions[selectedId];
    onSelectDeliveryOption && onSelectDeliveryOption(scheduledDate, deliveryDate, maxDeliveryDate, type);
  };

  const optionType = deliveryOptions[0]?.type;
  const group = optionType ? `deliveryDate-${optionType}` : 'deliveryDate';

  const defaultRegularOptionIndex = deliveryOptions.findIndex((option) => !FAST_DELIVERY_TYPES.includes(option.type));
  const preferredOptionIndex = deliveryOptions.findIndex((option) => option.type === deliveryPreference);
  const selected = (preferredOptionIndex > -1 ? preferredOptionIndex : defaultRegularOptionIndex).toString();

  return (
    <StyledMIRadioGroup
      id="PaymentDeliveryOption"
      options={options}
      selected={selected}
      onSelect={handleSelectDeliveryOption}
      colDirection
      group={group}
    />
  );
};

const StyledMIRadioGroup = styled(MIRadioGroup)`
  ${OuterCircle} {
    margin-top: 0.8rem;
  }
`;

export { PaymentDeliveryOptions };
