import React from 'react';
import { useSelector } from 'react-redux';
import paymentsStore from 'src/modules/payments/payment-store';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardTableDate } from '../components/DashboardTableDate';

type Props = {
  item: DashboardListItemType;
};

export const DashboardPaidOnCell = ({ item }: Props) => {
  const payment = useSelector(paymentsStore.selectors.byId(item.paymentId));
  return <DashboardTableDate date={payment?.deliveryEta} />;
};
