/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import vendorsEventsMapping from 'src/pages/vendor/event-mapping';
import regularBatchPaymentsMapping from 'src/billpay/qbdt/pages/batch-pay/analytics/event-mapping';
import regularBatchPaymentsSelectionMapping from 'src/billpay/qbdt/pages/batch-pay/analytics/selection-event-mapping';
import qbdtEventMapping from 'src/billpay/qbdt/services/analytics/event-mapping';
import { microDepositsEventMapping } from 'src/pages/funding-sources/event-mapping';
import { dashboardEventMapping } from 'src/billpay/qbdt/pages/dashboard/event-mapping';

import { CONSTS } from 'src/utils/consts';
import { mapApiSlice } from './event-mapping-utils';
import { EventMappingsTree } from './types';

const DEFAULT_MAPPING_NAME = CONSTS.EVENT_MAPPING_NAME.DEFAULT;

const MAPPINGS: EventMappingsTree = {
  '/orgs/*/bills': {
    'schedule.payment': ['bills', 'dashboard-schedule-payment-clicked'],
    'fab.action': ['bills', 'dashboard-fab-clicked'],
    'settings.view': ['bills', 'dashboard-settings-clicked'],
    'faq.view': ['bills', 'dashboard-faq-clicked'],
    'page.view': ['bills', 'dashboard-bills-list'],
    'bills.actions.decline': ['bills', 'decline-payment-button-clicked'],
    ...mapApiSlice('[PAYMENTS] PAYMENT_APPROVED', 'bills', 'payment_approved'),
    ...mapApiSlice('[PAYMENTS] PAYMENT_DECLINED', 'bills', 'payment_declined', {
      request: { reason: 'payload.reason' },
    }),
    'modal-close-decline-payment-modal': ['bills', 'decline-payment-modal-closed'],
    'toggle-actions-menu': ['bills', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'bills-payment': ['bills-payment', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'bills.actions.trackDelivery': ['check', 'tracking-delivery'],
    'mark-as-paid-modal': ['bills', 'mark-as-paid-modal', { toggle: 'toggle' }],
    'option-changed-intuitAccountId': ['bills', 'mark-as-paid-modal-option-changed', { qboAccountId: 'option.value' }],
    'bills.form.markAsPaidDialog.dialogOkButtonText': ['bills', 'mark-as-paid-modal-submit'],
    ...regularBatchPaymentsSelectionMapping,
  },
  '/orgs/*/bills/pay/batch-success': {
    'batchPayment.success.buttonLabel': ['pay-bills-batch-success', 'done'],
    'go-to-dashboard': [
      'pay-bills-batch-success',
      'go-to-online-bill-payment',
      { flow: 'flow', fromDashboard: 'fromDashboard' },
    ],
    'support@qbousers.meliopayments.com': ['pay-bills-batch-success', 'support-email'],
  },
  '/orgs/*/bills/pay/batch-complete-legal-info': {
    ...mapApiSlice('[USER] SET_COMPANY_INFO', 'complete-legal-info', 'continue'),
    ...mapApiSlice('[PAYMENTS] SEND_BATCH_PAYMENTS', 'pay-bills-batch', 'schedule-batch-payments', {
      failure: {
        paid: 'payload.paid.length',
        payments: 'meta.identifier.payments.length',
      },
    }),
  },
  '/orgs/*/bills/pay/batch': {
    'change-delivery-method': ['pay-bills-batch', 'change-delivery-method'],
    'select-funding-source': ['pay-bills-batch', 'change-payment-method'],
    'bill-selected': ['pay-bills-batch', 'bill-selected'],
    'select-scheduled-date-error': ['pay-bills-batch', 'select-scheduled-date-error', { error: 'error' }],
    'batch-add-new-bank': ['pay-bills-batch', 'add-bank-account'],
    'batch-add-new-credit': ['pay-bills-batch', 'add-credit-card'],
    'batch-add-new-debit': ['pay-bills-batch', 'add-debit-card'],
    'batch-add-new-delivery-method': ['pay-bills-batch', 'add-delivery-method', { deliveryMethod: 'deliveryMethod' }],
    'part-of-batch-failed-single': ['pay-bills-batch', 'part-of-batch-failed-single-case'],
    'part-of-batch-failed-multiple': ['pay-bills-batch', 'part-of-batch-failed-multiple-case'],
    'batchPayment.sidePanel.buttons.cancel': ['pay-bills-batch', 'cancel-side-panel'],
    'batchPayment.sidePanel.buttons.saveAndContinue': ['pay-bills-batch', 'set-side-panel'],
    'batchPayment.sidePanel.buttons.saveAndComplete': ['pay-bills-batch', 'set-side-panel'],
    'batchPayment.buttons.cancel': ['pay-bills-batch', 'cancel-batch-payment'],
    ...mapApiSlice('[PAYMENTS] SEND_BATCH_PAYMENTS', 'pay-bills-batch', 'schedule-batch-payments', {
      failure: {
        paid: 'payload.paid.length',
        payments: 'meta.identifier.payments.length',
      },
    }),
    'open-date-picker': ['pay-bills-batch', 'open-scheduled-date-calendar'],
    'open-date-picker-from-table': ['pay-bills-batch', 'open-scheduled-date-calendar-from-table'],
    'switch-fast-ach-on': ['pay-bills-batch', 'toggle-fast-ach-on'],
    'switch-fast-ach-off': ['pay-bills-batch', 'toggle-fast-ach-off'],
    'switch-fast-check-on': ['pay-bills-batch', 'toggle-fast-check-on'],
    'switch-fast-check-off': ['pay-bills-batch', 'toggle-fast-check-off'],
    'option-selected-deliveryDate-check': ['pay-bills-batch', 'option-selected-deliveryDate-check-${option.id}'],
    'option-selected-deliveryDate-ach': ['pay-bills-batch', 'option-selected-deliveryDate-ach-${option.id}'],
    'schedule-payments-success': [
      'pay-bills-batch',
      'schedule-payments-success',
      {
        billIds: 'billIds',
        totalPayments: 'totalPayments',
        totalFastEligibleAchPayments: 'totalFastEligibleAchPayments',
        totalFastEligibleCheckPayments: 'totalFastEligibleCheckPayments',
        totalFastCheckPayments: 'totalFastCheckPayments',
        totalFastAchPayments: 'totalFastAchPayments',
      },
    ],
    'filtered-biller-directory-bills': [
      'pay-bills-batch',
      'filtered-biller-directory-bills',
      {
        totalBills: 'totalBills',
        totalDisplayedBills: 'totalDisplayedBills',
        totalFilteredBills: 'totalFilteredBills',
      },
    ],
  },
  '/orgs/*/bills/*/pay/funding': {
    'page.view': ['pay-bill', 'add-funding'],
  },
  '/orgs/*/bills/*/payment/*/edit/funding': {
    'page.view': ['pay-bill', 'edit-funding'],
  },
  '/orgs/*/bills/*/pay/delivery-method': {
    'page.view': ['pay-bill', 'add-delivery'],
  },
  '/orgs/*/bills/*/payment/*/edit/delivery-method': {
    'page.view': ['pay-bill', 'edit-delivery-method'],
  },
  '/orgs/*/bills/*/pay/date': {
    'page.view': ['pay-bill', 'date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/orgs/*/bills/*/payment/*/edit/date': {
    'page.view': ['pay-bill', 'edit-date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/orgs/*/bills/*/pay/memo': {
    'page.view': ['pay-bill', 'memo'],
  },
  '/orgs/*/bills/*/payment/*/edit/memo': {
    'page.view': ['pay-bill', 'edit-memo'],
  },
  '/orgs/*/bills/*/pay/confirm': {
    'page.view': ['pay-bill', 'confirm'],
  },
  '/orgs/*/bills/*/payment/*/edit/confirm': {
    'page.view': ['pay-bill', 'edit-confirm'],
  },
  '/orgs/*/bills/*/pay/success': {
    'page.view': ['pay-bill', 'success'],
    'go-to-dashboard': [
      'pay-bill-success',
      'go-to-online-bill-payment',
      { flow: 'flow', fromDashboard: 'fromDashboard' },
    ],
  },
  '/orgs/*/bills/*/pay/success-from-dashboard': {
    'page.view': ['pay-bill', 'success'],
    'go-to-dashboard': [
      'pay-bill-success',
      'go-to-online-bill-payment',
      { flow: 'flow', fromDashboard: 'fromDashboard' },
    ],
  },
  '/orgs/*/bills/*/pay/complete-legal-info': {
    'page.view': ['pay-bill', 'complete-legal-info'],
  },
  '/quickbooks/block/*': {
    'page.view': ['quickbooks', 'blocked-user'],
  },
  '/orgs/*/vendors': {
    'page.view': ['vendors', 'vendors-list'],
    'toggle-actions-menu': ['vendors-delivery-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'view-vendors-actions': ['vendors', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/orgs/*/vendors/*/delivery-method/virtual/new': {
    ...mapApiSlice('[DELIVERYMETHODS] CREATE', 'virtual-delivery-method', 'create-delivery-method'),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE', 'virtual-delivery-method', 'update-delivery-method'),
    'go.exit': ['virtual-delivery-method', 'exit'],
  },
  [DEFAULT_MAPPING_NAME]: {
    'page-left': ['page', 'browser-left-or-reloaded'],
  },
  '/orgs/*/bill/*': {
    'bills.form.paymentActivity.actions.cancel': ['bill', 'delete-single-payment'],
    'bills.form.paymentActivity.actions.close': ['bill', 'close-marked-as-paid'],
    'bills.form.paymentActivity.actions.markAsUnpaid': ['bill', 'mark-as-unpaid'],
    'payments.form.deleteDialog.cancelText': ['bill', 'delete-payment-canceled'],
  },
  ...regularBatchPaymentsMapping,
  ...vendorsEventsMapping,
  ...qbdtEventMapping,
  ...microDepositsEventMapping,
  ...dashboardEventMapping,
};

export default MAPPINGS;
