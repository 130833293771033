const baseUrl = '/quickbooks-desktop/orgs/:orgId/settings';

const LOCATIONS = {
  base: baseUrl,
  paymentMethods: `${baseUrl}/payment-methods`,
  billing: `${baseUrl}/billing`,
  vendors: `${baseUrl}/vendors`,
  vendorsView: `${baseUrl}/vendors/:id`,
  account: `${baseUrl}/account`,
};

export default LOCATIONS;
