import React from 'react';
import { toast, cssTransition } from 'react-toastify';
import { CONSTS } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import { ToastMessage } from 'src/components/common-app/ToastMessage';

const slide = cssTransition({
  enter: 'Toastify__slide-enter',
  exit: 'Toastify__slide-exit',
  duration: [300, 500],
  appendPosition: true,
});

// Using react-tostify. Docs: https://fkhadra.github.io/react-toastify/
export function pushNotification(item: {
  type: string;
  msg: string;
  textValues?: any;
  icon?: string;
  autoClose?: any;
}) {
  const { type, msg, icon, textValues, autoClose = CONSTS.TIMEOUTS.CLOSE_NOTIFICATION } = item;
  let toastId: number;

  const toastSettings = {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: slide,
  };

  analytics.trackAction('toast-notification', { type });

  switch (type) {
    case CONSTS.NOTIFICATION_VARIANT.SUCCESS:
      toastId = toast.success(
        <ToastMessage type={CONSTS.NOTIFICATION_VARIANT.SUCCESS} text={msg} textValues={textValues} icon={icon} />,
        toastSettings
      );
      break;
    case CONSTS.NOTIFICATION_VARIANT.INFO:
      toastId = toast.info(
        <ToastMessage type={CONSTS.NOTIFICATION_VARIANT.INFO} text={msg} textValues={textValues} icon={icon} />,
        toastSettings
      );
      break;
    case CONSTS.NOTIFICATION_VARIANT.ERROR:
      toastId = toast.error(
        <ToastMessage type={CONSTS.NOTIFICATION_VARIANT.ERROR} textValues={textValues} text={msg} icon={icon} />,
        toastSettings
      );
      break;
    default:
      toastId = 0;
      break;
  }

  return toastId;
}

export function checkIfToastIsDisplayedById(toastId: number | null) {
  return toastId ? toast.isActive(toastId) : false;
}

export function dismissNotification() {
  toast.dismiss();
}

export function showErrorToast(notification: { msg: string; icon?: string }) {
  const { msg, icon } = notification;
  const localMsg = `${msg}. Our team was notified and we are working as quickly as we can to fix this.`;

  pushNotification({
    type: CONSTS.NOTIFICATION_VARIANT.ERROR,
    msg: localMsg,
    icon,
  });
}
