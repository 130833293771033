import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  id: string;
  label: string;
};

export const FieldTitle = ({ id, label }: Props) => (
  <Box id={id} as="span" display="block" textAlign="left" mb="ds.sm" textStyle="ds.body2" color="ds.gray.200">
    <MIFormattedText label={label} />
  </Box>
);
