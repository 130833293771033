import { useEffect, useState } from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import config from 'src/config';
import financialAccountsApi from 'src/services/api/financialAccounts';

const PLAID_ENV = config.services.plaid.env;
const PLAID_CLIENT_NAME = config.services.plaid.clientName;

export type PlaidConfigProps = {
  orgId: number;
  onSuccess(token: string, metadata: PlaidLinkOnSuccessMetadata): void;
  onEvent?: (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => void;
  onExit(error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata): void;
};
export const usePlaid = ({ orgId, onSuccess, onEvent, onExit }: PlaidConfigProps) => {
  const [linkToken, setLinkToken] = useState<string>('');

  useEffect(() => {
    const createLinkToken = async () => {
      const { linkToken } = await financialAccountsApi.createPlaidLinkToken(orgId, {
        clientName: config.services.plaid.clientName,
      });

      setLinkToken(linkToken);
    };

    createLinkToken();
  }, [orgId]);

  const linkConfig: PlaidLinkOptions = {
    token: linkToken,
    env: PLAID_ENV,
    clientName: PLAID_CLIENT_NAME,
    onSuccess,
    onEvent,
    onExit,
  };

  const { open, ready, error, exit } = usePlaidLink(linkConfig);

  return { ready, open, exit, error };
};
