import React from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Flex } from '@melio/billpay-design-system';

import { ExtendedSelectOption } from 'src/utils/types';
import { MIFormattedText } from 'src/utils/formatting';
import { chakra } from 'src/theme/ds';

type Props = {
  options: ExtendedSelectOption[];
  onChange: (value: ExtendedSelectOption[]) => void;
};

const CheckboxSelect = ({ options, onChange }: Props) => {
  const intl = useIntl();

  const handleSelection = (checked: boolean, selectedIndex: number) => {
    onChange(
      options.map((option, index) =>
        index === selectedIndex ? { ...option, value: checked, selected: checked } : option
      )
    );
  };

  return (
    <CheckboxSelectWrapper>
      {options.map((option, index) => (
        <CheckboxSelectItem
          key={`${index + option.label}`}
          title={intl.formatMessage({ id: option.label }, option.values)}
        >
          <Checkbox onChange={() => handleSelection(!option.value, index)} isChecked={option.value} />
          <CheckboxLabel onClick={() => handleSelection(!option.value, index)}>
            <MIFormattedText label={option.label} values={option.values} />
          </CheckboxLabel>
        </CheckboxSelectItem>
      ))}
    </CheckboxSelectWrapper>
  );
};

export { CheckboxSelect };

const CheckboxSelectWrapper = chakra(Flex, {
  baseStyle: {
    flexDir: 'column',
    justifyContent: 'start',
    maxH: '25rem',
    overflow: 'auto',
    py: 'ds.sm',
  },
});

const CheckboxSelectItem = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    gap: 'ds.md',
    lineHeight: '4rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    p: '1rem 1.4rem',
    _hover: {
      bgColor: 'ds.gray.800',
    },
  },
});

const CheckboxLabel = chakra('span', {
  baseStyle: {
    w: 'full',
    textStyle: 'ds.body2',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'ds.gray.100',
  },
});
