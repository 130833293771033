import { CONSTS } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { isCheckDeliveryMethod, isFastDeliveryType } from 'src/utils/delivery-methods';

import { IsAchCheckPaymentType } from './types';

export const featureFlagValues = {
  CLOSED: 'closed',
  OPEN_WITH_PROMOTIONS: 'open-with-promotions',
  OPEN_NO_PROMOTIONS: 'open-no-promotions',
};

export const isCheckFeeClosed = (value) => value === featureFlagValues.CLOSED;

export const isCheckFeeOpen = (value) => isCheckFeeOpenWithPromotions(value) || isCheckFeeOpenNoPromotions(value);

export const isCheckFeeOpenWithPromotions = (value) => value === featureFlagValues.OPEN_WITH_PROMOTIONS;

export const isCheckFeeOpenNoPromotions = (value) => value === featureFlagValues.OPEN_NO_PROMOTIONS;

export const isAchFundingType = (selectedFundingSource) =>
  selectedFundingSource?.fundingType === CONSTS.FUNDING_TYPE.ACH;

export const isBatchAchCheck = (data = [], fundingSources) =>
  data.some(({ fee, payment }: any) => {
    const fundingSource = fundingSources?.find((fs) => fs.id === payment?.fundingSourceId);
    const isAch = isAchFundingType(fundingSource);
    const isFastChecks = fee?.['express-check'] || fee?.['overnight-check'];
    const isCheck = isCheckDeliveryMethod(payment?.deliveryMethod);

    return isCheck && isAch && !isFastChecks;
  });

export const setAchToCheckFee = (prevFee, amount) => ({
  ...prevFee,
  [CONSTS.FEE_VARIANTS.ACH_TO_CHECK]: {
    feeType: CONSTS.FEE_VARIANTS.ACH_TO_CHECK,
    isFixed: true,
    amount,
    percent: null,
    cap: null,
  },
});

export const setFreeCheck = (prevFee) => ({
  ...prevFee,
  [CONSTS.FEE_VARIANTS.ACH_TO_CHECK]: {
    feeType: CONSTS.FEE_VARIANTS.ACH_TO_CHECK,
    isFixed: true,
    percent: null,
    cap: null,
    free: true,
  },
});

// verify ach-to-check in single/batch flows
export const isAchCheckPayment = ({
  deliveryMethod,
  fundingSource,
  deliveryPreference,
  batchData,
  batchFundingSources,
}: IsAchCheckPaymentType) => {
  if (batchData) {
    return isBatchAchCheck(batchData, batchFundingSources);
  }

  const isCheck = isCheckDeliveryMethod(deliveryMethod as DeliveryMethodType);
  const hasValidFS = isAchFundingType(fundingSource);
  const isFastDelivery = isFastDeliveryType(deliveryPreference);

  return isCheck && hasValidFS && !isFastDelivery;
};
