import React from 'react';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { useCancelPayment } from '../hooks/useCancelPayment';
import { useEditPayment } from '../hooks/useEditPayment';
import { ActionButtonsWrapper } from './ActionButtonsWrapper';

export const PaymentScheduledActions = () => {
  const { openPaymentCancelModal, cancelPaymentModal } = useCancelPayment();
  const { goEditPayment } = useEditPayment();

  return (
    <>
      {cancelPaymentModal}
      <ActionButtonsWrapper direction="row">
        <MIButton
          label="bills.form.paymentActivity.actions.cancel"
          variant={BUTTON_VARIANT.DESTRUCTIVE}
          onClick={openPaymentCancelModal}
          fullWidth
        />
        <MIButton
          label="bills.form.paymentActivity.actions.edit"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={goEditPayment}
          fullWidth
        />
      </ActionButtonsWrapper>
    </>
  );
};
