import { DeliveryMethodType, DeliveryOptionType, PaymentFeeItem } from 'src/utils/types';
import { SortingRule, TableOptions } from 'react-table';
import { BatchBillType, BatchPaymentType } from 'src/modules/regular-batch-payments/types/store-types';

export interface BatchPaymentsDataTableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
  name: string;
  onChangeSort?: (sort: Array<SortingRule<T>>) => void;
  clearSelected?: boolean;
  initialSortBy?: SortingRule<T>[];
  contentState?: DataTableContentState;
}

export type BillDetailsType = {
  invoiceNumber: string;
  totalAmount: number;
  dueDate: string;
  note: string;
  vendor: {
    companyName: string;
  };
};

export type VendorCellType = {
  id: string;
  name: string;
  companyName: string;
  deliveryMethods: DeliveryMethodType[];
  isOwnedVendor: boolean;
  mccCode?: string;
};

export type MainTableRowType = {
  id: string;
  dueDate: Date;
  vendor: VendorCellType;
  totalAmount: number;
  payment: BatchPaymentType;
  deliveryOptions?: DeliveryOptionType[];
  minScheduledDate: string;
  fee?: Record<string, PaymentFeeItem>;
  isBillReady: boolean;
  isLoadingDates: boolean;
  isScheduledDateSelectedByUser: boolean;
  subRows?: BatchBillType[];
  payBillFlowUUID: string;
};

export type DataTableRowType = MainTableRowType | BatchBillType;

export enum DataTableContentState {
  LOADING = 'loading',
  CONTENT = 'content',
}
