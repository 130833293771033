import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { FieldError } from '@basis-theory/basis-theory-js/types/elements/shared';
import { FieldErrorMessage } from 'src/billpay/qbdt/components/basis-theory/fields/common/FieldErrorMessage';

type Props = {
  testId: string;
  empty: boolean;
  emptyLabel: string;
  invalidLabel: string;
  fieldErrors?: FieldError[];
};

export const FieldErrors = ({ testId, fieldErrors, empty, emptyLabel, invalidLabel }: Props) => (
  <Flex direction="column" data-testid={testId}>
    {empty ? <FieldErrorMessage label={emptyLabel} /> : null}
    {fieldErrors?.map((error, index) =>
      error.type === 'invalid' || (error.type === 'incomplete' && !empty) ? (
        <FieldErrorMessage key={index} label={invalidLabel} />
      ) : null
    )}
  </Flex>
);
