import * as React from 'react';
import { compose } from 'recompose';
import get from 'lodash/get';
import { connect } from 'react-redux';
import config from 'src/config';
import mapKeys from 'lodash/mapKeys';
import { CONSTS } from 'src/utils/consts';
import { withPreservedStateNavigator } from 'src/hoc';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/funding-source/locations';
import { GlobalState } from 'src/redux/types';
import financialAccountsApi from 'src/services/api/financialAccounts';
import AreaLoader from 'src/components/common/AreaLoader';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { loadFundingSourcesAction, loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { generatePath } from 'react-router-dom';
import { PlaidLink } from './components/PlaidLink';

const eventPage = 'bank-add-plaid';

type MapStateToProps = {
  orgId: string;
};

type MapDispatchToProps = {
  selectFundingSourceForPayBillFlow: (id: number) => void;
  refreshFundingSources: () => Promise<void>;
  refreshDeliveryMethods: () => Promise<void>;
};

type Props = {
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  locationState: Record<string, any>;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  isPlaidOpen: boolean;
};

const handleEvent = (eventName, eventProperties) => {
  analytics.track(
    'add-funding-source',
    `plaid-${config.services.plaid.env}-link-${eventName.toLowerCase()}`,
    addPlaidPrefixToEventProps(eventProperties)
  );
};

const addPlaidPrefixToEventProps = (properties: Record<string, string>): Record<string, string> =>
  mapKeys(properties, (__value, key) => `plaid_metadata_${key}`);

class SetBankPlaidPageContainer extends React.PureComponent<any, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      isPlaidOpen: true,
    };
  }

  onPlaidSuccess = (token, metadata) => {
    const { locationState } = this.props;
    const origin = get(locationState, 'preservedState.origin', '');
    if (!metadata || metadata.error) {
      analytics.track(eventPage, 'plaid-error', { error: metadata.error });
      return;
    }

    const { account } = metadata;

    if (!account) {
      analytics.track(eventPage, 'plaid-error', {
        error: 'account not selected',
      });
      return;
    }

    const selectedAccountsIds = [account.id];
    this.addFundingSourcePlaidAccount(selectedAccountsIds, token, origin);
  };

  onPlaidExit = () => {
    const { navigate, orgId } = this.props;
    analytics.track(eventPage, 'plaid-exit');
    this.setState({ isPlaidOpen: false });

    analytics.track(eventPage, 'add-manual');
    navigate(generatePath(locations.bankManual, { orgId }), false, {
      cantFindPlaidAccount: true,
    });
  };

  addFundingSourcePlaidAccount = (selectedAccountsIds: Array<string>, token: string, origin: string) => {
    const {
      locationState,
      orgId,
      navigate,
      refreshFundingSources,
      selectFundingSourceForPayBillFlow,
      selectFundingSourceForBatchFlow,
    } = this.props;
    financialAccountsApi
      .addPlaidAccount(orgId, { token, selectedAccountsIds })
      .then((data) => {
        refreshFundingSources()
          .then(async () => {
            const accounts = data.accounts && data.accounts.length ? data.accounts : [];

            analytics.track(eventPage, 'plaid-success');
            analytics.trackMqlEvent('added-funding', 'mql');
            analytics.trackMqlEvent('added-funding-plaid', 'mql');
            analytics.setTraits({
              [CONSTS.DB_ANALYTICS_TRAITS.ADDED_FUNDING]: true,
            });
            analytics.setFundingSourceTraits();

            this.setState({ isPlaidOpen: false });

            if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS) {
              await selectFundingSourceForBatchFlow({
                newFundingSourceId: accounts[0].id,
              });
            }

            if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL) {
              selectFundingSourceForPayBillFlow(accounts[0].id);
            }

            navigate(generatePath(locations.linkAccount, { orgId }), false, {
              ...locationState,
              fundingSourceId: accounts[0].id,
              origin,
              accounts,
            });
          })
          .catch(() => {
            this.setState({ isPlaidOpen: false });
          });
      })
      .catch(() => {
        this.setState({ isPlaidOpen: false });
      });
  };

  render() {
    const { isPlaidOpen } = this.state;
    const { orgId } = this.props;

    return (
      <>
        <AreaLoader />
        <PlaidLink
          onEvent={handleEvent}
          orgId={orgId}
          onSuccess={this.onPlaidSuccess}
          onExit={this.onPlaidExit}
          showDialog={isPlaidOpen}
        />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectFundingSourceForPayBillFlow(id: number) {
    dispatch(selectFundingSourceAction(id));
  },
  refreshFundingSources() {
    return new Promise((resolve, reject) => {
      dispatch(loadFundingSourcesAction(resolve, reject));
    });
  },
  refreshDeliveryMethods() {
    return new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });
  },
});

export default compose(
  withPreservedStateNavigator(),
  connect(mapStateToProps, mapDispatchToProps)
)(SetBankPlaidPageContainer);
