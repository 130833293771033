import React, { useEffect } from 'react';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useEditCheckAddressStatus } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import paymentStore from 'src/modules/payments/payment-store';
import errorImage from 'src/images/qbo/help.svg';
import GeneralFlowErrorContainer from 'src/pages/quickbooks/GeneralFlowErrorContainer';
import AreaLoader from 'src/components/common/AreaLoader';

type Props = {
  token: string;
};
const ANALYTICS_TAG_NAME = 'PaymentCancelledPage';

export const PaymentCancelledPage = ({ token }: Props) => {
  const site = useSiteContext();
  const { paymentId, isPaymentLoading } = useEditCheckAddressStatus({ token });
  const { errorData } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));

  useEffect(() => {
    if (!analytics.extraPropertiesByTags[ANALYTICS_TAG_NAME]?.origin) {
      analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
        origin: 'payment-cancelled',
      });
    }

    return () => analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
  }, []);

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <GeneralFlowErrorContainer
      illustration={errorImage}
      subtitle="vendors.deliveryMethods.shiftToDebit.errors.paymentCancelled"
      subtitleValues={{
        companyName: errorData?.data?.companyName,
        email: errorData?.data?.email,
        supportEmail: <EmailLink href={`mailto:${site.config.support.email}`}>{site.config.support.email}</EmailLink>,
      }}
    />
  );
};

const EmailLink = styled.a`
  text-decoration: none;
  color: rgba(28, 130, 210);
`;
