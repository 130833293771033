import merge from 'lodash/merge';
import filter from 'lodash/filter';
import { createRestfulSlice } from '../../helpers/redux/createRestfulSlice';
import api from '../../services/api/user';
import { SET_PROFILE } from '../../redux/user/actionTypes';

const name = 'users';

export function getUsersOfOrg(orgId) {
  return (state) =>
    filter(state[name].byId, (user) => user.userOrganizations.some((userOrg) => userOrg.organizationId === orgId));
}
const usersStore = createRestfulSlice({
  name,
  schemaName: 'user',
  api,
  extraReducers: {
    [SET_PROFILE](state, payload) {
      const { userPreferences, ...profile } = payload.profile.toJS();
      const { organizations } = payload;
      state.byId[profile.id] = merge(state.byId[profile.id] || {}, {
        ...profile,
        userOrganizations: (organizations || []).filter((o) => !!o.userOrganization).map((o) => o.userOrganization),
      });
    },
  },
  selectors: {
    byId(userId) {
      return (state) => state[name].byId[userId];
    },
  },
});

export default usersStore;
