import React from 'react';
import { Flex, Icon } from '@melio/billpay-design-system';
import { ReactComponent as DeliveryMethodCheck } from './icons/delivery-method-check.svg';
import { ReactComponent as DeliveryMethodBank } from './icons/delivery-method-bank.svg';
import { ReactComponent as GreenCheckmark } from './icons/green-checkmark.svg';
import { MethodItemWrapper } from '../MethodItemWrapper/MethodItemWrapper';
import { MethodPlaceholder } from '../MethodItemWrapper/MethodPlaceholder';

type Props = {
  iconType: 'check' | 'bank';
};

export const DeliveryMethodItem = ({ iconType }: Props) => {
  const icon =
    iconType === 'check' ? (
      <Icon as={DeliveryMethodCheck} w="3rem" h="2rem" />
    ) : (
      <Icon as={DeliveryMethodBank} w="2.9rem" h="3rem" />
    );
  const checkmarkIcon = (
    <Flex justify="center" align="center" w="2.8rem" h="2.8rem">
      <Icon as={GreenCheckmark} w="1.8" h="1.3rem" />
    </Flex>
  );

  return (
    <MethodItemWrapper>
      {icon}
      <MethodPlaceholder />
      {checkmarkIcon}
    </MethodItemWrapper>
  );
};
