import React from 'react';
import { MITextInput } from 'src/components/common/MITextInput';

type Props = {
  vendorId: string;
  value: string;
  error?: string;
  label?: string;
  labelValues?: Record<string, any>;
  placeholder?: string;
  autoFocus?: boolean;
  required?: boolean;
  onChange: (changeField: { id: string; value: string }) => void;
};

const VendorEmailInput = ({
  vendorId,
  label = 'vendorEmail.label',
  labelValues = {},
  placeholder = 'vendorEmail.placeholder',
  autoFocus = false,
  required = false,
  value,
  error,
  onChange,
}: Props) => (
  <MITextInput
    id={vendorId}
    type="email"
    label={label}
    labelValues={labelValues}
    placeholder={placeholder}
    value={value}
    autoFocus={autoFocus}
    required={required}
    privateData
    errorMessage={error}
    onChange={onChange}
  />
);

export { VendorEmailInput };
