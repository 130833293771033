const ACTION_PREFIX = '[PAY_BILL_FLOW]';

export const BEGIN_REGULAR_PAY_BILL_FLOW = `${ACTION_PREFIX} BEGIN_REGULAR_PAY_BILL_FLOW`;
export const BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS = `${ACTION_PREFIX} BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS`;
export const BEGIN_REGULAR_PAY_BILL_FLOW_FAILED = `${ACTION_PREFIX} BEGIN_REGULAR_PAY_BILL_FLOW_FAILED`;

export const END_PAY_BILL_FLOW = `${ACTION_PREFIX} END_PAY_BILL_FLOW`;

export const SELECT_FUNDING_SOURCE = `${ACTION_PREFIX} SELECT_FUNDING_SOURCE`;
export const SELECT_PAYMENT_DATES = `${ACTION_PREFIX} SELECT_PAYMENT_DATES`;
export const UPDATE_PAYMENT_MEMO = `${ACTION_PREFIX} UPDATE_PAYMENT_MEMO`;
export const UPDATE_BILL = `${ACTION_PREFIX} UPDATE_BILL`;

export const FETCH_BILL = `${ACTION_PREFIX} FETCH_BILL`;
export const FETCH_BILL_SUCCESS = `${ACTION_PREFIX} FETCH_BILL_SUCCESS`;
export const FETCH_BILL_FAILED = `${ACTION_PREFIX} FETCH_BILL_FAILED`;

export const SELECT_NEW_DELIVERY_METHOD = `${ACTION_PREFIX} SELECT_NEW_DELIVERY_METHOD`;
export const SELECT_DELIVERY_METHOD = `${ACTION_PREFIX} SELECT_DELIVERY_METHOD`;
export const ADD_NEW_DELIVERY_METHOD = `${ACTION_PREFIX} ADD_NEW_DELIVERY_METHOD`;

export const CREATE_PAYMENT = `${ACTION_PREFIX} CREATE_PAYMENT`;
export const CREATE_PAYMENT_SUCCESS = `${ACTION_PREFIX} CREATE_PAYMENT_SUCCESS`;
export const CREATE_PAYMENT_ERROR = `${ACTION_PREFIX} CREATE_PAYMENT_ERROR`;

export const UPDATE_PAYMENT = `${ACTION_PREFIX} UPDATE_PAYMENT`;
export const UPDATE_PAYMENT_SUCCESS = `${ACTION_PREFIX} UPDATE_PAYMENT_SUCCESS`;
export const UPDATE_PAYMENT_ERROR = `${ACTION_PREFIX} UPDATE_PAYMENT_ERROR`;

export const RETRY_FAILED_PAYMENT = `${ACTION_PREFIX} RESCHEDULE_PAYMENT`;
export const RETRY_FAILED_PAYMENT_SUCCESS = `${ACTION_PREFIX} RESCHEDULE_PAYMENT_SUCCESS`;
export const RETRY_FAILED_PAYMENT_ERROR = `${ACTION_PREFIX} RESCHEDULE_PAYMENT_ERROR`;

export const RESET_ERROR = `${ACTION_PREFIX} RESET_ERROR`;
export const SET_URL_TO_BACK = `${ACTION_PREFIX} SET_URL_TO_BACK`;

export const SET_FEE = `${ACTION_PREFIX} SET_FEE`;

export const SET_FAQ_PANEL = `${ACTION_PREFIX} SET_FAQ_PANEL`;

export const SET_COMPLIANCE_LIMITATIONS = `${ACTION_PREFIX} SET_COMPLIANCE_LIMITATIONS`;
