import React from 'react';
import { Flex, Icon, Text } from '@melio/billpay-design-system';
import { ReactComponent as SuccessIllustration } from 'src/images/general/action-done-icon.svg';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { InvoiceNumberType } from 'src/pages/vendor/hooks/useInvoiceNumberType';

type Props = {
  amount: number;
  companyName: string;
  invoiceNumber: string;
  invoiceNumberType: InvoiceNumberType;
};

export const PaymentInfo = ({ amount, companyName, invoiceNumber, invoiceNumberType }: Props) => (
  <Flex direction="column" justify="center" align="start">
    <Icon as={SuccessIllustration} w="ds.3xl" h="ds.3xl" mb="ds.xl" />
    <Text textStyle="ds.h5" m="0" color="ds.gray.100">
      <MIFormattedText label="vendor.pushToDebit.addDebitCard.success.title" />
    </Text>
    <Text textStyle="ds.body2" mb="0" mt="ds.md" color="ds.gray.100">
      <MIFormattedText
        label="vendor.pushToDebit.addDebitCard.success.text"
        values={{
          amount: <MIFormattedCurrency value={amount} />,
          companyName,
          invoiceNumber,
          invoiceNumberType,
        }}
      />
    </Text>
  </Flex>
);
