import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import EditBankDeliveryMethodPageContainer from './EditBankDeliveryMethodPageContainer';
import EditCheckNameDeliveryMethodPageContainer from './EditCheckNameDeliveryMethodPageContainer';
import EditCheckAddressWithAutocompleteDeliveryMethodPageContainer from './EditCheckAddressWithAutocompleteDeliveryMethodPageContainer';
import VirtualDeliveryMethodPageContainer from './VirtualDeliveryMethodPageContainer';

export function DeliveryMethodEditRouter() {
  const match = useRouteMatch<{ vendorId: string; deliveryMethodId: string }>();
  const { vendorId, deliveryMethodId } = match.params;
  return (
    <Switch>
      <SmartRoute path={`${match.path}/ach`} exact>
        <EditBankDeliveryMethodPageContainer vendorId={vendorId} deliveryMethodId={deliveryMethodId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/check/name`} exact>
        <EditCheckNameDeliveryMethodPageContainer vendorId={vendorId} deliveryMethodId={deliveryMethodId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/check/address`} exact>
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer
          vendorId={vendorId}
          deliveryMethodId={deliveryMethodId}
        />
      </SmartRoute>
    </Switch>
  );
}
export function QBDTDeliveryMethodRouter() {
  const match = useRouteMatch<{ vendorId: string }>();
  const { vendorId } = match.params;

  return (
    <Switch>
      <SmartRoute path={`${match.path}/virtual/new`} exact>
        <VirtualDeliveryMethodPageContainer vendorId={vendorId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/ach/new`} exact>
        <EditBankDeliveryMethodPageContainer vendorId={vendorId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/check/new/name`} exact>
        <EditCheckNameDeliveryMethodPageContainer vendorId={vendorId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/check/new/address`} exact>
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer vendorId={vendorId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/:deliveryMethodId`}>
        <DeliveryMethodEditRouter />
      </SmartRoute>
    </Switch>
  );
}
