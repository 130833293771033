const baseUrl = '/quickbooks-desktop/entry/:realmId';

const LOCATIONS = {
  base: [baseUrl],
  authError: `${baseUrl}/auth-error`,
  view: `${baseUrl}/view-payment`,
  pay: `${baseUrl}/pay`,
  sync: `${baseUrl}/sync`,
  dashboard: `${baseUrl}/dashboard`,
};

export default LOCATIONS;
