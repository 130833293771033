import { useSelector } from 'react-redux';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchItemType } from 'src/modules/regular-batch-payments/types/store-types';
import useGetValidFundingSources from '../table/hooks/useGetValidFundingSources';
import { useBatchSettings } from './useBatchSettings';
import { useSetFundingSourceForBillInBatch } from './useSetFundingSourceForBillInBatch';

export const useSelectFundingSourceForBatch = ({ batchItemId, applyToAllBills }) => {
  const batchItems: BatchItemType[] = useSelector(regularBatchPaymentsStore.selectors.allBills);

  const [validFundingSources] = useGetValidFundingSources();
  const hasFundingSource = !!validFundingSources.length;

  const { setFundingSourceForBillInBatch } = useSetFundingSourceForBillInBatch();
  const { changeBatchSettings } = useBatchSettings();

  const selectFundingSourceForBatchFlow = async ({ newFundingSourceId }) => {
    if (applyToAllBills) {
      await changeBatchSettings(
        { fundingSourceId: newFundingSourceId },
        { refreshEffectiveDate: true, refreshBillsList: true }
      );
    } else {
      await setFundingSourceForBillInBatch({
        batchItemId,
        newFundingSourceId,
      });
    }

    /**
     * hasFundingSource holds the value that was before setFundingSourceForBillInBatch
     * cause in React we will have new state only on next render
     * and only for new selectFundingSourceForBatchFlow execution
     */
    if (hasFundingSource) {
      return;
    }

    /**
     * adds funding source to other bills and triggers date cells update for these bills,
     * Reason: we have the same logic on batch table loading
     * - we set existed FS for all the bills without it.
     *  */
    batchItems.forEach((batchItem) => {
      if (batchItem.id === batchItemId) {
        return;
      }

      setFundingSourceForBillInBatch({
        batchItemId: batchItem.id,
        newFundingSourceId,
      });
    });
  };

  return { selectFundingSourceForBatchFlow };
};
