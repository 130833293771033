import React, { useCallback, useEffect, useState } from 'react';
import { ProgressIndicatorPage } from 'src/billpay/qbdt/pages/entry/ProgressIndicationPage';

import styled from 'styled-components';

import { useFrameMessage } from 'src/billpay/qbdt/helpers/useAuthFrame';
import { AuthSuccessPayload, AuthFailurePayload } from 'src/billpay/qbdt/helpers/useQbdtOAuth';

const APPCENTER_TIMEOUT_MS = 15000;
const title = 'entry.header';
const messages = [
  'entry.messages.quicklyPay',
  'entry.messages.payWithACH',
  'entry.messages.extendFloat',
  'entry.messages.scheduleBill',
];
export type AuthWithAppcenterPageProps = {
  appcenterUrl: string;
  onSuccess: (payload: AuthSuccessPayload) => void;
  onFailure: (payload: AuthFailurePayload) => void;
};

export type FrameSuccessMessage = AuthSuccessPayload & {
  success: true;
};
export type FrameFailureMessage = AuthFailurePayload & {
  success: false;
};
export type FrameCompleteMessage = FrameSuccessMessage | FrameFailureMessage;

export function AuthWithAppcenterPage({ appcenterUrl, onSuccess, onFailure }: AuthWithAppcenterPageProps) {
  const [showLoader, setShowLoader] = useState(true);
  const onMessage = useCallback(
    (data: FrameCompleteMessage) => {
      if (data.success) {
        const { success, ...payload } = data;
        onSuccess(payload);
      } else {
        onFailure({
          error: data.error,
        });
      }
    },
    [onSuccess, onFailure]
  );
  useEffect(() => {
    const timeout = setTimeout(() => setShowLoader(false), APPCENTER_TIMEOUT_MS);
    return () => clearTimeout(timeout);
  }, []);
  const [frameRef] = useFrameMessage<FrameCompleteMessage>(onMessage);

  return (
    <>
      {showLoader && <ProgressIndicatorPage title={title} messages={messages} progress={0.3} />}
      <AppcenterIframe
        src={appcenterUrl}
        ref={(ref: HTMLIFrameElement) => {
          frameRef.current = ref?.contentWindow || undefined;
        }}
        data-testid="appcenter-iframe"
      />
    </>
  );
}
const AppcenterIframe = styled.iframe`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
`;
