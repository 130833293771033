import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { RecordOf } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useFundingSourceMicroDepositState } from 'src/hooks/settings/useFundingSourceMicroDeposits';
import { BillType, AccountType, PaymentType } from 'src/utils/types';
import { MIFormattedCurrency, MIFormattedDate } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';
import { isCardExpired } from 'src/utils/card';
import { VerifyMicroDeposits } from 'src/billpay/qbdt/components/VerifyMicroDeposits';
import MINotificationCard from 'src/components/common/MINotificationCard';
import { useDebitFee } from 'src/billpay/qbdt/hooks/useDebitFee';
import { useAmexVerification } from 'src/billpay/qbdt/hooks/useAmexVerification';
import { useCreditCardBenefits } from 'src/billpay/qbdt/hooks/useCreditCardBenefits';
import { microDepositsEventActions, microDepositsEventPages } from 'src/components/micro-deposits/consts';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import {
  QBDTStepLayout,
  WizardButtonContainer,
  WizardStepActionsContainer,
  WizardStepSubTitle,
} from 'src/billpay/qbdt/components';
import QBOMISecurityDetails from 'src/components/common/QBOMISecurityDetails';
import { getPartialBillId } from 'src/utils/bills';
import { useLocationState } from 'src/utils/hooks';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import analytics from 'src/services/analytics';
import { withNavigator } from 'src/hoc';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { getBill, getPayment, getIsLoading, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { getFundingSources, getOriginPlaidItemId } from 'src/redux/user/selectors';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { getLatestPayment, isPaymentFailed } from 'src/utils/payments';
import { trackMicroDepositsEvent } from 'src/hooks/settings/microDepositsCommon';
import { PayBillProps, withPayBillData } from '../hoc/withPayBillData';

const eventPage = microDepositsEventPages.payBill.base;

type Props = PayBillProps;

const Page = ({ onNextFundingSources: onNext, goExit, goAddSelectedFundingSource }: Props) => {
  const dispatch = useDispatch();
  const [verifyingId, setVerifyingId] = useState<number | undefined>();
  const isLoading: boolean = useSelector(getIsLoading);
  const bill: BillType | null = useSelector(getBill);
  const payment: PaymentType = useSelector(getPayment);
  const originPlaidItemId: number | null = useSelector(getOriginPlaidItemId);
  const { vendor, id: billId, dueDate } = bill || {};
  const amount = payment?.amount || '';

  const vendorName = vendor?.companyName || '';

  const fundingSources: RecordOf<AccountType>[] = useSelector(getFundingSources);
  const selectedFundingSource: RecordOf<AccountType> | null = useSelector(getSelectedFundingSource);

  const onSelectingFundingSource = async (
    fundingSource: AccountType,
    withRedirect = false,
    analyticsProperties?: { source?: string }
  ) => {
    analytics.track(eventPage, 'change-funding-source', {
      fundingSourceId: fundingSource.id,
      source: 'fs-page',
      ...analyticsProperties,
    });
    dispatch(selectFundingSourceAction(fundingSource.id));

    return withRedirect && onNextFundingSource(fundingSource);
  };

  const onVerifyFinished = () => {
    setVerifyingId(undefined);
  };

  const onVerifyClicked = (id: number) => {
    trackMicroDepositsEvent(eventPage, microDepositsEventActions.VERIFY_CLICK, {
      fundingSourceID: id,
      partialBillId: getPartialBillId(bill!),
    });

    setVerifyingId(id);
  };

  const lastPayment = getLatestPayment(bill?.payments || []);
  const originFundingSourceId = lastPayment?.fundingSourceId;
  const fundingSourceNotChanged = originFundingSourceId === payment?.fundingSourceId;
  const samePlaidAccount =
    !originPlaidItemId || originPlaidItemId === payment?.fundingSource?.plaidAccount?.plaidItemId;

  const showWarning = isPaymentFailed(lastPayment) && fundingSourceNotChanged && samePlaidAccount;

  const [isRedirectedFromBatchFlow] = useLocationState('isRedirectedFromBatchFlow', false);
  const [billsExceedAmountLimitCount] = useLocationState('billsExceedAmountLimitCount', 0);
  const [isRedirectedFromBatchFlowCardShown, setIsRedirectedFromBatchFlowCardShown] = useState(
    isRedirectedFromBatchFlow
  );
  const [isBillsExceedAmountLimitShown, setIsBillsExceedAmountLimitShown] = useState(billsExceedAmountLimitCount > 0);
  const microDepositEventPage = microDepositsEventPages.payBill.base;

  const microDepositProps: any = {
    key: verifyingId,
    fundingSourceId: verifyingId,
    onVerifyFinished,
  };
  const [state, actions]: any = useFundingSourceMicroDepositState(microDepositEventPage, microDepositProps, {
    fundingSourceID: verifyingId ?? null,
    partialBillId: getPartialBillId(bill!),
  });

  useEffect(() => {
    if (isRedirectedFromBatchFlow) {
      analytics.track(eventPage, 'redirect-from-batch-flow-card-shown');
    }
  }, [isRedirectedFromBatchFlow]);

  const debitFee = useDebitFee();

  const { shouldDisplayAmexVerification, openAmexModal, amexLoading, renderAmexModal } = useAmexVerification();

  const onNextFundingSource = async (nextFundingSource?: AccountType) => {
    const fundingSource = nextFundingSource || selectedFundingSource;
    const hasDebitFeeModal = debitFee.shouldDisplayModal(fundingSource as RecordOf<AccountType>);

    if (hasDebitFeeModal) {
      debitFee.openModal();

      return;
    }

    const hasAmexModal = await shouldDisplayAmexVerification(
      fundingSource as RecordOf<AccountType>,
      Number(vendor?.id)
    );

    if (hasAmexModal) {
      openAmexModal({
        billIds: bill ? [Number(billId)] : [],
        vendorId: Number(vendor?.id),
        vendorName,
      });

      return;
    }

    onNext();
  };

  const handleContinueClick = () => {
    onNextFundingSource();
  };

  const renderFooter = () => (
    <FooterContainer>
      <QBOMISecurityDetails showIconCC />
      {!isEmpty(fundingSources) && (
        <WizardStepActionsContainer>
          <WizardButtonContainer isProgressBar width="22.5rem">
            <MIButton
              isProcessing={amexLoading}
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={handleContinueClick}
              label="progress.continue"
              disabled={!selectedFundingSource || amexLoading}
            />
          </WizardButtonContainer>
        </WizardStepActionsContainer>
      )}
    </FooterContainer>
  );

  const filteredFundingSources = fundingSources.filter(
    (fs) => !(fs.fundingType === 'card' && (!fs.isVerified || isCardExpired(fs.cardAccount)))
  );

  const handleRedirectedFromBatchFlowCardClose = () => {
    setIsRedirectedFromBatchFlowCardShown(false);
  };

  const handleBillsExceedLimitAmountCardClose = () => {
    setIsBillsExceedAmountLimitShown(false);
  };

  const { CreditCardBenefitsModal, openCreditCardBenefitsModal } = useCreditCardBenefits({
    eventPage,
    onSelectFundingSource: onSelectingFundingSource,
    onAddFundingSource: goAddSelectedFundingSource,
  });

  const handleViewBenefitsClick = (fundingSource?: AccountType) => {
    const analyticsProperties = {
      billId: bill?.id,
      vendorId: vendor?.id,
      partialBillId: getPartialBillId(bill!),
    };
    openCreditCardBenefitsModal(fundingSource, analyticsProperties);
  };

  if (isLoading || !billId) {
    return <QBDTLoader />;
  }

  return (
    <StyledQBDTStepLayout
      headerLabel="qbo.header.title"
      headerLabelValues={{
        amount: <MIFormattedCurrency value={amount} />,
        companyName: vendorName,
      }}
      title="bills.pay.fundingSource.title"
      subtitle="bills.pay.fundingSource.subtitle"
      goExit={goExit}
      relativeStep={1 / 5}
      isLoading={isLoading}
      footer={renderFooter()}
    >
      {showWarning && (
        <InfoNotificationCard
          type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
          title={{
            label: 'bills.status.paymentFailedActionRequired',
          }}
          subtitle={{
            label: 'bills.pay.fundingSource.warningMessage',
            values: {
              date: <MIFormattedDate date={dueDate} />,
            },
          }}
        />
      )}

      {isRedirectedFromBatchFlowCardShown && (
        <RedirectFromBatchFlowCard
          type={CONSTS.NOTIFICATION_CARD_TYPES.WARNING}
          title={{
            label: 'bills.pay.fundingSource.redirectedFromBatchCard.title',
          }}
          subtitle={{
            label: 'bills.pay.fundingSource.redirectedFromBatchCard.subtitle',
          }}
          onClose={handleRedirectedFromBatchFlowCardClose}
        />
      )}

      {isBillsExceedAmountLimitShown && (
        <BillExceedLimitAmountCard
          type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
          title={{
            label: 'bills.pay.fundingSource.billsExceedAmountLimitShown.title',
            values: {
              count: billsExceedAmountLimitCount,
            },
          }}
          subtitle={{
            label: 'bills.pay.fundingSource.billsExceedAmountLimitShown.subtitle',
          }}
          onClose={handleBillsExceedLimitAmountCardClose}
        />
      )}

      <FundingSourcesListLayout
        value={selectedFundingSource}
        fundingSources={filteredFundingSources}
        onChange={onSelectingFundingSource}
        onAddMethod={goAddSelectedFundingSource}
        onVerifyClicked={onVerifyClicked}
        debitFee={debitFee.fee}
        onViewBenefitsClick={handleViewBenefitsClick}
      />

      {verifyingId && (
        <VerifyMicroDeposits
          {...state}
          {...actions}
          key={verifyingId}
          verifyingId={verifyingId}
          bill={bill}
          onVerifyFinished={onVerifyFinished}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}

      <debitFee.Modal onNext={onNext} />
      {renderAmexModal({ onSubmit: onNext })}
      {CreditCardBenefitsModal}
    </StyledQBDTStepLayout>
  );
};

export const PayBillFundingSourcePage = compose(withNavigator(), withPayBillData())(Page);

const FooterContainer = styled.div`
  margin-top: 0rem;
`;

const InfoNotificationCard = styled(MINotificationCard)`
  margin-bottom: 2rem;
`;

const RedirectFromBatchFlowCard = styled(QBONotificationCard)`
  margin-bottom: 2rem;
`;

const BillExceedLimitAmountCard = styled(QBONotificationCard)`
  margin-bottom: 2rem;
`;

const StyledQBDTStepLayout = styled(QBDTStepLayout)`
  ${WizardStepSubTitle} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;
