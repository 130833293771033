import React from 'react';
import { HStack, Badge } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';
import { CheckDelaysIcon, CheckDelaysIconVariant } from 'src/components/common/CheckDelaysIcon';
import { FeatureFlags } from 'src/utils/feature-flags';
import { MIFormattedText } from 'src/utils/formatting';
import { PaymentDeliveryOptionTitle } from './PaymentDeliveryOptionTitle';
import { PaymentDeliveryOptionDescription } from './PaymentDeliveryOptionDescription';

type Props = {
  deliveryOption: DeliveryOptionType;
  isFast: boolean;
  fundingSourceType?: string;
  totalAmount: number;
  freeChecksAvailableCount: string | undefined;
  expeditedACHDeliveryDate?: Date;
};

const PaymentDeliveryOption = ({
  deliveryOption,
  isFast,
  fundingSourceType,
  totalAmount,
  freeChecksAvailableCount,
  expeditedACHDeliveryDate,
}: Props) => {
  const [isCheckDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);

  const shouldShowCheckDelaysWarning = isCheckDelaysEnabled && !isFast && deliveryOption.type === DELIVERY_TYPE.CHECK;

  return (
    <div>
      <HStack align="center" fontWeight="ds.semi">
        <PaymentDeliveryOptionTitle
          totalAmount={totalAmount}
          expeditedACHDeliveryDate={expeditedACHDeliveryDate}
          deliveryOption={deliveryOption}
          fundingSourceType={fundingSourceType}
        />
        {shouldShowCheckDelaysWarning && (
          <CheckDelaysIcon
            tooltipLabel="checkDelaysWarning.tooltipLabels.specificCheck"
            sx={{
              position: 'relative',
              marginLeft: 'ds.sm',
            }}
            variant={CheckDelaysIconVariant.WARNING}
          />
        )}
        {isFast && (
          <Badge variant="primaryOutlined" ml="ds.sm">
            <MIFormattedText label="bills.pay.date.deliveryOptions.price.fastTag" />
          </Badge>
        )}
      </HStack>

      <PaymentDeliveryOptionDescription
        totalAmount={totalAmount}
        freeChecksAvailableCount={freeChecksAvailableCount}
        deliveryOption={deliveryOption}
        fundingSourceType={fundingSourceType}
      />
    </div>
  );
};

export { PaymentDeliveryOption };
