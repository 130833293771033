import * as React from 'react';
import styled, { css } from 'styled-components';
import analytics from 'src/services/analytics';
import { devices } from 'src/theme/AppDevices';
import { MIFormattedText } from 'src/utils/formatting';

export type Option = {
  id: string;
  label: string;
  disabled?: boolean;
  icon?: string;
  hint?: string;
  info?: React.ReactNode;
  ribbon?: React.ReactNode;
};

type Props = {
  onSelect: (id: string) => void;
  categories: Option[];
  selectedId?: string | null;
  analyticsProps?: Record<string, any>;
};

const MICategoryList = ({ categories, onSelect, selectedId, analyticsProps }: Props) => {
  const onSelectCategory = (id) => {
    analytics.trackAction('select-category', {
      value: id,
      ...analyticsProps,
    });
    onSelect(id);
  };

  return (
    <ListContainer>
      {categories.map(({ id, label, icon, disabled, hint, info, ribbon }) => (
        <Label
          htmlFor={id}
          key={id}
          selected={id === selectedId}
          disabled={disabled}
          data-testid={`category-list-${id}`}
          itemIcon={icon}
        >
          {icon && (
            <IconContainer>
              <MethodOptionIcon className={icon} disabled={disabled} />
            </IconContainer>
          )}
          <Category selected={id === selectedId} disabled={disabled}>
            <Input type="radio" id={id} onClick={() => onSelectCategory(id)} disabled={disabled} />
            <MIFormattedText label={label} />
            {hint && (
              <HintContainer>
                <MIFormattedText label={hint} />
              </HintContainer>
            )}
          </Category>
          {ribbon && <RibbonContainer>{ribbon}</RibbonContainer>}
          {info && <InfoContainer>{info}</InfoContainer>}
        </Label>
      ))}
    </ListContainer>
  );
};

export default MICategoryList;

const Category = styled.div<{ disabled?: boolean; selected?: boolean }>`
  letter-spacing: 0;
  color: ${(props) => (props.disabled ? props.theme.text.color.label : props.theme.text.color.main)};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  ${(props) => props.theme.text.fontType.medium};
  @media ${devices.mobile}, ${devices.phablet} {
    ${(props) => props.theme.text.fontType.hint};
  }
`;

const Input = styled.input`
  display: none;
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${devices.mobile}, ${devices.phablet} {
    justify-content: space-between;
  }
`;

const Label = styled.label<{
  selected?: boolean;
  itemIcon?: string;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.itemIcon ? '2.6rem 3.5rem' : '2rem 3.5rem')};
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
  border: ${(props) => (props.selected ? `0.2rem solid ${props.theme.colors.brand}` : '0.2rem solid transparent')};
  border-radius: 0.8rem;
  box-sizing: border-box;
  box-shadow: 0 5px 10px 0 rgba(33, 33, 36, 0.15);
  width: 21.5rem;
  max-width: 21.5rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin-bottom: 2rem;
  transition: ${(props) => props.theme.animation.transition.default};
  &:nth-child(even) {
    margin-left: 1.5rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    &:nth-child(even) {
      margin-left: 0rem;
    }
    width: 48%;
    max-width: 48%;
    padding: ${(props) => (props.itemIcon ? '2.4rem 2.5rem' : '1rem 1rem')};
  }

  ${(props) =>
    props.itemIcon &&
    css`
      flex-direction: column;
      align-items: center;
    `};

  ${(props) =>
    !props.selected &&
    !props.disabled &&
    css`
      &:hover {
        box-shadow: ${(props) => `0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2}`};
      }
    `};
`;

const IconContainer = styled.div`
  display: flex;
  margin-bottom: 0.4rem;
`;

const HintContainer = styled.div`
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  @media ${devices.mobile} {
    white-space: normal;
  }
`;

const MethodOptionIcon = styled.i<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? props.theme.text.color.label : props.theme.colors.icons.card)};
  font-size: 2.4rem;
`;

const RibbonContainer = styled.div`
  margin-top: 0.8rem;
`;

const InfoContainer = styled.div`
  margin-top: 0.8rem;
`;
