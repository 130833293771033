import React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';

type Props = {
  label: string;
  labelValues?: Record<string, any>;
  className?: string;
  variant: string;
  isSecondary?: boolean;
  testId?: string;
};

const MITag = ({ label, labelValues = {}, className, variant, isSecondary, testId }: Props) => (
  <TagContainer data-testid={testId} variant={variant} isSecondary={isSecondary} className={className}>
    <MIFormattedText label={label} values={labelValues} />
  </TagContainer>
);

export default MITag;

const tagColors = css`
  ${({ variant, theme, isSecondary }: any) => {
    switch (variant) {
      case CONSTS.TAG_VARIANT.ERROR:
        return `
          background-color: ${isSecondary ? theme.colors.failure.translucent1 : theme.colors.failure.opaque};
          color: ${isSecondary ? theme.colors.failure.opaque : theme.colors.white.opaque};
          border: 1px solid ${theme.colors.failure.opaque};
        `;
      case CONSTS.TAG_VARIANT.WARNING:
        return `
          background-color: ${isSecondary ? theme.colors.warning.translucent1 : theme.colors.warning.opaque};
          color: ${isSecondary ? theme.colors.warning.opaque : theme.colors.white.opaque};
          border: 1px solid ${theme.colors.warning.opaque};
        `;
      case CONSTS.TAG_VARIANT.DISABLED:
        return `
          background-color: ${isSecondary ? theme.colors.border.halfDarkGrey : theme.text.color.light};
          color: ${isSecondary ? theme.text.color.light : theme.colors.white.opaque};
          border: 1px solid ${theme.text.color.light};
        `;
      case CONSTS.TAG_VARIANT.EXPIRED:
        return `
          background-color: ${theme.colors.expired.translucent2};
          color: ${theme.colors.expired.opaque};
          border: 1px solid ${theme.colors.expired.opaque};
        `;
      case CONSTS.TAG_VARIANT.SUCCESS:
        return `
          background-color: ${isSecondary ? theme.colors.teal.translucent1 : theme.colors.teal.opaque};
          color: ${!isSecondary ? theme.colors.white.opaque : theme.colors.teal.opaque};
          border: 1px solid ${theme.colors.teal.opaque};
        `;
      case CONSTS.TAG_VARIANT.DEPOSITED:
        return `
          background-color: ${theme.colors.teal.translucent2};
          color: ${theme.colors.white.opaque};
        `;
      case CONSTS.TAG_VARIANT.ACH_DEPOSITED:
        return `
            background-color: ${theme.colors.teal.translucent2};
            color: ${theme.colors.white.opaque};
          `;
      case CONSTS.TAG_VARIANT.LIGHT:
        return `
          background-color: ${theme.colors.primary.translucent1};
          color: ${theme.colors.brand};
        `;
      case CONSTS.TAG_VARIANT.DARK:
        return `
          background-color: ${theme.colors.text};
          color: ${theme.colors.white.opaque};
          border: 1px solid ${theme.colors.text};
        `;
      case CONSTS.ROLE.CONTRIBUTOR:
        return `
          background-color: ${isSecondary ? theme.colors.primary.translucent2 : theme.colors.brand};
          color: ${isSecondary ? theme.colors.primary.opaque : theme.colors.white.opaque};
          border: 1px solid ${isSecondary ? theme.colors.primary.translucent2 : theme.colors.brand};
        `;
      case CONSTS.TAG_VARIANT.BRAND:
      default:
        return `
          background-color: ${isSecondary ? theme.colors.primary.translucent2 : theme.colors.brand};
          color: ${isSecondary ? theme.colors.primary.opaque : theme.colors.white.opaque};
          border: 1px solid ${theme.colors.brand};
        `;
    }
  }}
`;

const TagContainer = styled.span<{ variant: string; isSecondary?: boolean }>`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 0 0.45rem;
  border-radius: 3px;
  text-align: center;
  background-clip: padding-box;
  ${(props) => props.theme.text.fontType.small};
  ${tagColors}
  ${(props) => props.theme.components?.MITag?.TagContainer}
`;
