import { qbdtWindowsSite } from 'src/sites/qbdtWindowsSite';
import merge from 'lodash/merge';
import { CONSTS } from 'src/utils/consts';
import { qbdt } from '../config';

export const qbdtMacSite = merge({}, qbdtWindowsSite, {
  name: 'qbdt',
  config: qbdt,
  createOrigin: {
    pay: {
      payment: CONSTS.PAYMENT_CREATE_FLOW_ORIGIN.QBDT_MAC,
      payor: CONSTS.PAYMENT_CREATE_FLOW_ORIGIN.QBDT_MAC,
    },
  },
  registrationOrigin: CONSTS.REGISTRATION_ORIGIN.QBDT_MAC,
});
