import {
  ValidationResponse,
  validateDateOfBirth as validateDateOfBirthCompliance,
  validateBusinessType as validateBusinessTypeCompliance,
  validateBusinessIndustry as validateIndustryTypeCompliance,
  validateContactFirstName as validateContactFirstNameCompliance,
  validateContactLastName as validateContactLastNameCompliance,
  validateLegalCompanyName as validateLegalCompanyNameCompliance,
  validateContactPhone as validateContactPhoneCompliance,
  validateTaxIdType as validateTaxIdTypeCompliance,
  validateTaxId as validateTaxIdCompliance,
  validateCompanyBusinessAddress as validateCompanyBusinessAddressCompliance,
  FieldValidationError,
  NaicsIndustrySourceEnum,
  TaxIdTypeEnum,
} from '@melio/compliance-validator';
import { isValid } from 'date-fns';
import { GoogleCombinedAddressType, TaxIdType } from './types';
import { getGoogleAddress } from './address';

export const MASKS = {
  dateOfBirth: [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // i.e 12-30-1995 (MM-DD-YYYY)
  taxId: {
    [TaxIdTypeEnum.Ein]: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], // i.e 12-3456789,
    [TaxIdTypeEnum.Ssn]: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // i.e 123-45-6789,
    [TaxIdTypeEnum.Itin]: [/9/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // i.e 923-45-6781,
    any: [/./, /./, /./, /./, /./, /./, /./, /./, /./, /./], // i.e 123456789 | XXXXXX123,
  },
};

export const getTaxIdPlaceholder = (type?: TaxIdType) =>
  type
    ? `bills.pay.confirm.completeLegalInfo.taxId.placeholders.${type}`
    : `bills.pay.confirm.completeLegalInfo.taxId.placeholders.default`;

const calculateErrorMessage = ({ isValid, errors }: ValidationResponse) => {
  if (!isValid) {
    const error = errors?.find((error) => Object.values(FieldValidationError).includes(error));

    if (error) {
      return `mtl.risk.validations.${error}`;
    }
  }

  return 'mtl.risk.validations.Invalid';
};

export type ComplianceValidationErrors = {
  dateOfBirth?: string;
  businessType?: string;
  companyIndustry?: string;
  contactFirstName?: string;
  contactLastName?: string;
  legalCompanyName?: string;
  phone?: string;
  taxIdType?: string;
  taxId?: string;
};

export const validateDateOfBirth = (dateOfBirth: Date | null, shouldSkip?: boolean) => {
  const errors: ComplianceValidationErrors = {};

  if (shouldSkip) {
    return errors;
  }

  if (!dateOfBirth) {
    errors.dateOfBirth = 'inputErrors.companyInfo.dateOfBirth.required';
  } else if (!isValid(dateOfBirth)) {
    errors.dateOfBirth = 'inputErrors.companyInfo.dateOfBirth.notCorrectDate';
  }

  if (isValid(dateOfBirth)) {
    const { isValid, errors: dateOfBirthErrors } = validateDateOfBirthCompliance(dateOfBirth);
    if (dateOfBirthErrors) {
      errors.dateOfBirth = calculateErrorMessage({ isValid, errors: dateOfBirthErrors });
    }
  }

  return errors;
};

export const validateBusinessType = (businessType: string | null | undefined) => {
  if (!businessType) {
    return { businessType: 'inputErrors.companyInfo.businessType.required' };
  }

  const { isValid, errors: businessTypeErrors } = validateBusinessTypeCompliance(businessType);

  if (businessTypeErrors) {
    return { businessType: calculateErrorMessage({ isValid, errors: businessTypeErrors }) };
  }

  return {};
};

export const validateIndustryType = (naicsCode: number | null | undefined, shouldSkip?: boolean) => {
  if (shouldSkip) {
    return {};
  }

  if (!naicsCode) {
    return { companyIndustry: 'inputErrors.companyInfo.companyIndustry.required' };
  }

  const { isValid, errors: industryErrors } = validateIndustryTypeCompliance([
    { naicsCode, source: NaicsIndustrySourceEnum.USER_PROVIDED },
  ]);

  if (industryErrors) {
    return { companyIndustry: calculateErrorMessage({ isValid, errors: industryErrors }) };
  }

  return {};
};

export const validateContactFirstName = (contactFirstName: string | null | undefined) => {
  const { isValid, errors: contactFirstNameErrors } = validateContactFirstNameCompliance(contactFirstName);

  if (contactFirstNameErrors) {
    return { contactFirstName: calculateErrorMessage({ isValid, errors: contactFirstNameErrors }) };
  }

  return {};
};

export const validateContactLastName = (contactLastName: string | null | undefined) => {
  const { isValid, errors: contactLastNameErrors } = validateContactLastNameCompliance(contactLastName);

  if (contactLastNameErrors) {
    return { contactLastName: calculateErrorMessage({ isValid, errors: contactLastNameErrors }) };
  }

  return {};
};

export const validateLegalCompanyName = (legalCompanyName: string | null | undefined) => {
  const { isValid, errors: legalCompanyNameErrors } = validateLegalCompanyNameCompliance(legalCompanyName);

  if (legalCompanyNameErrors) {
    return { legalCompanyName: calculateErrorMessage({ isValid, errors: legalCompanyNameErrors }) };
  }

  return {};
};

export const validateContactPhone = (phoneNumber: string | null | undefined) => {
  const { isValid, errors: legalContactPhoneErrors } = validateContactPhoneCompliance(phoneNumber);

  if (legalContactPhoneErrors) {
    return { phone: calculateErrorMessage({ isValid, errors: legalContactPhoneErrors }) };
  }

  return {};
};

export const validateTaxIdType = (taxIdType: TaxIdTypeEnum | null | undefined) => {
  const { isValid, errors: taxIdTypeErrors } = validateTaxIdTypeCompliance(taxIdType);

  if (taxIdTypeErrors) {
    return { taxIdType: calculateErrorMessage({ isValid, errors: taxIdTypeErrors }) };
  }

  return {};
};

export const validateTaxId = (
  businessType: string | null | undefined,
  taxIdType: TaxIdTypeEnum | null | undefined,
  taxId: string | null | undefined
) => {
  const { isValid, errors: taxIdErrors } = validateTaxIdCompliance(businessType, taxIdType, taxId);

  if (taxIdErrors) {
    return { taxId: calculateErrorMessage({ isValid, errors: taxIdErrors }) };
  }

  return {};
};

export const validateCompanyBusinessAddress = (
  googleAddress: GoogleCombinedAddressType,
  isLegal?: boolean,
  shouldSkip?: boolean
) => {
  if (shouldSkip) {
    return {};
  }

  const address = convertAddressForComplianceCheck(googleAddress);
  const { isValid, errors: addressErrors } = validateCompanyBusinessAddressCompliance(address, isLegal);

  if (addressErrors) {
    return isLegal
      ? { legalAddress: calculateErrorMessage({ isValid, errors: addressErrors }) }
      : { companyAddress: calculateErrorMessage({ isValid, errors: addressErrors }) };
  }

  return {};
};

const convertAddressForComplianceCheck = (googleAddress: GoogleCombinedAddressType) => {
  const address = getGoogleAddress(googleAddress);
  return {
    addressLine1: address.addressLine1,
    city: address.cityComponent ? address.cityComponent.long_name : address.cityFromString,
    state: address.stateComponent ? address.stateComponent.short_name : null,
    zipCode: address.zipComponent ? address.zipComponent.long_name : null,
    countryCode: address.countryCodeComponent ? address.countryCodeComponent.short_name : null,
  };
};
