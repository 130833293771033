import React, { useEffect } from 'react';
import analytics from 'src/services/analytics';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { isPaymentDeletable } from 'src/utils/payments';
import { getPartialBillId } from 'src/utils/bills';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';
import { useRetryPayment } from '../hooks/useRetryPayment';
import { ActionButtonsWrapper } from './ActionButtonsWrapper';
import { useCancelPayment } from '../hooks/useCancelPayment';

const eventPage = 'bill';

export const FailedToCollectActions = () => {
  const { payment, bill } = useViewPaymentContext();
  const { openPaymentCancelModal, cancelPaymentModal } = useCancelPayment();
  const { onRetryPayment } = useRetryPayment();
  const isDeletable = isPaymentDeletable(payment);

  useEffect(() => {
    analytics.track(eventPage, 'ChangePaymentMethodOption-Shown', {
      paymentId: payment.id,
      paymentStatus: payment.status,
      billId: bill.id,
      partialBillId: getPartialBillId(bill),
      deliveryMethod: payment.deliveryMethodId,
      flow: 'retry-failed-payment',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isDeletable) {
    return (
      <ActionButtonsWrapper align="center">
        <MIButton
          label="bills.actions.retryPayment"
          testId="view-payment-change-payment-method-action"
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={onRetryPayment}
        />
      </ActionButtonsWrapper>
    );
  }

  return (
    <>
      {cancelPaymentModal}
      <ActionButtonsWrapper>
        <MIButton
          label="bills.actions.retryPayment"
          testId="view-payment-change-payment-method-action"
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={onRetryPayment}
          fullWidth
        />
        <MIButton
          label="bills.form.paymentActivity.actions.cancel"
          testId="view-payment-cancel-payment-action"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={openPaymentCancelModal}
          fullWidth
        />
      </ActionButtonsWrapper>
    </>
  );
};
