import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import countBy from 'lodash/countBy';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { PaymentType } from 'src/utils/types';
import { name, actionName } from 'src/modules/regular-batch-payments/consts';
import { mapFailedPaymentsData, getBatchItemByBillIds } from 'src/modules/regular-batch-payments/utils';
import { fetchBatchBillsListSlice } from 'src/modules/regular-batch-payments/regular-batch-bills-list-slice';
import api from 'src/modules/regular-batch-payments/api/regularBatchPayments';
import { CARD_PROVIDERS } from 'src/utils/consts';
import { CreateBatchValidationErrorType } from './types/store-types';

export type CreateBatchPaymentType = {
  payments: PaymentType[];
  failedBills: any;
  orgId: string;
};

export const batchHashFunc = (ids: string[]): string => [...ids].sort().join(',');
const createActionName = 'createBatch';

export const CREATE_BATCH_PAYMENTS_ACTION_NAME = `[${actionName}] CREATE`;

export const createBatchPaymentSlice = createApiCallSlice<CreateBatchPaymentType, any>({
  name: CREATE_BATCH_PAYMENTS_ACTION_NAME,
  api: (createBatchParams) => api.createBatchBulkPayments(createBatchParams),
  initialState: {
    byId: {},
    createBatch: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      const ids = action.payload.payments.map((p) => p.id);
      const key = batchHashFunc(ids);

      state[createActionName][key] = { loading: true };
    },
    [ON_SUCCESS]: (state, action) => {
      const ids = action.meta.identifier.payments.map((p) => p.id);
      const key = batchHashFunc(ids);

      state[createActionName][key] = {
        loading: false,
        scheduled: action.payload.payments,
        failedPayments: action.payload.failedBills.map((payment) => {
          const batchItem = getBatchItemByBillIds(state, payment.billIds);

          return mapFailedPaymentsData(batchItem!, payment.error.code);
        }),
      };
    },
    [ON_FAILURE]: (state, action) => {
      const ids = action.meta.identifier.payments.map((p) => p.id);
      const key = batchHashFunc(ids);

      state[createActionName][key] = {
        loading: false,
        error: action.error,
      };
    },
    [fetchBatchBillsListSlice.actions.success]: (state) => {
      state.createBatch = {};
    },
  },
  async validate(payload): Promise<CreateBatchValidationErrorType> {
    const validationErrors: Record<string, any> = payload.payments.reduce((prev, payment) => {
      if (!payment.deliveryMethodId) {
        prev[payment.bill.id] = 'deliveryMethodMissed';
      }

      if (!payment.fundingSourceId) {
        prev[payment.bill.id] = 'fundingSourceMissed';
      }

      if (payment.cardAccountNetwork === CARD_PROVIDERS.AMEX && !payment.vendor?.mccCode) {
        prev[payment.bill.id] = 'vendorMCCMissed';
      }

      return prev;
    }, {});

    if (!isEmpty(validationErrors)) {
      validationErrors.validationFailReasons = countBy(Object.values(validationErrors));
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  },
  selectors: {
    createBatch: (ids) => (state) => {
      const stateHash = state[name]?.[createActionName]?.[batchHashFunc(ids)];
      const isCreating = stateHash?.loading;
      const error = stateHash?.error;
      const scheduledPayments = stateHash?.scheduled;
      const scheduledPaymentsCount = scheduledPayments?.length;
      const scheduledPaymentsAmount = sumBy(scheduledPayments, 'amount');
      const failedPayments = stateHash?.failedPayments;
      const failedPaymentsCount = stateHash?.failedPayments?.length;
      const firstScheduledPaymentBillId = stateHash?.scheduled ? stateHash?.scheduled[0]?.billId : '';
      return {
        isCreating,
        error,
        scheduledPayments,
        scheduledPaymentsCount,
        scheduledPaymentsAmount,
        failedPayments,
        failedPaymentsCount,
        firstScheduledPaymentBillId,
      };
    },
  },
});
