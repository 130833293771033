import React from 'react';
import { Row } from 'react-table';
import { BatchBillType } from 'src/modules/regular-batch-payments/types/store-types';
import { DataTableRowType, MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import { Override } from 'src/utils/types';

const isSubRow = (depth) => depth > 0;

export type MainRowType = Override<Row<DataTableRowType>, { original: MainTableRowType }>;

export type SubRowType = Override<Row<DataTableRowType>, { original: BatchBillType }>;

// react-table expect to get same type for main rows and sub rows, so we need to override the types.
export const withCellWrapper = (
  Component: ({
    row,
    rowHovered,
  }: {
    row: MainRowType;
    rowHovered: boolean;
  }) => React.ReactElement<{
    row: MainRowType;
    rowHovered: boolean;
  }>,
  SubComponent?: ({
    row,
    rowHovered,
  }: {
    row: SubRowType;
    rowHovered: boolean;
  }) => React.ReactElement<{ row: SubRowType; rowHovered: boolean }>
) => ({ row, rowHovered }: { row: Row<DataTableRowType>; rowHovered: boolean }) =>
  !isSubRow(row.depth) ? (
    <Component row={row as MainRowType} rowHovered={rowHovered} />
  ) : (
    (SubComponent && <SubComponent row={row as SubRowType} rowHovered={rowHovered} />) || null
  );
