import { useModal } from 'src/helpers/react/useModal';
import { AccountType } from 'src/utils/types';
import { CARD_TYPES } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import { CreditCardBenefitsModal as CreditCardBenefitsDialog } from './CreditCardBenefitsModal';

type Props = {
  eventPage: string;
  onSelectFundingSource?: (
    fundingSource: AccountType,
    withRedirect?: boolean,
    analyticsProperties?: { source?: string }
  ) => void;
  onAddFundingSource?: (fundingSourceType: string) => void;
};

const useCreditCardBenefits = ({ eventPage, onSelectFundingSource, onAddFundingSource }: Props) => {
  const handlePayByCardClick = (fundingSource?: AccountType) => {
    analytics.track(eventPage, 'cc-benefits-modal-cta-click');

    if (fundingSource?.id) {
      onSelectFundingSource && onSelectFundingSource(fundingSource, true, { source: 'view-benefits' });
    } else {
      onAddFundingSource && onAddFundingSource(CARD_TYPES.CREDIT);
    }
  };

  const [CreditCardBenefitsModal, showCreditCardBenefitsModal] = useModal(CreditCardBenefitsDialog, {
    hasCTA: !!onAddFundingSource,
    onPayByCardClick: handlePayByCardClick,
  });

  const openCreditCardBenefitsModal = (
    fundingSource?: AccountType,
    analyticsProperties?: { billId?: string; vendorId?: string; partialBillId?: string }
  ) => {
    analytics.track(eventPage, 'cc-view-benefits-click');

    showCreditCardBenefitsModal({ fundingSource, eventPage, analyticsProperties });
  };

  return {
    CreditCardBenefitsModal,
    openCreditCardBenefitsModal,
  };
};

export { useCreditCardBenefits };
