import { Tooltip } from '@melio/billpay-design-system';
import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  tooltip?: string;
  children: React.ReactNode;
};

export const MainActionWrapper = ({ tooltip, children }: Props) => {
  const label = tooltip || <MIFormattedText label={tooltip} />;

  return (
    <Tooltip label={label} isDisabled={!tooltip}>
      {children}
    </Tooltip>
  );
};
