import React from 'react';
import { RecordOf } from 'immutable';
import { AccountType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { PaymentInfoSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSection';
import { PaymentInfoSectionDivider } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSectionDivider';
import { ConfirmPaymentDeductionDateInfo } from './fields/ConfirmPaymentDeductionDateInfo';
import { ConfirmPaymentFundingSourceInfo } from './fields/ConfirmPaymentFundingSourceInfo/ConfirmPaymentFundingSourceInfo';
import { ConfirmPaymentDeliveryDateInfo } from '../common-fields/ConfirmPaymentDeliveryDateInfo/ConfirmPaymentDeliveryDateInfo';
import { ActivityActionsType } from '../../types';

type Props = {
  bill: RecordOf<BillType>;
  fundingSource: RecordOf<AccountType>;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
} & Pick<ActivityActionsType, 'onEditFundingSource' | 'onEditDate'>;

export const ConfirmPayFromSection = ({
  bill,
  fundingSource,
  payment,
  deliveryMethod,
  onEditFundingSource,
  onEditDate,
}: Props) => {
  const isSharedVendor = bill.isVendorRequest();

  return (
    <PaymentInfoSection label="payBillPaymentActivity.payFromTitle">
      <ConfirmPaymentFundingSourceInfo
        bill={bill}
        payment={payment}
        fundingSource={fundingSource}
        onEdit={onEditFundingSource}
      />
      <PaymentInfoSectionDivider />
      <ConfirmPaymentDeductionDateInfo bill={bill} payment={payment} onEdit={onEditDate} />
      {isSharedVendor && (
        <>
          <PaymentInfoSectionDivider />
          <ConfirmPaymentDeliveryDateInfo
            bill={bill}
            payment={payment}
            deliveryMethod={deliveryMethod}
            fundingSource={fundingSource}
          />
        </>
      )}
    </PaymentInfoSection>
  );
};
