import React, { useState } from 'react';
import { RecordOf } from 'immutable';
import { chakra } from 'src/theme/ds';
import { MIFormattedText } from 'src/utils/formatting';
import { isManualBankAccountNotVerified, canVerifyManualBankAccount } from 'src/utils/funding-sources';
import { BILL_STATUS, CARD_TYPES, FUNDING_TYPE } from 'src/utils/consts';
import { AccountType, BillType } from 'src/utils/types';
import { useFundingSourceMicroDepositState } from 'src/hooks/settings/useFundingSourceMicroDeposits';
import VerifyMicroDeposits from 'src/components/micro-deposits/VerifyMicroDeposits';
import MIInlineLink from 'src/components/common/MIInlineLink';
import analytics from 'src/services/analytics';
import { microDepositsEventPages } from 'src/components/micro-deposits/consts';
import { PaymentInfoHint } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoHint';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import { getPartialBillId } from 'src/utils/bills';
import FundingSourceIcon from './FundingSourceIcon';
import FundingSourceDescription from './FundingSourceDescription';
import { getBillPaidColor } from '../../../utils/getBillPaidColor';

type Props = {
  fundingSource: RecordOf<AccountType>;
  bill: BillType;
};

export const ViewPaymentFundingSourceInfo = ({ bill, fundingSource }: Props) => {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const microDepositEventPage = microDepositsEventPages.payBill.base;
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [microDepositState, microDepositActions] = useFundingSourceMicroDepositState(
    microDepositEventPage,
    {
      fundingSourceId: fundingSource.id,
    },
    {
      fundingSourceID: fundingSource.id,
      partialBillId: getPartialBillId(bill),
    }
  );
  let label = 'viewBillPaymentActivity.fundingSource.achLabel';

  if (fundingSource.fundingType === FUNDING_TYPE.CARD) {
    label =
      fundingSource.cardAccount?.cardType === CARD_TYPES.CREDIT
        ? 'viewBillPaymentActivity.fundingSource.creditCardLabel'
        : 'viewBillPaymentActivity.fundingSource.debitCardLabel';
  }

  let hintValues = {};
  let hint = '';
  if (canVerifyManualBankAccount(fundingSource)) {
    hint = 'viewBillPaymentActivity.fundingSource.unverifiedBankAccountHintWithAction';
    hintValues = {
      verifyLink: (...chunks) => (
        <VerifyAccountLink
          text={React.Children.toArray(chunks) as any}
          onClick={(event) => {
            analytics.track('bill-payment', 'verify-manual-account-click');
            event.preventDefault();
            setShowVerifyDialog(true);
          }}
        />
      ),
    };
  } else if (isManualBankAccountNotVerified(fundingSource)) {
    hint = 'viewBillPaymentActivity.fundingSource.unverifiedBankAccountHint';
  }

  const paymentMethodHeaderLabel = (
    <>
      {fundingSource.nickname && `${fundingSource.nickname} | `}
      <MIFormattedText label={label} />
    </>
  );

  return (
    <>
      {showVerifyDialog && (
        <VerifyMicroDeposits
          {...microDepositState}
          {...microDepositActions}
          key={fundingSource.id}
          onVerifyFinished={() => {
            setShowVerifyDialog(false);
          }}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}
      <PaymentFieldContainer>
        <PaymentFieldIcon color={getBillPaidColor(isBillPaid)}>
          <FundingSourceIcon fundingSource={fundingSource} />
        </PaymentFieldIcon>
        <PaymentFieldInfo>
          <PaymentFieldLabel data-testid="billpay-view-payment-method">{paymentMethodHeaderLabel}</PaymentFieldLabel>
          <PaymentFieldContent color={getBillPaidColor(isBillPaid)} data-testid="billpay-view-payment-info-text">
            <FundingSourceDescription fundingSource={fundingSource} />
            {!isBillPaid && hint && (
              <PaymentInfoHint testId="billpay-view-payment-info-hint" hint={hint} hintValues={hintValues} />
            )}
          </PaymentFieldContent>
        </PaymentFieldInfo>
      </PaymentFieldContainer>
    </>
  );
};

const VerifyAccountLink = chakra(MIInlineLink, {
  baseStyle: {
    p: '1rem',
    m: '-1rem',
    height: 'inherit',
  },
});
