import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { theme as chakraTheme } from '@melio/billpay-design-system';
import oldChakraTheme from 'src/theme/old-ds/theme/qboTheme';
import { qbdtThemeExtension } from 'src/billpay/qbdt/theme-extension/qbdtThemeExtension';
import defaultSite from './defaultSite';
import { qbo } from '../config';
import { theme } from '../theme/qboTheme';
import { qboPrivateRoutes, qboPublicRoutes, qboBillRoutes } from '../pages/quickbooks/route';
import enTranslations from '../locale/en-US.qbo';
import * as WizardElements from '../components/layout/QBOWizardElements';
import QBOMISecurityDetails from '../components/common/QBOMISecurityDetails';
import Loader from '../pages/quickbooks/components/QuickbooksLoader';
import Dialog from '../components/common/QBOMIDialog';
import { CONSTS } from '../utils/consts';
import QboFloatedButtons from '../components/bill/QboFloatedButtons';

delete chakraTheme.components.Popover;
const combinedChakraTheme = merge(oldChakraTheme, chakraTheme, qbdtThemeExtension);

const qboSite = merge({}, omit(defaultSite, ['chakraTheme']), {
  name: 'qbo',
  config: qbo,
  theme,
  chakraTheme: combinedChakraTheme,
  routes: {
    public: {
      qboPublic: qboPublicRoutes,
    },
    authenticated: {
      orgs: {
        bills: qboBillRoutes,
        requests: null,
        vendor: null,
        vendorCreate: null,
      },
      qboPrivate: qboPrivateRoutes,
    },
  },
  messages: {
    'en-US': enTranslations['en-US'],
  },
  createOrigin: {
    pay: {
      payor: CONSTS.REGISTRATION_FLOW.QBO_INTEGRATION,
      payment: CONSTS.PAYMENT_CREATE_FLOW_ORIGIN.QBO_INTEGRATION,
    },
  },
  registrationOrigin: CONSTS.REGISTRATION_ORIGIN.QUICKBOOKS,
  components: {
    WizardElements,
    MISecurityDetails: QBOMISecurityDetails,
    Loader,
    Dialog,
    FloatedButtons: QboFloatedButtons,
  },
  title: 'Bill Pay services',
  icon: 'qbo-logo16x16.ico',
});

export default qboSite;
