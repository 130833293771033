import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import { encodeQuery } from 'src/utils/query-utils';
import { useHistory, generatePath } from 'react-router-dom';

type HistoryTypeParams = {
  path: string;
  params?: Record<string, string | number>;
  state?: Record<string, unknown>;
  query?: Record<string, string | number>;
};

const useHistoryWithOrgId = (withOrgId = true, params?: Record<string, string | number>) => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const pushParams = useMemo(() => {
    if (withOrgId) {
      return { ...params, orgId };
    }

    return params;
  }, [params, withOrgId, orgId]);

  const convertToHistoryLocation = useCallback(
    ({ path, params, state, query }: HistoryTypeParams) => ({
      pathname: generatePath(path, { ...pushParams, ...params }),
      state,
      search: encodeQuery(query || {}, [], ''),
    }),
    [pushParams]
  );
  const historyPush = (params: HistoryTypeParams) => history.push(convertToHistoryLocation(params));
  const historyReplace = (params: HistoryTypeParams) => history.replace(convertToHistoryLocation(params));

  return [historyPush, historyReplace];
};

export default useHistoryWithOrgId;
