import * as React from 'react';
import styled from 'styled-components';
import LoadingOverlay from './LoadingOverlay';
import { MIFormattedText } from '../../utils/formatting';

const PageContainer = styled.div`
  width: calc(100% - 9rem);
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 6rem;
`;

type Props = {
  children?: React.ReactNode;
  title?: string;
  isLoading?: boolean;
};

/**
 * @deprecated TODO: delete
 */
const Page = ({ title, isLoading, children }: Props) => (
  <LoadingOverlay isLoading={!!isLoading}>
    <PageContainer>
      {title && (
        <h2>
          <MIFormattedText label={title} />
        </h2>
      )}
      {children}
    </PageContainer>
  </LoadingOverlay>
);

export default Page;
