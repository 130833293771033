import React, { ReactNode, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import isString from 'lodash/isString';
import analytics from 'src/services/analytics';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';
import {
  ButtonContainer,
  ContentContainer,
  DockContainer,
  DescriptionContainer,
  FooterContainer,
  CloseButtonWrapper,
  ModalContentContainer,
  Icon,
  ModalContainer,
  ModalWrapper,
  SubTitleContainer,
  TitleContainer,
  Illustration,
  Backdrop,
} from './ModalMessage.styles';

type Props = {
  id?: string;
  image?: string | ReactNode;
  icon?: string;
  titleComponent?: ReactNode;
  contentComponent?: ReactNode;
  buttonComponent?: ReactNode;
  buttonLabel?: string;
  analyticsProperties?: Record<string, any>;
  onButtonClick?: () => any;
  secondaryButtonComponent?: ReactNode;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  onCloseClick?: (event: MouseEvent) => void;
  isLoading?: boolean;
  alignLeft?: boolean;
  descriptionComponent?: ReactNode;
  footerSubTitle?: ReactNode;
  contentSubTitle?: ReactNode;
  footerComponent?: ReactNode;
  isMobileFullSize?: boolean;
  largeSize?: boolean;
  dockedFooter?: boolean;
  className?: string;
};

export const ModalMessage = ({
  id,
  image = '',
  titleComponent,
  contentComponent,
  buttonLabel,
  analyticsProperties,
  isMobileFullSize = true,
  onButtonClick,
  onCloseClick,
  isLoading,
  descriptionComponent,
  footerSubTitle,
  contentSubTitle = '',
  footerComponent,
  buttonComponent,
  alignLeft,
  secondaryButtonComponent,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  largeSize,
  icon,
  dockedFooter,
  className,
}: Props) => {
  const doOnClose = (event) => {
    onCloseClick && onCloseClick(event);
    analytics.trackAction(`modal-close${id ? `-${id}` : ''}`);
  };

  return ReactDOM.createPortal(
    <ModalWrapper data-testid={id} className={className}>
      <Backdrop onClick={doOnClose} />
      <ModalContainer largeSize={largeSize} isMobileFullSize={isMobileFullSize} data-testid="modal-message-container">
        <ModalContentContainer>
          {onCloseClick && (
            <CloseButtonWrapper onClick={doOnClose} data-testid="modal-close">
              <i className="icon-close-icon" />
            </CloseButtonWrapper>
          )}

          {image && (isString(image) ? <Illustration src={image} /> : image)}

          {icon && <Icon src={icon} />}

          {titleComponent && <TitleContainer alignLeft={alignLeft}>{titleComponent}</TitleContainer>}

          {descriptionComponent && <DescriptionContainer>{descriptionComponent}</DescriptionContainer>}

          {contentSubTitle && <SubTitleContainer>{contentSubTitle}</SubTitleContainer>}

          {contentComponent && <ContentContainer>{contentComponent}</ContentContainer>}

          {footerSubTitle && <SubTitleContainer>{footerSubTitle}</SubTitleContainer>}

          {!dockedFooter && footerComponent && <FooterContainer>{footerComponent}</FooterContainer>}

          {buttonComponent ||
            (buttonLabel && onButtonClick && (
              <ButtonContainer>
                <MIButton
                  onClick={onButtonClick}
                  label={buttonLabel}
                  variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                  analyticsProperties={analyticsProperties}
                  isProcessing={isLoading}
                  fullWidth
                />
              </ButtonContainer>
            ))}

          {secondaryButtonComponent ||
            (secondaryButtonLabel && onSecondaryButtonClick && (
              <ButtonContainer>
                <MIButton
                  onClick={onSecondaryButtonClick}
                  label={secondaryButtonLabel}
                  variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                  analyticsProperties={analyticsProperties}
                  isProcessing={isLoading}
                  fullWidth
                />
              </ButtonContainer>
            ))}
        </ModalContentContainer>
        {dockedFooter && footerComponent && (
          <DockContainer isMobileFullSize={isMobileFullSize}>{footerComponent}</DockContainer>
        )}
      </ModalContainer>
    </ModalWrapper>,
    document.querySelector('body')
  );
};
