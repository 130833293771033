import React from 'react';
import ReactDOM from 'react-dom';
import { chakra } from '@chakra-ui/react';
import { Flex, Text, Box } from '@melio/billpay-design-system';
import { useBreak } from 'src/hoc';
import { MIFormattedText } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';

type Props = {
  onCloseModal: () => void;
};

export const ValidateDebitCardDetailsErrorModal = ({ onCloseModal }: Props) => {
  const { isDesktop } = useBreak();

  return ReactDOM.createPortal(
    <Overlay onClick={onCloseModal}>
      <ModalContainer w={isDesktop ? '57.6rem' : '34.3rem'} p={isDesktop ? 'ds.2xl' : 'ds.xl'}>
        {isDesktop && (
          <CloseButtonContainer onClick={onCloseModal}>
            <i className="icon-close-icon" />
          </CloseButtonContainer>
        )}
        <TextContainer mb={isDesktop ? 'ds.2xl' : 'ds.xl'}>
          <Text textStyle="ds.h5" m="0">
            <MIFormattedText label="vendor.pushToDebit.addDebitCard.validationError.title" />
          </Text>
          <Text textStyle="ds.body1" m="0">
            <MIFormattedText label="vendor.pushToDebit.addDebitCard.validationError.text" />
          </Text>
        </TextContainer>
        <Flex justify="center" w="100%">
          <MIButton
            label="vendor.pushToDebit.addDebitCard.validationError.button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            fullWidth={!isDesktop}
            onClick={onCloseModal}
          />
        </Flex>
      </ModalContainer>
    </Overlay>,
    document.querySelector('body')
  );
};

const Overlay = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'ds.gray.200',
    zIndex: 10000,
    position: 'fixed',
    left: 0,
    top: 0,
  },
});

const ModalContainer = chakra(Box, {
  baseStyle: {
    position: 'relative',
    backgroundColor: 'ds.white',
    h: 'fit-content',
    borderRadius: 'ds.lg',
    boxSizing: 'border-box',
  },
});

const CloseButtonContainer = chakra(Box, {
  baseStyle: {
    cursor: 'pointer',
    position: 'absolute',
    top: 'ds.xl',
    right: 'ds.xl',
    color: 'ds.gray.300',
    textStyle: 'ds.h5',
  },
});

const TextContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    gap: 'ds.md',
  },
});
