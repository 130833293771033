import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, HStack, Tabs } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { useDashboardListParams } from './hooks/useDashboardListParams';
import { useDashboardFTUMessage } from './components/DashboardFTUMessage/useDashboardFTUMessage';
import { useDashboardListItems } from './components/DashboardTab/hooks/useDashboardListItems';
import { DashboardTabStatusEnum, TABS } from './consts';
import { DashboardTab } from './components/DashboardTab/DashboardTab';
import { DashboardHeader } from './components/DashboardHeader/DashboardHeader';
import { DashboardFooter } from './components/DashboardFooter/DashboardFooter';
import { DashboardSearchInput } from './components/DashboardSearchInput/DashboardSearchInput';
import { dashboardStore } from './redux-store/dashboard-store';
import { useEditVendorModal } from './hooks/useEditVendorModal';
import { useSyncBillsStart } from './sync/hooks/useSyncBillsStart';
import { useLoadDashboardList } from './hooks/useLoadDashboardList';
import { useUnpaidTabListPollingOnSync } from './sync/hooks/useUnpaidTabListPollingOnSync';
import { SyncIndicator } from './sync/components/SyncIndicator';
import { useSinglePayFlowSuccess } from './hooks/useSinglePayFlowSuccess';
import { useBatchPayFlowSuccess } from './hooks/useBatchPayFlowSuccess';
import { DashboardTableVariantNotification } from './components/DashboardTableVariantNotification/DashboardTableVariantNotification';
import { useIsBillsSyncing } from './sync/hooks/useIsBillsSyncing';
import { useOnChangeTab } from './hooks/useOnChangeTab';
import { DashboardFilter, useDashboardFilter } from './components/DashboardFilter';

export function DashboardPage() {
  const [DashboardFTUMessage] = useDashboardFTUMessage();
  const [listParams, { setStatus }] = useDashboardListParams();
  const { totalCount } = useDashboardListItems(listParams);
  const selectedItemsIds = useSelector(dashboardStore.selectors.dashboardSelection.selectedIds);
  const isOpenFooter = selectedItemsIds.length > 0;

  const isBillsSyncing = useIsBillsSyncing();

  const { loadDashboardList } = useLoadDashboardList();

  const { SinglePayFlowSuccess, NotifyVendorModal, NotifyVendorSuccessModal } = useSinglePayFlowSuccess();
  const { BatchPayFlowSuccess, CollectVendorEmailsModal } = useBatchPayFlowSuccess();

  const { handleFiltersChange, filterMenuItemsList } = useDashboardFilter();

  const [EditVendorModal] = useEditVendorModal();

  useEffect(() => {
    loadDashboardList(listParams);
  }, [listParams]);

  const { onChangeTab } = useOnChangeTab({
    status: listParams.filters.status,
    setStatus,
  });

  useSyncBillsStart();
  useUnpaidTabListPollingOnSync();

  const shouldHideTableVariantNotification =
    listParams.filters.status === DashboardTabStatusEnum.Unpaid || totalCount === 0;

  return (
    <Flex flexDir="column" h="full">
      <Box paddingBottom={isOpenFooter ? '5rem' : 0}>
        <Tabs variant="line" index={TABS.indexOf(listParams.filters.status)} onChange={onChangeTab} isLazy>
          <Box bg="white" position="sticky" top="0" zIndex="2">
            <DashboardHeader />
            <Tabs.TabList m="1rem 2rem 0">
              <Tabs.Tab data-testid="dashboard-tab-unpaid" eventName="tab-event-unpaid">
                <MIFormattedText label="paymentDashboard.tabs.unpaid" />
              </Tabs.Tab>
              <Tabs.Tab data-testid="dashboard-tab-scheduled" eventName="tab-event-scheduled">
                <MIFormattedText label="paymentDashboard.tabs.scheduled" />
              </Tabs.Tab>
              <Tabs.Tab data-testid="dashboard-tab-paid" eventName="tab-event-paid">
                <MIFormattedText label="paymentDashboard.tabs.paid" />
              </Tabs.Tab>
            </Tabs.TabList>
            <DashboardSearchInput />
          </Box>
          <HStack spacing="ds.lg" mt="ds.xl" px="ds.xl">
            <DashboardFilter
              currentTabStatus={listParams.filters.status}
              filterMenuItems={filterMenuItemsList}
              onChange={handleFiltersChange}
            />
            {isBillsSyncing && <SyncIndicator />}
          </HStack>

          <DashboardTableVariantNotification hidden={shouldHideTableVariantNotification} />

          <Tabs.TabPanels m="1rem 2rem 0" w="auto">
            <Tabs.TabPanel>
              <DashboardTab status={DashboardTabStatusEnum.Unpaid} />
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <DashboardTab status={DashboardTabStatusEnum.Scheduled} />
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <DashboardTab status={DashboardTabStatusEnum.Paid} />
            </Tabs.TabPanel>
          </Tabs.TabPanels>
        </Tabs>
        <DashboardFooter isOpen={isOpenFooter} />
      </Box>
      {DashboardFTUMessage}
      {EditVendorModal}
      {SinglePayFlowSuccess}
      {NotifyVendorModal}
      {NotifyVendorSuccessModal}
      {BatchPayFlowSuccess}
      {CollectVendorEmailsModal}
    </Flex>
  );
}
