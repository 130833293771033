import React from 'react';
import { getJWTPayload } from 'src/helpers/jwt';
import styled from 'styled-components';
import { useBreak } from 'src/hoc';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import { MIFormattedText } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import { InlineTextInputField } from 'src/ui/form/InlineTextInputField';
import { useSiteContext } from 'src/hoc/withSiteContext';
import qbLogo from 'src/images/accounting-software/quickbooks-logo-horz.png';
import exclamationMark from 'src/images/qbo/exclamation-mark-icon.svg';
import { CONSTS } from 'src/utils/consts';
import { devices } from 'src/theme/AppDevices';
import { useShiftVendorToACHState } from 'src/pages/vendor/shift-vendor-to-ach/hoc/shift-vendor-to-ach-hooks';
import { SubmitButtonMobile } from 'src/pages/vendor/components/VendorLayoutElements';

type Props = {
  token: string;
  vendorId: string;
};

const QBOAddDeliveryMethodPage = ({ vendorId, token }: Props) => {
  const { isMobile } = useBreak();
  const site = useSiteContext();
  const { orgId } = getJWTPayload(token);
  const [submit, deliveryMethodMV, isLoading, editMode] = useShiftVendorToACHState(vendorId, orgId, token);
  const submitLabel = editMode
    ? 'vendors.deliveryMethods.ach.achDetails.buttonEdit'
    : 'vendors.deliveryMethods.ach.achDetails.buttonSave';
  return (
    <QBOLayoutPage contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <Content>
        <Title>
          <MIFormattedText label="vendors.deliveryMethods.ach.achDetails.title" />
        </Title>

        <SubTitle>
          <MIFormattedText label="vendors.deliveryMethods.ach.achDetails.subTitle" />
        </SubTitle>

        <Separator />

        <DialogTitle>
          <MIFormattedText label="vendors.deliveryMethods.ach.achDetails.formTitle" />
        </DialogTitle>

        <FormContainer onSubmit={submit}>
          <RowContainer>
            <InlineTextInputField
              label="vendors.addDeliveryMethodByLink.routingNumber"
              model={deliveryMethodMV?.bankAccount.routingNumber}
              required
              autoFocus
            />
            <InlineTextInputField
              label="vendors.addDeliveryMethodByLink.accountNumber"
              model={deliveryMethodMV?.bankAccount.accountNumber}
              required
            />
          </RowContainer>
        </FormContainer>

        {isMobile ? (
          <SubmitButtonMobile label={submitLabel} submit={submit} isLoading={isLoading} />
        ) : (
          <MIButton
            label={submitLabel}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={submit}
            isProcessing={isLoading}
            fullWidth
          />
        )}
        <Info>
          <ExclamationMark src={exclamationMark} />
          <InfoText>
            <MIFormattedText
              label="vendors.deliveryMethods.ach.achDetails.info"
              values={{
                termsOfService: (
                  <SupportLink href={site.config.agreementLinks.termsOfService} target="_blank">
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.termsOfService" />
                  </SupportLink>
                ),
                privacyPolicy: (
                  <SupportLink href={site.config.agreementLinks.privacyPolicy} target="_blank">
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.privacyPolicy" />
                  </SupportLink>
                ),
                intuitPrivacyPolicy: (
                  <SupportLink href={site.config.agreementLinks.intuitPrivacyPolicy} target="_blank">
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.intuitPrivacyPolicy" />
                  </SupportLink>
                ),
              }}
            />
          </InfoText>
        </Info>
      </Content>
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
  margin-bottom: 4.2rem;
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const Separator = styled.div`
  height: 0.4rem;
  background-color: ${(props) => props.theme.background.wizard};
  margin: 2rem 0 4rem;
  width: 100%;
  margin: 3rem 0;
`;

const Title = styled.div`
  font-size: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
`;

const DialogTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 2rem;
  margin-bottom: 2rem;
`;

const Info = styled.div`
  display: flex;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
  padding-top: 2rem;
`;

const InfoText = styled.div`
  text-align: left;
  line-height: ${(props) => props.theme.text.lineHeight.hint};
`;

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
`;

const ExclamationMark = styled.img`
  width: 2rem;
  margin-right: 1.2rem;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0.5rem;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  > * {
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    > * {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 2rem;
      flex: 1;
    }
  }
`;

const RowContainer = styled(FormRow)`
  align-items: flex-start;

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
  }
`;

export default QBOAddDeliveryMethodPage;
