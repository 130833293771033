import { useState, useEffect, useRef } from 'react';

export const DEFAULT_MINIMUM_VISIBILITY_TIME = 400; // in ms

type Params = {
  visible: boolean;
  minimumVisibilityTime?: number; // in ms
};

export const useMinimumVisibilityTime = ({
  minimumVisibilityTime = DEFAULT_MINIMUM_VISIBILITY_TIME,
  visible,
}: Params) => {
  const [internalVisible, setInternalVisible] = useState(visible);
  const visibilityTimeoutExceeded = useRef(false);
  const visibleExternal = useRef(visible);
  const timeoutsSet = useRef<Set<number>>(new Set<number>());

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const externalBecameVisible = visible;
    visibleExternal.current = visible;

    if (externalBecameVisible) {
      visibilityTimeoutExceeded.current = false;
      setInternalVisible(true);

      const visibilityTimeout = window.setTimeout(() => {
        visibilityTimeoutExceeded.current = true;

        const timeoutExceededAfterExternalHide = !visibleExternal.current;
        timeoutExceededAfterExternalHide && setInternalVisible(false);
      }, minimumVisibilityTime);

      timeoutsSet.current.add(visibilityTimeout);

      return;
    }

    const externalHideAfterTimeoutExceeded = visibilityTimeoutExceeded.current;

    if (externalHideAfterTimeoutExceeded) {
      setInternalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // clear timeouts on unmount
  useEffect(
    () => () => {
      [...timeoutsSet.current].forEach((timeout) => {
        clearTimeout(timeout);
      });
    },
    []
  );

  return internalVisible;
};
