const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'right',
    pr: '2.4rem',
  },
};

const selectedStyle = {
  '&[data-is-selected=true]': {
    bg: 'ds.gray.800',
  },
};

export const qbdtBatchVariant = {
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderSpacing: 0,
    tableLayout: 'fixed',
    overflow: 'hidden',
    width: 'full',
    borderBottomLeftRadius: 'ds.lg',
    borderBottomRightRadius: 'ds.lg',
    color: 'ds.gray.100',
    borderBottom: 'none',
    position: 'relative',
    borderCollapse: 'collapse',
    overflowY: 'inherit',
    tr: {
      cursor: 'pointer',
      th: {
        textStyle: 'ds.body3Semi',
        minHeight: 'ds.lg',
        display: 'table-cell',
        px: 'ds.lg',
        position: 'sticky',
        top: 0,
        '>div': {
          display: 'flex',
          h: '4.9rem',
          px: 0,
          alignItems: 'center',
          color: 'ds.gray.100',
          '>div': {
            border: 'none',
            color: 'ds.black',
            textTransform: 'none',
          },
        },
        '&:first-of-type,&:last-of-type': {
          px: 0,
        },
        '&[data-is-numeric=true]': {
          pr: 'ds.sm',
        },
      },
      td: {
        borderColor: 'ds.gray.600',
        color: 'ds.gray.100',
        borderBottomWidth: '1px',
        display: 'inline-flex',
        h: '4.4rem',
        px: 'ds.lg',
        alignItems: 'center',
        '>div': {
          color: 'ds.black',
          flex: 1,
          overflow: 'hidden',
        },
        '&:first-of-type,&:last-of-type': {
          p: 0,
        },
        '&[data-is-numeric=true]': {
          pr: 'ds.sm',
        },
      },
    },
    '&.isChildRow': {
      bg: 'ds.gray.800',
      height: 10,
      td: {
        height: 10,
      },
      _hover: {
        bg: 'ds.gray.700',
      },
    },
  },
  thead: {
    borderTopLeftRadius: 'ds.lg',
    borderTopRightRadius: 'ds.lg',
    borderColor: 'ds.gray.600',
    tr: {
      bg: 'ds.white',
      height: '4.4rem',
      position: 'sticky',
      top: 0,
      zIndex: 7,
      th: {
        color: 'ds.gray.100',
      },
      '&:first-of-type': {
        borderTopLeftRadius: 'ds.lg',
        borderTopRightRadius: 'ds.lg',
        border: 'none',
      },
    },
  },
  th: {
    textAlign: 'left',
    textTransform: 'capitalize',
    borderBottom: '1px',
    borderColor: 'ds.gray.600',
    '&[data-is-numeric=true]': {
      '> div': {
        justifyContent: 'flex-end',
      },
    },
  },
  tbody: {
    background: 'none',
    tr: {
      border: 'none',
      bg: 'ds.white',
      height: '4.4rem',
      '&:last-of-type': {
        borderBottomLeftRadius: 'ds.lg',
        borderBottomRightRadius: 'ds.lg',
        overflow: 'hidden',
        td: {
          borderBottom: '1px',
          borderColor: 'ds.gray.600',
          '&:last-of-type': {
            borderBottomRightRadius: 'ds.lg',
          },
          '&:first-of-type': {
            borderBottomLeftRadius: 'ds.lg',
          },
        },
      },
      ...selectedStyle,
      td: {
        textStyle: 'ds.body3',
      },
      _hover: {
        borderColor: 'ds.gray.600',
        bg: 'ds.gray.800',
        '&:last-of-type': {
          td: {
            '&:last-of-type': {
              borderBottomRightRadius: 'ds.lg',
            },
            '&:first-of-type': {
              borderBottomLeftRadius: 'ds.lg',
            },
          },
        },
      },
    },
  },
  tr: {},
  td: {
    textAlign: 'left',
    borderBottom: '1px',
    borderColor: 'ds.gray.600',
    ...numericStyles,
  },
  tfoot: {
    tr: {
      '&:last-of-type': {
        th: { borderBottomWidth: 'none' },
      },
    },
  },
  caption: {
    mt: 'ds.lg',
    textAlign: 'center',
    borderBottomLeftRadius: 'ds.lg',
    borderBottomRightRadius: 'ds.lg',
    borderBottom: '1px',
    borderLeft: '1px',
    borderRight: '1px',
    borderColor: 'ds.gray.600',
  },
};
