import React from 'react';
import { Box, Icon, Menu, Text } from '@melio/billpay-design-system';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { ReactComponent as ThreeDotsIcon } from 'src/images/settings/three-dots-horizontal-icon.svg';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  actions: ActionOption[];
  testId?: string;
};

export const ThreeDotsMenu = ({ actions, testId }: Props) => (
  <Menu placement="bottom-end" size="sm" autoSelect={false} autoWidth>
    <Menu.MenuButton
      data-testid={testId}
      as={Box}
      height="100%"
      sx={{
        '& > span': {
          height: 'ds.xl',
        },
      }}
    >
      <Icon as={ThreeDotsIcon} w="ds.xl" h="ds.xl" />
    </Menu.MenuButton>
    <Menu.MenuList minWidth="17rem" data-testid="menu-list">
      {actions.map(({ label, action }) => (
        <Menu.MenuItem as={Box} onClick={action} w="auto" key={label} data-testid="menu-item">
          <Text fontWeight="ds.regular">
            <MIFormattedText label={label} />
          </Text>
        </Menu.MenuItem>
      ))}
    </Menu.MenuList>
  </Menu>
);
