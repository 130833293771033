import React from 'react';
import { featureFlags } from '@melio/shared-web';

import { CONSTS } from 'src/utils/consts';
import { MIFormattedCurrency } from 'src/utils/formatting';
import { isCheckFeeOpen, featureFlagValues as checkFlagValues } from 'src/billpay/qbdt/hooks/useCheckFee/utils';
import {
  OptionalDeliveryMethodsType,
  organizationFee,
  CardTypes,
  FeeVariants,
  PaymentFeeItem,
  DeliveryOptionType,
} from 'src/utils/types';
import { FeatureFlags } from 'src/utils/feature-flags';

export const hasNewFeeApi = ({
  deliveryType,
  fundingSourceType,
}: {
  deliveryType?: OptionalDeliveryMethodsType;
  fundingSourceType?: string | undefined;
}) => {
  const checkFeeFlag = featureFlags.defaultClient.getVariant(FeatureFlags.CheckFees, checkFlagValues.CLOSED);

  if (deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL) return true;

  if (isCheckFeeOpen(checkFeeFlag) && deliveryType === CONSTS.DELIVERY_TYPE.CHECK) return true;

  if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT) return true;

  return false;
};

export const calculateTotalFees = (fees: Record<string, any>[]) => fees?.reduce((total, fee) => total + fee?.amount, 0);

export const defaultFeeObject = {
  finalAmount: 0,
  feeStructure: {
    value: 0,
  },
  promotion: {},
};

// extact final fee amount (including symbol e.g $1.5, 2.9%)
export const getCompleteFeeByDeliveryType = (
  fees: Record<string, any> | Record<string, any>[] | null,
  deliveryType?: OptionalDeliveryMethodsType | CardTypes | FeeVariants,
  existingFeeItem?: any
): string | React.ReactNode => {
  // fees are not loaded yet
  if (!fees) return undefined;

  const feeValues = !Array.isArray(fees) ? Object.values(fees) : fees;
  const feeItem = existingFeeItem || feeValues.find((fee: organizationFee) => fee?.feeType === deliveryType);

  // selected fee is not in the list
  if (!feeItem?.value) return undefined;

  const { valueType, value = '' } = feeItem;

  if (value === '0' || value === 0) return undefined;

  if (valueType === 'percent') return `${value}%`;

  return <MIFormattedCurrency value={value} />;
};

// get fee object from catalog by delivery type
export const getFeeFromCatalog = (fees: Record<string, any>, deliveryType: OptionalDeliveryMethodsType | CardTypes) =>
  fees?.find((fee) => fee?.feeCatalog?.feeType === deliveryType);

export const isAchCheckFeeType = (feeType: string | undefined) => feeType === CONSTS.FEE_VARIANTS.ACH_TO_CHECK;

const updateFeeObject = (fee: any = {}) => ({
  feeType: fee.feeType || fee.feeCatalog?.feeType || '',
  amount: fee.amount,
  isFixed: fee.isFixed || fee.feeCatalog?.valueType === 'fixed',
  percent: fee.percent,
});

export const mapCalendarFees = (deliveryOptions: DeliveryOptionType[]): any => {
  const result = deliveryOptions.map((dm: DeliveryOptionType) => {
    const fee = dm.fee?.find((fee: PaymentFeeItem) => {
      // check has a different feeType, ach-to-check
      if (dm.type === CONSTS.DELIVERY_TYPE.CHECK && isAchCheckFeeType(fee?.feeCatalog?.feeType)) return true;

      return fee.feeType === dm.type;
    });

    const newDm = { ...dm };
    let feeObject = {};

    if (fee) feeObject = updateFeeObject(fee);

    return { ...newDm, ...feeObject };
  });

  return result;
};

export const mapBatchFees = (fees: PaymentFeeItem[]) =>
  fees?.map((fee: PaymentFeeItem) => {
    // ach-to-check response is not always the same as previous fees
    if (isAchCheckFeeType(fee?.feeCatalog?.feeType)) return updateFeeObject(fee);

    // standard fee object
    return fee;
  });
