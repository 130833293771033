import { useCallback } from 'react';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { SelectedBillsResponse, syncBill } from 'src/billpay/qbdt/services/qbdt';
import { BATCH_MAX_LIMIT } from 'src/utils/consts';
import { BillType } from 'src/utils/types';

type FailedToSyncBill = {
  txnId: string;
  errorMessage: string;
};

export const useSyncSelectedBills = () => {
  const orgId = useOrgId();

  const syncSelectedBills = useCallback(
    async (selectedBills: SelectedBillsResponse) => {
      const { entities } = selectedBills;
      const selectedBillsTxnIds = entities.map(({ txnId }) => txnId);
      if (selectedBillsTxnIds.length === 0) {
        return {
          syncedBills: [],
          failedToSyncBills: [],
        };
      }

      const syncedBills: BillType[] = [];
      const failedToSyncBills: FailedToSyncBill[] = [];
      const limitedSelectedBillsTxnIds = Array.from(selectedBillsTxnIds).slice(0, BATCH_MAX_LIMIT);

      for (const txnId of limitedSelectedBillsTxnIds) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const syncedBill = await syncBill({ orgId, billTxnId: txnId });
          syncedBills.push(syncedBill);
        } catch (e) {
          const errorMessage = e instanceof Error ? e.message : 'Unknown error';
          failedToSyncBills.push({ txnId, errorMessage });
        }
      }

      return { syncedBills, failedToSyncBills };
    },
    [orgId]
  );
  return { syncSelectedBills };
};
