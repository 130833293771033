import filter from 'lodash/filter';
import merge from 'lodash/merge';
import api from 'src/services/api/bills';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { dashboardStore } from 'src/billpay/qbdt/pages/dashboard/redux-store/dashboard-store';
import regularListSlice from './regular-list-slice';
import setClassificationSlice from './set-classification-slice';
import markAsPaidSlice from './mark-as-paid-slice';

import { name } from './consts';
import batchMarkAsPaidSlice from './batch-mark-as-paid-slice';

const billsStore = createRestfulSlice({
  name,
  api: {
    list: (params) => api.getBills(params).then((res) => ({ items: res.objects, totalCount: res.totalCount })),
  },
  slices: {
    setClassification: setClassificationSlice,
    regularList: regularListSlice,
    markAsPaid: markAsPaidSlice,
    batchMarkAsPaid: batchMarkAsPaidSlice,
  },
  selectors: {
    byId: (id) => (state) => state[name]?.byId[id],
    getByIds: (ids) => (state) => filter(state[name].byId, (bill) => ids.includes(bill.id)) || [],
  },
  extraReducers: {
    [regularBatchPaymentStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        // TODO: remove this once 'qbdt-bulk-payment' is gone.
        if (item.bill) {
          state.byId[item.bill.id] = merge(state.byId[item.bill.id] || {}, item.bill);
        } else {
          item.payment.bills.forEach((bill) => {
            state.byId[bill.id] = merge(state.byId[bill.id] || {}, bill);
          });
        }
      });
    },
    [dashboardStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        item.bills.forEach((bill) => {
          state.byId[bill.id] = merge(state.byId[bill.id] || {}, bill);
        });
      });
    },
  },
});

export default billsStore;
