import React, { PropsWithChildren } from 'react';
import { Box } from '@melio/billpay-design-system';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';

type SmLinkProps = {
  href: string;
  target: string;
};

const SmLink = ({ href, target, children }: PropsWithChildren<SmLinkProps>) => (
  <Box as="a" href={href} color="blue.100" target={target}>
    {children}
  </Box>
);

export const LandingPageModalFooter = () => {
  const site = useSiteContext();

  return (
    <Box bgColor="#FBFBFD" color="#717173" fontSize="0.7rem" lineHeight="1.1rem" textAlign="center" p="3">
      <MIFormattedText
        label="entry.batch.landingPage.footer"
        values={{
          melio: (
            <SmLink href="http://www.meliopayments.com" target="_blank">
              <MIFormattedText label="qbo.firstTimeNew.melio" />
            </SmLink>
          ),
          privacyPolicy: (
            <SmLink href={site.config.agreementLinks.privacyPolicy} target="_blank">
              <MIFormattedText label="qbo.firstTimeNew.privacyPolicy" />
            </SmLink>
          ),
          termsOfService: (
            <SmLink href={site.config.agreementLinks.termsOfService} target="_blank">
              <MIFormattedText label="qbo.firstTimeNew.termsOfService" />
            </SmLink>
          ),
        }}
      />
    </Box>
  );
};
