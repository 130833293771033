import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryOptionType } from 'src/utils/types';
import { DeliveryDate } from 'src/utils/dates';
import { DeliveryWrapper } from './DeliveryWrapper';

type Props = {
  deliveryDate: Date;
  maxDeliveryDate: Date | null;
  deliveryOptions: DeliveryOptionType[];
  label: string;
  values: Record<string, any>;
};

const DeliveryRegularView = ({ deliveryOptions, deliveryDate, maxDeliveryDate, label, values }: Props) => (
  <DeliveryWrapper deliveryOptions={deliveryOptions}>
    <Box textStyle="ds.body1Semi" color="ds.gray.100" mt="ds.md">
      <DeliveryDate date={deliveryDate} maxDate={maxDeliveryDate} />
    </Box>
    <Box textStyle="ds.body3" color="ds.gray.200">
      <MIFormattedText label={label} values={values} />
    </Box>
  </DeliveryWrapper>
);

export { DeliveryRegularView };
