import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { zendeskService } from 'src/services/zendesk';
import MIInlineLink from 'src/components/common/MIInlineLink';
import { close } from 'src/billpay/qbdt/services/qbdt';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import { useLocationState } from 'src/utils/hooks';
import { NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import useBatchBillsList from './hooks/useBatchBillsList';

const PayBatchSuccessPage = () => {
  const site = useSiteContext();

  const { billsList } = useBatchBillsList();
  const billsListIds = billsList.map((bill) => bill.id);
  const [isSyncPaymentsFailed] = useLocationState('isSyncPaymentsFailed', false);
  const createBatchPaymentData = useSelector(
    regularBatchPaymentsStore.selectors.createBatchPayment.createBatch(billsListIds)
  );

  useEffect(() => {
    if (isSyncPaymentsFailed) {
      analytics.trackAction('sync-failed-notification-shown');
    }
  }, [isSyncPaymentsFailed]);

  const goExit = () => {
    close();
  };

  const textValues = {
    totalPayments: (
      <strong>
        {' '}
        <MIFormattedText
          label="batchPayment.success.total"
          values={{
            totalPayedBills: createBatchPaymentData.scheduledPaymentsCount,
          }}
        />
      </strong>
    ),
    total: (
      <strong>
        <MIFormattedCurrency value={createBatchPaymentData.scheduledPaymentsAmount} />
      </strong>
    ),
    email: (
      <div>
        <MIInlineLink
          text={site.config.support.email}
          onClick={() => {
            zendeskService.show();
          }}
        />
      </div>
    ),
  };

  const notification = {
    label: isSyncPaymentsFailed ? 'batchPayment.success.syncFailedNotification' : 'batchPayment.success.notification',
  };
  const notificationTitle = {
    label: isSyncPaymentsFailed
      ? 'batchPayment.success.syncFailedNotificationTitle'
      : 'batchPayment.success.notificationTitle',
  };
  const notificationType = isSyncPaymentsFailed ? NOTIFICATION_CARD_TYPES.WARNING : NOTIFICATION_CARD_TYPES.INFO;

  return (
    <QBDTSuccessLayout
      illustration={site.theme?.pages?.PayBillSuccessPage?.successImage}
      title="batchPayment.success.title"
      text="batchPayment.success.subTitle"
      textValues={textValues}
      buttonLabel="batchPayment.success.buttonLabel"
      buttonAction={goExit}
      goExit={goExit}
      notification={notification}
      notificationTitle={notificationTitle}
      notificationType={notificationType}
      hideHeader
    />
  );
};

export default PayBatchSuccessPage;
