import React from 'react';
import { VStack } from '@melio/billpay-design-system';
import { Item } from './Item';

const elements = [
  { label: 'paymentDashboard.ftuModal.items.view' },
  { label: 'paymentDashboard.ftuModal.items.manage' },
  { label: 'paymentDashboard.ftuModal.items.schedule' },
];

const Elements = () => (
  <VStack spacing="ds.md" align="flex-start">
    {elements.map(({ label }) => (
      <Item key={label} label={label} />
    ))}
  </VStack>
);

export { Elements };
