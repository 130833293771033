import React from 'react';
import { Flex, Text } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

export const PageTitle = () => (
  <Flex direction="column" gap="ds.sm">
    <Text textStyle="ds.h5" color="ds.gray.100" m="0">
      <MIFormattedText label="vendor.pushToDebit.virtualCard.learnMore.title" />
    </Text>
    <Text textStyle="ds.body2" color="ds.gray.100" m="0">
      <MIFormattedText label="vendor.pushToDebit.virtualCard.learnMore.description" />
    </Text>
  </Flex>
);
