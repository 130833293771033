import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import every from 'lodash/every';
import { Route, RouteComponentProps, RouteChildrenProps } from 'react-router-dom';
import { Location } from 'history';
import authStore from 'src/modules/auth/auth-store';
import { useSiteContext } from 'src/hoc/withSiteContext';

function getNotAllowedRoutingAction(site, notAllowedComponent) {
  if (notAllowedComponent) {
    return notAllowedComponent;
  }

  return <site.theme.pages.NotFoundPage />;
}
function siteDisablesRoute(site, path) {
  const paths = [].concat(path);
  return every(paths, (p) => site.routes[p] === null);
}

export type GuardedRouteType = {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  render?: (router: RouteComponentProps<any>) => React.ReactNode;
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
  path?: string | Array<string>;
  exact?: boolean;
  strict?: boolean;
  location?: Location;
  sensitive?: boolean;
  allowFor?: string | string[];
  notAllowedComponent?: React.ReactElement<any>;
  allowCallbackFn?: () => boolean;
};
export const SmartRoute = memo((props: GuardedRouteType) => {
  const site = useSiteContext();
  const authType = useSelector(authStore.selectors.userAuthType);
  const { allowFor, notAllowedComponent, allowCallbackFn, ...rest } = props;
  let allowed = true;
  if (siteDisablesRoute(site, props.path)) {
    allowed = false;
  } else if (allowFor) {
    let allowedResult: string[] = [];
    allowedResult = allowedResult.concat(allowFor);
    allowed = allowedResult.includes(authType);
  }

  if (allowCallbackFn) {
    allowed = allowed && allowCallbackFn();
  }

  return allowed ? <Route {...rest} /> : getNotAllowedRoutingAction(site, notAllowedComponent);
});
