import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { VendorEmailInput } from 'src/billpay/qbdt/modules/vendor-email/components/VendorEmalInput';
import { useUpdateVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useUpdateVendorEmail';
import { useValidateVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useValidateVendorEmail';
import { Modal, ModalTitle } from 'src/theme/ds';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';

type Props = {
  eventPage: string;
  vendorIds: number[];
  dismiss: VoidFunction;
};

const CollectVendorEmailsModal = ({ eventPage, vendorIds, dismiss }: Props) => {
  const vendors = useSelector(vendorsStore.selectors.all);

  const { loading, updateVendorEmailsBatch } = useUpdateVendorEmail({ eventPage });
  const {
    getVendorEmail,
    setVendorEmail,
    getValidationError,
    validateVendorEmailsBatch,
    isSmthEntered,
    getEnteredEmails,
  } = useValidateVendorEmail({
    eventPage,
  });

  const handleEmailChange = ({ id, value }) => {
    setVendorEmail({ id, value });
  };

  const handleCloseClick = () => {
    dismiss();
  };

  const handleSubmitClick = async () => {
    const isInputValid = validateVendorEmailsBatch({ ids: vendorIds, optional: true });

    if (isInputValid) {
      await updateVendorEmailsBatch(getEnteredEmails(vendorIds));
      dismiss();
    }
  };

  const hasUserInput = isSmthEntered(vendorIds);

  return (
    <Modal id="batch-collect-vendor-emails-dialog" onCloseClick={handleCloseClick}>
      <ModalTitle label="bills.batchPay.collectEmailsModal.titles.regular" />

      {vendorIds.map((vendorId) => (
        <VendorEmailInput
          key={vendorId}
          label="common.rawValue"
          labelValues={{ value: vendors[vendorId]?.companyName }}
          vendorId={vendorId.toString()}
          value={getVendorEmail(vendorId)}
          error={getValidationError(vendorId)}
          onChange={handleEmailChange}
          required
        />
      ))}

      <PrimaryButton
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={handleSubmitClick}
        label="bills.batchPay.collectEmailsModal.buttonLabel.saveAndNotify"
        size={BUTTON_SIZE.NORMAL}
        isProcessing={loading}
        disabled={!hasUserInput}
      />
    </Modal>
  );
};

const PrimaryButton = styled(MIButton)`
  width: 16.2rem;
  padding: 0;
  align-self: center;
`;

export { CollectVendorEmailsModal };
