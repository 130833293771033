import isNil from 'lodash/isNil';
import { getBillsSearchPath } from './bills';
import { CONSTS } from './consts';

const pattern = /\{([0-9a-zA-Z_]+)}/g;

class Route {
  _url: string;
  constructor(url) {
    this._url = url;
  }

  url(params = {}) {
    return this._url.replace(pattern, (_match, i) => (isNil(params[i]) ? `:${i}` : params[i]));
  }
}
// TODO: move location to be a module with functions and remove class
export class MainNavigator {
  index: Route;
  MainApp: {
    dashboardSingle: Route;
    dashboard: Route;
  };

  Bills: {
    index: Route;
    view: Route;
    filteredView: Route;
    filteredViewWithoutId: Route;
    edit: Route;
    contractors: Route;
    pay: {
      entry: Route;
      amount: Route;
      deliveryMethod: Route;
      date: Route;
      funding: Route;
      fastPayment: Route;
      batch: Route;
      batchSuccessFromDashboard: Route;
      confirm: Route;
      memo: Route;
      success: Route;
      batchSuccess: Route;
      batchCompleteLegalInfo: Route;
      batchCompleteComplianceInfo: Route;
      expedite: Route;
      completeLegalInfo: Route;
      completeComplianceInfo: Route;
      edit: {
        deliveryMethod: Route;
        deliveryMethodAch: Route;
        date: Route;
        funding: Route;
        confirm: Route;
        memo: Route;
        fastPayment: Route;
      };
    };
  };
  Vendors: {
    index: Route;
    view: Route;
    edit: Route;
    deliveryMethods: {
      add: Route;
      ach: {
        create: Route;
        edit: Route;
      };
      check: {
        create: Route;
        edit: Route;
        address: {
          create: Route;
          edit: Route;
        };
      };
      virtual: {
        create: Route;
      };
    };
  };

  Requests: {
    index: Route;
    create: {
      index: Route;
      options: Route;
      success: Route;
      completeLegalInfo: Route;
      completeComplianceInfo: Route;
    };
    view: Route;
  };
  Onboarding: {
    quickbooks: Route;
    accountingSoftware: {
      index: Route;
      quickbooks: {
        index: Route;
        success: Route;
        backToBills: Route;
      };
      comingSoon: {
        index: Route;
      };
    };
  };

  QuickBooks: {
    blocked: Route;
    billNotFound: Route;
  };
  Companies: {
    index: Route;
  };
  Team: {
    index: Route;
  };
  constructor() {
    this.index = new Route('/');
    this.MainApp = {
      dashboardSingle: new Route(
        `/orgs/{orgId}/bills?${getBillsSearchPath(CONSTS.BILL_STATUS.UNPAID)}&section=dashboard`
      ),
      dashboard: new Route(`/orgs/{orgId}/bills?${getBillsSearchPath(CONSTS.BILL_STATUS.UNPAID)}`),
    };
    this.Bills = {
      index: new Route('/orgs/{orgId}/bills'),
      view: new Route(`/orgs/{orgId}/bills?id={id}&${getBillsSearchPath(CONSTS.BILL_STATUS.UNPAID)}`),
      filteredView: new Route(
        '/orgs/{orgId}/bills?id={id}&status={status}&sorting={sorting}&start={start}&limit={limit}'
      ),
      filteredViewWithoutId: new Route(
        '/orgs/{orgId}/bills?status={status}&sorting={sorting}&start={start}&limit={limit}'
      ),
      edit: new Route('/orgs/{orgId}/bills/{id}/edit'),
      contractors: new Route('/orgs/{orgId}/bills/contractors'),
      pay: {
        entry: new Route('/orgs/{orgId}/bills/{id}/pay'),
        amount: new Route('/orgs/{orgId}/bills/{id}/pay/amount'),
        deliveryMethod: new Route('/orgs/{orgId}/bills/{id}/pay/delivery-method'),
        date: new Route('/orgs/{orgId}/bills/{id}/pay/date'),
        funding: new Route('/orgs/{orgId}/bills/{id}/pay/funding'),
        fastPayment: new Route('/orgs/{orgId}/bills/{id}/pay/fast-payment'),
        batch: new Route('/orgs/{orgId}/bills/pay/batch?ids={ids}&currentId={currentId}'),
        confirm: new Route('/orgs/{orgId}/bills/{id}/pay/confirm'),
        memo: new Route('/orgs/{orgId}/bills/{id}/pay/memo'),
        success: new Route('/orgs/{orgId}/bills/{id}/pay/success'),
        batchSuccess: new Route('/orgs/{orgId}/bills/pay/batch-success'),
        batchSuccessFromDashboard: new Route('/orgs/{orgId}/bills/pay/batch-success-from-dashboard'),
        batchCompleteLegalInfo: new Route('/orgs/{orgId}/bills/pay/batch-complete-legal-info'),
        batchCompleteComplianceInfo: new Route('/orgs/{orgId}/bills/pay/batch-complete-compliance-info'),
        expedite: new Route('/orgs/{orgId}/bills/{id}/pay/expedite'),
        completeLegalInfo: new Route('/orgs/{orgId}/bills/{id}/pay/complete-legal-info'),
        completeComplianceInfo: new Route('/orgs/{orgId}/bills/{id}/pay/complete-compliance-info'),
        edit: {
          deliveryMethod: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/delivery-method'),
          deliveryMethodAch: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/delivery-method-ach'),
          date: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/date'),
          funding: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/funding'),
          confirm: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/confirm'),
          memo: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/memo'),
          fastPayment: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/fast-payment'),
        },
      },
    };
    this.Vendors = {
      index: new Route('/orgs/{orgId}/contacts/vendors'),
      view: new Route('/orgs/{orgId}/contacts/vendors?id={id}'),
      edit: new Route('/orgs/{orgId}/contacts/vendors/{id}/edit'),
      deliveryMethods: {
        add: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/add'),
        ach: {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/ach/new'),
          edit: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/ach/{deliveryMethodId}'),
        },
        check: {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/check/new/name'),
          edit: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/check/{deliveryMethodId}/name'),
          address: {
            create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/check/new/address'),
            edit: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/check/{deliveryMethodId}/address'),
          },
        },
        virtual: {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/virtual/new'),
        },
      },
    };
    this.Requests = {
      index: new Route('/orgs/{orgId}/get-paid'),
      create: {
        index: new Route('/orgs/{orgId}/get-paid/new'),
        options: new Route('/orgs/{orgId}/get-paid/new/options'),
        success: new Route('/orgs/{orgId}/get-paid/new/success'),
        completeLegalInfo: new Route('/orgs/{orgId}/get-paid/new/complete-legal-info'),
        completeComplianceInfo: new Route('/orgs/{orgId}/bills/{id}/pay/complete-compliance-info'),
      },
      view: new Route('/orgs/{orgId}/get-paid?id={id}'),
    };
    this.Onboarding = {
      // FIXME: should be the same route as the above index, an open product issue
      quickbooks: new Route('/orgs/{orgId}/welcome/quickbooks'),
      accountingSoftware: {
        index: new Route('/orgs/{orgId}/welcome/accounting-software'),
        quickbooks: {
          index: new Route('/orgs/{orgId}/welcome/accounting-software/quickbooks'),
          success: new Route('/orgs/{orgId}/welcome/accounting-software/quickbooks/success'),
          backToBills: new Route('/orgs/{orgId}/welcome/accounting-software/quickbooks?action=bills'),
        },
        comingSoon: {
          index: new Route('/orgs/{orgId}/welcome/accounting-software/coming-soon/{type}'),
        },
      },
    };

    this.QuickBooks = {
      blocked: new Route('/quickbooks/blocked'),
      billNotFound: new Route('/quickbooks/bill-not-found'),
    };
    this.Companies = {
      index: new Route('/orgs/{orgId}/companies'),
    };
    this.Team = {
      index: new Route('/orgs/{orgId}/team'),
    };
  }
}

export default new MainNavigator();
