import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { getOrgId } from 'src/redux/user/selectors';
import { CONSTS } from 'src/utils/consts';
import ScrollToTop from 'src/components/layout/ScrollToTop';
import { generatePath } from 'react-router-dom';
import { useLocationState } from 'src/utils/hooks';
import { MIFormattedCurrency } from 'src/utils/formatting';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import QBOMISecurityDetails from 'src/components/common/QBOMISecurityDetails';
import { useCreditCardBenefits } from 'src/billpay/qbdt/hooks/useCreditCardBenefits';
import { useDebitFee } from 'src/billpay/qbdt/hooks/useDebitFee';
import { BATCH_PAGE_EVENT } from '../analytics/event-mapping';
import { useGoAddFundingSourceOfType } from './hooks/useGoAddFundingSourceOfType';
import locations from '../locations';
import { StyledQBDTStepLayout } from './styles';
import { useBatchSettings } from '../hooks/useBatchSettings';

export const AddFirstFundingSourcePage = () => {
  const orgId = useSelector(getOrgId);
  const [redirectUrl] = useLocationState('redirectUrl', null);
  const [totalAmount] = useLocationState('totalAmount', 0);
  const [numberOfBills] = useLocationState('numberOfBills', 0);
  const [isBillsOverLimit] = useLocationState('isBillsOverLimits', false);
  const [billsOverLimitAmount] = useLocationState('billsOverLimitAmount', 0);
  const { batchSettings } = useBatchSettings();
  const { billIds } = batchSettings;

  const debitFee = useDebitFee();

  const { goAddFundingSourceOfType } = useGoAddFundingSourceOfType({
    initialLocationState: {
      preservedState: {
        origin: CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS_FIRST_FS,
        closeAppOnExit: true,
        totalAmount,
        numberOfBills,
        isBillsOverLimit,
        billsOverLimitAmount,
        billIds,
      },
      redirectUrl,
      exitUrl: generatePath(locations.firstFundingSource, { orgId }),
    },
  });

  const { CreditCardBenefitsModal, openCreditCardBenefitsModal } = useCreditCardBenefits({
    eventPage: BATCH_PAGE_EVENT,
    onAddFundingSource: goAddFundingSourceOfType,
  });

  const headerLabelValues = {
    amount: <MIFormattedCurrency value={totalAmount} />,
    numberOfBills,
  };

  return (
    <ScrollToTop>
      <StyledQBDTStepLayout
        headerLabel="entry.batch.addFundingSource.header"
        headerLabelValues={headerLabelValues}
        title="bills.pay.batch.addFundingSource.title"
        subtitle="bills.pay.batch.addFundingSource.subtitle"
        goExit={close}
        footer={
          <Box mt="0">
            <QBOMISecurityDetails showIconCC />
          </Box>
        }
        relativeStep={1 / 2}
      >
        <FundingSourcesListLayout
          fundingSources={[]}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}} // removal of this function will cause change in the ui
          onAddMethod={goAddFundingSourceOfType}
          onViewBenefitsClick={openCreditCardBenefitsModal}
          debitFee={debitFee.fee}
        />
        {CreditCardBenefitsModal}
      </StyledQBDTStepLayout>
    </ScrollToTop>
  );
};
