import React, { useEffect } from 'react';
import { Link } from '@melio/billpay-design-system';
import blockedIcon from 'src/images/qbo/blocked.svg';
import analytics from 'src/services/analytics';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { OrganizationType } from 'src/utils/types';
import { Footer } from 'src/pages/vendor/components/VendorFlowLayout';
import { StyledQBDTSuccessLayout } from 'src/pages/funding-sources/components/common';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';

type Props = {
  organization?: OrganizationType;
};

export const PaymentBlockedPage = ({ organization }: Props) => {
  const site = useSiteContext();

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'blocked-payment-by-risk');
  }, []);

  const companyName = organization?.companyName || '';
  const companyEmail = organization?.userOrganizations?.[0]?.user?.email || '';
  const supportEmail = site.config.support.email;

  return (
    <StyledQBDTSuccessLayout
      illustration={blockedIcon}
      title="vendor.pushToDebit.paymentBlocked.title"
      text="vendor.pushToDebit.paymentBlocked.description"
      textValues={{
        companyName,
        companyEmail: <Link href={`mailto:${companyEmail}`}>{companyEmail}</Link>,
        supportEmail: <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>,
      }}
      hideHeader
      FooterComponent={<Footer />}
    />
  );
};
