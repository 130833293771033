import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as ClockIcon } from 'src/images/general/clock-icon.svg';
import { useLocation, useParams } from 'react-router-dom';
import analytics from 'src/services/analytics';
import {
  microDepositsEventAction,
  microDepositsEventPage,
  microDepositsEventStatuses,
} from 'src/pages/funding-sources/consts';
import { StyledQBDTFooterContainer, StyledQBDTSuccessLayout } from 'src/pages/funding-sources/components/common';

export function MicroDepositsCannotVerifyYetPage() {
  const location = useLocation<{ digits: string }>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    analytics.track(microDepositsEventPage, microDepositsEventAction, {
      fundingSourceId: id,
      status: microDepositsEventStatuses.CAN_NOT_VERIFY_YET,
    });
  }, []);

  return (
    <StyledQBDTSuccessLayout
      hideHeader
      illustration={<StyledClockIcon />}
      FooterComponent={<StyledQBDTFooterContainer />}
      title="fundingSources.microDeposits.cannotVerifyYet.title"
      text="fundingSources.microDeposits.cannotVerifyYet.subtitle"
      textValues={{
        digits: location.state?.digits,
      }}
    />
  );
}

const StyledClockIcon = styled(ClockIcon)`
  margin-bottom: 4rem;
`;
