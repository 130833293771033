import { createIntl, createIntlCache, IntlShape, injectIntl, IntlConfig, RawIntlProvider } from 'react-intl';
import merge from 'lodash/merge';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import errorTracker from 'src/utils/error-tracking';
import { logger } from './loggers';

export type TranslationObject = IntlShape & {
  setDefaultValue: (string, any) => void;
};

export function addFlattenedValues(values: any, prefix: string, obj: any) {
  if (!isObject(obj)) {
    values[prefix] = obj;
  } else {
    if (isArray(obj)) {
      values[`${prefix}_length`] = obj.length;
    }

    Object.keys(obj).forEach((key) => {
      addFlattenedValues(values, `${prefix}_${key}`, obj[key]);
    });
  }

  return values;
}

export function createTranslationService(options: IntlConfig, defaults: any = {}) {
  const cache = createIntlCache();
  const intl = createIntl(
    merge(
      {
        formats: {
          number: {
            currency: {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            },
            percent: {
              style: 'percent',
              maximumFractionDigits: 2,
            },
          },
        },
        onError: (error) => {
          logger.error('react-intl.getTranslation(): can not find translation', error);
          errorTracker.capture(error);
        },
        wrapRichTextChunksInFragment: true,
      },
      options
    ),
    cache
  );
  const originalFormatMessage = intl.formatMessage;
  const defaultValues = defaults;

  (intl as any).setDefaultValue = function (key, value) {
    addFlattenedValues(defaultValues, key, value);
  };
  intl.formatMessage = function (descriptor, values) {
    const newValues =
      values && Object.keys(values).length > 0 ? Object.assign({}, defaultValues, values) : defaultValues;
    return originalFormatMessage(descriptor, newValues);
  };
  return intl;
}

export { injectIntl as withIntl };
export { RawIntlProvider as TranslationProvider };
