export enum SDK_STATUS_CODES {
  OK = '0',
  NO_MATCH = '1',
  OBJECT_NOT_FOUND = '500',
}

export enum MELIO_STATUS_CODES {
  ERR_VENDOR_NAME_CONFLICT = 'QBDT01',
  ERR_VENDOR_RENAME_CONFLICT_EXTERNAL_ID = 'QBDT04',
}

export const TRANSACTION_LOCK_ERROR_CODES = ['3175', '3176'];
