import React from 'react';
import MIForm from 'src/components/common/MIForm';
import {
  FormType,
  GoogleCombinedAddressType,
  TaxIdType,
  ScreenMode,
  CompanyFormPageType,
  BusinessType,
} from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { getTaxIdTypesOptions } from 'src/utils/company';
import { useBusinessTypeOptions } from 'src/utils/useBusinessTypeOptions';
import { OnSelectNaicsCode } from 'src/billpay/qbdt/components/CompanyIndustryAutocomplete';
import { MASKS, getTaxIdPlaceholder } from 'src/utils/compliance-validator';
import { TaxIdTypeEnum } from '@melio/compliance-validator';

type Props = {
  legalCompanyName: string;
  address: GoogleCombinedAddressType;
  companyAddress: GoogleCombinedAddressType;
  taxId?: string;
  taxIdType?: TaxIdType;
  mode?: ScreenMode;
  onChange: (value: any) => void;
  formType?: FormType;
  validationErrors: Record<string, any>;
  companyFormPage: CompanyFormPageType;
  contactFirstName: string;
  contactLastName: string;
  phone?: string;
  businessType: BusinessType;
  dateOfBirth?: string;
  onAddressChange: (
    legalAddress: GoogleCombinedAddressType,
    type?: typeof CONSTS.ADDRESS_NAME_TYPE[keyof typeof CONSTS.ADDRESS_NAME_TYPE]
  ) => void;
  openLegalAddressInput?: boolean;
  isLoading?: boolean;
  onSelectNaicsCode: OnSelectNaicsCode;
  onResetNaicsCode: () => void;
  onDateOfBirthChange: (value: string) => void;
};

const QBOLegalInfoForm = (props: Props) => {
  const {
    legalCompanyName,
    address,
    formType = CONSTS.FORM_TYPE.WIZARD,
    taxId,
    taxIdType,
    mode = CONSTS.SCREEN_MODE.EDIT,
    companyAddress,
    openLegalAddressInput,
    isLoading,
    onChange,
    onAddressChange,
    validationErrors,
    companyFormPage,
    contactFirstName,
    contactLastName,
    phone,
    businessType,
    dateOfBirth,
    onSelectNaicsCode,
    onResetNaicsCode,
    onDateOfBirthChange,
  } = props;
  const { businessTypeOptions } = useBusinessTypeOptions();
  const companyLegalAddressValidationError =
    validationErrors.legalAddress ||
    validationErrors.legalGooglePlaceId ||
    validationErrors.legalAddressLine1 ||
    validationErrors.legalZipCode;
  const companyAddressValidationError =
    validationErrors.companyAddress || validationErrors.addressLine1 || validationErrors.zipCode;

  if (isLoading) {
    return null;
  }

  const legalInfoFormControls = () => {
    const legalInfoForm = [
      {
        id: 'contactFirstName',
        label: 'bills.pay.confirm.completeLegalInfo.contactFirstName',
        placeholder: 'bills.pay.confirm.completeLegalInfo.emptyLabelFirstName',
        value: contactFirstName,
        onChange,
        required: true,
        errorMessage: validationErrors.contactFirstName,
        controlType: CONSTS.FORM_CONTROL_TYPE.TEXT,
        initialState: false,
        privateData: true,
      },
      {
        id: 'contactLastName',
        label: 'bills.pay.confirm.completeLegalInfo.contactLastName',
        placeholder: 'bills.pay.confirm.completeLegalInfo.emptyLabelLastName',
        value: contactLastName,
        onChange,
        required: true,
        errorMessage: validationErrors.contactLastName,
        controlType: CONSTS.FORM_CONTROL_TYPE.TEXT,
        initialState: false,
        privateData: true,
      },
      {
        id: 'legalCompanyName',
        label: 'bills.pay.confirm.completeLegalInfo.legalName.inputTitle',
        placeholder: 'bills.pay.confirm.completeLegalInfo.legalName.emptyLabel',
        notices: ['bills.pay.confirm.completeLegalInfo.legalName.inputHint'],
        value: legalCompanyName,
        onChange,
        required: true,
        errorMessage: validationErrors.legalCompanyName,
        controlType: CONSTS.FORM_CONTROL_TYPE.TEXT,
        initialState: false,
        privateData: true,
      },
      {
        id: 'dateOfBirth',
        label: 'bills.pay.confirm.completeLegalInfo.dateOfBirth.inputTitle',
        placeholder: 'bills.pay.confirm.completeLegalInfo.dateOfBirth.emptyLabel',
        value: dateOfBirth,
        onChange: ({ value }) => {
          onDateOfBirthChange(value);
        },
        required: true,
        mask: MASKS.dateOfBirth,
        errorMessage: validationErrors.dateOfBirth,
        controlType: CONSTS.FORM_CONTROL_TYPE.TEXT,
        initialState: false,
      },
      {
        id: 'businessType',
        label: 'bills.pay.confirm.completeLegalInfo.businessType.inputTitle',
        placeholder: 'bills.pay.confirm.completeLegalInfo.businessType.emptyLabel',
        value: businessType,
        onChange: ({ value }) => {
          onChange({ id: 'businessType', value });
        },
        required: true,
        options: businessTypeOptions,
        errorMessage: validationErrors.businessType,
        controlType: CONSTS.FORM_CONTROL_TYPE.SELECT,
        initialState: false,
      },
      {
        id: 'companyIndustry',
        label: 'bills.pay.confirm.completeLegalInfo.companyIndustry.inputTitle',
        placeholder: 'bills.pay.confirm.completeLegalInfo.companyIndustry.emptyLabel',
        required: true,
        onSelectNaicsCode,
        onReset: onResetNaicsCode,
        errorMessage: validationErrors.companyIndustry,
        controlType: CONSTS.FORM_CONTROL_TYPE.INDUSTRY,
        initialState: false,
      },
      {
        id: 'companyAddress',
        addressLabel: 'bills.pay.confirm.completeLegalInfo.companyAddress.inputTitle',
        emptyAddressLabel: 'bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel',
        address: companyAddress,
        onChange: onAddressChange,
        onSelect: (value: string) => onChange({ id: 'openLegalAddressInput', value }),
        required: true,
        errorMessage: companyAddressValidationError,
        controlType: CONSTS.FORM_CONTROL_TYPE.ADDRESS,
        initialState: false,
        showCheckbooks: true,
        openLegalAddressInput,
        privateData: true,
      },
      {
        id: 'legalAddress',
        addressLabel: 'bills.pay.confirm.completeLegalInfo.legalAddress.inputTitle',
        emptyAddressLabel: 'bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel',
        address,
        onChange: onAddressChange,
        required: openLegalAddressInput,
        errorMessage: companyLegalAddressValidationError,
        controlType: CONSTS.FORM_CONTROL_TYPE.ADDRESS,
        initialState: false,
        hideInput: !openLegalAddressInput,
        showCheckbooks: false,
        privateData: true,
      },
      {
        id: 'taxIdType',
        label:
          companyFormPage === CONSTS.COMPANY_FORM_PAGE.ONBOARDING
            ? 'bills.pay.confirm.completeLegalInfo.taxIdType.inputTitle'
            : 'onboarding.companyInfo.taxIdType.inputTitle',
        emptyLabel: 'bills.pay.confirm.completeLegalInfo.taxIdType.emptyLabel',
        selected: taxIdType,
        onSelect: (value: string) => onChange({ id: 'taxIdType', value }),
        required: true,
        options: getTaxIdTypesOptions(),
        errorMessage: validationErrors.taxIdType,
        controlType: CONSTS.FORM_CONTROL_TYPE.RADIO,
        initialState: false,
      },
      {
        id: 'taxId',
        label: 'bills.pay.confirm.completeLegalInfo.taxId.inputTitle',
        placeholder: getTaxIdPlaceholder(taxIdType),
        mask: MASKS.taxId[taxIdType || TaxIdTypeEnum.Ein],
        value: taxId,
        onChange,
        required: true,
        errorMessage: validationErrors.taxId,
        controlType: CONSTS.FORM_CONTROL_TYPE.TEL,
        initialState: false,
        privateData: true,
      },
      {
        id: 'phone',
        label: 'bills.pay.confirm.completeLegalInfo.phone.placeholder',
        placeholder: 'bills.pay.confirm.completeLegalInfo.phone.emptyLabelPhone',
        value: phone,
        onChange,
        required: true,
        errorMessage: validationErrors.phone,
        controlType: CONSTS.FORM_CONTROL_TYPE.TEL,
        initialState: false,
        privateData: true,
      },
    ];

    return legalInfoForm;
  };

  return <MIForm formControls={legalInfoFormControls()} mode={mode} formType={formType} />;
};

export default QBOLegalInfoForm;
