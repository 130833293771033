import { RecordOf } from 'immutable';
import React from 'react';
import { AccountType, BillType } from 'src/utils/types';
import { ViewPaymentFundingSourceInfo } from './ViewPayFromSection/fields/ViewPaymentFundingSourceInfo/ViewPaymentFundingSourceInfo';
import { PaymentInfoSection } from '../../../../modules/view-and-confirm-pages/sections/PaymentInfoSection';

type Props = {
  bill: BillType;
  fundingSource: RecordOf<AccountType>;
};

export const FailedPaymentInfoSection = ({ bill, fundingSource }: Props) => (
  <PaymentInfoSection label="viewBillPaymentActivity.payFromTitle">
    <ViewPaymentFundingSourceInfo fundingSource={fundingSource} bill={bill} />
  </PaymentInfoSection>
);
