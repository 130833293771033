import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyInfo, getProfile } from 'src/redux/user/selectors';
import { isCompanyInfoDone, isProfileDateOfBirthAdded } from 'src/utils/company';
import { AuthPhase, AuthState, useQbdtOAuth } from 'src/billpay/qbdt/helpers/useQbdtOAuth';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { QBDTPayBillLoader } from 'src/billpay/qbdt/pages/entry/QBDTPayBillLoader';
import { AuthWithAppcenterPage } from 'src/billpay/qbdt/pages/entry/AuthWithAppcenterPage';
import { AuthWithAppcenterPageRedirect } from 'src/billpay/qbdt/pages/entry/AuthWithAppcenterPageRedirect';
import { useParams, Switch } from 'react-router-dom';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { QBDTIntent } from 'src/services/api/qbdt';
import { QBDTSyncLoader } from 'src/billpay/qbdt/pages/entry/QBDTSyncLoader';
import { QBDTViewPaymentLoader } from 'src/billpay/qbdt/pages/entry/QBDTViewPaymentLoader';
import { ProgressIndicatorPage } from 'src/billpay/qbdt/pages/entry/ProgressIndicationPage';
import analytics from 'src/services/analytics';
import { getSelectedBills } from 'src/billpay/qbdt/services/qbdt';
import { LandingPage } from 'src/billpay/qbdt/pages/entry/LandingPage/LandingPage';

import locations from './locations';
import { getShouldRedirectToAppCenter } from './utils';
import { QBDTDashboardLoader } from './QBDTDashboardLoader';
import { QBDTCodeEmailVerificationPage } from './QBDTCodeEmailVerificationPage';
import { EntryErrorPage } from './EntryErrorPage';
import QBDTAddDateOfBirthPageContainer from './DateOfBirthPage/QBDTAddDateOfBirthPageContainer';

const title = 'entry.header';
const messages = [
  'entry.messages.quicklyPay',
  'entry.messages.payWithACH',
  'entry.messages.extendFloat',
  'entry.messages.scheduleBill',
];
// eslint-disable-next-line @typescript-eslint/no-empty-function
type QBDTEntryRouterParams = {
  realmId: string;
};

function phaseToProgress(phase: AuthPhase) {
  switch (phase) {
    case AuthPhase.IDLE:
      return 0;
    case AuthPhase.CHECKING:
      return 0.1;
    case AuthPhase.INITIALIZE_USER:
    case AuthPhase.REQUIRES_AUTH_WITH_APPCENTER:
    default:
      return 0.3;
  }
}

function useEntryAnalytics(authState: AuthState) {
  useEffect(() => {
    getSelectedBills().then((res) => {
      analytics.trackAction('qbdt-entry', {
        entrypoint: res.entry || 'sync',
        numberOfBills: res.entities.length,
      });
    });
  }, []);
  useEffect(() => {
    analytics.trackAction('qbdt-entry-phase', { ...authState });
  }, [authState.phase]);
}

export function QBDTEntryRouter() {
  const { realmId } = useParams<QBDTEntryRouterParams>();
  const profile = useSelector(getProfile);
  const companyInfo = useSelector(getCompanyInfo);
  const shouldRedirectToAppCenter = getShouldRedirectToAppCenter();
  const shouldRedirectToAddDateOfBirthPage = isCompanyInfoDone(companyInfo) && !isProfileDateOfBirthAdded(profile);
  const site = useSiteContext();
  const intent = shouldRedirectToAppCenter ? QBDTIntent.CONNECT_APPCENTER_REDIRECT : QBDTIntent.CONNECT;

  const [authState, actions] = useQbdtOAuth({
    realmId,
    appType: 'qbdt',
    intent,
    registrationOrigin: site.registrationOrigin,
  });
  useEntryAnalytics(authState);

  if ([AuthPhase.IDLE, AuthPhase.CHECKING, AuthPhase.INITIALIZE_USER].includes(authState.phase)) {
    return <ProgressIndicatorPage title={title} messages={messages} progress={phaseToProgress(authState.phase)} />;
  }

  const authRequiresConfirmation = authState.phase === 'requires-confirmation';

  if (authRequiresConfirmation) {
    return <LandingPage onConfirm={actions.userConfirmed} />;
  }

  if (authState.phase === 'requires-auth-with-appcenter') {
    const AuthWithAppcenterPageComponent = shouldRedirectToAppCenter
      ? AuthWithAppcenterPageRedirect
      : AuthWithAppcenterPage;

    return (
      <AuthWithAppcenterPageComponent
        appcenterUrl={authState.appcenterUrl}
        onSuccess={actions.authSuccess}
        onFailure={actions.authError}
        realmId={realmId}
      />
    );
  }

  if (authState.phase === 'requires-email-verification') {
    return <QBDTCodeEmailVerificationPage onSuccess={actions.authSuccess} />;
  }

  if (authState.phase === 'error') {
    return <EntryErrorPage errors={authState.errorMessage} />;
  }

  if (shouldRedirectToAddDateOfBirthPage) {
    return <QBDTAddDateOfBirthPageContainer />;
  }

  // authenticated
  return (
    <Switch>
      <SmartRoute path={locations.pay}>
        <QBDTPayBillLoader />
      </SmartRoute>
      <SmartRoute path={locations.view}>
        <QBDTViewPaymentLoader />
      </SmartRoute>
      <SmartRoute path={locations.dashboard}>
        <QBDTDashboardLoader />
      </SmartRoute>
      <SmartRoute path={locations.sync}>
        <QBDTSyncLoader />
      </SmartRoute>
      <SmartRoute path="*">
        <div data-testid="unknown-action">Unknown action</div>
      </SmartRoute>
    </Switch>
  );
}
