import * as React from 'react';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import clientServiceApi from 'src/services/api/clientService';
import {
  BankType,
  CheckType,
  GoogleCombinedAddressType,
  EditableDeliveryMethodType,
  FieldType,
  AddressType,
  DeliveryMethodType,
  AccountType,
  CompanyInfoType,
  AddressTypeFromApi,
} from 'src/utils/types';
import { GlobalState } from 'src/redux/types';
import locations from 'src/utils/locations';
import { BankRecord, CheckRecord, AddressRecord } from 'src/pages/vendor/records';
import deliveryMethodsApi from 'src/services/api/deliveryMethods';
import vendorsApi from 'src/services/api/vendors';
import { CONSTS } from 'src/utils/consts';
import { whitePagesAddressKeys } from 'src/utils/address';
import analytics from 'src/services/analytics/index';

import { selectNewDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { getOrgId, getFundingSources, getOwnedVendorId, getCompanyInfo } from 'src/redux/user/selectors';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { checkBankAccount } from 'src/pages/vendor/delivery-methods/utils';

// redux-toolkit actions
import vendorStore from 'src/modules/vendors/vendors-store';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';

type MapStateToProps = {
  orgId: string;
  companyInfo: RecordOf<CompanyInfoType>;
  userFundingSources: RecordOf<AccountType>[];
  ownedVendorId: string;
  qbVendorInfoAddress: AddressTypeFromApi;
};

type MapDispatchToProps = {
  selectNewDeliveryMethodForPayBillFlow: (deliveryMethod: DeliveryMethodType) => void;
  refreshDeliveryMethods: () => Promise<void>;
  createDeliveryMethod: (orgId: string, vendorId: string, deliveryMethod: any) => Promise<any>;
  editDeliveryMethod: (params: any) => Promise<any>;
  checkVendorPaymentPreferences: ({ orgId, id }) => Promise<any>;
};

type Props = {
  vendorId: string;
  deliveryMethodId?: string;
  locationState: Record<string, any>;
  inputFields: Array<string>;
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void;
} & MapDispatchToProps &
  MapStateToProps;

type State = {
  isLoading: boolean;
  isNew: boolean;
  isReady: boolean;
  validationErrors: Record<string, string>;
  addressValidationErrors: Record<string, string>;
  bank: RecordOf<BankType>;
  check: RecordOf<CheckType>;
  manualAddress: RecordOf<AddressType>;
  origin: string;
  eventPage: string;
  selectedAddressId: string;
  invalidAddress: boolean;
  confirmMode: boolean;
  isVerified: boolean;
  validatedAddress?: ManualAddressType;
  errorCode: string;
  isVendorBlockedForPayment: boolean;
};

export type DeliveryMethodProps = {
  vendorId: string;
  isLoading: boolean;
  confirmMode: boolean;
  invalidAddress: boolean;
  isNew: boolean;
  bank: RecordOf<BankType>;
  check: RecordOf<CheckType>;
  manualAddress: AddressType;
  validatedAddress: ManualAddressType;
  validationErrors: Record<string, string>;
  addressValidationErrors: Record<string, string>;
  onManualAddressChange: (field: FieldType) => void;
  onAddressAddRequest: () => void;
  onAddressConfirmed: () => void;
  onEditAddress: () => void;
  onAddressSelect: (id: string) => void;
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  goExit: () => void;
  onCheckNameAdded: (printName: string) => void;
  onCheckAddressAdded: (address: GoogleCombinedAddressType) => void;
  onCheckAddressPrev: () => void;
  onBankAdded: (bankAccount: RecordOf<BankType>) => void;
  errorCode: string;
  selectedAddressId: string;
  isVendorBlockedForPayment: boolean;
};

type DeliveryMethodUpdatePayload = {
  orgId: string;
  vendorId: string;
  deliveryMethod: EditableDeliveryMethodType;
  deliveryMethodId?: string;
};

const mapStateToProps = (state: GlobalState, { vendorId }): MapStateToProps => ({
  orgId: getOrgId(state),
  companyInfo: getCompanyInfo(state),
  userFundingSources: getFundingSources(state),
  ownedVendorId: getOwnedVendorId(state),
  qbVendorInfoAddress: vendorStore.selectors.qboVendorInfo.value(state, vendorId),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectNewDeliveryMethodForPayBillFlow(deliveryMethod: DeliveryMethodType) {
    dispatch(selectNewDeliveryMethodAction(deliveryMethod));
  },
  refreshDeliveryMethods() {
    return new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });
  },
  createDeliveryMethod(orgId, vendorId, deliveryMethod) {
    const params = {
      deliveryType: deliveryMethod.deliveryType,
      bankAccount: deliveryMethod?.bankAccount?.toJS(),
    };
    return getDeliveryMethodActions(dispatch).create({
      orgId,
      vendorId,
      params,
    });
  },
  editDeliveryMethod(params: any) {
    return getDeliveryMethodActions(dispatch).edit(params);
  },
  checkVendorPaymentPreferences({ orgId, id }) {
    const vendorActions = getStoreActions(vendorStore)(dispatch);
    return vendorActions.checkVendorPaymentPreferences({ orgId, id });
  },
});

export function withDeliveryMethodData() {
  return function (Component: any) {
    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      class ComponentWithDeliveryMethodData extends React.PureComponent<Props, State> {
        static defaultProps = {};

        constructor(props: Props) {
          super(props);
          const { locationState, vendorId } = this.props;
          const { ownedVendorId } = this.props;

          this.state = {
            isVerified: false,
            selectedAddressId: '1',
            validatedAddress: undefined,
            invalidAddress: false,
            confirmMode: false,
            isReady: false,
            isLoading: false,
            isNew: !this.props.deliveryMethodId,
            check: locationState && locationState.check ? locationState.check : CheckRecord(),
            bank: BankRecord(),
            validationErrors: {},
            addressValidationErrors: {},
            manualAddress: AddressRecord(),
            origin: locationState && locationState.origin ? locationState.origin : '',
            eventPage:
              parseInt(ownedVendorId, 10) === parseInt(vendorId, 10)
                ? 'vendor-company-delivery-method'
                : 'vendor-delivery-method',
            errorCode: '',
            isVendorBlockedForPayment: false,
          };
        }

        componentDidMount() {
          if (this.state.isNew) {
            const { check } = this.state;

            if (check.printName) {
              // eslint-disable-next-line react/no-did-mount-set-state
              this.setState({ isReady: true });
            } else {
              this.loadDefaultPrintName();
            }
          } else {
            this.loadDeliveryMethod();
          }
        }

        onCheckNameAdded = (printName: string) => {
          const { deliveryMethodId, vendorId: id, locationState, userFundingSources = [] } = this.props;
          const url = this.props.deliveryMethodId
            ? locations.Vendors.deliveryMethods.check.address.edit.url({
                id,
                deliveryMethodId,
              })
            : locations.Vendors.deliveryMethods.check.address.create.url({
                id,
              });
          const validationErrors = getValidationErrors('deliveryMethodCheck', { printName }, this.props.inputFields);

          if (this.props.deliveryMethodId) {
            analytics.track(this.state.eventPage, 'edit-delivery-method-check-name');
          } else {
            analytics.track(this.state.eventPage, 'add-delivery-method-check-name');
          }

          this.setState({ validationErrors }, () => {
            if (isValidationOk(validationErrors)) {
              this.props.navigate(url, false, {
                check: this.state.check.merge({ printName }),
                origin: this.state.origin,
                fundingSource: userFundingSources.find((fs) => fs.id === locationState.fundingSourceId),
              });
            } else {
              analytics.track(
                this.state.eventPage,
                'add-delivery-method-check-name-validation-error',
                validationErrors
              );
            }
          });
        };

        onCheckAddressPrev = () => {
          const { deliveryMethodId, vendorId: id, navigate } = this.props;
          const { check } = this.state;
          const url = deliveryMethodId
            ? locations.Vendors.deliveryMethods.check.edit.url({
                id,
                deliveryMethodId,
              })
            : locations.Vendors.deliveryMethods.check.create.url({ id });

          navigate(url, false, { check });
        };

        onEditAddress = () => {
          const { validatedAddress } = this.state;
          if (validatedAddress && validatedAddress.diff) {
            const changes = validatedAddress.diff.reduce((obj, diff) => {
              const whitePagesKey = Object.keys(diff)[0];
              const key = whitePagesAddressKeys[whitePagesKey];
              // eslint-disable-next-line prefer-destructuring
              obj[key] = Object.values(diff)[0];
              return obj;
            }, {});
            this.setState(({ manualAddress }) => ({
              manualAddress: manualAddress.merge({ ...changes }),
            }));
          }

          this.setState({
            confirmMode: false,
            isLoading: false,
            invalidAddress: false,
          });
          analytics.track(this.state.eventPage, 'check-suggested-address-edited');
        };

        onAddressConfirmed = () => {
          const { selectedAddressId, validatedAddress, invalidAddress } = this.state;
          this.setState(({ check }) => ({
            check: check.merge({
              isAddressSuggestionIgnored: invalidAddress || selectedAddressId === CONSTS.MANUAL_ADDRESS.ORIGINAL,
            }),
            isLoading: true,
          }));

          if (!invalidAddress && selectedAddressId === CONSTS.MANUAL_ADDRESS.SUGGESTED && validatedAddress) {
            const confirmedChanges = validatedAddress.diff.reduce((obj, diff) => {
              const whitePagesKey = Object.keys(diff)[0];
              const key = whitePagesAddressKeys[whitePagesKey];
              // eslint-disable-next-line prefer-destructuring
              obj[key] = Object.values(diff)[0];
              return obj;
            }, {});
            this.setState(
              ({ manualAddress }) => ({
                manualAddress: manualAddress.merge({ ...confirmedChanges }),
                isVerified: true,
              }),
              () => this.onAddressAdded()
            );
            analytics.track(this.state.eventPage, 'check-suggested-address-confirmed');
          } else {
            this.setState({ isVerified: false }, () => {
              this.onAddressAdded();
            });
            if (invalidAddress) {
              analytics.track(this.state.eventPage, 'check-no-suggestion-address-confirmed');
            } else {
              analytics.track(this.state.eventPage, 'check-suggested-address-declined');
            }
          }
        };

        onAddressAddRequest = () => {
          this.setState({ isLoading: true });
          const manualAddress = this.state.manualAddress.toJS();
          const { deliveryMethodId } = this.props;
          const validationErrors = getValidationErrors('deliveryMethodCheck', manualAddress, [
            'addressLine1',
            'city',
            'state',
            'zipCode',
          ]);
          if (deliveryMethodId) {
            analytics.track(this.state.eventPage, 'edit-delivery-method-check-address');
          } else {
            analytics.track(this.state.eventPage, 'add-delivery-method-check-address');
          }

          if (get(this.props.qbVendorInfoAddress, 'addressLine1')) {
            analytics.track(this.state.eventPage, 'address-from-qbo-suggestion');
          }

          this.setState({ validationErrors }, async () => {
            if (isValidationOk(validationErrors)) {
              await this.addressValidationService();
            } else {
              this.setState({
                isLoading: false,
              });
              if (deliveryMethodId) {
                analytics.track(
                  this.state.eventPage,
                  'edit-delivery-method-check-address-regular-validation-error',
                  validationErrors
                );
              } else {
                analytics.track(
                  this.state.eventPage,
                  'add-delivery-method-check-address-regular-validation-error',
                  validationErrors.toString().toUpperCase()
                );
              }
            }
          });
        };

        onAddressAdded = async () => {
          const { isVerified } = this.state;
          const manualAddress = this.state.manualAddress.toJS();
          manualAddress.googlePlaceId = CONSTS.ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID;
          const paperCheck = this.state.check.merge({ ...manualAddress });
          await this.onDeliveryMethodAdded({
            deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
            paperCheck,
            isVerified,
          });
        };

        onAddressSelect = (id: string) => {
          analytics.track(this.state.eventPage, 'address-suggestion-selected');
          this.setState({ selectedAddressId: id });
        };

        onBankAdded = (bankAccount: RecordOf<BankType>) => {
          const { deliveryMethodId, ownedVendorId } = this.props;
          let validationErrors = getValidationErrors('deliveryMethodAch', bankAccount);

          if (isValidationOk(validationErrors) && parseInt(this.props.vendorId, 10) !== parseInt(ownedVendorId, 10)) {
            const { userFundingSources = [] } = this.props;
            validationErrors = checkBankAccount(bankAccount, userFundingSources);
          }

          this.setState({ validationErrors }, () => {
            if (isValidationOk(validationErrors)) {
              if (
                bankAccount.routingNumber === this.state.bank.routingNumber &&
                bankAccount.accountNumber === this.state.bank.accountNumber
              ) {
                this.navigateOut();
              } else {
                this.onDeliveryMethodAdded({
                  deliveryType: CONSTS.DELIVERY_TYPE.ACH,
                  bankAccount,
                });
              }
            } else if (deliveryMethodId) {
              analytics.track(this.state.eventPage, 'edit-delivery-method-validation-error', validationErrors);
            } else {
              analytics.track(this.state.eventPage, 'add-delivery-method-validation-error', validationErrors);
            }
          });
        };

        onDeliveryMethodAdded = async (deliveryMethod: EditableDeliveryMethodType) => {
          const { refreshDeliveryMethods, orgId, ownedVendorId, checkVendorPaymentPreferences, vendorId } = this.props;
          this.setState({ isLoading: true });
          try {
            const data = await this.updateDeliveryMethod({
              orgId,
              vendorId,
              deliveryMethodId: this.props.deliveryMethodId,
              deliveryMethod,
            });
            if (this.state.origin === CONSTS.DELIVERY_METHOD_ORIGIN.PAY_BILL) {
              const { selectNewDeliveryMethodForPayBillFlow } = this.props;
              selectNewDeliveryMethodForPayBillFlow(data);
              const {
                payload: { blockPayments },
              } = await checkVendorPaymentPreferences({ orgId, id: vendorId });
              this.setState({ isVendorBlockedForPayment: blockPayments });
            }

            if (parseInt(vendorId, 10) === parseInt(ownedVendorId, 10)) {
              await refreshDeliveryMethods();
            }

            if (this.props.deliveryMethodId) {
              analytics.track(this.state.eventPage, 'edit-delivery-method-success', {
                type: deliveryMethod.deliveryType,
              });
            } else {
              analytics.track(this.state.eventPage, 'add-delivery-method-success', {
                type: deliveryMethod.deliveryType,
              });
            }

            const dataToAdd = {
              newDeliveryMethod: data,
              batchActionMerge: this.state.origin === CONSTS.DELIVERY_METHOD_ORIGIN.BATCH_PAY_BILLS,
            };
            this.setState({ isLoading: false });
            this.navigateOut(dataToAdd);
          } catch (error: any) {
            this.setState({ isLoading: false, errorCode: error.code });
          }
        };

        onManualAddressChange = ({ value, id }: FieldType) => {
          this.setState(({ manualAddress }) => ({
            manualAddress: manualAddress.merge({ [id]: value }),
          }));
        };

        addressValidationService = async () => {
          const { addressValidationErrors } = this.state;
          const manualAddress = this.state.manualAddress.toJS();
          const validatedAddressObj = await clientServiceApi.getAddressValidation(manualAddress);

          if (validatedAddressObj && validatedAddressObj.is_valid) {
            if (!validatedAddressObj.diff) {
              this.setState(
                {
                  invalidAddress: false,
                  validatedAddress: validatedAddressObj,
                  isVerified: true,
                },
                () => {
                  this.onAddressAdded();
                }
              );
            } else {
              if (validatedAddressObj.diff.length) {
                validatedAddressObj.diff.map((diff) => Object.assign(addressValidationErrors, diff));
              }

              analytics.track(this.state.eventPage, 'alternative-suggested');
              this.setState({
                invalidAddress: false,
                confirmMode: true,
                isLoading: false,
                addressValidationErrors,
                validatedAddress: validatedAddressObj,
              });
            }
          } else {
            if (validatedAddressObj && !validatedAddressObj.error && !validatedAddressObj.is_valid) {
              analytics.track(this.state.eventPage, 'edit-delivery-method-check-no-suggestion-found');
              this.setState({
                invalidAddress: true,
                isLoading: false,
                isVerified: false,
                validatedAddress: undefined,
              });
            }

            if (validatedAddressObj.error) {
              this.setState(
                {
                  isVerified: false,
                  isLoading: false,
                },
                () => {
                  this.onAddressAdded();
                }
              );
              analytics.track(
                this.state.eventPage,
                'edit-delivery-method-check-address-whitepages-validation-error',
                validatedAddressObj.error
              );
            }
          }
        };

        loadDefaultPrintName = () => {
          const { vendorId, orgId } = this.props;

          this.setState({ isLoading: true });
          vendorsApi
            .getVendorById({ orgId, id: vendorId })
            .then(({ object: vendor }) => {
              this.setState({
                isLoading: false,
                isReady: true,
                check: CheckRecord({ printName: vendor.companyName }),
              });
            })
            .catch(() => {
              this.setState({
                isLoading: false,
                isReady: true,
                check: CheckRecord({ printName: '' }),
              });
            });
        };

        loadDeliveryMethod = () => {
          const { vendorId, orgId, deliveryMethodId } = this.props;

          this.setState({ isLoading: true });
          deliveryMethodsApi
            .getDeliveryMethodById({ orgId, vendorId, id: deliveryMethodId })
            .then(({ deliveryMethod }) => {
              this.setState({
                isLoading: false,
                isReady: true,
                bank: BankRecord(deliveryMethod.bankAccount),
                check: this.state.check.printName ? this.state.check : CheckRecord(deliveryMethod.paperCheck),
                manualAddress: AddressRecord(deliveryMethod.paperCheck),
              });
            })
            .catch(() => {
              this.setState({
                isLoading: false,
                isReady: true,
                bank: BankRecord(),
                check: CheckRecord(),
              });
            });
        };

        updateDeliveryMethod = async (deliveryMethodUpdateData: DeliveryMethodUpdatePayload) => {
          const { orgId, vendorId, deliveryMethodId, deliveryMethod } = deliveryMethodUpdateData;
          const { ownedVendorId, createDeliveryMethod, editDeliveryMethod } = this.props;
          if (deliveryMethodId) {
            analytics.track(this.state.eventPage, 'edit-delivery-method', {
              type: deliveryMethod.deliveryType,
            });
            const { payload } = await editDeliveryMethod({
              orgId,
              vendorId,
              id: deliveryMethodId,
              deliveryMethod,
            });

            return payload;
          }

          if (ownedVendorId && ownedVendorId?.toString() === vendorId?.toString()) {
            deliveryMethod.isFilledByVendor = true;
            analytics.setTraits({
              [CONSTS.DB_ANALYTICS_TRAITS.ADDED_DELIVERY_METHOD]: true,
            });
          }

          analytics.track(this.state.eventPage, 'add-delivery-method', {
            type: deliveryMethod.deliveryType,
          });

          const { payload } = await createDeliveryMethod(orgId, vendorId, deliveryMethod);

          return payload;
        };

        goExit = () => {
          analytics.track(this.state.eventPage, 'exit');
          if (this.props.navigateToExitWithPreservedState) {
            this.props.navigateToExitWithPreservedState();
          } else if (this.props.navigateWithPreservedState) {
            this.props.navigateWithPreservedState();
          } else {
            this.props.navigate(
              locations.Vendors.view.url({
                id: this.props.vendorId,
                type: CONSTS.CONTACTS_TAB.VENDORS,
              })
            );
          }
        };

        navigateOut = (dataToAdd?: Record<string, any>) => {
          if (this.state.isVendorBlockedForPayment) {
            return;
          }

          if (this.props.navigateWithPreservedState) {
            this.props.navigateWithPreservedState(dataToAdd);
          } else {
            this.props.navigate(
              locations.Vendors.view.url({
                id: this.props.vendorId,
                type: CONSTS.CONTACTS_TAB.VENDORS,
              })
            );
          }
        };
        render() {
          return (
            <React.Fragment>
              {this.state.isReady && (
                <Component
                  {...this.state}
                  {...this.props}
                  companyInfo={this.props.companyInfo}
                  goExit={this.goExit}
                  onCheckNameAdded={this.onCheckNameAdded}
                  onCheckAddressPrev={this.onCheckAddressPrev}
                  onBankAdded={this.onBankAdded}
                  onManualAddressChange={this.onManualAddressChange}
                  onAddressAddRequest={this.onAddressAddRequest}
                  onAddressConfirmed={this.onAddressConfirmed}
                  onEditAddress={this.onEditAddress}
                  onAddressSelect={(id) => this.onAddressSelect(id)}
                />
              )}
            </React.Fragment>
          );
        }
      }
    );
  };
}
