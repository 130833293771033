import head from 'lodash/head';
import { getPartialBillId } from 'src/utils/bills';
import { PaymentType } from 'src/utils/types';

export const getCommonAnalyticsParams = (payment?: PaymentType) => {
  const bill = payment?.bill || head(payment?.bills);
  return {
    flow: 'vendor',
    billId: payment?.billId || bill?.id,
    partialBillId: bill && getPartialBillId(bill),
    paymentId: payment?.id,
    vendorId: payment?.vendorId,
    organizationId: payment?.organizationId,
  };
};
