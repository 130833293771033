import deliveryApi, { GetDeliveryTimeParams } from 'src/services/api/delivery';
import { BILL_STATUS, CONSTS, PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';
import { deleteRequest, postRequest, putRequest, fetchRequest } from './api';
import analytics from '../../services/analytics';
import { convertLimit, convertStart } from '../../utils/pagination-utils';

export type PaymentFilters = {
  start?: number;
  limit?: number;
};

type PaymentFeeProps = {
  orgId: string;
  fundingSourceId: number;
  amount?: string | number | null;
  scheduledDate: Date;
  deliveryPreference?: string | null;
  deliveryMethodId: string | number;
  cardFeePaidBy?: string;
  allowDoubleFee?: boolean;
};

export type GetDeliveryTimeParamsForBatch = Omit<GetDeliveryTimeParams, 'scheduledDate'> & {
  deductionDate: Date;
};

// TODO: add a scope argument to GET requests to get payments which point to deleted funding sources & delivery methods
export default {
  getPayments({ orgId, filters = {} }: { orgId: string; filters: PaymentFilters }) {
    const url = `/orgs/${orgId}/payments`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  list({
    orgId,
    status,
    search,
    start,
    limit,
  }: {
    orgId: string;
    status: BILL_STATUS;
    search?: string;
    start?: number;
    limit?: number;
  }) {
    const url = `/orgs/${orgId}/payments/list`;
    const params = {
      status,
      search,
      start: convertStart(start),
      limit: convertLimit(limit),
    };

    return fetchRequest(url, params);
  },

  createPayment(orgId, params) {
    const url = `/orgs/${orgId}/payments`;

    analytics.setTraits({ [CONSTS.DB_ANALYTICS_TRAITS.FIRST_TIME_PAY]: true });
    return postRequest(url, params);
  },

  retryFailedToCollect(orgId, payment) {
    const url = `/orgs/${orgId}/payments/${payment.id}/retry-failed-to-collect`;

    return postRequest(url, payment);
  },

  retryFailedToDeliver(orgId, payment) {
    const url = `/orgs/${orgId}/payments/${payment.id}/retry-failed-to-deliver`;

    return postRequest(url, payment);
  },

  retryFailedToDeliverWithToken({ token }: { token: string }) {
    const url = '/payment/retry-failed-to-deliver';

    return postRequest(url, { token });
  },

  deletePaymentById(orgId, id, params = {}) {
    const url = `/orgs/${orgId}/payments/${id}`;
    return deleteRequest(url, params);
  },

  editPaymentById(orgId, params) {
    const url = `/orgs/${orgId}/payments/${params.id}`;
    return putRequest(url, params);
  },

  getPaymentById(orgId, paymentId) {
    const url = `/orgs/${orgId}/payments/${paymentId}`;
    return fetchRequest(url, {});
  },
  approvePayment({ orgId, id, reason }) {
    const url = `/orgs/${orgId}/payments/${id}/approval-decision`;
    return postRequest(url, { status: 'approved', reason });
  },
  declinePayment({ orgId, id, reason }) {
    const url = `/orgs/${orgId}/payments/${id}/approval-decision`;
    return postRequest(url, { status: 'declined', reason });
  },

  createPaymentsByBatch({ orgId, payments }) {
    const url = `/orgs/${orgId}/payments/create-batch`;
    return postRequest(url, payments);
  },

  getDeliveryTime({
    orgId,
    deductionDate,
    deliveryMethodId,
    fundingSourceId,
    amount,
    paymentId = undefined,
    payBillFlowUUID = '',
    createOrigin = PAYMENT_CREATE_FLOW_ORIGIN.QBDT_WINDOWS,
  }: GetDeliveryTimeParamsForBatch) {
    return deliveryApi.getDeliveryTime({
      orgId,
      scheduledDate: deductionDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID,
      createOrigin,
    });
  },

  getPaymentFee({
    orgId,
    fundingSourceId,
    amount,
    scheduledDate,
    deliveryPreference,
    deliveryMethodId,
    cardFeePaidBy,
    allowDoubleFee = false,
  }: PaymentFeeProps) {
    const url = `/orgs/${orgId}/payments/fee`;
    return fetchRequest(url, {
      amount,
      fundingSourceId,
      scheduledDate,
      deliveryPreference,
      deliveryMethodId,
      cardFeePaidBy,
      allowDoubleFee,
    });
  },

  getPaymentFeeApi(orgId, params) {
    const url = `/orgs/${orgId}/payments/fee-api`;

    return fetchRequest(url, params);
  },

  getDetailsWithToken(params) {
    const url = '/payment/details';
    return fetchRequest(url, params);
  },

  getEmailToVendorData(params) {
    const url = '/payment/details/email-to-vendor';
    return fetchRequest(url, params);
  },

  updatePaymentByIdWithToken(params) {
    const url = `/payment/${params.id}/push-to-debit`;
    return putRequest(url, params);
  },

  setVirtualCardAsDeliveryMethodByIdWithToken(params) {
    const url = `/payment/${params.id}/push-to-virtual-card`;
    return putRequest(url, params);
  },

  sendEditCheckAddress(orgId, id) {
    const url = `/orgs/${orgId}/payments/${id}/send-edit-check-address`;
    return postRequest(url);
  },

  getVirtualCardData(params: { token: string; virtualCardId: string }) {
    const url = '/payment/details/virtual-card';

    return fetchRequest(url, params);
  },
};
