import React, { useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Icon } from '@melio/billpay-design-system';
import { useSelector } from 'react-redux';
import { StyledQBDTSuccessLayout } from 'src/pages/funding-sources/components/common';
import { ReactComponent as DoneIllustration } from 'src/images/general/action-done-icon.svg';
import settingsLocation from 'src/billpay/qbdt/pages/settings/locations';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { CONSTS } from 'src/utils/consts';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { AccountType } from 'src/utils/types';
import { getNameParts } from 'src/utils/funding-sources';
import { billingFeeAnalytics } from 'src/billpay/qbdt/pages/settings/components/billing/events';
import { MIFormattedText } from 'src/utils/formatting';

const getDisplayName = (fundingSource: AccountType) => {
  if (fundingSource?.cardAccount?.cardType === CONSTS.CARD_TYPES.DEBIT) {
    return 'billingFee.success.methods.debitCard';
  }

  if (fundingSource?.cardAccount?.cardType === CONSTS.CARD_TYPES.CREDIT) {
    return 'billingFee.success.methods.creditCard';
  }

  // for ACH and Plaid BA
  return 'billingFee.success.methods.bankAccount';
};

const getDigits = (fundingSource: AccountType) => getNameParts(fundingSource).identifier;

const getTextLabel = (isVerified = false, isMethodUpdated = false): string => {
  if (isMethodUpdated && isVerified) {
    return 'billingFee.success.sourceChangedText';
  }

  if (isMethodUpdated) {
    return 'billingFee.success.nonVerifiedSourceText';
  }

  if (isVerified) {
    return 'billingFee.success.verifiedSourceText';
  }

  return 'billingFee.success.nonVerifiedSourceText';
};

export const BillingFeeSuccessPage = () => {
  const { state } = useLocation<{
    fundingSourceId?: number;
    isMethodUpdated?: boolean;
  }>();
  const orgId = useOrgId();
  const history = useHistory();

  useEffect(() => {
    billingFeeAnalytics.visitSuccessPage();
  }, []);

  const fundingSource: AccountType = useSelector(fundingSourcesStore.selectors.byId(state.fundingSourceId));

  const textValues = {
    digits: getDigits(fundingSource),
    method: <MIFormattedText label={getDisplayName(fundingSource)} />,
  };

  const title = state?.isMethodUpdated ? 'billingFee.success.methodChangedTitle' : 'billingFee.success.title';

  const textLabel = getTextLabel(fundingSource?.isVerified, state?.isMethodUpdated);

  const handleDone = () => {
    history.push(generatePath(settingsLocation.billing, { orgId }));
  };

  return (
    <StyledQBDTSuccessLayout
      hideHeader
      title={title}
      text={textLabel}
      illustration={<Icon as={DoneIllustration} w="6rem" h="6rem" mb="ds.2xl" />}
      goExit={handleDone}
      buttonAction={handleDone}
      buttonLabel="billingFee.success.buttonLabel"
      textValues={textValues}
    />
  );
};
