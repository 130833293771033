import { BillingFeeTypeEnum } from 'src/billpay/qbdt/services/qbdt/types';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';

export type OrganizationBillingFee = {
  billingFeeType: BillingFeeTypeEnum;
  createdAt: string;
  createdById: number;
  deletedAt: null | string;
  fundingSourceId: number;
  id: number;
  isActive: boolean;
  managedByOrganizationId: null | number;
  organizationId: number;
  updatedAt: string;
  updatedById: number;
};

export type CreateOrganizationBillingFee = {
  fundingSourceId: number;
  managedByOrganizationId?: number | null;
  isActive?: boolean;
  billingFeeType?: BillingFeeTypeEnum;
};

export type UpdateOrganizationBillingFeeParams = {
  fundingSourceId: number;
  managedByOrganizationId?: number | null;
  isActive?: boolean;
};

export type UpdateBillingFeeResponse = {
  data: OrganizationBillingFee | null;
};

export const billingFeeApi = {
  getBillingFees({ orgId }: { orgId: number }) {
    const url = `/orgs/${orgId}/billing-fee`;
    return fetchRequest(url);
  },
  createBillingFee({ orgId, body }: { orgId: number; body: CreateOrganizationBillingFee }) {
    const url = `/orgs/${orgId}/billing-fee`;
    return postRequest(url, body);
  },
  updateBillingFee({
    orgId,
    billingFee,
    billingFeeId,
  }: {
    orgId: number;
    billingFeeId: number;
    billingFee: UpdateOrganizationBillingFeeParams;
  }) {
    const url = `/orgs/${orgId}/billing-fee/${billingFeeId}`;
    return putRequest(url, billingFee);
  },
  deleteBillingFee({ orgId, billingFeeId }: { orgId: number; billingFeeId: number }) {
    const url = `/orgs/${orgId}/billing-fee/${billingFeeId}`;
    return deleteRequest(url);
  },
};
