import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Link } from '@melio/billpay-design-system';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { isCompanyEligibleForFastCheck } from 'src/utils/delivery-methods';
import { setFaqPanel } from 'src/redux/payBillWizard/actions';

type Props = {
  onClose: () => void;
};

export const CheckDelaysNotificationCard = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const companyInfo = useSelector(getCompanyInfo);

  const isFastCheckAvailable = isCompanyEligibleForFastCheck(companyInfo);

  const title = isFastCheckAvailable
    ? 'checkDelaysWarning.batch.notification.title'
    : 'checkDelaysWarning.batch.notificationWithoutFastCheck.title';
  const subtitle = isFastCheckAvailable
    ? 'checkDelaysWarning.batch.notification.subtitle'
    : 'checkDelaysWarning.batch.notificationWithoutFastCheck.subtitle';
  const goLearnMoreCheckDelays = () => {
    dispatch(setFaqPanel({ isOpen: true, focusedQuestion: 18 }));
  };

  return (
    <Box mt="ds.xl" data-testid="batch-check-delays-notification-card">
      <QBONotificationCard
        type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
        title={{
          label: title,
        }}
        subtitle={{
          label: subtitle,
          values: {
            learnMoreLink: (...chunks) => (
              <Box as={Link} onClick={goLearnMoreCheckDelays}>
                {React.Children.toArray(chunks)}
              </Box>
            ),
          },
        }}
        onClose={onClose}
      />
    </Box>
  );
};
