import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';

type Props = {
  orgsRoutes: any;
};

export const OrgsRouter = ({ orgsRoutes }: Props) => (
  <Switch>
    {orgsRoutes.map((route, index) => (
      <Route key={index} {...route} />
    ))}
    <SmartRoute
      allowFor="authenticated"
      component={withSiteContext()(({ site }) => (
        <site.theme.pages.NotFoundPage />
      ))}
    />
  </Switch>
);
