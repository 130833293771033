import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';

type Params = {
  billId: string;
};

const useSchedulePaymentAction = ({ billId }: Params) => {
  const orgId = useSelector(getOrgId);
  const location = useLocation();
  const history = useHistory();

  const goSchedulePayment = useCallback(() => {
    const payFlowUrl = generatePath(locations.pay.funding, {
      orgId,
      billId,
    });
    const exitUrl = `${location.pathname}${location.search}`;

    // TODO: dashboard - implement bill sync before redirect to pay flow
    history.push(payFlowUrl, {
      exitUrl,
    });
  }, [orgId, billId, location.pathname, location.search, history]);

  return [goSchedulePayment];
};

export default useSchedulePaymentAction;
