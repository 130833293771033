import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from '../../utils/formatting';
import fullMoonImage from '../../images/general/full-moon.svg';
import { devices } from '../../theme/AppDevices';

type Props = {
  text: string;
  textValues?: Record<string, any>;
};

export const EmptyList = ({ text, textValues }: Props) => (
  <EmptyListContainer>
    <MiddleContainer>
      <EmptyListImage src={fullMoonImage} />
      <SubTitle data-testId="empty-list-title">
        <MIFormattedText label={text} values={textValues} />
      </SubTitle>
    </MiddleContainer>
  </EmptyListContainer>
);

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${devices.mobile}, ${devices.phablet} {
    height: calc(100vh - 46rem);
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding-bottom: 0;
  margin-top: 20rem;

  @media ${devices.tablet}, ${devices.desktop} {
    height: auto;
  }

  @media ${devices.desktop} {
    top: 48.5rem;
  }

  @media ${devices.tablet} {
    top: 51.5rem;
  }
`;

const EmptyListImage = styled.img`
  width: 10rem;
  height: 10rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.label};
  margin-top: 2rem;
  text-align: center;
`;
