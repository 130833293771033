import React from 'react';
import styled from 'styled-components';

type Props = {
  context?: 'button' | 'page';
};

const QuickbooksLoader = ({ context = 'page' }: Props) => {
  const Loading = context === 'button' ? ButtonLoader : PageLoader;
  return (
    <Loading className="circular" viewBox="25 25 50 50" data-testid="quickbooks-loader">
      <circle className="track" cx="50" cy="50" r="20" fill="none" strokeWidth="1.2" strokeMiterlimit="10" />
      <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="1.2" strokeMiterlimit="10" />
    </Loading>
  );
};

export default QuickbooksLoader;

const PageLoader = styled.svg`
  position: absolute;
  height: 12rem;
  width: 12rem;
  top: calc(50% - 6rem);
  left: calc(50% - 6rem);
  // animation: spinnerrotate 4s linear infinite;
  display: none;
  transform-origin: center center;

  .track {
    stroke: #f4f5f8;
    stroke-width: 0.2rem;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-width: 0.2rem;
    // animation: spinnerdash 4s cubic-bezier(0.15, 0, 0, 1) infinite,
    //   spinnercolor 4s linear infinite;
    display: none;
    stroke-linecap: round;
  }

  @keyframes spinnerrotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinnerdash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: 0;
    }

    100% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: -126;
    }
  }

  @keyframes spinnercolor {
    0% {
      stroke: rgba(16, 128, 0, 1);
    }

    50% {
      stroke: rgba(44, 160, 28, 1);
    }

    100% {
      stroke: rgba(44, 160, 28, 1);
    }
  }
`;

const ButtonLoader = styled.svg`
  position: absolute;
  height: 2.4rem;
  width: 2.4rem;
  top: calc(50% - 1.2rem);
  left: calc(50% - 1.2rem);
  animation: spinnerrotate 4s linear infinite;
  transform-origin: center center;

  .track {
    stroke: #f4f5f8;
    stroke-width: 0.2rem;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-width: 0.2rem;
    animation: spinnerdash 4s cubic-bezier(0.15, 0, 0, 1) infinite, spinnercolor 4s linear infinite;
    stroke-linecap: round;
  }

  @keyframes spinnerrotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinnerdash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: 0;
    }

    100% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: -126;
    }
  }

  @keyframes spinnercolor {
    0% {
      stroke: rgba(16, 128, 0, 1);
    }

    50% {
      stroke: rgba(44, 160, 28, 1);
    }

    100% {
      stroke: rgba(44, 160, 28, 1);
    }
  }
`;
