import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CONSTS } from 'src/utils/consts';
import { selectNewDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { useForm } from 'src/ui/form';
import profileStore from 'src/modules/profile/profile-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  vendorId: string;
  navigate: (url: string) => void;
  shouldIgnorePhone?: boolean;
};

const useVirtualDeliveryMethodState = ({ vendorId, navigate, shouldIgnorePhone = false }: Props) => {
  const dispatch = useDispatch();
  const vendorActions = useStoreActions(vendorsStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const [origin] = useLocationState('origin', null);
  const [redirectUrl] = useLocationState('redirectUrl', null);
  const [exitUrl] = useLocationState('exitUrl', null);
  const [changeDeliveryMethodType] = useLocationState('changeDeliveryMethodType', null);
  const { vendor, isVendorLoading } = useFetchVendor(vendorId);

  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isVendorUpdating = useSelector(vendorsStore.selectors.update.status(vendorId))?.loading;
  const existingVirtualDeliveryMethod = vendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL
  );
  const isDeliveryMethodCreating = useSelector(deliveryMethodsStore.selectors.create.status())?.loading;
  const isDeliveryMethodUpdating = useSelector(
    deliveryMethodsStore.selectors.update.status(existingVirtualDeliveryMethod?.id)
  )?.loading;
  const isFormProcessing = isDeliveryMethodCreating || isVendorUpdating || isDeliveryMethodUpdating;

  const model = useMemo(
    () => ({
      deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL,
      virtualAccount: {
        email: existingVirtualDeliveryMethod?.virtualAccount?.email || vendor?.contactEmail,
        phone: shouldIgnorePhone ? '' : existingVirtualDeliveryMethod?.virtualAccount?.phone || vendor?.contactPhone,
      },
    }),
    [vendor]
  );

  const submitAction = async (value) => {
    let payload;
    if (existingVirtualDeliveryMethod?.id) {
      const shouldUpdateVirtualAccount =
        existingVirtualDeliveryMethod.virtualAccount?.email !== value.virtualAccount.email ||
        existingVirtualDeliveryMethod.virtualAccount?.phone !== value.virtualAccount.phone;
      if (shouldUpdateVirtualAccount) {
        ({ payload } = await deliveryMethodActions.update({
          orgId,
          vendorId,
          id: existingVirtualDeliveryMethod.id,
          deliveryMethod: value,
        }));
      } else {
        payload = existingVirtualDeliveryMethod;
      }
    } else {
      ({ payload } = await deliveryMethodActions.create({
        orgId,
        vendorId,
        ...value,
      }));
    }

    const shouldUpdateVendor = !vendor.contactEmail || (!vendor.contactPhone && value.virtualAccount.phone);
    if (shouldUpdateVendor) {
      let vendorData = {};
      if (!vendor?.contactEmail) {
        vendorData = {
          ...vendorData,
          contactEmail: value.virtualAccount.email,
        };
      }

      if (!vendor?.contactPhone) {
        vendorData = {
          ...vendorData,
          contactPhone: value.virtualAccount.phone,
        };
      }

      await vendorActions.update({ orgId, id: vendorId, ...vendorData });
    }

    if (
      origin === CONSTS.DELIVERY_METHOD_ORIGIN.PAY_BILL ||
      origin === CONSTS.DELIVERY_METHOD_ORIGIN.EDIT_PAYMENT ||
      changeDeliveryMethodType
    ) {
      dispatch(selectNewDeliveryMethodAction(payload));
    }

    navigate(redirectUrl);
  };
  const [virtualDeliveryMethodVM, { submit }] = useForm(model, {
    submit: submitAction,
  });

  const goExit = () => {
    navigate(exitUrl);
  };

  return {
    virtualDeliveryMethodVM,
    submit,
    vendor,
    isFormProcessing,
    isVendorLoading,
    goExit,
  };
};

export default useVirtualDeliveryMethodState;
