import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';

const eventPage = 'pay-bill';

export const useGoEditNote = () => {
  const history = useHistory();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);

  const goEditNote = useCallback(() => {
    analytics.track(eventPage, 'edit-note');

    const url = payment.id
      ? generatePath(locations.edit.memo, {
          billId: bill.id,
          paymentId: payment.id,
          orgId,
        })
      : generatePath(locations.pay.memo, { billId: bill.id, orgId });

    history.push(url);
  }, [payment.id, bill.id, orgId]);

  return { goEditNote };
};
