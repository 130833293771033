import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';

export const useOnBatchExit = () => {
  const history = useHistory();
  const { exitUrl } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const batchActions = useStoreActions(regularBatchPaymentsStore);

  return {
    onBatchExit: () => {
      if (exitUrl) {
        batchActions.settings.resetStore();
        history.push(exitUrl);

        return;
      }

      close();
    },
  };
};
