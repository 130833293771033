import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import AmountInput from 'src/components/common/AmountInput';
import { VendorType } from 'src/utils/types';
import MIButton from 'src/components/common/MIButton';
import MISecurityDetails from 'src/components/common/MISecurityDetails';
import * as WizardElements from 'src/components/layout/WizardElements';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { devices } from 'src/theme/AppDevices';
import analytics from 'src/services/analytics/index';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { getNameInitials } from 'src/utils/string-utils';
import { useInvoiceNumber } from '../hooks/useInvoiceNumber';

type BackButtonProps = {
  onPrev?: (() => void) | null;
  analyticsProps?: Record<string, any>;
};

const BackButton = ({ onPrev, analyticsProps }: BackButtonProps) => {
  const handlePrevClick = () => {
    if (onPrev) {
      analytics.trackAction('go.back', {
        ...analyticsProps,
      });
      onPrev();
    }
  };

  return (
    <BackButtonContainer>
      <Back onClick={handlePrevClick} data-testid="previous-icon">
        <i className="icon-prev-icon-2" />
      </Back>
    </BackButtonContainer>
  );
};

type HeaderProps = {
  onPrev?: (() => void) | null;
  payment?: Record<string, any>;
  organization?: any;
  vendor?: VendorType;
  filesUrls?: Record<string, any>;
  showFullHeader?: boolean;
  hideLogo?: boolean;
  note?: string;
  subTitle?: string;
  description?: string;
  descriptionValues?: Record<string, any>;
  hideInvoice?: boolean;
};

const Header = ({
  onPrev,
  organization,
  vendor,
  payment,
  filesUrls,
  showFullHeader,
  hideLogo,
  note,
  subTitle,
  description,
  descriptionValues,
  hideInvoice,
}: HeaderProps) => {
  const companyName = organization?.companyName;
  const logoUrl = filesUrls?.logoUrl;
  const { invoiceNumber } = useInvoiceNumber(payment);
  const fileStorageUrl = filesUrls?.fileStorageUrl;

  const handleAttachmentClick = () => {
    if (fileStorageUrl) {
      analytics.trackAction('unilateral-view-attachment');
      window.open(fileStorageUrl);
    }
  };

  return (
    <HeaderContentContainer>
      {onPrev && (
        <BackButton
          onPrev={onPrev}
          analyticsProps={{
            flow: payment ? 'Unilateral with payment' : 'Unilateral without payment',
            vendorId: vendor?.id,
            vendorEmail: vendor?.contactEmail,
            payorOrgId: organization?.id,
          }}
        />
      )}
      <VendorInfoContainer>
        {!hideLogo &&
          (logoUrl ? (
            <VendorLogo src={logoUrl} alt={companyName} />
          ) : (
            <DefaultLogoContainer>{getNameInitials(companyName)}</DefaultLogoContainer>
          ))}
        <VendorTitle data-testid="title-vendor-name">{companyName}</VendorTitle>
        <VendorSubTitle>{showFullHeader && subTitle && <MIFormattedText label={subTitle} />}</VendorSubTitle>
        {payment && (
          <AmountInputContainer fullView={showFullHeader}>
            <AmountInput totalAmount={payment?.amount} viewOnly />
          </AmountInputContainer>
        )}
        {description && showFullHeader && (
          <Description>
            <MIFormattedText label={description} values={descriptionValues} />
          </Description>
        )}
        {!hideInvoice && invoiceNumber && showFullHeader && (
          <InvoiceAttachmentContainer onClick={handleAttachmentClick}>
            <InvoiceLabel>
              <MIFormattedText label="vendors.addDeliveryMethodByLink.for" />
            </InvoiceLabel>
            <InvoiceName>{invoiceNumber}</InvoiceName>
          </InvoiceAttachmentContainer>
        )}
        {note && showFullHeader && (
          <VendorInfoHint>
            <MIFormattedText
              label="vendors.addDeliveryMethodByLink.includedNote"
              values={{ note: <InvoiceNote>{note}</InvoiceNote> }}
            />
          </VendorInfoHint>
        )}
      </VendorInfoContainer>
    </HeaderContentContainer>
  );
};

type ContentWrapperProps = {
  children: ReactNode;
  title?: string;
  subTitle?: string;
  titleValues?: Record<string, any>;
};

const ContentWrapper = ({ children, title, subTitle, titleValues }: ContentWrapperProps) => (
  <ContentContainer>
    {title && (
      <ContentTitle>
        <MIFormattedText label={title} values={titleValues} />
      </ContentTitle>
    )}
    {subTitle && (
      <ContentSubTitle>
        <MIFormattedText label={subTitle} />
      </ContentSubTitle>
    )}
    {children}
  </ContentContainer>
);

type FooterProps = {
  companyName: string;
};

const Footer = ({ companyName }: FooterProps) => {
  const site = useSiteContext();

  return (
    <React.Fragment>
      <FooterText>
        <MIFormattedText
          label="vendors.addDeliveryMethodByLink.confirmMelio"
          values={{
            termsOfService: (
              <Link
                href={site.config.agreementLinks.userAgreement}
                target="_blank"
                onClick={() => {
                  analytics.track('unilateral', 'terms-of-service');
                }}
              >
                <MIFormattedText label="vendors.addDeliveryMethodByLink.termsOfService" />
              </Link>
            ),
            privacyPolicy: (
              <Link
                href={site.config.agreementLinks.privacyPolicy}
                target="_blank"
                onClick={() => {
                  analytics.track('unilateral', 'privacy-policy');
                }}
              >
                <MIFormattedText label="vendors.addDeliveryMethodByLink.privacyPolicy" />
              </Link>
            ),
          }}
        />
      </FooterText>
      <MISecurityDetails
        eventPage="unilateral-payment"
        label="vendors.deliveryMethods.virtual.securityText"
        values={{ companyName }}
      />
    </React.Fragment>
  );
};

type SubmitButtonMobileProps = {
  submit: any;
  label: string;
  isLoading?: boolean;
};

const SubmitButtonMobile = ({ submit, label, isLoading }: SubmitButtonMobileProps) => (
  <WizardElements.WizardStepActionsContainerDocked>
    <FooterButtonContainer
      label={label}
      variant={CONSTS.BUTTON_VARIANT.PRIMARY}
      onClick={submit}
      isProcessing={isLoading}
      fullWidth
    />
  </WizardElements.WizardStepActionsContainerDocked>
);

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.opaque};
  padding: 4rem;
  border-radius: 0.6rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
  padding-top: 4rem;
`;

const ContentTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-align: left;
  line-height: 2.8rem;
  font-size: 1.8rem;
`;

const labelStyles = css`
  color: ${(props) => props.theme.text.color.label};
  ${(props) => props.theme.text.fontType.hint};
`;

const ContentSubTitle = styled.div`
  ${labelStyles}
  line-height: 1.8rem;
  text-align: left;
  font-size: 2rem;
  line-height: 2.8rem;
`;

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  align-items: center;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
  padding-bottom: 3rem;
`;

const picStyles = css`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.progress.undone};
`;

const DefaultLogoContainer = styled.div`
  ${picStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.light.opaque};
  font-size: ${(props) => props.theme.text.size.subNav};
  color: white;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-transform: uppercase;
`;

const VendorLogo = styled.img`
  ${picStyles}
  display: block;
  object-fit: contain;
`;

const VendorTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => props.theme.text.fontType.regular};
`;

const VendorSubTitle = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.subtitle};
  text-transform: lowercase;
`;

const VendorInfoHint = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.label};
  margin-top: 0.6rem;
`;

const VendorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceAttachmentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
`;
const InvoiceNote = styled.div`
  font-weight: 500;
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.medium};
`;

const InvoiceName = styled.span`
  margin-left: 0.3rem;
  color: ${(props) => props.theme.text.color.highlight};
  ${(props) => props.theme.text.fontType.medium};
`;

const InvoiceLabel = styled.span`
  ${labelStyles}
  display: flex;
  align-items: center;
`;

const AmountInputContainer = styled.div<{ fullView?: boolean }>`
  ${(p) =>
    !p.fullView &&
    css`
      margin-bottom: -2rem;
    `};
  margin-top: 0;
  font-weight: ${(props) => props.theme.text.weight.semiBold};

  padding-top: 1rem;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  left: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    left: 2rem;
  }
`;

const Back = styled.div`
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.text.color.label};
  margin-top: 0;

  i {
    cursor: pointer;
  }
`;

const FooterText = styled.div`
  margin: 1rem 0 2rem;
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  text-align: center;
`;

const Link = styled.a`
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
`;

const FooterButtonContainer = styled(MIButton)`
  margin-bottom: 2rem;
`;

const Description = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
  display: inline;
`;

export { Header, BackButton, ContentWrapper, Footer, SubmitButtonMobile };
