import vendorsApi from 'src/services/api/vendors';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';

const vendorUpdateTrustedVendor = createApiCallSlice<any, any>({
  name: '[VENDORS] UPDATE_TRUSTED_FIELD',
  api: vendorsApi.updateTrustedVendor,
  initialState: {
    byId: {},
    updateTrustedVendor: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.updateTrustedVendor.loading = true;
      state.updateTrustedVendor.error = undefined;
      state.byId[action.payload.vendorId].vendorTrusted = !state.byId[action.payload.vendorId].vendorTrusted;
    },
    [ON_SUCCESS]: (state, action) => {
      state.updateTrustedVendor.loading = false;
      state.updateTrustedVendor.error = undefined;
      state.byId[action.payload.object.id] = action.payload.object;
    },
    [ON_FAILURE]: (state, action) => {
      state.updateTrustedVendor.loading = false;
      state.updateTrustedVendor.error = action.error;
      state.byId[action.payload.vendorId].vendorTrusted = !state.byId[action.payload.vendorId].vendorTrusted;
    },
  },
  selectors: {},
});

export default vendorUpdateTrustedVendor;
