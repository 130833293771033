import React from 'react';
import { VendorEmailInput } from 'src/billpay/qbdt/modules/vendor-email/components/VendorEmalInput';
import { useUpdateVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useUpdateVendorEmail';
import { useValidateVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useValidateVendorEmail';
import { Modal, ModalSubtitle, ModalTextContent, ModalTitle } from 'src/theme/ds';
import MIButton from 'src/components/common/MIButton';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import styled from 'styled-components';
import { BATCH_PAGE_EVENT } from '../../../analytics/event-mapping';

type Props = {
  vendorId: number;
  dismiss: VoidFunction;
};

const CollectVendorEmailModal = ({ vendorId, dismiss }: Props) => {
  const { loading, updateVendorEmail } = useUpdateVendorEmail({ eventPage: BATCH_PAGE_EVENT });
  const { getVendorEmail, setVendorEmail, getValidationError, validateVendorEmail } = useValidateVendorEmail({
    eventPage: BATCH_PAGE_EVENT,
  });

  const handleEmailChange = ({ id, value }) => {
    setVendorEmail({ id, value });
  };

  const onCloseClick = (event) => {
    event.stopPropagation();
    dismiss();
  };

  const handleSubmitClick = async () => {
    const email = getVendorEmail(vendorId);
    const isEmailValid = validateVendorEmail({ id: vendorId, optional: false });

    if (!isEmailValid) {
      return;
    }

    await updateVendorEmail({ id: vendorId, email });
  };

  return (
    <Modal id="batch-collect-vendor-email-dialog" onCloseClick={onCloseClick}>
      <ModalTextContent>
        <ModalTitle label="regularBatchPayments.dialog.vendorEmail.title" />
        <ModalSubtitle label="regularBatchPayments.dialog.vendorEmail.subtitle" />
      </ModalTextContent>

      <VendorEmailInput
        vendorId={vendorId.toString()}
        value={getVendorEmail(vendorId)}
        error={getValidationError(vendorId)}
        onChange={handleEmailChange}
        required
      />

      <PrimaryButton
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={handleSubmitClick}
        label="general.save"
        size={BUTTON_SIZE.NORMAL}
        isProcessing={loading}
      />
    </Modal>
  );
};

const PrimaryButton = styled(MIButton)`
  width: 8.4rem;
  padding: 0;
  align-self: center;
`;

export { CollectVendorEmailModal };
