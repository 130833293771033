import React from 'react';
import { RecordOf } from 'immutable';
import { CARD_TYPES, FUNDING_LOGO, FUNDING_TYPE } from 'src/utils/consts';
import { getCardLogo } from 'src/utils/card';
import { AccountType } from 'src/utils/types';

type FundingSourceIconProps = {
  fundingSource: RecordOf<AccountType>;
};

const FundingSourceIcon = ({ fundingSource }: FundingSourceIconProps) => {
  const bankIcon =
    fundingSource.fundingType === FUNDING_TYPE.ACH &&
    fundingSource.logo &&
    fundingSource.logo !== FUNDING_LOGO.DEFAULT ? (
      <img src={`data:image/jpeg;base64,${fundingSource.logo}`} alt="funding source icon" />
    ) : (
      <div className="icon-bank-icon" />
    );
  const cardType = fundingSource.cardAccount?.cardType || '';
  const network = (fundingSource.cardAccount?.network || '').toUpperCase();
  let icon = bankIcon;

  const isCreditCard = cardType === CARD_TYPES.CREDIT;
  const isDebitCard = cardType === CARD_TYPES.DEBIT;

  const isCreditOrDebitCard = isCreditCard || isDebitCard;

  if (fundingSource && isCreditOrDebitCard) {
    icon = getCardLogo(cardType, network);
  }

  return icon;
};

export default FundingSourceIcon;
