import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { BusinessType } from 'src/utils/types';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import { devices } from 'src/theme/AppDevices';
import { getErrorCode } from 'src/redux/payBillWizard/selectors';
import failedToLoad from 'src/images/qbo/failed-to-load-002-icon.png';
import analytics from 'src/services/analytics';
import ErrorPage from 'src/components/layout/ErrorLayoutPage';
import { OnSelectNaicsCode } from 'src/billpay/qbdt/components/CompanyIndustryAutocomplete';
import ComplianceInfoForm from './ComplianceInfoForm';

type Props = {
  isLoading: boolean;
  businessType: BusinessType | null;
  validationErrors: Record<string, any>;
  isAfterComplianceGracePeriod: boolean;
  goExit: () => void;
  handleOnSkip: () => void;
  handleOnSubmit: () => void;
  onResetNaicsCode: () => void;
  onSelectNaicsCode: OnSelectNaicsCode;
  onSelectBusinessType: (value: BusinessType) => void;
};

export const QBDTPayBillCompleteComplianceInfoPage = ({
  isLoading,
  businessType,
  validationErrors,
  isAfterComplianceGracePeriod,
  goExit,
  handleOnSkip,
  handleOnSubmit,
  onResetNaicsCode,
  onSelectNaicsCode,
  onSelectBusinessType,
}: Props) => {
  const errorCode = useSelector(getErrorCode);

  if (errorCode) {
    analytics.track('bill-error', 'payment-cannot-be-approved');
    return (
      <ErrorPage
        illustration={failedToLoad}
        title="bills.pay.confirm.error.approvingPaymentTitle"
        subtitle="bills.pay.confirm.error.approvingPaymentSubtitle"
        buttonAction={goExit}
        buttonLabel="bills.pay.confirm.error.approvingPaymentCTA"
      />
    );
  }

  const calculateLayoutTitle = (isLoading: boolean, isAfterComplianceGracePeriod: boolean) => {
    if (isLoading) {
      return 'bills.pay.confirm.completeComplianceInfo.titleLoading';
    }

    if (isAfterComplianceGracePeriod) {
      return 'bills.pay.confirm.completeComplianceInfo.afterComplianceGracePeriod.title';
    }

    return 'bills.pay.confirm.completeComplianceInfo.title';
  };

  const calculateLayoutSubtitle = (isLoading: boolean, isAfterComplianceGracePeriod: boolean) => {
    if (isLoading) {
      return 'bills.pay.confirm.completeComplianceInfo.subtitleLoading';
    }

    if (isAfterComplianceGracePeriod) {
      return 'bills.pay.confirm.completeComplianceInfo.afterComplianceGracePeriod.subtitle';
    }

    return 'bills.pay.confirm.completeComplianceInfo.subtitle';
  };

  const layoutTitle = calculateLayoutTitle(isLoading, isAfterComplianceGracePeriod);
  const layoutSubtitle = calculateLayoutSubtitle(isLoading, isAfterComplianceGracePeriod);
  const nextLabel = isAfterComplianceGracePeriod
    ? 'bills.pay.confirm.completeComplianceInfo.afterComplianceGracePeriod.action'
    : 'bills.pay.confirm.completeComplianceInfo.action';
  const skipLabel = isAfterComplianceGracePeriod ? undefined : 'bills.pay.confirm.completeComplianceInfo.skipAction';
  const onSkip = isAfterComplianceGracePeriod ? undefined : handleOnSkip;

  return (
    <QBOLayoutPage
      title={layoutTitle}
      subtitle={layoutSubtitle}
      isLoading={isLoading}
      innerSize={65}
      nextLabel={nextLabel}
      skipLabel={skipLabel}
      goExit={goExit}
      onSkip={onSkip}
      onNext={handleOnSubmit}
    >
      <ComplianceInfoContainer>
        <FormWrapper>
          <ComplianceInfoForm
            isLoading={isLoading}
            businessType={businessType}
            validationErrors={validationErrors}
            onResetNaicsCode={onResetNaicsCode}
            onSelectNaicsCode={onSelectNaicsCode}
            onSelectBusinessType={onSelectBusinessType}
          />
        </FormWrapper>
      </ComplianceInfoContainer>
    </QBOLayoutPage>
  );
};

const ComplianceInfoContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 45rem;
  padding-top: 0.7rem;

  @media ${devices.desktop} {
    justify-content: space-between;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2.4rem 0 2rem;
  }
`;

const FormWrapper = styled.div``;
