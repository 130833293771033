import { isVirtualCardPaymentExpired } from 'src/utils/payments';
import { PaymentType } from 'src/utils/types';

export const getFailedPaymentTitle = (payment: PaymentType | null) => {
  const isVirtualPaymentFailedToDeliver = payment && isVirtualCardPaymentExpired(payment);

  if (isVirtualPaymentFailedToDeliver) {
    return 'bills.status.paymentFailedResend';
  }

  return 'bills.status.paymentFailedActionRequired';
};

export const getFailedPaymentDescription = (payment: PaymentType | null) => {
  const key = payment?.metadata?.failureMessage || 'default';

  return `bills.form.paymentActivity.failedPaymentDescription.${key}`;
};
