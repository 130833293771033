import React, { PropsWithChildren } from 'react';
import { Stack } from '@melio/billpay-design-system';

type Props = {
  direction?: 'row' | 'column';
  align?: 'initial' | 'center';
};

export const ActionButtonsWrapper = ({
  direction = 'column',
  align = 'initial',
  children,
}: PropsWithChildren<Props>) => (
  <Stack direction={direction} spacing="ds.md" alignItems={align} mt="ds.lg" data-testId="view-payment-actions">
    {children}
  </Stack>
);
