import { chakra } from 'src/theme/ds';
import EmailLink from './EmailLink';

export const QBDTEmailLink = chakra(EmailLink, {
  baseStyle: {
    color: 'ds.blue.200',
    _hover: {
      color: 'ds.blue.100',
    },
    _active: {
      color: 'ds.blue.100',
    },
  },
});
