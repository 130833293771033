import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import { getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { withNavigator } from 'src/hoc';
import { MIFormattedText } from 'src/utils/formatting';
import AreaLoader from 'src/components/common/AreaLoader';
import { QBDTStepLayout, SimpleTextFooter } from 'src/billpay/qbdt/components';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { CONSTS } from 'src/utils/consts';

import useVirtualDeliveryMethodState from './hooks/useVirtualDeliveryMethodState';

type Props = {
  locationState: Record<string, any>;
  navigate: (arg0: string) => void;
  vendorId: string;
};

const VirtualDeliveryMethodPageContainer = ({ navigate, vendorId }: Props) => {
  const fundingSource = useSelector(getSelectedFundingSource);
  const {
    virtualDeliveryMethodVM,
    submit,
    vendor,
    isFormProcessing,
    isVendorLoading,
    goExit,
  } = useVirtualDeliveryMethodState({
    vendorId,
    navigate,
    shouldIgnorePhone: true,
  });

  if (isVendorLoading) {
    return <AreaLoader />;
  }

  let subtitle = 'vendors.deliveryMethods.virtual.subtitle';
  const title = 'vendors.deliveryMethods.virtual.checkFee.title';
  const email = 'vendors.deliveryMethods.virtual.checkFee.email';
  const done = 'vendors.deliveryMethods.virtual.checkFee.done';

  if (fundingSource?.fundingType === CONSTS.FUNDING_TYPE.ACH) {
    subtitle = 'vendors.deliveryMethods.virtual.checkFee.subtitle';
  }

  const vendorCompanyName = vendor?.companyName || vendor?.printName;
  return (
    <QBDTStepLayout
      title={title}
      titleValues={{ vendor: vendorCompanyName }}
      subtitle={subtitle}
      subTitleValues={{ vendor: vendorCompanyName }}
      goExit={goExit}
      onNext={submit}
      nextLabel={done}
      isLoading={isFormProcessing}
      innerSize={50}
      footer={
        <SimpleTextFooter>
          <MIFormattedText label="vendors.deliveryMethods.virtual.footer" values={{ vendor: vendorCompanyName }} />
        </SimpleTextFooter>
      }
    >
      <FormContainer>
        <WizardTextInputField
          label={email}
          model={virtualDeliveryMethodVM.virtualAccount.email}
          required
          type="email"
          autoFocus
          autocomplete="username email"
          privateData
        />
      </FormContainer>
    </QBDTStepLayout>
  );
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;

export default compose(withNavigator())(VirtualDeliveryMethodPageContainer);
