import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AddOptionComponent } from 'src/components/common/DropDown/components/AddOptionComponent';
import { DropDownContainer } from 'src/components/common/DropDown/MIDropDown';
import { InputContainer, List } from 'src/components/common/MIAutocompleteExtras/components';
import { Suggestion, SuggestionType } from 'src/components/common/MIAutocompleteExtras/Suggestion';
import { ReactComponent as CloseButton } from 'src/images/general/close-icon.svg';
import { Flex, Icon } from '@melio/billpay-design-system';
import { ModelViewField } from 'src/ui/form/use-form/useForm';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import errorTracker from 'src/utils/error-tracking';
import { MIFormattedText } from 'src/utils/formatting';
import { TextInputSize } from 'src/utils/types';
import withOutsideClickHandler from 'src/hoc/withOutsideClickHandler';
import styled from 'styled-components';

const DEBOUNCE_DELAY = 300;

const defaultShouldShowSuggestions = (suggestions) => suggestions.length > 0;
type Void = void | Promise<void>;
type OnType = (value: string) => Void;
export type OnSelected<ValueType> = (value: SuggestionType<ValueType> | null) => void;

export interface RenderSuggestionsProps<T> {
  suggestions: SuggestionType<T>[];
  onSelectedOption: OnSelected<T>;
  text: string;
}

export interface Props<ValueType> {
  id?: any;
  size?: TextInputSize;
  text?: string;
  hint?: string;
  top?: string;
  label?: any;
  model?: ModelViewField<string>;
  required?: any;
  hideInput?: boolean;
  autoFocus?: any;
  placeholder?: string;
  privateData?: boolean;
  stickyLabel?: string;
  errorMessage?: string;
  autocomplete?: any;
  cantFindSuggestionsText?: string;
  renderSuggestionsFromLength?: number;
  onType?: OnType;
  onSelected: OnSelected<ValueType>;
  getSuggestions: (text: string) => any;
  renderSuggestions?: (props: RenderSuggestionsProps<ValueType>) => JSX.Element;
  onClickCreateOption?: () => void;
  shouldShowSuggestions?: (suggestions: SuggestionType<ValueType>[]) => any;
  onSuggestionsLoadError?: () => void;
  onCantFindSuggestionsOptionClick?: () => void;
}

export const MIAutocomplete = <ValueType,>({
  id,
  size,
  text = '',
  top,
  hint,
  label,
  model,
  required,
  hideInput,
  autoFocus,
  placeholder,
  privateData,
  stickyLabel,
  errorMessage,
  autocomplete = 'off',
  cantFindSuggestionsText,
  renderSuggestionsFromLength = 0,
  onType,
  onSelected,
  getSuggestions: searchSuggestions,
  renderSuggestions,
  onClickCreateOption,
  shouldShowSuggestions = defaultShouldShowSuggestions,
  onSuggestionsLoadError,
  onCantFindSuggestionsOptionClick,
}: Props<ValueType>) => {
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const listRef = useRef<HTMLInputElement>();
  const [suggestions, setSuggestions] = useState<SuggestionType<ValueType>[]>([]);
  const [cantFindSuggestionsOptionVisible, setCantFindSuggestionsOptionVisible] = useState(false);

  const resetSuggestionBox = () => {
    setSuggestions([]);
    setOpen(false);
  };

  const loadSuggestions = useCallback(
    async (query: string) => {
      try {
        return await searchSuggestions(query);
      } catch (err: any) {
        onSuggestionsLoadError?.();
        errorTracker.capture(err, {
          message: `Failed to load address suggestions in MIAutocomplete component`,
        });

        return [];
      }
    },
    [onSuggestionsLoadError, searchSuggestions]
  );

  const debouncedSearchSuggestions = useMemo(
    () =>
      debounce(async (query: string) => {
        const suggestionsLoaded = await loadSuggestions(query);

        const isInputFocused = document.activeElement === inputRef.current;
        setSuggestions(suggestionsLoaded);

        if (!isInputFocused) {
          return;
        }

        const suggestionsVisible = shouldShowSuggestions(suggestionsLoaded);

        if (!suggestionsVisible && cantFindSuggestionsText) {
          setOpen(true);
          setCantFindSuggestionsOptionVisible(true);
        }

        if (suggestionsVisible) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          listRef.current!.scrollTop = 0;
          setCantFindSuggestionsOptionVisible(false);
          setOpen(true);
        }
      }, DEBOUNCE_DELAY),
    [loadSuggestions, shouldShowSuggestions]
  );

  const onChange = useCallback(
    ({ value }: { value: string }) => {
      if (value && renderSuggestionsFromLength <= value.length) {
        debouncedSearchSuggestions(value);
      } else {
        listRef.current!.scrollTop = 0;
        debouncedSearchSuggestions.cancel();
      }

      resetSuggestionBox();
      onType?.(value);
    },
    [onType, renderSuggestionsFromLength, debouncedSearchSuggestions]
  );

  const onSelectedOption = useCallback(
    (option) => {
      onSelected?.(option);
      resetSuggestionBox();
    },
    [onSelected]
  );

  const onClearClick = useCallback(() => {
    onSelected?.(null);
    onType?.('');
    resetSuggestionBox();
  }, [onSelected, onType]);

  const handleClickOutside = useCallback(() => setOpen(false), []);
  const isExpanded = !!suggestions?.[0]?.sublabel;

  return (
    <SingleSelectContainer size={size} handleClickOutside={handleClickOutside} hideInput={hideInput}>
      <InputContainer>
        <WizardTextInputField
          id={id}
          label={label}
          placeholder={placeholder}
          model={model}
          value={text}
          required={required}
          autoFocus={autoFocus}
          autocomplete={autocomplete}
          onChange={onChange}
          type="search"
          inputRef={inputRef}
          privateData={privateData}
          hint={hint}
          errorMessage={errorMessage}
        />
        {text && (
          <Flex
            onClick={onClearClick}
            position="absolute"
            zIndex="2"
            top={label ? 4 : 0}
            right={-1}
            cursor="pointer"
            opacity={0.3}
            w="4rem"
            h="3.8rem"
            align="center"
            justify="center"
          >
            <Icon as={CloseButton} color="ds.gray.200" />
          </Flex>
        )}
      </InputContainer>
      <DropDownContainer hidden={!isOpen} className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined} top={top}>
        <List ref={listRef} data-testid="suggestions-list">
          {cantFindSuggestionsOptionVisible ? (
            <CantFindSuggestionsOption
              onClick={() => {
                onCantFindSuggestionsOptionClick?.();
                resetSuggestionBox();
                setCantFindSuggestionsOptionVisible(false);
              }}
              data-testid="cant-find-address"
            >
              <MIFormattedText label={cantFindSuggestionsText} />
            </CantFindSuggestionsOption>
          ) : null}
          {renderSuggestions?.({ suggestions, onSelectedOption, text }) ??
            suggestions.map((option) => (
              <Suggestion
                isExpanded={isExpanded}
                onSelectedOption={onSelectedOption}
                option={option}
                key={option.label}
              />
            ))}
        </List>
        {onClickCreateOption && (
          <AddOptionComponent
            createOption={() => {
              onSelectedOption(null);
              onClickCreateOption();
            }}
            text={text}
            label={stickyLabel!}
            options={[]}
          />
        )}
      </DropDownContainer>
    </SingleSelectContainer>
  );
};

type CantFindSuggestionsOptionProps = {
  children?: React.ReactNode;
  'data-testid': string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const CantFindSuggestionsOption = ({ children, ...rest }: CantFindSuggestionsOptionProps) => (
  <Flex
    h="3rem"
    w="full"
    textStyle="body4"
    alignItems="center"
    bg="white"
    py={0}
    px={2}
    color="primary.500"
    boxSizing="border-box"
    cursor="pointer"
    zIndex={100}
    {...rest}
  >
    {children}
  </Flex>
);

const SingleSelectContainer = withOutsideClickHandler(styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: ${({ hideInput }) => (hideInput ? 'none' : 'block')};
  margin-bottom: ${(props) => (props.size === CONSTS.TEXT_INPUT_SIZE.WIZARD ? '4rem' : '1.6rem')};
`);
