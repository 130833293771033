import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Tooltip } from '@melio/billpay-design-system';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';

type InvoiceNumberCellProps = {
  invoiceNumber: string | null;
};

export const InvoiceNumberCell = ({ invoiceNumber }: InvoiceNumberCellProps) => {
  const intl = useIntl();
  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();

  const label = invoiceNumber
    ? intl.formatMessage({ id: 'regularBatchPayments.cells.bills.invoiceNumber' }, { invoiceNumber })
    : intl.formatMessage({
        id: 'regularBatchPayments.cells.bills.noInvoiceNumber',
      });

  return useMemo(
    () => (
      <Tooltip placement="top" isDisabled={!isTruncated} label={label}>
        <Box textStyle="ds.body3" isTruncated ref={ref}>
          {label}
        </Box>
      </Tooltip>
    ),
    [invoiceNumber, isTruncated]
  );
};
