import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { catchAsync } from 'src/utils/async';
import { PaymentType } from 'src/utils/types';
import { getOrgId } from 'src/redux/user/selectors';
import paymentsApi from 'src/services/api/payments';

type Props = {
  paymentId: string;
  eventPage: string;
};

const useLoadPayment = ({ paymentId, eventPage }: Props) => {
  const orgId = useSelector(getOrgId);

  const [loading, setLoading] = useState<boolean>(false);
  const [payment, setPayment] = useState<PaymentType | undefined>();
  const [errorCode, setErrorCode] = useState<string | undefined>();

  const loadPayment = async () => {
    setLoading(true);

    const [error, data] = await catchAsync(paymentsApi.getPaymentById(orgId, paymentId));

    setLoading(false);

    if (error) {
      if (error.code === 'PMT01') {
        setErrorCode(error.code);
      }

      analytics.track(eventPage, 'payment-not-found');
      return;
    }

    setPayment(data?.object);
  };

  useEffect(() => {
    loadPayment();
  }, []);

  return {
    payment,
    errorCode,
    loading,
  };
};

export { useLoadPayment };
