import React from 'react';
import styled from 'styled-components';
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import { Box, Flex } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';
import { BillsInfoTable } from './BillsInfoTable';
import { BillInfo } from './types';

const BillsInfoPopover = ({ children, billsInfo }: { children: React.ReactNode; billsInfo: BillInfo[] }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <PopoverContainer>
      <Box __css={popoverStyle}>
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom">
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent left="-25%" onClick={(e) => e.stopPropagation()}>
            <PopoverArrow />
            <PopoverBody padding="2rem">
              <Flex direction="row" justifyContent="flex-end">
                <PopoverCloseButton className="icon-close-icon" onClick={onClose} />
              </Flex>

              <BillsInfoTable data={billsInfo} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </PopoverContainer>
  );
};

const PopoverCloseButton = chakra('i', {
  baseStyle: {
    color: 'ds.gray.200',
    fontSize: '2rem',
    cursor: 'pointer',
    alignSelf: 'end',
  },
});

const PopoverContainer = styled.div`
  position: relative;
  margin-top: 0;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.regular};

  .chakra-popover__popper {
    z-index: 1;
  }

  .chakra-popover__content {
    outline: none;
  }

  .chakra-popover__body {
    border-radius: var(--chakra-radii-ds-md);
    border: 1px solid var(--chakra-colors-ds-gray-500);
    box-shadow: var(--chakra-shadows-ds-md);
    background-color: white;
  }
`;

// Doesn't work without !important. Took it from billpay-web/
const arrowStyle: React.CSSProperties = {
  position: 'absolute',
  left: '50%!important',
  height: '11.31px!important',
  width: '11.31px!important',
  background: 'var(--chakra-colors-white)',
  // zIndex could be number or Globals in React types, but string is also valid
  zIndex: '10001!important' as React.CSSProperties['zIndex'],
  transform: 'rotate(45deg)!important',
};

const popoverStyle: Record<string, React.CSSProperties> = {
  '[data-popper-placement*="bottom"] .chakra-popover__arrow-positioner': {
    ...arrowStyle,
    top: '-6px!important',
    borderLeft: '1px solid var(--chakra-colors-ds-gray-500)',
    borderTop: '1px solid var(--chakra-colors-ds-gray-500)',
  },

  '[data-popper-placement*="bottom"] .chakra-popover__content': {
    marginTop: '1rem',
  },

  '[data-popper-placement*="top"] .chakra-popover__arrow-positioner': {
    ...arrowStyle,
    bottom: '-6px!important',
    borderRight: '1px solid var(--chakra-colors-ds-gray-500)',
    borderBottom: '1px solid var(--chakra-colors-ds-gray-500)',
  },

  '[data-popper-placement*="top"] .chakra-popover__content': {
    marginBottom: '1rem',
  },
};

export { BillsInfoPopover };
