// <reference types="googlemaps" />
/* eslint-disable camelcase */
import React from 'react';
import { List, RecordOf } from 'immutable';
import * as History from 'history';
import {
  BUTTONS_DIRECTIONS,
  BUTTONS_ROW_POSITION,
  IMAGE_POSITION,
  CREDIT_CARD_FEE_PAYMENT,
  ORGANIZATION_CREATE_ORIGIN,
  PAY_EDIT_LOCATION_NAME,
  DELIVERY_TYPE,
} from './consts';
import * as CONSTS from './consts';

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;
export type ValuesOf<T> = T[keyof T];

export type FieldType = { id: string; value: string };
export type FieldDateType = { id: string; value: Date | null };
export type DateFieldType = { id: string; date: Date | null };

export type ExtendedSelectOption = SelectOption & { id: string; values: any };

export type SelectOption = {
  label: string;
  value: any;
  selected: boolean;
  disabled: boolean;
};

export type AccountingSoftwareType =
  | CONSTS.ACCOUNTING_SOFTWARE.QUICKBOOKS
  | CONSTS.ACCOUNTING_SOFTWARE.QUICKBOOKS_DESKTOP
  | CONSTS.ACCOUNTING_SOFTWARE.FRESHBOOKS
  | CONSTS.ACCOUNTING_SOFTWARE.NONE;
export type UserPreferencesType = {
  accountingSoftwareType: AccountingSoftwareType | null | undefined;
  sendBillDueEmailReminder: boolean | null | undefined;
  billQbSyncedNotification: boolean | null | undefined;
  allowCompaniesSwitcher: boolean | null | undefined;
  getPaidProLayout: boolean | null | undefined;
  qbDashboardSeen: boolean | null | undefined;
  showQbDashboardInternalEntrypoints: boolean | null | undefined;
  qbExistingDebitCardFeeModal: boolean | null | undefined;
  qbExistingCheckFeeModal: boolean | null | undefined;
  qbdtBatchBulkPaymentsWalkthroughStepOneSeen: boolean | null | undefined;
  qbdtBatchBulkPaymentsWalkthroughStepTwoSeen: boolean | null | undefined;
  checkFeesNotificationCount: string | null | undefined;
  hideBatchPaymentsHolidaysNotification: boolean | null | undefined;
  hideSinglePayHolidaysNotification: boolean | null | undefined;
  qbdtDashboardSeen: boolean | null | undefined;
};

export type PaymentFeeItem = {
  feeType: string;
  isFixed: boolean;
  amount: number;
  percent: number | null;
  cap: number | null;
  feeCatalog?: organizationFee;
};

export type DeliveryOptionType = PaymentFeeItem & {
  showPercent: boolean;
  amount: number;
  id?: number;
  deliveryDate: Date;
  scheduledDate: Date;
  minScheduledDate: Date;
  maxDeliveryDate: Date;
  type: DELIVERY_TYPE | CONSTS.FastACHDeliveryType | CONSTS.FastCheckDeliveryType | CONSTS.ExpeditedDeliveryType;
  fee: PaymentFeeItem[];
};

export type paymentFeeApiType = {
  amount: number;
  feeType: string;
  valueType: string;
  feeCatalog?: organizationFee;
};
export type feeApiItem = {
  feeCatalog?: organizationFee;
  amount?: number;
  isCap?: boolean;
  type?: string;
  value?: string;
  cap?: any;
  feeType?: string;
  valueType?: string;
  percent?: string | number;
};
export type feeApiFastItem = {
  fee?: string;
  totalAmount?: number;
  feeType?: string;
  cap?: number;
};
export type organizationFee = {
  cap: any;
  feeType: DELIVERY_TYPE;
  value: string;
  valueType: string;
};

export type OrganizationFeeCatalogType = {
  credit: organizationFee;
  check?: organizationFee;
  'expedited-ach': organizationFee;
  'express-check': organizationFee;
  international: organizationFee;
  'overnight-check': organizationFee;
  'push-to-debit': organizationFee;
} | null;

export type FundingSourceOriginType =
  | CONSTS.FUNDING_SOURCE_ORIGIN.MANUAL
  | CONSTS.FUNDING_SOURCE_ORIGIN.MICRO
  | CONSTS.FUNDING_SOURCE_ORIGIN.PLAID
  | CONSTS.FUNDING_SOURCE_ORIGIN.TABAPAY;

export type FundingSourceFundingType = CONSTS.FUNDING_TYPE.CARD | CONSTS.FUNDING_TYPE.ACH;

// Here also should be SAVINGS type, but got conflicts.
export type FundingSourceAccountType = CONSTS.BANK_ACCOUNT_TYPE.CHECKING;

export type AccountType = {
  id: number;
  displayName: string;
  intuitAccountId: string;
  logo: string;
  fundingType: string;
  origin: FundingSourceOriginType;
  isVerified: boolean;
  deletedAt: Date | null | undefined;
  nickname: string | null;
  createdAt?: Date | null;
  bankAccount: {
    accountNumber?: string;
    accountNumber4digits?: string;
    routingNumber?: string;
    canVerify?: boolean;
    isBlocked?: boolean;
  };
  plaidAccount: {
    balance: number;
    plaidItem: {
      institutionName: string;
    };
    plaidItemId: number;
  };
  cardAccount: CardAccountType;
  getName: (showDisplayName?: boolean) => string;
  getNameParts: () => {
    displayName: string;
    institutionName: string;
    identifier: string;
    balance: string;
  };
  getDisplayName: () => string;
  getInstitutionName: () => string;
};

export type RegistrationOriginType =
  | CONSTS.REGISTRATION_ORIGIN.APP
  | CONSTS.REGISTRATION_ORIGIN.LANDING
  | CONSTS.REGISTRATION_ORIGIN.LANDING_NO_VERIFICATION
  | CONSTS.REGISTRATION_ORIGIN.LANDING_WITH_PROMO
  | CONSTS.REGISTRATION_ORIGIN.QUICKBOOKS
  | CONSTS.REGISTRATION_ORIGIN.QUICKBOOKS_ROCKETMAN_ANDROID
  | CONSTS.REGISTRATION_ORIGIN.QUICKBOOKS_ROCKETMAN_IOS
  | CONSTS.REGISTRATION_ORIGIN.QUICKBOOKS_QBM_ANDROID
  | CONSTS.REGISTRATION_ORIGIN.QUICKBOOKS_QBM_IOS;

export type RegistrationFlowType =
  | CONSTS.REGISTRATION_FLOW.REGULAR
  | CONSTS.REGISTRATION_FLOW.VENDOR
  | CONSTS.REGISTRATION_FLOW.GUEST
  | CONSTS.REGISTRATION_FLOW.PUSH_TO_DEBIT
  | CONSTS.REGISTRATION_FLOW.QUICKBOOKS
  | CONSTS.REGISTRATION_FLOW.QBO_INTEGRATION;

export type UserContextType = {
  email: string;
  isEmailVerified: boolean;
  id: number | null | undefined;
  dateOfBirth: string;
  isIntuitLinked: boolean;
  isGuest: boolean;
  name: string | null | undefined;
  orgId: string;
  orgName: string | null | undefined;
  userPreferences?: Partial<UserPreferencesType> | null | undefined;
  organizations: any[];
  registrationOrigin: RegistrationOriginType;
  registrationFlow: RegistrationFlowType;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
};

export type RoleLiteral = 'owner' | 'admin' | 'contributor' | 'accountant';

export type UserOrganization = {
  id: number;
  role: RoleLiteral;
  approvalAmountThreshold?: number;
  requireApproval: boolean;
  userId: number;
  organizationId: number;
  user?: {
    email?: string;
  };
};

export type CountryCodeType =
  | CONSTS.COUNTRY.US
  | CONSTS.COUNTRY.PR
  | CONSTS.COUNTRY.VI
  | CONSTS.COUNTRY.GU
  | CONSTS.COUNTRY.MP;
export type AddressType = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  countryCode?: CountryCodeType | null | string;
  googlePlaceId?: string | null;
  aptNumber?: string | null;
  addressLat?: number;
  addressLng?: number;
  formattedAddress?: string | null;
};

export type FormAddressType = AddressType & LegalAddressType;

export type LegalAddressType = {
  legalAddressLine1?: string | null;
  legalAddressLine2?: string | null;
  legalCity?: string | null;
  legalState?: string | null;
  legalZipCode?: string | null;
  legalGooglePlaceId?: string | null;
  legalAptNumber?: string | null;
  legalAddressLat?: number;
  legalAddressLng?: number;
  legalFormattedAddress?: string | null;
  legalCountryCode?: CountryCodeType | null | string;
};
export type CheckType = {
  printName: string;
  isAddressSuggestionIgnored?: boolean | null;
  deliveryMethodId?: string;
} & AddressType;
export type CardAccountType = {
  printName: string;
  cardType: string;
  card4digits: string;
  network: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  expiration: string;
} & AddressType;
export type LatLng = {
  lat?: number;
  lng?: number;
  toJSON?: () => LatLng;
};
export type LocationType = {
  location: LatLng;
};
export type GooglePlaceAddressComponentType = {
  long_name?: string | null | undefined;
  short_name?: string | null | undefined;
  types: Array<string>;
};
export type GoogleCombinedAddressType = {
  addressComponents: Array<GooglePlaceAddressComponentType>;
  formattedAddress: string | null | undefined;
  geometry: LatLng;
  placeId: string | null | undefined;
  aptNumber: string | null | undefined;
  name?: string | null;
};
export type GooglePlaceType = google.maps.places.PlaceResult;

export type BankType = {
  accountType: CONSTS.BANK_ACCOUNT_TYPE.CHECKING;
  routingNumber?: string;
  accountNumber?: string;
  accountNumber4digits?: string;
  intuitAccountId?: string;
  isBlocked?: boolean;
  canVerify?: boolean;
};
export type PlaidType = {
  plaidItemId: number;
  accountId: string;
  plaidItem?: {
    institutionName: string;
  };
};
export type VirtualCardAccountType = {
  id: string;
  accountEmail: string;
};
export type OptionalDeliveryMethodsType =
  | CONSTS.DELIVERY_TYPE.ACH
  | CONSTS.DELIVERY_TYPE.CHECK
  | CONSTS.DELIVERY_TYPE.VIRTUAL
  | CONSTS.DELIVERY_TYPE.VIRTUAL_CARD
  | CONSTS.DELIVERY_TYPE.CARD
  | CONSTS.DELIVERY_TYPE.RPPS
  | CONSTS.DELIVERY_TYPE.INTERNATIONAL;
export type EditableDeliveryMethodType = {
  deliveryType: OptionalDeliveryMethodsType;
  isVerified?: boolean;
  paperCheck?: CheckType | null | undefined;
  bankAccount?: BankType | null | undefined;
  plaidAccount?: PlaidType | null | undefined;
  cardAccount?: CardAccountType | null | undefined;
  virtualAccount?: VirtualAccountType | null | undefined;
  verifiedStatus?: VerifiedStatusTypes;
  isFilledByVendor?: boolean;
  cardAccountId?: number | null;
  virtualCardAccount?: VirtualCardAccountType | null | undefined;
  virtualCardAccountId?: number | null;
};

export type VerifiedStatusTypes =
  | CONSTS.VERIFIED_STATUS.NOT_VERIFIED
  | CONSTS.VERIFIED_STATUS.SOFT_VERIFIED
  | CONSTS.VERIFIED_STATUS.MICRO_DEPOSIT_VERIFIED
  | CONSTS.VERIFIED_STATUS.PLAID_VERIFIED;

export type CardTypes = CONSTS.CARD_TYPES.CREDIT | CONSTS.CARD_TYPES.DEBIT;
export type CardNetworkTypes =
  | CONSTS.CARD_NETWORK_TYPES.VISA
  | CONSTS.CARD_NETWORK_TYPES.MASTERCARD
  | CONSTS.CARD_NETWORK_TYPES.AMEX
  | CONSTS.CARD_NETWORK_TYPES.DINERS;

export type VirtualAccountType = {
  id?: string;
  email: string;
  phone?: string;
};

export type RppsAccountType = {
  accountNumber: string;
  billerId: string;
};

export type OrganizationType = Partial<{
  id: number;
  companyName: string | null;
  logoId: number | null;
  phone: string | null;
  userOrganizations: UserOrganization[];
}>;

export type DeliveryMethodType<TType extends 'default' | 'created' = 'default'> = {
  logo: string;
  hasScheduledPayments: boolean;
  isFilledByVendor: boolean;
  createdById?: string;
  createdAt?: string;
  updatedAt?: Date | null;
  deletedAt?: Date | null;
  getDisplayName: (vendorName?: string) => string | React.ReactElement;
  getDeliveryInfo: (vendorName?: string, isRPPSDeliveryMethod?: boolean) => string | React.ReactElement;
  intuitAccountId: string | null;
  rppsAccount?: RppsAccountType;
} & EditableDeliveryMethodType &
  {
    default: {
      id?: string;
    };
    created: {
      id: string;
    };
  }[TType];

export type KybStatusType = CONSTS.KYB_STATUS.ACCEPTED | CONSTS.KYB_STATUS.UNDECIDED | CONSTS.KYB_STATUS.DENIED;
export type KybType =
  | CONSTS.KYB_TYPE.ACCEPT
  | CONSTS.KYB_TYPE.UNDECIDED
  | CONSTS.KYB_TYPE.REJECT
  | CONSTS.KYB_TYPE.POLICY
  | CONSTS.KYB_TYPE.APPROVEALL;
export type MqlType = CONSTS.MQL_TYPE.UNDECIDED | CONSTS.MQL_TYPE.QUALIFIED | CONSTS.MQL_TYPE.UNQUALIFIED;
export type TaxIdType = CONSTS.TAX_ID_TYPE.SSN | CONSTS.TAX_ID_TYPE.ITIN | CONSTS.TAX_ID_TYPE.EIN;
export type BillingSettingFeeType = {
  value: number;
  type: 'fixed' | 'percent';
};
export type BillingSettingsType = {
  fee: {
    ach: BillingSettingFeeType;
    credit: BillingSettingFeeType;
    card: BillingSettingFeeType;
  };
};

export enum CompanyType {
  SMB = 'smb',
  ACCOUNTING_FIRM = 'accounting-firm',
}

export type CompanyTypeLiteral = 'accounting-firm' | 'smb';

export type CompanyInfoType = {
  id: number;
  // TODO: add companyName can be null (or find the root cause).
  companyName: string;
  legalCompanyName: string;
  taxId?: string;
  taxIdType?: TaxIdType;
  phone: string;
  contactFirstName: string;
  contactLastName: string;
  yearOfIncorporation: number | null | undefined;
  accountingSoftware: string;
  businessType: BusinessType;
  intuitLastFullUpdate: Date | null;
  logoId: number | null | undefined;
  logoUrl: string | undefined;
  canDoPayments: boolean;
  canPayWithFastCheck: boolean;
  kybStatus: KybStatusType;
  kyb: KybType;
  mql: MqlType;
  ownedVendorId: number | null | undefined;
  ownedVendorHandle: string | null | undefined;
  billingSetting: BillingSettingsType;
  onboardingIndustryId: number | null | undefined;
  onboardingSubIndustryId: number | null | undefined;
  companyType?: CompanyType;
  isIntuitLinked?: boolean;
  createOrigin?: ORGANIZATION_CREATE_ORIGIN;
  industryId: number | null | undefined;
} & AddressType &
  LegalAddressType;

export enum BusinessType {
  Partnership = 'partnership',
  SoleProprietorship = 'sole-proprietorship',
  LimitedLiabilityCompany = 'llc',
  Corporation = 'corporation',
  Trust = 'trust',
  NonProfit = 'non-profit',
  Municipal = 'municipal',
  Municipality = 'municipality',
  NonGovernmentalOrganization = 'ngo',
}

export type EditableVendorType = {
  contactName: string;
  contactEmail: string;
  contactPhone: string;
};

export type VendorType<TType extends 'default' | 'created' = 'default'> = {
  companyName: string;
  accountIdentifier?: string;
  address?: AddressType;
  paymentRequestId?: string;
  deliveryMethods: Array<DeliveryMethodType>;
  createdAt: Date | null | undefined;
  createdById: string;
  updatedById: string;
  managedBy: CONSTS.VENDOR_MANAGED_BY | null;
  origin: string;
  originId: string;
  ownedById: string | null | undefined;
  handle: string | null | undefined;
  hasDeliveryMethods: boolean;
  isEditable: boolean;
  isOwned: boolean;
  vendorTrusted: boolean;
  getDeliveryMethodByType: (type: OptionalDeliveryMethodsType) => RecordOf<DeliveryMethodType>;
  getDeliveryMethodById: (id: string) => RecordOf<DeliveryMethodType>;
  printName?: string;
  printOnCheck?: string;
  mccCode?: string;
  accountingPlatformEntity?: {
    entityId?: string;
    externalId?: string;
  };
} & EditableVendorType &
  {
    default: {
      id?: string;
    };
    created: {
      id: string;
    };
  }[TType];

export type BillLineItemType = {
  id: string;
  description: string;
  amount: number;
  currency: string;
};
export type EditablePaymentType = {
  id: string;
};
export type NewBillType = {
  totalAmount?: number | null | undefined;
  invoiceNumber?: string;
  // invoiceDate?: Date,
  // terms?: number,
  dueDate?: Date;
  note?: string | null | undefined;
  vendorId?: number | null | undefined;
  lineItems?: List<RecordOf<BillLineItemType>>;
};
export type TranscationTypesEnum =
  | CONSTS.TRANSACTION_TYPES.ACH
  | CONSTS.TRANSACTION_TYPES.CHECK
  | CONSTS.TRANSACTION_TYPES.CARD;
export type TranscationDestinationsEnum =
  | CONSTS.TRANSACTION_DESTINATIONS.VENDOR
  | CONSTS.TRANSACTION_DESTINATIONS.ORGANIZATION;
export type TranscationDirectionsEnum = CONSTS.TRANSACTION_DIRECTIONS.DEBIT | CONSTS.TRANSACTION_DIRECTIONS.CREDIT;
export type TransactionType = {
  id: number;
  createdAt: any;
  updatedAt: any;
  transactionType: TranscationTypesEnum | null | undefined;
  amount: number | null | undefined;
  currency: number | null | undefined;
  destination: TranscationDestinationsEnum | null | undefined;
  transactionDirection: TranscationDirectionsEnum | null | undefined;
  sourceLink: string | null | undefined;
  status: string | null | undefined;
  rawData:
    | {
        [key: string]: any;
      }
    | null
    | undefined;
  source: string | null | undefined;
  checkSerialNumber: string | null;
  organizationId: number | null | undefined;
  vendorId: number | null | undefined;
};
export type PaymentApprovalActionType = {
  id: string;
  source: 'manual' | 'system';
  result: 'approved' | 'declined' | 'pending';
  reasons: string | null | undefined;
  paymentId: number;
  riskDecisionCodeId: number;
  createdAt: Date;
  updatedAt: Date;
};

export type CheckTrackDeliveryType = {
  trackingUrl: string;
};

export type BillPaymentType = {
  amount: number;
  billId: number;
  createdAt: string;
  deletedAt?: string;
  id: number;
  paymentId: number;
  uniqueBillPaymentId: boolean;
  updatedAt: string;
};

export type VirtualCardType = {
  id: number;
  paymentId: number;
  accountNumber4digits: number;
  createdAt: Date;
  cardNumber: string;
  cvv: string;
  expirationDate: Date;
  endDate: Date;
  authorizationDate?: Date;
  postedDate?: Date;
  canceledDate?: Date;
  status: CONSTS.VirtualCardStatus;
};

export type PaymentType = {
  deliveryPreference?: string;
  originDeliveryPreference?: string;
  id: string;
  organizationId: string;
  status: string;
  amount?: number | null;
  balance?: number | null;
  billId?: string | null;
  bill: {
    id: string;
    invoiceNumber: string;
    totalAmount: number | null;
  };
  bills?: BillType[];
  billPayments?: BillPaymentType[];
  organization?: OrganizationType;
  vendorId: string;
  vendor: {
    // TODO: replace with vendor type
    id: string;
    companyName: string;
    deliveryMethods: Array<DeliveryMethodType> | null | undefined;
    contactEmail: string;
    contactPhone: string;
    ownedById?: string;
    printOnCheck?: string;
    mccCode?: string;
  };
  fundingSourceId: number;
  fundingSource: {
    id?: string;
    displayName: string;
    origin: string;
    plaidAccount: any;
    deletedAt?: string;
  };
  virtualCards?: VirtualCardType[];
  lastCreatedVirtualCard?: VirtualCardType;
  deliveryMethodId: string;
  deliveryCompleteDate: string;
  deliveryMethod: DeliveryMethodType;
  collectStatus: CONSTS.PAYMENT_COLLECT_STATUS;
  currency: string;
  scheduledDate: any;
  deliveryEta: any;
  originId: string;
  maxDeliveryEta: any;
  paidDate: string | null | undefined;
  actualStartDate?: string | null | undefined;
  deliverStatus: CONSTS.PAYMENT_DELIVER_STATUS;
  note?: string;
  manual: boolean;
  createdAt: any;
  updatedAt: string;
  createdById: number;
  canUserRetry?: boolean;
  transactions: TransactionType[];
  createOrigin: string;
  approvalDecisionById: number;
  approvalDecisionDate: any;
  approvalDecisionReason: string;
  approvalDecisionStatus: string;
  riskStatus: string;
  intuitAccountId?: string;
  paymentApprovalActions: PaymentApprovalActionType[];
  metadata?: {
    achDeposited: boolean;
    canUserContactSupport: boolean;
    canUserRetry: boolean;
    failureMessage?: string;
    failedType?: string;
    isDeletable?: boolean;
  };
  checkTracks: CheckTrackDeliveryType[];
  cardAccountNetwork?: CONSTS.CARD_PROVIDERS;
  originalDeliveryMethodId?: string;
  originalDeliveryMethod?: {
    deliveryType: DELIVERY_TYPE;
  };
  payBillFlowUUID?: string;
  isEligibleToVirtualCard?: boolean;
  isCheckToP2DOfferExpired?: boolean;
  checkToP2DExpirationDate?: string;
  expeditedBy?: PaymentExpeditedByEnum;
};
export type BillStatusType =
  | CONSTS.BILL_STATUS.UNPAID
  | CONSTS.BILL_STATUS.PAID
  | CONSTS.BILL_STATUS.PARTIALLY_PAID
  | CONSTS.BILL_STATUS.SCHEDULED;
export type PaymentStatusType =
  | CONSTS.PAYMENT_STATUS.SCHEDULED
  | CONSTS.PAYMENT_STATUS.COMPLETED
  | CONSTS.PAYMENT_STATUS.FAILED;
export type FileType = {
  id: string;
  mimeType: string;
};
export type ModalDialogTypes = CONSTS.DIALOG_TYPE.CONFIRM | CONSTS.DIALOG_TYPE.ALERT;
export type ModalDialogVariants =
  | CONSTS.DIALOG_VARIANTS.SUCCESS
  | CONSTS.DIALOG_VARIANTS.ERROR
  | CONSTS.DIALOG_VARIANTS.WARNING;

export type BillType = {
  id: string;
  createdAt: string;
  balance: number | null | undefined;
  totalAmount: any;
  currency: string;
  organization: {
    id: string;
    companyName: string;
  };
  createdById: number;
  metadata?: {
    isDeletable: boolean;
  };
  origin: string;
  originId: string;
  organizationId: string;
  status: BillStatusType;
  vendorId: string;
  lineItems: Array<BillLineItemType>;
  payments: Array<PaymentType>;
  dueDate: Date | string;
  originMetaUrl: string;
  lastUpdater: string;
  invoiceDate: Date;
  terms: number;
  creator: string;
  invoiceNumber: string;
  note: string;
  intuitAccountId: string;
  internalBill: boolean;
  goodsReceived: boolean;
  // need to convert to VendorType
  vendor: {
    id: string;
    companyName: string;
    deliveryMethods: Array<DeliveryMethodType> | null | undefined;
    contactEmail: string;
    contactPhone: string;
    ownedById?: string | null;
    printOnCheck?: string;
    accountIdentifier?: string;
    mccCode?: string;
  };
  files: Array<FileType>;
  getDeliveryMethodByType: (type: OptionalDeliveryMethodsType) => RecordOf<DeliveryMethodType>;
  getDeliveryMethodById: (id: string) => RecordOf<DeliveryMethodType>;
  getFirstFileId(): string | null | undefined;
  isVendorRequest(): boolean;
  classificationId?: string;
  partner?: string;
  paymentRequestId?: string;
  paymentRequest: {
    id: string;
    feesPaidBy: CREDIT_CARD_FEE_PAYMENT;
  };
  contractor?: string;
  externallyPaid?: number;
};
export type ModalDialogSettings = {
  image?: string;
  imagePosition?: IMAGE_POSITION;
  type: ModalDialogTypes;
  variant: ModalDialogVariants;
  title: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subtitleValues?: Record<string, any>;
  okButtonText?: string;
  cancelButtonText?: string;
  onOkAction?: () => any;
  onOkDisabled?: boolean;
  onCancelAction?: () => any;
  hideIcon?: boolean;
  showCancel?: boolean;
  maxWidth?: string;
  minHeight?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  buttonsRowPosition?: BUTTONS_ROW_POSITION;
  buttonsDirection?: BUTTONS_DIRECTIONS;
  buttonsSize?: CONSTS.BUTTON_SIZE;
  className?: string;
};

export type FormType = CONSTS.FORM_TYPE.WIZARD | CONSTS.FORM_TYPE.DETAILS;
export type FormControlType = CONSTS.FORM_CONTROL_TYPE.TEXT | CONSTS.FORM_CONTROL_TYPE.ADDRESS;
export type AddressFormControlModeType =
  | CONSTS.ADDRESS_FORM_CONTROL_MODE.WITH_MAP
  | CONSTS.ADDRESS_FORM_CONTROL_MODE.INLINE
  | CONSTS.ADDRESS_FORM_CONTROL_MODE.FORM;
export type ScreenMode = CONSTS.SCREEN_MODE.VIEW | CONSTS.SCREEN_MODE.EDIT;
export type MIMoneyFlavor =
  | CONSTS.MIMONEY_FLAVOR.DEFAULT
  | CONSTS.MIMONEY_FLAVOR.POSITIVE
  | CONSTS.MIMONEY_FLAVOR.NEGATIVE;
export type SingleSelectFlavor =
  | CONSTS.SINGLE_SELECT_FLAVOR.DEFAULT
  | CONSTS.SINGLE_SELECT_FLAVOR.INLINE
  | CONSTS.SINGLE_SELECT_FLAVOR.TABLE;
export type TextInputSize = CONSTS.TEXT_INPUT_SIZE.INLINE | CONSTS.TEXT_INPUT_SIZE.WIZARD;
export type CompanyFormPageType = CONSTS.COMPANY_FORM_PAGE.SETTINGS | CONSTS.COMPANY_FORM_PAGE.ONBOARDING;
export type PaginationItemsType = CONSTS.PAGINATION_ITEMS_TYPE.BUTTON | CONSTS.PAGINATION_ITEMS_TYPE.LINK;
export type DeliveryMethodOriginType = CONSTS.DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS;
export type ButtonVariantType =
  | CONSTS.BUTTON_VARIANT.PRIMARY
  | CONSTS.BUTTON_VARIANT.PAY
  | CONSTS.BUTTON_VARIANT.SECONDARY
  | CONSTS.BUTTON_VARIANT.TERTIARY
  | CONSTS.BUTTON_VARIANT.DESTRUCTIVE
  | CONSTS.BUTTON_VARIANT.CANCEL
  | CONSTS.BUTTON_VARIANT.REMOVE
  | CONSTS.BUTTON_VARIANT.EMPTY
  | CONSTS.BUTTON_VARIANT.INTUIT
  | CONSTS.BUTTON_VARIANT.UNDO
  | CONSTS.BUTTON_VARIANT.NAKED;
export type ButtonSizeType =
  | CONSTS.BUTTON_SIZE.NORMAL
  | CONSTS.BUTTON_SIZE.SMALL
  | CONSTS.BUTTON_SIZE.TINY
  | CONSTS.BUTTON_SIZE.VERY_SMALL;

export type StatusInfoType = {
  status?: BillStatusType | PaymentStatusType;
  color: string;
  label: string;
  colorOpacity: string;
  icon: string | React.ReactNode;
  description?: string;
  descriptionValues?: Record<string, any>;
};
export type BillsGroup = {
  header?: string;
  sum?: number;
  bills: Array<RecordOf<BillType> | BillType>;
};

export type BadgeSizeType =
  | CONSTS.BADGE_SIZE.LARGE
  | CONSTS.BADGE_SIZE.MEDIUM
  | CONSTS.BADGE_SIZE.SMALL
  | CONSTS.BADGE_SIZE.X_SMALL
  | CONSTS.BADGE_SIZE.X_LARGE;

export enum OrgPreferencesTypeKeys {
  showPaymentAuditLog = 'showPaymentAuditLog',
  featureInternalTesting = 'featureInternalTesting',
  onboardingBookkeeperNumOfCompanies = 'onboardingBookkeeperNumOfCompanies',
  onboardingCompanyTPV = 'onboardingCompanyTPV',
  onboardingIndustryId = 'onboardingIndustryId',
  onboardingSubIndustryId = 'onboardingSubIndustryId',
  getPaidProLayout = 'getPaidProLayout',
  regularBatchPayments = 'regularBatchPayments',
  vendorDirectory = 'vendorDirectory',
  partialPayments = 'partialPayments',
  allowGetPro = 'allowGetPro',
  isVendorAbsorbedFee = 'isVendorAbsorbedFee',
  enableTeamManagement = 'enableTeamManagement',
  ffNativeQboPilot = 'ffNativeQboPilot',
  showPaymentRequestInboxTabRedesign = 'showPaymentRequestInboxTabRedesign',
  qbdtUnpaidBillsChecked = 'qbdtUnpaidBillsChecked',
  qbdtAchFeeNotificationSeen = 'qbdtAchFeeNotificationSeen',
  qbdtAchFeeNotificationSeenAfter = 'qbdtAchFeeNotificationSeenAfter',
}

export enum PaymentExpeditedByEnum {
  PAYEE = 'payee',
  PAYOR = 'payor',
}

export type OrganizationPreferencesType = {
  [OrgPreferencesTypeKeys.showPaymentAuditLog]?: 'true' | null;
  [OrgPreferencesTypeKeys.featureInternalTesting]?: 'true' | null;
  [OrgPreferencesTypeKeys.onboardingBookkeeperNumOfCompanies]?: '1-20' | '21-50' | '51-100' | '100+' | null;
  [OrgPreferencesTypeKeys.onboardingCompanyTPV]?: '10000' | '10000-50000' | '50000 - 150000' | '150000+' | null;
  [OrgPreferencesTypeKeys.onboardingIndustryId]?: string | null;
  [OrgPreferencesTypeKeys.onboardingSubIndustryId]?: string | null;
  [OrgPreferencesTypeKeys.getPaidProLayout]?: 'true' | null;
  [OrgPreferencesTypeKeys.regularBatchPayments]?: 'true' | null;
  [OrgPreferencesTypeKeys.vendorDirectory]?: 'true' | null;
  [OrgPreferencesTypeKeys.partialPayments]?: 'true' | null;
  [OrgPreferencesTypeKeys.showPaymentRequestInboxTabRedesign]?: 'true' | null;
  [OrgPreferencesTypeKeys.isVendorAbsorbedFee]?: string | null;
  [OrgPreferencesTypeKeys.allowGetPro]?: '1' | null;
  [OrgPreferencesTypeKeys.enableTeamManagement]?: 'yes' | 'no' | null;
  [OrgPreferencesTypeKeys.ffNativeQboPilot]?: 'true' | '1' | boolean | null;
  [OrgPreferencesTypeKeys.qbdtUnpaidBillsChecked]?: 'true' | '1' | boolean | null;
  [OrgPreferencesTypeKeys.qbdtAchFeeNotificationSeen]?: 'yes' | 'no';
  [OrgPreferencesTypeKeys.qbdtAchFeeNotificationSeenAfter]?: 'yes' | 'no';
};

export type AddressTypeFromApi = {
  line1: string | null | undefined;
  line2: string | null | undefined;
  city: string | null | undefined;
  countryCode: CountryCodeType;
  postalCode: string | null | undefined;
  state: string | null | undefined;
};

export type UserOrgType = {
  id: number | null;
  firstName: string;
  lastName: string;
};

export type UserOrganizationType = {
  user?: UserOrgType;
};

export type JoinOrganizationRequestType = {
  id: number;
  userId: number;
  status: 'approved' | 'pending' | 'canceled';
  firstName: string;
  lastName: string;
  phone: string;
  role: RoleLiteral;
  email: string;
  expired: null | Date;
  companyName: string;
  user?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  organizationsToJoin?: number[];
  organizationId?: number;
} & AddressType;

export type NavigateType = (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any>) => void;

export type Expandable<T> = T & Record<string, any>;

export type ErrorState = {
  errorOccurred: boolean;
  title?: string;
  subtitle?: string;
};

export type ScheduledDataType = {
  title: string;
  date?: Date | string;
  method: string;
  info: string | React.ReactNode;
  hint: string;
  icon: React.ReactNode;
  dateIcon: string;
  description: string;
  isEditable: boolean;
  onEdit?: () => void;
  onEditDate?: () => void;
  secondDate?: React.ReactNode;
  secondDateIcon?: string;
  secondDateDescription?: string;
};

export type DeliveryDataType = {
  title?: string;
  date?: Date | string;
  deliveryDate?: Date | string;
  maxDate?: Date | string;
  method?: string;
  info?: string | React.ReactNode;
  icon?: React.ReactNode;
  dateIcon?: string;
  description?: string;
  deliveryPreference?: string;
  additionalDescription?: string;
  isEditable?: boolean;
  isExpedited?: boolean;
  onEdit?: () => void;
  onEditDate?: () => void;
  formattedCheckSerial?: string;
  origin?: string | null;
  type?: OptionalDeliveryMethodsType | null;
  paperCheck?: CheckType | null;
  additionalHint?: {
    label?: string;
    values?: React.ReactNode | string;
  };
} | null;

export type InvitationActionType = {
  label: string;
  negative: boolean;
  action: () => void;
};

export type ToNavigationType =
  | History.LocationDescriptor
  | ((location?: History.Location) => History.LocationDescriptor);

export type ValidationErrors<T> = Partial<{ [F in keyof T]: string }>;

export type PayEditLocationNamesType =
  | PAY_EDIT_LOCATION_NAME.DATE
  | PAY_EDIT_LOCATION_NAME.FUNDING
  | PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD
  | PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD_ACH
  | PAY_EDIT_LOCATION_NAME.CONFIRM
  | PAY_EDIT_LOCATION_NAME.MEMO
  | PAY_EDIT_LOCATION_NAME.FAST_PAYMENT;

export type IntuitBankAccountResponseType = {
  AccountSubType: string;
  AccountType: string;
  Classification: string;
  FullyQualifiedName: string;
  Id: string;
  Name: string;
};

export type IntuitBankAccountType = {
  accountSubType: string;
  accountType: string;
  classification: string;
  fullyQualifiedName: string;
  name: string;
  id: string;
};

export type ServerErrorType = {
  code: string;
  message: string;
  method: string;
  name: string;
  requestData?: Record<string, any>;
  responseData?: Record<string, any>;
  url: string;
  status?: number;
};

export type FeeVariants = CONSTS.FEE_VARIANTS.ACH_TO_CHECK;

export enum UserField {
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
}

export enum CompanyField {
  ContactFirstName = 'contactFirstName',
  ContactLastName = 'contactLastName',
  CompanyName = 'companyName',
  Address = 'address',
  LegalCompanyName = 'legalCompanyName',
  LegalAddress = 'legalAddress',
  TaxInfoType = 'taxInfoType',
  TaxInfoIdentifier = 'taxInfoIdentifier',
  PhoneNumber = 'phoneNumber',
  Industry = 'industry',
  BusinessType = 'businessType',
}

export type SupportedField = UserField | CompanyField;

export enum FieldConflictType {
  Missing = 'Missing',
  Invalid = 'Invalid',
}

export const ConflictedFieldType = {
  Missing: 'Missing',
  Invalid: 'Invalid',
} as const;

export type ConflictedFieldType = typeof ConflictedFieldType[keyof typeof ConflictedFieldType];

export type ConflictedField<Field extends SupportedField> = {
  name: Field;
  type: FieldConflictType;
  isRequired?: boolean;
};

export type ComplianceEntityInformation<Field extends SupportedField> = {
  missingFields: ConflictedField<Field>[];
  isGracePeriodEnded: boolean;
  isGracePeriodStarted: boolean;
  isVerificationFailed: boolean;
  numberOfPaymentsLeftInGracePeriod: null | number;
};

export type ComplianceEntitiesInformation = {
  user: ComplianceEntityInformation<UserField> | null;
  company: ComplianceEntityInformation<CompanyField> | null;
};

export type AccountLimitations = {
  entitiesInformation: ComplianceEntitiesInformation;
  isCompliance: boolean;
  isVerificationFailed: boolean;
  isGracePeriodEnded: boolean;
  isGracePeriodStarted: boolean;
  numberOfPaymentsLeftInGracePeriod: number | null;
};

export type MissingFieldType = ConflictedFieldType;

export type MissingField = {
  name: SupportedField;
  type: MissingFieldType;
  isRequired?: boolean;
  entity: string;
};
