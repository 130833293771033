export enum CURRENCY {
  USD = 'USD',
}
export enum CURRENCY_SIGN {
  USD = '$',
}

export enum COUNTRY {
  US = 'US',
  PR = 'PR',
  VI = 'VI',
  GU = 'GU',
  MP = 'MP',
}

export enum CheckDelaysIconVariant {
  WARNING = 'warning',
  INFO = 'info',
}

export const BILL_MAX_AMOUNT = 1000000;

export enum VENDOR_MANAGED_BY {
  VIRTUAL_CARD = 'virtual-card',
  SHARED = 'shared',
  BILLER = 'biller',
}

export enum DELIVERY_TYPE {
  ACH = 'ach',
  CHECK = 'check',
  VIRTUAL = 'virtual',
  VIRTUAL_CARD = 'virtual-card',
  CARD = 'card',
  RPPS = 'rpps',
  INTERNATIONAL = 'international',
}

export enum ADDRESS_TYPE {
  GOOGLE = 'google',
  POBOX = 'pobox',
}
export enum ADDRESS_NAME_TYPE {
  LEGAL_ADDRESS = 'legalAddress',
  COMPANY_ADDRESS = 'companyAddress',
}

export const ADDRESS_DEFAULTS = {
  NO_GOOGLE_PLACE_ID: '-1',
};

export enum MANUAL_ADDRESS {
  SUGGESTED = '1',
  ORIGINAL = '2',
}

export enum CREDIT_CARD_FEE_PAYMENT {
  PAYOR = 'payor',
  VENDOR = 'vendor',
}

export enum BANK_ACCOUNT_TYPE {
  CHECKING = 'checking',
}

export enum BILL_STATUS {
  UNPAID = 'unpaid',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  SCHEDULED = 'scheduled',
}

const BILL_STATUSES = [BILL_STATUS.UNPAID, BILL_STATUS.SCHEDULED, BILL_STATUS.PAID];

export enum VirtualCardStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  POSTED = 'posted',
  AUTHORIZED = 'authorized',
  EXPIRED = 'expired',
}

export enum PAYMENT_STATUS {
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
  PROCESSED = 'processed',
  IN_PROGRESS = 'in-progress',
  BLOCKED = 'blocked',
  FAILED = 'failed',
}

export enum RISK_STATUS {
  APPROVED = 'approved',
  DECLINED = 'declined',
  PENDING = 'pending',
}

export enum PAYMENT_APPROVAL_STATUS {
  SKIP = 'skip',
  PENDING = 'pending',
  DECLINED = 'declined',
  APPROVED = 'approved',
}

export enum PAYMENT_ACTION_OPTIONS {
  DECLINED = 'declined',
  SCHEDULE = 'schedule',
  RESCHEDULE = 'reschedule',
  RETRY = 'retry',
  SUPPORT = 'support',
  TRACK_DELIVERY = 'trackDelivery',
}

export enum PAYMENT_COLLECT_STATUS {
  BLOCKED = 'blocked',
  READY = 'ready',
  PROCESSING = 'processing',
  SENT = 'sent',
  COMPLETED = 'completed',
  FAILED = 'failed',
  RETRY = 'retry',
  TECHNICAL_FAILURE = 'technicalFailure',
  CANCEL = 'cancel',
  CANCELED = 'canceled',
  SCHEDULED = 'scheduled',
  INVALID = 'invalid',
  IN_PROGRESS = 'in-progress',
  CLEARED = 'cleared',
  SETTLED = 'settled',
  CANCEL_SCHEDULED = 'cancel-scheduled',
  CANCEL_IN_PROGRESS = 'cancel-in-progress',
  CANCEL_SENT = 'cancel-sent',
  CANCEL_CLEARED = 'cancel-cleared',
  CANCEL_SETTLED = 'cancel-settled',
  CANCEL_FAILED = 'cancel-failed',
}

export enum PAYMENT_DELIVER_STATUS {
  BLOCKED = 'blocked',
  READY = 'ready',
  PROCESSING = 'processing',
  SENT = 'sent',
  COMPLETED = 'completed',
  FAILED = 'failed',
  RETRY = 'retry',
  TECHNICAL_FAILURE = 'technicalFailure',
  CANCEL = 'cancel',
  CANCELED = 'canceled',
  SCHEDULED = 'scheduled',
  INVALID = 'invalid',
  IN_PROGRESS = 'in-progress',
  CLEARED = 'cleared',
  SETTLED = 'settled',
  CANCEL_SCHEDULED = 'cancel-scheduled',
  CANCEL_IN_PROGRESS = 'cancel-in-progress',
  CANCEL_SENT = 'cancel-sent',
  CANCEL_CLEARED = 'cancel-cleared',
  CANCEL_SETTLED = 'cancel-settled',
  CANCEL_FAILED = 'cancel-failed',
}

export enum ONBOARDING_MODULE {
  COMPANY_INFO = 'companyInfo',
  FUNDING_SOURCE = 'fundingSource',
  ACCOUNTING_SOFTWARE = 'accountingSoftware',
  EMAIL_VERIFICATION = 'emailVerification',
  ADD_BILL = 'addBill',
}

export enum SCREEN_MODE {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum DATA_ORIGIN {
  INTUIT = 'intuit',
}
export enum ACCOUNTING_SOFTWARE {
  QUICKBOOKS = 'Quickbooks Online',
  QUICKBOOKS_DESKTOP = 'Quickbooks Desktop',
  FRESHBOOKS = 'Freshbooks',
  NONE = 'NONE',
}

export enum SINGLE_SELECT_FLAVOR {
  DEFAULT = 'default',
  INLINE = 'inline',
  TABLE = 'table',
}

export enum MIMONEY_FLAVOR {
  DEFAULT = 'default',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export enum TEXT_INPUT_SIZE {
  INLINE = 'inline',
  WIZARD = 'wizard',
}

export enum FORM_TYPE {
  WIZARD = 'wizard',
  DETAILS = 'details',
}

export enum FORM_CONTROL_TYPE {
  TEXT = 'text',
  LINK = 'link',
  CURRENCY = 'currency',
  ADDRESS = 'address',
  DATE = 'date',
  SELECT = 'select',
  DROP_DOWN = 'drop-down',
  RADIO = 'radio',
  TEL = 'tel',
  INDUSTRY = 'industry',
}

export const FORM_AUTOCOMPLETE = {
  // basically, any unrecognized string will work, see here:
  // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
  OFF: 'autocomplete-off',
};

export const FORM_MAX_LENGTH = {
  NOTE: 50,
};

export enum ADDRESS_FORM_CONTROL_MODE {
  WITH_MAP = 'with-map',
  INLINE = 'inline',
  FORM = 'form',
}

export enum FUNDING_TYPE {
  ACH = 'ach',
  CARD = 'card',
}

export const FUNDING_LOGO = {
  DEFAULT: 'default',
};

export enum FUNDING_SOURCE_ORIGIN {
  PLAID = 'plaid',
  MICRO = 'micro',
  MANUAL = 'manual',
  TABAPAY = 'taba-pay',
}

export const FUNDING_SOURCE_LIMIT = {
  ACH_UNVERIFIED: 2,
};

export enum CARD_NETWORK_TYPES {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMEX = 'americanexpress',
  DINERS = 'diners',
}

export enum CARD_TYPES {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum CARD_PROVIDERS {
  VISA = 'Visa',
  AMEX = 'Amex',
}

export enum TAX_ID_TYPE {
  SSN = 'SSN',
  ITIN = 'ITIN',
  EIN = 'EIN',
}

export enum DIALOG_TYPE {
  ALERT = 'ALERT',
  CONFIRM = 'CONFIRM',
}

export enum DIALOG_VARIANTS {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export enum BUTTONS_DIRECTIONS {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum BUTTONS_ROW_POSITION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

export enum IMAGE_POSITION {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
}

export enum KYB_STATUS {
  ACCEPTED = 'accepted',
  UNDECIDED = 'undecided',
  DENIED = 'denied',
}

export enum KYB_TYPE {
  ACCEPT = 'accept',
  UNDECIDED = 'undecided',
  APPROVEALL = 'approveAll',
  REJECT = 'reject',
  POLICY = 'policy',
}

export enum MQL_TYPE {
  UNDECIDED = 'undecided',
  QUALIFIED = 'qualified',
  UNQUALIFIED = 'unqualified',
}

export enum COMPANY_FORM_PAGE {
  SETTINGS = 'settings',
  ONBOARDING = 'onboarding',
}

export const PAGINATION = {
  DEFAULT_LIMIT: 20,
  DEFAULT_START: 0,
};

export enum PAGINATION_ITEMS_TYPE {
  BUTTON = 'button',
  LINK = 'link',
}

export enum PLAID_EVENT_NAME {
  TRANSITION_VIEW = 'TRANSITION_VIEW',
}
export const TIMEOUTS = {
  CLOSE_MODAL: 2000,
  MELIO_ME_INFO_MODAL: 2800,
  CLOSE_NOTIFICATION: 3000,
};
export const INTERVALS = {
  RECHECK_IS_EMAIL_VERIFIED: 5000,
};

export enum REGISTRATION_ORIGIN {
  APP = 'app',
  LANDING = 'landing',
  QUICKBOOKS = 'quickbooks',
  QUICKBOOKS_MOBILE = 'quickbooksMobile',
  QUICKBOOKS_DESKTOP = 'qbdt-integration',
  QBDT_WINDOWS = 'qbdt-windows',
  QBDT_MAC = 'qbdt-mac',
  QUICKBOOKS_ROCKETMAN_IOS = 'quickbooksRocketmanIOS',
  QUICKBOOKS_ROCKETMAN_ANDROID = 'quickbooksRocketmanAndroid',
  QUICKBOOKS_QBM_IOS = 'quickbooksQbmIOS',
  QUICKBOOKS_QBM_ANDROID = 'quickbooksQbmAndroid',
  LANDING_NO_VERIFICATION = 'landingNoVerification',
  LANDING_WITH_PROMO = 'landingWithPromo',
  ADD_COMPANY = 'addCompany',
  SYNGENCY_INTEGRATION = 'syngency',
}

export enum REGISTRATION_FLOW {
  REGULAR = 'regular',
  VENDOR = 'vendor',
  GUEST = 'guest',
  PUSH_TO_DEBIT = 'push-to-debit',
  QUICKBOOKS = 'quickbooks',
  QBO_INTEGRATION = 'qbo-integration',
  MCB = 'checkout-button',
  SYNGENCY_INTEGRATION = 'syngency-integration',
}
export enum ORGANIZATION_CREATE_ORIGIN {
  QBO_INTEGRATION = 'qbo-integration',
  ADD_COMPANY = 'add-company',
  USER_SIGNUP = 'user-signup',
  GUEST = 'guest',
  QBM_IOS = 'qbm-ios',
  QBM_ANDROID = 'qbm-android',
  QBDT_INTEGRATION = 'qbdt-integration',
  QBDT_WINDOWS = 'qbdt-windows',
  QBDT_MAC = 'qbdt-mac',
}
export enum PAYMENT_CREATE_FLOW_ORIGIN {
  PAY = 'pay',
  REQUEST = 'request',
  QBO_INTEGRATION = 'qbo-integration',
  QBDT_INTEGRATION = 'qbdt-integration',
  QBDT_WINDOWS = 'qbdt-windows',
  QBDT_MAC = 'qbdt-mac',
  QBM_IOS = 'qbm-ios',
  QBM_ANDROID = 'qbm-android',
}

export const RESTRICTED_ORIGINS = [
  ORGANIZATION_CREATE_ORIGIN.QBDT_INTEGRATION,
  ORGANIZATION_CREATE_ORIGIN.QBDT_MAC,
  ORGANIZATION_CREATE_ORIGIN.QBDT_WINDOWS,
];

export enum PAYMENT_REQUEST_CREATE_ORIGIN {
  MELIOME = 'melio-me',
  MELIOME_GENERIC_LINK = 'melio-me-generic-link',
  REQUEST = 'payment-request',
  MCB = 'checkout-button',
  EXTERNAL_API = 'melio-me-external-api',
}

export enum PAYMENT_REQUEST_SENT_FROM {
  MELIO_ME = 'melio-me',
  MELIO_PRO = 'melio-pro',
  PARTNER = 'partner',
}

/* Note: if you add a trait here, the app will try to update it in the DB */
export enum DB_ANALYTICS_TRAITS {
  FIRST_TIME_PAY = 'first_time_pay',
  ADDED_FUNDING = 'added_funding',
  CREATE_A_BILL = 'create_a_bill',
  LINK_ACCOUNTING_SOFTWARE = 'link_accounting_software',
  EMAIL_VERIFIED = 'Email_Verified',
  IS_BUSINESS_EMAIL = 'is_business_email',
  ADDED_DELIVERY_METHOD = 'added_delivery_method',
}

export enum CONTACTS_TAB {
  CUSTOMERS = 'customers',
  VENDORS = 'vendors',
}

export enum SERVER_RESPONSE_CODES {
  OK = 'OK',
}

export enum TRANSACTION_TYPES {
  ACH = 'ach',
  CHECK = 'check',
  CARD = 'card',
}

export enum TRANSACTION_DESTINATIONS {
  VENDOR = 'vendor',
  ORGANIZATION = 'organization',
}

export enum TRANSACTION_DIRECTIONS {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export enum TRANSACTION_STATUSES {
  COMPLETED = 'COMPLETED',
  AUTHORIZATION = 'AUTHORIZATION',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
}

export enum ADD_FUNDING_SOURCE_WIZARD_ORIGIN {
  PAY_BILL = 'funding-source-pay-bill',
  SETTINGS = 'funding-source-settings',
  BATCH_PAY_BILLS = 'funding-source-batch-pay-bills',
  BATCH_PAY_BILLS_FIRST_FS = 'first-funding-source-batch-pay-bills',
  BILLING = 'funding-source-billing-fee',
}

export enum DELIVERY_METHOD_ORIGIN {
  VENDOR_DETAILS = 'delivery-method-vendor-details',
  PAY_BILL = 'delivery-method-pay-bill',
  EDIT_PAYMENT = 'edit-payment',
  BATCH_PAY_BILLS = 'delivery-method-batch-pay-bills',
}

export enum DELIVERY_METHOD_CHECK_FLOW {
  UNILATERAL = 'delivery-method-check-go-unilateral',
  CONFIRM = 'delivery-method-check-confirm-address',
}

export enum BUTTON_VARIANT {
  PRIMARY = 'primary',
  PAY = 'pay',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DESTRUCTIVE = 'destructive',
  CANCEL = 'cancel',
  REMOVE = 'remove',
  EMPTY = 'empty',
  INTUIT = 'intuit',
  UNDO = 'undo',
  NAKED = 'naked',
}

export enum BUTTON_SIZE {
  SMALL = 'small',
  TINY = 'tiny',
  NORMAL = 'normal',
  VERY_SMALL = 'very-small',
}

export enum BADGE_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_SMALL = 'x-small',
  X_LARGE = 'x-large',
}

export const DEFAULT_LOCATION = {
  lat: 40.77,
  lng: -74,
};

export enum VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES {
  NOT_FOUND = 'RSC01',
  ERR_VERIFY_MICRO_DEPOSITS = 'RSC11',
  CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS = 'RSC12',
  INVALID_TOKEN = 'RSC13',
}

export enum INPUT_PATTERNS {
  NUMBERS = '[0-9]*',
}

export enum COMPANY_INFO_ONBOARDING_ORIGIN {
  SETUP = 'setup',
  SETTINGS = 'settings',
  ACCOUNTANT_ADD_COMPANY = 'accountant-add-company',
}

export enum NOTIFICATION_VARIANT {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export enum ROLE {
  OWNER = 'owner',
  ADMIN = 'admin',
  CONTRIBUTOR = 'contributor',
  ACCOUNTANT = 'accountant',
  DELETED = 'deleted',
  ACCOUNTING_FIRM_ADMIN = 'accountingFirmAdmin',
  PENDING = 'pending',
}
export enum TAG_VARIANT {
  BRAND = 'brand',
  LIGHT = 'light',
  DARK = 'dark',
  WARNING = 'warning',
  ERROR = 'error',
  DISABLED = 'disabled',
  EXPIRED = 'expired',
  SUCCESS = 'success',
  DEPOSITED = 'deposited',
  ACH_DEPOSITED = 'ach-deposited',
}
export const RISK_BILL_AMOUNT = {
  REQUIRES_INVOICE_FILE: 10000,
  REQUIRES_GOODS_CONFIRMATION: 3000,
  REQUIRES_GOODS_CONFIRMATION_10K: 10000,
  BIG_TRANSFER_THRESHOLD: 100000,
};
export enum EVENT_MAPPING_NAME {
  DEFAULT = 'DEFAULT',
}

export enum FEE_VARIANTS {
  ACH_TO_CHECK = 'ach-to-check',
  ACH_TO_ACH = 'ach-to-ach',
}

export enum FastACHDeliveryType {
  EXPEDITED = 'expedited-ach',
}

export enum FastCheckDeliveryType {
  EXPRESS = 'express-check',
  OVERNIGHT = 'overnight-check',
}

export enum ExpeditedDeliveryType {
  EXPEDITED_ACH = 'expedited-ach',
  OVERNIGHT_CHECK = 'overnight-check',
}

export const EXPEDITED_DELIVERY_TYPES = ['expedited-ach', 'overnight-check'];

export const FAST_DELIVERY_TYPES = [...EXPEDITED_DELIVERY_TYPES, 'express-check'];

export const BATCH_MAX_LIMIT = 20;

export enum LINK_ACCOUNTING_SOFTWARE_ERROR {
  ACCESS_DENIED = 'access_denied',
  DIFF_REALMID = 'diff_realmid',
}

export enum CONNECT_ERROR {
  ACCESS_DENIED = 'access_denied',
  ORG_NOT_EXIST = 'org_not_exist',
  USER_NOT_ACTIVE = 'user_not_active',
  USER_NOT_EXIST = 'user_not_exist',
  NO_USER_PERMISSION = 'no_user_permission',
}

export enum VERIFIED_STATUS {
  PLAID_VERIFIED = 'plaid-verified',
  NOT_VERIFIED = 'not-verified',
  MICRO_DEPOSIT_VERIFIED = 'micro-deposit-verified',
  SOFT_VERIFIED = 'soft-verified',
}

export enum DELIVERY_DATE_FONT_TYPE {
  MEDIUM = 'medium',
  REGULAR = 'regular',
  HINT = 'hint',
  SMALL = 'small',
}

export enum NOTIFICATION_CARD_TYPES {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  HELP = 'help',
}

export const REGEX = {
  CHECK_PRINT_NAME: /[^a-zA-Z0-9,:;/&#@‘\-.() ]|  +/g,
};

export enum FAILED_PAYMENT_TYPE {
  FAILED_PAYMENT = 'failedPayment',
  FAILED_TO_COLLECT = 'failedToCollect',
  FAILED_TO_DELIVER = 'failedToDeliver',
}

const BANK_ACCOUNT_EXIST = 'DLM07';
const FAILED_TO_DELIVER_ERR = 'FAILED_TO_DELIVER';
const RECEIVER_REFUSED_CREDIT_ENTRY = 'receiverRefusedCreditEntry';

export const FULL_STORY_MASK_RULE_CLASS = 'fs-mask';

export const RESPONSE_STATUSES = {
  NOT_FOUND: 404,
};

export enum CALENDAR_TYPE {
  GOOGLE = 'google',
  YAHOO = 'yahoo',
  OUTLOOKCOM = 'outlookcom',
  OUTLOOK = 'outlook',
  APPLE = 'apple',
}

export enum INTUIT_USER_TYPE {
  NEW = 'new',
  QBO_MULTI_ORG = 'qboMultiOrg',
  QBO_SINGLE_ORG = 'qboSingleOrg',
  KNOWN = 'known',
}

export const EMAIL_SOURCE = 'email';

export enum TOGGLE_TYPE {
  COMPLETED = 'completed',
  OPEN = 'open',
}

export const INITIAL_STATE = 'initialState';

export enum PAY_EDIT_LOCATION_NAME {
  DATE = 'date',
  FUNDING = 'funding',
  DELIVERY_METHOD = 'deliveryMethod',
  DELIVERY_METHOD_ACH = 'deliveryMethodAch',
  CONFIRM = 'confirm',
  MEMO = 'memo',
  FAST_PAYMENT = 'fastPayment',
}

const MICRODEPOSITS_VERIFY_SERVER_ERRORS = {
  INVALID_CREDENTIALS: 'server.VT1',
  INVALID_TOKEN: 'server.VT2',
  EXPIRED_TOKEN: 'server.VT3',
} as const;

export const CONSTS = {
  FAST_DELIVERY_TYPES,
  BATCH_MAX_LIMIT,
  EXPEDITED_DELIVERY_TYPES,
  CURRENCY,
  CURRENCY_SIGN,
  COUNTRY,
  DELIVERY_TYPE,
  ADDRESS_TYPE,
  ADDRESS_DEFAULTS,
  ADDRESS_NAME_TYPE,
  MANUAL_ADDRESS,
  BANK_ACCOUNT_TYPE,
  BILL_STATUS,
  BILL_STATUSES,
  PAYMENT_STATUS,
  PAYMENT_COLLECT_STATUS,
  PAYMENT_DELIVER_STATUS,
  RISK_STATUS,
  PAYMENT_APPROVAL_STATUS,
  PAYMENT_ACTION_OPTIONS,
  ONBOARDING_MODULE,
  SCREEN_MODE,
  DATA_ORIGIN,
  ACCOUNTING_SOFTWARE,
  SINGLE_SELECT_FLAVOR,
  MIMONEY_FLAVOR,
  TEXT_INPUT_SIZE,
  FORM_TYPE,
  FORM_CONTROL_TYPE,
  FORM_AUTOCOMPLETE,
  FORM_MAX_LENGTH,
  ADDRESS_FORM_CONTROL_MODE,
  FUNDING_TYPE,
  FUNDING_LOGO,
  FUNDING_SOURCE_ORIGIN,
  FUNDING_SOURCE_LIMIT,
  CARD_NETWORK_TYPES,
  CARD_TYPES,
  VENDOR_MANAGED_BY,
  TAX_ID_TYPE,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
  KYB_STATUS,
  KYB_TYPE,
  MQL_TYPE,
  COMPANY_FORM_PAGE,
  PAGINATION,
  PAGINATION_ITEMS_TYPE,
  PLAID_EVENT_NAME,
  TIMEOUTS,
  INTERVALS,
  REGISTRATION_ORIGIN,
  REGISTRATION_FLOW,
  PAYMENT_CREATE_FLOW_ORIGIN,
  PAYMENT_REQUEST_CREATE_ORIGIN,
  DB_ANALYTICS_TRAITS,
  CONTACTS_TAB,
  SERVER_RESPONSE_CODES,
  TRANSACTION_TYPES,
  TRANSACTION_DESTINATIONS,
  TRANSACTION_DIRECTIONS,
  TRANSACTION_STATUSES,
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  DELIVERY_METHOD_ORIGIN,
  DELIVERY_METHOD_CHECK_FLOW,
  BUTTON_VARIANT,
  BUTTON_SIZE,
  BADGE_SIZE,
  DEFAULT_LOCATION,
  VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES,
  INPUT_PATTERNS,
  COMPANY_INFO_ONBOARDING_ORIGIN,
  NOTIFICATION_VARIANT,
  ROLE,
  TAG_VARIANT,
  RISK_BILL_AMOUNT,
  EVENT_MAPPING_NAME,
  LINK_ACCOUNTING_SOFTWARE_ERROR,
  BUTTONS_DIRECTIONS,
  IMAGE_POSITION,
  VERIFIED_STATUS,
  DELIVERY_DATE_FONT_TYPE,
  NOTIFICATION_CARD_TYPES,
  REGEX,
  FAILED_PAYMENT_TYPE,
  BANK_ACCOUNT_EXIST,
  FAILED_TO_DELIVER_ERR,
  RECEIVER_REFUSED_CREDIT_ENTRY,
  INTUIT_USER_TYPE,
  CREDIT_CARD_FEE_PAYMENT,
  EMAIL_SOURCE,
  TOGGLE_TYPE,
  INITIAL_STATE,
  PAY_EDIT_LOCATION_NAME,
  MICRODEPOSITS_VERIFY_SERVER_ERRORS,
  CARD_PROVIDERS,
  FEE_VARIANTS,
};

export const ISSUER_ADDRESS = '205 Hudson St., New York, NY, USA';

export const ISSUER_ZIP_CODE = '10013';
