import React, { useState } from 'react';
import ManualAddressInvalidPage from 'src/components/layout/ManualAddressInvalidPage';
import ManualAddressConfirmPage from 'src/components/layout/ManualAddressConfirmPage';
import { whitePagesAddressKeys, convertWhitePageAddress } from 'src/utils/address';
import { CONSTS } from 'src/utils/consts';
import { AddressType, CheckType, EditableDeliveryMethodType, OptionalDeliveryMethodsType } from 'src/utils/types';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { ModelView } from 'src/ui/form';
import { AddressRecord } from 'src/pages/vendor/records';
import analytics from 'src/services/analytics';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

type Props = {
  setWhitePageAddressView: (value: boolean) => void;
  whitePageAddress: ManualAddressType;
  model: ModelView<AddressType>;
  isLoading: boolean;
  submit: (deliveryMethod: EditableDeliveryMethodType, isAddressVerified: boolean) => Promise<void>;
  deliveryMethodType?: OptionalDeliveryMethodsType;
};

function useWhitePageAction(props: Props) {
  const [selectedAddressId, setSelectedAddressId] = useState(CONSTS.MANUAL_ADDRESS.SUGGESTED);

  const { submit, model, setWhitePageAddressView, whitePageAddress, deliveryMethodType } = props;
  const originalAddress = convertWhitePageAddress(model);
  const whitePageValidationErrors = () => {
    if (whitePageAddress && whitePageAddress.is_valid) {
      const whitePageAddressDiff = whitePageAddress.diff.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      return pickBy(
        {
          zipCode: whitePageAddressDiff.postal_code,
          addressLine1: whitePageAddressDiff.street_line_1,
          addressLine2: whitePageAddressDiff.street_line_2,
          city: whitePageAddressDiff.city,
          state: whitePageAddressDiff.state_code,
          countryCode: whitePageAddressDiff.country_code,
        },
        identity
      );
    }

    return undefined;
  };

  const isValidAddress = whitePageAddress && whitePageAddress.is_valid;
  const confirmAddress = () => {
    let data;
    let option = '';
    const { printName } = model as ModelView<CheckType>;
    if (isValidAddress && selectedAddressId === CONSTS.MANUAL_ADDRESS.SUGGESTED) {
      option = 'suggested-address-confirmed';
      const updatedAddress = Object.keys(whitePagesAddressKeys).reduce((obj, key) => {
        const newKey = whitePagesAddressKeys[key];
        obj[newKey] = whitePageAddress[key];
        return obj;
      }, AddressRecord().toJSON());
      updatedAddress.googlePlaceId = CONSTS.ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID;
      data = { ...updatedAddress, isAddressSuggestionIgnored: false };
    } else {
      option =
        whitePageAddress && !whitePageAddress.is_valid
          ? 'no-suggestion-address-confirmed'
          : 'suggested-address-declined';
      data = { ...originalAddress, isAddressSuggestionIgnored: true };
    }

    analytics.trackAction('submit-address', { option });
    data.printName = printName?.value;
    if (deliveryMethodType === CONSTS.DELIVERY_TYPE.CARD) {
      submit({ deliveryType: CONSTS.DELIVERY_TYPE.CARD, cardAccount: data }, true);
    } else {
      submit({ deliveryType: CONSTS.DELIVERY_TYPE.CHECK, paperCheck: data }, true);
    }
  };

  const renderWhitePageAddress = () => {
    const { printName } = model as ModelView<CheckType>;
    if (isValidAddress) {
      return (
        <ManualAddressConfirmPage
          addressToConfirm={whitePageAddress}
          addressValidationErrors={whitePageValidationErrors()}
          originalAddress={originalAddress}
          printName={printName?.value}
          onAddressSelect={(id) => setSelectedAddressId(id)}
          onEditAddress={() => setWhitePageAddressView(false)}
          selectedAddressId={selectedAddressId}
        />
      );
    }

    return (
      <ManualAddressInvalidPage
        originalAddress={originalAddress}
        onEditAddress={() => setWhitePageAddressView(false)}
        printName={printName?.value}
      />
    );
  };
  return [confirmAddress, renderWhitePageAddress];
}

export default useWhitePageAction;
