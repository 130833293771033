import React from 'react';
import styled from 'styled-components';
import { Image } from '@melio/billpay-design-system';
import { NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import warningIcon from 'src/images/icons/warning-icon.svg';

type LabelValues = {
  label: string;
  values?: Record<string, any>;
};

export type NotificationCardProps = {
  className?: string;
  type: NOTIFICATION_CARD_TYPES;
  title?: LabelValues;
  subtitle?: LabelValues;
  onClose?: () => void;
};

const QBONotificationCard = ({ className = '', type, title, subtitle, onClose }: NotificationCardProps) => {
  const iconClass = getIcon(type);
  return (
    <Container className={className} type={type} showCloseButton={!!onClose} hasSubtitle={!!subtitle}>
      <NotificationIcon className={iconClass} type={type} />
      <TextContainer>
        <Title className="notification-title" hasSubtitle={!!subtitle}>
          <MIFormattedText label={title?.label} values={title?.values} />
        </Title>
        {subtitle?.label && (
          <Subtitle className="notification-subtitle">
            <MIFormattedText label={subtitle?.label} values={subtitle.values} />
          </Subtitle>
        )}
      </TextContainer>
      {!!onClose && <CloseButton className="icon-close-icon" onClick={onClose} />}
    </Container>
  );
};

export default QBONotificationCard;

const getIcon = (type: NOTIFICATION_CARD_TYPES) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.INFO:
      return 'icon-intuit-info-icon';
    case NOTIFICATION_CARD_TYPES.SUCCESS:
      return 'icon-full-checkmark-icon';
    case NOTIFICATION_CARD_TYPES.WARNING:
    default:
      return 'icon-warning-icon';
  }
};

const getColor = (type: NOTIFICATION_CARD_TYPES, theme: any) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.ERROR:
      return theme.colors.failure.opaque;
    case NOTIFICATION_CARD_TYPES.WARNING:
      return theme.colors.warning.opaque;
    case NOTIFICATION_CARD_TYPES.INFO:
      return theme.text.color.blue;
    case NOTIFICATION_CARD_TYPES.SUCCESS:
    default:
      return theme.colors.success.opaque;
  }
};

const NotificationIcon = ({ type, className }) => {
  if (type === NOTIFICATION_CARD_TYPES.WARNING) {
    return <Image h="1.8rem" mr="ds.md" mt="ds.xs" src={warningIcon} />;
  }

  return <Icon type={type} className={className} />;
};

type ContainerType = {
  type: NOTIFICATION_CARD_TYPES;
  showIcon: boolean;
  showCloseButton: boolean;
  hasSubtitle: boolean;
};

const Container = styled.div<ContainerType>`
  display: flex;
  padding: ${({ hasSubtitle }) => (hasSubtitle ? '2rem 2rem 2rem 2rem' : '2.8rem 2rem')};
  border-radius: 0.8rem;
  border-style: solid;
  border-width: 0.1rem;
  box-sizing: border-box;
  border-color: ${({ type, theme }) => getColor(type, theme)};
  padding: 1.6rem;

  ${(props) => props.theme?.components?.QBONotificationCard?.Container}
`;

const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const Icon = styled.i<{ type: NOTIFICATION_CARD_TYPES }>`
  color: ${({ type, theme }) => getColor(type, theme)};
  font-size: 2.4rem;
  margin-left: -0.3rem;
  margin-top: -0.3rem;
  margin-right: 0.7rem;
`;

const Title = styled.div<{ hasSubtitle: boolean }>`
  color: ${({ theme }) => theme.text.color.main};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  ${({ theme }) => theme.text.fontType.medium};
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.text.color.darkGrey};
  ${({ theme }) => theme.text.fontType.medium};
`;

const CloseButton = styled.i`
  color: ${({ theme }) => theme.text.color.darkGrey};
  font-size: 2.4rem;
  cursor: pointer;
  margin-left: auto;
  align-self: start;
`;

export { Icon, Title, TextContainer };
