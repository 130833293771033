import React, { useState } from 'react';
import NotificationCard, {
  NotificationCardProps,
  Icon,
  Title,
  TextContainer,
} from 'src/components/qbo/QBONotificationCard';
import styled from 'styled-components';

export const BatchPayFlowSuccessNotification = (props: NotificationCardProps) => {
  const [visible, setVisible] = useState<boolean>(true);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return <StyledNotificationCard {...props} onClose={handleClose} />;
};

const StyledNotificationCard = styled(NotificationCard)`
  ${Icon} {
    font-size: 2rem;
    margin: 0 1rem 0 0;
  }

  ${Title} {
    margin-bottom: 0.8rem;
  }

  ${TextContainer} {
    margin-right: 1rem;
  }
`;
