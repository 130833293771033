import { CONSTS, DELIVERY_TYPE } from 'src/utils/consts';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import { isEligibleToReceiveVirtualCard } from 'src/pages/vendor/virtual-delivery-method/utils';
import { AccountType, DeliveryMethodType } from 'src/utils/types';
import { getDeliveryMethodByType } from 'src/records/vendor';

function getDefaultDeliveryMethod(deliveryMethods) {
  const deliveryTypesPriority = [
    CONSTS.DELIVERY_TYPE.RPPS,
    CONSTS.DELIVERY_TYPE.VIRTUAL,
    CONSTS.DELIVERY_TYPE.ACH,
    CONSTS.DELIVERY_TYPE.CHECK,
  ];
  const supportedDeliveryMethods = deliveryMethods.filter(
    (dm) => deliveryTypesPriority.indexOf(dm.deliveryType) !== -1
  );
  return head(sortBy(supportedDeliveryMethods, (dm) => deliveryTypesPriority.indexOf(dm.deliveryType)));
}

type Params = {
  deliveryMethods: DeliveryMethodType[];
  fundingSource?: AccountType;
  currentDeliveryMethodId?: string;
};

export const getDeliveryMethodForPayment = ({
  deliveryMethods,
  fundingSource,
  currentDeliveryMethodId,
}: Params): DeliveryMethodType | undefined => {
  const currentDeliveryMethod = deliveryMethods.find((dm) => dm.id === currentDeliveryMethodId);
  const isVirtualCardSupported = isEligibleToReceiveVirtualCard(fundingSource);
  const hasVirtualCardDeliveryMethod = deliveryMethods.some((dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD);

  if (hasVirtualCardDeliveryMethod && isVirtualCardSupported) {
    return getDeliveryMethodByType(DELIVERY_TYPE.VIRTUAL_CARD, deliveryMethods);
  }

  const isCurrentDeliveryMethodInvalid =
    !isVirtualCardSupported && currentDeliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

  if (isCurrentDeliveryMethodInvalid || !currentDeliveryMethod) {
    return getDefaultDeliveryMethod(deliveryMethods);
  }

  return currentDeliveryMethod;
};
