import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CardExpirationDateElement } from '@basis-theory/basis-theory-react';
import { CardExpirationDateElement as CardExpirationDateElementType } from '@basis-theory/basis-theory-js/types/elements/elements';
import { Flex } from '@melio/billpay-design-system';
import { FieldErrors } from 'src/billpay/qbdt/components/basis-theory/fields/common/FieldErrors';
import { FieldInputWrapper } from 'src/billpay/qbdt/components/basis-theory/fields/common/FieldInputWrapper';
import { FieldTitle } from 'src/billpay/qbdt/components/basis-theory/fields/common/FieldTitle';
import { ELEMENT_COMMON_STYLE } from 'src/billpay/qbdt/components/basis-theory/fields/consts';
import { useErrorValidations } from 'src/billpay/qbdt/components/basis-theory/fields/hooks/useErrorValidations';
import { CardFieldProps } from 'src/billpay/qbdt/components/basis-theory/types';

export const CardExpirationDateField = ({
  onError,
  attemptedToLink,
  elementRef,
  onReady,
}: CardFieldProps<CardExpirationDateElementType>) => {
  const [focus, setFocus] = useState(false);
  const intl = useIntl();

  const onBlur = () => {
    setFocus(false);
  };

  const { handleChange, handleBlur, shouldShowError, errors, empty } = useErrorValidations({
    onError,
    onBlur,
    attemptedToLink,
  });

  const handleFocus = () => {
    setFocus(true);
  };

  const placeholder = intl.formatMessage({ id: 'linkCard.cardExpirationDatePlaceholder' });

  return (
    <Flex direction="column" w="48%">
      <FieldTitle id="ced" label="linkCard.cardExpirationDateTitle" />
      <FieldInputWrapper error={shouldShowError} focus={focus}>
        <CardExpirationDateElement
          id="expiration_date"
          ref={elementRef}
          onReady={onReady}
          style={ELEMENT_COMMON_STYLE}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FieldInputWrapper>
      {shouldShowError && (
        <FieldErrors
          testId="expiration-date-errors"
          fieldErrors={errors}
          emptyLabel="linkCard.errors.cardExpirationDate.empty"
          invalidLabel="linkCard.errors.cardExpirationDate.invalid"
          empty={empty}
        />
      )}
    </Flex>
  );
};
