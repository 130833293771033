import React from 'react';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { VirtualCardInfoIcon } from './VirtualCardInfoIcon';

type Props = {
  deliveryType: DELIVERY_TYPE;
};

export const DeliveryMethodLabel = ({ deliveryType }: Props) => {
  const isVirtualCardDeliveryMethod = deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;
  const isCheckDeliveryMethod = deliveryType === DELIVERY_TYPE.CHECK;

  const getDeliveryMethodLabel = () => {
    if (isCheckDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.paperCheckLabel';
    }

    if (isVirtualCardDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.virtualCardLabel';
    }

    return 'payBillPaymentActivity.deliveryMethod.achLabel';
  };

  const label = getDeliveryMethodLabel();

  return (
    <>
      <MIFormattedText label={label} />
      {isVirtualCardDeliveryMethod && <VirtualCardInfoIcon />}
    </>
  );
};
