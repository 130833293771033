import React from 'react';
import { Flex, Text } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { CardFieldsProps } from 'src/pages/vendor/virtual-card-details/components/CardFields/CardFields';
import { ISSUER_ADDRESS, ISSUER_ZIP_CODE } from 'src/utils/consts';

export const CardFieldsDesktop = ({ exp, cvc, number, onClick }: CardFieldsProps) => (
  <Flex direction="column" gap="ds.md" w="100%">
    <Flex width="100%" align="start" justify="space-between">
      <Flex
        direction="column"
        align="start"
        gap="ds.sm"
        cursor="pointer"
        onClick={() => onClick('cardNumber', number.split(' ').join(''))}
      >
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.cardNumber" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          {number}
        </Text>
      </Flex>
      <Flex direction="column" align="start" gap="ds.sm" cursor="pointer" onClick={() => onClick('cvc', cvc)}>
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.cvc" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          {cvc}
        </Text>
      </Flex>
      <Flex
        direction="column"
        align="start"
        gap="ds.sm"
        w="7.5rem"
        cursor="pointer"
        onClick={() => onClick('expiryDate', exp)}
      >
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.expiryDate" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          {exp}
        </Text>
      </Flex>
    </Flex>
    <Flex width="100%" align="start" justify="space-between">
      <Flex
        direction="column"
        align="start"
        gap="ds.sm"
        cursor="pointer"
        onClick={() => onClick('issuerAddress', ISSUER_ADDRESS)}
      >
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.issuerAddress" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.issuerAddress" />
        </Text>
      </Flex>
      <Flex
        direction="column"
        align="start"
        gap="ds.sm"
        w="7.5rem"
        cursor="pointer"
        onClick={() => onClick('zipCode', ISSUER_ZIP_CODE)}
      >
        <Text textStyle="ds.body2Medium" color="ds.gray.300" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.labels.zipCode" />
        </Text>
        <Text textStyle="ds.body2Medium" color="ds.gray.100" m="0">
          <MIFormattedText label="vendor.unilateral.virtualCardDetails.zipCode" />
        </Text>
      </Flex>
    </Flex>
    <Text textStyle="ds.body3" color="ds.gray.200" m="0">
      <MIFormattedText label="vendor.unilateral.virtualCardDetails.issuerAddressHint" />
    </Text>
  </Flex>
);
