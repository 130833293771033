import React from 'react';
import { RecordOf } from 'immutable';
import { featureFlags } from '@melio/shared-web';
import { chakra } from 'src/theme/ds';
import { FeatureFlags } from 'src/utils/feature-flags';
import { Box } from '@melio/billpay-design-system';
import { BILL_STATUS, DELIVERY_TYPE, PAYMENT_STATUS, TAG_VARIANT } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { isSameDay, DeliveryDate } from 'src/utils/dates';
import MITag from 'src/components/common/MITag';
import { AccountType, BillType, DeliveryMethodType, PaymentExpeditedByEnum, PaymentType } from 'src/utils/types';
import { isFastDeliveryType } from 'src/utils/delivery-methods';
import { getCheckDepositedDate } from 'src/utils/payments';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import { CheckDelaysIcon, CheckDelaysIconVariant } from 'src/components/common/CheckDelaysIcon';
import DeliveryDateInfoHint from './DeliveryDateInfoHint';
import CheckDeposited from './CheckDeposited';
import { getBillPaidColor } from '../../utils/getBillPaidColor';

type Props = {
  payment: PaymentType;
  bill: BillType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: RecordOf<AccountType>;
};

export const ViewPaymentDeliveryDateInfo = ({ payment, bill, deliveryMethod, fundingSource }: Props) => {
  const [isCheckDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isSameDayDelivery = isSameDay(payment.deliveryEta);
  const isExpeditedByPayee = payment.expeditedBy === PaymentExpeditedByEnum.PAYEE;
  const isFast = !isExpeditedByPayee && isFastDeliveryType(payment.deliveryPreference);
  const label = isBillPaid
    ? 'viewBillPaymentActivity.deliveryDate.paidBillDeliveryEtaLabel'
    : 'viewBillPaymentActivity.deliveryDate.deliveryEtaLabel';
  let checkDepositedDate;

  const isDeliveryMethodCheck = deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK;

  if (isBillPaid && isDeliveryMethodCheck) {
    checkDepositedDate = getCheckDepositedDate(payment.transactions);
  }

  const shouldShowCheckDelaysWarning =
    isCheckDelaysEnabled &&
    !isFast &&
    isDeliveryMethodCheck &&
    [PAYMENT_STATUS.SCHEDULED, PAYMENT_STATUS.BLOCKED].includes(payment.status as PAYMENT_STATUS);

  return (
    <>
      <PaymentFieldContainer>
        <PaymentFieldIcon color={getBillPaidColor(isBillPaid)}>
          <i className="icon-eta-cal" />
        </PaymentFieldIcon>
        <PaymentFieldInfo>
          <PaymentFieldLabel>
            <MIFormattedText label={label} />
          </PaymentFieldLabel>
          <PaymentFieldContent color={getBillPaidColor(isBillPaid)}>
            <Box>
              {isSameDayDelivery && <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />}
              <DeliveryDate date={payment.deliveryEta} maxDate={payment.maxDeliveryEta} />
              {isFast && <FastTag label="bills.pay.date.deliveryOptions.price.fastTag" variant={TAG_VARIANT.BRAND} />}
              {shouldShowCheckDelaysWarning && (
                <CheckDelaysIcon
                  variant={CheckDelaysIconVariant.WARNING}
                  sx={{
                    position: 'relative',
                    top: '3px',
                    ml: 'ds.sm',
                  }}
                />
              )}
            </Box>
            {shouldShowCheckDelaysWarning && (
              <Box textStyle="ds.body3" color="rgba(153, 153, 156, 1)">
                <MIFormattedText label="checkDelaysWarning.tooltipLabels.specificCheck" />
              </Box>
            )}
            {!isBillPaid && (
              <DeliveryDateInfoHint payment={payment} deliveryMethod={deliveryMethod} fundingSource={fundingSource} />
            )}
          </PaymentFieldContent>
        </PaymentFieldInfo>
      </PaymentFieldContainer>
      {checkDepositedDate && <CheckDeposited date={checkDepositedDate} />}
    </>
  );
};

const FastTag = chakra(MITag, {
  baseStyle: {
    ml: '0.6rem',
    verticalAlign: 'middle',
    fontSize: '0.8rem',
    padding: '0 0.4rem',
    position: 'relative',
    top: '-2px',
  },
});
