import noop from 'lodash/noop';
import { OrganizationPreferencesType, OrganizationFeeCatalogType } from '../../utils/types';
import {
  SET_ORGANIZATION_PREFERENCES,
  UPDATE_ORGANIZATION_PREFERENCE,
  UPDATE_ORGANIZATION_PREFERENCE_SUCCESS,
  UPDATE_ORGANIZATION_PREFERENCE_FAILED,
  LOAD_FEE_CATALOG_SUCCESS,
  LOAD_FEE_CATALOG_FAILED,
} from './actionTypes';

export const updateOrganizationPreferenceAction = (
  orgId: string,
  key: string,
  value: any,
  resolve: () => void = noop,
  reject: () => void = noop
) => ({
  type: UPDATE_ORGANIZATION_PREFERENCE,
  orgId,
  key,
  value,
  resolve,
  reject,
});

export const setOrganizationPreferencesAction = (organizationPreferences: OrganizationPreferencesType) => ({
  type: SET_ORGANIZATION_PREFERENCES,
  organizationPreferences,
});

export const updateOrganizationPreferenceSuccessAction = (key: string, value: any) => ({
  type: UPDATE_ORGANIZATION_PREFERENCE_SUCCESS,
  key,
  value,
});

export const updateOrganizationPreferenceFailedAction = () => ({
  type: UPDATE_ORGANIZATION_PREFERENCE_FAILED,
});

export const loadFeeCatalogSuccessAction = (feeCatalog: OrganizationFeeCatalogType) => ({
  type: LOAD_FEE_CATALOG_SUCCESS,
  feeCatalog,
});

export const loadFeeCatalogFailedAction = () => ({
  type: LOAD_FEE_CATALOG_FAILED,
});
