import React, { useEffect } from 'react';
import { OrganizationType, PaymentType } from 'src/utils/types';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import { getFormattedAddress } from 'src/utils/formatting';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { PaymentInfo, VendorReceive } from '../components/OfferHasExpiredPage';
import { useInvoiceNumberType } from '../../hooks/useInvoiceNumberType';

type Props = {
  payment?: PaymentType;
  organization?: OrganizationType;
};

export const OfferHasExpiredPage = ({ payment, organization }: Props) => {
  const amount = payment?.amount || 0;
  const { deliveryMethod, deliveryEta, maxDeliveryEta } = payment || {};
  const companyName = organization?.companyName || '';
  const { invoiceNumber, invoiceNumberType } = useInvoiceNumberType(payment);
  const address = deliveryMethod?.paperCheck ? getFormattedAddress(deliveryMethod.paperCheck) : '';

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'offer-has-expired', { OriginDeliveryType: 'check' });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <PaymentInfo
          amount={amount}
          companyName={companyName}
          invoiceNumber={invoiceNumber}
          invoiceNumberType={invoiceNumberType}
        />
        <Divider my="ds.2xl" />
        <VendorReceive address={address} deliveryEta={deliveryEta} maxDeliveryEta={maxDeliveryEta} />
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
