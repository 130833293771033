import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';
import billsStore from 'src/modules/bills/bills-store';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { BulkPaymentInvoiceNumberCell } from './BulkPaymentInvoiceNumberCell/BulkPaymentInvoiceNumberCell';
import { BillInfo } from './BulkPaymentInvoiceNumberCell/types';
import { EMPTY_INVOICE_NUMBER } from './consts';

type Props = {
  item: DashboardListItemType;
};

export const DashboardInvoiceNumberCell = ({ item }: Props) => {
  const bills = useSelector(billsStore.selectors.getByIds(item.billIds));

  const isBulkPayment = bills.length > 1;

  if (isBulkPayment) {
    const billsInfo = bills.map((bill) => ({
      invoiceNumber: bill.invoiceNumber,
      dueDate: bill.dueDate,
      totalAmount: bill.totalAmount,
    }));

    return <BulkPaymentInvoiceNumberCell billsInfo={billsInfo as BillInfo[]} />;
  }

  const firstBill = bills[0];
  const label = firstBill?.invoiceNumber ? firstBill.invoiceNumber : EMPTY_INVOICE_NUMBER;

  return <Box color={label === EMPTY_INVOICE_NUMBER ? 'ds.gray.200' : 'inherit'}>{label}</Box>;
};
