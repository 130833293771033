import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { CONSTS } from 'src/utils/consts';
import locations from 'src/billpay/qbdt/pages/locations';
import { getOrgId } from 'src/redux/user/selectors';
import analytics from 'src/services/analytics';
import { InitialAddFundingSourceLocationState } from 'src/billpay/qbdt/pages/funding-source/types';
import { BATCH_PAGE_EVENT } from '../../analytics/event-mapping';

type useGoAddFundingSourceOfTypeParams = {
  initialLocationState: InitialAddFundingSourceLocationState;
};

export const useGoAddFundingSourceOfType = ({ initialLocationState }: useGoAddFundingSourceOfTypeParams) => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);

  const getRedirectData = (selectedFundingSourceType) => {
    const isAchFundingSource = selectedFundingSourceType === CONSTS.FUNDING_TYPE.ACH;
    const isDebitCardFundingSource = selectedFundingSourceType === CONSTS.CARD_TYPES.DEBIT;
    const isCreditCardFundingSource = selectedFundingSourceType === CONSTS.CARD_TYPES.CREDIT;

    if (isAchFundingSource) {
      return {
        analyticsEventName: 'add-bank-account',
        path: locations.fundingSource.bankSelect,
      };
    }

    if (isDebitCardFundingSource || isCreditCardFundingSource) {
      return {
        analyticsEventName: isDebitCardFundingSource ? 'add-debit-card' : 'add-credit-card',
        path: locations.fundingSource.card,
      };
    }

    return null;
  };

  const goAddFundingSourceOfType = async (selectedFundingSourceType) => {
    const redirectData = getRedirectData(selectedFundingSourceType);

    if (!redirectData) {
      return;
    }

    analytics.track(BATCH_PAGE_EVENT, redirectData.analyticsEventName);
    history.push(generatePath(redirectData.path, { orgId }), initialLocationState);
  };

  return { goAddFundingSourceOfType };
};
