import { DeliveryMethodType, DeliveryOptionType, BillType, CompanyInfoType, PaymentType } from 'src/utils/types';
import remove from 'lodash/remove';
import find from 'lodash/find';
import clone from 'lodash/clone';
import head from 'lodash/head';
import { getDeliveryMethodById } from '../pages/bill/records';
import { CONSTS, DELIVERY_TYPE, FastCheckDeliveryType, FAST_DELIVERY_TYPES } from './consts';
import { getAccountNumber4digits } from './bank-account';
import locations from './locations';
import { isPOBox } from './address';

const getDeliveryMethodName = (deliveryMethod?: DeliveryMethodType) => {
  const accountType = deliveryMethod?.bankAccount ? deliveryMethod?.bankAccount.accountType : '';
  const accountTypeCapitalize = accountType.charAt(0).toUpperCase() + accountType.slice(1);
  const deliveryInfo = deliveryMethod?.bankAccount ? deliveryMethod?.getDeliveryInfo() : '';
  return `${accountTypeCapitalize} account ${deliveryInfo}`;
};

const deliveryTypePredicate = (deliveryType?: DELIVERY_TYPE) => (dm: DeliveryMethodType) =>
  dm.deliveryType === deliveryType;

const formatCheckPrintName = (printName: string) =>
  (printName || '')
    .replace(/[^a-zA-Z0-9,:;/&#@‘\-.() ]/g, ' ')
    .replace(/  +/g, ' ')
    .toUpperCase();

type RemoveUnsupportedDeliveryOptionsByBill = {
  deliveryOptions: DeliveryOptionType[];
  bill: BillType;
  payment: PaymentType;
  companyInfo: CompanyInfoType;
};

const removeUnsupportedDeliveryOptionsByBill = ({
  deliveryOptions,
  bill,
  payment,
  companyInfo,
}: RemoveUnsupportedDeliveryOptionsByBill) =>
  removeUnsupportedDeliveryOptionsByDeliveryMethod({
    deliveryOptions,
    deliveryMethod: getDeliveryMethodById(bill, payment?.deliveryMethodId) || undefined,
    companyInfo,
  });

type RemoveUnsupportedDeliveryOptionsByDeliveryMethod = {
  deliveryOptions: DeliveryOptionType[];
  deliveryMethod: DeliveryMethodType<'created'> | DeliveryMethodType | undefined;
  companyInfo: CompanyInfoType;
};

const removeUnsupportedDeliveryOptionsByDeliveryMethod = ({
  deliveryOptions,
  deliveryMethod,
  companyInfo,
}: RemoveUnsupportedDeliveryOptionsByDeliveryMethod) => {
  const resultDeliveryOptions = clone(deliveryOptions);

  if (resultDeliveryOptions?.length > 1) {
    remove(resultDeliveryOptions, (item: DeliveryOptionType) => item.type === FastCheckDeliveryType.OVERNIGHT);

    if (isDeliveryMethodAddressPOBox(deliveryMethod) || !isCompanyEligibleForFastCheck(companyInfo)) {
      remove(resultDeliveryOptions, (item: DeliveryOptionType) => item.type === FastCheckDeliveryType.EXPRESS);
    }
  }

  return resultDeliveryOptions;
};

const isCompanyEligibleForFastCheck = (companyInfo: CompanyInfoType) =>
  companyInfo.legalState && companyInfo.state && companyInfo.canPayWithFastCheck;

const isDeliveryMethodAddressPOBox = (deliveryMethod?: DeliveryMethodType) =>
  isPOBox(deliveryMethod?.paperCheck?.addressLine1);

const shouldDisplayDeliverySpeed = (fundingSourceId, deliveryOptions) =>
  !!fundingSourceId && Array.isArray(deliveryOptions) && deliveryOptions.length > 1;

const getDeliveryOption = (deliveryPreference?: string, deliveryOptions?: DeliveryOptionType[]) =>
  find(deliveryOptions, (o) => o.type === deliveryPreference) || (deliveryOptions && deliveryOptions[0]) || null;

const getFastType = (deliveryMethodType) => {
  switch (deliveryMethodType) {
    case DELIVERY_TYPE.ACH:
      return 'expedited-ach';

    case DELIVERY_TYPE.CHECK:
      return 'express-check';

    default:
      return deliveryMethodType;
  }
};

const isFastDeliveryType = (deliveryPreference) => FAST_DELIVERY_TYPES.includes(deliveryPreference || '');

const getPaymentFastFee = (bill, deliveryOptions) => {
  const payment = head(bill.payments) as PaymentType;
  const { deliveryPreference } = payment;
  if (!deliveryPreference) return 0;

  const deliveryOption = deliveryOptions.find((item) => item.type === deliveryPreference);
  const fee = parseFloat(deliveryOption?.amount);
  return fee || 0;
};

const calculateTotalFee = (billItems) => {
  let totalFee = 0;

  for (const { bill, deliveryOptions } of billItems) {
    const fee = getPaymentFastFee(bill, deliveryOptions);
    if (fee) {
      totalFee += fee;
    }
  }

  return totalFee;
};

const getNameParts = (deliveryMethod: DeliveryMethodType | null) => {
  if (deliveryMethod?.bankAccount) {
    return {
      displayName: deliveryMethod?.plaidAccount ? '' : 'Bank account',
      institutionName: deliveryMethod?.plaidAccount?.plaidItem?.institutionName,
      identifier: getAccountNumber4digits(deliveryMethod.bankAccount),
    };
  }

  return {
    displayName: '',
    institutionName: '',
    identifier: '',
  };
};

const getAddDeliveryMethodUrlMap = (orgId: string, vendorId?: string) => ({
  [CONSTS.DELIVERY_TYPE.ACH]: locations.Vendors.deliveryMethods.ach.create.url({
    orgId,
    id: vendorId,
  }),
  [CONSTS.DELIVERY_TYPE.CHECK]: locations.Vendors.deliveryMethods.check.create.url({
    orgId,
    id: vendorId,
  }),
  [CONSTS.DELIVERY_TYPE.VIRTUAL]: locations.Vendors.deliveryMethods.virtual.create.url({
    orgId,
    id: vendorId,
  }),
});

const isCheckDeliveryMethod = (deliveryMethod: DeliveryMethodType) =>
  deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;

export {
  getDeliveryMethodName,
  deliveryTypePredicate,
  formatCheckPrintName,
  removeUnsupportedDeliveryOptionsByBill,
  isCompanyEligibleForFastCheck,
  isDeliveryMethodAddressPOBox,
  shouldDisplayDeliverySpeed,
  getDeliveryOption,
  getFastType,
  calculateTotalFee,
  isFastDeliveryType,
  getPaymentFastFee,
  getNameParts,
  getAddDeliveryMethodUrlMap,
  removeUnsupportedDeliveryOptionsByDeliveryMethod,
  isCheckDeliveryMethod,
};
