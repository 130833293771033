import { useEffect, useRef } from 'react';

export type OnMessage<T> = (arg: T) => void;
export function useFrameMessage<T>(onMessage: OnMessage<T>) {
  const frameRef = useRef<Window>();
  useEffect(() => {
    function handleMessage(event: MessageEvent<T>) {
      if (event.source === frameRef.current) {
        onMessage(event.data);
      }
    }

    window.addEventListener('message', handleMessage, false);

    return () => window.removeEventListener('message', handleMessage, false);
  }, []);

  return [frameRef] as const;
}
