import * as React from 'react';
import { useSelector } from 'react-redux';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { useLocationState } from 'src/utils/hooks';
import styled from 'styled-components';
import { compose } from 'recompose';
import { CONSTS } from 'src/utils/consts';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { withPreservedStateNavigator } from 'src/hoc';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';

type Props = {
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const CompleteAddingBankAccountsPageContainer = ({ navigateWithPreservedState }: Props) => {
  const site = useSiteContext();
  const fundingSources = useSelector(fundingSourcesStore.selectors.all);
  const [newFundingSourceId] = useLocationState('newFundingSourceId', '');
  const fundingSource = fundingSources.find((fs) => fs.id === newFundingSourceId);

  const goNextFlow = () => {
    if (navigateWithPreservedState) {
      // Passed state is used in batch payments
      const fs = newFundingSourceId ? { fsId: newFundingSourceId } : { origin: CONSTS.FUNDING_SOURCE_ORIGIN.PLAID };
      const state: Record<string, any> = {
        newFundingSource: fs,
      };

      navigateWithPreservedState(state);
    }
  };

  let title = 'onboarding.fundingSources.bank.complete.title';
  let subTitle = 'onboarding.fundingSources.bank.complete.subtitle';
  let buttonCTA = 'onboarding.fundingSources.bank.complete.next';
  let illustration = site.theme.pages?.CompleteAddingBankAccountsPage?.successImage;
  let textAlign = 'center';
  const subTitleValues: { email?: JSX.Element } = {
    email: (
      <SupportLink href={`mailto:${site.config.support.email}`} target="_blank">
        {site.config.support.email}
      </SupportLink>
    ),
  };
  if (fundingSource?.origin === CONSTS.FUNDING_SOURCE_ORIGIN.MANUAL) {
    title = 'onboarding.fundingSources.bank.manually.connectSuccessTitle';
    subTitle = 'onboarding.fundingSources.bank.manually.connectSuccessSubTitle';
    buttonCTA = 'onboarding.fundingSources.bank.manually.buttonCTA';
    illustration = site.theme.pages?.CompleteAddingBankAccountsPage?.pendingImage;
    textAlign = 'left';
  }

  return (
    <QBDTSuccessLayout
      illustration={illustration}
      textAlign={textAlign}
      title={title}
      text={subTitle}
      textValues={subTitleValues}
      buttonLabel={buttonCTA}
      buttonAction={goNextFlow}
      hideHeader
    />
  );
};

export default compose(withPreservedStateNavigator())(CompleteAddingBankAccountsPageContainer);

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;
