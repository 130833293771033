import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@melio/billpay-design-system';
import analytics from 'src/services/analytics';
import { MIFormattedText } from 'src/utils/formatting';

import { dashboardStore } from '../../../redux-store/dashboard-store';
import useSchedulePaymentAction from '../../../hooks/useSchedulePaymentAction';
import useScheduleBatchPaymentsAction from '../../../hooks/useScheduleBatchPaymentsAction';
import { useSelectedBillIds } from '../hooks/useSelectedBillIds';

export const DashboardFooterButton = () => {
  const selectedDashboardItemsIds = useSelector(dashboardStore.selectors.dashboardSelection.selectedIds);
  const selectedBillIds = useSelectedBillIds();
  const itemsCount = selectedDashboardItemsIds.length;
  const isBatch = itemsCount > 1;

  const [schedulePayment] = useSchedulePaymentAction({
    billId: selectedBillIds[0],
  });

  const goSchedulePayment = () => {
    analytics.trackAction('batch-actions', {
      action: 'schedule-single',
      billIds: selectedBillIds,
      partialPaymentsCount: 0, // TODO: change with actual value when partial payments will be introduced
    });

    schedulePayment();
  };

  const [goScheduleBatchPayments] = useScheduleBatchPaymentsAction(selectedBillIds);

  const onClick = isBatch ? goScheduleBatchPayments : goSchedulePayment;

  return (
    <Button variant="primary" size="md" onClick={onClick} data-testid="footer-main-action">
      <MIFormattedText label="paymentDashboard.footer.payOnline" values={{ amount: itemsCount }} />
    </Button>
  );
};
