import config from 'src/config';
import { datadogRum } from '@datadog/browser-rum';

const absPathBlock = [
  'api-analytics.melio.com',
  'googlesyndication.com',
  'edge.fullstory.com',
  'obs.cheqzone.com',
  'h.clarity.ms',
  'js.intercomcdn.com',
  'maps.googleapis.com',
  'launchdarkly-js-sdk',
  'googletagmanager.com',
  'safari-extension',
  'safari-web-extension',
  'chrome-extension',
  'cdn.nmgassets.com',
] as const;

const shouldDiscardErrorLog = (stackTrace?: string) => {
  if (!stackTrace) {
    return false;
  }

  return absPathBlock.some((path) => stackTrace.includes(path));
};

export const dataDog = {
  init: () => {
    if (process.env.REACT_APP_ENV === 'production') {
      const { clientToken, applicationId, site, service } = config.services.dataDog;

      datadogRum.init({
        applicationId,
        clientToken,
        site,
        service,
        env: process.env.REACT_APP_ENV,
        sampleRate: 100,
        premiumSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        useCrossSiteSessionCookie: true,
        // eslint-disable-next-line consistent-return
        beforeSend: (event) => {
          if (event?.type === 'error') {
            const stackTrace = event?.error?.stack;

            if (shouldDiscardErrorLog(stackTrace)) {
              return false;
            }
          }
        },
      });

      datadogRum.startSessionReplayRecording();
    }
  },
};
