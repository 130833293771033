import { createListSlice } from 'src/helpers/redux/restListSlice';
import mergeWith from 'lodash/mergeWith';
import isNil from 'lodash/isNil';
import api from 'src/services/api/bills';
import { hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { name } from './consts';

const regularListSlice = createListSlice({
  storeName: name,
  api: (params) => api.getBillList(params).then((res) => ({ items: res.objects, totalCount: res.totalCount })),
  actionName: 'REGULAR_LIST',
  listHashFunc: hashListKey,
  resultMapFunction: (state, currItem, newItem) =>
    mergeWith(currItem, newItem, (currItem, newItem) => {
      if (!isNil(newItem)) {
        return newItem;
      }

      return currItem;
    }),
});

export default regularListSlice;
