const cellStyle = {
  px: 'ds.lg',
  py: '1.2rem',
  boxSizing: 'border-box',
};

export const qdbtBillingFeesHistoryMdSize = {
  td: {
    ...cellStyle,
    '&:last-of-type': {
      width: '8rem',
      p: 0,
    },
  },
  th: {
    ...cellStyle,
    boxSizing: 'border-box',
    '&:last-of-type': {
      width: '8rem',
    },
  },
};
