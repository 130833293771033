import { CancelToken } from 'axios';
import { fetchRequest } from 'src/services/api/api';
import { DashboardTabStatusEnum } from '../consts';
import { DashboardListItemTypeResponse, DashboardListParams } from '../types';
import { getDashboardItemId } from '../utils';

type DashboardListResponse = {
  totalCount: number;
  items: DashboardListItemTypeResponse[];
};

export type DashboardItemMetadataParams = {
  orgId: string;
  params: {
    billIds?: string[];
    paymentId?: string;
    pageSize: number;
  };
  options?: {
    cancelToken?: CancelToken;
  };
};

export type DashboardItemMetadataResponse = {
  start: number;
  status: DashboardTabStatusEnum;
  billIds: number[];
};

type GetDashboardListParams = DashboardListParams & {
  options?: {
    cancelToken?: CancelToken;
  };
};

export function getDashboardList({ orgId, filters, options }: GetDashboardListParams): Promise<DashboardListResponse> {
  const url = `/orgs/${orgId}/qbdt/dashboard/list`;

  return fetchRequest(url, filters, options).then(({ items, totalCount }) => ({
    items: items.map((item) => {
      const { billIds, paymentId } = item;

      return {
        ...item,
        id: getDashboardItemId(billIds, paymentId),
      };
    }),
    totalCount,
  }));
}

export function getDashboardItemMetadata({
  orgId,
  params,
  options,
}: DashboardItemMetadataParams): Promise<DashboardItemMetadataResponse> {
  const url = `/orgs/${orgId}/qbdt/dashboard/item-metadata`;
  return fetchRequest(url, params, options);
}
