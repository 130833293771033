import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Box, Flex } from '@melio/billpay-design-system';
import SelectItemCard from 'src/components/common/SelectItemCard';
import { WizardOrLine } from 'src/components/layout/WizardElements';
import batchLocations from 'src/billpay/qbdt/pages/batch-pay/locations';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import { getDeliveryMethodByType } from 'src/records/vendor';
import { getOrgId } from 'src/redux/user/selectors';
import { useLocationState } from 'src/utils/hooks';
import locations from 'src/billpay/qbdt/pages/locations';
import { NotificationDM as CheckFeeNotification } from 'src/billpay/qbdt/hooks/useCheckFee/NotificationDM';
import { CONSTS } from 'src/utils/consts';
import { OptionalDeliveryMethodsType } from 'src/utils/types';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { QBODeliveryMethodsListItem } from 'src/billpay/qbdt/pages/pay/components/QBODeliveryMethodListItem';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';

export function AddNewDeliveryMethodPage() {
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const { billsList, getSingleBatchItem } = useBatchBillsList();
  const [batchItemId] = useLocationState<string>('batchItemId', null);
  const batchActions = useStoreActions(regularBatchPaymentsStore);
  const fetchSelector = vendorsStore.selectors.fetch;
  const batchItem = getSingleBatchItem(batchItemId);
  const vendor = useSelector(fetchSelector.byId(batchItem?.payment.vendorId));
  const ach = getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.ACH, vendor?.deliveryMethods);
  const check = getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.CHECK, vendor?.deliveryMethods);
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(batchItem?.payment?.fundingSourceId));
  const deliveryMethodOptions = [ach, check];
  const showVirtualDeliveryMethodOption = isEmpty(vendor?.deliveryMethods) && fundingSource?.isVerified;

  const getAddDeliveryMethodUrlMap = (orgId: string, vendorId?: string) => ({
    [CONSTS.DELIVERY_TYPE.ACH]: generatePath(locations.deliveryMethod.ach, {
      orgId,
      vendorId,
    }),
    [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(locations.deliveryMethod.check, {
      orgId,
      vendorId,
    }),
    [CONSTS.DELIVERY_TYPE.VIRTUAL]: generatePath(locations.deliveryMethod.virtual, {
      orgId,
      vendorId,
    }),
  });

  const goAddDeliveryMethod = async (type: OptionalDeliveryMethodsType) => {
    const deliveryMethodUrlMap = getAddDeliveryMethodUrlMap(orgId, vendor.id);
    const isDeliveryMethodTypeExist = vendor?.deliveryMethods?.find((dm) => dm.deliveryType === type);
    const url = isDeliveryMethodTypeExist
      ? batchLocations.base
      : deliveryMethodUrlMap[type || CONSTS.DELIVERY_TYPE.ACH];
    const billsToUpdate =
      vendor?.deliveryMethods?.length > 0
        ? [batchItemId]
        : billsList
            .filter((batchListItem) => batchListItem?.vendor?.id === vendor.id)
            .map((batchListItem) => batchListItem.id);

    await batchActions.updatePayments.setBillsToUpdate({ billsToUpdate });
    history.push(generatePath(url, { orgId }), {
      redirectUrl: batchLocations.base,
      exitUrl: batchLocations.base,
      origin: CONSTS.DELIVERY_METHOD_ORIGIN.BATCH_PAY_BILLS,
    });
  };

  const onExit = () => history.goBack();

  return (
    <QBDTStepLayout
      title="bills.pay.deliveryMethods.title"
      titleValues={{ vendor: vendor?.companyName }}
      goExit={onExit}
      onPrev={onExit}
    >
      <CheckFeeNotification selectedFundingSource={fundingSource} />
      <Flex direction="column">
        {deliveryMethodOptions.map((dm) => (
          <QBODeliveryMethodsListItem
            key={dm.deliveryType}
            deliveryMethod={dm}
            companyName={vendor?.companyName}
            isSelected={false}
            onItemClick={() => goAddDeliveryMethod(dm.deliveryType)}
            selectedFundingSource={fundingSource}
          />
        ))}
      </Flex>
      {showVirtualDeliveryMethodOption && (
        <Box mt="1.2rem">
          <WizardOrLine />
          <SelectItemCard
            onClick={() => goAddDeliveryMethod(CONSTS.DELIVERY_TYPE.VIRTUAL)}
            label="bills.pay.deliveryMethods.unilateralLink"
            description="bills.pay.deliveryMethods.unilateralHint"
          />
        </Box>
      )}
    </QBDTStepLayout>
  );
}
