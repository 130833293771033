import { useSelector } from 'react-redux';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import analytics from 'src/services/analytics';
import { getBillIdsFromBatchItem } from '../utils';

export const useSinglePaymentAnalytics = (batchItemId) => {
  const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchItemId));

  const isBulk = batchItem.payment.bills.length > 1;

  const trackRemoveFromListAction = () => {
    analytics.trackAction('regularBatchPayments.removeBillId', {
      billIds: getBillIdsFromBatchItem(batchItem),
      vendorId: batchItem?.payment?.vendorId,
      isBulk,
      payBillFlowUuid: batchItem.payBillFlowUUID,
    });
  };

  return { trackRemoveFromListAction };
};
