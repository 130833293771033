import * as React from 'react';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import Page from '../../../components/layout/Page';
import * as QBLayoutElements from '../../../components/layout/QBOElements';
import { MIFormattedText } from '../../../utils/formatting';

const NotFoundPage = () => {
  const eventPage = 'qbo-not-found-404';
  analytics.page(eventPage, eventPage);
  return (
    <QBLayoutElements.QBOLayoutContainer>
      <Page>
        <Title404>
          <MIFormattedText label="notFound.title" />
        </Title404>
        <Inner404>
          <MIFormattedText label="notFound.text" />
        </Inner404>
      </Page>
    </QBLayoutElements.QBOLayoutContainer>
  );
};

NotFoundPage.defaultProps = {};

export default NotFoundPage;

const Title404 = styled.div`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const Inner404 = styled.div`
  font-size: 1.4rem;
  margin-bottom: 2rem;
  line-height: 2.2rem;
`;
