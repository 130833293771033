import React from 'react';
import styled from 'styled-components';

const DEFAULT_SIZE = 1.6;

type Props = {
  size?: number;
};

const SpinnerLoader = ({ size = DEFAULT_SIZE }: Props) => {
  // strokeWidth value is 0.5 here because of the strange svg behaviour - it becomes 2px wide only with this value
  const strokeWidth = 0.5;

  /**
   * strokeWidth should be doubled here because of this issue with SVG specification:
   * https://stackoverflow.com/questions/57007869/stroke-svg-path-only-inside-or-only-outside
   * We use clipPath below to force stroke be painted inside circle shape(not inside and outside at the same time as it works by default)
   */
  const doubledStrokeWidth = strokeWidth * 2;

  const circleProps = {
    cx: '50',
    cy: '50',
    r: '25',
    fill: 'none',
    strokeMiterlimit: '10',
  };

  return (
    <Loading
      size={size}
      strokeWidth={doubledStrokeWidth}
      className="circular"
      viewBox="25 25 50 50"
      data-testid="quickbooks-loader"
    >
      <defs>
        <clipPath id="my-clip-path">
          <circle id="my-clip-path-circle" {...circleProps} />
        </clipPath>
      </defs>
      <circle className="track" {...circleProps} />
      <circle className="path" {...circleProps} />
    </Loading>
  );
};

export default SpinnerLoader;

const Loading = styled.svg<{
  size: number;
  strokeWidth: number;
}>`
  height: ${(props) => props.size}rem;
  width: ${(props) => props.size}rem;
  animation: spinnerrotate 4s linear infinite;
  transform-origin: center center;

  .track {
    stroke: #d4d7dc;
    stroke-width: ${(props) => props.strokeWidth}rem;
    paint-order: fill;
    clip-path: url(#my-clip-path);
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-width: ${(props) => props.strokeWidth}rem;

    stroke-linecap: round;
    paint-order: fill;
    clip-path: url(#my-clip-path);

    animation: spinnerdash 4s cubic-bezier(0.15, 0, 0, 1) infinite, spinnercolor 4s linear infinite;
  }

  @keyframes spinnerrotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinnerdash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: 0;
    }

    100% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: -126;
    }
  }

  @keyframes spinnercolor {
    0% {
      stroke: rgba(16, 128, 0, 1);
    }

    50% {
      stroke: rgba(44, 160, 28, 1);
    }

    100% {
      stroke: rgba(44, 160, 28, 1);
    }
  }
`;
