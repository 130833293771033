import { IntuitBankAccountResponseType, IntuitBankAccountType } from 'src/utils/types';
import { fetchRequest } from './api';

type ListParams = { orgId: string };

type ListIntuitResponse = {
  accounts?: IntuitBankAccountResponseType[];
};

type ListResponse = {
  items: IntuitBankAccountType[];
};

const list = ({ orgId }: ListParams): Promise<ListResponse> => {
  const url = `/orgs/${orgId}/accounts-payment-options`;
  return fetchRequest(url).then((res: ListIntuitResponse) => ({
    items: (res.accounts || []).map((account) => ({
      id: account.Id,
      name: account.Name,
      fullyQualifiedName: account.FullyQualifiedName,
      accountSubType: account.AccountSubType,
      accountType: account.AccountType,
      classification: account.Classification,
    })),
  }));
};

const intuitBankAccountsApi = {
  list,
};

export default intuitBankAccountsApi;
