import { RecordOf } from 'immutable';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { BillType, PaymentType } from 'src/utils/types';
import { getLatestPayment } from 'src/utils/payments';
import { CONSTS } from 'src/utils/consts';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldEdit } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldEdit';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import Tag from '../../components/Tag';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
  onEdit: () => void;
};

export const ConfirmPaymentDeductionDateInfo = ({ payment, bill, onEdit }: Props) => {
  const now = moment();
  const originPayment = getLatestPayment(bill?.payments);
  const canEdit = payment?.metadata?.failedType !== CONSTS.FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;
  const isUpdated =
    originPayment &&
    payment?.metadata?.failedType &&
    !!moment(payment?.scheduledDate).diff(moment(originPayment?.scheduledDate), 'days');
  const deductionDateLabel = now.isAfter(moment(originPayment?.scheduledDate))
    ? 'payBillPaymentActivity.deductionDate.pastLabel'
    : 'payBillPaymentActivity.deductionDate.futureLabel';

  return (
    <PaymentFieldContainer>
      <PaymentFieldIcon>
        <ScheduledIcon />
      </PaymentFieldIcon>
      <PaymentFieldInfo>
        <PaymentFieldLabel>
          <MIFormattedText label={deductionDateLabel} />
        </PaymentFieldLabel>
        <PaymentFieldContent>
          <MIFormattedDate date={payment.scheduledDate} />
          {isUpdated ? <Tag label="payBillPaymentActivity.updated" testId="scheduled-date-updated-tag" /> : null}
        </PaymentFieldContent>
      </PaymentFieldInfo>
      {canEdit && <PaymentFieldEdit onEdit={onEdit} />}
    </PaymentFieldContainer>
  );
};

const ScheduledIcon = styled.i.attrs({ className: 'icon-scheduled-icon' })``;
