import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardTabStatusEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import { ColumnItem } from '../types';

import { usePaidColumns } from './paid/usePaidColumns';
import { useScheduledColumns } from './scheduled/useScheduledColumns';
import { useUnpaidColumns } from './unpaid/useUnpaidColumns';

type ResponseType = [ColumnItem<DashboardListItemType>[]];

export const useDashboardColumns = (status: DashboardTabStatusEnum): ResponseType => {
  const [unpaidColumns] = useUnpaidColumns();
  const [scheduledColumns] = useScheduledColumns();
  const [paidColumns] = usePaidColumns();

  switch (status) {
    case DashboardTabStatusEnum.Scheduled:
      return [scheduledColumns];
    case DashboardTabStatusEnum.Paid:
      return [paidColumns];
    case DashboardTabStatusEnum.Unpaid:
    default:
      return [unpaidColumns];
  }
};
