import React from 'react';
import styled, { css } from 'styled-components';
import isNil from 'lodash/isNil';
import { MIFormattedText } from 'src/utils/formatting';
import {
  PaymentIcon,
  PaymentInfoText,
  PaymentMethodText,
  ErrorMessage,
} from 'src/pages/bill/components/PaymentActivity';

type SelectedMethodProps = {
  icon?: string;
  imageSrc?: string;
  title?: string;
  errorLabelValues?: any;
  errorLabel?: string;
  label: string;
  labelValues?: Record<string, any>;
  showDropdownIcon?: boolean;
  isOpen?: boolean;
};

function SelectedMethod({
  icon,
  imageSrc,
  title,
  label,
  labelValues,
  isOpen,
  showDropdownIcon,
  errorLabel,
  errorLabelValues,
}: SelectedMethodProps) {
  return (
    <>
      <SelectedMethodInfoContainer>
        <PaymentIcon>
          {icon && <i className={icon} />}
          {imageSrc && <img src={imageSrc} alt="selected-method-icon" />}
        </PaymentIcon>
        <SelectMethodInfoTextContainer>
          {title && (
            <PaymentMethodText>
              <MIFormattedText label={title} />
            </PaymentMethodText>
          )}
          <PaymentInfoText balance={!isNil(labelValues?.balance)}>
            {label && <MIFormattedText label={label} values={labelValues} />}
          </PaymentInfoText>
          {errorLabel && (
            <ErrorMessage>
              <i className="icon-warning-icon" />
              <ErrorMessageText>
                <MIFormattedText label={errorLabel} values={errorLabelValues} />
              </ErrorMessageText>
            </ErrorMessage>
          )}
        </SelectMethodInfoTextContainer>
      </SelectedMethodInfoContainer>
      {showDropdownIcon && <DropdownIcon isDropdownOpen={isOpen} />}
    </>
  );
}

export default SelectedMethod;

const SelectedMethodInfoContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 3.8rem;
`;

const SelectMethodInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ErrorMessageText = styled.div`
  padding-left: 0.5rem;
`;
const DropdownIcon = styled.i.attrs({ className: 'icon-next-icon-2' })<{
  isDropdownOpen?: boolean;
  label?: string;
}>`
  font-size: 1.6rem;
  color: rgba(107, 108, 114, 1);
  font-weight: ${(props) => props.theme.text.weight.bold};
  transform: rotate(90deg);
  position: relative;
  right: ${({ isDropdownOpen, label }) =>
    /* eslint-disable no-nested-ternary */
    isDropdownOpen ? '-0.4rem;' : label ? '2rem' : '1.9rem'};
  ${(props) =>
    props.isDropdownOpen &&
    css`
      transform: rotate(-90deg);
    `}
`;
