import React from 'react';
import { Box, HStack, VStack } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  message: string;
};

const BenefitOption = ({ icon: Icon, title, message }: Props) => (
  <HStack
    w="full"
    spacing="ds.xl"
    p="ds.lg"
    bgColor="ds.gray.800"
    borderRadius="ds.lg"
    color="ds.gray.100"
    boxSizing="border-box"
  >
    <Box w="6rem" h="6rem">
      <Icon />
    </Box>
    <VStack spacing="ds.md">
      <Box w="full" textStyle="ds.body1Semi">
        <MIFormattedText label={title} />
      </Box>
      <Box w="full" textStyle="ds.body2">
        <MIFormattedText label={message} />
      </Box>
    </VStack>
  </HStack>
);

export { BenefitOption };
