import { Text, Flex, Box } from '@melio/billpay-design-system';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import React from 'react';
import { PaymentType } from 'src/utils/types';
import { useInvoiceNumber } from 'src/pages/vendor/hooks/useInvoiceNumber';

type PaymentInfoProps = {
  companyName?: string;
  payment: PaymentType;
};

export const PaymentInfo = ({ payment, companyName }: PaymentInfoProps) => {
  const { invoiceNumber } = useInvoiceNumber(payment);

  return (
    <Flex direction="column" gap="1rem" align="center" justify="center">
      <Text textStyle="ds.body2" align="center" m="0" color="ds.gray.100">
        <MIFormattedText
          label="vendor.unilateral.payor"
          values={{
            payorName: companyName || '',
          }}
        />
      </Text>
      <Text textStyle="ds.h3" align="center" m="0" color="ds.gray.100">
        <MIFormattedCurrency value={payment?.amount || null} />
      </Text>
      {invoiceNumber && (
        <Box>
          <Text textStyle="ds.body3" align="center" m="0" fontWeight="ds.medium" color="ds.gray.300">
            <MIFormattedText label="vendor.unilateral.invoiceNumber" />
          </Text>
          <Text textStyle="ds.body2" align="center" color="ds.green.200" m="0" fontWeight="ds.medium">
            {invoiceNumber}
          </Text>
        </Box>
      )}
      {payment?.note && (
        <Box>
          <Text textStyle="ds.body3" align="center" m="0" color="ds.gray.300">
            <MIFormattedText label="vendor.unilateral.invoiceNote" />
          </Text>
          <Text align="center" textStyle="ds.body2" color="ds.gray.100" fontWeight="ds.medium" m="0">
            {payment.note}
          </Text>
        </Box>
      )}
    </Flex>
  );
};
