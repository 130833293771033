import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/theme/AppDevices';

type Props = {
  relativeStep: number;
};

const QBOProgressBar = ({ relativeStep }: Props) => {
  const width = Math.round(relativeStep * 100) / 100;
  return (
    <TotalBar>
      <Progress style={{ width: `${width * 100}%` }} />
    </TotalBar>
  );
};

export default QBOProgressBar;

const TotalBar = styled.div`
  background-color: rgba(216, 216, 216, 1);
  height: 0.2rem;
  width: 100%;
  margin-top: -0.2rem;
  @media ${devices.mobile}, ${devices.phablet} {
    height: 0.4rem;
    margin-top: -0.4rem;
  }
`;

const Progress = styled.div`
  background-color: ${(props) => props.theme.colors.progress.done};
  height: 100%;
  max-width: 100%;
`;
