import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import AreaLoader from 'src/components/common/AreaLoader';
import OutsideLayout from 'src/components/layout/OutsideLayout';
import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { useEditCheckAddressStatus } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';

type Props = {
  token: string;
};

const SwitchedToACHAlreadyPage = ({ token }: Props) => {
  const { payment, isPaymentLoading, organization } = useEditCheckAddressStatus({ token });
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout>
      <BillPaymentBlock>
        <BillInfoContainer>
          <InfoIcon />
          <Title data-testid="success-title">
            <MIFormattedText label="vendors.editCheckAddress.switchedToACHAlready.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.switchedToACHAlready.subtitle"
              values={{
                companyName: organization?.companyName,
                totalAmount: <MIFormattedCurrency value={payment?.amount} />,
                digits: deliveryMethod?.bankAccount?.accountNumber?.slice(-4),
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
      </BillPaymentBlock>
    </OutsideLayout>
  );
};

export default SwitchedToACHAlreadyPage;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const InfoIcon = styled.i.attrs({ className: 'icon-intuit-info-icon' })`
  font-size: 4rem;
  color: ${(props) => props.theme.text.color.blue};
  margin-bottom: 2rem;
`;
