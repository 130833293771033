import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { ErrorPage } from 'src/billpay/qbdt/pages/ErrorPage';
import { AddNewDeliveryMethodPage } from 'src/billpay/qbdt/pages/batch-pay/components/deliveryMethod/AddNewDeliveryMethodPage';
import { PayBatchBillPage } from './PayBatchBillPage';
import PayBatchSuccessPage from './PayBatchSuccessPage';
import { PayBatchCompleteLegalInfoPageContainer } from './PayBatchCompleteLegalInfoPageContainer';
import { PayBatchCompleteComplianceInfoPageContainer } from './PayBatchCompleteComplianceInfoPageContainer';

import { QBDTBillsAlreadyScheduledPage } from './QBDTBillsAlreadyScheduledPage';
import { AddFundingSourcePage } from './add-funding-source/AddFundingSourcePage';
import { AddFirstFundingSourcePage } from './add-funding-source/AddFirstFundingSourcePage';

export function QBDTBatchPayWizardRouter() {
  const match = useRouteMatch();

  return (
    <Switch>
      <SmartRoute path={`${match.path}`} exact>
        <PayBatchBillPage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/bills-already-scheduled`} exact>
        <QBDTBillsAlreadyScheduledPage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/success`} exact>
        <PayBatchSuccessPage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/complete-legal-info`} exact>
        <PayBatchCompleteLegalInfoPageContainer />
      </SmartRoute>
      <SmartRoute path={`${match.path}/complete-compliance-info`} exact>
        <PayBatchCompleteComplianceInfoPageContainer />
      </SmartRoute>
      <SmartRoute path={`${match.path}/delivery-method`} exact>
        <AddNewDeliveryMethodPage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/funding-source`} exact>
        <AddFundingSourcePage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/add-first-funding-source`} exact>
        <AddFirstFundingSourcePage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/error`} exact>
        <ErrorPage title="entry.sync.error" subtitle="entry.sync.errorSubtitle" />
      </SmartRoute>
    </Switch>
  );
}
