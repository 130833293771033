import { featureFlags } from '@melio/shared-web';
import config from 'src/config';

type TrackVariant = (variantName: string, value: unknown) => unknown;

const initialize = (trackVariant: TrackVariant) => {
  if (config.services.featureFlagProvider.enabled) {
    featureFlags.defaultClient.initialize({
      clientId: config.services.featureFlagProvider.clientId,
      trackVariant,
    });
  }
};

const identify = async (user: featureFlags.User, organization: featureFlags.Organization) => {
  if (config.services.featureFlagProvider.enabled) {
    await featureFlags.defaultClient.identify(
      { id: user.id, email: user.email },
      { id: organization.id, companyType: organization.companyType }
    );
  }
};

export { initialize, identify };
