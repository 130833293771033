import React from 'react';
import { CARD_TYPES, DIALOG_TYPE, DIALOG_VARIANTS } from 'src/utils/consts';
import Dialog from 'src/components/common/QBOMIDialog';
import { CardAccountType } from 'src/utils/types';

type Props = {
  onClose: () => void;
  cardAccount: CardAccountType | null;
};

export const AddCardSuccessModal = ({ onClose, cardAccount }: Props) => {
  const getCardLabel = (type) =>
    type === CARD_TYPES.CREDIT
      ? 'onboarding.fundingSources.card.successNoteCredit'
      : 'onboarding.fundingSources.card.successNoteDebit';
  const successSubtitle = 'common.rawValue';
  const successSubtitleValues = {
    value: cardAccount
      ? `${cardAccount.network}
           (${cardAccount.card4digits})\n${cardAccount.firstName} ${cardAccount.lastName}`
      : '',
  };
  const successTitle = cardAccount ? getCardLabel(cardAccount.cardType.toLowerCase()) : '';

  return (
    <Dialog
      type={DIALOG_TYPE.ALERT}
      variant={DIALOG_VARIANTS.SUCCESS}
      title={successTitle}
      subtitleValues={successSubtitleValues}
      subtitle={successSubtitle}
      onCancelAction={onClose}
    />
  );
};
