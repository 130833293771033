import React from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/modules/bills/bills-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { useViewPaymentMenuAction } from '../actions-common/useViewPaymentMenuAction';
import { useCancelPaymentMenuAction } from '../actions-common/useCancelPaymentMenuAction/useCancelPaymentMenuAction';
import { useEditPaymentMenuAction } from '../actions-common/useEditPaymentMenuAction';
import { useVerifyMicroDepositsMenuAction } from './useVerifyMicroDepositsMenuAction';
import { DashboardActionsCell } from '../DashboardActionsCell/DashboardActionsCell';
import { DashboardMenuAction } from '../types';
import { useActionCellEventProperties } from '../hooks/useActionCellEventProperties';

type Props = {
  item: DashboardListItemType;
};

export const DashboardScheduledActionCell = ({ item }: Props) => {
  const { fundingSourceId, vendorId, paymentId, metadata, id } = item;
  // TODO: dashboard - fix in terms of https://linear.app/meliopayments/issue/QBDT-506/web-adapt-table-for-bulk-payments
  const billId = item.billIds[0];
  const bill = useSelector(billsStore.selectors.byId(billId));
  const vendor = useSelector(vendorsStore.selectors.byId(vendorId));

  const { cancelPaymentDialog, cancelPaymentMenuAction } = useCancelPaymentMenuAction({
    billIds: item.billIds,
    paymentId,
    companyName: vendor?.companyName,
    totalAmount: bill?.totalAmount,
  });

  const { verifyMicroDepositsMenuAction } = useVerifyMicroDepositsMenuAction(fundingSourceId);

  const { viewPaymentMenuAction } = useViewPaymentMenuAction({
    billId,
    paymentId,
  });

  const { editPaymentMenuAction } = useEditPaymentMenuAction({ billId, paymentId });

  const actions: DashboardMenuAction[] = [
    ...(verifyMicroDepositsMenuAction ? [verifyMicroDepositsMenuAction] : []),
    viewPaymentMenuAction,
    cancelPaymentMenuAction,
    editPaymentMenuAction,
  ];

  const { eventProperties } = useActionCellEventProperties({
    billId,
    paymentId,
    status: metadata.statusInDashboard,
  });

  return (
    <>
      {cancelPaymentDialog}
      <DashboardActionsCell eventProperties={eventProperties} actions={actions} itemId={id} />
    </>
  );
};
