import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { isEligibleToReceiveVirtualCard } from 'src/pages/vendor/virtual-delivery-method/utils';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { BatchItemType } from 'src/modules/regular-batch-payments/types/store-types';
import { DeliveryMethodType } from 'src/utils/types';
import useGetValidFundingSources from '../table/hooks/useGetValidFundingSources';
import { isFundingSourceAndDeliveryMethodIncompatible } from '../utils';

export const useSetFundingSourceForBillInBatch = () => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const [validFundingSources] = useGetValidFundingSources();
  const batchItems: BatchItemType[] = useSelector(regularBatchPaymentsStore.selectors.allBills);
  const orgId = useSelector(getOrgId);

  const setFundingSourceForBillInBatch = async ({ batchItemId, newFundingSourceId }) => {
    const batchItem = batchItems.find((item) => item.id === batchItemId);
    const selectedFundingSource = validFundingSources.find((fundingSource) => fundingSource.id === newFundingSourceId);
    if (!batchItem || !selectedFundingSource) {
      return;
    }

    const { deliveryMethod, deliveryMethodId, amount, scheduledDate } = batchItem.payment;

    const areFundingSourceAndDeliveryMethodIncompatible = isFundingSourceAndDeliveryMethodIncompatible({
      deliveryMethod,
      fundingSource: selectedFundingSource,
    });
    const deliveryMethods = batchItem.payment.vendor.deliveryMethods || [];
    const virtualCardDeliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD);
    const fallbackDeliveryMethod = getFallbackDeliveryMethod(deliveryMethods);
    const canUseVirtualCardDeliveryMethod =
      virtualCardDeliveryMethod &&
      isEligibleToReceiveVirtualCard(selectedFundingSource) &&
      deliveryMethodId !== virtualCardDeliveryMethod.id;

    if (!deliveryMethodId && !canUseVirtualCardDeliveryMethod) {
      await actions.updatePaymentsDeductionDates({
        batchItemId,
        orgId,
        scheduledDate: new Date(),
        fundingSourceId: newFundingSourceId,
      });

      return;
    }

    if (areFundingSourceAndDeliveryMethodIncompatible && !fallbackDeliveryMethod) {
      await actions.updatePayments.setPaymentsMethod({
        batchItemId,
        fundingSourceId: newFundingSourceId,
        deliveryMethod: null,
        deliveryMethodType: null,
        deliveryPreference: null,
        deliveryOptions: null,
      });

      return;
    }

    const canReplaceIncompatibleDeliveryMethod =
      areFundingSourceAndDeliveryMethodIncompatible && fallbackDeliveryMethod;
    let deliveryMethodForUpdate: any = deliveryMethod;
    if (canReplaceIncompatibleDeliveryMethod) {
      deliveryMethodForUpdate = fallbackDeliveryMethod;
    } else if (canUseVirtualCardDeliveryMethod) {
      deliveryMethodForUpdate = virtualCardDeliveryMethod;
    }

    const optionsForUpdate = {
      batchItemId,
      orgId,
      fundingSourceId: newFundingSourceId,
      amount,
      deliveryMethod: deliveryMethodForUpdate,
      deliveryMethodId: deliveryMethodForUpdate?.id,
      payBillFlowUUID: batchItem.payBillFlowUUID,
      createOrigin: batchItem.payment.createOrigin,
      // we want to reset delivery speed cell on funding source change.
      deliveryPreference: null,
    };
    const { dueDate, isScheduledDateSelectedByUser } = batchItem;

    if (isScheduledDateSelectedByUser) {
      await actions.updatePaymentsDeliveryOptions({
        ...optionsForUpdate,
        deductionDate: scheduledDate,
      });

      return;
    }

    await actions.updatePaymentsDeductionDatesByDueDate({
      ...optionsForUpdate,
      dueDate,
      scheduledDate: null,
    });
  };

  return { setFundingSourceForBillInBatch };
};

const getFallbackDeliveryMethod = (deliveryMethods: DeliveryMethodType[]): DeliveryMethodType | undefined => {
  const achDeliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === DELIVERY_TYPE.ACH);
  const checkDeliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === DELIVERY_TYPE.CHECK);

  return achDeliveryMethod || checkDeliveryMethod;
};
