import React, { useEffect } from 'react';
import { Box } from '@melio/billpay-design-system';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import ErrorIllustration from 'src/images/qbo/failed-to-load-icon.svg';
import * as WizardElements from 'src/billpay/qbdt/components/layout/WizardElement';
import analytics from 'src/services/analytics';
import { useSiteContext } from 'src/hoc/withSiteContext';
import {
  BRIDGE_INTSTANCE_CAN_NOT_BE_LOADED_ERROR,
  BROWSER_INTSTANCE_CAN_NOT_BE_LOADED_ERROR,
} from 'src/billpay/qbdt/services/qbdt/adapter/bridge';

export type Props = {
  errors?: string;
};

export function EntryErrorPage({ errors }: Props) {
  const site = useSiteContext();

  useEffect(() => {
    analytics.trackAction('error-page', {
      errorMessage: errors,
    });
  }, [errors]);

  const isLoadWindowObjectError = [
    BRIDGE_INTSTANCE_CAN_NOT_BE_LOADED_ERROR,
    BROWSER_INTSTANCE_CAN_NOT_BE_LOADED_ERROR,
  ].includes(errors as string);

  const title = isLoadWindowObjectError ? 'entry.loadWindowObjectsError.title' : 'entry.error.title';
  const subtitle = isLoadWindowObjectError ? 'entry.loadWindowObjectsError.subtitle' : 'entry.error.subtitle';

  return (
    <QBDTSuccessLayout
      illustration={ErrorIllustration}
      title={title}
      text={subtitle}
      textValues={{
        email: site.config.support.email,
      }}
      hideHeader
    >
      {errors && !isLoadWindowObjectError ? (
        <WizardElements.WizardNotesContainer label="entry.error.errorReport" data-testid="error-message" fullWidth>
          <Box as="span" sx={{ wordWrap: 'break-word' }}>
            {errors}
          </Box>
        </WizardElements.WizardNotesContainer>
      ) : null}
    </QBDTSuccessLayout>
  );
}
