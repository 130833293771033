import React from 'react';
import { isAfter } from 'date-fns';
import styled from 'styled-components';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { DELIVERY_TYPE, TAG_VARIANT } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryDateFormat } from 'src/utils/dates';
import MITag from 'src/components/common/MITag';
import { DeliveryMethodType } from 'src/utils/types';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';
import { FeatureFlags } from 'src/utils/feature-flags';
import { CheckDelaysIcon, CheckDelaysIconVariant } from 'src/components/common/CheckDelaysIcon';
import { isFastDeliveryType } from 'src/utils/delivery-methods';
import useGetValidFundingSources from '../hooks/useGetValidFundingSources';

type Props = {
  deliveryEta?: Date;
  maxDeliveryEta?: Date;
  dueDate: Date;
  deliveryMethod?: DeliveryMethodType;
  deliveryPreference?: string;
  fundingSourceId?: number;
};

export const DeliveryDateCell = ({
  deliveryEta,
  maxDeliveryEta,
  dueDate,
  fundingSourceId,
  deliveryMethod,
  deliveryPreference,
}: Props) => {
  const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);
  const [validFundingSources] = useGetValidFundingSources();
  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();

  const fundingSource = fundingSourceId ? validFundingSources.find(({ id }) => id === fundingSourceId) : null;

  const isLate =
    deliveryEta &&
    dueDate &&
    isAfter(new Date(deliveryEta).setHours(0, 0, 0, 0), new Date(dueDate).setHours(0, 0, 0, 0));
  const isEmptyState = !deliveryEta || !fundingSource;
  const isFast = isFastDeliveryType(deliveryPreference);
  const isDeliveryMethodCheck = deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK;
  const showChecksDelaysWarning = isChecksDelaysEnabled && !isFast && isDeliveryMethodCheck;
  const shouldShowLateBadge = isLate && !showChecksDelaysWarning;

  if (isEmptyState) {
    return (
      <Tooltip
        placement="top"
        isDisabled={!isTruncated}
        label={<MIFormattedText label="regularBatchPayments.cells.deliveryDate.notAvailable" />}
      >
        <ContentWrapper>
          <Box color="ds.gray.200" ref={ref} isTruncated>
            <MIFormattedText label="regularBatchPayments.cells.deliveryDate.notAvailable" />
          </Box>
        </ContentWrapper>
      </Tooltip>
    );
  }

  return (
    <ContentWrapper>
      <Tooltip
        placement="top"
        isDisabled={!isTruncated}
        label={<DeliveryDateFormat date={deliveryEta} maxDate={maxDeliveryEta} />}
      >
        <Box ref={ref} isTruncated>
          <DeliveryDateFormat date={deliveryEta} maxDate={maxDeliveryEta} />
        </Box>
      </Tooltip>
      {shouldShowLateBadge && (
        <LateTag label="regularBatchPayments.cells.deliveryDate.late" variant={TAG_VARIANT.WARNING} isSecondary />
      )}
      {showChecksDelaysWarning && (
        <CheckDelaysIcon
          tooltipLabel="checkDelaysWarning.tooltipLabels.specificCheck"
          variant={CheckDelaysIconVariant.INFO}
          sx={{
            ml: 'ds.sm',
          }}
        />
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = ({ children }) => (
  <Flex align="center" justify="space-between" data-testid="regularBatchPayments.cells.deliveryDate">
    {children}
  </Flex>
);

const LateTag = styled(MITag)`
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
  line-height: 1.1rem;
  margin-left: 0.4rem;
`;
