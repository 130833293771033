import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { useInitFetch } from 'src/pages/vendor/shift-vendor-to-ach/hoc/useInitFetchHook';
import { InvalidAddDeliveryMethodPage } from 'src/pages/vendor/shift-vendor-to-ach/InvalidAddDeliveryMethodPage';
import SwitchCompanyPage from 'src/pages/vendor/shift-vendor-to-ach/SwitchCompanyPage';
import LinkDeliveryMethodPage from 'src/pages/vendor/shift-vendor-to-ach/LinkDeliveryMethodPage';
import ShiftedCurrentPaymentPage from 'src/pages/vendor/shift-vendor-to-ach/ShiftedCurrentPaymentPage';
import QBOAddDeliveryMethodPage from 'src/pages/vendor/shift-vendor-to-ach/QBOAddDeliveryMethodPage';
import QBOSuccessAddDeliveryMethodPage from 'src/pages/vendor/shift-vendor-to-ach/QBOAddDeliveryMethodSuccessPage';
import locations from '../locations';

export const ShiftVendorToAchRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const id = match.params?.id || '';
  useInitFetch(id, token);

  return (
    <Switch>
      <SmartRoute path={locations.shiftVendorToAch.index} exact>
        <QBDTLoader />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.addMethod} exact>
        <QBOAddDeliveryMethodPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.linkMethod} exact>
        <LinkDeliveryMethodPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.switchCompany} exact>
        <SwitchCompanyPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.shiftedCurrentPayment} exact>
        <ShiftedCurrentPaymentPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.success} exact>
        <QBOSuccessAddDeliveryMethodPage />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.invalidToken} exact>
        <InvalidAddDeliveryMethodPage token={token} />
      </SmartRoute>
    </Switch>
  );
};
