import React from 'react';
import { generatePath, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { useUnilateralPaymentState } from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { CONSTS } from 'src/utils/consts';
import locations from 'src/pages/vendor/locations';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import {
  AddACHDeliveryMethodPage,
  AddPaperCheckDeliveryMethodPage,
  AddVirtualCardDeliveryMethodPage,
  AddVirtualCardDeliveryMethodSuccessPage,
  QBOAddDeliveryMethodSuccessPage,
  VendorSelectDeliveryMethodPage,
  VirtualCardLearnMorePage,
  InvalidAddDeliveryMethodPage,
} from './pages';

export const VendorUnilateralRouter = () => {
  const match = useRouteMatch<{ token?: string }>();
  const token = match.params?.token || '';
  const [state, actions, newDeliveryMethod, currentDeliveryMethod] = useUnilateralPaymentState({ token });

  const getInitialUrl = () => {
    if (currentDeliveryMethod) {
      const { deliveryType } = currentDeliveryMethod;
      if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
        return locations.unilateral.addMethod;
      }

      if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
        return locations.unilateral.addVirtualCardMethodSuccess;
      }

      return locations.unilateral.success;
    }

    return locations.unilateral.index;
  };

  if (!state || !state.payment) {
    return <QBDTLoader />;
  }

  return (
    <Switch>
      <SmartRoute path={locations.unilateral.index} exact>
        <Redirect to={generatePath(getInitialUrl(), { token })} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.addMethod} exact>
        <VendorSelectDeliveryMethodPage state={state} token={token} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.addBankAccountMethod} exact>
        <AddACHDeliveryMethodPage state={state} actions={actions} deliveryMethod={newDeliveryMethod} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.addPaperCheckMethod} exact>
        <AddPaperCheckDeliveryMethodPage state={state} actions={actions} deliveryMethod={newDeliveryMethod} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.addVirtualCardMethod} exact>
        <AddVirtualCardDeliveryMethodPage state={state} actions={actions} token={token} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.virtualCardLearnMore} exact>
        <VirtualCardLearnMorePage token={token} payment={state?.payment} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.invalidToken} exact>
        <InvalidAddDeliveryMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.addVirtualCardMethodSuccess} exact>
        <AddVirtualCardDeliveryMethodSuccessPage state={state} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.success} exact>
        <QBOAddDeliveryMethodSuccessPage token={token} />
      </SmartRoute>
    </Switch>
  );
};
