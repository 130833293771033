import isEmpty from 'lodash/isEmpty';
import { AccountLimitations, CompanyField, MissingField } from './types';
import { includesAny } from './company';

const PRE_GRACE_PERIOD = 'pre-grace-period';
const IN_GRACE_PERIOD = 'in-grace-period';
const POST_GRACE_PERIOD = 'post-grace-period';

export const isComplianceInfoDone = (limitations: AccountLimitations): boolean => {
  const requiredFields = [CompanyField.BusinessType, CompanyField.Industry];
  const fieldsFromRiskApi = limitations?.entitiesInformation?.company?.missingFields?.map((field) => field.name);
  if (isEmpty(fieldsFromRiskApi)) {
    return true;
  }

  return !includesAny(fieldsFromRiskApi, requiredFields);
};

export const calculateGracePeriodStatus = (isGracePeriodStarted?: boolean, isGracePeriodEnded?: boolean) => {
  if (!isGracePeriodStarted) {
    return PRE_GRACE_PERIOD;
  }

  if (!isGracePeriodEnded) {
    return IN_GRACE_PERIOD;
  }

  return POST_GRACE_PERIOD;
};

export const getMissingFields = (limitations: AccountLimitations): Array<MissingField> => {
  const { user, company } = limitations?.entitiesInformation || {};
  const companyFields = company?.missingFields || [];
  const userFields = user?.missingFields || [];

  return [
    ...userFields.map((field) => ({ ...field, entity: 'user' })),
    ...companyFields.map((field) => ({ ...field, entity: 'company' })),
  ];
};

const FieldEntityToFieldTypeMapper: Record<MissingField['entity'], 'organization' | 'user'> = {
  company: 'organization',
  user: 'user',
};

const convertToAnalyticsField = (field: MissingField) => ({
  FieldName: field.name,
  FieldType: FieldEntityToFieldTypeMapper[field.entity],
  FieldMandatory: !!field.isRequired,
});

const getRequiredFields = (missingFields: MissingField[]) => {
  const numberOfRequiredOrgFields = missingFields.filter((item) => item.entity === 'company').length;
  const numberOfRequiredUserFields = missingFields.filter((item) => item.entity === 'user').length;
  const requiredFields = missingFields.map(convertToAnalyticsField);

  return {
    numberOfRequiredOrgFields,
    numberOfRequiredUserFields,
    requiredFields,
  };
};

export const calculateComplianceAnalyticsFields = (limitations: AccountLimitations) => {
  const { isGracePeriodStarted, isGracePeriodEnded, numberOfPaymentsLeftInGracePeriod } = limitations || {};
  const missingFields = limitations ? getMissingFields(limitations) : [];
  const { requiredFields, numberOfRequiredUserFields, numberOfRequiredOrgFields } = getRequiredFields(missingFields);
  return {
    GracePeriodStatus: calculateGracePeriodStatus(isGracePeriodStarted, isGracePeriodEnded),
    GracePeriodPaymentsRemain: numberOfPaymentsLeftInGracePeriod,
    NumRequiredUserFields: numberOfRequiredUserFields,
    NumRequiredOrgFields: numberOfRequiredOrgFields,
    RequiredFields: requiredFields,
  };
};

export const isAfterGracePeriod = (limitations: AccountLimitations): boolean =>
  !!(limitations?.numberOfPaymentsLeftInGracePeriod === 0 || limitations?.isGracePeriodEnded);
