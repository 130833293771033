import React from 'react';
import { RecordOf } from 'immutable';
import moment from 'moment';
import { DELIVERY_TYPE, PAYMENT_APPROVAL_STATUS, TAG_VARIANT } from 'src/utils/consts';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import { chakra } from 'src/theme/ds';
import { Box } from '@melio/billpay-design-system';
import MITag from 'src/components/common/MITag';
import { MIFormattedText } from 'src/utils/formatting';
import { isSameDay, DeliveryDate } from 'src/utils/dates';
import { isFastDeliveryType } from 'src/utils/delivery-methods';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { getLatestPayment } from 'src/utils/payments';
import { CheckDelaysIcon, CheckDelaysIconVariant } from 'src/components/common/CheckDelaysIcon';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldEdit } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldEdit';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import DeliveryDateInfoHint from './DeliveryDateInfoHint';
import Tag from '../../components/Tag';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: RecordOf<AccountType>;
  onEdit?: (() => void) | null;
};

export const ConfirmPaymentDeliveryDateInfo = ({ payment, bill, deliveryMethod, onEdit, fundingSource }: Props) => {
  const [isCheckDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const isSameDayDelivery = isSameDay(payment.deliveryEta);
  const isFast = isFastDeliveryType(payment.deliveryPreference);
  const isDeliveryMethodCheck = deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK;
  const originPayment = getLatestPayment(bill?.payments);
  const isUpdated =
    originPayment &&
    payment?.metadata?.failedType &&
    moment(payment?.deliveryEta).diff(moment(originPayment?.deliveryEta), 'days') !== 0;

  let label = 'payBillPaymentActivity.deliveryDate.deliveryEtaLabel';
  if (isMicrodeposit || isUnilateral || isApprovalRequired) {
    label = 'payBillPaymentActivity.deliveryDate.estimatedDeliveryEtaLabel';
  }

  const shouldShowCheckDelaysWarning = isCheckDelaysEnabled && !isFast && isDeliveryMethodCheck;

  return (
    <PaymentFieldContainer>
      <PaymentFieldIcon>
        <i className="icon-eta-cal" />
      </PaymentFieldIcon>

      <PaymentFieldInfo>
        <PaymentFieldLabel>
          <MIFormattedText label={label} />
        </PaymentFieldLabel>

        <PaymentFieldContent>
          <Box>
            {isSameDayDelivery && <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />}
            <DeliveryDate date={payment.deliveryEta} maxDate={payment.maxDeliveryEta} />
            {isFast && <FastTag label="bills.pay.date.deliveryOptions.price.fastTag" variant={TAG_VARIANT.BRAND} />}
            {shouldShowCheckDelaysWarning && (
              <CheckDelaysIcon
                variant={CheckDelaysIconVariant.WARNING}
                sx={{
                  position: 'relative',
                  top: '3px',
                  ml: 'ds.sm',
                }}
              />
            )}
            {isUpdated && <Tag label="payBillPaymentActivity.updated" testId="delivery-date-updated-tag" />}
          </Box>
          {shouldShowCheckDelaysWarning && (
            <CheckDelaysWarningText>
              <MIFormattedText label="checkDelaysWarning.tooltipLabels.specificCheck" />
            </CheckDelaysWarningText>
          )}
          <DeliveryDateInfoHint payment={payment} deliveryMethod={deliveryMethod} fundingSource={fundingSource} />
        </PaymentFieldContent>
      </PaymentFieldInfo>

      <PaymentFieldEdit onEdit={onEdit} />
    </PaymentFieldContainer>
  );
};

const FastTag = chakra(MITag, {
  baseStyle: {
    ml: '0.6rem',
    verticalAlign: 'middle',
    fontSize: '0.8rem',
    padding: '0 0.4rem',
    position: 'relative',
    top: '-0.2rem',
  },
});

const CheckDelaysWarningText = chakra('span', {
  baseStyle: {
    textStyle: 'ds.body3',
    color: 'rgba(153, 153, 156, 1)',
  },
});
