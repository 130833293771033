import React, { ReactNode } from 'react';
import { HStack } from '@melio/billpay-design-system';

type Props = {
  children: ReactNode;
};

export const PaymentFieldContainer = ({ children }: Props) => (
  <HStack spacing="3" flex="1" width="full" alignItems="start">
    {children}
  </HStack>
);
