import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { useBreak } from 'src/hoc';

type Props = {
  children: React.ReactNode;
};

export const DeliveryMethodCardContentWrapper = ({ children }: Props) => {
  const { isDesktop } = useBreak();
  return (
    <Flex
      direction="column"
      align="start"
      mr={isDesktop ? 'ds.sm' : '0'}
      ml="ds.xl"
      w={isDesktop ? '31.6rem' : '20.3rem'}
      gap="ds.sm"
    >
      {children}
    </Flex>
  );
};
