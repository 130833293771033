import React from 'react';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import trim from 'lodash/trim';
import styled from 'styled-components';
import { FirstCustomOptionProps, DropDownOption } from 'src/components/common/DropDown/MIDropDown';
import { MIFormattedText } from 'src/utils/formatting';

type AddOptionComponentType = {
  label: string;
} & FirstCustomOptionProps;

export const AddOptionComponent = ({ label, createOption, text, options }: AddOptionComponentType) => {
  const trimmedText = trim(text);
  if (isEmpty(trimmedText)) {
    return null;
  }

  if (find(options, (option) => option.label === trimmedText)) {
    return null;
  }

  const onClick = () => {
    createOption(trimmedText);
  };

  return (
    <DropDownOption onClick={onClick}>
      <PlusIcon className="icon-plus-icon" />
      {label ? <MIFormattedText values={{ value: trimmedText }} label={label} /> : { trimmed: trimmedText }}
    </DropDownOption>
  );
};

const PlusIcon = styled.i`
  vertical-align: text-bottom;
  font-size: 1.4em;
  margin-right: 1.2rem;
`;
