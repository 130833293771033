import React, { useEffect, useCallback } from 'react';
import qbLogo from 'src/images/accounting-software/quickbooks-logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useModal } from 'src/helpers/react/useModal';
import { useApi } from 'src/hoc/useApi';
import { compose } from 'recompose';
import styled from 'styled-components';
import { CONSTS } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import { MIFormattedText, MIFormattedCurrency, MIFormattedDate } from 'src/utils/formatting';
import CollectVendorEmailModalMessage from 'src/billpay/qbdt/pages/bill/pay/components/CollectVendorEmailModalMessage';
import AreaLoader from 'src/components/common/AreaLoader';
import { PaymentType, BillType } from 'src/utils/types';
import vendorApi from 'src/services/api/vendors';
import { endPayBillFlowAction } from 'src/redux/payBillWizard/actions';
import { getBill, getPayment, getIsLoading } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { WizardIllustration } from 'src/components/layout/WizardElements';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { withPayBillData } from './hoc/withPayBillData';
import QBDTSuccessLayout from '../../components/layout/QBDTSuccessLayout';

const eventPage = 'pay-bill';

const PayBillSuccessPageContainer = () => {
  const site = useSiteContext();
  const { Dialog } = site.components;
  const dispatch = useDispatch();
  const bill = useSelector<BillType>(getBill);
  const payment = useSelector<PaymentType>(getPayment);
  const isLoading = useSelector(getIsLoading);
  const orgId = useSelector(getOrgId);
  const vendorName = bill.vendor.companyName;
  const deliveryMethod = bill.getDeliveryMethodById(payment.deliveryMethodId);
  const deliveryType = deliveryMethod?.deliveryType;
  const [getOwnedVendorExists, isOwnedVendorExists, isGettingOwnedVendorData] = useApi<
    [{ orgId: string; id: string }],
    boolean
  >(vendorApi.getOwnedVendorExists, false, true);
  const hasVendorEmail = isOwnedVendorExists ? true : !!bill.vendor.contactEmail;
  const vendorEmailExist = hasVendorEmail || deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;

  const handleCollectVendorEmailSuccessModalOk = () => {
    analytics.track(eventPage, 'success-vendor-email-modal-closed');
    goBillsList();
  };

  const [CollectVendorEmailSuccessModal, showCollectVendorEmailSuccessModal] = useModal(Dialog, {
    type: CONSTS.DIALOG_TYPE.CONFIRM,
    variant: CONSTS.DIALOG_VARIANTS.SUCCESS,
    title: 'bills.pay.collectVendorEmailModal.successTitle',
    titleValues: { vendorName },
    image: site.theme?.pages?.PayBillSuccessPageContainer?.successImage,
    subtitle: 'bills.pay.collectVendorEmailModal.successSubTitle',
    okButtonText: 'bills.pay.collectVendorEmailModal.confirm',
    onOkAction: handleCollectVendorEmailSuccessModalOk,
    onCancelAction: handleCollectVendorEmailSuccessModalOk,
    showCancel: false,
    modalName: 'successCollectEmail',
  });

  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(CollectVendorEmailModalMessage, {
    onSuccess: showCollectVendorEmailSuccessModal,
    payment,
    bill,
    eventPage,
    modalName: 'collectEmail',
  });

  const getOwnedVendor = useCallback(async () => {
    if (bill.vendor?.id) {
      await getOwnedVendorExists({
        orgId,
        id: bill.vendor?.id,
      });
    }
  }, [bill.vendor, getOwnedVendorExists, orgId]);

  useEffect(() => {
    const { vendor } = bill;
    if (vendor) {
      getOwnedVendor();
    }

    if (!vendor.contactEmail) {
      analytics.track(eventPage, 'confirm-notify-vendor');
    }
  }, [bill, getOwnedVendor]);

  const goBillsList = () => {
    analytics.track(eventPage, 'done');
    close();
    dispatch(endPayBillFlowAction());
  };

  const collectVendorEmail = () => {
    showCollectVendorEmailModal();
    analytics.track(eventPage, 'notify-my-vendor');
  };

  const getValues = () => {
    const isUnilateralPayment = deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
    const getHeadTexts = () => {
      const isExpedited =
        payment.deliveryPreference !== null
          ? CONSTS.EXPEDITED_DELIVERY_TYPES.includes(payment.deliveryPreference)
          : false;

      const title = 'bills.pay.payBillSuccess.titles.regular';
      let subtitle = hasVendorEmail
        ? 'bills.pay.payBillSuccess.subtitles.regularWithNotify'
        : 'bills.pay.payBillSuccess.subtitles.regular';

      if (isExpedited) {
        subtitle = 'bills.pay.payBillSuccess.subtitles.expedited';
      }

      return { title, subtitle };
    };

    const getButtonLabel = () => {
      let buttonLabel = hasVendorEmail
        ? 'bills.pay.payBillSuccess.buttonLabel.done'
        : 'bills.pay.payBillSuccess.buttonLabel.notifyVendor';
      if (isUnilateralPayment) {
        buttonLabel = 'bills.pay.payBillSuccess.buttonLabel.toDashboard';
      }

      return buttonLabel;
    };

    const textValues = {
      amount: <MIFormattedCurrency key="amount" value={payment?.amount?.toString() || null} />,
      vendorName,
      processDate: <MIFormattedDate key="scheduledDate" date={payment.scheduledDate} />,
    };
    const { title, subtitle } = getHeadTexts();
    const buttonLabel = getButtonLabel();
    return {
      title,
      subtitle,
      textValues,
      buttonLabel,
    };
  };

  const { title, subtitle, textValues, buttonLabel } = getValues();

  if (isGettingOwnedVendorData) {
    return <AreaLoader />;
  }

  return (
    <Wrapper>
      {CollectVendorEmailModal}
      {CollectVendorEmailSuccessModal}
      {
        // eslint-disable-next-line
        payment && payment.amount && isNaN(payment.amount) ? (
          <React.Fragment />
        ) : (
          <QBDTSuccessLayout
            illustration={site.theme?.pages?.PayBillSuccessPageContainer?.successImage}
            title={title}
            text={subtitle}
            textValues={textValues}
            buttonLabel={buttonLabel}
            buttonAction={vendorEmailExist ? goBillsList : collectVendorEmail}
            linkLabel={vendorEmailExist ? '' : 'bills.pay.payBillSuccess.doneHere'}
            linkAction={goBillsList}
            isLoading={isLoading}
            hideHeader
          >
            {site.theme?.pages?.PayBillSuccessPageContainer?.showQBContainer && (
              <QBContainer>
                <QBLogo src={qbLogo} alt="logo" />
                <MIFormattedText label="bills.pay.payBillSuccess.notes.qbo" />
              </QBContainer>
            )}
          </QBDTSuccessLayout>
        )
      }
    </Wrapper>
  );
};

export default compose(withPayBillData())(PayBillSuccessPageContainer);

const QBContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.text.color.subtitle};
  margin: 0 0 4.5rem 0;
  padding: 2.1rem 2rem;
  border: 0.1rem solid ${(props) => props.theme.text.color.light};
  border-radius: 0.8rem;
  ${(props) => props.theme.text.fontType.medium};
`;

const QBLogo = styled.img`
  margin: 0 1.1rem 0 0;
  width: 2.2rem;
  height: 2.2rem;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  ${WizardIllustration} {
    height: 10rem;
    margin-bottom: 2rem;
  }
`;
