import React from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { Box, Link } from '@melio/billpay-design-system';
import { AccountType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import {
  getCardIcon,
  getCardImageUrl,
  isCardExpired,
  convertDateToMonthYearFormat,
  getExpirationDateHint,
  isAMEXCard,
} from 'src/utils/card';
import { getModifyFundingSourceOptions } from 'src/utils/funding-sources';
import profileStore from 'src/modules/profile/profile-store';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick?: () => void;
  method: RecordOf<AccountType>;
  showDropdownSelectedMethod: boolean;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  isSettings: boolean;
  modifyActions: Record<string, any>;
  isBillingFee?: boolean;
  onViewBenefitsClick?: (fundingSource?: AccountType) => void;
};

const getBillingFeeLabel = (isBillingFee: boolean, method?: RecordOf<AccountType>) => {
  if (isBillingFee && method) {
    return 'billingFee.addMethod.options.creditCard';
  }

  if (isBillingFee) {
    return 'billingFee.addMethod.options.emptyMethod.creditCard';
  }

  return null;
};

type GetCardDescriptionProps = {
  fundingSource?: AccountType;
  isSettings: boolean;
  isExpired?: boolean;
  onViewBenefitsClick: React.MouseEventHandler<HTMLDivElement> & React.MouseEventHandler<HTMLAnchorElement>;
};

const getCardDescription = ({ fundingSource, isSettings, isExpired, onViewBenefitsClick }: GetCardDescriptionProps) => {
  let description;
  let descriptionValues = {};

  if (!fundingSource) {
    description = isSettings
      ? 'fundingSources.option.credit.pluralLB' // Defer payments and earn card rewards ({fees_credit_value}% fee).{br}<link>View benefits</link>
      : 'fundingSources.option.credit.regular'; // Defer this payment, earn card rewards ({fees_credit_value}% fee). <link>View benefits</link>
    descriptionValues = {
      link: (...chunks) => (
        <Box as={Link} onClick={onViewBenefitsClick}>
          {React.Children.toArray(chunks)}
        </Box>
      ),
    };

    return { description, descriptionValues };
  }

  // With payment method. Expired credit card.
  if (isExpired) {
    description = 'bills.pay.fundingSource.expiredDescription'; // 'Please add a new card'

    return { description, descriptionValues };
  }

  const label = isSettings
    ? 'fundingSources.option.credit.plural' // Defer payments and earn card rewards ({fees_credit_value}% fee). <link>View benefits</link>
    : 'fundingSources.option.credit.regular'; // Defer this payment, earn card rewards ({fees_credit_value}% fee). <link>View benefits</link>
  description = isAMEXCard(fundingSource)
    ? 'fundingSources.option.credit.amex' // Limited for vendors in industries approved by AMEX. <link>View benefits</link>
    : label;
  descriptionValues = {
    link: (...chunks) => (
      <Box as={Link} onClick={onViewBenefitsClick}>
        {React.Children.toArray(chunks)}
      </Box>
    ),
  };

  return { description, descriptionValues };
};

function CreditPaymentMethod({
  onClick,
  method,
  isSelected,
  showDropdownSelectedMethod,
  optionComponent: OptionComponent,
  disabled,
  isSettings,
  modifyActions,
  isBillingFee = false,
  onViewBenefitsClick,
}: Props) {
  const handleViewBenefitsClick = (event) => {
    event.stopPropagation();

    onViewBenefitsClick && onViewBenefitsClick(method);
  };

  const permissions = useSelector(profileStore.selectors.getPermissions);
  const billingFeeLabel = getBillingFeeLabel(isBillingFee);

  if (showDropdownSelectedMethod) {
    if (method) {
      const icon = getCardIcon(method);
      const imageSrc = getCardImageUrl(method);
      return (
        <SelectedMethod
          icon={icon}
          imageSrc={imageSrc}
          title="bills.form.paymentActivity.scheduledBill.scheduleMethodCreditCard"
          label="bills.pay.fundingSource.creditLabel"
          labelValues={{
            ...method.getNameParts(),
          }}
        />
      );
    }

    return null;
  }

  if (!method) {
    const { description, descriptionValues } = getCardDescription({
      fundingSource: method,
      isSettings,
      onViewBenefitsClick: handleViewBenefitsClick,
    });
    return (
      <OptionComponent
        id="credit"
        label="onboarding.fundingSources.options.credit.label"
        description={billingFeeLabel || description}
        descriptionValues={descriptionValues}
        icon="icon-credit-card-icon"
        onClick={onClick}
        isEmptyList
      />
    );
  }

  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  const isCreditCardExpired = isCardExpired(method.cardAccount);
  const actionOptions = getModifyFundingSourceOptions(
    permissions,
    modifyActions,
    disabled,
    method.origin,
    isCreditCardExpired
  );
  const { description, descriptionValues } = getCardDescription({
    fundingSource: method,
    isExpired: isCreditCardExpired,
    isSettings,
    onViewBenefitsClick: handleViewBenefitsClick,
  });

  return (
    <OptionComponent
      id={method.id}
      isSelected={isSelected}
      disabled={disabled}
      isExpired={isCreditCardExpired}
      icon={icon}
      imageSrc={imageSrc}
      label="bills.pay.fundingSource.creditLabel"
      labelValues={{
        ...method.getNameParts(),
      }}
      onTopDescription={method.nickname}
      hint={getExpirationDateHint(method.cardAccount)}
      hintValues={{
        expirationDate: convertDateToMonthYearFormat(method.cardAccount.expiration),
      }}
      description={billingFeeLabel || description}
      descriptionValues={descriptionValues}
      onClick={onClick}
      actionOptions={actionOptions}
    />
  );
}

function CreditPaymentSelectedMethod({ method }: { method: RecordOf<AccountType> }) {
  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  return (
    <SelectedMethod
      icon={icon}
      imageSrc={imageSrc}
      title="bills.form.paymentActivity.scheduledBill.scheduleMethodCreditCard"
      label="bills.pay.fundingSource.creditLabel"
      labelValues={{
        ...method.getNameParts(),
      }}
    />
  );
}

CreditPaymentMethod.methodType = CONSTS.CARD_TYPES.CREDIT;
CreditPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherCreditCard';
CreditPaymentMethod.SelectedMethod = CreditPaymentSelectedMethod;
CreditPaymentMethod.isDisabled = (method) => isCardExpired(method.cardAccount);
CreditPaymentMethod.showAddAnotherOption = true;

export default CreditPaymentMethod;
