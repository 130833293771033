import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { BatchDateOption } from 'src/billpay/qbdt/pages/batch-pay/consts';
import { removeIdsFromState } from 'src/helpers/redux/listsActions';
import { name, batchPaymentsListName } from './consts';

const initialState = {
  settings: {
    fundingSourceId: null,
    scheduledDate: null,
    minScheduledDate: null,
    dateOption: BatchDateOption.ALL_AT_ONCE,
    isBillsGroupedByVendor: true,
    billIds: [],
    freeChecks: null,
    refreshRequired: true,
    hasScheduledPaymentsForBills: false,
    billsExceedAmountLimitCount: 0,
    exitUrl: undefined,
  },
  lists: {},
  createBatch: {},
  byId: {},
  billsToUpdate: [],
};
type SliceState = typeof initialState;

type SliceReducers = {
  update: (state: SliceState, action: any) => void;
  removeBillId: (state: SliceState, action: any) => void;
  removeBillFromPayment: (state: SliceState, action: any) => void;
  clearBillIdsList: (state: SliceState) => void;
  resetStore: (state: SliceState) => void;
};

const createRegularBatchBillsSettingsSlice = () => {
  const slice = createSlice<SliceState, SliceReducers>({
    name,
    initialState,
    reducers: {
      update(state, { payload }) {
        state.settings = { ...state.settings, ...payload };
        state.createBatch = {};
      },
      removeBillId(state, { payload }) {
        const { batchItemId } = payload;
        const batchItem = state.byId[batchItemId];
        const billsIds = batchItem.payment.bills.map(({ id }) => id);
        state.settings.billIds = state.settings.billIds.filter((id) => !billsIds.includes(id));
        removeIdsFromState(state, [batchItemId]);
      },
      removeBillFromPayment(state, { payload }) {
        const { batchItemId, parentId } = payload;

        state.settings.billIds = state.settings.billIds.filter((id) => id !== batchItemId);

        state.byId[parentId].payment.bills = state.byId[parentId].payment.bills.filter(
          (bill) => bill.id !== batchItemId
        );
      },
      clearBillIdsList(state) {
        if (!isEmpty(state.lists)) {
          removeIdsFromState(state, state.settings.billIds);
          delete state.lists[batchPaymentsListName];
        }
      },
      resetStore(state) {
        // maybe we should move it to separate slice
        state.settings = initialState.settings;
        state.byId = initialState.byId;
        state.lists = initialState.lists;
        state.createBatch = initialState.byId;
        state.billsToUpdate = initialState.billsToUpdate;
      },
    },
  });

  return {
    ...slice,
    initialState,
    selectors: {
      settings: (state) => state[name].settings,
    },
    dispatchers: {
      update: (dispatch) => (params) => dispatch(slice.actions.update(params)),
      removeBillId: (dispatch) => (params) => dispatch(slice.actions.removeBillId(params)),
      clearBillIdsList: (dispatch) => () => dispatch(slice.actions.clearBillIdsList()),
      removeBillFromPayment: (dispatch) => (params) => dispatch(slice.actions.removeBillFromPayment(params)),
      resetStore: (dispatch) => () => dispatch(slice.actions.resetStore()),
    },
  };
};

export const regularBatchBillsSettingsSlice = createRegularBatchBillsSettingsSlice();
