import React, { useEffect, useCallback } from 'react';
import { generatePath, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { useVirtualCardData, VirtualCardPaymentState } from 'src/pages/vendor/virtual-card-details/hooks';
import { getJWTPayload } from 'src/helpers/jwt';
import { DELIVERY_TYPE } from 'src/utils/consts';
import locations from '../locations';
import { getInitialUrl } from './helpers';
import { PaymentCanceledPage, VirtualCardProcessedPage } from '../virtual-card-details/pages';
import { InvalidAddDeliveryMethodPage } from '../virtual-delivery-method/pages';
import { CurrentBankAccountPage, ShiftVirtualCardToACHSuccessPage, NewBankAccountPage } from './pages';

export const ShiftVirtualCardToACHRouter = () => {
  const match = useRouteMatch<{ token: string }>();
  const history = useHistory();
  const { token } = match.params;
  const { virtualCardId, vendorId, paymentId } = getJWTPayload(token);

  const onInvalidToken = useCallback(
    () => history.push(generatePath(locations.shiftVirtualCardDetailsToAch.invalidToken, { token })),
    [history, token]
  );

  const { virtualCardPaymentState, virtualCard, payment, organization, deliveryMethod } = useVirtualCardData({
    token,
    virtualCardId,
    onInvalidToken,
  });

  const achDeliveryMethod = payment?.vendor?.deliveryMethods?.find((dm) => dm.deliveryType === DELIVERY_TYPE.ACH);

  useEffect(() => {
    if (payment && virtualCardPaymentState !== VirtualCardPaymentState.Unknown) {
      const initialUrl = getInitialUrl({
        virtualCardPaymentState,
        achDeliveryMethod,
      });

      history.push(generatePath(initialUrl, { token }));
    }
  }, [payment, virtualCardPaymentState]);

  if (!payment || virtualCardPaymentState === VirtualCardPaymentState.Unknown) {
    return <QBDTLoader />;
  }

  return (
    <Switch>
      <SmartRoute path={locations.shiftVirtualCardDetailsToAch.newBankAccount} exact>
        <NewBankAccountPage payment={payment} token={token} vendorId={vendorId} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCardDetailsToAch.currentBankAccount} exact>
        <CurrentBankAccountPage achDeliveryMethod={achDeliveryMethod} token={token} vendorId={vendorId} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCardDetailsToAch.success} exact>
        <ShiftVirtualCardToACHSuccessPage paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCardDetailsToAch.invalidToken} exact>
        <InvalidAddDeliveryMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCardDetailsToAch.paymentCanceled} exact>
        <PaymentCanceledPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCardDetailsToAch.cardProcessed}>
        <VirtualCardProcessedPage payment={payment} virtualCard={virtualCard} deliveryMethod={deliveryMethod} />
      </SmartRoute>
    </Switch>
  );
};
