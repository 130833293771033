import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  label: string;
  values?: Record<string, any>;
  textAlign?: string;
};

export const ModalTitle = ({ label, values, textAlign = 'inherit' }: Props) => (
  <Box textStyle="ds.h5" textAlign={textAlign as any}>
    <MIFormattedText values={values} label={label} />
  </Box>
);
