import vendor from './vendor/locations';
import orgs from './orgs/locations';
import qbdt from '../billpay/qbdt/pages/locations';
import { microDepositsVerify } from './funding-sources/locations';

const LOCATIONS = {
  vendor,
  orgs,
  qbdt,
  microDepositsVerify,
};

export default LOCATIONS;
