import React from 'react';
import { Menu } from '@melio/billpay-design-system';
import { Portal } from 'src/core/ds/portal';
import { AccountType } from 'src/utils/types';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types/store-types';
import FundingSourceMenuItem from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/FundingSourceMenuItem';
import FundingSourceCellLabel from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/FundingSourceCellLabel';
import batchPaymentsLocation from 'src/billpay/qbdt/pages/batch-pay/locations';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import { generatePath, useHistory } from 'react-router-dom';
import analytics from 'src/services/analytics';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import ArrowMenuButtonCell from './ArrowMenuButtonCell';
import { AddFundingSourceMenuItem } from '../../components/menu/fundingSource/AddFundingSourceMenuItem';
import { EmptyMethodCell } from './EmptyMethodCell';
import { getBillIdsFromBatchItem } from '../../utils';

type Props = {
  batchItemId: string;
  fundingSources: AccountType[];
  payment: BatchPaymentType;
  fundingSourceId?: number;
  setCurrentFundingSourceId?: (number) => void;
  dueDate: Date;
  isScheduledDateSelectedByUser: boolean;
};

export const FundingSourceCell = ({
  batchItemId,
  fundingSources,
  payment,
  fundingSourceId,
  setCurrentFundingSourceId,
  dueDate,
  isScheduledDateSelectedByUser,
}: Props) => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);

  const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchItemId));

  const currentFundingSource = fundingSources.find((fs) => fs.id === fundingSourceId) as AccountType;

  const redirectUrl = generatePath(batchPaymentsLocation.batch, {
    orgId,
    billId: Number(payment.billId),
  });

  const handleAddFundingSourceClick = () => {
    analytics.trackAction('add-payment-method-click', {
      billIds: getBillIdsFromBatchItem(batchItem),
      vendorId: payment?.vendorId,
      fromTable: 'yes',
      payBillFlowUuid: batchItem?.payBillFlowUUID,
    });

    const addFundingSourceUrl = generatePath(batchPaymentsLocation.addFundingSource, {
      orgId,
      redirectUrl,
      exitUrl: redirectUrl,
    });

    history.push(addFundingSourceUrl, {
      batchItemId,
      redirectUrl,
      payment,
      dueDate,
      isScheduledDateSelectedByUser,
    });
  };

  const handleDropdownOpen = () => {
    analytics.trackAction('open-select-funding-source', {
      fromTable: 'yes',
      billIds: getBillIdsFromBatchItem(batchItem),
      vendorId: payment?.vendorId,
      payBillFlowUuid: batchItem?.payBillFlowUUID,
    });
  };

  return (
    <Menu autoSelect={false} isLazy autoWidth size="sm" onOpen={handleDropdownOpen}>
      {({ isOpen }) =>
        !currentFundingSource ? (
          <EmptyMethodCell
            onAddDeliveryMethod={handleAddFundingSourceClick}
            testId={`regularBatchPayments.addFundingSource-${payment.billId}`}
          />
        ) : (
          <FundingSourcesMenuDropdown
            fundingSources={fundingSources}
            currentFundingSource={currentFundingSource}
            isOpen={isOpen}
            onFundingSourceAdd={handleAddFundingSourceClick}
            onFundingSourceChange={setCurrentFundingSourceId}
            payment={payment}
          />
        )
      }
    </Menu>
  );
};

type FundingSourcesMenuDropdownProps = {
  currentFundingSource: AccountType;
  isOpen: boolean;
  onFundingSourceAdd: () => void;
  onFundingSourceChange?: (number) => void;
} & Pick<Props, 'payment' | 'fundingSources'>;

const FundingSourcesMenuDropdown = ({
  fundingSources,
  currentFundingSource,
  isOpen,
  onFundingSourceAdd,
  onFundingSourceChange,
  payment,
}: FundingSourcesMenuDropdownProps) => (
  <>
    <ArrowMenuButtonCell isOpen={isOpen}>
      <FundingSourceCellLabel payment={payment} fundingSource={currentFundingSource} isMenuOpen={isOpen} />
    </ArrowMenuButtonCell>
    <Portal>
      <Menu.MenuList display={isOpen ? 'block' : 'none'} maxH="40vh" overflow="auto">
        {fundingSources.map((fundingSource) => (
          <FundingSourceMenuItem
            key={fundingSource.id}
            fundingSource={fundingSource}
            onClick={onFundingSourceChange}
            isSelected={fundingSource.id === currentFundingSource?.id}
          />
        ))}
        <AddFundingSourceMenuItem
          onClick={onFundingSourceAdd}
          testId={`button-batchAddFundingSource-${payment.billId}`}
        />
      </Menu.MenuList>
    </Portal>
  </>
);
