import React, { PropsWithChildren } from 'react';
import { Box } from '@melio/billpay-design-system';

type Props = {
  error: boolean;
  focus: boolean;
};

const getBorderColor = (error: boolean, focus: boolean) => {
  if (error) {
    return 'ds.red.200';
  }

  if (focus) {
    return 'ds.black';
  }

  return 'ds.gray.400';
};

export const FieldInputWrapper = ({ error, focus, children }: PropsWithChildren<Props>) => (
  <Box borderBottom="1px" color={getBorderColor(error, focus)} w="full">
    {children}
  </Box>
);
