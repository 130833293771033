import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import { MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import { AccountType, BillType, CompanyInfoType, DeliveryMethodType, PaymentFeeItem } from 'src/utils/types';
import { removeUnsupportedDeliveryOptionsByDeliveryMethod } from 'src/utils/delivery-methods';
import { BatchItemType } from 'src/modules/regular-batch-payments/types/store-types';
import { getSubRowsFromBatchItem } from 'src/modules/regular-batch-payments/utils';
import { mapBatchFees } from 'src/utils/fee';
import { BatchBillResponseType } from 'src/modules/regular-batch-payments/types/api-types';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { isEligibleToReceiveVirtualCard } from 'src/pages/vendor/virtual-delivery-method/utils';

export const mapBatchBillItemToDataTableRowType = ({
  bills,
  companyInfo,
  isCheckFeeNoPromotions,
}: {
  bills: Record<string, BillType>;
  companyInfo: CompanyInfoType;
  isCheckFeeNoPromotions: boolean;
}) => (item: BatchItemType): MainTableRowType => {
  const deliveryMethods = bills[item.id]?.vendor?.deliveryMethods || [];
  const selectedDeliveryMethod = deliveryMethods.find((dm) => dm.id === item.payment.deliveryMethod?.id);
  const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByDeliveryMethod({
    deliveryOptions: item.deliveryOptions || [],
    deliveryMethod: selectedDeliveryMethod || item.payment.deliveryMethod,
    companyInfo,
  });

  let fee = keyBy(item.fee, 'feeType');

  if (isCheckFeeNoPromotions) {
    // fix fee type for ach-to-check
    fee = keyBy(mapBatchFees(item.fee as any) as PaymentFeeItem[], 'feeType');
  } else {
    fee = omit(fee, ['undefined']);
  }

  return {
    id: item.id,
    totalAmount: item.payment.amount || 0,
    dueDate: item.dueDate,
    vendor: {
      id: item.payment?.vendor?.id || '',
      name: item.payment?.vendor?.companyName || '',
      companyName: item.payment?.vendor?.companyName || '',
      deliveryMethods,
      isOwnedVendor: !!item.payment?.vendor?.ownedById,
      mccCode: item.payment?.vendor?.mccCode,
    },
    payment: item.payment,
    deliveryOptions: possibleDeliveryOptions,
    fee,
    minScheduledDate: item.minScheduledDate,
    isBillReady: !isNil(item.payment.deliveryMethodId),
    isLoadingDates: item.loadingDates,
    isScheduledDateSelectedByUser: item.isScheduledDateSelectedByUser,
    subRows: getSubRowsFromBatchItem(item),
    payBillFlowUUID: item.payBillFlowUUID,
  };
};

export const getBillIdsByBatchTableItems = (batchItems: MainTableRowType[]) =>
  batchItems.reduce<string[]>(
    (accBillIds, batchItem) => [...accBillIds, ...batchItem.payment.bills.map(({ id }) => id)],
    []
  );

export const getBillIdsFromBatchItem = (batchItem: BatchItemType) => batchItem?.payment?.bills?.map(({ id }) => id);

export const isBulkPaymentEligible = (bills: BatchBillResponseType[]) => {
  const billsByVendorId = groupBy(bills, 'vendorId');
  return bills.some((bill) => {
    const { vendorId } = bill;
    const billsCountForCurrentVendor = billsByVendorId[vendorId].length;
    const multipleBillsExistForVendor = billsCountForCurrentVendor > 1;

    return multipleBillsExistForVendor;
  });
};

type IsFundingSourceAndDeliveryMethodIncompatibleParams = {
  deliveryMethod?: DeliveryMethodType;
  fundingSource: AccountType;
};

export const isFundingSourceAndDeliveryMethodIncompatible = ({
  deliveryMethod,
  fundingSource,
}: IsFundingSourceAndDeliveryMethodIncompatibleParams) => {
  const isVirtualDeliveryMethodIncompatible =
    deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL && !fundingSource.isVerified;
  const isVirtualCardDeliveryMethodIncompatible =
    deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD && !isEligibleToReceiveVirtualCard(fundingSource);

  return isVirtualDeliveryMethodIncompatible || isVirtualCardDeliveryMethodIncompatible;
};
