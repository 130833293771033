import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import get from 'lodash/get';
import ScrollToTop from 'src/components/layout/ScrollToTop';
import financialAccountsApi from 'src/services/api/financialAccounts';
import config from 'src/config';
import { withPreservedStateNavigator } from 'src/hoc';
import analytics from 'src/services/analytics';
import { GlobalState } from 'src/redux/types';
import { getOrgId } from 'src/redux/user/selectors';
import errorTracker from 'src/utils/error-tracking';
import locations from 'src/billpay/qbdt/pages/locations';
import { logger } from 'src/services/loggers';
import AddCardAccountsPage from './components/AddCardAccountsPage';

type MapStateToProps = {
  orgId: string;
};

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  locationState: Record<string, any>;
} & MapStateToProps;

type State = {
  isLoading: boolean;
  errorCode: string | null | undefined;
};

const eventPage = 'payment-method-credit-card';

/**
 * @note not in use yet.
 * once we have a step for selecting a bank, we should move here some of the logic of FundingSourcePageContainer
 */

class AddCardAccountsPageContainer extends React.PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      errorCode: null,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.pfReceivedMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.pfReceivedMessage, false);
  }

  onHideErrorMessageAlert = () => {
    this.setState({ errorCode: null });
  };

  pfReceivedMessage = (event) => {
    // If event origin is not from TabaPay's iframe, skip this part
    if (!get(config, 'services.tabapay.url', '').startsWith(get(event, 'origin', ''))) return;

    try {
      if (event.data === 'Close') this.navigateOnError();

      analytics.track('taba-pay', 'iframe-response');
      if (event.data.slice(0, 7) === 'Error: ') {
        analytics.track('taba-pay', 'iframe-error-response');
        this.navigateOnError();
      }

      const [digitsAndBin, expiration, token] = event.data.split('|');
      analytics.track('taba-pay', 'iframe-response-data');
      if (!token) this.navigateOnError();

      const digits = (digitsAndBin || '0000').slice(-4);
      const cardBin = digitsAndBin.length === 10 ? digitsAndBin.substring(0, 6) : null;
      this.setState({ isLoading: true });
      financialAccountsApi
        .checkCard(this.props.orgId, { token, cardBin })
        .then(() => {
          this.setState({ isLoading: false });
          analytics.track('taba-pay', 'iframe-response-success');

          this.props.navigate(
            generatePath(locations.fundingSource.cardHolder, {
              orgId: this.props.orgId,
            }),
            false,
            {
              orgId: this.props.orgId,
              token,
              digits,
              expiration,
              redirectUrl: get(this.props, 'location.state.redirectUrl'),
              cardBin,
            }
          );
        })
        .catch((err) => {
          this.setState({
            errorCode: get(err, 'code') || get(err, 'response.status'),
            isLoading: false,
          });
        });
    } catch (error: any) {
      analytics.track('taba-pay', 'iframe-error-response');
      logger.error('AddCardAccountsPageContainer.pfReceivedMessage(): iframe-error-response', {
        event: JSON.stringify(event.data),
      });
      errorTracker.capture(error, {
        event: JSON.stringify(event.data),
        message: 'taba-pay iframe-error-response',
      });
      this.navigateOnError();
    }
  };

  navigateOnError = () => {
    this.props.navigateToExitWithPreservedState();
  };

  goNext = () => {
    const { locationState } = this.props;

    analytics.track(eventPage, 'link-credit-card');
    this.props.navigate(generatePath(locations.fundingSource.card, { orgId: this.props.orgId }), false, {
      ...locationState.preservedState,
    });
  };

  goExit = () => {
    analytics.track(eventPage, 'exit');
    this.props.navigateToExitWithPreservedState(this.props.locationState);
  };

  loadIframe = () => this.setState({ isLoading: false });

  loadIframeError = () => {
    analytics.track('taba-pay', 'iframe-error-response');
    this.navigateOnError();
    const error = new Error('iframe-error-response-test');
    logger.error('AddCardAccountsPageContainer.loadIframeError(): iframe-error-response', error);
    errorTracker.capture(error, 'taba-pay iframe-error-response');
  };

  render() {
    const { isLoading, errorCode } = this.state;
    return (
      <ScrollToTop>
        <AddCardAccountsPage
          loadIframe={this.loadIframe}
          goNext={this.goNext}
          goExit={this.goExit}
          isLoading={isLoading}
          errorCode={errorCode}
          onError={this.loadIframeError}
          onHideErrorMessageAlert={this.onHideErrorMessageAlert}
        />
      </ScrollToTop>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

export default compose(withPreservedStateNavigator(), connect(mapStateToProps))(AddCardAccountsPageContainer);
