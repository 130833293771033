import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import { ReactComponent as ChevronUpIcon } from 'src/images/regular-batch-payments/menu-arrow-up.svg';
import { ReactComponent as ChevronDownIcon } from 'src/images/regular-batch-payments/menu-arrow-down.svg';
import { DATE_FORMATS } from 'src/utils/date-fns';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { disabledMenuStyle } from 'src/billpay/qbdt/pages/batch-pay/table/tableStyles';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';
import { Portal } from '@chakra-ui/react';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverFooter } from 'src/core/ds/popover';
import analytics from 'src/services/analytics';
import DatePicker from 'src/components/form/DatePicker';
import { isBusinessDay } from 'src/utils/dates';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types/store-types';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';
import { getBillIdsFromBatchItem } from '../../utils';

type Props = {
  batchItemId: string;
  dueDate: Date;
  payment: BatchPaymentType;
  minScheduledDate: string;
};

export const DeductionDateCell = ({ batchItemId, dueDate, payment, minScheduledDate }: Props) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const deductionDate = payment?.scheduledDate;
  const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchItemId));
  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();

  const onChange = useCallback(
    (onClose) => async ({ date }) => {
      const { deliveryMethodId, fundingSourceId, amount } = payment;

      onClose();

      if (deliveryMethodId && fundingSourceId) {
        await actions.updatePaymentsDeliveryOptions({
          batchItemId,
          orgId,
          deductionDate: date,
          deliveryMethodId,
          fundingSourceId,
          amount,
          payBillFlowUUID: batchItem.payBillFlowUUID,
          createOrigin: batchItem.payment.createOrigin,
        });
      } else {
        actions.updatePayments.setPaymentScheduledDate({
          batchItemId,
          scheduledDate: date,
        });
      }

      analytics.trackAction('select-date', {
        billIds: getBillIdsFromBatchItem(batchItem),
        vendorId: payment?.vendorId,
        payBillFlowUuid: batchItem?.payBillFlowUUID,
      });
    },
    [actions, orgId, payment]
  );

  const getFilteredDate = (date: Moment) => isBusinessDay(date);

  const formattedDeductionDate = format(new Date(deductionDate), DATE_FORMATS.monthShortWithLongDateAndYear);

  const handleDropdownOpen = () => {
    analytics.trackAction('open-scheduled-date-calendar-from-table', {
      billIds: getBillIdsFromBatchItem(batchItem),
      vendorId: payment?.vendorId,
      payBillFlowUuid: batchItem?.payBillFlowUUID,
    });
  };

  return useMemo(
    () => (
      <Box __css={!payment ? disabledMenuStyle : undefined} h="full">
        <Popover gutter={12} isLazy placement="bottom-start" onOpen={handleDropdownOpen}>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Flex h="full" align="center" justify="space-between" pointerEvents={isOpen ? 'none' : 'auto'}>
                  <Tooltip placement="top" label={formattedDeductionDate} isDisabled={!isTruncated}>
                    <Box isTruncated textStyle="ds.body3" ref={ref}>
                      {formattedDeductionDate}
                    </Box>
                  </Tooltip>
                  <ArrowIcon>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</ArrowIcon>
                </Flex>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  border="1px"
                  borderColor="grey.500"
                  borderRadius="lg"
                  as="div"
                  zIndex={50}
                  outline="none"
                  boxShadow="card"
                  display={isOpen ? 'block' : 'none'}
                >
                  <PopoverBody>
                    <DatePickerWrapper>
                      <DatePicker
                        id="deductionDate"
                        date={deductionDate}
                        dueDate={new Date(dueDate)}
                        required
                        size={CONSTS.TEXT_INPUT_SIZE.INLINE}
                        inline
                        withBottomElement
                        min={new Date(minScheduledDate)}
                        onChange={onChange(onClose)}
                        overrideMobile
                        filterDate={getFilteredDate}
                        calendarContainerClass="small-calendar with-bottom-element"
                      />
                    </DatePickerWrapper>
                  </PopoverBody>
                  <PopoverFooter bg="white" borderBottomRadius="lg">
                    <Info>
                      <Flex style={{ marginRight: '2.1rem' }}>
                        <Dot />
                        <Flex flexDirection="column">
                          <Box>
                            <MIFormattedText label="regularBatchPayments.cells.deductionDate.billDue" />
                          </Box>
                          <Box color="ds.gray.200" textStyle="ds.body3Semi">
                            <MIFormattedDate date={dueDate} />
                          </Box>
                        </Flex>
                      </Flex>
                      <Flex>
                        <Dot isScheduledDate />
                        <Flex flexDirection="column">
                          <Box>
                            <MIFormattedText label="regularBatchPayments.cells.deductionDate.deducted" />
                          </Box>
                          <Box color="ds.gray.200" textStyle="ds.body3Semi">
                            <MIFormattedDate date={deductionDate} />
                          </Box>
                        </Flex>
                      </Flex>
                    </Info>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      </Box>
    ),
    [payment, deductionDate, dueDate, minScheduledDate, onChange, isTruncated]
  );
};

const ArrowIcon = styled.div`
  display: inherit;
  padding-left: 0.3rem;
  margin-left: auto;
`;

const DatePickerWrapper = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled
      ? `
    opacity: 0.5;
    pointer-events: none;
  `
      : ''};
  .react-datepicker__day {
    color: ${(props) => props.theme.text.color.main};
  }
  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.text.color.light};
  }
  .react-datepicker__current-month {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .react-datepicker__navigation {
    top: 2.8rem;
  }
  .react-datepicker__day-name {
    color: #393a3d;
  }
  .highlightToday {
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 0px 0px 2px rgba(153, 153, 156);
    -moz-box-shadow: inset 0px 0px 0px 2px rgba(153, 153, 156);
    box-shadow: inset 0px 0px 0px 2px rgba(153, 153, 156);
  }
  .highlightToday:before {
    display: none !important;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  ${({ theme }) => theme.text.fontType.hint};
`;

const Dot = styled.div<{ isScheduledDate?: boolean }>`
  display: flex;
  align-self: center;
  width: 1rem;
  height: 1rem;
  margin: 0 0.8rem 0 2rem;
  border-radius: 1rem;
  border: 0.2rem solid ${(props) => (props.isScheduledDate ? props.theme.colors.brand : props.theme.colors.border.dark)};
  background-color: ${(props) => props.isScheduledDate && props.theme.colors.brand};
`;
