import * as React from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import styled from 'styled-components';

type SizeType = 'inline' | 'wizard' | 'none';
type Props = {
  size?: SizeType;
  errorMessage?: string | null;
  errorMessageValues?: Record<string, any>;
  errorMessageIcon?: React.ReactNode;
  notices?: Array<any>;
  testId?: string | null;
  align?: string;
  noticeValues?: Record<string, string | number>;
};

const MINotices = ({
  size = 'wizard',
  errorMessage,
  notices = [],
  errorMessageValues = {},
  testId,
  align = 'left',
  errorMessageIcon = null,
  noticeValues = {},
}: Props) => (
  <Notices size={size} data-testid={testId}>
    {errorMessage && (
      <ErrorNotice size={size}>
        {errorMessageIcon}
        <MIFormattedText label={errorMessage} values={errorMessageValues} />
      </ErrorNotice>
    )}
    {!errorMessage &&
      notices &&
      notices.map((notice, index) => (
        <Notice key={index} size={size} align={align}>
          {typeof notice === 'string' && <MIFormattedText label={notice} values={{ ...noticeValues }} />}
          {typeof notice !== 'string' && notice}
        </Notice>
      ))}
  </Notices>
);

export default MINotices;

const Notices = styled.div<{ size?: SizeType }>`
  margin-top: 0.5rem;
`;

const Notice = styled.label<{ size?: SizeType; align?: string }>`
  display: block;
  text-align: ${(props) => props.align};

  @media ${devices.mobile}, ${devices.phablet} {
    line-height: 1.4rem;
  }
  height: 1.6rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: rgba(141, 144, 150, 1);
  font-weight: ${(props) => props.theme.text.weight.normal};
`;

const ErrorNotice = styled(Notice)`
  color: ${(props) => props.theme.text.color.error};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
