import { useSelector } from 'react-redux';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import analytics from 'src/services/analytics';
import sumBy from 'lodash/sumBy';
import isNull from 'lodash/isNull';
import flatten from 'lodash/flatten';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';
import { MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import { getBillIdsByBatchTableItems } from '../utils';

const getBasicInfo = (batchItems) => {
  const batchBillIds = getBillIdsByBatchTableItems(batchItems);

  return {
    batchBillIds,
    batchBillCount: batchBillIds.length,
    batchBillTotalAmount: sumBy(batchItems, 'payment.amount'),
  };
};

type TrackScheduleBatchActionParams = {
  batchTableItems: MainTableRowType[];
  failedPayments: { billIds: string[] }[];
  scheduledPayments: PaymentType[];
};

const getBillIdsFromPayments = (payments: PaymentType[]): number[] =>
  flatten(payments.map((p) => p.billPayments?.map((bp) => bp.billId) || []));

export const useBatchAnalytics = () => {
  const { fundingSourceId, scheduledDate } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);

  const trackScheduleBatchAction = ({
    batchTableItems,
    failedPayments,
    scheduledPayments,
  }: TrackScheduleBatchActionParams) => {
    const failedBillIds = flatten(failedPayments.map((p) => p.billIds));
    const scheduledBillIds = getBillIdsFromPayments(scheduledPayments);

    const fastACHItems = scheduledPayments.filter(
      (payment) => !isNull(payment.deliveryPreference) && payment.deliveryMethod.deliveryType === DELIVERY_TYPE.ACH
    );
    const expressCheckItems = scheduledPayments.filter(
      (payment) => !isNull(payment.deliveryPreference) && payment.deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK
    );
    const fastACHBillIds = getBillIdsFromPayments(fastACHItems);
    const expressCheckBillIds = getBillIdsFromPayments(expressCheckItems);

    analytics.trackAction('regularBatchPayments.createPayments', {
      ...getBasicInfo(batchTableItems),
      fundingSourceId,
      isAllAtOnceOption: !isNull(scheduledDate),
      deductionDate: scheduledDate,
      failedBillIds,
      failedBillCount: failedBillIds.length,
      scheduledBillIds,
      scheduledBillCount: scheduledBillIds.length,
      FastACHBillIds: fastACHBillIds,
      FastACHBillCount: fastACHBillIds.length,
      ExpressCheckBillsIds: expressCheckBillIds,
      ExpressCheckBillsCount: expressCheckBillIds.length,
    });

    const exposedToFastDelivery = batchTableItems.filter(
      (batchTableItem) =>
        batchTableItem.payment?.deliveryMethod?.deliveryType &&
        batchTableItem.deliveryOptions &&
        batchTableItem.deliveryOptions.length > 1
    );

    const exposedToFastAch = exposedToFastDelivery.filter(
      (batchTableItem) => batchTableItem.payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.ACH
    );

    analytics.trackAction('regularBatchPayments.createPayments.fastAchExposure', {
      ...getBasicInfo(exposedToFastAch),
    });

    const exposedToExpressCheck = exposedToFastDelivery.filter(
      (batchTableItem) => batchTableItem.payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK
    );
    analytics.trackAction('regularBatchPayments.createPayments.expressCheckExposure', {
      ...getBasicInfo(exposedToExpressCheck),
    });
  };

  return {
    trackScheduleBatchAction,
  };
};
