import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFundingSources } from 'src/redux/user/selectors';
import { isCardExpired } from 'src/utils/card';
import { CONSTS } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';

type GetValidFundingSourcesType = [validFundingSources: AccountType[]];

const useGetValidFundingSources = (): GetValidFundingSourcesType => {
  const fundingSources = useSelector(getFundingSources);
  const validFundingSources = useMemo(
    () =>
      fundingSources.filter(
        (fs) =>
          !(
            (fs.fundingType === CONSTS.FUNDING_TYPE.CARD && isCardExpired(fs.cardAccount)) ||
            fs?.bankAccount?.isBlocked
          )
      ),
    [fundingSources]
  );

  return [validFundingSources];
};

export default useGetValidFundingSources;
