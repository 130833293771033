import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import billsStore from 'src/modules/bills/bills-store';
import { useSelectedBillIds } from '../hooks/useSelectedBillIds';

export const DashboardFooterTotalAmount = () => {
  const selectedBillIds = useSelectedBillIds();

  const bills = useSelector(billsStore.selectors.getByIds(selectedBillIds));

  const totalAmount = bills.reduce((acc, bill) => acc + bill.balance, 0);

  return (
    <Box __css={TitleStyle} data-testid="qbdt-dashboard-footer-title">
      <MIFormattedText
        label="paymentDashboard.footer.title"
        values={{
          totalAmount: <MIFormattedCurrency value={totalAmount} currency="$" />,
        }}
      />
    </Box>
  );
};

const TitleStyle = {
  textStyle: 'ds.body1',
  color: 'ds.white',
  mr: 'ds.lg',
};
