import React, { useMemo } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { withNavigator } from 'src/hoc';
import { CONSTS } from 'src/utils/consts';
import { OptionalDeliveryMethodsType } from 'src/utils/types';
import {
  getBill,
  getDeliveryMethodType,
  getPayment,
  getSelectedFundingSource,
} from 'src/redux/payBillWizard/selectors';
import analytics from 'src/services/analytics';
import { selectDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { withPayBillData, PayBillProps } from './hoc/withPayBillData';
import { PayBillSelectDeliveryMethodPage } from './components/PayBillSelectDeliveryMethodPage';

type Props = PayBillProps;
const headerLabel = 'qbo.header.title';

function PayBillSelectDeliveryMethodPageContainer({ onPrev, goExit, onNext, goAddDeliveryMethod }: Props) {
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const selectedDeliveryMethodType = useSelector(getDeliveryMethodType);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const dispatch = useDispatch();

  const onDeliveryMethodChange = (type: OptionalDeliveryMethodsType) => {
    const selectedDeliveryMethod = (bill.vendor?.deliveryMethods || []).find((dm) => dm.deliveryType === type);
    if (selectedDeliveryMethod) {
      analytics.track('pay-bill', 'delivery-method-select', { type });
      dispatch(selectDeliveryMethodAction(selectedDeliveryMethod));
    } else {
      analytics.track('pay-bill', 'delivery-method-add', { type });
      goAddDeliveryMethod(type);
    }
  };

  const goAddVirtualDeliveryMethod = () => {
    analytics.track('pay-bill', 'delivery-method-add', {
      type: CONSTS.DELIVERY_TYPE.VIRTUAL,
    });
    goAddDeliveryMethod(CONSTS.DELIVERY_TYPE.VIRTUAL);
  };

  const deliveryMethodOptions = useMemo(() => {
    const ach = bill.getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.ACH);
    const check = bill.getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.CHECK);
    return [ach, check];
  }, [bill]);

  const titleValues = { vendor: bill.vendor.companyName };

  return (
    <PayBillSelectDeliveryMethodPage
      onNext={onNext}
      onPrev={onPrev}
      goExit={goExit}
      companyName={bill.vendor.companyName}
      amount={payment.amount}
      deliveryMethodOptions={deliveryMethodOptions}
      selectedDeliveryMethod={selectedDeliveryMethodType}
      selectedFundingSource={selectedFundingSource}
      onDeliveryMethodChange={onDeliveryMethodChange}
      bill={bill}
      goAddVirtualDeliveryMethod={goAddVirtualDeliveryMethod}
      headerLabel={headerLabel}
      relativeStep={2 / 5}
      title="bills.pay.deliveryMethods.title"
      titleValues={titleValues}
    />
  );
}

export default compose(withNavigator(), withPayBillData())(PayBillSelectDeliveryMethodPageContainer);
