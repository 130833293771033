import { useSelector } from 'react-redux';
import { dashboardStore } from '../../../redux-store/dashboard-store';
import { DashboardListItemType } from '../../../types';

export const useSelectedBillIds = () => {
  const selectedDashboardItemsIds = useSelector(dashboardStore.selectors.dashboardSelection.selectedIds);
  const selectedDashboardListItems: DashboardListItemType[] = useSelector(
    dashboardStore.selectors.getByIds(selectedDashboardItemsIds)
  );

  return selectedDashboardListItems.reduce<string[]>((acc, item) => [...acc, ...item.billIds], []);
};
