import { clearStateReducer } from 'src/helpers/redux/clearStateReducer';
import { composeSlices } from 'src/helpers/redux/composeSlice';
import profileStore from 'src/modules/profile/profile-store';
import { getIsLoggedIn } from 'src/redux/user/selectors';

const selectors = {
  authenticated: (state: any) => getIsLoggedIn(state),
  userAuthType: (state: any) => {
    const profile = profileStore.selectors.profile(state);
    const isLoggedIn = selectors.authenticated(state);
    let userAuthType = 'unauthenticated';

    if (isLoggedIn) {
      if (profile.isGuest) {
        userAuthType = 'guest';
      } else if (!profile.isEmailVerified) {
        userAuthType = 'emailNotVerified';
      } else {
        userAuthType = 'authenticated';
      }
    }

    return userAuthType;
  },
};

const authenticationStore = composeSlices(
  {},
  {
    selectors,
    extraReducers: { ...clearStateReducer({}) },
  }
);

export default authenticationStore;
