import React from 'react';
import { Icon } from '@melio/billpay-design-system';
import { ReactComponent as FundingSourceCheckmark } from './funding-source-checkmark.svg';
import { MethodItemWrapper } from '../MethodItemWrapper/MethodItemWrapper';
import { MethodPlaceholder } from '../MethodItemWrapper/MethodPlaceholder';

export const FundingSourceItem = () => (
  <MethodItemWrapper>
    <Icon as={FundingSourceCheckmark} w="2.7rem" h="2.7rem" />
    <MethodPlaceholder />
  </MethodItemWrapper>
);
