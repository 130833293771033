import React from 'react';
import maxBy from 'lodash/maxBy';
import { DELIVERY_TYPE, PAYMENT_DELIVER_STATUS, PAYMENT_STATUS } from 'src/utils/consts';
import { DeliveryMethodType, PaymentType } from 'src/utils/types';
import {
  PaymentInfoHint,
  PaymentInfoHintVariantsEnum,
} from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoHint';
import { isVirtualCardPaymentExpired } from 'src/utils/payments';
import { MIFormattedDate } from 'src/utils/formatting';

type Props = {
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
};

export const DeliveryMethodInfoHint = ({ payment, deliveryMethod }: Props) => {
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;
  const isPaymentProcessedButNotCleared =
    [PAYMENT_STATUS.IN_PROGRESS, PAYMENT_STATUS.COMPLETED].includes(payment.status as PAYMENT_STATUS) &&
    payment.deliverStatus !== PAYMENT_DELIVER_STATUS.CLEARED;
  const isDebitCardDeliveryMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.CARD;
  const isVirtualCardExpired = isVirtualCardPaymentExpired(payment);
  const virtualCardInfo = maxBy(payment.virtualCards, 'createdAt');
  const testId = 'view-payment-delivery-method-hint';

  if (isVirtualCardDeliveryMethod && isPaymentProcessedButNotCleared) {
    return <PaymentInfoHint testId={testId} hint="viewBillPaymentActivity.deliveryMethod.virtualCardNotClearedHint" />;
  }

  if (isVirtualCardExpired && virtualCardInfo) {
    return (
      <PaymentInfoHint
        testId={testId}
        hint="viewBillPaymentActivity.deliveryMethod.virtualCardExpiredHint"
        hintValues={{
          expirationDate: <MIFormattedDate date={virtualCardInfo.endDate} />,
        }}
        variant={PaymentInfoHintVariantsEnum.Error}
      />
    );
  }

  if (isDebitCardDeliveryMethod) {
    return <PaymentInfoHint testId={testId} hint="viewBillPaymentActivity.deliveryMethod.debitCardHint" />;
  }

  return null;
};
