import React, { PropsWithChildren } from 'react';
import { PopoverArrow, forwardRef, BoxProps } from '@chakra-ui/react';
import { Box, Flex, Popover } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';
import { BillsInfoTable } from './BillsInfoTable';
import { BillInfo } from './types';

type Props = { billsInfo: BillInfo[] };

export const BillsInfoPopover = ({ children, billsInfo }: PropsWithChildren<Props>) => (
  <Box position="relative" overflow="visible" w="fit-content">
    <Popover placement="bottom" preventOverflow trigger="click">
      {({ onClose }) => (
        <>
          <Popover.PopoverTrigger>
            <WrapperWithForwardRef>{children}</WrapperWithForwardRef>
          </Popover.PopoverTrigger>
          <Box sx={popoverStyle}>
            <Popover.PopoverContent as="div" onClick={(e) => e.stopPropagation()} overflow="visible">
              <PopoverArrow left="50%" top="50%" bgColor="ds.white" />
              <PopoverBody>
                <Flex direction="row" justifyContent="flex-end" mb="ds.sm">
                  <PopoverCloseButton className="icon-close-icon" onClick={onClose} />
                </Flex>
                <BillsInfoTable data={billsInfo} />
              </PopoverBody>
            </Popover.PopoverContent>
          </Box>
        </>
      )}
    </Popover>
  </Box>
);

const WrapperWithForwardRef = forwardRef<BoxProps, 'div'>(({ children, ...props }, ref) => (
  <Box tabIndex={0} {...props} _focus={{ outline: 'none' }} ref={ref}>
    {children}
  </Box>
));

const PopoverCloseButton = chakra('i', {
  baseStyle: {
    color: 'ds.gray.200',
    fontSize: '2.4rem',
    cursor: 'pointer',
    alignSelf: 'end',
  },
});

const PopoverBody = chakra(Popover.PopoverBody, {
  baseStyle: {
    p: '2rem',
    bgColor: 'ds.white',
    border: '1px solid',
    borderColor: 'ds.gray.500',
    rounded: 'ds.md',
    shadow: 'ds.sm',
  },
});

// Doesn't work without !important. Took it from billpay-web/
const arrowPositionerStyle: React.CSSProperties = {
  overflow: 'visible',
  // zIndex could be number or Globals in React types, but string is also valid
  zIndex: '10001!important' as React.CSSProperties['zIndex'],
};

const arrowStyle: React.CSSProperties = {
  height: '11.31px!important',
  width: '11.31px!important',
  top: '50%!important',
  left: '50%!important',
  transformOrigin: '0 0',
  borderRight: '1px solid var(--chakra-colors-ds-gray-500)',
  borderBottom: '1px solid var(--chakra-colors-ds-gray-500)',
};

const hideArrowAddedWithBeforeModificator = {
  '.chakra-popover__popper[data-popper-placement*="top"] .chakra-popover__content:before': {
    display: 'none',
  },
  '.chakra-popover__popper[data-popper-placement*="bottom"] .chakra-popover__content:before': {
    display: 'none',
  },
};

const popoverStyle: Record<string, React.CSSProperties> = {
  ...hideArrowAddedWithBeforeModificator,
  '.chakra-popover__popper': {
    overflow: 'visible',
  },
  '.chakra-popover__content, .chakra-popover__body': {
    outline: 'none',
  },
  '.chakra-popover__popper[data-popper-placement*="bottom"] .chakra-popover__arrow-positioner': {
    ...arrowPositionerStyle,
    top: '-3.7px!important',
  },
  '.chakra-popover__popper[data-popper-placement*="bottom"] .chakra-popover__arrow': {
    ...arrowStyle,
    transform: 'rotate(-135deg) translate(-50%, -50%)!important',
  },

  '[data-popper-placement*="bottom"] .chakra-popover__content': {
    top: '0.3rem',
  },

  '.chakra-popover__popper[data-popper-placement*="top"] .chakra-popover__arrow-positioner': {
    ...arrowPositionerStyle,
    bottom: '-3.7px!important',
  },

  '.chakra-popover__popper[data-popper-placement*="top"] .chakra-popover__arrow': {
    ...arrowStyle,
    transform: 'rotate(45deg) translate(-50%, -50%)!important',
  },

  '[data-popper-placement*="top"] .chakra-popover__content': {
    bottom: '0.3rem',
  },
};
