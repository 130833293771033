import React, { useEffect } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import NotFound from '../../../images/general/404-illustration.svg';
import { withNavigator } from '../../../hoc';
import locations from '../../../utils/locations';
import { devices } from '../../../theme/AppDevices';

type Props = {
  navigate: (url: string) => void;
};

const NotFoundPage = ({ navigate }: Props) => {
  useEffect(() => {
    const eventPage = 'not-found-404';
    analytics.page(eventPage, eventPage);
  }, []);
  const orgId = useSelector(getOrgId);
  return (
    <QBDTSuccessLayout
      title="notFound.title"
      text="notFound.subtitle"
      buttonLabel="notFound.button"
      buttonAction={() => navigate(locations.MainApp.dashboard.url({ orgId }))}
      hasLogo
    >
      <NotFoundContainer src={NotFound} />
    </QBDTSuccessLayout>
  );
};

NotFoundPage.defaultProps = {};

const NotFoundContainer = styled.img`
  margin-top: -5rem;
  height: 34rem;

  @media ${devices.mobile} {
    margin: -3rem 0 2rem;
    height: 24rem;
  }
`;

export default compose(withNavigator())(NotFoundPage);
