import { fetchRequest, postRequest, putRequest } from './api';
import retry from '../../utils/retry';

export default {
  getCompanyInfo(orgId, local = false) {
    const url = `/orgs/${orgId}/company-info?local=${local}`;
    return fetchRequest(url);
  },
  getVendorsIntuitAcctNum(orgId, vendorOriginIds) {
    const url = `/orgs/${orgId}/quickbooks/get-vendor-intuit-acct-num?vendorOriginIds=${vendorOriginIds.join()}`;
    return fetchRequest(url);
  },
  getOrgByBillId(billId) {
    const url = `/orgs/orgByBillId/${billId}`;
    return fetchRequest(url);
  },

  updateCompanyInfo(orgId, companyInfo) {
    const url = `/orgs/${orgId}/company-info`;
    return putRequest(url, companyInfo);
  },

  getAccountsPaymentOptions(orgId) {
    const url = `/orgs/${orgId}/accounts-payment-options`;
    return fetchRequest(url);
  },

  createIntuitAccount(orgId, params) {
    const url = `/orgs/${orgId}/create-intuit-account`;
    return postRequest(url, params);
  },

  getActivitySummary(params?: { noPaid?: boolean }) {
    const url = '/orgs/activity-summary';
    return fetchRequest(url, params);
  },

  runPaymentSync(orgId, paymentId) {
    const url = `/orgs/${orgId}/quickbooks/sync/local-payment/${paymentId}`;
    return retry(() => fetchRequest(url), 500, 3);
  },

  runFullsync(orgId) {
    const url = `/orgs/${orgId}/full-sync`;
    return retry(() => fetchRequest(url), 500, 3);
  },

  runFullSyncSynchronously(orgId) {
    const url = `/orgs/${orgId}/full-sync`;
    return retry(() => postRequest(url), 500, 3);
  },

  syncCompanyInfo(orgId) {
    const url = `/orgs/${orgId}/quickbooks/sync/company-info`;
    return postRequest(url);
  },

  setTraitsToDB(orgId, trait) {
    const url = `/orgs/${orgId}/traits`;
    return postRequest(url, trait);
  },

  getOrganizationFeeCatalog(orgId) {
    const url = `/orgs/${orgId}/fee-catalog`;

    return fetchRequest(url);
  },

  getOrganizationFee(orgId, params) {
    const url = `/orgs/${orgId}/fee`;

    if (params?.fundingSourceType === 'credit') params.fundingSourceType = 'creditCard';
    else if (params?.fundingSourceType === 'debit') params.fundingSourceType = 'debitCard';

    return fetchRequest(url, params);
  },

  getAllowedMCCList(orgId, params) {
    const url = `/orgs/${orgId}/allowed-mcc`;

    return fetchRequest(url, params);
  },

  getFreeChecks(orgId) {
    const url = `/orgs/${orgId}/free-checks`;

    return fetchRequest(url);
  },

  getComplianceLimitations(orgId) {
    const url = `/orgs/${orgId}/compliance-limitations?stage=payment&isInitiator=true`;

    return fetchRequest(url);
  },
};

export const OrganizationRestApi = {
  fetch({ id }) {
    const url = `/orgs/${id}/company-info`;
    return fetchRequest(url);
  },
  update(companyInfo) {
    const url = `/orgs/${companyInfo.id}/company-info`;
    return putRequest(url, companyInfo).then((res) => ({
      id: companyInfo.id,
      ...res.companyInfo,
    }));
  },

  create(companyInfo) {
    const url = '/orgs/create-owned-organization';
    return postRequest(url, companyInfo);
  },
};
