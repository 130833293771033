import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import { NOTIFICATION_CARD_TYPES } from 'src/utils/consts';

type LabelValues = {
  label: string;
  values?: Record<string, any>;
};

export type MINotificationCardProps = {
  title?: LabelValues;
  subtitle?: LabelValues;
  type: NOTIFICATION_CARD_TYPES;
  className?: string;
};

const MINotificationCard = ({ className, title, subtitle, type }: MINotificationCardProps) => {
  const icon = getIcon(type);
  return (
    <NotificationContainer className={className} type={type} data-testid={`notification-${type}`}>
      <NotificationIcon className={icon} type={type} />
      <NotificationTextContainer>
        {title && (
          <NotificationTitle>
            <MIFormattedText label={title.label} values={title.values} />
          </NotificationTitle>
        )}
        {subtitle && (
          <NotificationSubTitle>
            <MIFormattedText label={subtitle.label} values={subtitle.values} />
          </NotificationSubTitle>
        )}
      </NotificationTextContainer>
    </NotificationContainer>
  );
};

export default MINotificationCard;

const getIcon = (type: NOTIFICATION_CARD_TYPES) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.SUCCESS:
      return 'icon-checkmark-icon';
    case NOTIFICATION_CARD_TYPES.ERROR:
      return 'icon-warning-hollow-icon';
    case NOTIFICATION_CARD_TYPES.INFO:
      return 'icon-info-hollow-icon';
    case NOTIFICATION_CARD_TYPES.HELP:
      return 'icon-help-question-icon';
    case NOTIFICATION_CARD_TYPES.WARNING:
    default:
      return 'icon-warning-tri-icon';
  }
};

const getColor = (type: NOTIFICATION_CARD_TYPES, theme: any) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.SUCCESS:
      return theme.colors.success.opaque;
    case NOTIFICATION_CARD_TYPES.ERROR:
      return theme.colors.failure.opaque;
    case NOTIFICATION_CARD_TYPES.INFO:
      return theme.text.color.blue;
    case NOTIFICATION_CARD_TYPES.HELP:
      return theme.text.color.subtitle;
    case NOTIFICATION_CARD_TYPES.WARNING:
    default:
      return theme.colors.warning.opaque;
  }
};

const NotificationContainer = styled.div`
  display: flex;
  padding: 1.6rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  border: 0.1rem solid ${(props) => getColor(props.type, props.theme)};
  border-radius: 0.8rem;
  color: ${(props) => props.theme.text.color.main};
`;

const NotificationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.theme.text.fontType.medium};
`;

const NotificationTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const NotificationIcon = styled.i`
  font-size: 2.4rem;
  margin-right: 1.6rem;
  color: ${({ type, theme }) => getColor(type, theme)};
`;

const NotificationSubTitle = styled.div``;
