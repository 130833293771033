import { createSlice } from '@reduxjs/toolkit';
import { DashboardSyncBillsStatus } from '../sync/types';
import { name } from './consts';

const initialState = {
  syncBills: {
    status: DashboardSyncBillsStatus.None,
  },
};

type SliceState = {
  syncBills: {
    status: DashboardSyncBillsStatus;
  };
};

type SetStatusActionPayload = {
  payload: DashboardSyncBillsStatus;
};

type SliceReducers = {
  setStatus: (state: SliceState, action: SetStatusActionPayload) => void;
  startSync: (state: SliceState) => void;
};

const createDashboardSyncBillsSlice = () => {
  const slice = createSlice<SliceState, SliceReducers>({
    name: `[${name.toUpperCase()}] DASHBOARD_SYNC_BILLS`,
    initialState,
    reducers: {
      setStatus(state, { payload }) {
        state.syncBills = {
          status: payload,
        };
      },
      startSync() {
        // added to create slice action and use it in saga;
      },
    },
  });
  return {
    ...slice,
    initialState,
    selectors: {
      getStatus: (state) => state[name].syncBills.status,
    },
    dispatchers: {
      setStatus: (dispatch) => (params) => dispatch(slice.actions.setStatus(params)),
      startSync: (dispatch) => () => dispatch(slice.actions.startSync()),
    },
  };
};

const dashboardSyncBillsSlice = createDashboardSyncBillsSlice();

export { dashboardSyncBillsSlice };
