import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  onClose: () => void;
};

export const LearnMoreModal = ({ onClose }: Props) => (
  <ModalMessage
    titleComponent={<MIFormattedText label="vendors.deliveryMethods.virtualCard.learnMoreModal.title" />}
    contentComponent={
      <Box textStyle="ds.body1" color="ds.gray.100">
        <MIFormattedText label="vendors.deliveryMethods.virtualCard.learnMoreModal.text" />
      </Box>
    }
    buttonLabel="vendors.deliveryMethods.virtualCard.learnMoreModal.cta"
    onButtonClick={onClose}
    onCloseClick={onClose}
  />
);
