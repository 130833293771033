import { GlobalState } from '../types';
import { OrganizationPreferencesType, OrganizationFeeCatalogType } from '../../utils/types';
import { getUsersOfOrg } from '../../modules/users/users-store';
import profileStore from '../../modules/profile/profile-store';

function shouldShowPaymentAuditLog(state) {
  const orgId = profileStore.selectors.getCurrentOrgId(state);
  const users = getUsersOfOrg(orgId)(state);
  return users.length > 1;
}
export const getOrganizationPreferences = (state: GlobalState): OrganizationPreferencesType => {
  let preferences = state.organization.organizationPreferences;
  if (preferences.showPaymentAuditLog == null && shouldShowPaymentAuditLog(state)) {
    preferences = {
      ...preferences,
      showPaymentAuditLog: 'true',
    };
  }

  return preferences;
};
export const getIsOrganizationPreferencesUpdating = (state: GlobalState): boolean =>
  state.organization.isOrganizationPreferencesUpdating;

export const getOrganizationFeeCatalog = (state: GlobalState): OrganizationFeeCatalogType =>
  state.organization.feeCatalog;
