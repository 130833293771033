import * as React from 'react';
import { RecordOf } from 'immutable';
import { compose } from 'recompose';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { FieldType, BankType, DeliveryMethodOriginType, CompanyInfoType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { renderBlockedForPaymentDialog } from 'src/utils/vendors';
import { withPreservedStateNavigator } from '../../../hoc/index';
import EditBankDeliveryMethodPage from './components/EditBankDeliveryMethodPage';
import { DeliveryMethodProps, withDeliveryMethodData } from './hoc/withDeliveryMethodData';
import locations from '../../../utils/locations';
import analytics from '../../../services/analytics';

type Props = {
  origin: DeliveryMethodOriginType;
  vendorId: string;
  locationState: Record<string, any>;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void;
  companyInfo: RecordOf<CompanyInfoType>;
  site: any;
} & DeliveryMethodProps;

type State = {
  bank: RecordOf<BankType>;
};

const eventPage = 'vendor-delivery-method';

class EditBankDeliveryMethodPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bank: props.bank,
    };
  }

  onChange = ({ id, value }: FieldType) => {
    this.setState(({ bank }) => ({ bank: bank.merge({ [id]: value }) }));
  };

  onDone = () => {
    this.props.onBankAdded(this.state.bank);
  };

  goExit = () => {
    const { vendorId, navigate, navigateToExitWithPreservedState } = this.props;

    analytics.track(eventPage, 'exit');
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState();
    } else {
      navigate(
        locations.Vendors.view.url({
          id: vendorId,
          type: CONSTS.CONTACTS_TAB.VENDORS,
        })
      );
    }
  };

  onCloseBlockPaymentDialog = () => {
    const { navigate, locationState } = this.props;
    if (locationState?.billId) {
      navigate(
        locations.Bills.view.url({
          id: locationState?.billId,
        })
      );
    }
  };

  render() {
    const {
      isLoading,
      validationErrors,
      origin,
      errorCode,
      check,
      companyInfo,
      isVendorBlockedForPayment,
      site,
    } = this.props;

    const { bank } = this.state;
    return (
      <>
        {isVendorBlockedForPayment && renderBlockedForPaymentDialog(site, this.onCloseBlockPaymentDialog)}
        <EditBankDeliveryMethodPage
          vendorName={check.printName}
          bank={bank}
          onDone={this.onDone}
          onChange={this.onChange}
          goExit={this.goExit}
          isLoading={isLoading}
          validationErrors={validationErrors}
          origin={origin}
          errorCode={errorCode}
          companyName={companyInfo.companyName}
          isVendorBlockedForPayment={isVendorBlockedForPayment}
          onCloseBlockPaymentDialog={this.onCloseBlockPaymentDialog}
        />
      </>
    );
  }
}

export default compose(
  withPreservedStateNavigator(),
  withDeliveryMethodData(),
  withSiteContext()
)(EditBankDeliveryMethodPageContainer);
