import keyBy from 'lodash/keyBy';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { BillType } from 'src/utils/types';
import { BILL_MAX_AMOUNT } from 'src/utils/consts';
import { logger } from 'src/services/loggers';
import { syncBillEntities, syncBillEntity, SyncBillEntityDataItem } from 'src/services/api/qbdt';
import { getBill } from '../entities/bill';
import { isVendorConflictError } from '../util';
import { getQbdtVendor, getQbdtVendors, qbdtVendorToMelio } from '../entities/vendor';
import { syncQbdtVendor } from '../sync/sync-vendor';
import { QBDTBill, QBDTVendor } from '../types';

type SyncBillDataMapperParams = {
  qbdtBill: QBDTBill;
  qbdtVendor: QBDTVendor;
};

const syncBillDataMapper = ({ qbdtBill, qbdtVendor }: SyncBillDataMapperParams): SyncBillEntityDataItem => ({
  vendor: qbdtVendorToMelio(qbdtVendor),
  vendorExternalId: qbdtVendor.ListID,
  bill: {
    balance: qbdtBill.AmountDue,
    totalAmount: qbdtBill.AmountDue,
    invoiceDate: qbdtBill.TxnDate,
    dueDate: qbdtBill.DueDate,
    currency: 'USD',
    files: [],
    invoiceNumber: qbdtBill.RefNumber,
    note: qbdtBill.Memo,
  },
  billExternalId: qbdtBill.TxnID,
});

export const SyncBillMaxAmountErrorMessage = 'Bills with amount more than 1M are not allowed';
interface SyncBillWithIdParams {
  orgId: number;
  billTxnId: string;
  qbdtBillEntity?: never;
}
interface SyncBillWithEntityParams {
  orgId: number;
  qbdtBillEntity: QBDTBill;
  billTxnId?: never;
}

type SyncBillParams = SyncBillWithIdParams | SyncBillWithEntityParams;

export async function syncBill({ billTxnId, orgId, qbdtBillEntity }: SyncBillParams) {
  const qbdtBill: QBDTBill = qbdtBillEntity || (await getBill(billTxnId as string));

  if (!qbdtBill.VendorRef?.ListID) {
    throw Error('No vendor found related to bill');
  }

  if (qbdtBill.AmountDue >= BILL_MAX_AMOUNT) {
    throw Error(SyncBillMaxAmountErrorMessage);
  }

  const qbdtVendor = await getQbdtVendor(qbdtBill.VendorRef.ListID);
  if (!qbdtVendor) {
    throw Error(`Vendor ${qbdtBill?.VendorRef?.ListID} not found in bill ${qbdtBill.TxnID}`);
  }

  const postBillAndVendor = async () => {
    const data = syncBillDataMapper({ qbdtBill, qbdtVendor });
    const { bill } = await syncBillEntity({ orgId, data });

    return bill as BillType;
  };

  try {
    return await postBillAndVendor();
  } catch (error: any) {
    if (isVendorConflictError(error?.code)) {
      await syncQbdtVendor(orgId, qbdtVendor);
      return postBillAndVendor();
    }

    throw error;
  }
}

type SyncBillsParams = {
  orgId: number;
  qbdtBills: QBDTBill[];
};

export async function syncBills({ orgId, qbdtBills }: SyncBillsParams) {
  const qbdtVendorIds = uniq<string>(compact(qbdtBills.map((qbdtBill) => qbdtBill.VendorRef?.ListID)));
  const qbdtVendors = await getQbdtVendors(qbdtVendorIds);
  const qbdtVendorsMap = keyBy(qbdtVendors, 'ListID');

  const data = compact(
    qbdtBills.map((qbdtBill) => {
      const qbdtVendor = qbdtBill.VendorRef?.ListID ? qbdtVendorsMap[qbdtBill.VendorRef.ListID] : null;
      if (!qbdtVendor) {
        logger.error(`sync.syncBills(): Vendor ${qbdtBill.VendorRef?.ListID} not found in bill ${qbdtBill.TxnID}`);

        return null;
      }

      return syncBillDataMapper({ qbdtBill, qbdtVendor });
    })
  );

  if (data.length === 0) {
    return null;
  }

  try {
    return syncBillEntities({ orgId, data });
  } catch (error: any) {
    if (isVendorConflictError(error?.code)) {
      for (const qbdtVendor of Object.values(qbdtVendorsMap)) {
        // eslint-disable-next-line no-await-in-loop
        await syncQbdtVendor(orgId, qbdtVendor);
      }

      return syncBillEntities({ orgId, data });
    }

    throw error;
  }
}
