import React from 'react';
import { Menu } from '@melio/billpay-design-system';

import { AccountType } from 'src/utils/types';
import FundingSourceLabel from './FundingSourceLabel';

type Props = {
  fundingSource: AccountType;
  onClick?: (string) => void;
  isSelected?: boolean;
};

const FundingSourceMenuItem = ({ fundingSource, onClick, isSelected }: Props) => {
  const handleClick = () => {
    if (onClick) {
      onClick(fundingSource.id);
    }
  };

  return (
    <Menu.MenuItem
      bg={isSelected ? '#EEEEEE' : 'ds.white'}
      onClick={handleClick}
      data-testid={`funding-source-menu-item-${fundingSource.id}`}
    >
      <FundingSourceLabel fundingSource={fundingSource} />
    </Menu.MenuItem>
  );
};

export default FundingSourceMenuItem;
