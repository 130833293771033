import { createSlice } from '@reduxjs/toolkit';
import { name } from './consts';

const initialState = {
  dashboardSelection: {
    selectedIds: [],
  },
};

type SliceState = {
  dashboardSelection: {
    selectedIds: string[];
  };
};

type SelectionActionPayload = {
  payload: string[];
};

type SliceReducers = {
  setSelection: (state: SliceState, action: SelectionActionPayload) => void;
};

const createDashboardSelectionSlice = () => {
  const slice = createSlice<SliceState, SliceReducers>({
    name: `[${name.toUpperCase()}] DASHBOARD_SELECTION`,
    initialState,
    reducers: {
      setSelection(state, { payload }) {
        state.dashboardSelection = {
          selectedIds: payload,
        };
      },
    },
  });
  return {
    ...slice,
    selectors: {
      selectedIds: (state) => state[name].dashboardSelection?.selectedIds || [],
    },
    dispatchers: {
      setSelection: (dispatch) => (params) => dispatch(slice.actions.setSelection(params)),
    },
  };
};

const dashboardSelectionSlice = createDashboardSelectionSlice();

export { dashboardSelectionSlice };
