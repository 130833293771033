import { call } from 'redux-saga/effects';
import vendorsApi from 'src/services/api/vendors';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { updateVendorEmailInQBDTSaga } from './vendors-store';

const name = 'vendors';

export const vendorsUpdateBatchPayments = createApiCallSlice<any, any>({
  name: '[VENDORS] UPDATE_BATCH',
  api: vendorsApi.updateVendorsBatch,
  initialState: {
    byId: {},
    updateBatch: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.updateBatch.loading = true;
      state.updateBatch.error = undefined;
    },
    [ON_SUCCESS]: (state, action) => {
      state.updateBatch.loading = false;
      state.updateBatch.error = undefined;
      action.payload.forEach((vendor) => {
        state.byId[vendor.id] = vendor;
      });
    },
    [ON_FAILURE]: (state, action) => {
      state.updateBatch.loading = false;
      state.updateBatch.error = action.error;
    },
  },
  selectors: {
    status: (state) => state[name]?.updateBatch,
  },
  sagas: {
    *[ON_SUCCESS](action) {
      for (const vendor of action.payload) {
        yield call(updateVendorEmailInQBDTSaga, vendor.id, vendor.contactEmail || '');
      }
    },
  },
});
