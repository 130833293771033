import React, { RefObject, SyntheticEvent } from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import isEmpty from 'lodash/isEmpty';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { injectIntl, IntlShape } from 'react-intl';
import { compose } from 'recompose';
import { TextInputSize } from 'src/utils/types';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import styled, { css } from 'styled-components';
import MINotices from 'src/components/common/MINotices';
import MIInputLabel from './MIInputLabel';
import { withBreak } from '../../hoc';

export enum INPUT_TYPE {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  TEL = 'tel',
  SEARCH = 'search',
}

export type InputModeType = 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;

export type InputType = INPUT_TYPE.TEXT | INPUT_TYPE.PASSWORD | INPUT_TYPE.NUMBER | INPUT_TYPE.TEL | INPUT_TYPE.SEARCH;

export type MITextInputBaseProps = {
  id: string;
  value?: string | number | null;
  label: string;
  labelValues: Record<string, any>;
  placeholder?: string;
  type?: InputType;
  step?: number;
  // Because of `text-mask` lib issue we need to get DOM element from inputRef.current.inputElement,
  // not just from `inputRef.current` https://github.com/text-mask/text-mask/pull/871#issue-229558396
  inputRef?: RefObject<any>;
  notices?: Array<string>;
  hideInput?: boolean;
  errorMessage?: string | null;
  errorMessageValues?: Record<string, any>;
  errorMessageIcon?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  readOnlyValue?: boolean;
  size?: TextInputSize;
  autoFocus?: boolean;
  viewOnly?: boolean;
  intl: IntlShape;
  mask?: Array<any>;
  autocomplete?: string;
  min?: any;
  max?: any;
  onClick: (e: SyntheticEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
  device: { isMobile: boolean };
  maxlength?: number;
  pattern?: string;
  inputMode?: InputModeType;
  testId?: string | null;
  suffix?: any;
  site: any;
  noticeValues?: Record<string, string | number>;
  withDefaultInputArrows?: boolean;
  privateData?: boolean;
};

type Props = MITextInputBaseProps & {
  onChange?: (changeField: { id: string; value: string }) => void;
};

type PropsPassthrough = MITextInputBaseProps & {
  onChange: (changeField: { id: string; value: string } | SyntheticEvent<HTMLInputElement>) => void;
};

class MITextInputBase<T extends MITextInputBaseProps> extends React.PureComponent<T> {
  static defaultProps = {
    disabled: false,
    required: false,
    readOnlyValue: false,
    placeholder: '',
    notices: [],
    hideInput: false,
    type: INPUT_TYPE.TEXT,
    step: undefined,
    size: CONSTS.TEXT_INPUT_SIZE.WIZARD,
    viewOnly: false,
    errorMessage: null,
    errorMessageValues: {},
    errorMessageIcon: null,
    autoFocus: false,
    mask: [],
    passthroughOnChange: false,
    min: undefined,
    max: undefined,
    inputRef: undefined,
    maxlength: undefined,
    pattern: undefined,
    inputMode: undefined,
    testId: null,
    suffix: undefined,
    labelValues: undefined,
    noticeValues: {},
    withDefaultInputArrows: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {};

  render() {
    const {
      id,
      type,
      step,
      placeholder,
      intl,
      errorMessage,
      size,
      viewOnly,
      disabled,
      required,
      label,
      inputRef,
      value,
      notices,
      hideInput,
      onClick,
      mask,
      readOnlyValue,
      onFocus,
      onBlur,
      autocomplete,
      min,
      max,
      device,
      maxlength,
      errorMessageValues,
      errorMessageIcon,
      pattern,
      inputMode,
      suffix,
      labelValues,
      noticeValues,
      withDefaultInputArrows,
      privateData,
    } = this.props as MITextInputBaseProps;
    const placeholderText = !placeholder ? '' : intl.formatMessage({ id: placeholder });
    const autoFocus = device.isMobile ? false : this.props.autoFocus;
    const testId = this.props.testId || `input-${id}`;
    const inputWrapperClassName = classNames({
      [FULL_STORY_MASK_RULE_CLASS]: privateData,
    });

    return (
      <Container
        className="input-container"
        size={size}
        withDefaultInputArrows={withDefaultInputArrows}
        hideInput={hideInput}
      >
        <MIInputLabel
          inputId={id}
          label={label}
          labelValues={labelValues}
          errorMessage={errorMessage}
          size={size}
          required={required}
        />

        <InputWrapper className={inputWrapperClassName}>
          {isEmpty(mask) ? (
            <React.Fragment>
              <TextInput
                id={id}
                name={id}
                disabled={disabled}
                label={label}
                value={value == null ? '' : value}
                placeholder={placeholderText}
                error={errorMessage}
                type={type}
                step={step}
                inline={size}
                readOnly={readOnlyValue}
                viewOnly={viewOnly}
                autoFocus={autoFocus}
                onChange={this.handleChange}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete={autocomplete}
                min={min}
                max={max}
                ref={inputRef}
                maxLength={maxlength}
                pattern={pattern}
                inputMode={inputMode}
                data-testid={testId}
              />
            </React.Fragment>
          ) : (
            <MaskedInput
              mask={mask}
              guide={false}
              ref={inputRef}
              onChange={this.handleChange}
              render={(ref, props) => (
                <TextInput
                  ref={ref}
                  {...props}
                  id={id}
                  name={id}
                  disabled={disabled}
                  label={label}
                  value={value == null ? '' : value}
                  placeholder={placeholderText}
                  error={errorMessage}
                  type={type}
                  step={step}
                  inline={size}
                  viewOnly={viewOnly}
                  readOnly={readOnlyValue}
                  autoFocus={autoFocus}
                  onClick={onClick}
                  autoComplete={autocomplete}
                  min={min}
                  max={max}
                  maxLength={maxlength}
                  pattern={pattern}
                  inputMode={inputMode}
                  data-testid={testId}
                />
              )}
            />
          )}
          <Suffix inline={size}>{suffix}</Suffix>
        </InputWrapper>
        <MINotices
          size={size}
          notices={notices}
          errorMessage={errorMessage}
          errorMessageValues={errorMessageValues}
          errorMessageIcon={errorMessageIcon}
          testId={`${testId}-notices`}
          noticeValues={noticeValues}
        />
      </Container>
    );
  }
}

class PlainMITextInput extends MITextInputBase<Props> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { disabled, onChange, id, type } = this.props;
    if (!disabled && onChange) {
      onChange(
        Object.assign(
          {},
          {
            id,
            value: e.currentTarget.value,
            valueAsNumber: e.currentTarget.valueAsNumber,
          },
          type ? { type } : {}
        )
      );
    }
  };
}

export const MITextInput = compose(withBreak(), withSiteContext())(injectIntl(PlainMITextInput));

class MITextInputPassthroughBase extends MITextInputBase<PropsPassthrough> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!this.props.disabled) {
      this.props.onChange(e);
    }
  };
}

export const MITextInputPassthrough = compose(withBreak(), withSiteContext())(injectIntl(MITextInputPassthroughBase));

const Container = styled.div<{ size?: TextInputSize }>`
  width: 100%;
  margin-bottom: ${(props) => (props.size === CONSTS.TEXT_INPUT_SIZE.WIZARD ? '4rem' : '0')};
  display: ${({ hideInput }) => (hideInput ? 'none' : 'block')};

  ${(props) =>
    !props.withDefaultInputArrows &&
    `
    input[type=number] {
      -moz-appearance:textfield;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  `}
  ${(props) => props.theme?.components?.MITextInput?.TextInputContainer}
`;

const placeholderStyle = (props) => `
  color: ${props.theme.text.color.readonly};
  font-size: ${props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '1.6rem' : '2.3rem'};
  letter-spacing: -0.028rem;
  ${
    props.theme?.components?.MITextInput?.placeholderStyle && props.theme.components.MITextInput.placeholderStyle(props)
  }
`;

const fontSize = (props) => {
  if (props.type === 'password') {
    if (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE) {
      return '2.2rem';
    }

    return '2.6rem';
  } else if (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE) {
    return '1.6rem';
  }

  return '2.3rem';
};

const TextInput = styled.input.attrs<{
  inline?: TextInputSize;
  error?: string | null;
  viewOnly?: boolean;
  label?: string;
  maxLength?: number;
}>((props) => ({
  maxlength: props?.maxLength,
}))`
  width: 100%;
  height: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '3rem' : '3.8rem')};
  background-color: transparent;
  padding: 0 0 ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '0' : '0.5rem')} 0;
  border: none;
  border-bottom: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '0.1rem solid' : '0.2rem solid')};
  border-color: ${(props) => (props.error ? props.theme.text.color.error : props.theme.text.color.light)};
  outline: none;
  line-height: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '3rem' : '3.8rem')};
  color: ${(props) => props.theme.text.color.main};
  border-radius: 0;

  ${(props) =>
    props.viewOnly &&
    css`
      pointer-events: none;
      color: ${(props) => props.theme.text.color.subtitle};
    `};

  font-size: ${(props) => fontSize(props)};

  &:-webkit-autofill::first-line {
    font-size: ${(props) => fontSize(props)};
    line-height: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '3rem' : '3.8rem')};
    font-family: 'Avenir Next forINTUIT';
  }

  &::-webkit-input-placeholder {
    ${(props) => placeholderStyle(props)}
  }
  &::-moz-placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &:-ms-input-placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &::placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.dark.opaque};
  }

  &:disabled {
    -webkit-text-fill-color: ${(props) => props.theme.text.color.readonly};
    color: ${(props) => props.theme.text.color.readonly};
    opacity: 1;
    border-color: ${(props) => props.theme.text.color.readonly};
  }
  ${(props) => props.theme?.components?.MITextInput?.TextInput};
`;

const InputWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  ${(props) => props.theme?.components?.MITextInput?.InputWrapper}
`;

const Suffix = styled.div<{ inline?: TextInputSize }>`
  position: absolute;
  bottom: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '0.5rem' : '1.3rem')};
  max-height: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '1.7rem' : '2.2rem')};
  max-width: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '1.7rem' : '2.2rem')};
  overflow: hidden;
  right: 0;
  cursor: pointer;
  font-size: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '1.7rem' : '2.2rem')};

  img {
    height: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '1.7rem' : '2.2rem')};
    width: ${(props) => (props.inline === CONSTS.TEXT_INPUT_SIZE.INLINE ? '1.7rem' : '2.2rem')};
  }
  ${(props) => props.theme?.components?.MITextInput?.EyeIcon}
`;

export { TextInput, Container, InputWrapper };
