import React, { useEffect } from 'react';
import { Flex } from '@melio/billpay-design-system';
import { UnilateralPaymentState } from '../hoc/unilateral-payment-hooks';
import { PaymentInfo } from '../components/PaymentInfo';
import { VendorFlowLayout, VendorFlowContentCard, Footer } from '../../components/VendorFlowLayout';
import { Divider } from '../components/Divider';
import { ContentTitle } from '../components/ContentTitle';
import { BankAccountDeliveryMethodCard } from '../components/BankAccountDeliveryMethodCard';
import { PaperCheckDeliveryMethodCard } from '../components/PaperCheckDeliveryMethodCard';
import { VirtualCardDeliveryMethodCard } from '../components/VirtualCardDeliveryMethodCard';
import { vendorUnilateralAnalytics } from '../utils/analytics';

type Props = {
  state: UnilateralPaymentState;
} & { token: string };

const VendorSelectDeliveryMethodPage = ({ state, token }: Props) => {
  const isVirtualCardAllowed = state?.payment?.isEligibleToVirtualCard;

  const { payment, organization } = state;

  useEffect(() => {
    vendorUnilateralAnalytics.trackAddDeliveryMethod({
      deliveryMethodsType: isVirtualCardAllowed ? 'ach_check_virtual-card' : 'ach_check',
      isEligibleToVirtualCard: !!isVirtualCardAllowed,
      paymentId: payment?.id,
      vendorId: payment?.vendorId,
      vendorEmail: payment?.vendor?.contactEmail,
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <PaymentInfo payment={payment} companyName={organization?.companyName} />
        <Divider />
        <ContentTitle label="vendor.unilateral.methodTitle" />
        <Flex direction="column" gap="1rem" mt="ds.xl" data-testid="method-list">
          {isVirtualCardAllowed && <VirtualCardDeliveryMethodCard token={token} payment={payment} />}
          <BankAccountDeliveryMethodCard token={token} payment={payment} />
          <PaperCheckDeliveryMethodCard token={token} payment={payment} />
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};

export { VendorSelectDeliveryMethodPage };
