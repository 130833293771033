import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import analytics from 'src/services/analytics';
import { getIsFundingSourceVerifying } from 'src/redux/user/selectors';
import { verifyFundingSourceAction } from 'src/redux/user/actions';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import api from 'src/services/api/financialAccounts';
import { CONSTS } from 'src/utils/consts';
import { microDepositsEventActions } from 'src/pages/funding-sources/consts';
import {
  dismiss,
  MicroDepositActions,
  MicroDepositProps,
  MicroDepositState,
  onChange,
  parseErrorToState,
  RESOLUTIONS,
  SetState,
  State,
  validateInputs,
} from './microDepositsCommon';

export type FundingSourceMicroDepositProps = {
  fundingSourceId: number | null;
  token?: string;
} & MicroDepositProps;

const MicroDepositErrors = CONSTS.VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES;
const errorToResolutionMap: Record<string, any> = {
  [MicroDepositErrors.INVALID_TOKEN]: RESOLUTIONS.NOT_FOUND,
  [MicroDepositErrors.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS]: RESOLUTIONS.SUPPORT,
  [MicroDepositErrors.ERR_VERIFY_MICRO_DEPOSITS]: null,
  [MicroDepositErrors.NOT_FOUND]: RESOLUTIONS.NOT_FOUND,
};
const { ERR_VERIFY_MICRO_DEPOSITS } = MicroDepositErrors;

function callVerifyApi(fundingSourceId: number, state: State, dispatch: Dispatch<any>, token?: string) {
  const amount1Num = parseFloat(convertCurrencyToNumber(state.amount1));
  const amount2Num = parseFloat(convertCurrencyToNumber(state.amount2));

  return new Promise<void>((resolve, reject) => {
    dispatch(verifyFundingSourceAction(fundingSourceId, token, amount1Num, amount2Num, resolve, reject));
  });
}

async function verifyFundingSource(
  fundingSourceId: number,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>,
  token?: string
) {
  try {
    await callVerifyApi(fundingSourceId, state, dispatch, token);
    setState({
      ...state,
      resolution: RESOLUTIONS.SUCCESS,
    });

    return [true];
  } catch (error) {
    setState({
      ...state,
      ...parseErrorToState(error, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS),
    });

    return [false, error];
  }
}

async function onSubmit(
  eventPage: string,
  props: FundingSourceMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>
) {
  analytics.track(eventPage, microDepositsEventActions.FORM_SUBMIT, {
    fundingSourceId: props.fundingSourceId,
  });
  const [validationOk, validationErrors] = validateInputs(state, setState);

  if (validationOk && props.fundingSourceId) {
    const [success, errorMessage] = await verifyFundingSource(
      props.fundingSourceId,
      state,
      setState,
      dispatch,
      props.token
    );
    analytics.track(
      eventPage,
      success ? microDepositsEventActions.VERIFY_CONFIRMED_SUCCESS : microDepositsEventActions.VERIFY_CONFIRMED_FAILED,
      {
        errorMessage,
        fundingSourceId: props.fundingSourceId,
      }
    );
    if (success && props.onSuccess) {
      props.onSuccess();
    }

    const error = parseErrorToState(errorMessage, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);
    if (!success && error.resolution === RESOLUTIONS.SUPPORT && props.onBlocked) {
      props.onBlocked();
    }

    if (!success && error.resolution === RESOLUTIONS.NOT_FOUND && props.onInvalidToken) {
      props.onInvalidToken();
    }
  } else {
    analytics.track(eventPage, microDepositsEventActions.SUBMIT_VALIDATION_ERRORS, {
      ...(validationErrors && { validationErrors }),
      fundingSourceId: props.fundingSourceId,
    });
  }
}

export function createActions(
  eventPage: string,
  props: FundingSourceMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>
): MicroDepositActions {
  return {
    onChange: onChange(state, setState),
    dismiss: () => dismiss(setState),
    onSubmit: () => onSubmit(eventPage, props, state, setState, dispatch),
  };
}

async function checkAndUpdateVerificationStatus(
  props: FundingSourceMicroDepositProps,
  state: State,
  setState: SetState,
  isNewFlow: boolean
) {
  if (props.token) {
    setState({
      ...state,
      isLoading: true,
    });

    try {
      const verificationResult = await api.getMicroDepositsStatusWithToken({
        token: props.token,
        id: props.fundingSourceId,
      });

      setState({
        ...state,
        companyName: verificationResult?.company?.name,
        fundingSource: verificationResult?.fundingSource,
        isLoading: false,
      });
      if (verificationResult.status === 'VERIFIED' && props.onSuccess && !isNewFlow) {
        props.onSuccess();
      }
    } catch (e) {
      const parsedError = parseErrorToState(e, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);
      setState({
        ...state,
        ...parsedError,
        isLoading: false,
      });

      if (parsedError.resolution === RESOLUTIONS.SUPPORT && props.onBlocked) {
        props.onBlocked();
      }

      if (parsedError.resolution === RESOLUTIONS.NOT_FOUND && props.onInvalidToken) {
        props.onInvalidToken();
      }
    }
  }
}

export function useFundingSourceMicroDepositState(
  eventPage: string,
  props: FundingSourceMicroDepositProps,
  isNewFlow = false
): [MicroDepositState, MicroDepositActions] {
  const [state, setState]: [State, SetState] = useState<State>({
    amount1: '',
    amount2: '',
    validationErrors: {},
    isLoading: true,
  });
  const isFundingSourceVerifying = useSelector(getIsFundingSourceVerifying);

  const dispatch = useDispatch();

  const enhancedState: MicroDepositState = {
    isFundingSourceVerifying,
    ...state,
  };

  const actions = createActions(eventPage, props, state, setState, dispatch);

  useEffect(() => {
    checkAndUpdateVerificationStatus(props, state, setState, isNewFlow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [enhancedState, actions];
}
