import React from 'react';
import { Row } from 'react-table';
import { Table } from '@melio/billpay-design-system';
import { useBoolean } from 'src/core/ds/hooks';
import { DATA_TABLE_RENDER } from 'src/billpay/qbdt/pages/batch-pay/table/consts';

function DataTableRow<T extends Record<string, unknown>>({ row }: { row: Row<T> }) {
  const [hovered, setHovered] = useBoolean();

  return (
    <Table.Row
      data-testid={`batch-payments-table-row-${row.id}`}
      data-is-selected={row.isSelected}
      onMouseEnter={setHovered.on}
      onMouseLeave={setHovered.off}
      onBlur={setHovered.off}
      {...row.getRowProps()}
      className={row.depth === 1 ? 'isChildRow' : ''}
    >
      {row.cells.map((cell) => (
        <Table.Cell
          {...cell.getCellProps()}
          isNumeric={cell.column.isNumeric}
          style={{
            ...cell.getCellProps().style,
            minWidth: cell.column.minWidth,
            maxWidth: cell.column.maxWidth,
            flex: cell.column.minWidth,
          }}
          data-testid={`batchPayment-${cell.getCellProps().key}`}
        >
          {cell.render(DATA_TABLE_RENDER.Cell, {
            rowHovered: hovered,
          })}
        </Table.Cell>
      ))}
    </Table.Row>
  );
}

export default DataTableRow;
