import errorTracker from 'src/utils/error-tracking';
import MAPPINGS from './action-notification-mapping';
import { pushNotification } from './notificationService';
import { addFlattenedValues } from '../translation-service';
import { logger } from '../loggers';

function constructTextValues(mapping, action, intl) {
  const textValues = { error: '' };
  if (mapping.type === 'error') {
    const errorMessageId = `errors.${action.error.message}`;
    const errorMessage = intl.messages[errorMessageId];

    if (!errorMessage) {
      const missingNotificationTranslation = new Error(
        `Missing translation for error toast notification: ${errorMessageId}`
      );

      logger.error(`notificationMiddleware.constructTextValues(): ${missingNotificationTranslation.message}`);
      errorTracker.capture(missingNotificationTranslation, { action });
    }

    textValues.error = intl.formatMessage({ id: errorMessageId });
  } else {
    addFlattenedValues(textValues, 'payload', action.payload);
    if (action.meta) {
      addFlattenedValues(textValues, 'meta', action.meta);
    }
  }

  return textValues;
}

export function notificationMiddleware(intl) {
  return () => (next) => (action) => {
    const res = next(action);
    if (action.meta?.disableNotification) {
      return res;
    }

    let mapping = MAPPINGS[action.type];
    if (!mapping && action?.error?.code === 'ERR') {
      mapping = { type: 'error', msg: 'serverErrors.ERR' };
    }

    if (mapping && !action.error?.validationErrors) {
      pushNotification({
        type: mapping.type,
        msg: mapping.msg,
        icon: mapping.icon,
        textValues: constructTextValues(mapping, action, intl),
      });
    }

    return res;
  };
}
