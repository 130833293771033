import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Box, Link, Text } from '@melio/billpay-design-system';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { OrganizationType, PaymentType } from 'src/utils/types';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS, SINGLE_SELECT_FLAVOR } from 'src/utils/consts';
import { AddCardErrorModal } from 'src/billpay/qbdt/pages/funding-source/card/components/AddCardErrorModal';
import { WizardFormRow } from 'src/components/layout/WizardElements';
import CardFormInput from 'src/billpay/qbdt/pages/funding-source/card/components/CardFormInput';
import MISingleSelect from 'src/components/common/MISingleSelect';
import US_STATES from 'src/utils/us-states';
import {
  DesktopSubmitContainer,
  MobileSubmitContainer,
  PaymentInfo,
  UserSupport,
  SubmitHintText,
} from 'src/pages/vendor/shift-vendor-to-debit/components/AddDebitCardPage';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import MIButton from 'src/components/common/MIButton';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';
import { useCreateCardAccount } from 'src/pages/vendor/shift-vendor-to-debit/hooks';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { getCommonAnalyticsParams } from 'src/pages/vendor/shift-vendor-to-debit/analytics';
import { AddDebitCardHolderDetailsLocationState } from '../types';
import locations from '../locations';

type Props = {
  organization?: OrganizationType;
  payment?: PaymentType;
  token: string;
};

const initialIsFieldSubmittedState = {
  fullName: false,
  addressLine1: false,
  city: false,
  state: false,
  zipCode: false,
};

export const ConfirmCardHolderAddressPage = ({ organization, payment, token }: Props) => {
  const { config } = useSiteContext();
  const { privacyPolicy, termsOfService } = config.agreementLinks;
  const locationState = useLocation<AddDebitCardHolderDetailsLocationState>().state;
  const history = useHistory();
  const paymentId = payment?.id || '';

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'confirm-card-holder-address', {
      OriginDeliveryType: payment?.originalDeliveryMethod?.deliveryType,
    });
  }, []);

  const handleSuccessCardAccountCreated = () => {
    history.push(generatePath(locations.addDebitCardSuccessPage, { token }), {
      digits: locationState?.digits,
    });
  };

  const handleFailureCardAccountCreated = (errorCode?: string) => setErrorCode(errorCode);

  const { handleCreateCardAccount, isProcessing } = useCreateCardAccount({
    paymentId,
    onSuccess: handleSuccessCardAccountCreated,
    onFailure: handleFailureCardAccountCreated,
  });

  const [formState, setFormState] = useState({
    fullName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  });

  const [isFieldSubmittedState, setIsFieldSubmitted] = useState<Record<string, any>>(initialIsFieldSubmittedState);
  const [errorCode, setErrorCode] = useState<string | undefined>();

  const amount = payment?.amount || 0;
  const companyName = organization?.companyName || '';

  const getValidationErrors = () =>
    Object.entries(formState).reduce((acc, [key, value]) => {
      const isValueEmpty = !value?.trim();
      if (isValueEmpty && key in initialIsFieldSubmittedState) {
        acc[key] = true;
      }

      return acc;
    }, {});

  const handleSubmitCardHolderDetails = async () => {
    const validationErrors = getValidationErrors();
    if (Object.values(validationErrors).length) {
      setIsFieldSubmitted({ ...initialIsFieldSubmittedState, ...validationErrors });
      return;
    }

    analytics.track('debit-card', 'confirm-debit-card-payment', {
      ...getCommonAnalyticsParams(payment),
      deliveryType: 'debit-card',
      optInFlow: 'push-to-debit',
    });

    const { fullName, addressLine1, addressLine2, city, state, zipCode } = formState;
    const nameChunks = fullName.trim().split(' ');
    const lastName = nameChunks.pop() as string;
    const firstName = nameChunks.join(' ');
    const address = addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1;

    const { digits, cardBin, expiration, cardToken } = locationState;

    handleCreateCardAccount({
      token,
      card4digits: digits,
      cardBin,
      expiration,
      cardToken,
      city,
      firstName,
      lastName,
      line1: address,
      state,
      zipcode: zipCode,
    });
  };

  const handleCloseErrorModal = () => {
    setErrorCode(undefined);
  };

  const onChange = ({ value, id }: { value: string; id: string }) => {
    setFormState((prevState) => ({ ...prevState, [id]: value }));
    setIsFieldSubmitted((prevState) => ({ ...prevState, [id]: false }));
  };

  const { fullName, addressLine1, addressLine2, city, state, zipCode } = formState;

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack>
        <PaymentInfo amount={amount} companyName={companyName} />
        <Divider my="ds.2xl" />
        {errorCode && <AddCardErrorModal onClose={handleCloseErrorModal} errorCode={errorCode} />}
        <Text textStyle="ds.body1Semi" alignSelf="start" mt="0" mb="ds.lg">
          <MIFormattedText label="vendor.pushToDebit.addDebitCard.cardHolderAddress.title" />
        </Text>
        <Box className={FULL_STORY_MASK_RULE_CLASS} width="100%">
          <CardFormInput
            value={fullName}
            label="onboarding.fundingSources.card.fullName.label"
            required
            id="fullName"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.fullName.errorMessage"
            validationTest="string"
            returnVal={onChange}
            submitValidation={isFieldSubmittedState.fullName}
            wizardColumn={false}
            autoFocus
          />
          <CardFormInput
            value={addressLine1}
            label="onboarding.fundingSources.card.addressLine1.label"
            id="addressLine1"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.addressLine1.errorMessage"
            validationTest="string"
            wizardColumn={false}
            returnVal={onChange}
            required
            submitValidation={isFieldSubmittedState.addressLine1}
          />
          <CardFormInput
            value={addressLine2}
            label="onboarding.fundingSources.card.addressLine2.label"
            id="addressLine2"
            placeHolder=""
            validationTest="string"
            wizardColumn={false}
            returnVal={onChange}
            required={false}
            errorMessage=""
            submitValidation={false}
          />
          <WizardFormRow>
            <CardFormInput
              value={city}
              label="onboarding.fundingSources.card.city.label"
              id="city"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.city.errorMessage"
              validationTest="string"
              required
              returnVal={onChange}
              submitValidation={isFieldSubmittedState.city}
            />
            <MISingleSelect
              id="state"
              value={state}
              label="onboarding.fundingSources.card.state.label"
              options={US_STATES}
              onChange={onChange}
              placeholder=""
              errorMessage={isFieldSubmittedState.state ? 'onboarding.fundingSources.card.state.errorMessage' : ''}
              required
              flavor={SINGLE_SELECT_FLAVOR.DEFAULT}
            />
          </WizardFormRow>
          <CardFormInput
            value={zipCode}
            label="onboarding.fundingSources.card.zipcode.label"
            required
            id="zipCode"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.zipcode.errorMessage"
            validationTest="string"
            wizardColumn={false}
            returnVal={onChange}
            submitValidation={isFieldSubmittedState.zipCode}
          />
        </Box>
        <DesktopSubmitContainer>
          <MIButton
            label="vendor.pushToDebit.addDebitCard.cardHolderAddress.submit"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={handleSubmitCardHolderDetails}
            isProcessing={isProcessing}
            fullWidth
          />
          <SubmitHintText
            values={{
              companyName: organization?.companyName || '',
            }}
            label="vendor.pushToDebit.addDebitCard.cardHolderAddress.submitHint"
          />
        </DesktopSubmitContainer>
      </VendorFlowContentCard>
      <UserSupport />
      <Footer />
      <MobileSubmitContainer>
        <MIButton
          label="vendor.pushToDebit.addDebitCard.cardHolderAddress.submit"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={handleSubmitCardHolderDetails}
          isProcessing={isProcessing}
          fullWidth
        />
        <SubmitHintText
          values={{
            TermsOfServiceLink: (...chunks) => (
              <Link color="ds.gray.100" href={termsOfService} textDecoration="underline">
                {React.Children.toArray(chunks)}
              </Link>
            ),
            PrivacyPolicyLink: (...chunks) => (
              <Link color="ds.gray.100" href={privacyPolicy} textDecoration="underline">
                {React.Children.toArray(chunks)}
              </Link>
            ),
            companyName,
          }}
          label="vendor.pushToDebit.addDebitCard.submitHintMobile"
        />
      </MobileSubmitContainer>
    </VendorFlowLayout>
  );
};
