import React, { useMemo } from 'react';
import { whitePagesAddressKeys } from 'src/utils/address';
import { AddressType } from 'src/utils/types';
import { MICard } from 'src/components/common/MICard';
import styled from 'styled-components';
import { MIFieldOrEmpty } from 'src/utils/formatting';

type Props = {
  printName: string;
  address: AddressType;
};

const AddressRowCard = ({ printName, address }: Props) => {
  const addressFields = Object.values(whitePagesAddressKeys);
  const concatenatedAddress = useMemo(
    () =>
      addressFields
        .filter((field) => !!address[field])
        .map((field) => address[field])
        .join(', '),
    [address, addressFields]
  );

  return (
    <CardContainer>
      <MICard>
        <Container data-testid="invalid-manual-address">
          <AddressRow>
            <Name>
              <MIFieldOrEmpty value={printName} label="" />
            </Name>
          </AddressRow>
          <AddressRow>{concatenatedAddress}</AddressRow>
        </Container>
      </MICard>
    </CardContainer>
  );
};

export default AddressRowCard;

const CardContainer = styled.div`
  margin-top: 2rem;
  & > div {
    margin-bottom: 0;
  }
  ${(props) => props.theme?.components?.ManualAddressRow?.AddressRowCard}
`;

const Container = styled.div`
  padding: 2rem;
  ${(props) => props.theme?.components?.ManualAddressRow?.Container}
`;

const Name = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const AddressRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 37rem;
  flex-wrap: wrap;
  white-space: normal;
  font-size: ${(props) => props.theme.text.size.regular};
  margin: 1rem 0;
  cursor: default;

  ${(props) => props.theme?.components?.ManualAddressRow?.AddressRow}
`;
