import React, { useEffect } from 'react';
import { OrganizationType, PaymentType } from 'src/utils/types';
import { Box, Flex } from '@melio/billpay-design-system';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { getFormattedAddress } from 'src/utils/formatting';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { VendorReceive, PaymentInfo } from '../components/CheckDepositedPage';
import { useInvoiceNumberType } from '../../hooks/useInvoiceNumberType';

type Props = {
  organization: OrganizationType | undefined;
  payment: PaymentType | undefined;
};

export const CheckDepositedPage = ({ organization, payment }: Props) => {
  const amount = payment?.amount || 0;
  const companyName = organization?.companyName || '';
  const { deliveryMethod, deliveryEta } = payment || {};
  const { invoiceNumberType, invoiceNumber } = useInvoiceNumberType(payment);
  const address = deliveryMethod?.paperCheck ? getFormattedAddress(deliveryMethod.paperCheck) : '';

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'check-already-deposited', { OriginDeliveryType: 'check' });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" w="100%" gap="ds.2xl">
          <PaymentInfo
            invoiceNumberType={invoiceNumberType}
            amount={amount}
            companyName={companyName}
            invoiceNumber={invoiceNumber}
          />
          <Box h="ds.sm" bg="ds.gray.800" w="full" />
          <VendorReceive address={address} depositedDate={deliveryEta} />
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
