import React, { PropsWithChildren } from 'react';
import { VStack } from '@melio/billpay-design-system';

type Props = {
  align?: string;
};

export const ModalTextContent = ({ children, align = 'flex-start' }: PropsWithChildren<Props>) => (
  <VStack spacing="ds.md" align={align}>
    {children}
  </VStack>
);
