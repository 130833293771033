import React, { useEffect } from 'react';
import { OrganizationType, PaymentType } from 'src/utils/types';
import { Flex } from '@melio/billpay-design-system';
import { useLocation } from 'react-router-dom';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import {
  PaymentInfo,
  VendorReceiveInfo,
} from 'src/pages/vendor/shift-vendor-to-debit/components/AddDebitCardSuccessPage';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { getCommonAnalyticsParams } from 'src/pages/vendor/shift-vendor-to-debit/analytics';
import { usePreventGoBackBrowserButton } from 'src/hooks/usePreventGoBackBrowserButton';
import { useInvoiceNumberType } from '../../hooks/useInvoiceNumberType';

type Props = {
  organization?: OrganizationType;
  payment?: PaymentType;
};

export const AddDebitCardSuccessPage = ({ organization, payment }: Props) => {
  const locationState = useLocation<{ digits: string }>().state;
  const amount = payment?.amount || 0;
  const companyName = organization?.companyName || '';
  const digits = locationState?.digits || payment?.deliveryMethod?.cardAccount?.card4digits || '';
  const { invoiceNumber, invoiceNumberType } = useInvoiceNumberType(payment);

  usePreventGoBackBrowserButton();

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'add-delivery-method-success-page-loaded', {
      ...getCommonAnalyticsParams(payment),
      deliveryType: 'debit-card',
      OriginDeliveryType: payment?.originalDeliveryMethod?.deliveryType,
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" w="100%" gap="ds.2xl">
          <PaymentInfo
            amount={amount}
            companyName={companyName}
            invoiceNumber={invoiceNumber}
            invoiceNumberType={invoiceNumberType}
          />
          <VendorReceiveInfo digits={digits} />
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
