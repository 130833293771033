import { RecordOf } from 'immutable';
import { GlobalState } from '../types';
import { BillType, PaymentType, AccountType, OptionalDeliveryMethodsType } from '../../utils/types';

import { getFundingSources } from '../user/selectors';

export const getBill = (state: GlobalState): RecordOf<BillType> => state.payBillWizard.bill;
export const getCompanyName = (state: GlobalState): string => state.payBillWizard.bill.vendor.companyName;
export const getPayment = (state: GlobalState): RecordOf<PaymentType> => state.payBillWizard.payment;
export const getIsLoading = (state: GlobalState): boolean => state.payBillWizard.isLoading;
export const getUrlToBack = (state: GlobalState): string => state.payBillWizard.urlToBack;
export const getSelectedFundingSource = (state: GlobalState): RecordOf<AccountType> | null => {
  const fundingSources = getFundingSources(state);
  const payment = getPayment(state);
  return fundingSources.find((fs) => fs.id === payment.fundingSourceId) || null;
};
export const getDeliveryMethodType = (state: GlobalState): OptionalDeliveryMethodsType => {
  let deliveryType;
  const { deliveryMethodId } = state.payBillWizard.payment;
  if (deliveryMethodId) {
    ({ deliveryType } = state.payBillWizard.bill.getDeliveryMethodById(deliveryMethodId));
  }

  return deliveryType;
};
export const getErrorCode = (state: GlobalState): string | null => state.payBillWizard.errorCode;

export const getFee = (state: GlobalState) => state.payBillWizard.fee;

export const getRedirectUrl = (state: GlobalState) => state.payBillWizard.redirectUrl;
export const getExitUrl = (state: GlobalState) => state.payBillWizard.exitUrl;

export const getFaqPanel = (state: GlobalState): { isOpen: boolean; focusedQuestion?: number } =>
  state.payBillWizard.faqPanel;

export const getComplianceLimitations = (state: GlobalState) => state.payBillWizard.complianceLimitations;
