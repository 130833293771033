import { Badge } from '@melio/billpay-design-system';
import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { PaidBillDashboardStatusesEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';

type Props = {
  item: DashboardListItemType;
};

export const DashboardPaidStatusCell = ({ item }: Props) => {
  const { statusInDashboard } = item.metadata;

  if (statusInDashboard === PaidBillDashboardStatusesEnum.Deposited) {
    return (
      <Badge variant="primary">
        <MIFormattedText label="paymentDashboard.columns.paidStatus.deposited" />
      </Badge>
    );
  }

  return (
    <Badge variant="primaryOutlined">
      <MIFormattedText label="paymentDashboard.columns.paidStatus.sent" />
    </Badge>
  );
};
