export const microDepositsEventPage = 'VerifyMicroDepositBankAccount';
export const microDepositsEventAction = 'Viewed';

export const microDepositsEventStatuses = {
  VERIFY: 'verify',
  VERIFICATION_COMPLETED: 'verification-completed',
  ALREADY_VERIFIED: 'already-verified',
  ACCOUNT_BLOCKED: 'too-many-failed-attempts',
  CAN_NOT_VERIFY_YET: 'cant-verify-yet',
  LINK_EXPIRED: 'link-expired',
} as const;

export const microDepositsEventActions = {
  VERIFY_CONFIRMED_SUCCESS: 'verify-confirmed-success',
  VERIFY_CONFIRMED_FAILED: 'verify-confirmed-failed',
  FORM_SUBMIT: 'submit-continue',
  SUBMIT_VALIDATION_ERRORS: 'submit-validation-error',
} as const;
