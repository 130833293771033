import * as React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import MIInputLabel from 'src/components/common/MIInputLabel';
import MINotices from 'src/components/common/MINotices';
import { CONSTS } from 'src/utils/consts';
// eslint-disable-next-line import/no-unresolved
import { TextInputSize } from 'src/utils/types';
import analytics from 'src/services/analytics';

export type Option<T> = {
  id: T;
  label?: string;
  labelValues?: Record<string, any>;
  component?: React.ReactNode;
  icon?: string;
  'data-testid'?: string;
};

type Props<T> = {
  id: string;
  label?: string;
  required?: boolean;
  size?: TextInputSize;
  color?: string;
  group: string;
  options: Option<T>[];
  selected?: T | null;
  onSelect: (arg0: T) => void;
  disabled?: boolean;
  colDirection?: boolean;
  errorMessage?: string | null;
  className?: string;
};

const MIRadioGroup = function <T>({
  group,
  options,
  selected,
  onSelect,
  label,
  id,
  required,
  size,
  disabled,
  colDirection,
  color,
  errorMessage,
  className,
}: Props<T>) {
  return (
    <MIRadioGroupContainer className={className}>
      {label && (
        <MIRadioLabel>
          <MIInputLabel inputId={id} label={label} errorMessage={errorMessage} size={size} required={required} />
        </MIRadioLabel>
      )}
      <RadioGroupContainer size={size}>
        <OptionsContainer colDirection={colDirection}>
          {options.map((option, index) => {
            const handleSelect = () => {
              if (!disabled) {
                analytics.trackAction(`option-selected-${group}`, { option });
                onSelect(options[index].id);
              }
            };
            return (
              <OptionContainer
                disabled={disabled}
                key={`${group}-${option.id}`}
                id={option.id}
                onClick={handleSelect}
                component={option.component}
                colDirection={colDirection}
                color={color}
                data-testid={option['data-testid'] || `radio-button-${option.label || option.id}`}
                className={selected === option.id ? 'selected' : ''}
              >
                <RadioButtonCircle disabled={disabled} selected={selected === option.id} />
                <RadioButtonLabel disabled={disabled} selected={selected === option.id}>
                  <ManualAddressLabel>
                    <MIFormattedText label={option.label} values={option.labelValues || {}} />
                  </ManualAddressLabel>
                  {option.component && option.label && <ContentContainer>{option.component}</ContentContainer>}
                </RadioButtonLabel>
                <ManualAddressIcon>{option.icon && <Icon src={option.icon} />}</ManualAddressIcon>
                {option.component && !option.label && <>{option.component}</>}
              </OptionContainer>
            );
          })}
        </OptionsContainer>
        <MINotices size={size} errorMessage={errorMessage} />
      </RadioGroupContainer>
    </MIRadioGroupContainer>
  );
};

MIRadioGroup.defaultProps = {
  required: false,
  disabled: false,
  size: CONSTS.TEXT_INPUT_SIZE.INLINE,
  label: '',
  colDirection: false,
  color: 'main',
  errorMessage: null,
};

export default MIRadioGroup;

type RadioButtonCircleProps = {
  selected: boolean;
  disabled: boolean | null | undefined;
};

export const RadioButtonCircle = ({ selected, disabled }: RadioButtonCircleProps) => (
  <OuterCircle isSelected={selected} disabled={disabled} />
);

const Icon = styled.img`
  margin-left: 2rem;
  height: 2.5rem;
  min-width: 2.5rem;
`;

const MIRadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme?.components?.MIRadioGroup?.MIRadioGroupContainer}
`;

const MIRadioLabel = styled.div`
  margin-bottom: 0.8rem;
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.size === CONSTS.TEXT_INPUT_SIZE.WIZARD ? '4rem' : '0')};

  @media ${devices.mobile}, ${devices.phablet} {
    align-items: start;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    align-items: start;
  }
  ${(props) => props.theme?.components?.MIRadioGroup?.RadioGroupContainer}
`;

export const OptionsContainer = styled.div`
  display: flex;

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
  }

  ${(props) =>
    props.colDirection &&
    css`
      @media ${devices.desktop}, ${devices.tablet} {
        align-items: flex-start;
        flex-direction: column;
      }
    `}
  ${(props) => props.theme?.components?.MIRadioGroup?.OptionsContainer}
`;

const OptionContainer = styled.div`
  outline: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: ${(props) => (props.component ? 'flex-start' : 'center')};
  color: ${(props) => (props.color ? props.theme.text.color[props.color] : props.theme.text.color.main)};
  margin-bottom: 0;

  ${(props) =>
    props.colDirection &&
    css`
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    `}

  ${(props) =>
    props.colDirection &&
    props.component &&
    css`
      margin-bottom: 1rem;
    `}

  ${(props) =>
    !props.colDirection &&
    css`
      &:not(:last-child) {
        margin-right: 2.7rem;
      }

      @media ${devices.mobile}, ${devices.phablet} {
        &:last-child {
          margin-right: 2rem;
        }
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    `}
  ${(props) => props.theme?.components?.MIRadioGroup?.OptionContainer}
`;

const RadioButtonLabel = styled.div`
  ${(props) => props.theme.text.fontType.regular};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  ${(props) => props.theme?.components?.MIRadioGroup?.RadioButtonLabel}
`;

export const OuterCircle = styled.div<{ isSelected?: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  height: 2rem;
  min-width: 2rem;
  border: 0.2rem solid
    ${(props) => {
      const selectedColor = props.disabled ? props.theme.text.color.readonly : props.theme.colors.primary.opaque;
      const defaultColor = props.disabled ? props.theme.text.color.readonly : props.theme.colors.border.dark;
      return props.isSelected ? selectedColor : defaultColor;
    }};
  border-radius: 50%;
  background-color: ${(props) => {
    const selectedColor = props.disabled ? props.theme.text.color.readonly : props.theme.colors.primary.opaque;
    return props.isSelected ? selectedColor : props.theme.colors.white.opaque;
  }};
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
  box-shadow: inset 0 0 0 0.2rem ${(props) => props.theme.colors.white.opaque};
  ${(props) => props.theme?.components?.MIRadioGroup?.OuterCircle}
`;

const ContentContainer = styled.div`
  text-align: left;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: 2.5rem;
  margin: 1rem 0;
  font-weight: ${(props) => props.theme.text.weight.regular};
  cursor: default;
  ${(props) => props.theme?.components?.MIRadioGroup?.ContentContainer}
`;

const ManualAddressLabel = styled.div`
  display: block;

  ${(props) => props.theme?.components?.MIRadioGroup?.ManualAddressLabel}
`;

const ManualAddressIcon = styled.div`
  display: block;

  ${(props) => props.theme?.components?.MIRadioGroup?.ManualAddressIcon}
`;
