import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { PaymentFieldContent } from '../../field/PaymenFieldContent';
import { PaymentFieldInfo } from '../../field/PaymentFieldInfo';
import { PaymentFieldLabel } from '../../field/PaymentFieldLabel';
import { BillsInfoPopover } from './BillsInfoPopover';
import { BillInfo } from './types';

const BillsInfoField = ({ billsInfo }: { billsInfo: BillInfo[] }) => (
  <PaymentFieldInfo>
    <PaymentFieldLabel>
      <MIFormattedText label="bills.pay.confirm.invoiceNumberField" />
    </PaymentFieldLabel>
    <BillsInfoPopover billsInfo={billsInfo}>
      <Flex>
        <PaymentFieldContent
          data-testid="bill-invoice-number"
          color="ds.blue.200"
          fontWeight="ds.medium"
          cursor="pointer"
        >
          <MIFormattedText label="bills.view.numBills" values={{ number: billsInfo.length }} />
        </PaymentFieldContent>
      </Flex>
    </BillsInfoPopover>
  </PaymentFieldInfo>
);

export { BillsInfoField };
