import React, { useEffect, useState } from 'react';
import AreaLoader from 'src/components/common/AreaLoader';
import { PlaidConfigProps, usePlaid } from 'src/billpay/qbdt/pages/funding-source/bank/hooks/usePlaid';

type PlaidLinkProps = PlaidConfigProps & {
  showDialog?: boolean;
  isLoading?: boolean;
};

export const PlaidLink = ({ showDialog = false, isLoading = false, orgId, ...rest }: PlaidLinkProps) => {
  const [loading, setLoading] = useState(isLoading);

  const { ready, open, exit, error } = usePlaid({
    ...rest,
    orgId,
  });

  useEffect(() => {
    if (ready) {
      setLoading(false);

      if (showDialog) {
        open();
      } else {
        exit();
      }
    } else if (showDialog) {
      setLoading(true);
    }
  }, [ready, showDialog, exit, open]);

  useEffect(() => {
    exit?.();
  }, [error, exit]);

  return loading ? <AreaLoader /> : <></>;
};
