import { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import billsStore from 'src/modules/bills/bills-store';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { getOrgId, getCompanyInfo } from 'src/redux/user/selectors';
import { mapBatchBillItemToDataTableRowType } from 'src/billpay/qbdt/pages/batch-pay/utils';
import { MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import { BatchListResponseType } from 'src/modules/regular-batch-payments/types/api-types';
import { FeatureFlags } from 'src/utils/feature-flags';
import { BillType } from 'src/utils/types';
import {
  featureFlagValues as checkFlagValues,
  isCheckFeeOpenNoPromotions,
} from 'src/billpay/qbdt/hooks/useCheckFee/utils';
import { useModifyBillsFeesPromotion } from 'src/billpay/qbdt/pages/batch-pay/components/fees/hooks/useModifyBillsFeesPromotion';
import { BatchItemType } from 'src/modules/regular-batch-payments/types/store-types';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { BatchDateOption } from '../consts';

type UseBatchBillsListType = {
  billsList: MainTableRowType[];
  isBillListLoading: boolean;
  selectedBills: BillType[];
  loadBillsList: ({ billIds, fundingSourceId }) => Promise<Record<string, BatchListResponseType>>;
  getSingleBatchItem: (batchItemId: string) => MainTableRowType | undefined;
};

const useBatchBillsList = (): UseBatchBillsListType => {
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlags.CheckFees, checkFlagValues.CLOSED);

  const site = useSiteContext();

  const isCheckFeeNoPromotions = isCheckFeeOpenNoPromotions(checkFeeFlag);
  const actions = useStoreActions(regularBatchPaymentsStore);
  const listSelectors = regularBatchPaymentsStore.selectors?.list;
  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const { billIds, fundingSourceId, scheduledDate, dateOption, isBillsGroupedByVendor } = useSelector(
    regularBatchPaymentsStore.selectors.settings.settings
  );
  const { mapBillsListWithPromotion } = useModifyBillsFeesPromotion();

  const listParams = {
    orgId,
    billIds,
    fundingSourceId,
    scheduledDate: dateOption === BatchDateOption.ALL_AT_ONCE ? scheduledDate : null,
    isBillsGroupedByVendor,
    createOrigin: site.createOrigin.pay.payment,
  };

  const loadBatchBillsList = async (updatedParams = {}): Promise<Record<string, BatchListResponseType>> =>
    actions.list({ ...listParams, ...updatedParams });

  const isLoading = useSelector(listSelectors.status(listParams))?.loading;
  const batchItemsList: BatchItemType[] = useSelector((state) => listSelectors.value(state, listParams));
  const selectedBills = useSelector(billsStore.selectors.getByIds(billIds));
  const selectedBillsById = keyBy(selectedBills, 'id');

  const formattedBatchedObjectsList: MainTableRowType[] = useMemo(
    () =>
      batchItemsList.map(
        mapBatchBillItemToDataTableRowType({
          bills: selectedBillsById,
          companyInfo,
          isCheckFeeNoPromotions,
        })
      ),
    [batchItemsList, selectedBillsById, companyInfo]
  );

  const getSingleBatchItem = useCallback(
    (id: string) =>
      formattedBatchedObjectsList.find(
        (batchListItem) => batchListItem.id === id || batchListItem.subRows?.find((subRow) => subRow.id === id)
      ),
    [formattedBatchedObjectsList]
  );

  // will be removed after promotions
  const billsList = useMemo(() => mapBillsListWithPromotion(formattedBatchedObjectsList), [
    formattedBatchedObjectsList,
    mapBillsListWithPromotion,
  ]);

  return {
    billsList,
    isBillListLoading: isLoading,
    selectedBills,
    loadBillsList: loadBatchBillsList,
    getSingleBatchItem,
  };
};

export default useBatchBillsList;
