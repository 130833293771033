import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { AccountType } from 'src/utils/types';
import { AddAnotherRadioMethodOption } from './MethodElements';

export type MethodListItemType = {
  componentType: any;
  methods: any[];
  onVerifyClicked?: (id: number) => void;
  isSettings?: boolean;
  companyName?: string;
  debitFee?: React.ReactNode | string;
  onViewBenefitsClick?: (fundingSource?: AccountType) => void;
};

type Props = {
  value: any;
  list: MethodListItemType[];
  onAddMethod: (type: string) => void;
  onChange?: (value: any) => void;
  showAddAnotherOption?: boolean;
  isDisabled?: (method: any) => boolean;
  optionComponent: any;
  modifyFundingSourceActions?: (method: any) => Record<string, any>;
  isBillingFee?: boolean;
};

function MethodsList({
  value,
  list,
  onAddMethod,
  onChange,
  showAddAnotherOption,
  isDisabled = () => false,
  modifyFundingSourceActions,
  optionComponent,
  isBillingFee = false,
}: Props) {
  return (
    <>
      {list.map(({ componentType: ComponentType, methods, ...rest }, index) => (
        <GroupContainer key={index}>
          {isEmpty(methods) ? (
            <ComponentType
              onClick={() => {
                onAddMethod(ComponentType.methodType);
              }}
              optionComponent={optionComponent}
              isBillingFee={isBillingFee}
              {...rest}
            />
          ) : (
            <>
              {methods.map((method) => {
                const disabled = isDisabled(method) || ComponentType.isDisabled(method);
                return (
                  <ComponentType
                    key={method.id}
                    method={method}
                    onClick={(event: { stopPropagation: () => void }) => {
                      if (disabled) {
                        event.stopPropagation();
                      } else if (onChange) {
                        onChange(method);
                      }
                    }}
                    disabled={disabled}
                    isSelected={value === method}
                    optionComponent={optionComponent}
                    isBillingFee={isBillingFee}
                    modifyActions={modifyFundingSourceActions ? modifyFundingSourceActions(method) : null}
                    {...rest}
                  />
                );
              })}
              {showAddAnotherOption && ComponentType.showAddAnotherOption && (
                <AddAnotherRadioMethodOption
                  key={`add-another-${ComponentType.methodType}`}
                  data-testid={`radio-select-method-${ComponentType.methodType}`}
                  label={ComponentType.addAnotherMethodLabel}
                  onClick={() => {
                    onAddMethod(ComponentType.methodType);
                  }}
                />
              )}
            </>
          )}
        </GroupContainer>
      ))}
    </>
  );
}

const GroupContainer = styled.div`
  margin-bottom: 2rem;
  ${(props) => props.theme.components?.MethodsList?.GroupContainer}
`;

export default MethodsList;
