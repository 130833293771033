import React from 'react';
import { Flex } from '@melio/billpay-design-system';

import { footerWrapperStyle } from './styles';
import { DashboardFooterButton } from './components/DashboardFooterButton';
import { DashboardFooterTotalAmount } from './components/DashboardFooterTotalAmount';

type Props = {
  isOpen: boolean;
};

export function DashboardFooter({ isOpen }: Props) {
  return (
    <>
      <Flex data-testid="qbdt-dashboard-footer" display={isOpen ? 'flex' : 'none'} __css={footerWrapperStyle}>
        <DashboardFooterTotalAmount />
        <DashboardFooterButton />
      </Flex>
    </>
  );
}
