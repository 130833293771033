import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Flex, Text } from '@melio/billpay-design-system';
import { useDisclosure } from '@chakra-ui/react';
import { MIFormattedText } from 'src/utils/formatting';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { useApi } from 'src/hoc/useApi';
import { billingFeeApi } from 'src/services/api/smb-billing-fee';
import { AccountType } from 'src/utils/types';
import {
  getPaymentMethodIcon,
  getShortPaymentMethodLabel,
} from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/utils';
import FundingSourceIcon from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/FundingSourceIcon';
import useGetFundingSourceDisplayName from 'src/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import { useMicroDepositParams } from 'src/billpay/qbdt/pages/funding-source/bank/hooks/useMicroDepositParams';
import { VerifyMicroDeposits } from 'src/billpay/qbdt/components/VerifyMicroDeposits';
import { getDescriptionValues } from 'src/components/common/SelectMethods/types/AchPaymentMethod';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { DeleteBillingMethodModal } from 'src/billpay/qbdt/pages/settings/components/billing/components/DeleteBillingMethodModal';
import { pushNotification } from 'src/services/notifications/notificationService';
import { CONSTS } from 'src/utils/consts';
import { billingFeeAnalytics } from 'src/billpay/qbdt/pages/settings/components/billing/events';
import { ThreeDotsMenu } from 'src/components/common/tree-dots-menu/ThreeDotsMenu';
import { getFundingSourceType, isBankAccountBlocked, isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { billingFee } from '../../../components/billing/locations';

type Props = {
  fundingSource: AccountType;
  currentBillingMethodId: number;
  reFetch: () => void;
};

const MICRO_DEPOSIT_EVENT_PAGE = 'billing-verify-manual-account';

const getDescriptionLabel = (fundingSource: AccountType) => {
  if (isManualBankAccountNotVerified(fundingSource)) {
    if (isBankAccountBlocked(fundingSource)) {
      return 'settings.paymentMethods.microDepositsBlocked';
    }

    return 'settings.billing.billingFee.methodCard.nonVerified';
  }

  return 'settings.billing.billingFee.methodCard.verified';
};

export const SelectedBillingMethodCard = ({ fundingSource, currentBillingMethodId, reFetch }: Props) => {
  const orgId = useOrgId();
  const history = useHistory();
  const { icon, imageSrc } = getPaymentMethodIcon(fundingSource);
  const { nickname } = getShortPaymentMethodLabel(fundingSource);
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const fundingSourceDisplayName = getFundingSourceDisplayName({
    fundingSource,
  });

  const analyticsParams = {
    paymentMethodId: fundingSource.id,
    paymentMethodType: getFundingSourceType(fundingSource),
  };

  const [deleteBillingFee, , isDeletingBillingFee] = useApi<[{ orgId: number; billingFeeId: number }], void>(
    billingFeeApi.deleteBillingFee
  );

  const { onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal, isOpen: isDeleteModalOpen } = useDisclosure();

  const { verifyingId, setVerifyingId, state, actions } = useMicroDepositParams(MICRO_DEPOSIT_EVENT_PAGE);

  const handleOpenDeleteModal = () => {
    billingFeeAnalytics.removePaymentMethodModalOpen(analyticsParams);
    onOpenDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    billingFeeAnalytics.cancelRemovePaymentMethod(analyticsParams);
    onCloseDeleteModal();
  };

  const handDeleteBillingMethod = async () => {
    billingFeeAnalytics.removePaymentMethod(analyticsParams);
    await deleteBillingFee({ orgId, billingFeeId: currentBillingMethodId });
    onCloseDeleteModal();
    pushNotification({
      type: CONSTS.NOTIFICATION_VARIANT.INFO,
      msg: 'settings.billing.billingFee.deleteModal.toast',
    });
    reFetch();
  };

  const handleVerifyFinished = () => {
    setVerifyingId(null);
  };

  const handleVerifyClicked = (id: number) => {
    setVerifyingId(id);
  };

  const descriptionValues = getDescriptionValues(fundingSource, handleVerifyClicked);

  const descriptionLabel = getDescriptionLabel(fundingSource);

  const menuActions: ActionOption[] = [
    {
      label: 'settings.billing.billingFee.methodCard.actions.change',
      action: () => {
        billingFeeAnalytics.paymentMethodAction({
          ...analyticsParams,
          action: 'edit-method',
        });

        history.push(generatePath(billingFee.add, { orgId }));
      },
    },
    {
      label: 'settings.billing.billingFee.methodCard.actions.remove',
      action: () => {
        billingFeeAnalytics.paymentMethodAction({
          ...analyticsParams,
          action: 'remove-method',
        });
        handleOpenDeleteModal();
      },
    },
  ];

  return (
    <Flex
      w="full"
      boxSizing="border-box"
      p="ds.xl"
      border="1px"
      borderColor="ds.gray.400"
      borderStyle="solid"
      borderRadius="ds.lg"
      fontWeight="ds.medium"
    >
      <Flex justify="center" align="center">
        <FundingSourceIcon imageSrc={imageSrc} icon={icon} size="4rem" />
      </Flex>
      <Flex direction="column" ml="ds.xl" flexGrow={1}>
        <Text textStyle="ds.body2" color="ds.gray.100" m="0" mb="ds.sm" fontWeight="ds.medium">
          {nickname || fundingSourceDisplayName}
        </Text>
        <Text textStyle="ds.body2" color="ds.gray.300" m="0" fontWeight="ds.medium">
          <MIFormattedText label={descriptionLabel} values={descriptionValues} />
        </Text>
      </Flex>
      <ThreeDotsMenu actions={menuActions} />
      {isDeleteModalOpen && (
        <DeleteBillingMethodModal
          onClose={handleCloseDeleteModal}
          onSubmit={handDeleteBillingMethod}
          isLoading={isDeletingBillingFee}
        />
      )}
      {verifyingId && (
        <VerifyMicroDeposits
          {...state}
          {...actions}
          key={verifyingId}
          verifyingId={verifyingId}
          onVerifyFinished={handleVerifyFinished}
          eventPage={MICRO_DEPOSIT_EVENT_PAGE}
        />
      )}
    </Flex>
  );
};
