import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import { RecordOf } from 'immutable';
import { isBefore } from 'date-fns';

import { CONSTS } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { getUserPreferences } from 'src/redux/user/selectors';
import { isDebitCard } from 'src/utils/funding-sources';
import useUpdateUserPreference from 'src/modules/users/hooks/useUpdateUserPreferences';
import { getOrganizationFeeCatalog } from 'src/redux/organization/selectors';
import { getCompleteFeeByDeliveryType } from 'src/utils/fee';
import { getUTCDate } from 'src/utils/dates';

import { Modal as FeeModal } from './Modal';

export type ReturnType = {
  shouldDisplayModal: (fundingSource: RecordOf<AccountType>) => boolean;
  openModal: () => void;
  Modal: React.FC<DebitFeeModalProps>;
  fee: React.ReactNode | string | undefined;
};

type DebitFeeModalProps = {
  onNext?: () => void;
};

/**
 * Handle debit fee modal and fee status
 */
export const useDebitFee = (): ReturnType => {
  const [isOpen, setIsOpen] = useState<boolean>(false); // modal
  const [limitModalDate]: any = featureFlags.useFeature('qbdt-debit-fee-limit-date-modal');
  const [selectedFundingSource, setSelectedFundingSource] = useState<RecordOf<AccountType> | undefined>();
  const feeCatalog = useSelector(getOrganizationFeeCatalog);

  const fee = getCompleteFeeByDeliveryType(feeCatalog, CONSTS.CARD_TYPES.DEBIT);

  const userPreferences = useSelector(getUserPreferences);
  const { updateUserPreference } = useUpdateUserPreference();

  // getting user pref to see if this user already seen the modal
  const isFirstTimeUserModal = !userPreferences.qbExistingDebitCardFeeModal;

  const shouldDisplayModal = useCallback(
    (fundingSource: RecordOf<AccountType>): boolean => {
      const isDebit = isDebitCard(fundingSource);
      const limitDate = !!limitModalDate && new Date(limitModalDate); // utc already from ff
      const createdDate = getUTCDate(fundingSource.createdAt);
      const isBeforeLimitDate = !!createdDate && !!limitDate && isBefore(createdDate, limitDate);

      const shouldShowModal = isDebit && isFirstTimeUserModal && isBeforeLimitDate && !!fee;

      if (shouldShowModal) setSelectedFundingSource(fundingSource);

      return shouldShowModal;
    },
    [isFirstTimeUserModal, fee]
  );

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const updateUserModalVisibility = async () => {
    await updateUserPreference({
      key: 'qbExistingDebitCardFeeModal',
      value: true,
    });
  };

  const Modal = useCallback(
    ({ onNext }: DebitFeeModalProps) => {
      if (!isOpen) return null;

      return (
        <FeeModal
          onClose={closeModal}
          selectedFundingSource={selectedFundingSource}
          onNext={onNext}
          updateUserModalVisibility={updateUserModalVisibility}
        />
      );
    },
    [isOpen]
  );

  return {
    shouldDisplayModal,
    openModal,
    Modal,
    fee,
  };
};
