import React from 'react';
import { Image, Tooltip } from '@melio/billpay-design-system';
import { CSSObject } from '@chakra-ui/system';
import { MIFormattedText } from 'src/utils/formatting';
import warningIcon from 'src/images/icons/warning-icon.svg';
import infoIcon from 'src/images/icons/warning-info-icon.svg';

export enum CheckDelaysIconVariant {
  WARNING = 'warning',
  INFO = 'info',
}

type Props = {
  tooltipLabel?: string;
  sx?: CSSObject;
  variant?: CheckDelaysIconVariant;
};

export const CheckDelaysIcon = ({ tooltipLabel, sx, variant = CheckDelaysIconVariant.INFO }: Props) => {
  const image = variant === CheckDelaysIconVariant.WARNING ? warningIcon : infoIcon;

  return (
    <Tooltip
      placement="top"
      data-testid="check-delays-tooltip"
      isDisabled={!tooltipLabel}
      label={<MIFormattedText label={tooltipLabel} />}
    >
      <Image data-testid={`check-delays-icon-${variant}`} src={image} sx={sx} />
    </Tooltip>
  );
};
