import React from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { AccountType } from 'src/utils/types';
import profileStore from 'src/modules/profile/profile-store';
import { getModifyFundingSourceOptions } from 'src/utils/funding-sources';
import { CONSTS } from 'src/utils/consts';
import {
  getCardIcon,
  getCardImageUrl,
  isCardExpired,
  convertDateToMonthYearFormat,
  getExpirationDateHint,
} from 'src/utils/card';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick?: () => void;
  method: RecordOf<AccountType>;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  modifyActions: Record<string, any>;
  debitFee?: React.ReactNode | string;
  isBillingFee?: boolean;
};

const getBillingFeeLabel = (isBillingFee: boolean, method?: RecordOf<AccountType>) => {
  if (isBillingFee && method) {
    return 'billingFee.addMethod.options.debitCard';
  }

  if (isBillingFee) {
    return 'billingFee.addMethod.options.emptyMethod.debitCard';
  }

  return null;
};

function DebitPaymentMethod({
  onClick,
  method,
  isSelected,
  optionComponent: OptionComponent,
  disabled,
  modifyActions,
  debitFee,
  isBillingFee = false,
}: Props) {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const billingFeeLabel = getBillingFeeLabel(isBillingFee);

  if (!method) {
    const description = (() => {
      if (debitFee) {
        return 'onboarding.fundingSources.options.debit.descriptionFee';
      }

      return '';
    })();

    const descriptionValues = debitFee ? { fee: debitFee } : {};

    return (
      <OptionComponent
        id="debit"
        label="onboarding.fundingSources.options.debit.label"
        description={billingFeeLabel || description}
        descriptionValues={descriptionValues}
        icon="icon-debit-card-icon"
        onClick={onClick}
        isEmptyList
      />
    );
  }

  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  const isDebitCardExpired = isCardExpired(method.cardAccount);
  const actionOptions = getModifyFundingSourceOptions(
    permissions,
    modifyActions,
    disabled,
    method.origin,
    isDebitCardExpired
  );

  const description = (() => {
    if (isDebitCardExpired) return 'bills.pay.fundingSource.expiredDescription';

    if (debitFee) return 'onboarding.fundingSources.options.debit.descriptionFee';

    return '';
  })();

  const descriptionValues = debitFee ? { fee: debitFee } : {};

  return (
    <OptionComponent
      id={method.id}
      isSelected={isSelected}
      isExpired={isDebitCardExpired}
      disabled={disabled}
      onTopDescription={method.nickname}
      icon={icon}
      imageSrc={imageSrc}
      label="bills.pay.fundingSource.debitLabel"
      labelValues={{
        ...method.getNameParts(),
      }}
      hint={getExpirationDateHint(method.cardAccount)}
      hintValues={{
        expirationDate: convertDateToMonthYearFormat(method.cardAccount.expiration),
      }}
      description={billingFeeLabel || description}
      descriptionValues={descriptionValues}
      onClick={onClick}
      actionOptions={actionOptions}
    />
  );
}

function DebitPaymentSelectedMethod({ method }: { method: RecordOf<AccountType> }) {
  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  return (
    <SelectedMethod
      icon={icon}
      imageSrc={imageSrc}
      title="bills.form.paymentActivity.scheduledBill.scheduleMethodDebitCard"
      label="bills.pay.fundingSource.debitLabel"
      labelValues={{
        ...method.getNameParts(),
      }}
    />
  );
}

DebitPaymentMethod.methodType = CONSTS.CARD_TYPES.DEBIT;
DebitPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherDebitCard';
DebitPaymentMethod.SelectedMethod = DebitPaymentSelectedMethod;
DebitPaymentMethod.isDisabled = (method) => isCardExpired(method.cardAccount);
DebitPaymentMethod.showAddAnotherOption = true;

export default DebitPaymentMethod;
