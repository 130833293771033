import { useMemo } from 'react';
import { useDashboardHighlightedItems } from '../../../hooks/useDashboardHighlightedItems';
import { DashboardListItemType } from '../../../types';
import { useAutoScrollToItem } from './useAutoScrollToItem';

export const useScrollToHighlightedItem = (items: DashboardListItemType[]) => {
  const { highlightedItemIds } = useDashboardHighlightedItems();

  const topMostHighlightedItemId = useMemo(
    () => (highlightedItemIds.length > 0 ? items.find((item) => highlightedItemIds.includes(item.id))?.id : undefined),
    [highlightedItemIds, items]
  );

  useAutoScrollToItem({
    elementId: topMostHighlightedItemId && `data-table-row-${topMostHighlightedItemId}`,
  });
};
