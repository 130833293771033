import React from 'react';
import { chakra } from 'src/theme/ds';
import MIMoney from 'src/components/common/MIMoney';
import { PaymentInfoSection } from '../PaymentInfoSection';

type Props = {
  totalAmount: number;
};

export const TotalAmountSection = ({ totalAmount }: Props) => (
  <PaymentInfoSection label="bills.pay.confirm.total" spacing="0.6rem">
    <StyledMIMoney size="large" amount={totalAmount} />
  </PaymentInfoSection>
);

const StyledMIMoney = chakra(MIMoney, {
  baseStyle: {
    lineHeight: '3rem',
    fontWeight: '400',
  },
});
