import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { InlineTextInputField } from 'src/ui/form/InlineTextInputField';

const EditVendorForm = ({ vendorMV }) => (
  <>
    <Box w="full">
      <InlineTextInputField label="paymentDashboard.editVendor.name" model={vendorMV?.companyName} required disabled />
    </Box>
    <Box w="full">
      <InlineTextInputField label="paymentDashboard.editVendor.email" model={vendorMV?.contactEmail} />
    </Box>
  </>
);

export { EditVendorForm };
