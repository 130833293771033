import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Tooltip } from '@melio/billpay-design-system';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';

type Props = {
  item: DashboardListItemType;
};

export const DashboardDeliveryMethodCell = ({ item }: Props) => {
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(item.deliveryMethodId));

  const deliveryType = deliveryMethod?.deliveryType;
  if (deliveryType === DELIVERY_TYPE.VIRTUAL) {
    return (
      <Box as="span" color="ds.gray.300">
        <MIFormattedText label="paymentDashboard.columns.deliveryMethod.virtual" />
      </Box>
    );
  }

  if (deliveryType === DELIVERY_TYPE.ACH) {
    return <MIFormattedText label="paymentDashboard.columns.deliveryMethod.ach" />;
  }

  if (deliveryType === DELIVERY_TYPE.CHECK) {
    return <MIFormattedText label="paymentDashboard.columns.deliveryMethod.check" />;
  }

  if (deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
    return <MIFormattedText label="paymentDashboard.columns.deliveryMethod.virtualCard" />;
  }

  if (deliveryType === DELIVERY_TYPE.CARD) {
    return (
      <Tooltip
        placement="top"
        label={<MIFormattedText label="paymentDashboard.columns.deliveryMethod.debitCardTooltip" />}
        shouldWrapChildren
      >
        <MIFormattedText label="paymentDashboard.columns.deliveryMethod.debitCard" />
      </Tooltip>
    );
  }

  return null;
};
