import React from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/modules/bills/bills-store';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardTableDate } from '../components/DashboardTableDate';

type Props = {
  item: DashboardListItemType;
};

export const DashboardDueDateCell = ({ item }: Props) => {
  // TODO: dashboard - fix in terms of https://linear.app/meliopayments/issue/QBDT-506/web-adapt-table-for-bulk-payments
  const billId = item.billIds[0];
  const bill = useSelector(billsStore.selectors.byId(billId));
  return <DashboardTableDate date={bill?.dueDate} />;
};
