import React from 'react';
import { Box } from '@melio/billpay-design-system';

export enum PaymentInfoDividerSizeEnum {
  Md = 'md',
  Lg = 'lg',
}

type Props = {
  size?: PaymentInfoDividerSizeEnum;
};

export const PaymentInfoSectionDivider = ({ size = PaymentInfoDividerSizeEnum.Md }: Props) => {
  if (size === PaymentInfoDividerSizeEnum.Md) {
    return <Box width="full" height="px" backgroundColor="ds.gray.800" />;
  }

  return <Box height="1" width="calc(100% + 4rem)" transform="auto" backgroundColor="ds.gray.800" translateX="-2rem" />;
};
