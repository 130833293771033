import isNull from 'lodash/isNull';
import { isBefore, min } from 'date-fns';
import { CONSTS } from 'src/utils/consts';
import isEmpty from 'lodash/isEmpty';
import { getDeliveryOption } from 'src/utils/delivery-methods';
import { isAMEXCard } from 'src/utils/card';
import { BatchDateOption } from 'src/billpay/qbdt/pages/batch-pay/consts';
import { uuid } from 'src/utils/uuid';
import { batchPaymentsListName, name } from './consts';
import fundingSourcesStore from '../funding-sources/funding-sources-store';
import { GroupedBatchListItemResponseType, BatchBillResponseType } from './types/api-types';
import { BatchBillType, BatchItemType, FailedPaymentType } from './types/store-types';

export const getBatchItemByBillIds = (state, billIds: string[]): BatchItemType | undefined => {
  const batchItems = getCurrentBatchItems(state);

  return batchItems.find((batchItem) => batchItem.payment.bills.some((bill) => billIds.includes(bill.id)));
};

export const getCurrentBatchItems = (state): BatchItemType[] => {
  const currentBatchItemsIds: string[] = state.lists[batchPaymentsListName]?.order || [];
  const batchItems: BatchItemType[] = currentBatchItemsIds.map((batchItemId) => state.byId[batchItemId]);

  return batchItems;
};

export const getSubRowsFromBatchItem = (batchItem: BatchItemType): BatchBillType[] =>
  batchItem.payment?.bills && batchItem.payment.bills.length > 1 ? batchItem.payment.bills : [];

const getDefaultMemoFromBills = (bills: BatchBillResponseType[]) => {
  const texts: string[] = [];

  if (bills.length > 1) {
    texts.push('Multiple bills');
  } else if (bills?.[0]?.invoiceNumber) {
    texts.push(`Invoice no. #${bills[0].invoiceNumber}`);
  }

  return isEmpty(texts) ? '' : texts.join(' | ');
};

function getScheduledDateFromSettings(settings, dueDate) {
  if (
    settings.dateOption === BatchDateOption.ALL_AT_ONCE ||
    isBefore(new Date(dueDate), new Date(settings.scheduledDate))
  ) {
    return settings.scheduledDate;
  }

  return dueDate;
}

export const mapGroupedPayloadToBatchBill = (item: GroupedBatchListItemResponseType, settings): BatchItemType => {
  const firstBill = item.payment.bills[0];
  const minDueDate = min(item.payment.bills.map((bill) => new Date(bill.dueDate)));

  return {
    id: item.id,
    fee: item.fee,
    // TODO: remove the usages, we now have multiple bills
    dueDate: minDueDate,
    minScheduledDate: item.minScheduledDate || settings.minScheduledDate,
    deliveryOptions: item.deliveryOptions,
    loadingDates: false,
    isScheduledDateSelectedByUser: false,
    payment: {
      // TODO: remove billId and bill and use only bills.
      billId: firstBill.id,
      bill: firstBill,
      bills: item.payment.bills,
      vendor: { ...item.payment.vendor, mccCode: item.mccCode },
      organizationId: item.payment.organizationId,
      amount: item.payment.amount,
      vendorId: item.payment.vendorId,
      currency: item.payment.currency,
      deliveryPreference: item.payment.deliveryPreference,
      status: item.payment.status,
      fundingSourceId: settings.fundingSourceId,
      fundingSource: settings.fundingSource,
      deliveryMethod: item.payment.deliveryMethod,
      deliveryMethodId: item.payment.deliveryMethodId,
      scheduledDate: item.payment.scheduledDate || getScheduledDateFromSettings(settings, minDueDate),
      deliveryEta: item.payment.deliveryEta,
      maxDeliveryEta: item.payment.maxDeliveryEta,
      createOrigin: item.payment.createOrigin,
      ...(item.payment.deliveryMethod?.deliveryType !== CONSTS.DELIVERY_TYPE.RPPS && {
        note: getDefaultMemoFromBills(item.payment.bills),
      }),
    },
    payBillFlowUUID: uuid(),
  };
};

export const mapFailedPaymentsData = (item: BatchItemType, errorCode): FailedPaymentType => ({
  errorCode,
  amount: item.payment?.amount,
  vendorName: item.payment?.vendor?.companyName,
  bills: item.payment.bills,
});

export const updatePayment = (state, action) => {
  const { deliveryOptions } = action.payload;
  const {
    batchItemId,
    amount,
    fundingSourceId,
    deliveryMethodId,
    deliveryPreference,
    deliveryMethod,
  } = action.meta.identifier;
  const oldBillData = state.byId[batchItemId];
  const selectedDeliveryMethod = deliveryMethod || oldBillData.payment.deliveryMethod;
  const selectedDeliveryPreference = isNull(deliveryPreference)
    ? deliveryPreference
    : oldBillData.payment.deliveryPreference;
  const selectedDeliveryOption = getDeliveryOption(
    selectedDeliveryPreference || selectedDeliveryMethod?.deliveryType,
    deliveryOptions
  ) as Record<string, any>;

  state.byId[batchItemId] = {
    ...oldBillData,
    deliveryOptions,
    fee: selectedDeliveryOption.fee,
    minScheduledDate: selectedDeliveryOption.minScheduledDate,
    payment: {
      ...oldBillData.payment,
      amount,
      fundingSourceId,
      deliveryMethodId,
      deliveryMethod: selectedDeliveryMethod,
      deliveryPreference: selectedDeliveryPreference,
      scheduledDate: selectedDeliveryOption?.scheduledDate,
      deliveryEta: selectedDeliveryOption?.deliveryDate,
      maxDeliveryEta: selectedDeliveryOption?.maxDeliveryDate,
    },
  };
};

export const updatePaymentFundingSource = (state, action) => {
  const { batchItemId, fundingSourceId } = action.payload;
  const oldBillData = state.byId[batchItemId];

  state.byId[batchItemId] = {
    ...oldBillData,
    payment: {
      ...oldBillData.payment,
      fundingSourceId,
    },
  };
};

export const updatePaymentScheduledDate = (state, action) => {
  const { batchItemId, deductionDate } = action.payload;
  const oldBillData = state.byId[batchItemId];

  state.byId[batchItemId] = {
    ...oldBillData,
    payment: {
      ...oldBillData.payment,
      scheduledDate: deductionDate,
    },
  };
};

export const isPaymentReady = (state, billId) => {
  const payment = state[name].byId[billId]?.payment;

  if (!payment) return false;

  const { fundingSourceId, deliveryMethodId, deliveryMethod, vendor } = payment;
  const fundingSource = fundingSourcesStore.selectors.byId(fundingSourceId)(state);

  if (fundingSource?.isVerified === false) {
    return deliveryMethodId && deliveryMethod?.deliveryType !== 'virtual';
  }

  if (isAMEXCard(fundingSource)) {
    return deliveryMethodId && vendor?.mccCode;
  }

  return deliveryMethodId && fundingSourceId;
};
