import React, { memo } from 'react';
import { CellProps } from 'react-table';
import { Flex } from '@melio/billpay-design-system';
import { ReactComponent as ChevronDownIcon } from 'src/images/regular-batch-payments/chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from 'src/images/regular-batch-payments/chevron-right.svg';
import { DataTableRowType } from '../types';
import { useExpandRow } from '../hooks/useExpandRow';

type ExpanderCellType = {
  row: DataTableRowType;
};

type Props = CellProps<ExpanderCellType> | { cell: { row } };

export const ExpanderCell = memo(({ cell: { row } }: Props) => {
  const { toggleRowExpand } = useExpandRow({
    row,
    onClick: row.getToggleRowExpandedProps()?.onClick,
  });

  const dataTestId = `bulk-row-expander-icon_${row.id}_${row.isExpanded ? 'expanded' : 'collapsed'}`;

  return (
    row.canExpand && (
      <Flex pl="ds.md" onClick={toggleRowExpand} data-testid={dataTestId}>
        {row.isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
      </Flex>
    )
  );
});
