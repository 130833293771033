import * as React from 'react';
import styled from 'styled-components';
import cardIcons from 'src/images/icons/card_icons.svg';
import { devices } from 'src/theme/AppDevices';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  showIconCC: boolean;
};

const QBOMISecurityDetails = ({ showIconCC }: Props) => (
  <SecurityDetails>
    <LockContainer>
      <LockImage>
        <i className="icon-lock-icon" />
      </LockImage>
      <LockText>
        <MIFormattedText label="guests.register.securityText" />
      </LockText>
    </LockContainer>
    {showIconCC && <CardIcons src={cardIcons} alt="Card icons" />}
  </SecurityDetails>
);

export default QBOMISecurityDetails;

const SecurityDetails = styled.div`
  margin: 0 auto 4rem auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const CardIcons = styled.img`
  margin-top: 2rem;
  height: 2.4rem;
  @media ${devices.mobile} {
    height: 1.8rem;
  }
`;
const LockContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LockImage = styled.div`
  font-size: 2.4rem;
  color: rgba(113, 113, 115, 1);
  margin-right: 1.5rem;
  align-self: start;
`;

const LockText = styled.div`
  text-align: left;
  align: justify;
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.hint};
`;
