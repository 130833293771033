export enum DashboardTabStateEnum {
  Loading = 'loading',
  Empty = 'empty',
  NoSearchResults = 'noSearchResults',
  NotEmpty = 'notEmpty',
}

type UseDashboardTabStateParams = {
  totalCount: number;
  isLoading: boolean;
  search?: string;
};

export const useDashboardTabState = ({ totalCount, isLoading, search }: UseDashboardTabStateParams) => {
  if (isLoading) {
    return DashboardTabStateEnum.Loading;
  }

  if (totalCount === 0 && search) {
    return DashboardTabStateEnum.NoSearchResults;
  }

  if (totalCount === 0) {
    return DashboardTabStateEnum.Empty;
  }

  return DashboardTabStateEnum.NotEmpty;
};
