import { createContext, useContext } from 'react';
import { BillType, PaymentType } from 'src/utils/types';

type Nullable<T> = { [K in keyof T]: T[K] | null };

type ViewPaymentContextValue = {
  bill: BillType;
  payment: PaymentType;
  eventPage: string;
  onActionLoadStart: () => void;
  onActionLoadEnd: () => void;
};

const defaultContextValue: Nullable<ViewPaymentContextValue> = {
  bill: null,
  payment: null,
  eventPage: 'qbdt-single-bill',
  onActionLoadEnd: null,
  onActionLoadStart: null,
};

export const ViewPaymentContext = createContext<Nullable<ViewPaymentContextValue>>(defaultContextValue);

export const ViewPaymentProvider = ViewPaymentContext.Provider;

export const useViewPaymentContext = () => {
  const contextValue = useContext(ViewPaymentContext);

  return contextValue as ViewPaymentContextValue;
};
