import forEach from 'lodash/forEach';
import { createReducer } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { clearStateReducer } from 'src/helpers/redux/clearStateReducer';
import { UserContextType, UserPreferencesType, CompanyType } from 'src/utils/types';
import { updateUserPreferenceAction } from 'src/redux/user/actions';
import { SET_PROFILE, UPDATE_USER_PREFERENCE_SUCCESS } from 'src/redux/user/actionTypes';
import { composeReducers } from 'src/helpers/redux/composeReducers';
import { CONSTS } from 'src/utils/consts';
import permissions, { Permissions as PermissionsType } from 'src/utils/permissions';
import { composeSagas } from 'src/helpers/redux/composeSlice';
import usersStore from '../users/users-store';

const initialState = {
  currentUserId: null,
  changingPassword: false,
  userPreferences: {},
  currentOrganizationId: null,
};

const profileReducer = createReducer(initialState, {
  [SET_PROFILE]: (state, action) => {
    state.currentUserId = action.profile.id;
    state.currentOrganizationId = action.profile.orgId;
    state.userPreferences = action.profile.toJS().userPreferences;
  },
  [UPDATE_USER_PREFERENCE_SUCCESS]: (state, action) => {
    state.userPreferences[action.id] = action.value;
  },
});

const saga = composeSagas([]);
const reducer = composeReducers(initialState, profileReducer, clearStateReducer(initialState));

const profileStore = {
  reducer,
  saga,
  selectors: {
    profile(state: any) {
      return state.users.byId[state.profile.currentUserId];
    },
    preferences(state: any) {
      return state.profile.userPreferences;
    },
    userLoading(state: any) {
      return state.users.meta[state.profile.currentUserId]?.loading;
    },
    changingPassword(state: any) {
      return state.profile.changingPassword;
    },
    getCurrentOrgId(state: any) {
      return state.profile.currentOrganizationId;
    },
    getCurrentUserId(state: any) {
      return state.profile.currentUserId;
    },
    getCurrentOrg(state: any) {
      return state.organizations.byId[state.profile.currentOrganizationId];
    },
    getOrganizations(state: any) {
      const currentUser = profileStore.selectors.profile(state);
      return sortBy(currentUser?.userOrganizations || [], 'createdAt').map<any>(
        (userOrg) => state.organizations.byId[userOrg.organizationId]
      );
    },
    getFirstAccountingFirm(state: any) {
      const organizations = profileStore.selectors.getOrganizations(state);
      return organizations.find((org) => org.companyType === CompanyType.ACCOUNTING_FIRM);
    },
    getUserOrganization(state: any) {
      const currentUser = profileStore.selectors.profile(state);
      return (
        currentUser &&
        currentUser.userOrganizations.find((o) => o.organizationId === state.profile.currentOrganizationId)
      );
    },
    getUserRole(state: any) {
      return profileStore.selectors.getUserOrganization(state)?.role || CONSTS.ROLE.OWNER;
    },
    isAdmin(state: any) {
      const role = profileStore.selectors.getUserRole(state);
      return role === CONSTS.ROLE.ADMIN || role === CONSTS.ROLE.OWNER;
    },
    isMemberOfAccountingFirm(state: any) {
      const organizations = profileStore.selectors.getOrganizations(state);
      return organizations.some((org) => org.companyType === CompanyType.ACCOUNTING_FIRM);
    },
    getPermissions(state: any): PermissionsType {
      return permissions[profileStore.selectors.getUserRole(state)];
    },
  },
  actions: {
    update(payload: Partial<UserContextType>) {
      return usersStore.actions.update(payload);
    },
  },
  validate: usersStore?.validate,
};

export function getProfileActions(dispatch: any) {
  return {
    async update(profile: UserContextType, changes: Partial<UserContextType>) {
      return dispatch(
        profileStore.actions.update({
          id: profile.id,
          ...changes,
        })
      );
    },
    async updatePreferences(preferences: UserPreferencesType, changes: Partial<UserPreferencesType>) {
      forEach(changes, (value, key) => {
        dispatch(updateUserPreferenceAction(key, value));
      });
    },
  };
}

export default profileStore;
