import React, { ReactElement } from 'react';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { AddressType, LegalAddressType } from 'src/utils/types';
import styled from 'styled-components';
import ErrorIcon from 'src/images/general/error-icon.svg';
import { MICard } from 'src/components/common/MICard';
import { CONSTS, MANUAL_ADDRESS } from 'src/utils/consts';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { whitePagesAddressKeys } from 'src/utils/address';
import MIRadioGroup from '../../components/form/MIRadioGroup';
import { MIFieldOrEmpty } from '../../utils/formatting';
import ManualAddressRow from '../../components/layout/ManualAddressRow';

type Props = {
  addressToConfirm?: ManualAddressType | null;
  addressValidationErrors?: Record<string, any>;
  originalAddress?: AddressType | LegalAddressType | null;
  printName?: string;
  onAddressSelect: (id: MANUAL_ADDRESS) => void;
  onEditAddress: () => void;
  selectedAddressId: MANUAL_ADDRESS;
};

const ManualAddressConfirmPage = ({
  addressToConfirm,
  addressValidationErrors,
  originalAddress,
  printName = '',
  onAddressSelect,
  onEditAddress,
  selectedAddressId,
}: Props) => {
  const site = useSiteContext();
  const onSelect = (id: MANUAL_ADDRESS) => {
    onAddressSelect(id);
  };

  const getAddressContentComponent = (address: ReactElement[][] | string, editLink?: boolean) => (
    <ManualAddressRow printName={printName} address={address} editLink={editLink} onEditAddress={onEditAddress} />
  );

  const getSuggestedAddressContent = () => {
    const addressFields = Object.keys(whitePagesAddressKeys);
    const address = addressFields
      .filter((field) => !!addressToConfirm?.[field])
      .map((field) => addressToConfirm?.[field])
      .join(', ');
    return getAddressContentComponent(address, true);
  };

  const getOriginalAddress = () => {
    const addressFields = Object.values(whitePagesAddressKeys);
    const addressFiltered = addressFields.filter((field) => !!originalAddress?.[field]);
    const suggestedAddress = addressFiltered.map((field, index) => {
      const address: ReactElement[] = [];

      /* eslint-disable function-paren-newline */
      address.push(
        <AddressElement key={`validatedAddress-${field}`}>
          <MIFieldOrEmpty
            value={originalAddress?.[field]}
            color={
              Object.keys(addressValidationErrors || []).includes(field)
                ? site.theme.text.color.red
                : 'rgba(33, 33, 36, 1)'
            }
            label=""
          />
          {index !== addressFiltered.length - 1 && <Comma key={field} />}
        </AddressElement>
      );

      return address;
    });

    return getAddressContentComponent(suggestedAddress);
  };

  const addressOptions = [
    {
      id: CONSTS.MANUAL_ADDRESS.SUGGESTED,
      label: 'manualAddress.suggested',
      component: getSuggestedAddressContent(),
    },
    {
      id: CONSTS.MANUAL_ADDRESS.ORIGINAL,
      label: 'manualAddress.original',
      component: getOriginalAddress(),
      icon: ErrorIcon,
    },
  ];

  return (
    <MICard mode="regular">
      <FormContainer>
        <MIRadioGroup
          id="manualAddress"
          group="manualAddress"
          options={addressOptions}
          selected={selectedAddressId}
          onSelect={onSelect}
          colDirection
          color="label"
        />
      </FormContainer>
    </MICard>
  );
};

export default ManualAddressConfirmPage;

const FormContainer = styled.div`
  display: block;
  padding: 2.4rem;
  ${(props) => props.theme?.components?.ManualAddressConfirmPage?.FormContainer}
`;

const AddressElement = styled.div`
  display: inherit;
`;

const Comma = styled.span`
  &:after {
    content: ',';
    margin-right: 0.5rem;
  }
`;
