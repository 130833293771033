import React from 'react';
import { Flex, Icon, Text } from '@melio/billpay-design-system';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as ErrorIllustration } from 'src/images/general/error-icon.svg';
import { InvoiceNumberType } from 'src/pages/vendor/hooks/useInvoiceNumberType';

type Props = {
  amount: number;
  companyName: string;
  invoiceNumber: string;
  invoiceNumberType: InvoiceNumberType;
};

export const PaymentInfo = ({ amount, invoiceNumber, companyName, invoiceNumberType }: Props) => (
  <Flex direction="column" justify="center" align="start" gap="ds.xl" w="100%">
    <Icon as={ErrorIllustration} w="ds.2xl" h="ds.2xl" />
    <Flex direction="column" justify="center" align="start" gap="ds.md">
      <ContentTitle label="vendor.pushToDebit.offerExpired.check.title" />
      <Text textStyle="ds.body2" mb="0" color="ds.gray.100" data-testid="p2d-check-offer-has-expired-payment-info">
        <MIFormattedText
          label="vendor.pushToDebit.offerExpired.check.text"
          values={{
            amount: <MIFormattedCurrency value={amount} />,
            companyName,
            invoiceNumber,
            invoiceNumberType,
          }}
        />
      </Text>
    </Flex>
  </Flex>
);
