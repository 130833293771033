import React, { PropsWithChildren } from 'react';
import { Box, VStack } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  description: string;
  listWidth?: string;
};

export const MethodsListWrapper = ({ children, description, listWidth = '11rem' }: PropsWithChildren<Props>) => {
  const list = (
    <VStack spacing="1.2rem" w={listWidth}>
      {children}
    </VStack>
  );

  const descriptionElement = (
    <Box w="full" textAlign="center" color="#717173" textStyle="ds.body3">
      <MIFormattedText label={description} />
    </Box>
  );

  return (
    <VStack spacing="3rem" w="20.7rem" align="center">
      {list}
      {descriptionElement}
    </VStack>
  );
};
