import React from 'react';
import { Box } from '@melio/billpay-design-system';

import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';

import { feeType } from './types';

/**
 * Shows notification after applied fee in confirm page
 */
type Props = {
  catalogFee: feeType;
  label: string;
};
const VirtualNotification = ({ catalogFee, label }: Props) => (
  <Box mb="ds.lg">
    <QBONotificationCard
      type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
      subtitle={{
        label,
        values: { fee: catalogFee },
      }}
    />
  </Box>
);

export { VirtualNotification };
