import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { useBreak } from 'src/hoc';

type Props = {
  children: React.ReactNode;
};

export const MobileSubmitContainer = ({ children }: Props) => {
  const { isDesktop } = useBreak();

  if (isDesktop) {
    return null;
  }

  return (
    <Flex
      direction="column"
      padding="ds.xl"
      gap="ds.md"
      backgroundColor="ds.white"
      position="sticky"
      bottom="0"
      w="100%"
      boxSizing="border-box"
      align="center"
    >
      {children}
    </Flex>
  );
};
