import React, { useEffect } from 'react';
import { UnilateralPaymentState } from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { VendorFlowLayout, VendorFlowContentCard, Footer } from 'src/pages/vendor/components/VendorFlowLayout';

import { ReactComponent as SuccessIllustration } from 'src/images/general/action-done-icon.svg';
import { ReactComponent as CalendarIllustration } from 'src/images/get-pro/calendar.svg';
import { ReactComponent as MasterCardLogo } from 'src/images/icons/mastercard.svg';
import { Flex, HStack, Icon, Text, VStack } from '@melio/billpay-design-system';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedText, MIFormattedDate, MIFormattedCurrency } from 'src/utils/formatting';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';

type Props = {
  state: UnilateralPaymentState;
};

export const AddVirtualCardDeliveryMethodSuccessPage = ({ state }: Props) => {
  const { payment, organization } = state;
  const { deliveryEta, vendor, amount } = payment || {};
  const { contactEmail: vendorEmail } = vendor || {};
  const { companyName } = organization || {};

  useEffect(() => {
    vendorUnilateralAnalytics.trackSuccessPageLoaded({
      paymentId: payment?.id,
      vendorId: vendor?.id,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
      vendorEmail,
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" justify="center" align="start">
          <Icon as={SuccessIllustration} w="ds.3xl" h="ds.3xl" mb="ds.xl" />
          <ContentTitle label="vendor.unilateral.virtualCardSuccess.title" />
          <Text textStyle="ds.body2" mb="0" mt="ds.md" color="ds.gray.100">
            <MIFormattedText
              label="vendor.unilateral.virtualCardSuccess.text"
              values={{
                date: <MIFormattedDate date={deliveryEta} />,
                amount: <MIFormattedCurrency value={amount || 0} />,
                vendorEmail,
                companyName,
              }}
            />
          </Text>
        </Flex>
        <VStack gap="ds.xl" mt="ds.2xl" w="100%" align="start">
          <Text color="ds.gray.200" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.title" />
          </Text>
          <HStack gap="ds.lg">
            <Icon as={MasterCardLogo} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.text" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                {vendorEmail}
              </Text>
            </Flex>
          </HStack>
          <HStack gap="ds.lg">
            <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.deliveryETA" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedDate date={deliveryEta} />
              </Text>
              <Text color="ds.gray.300" textStyle="ds.body3" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.deliveryTimeHelperText" />
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
