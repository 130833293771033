import React from 'react';
import { NOTIFICATION_CARD_TYPES } from 'src/utils/consts';
import { AccountRecord } from 'src/records/settings.record';
import { BillType, PaymentType } from 'src/utils/types';
import { QBDTNotificationCard } from 'src/billpay/qbdt/components/QBDTNotificationCard';
import { QBDTEmailLink } from 'src/components/common/QBDTEmailLink';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { getFailedPaymentTitle, getFailedPaymentDescription } from './utils';

type Props = {
  bill: BillType;
  payment: PaymentType;
};

export const FailedPaymentNotificationCard = ({ payment, bill }: Props) => {
  const site = useSiteContext();
  const failedPaymentTitle = getFailedPaymentTitle(payment);
  const failedPaymentDescription = getFailedPaymentDescription(payment);
  const fundingSource = AccountRecord((payment as any)?.fundingSource);

  const showDisplayName = false;

  return (
    <QBDTNotificationCard
      type={NOTIFICATION_CARD_TYPES.ERROR}
      testId="view-payment-failed-payment-notification"
      title={{ label: failedPaymentTitle }}
      showIcon={false}
      subtitle={{
        label: failedPaymentDescription,
        values: {
          fundingSource: fundingSource.getName(showDisplayName),
          vendorCompanyName: bill.vendor.companyName,
          supportEmail: <QBDTEmailLink email={site.config.support.email} />,
        },
      }}
    />
  );
};
