import { theme } from '../theme/defaultTheme';
import config from '../config';
import enTranslations from '../locale/en-US';
import * as WizardElements from '../components/layout/WizardElements';
import MISecurityDetails from '../components/common/MISecurityDetails';
import Loader from '../components/common/Loader';
import Dialog from '../components/common/MIDialog';
import { CONSTS } from '../utils/consts';
import FloatedButtons from '../components/bill/FloatedButtons';

const defaultSite = {
  name: 'melio',
  theme,
  chakraTheme: {},
  preloadImages: ['/accounting-software/', '/funding-sources/', '/general/', '/icons/', '/vendors/'],
  config,
  routes: {},
  messages: {
    'en-US': enTranslations['en-US'],
  },
  createOrigin: {
    pay: {
      payor: CONSTS.REGISTRATION_FLOW.REGULAR,
      payee: CONSTS.REGISTRATION_FLOW.VENDOR,
      payment: CONSTS.PAYMENT_CREATE_FLOW_ORIGIN.PAY,
    },
    meliome: {
      payor: CONSTS.REGISTRATION_FLOW.GUEST,
      paymentRequest: CONSTS.PAYMENT_REQUEST_CREATE_ORIGIN.MELIOME,
    },
  },
  components: {
    WizardElements,
    MISecurityDetails,
    Loader,
    Dialog,
    FloatedButtons,
  },
  title: 'Melio',
  icon: 'logo64x64.ico',
  registrationOrigin: CONSTS.REGISTRATION_ORIGIN.APP,
};

export default defaultSite;
