import * as React from 'react';
import { RecordOf } from 'immutable';
import { compose } from 'recompose';
import { FieldType, BankType, DeliveryMethodOriginType, CompanyInfoType } from 'src/utils/types';
import { withPreservedStateNavigator } from 'src/hoc/index';
import analytics from 'src/services/analytics';
import EditBankDeliveryMethodPage from './components/EditBankDeliveryMethodPage';
import { DeliveryMethodProps, withDeliveryMethodData } from './hoc/withDeliveryMethodData';

type Props = {
  origin: DeliveryMethodOriginType;
  vendorId: string;
  locationState: Record<string, any>;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void;
  companyInfo: RecordOf<CompanyInfoType>;
} & DeliveryMethodProps;

type State = {
  bank: RecordOf<BankType>;
};

const eventPage = 'vendor-delivery-method';

class EditBankDeliveryMethodPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bank: props.bank,
    };
  }

  onChange = ({ id, value }: FieldType) => {
    this.setState(({ bank }) => ({ bank: bank.merge({ [id]: value }) }));
  };

  onDone = () => {
    this.props.onBankAdded(this.state.bank);
  };

  onPrev = () => {
    const { navigateToExitWithPreservedState } = this.props;

    navigateToExitWithPreservedState();
  };

  goExit = () => {
    const { navigateToExitWithPreservedState } = this.props;

    analytics.track(eventPage, 'exit');
    navigateToExitWithPreservedState();
  };

  render() {
    const { isLoading, validationErrors, origin, errorCode, check, companyInfo } = this.props;

    const { bank } = this.state;
    return (
      <EditBankDeliveryMethodPage
        vendorName={check.printName}
        bank={bank}
        onDone={this.onDone}
        onChange={this.onChange}
        onPrev={this.onPrev}
        goExit={this.goExit}
        isLoading={isLoading}
        validationErrors={validationErrors}
        origin={origin}
        errorCode={errorCode}
        companyName={companyInfo.companyName}
      />
    );
  }
}

export default compose(withPreservedStateNavigator(), withDeliveryMethodData())(EditBankDeliveryMethodPageContainer);
