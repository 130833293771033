import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import MIButton from 'src/components/common/MIButton';
import SelectedDMSvgIcon from 'src/images/icons/SelectedDMSvgIcon';
import { ReactComponent as WarningIcon } from 'src/images/general/warning-icon.svg';
import { devices } from 'src/theme/AppDevices';
import { BUTTONS_DIRECTIONS, BUTTONS_ROW_POSITION, CONSTS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { ModalDialogSettings, ModalDialogTypes, ModalDialogVariants } from 'src/utils/types';
import styled from 'styled-components';

const directions = {
  [BUTTONS_DIRECTIONS.VERTICAL]: 'column',
  [BUTTONS_DIRECTIONS.HORIZONTAL]: 'row-reverse',
};

const rowPosition = {
  [BUTTONS_ROW_POSITION.RIGHT]: 'flex-end',
  [BUTTONS_ROW_POSITION.LEFT]: 'flex-start',
  [BUTTONS_ROW_POSITION.CENTER]: 'center',
};

const MIDialog = ({
  type,
  variant,
  title,
  titleValues,
  subtitle,
  subtitleValues,
  okButtonText,
  onOkAction,
  cancelButtonText,
  onCancelAction,
  hideIcon,
  showCancel = true,
  onOkDisabled,
  maxWidth,
  minHeight,
  fullWidth,
  children,
  buttonsDirection = BUTTONS_DIRECTIONS.VERTICAL,
  buttonsRowPosition = BUTTONS_ROW_POSITION.CENTER,
}: ModalDialogSettings) => {
  const [loading, setLoading] = useState(false);
  const renderIcon = (dialogType: ModalDialogTypes, dialogVariant: ModalDialogVariants) => {
    if (hideIcon) {
      return null;
    } else if (dialogVariant === CONSTS.DIALOG_VARIANTS.WARNING) {
      return (
        <CenteredIcon>
          <WarningIcon />
        </CenteredIcon>
      );
    } else if (dialogVariant === CONSTS.DIALOG_VARIANTS.SUCCESS) {
      return (
        <CenteredIcon>
          <SelectedDMSvgIcon width="34" />
        </CenteredIcon>
      );
    }

    return <ErrorIcon />;
  };

  const renderCancelButtonText = (
    buttonText: string,
    dialogType: ModalDialogTypes,
    dialogVariant: ModalDialogVariants
  ) => {
    if (!buttonText) {
      return dialogType === CONSTS.DIALOG_TYPE.ALERT && dialogVariant === CONSTS.DIALOG_VARIANTS.SUCCESS
        ? 'dialogs.buttons.done'
        : 'dialogs.buttons.cancel';
    }

    return buttonText;
  };

  function onOk() {
    const res: any = onOkAction && onOkAction();
    if (res?.finally) {
      setLoading(true);
      res.finally(() => setLoading(false));
    }
  }
  return ReactDOM.createPortal(
    <DialogContainer data-testid="dialog-container">
      <Dialog maxWidth={maxWidth} minHeight={minHeight} alert={type === CONSTS.DIALOG_TYPE.ALERT}>
        <Close>
          <CloseAction onClick={onCancelAction}>
            <i className="icon-close-icon" />
          </CloseAction>
        </Close>
        <TextsContainer alert={type === CONSTS.DIALOG_TYPE.ALERT}>
          {renderIcon(type, variant)}
          <TitleContainer success={variant === CONSTS.DIALOG_VARIANTS.SUCCESS}>
            <MIFormattedText label={title} values={titleValues} />
          </TitleContainer>
          {subtitle && (
            <SubtitleContainer data-testid="dialog-subtitle">
              <MIFormattedText label={subtitle} values={subtitleValues} />
            </SubtitleContainer>
          )}
        </TextsContainer>
        {children && React.cloneElement(children as any, { onCancelAction })}
        <ActionsContainer buttonsDirection={buttonsDirection} buttonsRowPosition={buttonsRowPosition}>
          {type === CONSTS.DIALOG_TYPE.CONFIRM && okButtonText && onOkAction && (
            <OkButtonContainer fullWidth={fullWidth}>
              <MIButton
                disabled={onOkDisabled}
                variant={
                  type === CONSTS.DIALOG_TYPE.CONFIRM &&
                  (variant === CONSTS.DIALOG_VARIANTS.SUCCESS || variant === CONSTS.DIALOG_VARIANTS.WARNING) &&
                  onOkAction &&
                  okButtonText
                    ? CONSTS.BUTTON_VARIANT.PRIMARY
                    : CONSTS.BUTTON_VARIANT.DESTRUCTIVE
                }
                onClick={onOk}
                label={okButtonText || ''}
                isProcessing={loading}
                fullWidth
              />
            </OkButtonContainer>
          )}
          {onCancelAction && showCancel && (
            <MIButton
              variant={CONSTS.BUTTON_VARIANT.CANCEL}
              onClick={onCancelAction}
              label={renderCancelButtonText(cancelButtonText || '', type, variant)}
            />
          )}
        </ActionsContainer>
      </Dialog>
    </DialogContainer>, // $FlowFixMe
    document.querySelector('body')
  );
};

export default MIDialog;

const DialogContainer = styled.div`
  z-index: ${(props) => props.theme.zIndex.overlay};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.dark.translucent5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dialog = styled.div<{
  alert?: boolean;
  maxWidth?: string;
  minHeight?: string;
}>`
  box-sizing: border-box;
  padding: ${(props) => (props.alert ? '5.2rem 4.5rem 4.9rem' : '5.2rem 4.5rem 3.9rem')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '50rem')};
  min-height: ${(props) => props.minHeight ?? '43rem'};
  position: relative;
  width: calc(100% - 6rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 5rem 2rem;
    width: calc(100% - 4rem);
    max-width: 33.5rem;
  }
`;

const TextsContainer = styled.div<{ alert?: boolean }>`
  margin-bottom: ${(props) => (props.alert ? '2.7rem' : '3.3rem')};
`;

const ErrorIcon = styled.i.attrs({ className: 'icon-notification-icon' })`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.9rem;
  color: ${(props) => props.theme.colors.primary.destructive};
  font-size: 4.8rem;
`;

const BaseTitleContainer = styled.div`
  align-items: center;
  text-align: center;
`;

const TitleContainer = styled(BaseTitleContainer)<{ success?: boolean }>`
  margin: 0 0 1.4rem 0;
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: ${(props) => props.theme.text.lineHeight.bigTitleM};
  text-align: left;
`;

const SubtitleContainer = styled(BaseTitleContainer)`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  text-align: left;
`;

const ActionsContainer = styled.div<{
  buttonsRowPosition?: BUTTONS_ROW_POSITION;
  buttonsDirection?: BUTTONS_DIRECTIONS;
}>`
  display: flex;
  align-items: ${(props) => rowPosition[props.buttonsRowPosition]};
  width: 100%;
  flex-direction: ${(props) => directions[props.buttonsDirection]};
`;

const OkButtonContainer = styled.div`
  margin-bottom: 0.2rem;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

const CenteredIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.9rem;
`;

const Close = styled.div`
  font-size: 2.5rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

const CloseAction = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.text.color.label};

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;
