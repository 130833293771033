const MAPPINGS = {
  'regularBatchPayments/removeBillId': {
    type: 'success',
    msg: 'regularBatchPayments.notifications.billRemoved',
  },
  'regularBatchPayments/removeBillFromPayment': {
    type: 'success',
    msg: 'regularBatchPayments.notifications.billRemoved',
  },
};

export default MAPPINGS;
