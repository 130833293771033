import React from 'react';
import { Flex, Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  label: string;
};

export const FieldErrorMessage = ({ label }: Props) => (
  <Flex alignItems="center" color="ds.red.200" mt="ds.sm" textStyle="ds.body3" position="absolute">
    <Box as="i" fontSize="2rem" className="icon-warning-icon" color="ds.red.200" mr="ds.md" mt="-2px" />
    <MIFormattedText label={label} />
  </Flex>
);
