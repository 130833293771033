import { EventMappingsTree } from 'src/services/analytics/types';
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import locations from './locations';

export const DASHBOARD_PAGE_EVENT = 'bills-dashboard';
export const PAY_FLOW_PAGE_EVENT = 'pay-bill';
export const BATCH_PAY_FLOW_PAGE_EVENT = 'pay-bills-batch';

const dashboardPageUrl = locations.dashboard.replace(':orgId', '*');

export const dashboardEventMapping: EventMappingsTree = {
  [dashboardPageUrl]: {
    'tab-event-unpaid': [DASHBOARD_PAGE_EVENT, 'tab-unpaid'],
    'tab-event-scheduled': [DASHBOARD_PAGE_EVENT, 'tab-scheduled'],
    'tab-event-paid': [DASHBOARD_PAGE_EVENT, 'tab-paid'],
    'pagination-previous-page': [DASHBOARD_PAGE_EVENT, 'previous-page', { page: 'page' }],
    'pagination-next-page': [DASHBOARD_PAGE_EVENT, 'next-page', { page: 'page' }],
    'pagination-go-page': [DASHBOARD_PAGE_EVENT, 'go-to-page', { page: 'page' }],
    'batch-actions': [
      DASHBOARD_PAGE_EVENT,
      'batch-action-click',
      {
        action: 'action',
        billIds: 'billIds',
        isPartial: 'partialPaymentsCount',
      },
    ],
    'paymentDashboard.ftuModal.ctaButton': [DASHBOARD_PAGE_EVENT, 'ftu-modal-click'],
    'cancel-payment-submit': [
      DASHBOARD_PAGE_EVENT,
      'cancel-payment-submit',
      {
        paymentId: 'paymentId',
        billIds: 'billIds',
      },
    ],
    'cancel-payment-success': [
      DASHBOARD_PAGE_EVENT,
      'cancel-payment-success',
      {
        paymentId: 'paymentId',
        billIds: 'billIds',
      },
    ],
    'cancel-payment-failure': [
      DASHBOARD_PAGE_EVENT,
      'cancel-payment-failure',
      {
        paymentId: 'paymentId',
        billIds: 'billIds',
        reason: 'reason',
      },
    ],
    'cancel-payment-dialog': [DASHBOARD_PAGE_EVENT, 'cancel-payment-dialog', { toggle: 'toggle' }],
    'sort-by': [DASHBOARD_PAGE_EVENT, 'sort-bills', { sorting: 'sorting' }],
    'data-table-event': [DASHBOARD_PAGE_EVENT, 'select-bills', { billId: 'id', action: 'action' }],
    'vendor-modal-close': [DASHBOARD_PAGE_EVENT, 'edit-vendor-close'], // Unused for now (should be used in terms implementation of edit vendor action )
    ...mapApiSlice('[VENDORS] FETCH', DASHBOARD_PAGE_EVENT, 'edit-vendor-open', {
      // Unused for now (should be used in terms implementation of edit vendor action )
      success: { vendorId: 'payload.id', userId: 'payload.updatedById' },
    }),
    ...mapApiSlice('[VENDORS] UPDATE', DASHBOARD_PAGE_EVENT, 'edit-vendor-save', {
      // Unused for now (should be used in terms implementation of edit vendor action )
      request: {
        vendorCompanyName: 'payload.companyName',
        vendorEmail: 'payload.contactEmail',
      },
    }),
    navigate: [DASHBOARD_PAGE_EVENT, 'change-tab-from-empty-state', { to_tab: 'navigateTo.search' }],
    'dashboard-list-action': [
      DASHBOARD_PAGE_EVENT,
      'list',
      {
        total_bills_count: 'totalCount',
        filters: 'filters',
        tab_name: 'tabName',
        list_of_bills: 'billIds',
        list_of_payments: 'paymentIds',
        current_page_total_amount: 'totalAmount',
        billStatus: 'billStatus',
        withSyncLoader: 'withSyncLoader',
      },
    ],
    'dashboard-list-filters-action': [
      // Unused till dashboard filter feature is not introduced
      DASHBOARD_PAGE_EVENT,
      'filters-button-click',
      {
        click_target: 'clickTarget',
        status: 'status',
      },
    ],
    'show-ftu-dashboard-state-modal': [DASHBOARD_PAGE_EVENT, 'show-ftu-dashboard-state-modal'],
    'mark-as-paid-modal': [DASHBOARD_PAGE_EVENT, 'mark-as-paid-modal', { toggle: 'toggle' }], // Unused till markAsPaid is not introduced for QBDT
    'mark-as-paid-modal-submit': [DASHBOARD_PAGE_EVENT, 'mark-as-paid-modal-submit', { billIds: 'billIds' }], // Unused till markAsPaid is not introduced for QBDT
    'mark-as-paid-modal-on-focus-amount': [
      DASHBOARD_PAGE_EVENT,
      'focus-payment-amount',
      { amount: 'amount', source: 'source' },
    ], // Unused till markAsPaid is not introduced for QBDT
    'action-click': [
      DASHBOARD_PAGE_EVENT,
      'action-item-click',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        isPartial: 'isPartial',
        status: 'status',
        action: 'action',
        fromRow: 'fromRow',
      },
    ],
    'success-payment-modal': [
      PAY_FLOW_PAGE_EVENT,
      'success-payment-modal',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
    'success-payment-modal-done': [
      PAY_FLOW_PAGE_EVENT,
      'done',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
    'pay-bills-batch': [
      BATCH_PAY_FLOW_PAGE_EVENT,
      'success-batch-payment-modal',
      {
        partialBillIds: 'partialBillIds',
        paymentIds: 'paymentIds',
      },
    ],
  },
};
