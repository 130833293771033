import * as React from 'react';
import styled from 'styled-components';
import ErrorIcon from 'src/images/general/error-icon.svg';
import { MICard } from 'src/components/common/MICard';
import { AddressType, LegalAddressType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';
import { devices } from 'src/theme/AppDevices';
import ManualAddressRow from '../../components/layout/ManualAddressRow';
import { MIFormattedText } from '../../utils/formatting';
import { whitePagesAddressKeys } from '../../utils/address';

type Props = {
  originalAddress?: AddressType | LegalAddressType | null;
  printName?: string;
  onEditAddress: () => void;
};

const ManualAddressInvalidPage = ({ originalAddress, printName, onEditAddress }: Props) => {
  const getOriginalAddress = () => {
    const addressFields: string[] = Object.values(whitePagesAddressKeys);
    const address = addressFields
      .filter((field) => !!originalAddress?.[field])
      .map((field) => originalAddress?.[field])
      .join(', ');
    return address;
  };

  return (
    <MICard mode="regular" flex>
      <FormWrapper>
        <Icon src={ErrorIcon} />
        <FormContainer data-testid="invalid-manual-address">
          <Label>
            <MIFormattedText label="manualAddress.invalid" />
          </Label>
          <ManualAddressRow
            printName={printName}
            address={getOriginalAddress()}
            onEditAddress={onEditAddress}
            editLink
          />
        </FormContainer>
      </FormWrapper>
      <ButtonsContainer isProgressBar width="auto">
        <MIButton
          size={CONSTS.BUTTON_SIZE.NORMAL}
          variant={CONSTS.BUTTON_VARIANT.SECONDARY}
          onClick={onEditAddress}
          label="manualAddress.editAddress"
          fullWidth
        />
      </ButtonsContainer>
    </MICard>
  );
};

export default ManualAddressInvalidPage;

const FormContainer = styled.div`
  display: block;
  padding: 2.4rem;
  padding-left: 0;

  ${(props) => props.theme?.pages?.ManualAddressInvalidPage?.FormContainer}
`;

const Icon = styled.img`
  padding: 2rem;
  height: 2.5rem;
  width: 2.5rem;

  ${(props) => props.theme?.pages?.ManualAddressInvalidPage?.Icon}
`;

const Label = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.label};
  white-space: nowrap;
  opacity: 1;

  ${(props) => props.theme?.pages?.ManualAddressInvalidPage?.Label}
`;

const ButtonsContainer = styled.div`
  display: none;

  @media ${devices.mobile}, ${devices.phablet} {
    button {
      margin-bottom: 0;
      width: 100%;
      min-height: 4.8rem;
      border-radius: 2.4rem;
    }
    margin-bottom: 2rem;
  }

  ${(props) => props.theme?.pages?.ManualAddressInvalidPage?.ButtonsContainer}
`;

const FormWrapper = styled.div`
  display: flex;
  ${(props) => props.theme?.pages?.ManualAddressInvalidPage?.FormWrapper}
`;
