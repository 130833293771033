export const mainActionLabelStyle = {
  textStyle: 'ds.body2Medium',
  color: 'ds.blue.200',
  cursor: 'pointer',
};

export const menuButtonStyle = {
  color: 'ds.black',
  cursor: 'pointer',
  '> span': {
    display: 'flex',
  },
};
