import qs from 'qs';

function convertStringToUrlObject(url) {
  if (typeof url !== 'string') {
    return url;
  }

  const parsedUrl = {
    search: '',
    pathname: url,
  };
  const index = url.indexOf('?');
  if (index !== -1) {
    parsedUrl.search = url.substring(index, url.length);
    parsedUrl.pathname = url.substring(0, index);
  }

  return parsedUrl;
}

const LEADING_QUESTION_MARK_REGEX = /[^?](\S)*/g;

// decoder is rewritten to support preserve + sign in emails that we pass as query param
// I've just removed plus sign replacement on empty string from native
// lib implementation https://github.com/ljharb/qs/blob/master/lib/utils.js#L108-L120
function parseQueryString(locationSearch) {
  const [queryString] = locationSearch.match(LEADING_QUESTION_MARK_REGEX) || [];
  return qs.parse(queryString, {
    decoder: (str, decoder, charset) => {
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return str.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      // utf-8
      try {
        return decodeURIComponent(str);
      } catch (e) {
        return str;
      }
    },
  });
}

function encodeQuery(query, excludeFields, baseSearch) {
  return encodeURI(
    Object.keys(query)
      .filter((filterKey) => !excludeFields.some((field) => field === filterKey))
      .reduce((result, filterKey) => {
        if (query[filterKey] === undefined) {
          return result;
        }

        const filterSearchParam = `${filterKey}=${query[filterKey]}`;

        return result ? `${result}&${filterSearchParam}` : filterSearchParam;
      }, baseSearch)
  );
}

export { parseQueryString, encodeQuery, convertStringToUrlObject };
