import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';

import { CONSTS } from 'src/utils/consts';
import { FeatureFlags } from 'src/utils/feature-flags';
import { getOrganizationFeeCatalog } from 'src/redux/organization/selectors';
import useGetValidFundingSources from 'src/billpay/qbdt/pages/batch-pay/table/hooks/useGetValidFundingSources';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { MainTableRowType } from 'src/billpay/qbdt/pages/batch-pay/table/types';
import {
  featureFlagValues as checkFlagValues,
  isCheckFeeOpenWithPromotions,
  isBatchAchCheck,
  setAchToCheckFee,
  setFreeCheck,
} from 'src/billpay/qbdt/hooks/useCheckFee/utils';

/**
 * using original bills list from useBatchBillsList
 * modify its fees with correct values (check fees)
 * useBatchBillsList is triggered too many times, so whoever needs fix fees, shuold use this hook
 */
const useModifyBillsFeesPromotion = () => {
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlags.CheckFees, checkFlagValues.CLOSED);

  const catalog = useSelector(getOrganizationFeeCatalog);
  const [validFundingSources] = useGetValidFundingSources();
  const isCheckFeePromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);
  const achCheckCatalogFee = catalog?.[CONSTS.FEE_VARIANTS.ACH_TO_CHECK]?.value;
  const { freeChecks } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);

  const mapBillsListWithPromotion = useCallback(
    (billsList: MainTableRowType[]) => {
      if (isCheckFeePromotions && achCheckCatalogFee) {
        // the ui side is in charge of setting the fee (server limitation)
        // promotions is temp, will be removed few months after launch
        let availableFreeChecks = freeChecks;

        return billsList.map((bill) => {
          const shouldSetCheckFee = isBatchAchCheck([bill] as any, validFundingSources);

          if (!shouldSetCheckFee) return bill;

          if (availableFreeChecks > 0) {
            // free check
            availableFreeChecks -= 1;
            bill.fee = setFreeCheck(bill.fee);

            return bill;
          }

          // charge check
          bill.fee = setAchToCheckFee(bill.fee, achCheckCatalogFee);

          return bill;
        });
      }

      // default case
      return billsList;
    },
    [freeChecks, validFundingSources, isCheckFeePromotions, achCheckCatalogFee]
  );

  return { mapBillsListWithPromotion };
};

export { useModifyBillsFeesPromotion };
