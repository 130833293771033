import React from 'react';
import { Flex, HStack, Icon, Text } from '@melio/billpay-design-system';
import { ReactComponent as BankAccountIllustration } from 'src/images/general/bank-account-icon.svg';
import { ReactComponent as CalendarIllustration } from 'src/images/general/calendar-icon.svg';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';

type Props = {
  digits: string;
  depositedDate: Date;
};

export const VendorReceive = ({ digits, depositedDate }: Props) => (
  <Flex direction="column" gap="ds.xl" justify="center" align="start" w="100%">
    <Text textStyle="ds.body2" color="ds.gray.100" alignSelf="start" m="0" textTransform="uppercase">
      <MIFormattedText label="vendor.pushToDebit.achDeposited.paymentDetailsTitle" />
    </Text>
    <Flex direction="column" justify="center" align="start" gap="ds.xl">
      <HStack gap="ds.lg">
        <Icon as={BankAccountIllustration} w="3rem" h="auto" alignSelf="start" />
        <Flex direction="column" align="start">
          <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.achDeposited.bankTransfer" />
          </Text>
          <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.achDeposited.bankAccount" values={{ digits }} />
          </Text>
        </Flex>
      </HStack>
      <HStack gap="ds.lg">
        <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
        <Flex direction="column" align="start">
          <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.pushToDebit.achDeposited.depositedDate" />
          </Text>
          <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
            <MIFormattedDate date={depositedDate} />
          </Text>
        </Flex>
      </HStack>
    </Flex>
  </Flex>
);
