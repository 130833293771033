export const ELEMENT_COMMON_STYLE = {
  base: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    fontFamily: "'Avenir Next forINTUIT', sans-serif;",
    color: '#393A3D',
    '::placeholder': {
      color: '#BABEC5',
    },
  },
  invalid: {
    color: '#393A3D',
  },
};
