import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { AddDebitCardSuccessPage } from 'src/pages/vendor/shift-vendor-to-debit/pages/AddDebitCardSuccessPage';
import {
  AddDebitCardPage,
  AddVirtualCardPage,
  LearnMoreAboutVirtualCardPage,
  AddVirtualCardSuccessPage,
  SelectDeliveryMethodPage,
  ConfirmCardHolderAddressPage,
  OfferHasExpiredPage,
  InvalidTokenPage,
  PaymentBlockedPage,
  CheckDepositedPage,
  AddDebitCardCompletedPage,
  AddVirtualCardCompletedPage,
  AddVirtualCardNotClearedPage,
  PaymentCanceledPage,
  PaymentFailedPage,
} from './pages';
import { usePushToDebitState } from './hooks';
import locations from '../locations';
import { ACHDepositedPage } from './pages/ACHDepositedPage';

export const VendorPushToDebitRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const { token } = match.params;

  const { isLoading, payment, organization } = usePushToDebitState({ token });

  if (isLoading) {
    return <QBDTLoader />;
  }

  return (
    <Switch>
      <SmartRoute path={locations.shiftToDebit.index} exact>
        <SelectDeliveryMethodPage payment={payment} organization={organization} token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addDebitCard} exact>
        <AddDebitCardPage payment={payment} organization={organization} token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addVirtualCard} exact>
        <AddVirtualCardPage token={token} organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.learnMoreAboutVirtualCard} exact>
        <LearnMoreAboutVirtualCardPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.confirmCardHolder} exact>
        <ConfirmCardHolderAddressPage organization={organization} payment={payment} token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addDebitCardSuccessPage} exact>
        <AddDebitCardSuccessPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addDebitCardCompletedPage} exact>
        <AddDebitCardCompletedPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addVirtualCardMethodSuccess} exact>
        <AddVirtualCardSuccessPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addVirtualCardMethodNotCleared} exact>
        <AddVirtualCardNotClearedPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addVirtualCardMethodCompleted} exact>
        <AddVirtualCardCompletedPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.offerHasExpired} exact>
        <OfferHasExpiredPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.paymentBlocked} exact>
        <PaymentBlockedPage organization={organization} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.paymentFailed} exact>
        <PaymentFailedPage organization={organization} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.paymentCanceled} exact>
        <PaymentCanceledPage />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.checkDeposited} exact>
        <CheckDepositedPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.achDeposited} exact>
        <ACHDepositedPage organization={organization} payment={payment} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.invalidToken} exact>
        <InvalidTokenPage />
      </SmartRoute>
    </Switch>
  );
};
