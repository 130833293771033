import { createSlice } from '@reduxjs/toolkit';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import api from 'src/services/api/organizations';

const name = 'organizations';

type ActivityState = {
  byId: { [key: string]: any };
  activities: {
    loading: boolean;
    error: Error | null;
  };
};

const activitySlice = createApiCallSlice<any, ActivityState>({
  name: `[${name.toUpperCase()}] ACTIVITY`,
  api: api.getActivitySummary,
  initialState: {
    byId: {},
    activities: {
      loading: false,
      error: null,
    },
  },
  selectors: {
    getActivitiesStatus: (state) => state[name].activities,
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.activities.loading = true;
      state.activities.error = null;
    },
    [ON_FAILURE]: (state, action) => {
      state.activities.loading = false;
      state.activities.error = action.error;
    },
    [ON_SUCCESS]: (state, action) => {
      state.activities.loading = false;
      state.activities.error = null;
      Object.entries(action.payload.summary).forEach(([key, value]: any) => {
        if (state.byId[key]) {
          state.byId[key].payorActivity = value.payorActivity;
        }
      });
    },
  },
});

const updateNewCompanySlice = createSlice({
  name: `[${name.toUpperCase()}] SET_NEW_COMPANY_INFO`,
  initialState: {
    create: {
      company: {},
    },
  },
  reducers: {
    update(state, { payload }) {
      state.create.company = payload;
    },
  },
});

export { activitySlice, updateNewCompanySlice };
