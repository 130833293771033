import shiftVendorToAch from './shift-vendor-to-ach/locations';
import shiftToDebit from './shift-vendor-to-debit/locations';
import unilateral from './virtual-delivery-method/locations';
import editCheckAddress from './edit-check-adress/locations';
import virtualCardDetails from './virtual-card-details/locations';
import shiftVirtualCardDetailsToAch from './shift-virtual-card-to-ach/locations';

const LOCATIONS = {
  base: unilateral.base.concat(
    shiftVendorToAch.base,
    shiftToDebit.base,
    editCheckAddress.base,
    virtualCardDetails.base,
    shiftVirtualCardDetailsToAch.base
  ),
  unilateral,
  shiftVendorToAch,
  shiftToDebit,
  editCheckAddress,
  virtualCardDetails,
  shiftVirtualCardDetailsToAch,
};

export default LOCATIONS;
