const numericStyles = {
  '&[data-is-numeric=true]': {
    fontWeight: 600,
  },
};

const BORDER_WIDTH = '1px';
const BORDER_STYLE = 'solid';

const borderStyle = {
  borderColor: 'ds.gray.600',
  borderBottomWidth: BORDER_WIDTH,
  borderRightWidth: BORDER_WIDTH,
  borderBottomStyle: BORDER_STYLE,
  borderRightStyle: BORDER_STYLE,
};

const roundedTableCorners = {
  '&:first-of-type': {
    'th:first-of-type': {
      borderTopLeftRadius: 'ds.lg',
    },
    'th:last-of-type': {
      borderTopRightRadius: 'ds.lg',
    },
  },
  '&:last-of-type': {
    'td:first-of-type': {
      borderBottomLeftRadius: 'ds.lg',
    },
    'td:last-of-type': {
      borderBottomRightRadius: 'ds.lg',
    },
  },
};

export const qbdtBillingFeesHistoryVariant = {
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    tableLayout: 'fixed',
    borderRadius: 'ds.lg',
    width: 'full',
    textStyle: 'ds.body3',
    color: 'ds.gray.100',

    tr: {
      th: {
        bg: 'ds.gray.800',
        '&[data-is-numeric=true]': {
          '> div': {
            justifyContent: 'flex-end',
          },
        },
      },
      td: {
        ...numericStyles,
        '&:last-of-type': {
          textAlign: 'center',
        },
      },
      'th, td': {
        textAlign: 'left',
        ...borderStyle,
      },
      '&:first-of-type th': {
        borderTopWidth: BORDER_WIDTH,
        borderTopStyle: BORDER_STYLE,
      },
      'th:first-of-type, td:first-of-type': {
        borderLeftWidth: BORDER_WIDTH,
        borderLeftStyle: BORDER_STYLE,
      },
      ...roundedTableCorners,
    },
    caption: {
      borderBottomLeftRadius: 'ds.lg',
      borderBottomRightRadius: 'ds.lg',
      borderBottom: '1px',
      borderLeft: '1px',
      borderRight: '1px',
      borderColor: 'ds.gray.600',
    },
  },
};
