import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getOwnedVendorId, getDeliveryMethods } from 'src/redux/user/selectors';
import AreaLoader from 'src/components/common/AreaLoader';
import OutsideLayout from 'src/components/layout/OutsideLayout';
import { MIFormattedText, MIFormattedCurrency } from 'src/utils/formatting';
import { devices } from 'src/theme/AppDevices';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { getJWTPayload } from 'src/helpers/jwt';
import paymentStore from 'src/modules/payments/payment-store';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/modules/organizations/organizations-store';
import doneImage from 'src/images/general/check-circle.svg';
import { useInvoiceNumberWithPrefix } from '../hooks/useInvoiceNumberWithPrefix';

const ShiftedCurrentPaymentPage = ({ token }) => {
  const ownedVendorId = useSelector(getOwnedVendorId);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentStore.selectors.byId(paymentId));
  const { invoiceNumber } = useInvoiceNumberWithPrefix(payment);
  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id));

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout>
      <BillPaymentBlock>
        <BillInfoContainer>
          <DoneIcon src={doneImage} />
          <Title data-testid="success-title">
            <MIFormattedText
              label={
                ownedVendorId && orgDeliveryMethods?.length
                  ? 'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.ownedVendorTitle'
                  : 'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.title'
              }
            />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={payment?.amount} />,
                companyName: organization?.companyName,
                invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
      </BillPaymentBlock>
    </OutsideLayout>
  );
};

export default ShiftedCurrentPaymentPage;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
`;
