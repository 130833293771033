import React from 'react';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { MIFormattedText } from 'src/utils/formatting';
import { BillDetails } from 'src/billpay/qbdt/pages/batch-pay/components/BillDetails';
import { BatchBillType } from 'src/modules/regular-batch-payments/types/store-types';

type Props = {
  onClose?: () => void;
  bill: BatchBillType;
  vendorName: string;
  analyticsProperties: Record<string, any>;
};

const BillDetailsModal = ({ onClose, bill, vendorName, analyticsProperties }: Props) => (
  <ModalMessage
    alignLeft
    largeSize
    titleComponent={<MIFormattedText label="bills.view.title" />}
    contentComponent={<BillDetails bill={bill} vendorName={vendorName} />}
    analyticsProperties={analyticsProperties}
    onCloseClick={onClose}
  />
);

export default BillDetailsModal;
