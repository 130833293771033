import range from 'lodash/range';
import { VISIBLE_PAGES_COUNT } from './consts';

export const getVisiblePages = (pageIndex: number, pageCount: number) => {
  const visiblePagesCount = pageCount >= VISIBLE_PAGES_COUNT ? VISIBLE_PAGES_COUNT : pageCount;
  const offsetValue = Math.ceil((visiblePagesCount - 1) / 2);
  const leftRange = pageIndex - offsetValue;
  const rightRange = pageIndex + offsetValue;
  let startIndex;
  if (leftRange < 0) {
    startIndex = 0;
  } else if (rightRange >= pageCount) {
    startIndex = pageCount - visiblePagesCount;
  } else {
    startIndex = leftRange;
  }

  return range(startIndex, startIndex + visiblePagesCount);
};
