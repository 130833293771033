import * as React from 'react';
import styled from 'styled-components';
import { CONSTS } from 'src/utils/consts';
import { qbo } from '../../config';
import * as QBOLayoutElements from './QBOElements';
import { MIFormattedText } from '../../utils/formatting';
import MIButton from '../common/MIButton';
import { devices } from '../../theme/AppDevices';
import analytics from '../../services/analytics';

type Props = {
  illustration: string;
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonAction: () => void;
};

const ErrorPage = ({ illustration, title, subtitle, buttonLabel, buttonAction }: Props) => {
  const goExit = () => {
    analytics.track('bill-error', 'close-integration');
  };

  return (
    <QBOLayoutElements.QBOLayoutContainer>
      <Close onClick={goExit} />
      <ErrorContainer>
        <Illustration src={illustration} />

        <ErrorTitle>
          <MIFormattedText label={title} />
        </ErrorTitle>

        <ErrorText>
          <MIFormattedText
            label={subtitle}
            values={{
              email: <a href={`mailto:${qbo.support.email}`}>{qbo.support.email}</a>,
            }}
          />
        </ErrorText>

        <ButtonContainer>
          <MIButton label={buttonLabel} variant={CONSTS.BUTTON_VARIANT.PRIMARY} onClick={buttonAction} />
        </ButtonContainer>
      </ErrorContainer>
    </QBOLayoutElements.QBOLayoutContainer>
  );
};

ErrorPage.defaultProps = {};

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  font-size: 2rem;
  color: ${(props) => props.theme.text.color.label};
  cursor: pointer;
  z-index: 1;
`;

const Illustration = styled.img`
  height: 9.6rem;
  width: auto;
  margin-bottom: 4rem;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50rem;
    margin-bottom: 3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  ${(props) => props.theme?.pages?.ErrorLayoutPage?.ErrorContainer}
`;

const ErrorTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.normal};
  align-self: center;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;

const ErrorText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.wizardText};
  margin-bottom: 4rem;
  text-align: center;
  line-height: 2rem;
`;

export default ErrorPage;
