import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, useLocation } from 'react-router-dom';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';

export function QBDTDashboardLoader() {
  const orgId = useSelector(getOrgId);
  const location = useLocation();
  return (
    <Redirect
      to={{
        pathname: generatePath(locations.dashboard.dashboard, {
          orgId,
        }),
        search: location.search,
      }}
    />
  );
}
