import styled, { css } from 'styled-components';
import { devices } from 'src/theme/AppDevices';
import { FormButtonsAlwaysDocked } from 'src/ui/form/FormElements';

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.dark.translucent5};
`;

export const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;

  ${(props) => props.theme?.components?.ModalMessage?.ModalWrapper}
`;

export const ModalContainer = styled.div<{
  largeSize?: boolean;
  isMobileFullSize?: boolean;
}>`
  background-color: ${(props) => props.theme.colors.white.opaque};
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  z-index: 1;

  ${(props) =>
    props.isMobileFullSize &&
    css`
      @media ${devices.mobile}, ${devices.phablet} {
        max-height: 100vh;
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }

      @media ${devices.desktop}, ${devices.tablet} {
        width: ${(props: { largeSize?: boolean }) => (props.largeSize ? '67rem' : '56rem')};
        height: ${(props: { largeSize?: boolean }) => (props.largeSize ? '54rem' : 'auto')};
        margin: 0 auto;
        border-radius: 0.8rem;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(231, 231, 231, 1);
      }
    `}

  ${(props) =>
    !props.isMobileFullSize &&
    css`
      position: relative;
      width: 50rem;
      margin: 0 1rem;
      border-radius: 0.8rem;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
      border: 0.1rem solid rgba(231, 231, 231, 1);
    `}

  ${(props) => props.theme?.components?.ModalMessage?.ModalContainer}
`;

export const ModalContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2rem;
  }

  ${(props) => props.theme.components?.ModalMessage?.ModalContentContainer}
`;

export const TitleContainer = styled.div<{ alignLeft?: boolean }>`
  margin-bottom: 3rem;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: 3.2rem;
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  .title {
    font-size: 2.3rem;
    text-align: left;
    margin-bottom: 1rem;
  }
  .text {
    font-style: normal;
    text-align: left;
    font-weight: ${(props) => props.theme.text.weight.regular};
    ${(props) => props.theme.text.fontType.regular};
  }
  @media ${devices.mobile}, ${devices.phablet} {
    padding-top: 4rem;
  }
  ${(props) => props.theme.components?.ModalMessage?.TitleContainer}
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 3rem;
  color: ${(props) => props.theme.text.color.main};
  text-align: center;
  letter-spacing: 0.027rem;
  ${(props) => props.theme.text.fontType.regular};
`;

export const ContentContainer = styled.div`
  margin-bottom: 3rem;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.regular};
  ${(props) => props.theme?.components?.ModalMessage?.ContentContainer};
`;

export const SubTitleContainer = styled.div`
  letter-spacing: 0.027rem;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-align: left;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.ModalMessage?.SubTitleContainer}
`;
export const FooterContainer = styled.div`
  margin-bottom: 3.5rem;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  text-align: left;
`;

export const DockContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 2rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-sizing: border-box;
  box-shadow: 0 -0.1rem 1.2rem rgba(0, 0, 0, 0.2);
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  top: 1.8rem;
  right: 1.8rem;
  float: right;

  i {
    cursor: pointer;
  }

  color: ${(props) => props.theme.text.color.label};
  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }

  ${(props) => props.theme?.components?.ModalMessage?.CloseButtonWrapper}
`;

export const Illustration = styled.img`
  margin-bottom: 1.6rem;
  align-self: center;

  @media ${devices.desktop}, ${devices.tablet} {
  }
  height: 12rem;
  width: 12rem;

  @media ${devices.mobile}, ${devices.phablet} {
    height: 12rem;
    width: 12rem;
  }
`;

export const Icon = styled.img`
  margin-bottom: 1.6rem;
  align-self: center;
  height: 5rem;
  width: 5rem;
  ${(props) => props.theme?.components?.ModalMessage?.Icon}
`;

export const ButtonContainer = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;

  ${(props) => props.theme?.components?.ModalMessage?.ButtonContainer}
`;

export const ModalTitle = styled.div`
  font-size: 2.3rem;
  text-align: left;
  margin-bottom: 1rem;
`;

export const ModalButtonsAlwaysDocked = styled(FormButtonsAlwaysDocked)`
  @media ${devices.tablet}, ${devices.desktop} {
    padding: 2.6rem 5rem;
  }
`;
