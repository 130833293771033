import { Box } from '@melio/billpay-design-system';
import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  itemFromIndex: number;
  itemToIndex: number;
  totalCount: number;
};

export const PaginationInfo = ({ itemFromIndex, itemToIndex, totalCount }: Props) => (
  <Box textStyle="ds.body3Semi">
    <MIFormattedText
      label="paymentDashboard.pagination.info"
      values={{
        itemFromIndex,
        itemToIndex,
        totalCount,
      }}
    />
  </Box>
);
