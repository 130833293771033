import React, { useCallback, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import locations from 'src/pages/locations';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import financialAccountsApi from 'src/services/api/financialAccounts';
import { LinkCardView } from 'src/billpay/qbdt/components/basis-theory/link-card-view/LinkCardView';
import { RetrievedTokenizedData } from 'src/billpay/qbdt/components/basis-theory/types';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import analytics from 'src/services/analytics';
import { ValidateCardDetailsErrorModal } from './components/ValidateCardDetailsErrorModal';
import { useAddFundingSourceNavigator } from '../hooks/useAddFundingSourceNavigator';
import { InitialAddFundingSourceLocationState } from '../types';
import { AddCardHolderDetailsLocationState } from './AddCardHolderDetailsPage';

const eventPage = 'payment-method-credit-card';

export const AddCardDetailsPage = () => {
  const locationState = useLocation<InitialAddFundingSourceLocationState>().state;
  const { navigateOnExit } = useAddFundingSourceNavigator();
  const history = useHistory();
  const orgId = useOrgId();
  const [onValidateCard, , isCardValidating] = useApi(financialAccountsApi.validateCard);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

  const handleExit = () => {
    analytics.track(eventPage, 'exit');
    navigateOnExit();
  };

  const handleError = useCallback(() => {
    analytics.track('basis-theory', 'iframe-error-response');
    navigateOnExit();
  }, [navigateOnExit]);

  const handleLinkCard = useCallback(
    async ({
      id,
      expirationMonth,
      expirationYear,
      last4Digits,
      cardBin,
      cardNumberIdentifiers,
    }: RetrievedTokenizedData) => {
      analytics.track('basis-theory', 'iframe-response');
      analytics.track('basis-theory', 'iframe-response-data');

      if (!id) {
        navigateOnExit();
        return;
      }

      try {
        await onValidateCard(orgId, {
          token: id,
          cardBin,
          cardNumberFingerprint: cardNumberIdentifiers.fingerprint,
          cardNumberToken: cardNumberIdentifiers.token,
        });

        analytics.track('basis-theory', 'iframe-response-success');

        const nextPageLocationState: AddCardHolderDetailsLocationState = {
          ...locationState,
          token: id,
          digits: last4Digits,
          expiration: `${expirationMonth}/${expirationYear.toString().slice(-2)}`,
          cardBin,
        };
        history.push(generatePath(locations.qbdt.fundingSource.cardHolder, { orgId }), nextPageLocationState);
      } catch (e: any) {
        analytics.track(eventPage, 'add-card-error');
        setErrorCode(e?.code);
      }
    },
    [onValidateCard, orgId, locationState, navigateOnExit, history]
  );

  const handleHideErrorMessageAlert = () => {
    setErrorCode(undefined);
  };

  return (
    <QBDTStepLayout
      goExit={handleExit}
      title="onboarding.fundingSources.card.iframeTitle"
      innerSize={75}
      isHideActions
      hideHeader
    >
      {errorCode && <ValidateCardDetailsErrorModal errorCode={errorCode} onCloseModal={handleHideErrorMessageAlert} />}
      <LinkCardView
        onLinkCard={handleLinkCard}
        onError={handleError}
        showComplianceMessage
        showLinkCardButtonSpinner={isCardValidating}
      />
    </QBDTStepLayout>
  );
};
