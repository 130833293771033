import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import NoData from './NoData';

export function QBDTSyncWizardRouter() {
  const match = useRouteMatch();

  return (
    <Switch>
      <SmartRoute path={`${match.path}/no-data`} exact>
        <NoData />
      </SmartRoute>
    </Switch>
  );
}
