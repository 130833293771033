import React, { useCallback } from 'react';
import { Link as QBOLink, LinkProps } from '@chakra-ui/react';
import { ToNavigationType } from 'src/utils/types';
import analytics from 'src/services/analytics/index';

type Props = LinkProps & {
  to?: ToNavigationType;
};

const Link = ({ children, ...rest }: Props) => {
  const onClickWithEvent = useCallback(
    (event) => {
      analytics.trackAction('navigate', { navigateTo: rest.to || rest.href });
      rest.onClick && rest.onClick(event);
    },
    [rest]
  );
  return (
    <QBOLink {...rest} onClick={onClickWithEvent}>
      {children}
    </QBOLink>
  );
};

export default Link;
