import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { getMelioId } from './entities/dataExtension';
import {
  QBDTAccount,
  QBDTAccountRef,
  QBDTAccountType,
  QBDTBill,
  QBDTBillPaymentCheck,
  QBDTBillPaymentCreditCard,
  QBDTCashFlowSpecification,
  QBDTDataExtDef,
  QBDTLinkedTxn,
  QBDTLinkType,
  QBDTSpecialAccountType,
  QBDTTxnType,
  QBDTVendor,
  QBDTVendorRef,
  QBDTCompany,
  QBDTExpenseLineRet,
  QBDTAddress,
  QBDTPreferences,
} from './types';

function extract<T>(doc: Element | null, path, mapper?: (str: string) => T): T {
  const value = doc?.querySelector(path)?.textContent;

  return mapper ? mapper(value) : value;
}

const mappers = {
  date(str: string): Date {
    return new Date(str);
  },

  float(str: string): number {
    return parseFloat(str);
  },

  number(str: string): number {
    return parseInt(str, 10);
  },

  boolean(str: string): boolean {
    return str === 'true';
  },
};

export function mapQBDTAccountRef(doc: Element | null): QBDTAccountRef {
  return {
    ListID: extract(doc, 'ListID'),
    FullName: extract(doc, 'FullName'),
  };
}

export function mapQBDTVendorRef(doc: Element | null): QBDTVendorRef {
  return {
    ListID: extract(doc, 'ListID'),
    FullName: extract(doc, 'FullName'),
  };
}

export function mapQBDTLinkedTxn(doc: Element): QBDTLinkedTxn {
  return {
    TxnID: extract(doc, 'TxnID'),
    TxnType: extract(doc, 'TxnType') as QBDTTxnType,
    TxnDate: extract(doc, 'TxnDate'),
    LinkType: extract(doc, 'LinkType') as QBDTLinkType,
    Amount: extract(doc, 'Amount', mappers.float),
  };
}

export function mapQBDTExpenseLine(doc: Element): QBDTExpenseLineRet {
  return {
    TxnLineID: extract(doc, 'TxnLineID'),
    AccountRef: mapQBDTAccountRef(doc.querySelector('AccountRef')),
    Amount: extract(doc, 'Amount', mappers.float),
  };
}

export function mapQBDTBill(doc: Element): QBDTBill {
  return {
    TxnID: extract(doc, 'TxnID'),
    TimeCreated: extract<Date>(doc, 'TimeCreated', mappers.date),
    TimeModified: extract<Date>(doc, 'TimeModified', mappers.date),
    EditSequence: extract(doc, 'EditSequence'),
    TxnNumber: extract(doc, 'TxnNumber'),
    TxnDate: extract(doc, 'TxnDate'),
    DueDate: extract(doc, 'DueDate'),
    AmountDue: extract(doc, 'AmountDue', mappers.float),
    IsPaid: extract(doc, 'IsPaid', mappers.boolean),
    OpenAmount: extract(doc, 'OpenAmount', mappers.float),
    VendorRef: mapQBDTVendorRef(doc.querySelector('VendorRef')),
    APAccountRef: mapQBDTAccountRef(doc.querySelector('APAccountRef')),
    MelioId: getMelioId(doc),
    LinkedTxn: Array.from(doc.querySelectorAll('LinkedTxn')).map(mapQBDTLinkedTxn),
    ExpenseLineRet: Array.from(doc.querySelectorAll('ExpenseLineRet')).map(mapQBDTExpenseLine),
    RefNumber: extract(doc, 'RefNumber'),
    Memo: extract(doc, 'Memo'),
  };
}

export function mapQBDTPreferences(doc: Element): QBDTPreferences {
  return {
    ClosingDate: extract(doc, 'ClosingDate'),
  };
}

function validateEmail(contactEmail: string): any {
  if (!contactEmail) {
    return undefined;
  }

  const validations = getValidationErrors('vendor', { contactEmail }, 'contactEmail');
  return isValidationOk(validations) ? contactEmail : undefined;
}
export function mapQBDTVendor(doc: Element): QBDTVendor {
  return {
    ListID: extract(doc, 'ListID'),
    TimeCreated: extract<Date>(doc, 'TimeCreated', mappers.date),
    TimeModified: extract<Date>(doc, 'TimeModified', mappers.date),
    EditSequence: extract(doc, 'EditSequence'),
    Name: extract(doc, 'Name'),
    IsActive: extract(doc, 'IsActive', mappers.boolean),
    IsVendorEligibleFor1099: extract(doc, 'IsVendorEligibleFor1099', mappers.boolean),
    Balance: extract(doc, 'Balance', mappers.float),
    Phone: extract(doc, 'Phone'),
    Email: validateEmail(extract(doc, 'Email')),
    FirstName: extract(doc, 'FirstName'),
    MiddleName: extract(doc, 'MiddleName'),
    LastName: extract(doc, 'LastName'),
    Address: mapAddress(doc, 'VendorAddress'),
    NameOnCheck: extract(doc, 'NameOnCheck'),
    CompanyName: extract(doc, 'CompanyName'),
  };
}
function mapAddress(doc: Element, prefix: string): QBDTAddress {
  return {
    Addr1: extract(doc, `${prefix} Addr1`),
    Addr2: extract(doc, `${prefix} Addr2`),
    City: extract(doc, `${prefix} City`),
    State: extract(doc, `${prefix} State`),
    PostalCode: extract(doc, `${prefix} PostalCode`),
    Country: extract(doc, `${prefix} Country`),
  };
}
export function mapQBDTCompany(doc: Element): QBDTCompany {
  return {
    CompanyName: extract(doc, 'CompanyName'),
    LegalCompanyName: extract(doc, 'LegalCompanyName'),
    Address: mapAddress(doc, 'Address'),
    LegalAddress: mapAddress(doc, 'LegalAddress'),
    Phone: extract(doc, 'Phone'),
    Email: extract(doc, 'Email'),
    EIN: extract(doc, 'EIN'),
    SSN: extract(doc, 'SSN'),
  };
}
export function mapQBDTAccount(doc: Element): QBDTAccount {
  return {
    ListID: extract(doc, 'ListID'),
    TimeCreated: extract<Date>(doc, 'TimeCreated', mappers.date),
    TimeModified: extract<Date>(doc, 'TimeModified', mappers.date),
    EditSequence: extract(doc, 'EditSequence'),
    Name: extract(doc, 'Name'),
    FullName: extract(doc, 'FullName'),
    IsActive: extract(doc, 'IsActive', mappers.boolean),
    Sublevel: extract(doc, 'IsActive', mappers.number),
    AccountType: extract(doc, 'AccountType') as QBDTAccountType,
    SpecialAccountType: extract(doc, 'SpecialAccountType') as QBDTSpecialAccountType,
    AccountNumber: extract(doc, 'AccountNumber'),
    Desc: extract(doc, 'Desc'),
    Balance: extract(doc, 'OpenAmount', mappers.float),
    TotalBalance: extract(doc, 'OpenAmount', mappers.float),
    CashFlowClassification: extract(doc, 'CashFlowClassification') as QBDTCashFlowSpecification,
  };
}
export function mapQBDTBillPaymentCheck(doc: Element): QBDTBillPaymentCheck {
  return {
    TxnType: QBDTTxnType.BillPaymentCheck,
    TxnID: extract(doc, 'TxnID'),
    TimeCreated: extract<Date>(doc, 'TimeCreated', mappers.date),
    TimeModified: extract<Date>(doc, 'TimeModified', mappers.date),
    EditSequence: extract(doc, 'EditSequence'),
    TxnNumber: extract(doc, 'TxnNumber', mappers.number),
    PayeeEntityRef: mapQBDTVendorRef(doc.querySelector('PayeeEntityRef')),
    APAccountRef: mapQBDTAccountRef(doc.querySelector('APAccountRef')),
    TxnDate: extract(doc, 'TxnDate'),
    BankAccountRef: mapQBDTAccountRef(doc.querySelector('BankAccountRef')),
    Amount: extract(doc, 'Amount', mappers.float),
    IsToBePrinted: extract(doc, 'IsToBePrinted', mappers.boolean),
    LinkedTxn: Array.from(doc.querySelectorAll('AppliedToTxnRet')).map(mapQBDTLinkedTxn),
  };
}

export function mapQBDTBillPaymentCreditCard(doc: Element): QBDTBillPaymentCreditCard {
  return {
    TxnType: QBDTTxnType.BillPaymentCreditCard,
    TxnID: extract(doc, 'TxnID'),
    TimeCreated: extract(doc, 'TimeCreated', mappers.date),
    TimeModified: extract(doc, 'TimeModified', mappers.date),
    EditSequence: extract(doc, 'EditSequence'),
    TxnNumber: extract(doc, 'TxnNumber', mappers.number),
    PayeeEntityRef: mapQBDTVendorRef(doc.querySelector('PayeeEntityRef')),
    APAccountRef: mapQBDTAccountRef(doc.querySelector('APAccountRef')),
    TxnDate: extract(doc, 'TxnDate'),
    CreditCardAccountRef: mapQBDTAccountRef(doc.querySelector('CreditCardAccountRef')),
    Amount: extract(doc, 'Amount', mappers.float),
    LinkedTxn: Array.from(doc.querySelectorAll('AppliedToTxnRet')).map(mapQBDTLinkedTxn),
  };
}

export function mapQBDTDataExtDef(doc: Element): QBDTDataExtDef {
  return {
    AssignToObject: extract(doc, 'AssignToObject'),
    DataExtFormatString: extract(doc, 'DataExtFormatString'),
    DataExtID: extract(doc, 'DataExtID', mappers.number),
    DataExtListRequire: extract(doc, 'DataExtListRequire', mappers.boolean),
    DataExtName: extract(doc, 'DataExtName'),
    DataExtTxnRequire: extract(doc, 'DataExtTxnRequire', mappers.boolean),
    DataExtType: extract(doc, 'DataExtType'),
    OwnerID: extract(doc, 'OwnerID'),
  };
}
