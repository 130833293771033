import reduce from 'lodash/reduce';
import countBy from 'lodash/countBy';
import sumBy from 'lodash/sumBy';
import flatMap from 'lodash/flatMap';
import analytics from 'src/services/analytics';
import { DashboardListItemTypeResponse, DashboardListParams } from './types';

type Params = {
  payload: {
    totalCount: number;
    items: DashboardListItemTypeResponse[];
  };
  meta: {
    identifier: DashboardListParams;
  };
};

const getDashboardItemsTotalAmount = (items: DashboardListItemTypeResponse[]) =>
  reduce(items, (sum, item) => sum + sumBy(item.bills, 'totalAmount'), 0);

const getDashboardItemsBillIds = (items: DashboardListItemTypeResponse[]) => flatMap(items, 'billIds');

export const trackBillListLoaded = ({ payload, meta }: Params, isSyncing: boolean) => {
  const totalAmount = getDashboardItemsTotalAmount(payload.items);
  const billIds = getDashboardItemsBillIds(payload.items);
  const billStatus = countBy(payload.items, (item) => item.metadata.statusInDashboard);
  const { totalCount } = payload;
  const { filters } = meta.identifier;
  const tabName = filters.status;
  analytics.trackAction('dashboard-list-action', {
    totalAmount,
    billStatus,
    billIds,
    totalCount,
    tabName,
    filters,
    withSyncLoader: isSyncing,
  });
};

const BILL_IDS_SEPARATOR = ';';
const PAYMENT_ID_SEPARATOR = '_';

export function getDashboardItemId(billIds?: string[] | number[], paymentId?: string): string {
  const paymentIdPostfix = parseInt(paymentId || '', 10) ? `${PAYMENT_ID_SEPARATOR}${paymentId}` : '';
  return `${billIds?.join(BILL_IDS_SEPARATOR)}${paymentIdPostfix}`;
}
