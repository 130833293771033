import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { PopoverAnchor, useDisclosure } from '@chakra-ui/react';
import { Box, useOutsideClickWithIgnored } from '@melio/billpay-design-system';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Moment } from 'moment';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import { useBatchSettings } from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchSettings';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { ReactComponent as CalendarIcon } from 'src/images/regular-batch-payments/calendar-small.svg';
import { DATE_FORMATS } from 'src/utils/date-fns';
import { CONSTS } from 'src/utils/consts';
import { Portal } from 'src/core/ds/portal';
import { Popover, PopoverContent, PopoverBody } from 'src/core/ds/popover';
import DatePicker from 'src/components/form/DatePicker';
import { isBusinessDay } from 'src/utils/dates';
import analytics from 'src/services/analytics';
import { MenuButtonContent } from './MenuButtonContent';

const IGNORE_OUTSIDE_CLICK_CLASS = 'ignore-outsideClick';

const DeductionDatePicker = () => {
  const { isBillListLoading, billsList } = useBatchBillsList();
  const { batchSettings, changeBatchSettings } = useBatchSettings();
  const { scheduledDate, minScheduledDate } = batchSettings;
  const deductionDate = new Date(scheduledDate || minScheduledDate || new Date());

  const isMultipleFundingSource = useSelector(regularBatchPaymentsStore.selectors.isMultipleFundingSource);

  const { isOpen, onClose, onToggle } = useDisclosure();
  const popoverContentRef = useRef<HTMLDivElement>(null);

  useOutsideClickWithIgnored({
    ref: popoverContentRef,
    handler: onClose,
    ignoredClassName: IGNORE_OUTSIDE_CLICK_CLASS,
  });

  const handleAnchorClick = () => {
    if (!isOpen) {
      analytics.trackAction('regularBatchPayments.settings.deductionDate.choose');
    }

    onToggle();
  };

  const handleDateSelected = ({ date }: { date: Date | null }) => {
    analytics.trackAction('regularBatchPayments.settings.deductionDate.selected');

    onClose();

    const dateString = date?.toISOString() || null;

    if ((scheduledDate && new Date(scheduledDate) !== date) || !scheduledDate) {
      changeBatchSettings({ scheduledDate: dateString }, { refreshBillsList: true });
    }
  };

  const formattedDeductionDate = format(deductionDate, DATE_FORMATS.monthShortWithLongDateAndYear);

  const isDisabled = isMultipleFundingSource || isBillListLoading || !billsList.length;
  const renderIcon = () => <CalendarIcon />;
  const renderLabel = () => formattedDeductionDate;
  const getFilteredDate = (date: Moment) => isBusinessDay(date);

  return (
    <Box pt="2.4rem">
      <Popover isLazy placement="bottom-start" isOpen={isOpen}>
        <>
          <PopoverAnchor>
            <Box
              className={IGNORE_OUTSIDE_CLICK_CLASS}
              onClick={handleAnchorClick}
              pointerEvents={isDisabled ? 'none' : 'auto'}
            >
              <MenuButtonContent
                isOpen={isOpen}
                isDisabled={isDisabled}
                renderLabel={renderLabel}
                renderIcon={renderIcon}
              />
            </Box>
          </PopoverAnchor>

          <Portal>
            <PopoverContent
              ref={popoverContentRef}
              as="div"
              zIndex={50}
              outline="none"
              display={isOpen ? 'block' : 'none'}
            >
              <PopoverBody>
                <DatePickerWrapper>
                  <DatePicker
                    id="deductionDate"
                    date={deductionDate}
                    dueDate={null}
                    required
                    size={CONSTS.TEXT_INPUT_SIZE.INLINE}
                    inline
                    min={new Date(minScheduledDate || new Date())}
                    onChange={handleDateSelected}
                    overrideMobile
                    filterDate={getFilteredDate}
                    calendarContainerClass="small-calendar"
                  />
                </DatePickerWrapper>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      </Popover>
    </Box>
  );
};

const DatePickerWrapper = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled
      ? `
    opacity: 0.5;
    pointer-events: none;
  `
      : ''};
  .react-datepicker {
    border-radius: var(--chakra-radii-ds-lg);
    border: 1px solid var(--chakra-colors-ds-gray-600);
    box-shadow: var(--chakra-shadows-ds-sm);
  }
  .react-datepicker__day {
    color: ${(props) => props.theme.text.color.main};
  }
  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.text.color.light};
  }
  .react-datepicker__current-month {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .react-datepicker__navigation {
    top: 2.4rem;
    width: 1.5rem;
  }
  .react-datepicker__day-name {
    color: #393a3d;
  }
  .react-datepicker__month-container {
    padding: 2rem;
  }
`;

export { DeductionDatePicker };
