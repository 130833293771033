import * as React from 'react';
import { ReactNode } from 'react';
import moment, { Moment } from 'moment';
import ReactDatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';
import { DateFieldType, TextInputSize } from '../../utils/types';
import { useBreak } from '../../hoc';
import { MITextInputPassthrough } from '../common/MITextInput';
import './DatePicker.css';
import { CONSTS } from '../../utils/consts';

type Props = {
  id: string;
  date?: Date | string | null;
  dueDate?: Date | null;
  min?: Date;
  max?: Date;
  openToDate?: Date;
  filterDate?: (value: Moment) => boolean;
  dateFormat?: string;
  placeholder?: string;
  label?: string;
  notices?: Array<string>;
  errorMessage?: string | null;
  disabled?: boolean;
  required?: boolean;
  size?: TextInputSize;
  onChange: (value: DateFieldType) => void | Promise<void>;
  inline?: boolean;
  withBottomElement?: boolean;
  overrideMobile?: boolean;
  testId?: string | null;
  onInputClick?: () => void;
  customInput?: ReactNode;
  onBlur?: () => void;
  onMonthChange?: (month: Date) => void;
  useNativeMobileDatePicker?: boolean;
  isClearable?: boolean;
  calendarContainerClass?: string;
};

const DatePicker = React.forwardRef(
  (
    {
      id,
      date,
      dateFormat,
      placeholder,
      label,
      notices,
      errorMessage,
      disabled,
      required,
      size,
      onChange,
      inline,
      min,
      max,
      filterDate,
      overrideMobile,
      withBottomElement,
      openToDate,
      testId,
      dueDate,
      onInputClick,
      customInput,
      onBlur,
      onMonthChange,
      useNativeMobileDatePicker = true,
      isClearable = true,
      calendarContainerClass = undefined,
    }: Props,
    ref
  ) => {
    const device = useBreak();

    const handleChange = (selectedDate) => {
      onChange({ id, date: selectedDate ? selectedDate.toDate() : null });
    };

    // This logic is needed to make `Clear` button for iOS date picker works properly
    // See details https://github.com/facebook/react/issues/8938
    const resetMobileDefaultValue = (event) => {
      const { target } = event.nativeEvent;

      setTimeout(() => {
        target.defaultValue = '';
      }, 0);
    };

    const handleMobileChange = (event) => {
      const { value } = event.target;

      handleChange(moment(value));
      resetMobileDefaultValue(event);
    };
    const ignoreChanges = () => undefined;
    // ReactDatePicker overrides some customInput params with its own params.
    // Therefore, these params have to be passed as props for ReactDatePicker and not MITextInputPassthrough:
    // id, name, placeholder (as placeholderText), disabled, autoComplete, title, readonly, required, tabIndex
    const customTextInput = (
      <MITextInputPassthrough
        size={size}
        label={label}
        onChange={ignoreChanges}
        value={date}
        notices={notices}
        errorMessage={errorMessage}
        readOnlyValue
      />
    );

    const isFullSingleView = (device.isMobile || device.isPhablet) && !overrideMobile;
    const formattedDate = date ? moment(date).format().substring(0, 10) : undefined;
    const minFormatted = isFullSingleView && min ? moment(min).format('YYYY-MM-DD') : min;
    const maxFormatted = isFullSingleView && max ? moment(max).format('YYYY-MM-DD') : max;
    const calendarClassName =
      calendarContainerClass ||
      `${inline ? 'big-calender' : 'small-calendar'} ${withBottomElement ? 'with-bottom-element' : ''}`;
    const getDayClassName = (date) => {
      let className;
      if (moment(date).isSame(dueDate, 'day') && calendarClassName.includes('small-calendar')) {
        className = 'highlightToday';
      } else if (moment(date).isSame(dueDate, 'day')) {
        className = 'highlightDueDate';
      }

      return className;
    };
    const inputTestId = testId || `input-${id}`;
    return (
      <DatePickerContainer
        inline={inline}
        size={size}
        data-testid={`${inputTestId}-container`}
        customInput={customInput}
      >
        {isFullSingleView && useNativeMobileDatePicker && (
          <MITextInputPassthrough
            inputRef={ref}
            id={id}
            size={size}
            label={label}
            value={formattedDate}
            notices={notices}
            onChange={(e) => handleMobileChange(e)}
            onFocus={(e) => resetMobileDefaultValue(e)}
            type="date"
            errorMessage={errorMessage}
            min={minFormatted}
            max={maxFormatted}
            required={required}
            testId={`${inputTestId}-passthrough`}
          />
        )}
        {isFullSingleView && !useNativeMobileDatePicker && (
          <ReactDatePicker
            id={id}
            customInput={customInput || customTextInput}
            selected={date ? moment(date) : undefined}
            onChange={handleChange}
            dateFormat={dateFormat}
            dropdownMode="select"
            placeholderText={placeholder}
            required={required}
            disabled={disabled}
            disabledKeyboardNavigation
            popperPlacement="bottom"
            isClearable
            calendarClassName={calendarClassName}
            minDate={minFormatted}
            maxDate={maxFormatted}
            filterDate={filterDate && ((day) => filterDate(day.hour(12)))}
            openToDate={openToDate}
            data-testid={inputTestId}
            dayClassName={(date) => getDayClassName(date)}
            onInputClick={onInputClick}
            onBlur={onBlur}
            onMonthChange={onMonthChange}
            popperClassName="react-datepicker-popper-mobile"
          />
        )}
        {!isFullSingleView && (
          <React.Fragment>
            <ReactDatePicker
              ref={ref}
              id={id}
              customInput={customInput || customTextInput}
              selected={date ? moment(date) : undefined}
              onChange={handleChange}
              dateFormat={dateFormat}
              dropdownMode="select"
              placeholderText={placeholder}
              required={required}
              disabled={disabled}
              disabledKeyboardNavigation
              popperPlacement="bottom"
              isClearable={isClearable}
              inline={inline}
              calendarClassName={calendarClassName}
              minDate={minFormatted}
              maxDate={maxFormatted}
              filterDate={filterDate && ((day) => filterDate(day.hour(12)))}
              openToDate={openToDate}
              data-testid={inputTestId}
              dayClassName={(date) => getDayClassName(date)}
              onInputClick={onInputClick}
              onBlur={onBlur}
              onMonthChange={onMonthChange}
            />
          </React.Fragment>
        )}
      </DatePickerContainer>
    );
  }
);

DatePicker.defaultProps = {
  dateFormat: 'MMM D, YYYY',
  disabled: false,
  required: false,
  notices: [],
  errorMessage: null,
  placeholder: '',
  size: CONSTS.TEXT_INPUT_SIZE.WIZARD,
  inline: false,
  withBottomElement: false,
  min: undefined,
  max: undefined,
  filterDate: undefined,
  overrideMobile: false,
  openToDate: undefined,
  testId: null,
};

export default DatePicker;

const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
  ${(props) =>
    props.inline &&
    css`
      text-align: center;
    `}
  & .react-datepicker__close-icon {
    top: ${(props) => (props.size === CONSTS.TEXT_INPUT_SIZE.WIZARD ? '35px' : '25px')};
    display: ${(props) => props.customInput && 'none'};
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${(props) => props.theme.colors.brand};
  }
  .react-datepicker__day--today.react-datepicker__day--selected {
    color: #fff !important;
  }
  ${(props) => props.theme?.components?.DatePicker?.Container}
`;
