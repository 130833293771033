import React, { useCallback } from 'react';
import styled from 'styled-components';
import { MicroDepositState, MicroDepositActions } from 'src/pages/settings/hooks/microDepositsCommon';
import { MIFormattedText } from 'src/utils/formatting';
import {
  ContentContainer,
  ModalContainer,
  ModalContentContainer,
  ModalMessage,
} from 'src/components/common/ModalMessage';
import { getMicrodepositMask } from 'src/utils/currency-utils';
import { isEnterPressed } from 'src/utils/events';
import { MicroDepositPopover } from 'src/pages/funding-sources/components/MicroDepositPopover';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';
import { ButtonContainer, TitleContainer } from 'src/components/common/ModalMessage/ModalMessage.styles';
import { MITextInput } from 'src/components/common/MITextInput';
import { useMicroDepositAmountValidation } from 'src/hooks/settings/microDepositsCommon';
import {
  ErrorMessageIcon,
  MicroDepositsErrorMessage,
} from 'src/pages/funding-sources/components/MicroDepositsErrorMessage';

type Props = { digits: string } & MicroDepositState & MicroDepositActions;

function onKeyPressed(onSubmit, event: React.KeyboardEvent<any>) {
  if (isEnterPressed(event)) {
    onSubmit();
  }
}
export function VerifyMicroDepositsModal(props: Props) {
  const {
    amount1,
    amount2,
    onChange,
    validationErrors,
    serverError,
    onSubmit,
    dismiss,
    isLoading,
    companyName,
    digits,
  } = props;

  const [errors, handleChange, resetErrors] = useMicroDepositAmountValidation(onChange);

  const handleSubmit = useCallback(() => {
    if (errors.amount1 || errors.amount2) {
      return;
    }

    resetErrors();
    onSubmit();
  }, [resetErrors, onSubmit]);

  const keyPressed = useCallback((event) => onKeyPressed(handleSubmit, event), [handleSubmit]);

  return (
    <StyledModalMessage
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText label="settings.microDeposits.verifyModal.title" />
          </div>
          <div className="text">
            <MIFormattedText label="settings.microDeposits.verifyModal.subtitle" values={{ companyName, digits }} />
          </div>
        </>
      }
      contentComponent={
        <>
          <div className="form-subtitle">
            <MIFormattedText label="settings.microDeposits.verifyModal.formSubtitle" />
          </div>
          <SumsFormContainer onKeyDown={keyPressed}>
            <TextInputContainer>
              <MITextInput
                id="amount1"
                label="settings.microDeposits.verifyModal.sum1Label"
                placeholder="settings.microDeposits.verifyModal.placeholder"
                value={amount1}
                autoFocus
                required
                onChange={handleChange}
                errorMessage={validationErrors.amount1 || errors.amount1}
                errorMessageIcon={<ErrorMessageIcon className="icon-warning-icon" size={1.6} />}
                mask={getMicrodepositMask(true)}
              />
            </TextInputContainer>
            <TextInputContainer>
              <MITextInput
                id="amount2"
                label="settings.microDeposits.verifyModal.sum2Label"
                placeholder="settings.microDeposits.verifyModal.placeholder"
                value={amount2}
                required
                onChange={handleChange}
                errorMessage={validationErrors.amount2 || errors.amount2}
                errorMessageIcon={<ErrorMessageIcon className="icon-warning-icon" size={1.6} />}
                mask={getMicrodepositMask(true)}
              />
            </TextInputContainer>
          </SumsFormContainer>
          {serverError && <MicroDepositsErrorMessage serverError={serverError} />}
          <MicroDepositPopover />
        </>
      }
      buttonComponent={
        <ButtonContainer>
          <MIButton
            fullWidth
            onClick={handleSubmit}
            label="settings.microDeposits.verifyModal.confirm"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            isProcessing={isLoading}
          />
        </ButtonContainer>
      }
      onCloseClick={dismiss}
      isLoading={isLoading}
    />
  );
}

const SumsFormContainer = styled.form`
  display: flex;
`;

const TextInputContainer = styled.div`
  width: 100%;
  &:first-child {
    margin-right: 2rem;
  }
  .input-container {
    margin-bottom: 0;
  }
`;

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow: initial;
    width: 57.6rem;
  }

  ${ModalContentContainer} {
    padding: 4rem;
  }

  ${TitleContainer} {
    margin-bottom: 0;
  }

  ${ButtonContainer} {
    width: 10rem;
    margin: 0 auto;
  }

  ${ContentContainer} {
    margin-bottom: 4.2rem;
  }

  .title {
    font-size: 2.4rem;
    margin-bottom: 0.8rem;
  }

  .form-subtitle {
    margin: 1.2rem 0 3rem 0;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;
