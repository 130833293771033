import { BillType } from 'src/utils/types';

import { amountFormat, formatQBDTDate, formatQBDTDateTime } from '../util';
import { mapQBDTBill } from '../typeMapper';
import { DATA_EXT_UUID } from './dataExtension';
import { QBDTAccount, QBDTBill, QBDTVendor } from '../types';
import { batchQueryWithIterator, executeSDKRequest, getAllEntities, getSingleEntity, QBDTSDKError } from '../sdk';

/**
 * Get bill by ID
 * @param billId [string] The bill ID to fetch
 */
export function getBill(billId: string) {
  return getSingleEntity(
    `<BillQueryRq>
      <TxnID>${billId}</TxnID>
      <IncludeLinkedTxns>true</IncludeLinkedTxns>
      <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
    </BillQueryRq>`,
    mapQBDTBill
  );
}

export function getBills(billIds: string[]) {
  return getAllEntities<QBDTBill>(
    `<BillQueryRq>
      ${billIds.map((billId) => `<TxnID>${billId}</TxnID>`).join('')}
      <IncludeLinkedTxns>true</IncludeLinkedTxns>
      <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
    </BillQueryRq>`,
    'BillRet',
    mapQBDTBill
  );
}

export function getBillForUnpaidRecheck(billId: string) {
  return getSingleEntity(
    `<BillQueryRq>
      <TxnID>${billId}</TxnID>
      <IncludeRetElement>TxnID</IncludeRetElement>
      <IncludeRetElement>IsPaid</IncludeRetElement>
      <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
    </BillQueryRq>`,
    mapQBDTBill
  );
}

export function getBillsForUnpaidRecheck(billIds: string[]) {
  return getAllEntities<QBDTBill>(
    `<BillQueryRq>
      ${billIds.map((billId) => `<TxnID>${billId}</TxnID>`).join('')}
      <IncludeRetElement>TxnID</IncludeRetElement>
      <IncludeRetElement>IsPaid</IncludeRetElement>
      <OwnerID>{${DATA_EXT_UUID}}</OwnerID>
    </BillQueryRq>`,
    'BillRet',
    mapQBDTBill
  );
}

export function getBatchBillsModifiedSince(fromDate: Date, toDate: Date) {
  return batchQueryWithIterator(
    `
    <BillQueryRq>
      <ModifiedDateRangeFilter>
        <FromModifiedDate>${formatQBDTDateTime(fromDate)}</FromModifiedDate>
        <ToModifiedDate>${formatQBDTDateTime(toDate)}</ToModifiedDate>
      </ModifiedDateRangeFilter>
  </BillQueryRq>`,
    mapQBDTBill
  );
}

export async function createBill(
  melioBill: BillType,
  qbdtVendor: QBDTVendor,
  apAccount: QBDTAccount,
  expenseAccount: QBDTAccount
) {
  const result = await executeSDKRequest(
    `<BillAddRq>
        <BillAdd>
          <VendorRef>
            <ListID>${qbdtVendor.ListID}</ListID>
          </VendorRef>
          <TxnDate>${formatQBDTDate(new Date(melioBill.invoiceDate))}</TxnDate>
          <DueDate>${formatQBDTDate(new Date(melioBill.dueDate))}</DueDate>
          <RefNumber>${melioBill.invoiceNumber}</RefNumber>
          <ExpenseLineAdd>
            <AccountRef>
              <ListID>${expenseAccount.ListID}</ListID>
            </AccountRef>
            <Amount>${amountFormat(melioBill.totalAmount)}</Amount>
          </ExpenseLineAdd>
        </BillAdd>
      </BillAddRq>`
  );

  return mapQBDTBill(result.querySelector('BillRet'));
}

export async function sampleBillFromVendor(qbdtVendor: QBDTVendor) {
  try {
    return await getSingleEntity(
      `<BillQueryRq>
        <MaxReturned>1</MaxReturned>
        <EntityFilter>
          <ListID>${qbdtVendor.ListID}</ListID>
        </EntityFilter>
        <IncludeLineItems>true</IncludeLineItems>
      </BillQueryRq>`,
      mapQBDTBill
    );
  } catch (error: any) {
    if (error instanceof QBDTSDKError && error.code === '1') {
      return null;
    }

    throw error;
  }
}
