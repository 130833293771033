import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import MIButton from 'src/components/common/MIButton';
import { devices } from 'src/theme/AppDevices';
import { BUTTON_SIZE, BUTTONS_DIRECTIONS, CONSTS, IMAGE_POSITION } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { ModalDialogSettings, ModalDialogTypes, ModalDialogVariants } from 'src/utils/types';
import styled from 'styled-components';

const QBOMIDialog = ({
  image,
  imagePosition = IMAGE_POSITION.CENTER,
  type,
  variant,
  title,
  titleValues,
  subtitle,
  subtitleValues,
  okButtonText,
  onOkAction,
  cancelButtonText,
  onCancelAction,
  onOkDisabled,
  showCancel = true,
  buttonsDirection = BUTTONS_DIRECTIONS.HORIZONTAL,
  buttonsSize = BUTTON_SIZE.VERY_SMALL,
  className,
}: ModalDialogSettings) => {
  const [loading, setLoading] = useState(false);
  const renderCancelButtonText = (
    buttonText: string,
    dialogType: ModalDialogTypes,
    dialogVariant: ModalDialogVariants
  ) => {
    if (!buttonText) {
      return dialogType === CONSTS.DIALOG_TYPE.ALERT && dialogVariant === CONSTS.DIALOG_VARIANTS.SUCCESS
        ? 'dialogs.buttons.done'
        : 'dialogs.buttons.cancel';
    }

    return buttonText;
  };

  const onOk = () => {
    const res: any = onOkAction && onOkAction();
    if (res?.finally) {
      setLoading(true);
      res.finally(() => setLoading(false));
    }
  };

  return ReactDOM.createPortal(
    <DialogContainer className={className}>
      <Dialog>
        <Close>
          <CloseAction onClick={onCancelAction}>
            <i className="icon-close-icon" />
          </CloseAction>
        </Close>
        {image && <Illustration src={image} imagePosition={imagePosition} />}
        <TextsContainer>
          <TitleContainer>
            <MIFormattedText label={title} values={titleValues} />
          </TitleContainer>
          {subtitle && (
            <SubtitleContainer>
              <MIFormattedText label={subtitle} values={subtitleValues} />
            </SubtitleContainer>
          )}

          {type === CONSTS.DIALOG_TYPE.CONFIRM && okButtonText && onOkAction && onCancelAction && showCancel && (
            <SubtitleContainer>
              <MIFormattedText label="general.areYouSure" />
            </SubtitleContainer>
          )}
        </TextsContainer>

        <ActionsContainer
          showButtons={CONSTS.DIALOG_TYPE.CONFIRM && onCancelAction && showCancel}
          buttonsDirection={buttonsDirection}
        >
          {onCancelAction && showCancel && (
            <ButtonContainer buttonsDirection={buttonsDirection}>
              <MIButton
                variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                onClick={onCancelAction}
                label={renderCancelButtonText(cancelButtonText || '', type, variant)}
                fullWidth
                size={buttonsSize}
                disabled={loading}
              />
            </ButtonContainer>
          )}
          {type === CONSTS.DIALOG_TYPE.CONFIRM && okButtonText && onOkAction && (
            <ButtonContainer buttonsDirection={buttonsDirection}>
              <MIButton
                variant={
                  variant === CONSTS.DIALOG_VARIANTS.SUCCESS && onOkAction && okButtonText
                    ? CONSTS.BUTTON_VARIANT.PRIMARY
                    : CONSTS.BUTTON_VARIANT.DESTRUCTIVE
                }
                onClick={onOk}
                label={okButtonText}
                fullWidth
                size={buttonsSize}
                isProcessing={loading}
                disabled={onOkDisabled}
              />
            </ButtonContainer>
          )}
        </ActionsContainer>
      </Dialog>
    </DialogContainer>,
    document.querySelector('body')
  );
};

export default QBOMIDialog;

const directions = {
  [BUTTONS_DIRECTIONS.VERTICAL]: {
    dir: 'column-reverse',
    margin: 'margin-bottom: 1.2rem;',
  },
  [BUTTONS_DIRECTIONS.HORIZONTAL]: {
    dir: 'row',
    margin: 'margin-left: 1.2rem;',
  },
};

const imagePositions = {
  [IMAGE_POSITION.LEFT]: 'flex-start',
  [IMAGE_POSITION.CENTER]: 'center',
};

const DialogContainer = styled.div`
  z-index: ${(props) => props.theme.zIndex.overlay};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.dark.translucent5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dialog = styled.div`
  box-sizing: border-box;
  padding: 4rem;
  max-width: 57.6rem;
  position: relative;
  width: calc(100% - 6rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.1rem solid rgb(212, 215, 220);
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.2);

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 1.3rem;
  }
`;

export const TextsContainer = styled.div`
  margin-bottom: 3rem;
  @media ${devices.mobile}, ${devices.phablet} {
    text-align: center;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: ${(props) => props.theme.text.lineHeight.bigTitleM};

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 2rem;
  }
`;

export const SubtitleContainer = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.normal};
  ${(props) => props.theme.text.fontType.regular};
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 1.4rem;
  }
`;

const ActionsContainer = styled.div<{ showButtons?: boolean }>`
  display: flex;
  flex-direction: ${(props) => directions[props.buttonsDirection].dir};

  justify-content: ${(props) => (props.showButtons ? 'flex-end' : 'center')};
  width: 100%;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  min-width: 11.8rem;
  text-align: center;
  > button {
    width: 100%;
  }

  &:not(:first-child) {
    ${(props) => directions[props.buttonsDirection].margin};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    padding: 0 0.2rem;

    > button {
      height: 4.8rem;
      line-height: 4.8rem;
    }

    &:not(:first-child) {
      margin: 1.2rem 0;
    }
  }
`;

const Close = styled.div`
  font-size: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

const CloseAction = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.text.color.label};

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;

const Illustration = styled.img`
  margin-bottom: 4rem;
  align-self: ${(props) => imagePositions[props.imagePosition]};
  height: 6rem;
  width: 6rem;
`;
