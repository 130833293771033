export default {
  server: {
    baseUrl: 'https://api.meliopayments.com',
  },
  web: {
    baseUrl: 'https://app.meliopayments.com',
  },
  sentry: {
    enable: true,
  },
  logger: {
    mode: 'dataDog',
  },
  analytics: {
    shouldTrackEvents: true,
  },
  services: {
    intuit: {
      clientId: 'Q0IBv5YxrnDsWBvrWm03xWzYPLKxI4khYSIV84XPp4oRRoTkeL',
    },
    plaid: {
      env: 'production',
    },
    segment: {
      key: 'szPSLPsn0eLI4th6z5pTk91AbgVHmxg4',
      shouldLoad: true,
    },
    tabapay: {
      url: 'https://sso.tabapay.com/SSOMelioIntuit1.html',
    },
    intercom: {
      app_id: 'bc1zfb6n',
      isShow: true,
    },
    zendesk: {
      widgetApiKey: '4b5f39cc-f1f4-4f76-a7ee-512496efc414',
    },
    errorNotification: {
      isShow: false,
    },
    featureFlagProvider: {
      enabled: true,
      clientId: '6062c9171a6a080f1a6b1529',
    },
    basisTheory: {
      key: 'key_VygUcaXQL4NtSUMxpKxjS',
    },
  },
  meliome: {
    baseUrl: 'https://melio.me/',
  },
  qbo: {
    web: {
      baseUrl: 'https://intuit-app.meliopayments.com',
    },
    services: {
      tabapay: {
        url: 'https://sso.tabapay.com/SSOMelioIntuit1.html',
      },
    },
  },
  featureFlags: {
    partialPayments: false,
    qboPartialPayments: false,
    holidaysWarning: false,
  },
};
