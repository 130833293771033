import * as Sentry from '@sentry/browser';

import { UserContextRecord, UserPreferencesRecord } from 'src/context/records';
import { CompanyInfoRecord } from 'src/records/settings.record';

import intuit from 'src/utils/intuit';

import { CONSTS } from 'src/utils/consts';

import { UserState } from './types';

import {
  LOAD_FUNDING_SOURCES,
  LOAD_FUNDING_SOURCES_SUCCESS,
  LOAD_FUNDING_SOURCES_FAILED,
  DELETE_FUNDING_SOURCE,
  DELETE_FUNDING_SOURCE_SUCCESS,
  DELETE_FUNDING_SOURCE_FAILED,
  LOAD_DELIVERY_METHODS,
  LOAD_DELIVERY_METHODS_SUCCESS,
  LOAD_DELIVERY_METHODS_FAILED,
  SET_PROFILE,
  LOAD_PROFILE_SUCCESS,
  CLEAR_STATE,
  CLEAR_USER_INFO_FINISH,
  LOAD_COMPANY_INFO,
  LOAD_COMPANY_INFO_SUCCESS,
  LOAD_COMPANY_INFO_FAILED,
  SET_COMPANY_INFO,
  UPDATE_USER_PREFERENCE_SUCCESS,
  SET_USER_PREFERENCES,
  INIT_USER_SUCCESS,
  CHECK_AND_INIT_USER_FINISH,
  VERIFY_FUNDING_SOURCE,
  VERIFY_FUNDING_SOURCE_FAILED,
  VERIFY_FUNDING_SOURCE_SUCCESS,
  UPDATE_COMPANY_INFO_SUCCESS,
  UPDATE_ORIGIN_PLAID_ITEM_ID,
} from './actionTypes';

const getIntuitRealmId = ({ organizations, currentOrgId }) =>
  (organizations || []).find((o) => o.id === currentOrgId)?.intuitRealmId;

export const initialState: UserState = {
  fundingSources: [],
  profile: UserContextRecord(),
  deliveryMethods: [],
  companyInfo: CompanyInfoRecord(),
  userPreferences: UserPreferencesRecord(),
  isLoading: false,
  isLoggedIn: false,
  isLoggedInAs: false,
  isUserChecked: false,
  isFundingSourcesLoaded: false,
  isFundingSourceVerifying: false,
  isFundingSourceDeleting: false,
  isSavingFundingSourceLabel: false,
  isRemovingFundingSourceLabel: false,
  originPlaidItemId: null,
};

const userReducer = (state: UserState = initialState, action: any): UserState => {
  switch (action.type) {
    case LOAD_FUNDING_SOURCES:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_FUNDING_SOURCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFundingSourcesLoaded: true,
        fundingSources: action.fundingSources,
      };
    case LOAD_FUNDING_SOURCES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_FUNDING_SOURCE:
      return {
        ...state,
        isFundingSourceDeleting: true,
      };
    case DELETE_FUNDING_SOURCE_FAILED:
      return {
        ...state,
        isFundingSourceDeleting: false,
      };
    case DELETE_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        isFundingSourceDeleting: false,
        fundingSources: state.fundingSources.filter(({ id }) => id !== action.deletedFundingSourceId),
      };
    case LOAD_PROFILE_SUCCESS:
    case SET_PROFILE:
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: action.profile?.email || '',
          id: action.profile?.orgId || '',
        });

        scope.setTags({
          realmId: getIntuitRealmId({
            organizations: action.organizations,
            currentOrgId: action.profile?.orgId,
          }),
          qbdtVersion: intuit.getQbdtVersion(navigator?.userAgent),
        });
      });

      return {
        ...state,
        profile: state.profile.merge(action.profile),
      };
    case CLEAR_USER_INFO_FINISH:
      return {
        ...initialState,
        isUserChecked: true,
      };
    case VERIFY_FUNDING_SOURCE:
      return {
        ...state,
        isFundingSourceVerifying: true,
      };
    case VERIFY_FUNDING_SOURCE_FAILED:
      // eslint-disable-next-line no-case-declarations
      let updatedFundingSources = state.fundingSources;
      if (
        action.errorCode ===
        CONSTS.VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS
      ) {
        updatedFundingSources = state.fundingSources.map((fundingSource) => {
          if (fundingSource.id === action.verifiedFundingSourceId) {
            const bankAccount = {
              ...fundingSource.bankAccount,
              isBlocked: true,
            };
            return fundingSource.merge({ bankAccount });
          }

          return fundingSource;
        });
      }

      return {
        ...state,
        isFundingSourceVerifying: false,
        fundingSources: updatedFundingSources,
      };
    case VERIFY_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        isFundingSourceVerifying: false,
        fundingSources: state.fundingSources.map((fundingSource) => {
          if (fundingSource.id === action.verifiedFundingSourceId) {
            return fundingSource.merge({ isVerified: true });
          }

          return fundingSource;
        }),
      };
    case LOAD_DELIVERY_METHODS:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_DELIVERY_METHODS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliveryMethods: action.deliveryMethods,
      };
    case LOAD_DELIVERY_METHODS_FAILED:
      return {
        ...state,
        isLoading: false,
        deliveryMethods: [],
      };
    case LOAD_COMPANY_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_COMPANY_INFO_SUCCESS:
    case SET_COMPANY_INFO:
      return {
        ...state,
        isLoading: false,
        companyInfo: action.companyInfo,
      };
    case LOAD_COMPANY_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        companyInfo: CompanyInfoRecord(),
      };
    case UPDATE_USER_PREFERENCE_SUCCESS:
      return {
        ...state,
        userPreferences: state.userPreferences.merge({
          [action.id]: action.value,
        }),
        profile: state.profile.merge({
          userPreferences: {
            ...state.profile.userPreferences,
            [action.id]: action.value,
          },
        }),
      };
    case SET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: state.userPreferences.merge(action.userPreferences),
      };

    case INIT_USER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggedInAs: action.isLoggedInAs,
      };
    case CHECK_AND_INIT_USER_FINISH:
      return {
        ...state,
        isUserChecked: true,
      };
    case UPDATE_ORIGIN_PLAID_ITEM_ID:
      return {
        ...state,
        originPlaidItemId: action.id,
      };
    case UPDATE_COMPANY_INFO_SUCCESS:
      if (state.companyInfo.id === action.payload.id) {
        return {
          ...state,
          companyInfo: state.companyInfo.merge(action.payload),
        };
      }

      return state;
    case CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
