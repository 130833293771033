import React from 'react';
import PaginationControl from './PaginationControl';
import { ReactComponent as ChevronRightIcon } from '../images/chevron-right.svg';

type Props = {
  canNextPage?: boolean;
  goToNextPage: () => void;
};

const PaginationNext = ({ canNextPage, goToNextPage }: Props) => (
  <>
    <PaginationControl onClick={goToNextPage} disabled={!canNextPage} IconComponent={ChevronRightIcon} />
  </>
);

export default PaginationNext;
