import { executeSDKRequest, QBDTSDKError } from 'src/billpay/qbdt/services/qbdt/sdk';
import { QBDTTxnDelType } from 'src/billpay/qbdt/services/qbdt/types';

export async function txnDelete(entityType: QBDTTxnDelType, entityId: string) {
  try {
    await executeSDKRequest(`
      <TxnDelRq>
        <TxnDelType>${entityType}</TxnDelType>
        <TxnID>${entityId}</TxnID>
      </TxnDelRq>
    `);
  } catch (err) {
    if (err instanceof QBDTSDKError && err.code === '3120') {
      // Entity does not exist, so it's effectively deleted
      return true;
    }

    throw err;
  }

  return true;
}
