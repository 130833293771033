import React from 'react';
import { Flex, Text } from '@melio/billpay-design-system';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';

type Props = {
  companyName: string;
  amount: number;
};

export const PaymentInfo = ({ companyName, amount }: Props) => (
  <Flex direction="column" align="center" justify="center" gap="ds.md">
    <Text textStyle="ds.body1Semi" m="0">
      {companyName}
    </Text>
    <Text textStyle="ds.body2" m="0">
      <MIFormattedText label="vendor.pushToDebit.addDebitCard.paymentInfoMessage" />
    </Text>
    <Text textStyle="ds.h3" m="0">
      <MIFormattedCurrency value={amount} />
    </Text>
  </Flex>
);
