import React from 'react';
import MIButton from 'src/components/common/MIButton';
import { Box, Flex } from '@melio/billpay-design-system';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import styled from 'styled-components';
import { FlowScheme } from './components/FlowScheme/FlowScheme';
import { LandingPageModalFooter } from './LandingPageModalFooter';

type Props = {
  onContinue: () => void;
};

export const LandingPageModal = ({ onContinue }: Props) => {
  const heading = (
    <Box fontSize="2.17rem" fontWeight="600">
      <MIFormattedText label="entry.batch.landingPage.heading" />
    </Box>
  );

  const button = (
    <Flex w="full" justify="center" mt="4.3rem">
      <ContinueButton
        label="qbdt.firstTimeNew.button"
        variant={BUTTON_VARIANT.PRIMARY}
        size={BUTTON_SIZE.TINY}
        onClick={onContinue}
      />
    </Flex>
  );

  return (
    <Flex
      bgColor="white"
      flexDirection="column"
      rounded="0.6rem"
      shadow="sm"
      w="63.3rem"
      overflow="hidden"
      data-testid="ftu-landing-page"
    >
      <Flex flexDirection="column" align="center" p="8">
        {heading}
        <FlowScheme />
        {button}
      </Flex>
      <LandingPageModalFooter />
    </Flex>
  );
};

const ContinueButton = styled(MIButton)`
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  font-size: 1.3rem;
`;
