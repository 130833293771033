import axios from 'axios';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { getDashboardList } from '../api/dashboard-api';
import { DashboardTabStatusEnum } from '../consts';

const DASHBOARD_LIST_REQUEST_TIMEOUT = 5000;

export const useGetDashboardUnpaidItemsPresent = () => {
  const orgId = useOrgId();

  const [loadDashboardListRequest, , loading] = useApi(getDashboardList);

  const getDashboardUnpaidItemsPresent = async () => {
    const { cancelTimeoutId, cancelToken } = getCancelByTimeout();
    try {
      const dashboardList = await loadDashboardListRequest({
        orgId,
        filters: {
          status: DashboardTabStatusEnum.Unpaid,
          start: '0',
          limit: '1',
        },
        options: {
          cancelToken,
        },
      });

      return dashboardList.totalCount > 0;
    } catch {
      return false;
    } finally {
      clearTimeout(cancelTimeoutId);
    }
  };

  return { getDashboardUnpaidItemsPresent, loading };
};

const getCancelByTimeout = () => {
  // For users that have a lot of payments this request might take significant time to load
  // pagination params. It's a workaround to cancel request if it takes more than 5 secs
  // and redirect user to the first page. It's short term solution - in long term perspective
  // we'll need to optimize the request on api side
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const cancelTimeoutId = setTimeout(() => {
    source.cancel();
  }, DASHBOARD_LIST_REQUEST_TIMEOUT);

  return {
    cancelToken: source.token,
    cancelTimeoutId,
  };
};
