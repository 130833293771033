import { Box } from '@melio/billpay-design-system';
import React from 'react';

type Props = {
  page: number;
  isSelected: boolean;
  onClick: () => void;
};

const stylePaginationPage = {
  bg: 'transparent',
  border: 'none',
  cursor: 'pointer',
  color: 'ds.gray.200',
  textStyle: 'ds.body3',
  px: '0.6rem', // set it to 0.6rem to have square form of the block for 1 digit page number
  py: 'ds.xs',
  _selected: {
    color: 'ds.gray.100',
    textStyle: 'ds.body3Bold',
    backgroundColor: 'ds.gray.500',
    borderRadius: 'ds.md',
  },
  _focus: {
    outline: 0,
  },
};

const PaginationPage: React.FC<Props> = ({ page, isSelected, onClick }) => (
  <Box
    data-testid={`qbdt-pagination-page-${page}`}
    aria-selected={isSelected ? true : undefined}
    onClick={onClick}
    __css={stylePaginationPage}
  >
    {page + 1}
  </Box>
);

export default PaginationPage;
