import * as React from 'react';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import { WizardFormColumn } from 'src/components/layout/WizardElements';
import { CONSTS } from 'src/utils/consts';
import { FormType, ScreenMode } from 'src/utils/types';
import MIEditFormControl from 'src/components/common/MIEditFormControl';
import MIViewFormControl from 'src/components/common/MIViewFormControl';

type Props = {
  formControls: Array<Record<string, any> | Array<Record<string, any>>>;
  mode?: ScreenMode;
  formType?: FormType;
};

const renderFormControl = (formControl, mode, formType) => {
  if (formControl.isHide) {
    return null;
  }

  return mode === CONSTS.SCREEN_MODE.EDIT ? (
    <MIEditFormControl key={formControl.id} formControl={formControl} formType={formType} />
  ) : (
    <MIViewFormControl key={formControl.id} formControl={formControl} />
  );
};

const MIForm = ({ formControls, mode = CONSTS.SCREEN_MODE.EDIT, formType = CONSTS.FORM_TYPE.WIZARD }: Props) => (
  <React.Fragment>
    {formControls.map((formControl) => {
      if (isArray(formControl)) {
        const rowKeyId = formControl.reduce((result, nestedFormControl) => `${result} ${nestedFormControl.id}`, '');

        return (
          <RowContainer key={rowKeyId}>
            {formControl.map((nestedFormControl) => (
              <WizardFormColumn key={nestedFormControl.id}>
                {renderFormControl(nestedFormControl, mode, formType)}
              </WizardFormColumn>
            ))}
          </RowContainer>
        );
      }

      return renderFormControl(formControl, mode, formType);
    })}
  </React.Fragment>
);

const RowContainer = styled.div`
  display: flex;
`;

export default MIForm;
