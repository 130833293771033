const baseUrl = '/quickbooks-desktop/orgs/:orgId/funding-source';

const LOCATIONS = {
  base: [baseUrl],
  bankSelect: `${baseUrl}/bank/select`,
  bankPlaid: `${baseUrl}/bank/plaid`,
  bankManual: `${baseUrl}/bank/manual`,
  bankManualView: `${baseUrl}/bank/manual/:id`,
  linkAccount: `${baseUrl}/link/account`,
  bankComplete: `${baseUrl}/bank/complete`,
  card: `${baseUrl}/card`,
  cardHolder: `${baseUrl}/card/holder`,
};

export default LOCATIONS;
