import React from 'react';
import { PaymentInfoContainer } from 'src/components/layout/PaymentActivityElements';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { Box } from '@melio/billpay-design-system';

type Props = {
  paymentFailedDate: string;
};

export const FailedPaymentDateSection = ({ paymentFailedDate }: Props) => (
  <PaymentInfoContainer>
    <Box textStyle="ds.body2Semi" color="ds.red.200" data-testId="view-payment-failed-date">
      <MIFormattedText
        label="bills.form.paymentActivity.failedPayment"
        values={{
          date: <MIFormattedDate date={paymentFailedDate} />,
        }}
      />
    </Box>
  </PaymentInfoContainer>
);
