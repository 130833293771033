import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Flex, Link, Text } from '@melio/billpay-design-system';
import { chakra } from '@chakra-ui/react';
import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { VendorFlowContentCard, VendorFlowLayout, Footer } from 'src/pages/vendor/components/VendorFlowLayout';
import { PaymentInfo } from 'src/pages/vendor/virtual-delivery-method/components/PaymentInfo';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { CONSTS } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';
import { MIFormattedText } from 'src/utils/formatting';
import { InlineTextInputField } from 'src/ui/form';
import { Expandable, PaymentType } from 'src/utils/types';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useBreak } from 'src/hoc';
import analytics from 'src/services/analytics';
import { useShiftSUVCtoACH } from 'src/pages/vendor/shift-virtual-card-to-ach/hooks/useShiftSUVCtoACH';
import locations from '../locations';

type Props = {
  payment?: PaymentType;
  token: string;
  vendorId: string;
};

type ValidationErrors = {
  'bankAccount.accountNumber'?: string;
  'bankAccount.routingNumber'?: string;
};

export const NewBankAccountPage = ({ payment, token, vendorId }: Props) => {
  const history = useHistory();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { state: locationState } = useLocation<{
    routingNumber?: string;
    accountNumber?: string;
    deliveryMethodId?: string;
    goBack?: boolean;
  }>();

  const { shiftVirtualCardToAch, isProcessing } = useShiftSUVCtoACH(token);

  const site = useSiteContext();
  const { isDesktop } = useBreak();
  const [bankAccountValues, setBankAccountValues] = useState({
    routingNumber: locationState?.routingNumber || '',
    accountNumber: locationState?.accountNumber || '',
  });

  const handleChange = (change: Expandable<{ value: string; id?: 'routingNumber' | 'accountNumber' }>) => {
    if (change.id) {
      setBankAccountValues({
        ...bankAccountValues,
        [change.id]: change.value,
      });
    }
  };

  const organizationName = payment?.organization?.companyName || '';

  const handleSubmit = async () => {
    const deliveryMethodModel = {
      deliveryType: CONSTS.DELIVERY_TYPE.ACH,
      bankAccount: {
        accountType: CONSTS.BANK_ACCOUNT_TYPE.CHECKING,
        routingNumber: bankAccountValues.routingNumber,
        accountNumber: bankAccountValues.accountNumber,
      },
    };

    const validationErrors: ValidationErrors = await getValidationErrors('deliveryMethod', deliveryMethodModel);

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      await shiftVirtualCardToAch(
        {
          bankAccount: deliveryMethodModel.bankAccount,
        },
        locationState?.deliveryMethodId
      );

      history.push(generatePath(locations.success, { token }), {
        digits: deliveryMethodModel.bankAccount.accountNumber.slice(-4),
      });
    } catch {
      history.push(generatePath(locations.invalidToken, { token }));
    }
  };

  useEffect(() => {
    analytics.trackAction('vendor-add-ach-bank-details-page-viewed', {
      paymentId: payment?.id,
      vendorId,
      isNewACH: true,
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack={locationState?.goBack}>
        {payment && <PaymentInfo companyName={organizationName} payment={payment} />}
        <Divider />
        <Flex direction="column">
          <ContentTitle label="vendors.addDeliveryMethodByLink.achTitle" />
          <RowContainer>
            <InlineTextInputField
              label="vendors.addDeliveryMethodByLink.routingNumber"
              value={bankAccountValues.routingNumber}
              required
              type="tel"
              autoFocus
              errorMessage={errors['bankAccount.routingNumber']}
              id="routingNumber"
              onChange={handleChange}
            />
            <InlineTextInputField
              label="vendors.addDeliveryMethodByLink.accountNumber"
              value={bankAccountValues.accountNumber}
              required
              type="tel"
              errorMessage={errors['bankAccount.accountNumber']}
              id="accountNumber"
              onChange={handleChange}
            />
          </RowContainer>
          <MIButton
            label="vendors.addDeliveryMethodByLink.submitCTA"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={handleSubmit}
            isProcessing={isProcessing}
            fullWidth
          />
          <Text textStyle="ds.body3" color="ds.gray.100" align="center">
            <MIFormattedText
              label="vendor.unilateral.deliveryMethods.ACH.actionHint"
              values={{
                payorName: organizationName,
              }}
            />
          </Text>
        </Flex>
      </VendorFlowContentCard>
      <Text
        textStyle="ds.body3"
        mb={isDesktop ? '0' : 'ds.lg'}
        mt={isDesktop ? 'ds.lg' : '0'}
        color="ds.gray.200"
        width={isDesktop ? '53rem' : '34.3rem'}
        align="center"
      >
        <MIFormattedText
          label="vendor.unilateral.shiftVirtualCardToAch.newBankAccount.info"
          values={{
            termsOfService: (
              <Link
                href={site.config.agreementLinks.termsOfService}
                target="_blank"
                rel="noopener noreferrer"
                color="ds.gray.200"
                textDecoration="underline"
              >
                <MIFormattedText label="vendor.unilateral.shiftVirtualCardToAch.newBankAccount.termsOfService" />
              </Link>
            ),
            privacyPolicy: (
              <Link
                href={site.config.agreementLinks.privacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
                color="ds.gray.200"
                textDecoration="underline"
              >
                <MIFormattedText label="vendor.unilateral.shiftVirtualCardToAch.newBankAccount.privacyPolicy" />
              </Link>
            ),
            privacyStatement: (
              <Link
                href={site.config.agreementLinks.privacyStatement}
                target="_blank"
                rel="noopener noreferrer"
                color="ds.gray.200"
                textDecoration="underline"
              >
                <MIFormattedText label="vendor.unilateral.shiftVirtualCardToAch.newBankAccount.privacyStatement" />
              </Link>
            ),
          }}
        />
      </Text>
      <Footer />
    </VendorFlowLayout>
  );
};

const RowContainer = chakra(Flex, {
  baseStyle: {
    marginTop: 'ds.xl',
    marginBottom: 'ds.2xl',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    '> *': {
      margin: '0',
      flex: '1',
    },
    '> *:first-child': {
      marginRight: 'ds.md',
    },
    '> *:last-child': {
      marginLeft: 'ds.md',
    },
  },
});
