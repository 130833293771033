import { useState } from 'react';
import {
  FundingSourceMicroDepositProps,
  useFundingSourceMicroDepositState,
} from 'src/hooks/settings/useFundingSourceMicroDeposits';
import { microDepositsEventParamsType } from 'src/hooks/settings/microDepositsCommon';
import { MicroDepositsEventPageType } from 'src/components/micro-deposits/consts';

export const useMicroDepositParams = (microDepositEventPage: MicroDepositsEventPageType) => {
  const [verifyingId, setVerifyingId] = useState<number | null>(null);

  const fundingSourceProps: FundingSourceMicroDepositProps = {
    fundingSourceId: verifyingId,
  };

  const analyticsParams: microDepositsEventParamsType = {
    fundingSourceID: verifyingId,
  };

  const [state, actions] = useFundingSourceMicroDepositState(
    microDepositEventPage,
    fundingSourceProps,
    analyticsParams
  );

  return {
    verifyingId,
    setVerifyingId,
    state,
    actions,
    microDepositEventPage,
  };
};
