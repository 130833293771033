import React from 'react';
import { compose } from 'recompose';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import vendorApi from 'src/services/api/vendors';
import analytics from 'src/services/analytics';
import { CONSTS, DELIVERY_TYPE, PAYMENT_CREATE_FLOW_ORIGIN } from 'src/utils/consts';
import { History } from 'history';
import locations from 'src/billpay/qbdt/pages/locations';
import { close, validateFundingSourceExternalId } from 'src/billpay/qbdt/services/qbdt';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import {
  BillType,
  PaymentType,
  OptionalDeliveryMethodsType,
  CompanyInfoType,
  UserContextType,
  NavigateType,
  AccountType,
  DeliveryOptionType,
} from 'src/utils/types';
import { GlobalState } from 'src/redux/types';
import { getBillTag } from 'src/utils/bills';
import {
  beginRegularPayBillFlowAction,
  BeginRegularPayBillFlowActionProps,
  endPayBillFlowAction,
  selectPaymentDatesAction,
} from 'src/redux/payBillWizard/actions';
import { getBill, getExitUrl, getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { removeUnsupportedDeliveryOptionsByBill } from 'src/utils/delivery-methods';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { withBreak } from 'src/hoc';
import { getProfile, getOrgId, getCompanyInfo } from 'src/redux/user/selectors';
import { updateOriginPlaidItemIdAction } from 'src/redux/user/actions';
import { Site } from 'src/sites/site';
import { getInitialProcessingDates } from 'src/utils/delivery';
import {
  getDeliveryPreference,
  shouldShowFastPaymentOnRetryPaymentFlow,
  isVirtualCardPayment,
  getPaymentById,
} from 'src/utils/payments';
import { InitialAddFundingSourceLocationState } from '../../funding-source/types';

type MapStateToProps = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  profile: RecordOf<UserContextType>;
  orgId: string;
  exitUrl: string | null;
  companyInfo: RecordOf<CompanyInfoType>;
  selectedFundingSource: RecordOf<AccountType> | null;
};

type MapDispatchToProps = {
  endPayBillFlow: () => void;
  beginRegularPayBillFlow: ({ id, paymentId, exitUrl }: BeginRegularPayBillFlowActionProps) => void;
  selectPaymentDates: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) => void;
  updateOriginPlaidItemId: (id: number) => void;
};

type Props = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
  id: string;
  paymentId: string;
  location: Location;
  inputFields?: Array<string>;
  history: History;
  locationState?: Record<string, any>;
  site: Site;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  isLoading: boolean;
  deliveryOptionsDates: {
    suggestedScheduledDate: Date;
    deliveryDate: Date;
    maxDeliveryDate: Date;
    minScheduledDate: Date;
    deliveryOptions: DeliveryOptionType[];
  } | null;
};

export type PayBillProps = {
  navigate: NavigateType;
  onPrev: () => void;
  onNext: () => void;
  goAddDeliveryMethod: (selectedDeliveryMethodType: OptionalDeliveryMethodsType) => void;
  onNextFundingSources: () => void;
  onPrevConfirmPayment: () => void;
  onNextSelectDate: () => void;
  onPrevDate: () => void;
  onPrevMemo: () => void;
  goExit: () => void;
  goEditFundingSource: () => void;
  goEditDeliveryMethod: () => void;
  goAddSelectedFundingSource: (value: string) => void;
  isLoading: boolean;
  email: string;
  id: string;
  site: Site;
  getOwnedVendorExists: () => Promise<boolean>;
} & State;

const eventPage = 'pay-bill';

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  endPayBillFlow() {
    dispatch(endPayBillFlowAction());
  },
  beginRegularPayBillFlow({ id, paymentId, exitUrl }) {
    dispatch(
      beginRegularPayBillFlowAction({
        id,
        paymentId,
        exitUrl,
      })
    );
  },
  selectPaymentDates(scheduledDate, deliveryEta, maxDeliveryEta, selectedId?) {
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryEta, maxDeliveryEta, selectedId));
  },
  updateOriginPlaidItemId(id: number) {
    dispatch(updateOriginPlaidItemIdAction(id));
  },
});

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  bill: getBill(state),
  payment: getPayment(state),
  profile: getProfile(state),
  orgId: getOrgId(state),
  exitUrl: getExitUrl(state),
  companyInfo: getCompanyInfo(state),
  selectedFundingSource: getSelectedFundingSource(state),
});

export function withPayBillData() {
  return function (Component: any) {
    return compose(
      withSiteContext(),
      withBreak()
    )(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(
        class ComponentWithPayBillData extends React.PureComponent<Props, State> {
          static defaultProps = {
            inputFields: [],
          };

          constructor(props: Props) {
            super(props);

            this.state = {
              isLoading: false,
              deliveryOptionsDates: null,
            };
          }

          componentDidMount() {
            const { bill, id, beginRegularPayBillFlow, paymentId, payment, locationState } = this.props;

            const { exitUrl } = locationState || {};

            if (!bill.id || !id || bill.id.toString() !== id.toString()) {
              beginRegularPayBillFlow({
                id,
                paymentId,
                exitUrl,
              });
            }

            if (payment?.id) {
              this.getDeliveryOptionsDates();
            }
          }

          componentDidUpdate(prevProps: Readonly<Props>) {
            const { payment } = this.props;
            if (payment?.id && prevProps.payment?.id !== payment?.id) {
              this.getDeliveryOptionsDates();
            }
          }

          getDeliveryOptionsDates = async () => {
            this.setState({ isLoading: true });
            const { bill, payment, orgId } = this.props;
            const { deliveryMethodId, fundingSourceId, amount, payBillFlowUUID } = payment;
            const deliveryOptionsDates = await getInitialProcessingDates({
              orgId,
              deliveryMethodId: Number.parseInt(deliveryMethodId, 10),
              fundingSourceId,
              scheduledDate: null,
              amount,
              dueDate: bill.dueDate,
              paymentId: payment.id,
              payBillFlowUUID,
              createOrigin: payment.createOrigin as PAYMENT_CREATE_FLOW_ORIGIN,
            });

            this.setState({ deliveryOptionsDates, isLoading: false });
          };

          onNext = () => {
            const eventName = `set-${getEventNameFromLocation(this.props.location)}`;
            analytics.track(eventPage, `${eventName}-continue`);

            if (this.props.nextStepURL) {
              this.props.navigate(this.props.nextStepURL);
            }
          };

          onPrevMemo = () => {
            const { location, prevStepURL, navigate } = this.props;
            const eventName = `set-${getEventNameFromLocation(location)}`;
            analytics.track(eventPage, `${eventName}-back`);
            if (prevStepURL) {
              navigate(prevStepURL);
            }
          };

          getFundingUrl = () => {
            const { payment, bill, orgId } = this.props;
            const url = payment.id
              ? generatePath(locations.edit.funding, {
                  orgId,
                  billId: bill.id,
                  paymentId: payment.id,
                })
              : generatePath(locations.pay.funding, { orgId, billId: bill.id });

            return url;
          };

          onNextFundingSources = async () => {
            const { navigate, selectedFundingSource, orgId } = this.props;
            analytics.track(eventPage, 'funding-source-continue', {
              fundingSourceId: selectedFundingSource?.id,
            });
            const url = await this.getUrlAfterFundingSource();

            if (await validateFundingSourceExternalId(orgId, selectedFundingSource?.id)) {
              analytics.track(eventPage, 'funding-source-continue');
              navigate(url);
            } else {
              navigate(generatePath(locations.fundingSource.linkAccount, { orgId }), false, {
                fundingSourceId: selectedFundingSource?.id,
                redirectUrl: url,
                origin: CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL,
                exitUrl: this.getFundingUrl(),
              });
            }
          };

          onPrevConfirmPayment = () => {
            const { payment, bill, navigate, orgId } = this.props;
            const status = getBillTag(bill);

            if (status === CONSTS.PAYMENT_STATUS.FAILED && payment) {
              const eventName = `set-${getEventNameFromLocation(this.props.location)}`;
              analytics.track(eventPage, `${eventName}-back`);

              if (payment?.metadata?.failedType === CONSTS.FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER) {
                const isUnilateral = payment.deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;

                if (isUnilateral) {
                  const redirectUrl = generatePath(locations.edit.confirm, {
                    billId: bill.id,
                    paymentId: payment.id,
                    orgId,
                  });
                  const exitUrl = generatePath(locations.view.base, {
                    billId: bill.id,
                    paymentId: payment.id,
                    orgId,
                  });

                  return navigate(
                    generatePath(locations.deliveryMethod.virtual, {
                      orgId,
                      vendorId: bill.vendorId,
                    }),
                    false,
                    {
                      origin: CONSTS.DELIVERY_METHOD_ORIGIN.EDIT_PAYMENT,
                      redirectUrl,
                      exitUrl,
                    }
                  );
                }

                const originalPayment = getPaymentById(bill.payments, payment.id);

                if (originalPayment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
                  return navigate(
                    generatePath(locations.edit.virtualCardRecovery, {
                      billId: bill.id,
                      paymentId: payment.id,
                      orgId,
                    })
                  );
                }

                return navigate(
                  generatePath(locations.edit.deliveryMethodAch, {
                    billId: bill.id,
                    paymentId: payment.id,
                    orgId,
                  })
                );
              }

              const { id: paymentId, deliveryPreference, originDeliveryPreference, deliveryMethod } = payment;
              const shouldShowFastPaymentPage = this.shouldShowFastPayment(
                paymentId,
                deliveryPreference,
                originDeliveryPreference,
                deliveryMethod
              );

              return shouldShowFastPaymentPage
                ? navigate(
                    generatePath(locations.edit.fastPayment, {
                      billId: bill.id,
                      paymentId,
                      orgId,
                    })
                  )
                : navigate(
                    generatePath(locations.edit.funding, {
                      billId: bill.id,
                      paymentId,
                      orgId,
                    })
                  );
            }

            return this.onPrev();
          };

          onNextSelectDate = () => {
            const { payment, bill, navigate, orgId } = this.props;
            const status = getBillTag(bill);

            if (status === CONSTS.PAYMENT_STATUS.FAILED && payment) {
              const eventName = `set-${getEventNameFromLocation(this.props.location)}`;
              analytics.track(eventPage, `${eventName}-continue`);

              return navigate(
                generatePath(locations.edit.confirm, {
                  billId: bill.id,
                  paymentId: payment.id,
                  orgId,
                })
              );
            }

            return this.onNext();
          };

          getPaymentDeliveryOptions = (paymentId: string, deliveryPreference?: string) => {
            const { deliveryOptionsDates } = this.state;
            const { selectPaymentDates } = this.props;
            if (deliveryOptionsDates) {
              const { deliveryOptions } = deliveryOptionsDates;
              const selectedDeliveryId = getDeliveryPreference(deliveryOptions, deliveryPreference);
              const { type, scheduledDate, deliveryDate, maxDeliveryDate } = deliveryOptions[selectedDeliveryId];
              selectPaymentDates(scheduledDate, deliveryDate, maxDeliveryDate, type);
              return deliveryOptions;
            }

            return null;
          };

          shouldShowFastPayment = (paymentId, deliveryPreference, originDeliveryPreference, deliveryMethod) => {
            const { bill, payment, companyInfo } = this.props;

            const deliveryOptions = this.getPaymentDeliveryOptions(paymentId, deliveryPreference);

            if (deliveryOptions) {
              const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
                deliveryOptions,
                bill,
                payment,
                companyInfo,
              });
              const deliveryType = deliveryMethod?.deliveryType;

              return shouldShowFastPaymentOnRetryPaymentFlow(
                deliveryType,
                originDeliveryPreference,
                possibleDeliveryOptions
              );
            }

            return false;
          };

          onPrev = () => {
            const eventName = `set-${getEventNameFromLocation(this.props.location)}`;
            analytics.track(eventPage, `${eventName}-back`);

            if (this.props.prevStepURL) {
              this.props.navigate(this.props.prevStepURL);
            }
          };

          onPrevDate = () => {
            const { payment } = this.props;

            if (payment.deliveryMethodId) {
              const url = this.getFundingUrl();
              this.props.navigate(url);
            } else {
              this.onPrev();
            }
          };

          getUrlAfterFundingSource = async () => {
            const { payment, bill, nextStepURL, orgId } = this.props;
            const status = getBillTag(bill);
            if (status === CONSTS.PAYMENT_STATUS.FAILED && payment) {
              await this.getDeliveryOptionsDates();

              const { id: paymentId, deliveryPreference, originDeliveryPreference, deliveryMethod } = payment;
              const shouldShowFastPaymentPage = this.shouldShowFastPayment(
                paymentId,
                deliveryPreference,
                originDeliveryPreference,
                deliveryMethod
              );

              return shouldShowFastPaymentPage
                ? generatePath(locations.pay.fastPayment, {
                    billId: bill.id,
                    orgId,
                  })
                : generatePath(locations.pay.confirm, {
                    billId: bill.id,
                    orgId,
                  });
            }

            if (payment.deliveryMethodId && payment.id) {
              const deliveryMethod = bill.getDeliveryMethodById(payment.deliveryMethodId);
              const shouldSkipDeliveryMethodPage =
                bill.isVendorRequest() ||
                isVirtualCardPayment(payment) ||
                deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;

              return shouldSkipDeliveryMethodPage
                ? generatePath(locations.edit.date, {
                    billId: bill.id,
                    paymentId: payment.id,
                    orgId,
                  })
                : nextStepURL;
            }

            if (payment.deliveryMethodId) {
              return generatePath(locations.pay.date, {
                billId: bill.id,
                orgId,
              });
            }

            return nextStepURL;
          };

          goAddSelectedFundingSource = async (selectedFundingSource) => {
            const { payment, updateOriginPlaidItemId, navigate, orgId, bill } = this.props;
            const redirectUrl = await this.getUrlAfterFundingSource();
            const exitUrl = this.getFundingUrl();

            if (payment) {
              const plaidAccount = payment.fundingSource?.plaidAccount;
              if (plaidAccount) {
                updateOriginPlaidItemId(plaidAccount.plaidItemId);
              }
            }

            const locationState: InitialAddFundingSourceLocationState = {
              preservedState: {
                origin: CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL,
                billIds: bill ? [bill.id] : [],
              },
              redirectUrl,
              exitUrl,
            };

            if (selectedFundingSource === CONSTS.FUNDING_TYPE.ACH) {
              analytics.track(eventPage, 'add-bank-account');
              navigate(generatePath(locations.fundingSource.bankSelect, { orgId }), false, locationState);
              return;
            }

            if ([CONSTS.CARD_TYPES.CREDIT, CONSTS.CARD_TYPES.DEBIT].includes(selectedFundingSource)) {
              const analyticProperty =
                selectedFundingSource === CONSTS.CARD_TYPES.CREDIT ? 'add-credit-card' : 'add-debit-card';
              analytics.track(eventPage, analyticProperty);
              this.props.navigate(generatePath(locations.fundingSource.card, { orgId }), false, locationState);
            }
          };

          goAddDeliveryMethod = (type: OptionalDeliveryMethodsType) => {
            const { payment, bill, orgId } = this.props;

            let redirectUrl;
            let exitUrl;
            if (payment.id) {
              redirectUrl = generatePath(locations.edit.date, {
                orgId,
                billId: bill.id,
                paymentId: payment.id,
              });
              exitUrl = generatePath(locations.edit.deliveryMethod, {
                orgId,
                billId: bill.id,
                paymentId: payment.id,
              });
            } else {
              redirectUrl = generatePath(locations.pay.date, {
                orgId,
                billId: bill.id,
              });
              exitUrl = generatePath(locations.pay.deliveryMethod, {
                orgId,
                billId: bill.id,
              });
            }

            const deliveryMethodUrlMap = {
              [CONSTS.DELIVERY_TYPE.ACH]: generatePath(locations.deliveryMethod.ach, {
                orgId,
                vendorId: bill.vendorId,
              }),
              [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(locations.deliveryMethod.check, {
                orgId,
                vendorId: bill.vendorId,
              }),
              [CONSTS.DELIVERY_TYPE.VIRTUAL]: generatePath(locations.deliveryMethod.virtual, {
                orgId,
                vendorId: bill.vendorId,
              }),
            };
            const url = deliveryMethodUrlMap[type || CONSTS.DELIVERY_TYPE.ACH];

            this.props.navigate(url, false, {
              origin: CONSTS.DELIVERY_METHOD_ORIGIN.PAY_BILL,
              redirectUrl,
              exitUrl,
              fundingSourceId: payment.fundingSourceId,
            });
          };

          goEditDeliveryMethod = () => {
            analytics.track(eventPage, 'edit-delivery-method');
            const { bill, payment, navigate, orgId } = this.props;

            const url = payment.id
              ? generatePath(locations.edit.deliveryMethod, {
                  billId: bill.id,
                  paymentId: payment.id,
                  orgId,
                })
              : generatePath(locations.pay.deliveryMethod, {
                  billId: bill.id,
                  orgId,
                });

            navigate(url);
          };

          goEditFundingSource = () => {
            analytics.track(eventPage, 'edit-funding-source');

            const url = this.getFundingUrl();

            this.props.navigate(url);
          };

          goExit = () => {
            const { endPayBillFlow, exitUrl } = this.props;

            endPayBillFlow();

            if (exitUrl) {
              this.props.navigate(exitUrl);
              return;
            }

            close();
          };

          getOwnedVendorExists = async () => {
            const { orgId, bill } = this.props;
            if (!bill?.vendor?.id) {
              return false;
            }

            return vendorApi.getOwnedVendorExists({
              orgId,
              id: bill?.vendor?.id,
            });
          };

          render() {
            return (
              <React.Fragment>
                <Component
                  {...this.state}
                  navigate={this.props.navigate}
                  onPrev={this.onPrev}
                  onNext={this.onNext}
                  onNextFundingSources={this.onNextFundingSources}
                  onPrevConfirmPayment={this.onPrevConfirmPayment}
                  onNextSelectDate={this.onNextSelectDate}
                  onPrevDate={this.onPrevDate}
                  onPrevMemo={this.onPrevMemo}
                  goExit={this.goExit}
                  goAddSelectedFundingSource={this.goAddSelectedFundingSource}
                  goAddDeliveryMethod={this.goAddDeliveryMethod}
                  goEditDeliveryMethod={this.goEditDeliveryMethod}
                  goEditFundingSource={this.goEditFundingSource}
                  email={this.props.profile.email}
                  getOwnedVendorExists={this.getOwnedVendorExists}
                  id={this.props.id}
                />
              </React.Fragment>
            );
          }
        }
      )
    );
  };
}
