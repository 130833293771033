import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';

const DEFAULT_CYCLE_TIME = 5000;
export type ProgressIndicationPageProps = {
  messages: string[];
  title: string;
  progress: number;
  cycleTime?: number;
};
export function ProgressIndicatorPage(props: ProgressIndicationPageProps) {
  const { messages, title, progress, cycleTime = DEFAULT_CYCLE_TIME } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextIndex = (currentIndex + 1) % messages.length;
      setCurrentIndex(nextIndex);
    }, cycleTime);
    return () => clearTimeout(timeout);
  }, [messages, currentIndex, cycleTime]);
  return (
    <LoadingContainer>
      <LoadingMessage>
        <OneSecMessage>
          <MIFormattedText label={title} />
        </OneSecMessage>
        <FriendlyMessage animationTime={cycleTime} animate={messages.length > 1}>
          <MIFormattedText label={messages[currentIndex] || ''} />
        </FriendlyMessage>
        <ProgressBarContainer>
          <ProgressBar>
            <ProgressBarInner progress={progress} />
          </ProgressBar>
        </ProgressBarContainer>
      </LoadingMessage>
    </LoadingContainer>
  );
}

const LoadingContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.theme.zIndex.overlay};
  width: 100%;
  height: 100%;
  border: 0;
`;

const LoadingMessage = styled.div`
  left: 50%;
  width: calc(100% - 3.2rem);
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const OneSecMessage = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 32px;
`;
const FriendlyMessage = styled.div`
  position: relative;
  min-height: 40px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) => props.theme.text.color.label};
  ${(props) =>
    props.animate &&
    `
  animation: message ${(props) => props.animationTime / 1000}s linear infinite;`}

  @keyframes message {
    0% {
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    95% {
      opacity: 1;
      top: 0px;
    }

    100% {
      opacity: 0;
      top: 20px;
    }
  }
`;

const ProgressBarContainer = styled.div`
  max-width: 300px;
  margin: 0 auto 40px auto;
  border-radius: 60px;
  overflow: hidden;
  width: 100%;
`;

const ProgressBar = styled.div`
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.brand};
  border-radius: 60px;
`;
const ProgressBarInner = styled.div`
  background: ${(props) => props.theme.colors.brand};
  color: #fff;
  padding: 5px;
  width: ${(props) => props.progress * 100}%;
  transition: width 1s ease-in-out;
`;
