import React from 'react';
import styled from 'styled-components';
import { PopoverArrow, Portal } from '@chakra-ui/react';
import { Box, Popover, VStack } from '@melio/billpay-design-system';
import MIButton from 'src/components/common/MIButton';
import { useModal } from 'src/helpers/react/useModal';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';

import { CollectVendorEmailModal as CollectVendorEmailDialog } from './CollectVendorEmailModal';

type Props = {
  vendorId: number;
};

const CollectVendorEmailPopover = ({ vendorId }: Props) => {
  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(CollectVendorEmailDialog, {
    vendorId,
  });

  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  const handleAddEmailClick = () => {
    showCollectVendorEmailModal();
  };

  return (
    <>
      <Portal>{CollectVendorEmailModal}</Portal>
      <Popover placement="top-end">
        {({ isOpen }) => (
          <>
            <Popover.PopoverTrigger>
              <Box w="1.6rem" h="1.6rem">
                <Box
                  as="i"
                  fontSize="1.6rem"
                  lineHeight="1.6rem"
                  color="ds.gray.100"
                  className="icon-info-hollow-icon"
                  position="relative"
                />
              </Box>
            </Popover.PopoverTrigger>

            <Portal>
              <Box sx={popoverStyle}>
                <Popover.PopoverContent
                  as="div"
                  outline="none"
                  right="1.8rem"
                  onClick={handleContentClick}
                  display={isOpen ? 'block' : 'none'}
                >
                  <PopoverArrow />
                  <Popover.PopoverBody
                    border="1px solid"
                    borderRadius="ds.md"
                    borderColor="ds.gray.500"
                    boxShadow="ds.md"
                    backgroundColor="white"
                  >
                    <VStack w="32rem" p="ds.xl" spacing="ds.lg">
                      <Box textStyle="ds.body2" color="ds.gray.100">
                        <MIFormattedText label="regularBatchPayments.popover.vendorEmail.message" />
                      </Box>
                      <PrimaryButton
                        variant={BUTTON_VARIANT.PRIMARY}
                        onClick={handleAddEmailClick}
                        label="regularBatchPayments.popover.vendorEmail.cta"
                        size={BUTTON_SIZE.NORMAL}
                      />
                    </VStack>
                  </Popover.PopoverBody>
                </Popover.PopoverContent>
              </Box>
            </Portal>
          </>
        )}
      </Popover>
    </>
  );
};

const PrimaryButton = styled(MIButton)`
  width: 12.5rem;
  padding: 0;
  align-self: flex-end;
`;

// Doesn't work without !important.
const arrowStyle: React.CSSProperties = {
  position: 'absolute',
  left: '20.69px!important',
  height: '11.31px!important',
  width: '11.31px!important',
  background: 'var(--chakra-colors-white)',
  // zIndex could be number or Globals in React types, but string is also valid
  zIndex: '10001!important' as React.CSSProperties['zIndex'],
  transform: 'rotate(45deg)!important',
};

const popoverStyle: Record<string, React.CSSProperties> = {
  '[data-popper-placement*="bottom"] .chakra-popover__arrow-positioner': {
    ...arrowStyle,
    top: '-6px!important',
    borderLeft: '1px solid var(--chakra-colors-ds-gray-500)',
    borderTop: '1px solid var(--chakra-colors-ds-gray-500)',
  },

  '[data-popper-placement*="bottom"] .chakra-popover__content': {
    marginTop: '0.5rem',
  },

  '[data-popper-placement*="top"] .chakra-popover__arrow-positioner': {
    ...arrowStyle,
    bottom: '-6px!important',
    borderRight: '1px solid var(--chakra-colors-ds-gray-500)',
    borderBottom: '1px solid var(--chakra-colors-ds-gray-500)',
  },

  '[data-popper-placement*="top"] .chakra-popover__content': {
    marginBottom: '0.5rem',
  },
};

export { CollectVendorEmailPopover };
