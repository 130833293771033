import React, { useEffect } from 'react';
import { OrganizationType, PaymentType } from 'src/utils/types';
import { Flex, Icon, Text } from '@melio/billpay-design-system';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { ReactComponent as SuccessIllustration } from 'src/images/general/success.svg';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { useInvoiceNumberType } from '../../hooks/useInvoiceNumberType';

type Props = {
  organization: OrganizationType | undefined;
  payment: PaymentType | undefined;
};

export const AddDebitCardCompletedPage = ({ organization, payment }: Props) => {
  const amount = payment?.amount || 0;
  const companyName = organization?.companyName || '';
  const digits = payment?.deliveryMethod?.cardAccount?.card4digits || '';
  const { invoiceNumber, invoiceNumberType } = useInvoiceNumberType(payment);

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'add-debit-card-completed', {
      OriginDeliveryType: payment?.originalDeliveryMethod?.deliveryType,
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" w="100%" gap="ds.2xl">
          <Flex direction="column" justify="center" align="start">
            <Icon as={SuccessIllustration} w="ds.2xl" h="ds.2xl" />
            <Text textStyle="ds.body1Semi" mb="0" mt="ds.xl" color="ds.gray.100">
              <MIFormattedText label="vendor.pushToDebit.addDebitCard.completed.title" />
            </Text>
            <Text textStyle="ds.body2" mb="0" mt="ds.md" color="ds.gray.100">
              <MIFormattedText
                label="vendor.pushToDebit.addDebitCard.completed.text"
                values={{
                  amount: <MIFormattedCurrency value={amount} />,
                  companyName,
                  digits,
                  invoiceNumber,
                  invoiceNumberType,
                }}
              />
            </Text>
          </Flex>
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
