import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/theme/AppDevices';
import { CONSTS } from 'src/utils/consts';
import { ButtonVariantType } from 'src/utils/types';
import { isEnterPressed } from 'src/utils/events';
import * as WizardElements from './QBOWizardElements';
import * as QBOLayoutElements from './QBOElements';
import { MIFormattedText } from '../../utils/formatting';
import MIButton from '../common/MIButton';
import SandboxIndicator from '../common/SandboxIndicator';

type Props = {
  title?: any;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  children?: React.ReactNode;
  onNext?: () => void | Promise<void> | null;
  onSkip?: () => void | Promise<void>;
  nextLabel?: string;
  skipLabel?: string;
  goExit?: () => void | null;
  contentWrapperMode?: string;
  isNextDisabled?: boolean;
  onSubmit?: () => void | boolean | null;
  isLoading?: boolean;
  isHideActions?: boolean;
  ctaVariant?: ButtonVariantType;
  fullWidthCTA?: boolean;
  innerSize?: number;
  footer?: React.ReactNode | null;
  isSandboxIndicatorShown?: boolean;
  qboFooter?: React.ReactNode;
  className?: string;
};

const QBOLayoutPage = ({
  title = null,
  titleValues,
  subtitle,
  goExit,
  children,
  onNext,
  onSkip,
  nextLabel,
  skipLabel,
  isNextDisabled,
  onSubmit,
  isLoading,
  isHideActions,
  innerSize = 50,
  footer,
  subTitleValues,
  ctaVariant = CONSTS.BUTTON_VARIANT.PRIMARY,
  fullWidthCTA,
  contentWrapperMode,
  isSandboxIndicatorShown,
  qboFooter,
  className,
}: Props) => {
  const submitMethod = onSubmit || onNext;
  const nextActionLabel = nextLabel || 'progress.continue';
  const skipActionLabel = skipLabel || 'progress.skip';
  const buttonContainerWidth = fullWidthCTA ? '45' : 'auto';
  const actionsContainerDirecton = onSkip ? 'inline' : 'column';
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (submitMethod && !isNextDisabled && isEnterPressed(event)) {
      submitMethod();
    }
  };

  const handleNextClick = () => {
    if (submitMethod) {
      submitMethod();
    }
  };

  return (
    <QBOLayoutElements.QBOLayoutContainer className={className} onKeyDown={onKeyPressed}>
      {goExit && <Close onClick={goExit} />}

      <WizardElements.WizardInner mode={contentWrapperMode as string}>
        {title && (
          <WizardElements.WizardStepTitle isSubtitle={!!subtitle}>
            {title && <MIFormattedText label={title} values={titleValues} />}
          </WizardElements.WizardStepTitle>
        )}

        {subtitle && (
          <WizardElements.WizardStepSubTitle>
            <MIFormattedText label={subtitle} values={subTitleValues} />
          </WizardElements.WizardStepSubTitle>
        )}

        <WizardElements.WizardInputs mode={contentWrapperMode} innerSize={innerSize}>
          {children}
        </WizardElements.WizardInputs>

        {!isHideActions && submitMethod && (
          <WizardElements.WizardStepActionsContainer direction={actionsContainerDirecton}>
            {onSkip && (
              <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth}>
                <MIButton
                  label={skipActionLabel}
                  variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                  onClick={onSkip}
                  isProcessing={isLoading}
                  fullWidth
                />
              </WizardElements.WizardButtonContainer>
            )}
            <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth}>
              <MIButton
                label={nextActionLabel}
                variant={ctaVariant}
                onClick={handleNextClick}
                isProcessing={isLoading}
                disabled={isNextDisabled}
                fullWidth
              />
            </WizardElements.WizardButtonContainer>
          </WizardElements.WizardStepActionsContainer>
        )}

        {footer && <WizardElements.WizardFooterContainer>{footer}</WizardElements.WizardFooterContainer>}
      </WizardElements.WizardInner>
      {isSandboxIndicatorShown && <SandboxIndicator />}
      <QboLayoutPageFooter>
        {qboFooter}
        {!qboFooter && contentWrapperMode && <QBOLayoutElements.QBOFooterContainer />}
      </QboLayoutPageFooter>
    </QBOLayoutElements.QBOLayoutContainer>
  );
};

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 1;

  @media ${devices.mobile}, ${devices.phablet} {
    position: relative;
    display: flex;
    align-self: flex-end;
    margin: 12px 12px 0 0;
    font-size: ${(props) => props.theme.text.size.title};
    right: 1rem;
    top: 0.8rem;
    color: ${(props) => props.theme.text.color.green};
  }
`;

const QboLayoutPageFooter = styled.div`
  width: 100%;
  ${(props) => props.theme?.pages?.QboLayoutPage?.QboLayoutPageFooter}
`;

export default QBOLayoutPage;
