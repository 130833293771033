import { CLEAR_STATE } from 'src/redux/user/actionTypes';
import { OrganizationState } from './types';
import {
  SET_ORGANIZATION_PREFERENCES,
  UPDATE_ORGANIZATION_PREFERENCE,
  UPDATE_ORGANIZATION_PREFERENCE_SUCCESS,
  UPDATE_ORGANIZATION_PREFERENCE_FAILED,
  LOAD_FEE_CATALOG_SUCCESS,
  LOAD_FEE_CATALOG_FAILED,
} from './actionTypes';

export const initialState: OrganizationState = {
  organizationPreferences: {},
  isOrganizationPreferencesUpdating: false,
  feeCatalog: null,
};

export const organizationReducer = (state: OrganizationState = initialState, action: any): OrganizationState => {
  switch (action.type) {
    case SET_ORGANIZATION_PREFERENCES:
      return {
        ...state,
        organizationPreferences: action.organizationPreferences,
      };
    case UPDATE_ORGANIZATION_PREFERENCE:
      return {
        ...state,
        isOrganizationPreferencesUpdating: true,
      };
    case UPDATE_ORGANIZATION_PREFERENCE_SUCCESS:
      return {
        ...state,
        isOrganizationPreferencesUpdating: false,
        organizationPreferences: {
          ...state.organizationPreferences,
          [action.key]: action.value,
        },
      };
    case UPDATE_ORGANIZATION_PREFERENCE_FAILED:
      return {
        ...state,
        isOrganizationPreferencesUpdating: false,
      };
    case LOAD_FEE_CATALOG_SUCCESS:
      return {
        ...state,
        feeCatalog: action.feeCatalog,
      };
    case LOAD_FEE_CATALOG_FAILED:
      return {
        ...state,
        feeCatalog: null,
      };
    case CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
