import * as React from 'react';
import styled, { css } from 'styled-components';
import { devices } from 'src/theme/AppDevices';
import { MIFormattedText } from 'src/utils/formatting';
import NotificationCard, {
  NotificationCardProps,
  Icon,
  Title,
  TextContainer,
} from 'src/components/qbo/QBONotificationCard';

const WizardContainer = styled.div`
  background-color: ${(props) => props.theme.background.wizard};
  display: flex;
  justify-content: center;
  min-height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`;

const WizardInner = styled.div<{ isCenterVert?: boolean; mode?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  ${({ mode }) => mode !== 'batchList' && 'width: 90rem'};
  margin-bottom: 3rem;

  ${(props) =>
    props.mode === 'batchList' &&
    css`
      &&& {
        justify-content: flex-start;
      }
    `}
`;

const WizardInnerSuccess = styled(WizardInner)``;

const WizardIllustration = styled.img`
  margin-bottom: 4rem;
  align-self: center;

  @media ${devices.desktop}, ${devices.tablet} {
    height: 6rem;
  }
`;

const WizardTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.normal};
  margin-bottom: 1rem;
  align-self: center;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
`;

const WizardStepTitle = styled.div<{ isSubtitle?: boolean }>`
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.normal};
  align-self: center;
  text-align: center;
  margin-bottom: ${(props) => (props.isSubtitle ? '1rem' : '4rem')};

  em {
    font-style: normal;
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }

  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  margin-top: 4rem;
`;

const WizardStepSubTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.wizardText};
  line-height: 2rem;
  text-align: center;
  margin-bottom: 4rem;
  max-width: 45rem;
`;

const WizardText = styled.div<{ textAlign?: string }>`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.wizardText};
  margin-bottom: 4rem;
  align-self: center;
  text-align: ${(props) => props.textAlign || 'center'};
  line-height: 2rem;

  width: 50%;
`;

const WizardActionsContainer = styled.div`
  align-self: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  width: 50%;
`;

const WizardActionsRowContainer = styled.div`
  align-self: center;
  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 50%;

  div {
    &:first-child {
      margin-right: 2rem;
    }
    &:only-child {
      margin-right: 0 !important;
    }
  }
`;

const WizardButtonContainer = styled.div<{
  width?: string;
  isProgressBar?: boolean;
}>`
  width: ${(props) => (props.width ? `${props.width}rem` : 'auto')};
`;

const WizardLinkButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const WizardLinkContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const WizardRegisterLinkContainer = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`;

const WizardLinkHintContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.linkHint};
`;

const WizardOptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1.5rem;
  max-width: 45rem;
  align-self: center;
`;

const WizardOptionLine = styled.div`
  height: 0.1rem;
  border-top: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  flex-grow: 1;
`;

const WizardOptionText = styled.div`
  font-size: 1.4rem;
  flex-grow: none;
  color: ${(props) => props.theme.text.color.main};
  margin: 0 1.7rem 0 1.7rem;
`;

const WizardOrLine = () => (
  <WizardOptionContainer>
    <WizardOptionLine />
    <WizardOptionText>
      <MIFormattedText label="general.or" />
    </WizardOptionText>
    <WizardOptionLine />
  </WizardOptionContainer>
);

WizardOrLine.defaultProps = {};

type WizardInputsProps = {
  innerSize?: number;
  children: React.ReactNode;
  mode?: string;
};

const WizardInputs = ({ innerSize = 50, children, mode = 'default' }: WizardInputsProps) => (
  <WizardInputsContainer size={innerSize} mode={mode}>
    {children}
  </WizardInputsContainer>
);

const WizardInputsContainer = styled.div<{ size: number; mode: string }>`
  align-self: center;
  width: ${(props) => props.size}%;

  ${(props) =>
    props.mode === 'settings' &&
    css`
      display: flex;
      flex-direction: column;
      flex: 1;
    `}

  ${(props) =>
    props.mode === 'batchList' &&
    css`
      display: flex;
      justify-content: center;
    `}

  ${(props) =>
    props.mode === 'success' &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 51.6rem;
    `}
`;

const WizardStepActionsContainer = styled.div`
  align-self: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  align-items: center;
  min-width: 25%;
  margin-top: 2rem;
`;

const WizardFooterContainer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  margin-top: 4rem;
  width: 50%;
`;

const LogoContainer = styled.div`
  margin-bottom: 3rem;
  width: 11.2rem;
  height: 4.2rem;
  text-align: center;
`;

const SimpleTextFooter = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  letter-spacing: 0.037rem;
  line-height: 1.6rem;
  text-align: center;
`;

const SimpleTextFooterSlim = styled(SimpleTextFooter)`
  width: 28rem;
`;

type WizardFormRowProps = {
  children: React.ReactNode;
  className?: string;
};

const WizardFormRow = ({ children, className }: WizardFormRowProps) => (
  <WizardFormRowWrapper className={className}>{children}</WizardFormRowWrapper>
);

WizardFormRow.defaultProps = {};

const WizardFormRowWrapper = styled.div`
  display: flex;
`;

type WizardFormColumnProps = {
  size?: number;
  children: React.ReactNode;
};

const WizardFormColumn = ({ size = 50, children }: WizardFormColumnProps) => (
  <WizardFormColumnWrapper size={size}>{children}</WizardFormColumnWrapper>
);

WizardFormColumn.defaultProps = {
  size: 50,
};

const WizardFormColumnWrapper = styled.div<{ size: number }>`
  width: ${(props) => props.size}%;

  &:first-child {
    margin-right: 1.5rem;
  }
  &:nth-child(2) {
    margin-left: 1.5rem;
  }
`;

const InnerWizardContentWrapper = styled.div`
  margin-bottom: 4rem;
`;

type WizardNotesContainerProps = {
  label: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
};

const WizardNotesContainer = ({ label, fullWidth, children, ...rest }: WizardNotesContainerProps) => (
  <WizardNotesContainerWrapper fullWidth={!!fullWidth} {...rest}>
    <WizardNotesHeaderContainer>
      <WizardNotesTitle>
        <MIFormattedText label={label} />
      </WizardNotesTitle>
    </WizardNotesHeaderContainer>
    <WizardNotesSubTitle>{children}</WizardNotesSubTitle>
  </WizardNotesContainerWrapper>
);

const WizardNotesContainerWrapper = styled.div<{ fullWidth: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.8rem;
  background: ${(props) => props.theme.colors.white.opaque};
  padding: 2rem;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  line-height: 2rem;
  margin-bottom: 4rem;
  text-align: left;
  span {
    font-weight: 600 !important;
  }
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
`;

const WizardNotesTitle = styled.div`
  margin-bottom: 0.8rem;
`;

const WizardNotesSubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const WizardNotesHeaderContainer = styled.div`
  display: flex;
`;

const WizardNotification = (props: NotificationCardProps) => {
  const [visible, setVisible] = React.useState<boolean>(true);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  };

  if (!visible) return null;

  return <StyledNotificationCard {...props} onClose={handleClose} />;
};

const StyledNotificationCard = styled(NotificationCard)`
  width: 49.6rem;
  margin: -1rem 0 3rem;

  ${Icon} {
    font-size: 2rem;
    margin: 0 1rem 0 0;
  }

  ${Title} {
    margin-bottom: 0.8rem;
  }

  ${TextContainer} {
    margin-right: 1rem;
  }
`;

export {
  WizardContainer,
  WizardInner,
  WizardInnerSuccess,
  WizardIllustration,
  WizardTitle,
  WizardText,
  WizardActionsContainer,
  WizardActionsRowContainer,
  WizardButtonContainer,
  WizardLinkButtonContainer,
  WizardLinkContainer,
  WizardRegisterLinkContainer,
  WizardLinkHintContainer,
  WizardOrLine,
  WizardOptionLine,
  WizardStepTitle,
  WizardStepSubTitle,
  WizardInputs,
  WizardStepActionsContainer,
  LogoContainer,
  SimpleTextFooter,
  SimpleTextFooterSlim,
  WizardFormRow,
  WizardFormColumn,
  WizardFooterContainer,
  InnerWizardContentWrapper,
  WizardNotesContainer,
  WizardNotification,
};
