import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { DELIVERY_TYPE } from 'src/utils/consts';

const useCheckVendorEmail = () => {
  const vendors = useSelector(vendorsStore.selectors.all);

  const getVendorEmail = (vendorId?: number) => {
    const vendor = vendors[vendorId];
    return vendor?.contactEmail || '';
  };

  const isVendorEmailPresent = (vendorId?: number) => {
    const vendor = vendors[vendorId];
    return !isEmpty(vendor?.contactEmail);
  };

  const filterVendorIdsWithMissingEmail = (vendorIds: number[]) =>
    vendorIds.filter((vendorId, index, self) => {
      const vendor = vendors[vendorId];

      return self.indexOf(vendorId) === index && vendor && isEmpty(vendor.contactEmail);
    });

  const shouldShowBatchPayPopover = (vendorId?: number) => {
    const vendor = vendors[vendorId];

    return isEmpty(vendor?.contactEmail);
  };

  const shouldRequestVendorEmailsBatchPay = (vendorIds: number[]) => {
    const pickedVendors = vendorIds.map((id) => vendors[id]);

    return pickedVendors.some((vendor) => isEmpty(vendor.contactEmail));
  };

  const shouldRequestVendorEmailSinglePay = ({ id, deliveryType }: { id: number; deliveryType: DELIVERY_TYPE }) => {
    const vendor = vendors[id];
    const isUnilateralDeleveryMethod = deliveryType === DELIVERY_TYPE.VIRTUAL;

    return isEmpty(vendor?.contactEmail) && !isUnilateralDeleveryMethod;
  };

  return {
    getVendorEmail,
    isVendorEmailPresent,
    filterVendorIdsWithMissingEmail,
    shouldRequestVendorEmailSinglePay,
    shouldRequestVendorEmailsBatchPay,
    shouldShowBatchPayPopover,
  };
};

export { useCheckVendorEmail };
