import { useIntl } from 'react-intl';
import { isBulkPayment } from '../utils';

export const useInvoiceNumberWithPrefix = (payment) => {
  const intl = useIntl();

  function getInvoiceNumber() {
    if (isBulkPayment(payment)) {
      return intl.formatMessage({
        id: 'vendors.addDeliveryMethodByLink.bulkPaymentInvoiceNumber',
      });
    }

    if (payment?.bill?.invoiceNumber) {
      return intl.formatMessage(
        {
          id: 'vendors.addDeliveryMethodByLink.invoiceWithPrefix',
        },
        {
          invoiceNumber: payment.bill.invoiceNumber,
        }
      );
    }

    return intl.formatMessage({
      id: 'vendors.addDeliveryMethodByLink.invoicePlaceholder',
    });
  }

  return { invoiceNumber: getInvoiceNumber() };
};
