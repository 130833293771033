import styled from 'styled-components';

export const PaymentIcon = styled.i`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  > img {
    height: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;

export const PaymentMethodText = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.2px;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodText}
`;

export const PaymentInfoText = styled.div`
  color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoText}
`;
export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
`;

export const PaymentInfoHint = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  margin-left: 4.5rem;
  margin-right: 0.5rem;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoHint}
`;
