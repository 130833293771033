import { combineReducers } from 'redux';
import bills from 'src/modules/bills/bills-store';
import { connectRouter } from 'connected-react-router';
import payBillWizard from './payBillWizard/reducer';
import user from './user/reducer';
import { organizationReducer } from './organization/reducer';
import auth from '../modules/auth/auth-store';
import users from '../modules/users/users-store';
import profile from '../modules/profile/profile-store';
import organizations from '../modules/organizations/organizations-store';
import vendors from '../modules/vendors/vendors-store';
import payments from '../modules/payments/payment-store';
import deliveryMethods from '../modules/delivery-methods/delivery-methods-store';
import fundingSources from '../modules/funding-sources/funding-sources-store';
import regularBatchPaymentsStore from '../modules/regular-batch-payments/regular-batch-payments-store';
import intuitBankAccountsStore from '../modules/intuit-bank-accounts/intuit-bank-accounts-store';
import { dashboardStore } from '../billpay/qbdt/pages/dashboard/redux-store/dashboard-store';

export default function rootReducer(history) {
  const reducers = combineReducers({
    payBillWizard,
    user,
    organization: organizationReducer,
    bills: bills.reducer,
    auth: auth.reducer,
    users: users.reducer,
    profile: profile.reducer,
    organizations: organizations.reducer,
    vendors: vendors.reducer,
    payments: payments.reducer,
    deliveryMethods: deliveryMethods.reducer,
    fundingSources: fundingSources.reducer,
    regularBatchPayments: regularBatchPaymentsStore.reducer,
    dashboard: dashboardStore.reducer,
    router: connectRouter(history),
    intuitBankAccounts: intuitBankAccountsStore.reducer,
  });

  return (state, action) => {
    try {
      return reducers(state, action);
    } catch (e) {
      throw e;
    }
  };
}
