import React from 'react';
import { MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import styled from 'styled-components';

type CheckDepositedProps = {
  date: Date;
};

const CheckDeposited = ({ date }: CheckDepositedProps) => (
  <CheckDepositedContainer>
    <CheckDepositedLabel>
      <MIFormattedText label="bills.form.paymentActivity.checkDeposited" />
    </CheckDepositedLabel>
    <CheckDepositedDate>
      <MIFormattedText
        label="bills.form.paymentActivity.checkDepositedDate"
        values={{
          date: <MIFormattedDate date={date} timeZone="UTC" />,
        }}
      />
    </CheckDepositedDate>
  </CheckDepositedContainer>
);

export default CheckDeposited;

const CheckDepositedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CheckDepositedLabel = styled.div`
  color: ${(props) => props.theme.colors.white.opaque};
  background-color: ${(props) => props.theme.colors.success.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  border-radius: 0.4rem;
  padding: 0 0.8rem;
  text-transform: uppercase;
  ${(props) => props.theme.text.fontType.small};
`;

const CheckDepositedDate = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  margin-left: 1rem;
  ${(props) => props.theme?.components?.PaymentActivity?.baseTextStyles}
`;
