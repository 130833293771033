import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { BrandLogoContainer } from 'src/billpay/qbdt/components/basis-theory/brands-logos/BrandLogoContainer';
import { BasisTheoryCardsBrands } from 'src/billpay/qbdt/components/basis-theory/brands-logos/consts';
import { BasisTheoryCardBrandType } from 'src/billpay/qbdt/components/basis-theory/brands-logos/types';
import { ReactComponent as DinersLogo } from 'src/images/bills/icons/diners-logo.svg';
import { ReactComponent as VisaLogo } from 'src/images/bills/icons/visa-logo.svg';
import { ReactComponent as AmexLogo } from 'src/images/bills/icons/amex-logo.svg';
import { ReactComponent as DiscoverLogo } from 'src/images/bills/icons/discover-logo.svg';
import { ReactComponent as MastercardLogo } from 'src/images/bills/icons/mastercard-logo.svg';

type Props = {
  selectedBrand: BasisTheoryCardBrandType;
};

export const BrandsLogos = ({ selectedBrand }: Props) => {
  const isBrandSelected = (brand: BasisTheoryCardBrandType) =>
    selectedBrand === brand || selectedBrand === BasisTheoryCardsBrands.unknown;

  return (
    <Flex justify="center" textAlign="center" width="full">
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.visa)}>
        <VisaLogo />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.mastercard)}>
        <MastercardLogo />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.amex)}>
        <AmexLogo />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.diners)}>
        <DinersLogo />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.discover)}>
        <DiscoverLogo />
      </BrandLogoContainer>
    </Flex>
  );
};
