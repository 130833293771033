import React from 'react';
import styled from 'styled-components';

const fees = [
  {
    paymentMethod: 'ACH bank transfer',
    deliveryMethod: 'ACH bank transfer',
    cost: 'Free',
  },
  {
    paymentMethod: 'ACH bank transfer',
    deliveryMethod: 'Check',
    cost: '$1.50',
  },
  {
    paymentMethod: 'Credit or debit card',
    deliveryMethod: 'Check',
    cost: '2.9% transaction fee (you won’t pay $1.50)',
  },
  {
    paymentMethod: 'ACH bank transfer',
    deliveryMethod: 'Fast check',
    cost: '$20 fixed fee',
  },
  {
    paymentMethod: 'Credit or debit card',
    deliveryMethod: 'Fast check',
    cost: '2.9% transaction fee + $20 fixed fee',
  },
];

export const CheckFeesFAQTable = () => (
  <Table>
    <thead>
      <tr>
        <th>Payment method</th>
        <th>Delivery method</th>
        <th>Cost</th>
      </tr>
    </thead>
    <tbody>
      {fees.map((fee) => (
        <tr key={fee.cost}>
          <td>{fee.paymentMethod}</td>
          <td>{fee.deliveryMethod}</td>
          <td>{fee.cost}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;

  th,
  td {
    width: 33%;
    padding: 5px;
    border: 1px solid black;
  }
`;
