import React from 'react';
import { Text, VStack } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { useBreak } from 'src/hoc';

export const PageInfo = () => {
  const { isDesktop } = useBreak();
  return (
    <VStack gap="ds.lg">
      <Text textStyle="ds.h5" fontWeight="ds.semi" align="center" m="0">
        <MIFormattedText label="vendor.pushToDebit.virtualCard.confirm.title" />
      </Text>
      <Text textStyle={isDesktop ? 'ds.body2' : 'ds.body1'} align="center" m="0">
        <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.text" />
      </Text>
    </VStack>
  );
};
