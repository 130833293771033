class ValidationError extends Error {
  error: { validationErrors: any };

  constructor({ message = '', validationErrors }: { message?: string; validationErrors: Record<string, any> }) {
    super(message);
    this.error = { validationErrors };
  }
}

export default ValidationError;
