import analytics from 'src/services/analytics';
import { logger } from 'src/services/loggers';
import { debug } from './util';
import adapter from './adapter';
import { QBDTSDKSeverity, RetryOptions } from './types';
import { SDK_STATUS_CODES, TRANSACTION_LOCK_ERROR_CODES } from './constants';

const domParser = new DOMParser();

/**
 * Executes an SDK request
 *
 * Pass a qbXML operation. To provide a more simple implementation, only send what should be in between QBXMLMsgsRq
 * tags. Also, only send one request per call. This should provide an easier abstraction to begin with allowing this
 * function to validate the response generically.
 *
 * It then checks the response and validates a status code "0" response. If none is provided, a `QBDTSDKError` is
 * raised.
 *
 * @example
 * adapter.executeSDKRequest('<BillQueryRq><TxnID>AB-CDEFG123422</TxnID></BillQueryRq>');
 *
 * @param data [string] A qbXML string
 */
export async function executeSDKRequest(data: string, retryOptions?: RetryOptions) {
  return internalExecuteSDKRequest(data, retryOptions?.retries, retryOptions?.retrySleep, retryOptions?.retries);
}

async function internalExecuteSDKRequest(data, retries = 2, retrySleep = 1500, initialRetries = 2) {
  const onError = 'stopOnError';
  const version = '14.0';
  const request = `<?xml version="1.0"?>
<?qbxml version="${version}"?>
<QBXML>
<QBXMLMsgsRq onError="${onError}">
  ${data}
</QBXMLMsgsRq>
</QBXML>`;

  let response;

  try {
    const raw = await adapter.executeSDKRequest(request);
    response = domParser.parseFromString(raw, 'application/xml');

    // >> DEBUG
    (window as any).qbdtLastResponse = response;
    debug('executeSDKRequest', request, raw);
    // << DEBUG

    validateResponse(response);
  } catch (error: any) {
    if (error instanceof QBDTSDKError && TRANSACTION_LOCK_ERROR_CODES.includes(error.code)) {
      if (retries === 0) {
        handleError(request, response, error);
      } else {
        analytics.track('qbdt-sqk', 'retry-request', { retries });
        debug(`executeSDKRequest: retrying ${retries} ${retrySleep}`);
        await new Promise((r) => setTimeout(r, retrySleep));
        return internalExecuteSDKRequest(data, retries - 1, retrySleep, initialRetries);
      }
    } else {
      handleError(request, response, error);
    }
  }

  if (retries < initialRetries) {
    analytics.track('qbdt-sqk', 'retry-request-succeed', {
      retries,
      initialRetries,
      retrySleep,
    });
  }

  return response;
}

export class QBDTSDKError extends Error {
  doc: Document;
  code: string;
  severity: QBDTSDKSeverity;

  constructor(message, code, severity, doc) {
    super(message);

    this.doc = doc;
    this.code = code;
    this.severity = severity;
  }
}

function validateResponse(doc: Document) {
  if (!doc.querySelector) {
    throw new Error(`Supplied doc is of unexpected value "${doc}"`);
  }

  const msgResponse = doc.querySelector('QBXMLMsgsRs');
  if (!msgResponse) {
    throw new Error('Failed to parse response');
  }

  const response = msgResponse.firstElementChild;
  if (!response) {
    throw new Error('Response not found under QBXMLMsgsRs');
  }

  const statusCode = response?.getAttribute('statusCode');

  if (statusCode === SDK_STATUS_CODES.OK) {
    return response;
  }

  const statusSeverity = response?.getAttribute('statusSeverity') as QBDTSDKSeverity;
  const statusMessage = response?.getAttribute('statusMessage');

  throw new QBDTSDKError(statusMessage, statusCode, statusSeverity, doc);
}

export async function getSingleEntity<T>(query: string, mapper: (Element) => T): Promise<T> {
  return mapper(await executeSDKRequest(query));
}

export async function getAllEntities<T>(query, resultSelector, mapper: (Element) => T): Promise<T[]> {
  const sdkResponse = await executeSDKRequest(query);
  return Array.from(sdkResponse.querySelectorAll(resultSelector)).map(mapper);
}

type IteratorItemResponseBulk<T> = {
  entities: T[];
  remainingCount?: number;
  totalCount: number;
};

export async function* batchQueryWithIterator<T>(
  query: string,
  mapper: (Element) => T,
  pageSize = 20
): AsyncGenerator<IteratorItemResponseBulk<T>> {
  const queryDoc: Element | null = new DOMParser().parseFromString(query, 'application/xml').firstElementChild;

  if (!queryDoc) {
    throw Error(`Could not parse ${query}`);
  }

  queryDoc.prepend(
    new DOMParser().parseFromString(`<MaxReturned>${pageSize}</MaxReturned>`, 'application/xml').documentElement
  );
  queryDoc.setAttribute('iterator', 'Start');

  let sdkResponse;
  let iteratorID;
  let queryResponse;
  let iteratorRemaining;

  let remainingCount;
  let totalCount;

  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      sdkResponse = await executeSDKRequest(queryDoc.outerHTML);
      iteratorID = sdkResponse.querySelector('[iteratorID]').getAttribute('iteratorID');
      queryResponse = sdkResponse.querySelector('QBXMLMsgsRs').firstElementChild;
      iteratorRemaining = parseInt(queryResponse.getAttribute('iteratorRemainingCount') || '0', 10);

      queryDoc.setAttribute('iterator', 'Continue');
      queryDoc.setAttribute('iteratorID', iteratorID);

      if (totalCount === undefined) {
        totalCount = iteratorRemaining + queryResponse.children.length;
        remainingCount = totalCount - 1;
      }

      const entities = Array.from(queryResponse.children).map(mapper) || [];
      yield { entities, remainingCount, totalCount };
      remainingCount -= queryResponse.children.length;
    } while (iteratorRemaining > 0);
  } catch (e) {
    if (e instanceof QBDTSDKError && e.code === SDK_STATUS_CODES.NO_MATCH) {
      yield { totalCount: 0, entities: [] };
      return;
    }

    logger.error('qbdtSdk.queryWithIterator(): Failed to generate query results', e);
    if (iteratorID && iteratorRemaining > 0) {
      logger.error('qbdtSdk.queryWithIterator(): Stopping iterator to avoid memory leak', iteratorID);
      queryDoc.setAttribute('iterator', 'Stop');
      queryDoc.setAttribute('iteratorID', iteratorID);
      await executeSDKRequest(queryDoc.outerHTML);
    }

    throw e;
  }
}

function handleError(request, response, error: Error) {
  if (error instanceof QBDTSDKError) {
    throw error;
  }

  if (!response) {
    logger.error('qbdtSdk.executeSDKRequest(): Unexpected error occurred, no response', request, error);
    throw error;
  }

  if (response instanceof Document) {
    const serializer = new XMLSerializer();
    logger.error(
      'qbdtSdk.executeSDKRequest(): Unexpected error occurred, no response, serializer',
      request,
      serializer.serializeToString(response)
    );
    throw error;
  }

  logger.error('qbdtSdk.executeSDKRequest(): Unexpected error occurred, response is not a Document', request, response);
  throw error;
}
