import React from 'react';
import { useSelector } from 'react-redux';
import { VendorEmailInput } from 'src/billpay/qbdt/modules/vendor-email/components/VendorEmalInput';
import { useCheckVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useCheckVendorEmail';
import { useUpdateVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useUpdateVendorEmail';
import { useValidateVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useValidateVendorEmail';
import { Modal, ModalTextContent, ModalTitle } from 'src/theme/ds';
import MIButton from 'src/components/common/MIButton';
import { MITextInput } from 'src/components/common/MITextInput';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { getBillIdsFromBatchItem } from '../../utils';
import { BATCH_PAGE_EVENT } from '../../analytics/event-mapping';
import { useHandleBatchItemMemo } from './useHandleBatchItemMemo';

type Props = {
  vendorId: number;
  batchItemId: string;
  dismiss: VoidFunction;
};

const VendorMemoModal = ({ vendorId, batchItemId, dismiss }: Props) => {
  const { isVendorEmailPresent } = useCheckVendorEmail();
  const { loading, updateVendorEmail } = useUpdateVendorEmail({ eventPage: BATCH_PAGE_EVENT });
  const { getVendorEmail, setVendorEmail, getValidationError, validateVendorEmail } = useValidateVendorEmail({
    eventPage: BATCH_PAGE_EVENT,
  });

  const { memo, setMemo, updateBatchItemMemo } = useHandleBatchItemMemo({ batchItemId });

  const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchItemId));
  const billIds = getBillIdsFromBatchItem(batchItem);

  const handleEmailChange = ({ id, value }) => {
    setVendorEmail({ id, value });
  };

  const handleMemoChange = ({ value }) => {
    setMemo(value);
  };

  const handleCloseClick = () => {
    dismiss();
  };

  const handleContinueClick = async () => {
    analytics.track(BATCH_PAGE_EVENT, 'save-memo-to-vendor-click', {
      billIds,
      vendorId,
    });

    const email = getVendorEmail(vendorId);

    if (email) {
      const isEmailValid = validateVendorEmail({ id: vendorId, optional: true });

      if (!isEmailValid) {
        return;
      }

      await updateVendorEmail({ id: vendorId, email });
    }

    updateBatchItemMemo();
    dismiss();
  };

  const isVendorEmailMissing = !isVendorEmailPresent(vendorId);

  return (
    <Modal id="batch-add-vendor-memo-dialog" onCloseClick={handleCloseClick}>
      <ModalTextContent align="center">
        <ModalTitle label="regularBatchPayments.dialog.addMemo.title" />
      </ModalTextContent>

      <MITextInput
        id="memo"
        type="text"
        value={memo}
        onChange={handleMemoChange}
        label="regularBatchPayments.dialog.addMemo.input.memo.label"
        placeholder="regularBatchPayments.dialog.addMemo.input.memo.placeholder"
        privateData
      />

      {isVendorEmailMissing && (
        <VendorEmailInput
          vendorId={vendorId.toString()}
          value={getVendorEmail(vendorId)}
          error={getValidationError(vendorId)}
          onChange={handleEmailChange}
        />
      )}

      <PrimaryButton
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={handleContinueClick}
        label="general.continue"
        size={BUTTON_SIZE.NORMAL}
        isProcessing={loading}
      />
    </Modal>
  );
};

const PrimaryButton = styled(MIButton)`
  width: 11.6rem;
  padding: 0;
  align-self: center;
`;

export { VendorMemoModal };
