import * as dateFns from 'date-fns';
import uniq from 'lodash/uniq';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { fetchBatchBillsListSlice } from 'src/modules/regular-batch-payments/regular-batch-bills-list-slice';
import { createBatchPaymentSlice } from 'src/modules/regular-batch-payments/regular-batch-payments-create-slice';
import { regularBatchBillsSettingsSlice } from 'src/modules/regular-batch-payments/regular-batch-bills-settings-slice';
import { DELETE_FUNDING_SOURCE_SUCCESS, VERIFY_FUNDING_SOURCE_FAILED } from 'src/redux/user/actionTypes';
import {
  regularBatchUpdateDeliveryOptionsSlice,
  BATCH_UPDATE_DELIVERY_OPTIONS,
} from 'src/modules/regular-batch-payments/regular-batch-update-delivery-options-slice';
import {
  regularBatchUpdatePaymentsSlice,
  BATCH_UPDATE_PAYMENTS,
} from 'src/modules/regular-batch-payments/regular-batch-update-payments-slice';
import {
  regularBatchUpdateDeductionDatesSlice,
  BATCH_UPDATE_DEDUCTION_DATES,
} from 'src/modules/regular-batch-payments/regular-batch-update-deduction-dates-slice';
import { DATE_FORMATS } from 'src/utils/date-fns';
import { BatchDateOption } from 'src/billpay/qbdt/pages/batch-pay/consts';
import { VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES } from 'src/utils/consts';
import { name } from './consts';
import {
  regularBatchUpdateDeliveryOptionsByDueDateSlice,
  BATCH_UPDATE_DELIVERY_OPTIONS_BY_DUE_DATE,
} from './regular-batch-update-delivery-options-slice-due-date';
import { BatchItemType } from './types/store-types';
import { isPaymentReady, getCurrentBatchItems } from './utils';

const persistConfig = {
  whitelist: ['settings'],
};

const syncSettingsFundingSourceWithBatchItems = (state) => {
  const batchItems = getCurrentBatchItems(state);
  const fundingSourceIds = batchItems.map((batchItem) => batchItem.payment.fundingSourceId);

  const isMultipleFundingSource = uniq(fundingSourceIds).length > 1;

  if (!isMultipleFundingSource) {
    // eslint-disable-next-line prefer-destructuring
    state.settings.fundingSourceId = fundingSourceIds[0];
  }
};

const syncSettingsScheduledDateWithBatchItems = (state) => {
  const batchItems = getCurrentBatchItems(state);
  const deductionDates = batchItems.map((batchItem) =>
    dateFns.format(new Date(batchItem.payment.scheduledDate), DATE_FORMATS.isoDate)
  );

  const isMultipleDeductionDate =
    state.settings.dateOption === BatchDateOption.ALL_AT_ONCE && uniq(deductionDates).length > 1;

  if (!isMultipleDeductionDate) {
    // eslint-disable-next-line prefer-destructuring
    state.settings.scheduledDate = batchItems[0].payment.scheduledDate;
  }
};

const syncSettingsWithDeletedFundingSource = (state, action) => {
  const { deletedFundingSourceId } = action;

  if (deletedFundingSourceId === state.settings.fundingSourceId) {
    // eslint-disable-next-line prefer-destructuring
    state.settings.refreshRequired = true;
  }
};

const syncSettingsWithBlockedFundingSource = (state, action) => {
  const { verifiedFundingSourceId, errorCode } = action;

  if (
    errorCode === VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS &&
    verifiedFundingSourceId === state.settings.fundingSourceId
  ) {
    // eslint-disable-next-line prefer-destructuring
    state.settings.refreshRequired = true;
  }
};

const regularBatchPaymentsStore = createRestfulSlice<BatchItemType>({
  api: {},
  name,
  persistConfig,
  selectors: {
    isMultipleFundingSource: (state) => {
      if (!state[name].settings?.billIds || !state[name].byId) {
        return false;
      }

      const batchItems = getCurrentBatchItems(state[name]);
      const fundingSourceIds = batchItems.map((batchItem) => batchItem.payment.fundingSourceId);

      return uniq(fundingSourceIds).length > 1;
    },
    isMultipleDeductionDate: (state) => {
      if (!state[name].settings?.billIds || !state[name].byId) {
        return false;
      }

      const batchItems = getCurrentBatchItems(state[name]);
      const deductionDates = batchItems.map((batchItem) =>
        dateFns.format(new Date(batchItem.payment.scheduledDate), DATE_FORMATS.isoDate)
      );

      return state[name].settings?.dateOption === BatchDateOption.ALL_AT_ONCE && uniq(deductionDates).length > 1;
    },
    totalAmount: (state) =>
      sumBy(getCurrentBatchItems(state[name]), (batchItem: BatchItemType) =>
        batchItem.payment.deliveryMethodId ? batchItem.payment.amount : 0
      ),

    readyBillsCount: (state) =>
      getCurrentBatchItems(state[name]).filter((batchItem: BatchItemType) => isPaymentReady(state, batchItem.id))
        .length,

    allBillsReady: (state) =>
      getCurrentBatchItems(state[name]).every((batchItem: BatchItemType) => isPaymentReady(state, batchItem.id)),

    allBills: (state) => getCurrentBatchItems(state[name]).map(({ id }: BatchItemType) => state[name].byId[id]),
    isBillsGroupingPossible: (state) => {
      const batchItems = getCurrentBatchItems(state[name]);

      const batchItemsByVendorId = groupBy(batchItems, 'payment.vendorId');
      return batchItems.some((batchItem) => {
        const { vendorId } = batchItem.payment;
        const paymentHasMultipleBills = batchItem.payment.bills.length > 1;
        const batchItemsCountForCurrentVendor = batchItemsByVendorId[vendorId].length;
        const multipleBillsExistForVendor = batchItemsCountForCurrentVendor > 1;

        if (paymentHasMultipleBills || multipleBillsExistForVendor) {
          return true;
        }

        return false;
      });
    },
    totalCount: (state) => getCurrentBatchItems(state[name]).length,
    getMemo: (id: string) => (state) => state[name].byId[id]?.payment.note,
    byId: (id: string) => (state) => state[name].byId[id],
  },
  slices: {
    list: fetchBatchBillsListSlice,
    settings: regularBatchBillsSettingsSlice,
    createBatchPayment: createBatchPaymentSlice,
    updatePayments: regularBatchUpdatePaymentsSlice,
    updatePaymentsDeliveryOptions: regularBatchUpdateDeliveryOptionsSlice,
    updatePaymentsDeductionDates: regularBatchUpdateDeductionDatesSlice,
    updatePaymentsDeductionDatesByDueDate: regularBatchUpdateDeliveryOptionsByDueDateSlice,
  },
  extraReducers: {
    [DELETE_FUNDING_SOURCE_SUCCESS](state, action) {
      syncSettingsWithDeletedFundingSource(state, action);
    },
    [VERIFY_FUNDING_SOURCE_FAILED](state, action) {
      syncSettingsWithBlockedFundingSource(state, action);
    },
    [`${BATCH_UPDATE_PAYMENTS}/setPaymentsMethod`](state) {
      syncSettingsFundingSourceWithBatchItems(state);
    },
    [`${BATCH_UPDATE_PAYMENTS}/setPaymentScheduledDate`](state) {
      syncSettingsScheduledDateWithBatchItems(state);
    },
    [`${BATCH_UPDATE_DEDUCTION_DATES}_REQUEST`](state) {
      syncSettingsFundingSourceWithBatchItems(state);
    },
    [`${BATCH_UPDATE_DELIVERY_OPTIONS}_REQUEST`](state) {
      syncSettingsFundingSourceWithBatchItems(state);
      syncSettingsScheduledDateWithBatchItems(state);
    },
    [`${BATCH_UPDATE_DELIVERY_OPTIONS_BY_DUE_DATE}_REQUEST`](state) {
      syncSettingsFundingSourceWithBatchItems(state);
    },
  },
  listHashFunc: () => 'RegularBatchPayments',
});

export default regularBatchPaymentsStore;
