import React, { RefObject, useEffect, useState } from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-js/types/elements/elements';
import { BasisTheoryCardBrandType } from 'src/billpay/qbdt/components/basis-theory/brands-logos/types';
import { CardExpirationDateField } from 'src/billpay/qbdt/components/basis-theory/fields/CardExpirationDateField';
import { CardNumberField } from 'src/billpay/qbdt/components/basis-theory/fields/CardNumberField';
import { CardVerificationCodeField } from 'src/billpay/qbdt/components/basis-theory/fields/CardVerificationCodeField';

type Props = {
  cardNumberElementRef: RefObject<CardNumberElement>;
  cardExpirationDateElementRef: RefObject<CardExpirationDateElement>;
  cardVerificationCodeElementRef: RefObject<CardVerificationCodeElement>;
  onCardBrandChange(cardBrand: BasisTheoryCardBrandType): void;
  onReady(): void;
  onError(hasError: boolean): void;
  attemptedToLink: boolean;
};

export const CardDetailsForm = ({
  cardNumberElementRef,
  cardExpirationDateElementRef,
  cardVerificationCodeElementRef,
  onCardBrandChange,
  onReady,
  onError,
  attemptedToLink,
}: Props) => {
  const [cardNumberReady, setCardNumberReady] = useState(false);
  const [cardExpirationDateReady, setCardExpirationDateReady] = useState(false);
  const [cardVerificationCodeReady, setCardVerificationCodeReady] = useState(false);

  const [cardNumberError, setCardNumberError] = useState(true);
  const [cardExpirationDateError, setCardExpirationDateError] = useState(true);
  const [cardVerificationCodeError, setCardVerificationCodeError] = useState(true);

  useEffect(() => {
    const isReady = [cardNumberReady, cardExpirationDateReady, cardVerificationCodeReady].every(
      (isFieldReady) => isFieldReady
    );

    if (isReady) {
      onReady();
    }
  }, [cardNumberReady, cardExpirationDateReady, cardVerificationCodeReady, onReady]);

  useEffect(() => {
    const hasError = [cardNumberError, cardExpirationDateError, cardVerificationCodeError].some(
      (fieldHasError) => fieldHasError
    );
    onError(hasError);
  }, [cardNumberError, cardExpirationDateError, cardVerificationCodeError, onError]);

  const handleCardNumberReady = () => {
    setCardNumberReady(true);
  };

  const handleCardExpirationDateReady = () => {
    setCardExpirationDateReady(true);
  };

  const handleCardVerificationCodeReady = () => {
    setCardVerificationCodeReady(true);
  };

  const handleCardNumberError = (hasError: boolean) => {
    setCardNumberError(hasError);
  };

  const handleCardExpirationDateError = (hasError: boolean) => {
    setCardExpirationDateError(hasError);
  };

  const handleCardVerificationCodeError = (hasError: boolean) => {
    setCardVerificationCodeError(hasError);
  };

  return (
    <Box>
      <Box mb="ds.3xl">
        <CardNumberField
          elementRef={cardNumberElementRef}
          onReady={handleCardNumberReady}
          onError={handleCardNumberError}
          onCardBrandChange={onCardBrandChange}
          attemptedToLink={attemptedToLink}
        />
      </Box>
      <Flex width="full" justify="space-between" mb="ds.3xl">
        <CardExpirationDateField
          elementRef={cardExpirationDateElementRef}
          onReady={handleCardExpirationDateReady}
          onError={handleCardExpirationDateError}
          attemptedToLink={attemptedToLink}
        />
        <CardVerificationCodeField
          elementRef={cardVerificationCodeElementRef}
          onReady={handleCardVerificationCodeReady}
          onError={handleCardVerificationCodeError}
          attemptedToLink={attemptedToLink}
        />
      </Flex>
    </Box>
  );
};
