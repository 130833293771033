import isEmpty from 'lodash/isEmpty';
import { CONSTS } from './consts';
import { AccountLimitations, CompanyField, CompanyInfoType, UserContextType } from './types';

function getTaxIdTypesOptions() {
  return [
    { id: CONSTS.TAX_ID_TYPE.EIN, label: 'companyInfo.taxIdTypes.EIN' },
    { id: CONSTS.TAX_ID_TYPE.SSN, label: 'companyInfo.taxIdTypes.SSN' },
    { id: CONSTS.TAX_ID_TYPE.ITIN, label: 'companyInfo.taxIdTypes.ITIN' },
  ];
}

function isCompanyInfoDone(companyInfo: CompanyInfoType, limitations?: AccountLimitations): boolean {
  if (!isEmpty(limitations?.entitiesInformation?.company?.missingFields)) {
    const requiredFields = [
      CompanyField.Address,
      CompanyField.CompanyName,
      CompanyField.ContactFirstName,
      CompanyField.ContactLastName,
      CompanyField.LegalAddress,
      CompanyField.LegalCompanyName,
      CompanyField.PhoneNumber,
      CompanyField.TaxInfoIdentifier,
      CompanyField.TaxInfoType,
    ];
    const fieldsFromRiskApi = limitations?.entitiesInformation?.company?.missingFields?.map((field) => field.name);
    return !includesAny(fieldsFromRiskApi, requiredFields);
  }

  return !!(
    companyInfo.legalCompanyName &&
    companyInfo.legalFormattedAddress &&
    companyInfo.taxId &&
    companyInfo.taxIdType &&
    companyInfo.contactFirstName &&
    companyInfo.contactLastName &&
    companyInfo.phone &&
    companyInfo.addressLine1 &&
    companyInfo.zipCode &&
    companyInfo.city &&
    companyInfo.state
  );
}

export const includesAny = (arr, values) => {
  if (arr && Array.isArray(arr)) {
    return values.some((v) => arr.includes(v));
  }

  return false;
};

const isProfileDateOfBirthAdded = (profile: UserContextType) => !!profile.dateOfBirth;

export { getTaxIdTypesOptions, isCompanyInfoDone, isProfileDateOfBirthAdded };
