import { RecordOf } from 'immutable';
import React from 'react';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { CONSTS } from 'src/utils/consts';
import { mapCalendarFees } from 'src/utils/fee';
import { MIFormattedDate } from 'src/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType, DeliveryOptionType, OptionalDeliveryMethodsType } from 'src/utils/types';
import { DeliveryFastOptionsView } from './DeliveryFastOptionsView';
import { DeliveryRegularView } from './DeliveryRegularView';

type Props = {
  fundingSource: RecordOf<AccountType> | null;
  scheduledDate: Date;
  deliveryDate: Date;
  maxDeliveryDate: Date | null;
  deliveryMethodType: OptionalDeliveryMethodsType;
  deliveryOptions: DeliveryOptionType[];
  deliveryPreference?: string;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, type?: string) => void;
};

const PaymentDatePickerDelivery = ({
  fundingSource,
  scheduledDate,
  deliveryDate,
  maxDeliveryDate,
  deliveryMethodType,
  deliveryOptions,
  deliveryPreference,
  fundingSourceType,
  onSelectDeliveryOption,
}: Props) => {
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethodType === CONSTS.DELIVERY_TYPE.VIRTUAL;

  const getTextValues = () => {
    const defaultLabels = {
      [CONSTS.DELIVERY_TYPE.ACH]: 'bills.pay.date.ach.deliveryMethod',
      [CONSTS.DELIVERY_TYPE.CHECK]: 'bills.pay.date.check.deliveryMethod',
    };
    let label = defaultLabels[deliveryMethodType] || defaultLabels[CONSTS.DELIVERY_TYPE.ACH];

    if (isMicrodeposit) {
      label = 'bills.pay.date.paymentDatePickerDescription.unverifiedBankAccount';
    } else if (isUnilateral) {
      label = 'bills.pay.date.paymentDatePickerDescription.unilateral';
    }

    const identifier = getAccountNumber4digits(fundingSource?.bankAccount);
    const values = {
      date: <MIFormattedDate date={scheduledDate} year={undefined} />,
      bankAccount: `(...${identifier})`,
    };

    return { label, values };
  };

  const { label, values } = getTextValues();

  if (deliveryOptions && deliveryOptions.length > 1) {
    const fastFeeOptions = mapCalendarFees(deliveryOptions);

    return (
      <DeliveryFastOptionsView
        showLabel={isMicrodeposit}
        fundingSourceType={fundingSourceType}
        deliveryOptions={fastFeeOptions}
        deliveryPreference={deliveryPreference}
        onSelectDeliveryOption={onSelectDeliveryOption}
        label={label}
        values={values}
      />
    );
  }

  return (
    <DeliveryRegularView
      deliveryOptions={deliveryOptions}
      deliveryDate={deliveryDate}
      maxDeliveryDate={maxDeliveryDate}
      label={label}
      values={values}
    />
  );
};

export { PaymentDatePickerDelivery };
