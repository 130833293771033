import React from 'react';
import { Flex } from '@melio/billpay-design-system';

type Props = {
  onClick: VoidFunction;
  children: React.ReactNode;
  testId?: string;
};

export const DeliveryMethodCardWrapper = ({ onClick, children, testId }: Props) => (
  <Flex
    data-testid={testId}
    onClick={onClick}
    p="ds.xl"
    borderRadius="ds.md"
    borderStyle="solid"
    borderWidth="0.1rem"
    borderColor="ds.gray.400"
    sx={{
      '&:hover': {
        cursor: 'pointer',
      },
    }}
  >
    {children}
  </Flex>
);
