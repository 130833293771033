import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Flex, Icon, Link, Text } from '@melio/billpay-design-system';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { ReactComponent as BankAccountIcon } from 'src/images/general/bank-account-icon.svg';
import { DeliveryMethodType } from 'src/utils/types';
import { MIFormattedText } from 'src/utils/formatting';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { BANK_ACCOUNT_TYPE, CONSTS } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';
import { useSiteContext } from 'src/hoc/withSiteContext';
import analytics from 'src/services/analytics';
import { getJWTPayload } from 'src/helpers/jwt';
import { useShiftSUVCtoACH } from 'src/pages/vendor/shift-virtual-card-to-ach/hooks/useShiftSUVCtoACH';
import locations from '../locations';

type Props = {
  achDeliveryMethod?: DeliveryMethodType;
  token: string;
  vendorId: string;
};

export const CurrentBankAccountPage = ({ achDeliveryMethod, token, vendorId }: Props) => {
  const history = useHistory();
  const site = useSiteContext();
  const { paymentId } = getJWTPayload(token);
  const { shiftVirtualCardToAch, isProcessing } = useShiftSUVCtoACH(token);

  const digits = getAccountNumber4digits(achDeliveryMethod?.bankAccount);
  const supportEmail = site.config.support.email;

  const handleEditBankAccount = () =>
    history.push(generatePath(locations.newBankAccount, { token }), {
      accountNumber: achDeliveryMethod?.bankAccount?.accountNumber,
      routingNumber: achDeliveryMethod?.bankAccount?.routingNumber,
      deliveryMethodId: achDeliveryMethod?.id,
      goBack: true,
    });

  const handleSubmit = async () => {
    try {
      const { accountNumber, routingNumber } = achDeliveryMethod?.bankAccount || {};
      if (accountNumber && routingNumber) {
        await shiftVirtualCardToAch(
          {
            bankAccount: {
              accountType: BANK_ACCOUNT_TYPE.CHECKING,
              accountNumber,
              routingNumber,
            },
          },
          achDeliveryMethod?.id
        );

        history.push(generatePath(locations.success, { token }), {
          digits: accountNumber.slice(-4),
        });
      }
    } catch {
      history.push(generatePath(locations.invalidToken, { token }));
    }
  };

  useEffect(() => {
    analytics.trackAction('vendor-add-ach-bank-details-page-viewed', {
      paymentId,
      vendorId,
      isNewACH: false,
    });
  }, []);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Text textStyle="ds.body2Semi" m="0" color="ds.gray.100" alignSelf="start">
          <MIFormattedText label="vendor.unilateral.shiftVirtualCardToAch.existingBankAccount.title" />
        </Text>
        <Flex
          p="ds.xl"
          mt="ds.xl"
          mb="ds.2xl"
          gap="ds.xl"
          w="100%"
          boxSizing="border-box"
          borderWidth="0.1rem"
          borderStyle="solid"
          borderColor="ds.gray.400"
          borderRadius="ds.lg"
          align="center"
        >
          <Icon as={BankAccountIcon} w="3rem" h="3rem" />
          <Flex direction="column" flex="1" gap="ds.sm">
            <Text textStyle="ds.body2Medium" m="0" color="ds.gray.100">
              <MIFormattedText label="payBillPaymentActivity.fundingSource.achLabel" />
            </Text>
            <Text textStyle="ds.body2Medium" m="0" color="ds.gray.300">
              <MIFormattedText label="regularBatchPayments.deliveryMethodLabels.bankDescription" values={{ digits }} />
            </Text>
          </Flex>
          <Box as={Link} onClick={handleEditBankAccount} textStyle="ds.body2Medium">
            <MIFormattedText label="deliveryMethods.actions.edit" />
          </Box>
        </Flex>
        <MIButton
          label="vendor.unilateral.shiftVirtualCardToAch.existingBankAccount.submit"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={handleSubmit}
          isProcessing={isProcessing}
          fullWidth
        />
        <Text textStyle="ds.body2" mt="ds.lg" mb="0" color="ds.gray.100" alignSelf="start">
          <MIFormattedText
            label="vendor.unilateral.shiftVirtualCardToAch.existingBankAccount.needHelp"
            values={{
              supportLink: <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>,
            }}
          />
        </Text>
      </VendorFlowContentCard>
      <Flex gap="ds.md" mt="ds.xl">
        <Text textStyle="ds.body3Semi" m="0" color="ds.gray.200">
          <MIFormattedText label="vendors.addDeliveryMethodByLink.support" />
        </Text>
        <Text textStyle="ds.body3" m="0">
          <Link href={`mailto:${supportEmail}`} color="ds.gray.200">
            {supportEmail}
          </Link>
        </Text>
      </Flex>
      <Footer />
    </VendorFlowLayout>
  );
};
