import React from 'react';
import { Box, VStack, HStack, StackDivider } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

import { CombineBills } from './CombineBills';
import { SelectPaymentMethod } from './SelectPaymentMethod';
import { SetDeductionDate } from './SetDeductionDate';
import { useWalkthroughState } from './hooks/useWalkthroughState';

const PaymentsSettings = () => {
  const { getPopoverProps } = useWalkthroughState();

  return (
    <VStack w="full" spacing="ds.lg" align="flex-start">
      <Box textStyle="ds.body4Semi" color="ds.gray.200" textTransform="uppercase">
        <MIFormattedText label="regularBatchPayments.paymentsSettings.title" />
      </Box>

      <HStack align="flex-start" spacing="ds.2xl" divider={<StackDivider w="0.1rem" bgColor="ds.gray.500" />}>
        <CombineBills getPopoverProps={getPopoverProps} />
        <SelectPaymentMethod getPopoverProps={getPopoverProps} />
        <SetDeductionDate getPopoverProps={getPopoverProps} />
      </HStack>
    </VStack>
  );
};

export { PaymentsSettings };
