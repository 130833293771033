import React from 'react';
import Dialog from 'src/components/common/QBOMIDialog';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/utils/consts';

type Props = {
  onClose: () => void;
  errorCode: string;
};

export const AddCardErrorModal = ({ onClose, errorCode }: Props) => (
  <Dialog
    type={DIALOG_TYPE.ALERT}
    variant={DIALOG_VARIANTS.ERROR}
    title="onboarding.fundingSources.card.notAddedTitle"
    subtitle={`server.${errorCode}`}
    onCancelAction={onClose}
    cancelButtonText="onboarding.fundingSources.card.errorButton"
  />
);
