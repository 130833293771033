import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import { MICardForm, MICardTitle } from 'src/components/common/MICard';
import { CONSTS, DELIVERY_TYPE, SCREEN_MODE } from 'src/utils/consts';
import { DeliveryMethodType, FieldType, VendorType } from 'src/utils/types';
import { isEnterPressed } from 'src/utils/events';
import vendorsApi from 'src/services/api/vendors';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import QBDTFloatedButtons from 'src/billpay/qbdt/components/QBDTFloatedButtons';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { useApi } from 'src/hoc/useApi';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import VendorDeliveryMethodsList from './components/VendorDeliveryMethodsList';
import VendorForm from './components/VendorForm';

const eventPage = 'vendor';

export const ViewVendorPage = () => {
  const { id } = useParams<{ id: string }>();
  const orgId = useOrgId();
  const vendorActions = useStoreActions(vendorsStore);
  const isVendorUpdating = useSelector(vendorsStore.selectors.update.status(id))?.loading;
  const [getVendor, , isVendorLoading] = useApi(vendorsApi.getVendorById, false, true);
  const [vendor, setVendor] = useState<VendorType | null>(null);
  const history = useHistory();
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const loadVendor = async () => {
      if (id) {
        const { object } = await getVendor({ orgId, id });
        setVendor(object);
      }
    };

    loadVendor();
  }, [id, orgId]);

  const onKeyPressed = (event: React.KeyboardEvent) => {
    if (isEnterPressed(event)) {
      onEditVendor();
    }
  };

  const onFieldChanged = ({ id, value }: FieldType) => {
    setVendor((vendor) => ({ ...(vendor || {}), [id]: value } as any));
  };

  const onEditVendor = async () => {
    const validationErrors = getValidationErrors('vendor', vendor);
    if (!isValidationOk(validationErrors)) {
      setValidationErrors(validationErrors);
      analytics.track(eventPage, 'edit-vendor-validation-error', validationErrors);

      return;
    }

    analytics.track(eventPage, 'edit-vendor');

    try {
      await vendorActions.update({ orgId, id, ...vendor });
      analytics.track(eventPage, 'edit-vendor-success');
      history.push(
        generatePath(locations.settings.vendors, {
          orgId,
        })
      );
    } catch (e) {
      analytics.track(eventPage, 'edit-vendor-fail');
    }
  };

  const goEditDeliveryMethod = (deliveryMethod: DeliveryMethodType) => {
    const id = vendor?.id;
    const { deliveryType, id: deliveryMethodId } = deliveryMethod;
    const locationState = {
      redirectUrl: generatePath(locations.settings.vendorsView, {
        id,
        orgId,
      }),
      exitUrl: generatePath(locations.settings.vendorsView, {
        id,
        orgId,
      }),
      origin: CONSTS.DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS,
    };

    if (deliveryType === DELIVERY_TYPE.ACH && deliveryMethodId) {
      analytics.track(eventPage, 'edit-delivery-method', {
        type: deliveryType,
      });
      const editAchUrl = generatePath(locations.deliveryMethod.edit.ach, {
        vendorId: id,
        deliveryMethodId,
        orgId,
      });
      history.push(editAchUrl, locationState);

      return;
    }

    if (deliveryType === DELIVERY_TYPE.ACH) {
      analytics.track(eventPage, 'add-delivery-method', {
        type: deliveryType,
      });
      const addAchUrl = generatePath(locations.deliveryMethod.ach, {
        vendorId: id,
        orgId,
      });
      history.push(addAchUrl, locationState);

      return;
    }

    if (deliveryType === DELIVERY_TYPE.CHECK && deliveryMethodId) {
      analytics.track(eventPage, 'edit-delivery-method', {
        type: deliveryType,
      });
      const editCheckUrl = generatePath(locations.deliveryMethod.edit.check, {
        vendorId: id,
        deliveryMethodId,
        orgId,
      });
      history.push(editCheckUrl, locationState);

      return;
    }

    if (deliveryType === DELIVERY_TYPE.CHECK) {
      analytics.track(eventPage, 'add-delivery-method', {
        type: deliveryType,
      });
      const addCheckUrl = generatePath(locations.deliveryMethod.check, {
        vendorId: id,
        deliveryMethodId,
        orgId,
      });
      history.push(addCheckUrl, locationState);
    }
  };

  if (isVendorLoading || isVendorUpdating || !vendor) {
    return <QBDTLoader />;
  }

  const isVendorNotOwnedByUser = !vendor.ownedById;

  return (
    <>
      <VendorMICardForm onKeyDown={onKeyPressed}>
        <FormTitleContainer>
          <MICardTitle label="vendors.view.title" />
        </FormTitleContainer>
        {vendor && (
          <VendorForm
            mode={SCREEN_MODE.EDIT}
            vendor={vendor}
            onChange={onFieldChanged}
            validationErrors={validationErrors}
            formType={CONSTS.FORM_TYPE.DETAILS}
          />
        )}
      </VendorMICardForm>
      {isVendorNotOwnedByUser && (
        <VendorDeliveryMethodsList vendor={vendor} goEditDeliveryMethod={goEditDeliveryMethod} />
      )}
      <VendorMIFloatedEditDoneButtons onDone={onEditVendor} doneLabel="vendors.edit.done" />
    </>
  );
};

const FormTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VendorMICardForm = styled(MICardForm)`
  display: flex;
  flex-direction: column;
  padding: 2.3rem 3.6rem 3rem 3.6rem;
`;

const VendorMIFloatedEditDoneButtons = styled(QBDTFloatedButtons)`
  border-bottom-left-radius: 0.9rem;
  border-bottom-right-radius: 0.9rem;
`;
