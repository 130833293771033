import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import locations from 'src/pages/locations';
import { getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { setUrlToBackAction } from 'src/redux/payBillWizard/actions';
import { canVerifyManualBankAccount } from 'src/utils/funding-sources';
import { encodeQuery } from 'src/utils/query-utils';
import { DashboardMenuAction } from '../types';
import { DashboardActionItemsEnum } from '../consts';

type Response = {
  verifyMicroDepositsMenuAction: DashboardMenuAction | null;
};

export const useVerifyMicroDepositsMenuAction = (fundingSourceId?: string): Response => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const fundingSources = useSelector(getFundingSources);

  const selectedFundingSource = fundingSources.find((fs) => fs.id === fundingSourceId);

  const redirectToMicroDepositsVerificaiton = () => {
    const pathname = generatePath(locations.qbdt.settings.paymentMethods, { orgId });
    const search = encodeQuery({ verifyFundingSourceId: fundingSourceId }, [], '');

    dispatch(setUrlToBackAction(`${location.pathname}${location.search}`));

    history.push({ pathname, search });
  };

  const showVerifyMicroDepositsAction = fundingSourceId && canVerifyManualBankAccount(selectedFundingSource);

  const verifyMicroDepositsMenuAction: DashboardMenuAction | null = showVerifyMicroDepositsAction
    ? {
        id: DashboardActionItemsEnum.VerifyMicroDeposits,
        onClick: redirectToMicroDepositsVerificaiton,
        label: 'paymentDashboard.columns.actions.verifyAccount',
      }
    : null;

  return {
    verifyMicroDepositsMenuAction,
  };
};
