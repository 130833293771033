import React, { useEffect } from 'react';
import { Box } from '@melio/billpay-design-system';
import vendorsApi from 'src/services/api/vendors';
import AreaLoader from 'src/components/common/AreaLoader';
import { EmptyList } from 'src/components/list/EmptyList';
import { useApi } from 'src/hoc/useApi';
import VendorListItem from './components/VendorListItem';
import { useOrgId } from '../../../hooks/useOrgId';
import { sortAndGroupVendors } from './utils';

export const VendorsListPage = () => {
  const orgId = useOrgId();
  const [loadVendors, vendorsResult, isVendorsLoading] = useApi(vendorsApi.getVendors, false, true);
  const vendors = vendorsResult?.objects || [];

  useEffect(() => {
    loadVendors({ orgId });
  }, [orgId, loadVendors]);

  const renderVendorsSubList = function (vendorsSubList) {
    return vendorsSubList.map(({ id, companyName }) => (
      <VendorListItem testId={`vendor-list-item-${id}`} key={id} id={id} name={companyName} />
    ));
  };

  if (isVendorsLoading) {
    return (
      <VendorListContainer>
        <AreaLoader placement="list" />
      </VendorListContainer>
    );
  }

  if (vendors.length === 0) {
    return (
      <VendorListContainer>
        <EmptyList text="vendors.emptyState.vendorText" />
      </VendorListContainer>
    );
  }

  const sortedContacts = sortAndGroupVendors(vendors);

  return (
    <VendorListContainer>
      {Object.keys(sortedContacts).map((alpha) => (
        <div data-testId={`vendors-list-subgroup-${alpha}`} key={alpha}>
          <AlphabeticalLetters>{alpha}</AlphabeticalLetters>
          {renderVendorsSubList(sortedContacts[alpha])}
        </div>
      ))}
    </VendorListContainer>
  );
};

const AlphabeticalLetters = ({ children }) => (
  <Box
    color="ds.gray.200"
    textStyle="ds.body2"
    textTransform="uppercase"
    borderBottom="0.2rem solid"
    borderColor="ds.gray.500"
    padding="ds.md"
  >
    {children}
  </Box>
);
const VendorListContainer = ({ children }) => (
  <Box data-testId="vendors-list-container" position="relative" padding="3.4rem 3.6rem">
    {children}
  </Box>
);
