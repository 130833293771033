import { useMemo, useCallback } from 'react';
import { parseQueryString, encodeQuery } from 'src/utils/query-utils';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import { useLocation, useHistory } from 'react-router-dom';

export function useLocationState<T = any>(name: string, defaultValue?: any) {
  const location = useLocation<T>();
  const history = useHistory();
  const value = location.state?.[name] === undefined ? defaultValue : location.state?.[name];
  const setValue = useCallback(
    (value) => {
      const state = { ...location.state, [name]: value };
      history.push({ ...location, state });
    },
    [location, history, name]
  );

  return [value, setValue];
}

export function useQueryString() {
  const location = useLocation();
  return useMemo(() => parseQueryString(location.search) || {}, [location.search]);
}

export function useQueryState<T = any>(
  name: string,
  defaultValue: T | undefined = undefined,
  replace = true,
  copyState = true
): [T, (value: T) => void] {
  const query = useQueryString();
  const location = useLocation();
  const history = useHistory();
  const value = query?.[name] === undefined ? defaultValue : query?.[name];
  const setValue = useCallback<(value: T) => void>(
    async (value) => {
      const currQuery = parseQueryString(history.location.search);
      const newQuery = pickBy({ ...currQuery, [name]: value });
      const search = encodeQuery(newQuery, [], '');
      const { state, ...restLocation } = location;
      if (replace) {
        await history.replace({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      } else {
        await history.push({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      }
    },
    [replace, location, history, name, copyState]
  );
  return [value as T, setValue];
}

export function useQueryStateMultiValues<T extends Record<string, any>>(
  defaultValue: T,
  replace = true,
  copyState = false
): [T, (T) => void] {
  const query = useQueryString();
  const location = useLocation();
  const history = useHistory();
  const currentKeys = keys(defaultValue);
  const currentValue = { ...defaultValue, ...pick(query, currentKeys) };
  const setValue = useCallback(
    async (values: Partial<T>) => {
      const currQuery = parseQueryString(history.location.search);
      const newQuery = pickBy({ ...currQuery, ...values });
      const search = encodeQuery(newQuery, [], '');
      const { state, ...restLocation } = location;
      if (replace) {
        await history.replace({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      } else {
        await history.push({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      }
    },
    [replace, history, copyState]
  );
  return [currentValue, setValue];
}
