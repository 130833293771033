import React from 'react';
import { Flex, Table } from '@melio/billpay-design-system';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';

const BillingFeeHistoryTableCaption = () => (
  <Table.Caption>
    <Flex w="full" h="20rem" position="relative">
      <QBDTLoader context="button" />
    </Flex>
  </Table.Caption>
);

export { BillingFeeHistoryTableCaption };
