import * as React from 'react';
import styled, { css } from 'styled-components';
import { devices } from '../../theme/AppDevices';
import { MIFormattedText } from '../../utils/formatting';

const WizardContainer = styled.div`
  background-color: ${(props) => props.theme.background.wizard};
  display: flex;
  justify-content: center;
  min-height: 100%;
  box-sizing: border-box;
  flex-direction: column;

  @media ${devices.desktop}, ${devices.tablet} {
    align-items: center;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    align-items: start;
  }
`;

const WizardInner = styled.div<{ isCenterVert?: boolean; mode?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  @media ${devices.desktop}, ${devices.tablet} {
    justify-content: center;
    width: ${({ mode }) => (mode === 'batchList' ? '98rem' : '90rem')};
    margin-bottom: 3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    justify-content: ${(props) => (props.isCenterVert ? 'center' : 'start')};
    padding: 0 1.6rem;

    ${(props) =>
      props.mode === 'settings' &&
      css`
        width: 100%;
      `}
    ${(props) => props.theme?.components?.WizardElements?.WizardInner}
  }

  ${(props) =>
    props.mode === 'batchList' &&
    css`
      &&& {
        justify-content: flex-start;
      }
    `}
`;

const WizardInnerSuccess = styled(WizardInner)`
  ${(props) => props.theme?.components?.WizardElements?.WizardInnerSuccess}
`;

const WizardIllustration = styled.img`
  margin-bottom: 4rem;
  align-self: center;

  @media ${devices.desktop}, ${devices.tablet} {
    height: 6rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    height: 10rem;
  }

  ${(props) => props.theme?.components?.WizardElements?.WizardIllustration}
`;

const WizardTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.normal};
  margin-bottom: 1rem;
  align-self: center;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;

  @media ${devices.mobile}, ${devices.phablet} {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
`;

const WizardStepTitle = styled.div<{ isSubtitle?: boolean }>`
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.normal};
  align-self: center;
  text-align: center;
  margin-bottom: ${(props) => (props.isSubtitle ? '1rem' : '4rem')};

  em {
    font-style: normal;
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }

  @media ${devices.desktop}, ${devices.tablet} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitle};
    line-height: 3.2rem;
    margin-top: 4rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitleMobile};
    line-height: 3.2rem;
    padding: 3.2rem 0 0;
    font-weight: ${(props) => props.theme.text.weight.normal};
    margin-bottom: ${(props) => (props.isSubtitle ? '1rem' : '3.2rem')};
    ${(props) => props.theme?.components?.WizardElements?.WizardStepTitle}
  }
`;

const WizardStepSubTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.wizardText};
  line-height: 2rem;
  text-align: center;
  margin-bottom: 4rem;
  max-width: 45rem;
  ${(props) => props.theme?.components?.WizardElements?.WizardStepSubTitle}
`;

const WizardText = styled.div<{ textAlign?: string }>`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.wizardText};
  margin-bottom: 4rem;
  align-self: center;
  text-align: ${(props) => props.textAlign || 'center'};
  line-height: 2rem;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const WizardActionsContainer = styled.div`
  align-self: center;
  flex-direction: column;
  display: flex;
  text-align: center;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const WizardActionsRowContainer = styled.div`
  align-self: center;
  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: center;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div {
    &:first-child {
      margin-right: 2rem;
    }
    &:only-child {
      margin-right: 0 !important;
    }
  }
`;

const WizardButtonContainer = styled.div<{
  width?: string;
  isProgressBar?: boolean;
}>`
  @media ${devices.desktop}, ${devices.tablet} {
    width: ${(props) => (props.width ? `${props.width}rem` : 'auto')};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    button {
      margin-bottom: 0;
      width: 100%;
      min-height: 4.8rem;
      border-radius: 2.4rem;
    }
    width: 100%;
    height: 6.8rem;
    background: ${(props) => props.theme.background.default};
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem;
    min-height: 4.8rem;
    ${(props) => props.theme?.components?.WizardElements?.WizardButtonContainer}
  }
`;

const WizardLinkButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const WizardLinkContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const WizardRegisterLinkContainer = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`;

const WizardLinkHintContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.linkHint};
`;

const WizardOptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1.5rem;
  max-width: 45rem;
  align-self: center;
  ${(props) => props.theme?.pages.SelectBankAccountVerificationTypePage?.WizardOptionContainer}
`;

const WizardOptionLine = styled.div`
  height: 0.1rem;
  border-top: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  flex-grow: 1;
`;

const WizardOptionText = styled.div`
  font-size: 1.4rem;
  flex-grow: none;
  color: ${(props) => props.theme.text.color.main};
  margin: 0 1.7rem 0 1.7rem;
`;

type WizardOrLineProps = {
  className?: string;
};

const WizardOrLine = ({ className }: WizardOrLineProps) => (
  <WizardOptionContainer className={className}>
    <WizardOptionLine />
    <WizardOptionText>
      <MIFormattedText label="general.or" />
    </WizardOptionText>
    <WizardOptionLine />
  </WizardOptionContainer>
);

WizardOrLine.defaultProps = {};

type WizardInputsProps = {
  innerSize?: number;
  children: React.ReactNode;
  mode?: string;
};

const WizardInputs = ({ innerSize = 50, children, mode = 'default' }: WizardInputsProps) => (
  <WizardInputsContainer size={innerSize} mode={mode}>
    {children}
  </WizardInputsContainer>
);

const WizardInputsContainer = styled.div<{ size: number; mode: string }>`
  align-self: center;
  @media ${devices.desktop}, ${devices.tablet} {
    width: ${(props) => props.size}%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    flex-grow: 1;
    ${(props) => props.theme?.components?.WizardElements?.WizardInputsContainer}
  }

  ${(props) =>
    props.mode === 'settings' &&
    css`
      display: flex;
      flex-direction: column;
      flex: 1;
    `}

  ${(props) =>
    props.mode === 'batchList' &&
    css`
      display: flex;
      justify-content: center;
    `}

  ${(props) =>
    props.mode === 'success' &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media ${devices.desktop}, ${devices.tablet} {
        min-width: 53rem;
      }

      @media ${devices.mobile}, ${devices.phablet} {
        height: auto;
      }
    `}
`;

const WizardStepActionsContainer = styled.div<{ direction: string }>`
  gap: 1.5rem;
  align-self: center;
  flex-direction: ${({ direction }) => direction || 'column'};
  display: flex;
  text-align: center;
  align-items: center;

  @media ${devices.desktop}, ${devices.tablet} {
    min-width: 25%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    margin-top: 3rem;
  }
  ${(props) => props.theme?.components?.WizardElements?.WizardStepActionsContainer}
`;

const WizardFooterContainer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  margin-top: 4rem;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    margin-top: 0;
    margin-bottom: 2rem;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 3rem;
  width: 11.2rem;
  height: 4.2rem;
  text-align: center;
  @media ${devices.mobile}, ${devices.phablet} {
    svg {
      width: 8.8rem;
      height: 3.5rem;
    }
  }
`;

const SimpleTextFooter = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  letter-spacing: 0.037rem;
  line-height: 1.6rem;
  text-align: center;
`;

const SimpleTextFooterSlim = styled(SimpleTextFooter)`
  width: 28rem;
`;

type WizardFormRowProps = {
  children: React.ReactNode;
};

const WizardFormRow = ({ children }: WizardFormRowProps) => <WizardFormRowWrapper>{children}</WizardFormRowWrapper>;

WizardFormRow.defaultProps = {};

const WizardFormRowWrapper = styled.div`
  display: flex;
  @media ${devices.mobile}, ${devices.phablet} {
    height: 7.4rem;
    margin-bottom: 2rem;
  }
  ${(props) => props.theme?.components?.WizardElements?.WizardFormRow}
`;

type WizardFormColumnProps = {
  size?: number;
  children: React.ReactNode;
};

const WizardFormColumn = ({ size = 50, children }: WizardFormColumnProps) => (
  <WizardFormColumnWrapper size={size}>{children}</WizardFormColumnWrapper>
);

WizardFormColumn.defaultProps = {
  size: 50,
};

const WizardFormColumnWrapper = styled.div<{ size: number }>`
  width: ${(props) => props.size}%;

  &:first-child {
    margin-right: 1.5rem;
  }
  &:nth-child(2) {
    margin-left: 1.5rem;
  }
`;

const InnerWizardContentWrapper = styled.div`
  margin-bottom: 4rem;
`;

type WizardNotesContainerProps = {
  label: string;
  children?: React.ReactNode;
  className?: string;
};

const WizardNotesContainer = ({ label, children, ...rest }: WizardNotesContainerProps) => (
  <WizardNotesContainerWrapper {...rest}>
    <WizardNotesHeaderContainer>
      <WizardNotesTitle>
        <MIFormattedText label={label} />
      </WizardNotesTitle>
    </WizardNotesHeaderContainer>
    <WizardNotesSubTitle>{children}</WizardNotesSubTitle>
  </WizardNotesContainerWrapper>
);

const WizardNotesContainerWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.8rem;
  background: ${(props) => props.theme.colors.white.opaque};
  padding: 2rem;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  line-height: 2rem;
  margin-bottom: 4rem;
  text-align: left;
  span {
    font-weight: 600 !important;
  }
  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const WizardNotesTitle = styled.div`
  margin-bottom: 0.8rem;
`;

const WizardNotesSubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const WizardNotesHeaderContainer = styled.div`
  display: flex;
`;

export {
  WizardContainer,
  WizardInner,
  WizardInnerSuccess,
  WizardIllustration,
  WizardTitle,
  WizardText,
  WizardActionsContainer,
  WizardActionsRowContainer,
  WizardButtonContainer,
  WizardLinkButtonContainer,
  WizardLinkContainer,
  WizardRegisterLinkContainer,
  WizardLinkHintContainer,
  WizardOrLine,
  WizardOptionLine,
  WizardStepTitle,
  WizardStepSubTitle,
  WizardInputs,
  WizardStepActionsContainer,
  LogoContainer,
  SimpleTextFooter,
  SimpleTextFooterSlim,
  WizardFormRow,
  WizardFormColumn,
  WizardFooterContainer,
  InnerWizardContentWrapper,
  WizardNotesContainer,
};
