import React from 'react';
import { Box, Link, Flex } from '@melio/billpay-design-system';
import { useCreateBatchPayments } from 'src/billpay/qbdt/pages/batch-pay/hooks/useCreateBatchPayments';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  onAddDeliveryMethod: () => void;
  testId: string;
};

export const EmptyMethodCell: React.FC<Props> = ({ onAddDeliveryMethod, testId }) => {
  const {
    createBatchPaymentData: { error: showWarningIcon },
  } = useCreateBatchPayments();

  return (
    <Flex align="center" justify="space-between" h="full">
      <Box as={Link} onClick={onAddDeliveryMethod} data-testid={testId}>
        <MIFormattedText label="regularBatchPayments.addMethod" />
      </Box>
      {showWarningIcon && <Box as="i" __css={iconStyles} className="icon-warning-inline-icon" />}
    </Flex>
  );
};

const iconStyles = {
  fontSize: '1.6rem',
  color: '#FFB300',
};
