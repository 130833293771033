import { SelectOption, VendorType, ExtendedSelectOption } from 'src/utils/types';
import {
  UnpaidDueDateOptionsEnum,
  UnpaidBillDashboardStatusesEnum,
  ScheduledBillDashboardStatusesEnum,
  PaidBillDashboardStatusesEnum,
  DashboardTabStatusEnum,
} from '../../../consts';
import { DashboardListFiltersParams } from '../../../types';
import { DashboardFilterItemNamesEnum } from '../consts';
import { DashboardFilterMenuItems } from '../types';

const { NextSevenDays, ThisMonth, NextMonth, PastDue } = UnpaidDueDateOptionsEnum;
const { DueInOneWeek, Overdue, Failed, Open } = UnpaidBillDashboardStatusesEnum;
const { Scheduled, BankUnverified, PendingVendorDetails } = ScheduledBillDashboardStatusesEnum;
const { Sent, Deposited } = PaidBillDashboardStatusesEnum;

const staticOptionsByTab = {
  [DashboardTabStatusEnum.Unpaid]: {
    [DashboardFilterItemNamesEnum.StatusesInDashboard]: [DueInOneWeek, Overdue, Failed, Open],
    [DashboardFilterItemNamesEnum.DueDates]: [NextSevenDays, ThisMonth, NextMonth, PastDue],
  },
  [DashboardTabStatusEnum.Scheduled]: {
    [DashboardFilterItemNamesEnum.StatusesInDashboard]: [Scheduled, BankUnverified, PendingVendorDetails],
  },
  [DashboardTabStatusEnum.Paid]: {
    [DashboardFilterItemNamesEnum.StatusesInDashboard]: [Sent, Deposited],
  },
};

const getSelectOption = ({ label, value = false, selected = false, disabled = false }): SelectOption => ({
  label,
  value,
  selected,
  disabled,
});

const LABEL_ROOT_PATH = 'paymentDashboard.filters.menuItems';

type GetStaticFieldOptions = {
  fieldOptionsNames: string[];
  fieldName: string;
  fieldValue?: string;
};

const getStaticFieldOptions = ({ fieldOptionsNames, fieldName, fieldValue }: GetStaticFieldOptions) => {
  const selectedValues = fieldValue?.split(',') || [];

  return fieldOptionsNames.map((optionName: string) => {
    const isOptionSelected = selectedValues.includes(optionName);

    return getSelectOption({
      value: isOptionSelected,
      selected: isOptionSelected,
      label: `${LABEL_ROOT_PATH}.${fieldName}.options.${optionName}`,
    });
  });
};

type GetStaticFilterFieldsParams = {
  tabName: string;
  filtersValuesByFieldName: DashboardListFiltersParams;
};

const getStaticFilterFields = ({ tabName, filtersValuesByFieldName }: GetStaticFilterFieldsParams) => {
  const tabStaticOptionsByField = staticOptionsByTab[tabName];
  const fieldsNames = Object.keys(tabStaticOptionsByField);

  return fieldsNames.reduce((accFiltersOptions, currentFilterFieldName) => {
    const fieldOptionsNames = tabStaticOptionsByField[currentFilterFieldName];
    const fieldLabel = `${LABEL_ROOT_PATH}.${currentFilterFieldName}.label`;
    const fieldOptions = getStaticFieldOptions({
      fieldOptionsNames,
      fieldName: currentFilterFieldName,
      fieldValue: filtersValuesByFieldName[currentFilterFieldName],
    });

    const filterFieldData = {
      label: fieldLabel,
      options: fieldOptions,
    };

    return {
      ...accFiltersOptions,
      [currentFilterFieldName]: filterFieldData,
    };
  }, {});
};

type GetVendorsFieldParams = {
  vendorsFieldValue?: string;
  vendors: VendorType<'created'>[];
};

const getVendorsField = ({ vendorsFieldValue, vendors }: GetVendorsFieldParams) => {
  const vendorsRootLabel = `${LABEL_ROOT_PATH}.${DashboardFilterItemNamesEnum.Vendors}`;

  const selectedVendorsIds = vendorsFieldValue?.split(',') || [];
  const selectedVendorsIdsMap = selectedVendorsIds.reduce(
    (acc, selectedVendorId) => ({
      ...acc,
      [selectedVendorId]: true,
    }),
    {}
  );

  const vendorOptions: ExtendedSelectOption[] = vendors
    .map(({ id, companyName }) => ({
      ...getSelectOption({
        value: !!selectedVendorsIdsMap[id],
        label: `${vendorsRootLabel}.options.vendorName`,
      }),
      values: { vendorName: companyName },
      id,
    }))
    .sort((a, b) => a.values.vendorName.localeCompare(b.values.vendorName));

  return { label: `${vendorsRootLabel}.label`, options: vendorOptions };
};

type GetDashboardFilterFieldsParams = {
  tabName: string;
  filtersValuesByFieldName: DashboardListFiltersParams;
  vendors: VendorType<'created'>[];
};

export const getDashboardFilterFields = ({
  tabName,
  filtersValuesByFieldName,
  vendors,
}: GetDashboardFilterFieldsParams): DashboardFilterMenuItems => ({
  ...getStaticFilterFields({
    tabName,
    filtersValuesByFieldName,
  }),
  vendors: getVendorsField({
    vendorsFieldValue: filtersValuesByFieldName.vendors,
    vendors,
  }),
});

type RemoveSelectionFromFilterOptionsParams = {
  filterMenuItems: DashboardFilterMenuItems;
};

export const removeSelectionFromFilterOptions = ({ filterMenuItems }: RemoveSelectionFromFilterOptionsParams) => {
  const fieldNames = Object.keys(filterMenuItems);

  const fieldsListWithRemovedSelection = fieldNames.map((fieldName) => ({
    fieldName,
    label: filterMenuItems[fieldName].label,
    options: filterMenuItems[fieldName].options.map((option) => ({ ...option, value: false, selected: false })),
  }));

  const filterMenuItemsTransformedToObject = fieldsListWithRemovedSelection.reduce(
    (menuItems, { fieldName, label, options }) => ({
      ...menuItems,
      [fieldName]: { label, options },
    }),
    {}
  );

  return filterMenuItemsTransformedToObject;
};

export const getFieldValueFromMenuOptions = (options) => {
  const selectedOptions = options.filter(({ value }) => value);

  if (!selectedOptions.length) {
    return undefined;
  }

  return selectedOptions.map(({ id, label }) => {
    if (id) {
      return id;
    }

    const splitedTranslationLabel = label.split('.');
    const optionName = splitedTranslationLabel[splitedTranslationLabel.length - 1];

    return optionName;
  });
};
