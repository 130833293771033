import React from 'react';
import DatePicker from 'src/components/form/DatePicker';
import { RecordOf } from 'immutable';
import { chakra } from 'src/theme/ds';
import { isBusinessDay } from 'src/utils/dates';
import { isCardExpired } from 'src/utils/card';
import { AccountType, FieldDateType, OptionalDeliveryMethodsType, DeliveryOptionType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { PaymentDatePickerDelivery } from 'src/billpay/qbdt/pages/pay/components/PaymentDatePickerDelivery';
import { PaymentDatePickerNotation } from 'src/billpay/qbdt/pages/pay/components/PaymentDatePickerNotation';
import { useCardExpiredNotification } from './useCardExpiredNotification';

type Props = {
  fundingSource: RecordOf<AccountType> | null;
  deliveryDate: Date;
  deliveryOptions?: DeliveryOptionType[];
  maxDeliveryDate: Date | null;
  deliveryMethodType: OptionalDeliveryMethodsType;
  scheduledDate: Date;
  deliveryPreference?: string;
  dueDate?: Date | null;
  minDate?: Date;
  onChange: (value: FieldDateType) => Promise<void> | void;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, type?: string) => void;
};

const PaymentDatePicker = ({
  fundingSource,
  deliveryDate,
  deliveryOptions,
  maxDeliveryDate,
  deliveryMethodType,
  scheduledDate,
  minDate,
  onChange,
  dueDate,
  onSelectDeliveryOption,
  deliveryPreference,
  fundingSourceType,
}: Props) => {
  const [toggleCardExpiredNotification] = useCardExpiredNotification({ fundingSource, scheduledDate });
  const getFilteredDate = (date) =>
    isCardExpired({ expiration: fundingSource?.cardAccount?.expiration }, date) ? false : isBusinessDay(date);

  return (
    <Container>
      <DatePicker
        id="scheduledDate"
        date={scheduledDate}
        dueDate={dueDate}
        required
        size={CONSTS.TEXT_INPUT_SIZE.INLINE}
        inline
        withBottomElement
        min={minDate}
        filterDate={getFilteredDate}
        onChange={({ id, date }) => onChange({ id, value: date })}
        onMonthChange={toggleCardExpiredNotification}
        overrideMobile
      />
      <PaymentDatePickerNotation scheduledDate={scheduledDate} dueDate={dueDate} />
      <PaymentDatePickerDelivery
        fundingSource={fundingSource}
        scheduledDate={scheduledDate}
        deliveryDate={deliveryDate}
        maxDeliveryDate={maxDeliveryDate}
        deliveryMethodType={deliveryMethodType}
        deliveryOptions={deliveryOptions || []}
        deliveryPreference={deliveryPreference}
        fundingSourceType={fundingSourceType}
        onSelectDeliveryOption={onSelectDeliveryOption}
      />
    </Container>
  );
};

const Container = chakra('div', {
  baseStyle: {
    width: '35.9rem',
    backgroundColor: 'white',
    margin: '0 auto',
    borderRadius: 'ds.lg',
    border: '1px solid',
    borderColor: 'ds.gray.600',
    boxShadow: '0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)',
    '.react-datepicker': {
      border: 'none',
    },
  },
});

export { PaymentDatePicker };
