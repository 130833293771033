import React, { useEffect } from 'react';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { QBDTEntryBaseRouter } from 'src/billpay/qbdt/pages/entry/QBDTEntryBaseRouter';
import { QBDTPayWizardRouter } from 'src/billpay/qbdt/pages/pay/QBDTPayWizardRouter';
import { ViewPaymentRouter } from 'src/billpay/qbdt/pages/view/ViewPaymentRouter';
import { QBDTBatchPayWizardRouter } from 'src/billpay/qbdt/pages/batch-pay/QBDTBatchPayWizardRouter';
import { QBDTFundingSourceRouter } from 'src/billpay/qbdt/pages/funding-source/QBDTFundingSourceRouter';
import { QBDTSettingsRouter } from 'src/billpay/qbdt/pages/settings/QBDTSettingsRouter';
import { QBDTSyncWizardRouter } from 'src/billpay/qbdt/pages/sync/QBDTSyncWizardRouter';
import { BillingFeeRouter } from 'src/billpay/qbdt/pages/settings/components/billing/BillingFeeRouter';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from 'src/billpay/qbdt/pages/ErrorPage';
import { QBDTDeliveryMethodRouter } from 'src/billpay/qbdt/pages/delivery-method/QBDTDeliveryMethodRouter';
import errorTracker from 'src/utils/error-tracking';
import { getCompanyFileGuid } from 'src/billpay/qbdt/services/qbdt';
import analytics from 'src/services/analytics';
import { logger } from 'src/services/loggers';
import { LoggerMessage } from 'src/services/loggers/types';

import locations from './locations';
import { DashboardRouter } from './dashboard/DashboardRouter';
import { EntryErrorsRouter } from './entry-errors/EntryErrorsRouter';

const payWizardPaths = [locations.pay.base, locations.edit.base];

const ErrorFallback = ({ error }: { error: Error }) => (
  <ErrorPage title="entry.sync.error" subtitle="entry.sync.errorSubtitle" errors={error.message} />
);
const onError = (
  error: Error,
  info: {
    componentStack: string;
  }
) => {
  logger.error(error.message as LoggerMessage, { ...info, stack: error.stack });
  errorTracker.capture(error, info);
};

const ANALYTICS_TAG_NAME = 'qbdt';

export function QBDTRouter() {
  useAddExtraAnalytics();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      <SmartRoute path={locations.entry.base}>
        <QBDTEntryBaseRouter />
      </SmartRoute>
      <SmartRoute path={locations.entryErrors.base}>
        <EntryErrorsRouter />
      </SmartRoute>
      <SmartRoute path={payWizardPaths}>
        <QBDTPayWizardRouter />
      </SmartRoute>
      <SmartRoute path={locations.view.base}>
        <ViewPaymentRouter />
      </SmartRoute>
      <SmartRoute path={locations.fundingSource.base}>
        <QBDTFundingSourceRouter />
      </SmartRoute>
      <SmartRoute path={locations.batchPay.base}>
        <QBDTBatchPayWizardRouter />
      </SmartRoute>
      <SmartRoute path={locations.settings.base}>
        <QBDTSettingsRouter />
      </SmartRoute>
      <SmartRoute path={locations.deliveryMethod.base}>
        <QBDTDeliveryMethodRouter />
      </SmartRoute>
      <SmartRoute path={locations.sync.base}>
        <QBDTSyncWizardRouter />
      </SmartRoute>
      <SmartRoute path={locations.billingFee.base}>
        <BillingFeeRouter />
      </SmartRoute>
      <SmartRoute path={locations.dashboard.dashboard}>
        <DashboardRouter />
      </SmartRoute>
    </ErrorBoundary>
  );
}

const useAddExtraAnalytics = () => {
  useEffect(() => {
    const addExtraAnalytics = async () => {
      try {
        const { response } = await getCompanyFileGuid();
        analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
          companyFileGuid: response.companyguid,
        });
      } catch (e: any) {
        logger.error('QBDTRouter.addExtraAnalytics(): Add extra analytics failed', e?.message);
      }
    };

    addExtraAnalytics();
  }, []);
};

export { locations };
