import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { updatePayment, updatePaymentFundingSource } from 'src/modules/regular-batch-payments/utils';
import { getInitialProcessingDates, InitialProcessingDatesParams } from 'src/utils/delivery';
import { actionName } from './consts';

export const BATCH_UPDATE_DELIVERY_OPTIONS_BY_DUE_DATE = `[${actionName}] UPDATE PAYMENT DELIVERY OPTIONS BY DUE DATE`;

type BillOptionUpdatePayload = InitialProcessingDatesParams & {
  batchItemId: string;
};

export const regularBatchUpdateDeliveryOptionsByDueDateSlice = createApiCallSlice<BillOptionUpdatePayload, any, any>({
  name: BATCH_UPDATE_DELIVERY_OPTIONS_BY_DUE_DATE,
  initialState: {
    byId: {},
  },
  api: getInitialProcessingDates,
  reducers: {
    [ON_REQUEST](state, action) {
      state.byId[action.payload.batchItemId] = {
        ...state.byId[action.payload.batchItemId],
        loading: true,
        loadingDates: true,
        error: undefined,
      };
      updatePaymentFundingSource(state, action);
    },
    [ON_SUCCESS](state, action) {
      const { batchItemId } = action.meta.identifier as BillOptionUpdatePayload;

      state.byId[batchItemId] = {
        ...state.byId[batchItemId],
        loadingDates: false,
        loading: false,
      };
      updatePayment(state, action);
    },
    [ON_FAILURE](state, action) {
      const { batchItemId } = action.meta.identifier as BillOptionUpdatePayload;

      state.byId[batchItemId] = {
        ...state.byId[batchItemId],
        loading: false,
        loadingDates: false,
        error: action.error,
      };
    },
  },
});
