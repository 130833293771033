import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryApi from 'src/services/api/delivery';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchDateOption } from 'src/billpay/qbdt/pages/batch-pay/consts';
import { trackLoadBillsAction } from 'src/billpay/qbdt/pages/batch-pay/analytics/utils';
import { getOrgId } from 'src/redux/user/selectors';
import useBatchBillsList from './useBatchBillsList';

type BatchActionParams = {
  refreshRequired: boolean;
  isBillsGroupedByVendor: boolean;
  hasScheduledPaymentsForBills: boolean;
  billsExceedAmountLimitCount: number;
  fundingSourceId: number;
  scheduledDate: string | null;
  minScheduledDate: string | null;
  dateOption: BatchDateOption;
  billIds: number[];
  exitUrl?: string;
};

type BatchActionOptions = {
  refreshEffectiveDate: boolean;
  refreshBillsList: boolean;
};

type UseBatchSettingsChangeResult = {
  batchSettings: BatchActionParams;
  changeBatchSettings: (params: Partial<BatchActionParams>, options?: Partial<BatchActionOptions>) => void;
};

export const useBatchSettings = (): UseBatchSettingsChangeResult => {
  const orgId = useSelector(getOrgId);
  const currentSettingsParams: BatchActionParams = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const batchActions = useStoreActions(regularBatchPaymentsStore);
  const { loadBillsList } = useBatchBillsList();

  const refreshEffectiveScheduleDate = async (fundingSourceId: number) => {
    const { scheduleDate } = await deliveryApi.getEffectiveScheduleDate({
      orgId,
      scheduledDate: new Date(),
      fundingSourceId,
    });

    return scheduleDate;
  };

  const refreshBatchBillsList = async (settingsParams: BatchActionParams) => {
    const {
      payload: { items },
    } = await loadBillsList({
      ...pick(settingsParams, ['fundingSourceId', 'billIds', 'scheduledDate', 'isBillsGroupedByVendor']),
    });

    trackLoadBillsAction(items);
  };

  const changeBatchSettings = async (params: Partial<BatchActionParams>, options: Partial<BatchActionOptions> = {}) => {
    const { refreshEffectiveDate, refreshBillsList } = options;

    let updatedSettingsParams = {
      ...currentSettingsParams,
      ...params,
    };
    const { fundingSourceId, dateOption, scheduledDate } = updatedSettingsParams;

    if (updatedSettingsParams.dateOption === BatchDateOption.ALL_AT_ONCE && refreshEffectiveDate) {
      await batchActions.settings.update({
        fundingSourceId,
        dateOption,
        scheduledDate,
      });

      const minScheduledDate = await refreshEffectiveScheduleDate(fundingSourceId);
      const updatedScheduledDate = scheduledDate
        ? Math.max(new Date(scheduledDate).getTime(), new Date(minScheduledDate).getTime())
        : new Date(minScheduledDate).getTime();

      updatedSettingsParams = {
        ...updatedSettingsParams,
        scheduledDate: new Date(updatedScheduledDate).toISOString(),
        minScheduledDate,
      };
    } else if (updatedSettingsParams.dateOption === BatchDateOption.BY_DUE_DATE) {
      updatedSettingsParams = {
        ...updatedSettingsParams,
        scheduledDate: null,
      };
    }

    await batchActions.settings.update(updatedSettingsParams);

    if (refreshBillsList) {
      refreshBatchBillsList(updatedSettingsParams);
    }
  };

  return {
    batchSettings: currentSettingsParams,
    changeBatchSettings,
  };
};
