import { featureFlags } from '@melio/shared-web';

import sumBy from 'lodash/sumBy';

import { CONSTS } from 'src/utils/consts';
import { featureFlagValues as checkFlagValues, isCheckFeeOpen } from 'src/billpay/qbdt/hooks/useCheckFee/utils';
import { FeatureFlags } from 'src/utils/feature-flags';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import { useApi } from 'src/hoc/useApi';
import { billingFeeApi, OrganizationBillingFee } from 'src/services/api/smb-billing-fee';
import { useEffect } from 'react';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { getBillingMethod } from 'src/billpay/qbdt/pages/settings/components/billing/utils';

export type FeeDataType = {
  amount: number;
  label: string;
  labelBulk?: string;
  style: string;
  tooltip?: string;
  plus?: boolean;
};

type FeeParamsType = {
  feesData: Record<string, FeeDataType>;
};

const useFeesParams = (): FeeParamsType => {
  const orgId = useOrgId();
  const { billsList } = useBatchBillsList();
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlags.CheckFees, checkFlagValues.CLOSED);
  const isCheckFee = isCheckFeeOpen(checkFeeFlag); // both with/without promotions
  const readyBills = billsList.filter((bill) => bill.isBillReady);

  const [getOrganizationBillingFees, organizationBillingFeeResponse] = useApi<
    [{ orgId: number }],
    { organizationBillingFees: OrganizationBillingFee[] }
  >(billingFeeApi.getBillingFees, false, true);

  const totalTransactionCreditFeesAmount = sumBy(readyBills, (bill) => bill.fee?.credit?.amount || 0);

  const totalTransactionDebitFeesAmount = sumBy(readyBills, (bill) => bill.fee?.debit?.amount || 0);

  const totalTransactionCheckFeesAmount = isCheckFee
    ? sumBy(readyBills, (bill) => bill.fee?.[CONSTS.FEE_VARIANTS.ACH_TO_CHECK]?.amount || 0)
    : 0;

  const totalTransactionAchFeesAmount = sumBy(
    readyBills,
    (bill) => bill.fee?.[CONSTS.FEE_VARIANTS.ACH_TO_ACH]?.amount || 0
  );

  const totalFastFeesAmount = sumBy(readyBills, (bill) =>
    bill.payment.deliveryPreference ? bill.fee?.[bill.payment.deliveryPreference]?.amount || 0 : 0
  );
  const totalCardFeesAmount = totalTransactionCreditFeesAmount + totalTransactionDebitFeesAmount;

  const totalCheckFeesAmount = totalTransactionCheckFeesAmount;

  const totalFeesAmount =
    totalTransactionCreditFeesAmount +
    totalTransactionDebitFeesAmount +
    totalFastFeesAmount +
    totalTransactionCheckFeesAmount +
    totalTransactionAchFeesAmount;

  const billingFeeMethod = getBillingMethod(organizationBillingFeeResponse?.organizationBillingFees);

  useEffect(() => {
    getOrganizationBillingFees({ orgId });
  }, [orgId]);

  const feesData = {
    credit: {
      amount: totalTransactionCreditFeesAmount,
      label: 'regularBatchPayments.fees.creditCardFees',
      style: 'ds.body3',
      plus: true,
    },
    debit: {
      amount: totalTransactionDebitFeesAmount,
      label: 'regularBatchPayments.fees.debitCardFees',
      style: 'ds.body3',
      plus: true,
    },
    cards: {
      amount: totalCardFeesAmount,
      label: 'regularBatchPayments.fees.cardFees',
      labelBulk: 'regularBatchPayments.fees.cardProcessingFees',
      style: 'ds.body3',
      plus: true,
    },
    checks: {
      amount: totalCheckFeesAmount,
      label: 'regularBatchPayments.fees.checkFees',
      style: 'ds.body3',
      tooltip: billingFeeMethod ? 'regularBatchPayments.tooltips.fees.checkFees' : undefined,
      plus: true,
    },
    ach: {
      amount: totalTransactionAchFeesAmount,
      label: 'regularBatchPayments.fees.achFees',
      style: 'ds.body3',
      plus: true,
    },
    fast: {
      amount: totalFastFeesAmount,
      label: 'regularBatchPayments.fees.fastPaymentFees',
      labelBulk: 'regularBatchPayments.fees.additionalFees',
      style: 'ds.body3',
      tooltip: 'regularBatchPayments.tooltips.fees.additionalFees',
      plus: true,
    },
    total: {
      amount: totalFeesAmount,
      label: 'regularBatchPayments.fees.totalFees',
      style: 'ds.body3Semi',
    },
    transaction: {
      amount: totalFeesAmount,
      label: 'regularBatchPayments.fees.transactionFees',
      style: 'ds.body3Semi',
    },
  };

  return {
    feesData,
  };
};

export default useFeesParams;
