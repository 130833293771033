import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';
import { useApi } from 'src/hoc/useApi';
import { setProfileAction } from 'src/redux/user/actions';
import { getProfile } from 'src/redux/user/selectors';
import analytics from 'src/services/analytics';
import authApi from 'src/services/api/auth';
import AreaLoader from 'src/components/common/AreaLoader';
import MICodeInput from 'src/components/common/MICodeInput';
import MILink from 'src/components/common/MILink';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { QBDTStepLayout, WizardLinkContainer } from '../../components';

const eventPage = 'registration';
const eventName = 'email-code-verification';
export const QBDTCodeEmailVerificationPage = ({ onSuccess }) => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const [showError, setIsErrorShown] = useState(false);
  const [codeInputResetKey, setСodeInputResetKey] = useState(1);

  const [resolveEmailVerification, , isResolving, resolveError] = useApi<[string, number], Record<string, any>>(
    authApi.resolveEmailVerificationCode
  );
  const [resendEmailVerificationCode, codeResendResult, isResending] = useApi<[string], Record<string, any>>(
    authApi.resendEmailVerificationCode
  );

  const onCodeChange = async (code: string) => {
    if (code.length === 4) {
      await onResolveEmailVerification(Number(code));
    }

    if (code.length === 0) {
      setIsErrorShown(false);
    }
  };

  const onResolveEmailVerification = async (code: number) => {
    try {
      await resolveEmailVerification(profile.email, code);
      analytics.track(eventPage, `${eventName}-correct-code`);
      dispatch(setProfileAction(profile.merge({ isEmailVerified: true })));
      analytics.track(eventPage, `${eventName}-continue-success`);

      onSuccess();
    } catch (e) {
      if (e) {
        setIsErrorShown(true);
        analytics.track(eventPage, `${eventName}-incorrect-code`);
      }
    }
  };

  const sendEmailVerificationCode = async () => {
    await resendEmailVerificationCode(profile.email);

    setСodeInputResetKey(codeInputResetKey + 1);
    setIsErrorShown(false);
  };

  const { email } = profile;
  const errorCode = showError ? resolveError?.code : '';
  const isLoading = isResolving || isResending;
  const isCodeResend = !!codeResendResult;

  const renderFooter = () => (
    <WizardLinkContainer>
      <MILink to={sendEmailVerificationCode} label="guests.emailVerify.sendCodeAgain" />
    </WizardLinkContainer>
  );
  return (
    <>
      {isLoading && <AreaLoader placement="melioMe" />}
      <QBDTStepLayout
        title="guests.emailVerify.title"
        subtitle="guests.emailVerify.subTitle"
        subTitleValues={{
          email: <strong>{email}</strong>,
        }}
        footer={renderFooter()}
        hideSettings
      >
        {isCodeResend && (
          <NotificationCard
            type={CONSTS.NOTIFICATION_CARD_TYPES.SUCCESS}
            subtitle={{
              label: 'guests.emailVerify.resendNotification',
            }}
          />
        )}
        <CodeInputContainer>
          <MICodeInput
            key={codeInputResetKey}
            type="number"
            pattern="\d*"
            isValid={!errorCode}
            onChange={onCodeChange}
          />
        </CodeInputContainer>
        {errorCode && (
          <Box textStyle="ds.body3Semi" mt="ds.md" textAlign="center" color="ds.red.200">
            <MIFormattedText label={`server.${errorCode}`} />
          </Box>
        )}
      </QBDTStepLayout>
    </>
  );
};

const CodeInputContainer = ({ children }) => (
  <Box
    textAlign="center"
    sx={{
      "input[type='number']": {
        '-moz-appearance': 'textfield',
      },
      "input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button": {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        margin: 0,
      },
    }}
  >
    {children}
  </Box>
);

const NotificationCard = styled(QBONotificationCard)`
  margin: 0 0 4rem;
`;
