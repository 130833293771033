import { HStack, VStack } from '@melio/billpay-design-system';
import React, { ReactElement, useMemo } from 'react';
import { chakra } from 'src/theme/ds';
import { MIFormattedText } from 'src/utils/formatting';

export enum PaymentStatusVariantsEnum {
  Pending = 'pending',
  Success = 'success',
  Failure = 'failure',
}

type VariantStyleProps = {
  color: string;
  bgColor: string;
};

type Props = {
  renderIcon: (color: string) => ReactElement;
  label: string;
  description?: string;
  descriptionValues?: Record<string, any>;
  variant: PaymentStatusVariantsEnum;
};

export const PaymentStatusView = ({ renderIcon, label, description, descriptionValues, variant }: Props) => {
  const variantStyle: VariantStyleProps = useMemo<VariantStyleProps>(() => {
    if (variant === PaymentStatusVariantsEnum.Failure) {
      return {
        color: 'ds.red.100',
        bgColor: 'ds.red.700',
      };
    }

    if (variant === PaymentStatusVariantsEnum.Pending) {
      return {
        color: '#0491ff', // TODO: replace with color from DS
        bgColor: '#eaf4ff', // TODO: replace with color from DS
      };
    }

    // Success variant
    return {
      color: '#2ca01c', // TODO: replace with color from DS
      bgColor: '#ebf5e9', // TODO: replace with color from DS
    };
  }, [variant]);

  return (
    <StatusContainer bgColor={variantStyle.bgColor} spacing="ds.xl">
      {renderIcon(variantStyle.color)}
      <Status spacing="ds.sm" data-testId="view-payment-status">
        <StatusTitleText color={variantStyle.color}>
          <MIFormattedText label={label} />
        </StatusTitleText>
        {description && (
          <StatusDescriptionText>
            <MIFormattedText label={description} values={descriptionValues} />
          </StatusDescriptionText>
        )}
      </Status>
    </StatusContainer>
  );
};

const StatusContainer = chakra(HStack, {
  baseStyle: {
    borderTopRadius: 'ds.lg',
    borderBottomRadius: '0',
    padding: 'ds.xl',
    alignItems: 'flex-start',
  },
});

const Status = chakra(VStack, {
  baseStyle: {
    alignItems: 'flex-start',
    alignSelf: 'center',
    textAlign: 'left',
  },
});

const StatusTitleText = chakra('div', {
  baseStyle: {
    textStyle: 'ds.body2Semi',
  },
});

const StatusDescriptionText = chakra('div', {
  baseStyle: {
    color: 'ds.gray.100',
    textStyle: 'ds.body3',
    opacity: '0.7',
  },
});
