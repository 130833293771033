import axios from 'axios';
import { Adapter, BaseResponse, getSelectedBillsResponse, reportPaymentSummaryResponse } from './types';

const endpoint = 'http://localhost:5436';

export default {
  async getSelectedBills() {
    const url = new URL(endpoint);
    url.pathname = 'getSelectedBills';

    const response = await axios.post(
      url.toString(),
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      }
    );

    return response.data as getSelectedBillsResponse;
  },

  async reportPaymentSummary(summary) {
    const url = new URL(endpoint);
    url.pathname = 'reportPaymentSummary';

    const response = await axios.post(url.toString(), summary, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
    });

    return response.data as reportPaymentSummaryResponse;
  },

  async executeSDKRequest(data): Promise<string> {
    const url = new URL(endpoint);
    url.pathname = 'executeSDKRequest';

    const response = await axios.post(url.toString(), data, {
      responseType: 'text',
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    return response.data;
  },

  async getApiVersion() {
    const url = new URL(endpoint);
    url.pathname = 'getApiVersion';

    const response = await axios.post(
      url.toString(),
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      }
    );

    return response.data as string;
  },

  async initialize() {
    const url = new URL(endpoint);
    url.pathname = 'initialize';

    const response = await axios.post(
      url.toString(),
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      }
    );
    return response.data as BaseResponse;
  },

  async getCompanyFileGuid() {
    const url = new URL(endpoint);
    url.pathname = 'getCompanyFileGuid';

    const response = await axios.post(url.toString(), {
      responseType: 'document',
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    return response.data;
  },
  async setCanClose(value) {
    const url = new URL(endpoint);
    url.pathname = 'canCloseHostWindow';

    await axios.post(url.toString(), value, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
    });
  },
  async macWindowClose() {
    const url = new URL(endpoint);
    url.pathname = 'macWindowClose';

    await axios.post(
      url.toString(),
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      }
    );
  },
} as Adapter;
