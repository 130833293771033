import React from 'react';
import moment from 'moment';
import { featureFlags } from '@melio/shared-web';
import { MIFormattedText } from 'src/utils/formatting';
import { isSameDay, isTomorrow, DeliveryDateFormat } from 'src/utils/dates';
import { CARD_TYPES, DELIVERY_TYPE, FastACHDeliveryType, FUNDING_TYPE, RISK_BILL_AMOUNT } from 'src/utils/consts';

import { DeliveryOptionType } from 'src/utils/types';

import { FeatureFlags } from 'src/utils/feature-flags';

type GetTitleResponse = {
  label: string;
  values?: Record<string, any>;
};

type Props = {
  deliveryOption: DeliveryOptionType;
  fundingSourceType?: string;
  totalAmount: number;
  expeditedACHDeliveryDate?: Date;
};

const PaymentDeliveryOptionTitle = ({
  deliveryOption,
  fundingSourceType,
  totalAmount,
  expeditedACHDeliveryDate,
}: Props) => {
  const [isUpdatedDeliveryBlock] = featureFlags.useFeature(FeatureFlags.DatePickerDeliverySpeedChanges, false);

  const getLegacyTitle = (): GetTitleResponse => {
    if (deliveryOption.type === DELIVERY_TYPE.ACH) {
      if (fundingSourceType === FUNDING_TYPE.ACH) {
        return totalAmount >= RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD
          ? { label: 'bills.pay.date.deliveryOptions.subTitle.achOver100K' }
          : { label: 'bills.pay.date.deliveryOptions.subTitle.achBelow100K' };
      } else if (fundingSourceType === CARD_TYPES.DEBIT) {
        return { label: 'bills.pay.date.deliveryOptions.subTitle.achDebit' };
      }
    }

    return { label: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}` };
  };

  const getTitle = (): GetTitleResponse => {
    // Edge Case. Condition: same delivery date for Fast ACH option and regular option. Delivery date is today/tomorrow.
    // Expected behavour: Fast option title should be 'Today'/'Tomorrow'. Regular option title should be the exact date.
    if (
      deliveryOption.type !== FastACHDeliveryType.EXPEDITED &&
      moment(expeditedACHDeliveryDate).isSame(deliveryOption.deliveryDate, 'day')
    ) {
      return {
        label: 'bills.pay.date.deliveryOptions.title.date',
        values: {
          date: <DeliveryDateFormat date={deliveryOption.deliveryDate} maxDate={deliveryOption.maxDeliveryDate} />,
        },
      };
    }

    if (isSameDay(deliveryOption.deliveryDate)) {
      return { label: 'bills.pay.date.deliveryOptions.title.today' };
    }

    if (isTomorrow(deliveryOption.deliveryDate)) {
      return { label: 'bills.pay.date.deliveryOptions.title.tomorrow' };
    }

    return {
      label: 'bills.pay.date.deliveryOptions.title.date',
      values: {
        date: <DeliveryDateFormat date={deliveryOption.deliveryDate} maxDate={deliveryOption.maxDeliveryDate} />,
      },
    };
  };

  const { label, values } = isUpdatedDeliveryBlock ? getTitle() : getLegacyTitle();

  return <MIFormattedText label={label} values={values} />;
};

export { PaymentDeliveryOptionTitle };
