import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import errorTracker from 'src/utils/error-tracking';
import analytics from 'src/services/analytics';
import { logger } from 'src/services/loggers';
import { createPaymentExternalReference, getPaymentForSync, GetPaymentForSyncResponse } from 'src/services/api/qbdt';
import { mapQBDTBillPaymentCheck, mapQBDTBillPaymentCreditCard } from '../typeMapper';
import { QBDTSDKError } from '../sdk';
import { getBills } from '../entities/bill';
import { amountFormat } from '../util';
import {
  createBillPaymentCheck,
  createBillPaymentCreditCard,
  deleteBillPayment,
  getBillPaymentAny,
} from '../entities/billPayment';
import { reportPaymentSummaryRequest, BillPaymentType } from '../adapter/types';
import {
  BillsData,
  QBDTAccountType,
  QBDTBill,
  QBDTDataExtAssignToObject,
  QBDTSDKSeverity,
  RetryOptions,
} from '../types';
import { addExtensionDef } from '../entities/dataExtension';
import { getBankAccount, getCreditCardAccount } from '../entities/account';
import { TRANSACTION_LOCK_ERROR_CODES } from '../constants';
import { getMelioIdActionErrorEventParams } from './sync-utils';

type SyncPayment =
  | {
      txnId: string;
      extensionDefType: QBDTDataExtAssignToObject.BillPaymentCreditCard | QBDTDataExtAssignToObject.BillPaymentCheck;
      accountId: string;
      accountType: QBDTAccountType.CreditCard | QBDTAccountType.Bank;
      billPaymentType: BillPaymentType;
    }
  | undefined;

type CreatePaymentParams = {
  sync: GetPaymentForSyncResponse;
  billsData: BillsData;
  retryOptions?: RetryOptions;
};

async function createPaymentCard({ sync, billsData, retryOptions }: CreatePaymentParams): Promise<SyncPayment> {
  const qbdtCreditCardAccount = await getCreditCardAccount(sync.fundingSourceExternalId);

  const result = await createBillPaymentCreditCard({
    melioPayment: sync.payment,
    billsData,
    qbdtCreditCardAccount,
    retryOptions,
  });

  const qbdtBillPayment = mapQBDTBillPaymentCreditCard(result.querySelector('BillPaymentCreditCardRet'));

  return {
    txnId: qbdtBillPayment.TxnID,
    extensionDefType: QBDTDataExtAssignToObject.BillPaymentCreditCard,
    accountId: qbdtCreditCardAccount.ListID,
    accountType: QBDTAccountType.CreditCard,
    billPaymentType: BillPaymentType.CreditCard,
  };
}

async function createPaymentCheck({ sync, billsData, retryOptions }: CreatePaymentParams): Promise<SyncPayment> {
  const qbdtBankAccount = await getBankAccount(sync.fundingSourceExternalId);

  const result = await createBillPaymentCheck({
    melioPayment: sync.payment,
    billsData,
    qbdtBankAccount,
    retryOptions,
  });

  const qbdtBillPayment = mapQBDTBillPaymentCheck(result.querySelector('BillPaymentCheckRet'));

  return {
    txnId: qbdtBillPayment.TxnID,
    extensionDefType: QBDTDataExtAssignToObject.BillPaymentCheck,
    accountId: qbdtBankAccount.ListID,
    accountType: QBDTAccountType.Bank,
    billPaymentType: BillPaymentType.Check,
  };
}

async function deleteExistingBillPayment(txnId: string) {
  const existingQbdtPayment = await getBillPaymentAny(txnId);
  if (existingQbdtPayment) {
    await deleteBillPayment(existingQbdtPayment.TxnID);
  }
}

// This logic is needed for the case when we initially failed to sync payment with QBDT
// Between the failed attempt to sync and another try user might mark the payment as paid on QBDT side
// If that'll happen for combined bills we won't be able to create BillPayment on QBDT side
// So we're removing all BillPayments related to Bills that we're trying to sync
async function prepareQBDTBillsForSync(qbdtBills: QBDTBill[], sync: GetPaymentForSyncResponse) {
  try {
    // handle single bill case
    if (qbdtBills.length === 1) {
      const qbdtBillPayment = qbdtBills[0].LinkedTxn[0];
      const qbdtBillPaymentAmount = Math.abs(qbdtBillPayment.Amount);
      const areAmountsInMelioAndQBDTMatched = qbdtBillPaymentAmount.toFixed(2) === sync.payment.amount.toFixed(2);
      const billPaymentTxnId = qbdtBillPayment.TxnID;

      if (areAmountsInMelioAndQBDTMatched) {
        logger.warn('syncPayment.prepareQBDTBillsForSync(): single bill, remove marked as paid bill payment', {
          billPaymentTxnId,
        });

        await deleteExistingBillPayment(billPaymentTxnId);
      } else {
        logger.warn('syncPayment.prepareQBDTBillsForSync(): single bill, amounts not matched', {
          billPaymentTxnId,
          qbdtAmount: qbdtBillPaymentAmount,
          melioAmount: sync.payment.amount,
        });
      }
    } else {
      // handle combined bills case
      const billPaymentTxnIds = uniq(
        qbdtBills.reduce<string[]>((acc, qbdtBill) => {
          const paymentTxnIds = (qbdtBill.LinkedTxn || []).map((txn) => txn.TxnID);
          acc.push(...paymentTxnIds);
          return acc;
        }, [])
      );

      logger.warn('syncPayment.prepareQBDTBillsForSync(): combined bills, remove marked as paid bill payments', {
        billPaymentTxnIds,
      });
      for (const billPaymentTxnId of billPaymentTxnIds) {
        // eslint-disable-next-line no-await-in-loop
        await deleteExistingBillPayment(billPaymentTxnId);
      }
    }
  } catch (e) {
    logger.error('syncPayment.prepareQBDTBillsForSync(): preparing bills failed', e);

    throw e;
  }
}

type SyncPaymentParams = {
  orgId: string;
  paymentId: string;
  retryOptions?: RetryOptions;
};

export async function syncPayment({
  orgId,
  paymentId,
  retryOptions,
}: SyncPaymentParams): Promise<reportPaymentSummaryRequest> {
  const sync = await getPaymentForSync({ orgId, paymentId });

  const billExternalIds = compact<string>(sync.billPaymentEntities.map((bp) => bp.billExternalId));
  if (isEmpty(billExternalIds)) {
    throw Error(`Bill external ID not found for payment ${paymentId}`);
  }

  if (sync.paymentExternalId) {
    await deleteExistingBillPayment(sync.paymentExternalId);
  }

  const qbdtBills = await getBills(billExternalIds);
  const billPaymentEntitiesMap = keyBy(sync.billPaymentEntities, 'billExternalId');
  const billsData: BillsData = qbdtBills.map((qbdtBill) => {
    const { amount, billId } = billPaymentEntitiesMap[qbdtBill.TxnID];
    return { qbdtBill, amount, melioBillId: billId };
  });

  const billsAlreadyHaveBillPayments = qbdtBills.some((qbdtBill) => (qbdtBill.LinkedTxn || []).length > 0);
  // This check is needed to handle the case when we're trying to sync the payment which initial sync was failed.
  if (!sync.paymentExternalId && billsAlreadyHaveBillPayments) {
    await prepareQBDTBillsForSync(qbdtBills, sync);
  }

  let syncPayment: SyncPayment;

  if (sync.fundingSource?.fundingType === 'card' && sync.fundingSource?.cardAccount?.cardType === 'credit') {
    syncPayment = await createPaymentCard({ sync, billsData, retryOptions });
  } else if (sync.fundingSource?.fundingType === 'ach' || sync.fundingSource?.cardAccount?.cardType === 'debit') {
    syncPayment = await createPaymentCheck({ sync, billsData, retryOptions });
  } else {
    const error = new Error('SyncPayment - could not find the right payment type, creating check as default');
    logger.error(`sync-payment.syncPayment(): ${error.message}`);
    errorTracker.capture(error);
    syncPayment = await createPaymentCheck({ sync, billsData, retryOptions });
  }

  if (!syncPayment) throw Error('could not create a payment');

  const { txnId, extensionDefType } = syncPayment;
  const shouldSimulateTransactionLockError = featureFlags.defaultClient.getVariantNoTrack(
    'qbdt-simulate-transaction-lock-error',
    false
  );

  if (shouldSimulateTransactionLockError) {
    throw new QBDTSDKError(
      'Emulate transaction lock error',
      TRANSACTION_LOCK_ERROR_CODES[0],
      QBDTSDKSeverity.ERROR,
      {}
    );
  }

  await addExtensionDef({
    type: extensionDefType,
    entityId: txnId,
    value: sync.payment.id,
    retryOptions,
  });
  // we add the MelioId on bill sync because intuit uses this to show view payment
  try {
    for (const billDataItem of billsData) {
      const { qbdtBill, melioBillId } = billDataItem;
      if (!qbdtBill.MelioId) {
        // eslint-disable-next-line no-await-in-loop
        await addExtensionDef({
          type: QBDTDataExtAssignToObject.Bill,
          entityId: qbdtBill.TxnID,
          value: melioBillId,
          retryOptions,
        });
      }
    }
  } catch (error) {
    const billsIds = billsData.map((item) => item.melioBillId);
    const eventParams = {
      ...getMelioIdActionErrorEventParams(error),
      billsIds,
      paymentId: sync.payment.id,
    };
    analytics.track('pay-bill', 'add-melio-id-to-bill-failed', eventParams);
  }

  await createPaymentExternalReference({
    orgId,
    paymentId: sync.payment.id,
    externalPaymentEntityId: txnId,
  });
  const { qbdtBill } = billsData[0];

  return {
    [qbdtBill.TxnID]: {
      success: true,
      billPayment: txnId,
      billPaymentType: syncPayment.billPaymentType,
      accountId: syncPayment.accountId,
      accountType: syncPayment.accountType,
      amount: amountFormat(sync.payment.amount),
    },
  };
}
