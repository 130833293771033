/* eslint-disable max-len */
// eslint-disable-next-line import/no-default-export
export default {
  server: {
    baseUrl: 'https://localhost:8090',
  },
  web: {
    baseUrl: 'https://localhost:3031',
  },
  sentry: {
    enable: false,
    dsn: 'https://e3e5916a93a84f5db798c9fa52e14f55@o181640.ingest.sentry.io/5817109',
  },
  logger: {
    mode: 'console',
  },
  analytics: {
    shouldTrackEvents: false,
    shouldPrintEvents: false,
  },
  services: {
    intuit: {
      clientId: 'L0zQWUXvELmSFuBEywbazKeTTqsoUNIN8P7DEC7ybulhtlE68n',
      openid_url: 'https://intuit-oidgw.meliopayments.com/',
    },
    dataDog: {
      applicationId: '397229fe-86c3-4302-926a-110cd897c42a',
      clientToken: 'pubff4794ae8c835111f06915d28055f82e',
      service: 'bill-pay-qbdt',
      site: 'datadoghq.com',
    },
    plaid: {
      clientName: 'Melio',
      env: 'sandbox',
    },
    segment: {
      key: 'HHGcPnPXtwBScXC7UAmy75QPLHNfJFMs',
      shouldLoad: false,
    },
    googleMaps: {
      url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCy2BbnUakgGy08r_gmL8Qlo0VikreL7WA&v=3.exp&language=en',
      key: 'AIzaSyCy2BbnUakgGy08r_gmL8Qlo0VikreL7WA',
    },
    tabapay: {
      url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioIntuit1.html',
    },
    zendesk: {
      widgetApiKey: '5cfc5fd7-73cb-4bcd-8e93-6a4807dae219',
    },
    errorNotification: {
      isShow: true,
    },
    smartystreets: {
      key: '27409250903411363',
    },
    featureFlagProvider: {
      enabled: true,
      clientId: '6062c9171a6a080f1a6b1528',
    },
    basisTheory: {
      key: 'key_GZFc6KNn6zbN6Y54aFZfpV',
    },
  },
  jwt: {
    session: 'meliosession',
    expirationDays: 30,
  },
  meliome: {
    baseUrl: 'https://localhost:3031/meliome/pay/',
  },
  support: {
    phone: '(646) 568-9735',
    email: 'support@melio.com',
    accountantsEmail: 'accountants@meliopayments.com',
    site: 'https://help.meliopayments.com/',
  },
  agreementLinks: {
    userAgreement: 'https://www.meliopayments.com/legal/terms-of-service',
    privacyPolicy: 'https://www.meliopayments.com/legal/privacy-policy',
  },
  promo: {
    accountant: 'https://www.meliopayments.com/accountants',
  },
  quickbooks: {
    baseUrl: 'https://qbo.intuit.com',
  },
  qbo: {
    web: {
      baseUrl: 'https://localhost:3031',
    },
    support: {
      phone: '(612) 688-4189',
      email: 'support@qbousers.meliopayments.com',
    },
    compliance: {
      email: 'compliance@qbousers.meliopayments.com',
    },
    agreementLinks: {
      privacyPolicy: 'https://qbousers.meliopayments.com/privacy.html',
      userAgreement: 'https://qbousers.meliopayments.com/terms.html',
      termsOfService: 'https://qbousers.meliopayments.com/terms.html',
      QBOnlineData:
        'https://appcenter.intuit.com/cmsimages/promo/intuit_app_data_sharing/datasharingpolicy_accounting_payroll.html',
      intuitPrivacyPolicy: 'https://www.intuit.com/privacy/',
    },
    services: {
      tabapay: {
        url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioIntuit1.html',
      },
    },
    featureFlags: {
      unilateralPayment: true,
    },
  },
  qbdt: {
    support: {
      email: 'support@qbdtusers.melio.com',
    },
    agreementLinks: {
      privacyPolicy: 'https://qbdtusers.meliopayments.com/privacy.html',
      termsOfService: 'https://qbdtusers.meliopayments.com/terms.html',
    },
  },
  qbdtAdapter: 'bridge',
  debounceDelay: 300,
  featureFlags: {
    unilateralPayment: true,
    vendorsBatchUpload: false,
    customersBatchUpload: true,
    getPaidLabels: true,
    invoices: false,
    codatQBO: false,
    allowGetPro: true,
    allowMarkAsPaidQbDashboard: true,
    showPaymentRequestInboxTabRedesign: true,
    partialPayments: false,
    qboPartialPayments: true,
    holidaysWarning: false,
  },
};
