const LOCATIONS = {
  base: ['/vendors/:id/edit-address/:token'],
  index: '/vendors/:id/edit-address/:token',
  checkSent: '/vendors/:id/edit-address/:token/check-sent',
  checkDeposited: '/vendors/:id/edit-address/:token/check-deposited',
  checkNotSentChangeAddress: '/vendors/:id/edit-address/:token/change-address',
  checkNotSendAddressSaved: '/vendors/:id/edit-address/:token/address-saved',
  switchedToACH: '/vendors/:id/edit-address/:token/switched-to-ach',
  checkBlocked: '/vendors/:id/edit-address/:token/check-blocked',
  invalidToken: '/vendors/:id/edit-address/:token/invalid-link',
};

export default LOCATIONS;
