import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import fundingSourcesApi from 'src/services/api/fundingSources';
import { AccountType } from 'src/utils/types';
import { LOAD_FUNDING_SOURCES_SUCCESS, VERIFY_FUNDING_SOURCE_SUCCESS } from 'src/redux/user/actionTypes';
import { name } from './constants';
import { updateRegularBatchPaymentSaga } from './sagas';

const fundingSourcesStore = createRestfulSlice<AccountType>({
  name,
  schemaName: 'fundingSource',
  api: fundingSourcesApi,
  selectors: {
    all: (state) => Object.values(state[name]?.byId || {}),
    byId: (id) => (state) => state[name]?.byId[id],
  },
  extraReducers: {
    [LOAD_FUNDING_SOURCES_SUCCESS]: (state, { fundingSources }) => {
      state.byId = fundingSources.reduce((acc, fs) => ({ ...acc, [fs.id]: fs.toJS() }), {});
    },
    [VERIFY_FUNDING_SOURCE_SUCCESS]: (state, { verifiedFundingSourceId }) => {
      if (!state.byId[verifiedFundingSourceId]) {
        return;
      }

      state.byId[verifiedFundingSourceId].isVerified = true;
    },
  },
  extraSagas: updateRegularBatchPaymentSaga,
});

export default fundingSourcesStore;
