import { CONSTS } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { getCardIcon, getCardImageUrl } from 'src/utils/card';
import { AccountRecord } from 'src/records/settings.record';
import isEmpty from 'lodash/isEmpty';

function isCreditCard(method: AccountType) {
  return method.fundingType === CONSTS.FUNDING_TYPE.CARD && method.cardAccount?.cardType === CONSTS.CARD_TYPES.CREDIT;
}

function getAchImages(method: AccountType) {
  const hasLogo = !isEmpty(method.logo) && method.logo !== CONSTS.FUNDING_LOGO.DEFAULT;
  const icon = hasLogo ? '' : 'icon-bank-icon';
  const imageSrc = hasLogo ? `data:image/jpeg;base64,${method.logo}` : '';

  return { icon, imageSrc };
}

export const getPaymentMethodIcon = (method: AccountType) => {
  if (method.fundingType === CONSTS.FUNDING_TYPE.CARD) {
    const icon = getCardIcon(method);
    const imageSrc = getCardImageUrl(method);

    if (icon || imageSrc) {
      return { icon, imageSrc };
    }

    return { icon: 'icon-bank-icon', imageSrc: '' };
  }

  return getAchImages(method);
};

export const getPaymentMethodLabel = (method: AccountType) => {
  const label = isCreditCard(method) ? 'bills.pay.fundingSource.creditLabel' : 'bills.pay.fundingSource.achLabel';
  const values = AccountRecord(method as any).getNameParts();
  const { nickname } = method;

  return { label, values, nickname };
};

export const getShortPaymentMethodLabel = (method: AccountType) => {
  const label = isCreditCard(method)
    ? 'regularBatchPayments.cells.paymentMethod.creditLabel'
    : 'regularBatchPayments.cells.paymentMethod.achLabel';
  const values = AccountRecord(method as any).getNameParts();
  const { nickname } = method;

  return { label, values, nickname };
};

export const getPaymentMethodTitleLabel = (method: AccountType) =>
  isCreditCard(method)
    ? 'viewBillPaymentActivity.fundingSource.creditCardLabel'
    : 'viewBillPaymentActivity.fundingSource.achLabel';
