import { fetchRequest } from './api';

const list = ({ orgId, ...params }) => {
  const url = `/orgs/${orgId}/funding-sources`;
  return fetchRequest(url, params);
};

const fundingSourcesApi = {
  list,
};

export default fundingSourcesApi;
