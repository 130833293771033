import { RestfulSliceParams } from 'src/helpers/redux/types';
import { ON_FAILURE, ON_REQUEST, ON_SUCCESS, createApiCallSlice } from './createApiCallSlice';

export function fetchSliceReducer() {
  return {
    [ON_REQUEST](state, action) {
      state.fetch[action.payload.id] = { loading: true };
      state.meta[action.payload.id] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      delete state.fetch[action.payload.id];
      delete state.meta[action.payload.id];
      state.byId[action.payload.id] = action.payload;
    },
    [ON_FAILURE](state, action) {
      state.fetch[action.payload.id] = { loading: false, error: action.error };
      state.meta[action.payload.id] = { loading: false, error: action.error };
    },
  };
}

export function createFetchSlice<T>({ storeName, api, validate, sagas, actionName = 'fetch' }: RestfulSliceParams<T>) {
  return createApiCallSlice({
    initialState: {
      byId: {},
      fetch: {},
      meta: {},
    },
    name: `[${storeName}] ${actionName}`.toUpperCase(),
    api,
    reducers: fetchSliceReducer(),
    validate,
    selectors: {
      status: (id) => (state) => state[storeName].fetch?.[id],
      byId: (id) => (state) => state[storeName].byId[id],
    },
    sagas,
  });
}
