import React from 'react';
import styled from 'styled-components';
import { Popover, Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

import verifyBankAccountExample from 'src/images/funding-sources/verify-bank-account-example-min.jpeg';

const commonArrowStyles = {
  display: 'block',
  content: "' '",
  width: '1rem',
  height: '1rem',
  position: 'absolute',
  borderStyle: 'solid',
  boxSizing: 'border-box',
  borderWidth: '1px',
  transform: 'rotate(45deg) translateX(-50%)',
  backgroundColor: 'ds.white',
};

const arrowStyles = (isDesktop: boolean) => ({
  '[data-popper-placement*="bottom"] .chakra-popover__content:before': {
    ...commonArrowStyles,
    borderColor: 'transparent',
    left: isDesktop ? '50%' : '25%',
    borderTopColor: 'ds.gray.500',
    borderLeftColor: 'ds.gray.500',
    top: '-0.2rem',
  },
  '[data-popper-placement*="top"] .chakra-popover__content:before': {
    ...commonArrowStyles,
    borderColor: 'transparent',
    left: isDesktop ? '50%' : '25%',
    bottom: '-0.8rem',
    borderBottomColor: 'ds.gray.500',
    borderRightColor: 'ds.gray.500',
  },
});

export const MicroDepositPopover = ({ isDesktop = true }) => (
  <Box
    __css={{
      '*:focus, *:active': {
        outline: 'none',
      },
      '.chakra-popover__popper': {
        zIndex: 1,
      },
    }}
  >
    <Popover placement="bottom" arrowStyles={arrowStyles(isDesktop)} trigger={isDesktop ? 'hover' : 'click'}>
      <Popover.PopoverTrigger>
        <Box
          __css={{
            marginTop: '1.7rem',
            marginLeft: '0.2rem',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InfoIcon />
          <Popover.Text textStyle="ds.body2Semi" margin="0" color="#0077c5">
            <MIFormattedText label="settings.microDeposits.verifyModal.viewAnExample" />
          </Popover.Text>
        </Box>
      </Popover.PopoverTrigger>
      <Popover.PopoverContent left={isDesktop ? '-33%' : '0'}>
        <Popover.PopoverBody
          backgroundColor="white"
          padding={isDesktop ? 'ds.xl' : 'ds.lg'}
          borderRadius="0.7rem"
          borderWidth="0.1rem"
          borderStyle="solid"
          borderColor="ds.gray.500"
        >
          <Popover.Text
            marginTop={isDesktop ? 'ds.md' : 'ds.sm'}
            marginBottom={isDesktop ? 'ds.md' : 'ds.sm'}
            textStyle={isDesktop ? 'ds.body2Semi' : 'ds.body3Semi'}
          >
            <MIFormattedText label="settings.microDeposits.verifyModal.exampleTitleText" />
          </Popover.Text>
          <Popover.Image src={verifyBankAccountExample} alt="Example" width={isDesktop ? 'auto' : '31rem'} />
        </Popover.PopoverBody>
      </Popover.PopoverContent>
    </Popover>
  </Box>
);

const InfoIcon = styled.i.attrs({ className: 'icon-info-hollow-icon' })`
  font-size: 2rem;
  margin-right: 6px;
  color: #393a3d;
`;
