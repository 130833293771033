import React, { useEffect } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { Flex, Image, Text } from '@melio/billpay-design-system';
import { chakra } from '@chakra-ui/react';
import { useBreak } from 'src/hoc';
import { MIFormattedText } from 'src/utils/formatting';
import POSTerminal from 'src/images/virtual-card/pos-terminal.png';
import MIButton from 'src/components/common/MIButton';
import { CONSTS, DELIVERY_TYPE } from 'src/utils/consts';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import { OrganizationType, PaymentType } from 'src/utils/types';
import {
  PageInfo,
  PaymentDetails,
  WhyINeedPosInfo,
} from 'src/pages/vendor/shift-vendor-to-debit/components/AddVirtualCardPage';
import {
  DesktopSubmitContainer,
  MobileSubmitContainer,
} from 'src/pages/vendor/shift-vendor-to-debit/components/AddDebitCardPage';
import analytics from 'src/services/analytics';
import { pushToDebitEventPage } from 'src/pages/vendor/shift-vendor-to-debit/consts';
import { getCommonAnalyticsParams } from 'src/pages/vendor/shift-vendor-to-debit/analytics';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from '../../components/VendorFlowLayout';
import locations from '../locations';
import { useShiftToVirtualCard } from '../hooks';

type Props = {
  token: string;
  payment?: PaymentType;
  organization?: OrganizationType;
};

export const AddVirtualCardPage = ({ token, payment, organization }: Props) => {
  const { isDesktop } = useBreak();
  const history = useHistory();
  const deliveryMethod = payment?.deliveryMethod;

  const handleGoBack = () => history.goBack();

  const { isProcessing, handleRequestVirtualCard } = useShiftToVirtualCard({
    paymentId: payment?.id || '',
    onSuccess: () => history.push(generatePath(locations.addVirtualCardMethodSuccess, { token })),
    onFailure: () => history.push(generatePath(locations.invalidToken, { token })),
  });

  useEffect(() => {
    analytics.page(pushToDebitEventPage, 'add-virtual-card', {
      OriginDeliveryType: payment?.originalDeliveryMethod?.deliveryType,
    });
  }, []);

  const confirmClickHandler = async () => {
    if (deliveryMethod) {
      analytics.track('virtual-card', 'confirm-virtual-card-payment', {
        ...getCommonAnalyticsParams(payment),
        deliveryType: 'virtual-card',
        optInFlow: 'push-to-debit',
      });

      await handleRequestVirtualCard({
        deliveryMethod: {
          ...deliveryMethod,
          id: deliveryMethod.id,
          deliveryType: DELIVERY_TYPE.VIRTUAL_CARD,
          virtualCardAccount: { accountEmail: payment.vendor.contactEmail },
        },
        token,
        orgId: payment.organizationId,
        vendorId: payment.vendorId,
      });
    }
  };

  const amount = payment?.amount || 0;
  const vendorEmail = payment?.vendor?.contactEmail || '';
  const organizationName = organization?.companyName || '';
  const organizationEmail = organization?.userOrganizations?.[0]?.user?.email || '';

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack={isDesktop}>
        <PageInfo />
        <Divider h="ds.xs" my="ds.xl" />
        <PaymentDetails
          amount={amount}
          organizationEmail={organizationEmail}
          vendorEmail={vendorEmail}
          organizationName={organizationName}
        />
        <PosTerminalContainer>
          <WhyINeedPosInfo />
          <Image
            src={POSTerminal}
            my="ds.xl"
            w="full"
            objectFit="cover"
            height={isDesktop ? 'auto' : '16rem'}
            borderRadius="ds.md"
          />
          <DesktopSubmitContainer>
            <MIButton
              testId="p2d-receive-suvc-button"
              label="vendor.pushToDebit.virtualCard.confirm.submit"
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={confirmClickHandler}
              isProcessing={isProcessing}
              disabled={isProcessing}
              fullWidth
            />
          </DesktopSubmitContainer>
          <Text textStyle="ds.body3" align="center" m="0" mt="ds.md" color="ds.gray.100">
            <MIFormattedText
              label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.agreement"
              values={{
                payorName: organizationName,
              }}
            />
          </Text>
        </PosTerminalContainer>
      </VendorFlowContentCard>
      <Footer />
      <MobileSubmitContainer>
        <MIButton
          label="vendor.pushToDebit.virtualCard.confirm.submit"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={confirmClickHandler}
          fullWidth
          isProcessing={isProcessing}
          disabled={isProcessing}
        />
        <MIButton
          label="vendor.pushToDebit.virtualCard.learnMore.back"
          variant={CONSTS.BUTTON_VARIANT.SECONDARY}
          onClick={handleGoBack}
          fullWidth
          disabled={isProcessing}
        />
        <Text textStyle="ds.body3" align="center" m="0" color="ds.gray.100">
          <MIFormattedText
            label="vendor.unilateral.deliveryMethods.virtualCard.reviewAndConfirm.agreement"
            values={{
              payorName: organizationName,
            }}
          />
        </Text>
      </MobileSubmitContainer>
    </VendorFlowLayout>
  );
};

const PosTerminalContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 'ds.xl',
    borderRadius: 'ds.md',
    backgroundColor: 'ds.gray.800',
    marginTop: 'ds.xl',
  },
});
