import React, { useEffect } from 'react';
import QuickbooksLoader from 'src/pages/quickbooks/components/QuickbooksLoader';
import { useLocationState } from 'src/utils/hooks';
import { useRedirectToDashboard } from './hooks/useRedirectToDashboard';

export function DashboardEntry() {
  const [paymentId] = useLocationState('paymentId');
  const { redirectToDashboard } = useRedirectToDashboard();

  useEffect(() => {
    redirectToDashboard({
      paymentId,
    });
  }, [paymentId]);

  return <QuickbooksLoader />;
}
