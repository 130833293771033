import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import reduce from 'lodash/reduce';
import stubTrue from 'lodash/stubTrue';
import stubFalse from 'lodash/stubFalse';
import { CONSTS } from './consts';

export type PermissionsCheck = (...args: Array<any>) => boolean;

export type BillPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  approve: PermissionsCheck;
  reschedule: PermissionsCheck;
  markAsPaid: PermissionsCheck;
};

export type RequestsPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  createMelioLink: PermissionsCheck;
};

export type FundingSourcesPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  verify: PermissionsCheck;
};

export type DeliveryMethodPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  verify: PermissionsCheck;
};

export type SettingsPermissions = {
  editCompanySettings: PermissionsCheck;
  editAccountingSoftware: PermissionsCheck;
};

export type UserManagement = {
  read: PermissionsCheck;
  update: PermissionsCheck;
  delete: PermissionsCheck;
  transferOwnership: PermissionsCheck;
  manageUserOrganizations: PermissionsCheck;
};

export type Permissions = {
  bills: BillPermissions;
  requests: RequestsPermissions;
  fundingSources: FundingSourcesPermissions;
  deliveryMethod: DeliveryMethodPermissions;
  settings: SettingsPermissions;
  userManagement: UserManagement;
};

export type PermissionsPerRole = {
  owner: Permissions;
  admin: Permissions;
  contributor: Permissions;
  accountant: Permissions;
};

const defaultPermissions: Permissions = {
  bills: {
    update: stubFalse,
    delete: stubFalse,
    approve: stubFalse,
    markAsPaid: stubFalse,
    reschedule: stubFalse,
  },
  requests: {
    update: stubFalse,
    delete: stubFalse,
    createMelioLink: stubFalse,
  },
  settings: {
    editCompanySettings: stubFalse,
    editAccountingSoftware: stubFalse,
  },
  fundingSources: {
    update: stubFalse,
    delete: stubFalse,
    verify: stubFalse,
  },
  deliveryMethod: {
    update: stubFalse,
    delete: stubFalse,
    verify: stubFalse,
  },
  userManagement: {
    read: stubFalse,
    update: stubFalse,
    delete: stubFalse,
    transferOwnership: stubFalse,
    manageUserOrganizations: stubFalse,
  },
};

const setValueRec = (obj, val) =>
  reduce(
    obj,
    (result, currVal, key) => {
      result[key] = isFunction(currVal) ? val : setValueRec(currVal, val);
      return result;
    },
    {}
  );

const createdByTheSameUserId = (user, object) => user.id === object.createdById;

const allPermissions = setValueRec(defaultPermissions, stubTrue) as Permissions;
const contributor = merge(defaultPermissions, {
  bills: {
    update: createdByTheSameUserId,
    delete: createdByTheSameUserId,
    reschedule: stubTrue,
    retryPay: stubTrue,
  },
  requests: {
    update: stubTrue,
    delete: stubTrue,
  },
});

const canAccountantManageUser = (userToUpdateRole) =>
  [CONSTS.ROLE.ACCOUNTANT, CONSTS.ROLE.CONTRIBUTOR].includes(userToUpdateRole);
const accountant = merge({}, allPermissions, {
  bills: {
    approve: stubFalse,
  },
  userManagement: {
    update: canAccountantManageUser,
    delete: canAccountantManageUser,
    transferOwnership: stubFalse,
  },
});

const canAdminManageUser = (userToUpdateRole) => userToUpdateRole !== CONSTS.ROLE.OWNER;
const admin = merge({}, allPermissions, {
  userManagement: {
    update: canAdminManageUser,
    delete: canAdminManageUser,
    transferOwnership: stubFalse,
    manageUserOrganizations: canAdminManageUser,
  },
});

const permissions: PermissionsPerRole = {
  owner: allPermissions,
  admin,
  contributor,
  accountant,
};

export default permissions;
