import React from 'react';
import merge from 'lodash/merge';
import isArray from 'lodash/isArray';
import { Redirect, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import globalLocations from 'src/pages/locations';

import deliveryMethodRoutes from './vendor/delivery-methods/route';
import { withSiteContext } from '../hoc/withSiteContext';
import orgLocations from './orgs/locations';

type Props = {
  location: {
    pathname: string;
    search: string;
  };
};

const indexRedirectAuth = {
  path: '/',
  exact: true,
  component: withSiteContext()(() => {
    const orgId = useSelector(getOrgId);
    return <Redirect to={generatePath(globalLocations.qbdt.settings.base, { orgId })} />;
  }),
};

const defaultPublic = {
  component: withSiteContext()((props: Props & { site: any }) => {
    const orgId = useSelector(getOrgId);
    return (
      <Redirect
        to={
          generatePath(orgLocations.index + props.location.pathname, {
            orgId,
          }) + props.location.search
        }
      />
    );
  }),
};

const routes = {
  public: {},
  unauthenticated: {
    index: null,
    auth: [],
    default: { render: () => null },
  },
  authenticated: {
    orgs: {
      index: indexRedirectAuth,
      vendor: [],
      deliveryMethods: deliveryMethodRoutes,
      settings: [],
    },
  },
  guest: {
    orgs: {},
    index: null,
    default: { render: () => null },
  },
  emailNotVerified: {
    default: {
      render: () => null,
    },
  },
};

function isRoute(obj) {
  return obj && (obj.render || obj.component);
}
function flattenRoutes(def: any) {
  let defaultRoute = null;
  const flattened: Record<string, any> = [];
  Object.keys(def || {}).forEach((key) => {
    const route = def[key];
    if (key !== 'orgs') {
      if (key === 'default') {
        defaultRoute = route;
      } else if (isRoute(route)) {
        flattened.push(route);
      } else if (isArray(route)) {
        route.forEach((r) => flattened.push(r));
      } else {
        flattenRoutes(def[key]).forEach((r) => flattened.push(r));
      }
    }
  });
  return flattened.concat(defaultRoute).filter((route) => !!route);
}
export function buildRoutes(site: any, userAuthType: any): any {
  const mergedRoutes = merge(routes, site.routes);

  return {
    publicRoutes: flattenRoutes(mergedRoutes.public)
      .concat(flattenRoutes(mergedRoutes[userAuthType]))
      .concat(defaultPublic),
    orgsRoutes: flattenRoutes(mergedRoutes[userAuthType]?.orgs),
  };
}
