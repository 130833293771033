import React from 'react';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import authApi from 'src/services/api/auth';
import * as qbdtApi from 'src/services/api/qbdt';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { getProfile } from 'src/redux/user/selectors';
import { MICardForm } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/utils/formatting';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';
import styled from 'styled-components';

const eventPage = 'settings-account-information';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
  * {
    width: 100%;
  }
`;

const HintContainer = styled.div`
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text.color.black};
  ${(props) => props.theme.components?.AccountSettings?.HintContainer}
`;

export const AccountSettingsPageContainer = () => {
  const profile = useSelector(getProfile);

  const handleLogoutAndUnlink = () => {
    analytics.track(eventPage, 'logout');
    qbdtApi
      .logoutAndUnlink({ orgId: profile.orgId })
      .then(authApi.logout)
      .then(() => {
        close();
      });
  };

  return (
    <MICardForm>
      <HintContainer>
        <MIFormattedText label="qbo.settings.account.logoutHint" />
      </HintContainer>
      <ButtonsContainer>
        <MIButton
          variant={CONSTS.BUTTON_VARIANT.SECONDARY}
          fullWidth
          label="qbo.settings.account.logout"
          onClick={handleLogoutAndUnlink}
        />
      </ButtonsContainer>
    </MICardForm>
  );
};
