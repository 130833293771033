import { PaymentType } from 'src/utils/types';
import { isBulkPayment } from '../utils';

export enum InvoiceNumberType {
  BulkPayment = 'bulkPayment',
  RegularPayment = 'regularPayment',
  Empty = 'empty',
}

export const useInvoiceNumberType = (payment: PaymentType | undefined) => {
  if (isBulkPayment(payment)) {
    return { invoiceNumberType: InvoiceNumberType.BulkPayment, invoiceNumber: '' };
  }

  if (payment?.bill?.invoiceNumber) {
    return { invoiceNumberType: InvoiceNumberType.RegularPayment, invoiceNumber: payment.bill.invoiceNumber };
  }

  return { invoiceNumberType: InvoiceNumberType.Empty, invoiceNumber: '' };
};
