import getRegularBatchPaymentsMapping from 'src/billpay/qbdt/pages/batch-pay/action-notification-mapping';

const MAPPINGS = {
  '[PAYMENTS] PAYMENT_APPROVED_SUCCESS': {
    type: 'success',
    msg: 'bills.approval.approveSuccess',
  },
  '[PAYMENTS] PAYMENT_DECLINED_SUCCESS': {
    type: 'info',
    msg: 'bills.approval.declineSuccess',
  },
  ...getRegularBatchPaymentsMapping,
};

export default MAPPINGS;
