import React from 'react';
import styled from 'styled-components';

const FundingSourceIcon = ({ icon, imageSrc, size = '2rem' }) => (
  <Icon size={size}>{imageSrc ? <img src={imageSrc} alt="payment-method-logo" /> : <i className={icon} />}</Icon>
);

export default FundingSourceIcon;

const Icon = styled.i<{ size: string }>`
  font-size: ${(props) => props.size};

  > img {
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    object-fit: contain;
  }

  > i {
    font-size: ${(props) => props.size};
  }
`;
