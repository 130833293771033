import React from 'react';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import { MICardForm } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import AreaLoader from 'src/components/common/AreaLoader';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { VerifyMicroDeposits } from 'src/billpay/qbdt/components/VerifyMicroDeposits';
import QBOMIDialog from 'src/components/common/QBOMIDialog';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { useFundingSourceMicroDepositState } from 'src/hooks/settings/useFundingSourceMicroDeposits';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { useDebitFee } from 'src/billpay/qbdt/hooks/useDebitFee';
import { microDepositsEventPages } from 'src/components/micro-deposits/consts';
import { useCreditCardBenefits } from 'src/billpay/qbdt/hooks/useCreditCardBenefits';
import { DeleteFundingSourceErrorData } from '../PaymentMethodsSettingsPageContainer';

export type Props = {
  goAddFundingSources: (type: string) => void;
  onDeleteFailedError: () => void;
  onDeleteConfirmed: () => void;
  onDeletePaymentMethodCanceled: () => void;
  fundingSourceToDelete: RecordOf<AccountType>;
  deletingId: number;
  errorCode: string;
  errorData: DeleteFundingSourceErrorData;
  isSingleLoading: boolean;
  fundingSources: RecordOf<AccountType>[];
  onVerifyClicked: (id: number) => void;
  verifyingId: number;
  onVerifyFinished: () => void;
  modifyActions: (accountTypes: RecordOf<AccountType>) => RecordOf<AccountType>;
};

const eventPage = 'settings-payment-methods';

const getErrorDialogConfig = (errorCode: string, errorData: DeleteFundingSourceErrorData) => {
  let title = 'payments.form.deleteDialog.removalFailedTitle';
  let subtitle = `server.${errorCode}`;
  let cta = 'dialogs.buttons.close';

  if (errorCode === 'RSC03.1') {
    title = 'payments.form.deleteDialog.paymentMethodNeededTitle';
    cta = 'dialogs.buttons.gotIt';
  } else if (errorCode === 'RSC03' && errorData) {
    const hasFailedPayments = (errorData?.failedPayments || []).length > 0;

    title = 'payments.form.deleteDialog.failed.title';
    subtitle = hasFailedPayments
      ? 'payments.form.deleteDialog.failed.failedPaymentsSubtitle'
      : 'payments.form.deleteDialog.failed.scheduledPaymentsSubtitle';
  }

  return { title, subtitle, cta };
};

const PaymentMethodsSettingsPage = ({
  deletingId,
  errorCode,
  errorData,
  isSingleLoading,
  onDeletePaymentMethodCanceled,
  onDeleteFailedError,
  onDeleteConfirmed,
  fundingSourceToDelete,
  fundingSources,
  verifyingId,
  onVerifyFinished,
  onVerifyClicked,
  modifyActions,
  goAddFundingSources,
}: Props) => {
  const debitFee = useDebitFee();
  const microDepositEventPage = microDepositsEventPages.settings.base;
  const microDepositProps = {
    key: verifyingId,
    fundingSourceId: verifyingId,
  };
  const [state, actions]: any = useFundingSourceMicroDepositState(microDepositEventPage, microDepositProps, {
    fundingSourceID: verifyingId ?? null,
  });

  const { CreditCardBenefitsModal, openCreditCardBenefitsModal } = useCreditCardBenefits({ eventPage });

  const renderDeletePaymentMethodDialog = () => {
    let paymentMethodName = '';
    let paymentMethodNumber = '';
    if (fundingSourceToDelete.cardAccount) {
      paymentMethodName = fundingSourceToDelete.cardAccount.network;
      paymentMethodNumber = fundingSourceToDelete.cardAccount.card4digits;
    } else if (fundingSourceToDelete.bankAccount) {
      paymentMethodName = fundingSourceToDelete.getInstitutionName() || fundingSourceToDelete.getDisplayName();
      paymentMethodNumber = getAccountNumber4digits(fundingSourceToDelete.bankAccount);
    }

    return (
      <QBOMIDialog
        type={CONSTS.DIALOG_TYPE.CONFIRM}
        variant={CONSTS.DIALOG_VARIANTS.ERROR}
        title="settings.paymentMethods.deleteDialog.title"
        subtitle="settings.paymentMethods.deleteDialog.subtitle"
        subtitleValues={{
          paymentMethodName: <strong>{paymentMethodName}</strong>,
          paymentMethodNumber: <strong>...{paymentMethodNumber}</strong>,
        }}
        titleValues={{
          fundingType: <MIFormattedText label="settings.paymentMethods.ach" />,
          displayName: fundingSourceToDelete.getName(),
        }}
        okButtonText="settings.paymentMethods.deleteDialog.confirm"
        onOkAction={onDeleteConfirmed}
        onCancelAction={onDeletePaymentMethodCanceled}
      />
    );
  };

  const errDialogConfig = getErrorDialogConfig(errorCode, errorData);

  return (
    <React.Fragment>
      {isSingleLoading && <AreaLoader />}
      {!isSingleLoading && (
        <React.Fragment>
          <MICardForm>
            <FundingSourcesListContainer>
              <FundingSourcesListLayout
                fundingSources={fundingSources}
                onAddMethod={goAddFundingSources}
                onVerifyClicked={onVerifyClicked}
                modifyFundingSourceActions={modifyActions}
                debitFee={debitFee.fee}
                onViewBenefitsClick={openCreditCardBenefitsModal}
              />
            </FundingSourcesListContainer>
          </MICardForm>
          {verifyingId && (
            <VerifyMicroDeposits
              {...state}
              {...actions}
              key={verifyingId}
              verifyingId={verifyingId}
              onVerifyFinished={onVerifyFinished}
              eventPage={microDepositEventPage}
              dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
              dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
            />
          )}
          {deletingId && fundingSourceToDelete && renderDeletePaymentMethodDialog()}

          {errorCode && (
            <QBOMIDialog
              type={CONSTS.DIALOG_TYPE.ALERT}
              variant={CONSTS.DIALOG_VARIANTS.ERROR}
              title={errDialogConfig.title}
              subtitle={errDialogConfig.subtitle}
              cancelButtonText={errDialogConfig.cta}
              onCancelAction={onDeleteFailedError}
            />
          )}
        </React.Fragment>
      )}
      {CreditCardBenefitsModal}
    </React.Fragment>
  );
};

const FundingSourcesListContainer = styled.div`
  margin-top: 1.5rem;
`;

export default withSiteContext()(PaymentMethodsSettingsPage);
