import React from 'react';
import PaginationControl from './PaginationControl';
import { ReactComponent as ChevronLeftIcon } from '../images/chevron-left.svg';

type Props = {
  canPreviousPage?: boolean;
  goPreviousPage: () => void;
};

const PaginationPrevious = ({ canPreviousPage, goPreviousPage }: Props) => (
  <>
    <PaginationControl onClick={goPreviousPage} disabled={!canPreviousPage} IconComponent={ChevronLeftIcon} />
  </>
);

export default PaginationPrevious;
