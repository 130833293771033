import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { generatePath, useHistory } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import { getSelectedBills, deletePayment as deleteQbdtBillPayment } from 'src/billpay/qbdt/services/qbdt';
import analytics from 'src/services/analytics';
import { EntityType } from 'src/billpay/qbdt/services/qbdt/adapter/types';
import { catchAsync } from 'src/utils/async';
import { NOTIFICATION_VARIANT, RESPONSE_STATUSES } from 'src/utils/consts';
import { createPaymentExternalReference, getEntityByExternalId } from 'src/services/api/qbdt';
import { pushNotification } from 'src/services/notifications';
import { logger } from 'src/services/loggers';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';
import { ProgressIndicatorPage } from 'src/billpay/qbdt/pages/entry/ProgressIndicationPage';
import { ErrorPage } from 'src/billpay/qbdt/pages/ErrorPage';
import { getBillPaymentAny } from '../../services/qbdt/entities/billPayment';
import { getBill } from '../../services/qbdt/entities/bill';
import { QBDTTxnType } from '../../services/qbdt/types';

const title = 'entry.header';
const messages = [
  'entry.messages.quicklyPay',
  'entry.messages.payWithACH',
  'entry.messages.extendFloat',
  'entry.messages.scheduleBill',
];

export function QBDTViewPaymentLoader() {
  const [dashboardFeatureEnabled] = featureFlags.useFeature(FeatureFlags.Dashboard, false);
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const [{ progress, error }, setState] = useState({
    progress: 0.5,
  } as { progress: number; error?: string });

  useEffect(() => {
    async function load() {
      const { entities } = await getSelectedBills();
      const externalEntityId = entities[0].txnId;
      const externalEntityType = entities[0].entityType;
      let entityType;
      analytics.trackAction('track-entity-params', {
        externalEntityId,
        externalEntityType,
      });

      if (externalEntityType === EntityType.Bill) {
        entityType = 'bill';
      } else if ([EntityType.BillPaymentCheck, EntityType.BillPaymentCreditCard].includes(externalEntityType)) {
        entityType = 'payment';
      }

      setState({ progress: 0.6 });

      const [err, entityByIdResponse] = await catchAsync(
        getEntityByExternalId({ orgId, externalEntityId, entityType })
      );

      analytics.track('qbdt-entry-page', 'view-bill-entity-load-result', {
        errorStatus: err?.status,
        entityId: entityByIdResponse?.entityId,
        externalEntityId,
      });

      let entity = entityByIdResponse;
      const isPaymentEntityNotFound = err?.status === RESPONSE_STATUSES.NOT_FOUND && entityType === 'payment';
      if (isPaymentEntityNotFound) {
        analytics.trackAction('payment-external-reference-missing', {
          externalEntityId,
        });
        entity = await handleMissingPaymentExternalReference({
          orgId,
          externalPaymentEntityId: externalEntityId,
        });
      }

      if (!entity) {
        throw new Error('Could not find payment');
      }

      const isPaymentEntity = entity.entityType === 'payment';
      const isBillEntity = entity.entityType === 'bill' && entity.bill?.payments[0]?.id;
      const isMelioBillPresentButMelioPaymentCancelled =
        entity.entityType === 'bill' && !!entity.bill && isEmpty(entity.bill.payments);

      if (!isPaymentEntity && !isBillEntity && !isMelioBillPresentButMelioPaymentCancelled) {
        setState({ progress: 1, error: 'Could not find payment' });
        return;
      }

      if (isMelioBillPresentButMelioPaymentCancelled) {
        logger.error(
          'QBDTViewPaymentLoader.handleUnsyncedCancelledMelioPaymentCase(): unsynced canceled Melio payment handling started',
          { qbdtBillId: entity.externalId }
        );
        const { success } = await handleUnsyncedCancelledMelioPaymentCase(entity.externalId);

        if (!success) {
          logger.error(
            'QBDTViewPaymentLoader.handleUnsyncedCancelledMelioPaymentCase(): unsynced canceled Melio payment handling failed'
          );
          setState({ progress: 1, error: 'Could not find payment' });
          return;
        }

        logger.info(
          'QBDTViewPaymentLoader.handleUnsyncedCancelledMelioPaymentCase(): unsynced canceled Melio payment was successfuly handled'
        );

        pushNotification({
          type: NOTIFICATION_VARIANT.SUCCESS,
          msg: 'paymentDashboard.syncCanceledPayment.toastMessage',
          autoClose: 10000,
        });

        history.push(generatePath(locations.dashboard.entry, { orgId }));

        return;
      }

      const billId = isPaymentEntity ? entity.payment.billId : entity.entityId;
      const paymentId = isPaymentEntity ? entity.entityId : entity.bill.payments[0].id;

      if (dashboardFeatureEnabled) {
        history.push(
          generatePath(locations.dashboard.entry, {
            orgId,
          }),
          {
            paymentId,
          }
        );

        return;
      }

      history.push(
        generatePath(locations.view.base, {
          orgId,
          paymentId,
          billId,
        })
      );
    }

    if (!orgId) return;

    load().catch((error) => {
      setState({
        progress: 1,
        error: `Cannot continue, unexpected error - ${error.message}"`,
      });
    });
  }, [orgId, dashboardFeatureEnabled, history]);

  return error ? (
    <ErrorPage title="entry.sync.error" subtitle="entry.sync.errorSubtitle" errors={error} />
  ) : (
    <ProgressIndicatorPage title={title} messages={messages} progress={progress} />
  );
}

type HandleMissingPaymentExternalReferenceParams = {
  externalPaymentEntityId: string;
  orgId: string;
};

type HandleMissingPaymentExternalReferenceReturn = Record<string, any> | null;

async function handleMissingPaymentExternalReference({
  externalPaymentEntityId,
  orgId,
}: HandleMissingPaymentExternalReferenceParams): Promise<HandleMissingPaymentExternalReferenceReturn> {
  let entity: HandleMissingPaymentExternalReferenceReturn = null;
  const externalPayment = await getBillPaymentAny(externalPaymentEntityId);
  const externalBillId = externalPayment?.LinkedTxn.find((txn) => txn.TxnType === 'Bill')?.TxnID;
  const isExternalBillPresent = !!(externalBillId && (await getBill(externalBillId)));
  if (isExternalBillPresent) {
    entity = await getEntityByExternalId({
      orgId,
      externalEntityId: externalBillId as string,
      entityType: 'bill',
    });
    const paymentId = entity?.bill?.payments[0]?.id;
    if (paymentId) {
      await createPaymentExternalReference({
        orgId,
        paymentId,
        externalPaymentEntityId,
      });

      analytics.trackAction('payment-external-reference-restored', {
        externalEntityId: externalPaymentEntityId,
        paymentId,
      });
    }
  }

  return entity;
}

async function handleUnsyncedCancelledMelioPaymentCase(qbdtBillId: string) {
  const qbdtBill = await getBill(qbdtBillId);
  const linkedQbdtBillPayments =
    (qbdtBill &&
      qbdtBill.LinkedTxn?.filter((Txn) =>
        [QBDTTxnType.BillPaymentCheck, QBDTTxnType.BillPaymentCreditCard].includes(Txn.TxnType)
      )) ||
    [];
  const qbdtBillPaymentId = linkedQbdtBillPayments.length === 1 ? linkedQbdtBillPayments[0].TxnID : null;

  if (!qbdtBillPaymentId) {
    logger.error('QBDTViewPaymentLoader.handleUnsyncedCancelledMelioPaymentCase(): QBDT BillPayment id was not found', {
      qbdtBill,
    });
    return { success: false };
  }

  try {
    await deleteQbdtBillPayment(qbdtBillPaymentId);
    return { success: true };
  } catch (e) {
    logger.error(
      'QBDTViewPaymentLoader.handleUnsyncedCancelledMelioPaymentCase(): deleting QBDT BillPayment was failed',
      { error: e, qbdtBillPaymentTxnId: qbdtBillPaymentId, qbdtBillId }
    );
    return { success: false };
  }
}
