import * as React from 'react';
import Page from '../../../components/layout/Page';
import MIButton from '../../../components/common/MIButton';
import errorTracker from '../../../utils/error-tracking';

const ErrorPage = () => (
  <Page title="error.title">
    <p>We are sorry — something has gone wrong.</p>
    <p>Our team has been notified, but click here fill out a report.</p>
    <MIButton label="error.report" onClick={errorTracker.report} />
  </Page>
);

ErrorPage.defaultProps = {};

export default ErrorPage;
