import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import { logger } from 'src/services/loggers';
import { getPartialBillId } from 'src/utils/bills';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedCurrency } from 'src/utils/formatting';
import { getOrgId } from 'src/redux/user/selectors';
import * as qbdtAPI from 'src/services/api/qbdt';
import * as qbdtService from 'src/billpay/qbdt/services/qbdt';
import QBOMIDialog from 'src/components/common/QBOMIDialog';
import { useRedirectToDashboardUnpaidTab } from 'src/billpay/qbdt/pages/dashboard';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';
import { dashboardStore } from '../../../dashboard/redux-store/dashboard-store';

const eventPage = 'bill';

type Props = {
  dismiss: () => void;
};

export const DeletePaymentModal = ({ dismiss }: Props) => {
  const { bill, payment, onActionLoadStart, onActionLoadEnd } = useViewPaymentContext();

  const orgId = useSelector(getOrgId);
  const exitUrl = useSelector(dashboardStore.selectors.viewPaymentUrls.getExitUrl);
  const cameFromDashboard = !!exitUrl;

  const { redirectToDashboardUnpaidTab } = useRedirectToDashboardUnpaidTab();

  const analyticsData = {
    billId: bill.id,
    partialBillId: getPartialBillId(bill),
    paymentId: payment.id,
    fundingSourceId: payment.fundingSourceId,
  };

  const onDeletePayment = async () => {
    analytics.track(eventPage, 'delete-payment-confirmed', analyticsData);

    onActionLoadStart();

    try {
      const res = await qbdtAPI.qbdtDeletePaymentById(orgId, payment.id);
      if (res?.paymentExternalId) {
        logger.warn(`DeletePaymentModal.handleCancelPaymentClick(): paymentExternalId is not present. ${payment.id}`);
        await qbdtService.deletePayment(res.paymentExternalId);
      }

      analytics.track(eventPage, 'delete-payment-success', analyticsData);

      if (cameFromDashboard) {
        redirectToDashboardUnpaidTab();
        return;
      }

      qbdtService.close();
    } catch (e) {
      logger.error('DeletePaymentModal.onDeletePayment(): failed', e);
      onActionLoadEnd();
    }
  };

  useEffect(() => {
    analytics.track('bill-activity', 'delete-payment-dialog-opend', analyticsData);
  }, []);

  const onClose = () => {
    analytics.track('bill-activity', 'delete-payment-dialog-closed', analyticsData);
    dismiss();
  };

  return (
    <QBOMIDialog
      type={CONSTS.DIALOG_TYPE.CONFIRM}
      variant={CONSTS.DIALOG_VARIANTS.ERROR}
      title="payments.form.deleteDialog.title"
      titleValues={{
        companyName: <strong>{bill.vendor.companyName}</strong>,
      }}
      subtitle="payments.form.deleteDialog.subtitle"
      subtitleValues={{
        companyName: <strong>{bill.vendor.companyName}</strong>,
        amount: (
          <strong>
            <MIFormattedCurrency value={payment.amount || 0} />
          </strong>
        ),
      }}
      okButtonText="payments.form.deleteDialog.confirm"
      onOkAction={onDeletePayment}
      onCancelAction={onClose}
      cancelButtonText="payments.form.deleteDialog.cancelText"
    />
  );
};
