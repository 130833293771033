import React from 'react';
import locations from 'src/utils/locations';
import analytics from 'src/services/analytics';
import { withSiteContext } from 'src/hoc/withSiteContext';
import QBOVirtualDeliveryMethodPageContainer from 'src/pages/vendor/delivery-methods/QBOVirtualDeliveryMethodPageContainer';
import EditBankDeliveryMethodPageContainer from './EditBankDeliveryMethodPageContainer';
import EditCheckNameDeliveryMethodPageContainer from './EditCheckNameDeliveryMethodPageContainer';
import EditCheckAddressWithAutocompleteDeliveryMethodPageContainer from './EditCheckAddressWithAutocompleteDeliveryMethodPageContainer';

const eventPage = 'vendors';

export default [
  {
    path: locations.Vendors.deliveryMethods.virtual.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: withSiteContext()((cProps) => {
      analytics.page(eventPage, 'add-virtual-delivery-method');
      return <QBOVirtualDeliveryMethodPageContainer {...cProps} vendorId={cProps.match.params.id} />;
    }),
  },

  {
    path: locations.Vendors.deliveryMethods.ach.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'add-bank-delivery-method');
      return (
        <EditBankDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['routingNumber', 'accountNumber']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.ach.edit.url({
      id: ':id',
      deliveryMethodId: ':deliveryMethodId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'edit-bank-delivery-method');
      return (
        <EditBankDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
          inputFields={['routingNumber', 'accountNumber']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'add-check-delivery-method-name');
      return (
        <EditCheckNameDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['printName']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.edit.url({
      id: ':id',
      deliveryMethodId: ':deliveryMethodId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'edit-check-delivery-method-name');
      return (
        <EditCheckNameDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
          inputFields={['printName']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.address.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'add-check-delivery-method-address');
      return (
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['googlePlaceId']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.address.edit.url({
      id: ':id',
      deliveryMethodId: ':deliveryMethodId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'edit-check-delivery-method-address');
      return (
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['googlePlaceId']}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
        />
      );
    },
  },
];
