import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import { close } from 'src/billpay/qbdt/services/qbdt';
import ErrorIllustration from 'src/images/qbo/failed-to-load-icon.svg';
import * as WizardElements from 'src/billpay/qbdt/components/layout/WizardElement';
import styled from 'styled-components';
import analytics from 'src/services/analytics';
import { zendeskService } from 'src/services/zendesk';
import { useLocationState } from 'src/utils/hooks';

export type ErrorPageProps = {
  title: string;
  subtitle: string;
  errors?: string;
};

export function PayBillErrorPage({ title, subtitle, errors }: ErrorPageProps) {
  const [exitUrl] = useLocationState('exitUrl');
  const history = useHistory();

  useEffect(() => {
    analytics.trackAction('error-page', {
      title,
      subtitle,
      errorMessage: errors,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showIntercom = useCallback(() => {
    zendeskService.show();
  }, []);

  const onClose = () => {
    if (exitUrl) {
      history.push(exitUrl);

      return;
    }

    close();
  };

  return (
    <QBDTSuccessLayout
      illustration={ErrorIllustration}
      title={title}
      text={subtitle}
      linkLabel="entry.sync.tryAgainLater"
      linkAction={onClose}
      buttonLabel="entry.sync.startChat"
      buttonAction={showIntercom}
      hideHeader
    >
      {errors && (
        <WizardElements.WizardNotesContainer label="entry.sync.errorReport" data-testid="error-message" fullWidth>
          <Content>{errors}</Content>
        </WizardElements.WizardNotesContainer>
      )}
    </QBDTSuccessLayout>
  );
}

const Content = styled.span`
  word-wrap: break-word;
`;
