import { useSelector } from 'react-redux';
import { RestListStatus } from 'src/helpers/redux/restListSlice';
import { dashboardStore } from '../../../redux-store/dashboard-store';
import { DashboardListItemType, DashboardListParams } from '../../../types';

export const useDashboardListItems = (listParams: DashboardListParams) => {
  const dashboardListItems: DashboardListItemType[] = useSelector((state) =>
    dashboardStore.selectors.list.value(state, listParams)
  );
  const loadStatus: RestListStatus = useSelector(dashboardStore.selectors.list?.status(listParams));
  const totalCount = loadStatus?.totalCount || 0;
  const isLoading = loadStatus?.loading;

  return {
    dashboardListItems,
    isLoading,
    totalCount,
  };
};
