import React, { useMemo } from 'react';
import { Box, HStack, Tooltip } from '@melio/billpay-design-system';
import { useCheckVendorEmail } from 'src/billpay/qbdt/modules/vendor-email/hooks/useCheckVendorEmail';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';
import { MainRowType } from '../../withCellWrapper';
import { useExpandRow } from '../../hooks/useExpandRow';
import { CollectVendorEmailPopover } from './CollectVendorEmailPopover';

type Props = {
  vendorName: string;
  vendorId?: string;
  row?: MainRowType;
};

export const VendorCell = ({ vendorName, vendorId, row }: Props) => {
  const { isTruncated, ref } = useIsTruncated<HTMLDivElement>();

  const { shouldShowBatchPayPopover } = useCheckVendorEmail();

  const { toggleRowExpand } = useExpandRow({
    row,
    onClick: (row as any)?.getToggleRowExpandedProps()?.onClick,
  });

  const isVendorEmailMissing = vendorId && shouldShowBatchPayPopover(Number(vendorId));

  return useMemo(
    () => (
      <HStack h="full" align="center" spacing="ds.md" onClick={toggleRowExpand}>
        <Tooltip placement="top" label={vendorName} isDisabled={!isTruncated}>
          <Box isTruncated ref={ref}>
            {vendorName}
          </Box>
        </Tooltip>
        {isVendorEmailMissing && <CollectVendorEmailPopover vendorId={Number(vendorId)} />}
      </HStack>
    ),
    [isTruncated, vendorName, toggleRowExpand, isVendorEmailMissing]
  );
};
