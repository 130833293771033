import * as React from 'react';
import { compose } from 'recompose';
import { withPreservedStateNavigator } from '../../../hoc/index';
import EditCheckNameDeliveryMethodPage from './components/EditCheckNameDeliveryMethodPage';
import { DeliveryMethodProps, withDeliveryMethodData } from './hoc/withDeliveryMethodData';

const EditCheckNameDeliveryMethodPageContainer = ({
  check,
  isLoading,
  onCheckNameAdded,
  goExit,
  validationErrors,
}: DeliveryMethodProps) => (
  <EditCheckNameDeliveryMethodPage
    printName={check.printName}
    onNext={onCheckNameAdded}
    goExit={goExit}
    isLoading={isLoading}
    validationErrors={validationErrors}
  />
);

export default compose(
  withPreservedStateNavigator(),
  withDeliveryMethodData()
)(EditCheckNameDeliveryMethodPageContainer);
