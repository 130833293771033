/* eslint-disable no-template-curly-in-string */
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

const editAddressEvent = 'edit-address';
const unilateralPageEvent = 'unilateral-payment';
const shiftVendorToAchPageEvent = 'shift-vendor-to-ach';
const shiftToDebitPageEvent = 'shift-to-debit';
const virtualCardEvent = 'virtual-card';
const virtualCardDetailsPageEvent = 'virtual-card-details';
const posTerminalPage = 'POSTerminalExplanationPage';
const shiftVCToAchPage = 'shift-vc-to-ach';
const pushToDebitPage = 'vendor';

const MAPPING: EventMappingsTree = {
  '/vendors/*/debit-card-details/*/add-virtual-card/success': {
    'page.view': [pushToDebitPage, 'delivery-method-success'],
  },
  '/vendors/*/debit-card-details/*/add-debit-card/success': {
    'page.view': [pushToDebitPage, 'delivery-method-success'],
  },
  '/vendors/*/add-delivery-method/*': {
    'page.view': [shiftVendorToAchPageEvent, 'add-delivery-method'],
    'go.back': [shiftVendorToAchPageEvent, 'go-back'],
    ...mapApiSlice('[DELIVERYMETHODS] REPLACE_METHOD', 'vendors-ach-details', 'add-ach'),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE_ACH_METHOD', 'vendors-ach-details', 'edit-ach'),
  },
  '/vendors/*/add-delivery-method/*/switch-company': {
    'page.view': [shiftVendorToAchPageEvent, 'switch-company'],
    'option-selected-organization': [shiftVendorToAchPageEvent, 'select-organization', { selectedOrgId: 'option.id' }],
    'vendors.addDeliveryMethodByLink.switchCompanyCTA': [shiftVendorToAchPageEvent, 'switch-organization'],
  },
  '/vendors/*/link-delivery-method/*': {
    'page.view': [shiftVendorToAchPageEvent, 'link-delivery-method'],
    'go.back': [shiftVendorToAchPageEvent, 'go-back'],
    '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR_FAILURE': [
      shiftVendorToAchPageEvent,
      'validation-error',
      { validation: 'error.message' },
    ],
    '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR_SUCCESS': [shiftVendorToAchPageEvent, 'delivery-method-linked'],
  },
  '/vendors/*/add-delivery-method/*/shifted-current-payment': {
    'page.view': [shiftVendorToAchPageEvent, 'shifted-current-payment'],
    'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.upsaleCTA': [
      shiftVendorToAchPageEvent,
      'join-melio',
    ],
  },
  '/vendors/*/add-delivery-method/*/success': {
    'page.view': [shiftVendorToAchPageEvent, 'success'],
    'vendors.deliveryMethods.ach.changeSuccess.button': ['vendors-ach-details', 'quickbooks-signup'],
    'vendors.deliveryMethods.shiftVendorToAch.success.learnMore': [shiftVendorToAchPageEvent, 'learnMore'],
    'vendors.deliveryMethods.shiftVendorToAch.success.upsale.cta': ['vendors-ach-details', 'melio-signup'],
  },
  '/vendors/*/add-delivery-method/*/invalid-link': {
    'page.view': [shiftVendorToAchPageEvent, 'invalid-token'],
  },
  '/vendors/*/edit-address/*/check-sent': {
    'page.view': [shiftVendorToAchPageEvent, 'check-sent'],
    'vendors.editCheckAddress.checkSent.upsaleCTA': ['vendors-ach', 'get-paid-by-bank'],
  },
  '/vendors/*/edit-address/*/check-deposited': {
    'page.view': [shiftVendorToAchPageEvent, 'check-deposited'],
    'vendors.editCheckAddress.checkDeposited.upsaleCTA': ['vendors-ach', 'get-paid-by-bank'],
  },
  '/vendors/*/edit-address/*/change-address': {
    'page.view': [editAddressEvent, 'change-address'],
    'suggest-alt-address': [editAddressEvent, 'suggest-alternative-address', { flow: 'flow' }],
    'submit-address': [editAddressEvent, 'choose-address', { flow: 'option' }],
    'vendors.addDeliveryMethodByLink.manualAddressCTA': [editAddressEvent, 'confirm-address'],
    'vendors.editCheckAddress.checkNotSent.form.saveCTA': [editAddressEvent, 'save-address'],
  },
  '/vendors/*/edit-address/*/address-saved': {
    'page.view': [editAddressEvent, 'address-saved'],
    'vendors.editCheckAddress.checkNotSent.success.upsaleCTA': [editAddressEvent, 'enable-bank-deposits'],
  },
  '/vendors/*/edit-address/*/switched-to-ach': {
    'page.view': [shiftVendorToAchPageEvent, 'switched-to-ach'],
  },
  '/vendors/*/edit-address/*/check-cancelled': {
    'page.view': [shiftVendorToAchPageEvent, 'check-cancelled'],
    'support-email': [shiftVendorToAchPageEvent, 'support-email'],
  },
  '/vendors/*/edit-address/*/invalid-link': {
    'page.view': [shiftVendorToAchPageEvent, 'invalid-link'],
  },
  '/vendors/unilateral/add-delivery-method/:token/virtual-card/learn-more': {
    'page.view': [unilateralPageEvent, 'virtual-card-learn-more-page'],
  },
  '/vendors/unilateral/add-delivery-method/*/virtual-card': {
    'page.view': [unilateralPageEvent, 'review-virtual-card-page'],
  },
  '/vendors/unilateral/add-delivery-method/*': {
    'page.view': [unilateralPageEvent, 'add-delivery-method'],
    'select-category': [
      unilateralPageEvent,
      'select-delivery-method-${value}',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'go.back': [
      unilateralPageEvent,
      'go-back',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        paymentId: 'paymentId',
        payorOrgId: 'payorOrgId',
      },
    ],
    'vendors.addDeliveryMethodByLink.submitCTA': [unilateralPageEvent, 'add-new-delivery-method'],
    'log-in': [
      unilateralPageEvent,
      'go-log-in',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'save-delivery-method': [
      unilateralPageEvent,
      'add-delivery-method',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        organizationId: 'organizationId',
      },
    ],
    'option-selected-manualAddress': [unilateralPageEvent, 'select-manual-address', { address: 'option.label' }],
    'manualAddress.edit': [unilateralPageEvent, 'edit-manual-address'],
    'submit-address': [unilateralPageEvent, 'confirm-manual-address', { option: 'option' }],
    'vendors.addDeliveryMethodByLink.linkExistingMethod.submit': [unilateralPageEvent, 'link-meliome-delivery-method'],
    ...mapApiSlice('[DELIVERYMETHODS] ADDRESS VALIDATION', unilateralPageEvent, 'validate-manual-address'),
    ...mapApiSlice('[DELIVERYMETHODS] REPLACE VIRTUAL METHOD', unilateralPageEvent, 'submit-new-delivery-method', {
      request: { deliveryType: 'payload.deliveryMethod.deliveryType' },
      success: { deliveryType: 'payload.deliveryMethod.deliveryType' },
    }),
    ...mapApiSlice(
      '[DELIVERYMETHODS] COPY_WITH_UNILATERAL_TOKEN',
      unilateralPageEvent,
      'copy-delivery-method-from-owned-vendor'
    ),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE_WITH_UNILATERAL_TOKEN', unilateralPageEvent, 'update-delivery-method'),
  },
  '/vendors/unilateral/add-delivery-method/*/switch-company': {
    'page.view': [unilateralPageEvent, 'switch-company'],
    'option-selected-organization': [unilateralPageEvent, 'select-organization', { selectedOrgId: 'option.id' }],
    'vendors.addDeliveryMethodByLink.switchCompanyCTA': [unilateralPageEvent, 'switch-organization'],
  },
  '/vendors/unilateral/add-delivery-method/*/shifted-current-payment': {
    'page.view': [unilateralPageEvent, 'current-payment-shifted-to-ach'],
  },
  '/vendors/unilateral/add-delivery-method/*/success': {
    'page.view': [unilateralPageEvent, 'delivery-method-success'],
    'vendors.addDeliveryMethodByLink.signUpCTA': [unilateralPageEvent, 'sign-up'],
    'log-in': [
      unilateralPageEvent,
      'go-log-in',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'vendors.addDeliveryMethodByLink.success.joinService.button': [unilateralPageEvent, 'quickbooks-signup'],
  },
  '/vendors/unilateral/add-delivery-method/*/invalid-link': {
    'page.view': [unilateralPageEvent, 'invalid-token'],
  },
  '/vendors/add-debit-card/*': {
    'page.view': [shiftToDebitPageEvent, 'add-debit-card-details'],
    'ptd-starts': [shiftToDebitPageEvent, 'starts', { vendorId: 'vendorId' }],
  },
  '/vendors/add-debit-card/*/address': {
    'page.view': [shiftToDebitPageEvent, 'add-address-details'],
    'go.back': [shiftToDebitPageEvent, 'go-back'],
    ...mapApiSlice('[DELIVERYMETHODS] ADDRESS VALIDATION', shiftToDebitPageEvent, 'validate-address'),
    ...mapApiSlice('[DELIVERYMETHODS] ADD_CARD_ACCOUNT', shiftToDebitPageEvent, 'add-debit-card'),
    ...mapApiSlice('[PAYMENTS] UPDATE', shiftToDebitPageEvent, 'update-payment'),
    'option-selected-manualAddress': [shiftToDebitPageEvent, 'select-manual-address', { address: 'option.label' }],
    'manualAddress.edit': [shiftToDebitPageEvent, 'edit-manual-address'],
  },
  '/vendors/add-debit-card/*/invalid-link': {
    'page.view': [shiftToDebitPageEvent, 'invalid-token'],
  },
  '/vendors/add-debit-card/*/success': {
    'page.view': [shiftToDebitPageEvent, 'debit-card-added'],
    'vendors.deliveryMethods.shiftToDebit.success.upSaleCTA': [shiftToDebitPageEvent, 'go-meliome-register'],
    'join-quickbooks-online': [
      '',
      'join-quickbooks-online',
      {
        source: 'source',
      },
    ],
  },
  '/vendors/suvc-to-ach/*/*': {
    'vendor-add-ach-bank-details-page-viewed': [
      shiftVCToAchPage,
      'Viewed',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        isNewACH: 'isNewACH',
      },
    ],
  },
  '/vendors/virtual-card/pos-terminal/*': {
    'page-viewed': [
      posTerminalPage,
      'Viewed',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
    'view-card-clicked': [
      `${posTerminalPage}ViewCardDetails`,
      'Clicked',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
    'receive-bank-transfer-clicked': [
      `${posTerminalPage}ReceiveBankTransfer`,
      'Clicked',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
  },
  '/vendors/virtual-card/*/*': {
    'page.view': [virtualCardEvent, 'virtual-card-details'],
    'state-changed': [
      virtualCardDetailsPageEvent,
      'state-changed',
      {
        state: 'state',
        virtualCardId: 'virtualCardId',
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
      },
    ],
    'vendors.virtualCardDetails.valid.cta': [
      virtualCardDetailsPageEvent,
      'valid_click-cta',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        cardFieldName: 'cardFieldName',
      },
    ],
    'vendor.unilateral.virtualCardDetails.cardExpired.submit': [
      virtualCardDetailsPageEvent,
      'expired_click-cta',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
      },
    ],
  },
  '/vendors/virtual-card/*/*/invalid-link': {
    'page.view': [virtualCardEvent, 'invalid-token'],
    'invalid-link-page-loaded': [virtualCardEvent, 'invalid-link-page-loaded', { paymentId: 'paymentId' }],
  },
};

export default MAPPING;
