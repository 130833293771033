import { useEffect } from 'react';
import { useSiteContext } from 'src/hoc/withSiteContext';

export default function SiteIcon() {
  const site = useSiteContext();
  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = `/static/${site.icon}`;
    }
  }, [site]);

  return null;
}
