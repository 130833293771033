import moment from 'moment-business-days';
import 'moment-timezone';

const TIMEZONE = 'US/Central';

const months = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
};

const days = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

// return the day number n in month
function dayNInMonth(thisMoment, month, day, n) {
  const valueToAdd = 7 * n;
  const date = moment(thisMoment)
    .tz(TIMEZONE)
    .month(month)
    .startOf('month')
    .isoWeekday(day + valueToAdd);
  if (date.date() === day + valueToAdd) date.isoWeekday(-7 + day);

  // Change date if Thanksgiving Day calculation falling to December
  if (moment(date).month() === months.DECEMBER) date.isoWeekday(-3);

  // Change date for Thanksgiving Day calculation if month starts with wednesday or thursday
  if (
    moment(date).month() === months.NOVEMBER &&
    (moment(date).startOf('month').day() === days.WEDNESDAY || moment(date).startOf('month').day() === days.THURSDAY)
  )
    date.isoWeekday(-3);

  return date;
}

// return the day number n from the end of month
function dayNInMonthFromEnd(thisMoment, month, day, n) {
  const valueToAdd = -7 * (n - 1);
  const date = moment(thisMoment)
    .tz(TIMEZONE)
    .month(month)
    .endOf('month')
    .isoWeekday(day + valueToAdd);
  if (date.date() === day + valueToAdd) date.isoWeekday(7 + day);

  return date;
}

moment.updateLocale('en', {
  holidayFormat: 'MM-DD-YYYY',
  holiday: (thisMoment) => {
    const momentTimezone = moment(thisMoment).tz(TIMEZONE);

    // New Year's Day
    if (momentTimezone.month() === 0 && momentTimezone.date() === 1) return true;

    // Check if the day before the New Year's Day is Friday
    if (momentTimezone.month() === 11 && momentTimezone.date() === 31) {
      return momentTimezone.isoWeekday() === days.FRIDAY;
    }

    // Check if the day after the New Year's Day is Monday
    if (momentTimezone.month() === 0 && momentTimezone.date() === 2) {
      return momentTimezone.isoWeekday() === days.MONDAY;
    }

    // Independence Day
    if (momentTimezone.month() === 6 && momentTimezone.date() === 4) return true;

    // Check if the day before the Independence Day is Friday
    if (momentTimezone.month() === 6 && momentTimezone.date() === 3) {
      return momentTimezone.isoWeekday() === days.FRIDAY;
    }

    // Check if the day after the Independence Day is Monday
    if (momentTimezone.month() === 6 && momentTimezone.date() === 5) {
      return momentTimezone.isoWeekday() === days.MONDAY;
    }

    // Veterans Day
    if (momentTimezone.month() === 10 && momentTimezone.date() === 11) return true;

    // Check if the day before the Veterans Day is Friday
    if (momentTimezone.month() === 10 && momentTimezone.date() === 10) {
      return momentTimezone.isoWeekday() === days.FRIDAY;
    }

    // Check if the day after the Veterans Day is Monday
    if (momentTimezone.month() === 10 && momentTimezone.date() === 12) {
      return momentTimezone.isoWeekday() === days.MONDAY;
    }

    // Christmas Day
    if (momentTimezone.month() === 11 && momentTimezone.date() === 25) return true;

    // Check if the day before the Christmas Day is Friday
    if (momentTimezone.month() === 11 && momentTimezone.date() === 24) {
      return momentTimezone.isoWeekday() === days.FRIDAY;
    }

    // Check if the day after the Christmas Day is Monday
    if (momentTimezone.month() === 11 && momentTimezone.date() === 26) {
      return momentTimezone.isoWeekday() === days.MONDAY;
    }

    // Martin Luther King Jr. Day
    if (momentTimezone.isSame(dayNInMonth(thisMoment, months.JANUARY, days.MONDAY, 3), 'day')) return true;

    // Presidents Day/Washington's Birthday
    if (momentTimezone.isSame(dayNInMonth(thisMoment, months.FEBRUARY, days.MONDAY, 3), 'day')) return true;

    // Memorial Day
    if (momentTimezone.isSame(dayNInMonthFromEnd(thisMoment, months.MAY, days.MONDAY, 1), 'day')) return true;

    // Labor Day
    if (momentTimezone.isSame(dayNInMonth(thisMoment, months.SEPTEMBER, days.MONDAY, 1), 'day')) return true;

    // Columbus Day
    if (momentTimezone.isSame(dayNInMonth(thisMoment, months.OCTOBER, days.MONDAY, 2), 'day')) return true;

    // Thanksgiving Day
    if (momentTimezone.isSame(dayNInMonth(thisMoment, months.NOVEMBER, days.THURSDAY, 4), 'day')) return true;

    return false;
  },
});

export default moment;
