import React from 'react';
import { Box, Flex, Image } from '@melio/billpay-design-system';
import { FUNDING_LOGO } from 'src/utils/consts';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { AccountType } from 'src/utils/types';
import { DashboardListItemFundingSourceType } from 'src/billpay/qbdt/pages/dashboard/types';
import { imageStyles, iconStyles } from './styles';

type Props = {
  fundingSource?: AccountType | DashboardListItemFundingSourceType;
};

const ACHMethod = ({ fundingSource }: Props) => {
  const digits = getAccountNumber4digits(fundingSource?.bankAccount);
  const hasLogo = fundingSource?.logo && fundingSource?.logo !== FUNDING_LOGO.DEFAULT;

  const fallbackLogo = <Box as="i" __css={iconStyles} className="icon-bank-icon" />;

  return (
    <Flex>
      {hasLogo ? (
        <Image
          w="ds.xl"
          __css={imageStyles}
          src={`data:image/jpeg;base64,${fundingSource?.logo}`}
          fallback={fallbackLogo}
        />
      ) : (
        fallbackLogo
      )}
      {`...${digits}`}
    </Flex>
  );
};

export default ACHMethod;
