import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import analytics from 'src/services/analytics';
import sumBy from 'lodash/sumBy';
import { BatchBillResponseType, BatchListResponseType } from 'src/modules/regular-batch-payments/types/api-types';
import { isBulkPaymentEligible } from '../utils';

const getBillsFromBatchItemsResponse = (batchItems: BatchListResponseType['items']) =>
  batchItems.reduce<BatchBillResponseType[]>((acc, item) => [...acc, ...item?.payment.bills], []);

export const trackLoadBillsAction = (batchItems: BatchListResponseType['items']) => {
  const bills = getBillsFromBatchItemsResponse(batchItems);
  const bulkPaymentEligible = isBulkPaymentEligible(bills);
  const noDeliveryMethodBills = bills.filter((bill) => isEmpty(bill.vendor.deliveryMethods));

  const batchBillIds = bills.map(({ id }) => id);
  const batchBillTotalAmount = sumBy(bills, 'totalAmount');
  const vendorsIds = bills.map((bill) => bill.vendor.id);
  const uniqeVendors = [...new Set(vendorsIds)];

  analytics.trackAction('regularBatchPayments.loadBills', {
    batchBillIds,
    batchBillCount: bills.length,
    batchBillTotalAmount,
    noDeliveryMethodBillIds: map(noDeliveryMethodBills, 'id'),
    noDeliveryMethodBillCount: noDeliveryMethodBills.length,
    vendorsCount: uniqeVendors.length,
    bulkPaymentEligible,
  });
};
