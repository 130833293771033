import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import { useQueryStateMultiValues } from 'src/utils/hooks';
import { ITEMS_PER_PAGE, DashboardTabStatusEnum } from '../consts';
import { DashboardListParams, DashboardListFiltersParams } from '../types';
import { useDashboardHighlightedItems } from './useDashboardHighlightedItems';

type OrderParams = Pick<DashboardListFiltersParams, 'orderBy' | 'orderDirection'>;
type FilterParams = Pick<DashboardListFiltersParams, 'dueDates' | 'statusesInDashboard' | 'vendors'>;
type DashboardListParamsActions = {
  setPage: (pageIndex: number) => void;
  setStatus: (status: DashboardTabStatusEnum) => void;
  setOrder: (params: OrderParams) => void;
  setSearch: (text: string) => void;
  setFilters: (filterParams: FilterParams) => void;
  resetFilters: VoidFunction;
};

const DEFAULT_LIST_PARAMS = {
  status: DashboardTabStatusEnum.Unpaid,
  start: '0',
  limit: ITEMS_PER_PAGE.toString(),
  orderBy: undefined,
  orderDirection: undefined,
  search: undefined,
  statusesInDashboard: undefined,
  vendors: undefined,
  dueDates: undefined,
};

export const useDashboardListParams = (): [DashboardListParams, DashboardListParamsActions] => {
  const orgId = useSelector(getOrgId);
  const [queryParams, setQueryParams] = useQueryStateMultiValues<DashboardListFiltersParams>(DEFAULT_LIST_PARAMS);
  const { removeHighlightedIds } = useDashboardHighlightedItems();
  const { status, start, limit, orderBy, orderDirection, search, statusesInDashboard, vendors, dueDates } = queryParams;

  const setParams = useCallback(
    (params: Partial<DashboardListFiltersParams>) => {
      removeHighlightedIds();
      setQueryParams(params);
    },
    [setQueryParams, removeHighlightedIds]
  );

  const listParams = useMemo<DashboardListParams>(
    () => ({
      orgId,
      filters: {
        start,
        limit,
        status,
        orderBy: orderBy || undefined,
        orderDirection: orderDirection || undefined,
        search,
        statusesInDashboard,
        vendors,
        dueDates,
      },
    }),
    [orgId, start, limit, status, orderBy, orderDirection, search, statusesInDashboard, vendors, dueDates]
  );
  const setPage = useCallback(
    (pageIndex: number) => {
      setParams({
        start: (pageIndex * ITEMS_PER_PAGE).toString(),
        limit: ITEMS_PER_PAGE.toString(),
      });
    },
    [setParams]
  );

  const setOrder = useCallback(
    (params: OrderParams) => {
      setParams({
        start: '0',
        ...params,
      });
    },
    [setParams]
  );

  const setStatus = useCallback(
    (status: DashboardTabStatusEnum) => {
      setParams({
        ...DEFAULT_LIST_PARAMS,
        status,
      });
    },
    [setParams]
  );

  const setSearch = useCallback(
    (term: string) => {
      setParams({
        ...DEFAULT_LIST_PARAMS,
        search: term,
        status,
        statusesInDashboard,
        vendors,
        dueDates,
      });
    },
    [dueDates, setParams, status, statusesInDashboard, vendors]
  );

  const setFilters = useCallback(
    ({ statusesInDashboard, vendors, dueDates }: FilterParams) => {
      setParams({
        ...DEFAULT_LIST_PARAMS,
        status,
        search,
        statusesInDashboard,
        vendors,
        dueDates,
      });
    },
    [search, setParams, status]
  );

  const resetFilters = useCallback(() => {
    setParams({
      ...DEFAULT_LIST_PARAMS,
      status,
      search,
      vendors: undefined,
      statusesInDashboard: undefined,
      dueDates: undefined,
    });
  }, [search, setParams, status]);

  return [
    listParams,
    {
      setStatus,
      setPage,
      setOrder,
      setSearch,
      setFilters,
      resetFilters,
    },
  ];
};
