import React, { useEffect, useState, useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { RecordOf } from 'immutable';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { withNavigator } from 'src/hoc';
import { getPartialBillId } from 'src/utils/bills';
import { CONSTS } from 'src/utils/consts';
import { FieldType, BankType } from 'src/utils/types';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId, getOwnedVendorId, getFundingSources } from 'src/redux/user/selectors';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import { BankRecord } from 'src/pages/vendor/records';
import { selectNewDeliveryMethodAction, selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import AreaLoader from 'src/components/common/AreaLoader';
import { getPaymentById } from 'src/utils/payments';
import deliveryApi from 'src/services/api/delivery';
import EditBankDeliveryMethodPage from './components/EditBankDeliveryMethodPage';
import { checkBankAccount } from '../delivery-method/utils';
import { PayBillProps, withPayBillData } from '../pay/hoc/withPayBillData';

const eventPage = 'edit-ach-delivery-method';

function EditAchDeliveryMethodPage({ navigate }: PayBillProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const {
    id: paymentId,
    deliveryMethodId,
    vendorId,
    fundingSourceId,
    amount,
    scheduledDate,
    deliveryPreference,
  } = payment;
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const userFundingSources = useSelector(getFundingSources);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const bankAccount = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId))?.bankAccount;
  const [bank, setBank] = useState<RecordOf<BankType>>(bankAccount || BankRecord());
  const [isBankChanged, setIsBankChanged] = useState(false);
  const vendorName = bill.vendor.companyName;
  const titleValues = { vendor: vendorName };
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorCode, setErrorCode] = useState('');
  const origin = CONSTS.DELIVERY_METHOD_ORIGIN.EDIT_PAYMENT;

  const fetchDeliveryMethod = useCallback(async () => {
    if (!deliveryMethodId) {
      return;
    }

    await deliveryMethodActions.fetch({
      orgId,
      vendorId,
      id: deliveryMethodId,
    });
  }, [deliveryMethodId, orgId, vendorId, deliveryMethodActions]);

  const fetchExpeditedAchDeliveryDates = useCallback(async () => {
    const { deliveryDate, maxDeliveryDate } = await deliveryApi.getExpeditedAchDeliveryDates(
      orgId,
      Number.parseInt(deliveryMethodId, 10),
      fundingSourceId,
      amount
    );

    dispatch(selectPaymentDatesAction(scheduledDate, deliveryDate, maxDeliveryDate, deliveryPreference));
  }, [deliveryMethodId, orgId, amount, deliveryPreference, dispatch, fundingSourceId, scheduledDate]);

  useEffect(() => {
    if (!payment?.id) {
      return;
    }

    const { status, metadata } = payment;
    const isReceiverRefusedCredit =
      status === CONSTS.PAYMENT_STATUS.FAILED && metadata?.failureMessage === CONSTS.RECEIVER_REFUSED_CREDIT_ENTRY;

    if (isReceiverRefusedCredit) {
      fetchExpeditedAchDeliveryDates();
      navigate(
        generatePath(locations.edit.confirm, {
          orgId,
          billId: bill.id,
          paymentId: payment?.id,
        })
      );
    }
  }, [payment, bill]);

  useEffect(() => {
    if (bankAccount) {
      setBank(BankRecord(bankAccount));
    }
  }, [bankAccount]);

  useEffect(() => {
    if (deliveryMethodId) {
      fetchDeliveryMethod();
    }
  }, [deliveryMethodId, fetchDeliveryMethod]);

  const onChange = ({ id, value }: FieldType) => {
    setBank(bank.merge({ [id]: value }));

    if (!isBankChanged) {
      setIsBankChanged(true);
    }
  };

  const getValidationsError = () => {
    let validationErrors = getValidationErrors('deliveryMethodAch', bank);

    if (isValidationOk(validationErrors) && Number(vendorId) !== Number(ownedVendorId)) {
      validationErrors = checkBankAccount(bank, userFundingSources);
    }

    return validationErrors;
  };

  const updateDeliveryMethod = async (deliveryMethod) => {
    const { payload } = await deliveryMethodActions.update({
      orgId,
      vendorId,
      id: deliveryMethodId,
      deliveryMethod,
    });

    await dispatch(selectNewDeliveryMethodAction(payload));

    analytics.track(eventPage, 'edit-delivery-method-success', {
      type: deliveryMethod.deliveryType,
      partialBillId: getPartialBillId(bill),
    });
  };

  const onDone = async () => {
    setIsLoading(true);
    const originPayment = getPaymentById(bill.payments, paymentId);
    const validationErrors = getValidationsError();

    setValidationErrors(validationErrors);

    const newDeliveryMethod = {
      deliveryType: CONSTS.DELIVERY_TYPE.ACH,
      bankAccount: bank,
    };

    if (!isValidationOk(validationErrors)) {
      setIsLoading(false);
      analytics.track(eventPage, 'edit-delivery-method-validation-error', {
        ...validationErrors,
        partialBillId: getPartialBillId(bill),
      });
      return;
    }

    try {
      await fetchExpeditedAchDeliveryDates();

      if (originPayment?.deliveryMethodId === deliveryMethodId || isBankChanged) {
        await updateDeliveryMethod(newDeliveryMethod);
      }

      historyPush({
        path: generatePath(locations.edit.confirm, {
          orgId,
          billId: bill.id,
          paymentId: payment?.id,
        }),
      });
    } catch (err: any) {
      const errorCode = err?.error?.code || err?.code;

      if (errorCode === CONSTS.BANK_ACCOUNT_EXIST) {
        setErrorCode(CONSTS.FAILED_TO_DELIVER_ERR);
      } else {
        setErrorCode(errorCode);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const goExit = () => {
    history.goBack();
  };

  if (!deliveryMethodId) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <EditBankDeliveryMethodPage
      vendorName={vendorName}
      bank={bank}
      titleValues={titleValues}
      onDone={onDone}
      onChange={onChange}
      onPrev={goExit}
      goExit={goExit}
      isLoading={isLoading}
      validationErrors={validationErrors}
      origin={origin}
      errorCode={errorCode}
    />
  );
}

export default compose(withNavigator(), withPayBillData())(EditAchDeliveryMethodPage);
