import { Record } from 'immutable';
import get from 'lodash/get';
import { DeliveryMethodRecord, VendorRecord } from '../vendor/records';
import { BillType, OptionalDeliveryMethodsType } from '../../utils/types';
import { CONSTS } from '../../utils/consts';

export const BillRecord: Record.Factory<BillType> = Record(
  {
    id: '',
    balance: undefined,
    totalAmount: null,
    currency: CONSTS.CURRENCY.USD,
    organization: {
      id: '',
      companyName: '',
    },
    createdById: undefined,
    metadata: {
      isDeletable: true,
    },
    origin: '',
    originId: '',
    status: CONSTS.BILL_STATUS.UNPAID,
    vendorId: NaN,
    lineItems: [],
    payments: [],
    files: [],
    dueDate: '',
    originMetaUrl: '',
    lastUpdater: '',
    invoiceDate: new Date(),
    terms: NaN,
    creator: '',
    invoiceNumber: '',
    note: '',
    intuitAccountId: '',
    internalBill: false,
    goodsReceived: null,
    vendor: {
      id: '',
      companyName: '',
      deliveryMethods: undefined,
      contactEmail: '',
    },
    partner: undefined,
    externallyPaid: NaN,

    getDeliveryMethodByType(type: OptionalDeliveryMethodsType) {
      return getDeliveryMethodByType(this, type);
    },
    getDeliveryMethodById(id: string) {
      return getDeliveryMethodById(this, id);
    },
    getFirstFileId() {
      return get(this.files, '[0].id', null);
    },

    isVendorRequest() {
      return this.vendor && !!this.vendor.ownedById;
    },
    paymentRequest: null,
  } as any,
  'BillRecord'
);

export const getDeliveryMethodByType = (bill: BillType, type: OptionalDeliveryMethodsType) => {
  const vendorRecord = VendorRecord(bill.vendor as any);
  return vendorRecord.getDeliveryMethodByType(type);
};

export const getDeliveryMethodById = (bill: BillType, id: string) => {
  if (bill.vendor && bill.vendor.deliveryMethods) {
    return DeliveryMethodRecord(bill.vendor.deliveryMethods.find((dm) => dm.id === id));
  }

  return null;
};
