export const EVENT_PAGE = 'qbdt-dashboard';

export const ITEMS_PER_PAGE = 24;

export enum DashboardTabStatusEnum {
  Unpaid = 'unpaid',
  Scheduled = 'scheduled',
  Paid = 'paid',
}

export const TABS = [DashboardTabStatusEnum.Unpaid, DashboardTabStatusEnum.Scheduled, DashboardTabStatusEnum.Paid];

export enum DashboardColumnsEnum {
  VendorName = 'vendorName',
  InvoiceNumber = 'invoiceNumber',
  ScheduledDate = 'scheduledDate',
  Status = 'status',
  DeliveryEta = 'deliveryEta',
  DueDate = 'dueDate',
  FundingType = 'fundingType',
  DeliveryType = 'deliveryType',
  TotalAmount = 'totalAmount',
  Payment = 'payment',
  BillAmount = 'totalAmount',
  PaymentAmount = 'amount',
  Balance = 'balance',
  Actions = 'actions',
}

export enum UnpaidDueDateOptionsEnum {
  NextSevenDays = 'nextSevenDays',
  ThisMonth = 'thisMonth',
  NextMonth = 'nextMonth',
  PastDue = 'pastDue',
}

export enum UnpaidBillDashboardStatusesEnum {
  Failed = 'failed',
  Overdue = 'overdue',
  DueInOneWeek = 'dueInOneWeek',
  Open = 'open',
}

export enum ScheduledBillDashboardStatusesEnum {
  PendingVendorDetails = 'pendingVendorDetails',
  BankUnverified = 'bankUnverified',
  Scheduled = 'scheduled',
}

export enum PaidBillDashboardStatusesEnum {
  Deposited = 'deposited',
  Sent = 'sent',
}

export enum PaymentFlowTypeEnum {
  Pay = 'pay',
  Edit = 'edit',
}

export enum DashboardActionConfirmFlowEnum {
  SinglePay = 'single-pay',
  BatchPay = 'batch-pay',
}

export const DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP = 'dashboardActionConfrimFlow';
