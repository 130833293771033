import React from 'react';
import { useSelector } from 'react-redux';
import errorIllustration from 'src/images/icons/errorToken.svg';
import styled from 'styled-components';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import qbLogo from 'src/images/accounting-software/quickbooks-logo-horz.png';
import canceledImage from 'src/images/qbo/failed-to-load-002-icon.png';
import expiredImage from 'src/images/qbo/no-payment-created-icon.png';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { UnilateralPaymentProps } from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import paymentStore from 'src/modules/payments/payment-store';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { MIFormattedText } from 'src/utils/formatting';

type Props = UnilateralPaymentProps;

export const InvalidAddDeliveryMethodPage = ({ token }: Props) => {
  const site = useSiteContext();
  const { paymentId } = getJWTPayload(token);
  const { errorData } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation);
  const error = errorData || invalidTokenData;
  let companyName = '';
  let email = '';
  if (error && error.data) {
    ({ companyName, email } = error.data);
  }

  const renderIcon = (error) => {
    switch (error?.code) {
      case 'PMT17':
        return expiredImage;
      case 'PMT18':
        return canceledImage;
      default:
        return errorIllustration;
    }
  };

  return (
    <QBOLayoutPage contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <Content>
        <Illustration src={renderIcon(error)} alt="error" />
        <SubTitle>
          {error && email && companyName ? (
            <MIFormattedText
              label={`server.${error.code}`}
              values={{
                companyName,
                email,
                supportEmail: site.config.support.email,
              }}
            />
          ) : (
            <MIFormattedText label="vendors.deliveryMethods.ach.error" values={{ email: site.config.support.email }} />
          )}
        </SubTitle>
      </Content>
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;
const Illustration = styled(QBLogo)`
  width: 12rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;
