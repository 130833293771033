import analytics from 'src/services/analytics';

const page = 'settings-billing';

const eventNamesMap = {
  addMethod: 'add-method-click',
  paymentMethodAction: 'payment-method-action',
  paymentMethodContinue: 'payment-method-continue',
  addNewPaymentMethod: 'add-new-payment-method',
  removePaymentMethodModalOpen: 'remove-payment-method-modal-shown',
  removePaymentMethod: 'remove-payment-method',
  cancelRemovePaymentMethod: 'remove-payment-method-cancel',
  receiptDownload: 'receipt-download',
};

type ActionType = 'edit-method' | 'remove-method';

interface BillingFeeAnalytics {
  addMethod: () => void;
  paymentMethodAction: (params: { paymentMethodId: number; paymentMethodType?: string; action: ActionType }) => void;
  paymentMethodContinue: (params: { paymentMethodId: number; paymentMethodType?: string }) => void;
  addNewPaymentMethod: (params: { paymentMethodType?: string }) => void;
  removePaymentMethodModalOpen: (params: { paymentMethodId: number; paymentMethodType?: string }) => void;
  removePaymentMethod: (params: { paymentMethodId: number; paymentMethodType?: string }) => void;
  cancelRemovePaymentMethod: (params: { paymentMethodId: number; paymentMethodType?: string }) => void;
  receiptDownload: (params: { receiptDate: string; receiptAmount: number }) => void;
  visitAddMethodPage: () => void;
  visitSuccessPage: () => void;
}

export const billingFeeAnalytics: BillingFeeAnalytics = {
  addMethod: () => analytics.track(page, eventNamesMap.addMethod),
  visitAddMethodPage: () => analytics.page(page, 'payment-method'),
  visitSuccessPage: () => analytics.page(page, 'payment-method-added'),
  paymentMethodAction: (params) => analytics.track(page, eventNamesMap.paymentMethodAction, params),
  paymentMethodContinue: (params) => analytics.track(page, eventNamesMap.paymentMethodContinue, params),
  addNewPaymentMethod: (params) => analytics.track(page, eventNamesMap.addNewPaymentMethod, params),
  removePaymentMethodModalOpen: (params) => analytics.track(page, eventNamesMap.removePaymentMethod, params),
  removePaymentMethod: (params) => analytics.track(page, eventNamesMap.removePaymentMethod, params),
  cancelRemovePaymentMethod: (params) => analytics.track(page, eventNamesMap.cancelRemovePaymentMethod, params),
  receiptDownload: (params) => analytics.track(page, eventNamesMap.receiptDownload, params),
};
