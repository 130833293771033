import React, { useEffect } from 'react';
import { Flex, Image } from '@melio/billpay-design-system';
import qbLogo from 'src/images/accounting-software/quickbooks-logo-horz.png';

type Props = {
  children: React.ReactNode;
};

export const VendorFlowLayout = ({ children }: Props) => {
  useEffect(() => {
    if (window) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <Flex minHeight="100vh" align="center" justify="center" direction="column" bg="ds.gray.700">
      <Image src={qbLogo} w="15rem" mt="ds.2xl" />
      {children}
    </Flex>
  );
};
