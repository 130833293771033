import { generatePath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';

export const useEditPayment = () => {
  const location = useLocation();
  const [historyPush] = useHistoryWithOrgId();
  const { bill, payment, eventPage } = useViewPaymentContext();

  const orgId = useSelector(getOrgId);

  const exitUrl = `${location.pathname}${location.search}`;

  const goEditPayment = () => {
    analytics.track(eventPage, 'edit-bill');

    historyPush({
      path: generatePath(locations.edit.funding, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      }),
      state: {
        exitUrl,
      },
    });
  };

  return {
    goEditPayment,
  };
};
