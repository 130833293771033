import regularBatchPaymentStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import api from 'src/services/api/deliveryMethods';
import paymentStore from 'src/modules/payments/payment-store';
import { dashboardStore } from 'src/billpay/qbdt/pages/dashboard/redux-store/dashboard-store';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { LOAD_DELIVERY_METHODS_SUCCESS } from 'src/redux/user/actionTypes';
import { selectCreateIsLoading } from 'src/helpers/redux/restCreateSlice';
import { DeliveryMethodType, EditableDeliveryMethodType } from 'src/utils/types';
import {
  replaceVirtualDeliveryMethodSlice,
  fetchUnilateralRequestDetailsWithTokenSlice,
  whitePagesAddressSlice,
  copyFromOwnedVendorWithUnilateralToken,
  replaceDeliveryMethodSlice,
  copyFromOwnedVendorSlice,
  updateWithUnilateralToken,
  updateDeliveryMethodACHSlice,
  shouldCloseVerifyDeliveryMethodIndicatorSlice,
  addCardAccountWithToken,
  clearWhitePageAddressSlice,
} from './delivery-methods-slice';
import { updateRegularBatchPaymentSaga } from './sagas';

export const name = 'deliveryMethods';

const createDeliveryMethodIsLoading = selectCreateIsLoading('default');

const persistConfig = {
  whitelist: ['shouldCloseVerifyIndicator'],
};

type DeliveryMethodStoreType = {
  orgId: string;
  vendorId: string;
  id: string;
  deliveryMethod: DeliveryMethodType;
};

type DeliveryMethodStoreInitialStateType = {
  validation: {
    loading: boolean;
    invalidTokenData: any | null;
    deliveryMethodId: string;
  };
};

const deliveryMethodsStore = createRestfulSlice<DeliveryMethodStoreType, DeliveryMethodStoreInitialStateType>({
  name,
  schemaName: 'deliveryMethod',
  persistConfig,
  api: {
    create({ orgId, vendorId, ...params }) {
      return api.addDeliveryMethod({ orgId, vendorId, params }).then((res) => res.deliveryMethod);
    },
    update({ orgId, vendorId, id, deliveryMethod }) {
      return api.editDeliveryMethodById(orgId, vendorId, id, deliveryMethod).then((res) => res.deliveryMethod);
    },
    fetch: ({ orgId, vendorId, id }) =>
      api.getDeliveryMethodById({ orgId, vendorId, id }).then((res) => res.deliveryMethod),
  },
  extraReducers: {
    [LOAD_DELIVERY_METHODS_SUCCESS](state, { deliveryMethods }) {
      deliveryMethods.forEach((deliveryMethod) => {
        state.byId[deliveryMethod.id] = deliveryMethod;
      });
    },
    [paymentStore?.actions.fetchPaymentDetailsWithToken.success](state: any, { payload }) {
      payload.payment.vendor.deliveryMethods.forEach((dm) => {
        state.byId[dm.id] = dm;
      });
    },
    [paymentStore?.actions.fetchEmailToVendorDetails.success](state: any, { payload }) {
      payload.payment.vendor.deliveryMethods.forEach((dm) => {
        state.byId[dm.id] = dm;
      });
    },
    [regularBatchPaymentStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        const vendor = item.bill ? item.bill.vendor : item.payment.vendor;
        vendor.deliveryMethods.forEach((dm) => {
          state.byId[dm.id] = dm;
        });
      });
    },
    [dashboardStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        if (item.deliveryMethod) {
          state.byId[item.deliveryMethod.id] = item.deliveryMethod;
        }
      });
    },
  },
  selectors: {
    byId: (deliveryMethodId) => (state) => state[name].byId[deliveryMethodId],
    all: (state) => Object.values(state[name]?.byId || {}),
    manualAddress(deliveryMethodId) {
      const paymentSelectors = {
        whitePageAddress(state) {
          return state[name].meta[deliveryMethodId]?.whitePageAddress;
        },
        isAddressLoading(state) {
          return state[name].meta[deliveryMethodId]?.loading;
        },
      };
      return paymentSelectors;
    },
    validation: (state) => state[name].validation,
    isCreating: (state) => createDeliveryMethodIsLoading()(state[name]),
  },
  slices: {
    clearWhitePageAddressSlice,
    replaceVirtualDeliveryMethod: replaceVirtualDeliveryMethodSlice,
    fetchUnilateralRequestDetails: fetchUnilateralRequestDetailsWithTokenSlice,
    validateAddress: whitePagesAddressSlice,
    copyFromOwnedVendorWithUnilateralToken,
    replaceDeliveryMethodSlice,
    updateDeliveryMethodACHSlice,
    copyFromOwnedVendorSlice,
    updateWithUnilateralToken,
    addCardAccountWithToken,
    shouldCloseVerifyDeliveryMethodIndicatorSlice,
  },
  extraSagas: updateRegularBatchPaymentSaga,
});

export function getDeliveryMethodActions(dispatch) {
  return {
    async clearWhitePageAddress({ deliveryMethodId }) {
      return dispatch(clearWhitePageAddressSlice.actions.clear(deliveryMethodId));
    },
    async create({ orgId, vendorId, params }) {
      return dispatch(deliveryMethodsStore.actions.create({ orgId, vendorId, ...params }));
    },
    async edit({
      orgId,
      vendorId,
      id,
      deliveryMethod,
    }: {
      orgId: string;
      vendorId: string;
      id: string;
      deliveryMethod: EditableDeliveryMethodType;
    }) {
      return dispatch(
        deliveryMethodsStore.actions.update({
          orgId,
          vendorId,
          id,
          deliveryMethod,
        })
      );
    },
    async replaceDeliveryMethodWithToken({ token, deliveryMethod, orgId, vendorId }) {
      return dispatch(
        replaceDeliveryMethodSlice.actions({
          token,
          deliveryMethod,
          orgId,
          vendorId,
        })
      );
    },
    async updateDeliveryMethodACHWithToken({ token, deliveryMethod, orgId, vendorId }) {
      return dispatch(
        updateDeliveryMethodACHSlice.actions({
          token,
          deliveryMethod,
          orgId,
          vendorId,
        })
      );
    },
    async copyFromOwnedVendor({ orgId, vendorId, token }) {
      return dispatch(copyFromOwnedVendorSlice.actions({ orgId, vendorId, token }));
    },
  };
}

export default deliveryMethodsStore;
