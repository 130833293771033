import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { InitialAddFundingSourceLocationState } from '../types';

export const useAddFundingSourceNavigator = () => {
  const locationState = useLocation<InitialAddFundingSourceLocationState>().state;
  const history = useHistory();
  const closeAppOnExit = locationState?.preservedState?.closeAppOnExit;
  const exitUrl = locationState?.exitUrl;
  const navigateOnExit = useCallback(() => {
    if (closeAppOnExit || !exitUrl) {
      close();
      return;
    }

    history.push(exitUrl);
  }, [exitUrl, closeAppOnExit, history]);

  return { navigateOnExit };
};
