const base = '/vendors/debit-card-details/:token';

const LOCATIONS = {
  base: [base],
  index: base,
  addDebitCard: `${base}/add-debit-card`,
  confirmCardHolder: `${base}/add-debit-card/card-holder`,
  addDebitCardSuccessPage: `${base}/add-debit-card/success`,
  addDebitCardCompletedPage: `${base}/add-debit-card/completed`,
  addVirtualCard: `${base}/add-virtual-card`,
  addVirtualCardMethodSuccess: `${base}/add-virtual-card/success`,
  addVirtualCardMethodCompleted: `${base}/add-virtual-card/completed`,
  addVirtualCardMethodNotCleared: `${base}/add-virtual-card/not-cleared`,
  learnMoreAboutVirtualCard: `${base}/add-virtual-card/learn-more`,
  paymentBlocked: `${base}/payment-blocked`,
  paymentCanceled: `${base}/payment-canceled`,
  paymentFailed: `${base}/payment-failed`,
  checkDeposited: `${base}/check-deposited`,
  achDeposited: `${base}/ach-deposited`,
  invalidToken: `${base}/invalid-token`,
  offerHasExpired: `${base}/offer-expired`,
};

export default LOCATIONS;
