import { RecordOf } from 'immutable';
import classNames from 'classnames';
import React from 'react';
import { CONSTS, DELIVERY_TYPE, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { BillType, DeliveryMethodType, PaymentType, VendorType } from 'src/utils/types';
import { getLatestPayment } from 'src/utils/payments';
import { getDeliveryMethodInfo } from 'src/pages/vendor/records';
import { isSharedVendor } from 'src/pages/vendor/utils';
import { PaymentFieldContent } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymenFieldContent';
import { PaymentFieldContainer } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldContainer';
import { PaymentFieldEdit } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldEdit';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { PaymentFieldInfo } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldInfo';
import { PaymentFieldLabel } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldLabel';
import Tag from '../../components/Tag';
import { DeliveryMethodIcon } from './DeliveryMethodIcon';
import { DeliveryMethodLabel } from './DeliveryMethodLabel';

type Props = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  onEdit?: () => void;
};

export const ConfirmPaymentDeliveryMethodInfo = ({ deliveryMethod, onEdit, bill, payment }: Props) => {
  const isCheckDeliveryMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK;
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

  const description = getDeliveryMethodInfo({
    deliveryMethod,
    vendorName: bill?.vendor?.companyName || '',
    isSharedVendor: isSharedVendor(bill?.vendor as VendorType),
  });

  const originPayment = getLatestPayment(bill?.payments);

  const canEdit =
    !isSharedVendor(bill?.vendor as VendorType) &&
    payment?.metadata?.failedType !== CONSTS.FAILED_PAYMENT_TYPE.FAILED_TO_COLLECT &&
    !isVirtualCardDeliveryMethod;

  const isUpdated =
    originPayment && payment?.metadata?.failedType && deliveryMethod?.id !== originPayment?.deliveryMethodId;

  const paymentInfoTextClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: isCheckDeliveryMethod,
  });

  return (
    <PaymentFieldContainer>
      <PaymentFieldIcon>
        <DeliveryMethodIcon deliveryType={deliveryMethod.deliveryType} />
      </PaymentFieldIcon>

      <PaymentFieldInfo>
        <PaymentFieldLabel>
          <DeliveryMethodLabel deliveryType={deliveryMethod.deliveryType} />
        </PaymentFieldLabel>

        <PaymentFieldContent data-testid="bill-pay-delivery-method" className={paymentInfoTextClassName}>
          {description}
          {isUpdated && <Tag label="payBillPaymentActivity.updated" testId="delivery-method-updated-tag" />}
        </PaymentFieldContent>
      </PaymentFieldInfo>

      {canEdit && <PaymentFieldEdit testId="delivery-method-edit-icon" onEdit={onEdit} />}
    </PaymentFieldContainer>
  );
};
