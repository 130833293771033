import { useState, useEffect } from 'react';

export const DEFAULT_VISIBILITY_DELAY = 300; // in ms

type Params = {
  visible: boolean;
  delay?: number; // in ms
};

export const useDelayVisibility = ({ delay = DEFAULT_VISIBILITY_DELAY, visible }: Params) => {
  const [internalVisible, setInternalVisible] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      const delayTimeout = setTimeout(() => {
        setInternalVisible(true);
      }, delay);

      return () => {
        clearTimeout(delayTimeout);
      };
    }

    setInternalVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return internalVisible;
};
