import React from 'react';
import { useSelector } from 'react-redux';
import { HStack } from '@melio/billpay-design-system';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useBatchSettings } from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchSettings';
import { BatchDateOption } from 'src/billpay/qbdt/pages/batch-pay/consts';
import { DeductionDateMenu } from './DeductionDateMenu';
import { DeductionDatePicker } from './DeductionDatePicker';
import { WalkthroughPopoverProps } from './WalkthroughPopover';
import { WalkthroughStep } from './hooks/useWalkthroughState';

type Props = {
  getPopoverProps: (step: WalkthroughStep) => WalkthroughPopoverProps;
};

const SetDeductionDate = ({ getPopoverProps }: Props) => {
  const { batchSettings } = useBatchSettings();
  const isMultipleDeductionDate = useSelector(regularBatchPaymentsStore.selectors.isMultipleDeductionDate);

  const isDatePickerAvailable = batchSettings.dateOption === BatchDateOption.ALL_AT_ONCE && !isMultipleDeductionDate;

  return (
    <HStack spacing="ds.2xl" align="flex-start" minH="6rem">
      <DeductionDateMenu getPopoverProps={getPopoverProps} />
      {isDatePickerAvailable && <DeductionDatePicker />}
    </HStack>
  );
};

export { SetDeductionDate };
