export function getNameInitials(name?: string | null, numOfInitials = 2) {
  const text = name || '';

  return text
    .split(/\W/g)
    .map((n) => n[0])
    .join('')
    .slice(0, numOfInitials)
    .toUpperCase();
}

export function getEventNameFromLocation(location) {
  return location.pathname.split(/[/]+/).pop();
}

export function checkApostropheForPluralPossessive(value: string) {
  if (!value) return value;

  return value.slice(-1) === 's' ? `${value}’` : `${value}’s`;
}
