import React from 'react';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { ModelView } from 'src/ui/form';
import { AddressType, EditableDeliveryMethodType, OptionalDeliveryMethodsType } from 'src/utils/types';
import styled from 'styled-components';
import MIButton from 'src/components/common/MIButton';
import OutsideLayout from 'src/components/layout/OutsideLayout';
import { devices } from 'src/theme/AppDevices';
import { MIFormattedText } from 'src/utils/formatting';
import { FormRow } from 'src/ui/form/FormElements';
import { CONSTS } from 'src/utils/consts';
import { BackButton, ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import useWhitePageAction from 'src/pages/vendor/hoc/white-page-hooks';

type Props = {
  setWhitePageAddressView: (value: boolean) => void;
  whitePageAddress: ManualAddressType;
  model: ModelView<AddressType>;
  isLoading: boolean;
  submit: (deliveryMethod: EditableDeliveryMethodType, isAddressVerified: boolean) => Promise<void>;
  deliveryMethodType?: OptionalDeliveryMethodsType;
};

function WhitePageAddressContainer(props: Props) {
  const { isLoading, setWhitePageAddressView } = props;
  const [confirmAddress, renderWhitePageAddress] = useWhitePageAction(props);

  return (
    <OutsideLayout>
      <BackButtonWrapper>
        <BackButton onPrev={() => setWhitePageAddressView(false)} />
      </BackButtonWrapper>
      <ContentWrapper title="vendors.addDeliveryMethodByLink.manualAddressTitle">
        <ManualAddressSubTitle>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.manualAddressSubTitle" />
        </ManualAddressSubTitle>
        {renderWhitePageAddress()}
        <ButtonContainer>
          <MIButton
            onClick={confirmAddress}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            label="vendors.addDeliveryMethodByLink.manualAddressCTA"
            fullWidth
            isProcessing={isLoading}
          />
        </ButtonContainer>
      </ContentWrapper>
    </OutsideLayout>
  );
}

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.opaque};
  padding: 4rem 4rem 1rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ButtonContainer = styled(FormRow)`
  margin-top: 4rem;
`;

const ManualAddressSubTitle = styled.div`
  margin: 1rem 0 4rem;
  ${(props) => props.theme.text.fontType.regular};
`;

export default WhitePageAddressContainer;
