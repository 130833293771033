import { OrganizationType, PaymentType, VirtualCardType } from 'src/utils/types';
import { Flex, Icon, Link, Text } from '@melio/billpay-design-system';
import { ReactComponent as ErrorIllustration } from 'src/images/general/error-icon.svg';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedText } from 'src/utils/formatting';
import React from 'react';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { useStateChangedAnalytics, VirtualCardPaymentState } from 'src/pages/vendor/virtual-card-details/hooks';

type Props = {
  organization?: OrganizationType;
  payment?: PaymentType;
  virtualCard?: VirtualCardType;
};

export const PaymentCanceledPage = ({ organization, payment, virtualCard }: Props) => {
  const payorName = organization?.companyName;
  const email = organization?.userOrganizations?.[0]?.user?.email;
  useStateChangedAnalytics({ payment, virtualCard, paymentState: VirtualCardPaymentState.Cancelled });

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" justify="center" align="start" gap="ds.xl">
          <Icon as={ErrorIllustration} w="ds.2xl" h="ds.2xl" />
          <Flex direction="column" justify="center" align="start" gap="ds.md">
            <ContentTitle label="vendor.unilateral.virtualCardDetails.paymentCanceled.title" />
            <Text textStyle="ds.body2" mb="0" color="ds.gray.100">
              <MIFormattedText
                label="vendor.unilateral.virtualCardDetails.paymentCanceled.text"
                values={{
                  payorName,
                  link: <Link href={`mailto:${email}`}>{email}</Link>,
                }}
              />
            </Text>
          </Flex>
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
