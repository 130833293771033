import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Text, Image, Flex } from '@melio/billpay-design-system';
import { VendorFlowLayout, VendorFlowContentCard, Footer } from 'src/pages/vendor/components/VendorFlowLayout';
import { MIFormattedText } from 'src/utils/formatting';
import virtualCardExample from 'src/images/virtual-card/virtucal-card-example.png';
import POSTerminal from 'src/images/virtual-card/POS.png';
import MIButton from 'src/components/common/MIButton';
import { PaymentType } from 'src/utils/types';
import { CONSTS } from 'src/utils/consts';
import { useBreak } from 'src/hoc';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';
import locations from '../locations';

type Props = {
  token: string;
  payment?: PaymentType;
};

export const VirtualCardLearnMorePage = ({ token, payment }: Props) => {
  const history = useHistory();
  const { isDesktop } = useBreak();

  const buttonCLickHandler = () => history.push(generatePath(locations.addVirtualCardMethod, { token }));

  const trackGoBackAnalytics = () => {
    vendorUnilateralAnalytics.trackGoBack({
      paymentId: payment?.id,
      vendorId: payment?.vendorId,
      vendorEmail: payment?.vendor?.contactEmail,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
    });
  };

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack trackAnalyticsOnGoBack={trackGoBackAnalytics}>
        <Text
          textStyle={isDesktop ? 'ds.h6' : 'ds.h5'}
          fontWeight="ds.semi"
          align="center"
          mb="0"
          mt={isDesktop ? '0' : 'ds.xl'}
        >
          <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.learnMore.title" />
        </Text>
        <Text textStyle="ds.body2" align="center" mt={isDesktop ? 'ds.sm' : 'ds.md'} mb="0" color="ds.gray.100">
          <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.learnMore.text" />
        </Text>
        <Image src={virtualCardExample} my="ds.xl" w="100%" />
        <Text textStyle={isDesktop ? 'ds.h6' : 'ds.h5'} fontWeight="ds.semi" align="center" my="0">
          <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.learnMore.howItWorksTitle" />
        </Text>
        <Text textStyle="ds.body2" align="center" mt={isDesktop ? 'ds.sm' : 'ds.md'} mb="0" color="ds.gray.100">
          <MIFormattedText label="vendor.unilateral.deliveryMethods.virtualCard.learnMore.howItWorksText" />
        </Text>
        <Image src={POSTerminal} mt="ds.xl" mb="ds.2xl" w="100%" />
        {isDesktop && (
          <MIButton
            testId="receive-virtual-card-button"
            label="vendor.unilateral.deliveryMethods.virtualCard.learnMore.confirm"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={buttonCLickHandler}
            fullWidth
          />
        )}
      </VendorFlowContentCard>
      <Footer />
      {!isDesktop && (
        <Flex
          direction="column"
          bg="ds.gray.600"
          p="ds.xl"
          boxSizing="border-box"
          width="100%"
          position="sticky"
          bottom="0"
        >
          <MIButton
            label="vendor.unilateral.deliveryMethods.virtualCard.learnMore.confirm"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={buttonCLickHandler}
            fullWidth
          />
        </Flex>
      )}
    </VendorFlowLayout>
  );
};
