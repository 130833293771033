import React from 'react';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';
import { DeliveryMethodType } from 'src/utils/types';
import { DeliveryMethodMenuList } from 'src/billpay/qbdt/pages/batch-pay/table/components/DeliveryMethodCell/DeliveryMethodMenuList';
import ArrowMenuButtonCell from 'src/billpay/qbdt/pages/batch-pay/table/components/ArrowMenuButtonCell';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { SelectedDeliveryMethodLabel } from '../../../components/deliveryMethod/SelectedDeliveryMethodLabel';

type Props = {
  deliveryMethods: DeliveryMethodType[];
  currentDeliveryMethodId?: string;
  onChangeDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  isOpen: boolean;
  onAddDeliveryMethod: () => void;
  listTestId?: string;
  isError?: boolean;
  addDeliveryMethodTestId: string;
  currentDeliveryMethod: DeliveryMethodType;
};

export const DeliveryMethodMenu = ({
  deliveryMethods,
  currentDeliveryMethodId,
  onChangeDeliveryMethod,
  isOpen,
  isError,
  onAddDeliveryMethod,
  listTestId,
  addDeliveryMethodTestId,
  currentDeliveryMethod,
}: Props) => {
  const isDeliveryMethodVirtual = currentDeliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;

  return (
    <>
      <Tooltip
        placement="bottom"
        isDisabled={!isDeliveryMethodVirtual}
        label={<MIFormattedText label="regularBatchPayments.tooltips.virtualDeliveryMethod" />}
      >
        <Flex>
          <Box width="100%">
            <ArrowMenuButtonCell isOpen={isOpen}>
              <SelectedDeliveryMethodLabel deliveryMethod={currentDeliveryMethod} />
            </ArrowMenuButtonCell>
          </Box>
          {isError && <Box as="i" __css={iconStyles} className="icon-warning-inline-icon" />}
        </Flex>
      </Tooltip>
      <DeliveryMethodMenuList
        deliveryMethods={deliveryMethods}
        setCurrentDeliveryMethod={onChangeDeliveryMethod}
        onAddDeliveryMethod={onAddDeliveryMethod}
        selectedDeliveryMethodId={currentDeliveryMethodId}
        isOpen={isOpen}
        testId={listTestId}
        addDeliveryMethodTestId={addDeliveryMethodTestId}
      />
    </>
  );
};

const iconStyles = {
  fontSize: '1.6rem',
  color: '#FFB300',
};
