import React from 'react';
import { Box, Table } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { InternalBill } from 'src/services/api/bills';
import {
  QbdtTableSizesEnum,
  QbdtTableVariantsEnum,
} from 'src/billpay/qbdt/theme-extension/components/Table/Table.consts';
import { BillingFeeHistoryTableRow } from './BillingFeeHistoryTableRow';
import { BillingFeeHistoryTableCaption } from './BillingFeeHistoryTableCaption';
import { BillingFeeHistoryTablePaginationBar } from './BillingFeeHistoryTablePaginationBar';
import { ReceiptStatus } from '../../../components/consts';

type Props = {
  internalBills: InternalBill[];
  internalBillsLoading: boolean;
  internalBillsTotal: number;
  internalBillsStatuses: Record<string, ReceiptStatus>;
  onGetReceiptClick: (internalBill: InternalBill) => void;
  filters: {
    start: number;
    limit: number;
  };
  setPage: (v: number) => void;
};

const BillingFeeHistoryTable = ({
  internalBills,
  internalBillsTotal,
  internalBillsLoading,
  internalBillsStatuses,
  onGetReceiptClick,
  filters,
  setPage,
}: Props) => {
  if (!internalBillsTotal) {
    return (
      <Box color="ds.gray.300" textStyle="ds.body3">
        <MIFormattedText label="settings.billing.feeHistory.emptyHistory" />
      </Box>
    );
  }

  return (
    <>
      <Table<QbdtTableVariantsEnum, QbdtTableSizesEnum>
        variant={QbdtTableVariantsEnum.QbdtBillingFeesHistory}
        size={QbdtTableSizesEnum.QbdtBillingFeesHistoryMd}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <MIFormattedText label="settings.billing.paymentsTable.date" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <MIFormattedText label="settings.billing.paymentsTable.amount" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <MIFormattedText label="settings.billing.paymentsTable.receipt" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {internalBillsLoading ? (
          <BillingFeeHistoryTableCaption />
        ) : (
          <Table.Body>
            {internalBills.map((internalBill) => (
              <BillingFeeHistoryTableRow
                key={internalBill.id}
                status={internalBillsStatuses[internalBill.id]}
                invoiceDate={internalBill.invoiceDate}
                totalAmount={internalBill.totalAmount}
                onIconClick={() => onGetReceiptClick(internalBill)}
              />
            ))}
          </Table.Body>
        )}
      </Table>

      <BillingFeeHistoryTablePaginationBar
        total={internalBillsTotal}
        start={filters.start}
        limit={filters.limit}
        onPageChange={setPage}
      />
    </>
  );
};

export { BillingFeeHistoryTable };
