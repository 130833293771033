import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DropDownOption } from 'src/components/common/DropDown/MIDropDown';
import { Sublabel } from 'src/components/common/MIAutocompleteExtras/components';
import Tooltip from 'src/core/ds/tooltip';
import { useBreak } from 'src/hoc';
import { SelectFieldOption } from 'src/ui/form/WizardSelectField';

const RegularOption = styled(DropDownOption)`
  cursor: pointer;
`;

const ExpandedOption = styled(DropDownOption)`
  cursor: pointer;
  height: 56px;
  background-color: ${(props) => props.theme.colors.white.opaque};
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 2rem;
  box-sizing: border-box;
  padding: 1.4rem 2rem;
  &:hover {
    background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  }
`;

export type SuggestionType<ValueType> = SelectFieldOption<ValueType> & { sublabel?: string; label: string };
const defaultRender = <T,>(option: SuggestionType<T>) => <div>{option.label}</div>;

type SuggestionProps<T> = {
  isExpanded: boolean;
  option: SuggestionType<T>;
  onSelectedOption: (option: SuggestionType<T>) => void;
  /* must be a list of elements without container */
  render?: (option: SuggestionType<T>) => JSX.Element;
};

export const Suggestion = <T,>({ isExpanded, option, onSelectedOption, render }: SuggestionProps<T>) => {
  const { isMobile } = useBreak();
  const [shouldDisableTooltip, setDisableTooltip] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (isMobile) {
      setDisableTooltip(true);

      return;
    }

    const isEllipsis = ref.current && ref.current?.offsetWidth < ref.current?.scrollWidth;

    if (isEllipsis) {
      setDisableTooltip(false);
    }
  }, [ref?.current]);

  const Option = isExpanded ? ExpandedOption : RegularOption;

  return (
    <Tooltip
      data-testid="suggestion-tooltip"
      isDisabled={shouldDisableTooltip}
      label={option.label}
      hasArrow
      placement="top-end"
      closeDelay={0}
    >
      <Option
        key={option.value}
        onClick={() => onSelectedOption(option)}
        data-testid="autocomplete-suggestion"
        ref={ref}
      >
        {render?.(option) ?? defaultRender(option)}
      </Option>
    </Tooltip>
  );
};

export const ExpandedSuggestion = <T,>(props: Exclude<SuggestionProps<T>, 'render'>) => {
  const { render, ...rest } = props;

  return (
    <Suggestion
      {...rest}
      render={(option) => (
        <>
          {render?.(option) ?? defaultRender(option)}
          <Sublabel>{option.sublabel}</Sublabel>
        </>
      )}
    />
  );
};
