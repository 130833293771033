import React from 'react';
import { Record } from 'immutable';
import { MIFormattedText, getFormattedAddress } from 'src/utils/formatting';
import {
  DeliveryMethodType,
  VendorType,
  BankType,
  CheckType,
  OptionalDeliveryMethodsType,
  AddressType,
} from 'src/utils/types';
import { CONSTS, VERIFIED_STATUS } from 'src/utils/consts';
import { addressDefaults } from 'src/records/settings.record';
import { getAccountNumber4digits } from 'src/utils/bank-account';

export const DeliveryMethodRecord = Record<DeliveryMethodType>(
  {
    id: '',
    logo: '',
    deliveryType: CONSTS.DELIVERY_TYPE.ACH,
    paperCheck: null,
    bankAccount: null,
    plaidAccount: null,
    hasScheduledPayments: false,
    isFilledByVendor: false,
    isVerified: false,
    verifiedStatus: VERIFIED_STATUS.NOT_VERIFIED,
    createdById: '',
    updatedAt: null,
    cardAccountId: null,
    intuitAccountId: null,
    virtualCardAccount: null,
    cardAccount: null,
    getDisplayName(vendorName = '') {
      const accountNumber4digits = getAccountNumber4digits(this?.bankAccount);
      if (this.deliveryType === CONSTS.DELIVERY_TYPE.ACH && accountNumber4digits) {
        return `${vendorName} (...${accountNumber4digits})`;
      }

      if (this.paperCheck) {
        const { addressLine1, addressLine2, city, state, zipCode, countryCode } = this.paperCheck;
        const address = `${addressLine1}${
          addressLine2 ? `, ${addressLine2}` : ''
        }, ${city}, ${state}, ${zipCode}, ${countryCode}`;
        return `${this.paperCheck.printName}  | ${address}`;
      }

      return '';
    },

    getDeliveryInfo(vendorName = '', isRPPSDeliveryMethod?: boolean) {
      if (isRPPSDeliveryMethod) {
        return <MIFormattedText label="deliveryMethods.rpps" />;
      }

      const accountNumber4digits = getAccountNumber4digits(this?.bankAccount);
      if (this.deliveryType === CONSTS.DELIVERY_TYPE.ACH && accountNumber4digits) {
        return `${vendorName} (...${accountNumber4digits})`;
      }

      if (this.paperCheck) {
        return getFormattedAddress(this.paperCheck);
      }

      return '';
    },
  },
  'DeliveryMethodRecord'
);

export const getDeliveryMethodByType = (type: OptionalDeliveryMethodsType, deliveryMethods: DeliveryMethodType[]) => {
  let deliveryMethod: DeliveryMethodType | undefined;
  if (deliveryMethods) {
    deliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === type);
  }

  if (!deliveryMethod) {
    return DeliveryMethodRecord({ id: '', deliveryType: type });
  }

  return DeliveryMethodRecord(deliveryMethod);
};

export const VendorRecord = Record<VendorType>(
  {
    id: '',
    origin: '',
    originId: '',
    companyName: '',
    accountIdentifier: '',
    address: { ...addressDefaults },
    deliveryMethods: [],
    updatedById: '',
    createdAt: undefined,
    createdById: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    paymentRequestId: '',
    ownedById: null,
    managedBy: null,
    isOwned: false,
    isEditable: false,
    handle: '',
    hasDeliveryMethods: false,
    vendorTrusted: false,
    getDeliveryMethodByType(type: OptionalDeliveryMethodsType) {
      return getDeliveryMethodByType(type, this.deliveryMethods);
    },
    getDeliveryMethodById(id: string) {
      return DeliveryMethodRecord(this.deliveryMethods.find((dm) => dm.id === id));
    },
  },
  'VendorRecord'
);

export const BankRecord = Record<BankType>(
  {
    accountType: CONSTS.BANK_ACCOUNT_TYPE.CHECKING,
    routingNumber: '',
    accountNumber: '',
    intuitAccountId: '',
    isBlocked: false,
    accountNumber4digits: '',
  },
  'BankRecord'
);

export const CheckRecord = Record<CheckType>(
  {
    ...addressDefaults,
    printName: '',
    isAddressSuggestionIgnored: null,
  },
  'CheckRecord'
);

export const AddressRecord = Record<AddressType>(
  {
    ...addressDefaults,
  },
  'AddressRecord'
);
