import React, { memo, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { generatePath, Redirect } from 'react-router-dom';
import analytics from 'src/services/analytics';
import { withNavigator } from 'src/hoc';
import locations from 'src/billpay/qbdt/pages/locations';
import { CONSTS } from 'src/utils/consts';
import MICategoryList from 'src/components/common/MICategoryList';
import billsApi from 'src/services/api/bills';
import { getOrgId } from 'src/redux/user/selectors';
import { getBill, getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { updateBillAction } from 'src/redux/payBillWizard/actions';
import { isBillAmountRequiresGoodsConfirmation, productReceivedOptions } from 'src/utils/bills';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';
import { QBDTStepLayout } from '../../components';

type Props = PayBillProps;

const eventPage = 'expedite-payment-page';

const Page = memo(({ onNext, goExit, onPrev }: Props) => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isUpdateBillProcessing, setIsUpdateBillProcessing] = useState(false);

  const onChange = (id: string) => {
    const answer = id === '0' ? 'no' : 'yes';
    analytics.track(eventPage, `change-product-received-answer-${answer}`);
    setSelectedId(id);
  };

  const onNextHandler = () => {
    analytics.track(eventPage, 'continue');
    const fileId = !isEmpty(bill.files) ? [bill.files[0].id] : [];
    setIsUpdateBillProcessing(true);
    const goodsReceived = selectedId ? !!+selectedId : false;
    dispatch(updateBillAction(goodsReceived));
    const newBill = {
      totalAmount: bill.totalAmount,
      balance: bill.balance,
      currency: bill.currency,
      invoiceDate: bill.invoiceDate,
      dueDate: bill.dueDate,
      invoiceNumber: bill.invoiceNumber,
      note: bill.note,
      vendorId: bill.vendorId,
      terms: bill.terms,
      intuitAccountId: bill.intuitAccountId,
      goodsReceived,
      files: fileId,
    };
    billsApi
      .editBillById(orgId, bill.id, newBill, 'all')
      .then(() => {
        setIsUpdateBillProcessing(false);
        onNext();
      })
      .catch(() => {
        setIsUpdateBillProcessing(false);
      });
  };

  const shouldRedirectToConfirmPage =
    !isNil(bill.goodsReceived) ||
    !isBillAmountRequiresGoodsConfirmation(payment.amount) ||
    (selectedFundingSource && selectedFundingSource.fundingType !== CONSTS.FUNDING_TYPE.CARD);

  useEffect(() => {
    if (!shouldRedirectToConfirmPage) {
      analytics.page('pay-bill', 'expedite-payment');
    }
  }, [shouldRedirectToConfirmPage]);

  if (shouldRedirectToConfirmPage) {
    return (
      <Redirect
        to={{
          pathname: generatePath(locations.pay.confirm, { billId: bill.id, orgId }),
        }}
      />
    );
  }

  return (
    <QBDTStepLayout
      title="bills.pay.expedite.title"
      subtitle="bills.pay.expedite.subtitle"
      goExit={goExit}
      onNext={onNextHandler}
      onPrev={onPrev}
      isLoading={isUpdateBillProcessing}
      isNextDisabled={isNil(selectedId)}
    >
      <MICategoryList selectedId={selectedId} categories={productReceivedOptions} onSelect={onChange} />
    </QBDTStepLayout>
  );
});

export const ExpeditePaymentPage = compose(withNavigator(), withPayBillData())(Page);
