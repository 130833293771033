import React from 'react';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';
import { getDeliveryMethodLabel } from 'src/billpay/qbdt/pages/batch-pay/components/deliveryMethod/utils';
import { CONSTS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryMethodType } from 'src/utils/types';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';

type Props = {
  deliveryMethod?: DeliveryMethodType;
};

export const SelectedDeliveryMethodLabel = ({ deliveryMethod }: Props) => {
  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();
  const isRPPSDeliveryMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const { label, values } = getDeliveryMethodLabel(deliveryMethod);

  const methodLabel = <MIFormattedText label={label} values={values} />;
  const tooltipLabel = isRPPSDeliveryMethod ? (
    <MIFormattedText label="getPro.tooltip.directPaymentWithMethod" values={{ method: methodLabel }} />
  ) : (
    methodLabel
  );

  return (
    <Tooltip isDisabled={!isRPPSDeliveryMethod || !isTruncated} placement="top" label={tooltipLabel}>
      <Flex align="center" data-testid={`delivery-method-label-${deliveryMethod?.id}`}>
        <Box as="span" isTruncated ref={ref}>
          <MIFormattedText label={label} values={values} />
        </Box>
      </Flex>
    </Tooltip>
  );
};
