import React from 'react';
import { VStack } from '@melio/billpay-design-system';
import { Title, PaymentsSettings } from './components';

const BulkHeader = () => (
  <VStack
    w="full"
    py="ds.2xl"
    px="ds.xl"
    boxSizing="border-box"
    bgColor="white"
    borderBottom="1px"
    borderBottomColor="ds.gray.500"
  >
    <Title />
    <PaymentsSettings />
  </VStack>
);

export { BulkHeader };
