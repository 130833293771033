import React from 'react';
import { Box, HStack, Switch, Tooltip, VStack } from '@melio/billpay-design-system';
import { useSelector } from 'react-redux';
import analytics from 'src/services/analytics';
import { MIFormattedText } from 'src/utils/formatting';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { pushNotification } from 'src/services/notifications';
import { NOTIFICATION_VARIANT } from 'src/utils/consts';
import useBatchBillsList from '../../../../hooks/useBatchBillsList';
import { useCombinedBillsToggleStatus } from './hooks/useCombinedBillsToggleStatus';
import { useBatchSettings } from '../../../../hooks/useBatchSettings';
import { WalkthroughPopover, WalkthroughPopoverProps } from './WalkthroughPopover';
import { WalkthroughStep } from './hooks/useWalkthroughState';

type Props = {
  getPopoverProps: (step: WalkthroughStep) => WalkthroughPopoverProps;
};

const CombineBills = ({ getPopoverProps }: Props) => {
  const { changeBatchSettings } = useBatchSettings();
  const isBillsGroupingPossible = useSelector(regularBatchPaymentsStore.selectors.isBillsGroupingPossible);

  const handleCombineBillsToggle = (event) => {
    const toggleEnabled = event.target.checked;

    changeBatchSettings({ isBillsGroupedByVendor: toggleEnabled }, { refreshBillsList: true });

    analytics.trackAction('regularBatchPayments.toggleBulk', {
      newToggleState: toggleEnabled ? 'on' : 'off',
    });

    if (toggleEnabled) {
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: 'regularBatchPayments.paymentsSettings.combineBills.toastCombined',
      });

      return;
    }

    pushNotification({
      type: NOTIFICATION_VARIANT.SUCCESS,
      msg: 'regularBatchPayments.paymentsSettings.combineBills.toastSeparate',
    });
  };

  const { isBillListLoading } = useBatchBillsList();

  const isSwitchOn = useCombinedBillsToggleStatus();
  const isSwitchDisabled = isBillListLoading || !isBillsGroupingPossible;

  return (
    <VStack spacing="ds.lg" align="flex-start" minH="6rem">
      <HStack spacing="ds.md" align="center">
        <Box textStyle="ds.body2" color="ds.gray.200">
          <MIFormattedText label="regularBatchPayments.paymentsSettings.combineBills.message" />
        </Box>
        <Tooltip
          placement="top"
          label={<MIFormattedText label="regularBatchPayments.paymentsSettings.combineBills.tooltip" />}
        >
          <Box as="i" className="icon-info-hollow-icon" fontSize="1.6rem" color="ds.gray.100" cursor="pointer" />
        </Tooltip>
      </HStack>
      <Tooltip
        placement="top"
        isDisabled={isBillsGroupingPossible}
        label={
          <MIFormattedText label="regularBatchPayments.paymentsSettings.combineBills.combinedUnavailableTooltip" />
        }
      >
        <HStack w="full" spacing="ds.md" align="center">
          <WalkthroughPopover {...getPopoverProps(WalkthroughStep.COMBINE_BILLS)}>
            <Switch
              data-testid="bulk-combined-switch"
              isDisabled={isSwitchDisabled}
              isChecked={isSwitchOn}
              onChange={handleCombineBillsToggle}
            />
          </WalkthroughPopover>
          <Box textStyle="ds.body2" color="ds.gray.100">
            <MIFormattedText label="regularBatchPayments.paymentsSettings.combineBills.switch" />
          </Box>
        </HStack>
      </Tooltip>
    </VStack>
  );
};

export { CombineBills };
