import React from 'react';
import { Flex, HStack } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';
import { NOTIFICATION_VARIANT } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import successNotificationIconSrc from './icons/success-notification-icon.svg';
import errorNotificationIconSrc from './icons/error-notification-icon.svg';

type Props = {
  type: NOTIFICATION_VARIANT;
  text: string;
  textValues?: Record<string, any>;
  icon?: string;
};

export const ToastMessage = ({ type, text, textValues, icon }: Props) => {
  const getIconElement = () => {
    if (icon) {
      return <i className={icon} />;
    }

    switch (type) {
      case NOTIFICATION_VARIANT.SUCCESS:
        return <img src={successNotificationIconSrc} alt="success notification icon" />;
      case NOTIFICATION_VARIANT.ERROR:
        return <img src={errorNotificationIconSrc} alt="error notification icon" />;
      default:
        return null;
    }
  };

  const iconElement = getIconElement();

  return (
    <ToastMessageContainer data-testid={`toast-${type}-${text}`}>
      <Content spacing="ds.md">
        {iconElement && <ToastIcon color={getBackgroundColor(type)}>{iconElement}</ToastIcon>}
        <ToastMessageText data-testid={`toast-message-${type}`}>
          <MIFormattedText label={text || ''} values={textValues} />
        </ToastMessageText>
        <Close>
          <i className="icon-close-icon" />
        </Close>
      </Content>
    </ToastMessageContainer>
  );
};

const getBackgroundColor = (type) => {
  switch (type) {
    case NOTIFICATION_VARIANT.SUCCESS:
      return 'ds.green.100';
    case NOTIFICATION_VARIANT.ERROR:
      return 'ds.red.200';
    default:
      return 'ds.gray.100';
  }
};

const ToastMessageContainer = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '47.8rem',
    minWidth: '22rem',
    height: '5.4rem',
    borderRadius: 'ds.lg',
    border: '1px',
    borderColor: 'ds.gray.500',
    backgroundColor: 'ds.white',
    fontFamily: "'Avenir Next forINTUIT', sans-serif;",
  },
});

const Content = chakra(HStack, {
  baseStyle: {
    flexGrow: '1',
    alignItems: 'center',
    padding: '1.8rem 1.6rem 1.6rem',
  },
});

const ToastMessageText = chakra('div', {
  baseStyle: {
    flexGrow: '1',
    marginRight: 'ds.2xl',
    textStyle: 'ds.body3',
    color: 'ds.gray.100',
  },
});

const ToastIcon = chakra('div', {
  baseStyle: {
    fontSize: '2rem',
    height: '2rem',
  },
});

const Close = chakra('div', {
  baseStyle: {
    fontSize: '2rem',
    cursor: 'pointer',
    color: 'ds.gray.200',
    height: '2rem',
  },
});
