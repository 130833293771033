import { takeEvery, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import analytics from '../../services/analytics';

function* onLocationChange() {
  analytics.trackRoute();
  yield;
}
function* navigationSagas() {
  yield all([takeEvery(LOCATION_CHANGE, onLocationChange)]);
}
const NavigationStore = {
  saga: navigationSagas,
};

export default NavigationStore;
