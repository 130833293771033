import curry from 'lodash/curry';
import { useState } from 'react';
import { FieldType } from 'src/utils/types';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import {
  microDepositsEventActions,
  MicroDepositsEventActionType,
  MicroDepositsEventPageType,
  microDepositsEventPages,
} from 'src/components/micro-deposits/consts';
import analytics from 'src/services/analytics';

export enum RESOLUTIONS {
  SUCCESS = 'success',
  SUPPORT = 'support',
  NOT_FOUND = 'not_found',
}

export type State = {
  amount1: string;
  amount2: string;
  resolution?: RESOLUTIONS;
  serverError?: string;
  validationErrors: {
    amount1?: string;
    amount2?: string;
  };
};

export type MicroDepositState = {
  isLoading?: boolean;
  companyName?: string;
} & State;

export type MicroDepositProps = {
  onSuccess?: () => void;
  onBlocked?: () => void;
  onInvalidToken?: () => void;
};

export type MicroDepositActions = {
  onChange: (FieldType) => void;
  onSubmit: () => Promise<void>;
  dismiss: () => void;
};

export const validateInputs = (state: State, setState: (state: State) => void) => {
  const { amount1, amount2 } = state;
  const cleanAmount1 = convertCurrencyToNumber(amount1);
  const cleanAmount2 = convertCurrencyToNumber(amount2);
  const validationErrors = getValidationErrors('microDepositsVerification', {
    amount1: cleanAmount1,
    amount2: cleanAmount2,
  });

  setState({ ...state, validationErrors, serverError: undefined });

  return [isValidationOk(validationErrors), validationErrors];
};

export const parseErrorToState = (
  error: any,
  errorToResolutionMap: Record<string, any>,
  errVerifyMicroDeposit: string
) => {
  const errorCode = error?.code || '';

  const resolution = errorToResolutionMap[errorCode];
  if (resolution) {
    return {
      resolution,
    };
  }

  let serverError = `server.${errorCode}`;
  if (errorCode === errVerifyMicroDeposit) {
    serverError = 'inputErrors.microDepositsVerification.invalidAmount';
  }

  return {
    serverError,
    validationErrors: {
      amount1: '',
      amount2: '',
    },
  };
};

export const dismiss = (setState: (state: State) => void) => {
  setState({
    amount1: '',
    amount2: '',
    validationErrors: {},
    resolution: undefined,
  });
};

export const onChange = curry((state: State, setState: (state: State) => void, { id, value }: FieldType) => {
  setState({
    ...state,
    [id]: value,
    validationErrors: {
      ...state.validationErrors,
      [id]: undefined,
    },
  });
});

export type ErrorsState = {
  amount1: string;
  amount2: string;
};

const initialErrorsState = {
  amount1: '',
  amount2: '',
};

type InputType = {
  id: number;
  value: string;
};

type ReturnErrorsType = [ErrorsState, (InputType) => void, () => void];

export const useMicroDepositAmountValidation = (onChange: (input: InputType) => void): ReturnErrorsType => {
  const [errors, setErrors] = useState(initialErrorsState);

  const handleChange = (input: InputType) => {
    const inputValue = +input.value.slice(1);
    const maxIntegerAmount = 1;

    if (inputValue >= maxIntegerAmount) {
      setErrors((prevState) => ({
        ...prevState,
        [input.id]: 'settings.microDeposits.verifyModal.invalidDeposit',
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [input.id]: initialErrorsState[input.id],
      }));
    }

    onChange(input);
  };

  const resetErrors = () => {
    setErrors(initialErrorsState);
  };

  return [errors, handleChange, resetErrors];
};

export type microDepositsEventParamsType = {
  fundingSourceID: number | string | null;
  partialBillId?: string;
  [k: string]: unknown;
};

export function getMicroDepositsEventPageByAction(
  eventPage: MicroDepositsEventPageType,
  eventAction: MicroDepositsEventActionType
) {
  const verifyManualActions: Array<MicroDepositsEventActionType> = [
    microDepositsEventActions.ACCOUNT_SUBMIT_CONTINUE,
    microDepositsEventActions.VERIFICATION_FAILED,
    microDepositsEventActions.VERIFICATION_SUCCESS,
    microDepositsEventActions.CLOSE_MODAL,
  ];

  let event: string = eventPage;

  if (verifyManualActions.includes(eventAction)) {
    event =
      eventPage === microDepositsEventPages.payBill.base
        ? microDepositsEventPages.payBill.manual
        : microDepositsEventPages.settings.manual;
  }

  return event;
}

export const trackMicroDepositsEvent = (
  eventPage: MicroDepositsEventPageType,
  eventAction: MicroDepositsEventActionType,
  params: microDepositsEventParamsType
) => {
  const event = getMicroDepositsEventPageByAction(eventPage, eventAction);

  analytics.track(event, eventAction, { ...params });
};
