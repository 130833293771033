import styled from 'styled-components';
import MINotification, { Notification } from 'src/components/common/MINotification';
import { MIFormattedText } from 'src/utils/formatting';
import React from 'react';

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  margin-top: 2rem;
  ${(props) => props.theme.text.fontType.hint};
`;

export const ErrorMessageIcon = styled.i`
  display: none;
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageIcon}
`;

const ErrorMessageText = styled.div`
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageText}
`;

const ErrorMessageNotification = styled.div`
  display: none;
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageNotification}
`;

const StyledMINotification = styled(MINotification)`
  width: 100%;
  box-shadow: 0 2px 10px #d4d7dc;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 2rem;
  padding: 1.8rem;

  ${Notification} {
    justify-content: flex-start;
  }
`;

export function MicroDepositsErrorMessage({ serverError }: { serverError: string }) {
  return (
    <ErrorMessage>
      <ErrorMessageText>
        <MIFormattedText label={serverError} />
      </ErrorMessageText>
      <ErrorMessageNotification>
        <StyledMINotification
          label={serverError}
          icon={<ErrorMessageIcon className="icon-warning-icon" size={2} />}
          type="error"
        />
      </ErrorMessageNotification>
    </ErrorMessage>
  );
}
