import React from 'react';
import { Box, Icon, Menu, Popover } from '@melio/billpay-design-system';
import { MultiSelect } from 'src/components/common/MultiSelect';
import { chakra } from 'src/theme/ds';
import { MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down.svg';
import { DashboardFilterItemNamesEnum } from '../consts';
import { FiltersChangeInfo, MenuFilterItem } from '../types';

type Props = {
  menuItems: MenuFilterItem[];
  onChange: (change: FiltersChangeInfo) => void;
  onMenuItemHover: (label: string) => void;
  hoveredMenuItemLabel?: string;
};

export const DashboardFilterMenuList = ({ menuItems, onChange, onMenuItemHover, hoveredMenuItemLabel }: Props) => (
  <Menu.MenuList maxW="13.8rem" zIndex="3">
    {menuItems.map(({ fieldName, label, options }) => {
      const isItemHovered = hoveredMenuItemLabel === label;
      return (
        <Box
          key={fieldName}
          position="relative"
          onMouseOverCapture={() => onMenuItemHover(label)}
          sx={removeOutlineFromPopoverContent}
        >
          <Menu.MenuItem
            fontWeight={options.some(({ value }) => value) ? '600' : 'intial'}
            backgroundColor={isItemHovered ? 'ds.gray.800' : 'initial'}
          >
            <MIFormattedText label={label} />
            <Icon
              as={ChevronDownIcon}
              w="2.4rem"
              h="2.4rem"
              color="ds.gray.200"
              position="absolute"
              right="1.33rem"
              transform="rotate(270deg)"
            />
          </Menu.MenuItem>
          <Popover
            isLazy
            isOpen={isItemHovered}
            placement="right-end"
            arrowStyles={popoverFixedPositionStyle}
            closeOnBlur={false}
          >
            {({ forceUpdate }) => (
              <>
                <Popover.PopoverTrigger>
                  <PopoverTriggerFiller />
                </Popover.PopoverTrigger>
                <Popover.PopoverContent>
                  <MultiSelect
                    searchable={fieldName === DashboardFilterItemNamesEnum.Vendors}
                    options={options}
                    onChange={(updatedOptions) => onChange({ fieldName, options: updatedOptions })}
                    onSearch={forceUpdate}
                  />
                </Popover.PopoverContent>
              </>
            )}
          </Popover>
        </Box>
      );
    })}
  </Menu.MenuList>
);

const PopoverTriggerFiller = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    right: '1rem',
    top: 0,
  },
});

const popoverFixedPositionStyle = {
  '.chakra-popover__popper': {
    '--popper-transform-origin': 'left top !important',
    transform: 'translate3d(13.8rem, 0, 0) !important',
  },
};

const removeOutlineFromPopoverContent = {
  '.chakra-popover__content:focus': {
    outline: 'none',
  },
};
