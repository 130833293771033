import React, { useEffect } from 'react';
import analytics from 'src/services/analytics';
import { InvalidLinkPage } from 'src/pages/vendor/components/InvalidLinkPage';
import { getJWTPayload } from 'src/helpers/jwt';

type Props = {
  token: string;
};

export const InvalidAddDeliveryMethodPage = ({ token }: Props) => {
  const { paymentId } = getJWTPayload(token);

  useEffect(() => {
    if (paymentId) {
      analytics.trackAction('invalid-link-page-loaded', {
        paymentId,
      });
    }
  }, [paymentId]);

  return <InvalidLinkPage token={token} />;
};
