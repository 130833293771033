import { useCallback, MouseEvent } from 'react';
import { Row } from 'react-table';
import analytics from 'src/services/analytics';
import { DataTableRowType, MainTableRowType } from '../types';

type UseExpandRow = {
  row?: Row<DataTableRowType>;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

export const useExpandRow = ({ row, onClick }: UseExpandRow) => {
  const vendorId = (row as Row<MainTableRowType>)?.original?.payment?.vendorId;
  const canExpand = row?.canExpand;
  const isExpanded = row?.isExpanded;
  const payBillFlowUUID = (row as Row<MainTableRowType>)?.original?.payBillFlowUUID;

  const toggleRowExpand = useCallback(
    (e) => {
      if (!canExpand) {
        return;
      }

      onClick?.(e);
      const newExpandState = isExpanded ? 'collapse' : 'expand';

      analytics.trackAction('bulk-grouping-click', {
        groupingState: newExpandState,
        vendorId,
        payBillFlowUUID,
      });
      // onClick prop was excluded by intend, cause it changes every render
      // however it doesn't affect expand functionality
    },
    [canExpand, isExpanded, vendorId, payBillFlowUUID]
  );

  return {
    toggleRowExpand,
  };
};
