import React, { useEffect, useMemo, useState } from 'react';
import { CONSTS } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import AreaLoader from 'src/components/common/AreaLoader';
import MIButton from 'src/components/common/MIButton';
import OutsideLayout from 'src/components/layout/OutsideLayout';
import { ContentWrapper, Footer, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import NewDeliveryMethodForm from 'src/pages/vendor/components/NewDeliveryMethodForm';
import { ModelView, useForm } from 'src/ui/form';
import { formatCheckPrintName } from 'src/utils/delivery-methods';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import paymentStore from 'src/modules/payments/payment-store';
import WhitePageAddressContainer from 'src/pages/vendor/components/WhitePageAddressContainer';
import { CheckType } from 'src/utils/types';
import { convertPaperCheck } from 'src/utils/address';
import isEmpty from 'lodash/isEmpty';
import { useEditCheckAddressFormState } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressFormState';
import { useEditCheckAddressStatus } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';

type Props = {
  token: string;
  id: string;
};

const CheckChangeAddressPage = (props: Props) => {
  const { token, id } = props;
  const { paymentId, payment, isPaymentLoading, organization } = useEditCheckAddressStatus({ token });
  const { filesUrls } = useStructuredSelectors(paymentStore.selectors.payment(paymentId));
  const { actions, deliveryMethodModel, isDeliveryMethodProcessing } = useEditCheckAddressFormState({ token, id });

  const { onSubmit } = actions;
  const [deliveryMethodMV, deliveryMethodMVActions] = useForm<{
    paperCheck: CheckType;
  }>(deliveryMethodModel, {
    submit: (value) => {
      const { ...rest } = value;
      value.paperCheck.printName = formatCheckPrintName(value.paperCheck.printName);
      return onSubmit({ ...rest }, false);
    },
  });

  const paperCheck = useMemo<CheckType>(() => convertPaperCheck(deliveryMethodMV.paperCheck as ModelView<CheckType>), [
    deliveryMethodMV.paperCheck,
  ]);
  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );
  const [whitePageAddressView, setWhitePageAddressView] = useState(false);

  useEffect(() => {
    const suggestAddress =
      whitePageAddress &&
      !isEmpty(whitePageAddress) &&
      ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid);
    setWhitePageAddressView(suggestAddress);
    if (suggestAddress)
      analytics.trackAction('suggest-alt-address', {
        flow: 'payment-schedule',
      });

    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit({ deliveryType: CONSTS.DELIVERY_TYPE.CHECK, paperCheck }, true);
    }
  }, [whitePageAddress]);

  if (isPaymentLoading) return <AreaLoader />;

  if (whitePageAddressView) {
    return (
      <WhitePageAddressContainer
        setWhitePageAddressView={setWhitePageAddressView}
        isLoading={isDeliveryMethodProcessing || isAddressLoading}
        whitePageAddress={whitePageAddress}
        submit={onSubmit}
        model={deliveryMethodMV.paperCheck}
      />
    );
  }

  return (
    <OutsideLayout>
      <Header payment={payment} organization={organization} filesUrls={filesUrls} />
      <ContentWrapper title="vendors.editCheckAddress.checkNotSent.form.title">
        <NewDeliveryMethodForm
          submit={deliveryMethodMVActions.submit}
          selectedDeliveryMethod={CONSTS.DELIVERY_TYPE.CHECK}
          checkModel={deliveryMethodMV.paperCheck}
        />
        <MIButton
          label="vendors.editCheckAddress.checkNotSent.form.saveCTA"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={deliveryMethodMVActions.submit}
          isProcessing={isDeliveryMethodProcessing || isAddressLoading}
          fullWidth
        />
        <Footer companyName={organization?.companyName} />
      </ContentWrapper>
    </OutsideLayout>
  );
};

export default CheckChangeAddressPage;
