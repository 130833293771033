import React from 'react';
import { Menu, Box, Flex } from '@melio/billpay-design-system';
import { DeliveryMethodType } from 'src/utils/types';
import { MIFormattedText } from 'src/utils/formatting';
import { getDeliveryMethodIcon } from 'src/utils/bill';
import { getDeliveryMethodDescription } from './utils';

type Props = {
  deliveryMethod: DeliveryMethodType;
  onClick?: (method: DeliveryMethodType) => void;
  isSelected?: boolean;
};

export const DeliveryMethodMenuItem = ({ deliveryMethod, onClick, isSelected }: Props) => {
  const iconClass = getDeliveryMethodIcon(deliveryMethod);
  const { label, values } = getDeliveryMethodDescription(deliveryMethod);

  const handleClick = () => {
    if (onClick) {
      onClick(deliveryMethod);
    }
  };

  return (
    <Menu.MenuItem
      onClick={handleClick}
      bg={isSelected ? '#EEEEEE' : 'ds.white'}
      data-testid={`delivery-method-menu-item-${deliveryMethod.id}`}
    >
      <Flex align="center" data-testid={`delivery-method-label-${deliveryMethod?.id}`}>
        <Box as="i" fontSize="2rem" mr="ds.lg">
          <i className={iconClass} />
        </Box>
        <Box as="span" isTruncated>
          <MIFormattedText label={label} values={values} />
        </Box>
      </Flex>
    </Menu.MenuItem>
  );
};
