import * as React from 'react';
import { Flex, Box, Text, Icon, Link } from '@melio/billpay-design-system';
import { SystemProps } from '@chakra-ui/react';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { ReactComponent as QBOLogoIcon } from 'src/images/qbo/logo-qbo-footer-with-jpm-no-svb.svg';
import { MIFormattedText } from 'src/utils/formatting';
import { useBreak } from 'src/hoc';

export const Footer = () => {
  const site = useSiteContext();
  const { isDesktop } = useBreak();

  const containerProps = {
    py: 'ds.lg',
    width: '100%',
    gap: 'ds.xl',
    justify: 'center',
    align: 'center',
    mt: 'auto',
    bg: 'ds.gray.600',
    ...(!isDesktop && {
      bg: 'ds.gray.700',
      borderTop: '0.1rem solid',
      borderTopColor: 'ds.gray.600',
      direction: 'column' as SystemProps['flexDirection'],
      gap: 'ds.md',
      height: 'auto',
      p: 'ds.xl',
    }),
  };
  return (
    <Flex boxSizing="border-box" {...containerProps}>
      <Box>
        <Icon as={QBOLogoIcon} h="ds.xl" w="100%" />
      </Box>
      <Text textStyle="ds.body4" m="0" color="ds.gray.300">
        <MIFormattedText label="qbo.footer.poweredBy" />
      </Text>
      <Flex gap="1.6rem">
        <Link
          href={site.config.agreementLinks.termsOfService}
          target="_blank"
          rel="noopener noreferrer"
          textStyle="ds.body3"
        >
          <MIFormattedText label="qbo.footer.termsOfService" />
        </Link>
        <Link
          href={site.config.agreementLinks.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          textStyle="ds.body3"
        >
          <MIFormattedText label="qbo.footer.privacyPolicy" />
        </Link>
      </Flex>
    </Flex>
  );
};
