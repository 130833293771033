import React, { useEffect, useState } from 'react';
import { Flex, Text, Icon } from '@melio/billpay-design-system';
import { RecordOf } from 'immutable';
import { generatePath, useHistory } from 'react-router-dom';
import useGetValidFundingSources from 'src/billpay/qbdt/pages/batch-pay/table/hooks/useGetValidFundingSources';
import { useOrgId } from 'src/billpay/qbdt/hooks/useOrgId';
import { useApi } from 'src/hoc/useApi';
import {
  billingFeeApi,
  CreateOrganizationBillingFee,
  OrganizationBillingFee,
  UpdateBillingFeeResponse,
  UpdateOrganizationBillingFeeParams,
} from 'src/services/api/smb-billing-fee';
import { getBillingMethod } from 'src/billpay/qbdt/pages/settings/components/billing/utils';
import settingsLocation from 'src/billpay/qbdt/pages/settings/locations';
import { BillingFeeTypeEnum } from 'src/billpay/qbdt/services/qbdt/types';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { AccountType } from 'src/utils/types';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN, CONSTS } from 'src/utils/consts';
import locations from 'src/billpay/qbdt/pages/locations';
import { MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as LockIcon } from 'src/images/icons/lock-icon-new.svg';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import { useLocationState } from 'src/utils/hooks';
import { VerifyMicroDeposits } from 'src/billpay/qbdt/components/VerifyMicroDeposits';
import { useMicroDepositParams } from 'src/billpay/qbdt/pages/funding-source/bank/hooks/useMicroDepositParams';
import { billingFeeAnalytics } from 'src/billpay/qbdt/pages/settings/components/billing/events';
import { getFundingSourceType } from 'src/utils/funding-sources';
import { billingFee } from '../settings/components/billing/locations';
import { InitialAddFundingSourceLocationState } from '../funding-source/types';

const MICRO_DEPOSIT_EVENT_PAGE = 'billing-verify-manual-account';

export const SelectBillingMethodPage = () => {
  const orgId = useOrgId();
  const history = useHistory();
  const [fundingSources] = useGetValidFundingSources();
  const [newFundingSourceId] = useLocationState<number>('newFundingSourceId');
  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<number | undefined>();
  const [getOrganizationBillingFees, result, isFetchingOrgBillingFee] = useApi<
    [{ orgId: number }],
    { organizationBillingFees: OrganizationBillingFee[] }
  >(billingFeeApi.getBillingFees, true, true);

  const organizationBillingFees = result?.organizationBillingFees;

  const [createOrganizationBillingFee, , isCreatingBillingFee] = useApi<
    [{ orgId: number; body: CreateOrganizationBillingFee }],
    OrganizationBillingFee
  >(billingFeeApi.createBillingFee);

  const [updateOrganizationBillingFee, , isUpdatingBillingFee] = useApi<
    [
      {
        orgId: number;
        billingFeeId: number;
        billingFee: UpdateOrganizationBillingFeeParams;
      }
    ],
    UpdateBillingFeeResponse
  >(billingFeeApi.updateBillingFee);

  const currentBillingMethod = getBillingMethod(organizationBillingFees);
  const currentFundingSourceId = currentBillingMethod?.fundingSourceId;
  const { verifyingId, setVerifyingId, state, actions } = useMicroDepositParams(MICRO_DEPOSIT_EVENT_PAGE);

  const selectedFundingSource = fundingSources.find(({ id }) => id === selectedFundingSourceId);

  const isNextStepDisabled = !(selectedFundingSourceId && currentFundingSourceId !== selectedFundingSourceId);

  const handleVerifyFundingSourceClick = (id: number) => setVerifyingId(id);

  const handleCancelClick = () => {
    history.push(generatePath(settingsLocation.billing, { orgId }));
  };

  const handleVerifyFinished = () => {
    setVerifyingId(null);
  };

  const handleChangeFundingSource = (fundingSource: RecordOf<AccountType>) => {
    setSelectedFundingSourceId(fundingSource.id);
  };

  const handleAddSelectedFundingSource = (selectedFundingSourceType) => {
    const fundingSourcesTypesMap = {
      [CONSTS.FUNDING_TYPE.ACH]: {
        url: locations.fundingSource.bankSelect,
        paymentMethodType: 'ach',
      },
      [CONSTS.CARD_TYPES.CREDIT]: {
        url: locations.fundingSource.card,
        paymentMethodType: 'credit',
      },
      [CONSTS.CARD_TYPES.DEBIT]: {
        url: locations.fundingSource.card,
        paymentMethodType: 'debit',
      },
    };

    const { paymentMethodType, url } = fundingSourcesTypesMap[selectedFundingSourceType];

    if (paymentMethodType && url) {
      billingFeeAnalytics.addNewPaymentMethod({
        paymentMethodType,
      });
      const exitUrl = billingFee.add;
      const locationState: InitialAddFundingSourceLocationState = {
        exitUrl,
        redirectUrl: exitUrl,
        preservedState: {
          origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BILLING,
        },
      };
      history.push(generatePath(url, { orgId }), locationState);
    }
  };

  const handleUpdateBillingFee = async (billingFeeId: number) => {
    if (selectedFundingSourceId && selectedFundingSourceId !== currentFundingSourceId) {
      const params = {
        orgId,
        billingFeeId,
        billingFee: {
          fundingSourceId: selectedFundingSourceId,
        },
      };

      await updateOrganizationBillingFee(params);
      history.push(generatePath(billingFee.success, { orgId }), {
        fundingSourceId: selectedFundingSourceId,
        isMethodUpdated: true,
      });
    }
  };

  const handleCreateNewBillingFee = async () => {
    if (selectedFundingSourceId && selectedFundingSourceId !== currentFundingSourceId) {
      const billingFeeBody = {
        fundingSourceId: selectedFundingSourceId,
        isActive: true,
        billingFeeType: BillingFeeTypeEnum.AchToCheck,
        managedByOrganizationId: null,
      };
      await createOrganizationBillingFee({ orgId, body: billingFeeBody });
      history.push(generatePath(billingFee.success, { orgId }), {
        fundingSourceId: selectedFundingSourceId,
      });
    }
  };

  const handleNext = async () => {
    if (selectedFundingSourceId) {
      billingFeeAnalytics.paymentMethodContinue({
        paymentMethodId: selectedFundingSourceId,
        paymentMethodType: getFundingSourceType(selectedFundingSource),
      });
    }

    if (currentBillingMethod) {
      await handleUpdateBillingFee(currentBillingMethod.id);
      return;
    }

    await handleCreateNewBillingFee();
  };

  useEffect(() => {
    billingFeeAnalytics.visitAddMethodPage();

    const loadData = async () => {
      const result = await getOrganizationBillingFees({ orgId });
      const currentFundingSourceId = getBillingMethod(result?.organizationBillingFees)?.fundingSourceId;
      if (newFundingSourceId) {
        setSelectedFundingSourceId(newFundingSourceId);
        return;
      }

      if (currentFundingSourceId) {
        setSelectedFundingSourceId(currentFundingSourceId);
      }
    };

    loadData();
  }, [newFundingSourceId, orgId, getOrganizationBillingFees]);

  if (isFetchingOrgBillingFee) {
    return <QBDTLoader />;
  }

  return (
    <QBDTStepLayout
      goExit={handleCancelClick}
      title="billingFee.addMethod.title"
      subtitle={currentBillingMethod ? 'billingFee.addMethod.methodChangingSubtitle' : undefined}
      nextLabel="progress.continue"
      onNext={handleNext}
      isNextDisabled={isNextStepDisabled}
      isLoading={isCreatingBillingFee || isUpdatingBillingFee}
    >
      {verifyingId && (
        <VerifyMicroDeposits
          {...state}
          {...actions}
          key={verifyingId}
          verifyingId={verifyingId}
          onVerifyFinished={handleVerifyFinished}
          eventPage={MICRO_DEPOSIT_EVENT_PAGE}
        />
      )}
      <FundingSourcesListLayout
        value={selectedFundingSource as RecordOf<AccountType>}
        fundingSources={fundingSources as RecordOf<AccountType>[]}
        onChange={handleChangeFundingSource}
        onAddMethod={handleAddSelectedFundingSource}
        onVerifyClicked={handleVerifyFundingSourceClick}
        isBillingFee
      />
      <Flex>
        <Flex mr="1rem">
          <Icon as={LockIcon} w="2.4rem" h="2.4rem" fill="ds.gray.200" />
        </Flex>
        <Text textStyle="ds.body3" color="ds.gray.100" my="0">
          <MIFormattedText label="guests.register.securityText" />
        </Text>
      </Flex>
    </QBDTStepLayout>
  );
};
