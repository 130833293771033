import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId, getFundingSources, getIsFundingSourcesLoaded } from 'src/redux/user/selectors';
import { getOrganizationPreferences } from 'src/redux/organization/selectors';
import { close, syncAll, setCanClose } from 'src/billpay/qbdt/services/qbdt';
import SuccessIllustration from 'src/images/qbo/success-check.svg';
import { ProgressIndicatorPage } from 'src/billpay/qbdt/pages/entry/ProgressIndicationPage';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import { ErrorPage } from 'src/billpay/qbdt/pages/ErrorPage';
import analytics from 'src/services/analytics';
import { AccountType } from 'src/utils/types';
import { generatePath, useHistory } from 'react-router-dom';
import locations from 'src/billpay/qbdt/pages/locations';

enum SyncStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}
type SyncState = {
  status: SyncStatus;
  error?: string;
};
const title = 'entry.sync.header';
const messages = ['entry.sync.messages.takeUp'];

const useSync = (orgId: string): [number, SyncStatus, string | undefined] => {
  const [{ status, error }, setState] = useState<SyncState>({
    status: SyncStatus.PENDING,
  });
  const [progress, setProgress] = useState(0.4);
  const userData: AccountType[] = useSelector(getFundingSources);
  const isUserDataLoaded = useSelector(getIsFundingSourcesLoaded);
  const organizationPreferences = useSelector(getOrganizationPreferences);
  const history = useHistory();
  const sp = (v) => {
    setProgress(v);
  };

  useEffect(() => {
    if (!isUserDataLoaded) return;

    if (!userData.length) {
      history.replace(generatePath(locations.sync.noData, { orgId }));
      return;
    }

    const sync = async () => {
      if (orgId) {
        let errors: string[] = [];
        try {
          await setCanClose(false);
          errors = await syncAll({ orgId, setProgress: sp, organizationPreferences });
          await setCanClose(true);
          setProgress(1);
        } catch (error: any) {
          await setCanClose(true);
          errors.push(`Unexpected error during sync: ${error.message}`);
        }

        if (errors.length > 0) {
          setState({ status: SyncStatus.ERROR, error: errors.join('\n \n') });
        } else {
          analytics.trackAction('sync-success');
          setState({ status: SyncStatus.SUCCESS });
        }
      }
    };

    if (orgId) {
      sync();
    }
  }, [orgId, isUserDataLoaded]);

  return [progress, status, error];
};

export function QBDTSyncLoader() {
  const orgId = useSelector(getOrgId);

  const [progress, status, error] = useSync(orgId);

  if (status === SyncStatus.PENDING) {
    return <ProgressIndicatorPage title={title} messages={messages} progress={progress} />;
  } else if (status === SyncStatus.SUCCESS) {
    return (
      <QBDTSuccessLayout
        illustration={SuccessIllustration}
        title="entry.sync.success"
        text="entry.sync.successSubtitle"
        buttonLabel="dialogs.buttons.close"
        buttonAction={close}
        hideHeader
      />
    );
  }

  return <ErrorPage title="entry.sync.error" subtitle="entry.sync.errorSubtitle" errors={error} />;
}
