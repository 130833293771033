import React from 'react';
import styled from 'styled-components';
import { devices } from 'src/theme/AppDevices';
import { MIFormattedText } from 'src/utils/formatting';
import { useEditCheckAddressStatus } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import AreaLoader from 'src/components/common/AreaLoader';
import OutsideLayout from 'src/components/layout/OutsideLayout';
import errorImage from 'src/images/general/error-icon.svg';
import { useSiteContext } from 'src/hoc/withSiteContext';

type Props = {
  token: string;
};

const CheckBlockedPage = ({ token }: Props) => {
  const { isPaymentLoading, organization } = useEditCheckAddressStatus({
    token,
  });
  const site = useSiteContext();

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout>
      <BillPaymentBlock>
        <BillInfoContainer>
          <ErrorIcon src={errorImage} />
          <Description>
            <MIFormattedText
              label="vendors.editCheckAddress.checkBlocked.description"
              values={{
                companyName: organization?.companyName,
                companyEmail: organization?.userOrganizations?.[0]?.user?.email,
                supportEmail: site.config.support.email,
              }}
            />
          </Description>
        </BillInfoContainer>
      </BillPaymentBlock>
    </OutsideLayout>
  );
};

export default CheckBlockedPage;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ErrorIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: 2rem;
`;

const Description = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;
