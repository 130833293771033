import React from 'react';
import { Flex, Table } from '@melio/billpay-design-system';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';

const LoadingTableCaption: React.FC = () => (
  <Table.Caption bgColor="white" mt={0}>
    <Flex w="full" h="27.5rem" position="relative">
      <QBDTLoader context="button" />
    </Flex>
  </Table.Caption>
);

export { LoadingTableCaption };
