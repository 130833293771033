import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath, useLocation } from 'react-router-dom';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';
import analytics from 'src/services/analytics';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';

const useScheduleBatchPaymentsAction = (selectedBillsIds: string[]) => {
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const location = useLocation();

  const batchActions = useStoreActions(regularBatchPaymentsStore);

  const goScheduleBatchPayments = useCallback(() => {
    const exitUrl = `${location.pathname}${location.search}`;

    batchActions.settings.update({
      billIds: selectedBillsIds,
      refreshRequired: true,
      hasScheduledPaymentsForBills: false, // resetValue
      billsExceedAmountLimitCount: 0, // resetValue,
      exitUrl,
      isBillsGroupedByVendor: true,
    });

    const batchFlowUrl = generatePath(locations.batchPay.batch, {
      orgId,
    });

    analytics.trackAction('batch-actions', {
      action: 'schedule-batch',
      billIds: selectedBillsIds,
      partialPaymentsCount: 0, // TODO: change with actual value when partial payments will be introduced
    });

    history.push(batchFlowUrl, {
      exitUrl,
    });
  }, [location.pathname, location.search, batchActions.settings, selectedBillsIds, orgId, history]);

  return [goScheduleBatchPayments];
};

export default useScheduleBatchPaymentsAction;
