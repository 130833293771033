import React from 'react';
import { Route, Switch } from 'react-router-dom';

import locations from '../locations';
import { VendorsListPage } from './VendorsListPage';
import { ViewVendorPage } from './ViewVendorPage';

export const VendorSettingsRouter = () => (
  <Switch>
    <Route path={locations.vendorsView} exact>
      <ViewVendorPage />
    </Route>
    <Route>
      <VendorsListPage />
    </Route>
  </Switch>
);
