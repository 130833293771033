import React from 'react';
import MIButton from 'src/components/common/MIButton';
import { isPaymentDeletable } from 'src/utils/payments';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { ActionButtonsWrapper } from './ActionButtonsWrapper';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';
import { useCancelPayment } from '../hooks/useCancelPayment';

export const NotRetryableFailedPaymentActions = () => {
  const { payment } = useViewPaymentContext();
  const { openPaymentCancelModal, cancelPaymentModal } = useCancelPayment();
  const isDeletable = isPaymentDeletable(payment);

  if (!isDeletable) {
    return null;
  }

  return (
    <>
      {cancelPaymentModal}
      <ActionButtonsWrapper align="center">
        <MIButton
          label="bills.form.paymentActivity.actions.cancel"
          testId="view-payment-cancel-payment-action"
          variant={BUTTON_VARIANT.DESTRUCTIVE}
          onClick={openPaymentCancelModal}
        />
      </ActionButtonsWrapper>
    </>
  );
};
