import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { devices } from 'src/theme/AppDevices';
import { getBill } from 'src/redux/payBillWizard/selectors';
import analytics from 'src/services/analytics';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { ModalContentContainer } from 'src/components/common/ModalMessage/ModalMessage.styles';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';

type Props = {
  onClose: () => void;
  selectedFundingSource: any;
  onNext?: () => void;
  updateUserModalVisibility: () => Promise<any>;
};

const Modal = ({ onClose, selectedFundingSource, onNext, updateUserModalVisibility }: Props) => {
  const bill = useSelector(getBill);
  const [loading, setLoading] = useState(false);

  const analyticsProps = {
    fundingSourceId: selectedFundingSource?.id,
    billId: bill?.id,
  };

  useEffect(() => {
    analytics.track('pay-bill', 'DebitCardFeeModal-Shown', analyticsProps);
  }, []);

  const onApprove = async () => {
    try {
      setLoading(true);
      await updateUserModalVisibility();
    } catch (er) {
      // error
      setLoading(false);
    }

    analytics.track('pay-bill', 'DebitCardFeeModal-Closed', analyticsProps);

    onClose();
    setTimeout(onNext);
  };

  const commonClose = () => {
    analytics.track('pay-bill', 'PaymentMethodOnDebitCardFeeModal-Changed', analyticsProps);

    updateUserModalVisibility();
    onClose();
  };

  const onDecline = () => {
    commonClose();
  };

  const onGeneralClose = () => {
    commonClose();
  };

  return (
    <StyledModal
      alignLeft
      titleComponent={<MIFormattedText label="debitFee.modal.title" />}
      contentSubTitle={<MIFormattedText label="debitFee.modal.description" />}
      buttonComponent={
        <ButtonsContainer>
          <StyledButton label="debitFee.modal.decline" variant={CONSTS.BUTTON_VARIANT.NAKED} onClick={onDecline} />
          <StyledButton
            isProcessing={loading}
            label="debitFee.modal.confirm"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={onApprove}
          />
        </ButtonsContainer>
      }
      onCloseClick={onGeneralClose}
    />
  );
};

export { Modal };

const StyledModal = styled(ModalMessage)`
  ${ModalContentContainer} {
    padding: 4rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
    gap: 1.6rem;
  }
`;

const StyledButton = styled(MIButton)`
  @media ${devices.mobile} {
    height: 4.8rem;
  }
`;
