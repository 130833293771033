const base = '/vendors/suvc-to-ach/:token';

const locations = {
  base: [base],
  index: base,
  newBankAccount: `${base}/new-bank-account`,
  currentBankAccount: `${base}/current-bank-account`,
  success: `${base}/success`,
  invalidToken: `${base}/invalid-token`,
  paymentCanceled: `${base}/canceled`,
  cardProcessed: `${base}/card-processed`,
};

export default locations;
