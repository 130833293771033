import * as React from 'react';
import { logger } from 'src/services/loggers';
import ErrorPage from './components/ErrorPage';
import errorTracker from '../../utils/error-tracking';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ hasError: true });
    logger.error(error?.message, error, errorInfo);
    errorTracker.capture(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
