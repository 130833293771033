import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, HStack, Icon, Text, VStack } from '@melio/billpay-design-system';
import { Footer, VendorFlowContentCard, VendorFlowLayout } from 'src/pages/vendor/components/VendorFlowLayout';
import { ReactComponent as SuccessIllustration } from 'src/images/general/action-done-icon.svg';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as BankAccountIllustration } from 'src/images/general/bank-account-icon.svg';
import { ReactComponent as CalendarIllustration } from 'src/images/get-pro/calendar.svg';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import paymentsStore from 'src/modules/payments/payment-store';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  paymentId?: string;
};

export const ShiftVirtualCardToACHSuccessPage = ({ paymentId }: Props) => {
  const [achBankAccountDigits] = useLocationState('digits', null);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const achDeliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));
  const { deliveryEta, amount, organization } = payment || {};
  const companyName = organization?.companyName || '';
  const digits = achBankAccountDigits || getAccountNumber4digits(achDeliveryMethod?.bankAccount);

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard>
        <Flex direction="column" justify="center" align="start" width="100%">
          <Icon as={SuccessIllustration} w="ds.3xl" h="ds.3xl" mb="ds.xl" />
          <ContentTitle label="vendor.unilateral.shiftVirtualCardToAch.successPage.title" />
          <Text textStyle="ds.body2" mb="0" mt="ds.md" color="ds.gray.100">
            <MIFormattedText
              label="vendor.unilateral.shiftVirtualCardToAch.successPage.text"
              values={{
                amount: <MIFormattedCurrency value={amount || 0} />,
                companyName,
              }}
            />
          </Text>
        </Flex>
        <VStack gap="ds.xl" mt="ds.2xl" w="100%" align="start">
          <Text color="ds.gray.200" textStyle="ds.body2Medium" m="0">
            <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.title" />
          </Text>
          <HStack gap="ds.lg">
            <Icon as={BankAccountIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.shiftVirtualCardToAch.successPage.bankTransfer" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedText
                  label="regularBatchPayments.deliveryMethodLabels.bankDescription"
                  values={{
                    digits,
                  }}
                />
              </Text>
            </Flex>
          </HStack>
          <HStack gap="ds.lg">
            <Icon as={CalendarIllustration} w="3rem" h="auto" alignSelf="start" />
            <Flex direction="column" align="start">
              <Text color="ds.gray.300" textStyle="ds.body2Medium" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.deliveryETA" />
              </Text>
              <Text color="ds.gray.100" textStyle="ds.body1Medium" m="0">
                <MIFormattedDate date={deliveryEta} />
              </Text>
              <Text color="ds.gray.300" textStyle="ds.body3" m="0">
                <MIFormattedText label="vendor.unilateral.virtualCardSuccess.vendorReceive.deliveryTimeHelperText" />
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
