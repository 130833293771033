import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';

type Props = {
  billsAmount: number;
};

export const NotificationCardLimitBills = ({ billsAmount }: Props) => {
  const paymentLimit = CONSTS.BATCH_MAX_LIMIT;

  return (
    <Box mt="ds.xl" data-testid="batchPayment.notifications.warning.limit">
      <QBONotificationCard
        type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
        title={{
          label: 'regularBatchPayments.notifications.limitBillsTitle',
          values: { paymentLimit },
        }}
        subtitle={{
          label: 'regularBatchPayments.notifications.limitBillsSubTitle',
          values: { paymentLimit, originalNumOfBills: billsAmount },
        }}
      />
    </Box>
  );
};
