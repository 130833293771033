import { mapGroupedPayloadToBatchBill } from 'src/modules/regular-batch-payments/utils';
import regularBatchPaymentsApi from 'src/modules/regular-batch-payments/api/regularBatchPayments';
import { createListSlice } from 'src/helpers/redux/restListSlice';
import { name, batchPaymentsListName } from './consts';
import { GroupedBatchListItemResponseType } from './types/api-types';
import { BatchItemType } from './types/store-types';

export const fetchBatchBillsListSlice = createListSlice<GroupedBatchListItemResponseType, BatchItemType>({
  storeName: name,
  api: (params) => regularBatchPaymentsApi.groupedList(params),
  actionName: 'batchBillsList',
  listHashFunc: () => batchPaymentsListName,
  resultMapFunction: (state, currItem, newItem): BatchItemType => mapGroupedPayloadToBatchBill(newItem, state.settings),
});
