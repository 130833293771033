import React from 'react';
import { CONSTS } from 'src/utils/consts';
import { OptionalDeliveryMethodsType } from 'src/utils/types';
import NewAchDeliveryMethodForm from './NewAchDeliveryMethodForm';
import NewCheckOrCardDeliveryMethodForm from './NewCheckOrCardDeliveryMethodForm';

type Props = {
  submit: any;
  selectedDeliveryMethod?: OptionalDeliveryMethodsType;
  achModel?: any;
  checkModel?: any;
  cardModel?: any;
};

const NewDeliveryMethodForm = ({ submit, selectedDeliveryMethod, achModel, checkModel, cardModel }: Props) => {
  const printNameLabel = cardModel
    ? 'vendors.deliveryMethods.shiftToDebit.printName'
    : 'vendors.addDeliveryMethodByLink.printName';
  return (
    <>
      {selectedDeliveryMethod === CONSTS.DELIVERY_TYPE.ACH ? (
        <NewAchDeliveryMethodForm submit={submit} achModel={achModel} />
      ) : (
        <NewCheckOrCardDeliveryMethodForm
          submit={submit}
          model={cardModel || checkModel}
          printNameLabel={printNameLabel}
        />
      )}
    </>
  );
};

export default NewDeliveryMethodForm;
