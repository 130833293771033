import * as React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/utils/formatting';
import analytics from 'src/services/analytics';

type Props = {
  label: string;
  description: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  id?: string | null;
  testId?: string | null;
  imgSource?: string;
};

const SelectItemCard = ({ label, description, id, testId, onClick, imgSource }: Props) => (
  <Card
    id={id as string}
    data-testid={testId}
    onClick={(event) => {
      analytics.trackAction(label);
      onClick(event);
    }}
  >
    {imgSource && <Image src={imgSource} />}
    <CardInfoContainer>
      <CardInfoGroup>
        <CardLabelText>{label && <MIFormattedText label={label} />}</CardLabelText>
        {description && (
          <DescriptionText>
            <MIFormattedText label={description} />
          </DescriptionText>
        )}
      </CardInfoGroup>
    </CardInfoContainer>
    <IconContainer>
      <Icon className="icon-next-icon-2" />
    </IconContainer>
  </Card>
);

export default SelectItemCard;

const Card = styled.div`
  display: flex;
  box-shadow: ${(props) => `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}`};
  padding: 2.4rem 2rem 3rem 2.4rem;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin: 0 0 2rem 0;
  width: 100%;
  max-width: 45rem;
  border-radius: 0.8rem;
  transition: ${(props) => props.theme.animation.transition.default};
  transition-property: box-shadow;

  &:hover:not(:active) {
    ${(props) => css`
      box-shadow: 0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2};
    `}
  }

  ${(props) => props.theme?.pages?.SelectBankAccountVerificationTypePage?.Card}
`;

const CardInfoContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CardInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardLabelText = styled.div`
  line-height: 2.2rem;
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.2rem;

  ${(props) => props.theme?.pages?.SelectBankAccountVerificationTypePage?.CardLabelText}
`;

const DescriptionText = styled.div<{ addAnotherMethodType?: boolean }>`
  color: ${(props) => props.theme.text.color.subtitle};
  font-weight: ${(props) =>
    props.addAnotherMethodType ? props.theme.text.weight.semiBold : props.theme.text.weight.regular};
  text-overflow: ellipsis;
  text-align: left;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.pages?.SelectBankAccountVerificationTypePage?.DescriptionText}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  ${(props) => props.theme?.pages?.SelectBankAccountVerificationTypePage?.IconContainer}
`;

const Icon = styled.i`
  display: block;
  width: 2.4rem;
  font-size: 2.4rem;
  margin-right: 0;
  color: ${(props) => props.theme.text.color.main};
  align-self: center;
`;

const Image = styled.img`
  width: 4.4rem;
  margin-right: 2rem;
  ${(props) => props.theme?.pages?.SelectBankAccountVerificationTypePage?.Image}
`;
