import { createContext, useContext } from 'react';
import { AmexModalVerificationData } from 'src/billpay/qbdt/hooks/useAmexVerification';

type AmexVerificationContextValue = {
  openAmexModal: ({ billIds, vendorId, vendorName }: AmexModalVerificationData) => void;
};

const defaultContextValue: AmexVerificationContextValue = {
  openAmexModal: () => null,
};

const AmexVerificationContext = createContext<AmexVerificationContextValue>(defaultContextValue);

const AmexVerificationContextProvider = AmexVerificationContext.Provider;

const useAmexVerificationContext = () => {
  const contextValue = useContext(AmexVerificationContext);

  return contextValue;
};

export { useAmexVerificationContext, AmexVerificationContext, AmexVerificationContextProvider };
