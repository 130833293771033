import { useEffect } from 'react';
import { convertDateToMonthYearFormat, isCardExpired } from 'src/utils/card';
import { AccountType } from 'src/utils/types';
import { NOTIFICATION_VARIANT } from 'src/utils/consts';
import { pushNotification, dismissNotification } from 'src/services/notifications/notificationService';

type Props = {
  fundingSource?: AccountType | null;
  scheduledDate: Date;
};

const useCardExpiredNotification = ({ fundingSource, scheduledDate }: Props) => {
  const card4digits = fundingSource?.cardAccount?.card4digits;
  const cardNetwork = fundingSource?.cardAccount?.network;
  const cardExpirationDate = fundingSource?.cardAccount?.expiration;

  const toggleNotification = () => {
    const isExpired = isCardExpired({ expiration: cardExpirationDate }, scheduledDate);

    if (isExpired && cardExpirationDate) {
      pushNotification({
        type: NOTIFICATION_VARIANT.ERROR,
        msg: 'bills.pay.date.cardExpiredLabel',
        textValues: {
          cardNetwork,
          card4digits,
          expirationDate: convertDateToMonthYearFormat(cardExpirationDate),
        },
        autoClose: false,
      });
    } else {
      dismissNotification();
    }
  };

  useEffect(() => {
    toggleNotification();

    return () => {
      dismissNotification();
    };
  }, []);

  return [toggleNotification];
};

export { useCardExpiredNotification };
