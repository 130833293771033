import React from 'react';
import { MIFieldOrEmpty, MIFormattedText } from 'src/utils/formatting';
import { PaymentFieldContent } from '../../field/PaymenFieldContent';
import { PaymentFieldInfo } from '../../field/PaymentFieldInfo';
import { PaymentFieldLabel } from '../../field/PaymentFieldLabel';

const InvoiceNumberField = ({ invoiceNumber }: { invoiceNumber?: string }) => (
  <PaymentFieldInfo>
    <PaymentFieldLabel>
      <MIFormattedText label="bills.pay.confirm.invoiceNumberField" />
    </PaymentFieldLabel>
    <PaymentFieldContent data-testid="bill-invoice-number">
      <MIFieldOrEmpty value={invoiceNumber} label="bills.view.noInvoiceNumber" />
    </PaymentFieldContent>
  </PaymentFieldInfo>
);

export { InvoiceNumberField };
