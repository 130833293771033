import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import version from 'src/version';
import config from 'src/config';
import { ServerError } from 'src/services/api/ServerError';

const absPathBlock = [
  'analytics.tiktok.com',
  'h.clarity.ms',
  'js.intercomcdn.com',
  'maps.googleapis.com',
  'launchdarkly-js-sdk',
  'googlertagmanager.com',
  'safari-extension',
  'safari-web-extension',
  'chrome-extension',
  'cdn.nmgassets.com',
] as const;
const verifyAbsPaths = (stackTrace: Sentry.Stacktrace | undefined) =>
  !stackTrace ? true : !stackTrace.frames?.some((frame) => absPathBlock.some((path) => frame.filename?.includes(path)));

export default {
  config() {
    if (config.sentry.enable && config.sentry.dsn) {
      Sentry.init({
        dsn: config.sentry.dsn,
        release: version,
        environment: process.env.REACT_APP_ENV || 'N/A',
        attachStacktrace: true,
        normalizeDepth: 3,
        ignoreErrors: ['Non-Error promise rejection captured', 'Object Not Found Matching Id'],
        beforeSend(event: Sentry.Event) {
          const stackTrace = event.exception?.values?.[0]?.stacktrace;

          if (!verifyAbsPaths(stackTrace)) {
            return null;
          }

          return event;
        },
      });
    }
  },
  setSiteContext(context) {
    Sentry.setTag('site_context', context);
  },
  capture(error: Error, errorInfo?: any) {
    return Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  },

  convertErrorNetwork(error: Error) {
    const url = get(error, 'config.url', null);
    const method = get(error, 'config.method', null);
    const code = get(error, 'response.status', null);
    const data = get(error, 'response.data', null);
    const params = get(error, 'config.data', null);
    const message = get(error, 'response.statusText', null);
    return new ServerError({ url, method, code, message, params, data, error: null });
  },

  captureNetworkError(error: Error) {
    const networkError = error instanceof ServerError ? error : this.convertErrorNetwork(error);
    const { message, code, url, method, requestData, responseData } = networkError;
    const extra = { message, code, url, method, requestData, responseData };
    return Sentry.withScope((scope) => {
      scope.setExtras(extra);
      Sentry.captureException(networkError);
    });
  },

  report() {
    const lastEventId = Sentry.lastEventId();
    if (lastEventId) {
      Sentry.showReportDialog();
    }
  },
};
