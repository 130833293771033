const BASE_URL = '/funding-sources/:id/micro-deposits';

export const microDepositsVerify = {
  base: BASE_URL,
  verify: `${BASE_URL}/verify/:token`,
  success: `${BASE_URL}/success`,
  blocked: `${BASE_URL}/blocked`,
  expired: `${BASE_URL}/expired`,
  alreadyVerified: `${BASE_URL}/already-verified`,
  cannotVerifyYet: `${BASE_URL}/cannot-verify`,
};
