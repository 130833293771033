import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import {
  close,
  getSelectedBills,
  reportPaymentSummary,
  SyncBillMaxAmountErrorMessage,
  syncPayment,
} from 'src/billpay/qbdt/services/qbdt';
import analytics from 'src/services/analytics';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';
import { ProgressIndicatorPage } from 'src/billpay/qbdt/pages/entry/ProgressIndicationPage';
import QBDTSuccessLayout from 'src/billpay/qbdt/components/layout/QBDTSuccessLayout';
import SuccessIllustration from 'src/images/qbo/success-check.svg';
import { CONSTS } from 'src/utils/consts';
import { BillType } from 'src/utils/types';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useSyncSelectedBills } from './hooks/useSyncSelectedBills';
import { useGenerateBatchUrl } from './hooks/useGenerateBatchUrl';
import { PayBillErrorPage } from '../pay/PayBillErrorPage';

const title = 'entry.header';
const messages = [
  'entry.messages.quicklyPay',
  'entry.messages.payWithACH',
  'entry.messages.extendFloat',
  'entry.messages.scheduleBill',
];
const eventPage = 'bill';

type GoToSinglePayFlowParams = {
  bill: BillType;
  isRedirectedFromBatchFlow?: boolean;
  billsExceedAmountLimitCount?: number;
};

export function QBDTPayBillLoader() {
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const [isDashboardFeatureEnabled] = featureFlags.useFeature(FeatureFlags.Dashboard, false);

  const [{ progress, error, message }, setState] = useState({
    progress: 0.5,
  } as { progress: number; error?: string; message?: string });

  const { syncSelectedBills } = useSyncSelectedBills();
  const { generateBatchUrl } = useGenerateBatchUrl();

  const batchActions = useStoreActions(regularBatchPaymentsStore);

  useEffect(() => {
    async function tryToSyncPayment(paymentId: string) {
      setState({ progress: 0.7 });
      const summary = await syncPayment({
        orgId,
        paymentId,
      });

      if (!isDashboardFeatureEnabled) {
        await reportPaymentSummary(summary);
      }

      setState({
        progress: 1,
        message: 'entry.payBill.alreadyPaid',
      });
    }

    function goToSinglePayFlow({
      bill,
      isRedirectedFromBatchFlow,
      billsExceedAmountLimitCount,
    }: GoToSinglePayFlowParams) {
      history.push(generatePath(locations.pay.funding, { orgId, billId: bill.id }), {
        isRedirectedFromBatchFlow,
        billsExceedAmountLimitCount,
      });
    }

    async function load() {
      const selectedBillsResponse = await getSelectedBills();
      const { syncedBills, failedToSyncBills } = await syncSelectedBills(selectedBillsResponse);
      const billsExceedAmountLimitCount = failedToSyncBills.filter(
        ({ errorMessage }) => errorMessage === SyncBillMaxAmountErrorMessage
      ).length;
      if (failedToSyncBills.length > 0) {
        analytics.track(eventPage, 'failed-to-sync-bills', {
          failedToSyncBills,
        });
      }

      if (syncedBills.length === 0 && billsExceedAmountLimitCount > 0) {
        history.push(locations.entryErrors.billAmountLimitError);
        return;
      }

      if (syncedBills.length === 0) {
        setState({
          progress: 1,
          error: 'Cannot continue, failed to sync bills',
        });
        return;
      }

      setState({ progress: 0.6 });
      const isBatch = syncedBills.length > 1;
      if (isBatch) {
        const [unpaidBills, billsWithPayments] = partition(syncedBills, (bill) => isEmpty(bill.payments));

        analytics.track(eventPage, 'batch-flow-bills-to-pay-status', {
          unpaidCount: unpaidBills.length,
          billsWithPaymentsCount: billsWithPayments.length,
        });

        if (isEmpty(unpaidBills)) {
          history.push(generatePath(locations.batchPay.billsAlreadyScheduled, { orgId }));
          return;
        }

        if (unpaidBills.length === 1) {
          goToSinglePayFlow({
            bill: unpaidBills[0],
            isRedirectedFromBatchFlow: true,
            billsExceedAmountLimitCount,
          });
          return;
        }

        const hasScheduledPaymentsForBills = !isEmpty(billsWithPayments);
        const ids = syncedBills.map((b) => Number(b.id));
        batchActions.settings.update({
          billIds: ids,
          refreshRequired: true,
          hasScheduledPaymentsForBills,
          billsExceedAmountLimitCount,
        });

        const { url, locationState } = generateBatchUrl(unpaidBills, selectedBillsResponse);
        history.push(url, locationState);

        return;
      }

      const bill = syncedBills[0];
      const payment = bill?.payments?.[0];
      const billHasPayment = !!payment;

      if (billHasPayment) {
        analytics.track(eventPage, 'bill-with-payment-on-pay-flow', {
          paymentId: payment.id,
          billId: bill.id,
        });
      }

      if (billHasPayment) {
        await tryToSyncPayment(payment.id);
        return;
      }

      if (bill.status === CONSTS.BILL_STATUS.UNPAID) {
        goToSinglePayFlow({ bill, billsExceedAmountLimitCount });
        return;
      }

      setState({
        progress: 1,
        error: `Cannot continue, bill status is "${bill.status}"`,
      });
    }

    load().catch((error) => {
      setState({
        progress: 1,
        error: `Cannot continue, unexpected error - ${error.message}"`,
      });
    });
  }, [history, orgId, syncSelectedBills, batchActions, generateBatchUrl]);

  if (error) {
    return <PayBillErrorPage title="entry.sync.error" subtitle="entry.sync.errorSubtitle" errors={error} />;
  }

  if (message) {
    return (
      <QBDTSuccessLayout
        illustration={SuccessIllustration}
        title="entry.sync.success"
        text="entry.payBill.alreadyPaid"
        buttonLabel="dialogs.buttons.close"
        buttonAction={close}
        hideHeader
      />
    );
  }

  return <ProgressIndicatorPage title={title} messages={messages} progress={progress} />;
}
