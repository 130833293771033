import React from 'react';
import styled from 'styled-components';
import QBOLayoutPage from 'src/components/layout/QBOLayoutPage';
import qbLogo from 'src/images/accounting-software/quickbooks-logo-horz.png';
import { MIFormattedText } from 'src/utils/formatting';
import { useSiteContext } from 'src/hoc/withSiteContext';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';

type Props = {
  illustration: string;
  title?: string;
  subtitle?: string;
  settingsHelp?: string;
  settingsHelpTitle?: string;
  supportText?: string;
  token?: string;
  vendorId?: string;
  buttonLabel?: string;
  buttonAction?: () => void;
  subtitleValues?: Record<string, any>;
};

const GeneralFlowErrorContainer = (props: Props) => {
  const site = useSiteContext();

  return (
    <QBOLayoutPage contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <Content>
        <Illustration src={props.illustration} />
        {props.title && (
          <Title>
            <MIFormattedText label={props.title} />
          </Title>
        )}
        <SubTitle>
          <MIFormattedText
            label={props.subtitle}
            values={
              props.subtitleValues || {
                email: (
                  <Link href={`mailto:${site.config.support.email}`}>
                    <MIFormattedText label={site.config.support.email} />
                  </Link>
                ),
              }
            }
          />
        </SubTitle>
        <SettingsHelp>
          <SettingsHelpTitle>
            <MIFormattedText label={props.settingsHelpTitle} />
          </SettingsHelpTitle>
          <MIFormattedText label={props.settingsHelp} />
        </SettingsHelp>

        {props.buttonLabel && (
          <ButtonContainer>
            <MIButton label={props.buttonLabel} variant={CONSTS.BUTTON_VARIANT.PRIMARY} onClick={props.buttonAction} />
          </ButtonContainer>
        )}
      </Content>
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme.colors.shadow.lightGrey};
  box-sizing: border-box;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Link = styled.a`
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const Illustration = styled(QBLogo)`
  width: auto;
`;

const SettingsHelp = styled.span`
  margin-top: 2rem;
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;

const SettingsHelpTitle = styled.span`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.text.size.errorPageTitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 2rem;
  align-self: center;
  margin-bottom: 1rem;
`;

export default GeneralFlowErrorContainer;
