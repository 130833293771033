import React from 'react';
import { Text } from '@melio/billpay-design-system';
import { MIFormattedCurrency, MIFormattedText } from 'src/utils/formatting';
import { DELIVERY_TYPE } from 'src/utils/consts';

type Props = {
  amount: number;
  companyName: string;
  deliveryType?: DELIVERY_TYPE;
};

export const SelectDeliveryMethodSubtitle = ({ amount, companyName, deliveryType }: Props) => (
  <Text color="ds.gray.100" textStyle="ds.body1" align="center" m="0">
    <MIFormattedText
      label="vendor.pushToDebit.selectDeliveryMethod.text"
      values={{
        amount: <MIFormattedCurrency value={amount} />,
        companyName,
        deliveryType,
      }}
    />
  </Text>
);
