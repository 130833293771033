import React from 'react';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { getDeliveryMethodIcon } from 'src/utils/bill';
import { ReactComponent as MastercardIcon } from 'src/images/icons/mastercard.svg';
import { PaymentFieldIcon } from 'src/billpay/qbdt/modules/view-and-confirm-pages/field/PaymentFieldIcon';
import { getBillPaidColor } from '../../utils/getBillPaidColor';

export const DeliveryMethodIcon = ({ deliveryMethod, isBillPaid }) => {
  const { deliveryType } = deliveryMethod;
  const isVirtualCardDeliveryMethod = deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

  if (isVirtualCardDeliveryMethod) {
    return <MastercardIcon width="3.2rem" />;
  }

  return (
    <PaymentFieldIcon color={getBillPaidColor(isBillPaid)}>
      <i className={getDeliveryMethodIcon(deliveryMethod)} />
    </PaymentFieldIcon>
  );
};
