import React from 'react';
import { HStack } from '@melio/billpay-design-system';
import { PaymentInfoSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSection';
import { BillsInfoField } from './BillsInfoField';
import { InvoiceNumberField } from './InvoiceNumberField';
import { VendorNameField } from './VendorNameField';
import { BillInfo } from './types';

type Props = {
  companyName: string;
  invoiceNumber?: string;
  billsInfo?: BillInfo[];
};

export const PayToSection = ({ companyName, invoiceNumber, billsInfo }: Props) => {
  const isBulkPayment = billsInfo && billsInfo?.length > 1;

  return (
    <PaymentInfoSection label="bills.pay.confirm.payTo">
      <HStack spacing="4" alignItems="start">
        <VendorNameField companyName={companyName} />
        {isBulkPayment ? (
          <BillsInfoField billsInfo={billsInfo as BillInfo[]} />
        ) : (
          <InvoiceNumberField invoiceNumber={invoiceNumber || billsInfo?.[0]?.invoiceNumber} />
        )}
      </HStack>
    </PaymentInfoSection>
  );
};
