import { CONSTS } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';

export const getDeliveryMethodIcon = (deliveryMethod: DeliveryMethodType) => {
  const isRPPSDeliveryMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const isCheckDeliveryMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;
  const isUnilateralDeliveryMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;

  if (isRPPSDeliveryMethod) {
    return 'icon-dirctory-confirmed';
  }

  if (isCheckDeliveryMethod) {
    return 'icon-check-icon';
  }

  if (isUnilateralDeliveryMethod) {
    return 'icon-email-icon';
  }

  return 'icon-bank-icon';
};
