import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

export const usePreventGoBackBrowserButton = () => {
  const history = useHistory();
  const unsubscribe = () => history.listen(() => null);

  useEffect(() => {
    history.listen((newLocation, action) => {
      if (action === 'POP') {
        history.goForward();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return null;
};
