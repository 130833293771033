import React from 'react';
import { Flex, Icon, Text } from '@melio/billpay-design-system';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { PaymentType } from 'src/utils/types';
import { ReactComponent as SuccessIllustration } from 'src/images/general/action-done-icon.svg';
import { InvoiceNumberType } from 'src/pages/vendor/hooks/useInvoiceNumberType';

type Props = {
  deliveryEta: PaymentType['deliveryEta'];
  amount: number;
  vendorEmail: string;
  organizationName: string;
  invoiceNumber: string;
  invoiceNumberType: InvoiceNumberType;
};

export const PaymentInfo = ({
  deliveryEta,
  organizationName,
  vendorEmail,
  amount,
  invoiceNumber,
  invoiceNumberType,
}: Props) => (
  <Flex direction="column" justify="center" align="start" w="100%">
    <Icon as={SuccessIllustration} w="ds.3xl" h="ds.3xl" mb="ds.xl" />
    <ContentTitle label="vendor.unilateral.virtualCardSuccess.title" />
    <Text textStyle="ds.body2" mb="0" mt="ds.md" color="ds.gray.100" data-testid="p2d-virtual-card-payment-info">
      <MIFormattedText
        label="vendor.pushToDebit.virtualCard.notCleared.text"
        values={{
          deliveryEta: <MIFormattedDate date={deliveryEta} />,
          amount: <MIFormattedCurrency value={amount} />,
          vendorEmail,
          organizationName,
          invoiceNumber,
          invoiceNumberType,
        }}
      />
    </Text>
  </Flex>
);
