import { keyframes } from '@chakra-ui/react';

export const footerWrapperStyle = {
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: 'full',
  height: '5rem',
  bg: 'ds.gray.100',
  position: 'fixed',
  bottom: '0',
  animation: `0.5s linear alternate ${keyframes({
    from: { bottom: '-5rem' },
    to: { bottom: '0' },
  })}`,
  px: 'ds.lg',
  boxSizing: 'border-box',
};
