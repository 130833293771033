import React from 'react';
import styled from 'styled-components';
import { Flex } from '@melio/billpay-design-system';
import analytics from 'src/services/analytics';
import { Modal, ModalTextContent, ModalTitle, ModalSubtitle } from 'src/theme/ds';
import MIButton from 'src/components/common/MIButton';
import { ReactComponent as CheckIcon } from 'src/billpay/qbdt/pages/dashboard/images/check-mark.svg';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/utils/consts';
import { getPartialBillId } from 'src/utils/bills';
import { useLocationState } from 'src/utils/hooks';
import { BillType } from 'src/utils/types';
import { PAY_FLOW_PAGE_EVENT } from '../../event-mapping';

type Props = {
  dismiss: VoidFunction;
};

const NotifyVendorSuccessModal = ({ dismiss }: Props) => {
  const [bill] = useLocationState<BillType>('bill');

  const onCloseClick = () => {
    analytics.track(PAY_FLOW_PAGE_EVENT, 'success-vendor-email-modal-closed', {
      vendorId: bill?.vendorId,
      partialBillId: getPartialBillId(bill),
    });
    dismiss();
  };

  return (
    <Modal id="notify-vendor-success-dialog" onCloseClick={onCloseClick}>
      <Flex justify="center">
        <CheckIcon />
      </Flex>
      <ModalTextContent>
        <ModalTitle label="paymentDashboard.notifyVendorSuccess.dialogTitle" />
        <ModalSubtitle label="paymentDashboard.notifyVendorSuccess.dialogSubTitle" />
      </ModalTextContent>
      <PrimaryButton
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={onCloseClick}
        label="general.done"
        size={BUTTON_SIZE.NORMAL}
      />
    </Modal>
  );
};

const PrimaryButton = styled(MIButton)`
  width: 10rem;
  padding: 0;
  align-self: center;
`;

export { NotifyVendorSuccessModal };
