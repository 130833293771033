import React from 'react';
import { Flex } from '@melio/billpay-design-system';
import { MIFieldOrEmpty, MIFormattedCurrency } from 'src/utils/formatting';
import { MICardField } from 'src/components/common/MICard';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { BatchBillType } from 'src/modules/regular-batch-payments/types/store-types';

type Props = {
  bill: BatchBillType;
  vendorName: string;
};

export const BillDetails = ({ bill, vendorName }: Props) => (
  <>
    <Flex justify="space-between">
      <Flex direction="column">
        <MICardField label="bills.form.vendor">{vendorName}</MICardField>
        <MICardField label="bills.form.totalAmount">
          <MIFormattedCurrency value={convertCurrencyToNumber(bill.totalAmount)} />
        </MICardField>
        <MICardField label="bills.form.dueDate">
          <MIFieldOrEmpty value={bill.dueDate} label="bills.form.dueDateEmpty" type="date" />
        </MICardField>
        <MICardField label="bills.form.billNumberField">
          <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.form.invoiceNumberEmpty" type="text" />
        </MICardField>
        <MICardField label="bills.form.description">
          <MIFieldOrEmpty value={bill.note} label="bills.form.descriptionEmpty" type="text" />
        </MICardField>
      </Flex>
    </Flex>
  </>
);
