const mapAllowedMCCsToOptions = (allowedMCCs) => {
  const mccIndustries = [] as any;
  const mccSubIndustries = {} as any;

  for (const [, mcc] of Object.entries(allowedMCCs)) {
    const { code, industry, description } = mcc as any;

    if (!code || !industry || !description) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const isExist = mccIndustries.some((mmcIndustry) => mmcIndustry.label === industry);

    if (!isExist) {
      mccIndustries.push({ label: industry, value: industry });
    }

    if (!mccSubIndustries[industry]) {
      mccSubIndustries[industry] = [];
    }

    mccSubIndustries[industry].push({ label: description, value: code });
  }

  return {
    mccIndustries,
    mccSubIndustries,
  };
};

export { mapAllowedMCCsToOptions };
