/* eslint-disable import/no-default-export */
/* eslint-disable max-len,no-template-curly-in-string */
import { ISSUER_ADDRESS, ISSUER_ZIP_CODE } from 'src/utils/consts';

export default {
  'en-US': {
    qbo: {
      header: {
        help: 'FAQ',
        settings: 'Settings',
        support: 'Need help?',
        title: 'Schedule a <sb>{amount}</sb> payment to <sb>{companyName}</sb>',
        billDetails: 'Bill details',
        beta: 'Beta',
      },
      footer: {
        poweredBy:
          'Bill Pay services powered by <sb>Melio</sb> with funds held by <sb>Evolve Bank & Trust</sb> or <sb>J.P Morgan</sb> (members of the FDIC and the Federal Reserve)',
        termsOfService: 'Terms of service',
        privacyPolicy: 'Privacy policy',
      },
      settings: {
        title: 'Bill pay settings',
        account: {
          delete: 'Delete my account',
          logout: 'Log out of Bill Pay',
          logoutHint:
            'Click below to log out of Bill Pay services. Even once logged out, we’ll process all your scheduled payments and send you status updates for them. You can always log back in later.',
        },
      },
      blockedUser: {
        title: 'We’re unable to authorize your account',
        subTitle:
          'Thank you for your interest in Bill Pay powered by <b>Melio</b>. Unfortunately, our team couldn’t verify your account and/or your activity does not meet our Eligibility Criteria outlined in our {tos} agreement.{br}{br}As a result, we’re unable to authorize your activity and have blocked you from using Bill Pay services.{support}',
        dismiss: 'Dismiss',
        contactDetails: '{br}{br}If you have any questions you can email {supportEmail}',
        termsOfService: 'Terms of Service',
        buttonTextClose: 'Close',
        titleBlocked: 'Sorry, we could not locate the bill',
        subtitleBlocked:
          'You’re probably looking for something else. You can find this payment’s details in your QuickBooks account.',
      },
      drawer: {
        title: 'Frequently asked questions',
        contactLabel: 'If you still have questions, please contact our support team',
        emailLabel: 'Email ',
        supportEmailAddress: ' {supportEmail} ',
        orLabel: ' or ',
        chatLabel: 'start a chat',
      },
      firstTimeNew: {
        melio: 'Melio',
        privacyPolicy: 'Privacy policy',
        termsOfService: 'Terms of service',
      },
      launcher: {
        startChat: 'Start a chat',
      },
    },
    qbdt: {
      firstTimeNew: {
        title: 'Get started and pay your first bill',
        stepsTitle1: 'Pay with a bank or card',
        stepsTitle2: 'Choose how vendor gets paid',
        stepsSubTitle1:
          'Schedule a payment using a bank transfer or debit card for free. Or use your credit card for a {fees_credit_value}% fee.',
        stepsSubTitle2:
          'We’ll mail a check on your behalf or deposit funds to your vendor’s bank at no additional cost.',
        stepsSubTitle2Hint: '* Vendors don’t need to sign up or pay any fees',
        costTitle: 'How much does it cost?',
        costSubTitle:
          'Completely free when paying with a debit card or bank transfer (no extra cost for mailing checks). {fees_credit_value}% fee when using a credit card. Vendors never pay any fees.',
        description:
          'Bill Pay is a service powered by {melio} with funds held by Evolve Bank & Trust or J.P Morgan. ' +
          'By clicking “Continue”, you agree to Melio’s {termsOfService} and that you have read and acknowledged Melio’s {privacyPolicy}. ' +
          'When you Get Started with Bill Pay, you understand and agree to share your QuickBooks Desktop data with Melio for purposes of the Bill Pay service.' +
          'You can find the list of data that may be shared in {sharingPolicy}',
        melio: 'Melio',
        privacyPolicy: 'Privacy Policy',
        termsOfService: 'Terms of Service',
        sharingPolicy: 'QuickBooks Desktop Data Sharing Policy',
        QBDTOnlineData: 'QuickBooks Desktop data',
        button: 'Continue',
      },
    },
    actionsMenu: {
      label: 'Actions',
      cancel: 'Cancel',
    },
    form: {
      addressAutocomplete: {
        addressLabel: 'Business address',
        emptyAddressLabel: 'No business address',
        addressPlaceholder: 'Ex.: 177a Bleecker Street, New York, NY, USA',
        addressInputHint: 'The address of your business’s primary location',
        hint: 'Start typing for autocomplete',
        aptNumberLabel: 'Suite #',
        emptyNumberLabel: 'No suite',
        aptNumberPlaceholder: 'Suite or appartment number',
        cantFindAddress: 'I can’t find my address',
        legalAddressLabel: 'Legal address is different from the company address',
      },
      input: {
        currency: {
          placeholder: '$0.00',
        },
      },
    },
    categoryList: {
      yes: 'Yes',
      no: 'No',
    },
    payBillPaymentActivity: {
      payFromTitle: 'Pay from my',
      receiveTitle: 'Vendor receives',
      updated: 'Updated',
      fundingSource: {
        achLabel: 'Bank account',
        creditCardLabel: 'Credit card',
        debitCardLabel: 'Bank account',
        debitCardDescription: 'Using debit card ...{card4digits}',
        unverifiedBankAccountHint: 'Pending verification',
      },
      deductionDate: {
        futureLabel: 'Payment will be deducted on',
        pastLabel: 'Payment was deducted on',
      },
      deliveryMethod: {
        paperCheckLabel: 'Paper check delivered to',
        achLabel: 'Bank transfer to',
        rppsLabel: 'Direct payment',
      },
      deliveryDate: {
        unverifiedBankAccountHint:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountApprovalRequiredHint:
          'To pay on schedule, verify your bank account {bankAccount} and make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
        approvalRequiredHint:
          'To pay on schedule, make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
        unilateralApprovalRequiredHint:
          'To pay on schedule, make sure this payment is admin approved and that your vendor selects how to receive payments by <sb>{date} at 12pm EST</sb>',
        unilateralHint:
          'To pay on schedule, your vendor needs to select how to receive payments by <sb>{date} at 12pm EST</sb>',
        unilateralFailedToDeliver:
          'For this payment to arrive on schedule your vendor needs to enter their delivery details by {date}',
        achHint: 'By 8 PM vendor’s local time',
        deliveryEtaLabel: 'Delivery ETA',
        estimatedDeliveryEtaLabel: 'Estimated payment delivery',
      },
      memo: {
        label: 'Note to vendor',
        emptyPlaceholder: 'No note to vendor',
      },
    },
    viewBillPaymentActivity: {
      payFromTitle: 'Pay from my',
      paidFromTitle: 'Paid from my',
      receiveTitle: 'Vendor receives',
      receivedTitle: 'Vendor received',
      fundingSource: {
        achLabel: 'Bank account',
        creditCardLabel: 'Credit card',
        debitCardLabel: 'Bank account',
        debitCardDescription: 'Using debit card ...{card4digits}',
        unverifiedBankAccountHint: 'Pending verification',
        unverifiedBankAccountHintWithAction: 'Pending verification. <verifyLink>Verify account</verifyLink>',
      },
      deductionDate: {
        unpaidLabel: 'Payment will be deducted on',
        paidLabel: 'Payment was processed on',
        approvalRequiredHint: 'Pending admin approval',
      },
      deliveryMethod: {
        paperCheckLabel: 'Paper check delivered to',
        achLabel: 'Bank transfer to',
        rppsLabel: 'Direct payment to',
        paidBillPaperCheckLabel: 'Delivery by paper check {formattedCheckSerial}',
        paidBillAchLabel: 'Delivery by bank transfer',
        virtualCardLabel: 'Single-use virtual card to',
        virtualCardLabelWithDetails: 'Single-use virtual card (...{card4digits}) emailed to',
        virtualCardNotClearedHint: 'Pending clearance by the vendor',
        virtualCardExpiredHint: 'Expired on {expirationDate}',
        debitCardLabel: 'Delivery by bank using debit card',
        debitCardHint: 'Delivery method was modified by vendor',
      },
      deliveryDate: {
        unilateralApprovalRequiredHint: 'Pending admin approval and delivery details ',
        unilateralHint: 'Pending delivery details',
        achHint: 'By 8 PM vendor’s local time',
        deliveryEtaLabel: 'Delivery ETA',
        paidBillDeliveryEtaLabel: 'Delivery by',
      },
      memo: {
        label: 'Memo to vendor',
        emptyPlaceholder: 'No memo to vendor',
      },
    },
    viewBillWarningNotificationCard: {
      actionRequiredTitle: 'Action required:',
      contributor: {
        unverifiedBankAccountMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountWithActionMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb></li>
        <li>Make sure this payment is admin approved by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        approvalRequiredMessage:
          'To pay on schedule, make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
        unilateralApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Make sure this payment is admin approved by <sb>{date} at 12pm EST</sb></li>
        <li><sb>{vendor}</sb> needs to select how to receive payments by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        unilateralMessage:
          'To pay on schedule, <sb>{vendor}</sb> needs to select how they’d like to receive payments by <sb>{date} at 12pm EST</sb>',
      },
      admin: {
        unverifiedBankAccountMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountWithActionMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb></li>
        <li>Make sure this payment is approved by yourself or another admin by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        approvalRequiredMessage:
          'To pay on schedule, make sure this payment is approved by <sb>{date} at 12pm EST</sb>',
        unilateralApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Make sure this payment is approved by yourself or another admin before <sb>{date} at 12pm EST</sb></li>
        <li><sb>{vendor}</sb> needs to select how to receive payments by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        unilateralMessage:
          'To pay on schedule, <sb>{vendor}</sb> needs to select how they’d like to receive payments by <sb>{date} at 12pm EST</sb>',
      },
    },
    deliveryMethod: {
      achLabel: '{institutionName, select, undefined{} other{{institutionName}, }}{displayName} ...{identifier}',
      achLabelNoDots: '{institutionName, select, undefined{} other{{institutionName}, }}{displayName} {identifier}',
    },
    progress: {
      next: 'Next',
      prev: 'Back',
      finish: 'Finish',
      continue: 'Continue',
      continueDDate: 'Continue',
      update: 'Update',
      skip: 'Skip',
    },
    general: {
      free: 'Free',
      or: 'Or',
      new: 'New',
      emptyLabel: ' ',
      areYouSure: 'Are you sure?',
      error: {
        title: 'Sorry, something went wrong',
        subtitle: 'If this persists, please contact support at {email} to let us know.',
        buttonlabel: 'Reload',
      },
      on: 'On',
      off: 'Off',
      cancel: 'Cancel',
      save: 'Save',
      active: 'Active',
      notNow: 'Not now',
      copy: 'Copy',
      gotIt: 'Got it',
      next: 'Next',
      back: 'Back',
      done: 'Done',
      yes: 'Yes',
      no: 'No',
      continue: 'Continue',
    },
    manualAddress: {
      title: 'Let’s fix this',
      subtitle:
        'We spotted a small issue with the address you provided, and suggested an alternative below in green. Please select and/or edit the final version you’d like to use.',
      line1Label: 'Address line 1',
      line1Placeholder: 'Street and number, PO box, check locker',
      line2Label: 'Address line 2',
      line2Placeholder: 'Apartment, suite, building, floor, etc',
      cityLabel: 'City',
      stateLabel: 'State',
      zipCodeLabel: 'ZIP code',
      edit: 'Edit address',
      original: 'Original address',
      suggested: 'Suggested address',
      editAddress: 'Edit address',
      confirm: 'Confirm address and continue',
      correctInvalid: 'Continue anyway',
      goUnilateral: 'Ask vendor for details',
      confirmInvalid: 'Confirm payment address',
      invalid: 'Address entered',
      invalidTitle: 'Address couldn’t be verified',
      invalidSubtitle:
        'The address entered couldn’t be verified. Please review it below to ensure the check arrives at the right place.',
      invalidPOBoxSubtitle:
        'We couldn’t confirm the accuracy of this address, but that doesn’t necessarily mean it’s wrong. Please review to ensure this check arrives at the right place.',
      invalidUnilateralSubtitle:
        'We couldn’t verify the address you entered. Edit the address or ask your vendor for their delivery details so they can choose how to receive your payments.',
      form: {
        title: 'No worries, let’s try it this way instead:',
      },
      confirmPage: {
        title: 'Let’s fix the address you entered',
        subtitle:
          'We spotted a small issue with the address you provided, and suggested an alternative below. Please select and/or edit the final version you’d like to use.',
      },
      poBoxPage: {
        subtitle:
          'We can’t verify the address you entered, which doesn’t necessarily mean it’s wrong. Please take a minute and review the address to ensure the check arrives at the right place.',
        correctInvalid: 'It’s correct, continue',
      },
    },
    list: {
      search: 'Search...',
      cancelSearch: 'Cancel',
      refresh: 'Refresh',
      refreshBills: 'Refresh bills',
      refreshPayments: 'Refresh payments',
      tab: {
        bills: {
          paid: 'paid',
          unpaid: 'inbox',
          scheduled: 'scheduled',
        },
        requests: {
          paid: 'paid',
          scheduled: 'in-progress',
          sent: 'sent',
          unsent: 'unsent',
        },
        contacts: {
          vendors: 'vendors',
          customers: 'customers',
        },
        payments: {
          scheduled: 'scheduled',
          completed: 'completed',
          failed: 'failed',
        },
        invoices: {
          all: 'all',
          failed: 'failed',
          sent: 'sent',
          unsent: 'unsent',
        },
        getPro: {
          invoices: 'invoices',
          requests: 'requests',
          payments: 'payments',
        },
      },
      group: {
        bill: {
          failedPayment: 'Failed',
          pendingApprovalPayment: 'Pending approval',
          declinedPayment: 'Declined',
          overdueBills: 'Overdue',
          billsBeforeThisWeek: 'Older',
          billsDueThisWeek: 'This week',
          billsDueThisMonth: 'Later this month',
          billsForRest: 'Later',
          billsForLater: 'Later',
          requestedPayments: 'Requested payments',
          listItemStatus: {
            failed: 'Failed',
            pendingApproval: 'Pending',
            declined: 'Declined',
            scheduled: 'Scheduled',
            paid: 'Paid',
          },
          scheduled: {
            billsDueThisWeek: 'Scheduled for this week',
            billsForLater: 'Scheduled for later',
            billsDueThisMonth: 'Scheduled for later this month',
            billsWithoutVendorInfo: 'Pending vendor’s information',
          },
          paymentRequested: 'Requested',
          createdByCustomer: 'Created by customer',
        },
        paymentRequest: {
          overduePaymentRequests: 'Overdue',
          paymentRequestsDueThisWeek: 'Sent early this week',
          paymentRequestsForLater: 'Later',
          failedPaymentRequests: 'Failed',
        },
        invoice: {
          listTagStatus: {
            unsent: 'unsent',
            sent: 'sent',
            failed: 'failed',
          },
          failed: 'failed',
          overDue: 'overdue',
          dueThisWeek: 'due this week',
          dueThisMonth: 'due this month',
          dueLater: 'due later',
          todayScheduled: 'scheduled today',
          pastWeekScheduled: 'scheduled in the past week',
          todayPaid: 'Paid today',
          pastWeekPaid: 'Paid last 7 days',
          paidEarlier: 'Paid earlier',
          rest: 'scheduled earlier',
        },
      },
    },
    uploader: {
      remove: 'Remove',
    },
    wizard: {
      coverImageAlt: 'Screen cover image',
      support: 'Need help?',
    },
    input: {
      optional: ' (optional)',
    },
    select: {
      defaultPlaceholder: 'Select',
      noOptions: 'No options',
    },
    addressValidation: {
      invalid: 'Hmm, something here doesn’t look right. Please enter a valid address.',
    },
    auth: {
      verifyEmail: {
        section: 'Email verification',
        loading: {
          title: 'Verifying...',
          subtitle: 'Please wait, your email is being verified',
        },
        success: {
          title: 'Your email is now verified',
          details:
            'You’re now being redirected to your account. If you’re not being redirected, please click the link below:',
          redirectLink: 'Take me to my account',
        },
        failure: {
          title: 'Oh Snap!',
          details: 'This link is no longer valid. Don’t worry, it’s easy to get a new one.',
          actions: {
            resend: 'Resend email verification',
          },
        },
        resend: {
          title: 'Verify your email',
          details:
            'We’ve sent you another verification to {email}. To verify your account, go to your inbox and open the email from Melio. Click “Verify my email” and you’re good to go.',
          resendLink: 'Resend email verification',
          login: 'Login',
        },
        fromLanding: {
          title: 'Check your email',
          details:
            'We’ve sent a message to {email} with a link to activate your account. If you don’t see an email from us within a few minutes, be sure to check your spam folder. {br}{br} Didn’t get an email?',
          buttonLabel: 'Resend email verification',
          linkLabel: 'Re-enter your email',
        },
      },
    },
    companies: {
      acceptRequest: {
        title: 'Select company {user} should join',
        subtitle:
          'Which of your Melio companies would you like <b>{user}</b> to join as <ind>{role}</ind> <capb>{role}</capb>?',
        companyOption: '{companyName}',
      },
    },
    server: {
      'Network Error':
        'Looks like some network error. Please reload and try again. If the problem continues, please contact our customer support',
      ATH02: 'Email or password are incorrect, please enter again',
      ATH03: 'There’s an error with this account, please contact support',
      ATH04: 'We’re sorry, looks like there’s an error with this account, please contact support.',
      ATH01: 'This email already registered in Melio. If it’s yours, please {login}',
      ATH09: 'We’re sorry, looks like this QuickBooks account can not be connected. Please contact our support.',
      ATH13: 'The code does not match the latest code or the latest code is invalid',
      ATH14: 'Can’t sign in as a guest',
      ATH15: 'The user already belong to organization',
      RSC03: 'You will have to manually cancel all scheduled payments for this payment method.',
      'RSC03.1': 'First select or add another payment method before deleting the current one.',
      ALREADY_EXISTS_CONTINUE: 'continue to login',
      ALREADY_EXISTS_CHANGE_EMAIL_CONTINUE: 'confirm verification code',
      DLM07: 'You already added this bank account',
      FAILED_TO_DELIVER: 'This account recently failed to receive your payment, try another account instead.',
      RSC01: 'This payment method is currently being used in a different tab on your computer.',
      RSC05: 'You already added this card',
      RSC11: 'Microdeposit verification failed',
      CHC01: 'Sorry, we don’t support consumer Visa cards. Please try again with a business card.',
      CHC02: 'Sorry, but we don’t currently support pre-paid cards or any cards issued outside the US.',
      CHC03:
        'Unfortunately, we weren’t able to link your card. ' +
        'Please take another look at the card details and contact support if you’re still having trouble.',
      CHC04: 'The billing details you’ve entered don’t match the bank’s records, please check and re-enter.',
      CHC05:
        'Unfortunately, we weren’t able to link your card. ' +
        'Please take another look at the card details and contact support if you’re still having trouble.',
      RSC09: 'You already added this bank account',
      DLM15: 'You already added this card',
      VDR03: 'We’re sorry, this contact has existing bills and can not be deleted.',
      VDR04: 'We’re sorry, this contact has scheduled payments and can not be deleted.',
      VDR06: 'We’re sorry, looks like your link is not valid.',
      PMT05: 'Payment approval has declined the payment',
      PMT10:
        'You can not use the same account as both a payment and a delivery method. Please change one of them and try again.',
      RB06:
        'You can not use the same account as both a payment and a delivery method. Please change one of them and try again.',
      ERR: 'We’re sorry, some issue occurred and our team was notified. If this still happens, please contact support.',
      VT1: 'We’re sorry, looks like your link is not valid.',
      VT2: 'We’re sorry, looks like your link is not valid.',
      VT3: 'This payment link has expired.{br}If you need any help we’re available at {br}{supportEmail}',
      UNP1: 'We’re sorry, looks like your link is not valid.',
      UNP3: 'You already added payment delivery details',
      UNP4: 'We’re sorry but this payment was cancelled. {br}Need help? We are always available at {supportEmail}',
      UNP5:
        'This payment link has expired. Please contact <sb>{companyName}</sb> at {email} to resolve this and get an updated link.',
      PMT01: 'We’re sorry, looks like your link is not valid.',
      PMT14:
        'We’re sorry but you cannot ask your vendor to add their payment details when using a payment method that hasn’t been verified.',
      PMT17:
        'We’re sorry but this payment was cancelled by <sb>{companyName}</sb>. Please reach out to <sb>{companyName}</sb> at {email} and ask them to resend their request.{br}{br}Need help? We are always available at {supportEmail}',
      PMT18:
        'This payment link has expired. Please contact <sb>{companyName}</sb> at {email} to resolve this and get an updated link.',
      PMT19:
        'Sorry, but this payment can’t be processed instantly anymore. It’ll be processed as a free ACH bank transfer. Need any help? {chatWithUs}',
      RB07:
        'We’re sorry but you cannot ask your vendor to add their payment details when using a payment method that hasn’t been verified.',
      RSC20: 'Manual unverified bank accounts limit exceeded',
      RSC22: 'Funding source has existing billing fees',
    },
    settings: {
      title: 'Settings',
      account: {
        title: 'Profile settings',
        description: 'Set a new password, change email notifications settings.',
        cardTitle: 'Account information',
        form: {
          name: 'Name',
          nameEmpty: 'No name',
          email: 'Email',
          emailEmpty: 'No email',
          phone: 'phone',
          phoneEmpty: 'No phone',
          password: {
            title: 'Change password',
            currentPassword: 'Current password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password',
          },
          sendEmailNotifications: 'Email notifications',
          sendBillDueEmailReminder: 'Remind me when a bill’s due date is near',
          billQbSyncedNotification: 'Remind me about new bills added on QuickBooks',
          on: 'On',
          off: 'Off',
        },
        profilePicture: {
          helpText: 'Upload your profile picture',
        },
      },
      company: {
        title: 'Company settings',
        description: 'Edit your business name, address or phone number. Update your business’s legal information.',
        proLayoutSwitch: {
          title: 'Advanced mode in Get Paid',
          switchOnDescription: 'Advanced mode is on. Turn off to revert back to basic mode',
          switchOffDescription: 'Turn on to manage large numbers of incoming payments more easily',
          updateSuccess: 'Settings were updated successfully',
          updateFailed: 'Settings update has failed',
        },
        cardTitle: 'Company information',
        publicLinkTitle: 'Company public link',
        publicLinkSubTitle:
          'You can share your public link with your customers to have them pay any amount for any invoice.',
        legalInfoTitle: 'Company legal information',
        texFormsTitle: 'Tax forms',
        form: {
          companyName: 'Company legal name',
          companyNameEmpty: 'No company legal name',
          addressEmpty: 'No address',
          suiteNumberEmpty: 'No suite #',
        },
        logo: {
          title: 'Company logo',
          helpText: 'Upload your company logo',
        },
      },
      paymentMethods: {
        title: 'Payment methods',
        description: 'Add/remove or edit all your payment methods - bank accounts, credit or debit cards.',
        addLabel: 'Add label',
        editLabel: 'Edit label',
        delete: 'Delete',
        verify: 'Verify',
        edit: 'Edit',
        editQBLink: 'Edit QB Link',
        view: 'View',
        ach: 'Bank account',
        achNameLabel: '{name}',
        cardItemName: '{name}',
        hint: 'Add this method',
        bankHint: 'Free.',
        notVerifiedAccountHint: '* Account is pending verification.',
        notVerifiedAccountHintWithAction: '* Account is pending verification. {link}',
        verifiedAccountHint: 'Free',
        verifyAccountHint: 'Verify account',
        microDepositsBlocked: 'Unable to verify your account. Contact support',
        creditCardHint: 'Defer this payment and earn card rewards. {fees_credit_value}% fee.',
        addAnotherBank: '+ Add another bank account',
        addAnotherCreditCard: '+ Add another credit card',
        addAnotherDebitCard: '+ Add another debit card',
        credit: 'Credit card',
        deleteDialog: {
          title: 'Delete {fundingType} {displayName}?',
          subtitle: 'Warning: this cannot be undone.',
          confirm: 'Yes, delete',
        },
      },
      receivingMethods: {
        title: 'Receiving methods',
        description: 'Add/remove or edit your receiving method - your bank account to get paid.',
        addAnotherBank: '+ Add another bank account',
        addAnotherCreditCard: '+ Add another credit card',
        addAnotherDebitCard: '+ Add another debit card',
        ach: 'Bank account',
        achDescription: '{accountName}',
        notVerifiedAccountHintWithAction: '* Account is pending verification. {link}',
        microDepositsBlocked: 'Unable to verify your account. Contact support',
        verifyAccountHint: 'Verify account',
        hint: 'Connect your bank account to get paid',
        edit: 'Edit',
        view: 'View',
        editBankAccountLink: 'Edit sync details',
        delete: 'Delete',
        dialog: {
          delete: {
            titleDeletable: 'Delete this bank account?',
            titleUndeletable: 'Can’t delete this bank account',
            subtitleDeletable:
              'Your Melio.me payment portal will stop working without a bank account that will receive the funds.',
            subtitleUndeletable:
              'This receiving method has scheduled payments incoming and cannot be deleted right now. You can delete it when the payment will be deposited.',
            confirm: 'Yes, delete',
          },
          edit: {
            title: 'Change this bank account?',
            subtitle: 'You have ongoing requests. They will be deposited to the new bank account',
            confirm: 'Yes, continue to edit',
          },
        },
      },
      microDeposits: {
        verifyDialog: {
          title: 'Enter the two micro-deposit amounts you received to verify your account',
          subtitle:
            'Once submitted and approved, {companyName, select, undefined {The} other {{companyName}}} bank account will be verified and connected right away.',
          sum1Label: 'Amount #1',
          sum2Label: 'Amount #2',
          confirm: 'Submit and verify my account',
          placeholder: '$0.00',
          incorrectAmounts: 'One or both of the amounts above is incorrect. Please try again.',
          emptyAmounts: 'Please enter both amounts and try again.',
        },
        verifyModal: {
          title: 'Verify your bank account',
          subtitle:
            'We sent 2 small deposits (less than $1.00) from Melio Payments to your bank account ending in <b>{digits}</b>.',
          formSubtitle: 'Enter the exact amounts below to verify your account.',
          sum1Label: 'Deposit #1',
          sum2Label: 'Deposit #2',
          viewAnExample: 'View an example',
          exampleTitleText: 'This is only an example',
          confirm: 'Verify',
          placeholder: '$0.00',
          incorrectAmounts: 'One or both of the amounts above is incorrect. Please try again.',
          emptyAmounts: 'Please enter both amounts and try again.',
          invalidDeposit: 'Deposit must be less than $1.00',
        },
        verifyDialogLimitReached: {
          title: 'Too many failed attempts',
          subtitle:
            'To verify your account, please contact our support team at {supportEmail}{br} or call {supportPhone}',
          dismiss: 'Close',
          chatWithUs: 'chat with us',
        },
        verifyDialogSuccess: {
          paymentMethods: {
            title: 'Account verified!',
            subtitle: 'Payments made with this account will now be processed without interruption.',
          },
          receivingMethods: {
            title: 'Your bank account is verified!',
            subtitle: 'You can view all incoming payments, including those initiated by your customers.',
          },
          ok: 'Close and continue',
        },
        verifyModalSuccess: {
          paymentMethods: {
            title: 'Bank account verified!',
            subtitle: 'Payments made from this bank account will now be processed without interruption.',
            ok: 'Dismiss',
          },
        },
        depositsNotSentYetDialog: {
          title: 'Can’t verify just yet',
          subtitle:
            'We’re sending 2 deposits to your bank account ending in <b>{digits}</b> (can take up to 3 business days).{br}{br}We’ll email you once the deposits are made with a link to complete the verification.',
          ok: 'Got it',
        },
      },
      accountingSoftware: {
        title: 'Sync with QuickBooks',
        cardTitle: 'QuickBooks sync',
        description: 'Connect your QuickBooks account to sync your bills, vendors and payments.',
        content:
          'Log in and connect your QuickBooks account to sync your vendors, bills, and payments between Melio and QuickBooks.',
        connected:
          'Your <b>{melioAccount}</b> Melio account is connected with your <b>{QBAccount}</b> QuickBooks account.',
        disconnected:
          'Syncing Melio to the <b>{QBAccount}</b> QuickBooks account has been disconnected. You can reconnect the sync below. ',
        connectedStatus: 'Connected',
        disconnectedStatus: 'Disconnected',
        errorStatus: 'Failed',
        lastSynced: 'Last sync at {date}',
        link: 'Connect QuickBooks',
        unlink: 'Disconnect',
        syncNow: 'Sync now',
        syncingNow: 'Syncing...',
        unlinkDialog: {
          title: 'Are you sure about disconnecting this QuickBooks account?',
          confirm: 'Yes, disconnect it',
        },
      },
      billing: {
        title: 'Billing',
        description: 'View or download your billing receipts.',
        cardTitle: 'Payment history',
        emptyTablePlaceholder: 'You do not have any payments',
        feeHistory: {
          title: 'Transaction fee history',
          emptyHistory: 'No fees just yet.',
          pagination: '{start}–{end} of {total} items',
        },
        billingFee: {
          title: 'Payment method for check fees',
          emptyMethodTitle: 'Pay paper check fees separately',
          emptyMethodSubtitle: 'Use a specific payment method for all check fees.',
          addMethod: 'Add method',
          deleteModal: {
            title: 'Remove payment method',
            text:
              'Are you sure you want to remove this payment method for check fees? Check fees will then be billed to the method used for each payment.',
            cancelButton: 'Cancel',
            deleteButton: 'Remove',
            toast: 'Payment method for paper check fees was removed.',
          },
          methodCard: {
            verified: 'Check fees will be billed separately to this payment method.',
            nonVerified:
              'Until the bank account is verified, check fees will be billed to the method used for each payment.{br}{link}',
            actions: {
              change: 'Change',
              remove: 'Remove',
            },
          },
        },
        paymentsTable: {
          date: 'Date',
          paymentMethod: 'Payment method',
          amount: 'Amount',
          receipt: 'Receipt',
        },
        tooltip: {
          receipt: {
            regular: 'Get this month’s transaction fee history sent to your email.',
            success: 'Check your inbox for this month’s transaction fee history.',
          },
        },
        notification: {
          getReceipt: {
            success: 'Transaction fee history sent to your email.',
            failure: 'The receipt couldn’t be emailed due to a technical issue. Please wait and try again later.',
          },
        },
      },
      vendors: {
        title: 'Vendors',
      },
      actions: {
        edit: 'Edit',
        done: 'Done',
        update: 'Update',
      },
      linkQB: 'Link QB',
      unlinkQB: 'Unlink QB',
      requestResendingEmailVerification: 'Resend email verification',
      goCompanyInfo: 'Edit company info',
      fundingSources: 'Add payment methods',
      notifications: {
        title: 'Notifications',
        description: 'change notification settings',
      },
      groupsHeaders: {
        companyGroup: 'Company',
        paymentsAndBillingGroup: 'Payments and billing',
        supportGroup: 'Support',
      },
    },
    companyInfo: {
      title: 'Company info',
      companyName: 'Company name',
      taxId: 'Tax ID',
      phone: 'Phone',
      yearOfIncorporation: 'Year of incorporation',
      accountingSoftware: 'Accounting software',
      businessType: 'Business type',
      name: {
        inputTitle: 'Business name',
      },
      taxIdTypes: {
        SSN: 'SSN',
        ITIN: 'ITIN',
        EIN: 'EIN',
      },
      businessTypes: {
        partnership: 'Partnership',
        soleProprietorship: 'Sole Proprietorship',
        LLC: 'Limited Liability Company',
        corporation: 'Corporation',
        trust: 'Trust/Estate',
        nonProfitCorporation: 'Non-Profit',
        municipal: 'Municipal',
        municipality: 'Municipality',
        nonGovernmentalOrganization: 'Non-governmental organization',
      },
    },
    bills: {
      title: 'Pay',
      errors: {
        sync: {
          title: 'Sorry, something went wrong',
          subTitle: 'We couldn’t load Bill Pay services. Please try again or reach out to us via chat for assistance',
          buttonLabel: 'Start chat',
        },
      },
      actions: {
        createFirst: 'Add your first bill',
        edit: 'Edit',
        pay: 'Schedule this bill payment',
        refresh: 'Refresh',
        delete: 'Delete',
        markAsPaid: 'Mark as paid',
        undoMarkAsPaid: 'Paid',
        undoMarkAsPaidMobile: 'Make unpaid',
        save: 'Save',
        contactSupport: 'Contact support',
        retryPayment: 'Change payment method',
        retryPaymentDelivery: 'Edit delivery details',
        resendPayment: 'Resend payment',
        decline: 'Decline',
        approve: 'Approve',
        reschedule: 'Reschedule this bill payment',
        trackDelivery: 'Track Delivery',
        rejectPaymentRequest: 'Reject',
        schedulePaymentRequest: 'Schedule payment',
      },
      emptyState: {
        title: 'How paying your vendors works',
        btnText: 'Add a bill',
        btnZeroStateText: 'OK. Got it',
        btnHint: 'You have 0 bills in your inbox.',
        text: 'No bills here',
        search: 'Oops! No results found',
        textPaid: 'You have no paid bills',
        textScheduled: 'You have no scheduled bills',
      },
      form: {
        total: 'Total to pay',
        billAmount: 'Bill amount',
        totalAmount: 'Total amount',
        totalAmountPlaceholder: '$0.00',
        vendor: 'Vendor name',
        accountIdentifier: 'Account number',
        vendorNoOptions: 'Enter a vendor name to add it to your list',
        vendorPlaceholder: 'Enter vendor',
        vendorAccNum: 'Account no.',
        vendorAccNumPlaceholder: 'e.g 012078200',
        vendorAccNumEmpty: 'No Account Number',
        vendorAccNumHint: 'Unique to your company. Typically found on invoices.',
        receivedProduct: {
          question: 'A new text will be provided 1',
          instruction: 'To avoid delays please include invoices for payments that exceed $10,000',
          no: 'Not yet',
        },
        partialPayments: {
          linkedPayments: 'Linked payments',
          total: 'Total amount',
          openBalance: 'Open balance',
          partialPaymentStatus: '{status} {preposition} {date}',
          preposition: {
            for: 'for',
            on: 'on',
          },
          current: '(Current)',
          reviewAndResolve: 'Review & Resolve',
          review: 'Review',
          markUnpaid: 'Mark unpaid',
          viewDetails: 'View details',
          status: {
            failed: 'Failed',
            declined: 'Declined',
            scheduled: 'Scheduled',
            manuallyPaid: 'Marked as paid',
            paid: 'Paid',
            externallyPaid: 'Amount paid in Quickbooks',
          },
          markAsPaid: {
            title: 'Enter an amount to mark as paid',
            intuitLinkedTitle: 'Mark bill as paid',
            amountLabel: 'Amount paid',
            description:
              'You asked to mark as paid <sb>bill {invoiceNumber}</sb>. What is the amount you paid and which <sb>Quickbooks</sb> account was used to make that payment?',
          },
          tooltip: 'Edit payment amount',
          validationAmountError: 'Enter an amount equal or lower than the open balance ({paymentsAmount})',
          errors: {
            editAmountError: 'Enter amount equal or lower than the bill open balance',
            enterAmount: 'Enter a payment amount',
          },
        },
        invoiceNumber: 'Bill no.',
        invoiceNumberEmpty: 'No invoice number',
        invoiceNumberPlaceholder: 'e.g. 200000',
        deliveredBy: 'Delivered by',
        deliveredByEmpty: '',
        terms: 'Payment term',
        termsPlaceholder: 'Select terms',
        termsEmpty: 'No terms',
        description: 'Note to self',
        noteFromVendor: 'Note from vendor',
        paymentRequestInvoiceNumber: 'Invoice no.',
        descriptionEmpty: 'No note',
        descriptionPlaceholder: 'e.g. office expenses',
        dueDate: 'Due date',
        dueDatePlaceholder: 'Select due date',
        dueDateEmpty: 'No due date',
        dateIssued: 'Bill date',
        dateIssuedPlaceholder: 'Select issue date',
        dateIssuedEmpty: 'No invoice date',
        intuitAccount: 'Enter QuickBooks account',
        intuitAccountPlaceholder: 'QuickBooks category',
        intuitAccountEmpty: 'No QuickBooks account',
        attachment: {
          label: 'Image attachment',
          addAnother: 'Add another',
          remove: 'Remove',
          uploading: 'Uploading your file...',
          add: 'Add',
          addAttachmentAlt: 'Add image or file',
          upload: 'Upload file',
          replace: 'Replace file',
          retry: 'Try again',
          delete: 'Delete file',
          formatError: 'Please try to upload an image',
          error: 'Oops. Something went wrong',
        },
        lineItems: {
          title: 'Items',
          description: 'Line Item Name',
          amount: 'Amount',
          delete: 'Delete line item',
          add: 'Add new item',
          item: 'Item',
          amountPlaceholder: '$00.00',
          addItemImageAlt: 'Add item',
        },
        paymentActivity: {
          title: 'Payment activity',
          fullPaymentTitle: 'Full payment',
          failedPaymentTitle: 'Failed payment',
          checkDeposited: 'deposited',
          achDeposited: 'delivered',
          partiallyPaid: 'partially paid',
          checkDepositedDate: 'On {date}',
          debitInfoLabel: 'Using debit card ...{card4digits}',
          scheduledBill: {
            scheduleMethodAch: 'Bank account',
            scheduleMethodCreditCard: 'Credit card',
            scheduleMethodDebitCard: 'Bank account',
            deliveryPaperCheckMethod: 'Paper check delivered to',
            deliveryBankTransferMethod: 'Bank transfer to',
          },
          paidBill: {
            scheduleMethodAch: 'Bank account',
            deliveryPaperCheckMethod: 'Delivery by paper check {formattedCheckSerial}',
            deliveryBankTransferMethod: 'Delivery by bank transfer',
          },
          failedPayment: 'Failed on {date}',
          failedPaymentDescription: {
            defaultFailedToCollect:
              'This payment couldn’t be processed. Please contact billops@qbdtusers.melio.com to learn more and resolve this issue.',
            exceedsWithdrawalAmountLimit:
              'This card {fundingSource} has a limit preventing this payment from being processed. Change your payment method or ask the card’s issuer to remove or modify your card’s withdrawal limit to reschedule the payment.',
            defaultFailedToDeliver:
              'This payment couldn’t be delivered to your vendor. Please contact billops@qbdtusers.melio.com for assistance.',
            cardNetworkRestrictions:
              'Payment failed due to card network restrictions. Please connect another payment method and retry payment to complete this payment.',
            paymentNotAllowedConsumer: 'Sorry but Melio doesn’t support consumer payments.',
            paymentNotAllowedVendorDetails: 'Payment failed because we were unable to verify your vendor’s details.',
            paymentNotAllowedPayroll: 'Sorry but Melio doesn’t support payroll transactions at the moment.',
            retrySupportedFundingSource: 'Please retry the payment with a payment method we support.',
            paymentNotAllowedOther: 'We were unable to process this payment.',
            paymentNotAllowedTechnical:
              'Payment failed because of an issue with the information you added. Please contact our team at billops@qbdtusers.melio.com or on the chat below.',
            paymentNotAllowedDuplicate:
              'Payment failed because it appears to be a duplicate of another payment you’ve already scheduled. Please contact support if this isn’t a copy or mistake.',
            unilateralDeliveryMethodNotProvided: 'Vendor’s payment details were not provided',
            doNotHonor:
              'This card {fundingSource} seems to have been blocked, possibly due to a card limit or fraud concern. Change your payment method or ask your card’s issuer to remove the "card issuer block" to reschedule the payment.',
            insufficientFunds:
              'This bank account {fundingSource} has insufficient funds for this payment. You can either add funds to your bank account or change your payment method to reschedule the payment.',
            balancesNotAvailable: 'Payment failed due to insufficient funds for this payment.',
            incorrectVendorACH:
              'Please correct the payment receiver’s routing number (ACH/direct deposit, not wire) and notify us at support@qbdtusers.melio.com so we can quickly process this payment.',
            accountFrozen:
              'This bank account {fundingSource} seems to be frozen. Change your payment method to reschedule the payment.',
            userBlockedMelioAccount:
              'This payment has been blocked by your bank. Change your payment method or ask them to remove their block to resend the payment.',
            receiverAccountClosed:
              'The bank account used for this payment’s delivery appears unable to receive payments. Change to another delivery method to resend this payment.',
            noBankAccount:
              'Your vendor’s delivery details seem to be outdated. Change to another delivery method to resend this payment.',
            invalidBankAccountNumber:
              'Your vendor’s bank account number seems to be incorrect. Change to another delivery method to resend this payment.',
            routingNumberIncorrect:
              'Your vendor’s routing number seems to be incorrect. Change to another delivery method to resend this payment.',
            receiverRefusedCreditEntry:
              'This payment couldn’t be delivered because its recipient has declined it. Resolve this with {vendorCompanyName} before resending the payment.',
            nonTransactionAccount:
              'This payment’s delivery method seems unable to receive payments. Change to another delivery method to resend this payment.',
            uncollectedFunds:
              'This payment could not be processed as there aren’t enough accessible funds in your account. Change your payment method or try again once there are sufficient accessible funds.',
            invalidTransaction:
              'This payment failed to transfer, which may have been caused by a number of reasons. Change your payment method or contact your card issuer to resolve this and reschedule the payment.',
            paymentStoppedByVendor:
              'You stopped this payment with your bank. Please contact billops@qbdtusers.melio.com to resolve this issue.',
            bankAccountUnauthorized:
              'This payment has been put on hold due to the bank account owner reporting it as unauthorized. Change your payment method or contact the bank to authorize it and reschedule the payment.',
            unauthorizedDebitToConsumerAccount:
              'This payment wasn’t delivered as it was being transferred to a consumer bank account. Please contact billops@qbdtusers.melio.com to resolve this issue.',
            internationalFailedToDeliver:
              'This payment couldn’t be delivered to your vendor. Please contact billops@qbdtusers.melio.com for assistance.',
            default:
              'Payment failed due to card network restriction. Please connect another payment method and retry to complete this payment.',
            returnedCheck:
              'USPS return reason: "No mailbox, check returned as undeliverable". Resend the payment by ACH or reissue the check.',
            virtualCardExpired:
              'The vendor didn’t clear the card payment within 30 days. Resend payment with a new single-use card or as a bank transfer.',
            virtualCardExpiredWithSupport:
              'The vendor didn’t clear the card payment within 30 days. Please reach out to support at {supportEmail} to resolve the issue.',
          },
          deliveryTitleETA: 'Delivery ETA',
          checkDeliveryTitleETA: 'Check was deposited on',
          deliveryVirtualDescription: 'Estimated payment delivery',
          deliveryVirtualAdditionalHint:
            'You’ll be updated with a final delivery date once payment details are received.',
          deliveryTitleAt: 'Delivery by',
          deliveryMethodAdditionalDescription: 'By 8 PM vendor’s local time',
          deliveryMethodAdditionalDescriptionPayee: 'By 8 PM local time',
          processed: 'Processed on {date}',
          actions: {
            edit: 'Edit',
            cancel: 'Cancel',
            cancelPayment: 'Cancel payment',
            deleteBill: 'Delete bill',
            deletePayment: 'Delete payment',
            retryPayment: 'Retry payment',
            markAsPaid: 'Mark bill as paid',
          },
          editLink: 'Edit',
          futureProcessedDate: 'Payment will be deducted on',
          processedDate: 'Payment was processed on',
          paidFromMy: 'Paid from my',
          payFromMy: 'Pay from my',
          vendorReceive: 'Vendor receives',
          vendorReceived: 'Vendor received',
          logs: {
            actionDate: 'On {date}',
            approved: {
              title: 'Payment approved by',
            },
            declined: {
              title: 'Payment declined by',
              reasonTitle: 'Decline reason or note',
            },
            scheduled: {
              title: 'Payment scheduled by',
            },
          },
        },
        deleteDialog: {
          title: 'Delete bill {invoiceNumber} from {companyName}?',
          subtitle: 'Warning: this cannot be undone.',
          confirm: 'Yes, delete this bill',
        },
        markAsPaidDialog: {
          dialogTitle: 'Which account was used for this payment?',
          intuitAccountLabel: 'Bank account in QuickBooks',
          intuitAccountPlaceholder: 'Select account',
          dialogOkButtonText: 'Save',
        },
        blockedForPaymentDialog: {
          title: 'Blocked at vendor’s request',
          subtitle:
            'This vendor has requested they not be paid with Melio while the NYSLA evaluates Melio’s credit card payment feature.{br}{br} Feel free to contact them or our support team at {supportEmail} for further clarification.',
        },
        declineDialog: {
          title: 'Payment declined.{br}Let {userName} know why you’ve declined this payment.',
          noteTitle: 'Decline reason or note',
          placeholder: ' ',
          buttonLabel: 'Submit and decline',
        },
        nonDeletableDialog: {
          title: 'We’re sorry, this bill can not be deleted',
          subtitle:
            'This bill can’t be deleted since payment for it is already in progress.{br}{br}Please contact support by chatting with us or emailing {supportEmail}',
          ok: 'Ok. Got it',
        },
      },
      status: {
        paymentScheduled: 'Payment scheduled',
        paymentPaid: 'Fully paid',
        paymentManuallyPaid: 'Marked as paid',
        paymentFailed: 'Payment failed',
        paymentPendingApproval: 'Pending approval',
        paymentDeclined: 'Payment declined',
        paymentFailedActionRequired: 'Action required',
        paymentFailedResend: 'Resend payment',
        cantSchedulePayment: {
          title: 'Can’t schedule a payment',
          description: '{vendorName} can not receive your payment at the moment. Please contact support.',
        },
        virtualCardExpired: 'Card expired',
      },
      view: {
        title: 'Bill details',
        mobileTitle: 'Payment details',
        headerTitle: 'New Bill',
        noInvoiceNumber: 'No invoice number',
        numBills: '{number} bills',
        columns: {
          invoiceNumber: 'Bill #',
          dueDate: 'Due date',
          amount: 'Amount',
        },
        due: 'Due {date}',
        scheduledOn: 'Scheduled for {date}',
        deliveryEta: 'Delivery ETA {date}',
        pendingInformation: 'Pending vendor’s information',
        partiallyPaid: 'Total bill amount',
        processed: 'Paid on {date}',
        invoiceNumber: 'Invoice #{invoiceNumber}',
        markAsPaidInfo: 'Marked as paid on {date}',
        markAsPaidAcountInfo: 'Paid with {account}',
        paymentDetails: 'Payment details',
        error: {
          titleNotFound: ' Sorry, we could not locate the bill',
          subtitleNotFound:
            'You’re probably looking for something else. You can find this payment’s details in your QuickBooks account.',
          buttonTextNotFound: 'Ok. Take me back',
          buttonTextBillNoMelioFound: 'Try online Bill Pay',
          notMelioBillTitle: 'This bill wasn’t paid online',
          notMelioBillSubtitle:
            'Once you pay a bill online, you will be able see the online payment details on this page. To make an online payment, go to a bill page and look for the “Pay bill online” button.',
          notSupportedVendorTitle: 'This vendor cannot be paid with Bill Pay',
          notSupportedVendorSubTitle: 'At the moment this vendor is not supported for payments with Bill Pay',
          notSupportedVendorSubButton: 'Ok, Take me back',
        },
      },
      request: {
        description:
          '<b>{vendorName}</b> has sent you a payment request of <b>{amount, number, currency}</b> for invoice <b>{invoiceNumber}</b>.',
        trustVendor: 'Add their future payment requests too',
        addButton: 'Add bill to inbox',
        removeButton: 'Remove',
        addNotification: '1 Payment request was added to your inbox {undo}',
        removeNotification: 'Payment request removed {undo}',
        note:
          'This bill has been added to your inbox because <b>{vendor}</b> requested a payment from you. You can reject the bill if you don’t recognize it.',
      },
      edit: {
        title: 'Bill - edit mode',
        cancel: 'Cancel',
        save: 'Save',
      },
      pay: {
        sectionTitle: 'Paying {companyName} for invoice {invoiceNumber}',
        amount: {
          title: 'Confirm amount to pay',
          notice: 'Bill total ',
        },
        deliveryMethods: {
          title: 'How would <em>{vendor}</em> like to receive this payment?',
          unilateralLink: 'Ask vendor for payment details',
          unilateralHint: 'Send an email to the vendor',
        },
        editAchDeliveryMethod: {
          title: "Please edit <em>{vendor}</em>'s bank account details",
          save: 'Save new bank details',
        },
        date: {
          title: 'When would you like this payment to be deducted?',
          subtitle: 'Choose a deduction date for this payment',
          deliveryMethodBank: 'Bank transfer',
          deliveryMethodCheck: 'Paper check',
          dueDate: 'Bill due date',
          processDate: 'When you pay',
          shortProcessDate: 'Process date',
          deliveryDate: 'When vendor receives',
          ach: {
            deliveryMethod: '(by 8 PM vendor’s local time)',
          },
          deliveryOptions: {
            price: {
              default: '(Free)',
              dollar: '({fee})',
              freeCheck: 'via <b>USPS</b> (Free)',
              defaultCheck: 'via <b>USPS</b> ($1.50)',
              dollarCheck: 'via <b>FedEx</b> ({fee})',
              dollarUSPSCheck: 'via <b>USPS</b> ({fee})',
              fastTag: 'Fast',
            },
            fastAchDates: {
              today: 'Today, ',
              date: '{date}',
              free: '{date}',
            },
            subTitle: {
              'expedited-ach': 'Same day delivery',
              'overnight-check': 'Next business day',
              'express-check': '3 business days',
              ach: '3-4 business days (ACH)',
              achBelow100K: '3 business days',
              achOver100K: '4 business days',
              achDebit: '1 business day',
              achCredit: '1 business day',
              achCard: '{n} business days',
              rppsCreditOrDebit: '2 business days',
              rppsAch: '4 business days',
              check: '5-7 business days',
              virtual: '3-7 business days',
              virtualCardACH: '3 business days',
              virtualCardCreditOrDebit: '1 business day',
            },
            title: {
              today: 'Today',
              tomorrow: 'Tomorrow',
              date: '{date}',
            },
            description: {
              sameDay: 'Same business day',
              nextDay: 'Next business day',
            },
            deliveryExplanation: 'Vendor receives on {date}',
            deliveryExplanationCheck: '{indication} Arriving on {date}',
            deliveryExplanationCheckIndication: 'Trackable.',
            sameDayDeliveryExplanation: 'Vendor receives today ',
            default: 'Free',
          },
          check: {
            deliveryMethod: 'Paper checks take 5-7 business days',
          },
          billDueDate: 'Bill due <sb>{date}</sb>',
          billScheduledDate: 'Deducted <sb>{date}</sb>',
          cardExpiredLabel: '{cardNetwork} ...{card4digits} expires in {expirationDate}',
          estimatedDelivery: 'Estimated payment delivery',
          deliverySpeed: 'Choose delivery speed',
          chooseArrival: 'Choose when this payment arrives',
          paymentDeliveryDate: 'Payment delivery date',
          unilateralPaymentDescription:
            'You’ll be updated with a final delivery date once payment details are received.',
          paymentDatePickerDescription: {
            unverifiedBankAccount:
              'To pay on schedule: verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
            unilateral:
              'To pay on schedule: your vendor needs to select how to receive payments by <sb>{date} at 12pm EST</sb>',
          },
        },
        fundingSource: {
          title: 'Choose how you want to pay',
          subtitle: 'Connect a bank account or add a card.',
          debitLabel: '{institutionName, select, undefined{} other{{institutionName} }} ...{identifier}',
          creditDescription: 'Credit. {fees_credit_value}% Fee',
          partialPayment: {
            title: 'Pay {companyName}',
            subtitle: 'Choose how you want to pay:',
          },
          creditLabel: '{institutionName, select, undefined{} other{{institutionName} }} ...{identifier}',
          selectedDebitLabel: 'Using debit card ...{identifier}',
          achLabel: '{institutionName, select, undefined{} other{{institutionName}, }}{displayName} ...{identifier}',
          debitDescription: 'Free',
          achDescription: 'Use your bank account as a payment method.',
          expired: ' Expired',
          expiredDescription: 'Please add a new card',
          expirationDateLabel: ' Expires {expirationDate}',
          warningMessage:
            'FYI - the selected payment method couldn’t transfer a payment on {date}. Make sure that issue has been resolved before you continue.',
          redirectedFromBatchCard: {
            title: 'Important note',
            subtitle: 'This is the only bill eligible for payment. The other selected bills are already scheduled.',
          },
          billsExceedAmountLimitShown: {
            title: '{count} of your bills {count, plural, one {exceeds} other {exceed}} the sending limit',
            subtitle:
              'Bill payments exceeding the sending limit were removed. Currently, you can only schedule bill payments for up to $1,000,000.00.',
          },
        },
        fastPayment: {
          title: 'Want your payment delivered sooner?',
          subtitle: {
            fastAch:
              'This payment’s new delivery date is <b>{deliveryDate}</b>. If you’d like it to arrive sooner, you can expedite it with a same-day delivery.',
            fastAchPastDueDate:
              'This payment’s new delivery date is <b>{deliveryDate}</b>, which is past the bill’s due date of <b>{dueDate}</b>. Easily expedite this payment’s arrival with a same-day delivery.',
            fastCheck:
              'This payment’s delivery is now <b>{deliveryDate}</b>. Easily expedite this payment’s arrival with a faster delivery option.',
            fastCheckPastDueDate:
              'This payment’s delivery is now <b>{deliveryDate}</b>, which is past the bill’s due date of <b>{dueDate}</b>. Easily expedite this payment’s arrival with a faster delivery option.',
          },
        },
        memo: {
          title: 'Leave a note for your vendor',
          inputTitle: 'Leave a note for your vendor',
          placeholder: 'Account ID#, payment description',
          hint: 'Will appear on the payment notifications they receive',
        },
        expedite: {
          title: 'Have you received the goods or services you’re paying for?',
          subtitle: 'Please specify whether you’ve already received the goods or services related to this payment.',
          meliomeSubtitle:
            'Please specify whether you’ve already received the goods or services related to this payment.',
          dragAndDrop: 'Include a file attachment',
        },
        confirm: {
          title: 'Review & confirm',
          addMemo: 'Memo to vendor',
          emptyMemoLabel: 'No memo to vendor',
          placeholder: 'Add memo to vendor here',
          amountField: 'Bill total',
          vendorField: 'Vendor',
          businessName: 'Business name',
          accountIdentifier: 'Account number',
          total: 'Amount',
          payTo: 'Pay to',
          paidBy: 'Paid by',
          invoiceNumberField: 'Invoice #',
          billNumberField: 'Bill #',
          deliveryMethodField: 'Delivery method',
          dateField: 'Process date',
          deliveryDate: 'Delivery ETA',
          fundingSourceField: 'Payment method',
          memoField: 'Memo',
          ach: 'Bank transfer',
          check: 'Paper check',
          fee: 'Transaction fee',
          feeLabel: '({amount} fee)',
          feeHint: `({finalAmount, select,
              0 {}
              other {Originally <private>{baseAmount, number, currency}</private>. }
            }Saving <private>{savingAmount, number, currency}</private> in Credit Card fees.{expiration, select,
              null {)}
              other {{br} Applies for payments processed by {expiration, date, medium})}
            }`,
          feeTerm: `{showAmount, select,
              true {{basePercent, number, percent} of payment. Charged separately}
              false {Billed separately once payment has completed}
            }`,
          feePromoBase: '{basePercent, number, percent} credit card fee applies',
          feePromoReduction: `{promotionName, select,
            COVID_APRIL{April COVID-19 promotion}
            AMEX_STAND_FOR_SMALL{Stand For Small promotion}
            other{Promotion}
          }`,
          feePromoReductionHint: 'Applies for payments processed by {expiration, date, medium}',
          absorbedFee: 'Free for this payment!',
          totalFee: 'Total fee',
          'expedited-ach': {
            feeDetails: 'Same day delivery ({fee}% fee)',
            feeDetailsNoPercentage: 'Same day delivery',
            today: 'Today, ',
            totalFeeDescription: 'Charged separately to your account',
          },
          creditCardFee: 'Credit card fee <private>{fee}</private>',
          debitCardFee: 'Debit card fee <private>{fee}</private>',
          'overnight-check': {
            feeDetails: 'Next business day delivery',
          },
          'express-check': {
            feeDetails: '3 business days delivery',
          },
          edit: 'Edit',
          action: 'Confirm and schedule payment',
          confirmTerms: 'By clicking confirm and schedule I give permission to charge my {type} on {date} for {amount}',
          error: {
            title: 'This payment can’t be scheduled',
            CTA: 'OK',
            approvingPaymentTitle: 'Sorry, something went wrong',
            approvingPaymentSubtitle:
              'We had trouble receiving the data to approve your payment in real-time. We’ve saved your payment details and we’ll send you an email as soon as the payment is approved.',
            approvingPaymentCTA: 'Ok. I’ll check back later',
          },
          completeLegalInfo: {
            title: 'One last thing...',
            titleLoading: 'Scheduling payment...',
            subtitle:
              'Please complete these missing details from your QuickBooks account to finalize your account and schedule your payment.',
            subtitleLoading: 'it’ll just be a minute',
            action: 'Save and schedule payment',
            contactFirstName: 'First name',
            contactLastName: 'Last name',
            emptyLabelFirstName: 'No first name',
            emptyLabelLastName: 'No last name',
            legalName: {
              inputTitle: 'Your business’s legal name',
              emptyLabel: 'No legal business name',
              inputHint: 'Your full legal business name as it appears on your tax return',
            },
            legalAddress: {
              inputTitle: 'Legal address',
              emptyAddressLabel: 'No legal business address',
            },
            businessType: {
              inputTitle: 'Business type',
              emptyLabel: 'Select a type',
            },
            companyIndustry: {
              inputTitle: 'Industry the business operate in',
              emptyLabel: 'Select industry',
              stickyLabel: 'Use <b>{value}</b> as your industry',
            },
            dateOfBirth: {
              inputTitle: 'Date of Birth',
              emptyLabel: 'MM-DD-YYYY',
            },
            taxId: {
              inputTitle: 'Tax ID number',
              emptyLabel: 'No Tax ID',
              placeholders: {
                default: 'XX-XXXXXXX',
                EIN: 'XX-XXXXXXX',
                ITIN: '9XX-XX-XXXX',
                SSN: 'XXX-XX-XXXX',
              },
            },
            taxIdType: {
              inputTitle: 'Select your tax ID type',
              emptyLabel: 'No Tax ID type',
            },
            companyAddress: {
              inputTitle: 'Company address',
            },
            phone: {
              placeholder: 'Phone number',
              emptyLabelPhone: 'No phone number',
            },
          },
          completeComplianceInfo: {
            title: 'Important: Additional Business Information Required',
            titleLoading: 'Scheduling payment...',
            subtitle:
              'Please provide your business details below. You can skip this step for up to three payments. After three payments, providing this information will be mandatory to continue using Bill Pay.',
            subtitleLoading: 'it’ll just be a minute',
            action: 'Save and schedule my payment',
            batchAction: 'Save and schedule my payments',
            skipAction: 'Skip and schedule my payment',
            batchSkipAction: 'Skip and schedule my payments',

            businessType: {
              inputTitle: 'Business type',
              emptyLabel: 'Select a type',
            },
            companyIndustry: {
              inputTitle: 'Industry the business operate in',
              emptyLabel: 'Select industry',
              stickyLabel: 'Use <b>{value}</b> as your industry',
            },
            afterComplianceGracePeriod: {
              title: 'Action Required to Reactivate Bill Pay',
              titleLoading: 'Scheduling payment...',
              subtitle:
                'We noticed you haven’t provided your business information after three payments. To ensure uninterrupted service, please provide the requested details below. Once submitted, your Bill Pay access will be restored.',
              subtitleLoading: 'it’ll just be a minute',
              action: 'Save and continue',
            },
          },
          checkFee: {
            title: 'Paper check fee',
            promotionsLeft: 'You currently have <sb>{available}</sb> of <sb>{total}</sb> monthly free checks left',
            feeCharge: 'Charged separately to your account',
            feeChargeAnotherSource: 'Billed separately to the payment method for paper checks.',
            noCharge: 'No additional fees',
          },
        },
        success: {
          titles: {
            regular: 'Payment scheduled!',
            pendingApproval: 'Payment scheduled & pending approval!',
            fastAch: 'Payment scheduled!',
          },
          subtitles: {
            'expedited-ach': {
              regular:
                'Your <b>{amount}</b> expedited payment was successfully scheduled to be processed on <b>{processDate}</b>.',
              pendingApproval:
                'Your <b>{amount}</b> expedited payment to <b>{vendorName}</b>{br}was successfully scheduled and is pending your admin approval.',
            },
            regular: 'Your <b>{amount}</b> payment was successfully scheduled to be processed on <b>{processDate}</b>.',
            pendingApproval:
              'Your <b>{amount}</b> payment to <b>{vendorName}</b>{br}was successfully scheduled and is pending your admin approval.',
          },
          notes: {
            title: '<b>What’s next?</b>',
            unilateral:
              'This payment’s estimated delivery date is between {deliveryDate} but is dependent on <b>{vendorName}</b> selecting their preferred payment delivery method.',
            unilateralPendingApproval:
              'This payment’s estimated delivery date is between <b>{deliveryDate}</b> but is dependent on the payment’s approval by an Admin. Once approved, <b>{vendorName}</b> will be asked to enter their payment delivery details.{br}{br} After the payment has been approved and then finalized by <b>{vendorName}</b> with delivery details, you’ll be updated with an accurate payment delivery ETA.',
            microDeposit:
              'While scheduled, this payment will not be processed until you’ve verified your bank account (micro-deposits are usually sent within two business days).',
            microDepositPendingApproval:
              'While scheduled, this payment will not be processed until it’s approved by an Admin and you’ve verified your bank account (micro-deposits are usually sent within two business days).',
          },
          buttonLabel: {
            done: 'Done',
            toDashboard: 'Ok, return to dashboard',
            notifyVendor: 'Notify my vendor',
          },
          doneHere: 'I’m done here',
        },
        payBillSuccess: {
          titles: {
            regular: 'Payment scheduled!',
          },
          subtitles: {
            regular: 'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> is scheduled for <sb>{processDate}</sb>.',
            regularWithNotify:
              'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> is scheduled for <sb>{processDate}</sb>. We’ll notify them once it’s on the way.',
            expedited:
              'Your <sb>{amount}</sb> expedited payment was successfully scheduled to be processed on <sb>{processDate}</sb>.',
          },
          notes: {
            qbo: 'This payment will be synced to your QuickBooks',
          },
          buttonLabel: {
            done: 'Thanks, I’m done here',
            notifyVendor: 'Notify my vendor',
            toDashboard: 'Great, go to my dashboard',
          },
          doneHere: 'Thanks, I’m done here',
        },
        collectVendorEmailModal: {
          title: 'Let {vendorName} know the payment is on its way!',
          successTitle: '{vendorName} will be notified once this payment has been transferred',
          successSubTitle: 'We’ve also saved their email to your account for your future payments to them.',
          confirm: 'OK. I’m done here',
          subTitle:
            'Once your payment goes out we’ll email {vendorName} with your payment’s details to let them know when and how to expect it.',
          pending: '<b>Note:</b> This email will be sent only after the payment is approved by your admin.',
          sendEmail: 'Send email notification',
          emailLabel: 'Vendor’s business email',
          emailPlaceholder: 'Email address',
        },
        billAmountLimitError: {
          title: 'Bill amount exceeds the sending limit',
          subtitle: 'Currently, you can only schedule bill payments for up to <b>$1,000,000.00.</b>',
          okButton: 'Go back and edit bill',
        },
      },
      approval: {
        approveSuccess: 'Payment approved and scheduled',
        declineSuccess: 'Payment declined',
      },
      labelPaidTab: {
        title: 'Don’t see all your paid bills?',
        subTitle: 'If you marked bills as paid on the web, head to the web to see them.',
      },
    },
    getPaid: {
      link: {
        seen: 'Seen on {date, date, medium}',
        unseen: 'Unseen',
      },
      view: {
        send: 'Send payment request',
        header: {
          subtitle: 'Invoice #{invoiceNumber}',
        },
        statuses: {
          paid: 'Fully paid',
          markedAsPaid: 'Marked As Paid',
          failed: 'Payment failed',
          scheduled: 'Payment scheduled',
          inProgress: 'Payment being processed',
          cancelled: 'Cancelled by customer',
        },
        invoice: {
          title: 'Invoice details',
          amount: 'Amount',
          customerName: 'Customer name',
          invoiceNumber: 'Invoice #',
          dueDate: 'Due date',
          dueDateEmpty: 'No due date',
          markAsPaid: 'Mark as paid',
          undoMarkAsPaid: 'Change to unpaid',
          edit: 'Edit',
          delete: 'Remove',
          deleteMessage: {
            title: 'Remove this invoice from Melio?',
            description:
              'Are you sure about removing this <b>{totalAmount, number, currency}</b> invoice to <b>{companyName}</b>?',
            confirm: 'Yes, remove this invoice',
            cancel: 'No thanks',
          },
        },
        paymentRequest: {
          title: 'Request details',
          link: 'Request link',
          email: 'Sent to email',
          emailEmpty: 'No email',
          note: 'Note to customer',
          noteEmpty: 'No note',
          fee: 'Your fee',
          feeDescription:
            'A {fees_credit_value}% fee applies for this incoming credit card payment. It’ll be issued to you (as set in your preferences) once it’s received.',
          paidFeeDescription: '{fees_credit_value}% were charged separately for service fees. {downloadReceiptLink}',
          downloadReceiptLink: 'Download receipt',
          edit: 'Edit',
          delete: 'Cancel',
          deleteMessage: {
            title: 'Cancel request?',
            description:
              'Are you sure about cancelling your <b>{totalAmount, number, currency}</b> payment request already sent to <b>{companyName}</b>?{br}{br}{removeInvoice}',
            confirm: 'Yes, cancel this request',
            cancel: 'No thanks',
            removeInvoice: 'Also remove this invoice from Melio',
          },
        },
        payment: {
          title: 'Payment details',
          total: 'Total payment',
          failedTotal: 'Failed payment',
          delete: 'Remove',
        },
        batch: {
          title: {
            unsent: '{numOfInvoices} {numOfInvoices, plural, one {invoice} other {invoices}} selected',
            sent: '{numOfInvoices} payment {numOfInvoices, plural, one {request} other {requests}} selected',
            failed: '{numOfInvoices} failed payment {numOfInvoices, plural, one {request} other {requests}} selected',
          },
          description: 'with a total amount of {totalAmount, number, currency}',
          send: 'Send payment requests',
          completeLegalInfo: {
            title: 'One last thing...',
            subtitle:
              'Before requesting your first payment, please enter your company’s tax information as it’s legally registered.',
            action: 'Complete and save',
          },
          delete: {
            unsent: 'Remove',
            sent: 'Cancel',
            failed: 'Remove',
          },
          checkboxes: {
            selectAll: 'Select all',
            clear: 'Clear',
          },
          unsentInvoiceForm: {
            email: 'Customer’s email',
            note: 'Memo to customer',
          },
          deleteMessage: {
            unsent: {
              title:
                'Remove {numOfInvoices, plural, one {this} other {these}} unsent {numOfInvoices, plural, one {invoice} other {invoices}} from Melio?',
              description:
                "Are you sure about removing {numOfInvoices, plural, one {this} other {these}} <b>{numOfInvoices} unsent {numOfInvoices, plural, one {invoice} other {invoices}}</b> from Melio? Altogether {numOfInvoices, plural, one {it's} other {their}} total amount is <b>{totalAmount, number, currency}</b>",
              confirm: 'Yes, remove unsent invoices',
              cancel: 'Cancel',
            },
            sent: {
              title:
                'Cancel {numOfInvoices, plural, one {this} other {these}} payment {numOfInvoices, plural, one {request} other {requests}}?',
              description:
                'Are you sure about cancelling {numOfInvoices, plural, one {this} other {these}} <b>{numOfInvoices} payment {numOfInvoices, plural, one {request} other {request}}?</b> Altogether {numOfInvoices, plural, one {it} other {they}} amount to <b>{totalAmount, number, currency}</b>{br}{br}{removeInvoices}',
              confirm: 'Yes, cancel requests',
              removeInvoices: 'Also remove invoices from Melio?',
              cancel: 'Cancel',
            },
          },
          mobile: {
            title: 'Send Payment requests',
            errorToast: '{count} of your payment requests are missing customer’s email. Fix it to send all requests.',
            errorToastSingle: '1 Payment request is missing customer’s email. Fix it to send all requests.',
          },
          sendMessage: {
            success:
              '{count} payment {count, plural, one {request} other {requests}} ' +
              '{count, plural, one {was} other {were}} shared',
            error: 'Failed to share payment requests',
          },
        },
      },
      emptyState: {
        search: 'Oops! No results found',
        createLink: {
          title: 'Get paid the Melio way',
          text: 'Connect your bank account and send a payment request to receive invoice payments quickly for free',
          action: 'Start getting paid',
        },
        requests: {
          first: {
            title: 'Create, track, get paid.',
            text:
              'Create a trackable payment request link from an invoice. Share it with a customer to enable them to pay you quickly and easily for free.',
            action: 'Create your first request',
          },
          notFirst: {
            title: 'No unsent payment requests',
            text: 'Sending new payment requests is just a few clicks away...',
            action: 'Create new request',
          },
          unsent: {
            text:
              '<b>Unsent payment requests will wait here</b>{br}If you create payment requests but don’t send them right away, they’ll be right here.',
          },
          sent: {
            text:
              '<b>Payment requests will be here once sent</b>{br}Already-sent payment requests will move over to here.',
          },
        },
        scheduled: {
          text: 'You don’t have any In-progress payments yet',
        },
        paid: {
          text: 'You don’t have any paid payments yet',
        },
      },
      edit: {
        save: 'Save',
        cancel: 'Cancel',
        changeNotificationMessage: {
          title: 'Your customer will be notified',
          description:
            'Since you’ve already shared this payment request with <b>{customerName}</b>, we’ll send them an email to let them know about your recent update.',
          discard: 'Discard changes',
          confirm: 'Notify my customer',
        },
      },
      new: {
        create: {
          title: 'Add an invoice to create your payment request',
          subTitle: 'Choose your preferred way to add invoices.',
          importInvoiceFile: 'Upload invoice file',
          importInvoiceFileDescription: 'Add an invoice from your device and scan its details',
          addInvoiceManually: 'Add invoice manually',
          addInvoiceManuallyDescription: 'Enter an invoice’s details',
          connectAccountingSoftware: 'Connect your accounting software {newTag}',
          connectAccountingSoftwareDescription: 'Import and sync all your invoices',
          newTag: 'new',
          dragAndDrop: 'Drag and drop here to upload your invoice image/file or <a>browse</a>',
          dragAndDropMobile: 'Click here to upload a file',
          importInvoicesList: 'Upload a list of invoices',
          importInvoicesListDescription: 'Select a CSV file of invoices from your device',
          importInvoicesListLink: 'Which details are required?',
          form: {
            title: 'Invoice details',
            customerLabel: 'Customer name',
            customerPlaceholder: 'Enter customer’s name',
            customerAddInSelect: 'Add <sb>{value}</sb> as a new customer',
            customerNoOptions: 'Enter a customer name to add it to your list',
            amountLabel: 'Amount',
            amountPlaceholder: '$0.00',
            invoiceLabel: 'Invoice number',
            invoiceNumberPlaceholder: 'e.g. 200000',
            dueDateLabel: 'Due date',
            dueDatePlaceholder: 'Select due date',
            submitLabel: 'Save and send as a request',
            saveAndClose: 'Save and close',
          },
          batch: {
            column: {
              title: 'Which column contains the {columnName}?',
            },
            columnExplain: {
              title: 'Tell us which column is where',
              subtitle:
                'Match each column name with the right invoice detail to ensure your invoices are added correctly.',
            },
            columnNames: {
              companyName: 'company name',
              amount: 'amount',
              invoiceNumber: 'invoice number',
              dueDate: 'due date',
            },
            error: {
              subtitle:
                'Hmm… Seems like we can’t process that file. ' +
                'Try again with another CSV file but if you’re still having trouble please let us know by opening our ' +
                'chat or emailing {email}.',
              cancelButton: 'Ok',
            },
            loading: {
              title: 'We’re processing your invoices',
              subtitle: 'This might take up to a few minutes, thanks for your patience!',
              button: 'Cancel upload',
            },
            noInvoicesToUpload: {
              title: 'Nothing new to upload',
              subtitle:
                'Those latest invoices were in an unsupported format. {br}' +
                'If you’re having trouble, reach out to {email}.',
              button: 'Go back',
            },
            noNewInvoicesToCreate: {
              title: 'Nothing new to add',
              subtitle:
                'Those latest invoices were already added to your account. ' +
                'If you’re having trouble, reach out to {email}.',
              button: 'Go back',
            },
            invalidDate: {
              title: 'Upload failed',
              subtitle:
                'The format used in the dates column isn’t supported. {br}' +
                'Please change the date to mm/dd/yyyy format and retry.',
              cancelButton: 'Ok',
            },
            reviewColumnNames: {
              companyName: 'Company',
              amount: 'Amount',
              invoiceNumber: 'Invoice Number',
              dueDate: 'Due Date',
            },
            review: {
              title: 'Review and confirm your invoices',
              confirmButton: 'Confirm and save',
            },
            errorNotification: 'We failed to add your invoices, please try again in a few minutes',
            successNotification:
              '{numOfInvoices} {numOfInvoices, plural, one {invoice} other {invoices}} have been added to your account',
          },
        },
        paymentRequest: {
          title: 'Send a payment request',
          subTitle:
            'Send your payment request to <b>{customerName}</b> by email, or get a shareable link to send it however you would like.',
          customerEmailLabel: 'Customer’s email',
          customerEmailPlaceholder: 'customer@email.com',
          noteToCustomerLabel: 'Note to customer',
          noteToCustomerPlaceholder: 'e.g. office expenses',
          submitLabel: 'Send payment request',
          secondaryButtonLabel: 'Get shareable link',
          modal: {
            title: 'Copy your request’s link to share it with your customer',
            subTitle:
              'Share your link however you would like. You can always copy it again in the future from the payment request’s details.',
            copyAndCloseButton: 'Copy & Close',
            previewLinkText: 'Preview what your customers will see',
          },
        },
      },
      create: {
        success: 'Invoice created successfully',
        error: 'Failed to create invoice',
      },
      update: {
        success: 'Saved changes',
        error: 'Failed to update invoice',
      },
      delete: {
        error: 'Failed to delete invoice',
      },
      cancelPaymentRequest: {
        error: 'Failed to delete payment request',
      },
      share: {
        success: 'Payment request sent',
        error: 'Payment request couldn’t be sent right now',
      },
      deleteInvoices: {
        success: {
          single: 'Unsent invoice was removed',
          multi: '{count} unsent invoices were removed',
        },
        error: 'Failed to delete unsent invoices',
      },
      deletePaymentRequests: {
        success: {
          singleCancelAndRemove: 'Payment request cancelled and removed',
          singleCancel: 'Payment request was cancelled',
          multiCancelAndRemove: '{count} payment requests cancelled and removed',
          multiCancel: '{count} payment requests were cancelled',
        },
        error: 'Failed to cancel payment requests',
      },
      deleteFailedPaymentRequests: {
        success:
          '{count} failed payment {count, plural, one {request} other {requests}} ' +
          '{count, plural, one {was} other {were}} removed',
        error: 'Failed to remove failed payment requests',
      },
      filter: ' ',
      verifyBankAccount: {
        title: 'Want to see customer-initiated payments?',
        subtitle: 'View all your incoming payments, even those you didn’t request once you verify your account',
        verifyButton: 'Verify your bank account',
      },
    },
    vendorDirectory: {
      select: {
        local: 'my vendors',
        directory: "use melio's network",
      },
      duplicateNameModal: {
        title: 'You already have a vendor with the same name',
        subTitle: "Change the new vendor's name so it’s easy to tell apart from your existing vendor.\n",
        notice: "You can't save two vendors with the same name\n",
        inputField: {
          title: "New vendor's name",
          placeholder: 'Vendor name',
        },
        continueButton: 'Use new vendor',
        useExistingVendorButton: 'Use existing vendor',
      },
    },
    regularBatchPayments: {
      addPaymentMethod: 'Add a payment method',
      addAnotherPaymentMethod: '+ Add another payment method',
      addMethod: '+ Add method',
      addAnotherDeliveryMethod: '+ Add another delivery method',
      bank: 'Bank account (free)',
      creditCard: 'Credit card (2.9% fee)',
      table: {
        columns: {
          bill: 'Bill',
          vendor: 'Vendor',
          paymentMethod: 'Payment method',
          deductionDate: 'Deduction date',
          deliveryMethod: 'Delivery method',
          deliverySpeed: 'Delivery speed',
          deliveryDate: 'Delivery date',
          amount: 'Amount',
        },
      },
      cells: {
        bills: {
          billsCount: 'Show {billsCount} Bills',
          hideBills: 'Hide Bills',
          noInvoiceNumber: 'No bill number',
          invoiceNumber: '#{invoiceNumber}',
        },
        deductionDate: {
          billDue: 'Bill due',
          deducted: 'Deducted',
        },
        deliverySpeed: {
          fastAch: 'Fast ACH',
          fastCheck: 'Fast Check',
          notAvailable: 'N/A',
        },
        deliveryDate: {
          notAvailable: 'N/A',
          late: 'Late',
        },
        actions: {
          viewBillDetails: 'View bill details',
          removeFromList: 'Remove from list',
          addMemoToVendor: 'Add note to vendor',
          AddMemoToVendorMenu: 'Add memo to vendor',
          menuTooltip: 'More actions',
        },
        paymentMethod: {
          creditLabel: '...{identifier}',
          achLabel: '...{identifier}',
        },
      },
      addMemo: {
        button: 'Save and Close',
      },
      totalAmount: 'Total amount',
      totalPaymentsAmount: 'Total payments amount',
      schedulePayments: 'Schedule payments',
      scheduledPayments: 'Schedule {totalCount} bill payments',
      paymentsSettings: {
        title: 'Payments settings',
        combineBills: {
          message: 'Multiple payments to same vendor',
          toastCombined: 'Bills from the same vendor are combined and will be sent as 1 payment',
          toastSeparate: 'Bills from the same vendor will be sent as separate payments',
          tooltip: 'Multiple bill payments to the same vendor can be combined and sent as one payment.',
          combinedUnavailableTooltip:
            'Combining multiple payments into one is only available for payments going to the same vendor.',
          switch: 'Combine into one',
        },
        selectPaymentMethod: {
          message: 'Select payment method',
          multipleMethods: 'Multiple payment method',
        },
        setDeductionDate: {
          message: 'Set deduction date',
          multipleDates: 'Multiple deduction date',
          sameDate: 'The same date',
          arriveByDueDate: 'Arrive by due date',
          tooltip: 'Since multiple payment methods are selected, each deduction date must be updated individually.',
        },
      },
      walkthrough: {
        steps: {
          combineBills: {
            title: 'Combine into one',
            message: 'Bills payments to the same vendor are combined and sent as one payment. Toggle off to separate.',
          },
          selectPaymentMethod: {
            title: 'Payment method',
            message: 'Selecting a payment method here will apply to all bill payments below.',
          },
          setDeductionDate: {
            title: 'Deduction date',
            message: 'You can apply the same deduction date for all the bill payments here.',
          },
        },
        stepLabel: '{some} of {total}',
      },
      readyBillsLabel:
        '{readyBillsCount, plural, =0 {No bills are ready for payment yet} other ' +
        '{{missingDetailsBills} {missingDetailsBills, plural, one {bill is} other {bills are}} missing details}}',
      readyAllBillsLabel: 'All bills are ready for payment',
      goSettings: 'Settings',
      cancel: 'Cancel',
      scheduleBatchPayments: 'Schedule payments',
      viewErrorDetails: 'View error details',
      failedBillsList: {
        title: 'Some payments could not be scheduled',
        subtitle: 'You can review the unsuccessful payments below and reschedule them from your bills inbox',
        invoiceNumber: 'Invoice #{invoiceNumber}',
        error: 'Error: {errors}',
        errors: {
          PMT02: 'bill payment is missing details',
          PMT10: 'payment and delivery methods are identical',
          PMT14: 'unable to create unilateral payment with unverified bank account',
          PMT21: 'payment request was already fulfilled',
          PMT22: 'payment request was not found',
        },
      },
      timeoutError: {
        title: 'Well that’s unusual',
        subtitle:
          'Looks like the server is taking too long to respond. This might be caused by either poor connectivity or a heavy load on our servers. Please try again in a little bit.',
      },
      messages: {
        scheduled: '{scheduledPaymentsCount} bill payments have been scheduled!',
      },
      notifications: {
        billRemoved: '1 bill has been removed',
        success: {
          title: 'Please note that',
        },
        unreadyBillsTitle:
          '{numberUnreadyBills} {numberUnreadyBills, plural, one {bill is} other {bills are}} missing details',
        unreadyBillsSubtitle:
          'This batch payment can’t be scheduled as {numberUnreadyBills} {numberUnreadyBills, plural, one {bill is} other {bills are}} missing payment details. Add the missing details below or remove these bills to continue.',
        limitBillsTitle: 'Batch payments can only schedule {paymentLimit} payments at a time',
        limitBillsSubTitle:
          'Listed below are the first {paymentLimit} bills of the {originalNumOfBills} you previously selected',
        alreadyScheduledBillsTitle: 'Important note',
        alreadyScheduledBillsSubTitle:
          'Some selected bills were removed from the list below as they were already scheduled but not updated in QuickBooks.',
        billsExceedAmountLimitTitle:
          '{count} of your bills {count, plural, one {exceeds} other {exceed}} the sending limit',
        billsExceedAmountLimitSubTitle:
          'Bill payments exceeding the sending limit were removed. Currently, you can only schedule bill payments for up to <b>$1,000,000.00</b>.',
      },
      success: {
        title: '{scheduledPaymentsCount} payments scheduled!',
        subTitle:
          'You’ve successfully scheduled <sb>{scheduledPaymentsCount}</sb> bill {scheduledPaymentsCount, plural, one {payment of} other {payments totaling}} <sb>{totalAmount}</sb>',
        cta: 'Great, go to my dashboard',
        goDashboard: 'Go to dashboard',
      },
      fees: {
        totalFees: 'Total fees',
        transactionFees: 'Transaction fees',
        fastPaymentFees: 'Fast payment fees',
        creditCardFees: 'Credit card fees',
        debitCardFees: 'Debit card fees',
        cardFees: 'Card fees',
        additionalFees: 'Additional fees',
        cardProcessingFees: 'Card processing fees',
        checkFees: 'Paper check fees',
        achFees: 'Bank transfer fees',
      },
      tooltips: {
        fee: 'Fees will be charged to your card separately',
        fastAchTooltip: 'Enable Fast ACH for delivery within 1 business day (1% fee)',
        fastCheckTooltip: 'Enable Fast Check for delivery within 3 business days ($20 fee)',
        virtualDeliveryMethod: 'Asking vendor for delivery details',
        missingAmexMCC: 'Click here to verify that your vendor’s industry is authorized for AMEX payments',
        fees: {
          additionalFees: 'Fees incurred for fast payments',
          checkFees: 'Paper check fees are billed to the specific payment method used for their billing.',
        },
      },
      popover: {
        vendorEmail: {
          message:
            'Add the vendor’s email and we’ll send them a payment confirmation with invoice numbers for reconciliation.',
          cta: 'Add email',
        },
      },
      dialog: {
        vendorEmail: {
          title: 'Add vendor email for reconciliation',
          subtitle: 'We’ll send the vendor a payment confirmation email with invoice numbers for reconciliation.',
          input: {
            label: 'Vendor’s business email',
            placeholder: 'Email address',
            error: {
              empty: 'Please enter vendor’s email',
            },
          },
        },
        addMemo: {
          title: 'Leave a memo for your vendor',
          input: {
            memo: {
              label: 'Memo',
              placeholder: 'Account ID#, payment description',
            },
          },
        },
      },
      selection: {
        selectAll: 'Select all (max {maxSelected})',
        clearSelected: 'Clear',
        tooltip: 'Batch payments are currently limited to {maxSelected} at a time',
      },
      deliveryMethodLabels: {
        bank: 'Bank',
        check: 'Check',
        checkDescription: '{displayName}',
        bankDescription: 'Bank account ...{digits}',
        virtual: 'Pending vendor',
        virtualCard: 'Single-use card',
        virtualDescription: 'Ask vendor for details',
        chooseDM: 'Choose Delivery Method',
        direct: 'Direct',
      },
      BatchSettings: {
        title: 'Set a batch payment',
        button: 'Continue',
        billsSelected: '{billsAmount} {billsAmount, plural, one {bill} other {bills}} selected',
        totalAmount: 'Total amount',
        text: 'Select your preferred settings for this batch payment',
        paymentMethodTitle: 'Payment method',
        paymentDateTitle: 'Payment date',
        allAtOnceTitle: 'All at once',
        allAtOnceText: 'Pay all selected bills on the same day',
        dueDateTitle: 'By due date',
        dueDateText: 'Set payments to arrive just before each bill’s due date',
        drawer: {
          title: 'Batch payment settings',
          confirm: 'Apply to batch',
          cancel: 'Cancel',
        },
      },
      unsupportedResolution: {
        title: 'Screen width is too small',
        description: 'This screen resolution is not supported. Please resize the window.',
      },
    },
    getPro: {
      message: {
        matchAccount: {
          title: 'Sync your Get Paid invoices with QuickBooks',
          description:
            'To reconcile your invoices, requests, and payments, set up your payment account with QuickBooks.',
          action: 'Complete setup with QuickBooks',
        },
        verifyAccount: {
          title: 'Want to see customer-initiated payments?',
          description: 'View all your incoming payments, even those you didn’t request once you verify your account',
          action: 'Verify your bank account',
        },
      },
      tooltip: {
        directPayment: 'This method was entered{br}by the vendor',
        directPaymentWithMethod: '{method}. This method was entered{br}by the vendor',
      },
    },
    requests: {
      title: 'Get paid',
      form: {
        totalAmount: 'Total amount',
        totalAmountPlaceholder: '$0.00',
        invoiceNumber: 'Invoice number',
        invoiceNumberEmpty: 'No invoice number',
        invoiceNumberPlaceholder: 'e.g. 200000',
        description: 'Note to customer',
        descriptionEmpty: 'No note',
        total: 'Total to pay',
        customerBusinessName: 'Customer name',
        customerNoOptions: 'Enter a customer name to add it to your list',
        customerPlaceholder: 'Enter customer',
        descriptionPlaceholder: 'e.g. office expenses',
        customerEmail: 'Sent to email',
        customerEmailEmpty: 'No customer email',
        customerEmailPlaceholder: 'Your customer’s email',
        contactPhone: 'Phone number',
        contactPhoneEmpty: 'No phone',
        link: 'Request link',
        emptyLabelLink: 'no request link',
        dueDate: 'Due date',
        dueDatePlaceholder: 'Select due date',
        dueDateEmpty: 'No due date',
        dateIssued: 'Bill date',
        dateIssuedPlaceholder: 'Select issue date',
        dateIssuedEmpty: 'No invoice date',
        intuitAccount: 'Enter QuickBooks account',
        intuitAccountPlaceholder: 'QuickBooks category',
        intuitAccountEmpty: 'No QuickBooks account',
        seen: 'Seen',
        on: ' On ',
        unseen: 'Unseen',
        sent: 'Sent at ',
        notsent: 'Not sent yet',
        paymentActivity: {
          youReceived: 'You received',
          title: 'Payment details',
          paymentETA: 'Payment ETA',
          deliveryPaperCheckMethod: 'Paper check delivered to',
          deliveryBankTransferMethod: 'Bank transfer to',
          vendorReceive: 'You’ll receive',
          vendorReceived: 'You received',
          paymentDetails: 'Payment details',
          noteToCustomer: 'Note to customer',
          emptyNoteToCustomerLabel: 'No note to customer',
          noteFromCustomer: 'Note from customer',
          emptyNoteFromCustomerLabel: 'No memo from customer',
          deliveredOn: 'Delivered on',
          failedPaymentLabel: 'Can’t receive this payment',
        },
        deleteDialog: {
          title: 'Delete request {invoiceNumber} from {customerName}?',
          subtitle: 'Warning: this cannot be undone.',
          confirm: 'Yes, delete this request',
        },
      },
    },
    vendors: {
      title: 'Contacts',
      actions: {
        create: 'Create a vendor',
        newBill: 'New bill',
        newRequest: 'New request',
        unilateralRequest: 'Request payment details',
        edit: 'Edit contact',
        delete: 'Delete contact',
        save: 'Save',
      },
      emptyState: {
        btnText: 'Add your first vendor',
        text: 'Oops! No results found',
        customerText: 'You have no customers yet',
        vendorText: 'You have no vendors yet',
        search: 'Oops! No results found',
      },
      unilateralWithoutPayment: {
        collectVendorEmailModal: {
          title: 'What is <ap>{vendorName}</ap> email address?',
          subTitle: 'Enter your vendor’s email and we’ll ask how they’d like to receive your payments.',
          label: 'Vendor’s email address',
          placeholder: 'Email address',
          buttonLabel: 'Save & send request',
        },
        successNotification: 'Request sent to vendor',
        ribbon: { recommended: 'Recommended' },
      },
      view: {
        title: 'Contact details',
        emptyIndicator: 'No contact info',
      },
      edit: {
        title: 'Vendors - edit mode',
        submit: 'Edit vendor',
        done: 'Done',
        cancel: 'Cancel',
      },
      create: {
        sectionTitle: 'Add new contact',
        name: {
          title: 'A few quick details about your vendor',
          inputTitle: 'Vendor’s company name',
          customerInputTitle: 'Customer’s company name',
          inputNotice: 'The name their business is registered under',
        },
        accountIdentifier: {
          inputTitle: 'Account no.',
        },
        address: {
          inputTitle: 'Vendor’s company address',
        },
        contact: {
          title: 'Who is your main contact there?',
          inputTitle: 'Contact full name',
        },
        email: {
          title: 'What’s their email address?',
          inputTitle: 'Email address',
          inputNotice: 'The email you usually use to contact this vendor',
        },
        phone: {
          title: 'What’s their phone number?',
          inputTitle: 'Phone number',
          inputNotice: 'This helps Melio enhance payment security',
        },
        verify: {
          title: 'Everything looking good?',
        },
        success: {
          title: 'Vendor added',
          subtitle:
            'New vendor has been successfully added to your network. You can now add bills and make payments to this vendor.',
          actions: {
            schedulePayment: 'Schedule a payment',
            done: 'Done',
            confirm: 'Save',
          },
        },
      },
      deliveryMethods: {
        title: 'Delivery methods',
        new: {
          section: 'Add delivery method',
          title: 'Choose delivery method',
        },
        bank: {
          sectionTitle: 'Add delivery method - Bank transfer',
          title: 'What are <em><ap>{vendor}</ap></em> bank details?',
          titleNoVendorAlternative: 'What are your vendor’s bank details?',
          routingNumber: 'Vendor’s routing number (ACH)',
          accountNumber: 'Vendor’s account number ',
          save: 'Save bank details',
          completeAndSave: 'Complete and save',
          saveFooter:
            'These details will be used for future payments to this vendor. You can always edit them in your vendors section.',
          manuallyConnectBankTitle: 'Connect bank manually',
          manuallyConnectBankSubTitle:
            'Enter your routing and account details for bank transfers (ACH) and get your account verified in one business day.',
          selectAccountType: 'Select your account type',
          accountTypeSaving: 'Saving',
          accountTypeChecking: 'Checking',
        },
        check: {
          sectionTitle: 'Add delivery method - Paper check',
          save: 'Save check details',
          submit: 'Submit and add delivery method',
          saveFooter: 'Vendor bank details will be used for future payments. You can always change them.',
          name: {
            title: 'Who’s receiving the checks we’ll send',
            label: 'Name on check',
            notice: 'You can usually find this on the invoice',
          },
          address: {
            title: 'Where should we mail the check?',
            confirm: 'Confirm address and save',
            confirmSubTitleLine:
              'We spotted a small issue with the address you provided, and suggested an alternative below in green. Please select and/or edit the final version you’d like to use.',
            label: 'Mailing address',
            suiteLabel: 'Suite #',
            notice: 'Look on your bill to find the address of your vendor',
          },
        },
        virtual: {
          title: 'Send this payment with an email',
          subtitle:
            'Enter <ap>{vendor}</ap> email and we’ll ask how they’d like to receive this payment. Their details will also be available for your future payments.',
          email: 'Your vendor’s business email',
          phone: 'Your vendor’s mobile number',
          footer:
            'If <b>{vendor}</b> doesn’t respond to our email within seven business days this payment will be cancelled.',
          supportLink: 'What will my vendor see?',
          done: 'Save and continue',
          securityText:
            'All your account information will remain secure and hidden from <b>{companyName}</b> and other businesses.',
          checkFee: {
            title: 'Send this payment with an email',
            subtitle:
              'They can choose how to get paid for this and future payments. Bank transfers will incur a $0.50 fee, paper checks a $1.50 fee.',
            email: "Your vendor's business email",
            done: 'Submit and continue',
          },
        },
        virtualCard: {
          title: 'Single-use card',
          description: 'Free. No additional fee.',
          cardDetails:
            'Delivery by email to {email}{br}Added by the vendor on {createdDate}. <learnMoreLink>Learn more about single-use cards</learnMoreLink>',
          learnMoreModal: {
            title: 'Why has my vendor’s delivery method changed to single-use card?',
            text: `
            You noticed that a vendor’s delivery method was changed to single-use card but you didn’t do it. What does that mean?{br}{br}

            It means that your vendor has opted in for a single-use card. By opting in, they choose for all of their payments to be sent through this delivery method.{br}{br}

            Melio automatically updates your eligible vendor’s payment method to single-use virtual cards if they accept them.{br}{br}

            <b>What is it?</b>{br}
            A single-use card is a virtual credit card which has become a common payout method for Account Payable solutions, meaning, services like Melio where you pay your bills.`,
            cta: 'Got it',
          },
        },
        ach: {
          error:
            'Sorry but it seems like this link isn’t valid anymore. If you need a new link or have questions email us at: {email}',
        },
        shiftVendorToAch: {
          addAchTitle: 'Get paid from {companyName} faster, for free.',
          addAchSubtitle:
            'Enter your bank details to get your future payments deposited directly into your bank account for free, in just 1-3 business days.',
          achTitle: 'Enter your bank account’s ACH details',
          submitCTA: 'Save bank details',
          linkDeliveryMethodTitle:
            '{companyName}There’s a bank account already connected to this account, would you like to recieve this payment there?',
          success: {
            title: 'All set!',
            subtitle:
              'Your future payments from <sb>{companyName}</sb> will be directly deposited to your bank account ending in {digits}',
            contentTitle: 'Get bank deposits quickly & easily from <un>all</un> your customers, for free.',
            descriptionTitle: 'It’s simple:',
            cta: 'Join melio for free',
            goDashboard: 'Go to your dashboard',
            learnMore: 'Learn more',
            shareLabel: 'Share a payment request',
            shareDescription:
              'Melio users just upload or enter an invoice to share it with any customer as a trackable payment request.',
            getPaidLabel: 'Get paid free',
            getPaidDescription:
              'Customers click on your unique link and schedule payments from their bank for free, or even their credit card for a 2.9% fee. You receive all payments for free.',
            upsale: {
              title: 'Got it! Your bank’s details are saved',
              cta: 'Sign up for free',
              subtitle:
                'All <sb>future</sb> payments from <sb>{companyName}</sb> will now be directly deposited to your bank account ending in {digits}.',
              acceptPaymentLabel: 'Accept business payments online',
              acceptPaymentDescription:
                'Enable customers to pay you in a few clicks for free or even use their credit card (2.9% fee). Even without them signing up.',
              getPaidLabel: 'Get paid fast for free',
              getPaidDescription:
                'Stop waiting for checks! Get paid online in 1-3 business days instead, without any fees.',
            },
          },
          shiftedCurrentPayment: {
            title: 'Your bank’s details are saved',
            ownedVendorTitle: 'Your payment will be deposited',
            subtitle:
              'You’ll receive this <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>{invoiceNumber}</sb> as a bank deposit. Your details will also be saved for their future payments.',
            upsaleTitle: 'Get free bank deposits from your other customers too',
            upsaleSubTitle:
              'Join Melio to receive all your business payments quickly and easily right to your bank account.',
            learnMore: 'Learn more.',
            upsaleCTA: 'Join melio for free',
          },
        },
        shiftToDebit: {
          description: 'Debit card transaction fee 1% ({fee})',
          descriptionNoFeeValue: 'Debit card transaction fee ({fee})',
          title: 'Instantly receive this payment to your bank account',
          subtitle: 'Sent you an online payment of',
          contentSubtitle:
            'Enter your debit card details to receive this payment to its associated bank account within minutes',
          errorCard: 'We are sorry but there seems to have been a problem with adding your card. Please try again.',
          errorTitle: 'Your card was not added',
          notSupportedSubtitle:
            'Credit cards are not supported for instant payments. Payments can be received only via a debit card.',
          printName: 'Cardholder name',
          linkExistingMethod: {
            title: 'There’s already a debit card linked to this account- want to use it to receive this payment? ',
            submit: 'Receive payment to this card',
          },
          addressDetails: {
            title: 'Please add your cardholder details',
            submit: 'Submit and accept payment',
          },
          success: {
            title: 'Funds are on their way!',
            subtitle:
              'Your payment from <sb>{companyName}</sb> is on its way and should arrive within <sb>30 minutes</sb>.',
            description: '*In a case where there is an issue with your card we will notify you and suggest next steps.',
            contentTitle: 'Receive these direct deposits from your other customers too',
            contentSubtitle:
              'Join Melio to have all your payments deposited directly to your bank account quickly and easily. {learnMore}',
            upSaleCTA: 'Join melio for free',
            learnMore: 'Learn more.',
          },
          processed: {
            title: 'Payment has been processed ',
            subtitle:
              'A <sb>{amount}</sb> payment from <sb>{companyName}</sb> was sent to your account ending ...<sb>{digits}</sb>',
          },
          chatWithUs: 'chat with us',
          paymentCompletedError:
            'Sorry but this payment was already processed and therefore you can not receive this payment instantly. Need assitance? {chatWithUs}',
        },
        addressGoogle: 'To a physical address',
        addressPobox: 'To a PO box',
      },
      editCheckAddress: {
        shiftAchTitle: 'Get this {totalAmount} payment from {companyName} faster, for free.',
        shiftAchSubtitle:
          'Enter your bank details to receive this and future payments from <sb>{companyName}</sb> as bank deposits in just 1-3 business days, for free. ',
        checkSent: {
          title: 'Your check is already on the way',
          subtitle:
            'This <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>{invoiceNumber}</sb> is already on its way to you.{br}{br}If the delivery address is incorrect, contact <sb>{companyName}</sb> at <sb>{companyEmail}</sb> or {companyPhone}',
          upsaleTitle: "Get {companyName}'s future payments faster, for free.",
          upsaleSubTitle:
            'Add your bank details to get future payments deposited into your bank account for free in just 1-3 business days.',
          upsaleCTA: 'Get paid by bank deposit',
        },
        checkDeposited: {
          title: 'Your check has already been deposited ',
          subtitle:
            'This <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>{invoiceNumber}</sb> has already been deposited.',
          upsaleTitle: 'Get future payments from {companyName} faster, for free.',
          upsaleSubTitle:
            'Add your bank details to get future payments deposited into your bank account for free in just 1-3 business days.',
          upsaleCTA: 'Get paid by bank deposit',
        },
        checkNotSent: {
          form: {
            title: 'Enter a delivery address for your paper checks',
            saveCTA: 'Save and update address',
          },
          success: {
            title: 'Address updated!',
            subtitle:
              'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>{invoiceNumber}</sb> will arrive as scheduled.',
            upsaleTitle: 'Get {companyName} payments faster, for free.',
            upsaleSubTitle:
              'Add your bank details to get this and future payments deposited into your bank account for free in just 1-3 business days.',
            upsaleCTA: 'Enable bank deposits',
          },
        },
        switchedToACHAlready: {
          title: 'This payment’s delivery has been updated',
          subtitle:
            '<sb>{companyName}</sb> updated this payment’s delivery method, this <sb>{totalAmount}</sb> payment will now ne deposited directly to your bank account ending in {digits}.',
        },
        checkCancelled: {
          description:
            'Sorry but this payment has been cancelled by <sb>{companyName}</sb>. Please reach out to {companyEmail} and ask them to resend it.{br}{br}Need our help? We’re always available at {supportMail}',
        },
        checkBlocked: {
          description:
            'Sorry but this payment by <sb>{companyName}</sb> couldn’t be processed and won’t be delivered. Please reach out to {companyEmail} or email support: {supportEmail}',
        },
      },
      form: {
        vendorName: 'Vendor name',
        contactEmail: 'Email address',
        contactEmailEmpty: 'No email address',
        contactName: 'Contact person',
        contactNameEmpty: 'No contact person',
        contactPhone: 'Phone',
        contactPhoneEmpty: 'No phone',
        handle: 'Melio.me link',
        trustLabel: 'Trust this vendor?',
        trustCheckbox: 'Add this vendor’s payment requests to my inbox',
        paymentTerms: 'Payment terms',
        goAddDeliveryMethod: 'Add delivery method',
        useSameAddressForCheck: 'Fill from address',
        deleteDialog: {
          title: 'Delete {vendorName}?',
          subtitle: 'Warning: this cannot be undone.',
          subtitleWarning: 'NOTICE: you have {number} {number, plural, one {bill} other {bills}}',
          subtitleWarningHasBills: 'If you still wish to delete it, please delete the bills first.',
          subtitleWarningHasPayments: 'If you still wish to delete it, please cancel the payments first.',
          confirm: 'Yes, delete this vendor',
        },
        cantDeleteDialog: {
          title: '{vendorName} {br} has scheduled payments and cannot be deleted',
          subtitle: 'You will have to manually cancel all scheduled payments for this vendor',
        },
      },
      bills: {
        title: 'Bills',
        tabs: {
          paid: 'Paid bills',
          unpaid: 'Unpaid bills',
          scheduled: 'Scheduled bills',
        },
      },
      addDeliveryMethodByLink: {
        paymentETA: 'Estimated payment delivery',
        partnersIconsAlt: 'Partners icon',
        achLabel: 'Bank transfer (ACH)',
        routingNumber: 'Routing number',
        accountNumber: 'Account number',
        bulkPaymentInvoiceNumber: 'Multiple invoices',
        invoiceWithPrefix: 'invoice #{invoiceNumber}',
        invoicePlaceholder: 'invoice',
        submitCTA: 'Submit and accept payment',
        printName: 'Name on check',
        addressLine1: 'Address line 1',
        addressLine2: 'Address line 2',
        city: 'City',
        state: 'State',
        zipCode: 'Zip code',
        support: 'Have any questions?',
        bankAccount: 'Bank account',
        success: {
          title: 'Success!',
          subtitle:
            'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>Invoice {invoiceNumber}</sb> is on its way.',
        },
        linkExistingMethod: {
          title:
            'There’s a bank account already connected to this account, would you like to receive this payment there?',
          submit: 'Receive payment to this account',
        },
        unilateralWithoutPayment: {
          subTitle: 'wants to send you payments with Melio',
          selectTitle: 'How would you like to receive their payments?',
          achCategoryLabel: 'Bank transfers ACH',
          checkCategoryLabel: 'Paper checks',
          achHint: 'Arrive in 2-3 days (free)',
          checkHint: 'Arrive in 5 -7 days (free)',
          submitCTA: 'Save details',
          success: {
            title: 'Success!',
            subtitleAch: 'Future payments from <b>{companyName}</b> will now arrive directly to your bank account',
            subtitleCheck: 'Future payments from <b>{companyName}</b> will now arrive as paper check to your address',
          },
          signUpTitle: 'Get deposits to this account from your other customers',
          signUpSubtitle:
            'Join Melio to get all your payments faster and directly to your bank account quickly and easily. ',
          signUpCTA: 'Join melio for free',
          linkExistingMethod: {
            title:
              'There’s a bank account already connected to this account, would you like to recieve your payments there?',
            submit: 'Recieve payments to this account',
          },
        },
        loginHint: 'Already a Melio user?',
        loginHintMobile: 'Melio user?',
        loginSubmit: 'Log In',
        vendorSignUpTitle: 'Receive payments to this account from any of your customers',
        vendorSignUpSubtitle:
          'Join Melio to get all your payments faster and directly to your bank account quickly and easily.',
        vendorSignUpCTA: 'Join Melio free',
        signUpTitle: 'Join Melio to get payments faster!{br} Just like this one.',
        signUpSubtitle:
          'With Melio, you can get paid from all your customers quickly and easily. Upload invoices and send over payment requests with a few clicks. And it’s all completely free.',
        signUpCTA: 'Sign up for free',
        trustedBy: 'Trusted by our leading financial partners',
        achTitle: 'Add your company’s ACH bank details',
        checkTitle: 'Enter your information so Melio can mail you a paper check',
        for: 'For:',
        subTitle: 'sent you an online payment with Melio',
        includedNote: 'Included note: {note}',
        selectTitle: 'When would you like to receive this payment?',
        achCategoryLabel: 'In 2-3 days',
        checkCategoryLabel: 'In 5-7 days',
        achHint: 'Bank transfer (free)',
        checkHint: 'Paper check (free)',
        confirmMelio: 'By continuing you agree to Melio’s {termsOfService} and {privacyPolicy}',
        privacyPolicy: 'Privacy policy',
        termsOfService: 'Terms of service',
        manualAddressTitle: 'Let’s fix the address you entered',
        manualAddressSubTitle:
          'We spotted a small issue with the address you provided, and suggested an alternative below. Please select and/or edit the final version you’d like to use.',
        manualAddressCTA: 'Confirm and accept payment',
        switchCompanyTitle: 'To which of your organizations would you like to receive this payments?',
      },
    },
    payments: {
      form: {
        deleteDialog: {
          title: 'Cancel payment?',
          subtitle: 'Payment will not be collected. You’ll need to schedule a new payment for this bill.',
          removalFailedTitle: 'This payment method has scheduled bills and can not be deleted.',
          confirm: 'Yes, cancel this payment',
          cancelText: 'Go back',
        },
      },
    },
    deliveryMethods: {
      bank: 'Bank transfer',
      check: 'Mail check',
      rpps: "Vendor's verified delivery method",
      to: 'to ',
      notDefinedInfo: {
        ach: {
          label: 'Bank transfer (ACH)',
          selectedLabel: 'Bank transfer (ACH) to {displayName}',
          desc: 'Free. No additional fee.',
          placeholder: 'Deposit funds into your vendor’s bank account',
          futureFees: 'Free until {freeUntilDate}.',
          feesAfter: '$0.50 fee',
          futureFeesNotification: `Starting {freeUntilDateShort} bank transfers will incur a $0.50 fee unless paying by card. This won’t affect payments created before.`,
          feesNotificationAfter: `Bank transfers now incur a $0.50 fee unless paying by card. Payments created before {freeUntilDateShort} won’t be affected.`,
        },
        check: {
          label: 'Paper check',
          selectedLabel: 'Paper check to <private>{displayName}</private>',
          desc: 'Free. No additional fee.',
          placeholder: 'Melio mails a check to your vendor’s address on your behalf',
          notification:
            'Starting on Oct 19, 2022 paying with an ACH to check payment will incur a $1.50 fee. This won’t affect payments already scheduled. {learnMoreLink}',
          moreAboutFeaturesLink: 'More about the new features.',
          tellMeMoreLink: 'Tell me more.',
          learnMoreLink: 'Learn more',
          appliedFee: {
            description: '{fee} fee, first 2 every month are free.{br} Covered by Check Protection. {tellMeMoreLink}',
            settingsDescription:
              '{fee} fee when paying via ACH, first 2 every month are free.{br} Covered by Check Protection. {tellMeMoreLink}',
            free: 'Free. Covered by Check Protection.{br}{learnMoreLink}',
            notification: {
              before: {
                title: 'Paper checks now incur a fee',
                body:
                  'As of October 19, 2022, checks incur a {fee}. The first 2 checks every month are free. Payments created before won’t be affected. {learnMoreLink}',
              },
              after: {
                title: 'You get 2 free checks every month',
                body: 'Checks incur a {fee} fee but the first 2 checks you send every month are free.',
              },
            },
          },
          appliedFeeNoPromotions: {
            description: '{fee} fee.{br} Covered by Check Protection. {tellMeMoreLink}',
            settingsDescription: '{fee} fee when paying via ACH.{br} Covered by Check Protection. {tellMeMoreLink}',
          },
        },
        virtual: {
          label: 'Ask vendor for payment details',
          placeholder: 'Send an email to the vendor',
          checkFee: {
            notification:
              'A {fee} fee is applied if your vendor chooses to get a check. If you have a monthly free check available, no fee will apply. A $0.50 fee will be applied for bank transfers.',
            notificationNoPromotions:
              'A fee will be applied depending on what your vendor chooses ($0.50 for a bank transfer, {fee} for a check).',
          },
        },
        rpps: {
          label: 'Direct payment',
          desc: 'Free. No additional fee.',
          placeholder: 'Payment is delivered according to method entered by this vendor',
        },
        infoText: ' ',
      },
      definedInfo: {
        virtual: {
          desc: 'Request was sent on {date}.',
          tag: 'Pending details',
        },
      },
      actions: {
        edit: 'Edit',
        delete: 'Delete',
        addMethod: 'Add method',
        sendRequest: 'Send request',
        resendRequest: 'Resend request',
        replace: 'Replace details',
      },
      replaceDetailsModal: {
        title: 'These bank details were added by {styledCompanyName}',
        description:
          '<b><ap>{companyName}</ap></b> bank details can’t be viewed as they’re kept hidden. You can, however, replace their bank account details.',
        confirm: 'Replace account details ',
      },
      updatedBy: 'Updated on {date} by {displayName}',
    },
    fundingSources: {
      microDeposits: {
        title: 'Enter the amounts you’ve received',
        successCTA: 'Sign up now',
        whatsNext: 'What’s next',
        signUp: 'Sign up to Melio and pay all your vendors however you like, even if they only accept checks.',
        signUpNow: 'Sign up now',
        learnMore: 'Learn more about Melio',
        verify: {
          title: 'Verify your bank account',
          subtitle:
            'We sent 2 small deposits (less than $1.00) from Melio Payments to your bank account ending in <b>{digits}</b>.{break}Enter the exact amounts below to verify your account.',
          sum1Label: 'Deposit #1',
          sum2Label: 'Deposit #2',
          viewAnExample: 'View an example',
          exampleTitleText: 'This is only an example',
          confirm: 'Verify',
          placeholder: '$0.00',
          incorrectAmounts: 'One or both of the amounts above is incorrect. Please try again.',
          emptyAmounts: 'Please enter both amounts and try again.',
        },
        alreadyVerified: {
          title: 'Account already verified',
          subtitle: 'This bank account is already verified. You can use it to send and schedule payments online.',
        },
        limitReached: {
          title: 'Too many failed attempts',
          subtitle: 'To verify your account, please contact our support team at {supportEmail}',
        },
        cannotVerifyYet: {
          title: 'Can’t verify just yet',
          subtitle:
            'We’re sending 2 deposits to your bank account ending in <b>{digits}</b> (can take up to 3 business days).{br}{br}We’ll email you once the deposits are made with a link to complete the verification.',
        },
        success: {
          title: 'Verification complete!',
          subtitle: 'You successfully verified this bank account and can now use it to send and schedule payments.',
        },
      },
    },
    onboarding: {
      companyInfo: {
        sectionTitle: 'Business details',
        name: {
          title: 'Now, tell us about your company',
          inputTitle: 'What’s the name of your company?',
          inputHint: 'The doing business as (DBA) name',
          emptyLabel: 'No company name',
          accountingFirm: {
            title: 'Now, tell us about your firm',
            inputTitle: 'What’s your firm’s name?',
          },
        },
        address: {
          title: 'Where is {companyName} located?',
          manualTitle: 'No worries. Let’s try it this way',
          aptNumberLabel: 'Address line 2',
          addressInputLabel: 'Company address',
          aptNumberInputLabel: 'Suite #',
          confirmSubTitleLine1:
            'There’s an issue with the address provided - we’ve marked our suggestions in green below. Please choose or edit which version of the address you want to use.',
        },
        contacts: {
          title: 'First, tell us about yourself',
          inputTitleFirstName: 'First name',
          inputTitleLastName: 'Last name',
          inputTitlePhone: 'Mobile number',
          inputHintPhone: 'The best number to reach you',
        },
        phone: {
          title: 'Please enter your mobile number',
          inputTitle: 'Mobile number',
          emptyLabel: 'No phone',
        },
        contactName: {
          inputTitleFirstName: 'Contact first name',
          emptyLabelFirstName: 'No first name',
          inputTitleLastName: 'Contact last name',
          emptyLabelLastName: 'No last name',
        },
        type: {
          title: 'What type of company are you with?',
          subtitle: 'Help us tailor-fit the relevant features for you',
          options: {
            smb: {
              title: 'Business',
              hint: '<sb>Choose this if</sb> you’re a company’s owner or an employee managing this company’s payments',
            },
            'accounting-firm': {
              title: 'Accounting firm',
              hint:
                '<sb>Choose this if</sb> you’re part of an accounting or bookkeeping firm that manages payments for multiple clients',
            },
          },
        },
        link: {
          inputTitleLink: 'Melio.me link',
          emptyLabelLink: 'You haven’t set up your paying portal yet',
        },
        taxIdType: {
          inputTitle: 'Tax ID type',
          emptyLabel: 'No tax ID type',
        },
        added: {
          imageAlt: 'Setup complete',
          title: 'Setup complete',
          text:
            'Nice! Your Melio account is all set and ready for action. {br} It’s time to start paying bills with bank transfers (ACH), debit cards and credit cards. Even if your vendors only accept checks.',
          schedulePayment: 'Schedule a payment',
          continueToPay: 'Continue to pay',
          exploreLink: 'No thanks, later',
          accountReady: 'You’re all set!',
          getStarted: 'Get started',
          getStartedText: 'Get ready for your new and improved payment workflow.',
        },
        industry: {
          title: 'Which industry best describes {companyName}?',
          industryField: {
            label: 'Select your industry',
            placeholder: ' ',
            errorRequired: 'Please select an industry from the list above',
          },
          subIndustryField: {
            label: 'Select a sub-industry',
            placeholder: ' ',
            errorRequired: 'Please select a sub-industry',
          },
        },
        clientsAmount: {
          title: 'Lastly, how many clients does {companyName} manage?',
          values: {
            first: '1-20',
            second: '21-50',
            third: '51-100',
            fourth: '100+',
          },
        },
        monthlyPaymentVolume: {
          title: 'What is {companyName} estimated monthly bill payment volume?',
          values: {
            first: 'Up to $10K',
            second: '$10K-$50K',
            third: '$50K-$150K',
            fourth: '$150K+',
          },
        },
      },
      fundingSources: {
        section: 'Add a payment method',
        title: 'Link payment method',
        subtitle: 'Pay vendors with bank or card, they receive a check or ACH.',
        options: {
          bank: {
            label: 'Bank account',
            description: 'Free.',
            settingsDescription: 'Free.',
          },
          debit: {
            label: 'Debit card',
            description: 'Free.',
            descriptionFee: 'Fast payment delivery ({fee} fee)',
          },
          credit: {
            label: 'Credit card',
          },
        },
        bank: {
          section: 'Add a payment method - bank account',
          plaid: {
            title: 'Choose your bank',
          },
          verification: {
            title: 'Connect your bank account',
            paymentSubtitle: 'Choose how you’d like to transfer payments for free.',
            deliverySubtitle: 'Connect your bank account and get paid with a direct bank transfer (ACH).',
            plaid: {
              label: 'Connect instantly',
              description: 'Securely log in to your bank and start scheduling  transfers right away.',
            },
            manual: {
              label: 'Verify with deposits',
              description:
                'You’ll receive two micro-deposits in your bank account in 1-2 business days. Verify the amounts to start making payments.',
              limit:
                'This account has the maximum number of unverified bank accounts allowed at a time. Please remove or verify a bank account with micro-deposits before adding another one.',
            },
            debit: {
              label: 'Connect using a debit card',
              description: 'Enter your card’s details to schedule transfers. Great for next-day payments under $3,000.',
            },
          },
          intuitAccounts: {
            title: 'What’s this {type} called in QuickBooks?',
            subtitle: 'Select the matching payment account in your QuickBooks to link it and sync its payment activity',
            ach: 'bank account',
            creditAccount: 'credit card account',
            debitAccount: 'debit card account',
            linkAch: 'Link my bank account',
            linkCredit: 'Link my credit card',
            linkDebit: 'Link my debit card',
            accountLabel: 'Payment account in QuickBooks',
            placeholder: 'Select account',
            customLabel: 'Add this as a new payment account in QuickBooks',
            notification:
              'A new payment account will be added to your QuickBooks account. It’s labelled "<sb>{displayName}</sb>" and you can edit its details in your QuickBooks.',
            paymentNotification:
              'This payment will be synced to a new payment account in QuickBooks labelled "<sb>{displayName}</sb>". You can edit its details in QuickBooks. ',
            errors: {
              exist: 'This account already exists',
              required: 'Please select the matching account in QuickBooks',
            },
            success: {
              title: 'We’ve added this {type} to your QuickBooks',
              subTitle:
                'Seems like this payment account wasn’t in QuickBooks yet, that’s OK, we’ve just added it. It’s labelled <sb>{displayName}</sb> and you can edit its details in QuickBooks.',
              cta: 'Link {type}',
            },
          },
          complete: {
            section: 'Add a payment method - bank account',
            imageAlt: 'Success! Bank linked.',
            title: 'Success! Bank linked.',
            subtitle: 'You can now make bank transfers (ACH) to any of your vendors.',
            next: 'Done',
            settingsNext: 'All set',
            exploreText: 'Already using QuickBooks?',
            exploreLink: 'Connect your QuickBooks account',
          },
          manually: {
            view: {
              title: 'Bank account details',
              subtitle: 'Review the routing and account numbers of this bank account in Melio',
            },
            connectBankTitle: 'Link your account with micro-deposits',
            connectBankSubTitle:
              'Get 2 small deposits in the next 1-3 business days, then confirm the amounts to verify. Add your account details below.',
            connectSuccessTitle: 'Check your email in 1-2 business days',
            connectSuccessSubTitle:
              'When the micro-deposits are transferred to your account, you’ll receive an email with a link to complete the verification process. The micro-deposits will show up as credits and then debits from Melio Payments inc.',
            connectSuccessNext: 'Done',
            accountTypeSaving: 'Saving',
            accountTypeChecking: 'Checking',
            saveFooter: 'Your details will be used for future payments.{br}You can always change them.',
            selectAccountType: 'Select your account type',
            routingNumber: 'Routing number (ACH)',
            accountNumber: 'Account number',
            completeAndSave: 'Save and continue',
            buttonCTA: 'Got it',
            useDifferentPaymentMethod: 'Use a different payment method',
          },
          cantFind: {
            title: 'Don’t see your bank listed?',
            subtitle:
              'That’s OK, you can enter your account and routing numbers to connect your bank account manually.',
            next: 'Connect account manually',
            cancel: 'Cancel',
          },
        },
        card: {
          iframeTitle: 'Add your card details',
          title: 'Add cardholder details',
          editTitle: 'Cardholder details',
          save: 'Complete and save',
          successNoteCredit: 'Your credit card was successfully added.',
          successNoteDebit: 'Your debit card was successfully added.',
          failNote: 'Your card was not added',
          failCancelButton: 'Ok',
          notAddedTitle: 'Card not added',
          notSupportedTitle: 'This card is not supported',
          errorButton: 'OK. Let me fix it',
          sandboxIndication: 'You are currently in Tabapay Sandbox mode.',
          firstName: {
            label: 'First name',
            id: 'firstName',
            errorMessage: 'Please enter your first name',
            validationTest: 'string',
          },
          fullName: {
            label: 'Cardholder name',
            id: 'fullName',
            errorMessage: 'Please enter your name',
            validationTest: 'string',
          },
          lastName: {
            label: 'Last name',
            id: 'lastName',
            errorMessage: 'Please enter your last name',
            validationTest: 'string',
          },
          address: {
            label: 'Address line 1',
            id: 'address',
            errorMessage: 'Please enter your address',
            validationTest: 'string',
          },
          addressLine1: {
            label: 'Address line 1',
            id: 'addressLine1',
            errorMessage: 'Please enter your address',
            validationTest: 'string',
          },
          addressLine2: {
            label: 'Address line 2',
            id: 'addressLine2',
          },
          city: {
            label: 'City',
            id: 'city',
            errorMessage: 'Please enter your city',
            validationTest: 'string',
          },
          state: {
            label: 'State',
            id: 'state',
            errorMessage: 'Please choose a state',
            validationTest: 'string',
          },
          zipcode: {
            label: 'ZIP code',
            id: 'zipCode',
            errorMessage: 'Please enter your ZIP code',
            validationTest: 'string',
          },
        },
      },
      accountingSoftware: {
        section: 'Sync with your accounting software',
        title: 'Link your accounting software',
        subtitle: 'All your vendors, bills and payments from last 60 days will be synced with Melio.',
        addLater: 'I don’t use an accounting software',
        comingSoon: {
          title: 'We promise, it’s coming soon...',
          text:
            'We’re working hard to enable you to connect with your accounting software so, we will notify you when it’s available.',
          actions: {
            notify: 'Yes please, notify me',
            cancel: 'Cancel',
          },
        },
        loading: {
          title: 'Syncing...',
          subtitle: 'Please wait, this may take a minute',
        },
        checking: {
          title: 'Just a moment...',
          subtitle: '',
        },
        success: {
          title: 'QuickBooks linked',
          subtitle: 'Your accounting software is now connected',
          text:
            'All vendors, invoices and payments will be continuously synced between QuickBooks and Melio no matter where they were added.',
          actions: {
            done: 'Add business details',
          },
        },
        error: {
          // FIXME: failure
          title: 'Oh snap!',
          subtitle: 'we’re sorry, but something went wrong.',
          recommendation: 'Don’t worry, it is not your fault. Please check your connection and try again.', // FIXME: details
          actions: {
            tryAgain: 'Try again',
            cancel: 'Cancel',
          },
        },
      },
      deliveryMethods: {
        index: {
          title: 'Choose how you want to get paid',
          subtitle: 'Get paid via bank transfer or debit card',
          bank: {
            label: 'Bank transfer',
            description: 'Simple and fast (free)',
          },
          card: {
            label: 'Debit card',
            description: 'Easy to link, get paid immediately',
          },
        },
        bank: {
          title: 'Verify with deposits',
          verifyTitle: 'Verify your bank account',
          verifySubtitle: 'Please verify that you’d like to use these bank details for your future incoming payments.',
          melioMeSubtitle: 'Enter your bank account details.',
          routingNumber: 'Routing number (ACH)',
          accountNumber: 'Account number',
          view: {
            title: 'Bank account details',
            subtitle: 'Review the routing and account numbers of this bank account in Melio',
          },
          save: 'Link my bank account and finish',
          verifyCta: 'Verify details and continue',
          verification: {
            plaid: {
              label: 'Connect instantly',
              description: 'Log in and connect your bank to request, track, and receive incoming payments.',
            },
            manual: {
              label: 'Verified with deposits',
              description:
                'You’ll receive two micro-deposits in your bank account in 1-2 business days. Verify the amounts to start making payments.',
            },
          },
        },
      },
    },
    guests: {
      register: {
        invoiceNumber: 'Invoice #',
        paymentHint: 'Enter an amount and invoice # to pay.',
        buttonText: 'Pay {companyName}',
        disabledHint: 'You are the link owner. This is what your customers will see.',
        payUsing: 'You can pay using:',
        fundingSources: {
          creditCard: 'Credit card',
          bankTransfer: 'Bank transfer',
          debitCard: 'Debit card',
        },
        fee: {
          free: 'Free',
          freeTag: 'Free!',
          percentage: '{percentage}% fee',
        },
        amountPlaceholder: '0.00',
        promotionDescription: `{promotionName, select,
          COVID_APRIL {<sb>Pay your bills with your credit card using Melio and have 0% credit card fees until May 8th (for payments up to $10K).
            </sb> Or pay with a bank transfer (ACH) or debit card for free, as always.}
          other { }
        }`,
        trustedBy: '<sb>Trusted by leading financial partners:</sb>',
        bankAndCreditCard: 'Bank transfer, credit card',
        debitCard: 'Debit card.',
        cardSupport: 'Cards we support:',
        cardIconsAlt: 'Card icons',
        learnMore: 'Learn more.',
        securityText: 'Melio keeps all your details 100% secure so your sensitive information remains private. {link}',
        paymentRequestLinkSecurityText:
          'All your account information will remain secure and hidden from <b>{companyName}</b> and other businesses.',
      },
      emailVerify: {
        title: 'Check your email',
        subTitle:
          'We sent a 4-digit code to {email}.{br}Please enter it below.{br}Can’t find it? Check your spam folder',
        sendCodeAgain: 'Resend the code',
        changeEmail: 'Noticed a typo? Fix your email address',
        codeNotValid: 'Oops! You got it wrong',
        resendNotification:
          'We’ve just sent you another verification code. Please check your inbox (it might be in your spam folder)',
      },
      addDateOfBirth: {
        title: 'Action Required to Reactivate Bill Pay',
        titleLoading: 'Please wait...',
        subtitle: 'Please provide the following details to continue using Bill Pay.',
        subtitleLoading: 'it’ll just be a minute',
        action: 'Save and continue',
        dateOfBirth: {
          inputTitle: 'Date of Birth',
          emptyLabel: 'MM-DD-YYYY',
        },
      },
    },
    notFound: {
      title: 'Whoops! Looks like you’re lost.',
      subtitle: 'We searched everywhere but couldn’t find what you’re looking for. Let’s get you home.',
      button: 'Melio home',
    },
    error: {
      title: 'Error',
      report: 'Report',
    },
    dialogs: {
      buttons: {
        close: 'Close',
        cancel: 'Cancel',
        done: 'Done',
        no: 'No',
        gotIt: 'Got it',
      },
    },
    storybook: {
      button: 'Button',
      label: 'Label',
      guidingText1: 'This is guiding text #1',
      guidingText2: 'This is guiding text #2',
      error: 'This is an error message',
      placeholder: 'This is a placeholder',
      description: 'This is a description',
      inputValue: 'A Value example',
      textType: 'text',
      yes: 'yes',
      no: 'no',
      notification: 'This is an notification message',
      notificationWithValues: 'This is an notification message {undo}',
    },
    serverErrors: {
      ERR: 'An error has occurred',
      '[INVITATIONS] CREATE_REQUEST': {
        'ALREADY-INVITED': 'User already invited, please choose a different email',
        'USER-IN-ORG': 'User already exists, please choose a different email',
      },
      '[INVITATIONS] VALIDATE_CREATE_REQUEST': {
        'ALREADY-INVITED': 'User already invited, please choose a different email',
        'USER-IN-ORG': 'User already exists, please choose a different email',
      },
      '[CUSTOMERS] CREATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
      '[CUSTOMERS] UPDATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
      '[VENDORS] CREATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
      '[VENDORS] UPDATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
    },
    inputErrors: {
      regularBatchPayments: {
        fundingSourceId: 'Payment method is missing',
      },
      userRegistration: {
        email: {
          string: {
            email: 'Please enter your email, make sure it’s valid',
          },
        },
        password: {
          string: {
            base: 'This password is too easy to guess, please try a more complex one',
            zxcvbn: {
              generic: 'This password is too easy to guess, please try a more complex one',
              addMoreWords: 'This password is too easy to guess, please try a more complex one',
              avoidAssociatedDatesAndYears: 'Please avoid dates and years that are associated with you',
              avoidRepeats: 'Please avoid repeated words and characters',
              avoidSequences: 'Please avoid sequences',
              avoidRecentYears: 'Please avoid recent years',
              tooCommon: 'This password is very common and too easy to guess, please try a more complex one',
              noRepeats:
                'Repeats like "abcabcabc" are only slightly harder to guess than "abc", please try a more complex password',
            },
            min: 'Please enter a password with at least 8 characters',
          },
          server: {
            ALREADY_EXISTS: 'This email already registered',
            ATH02: 'Current password is incorrect',
          },
        },
        confirmedPassword: {
          any: {
            allowOnly: 'Passwords don’t match',
          },
        },
        currentPassword: {
          any: {
            empty: 'Please enter your current password',
          },
          string: {
            base: 'Please enter your current password',
          },
        },
        companyOwnerInfo: {
          email: {
            any: {
              required: 'Enter the company owner’s email, make sure it’s valid',
            },
            string: {
              base: 'Enter the company owner’s email, make sure it’s valid',
              email: 'Enter the company owner’s email, make sure it’s valid',
            },
          },
        },
      },
      vendorUserRegistration: {
        handle: {
          any: {
            empty: 'Please enter your handle',
          },
          string: {
            base: 'Please enter your desired Melio.me link',
            min: 'Your Melio.me link should be at least 3 characters long',
            max: '25 characters is maximum, please enter shorter Melio.me link',
            uriRelativeOnly: 'Please make sure that your Melio.me link contains only numbers and letters',
            regex: {
              base: 'Please make sure that your Melio.me link contains only numbers and letters',
            },
          },
          server: {
            VDR05: 'This name is not available. Please choose a different one.',
          },
        },
      },
      bill: {
        totalAmount: {
          any: {
            empty: 'Please enter the bill total',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Enter a bill total',
            max: 'That’s too much!',
          },
        },

        note: {
          string: {
            max: 'We’re sorry, but bill note should not exceed 2000 characters',
          },
        },

        invoiceNumber: {
          any: {
            empty: 'Enter a bill number',
          },
          string: {
            base: 'Please enter an invoice number',
            max: 'We’re sorry, but invoice number should not exceed 255 characters',
          },
        },
        vendorId: {
          number: {
            base: 'Select a vendor',
          },
        },
        dueDate: {
          date: {
            base: 'Enter a due date',
            min: 'Please select today’s date or greater',
          },
          any: {
            required: 'Please select a due date for this bill',
          },
        },
        invoiceDate: {
          date: {
            base: 'Please select an issue date for this bill',
          },
          any: {
            required: 'Please select an issue date for this bill',
          },
        },
        terms: {
          number: {
            base: 'Please select payment terms',
            min: 'Please enter 0 or more days, not a negative number',
          },
        },
      },
      microDepositsVerification: {
        amount1: {
          any: {
            empty: 'Please enter the microdeposit sum',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Please enter the microdeposit sum',
          },
        },
        amount2: {
          any: {
            empty: 'Please enter the microdeposit sum',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Please enter the microdeposit sum',
          },
        },
        invalidAmount: 'One or both of the amounts above is incorrect. Try again',
      },
      paymentRequest: {
        totalAmount: {
          any: {
            empty: 'Enter invoice‘s amount',
          },
          number: {
            greater: 'Please enter a positive amount',
            base: 'Enter invoice‘s amount',
            max: 'That’s too much!',
          },
        },
        invoiceNumber: {
          any: {
            empty: 'Enter invoice number',
          },
          string: {
            base: 'Enter invoice number',
          },
        },
        customerId: {
          number: {
            base: 'Enter your customer‘s name',
          },
        },
        customerNote: {
          string: {
            max: 'We’re sorry, but request note should not exceed 2000 characters',
          },
        },
        dueDate: {
          date: {
            base: 'Select invoice‘s due date',
            min: 'Please select today’s date or greater',
          },
          any: {
            required: 'Select invoice‘s due date',
          },
        },
        customerEmail: {
          string: {
            email: 'Please enter the correct email address of your customer',
          },
          any: {
            required: 'Email is missing',
          },
        },
      },
      directoryVendor: {
        accountIdentifier: {
          custom: {
            mask: 'Invalid account number',
          },
          any: {
            empty: 'Please enter the account number of your vendor',
            required: 'Please enter the account number of your vendor',
          },
        },
      },
      vendor: {
        companyName: {
          any: {
            empty: 'Please enter the business name of your vendor',
            required: 'Please enter the business name of your vendor',
          },
          string: {
            max: 'Noooo, that’s just too long',
          },
        },
        contactEmail: {
          any: {
            empty: 'Please enter the email address of your vendor',
            required: 'Please enter the email address of your vendor',
          },
          string: {
            email: 'Please enter the correct email address of your vendor',
            base: 'Please enter a valid email address',
            max: 'We’re sorry, but email address should not exceed 255 characters',
          },
        },
        contactPhone: {
          any: {
            empty: 'Please enter the phone number of your vendor',
            required: 'Please enter the phone number of your vendor',
          },
        },
      },
      deliveryMethodCheck: {
        printName: {
          any: {
            empty: 'Please enter the vendor name (to print on the check)',
            required: 'Please enter the vendor name (to print on the check)',
            invalidChar: 'Hmm, that character isn’t supported here',
          },
          string: {
            max: 'We’re sorry, but name on check should not exceed 255 characters',
            'regex.base': 'Please enter the vendor name (to print on the check)',
          },
        },
        googlePlaceId: {
          any: {
            empty: 'Please enter the vendor address',
            required: 'Please enter the vendor address',
          },
        },
        addressLine1: {
          any: {
            empty: 'Please enter the vendor address',
            required: 'Please enter the vendor address',
          },
          string: {
            min: 'Please enter the address with at least 3 characters',
          },
        },
        city: {
          any: {
            empty: 'Please enter the city',
            required: 'Please enter the city',
          },
        },
        state: {
          any: {
            empty: 'Please select the state',
            required: 'Please enter the state',
            allowOnly: 'Please select the state',
          },
        },
        zipCode: {
          any: {
            empty: 'Please retry, zip codes should be 5 or 9 digits',
            required: 'Please retry, zip codes should be 5 or 9 digits',
          },
          string: {
            regex: {
              base: 'Please retry, zip codes should be 5 or 9 digits',
            },
          },
        },
      },
      deliveryMethodAch: {
        routingNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like a routing number, should be 9 digits',
            },
            invalidChecksum: 'The routing number you have entered is invalid',
          },
        },
        accountNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like an account number, should be from 6 to 17 digits',
            },
          },
        },
        tryToPayHimself:
          'This account is already set to be a vendor’s delivery method and can’t be set as your payment method',
      },
      deliveryMethod: {
        virtualAccount: {
          email: {
            any: {
              empty: 'Please enter the email address of your vendor',
              required: 'Please enter the email address of your vendor',
            },
            string: {
              email: 'Please enter the correct email address of your vendor',
              base: 'Please enter your vendor’s email',
              max: 'We’re sorry, but email address should not exceed 255 characters',
            },
          },
        },
        bankAccount: {
          routingNumber: {
            string: {
              regex: {
                base: 'This should be 9 digits',
              },
              invalidChecksum: 'The routing number you have entered is invalid',
            },
          },
          accountNumber: {
            string: {
              regex: {
                base: 'This should be 6-17 digits',
              },
            },
          },
        },
        paperCheck: {
          printName: {
            any: {
              empty: 'Please enter the recipient’s name',
              required: 'Please enter the recipient’s name',
            },
            string: {
              max: 'We’re sorry, but name on check should not exceed 255 characters',
              'regex.base': 'Please enter the recipient’s name',
            },
          },
          googlePlaceId: {
            any: {
              empty: 'Please enter the address',
              required: 'Please enter the address',
            },
          },
          addressLine1: {
            any: {
              empty: 'Please enter a delivery address',
              required: 'Please enter a delivery address',
            },
            string: {
              min: 'Please enter a delivery address',
            },
          },
          city: {
            any: {
              empty: 'Please enter a city',
              required: 'Please enter a city',
            },
          },
          state: {
            any: {
              empty: 'Please select a state',
              required: 'Please select a state',
              allowOnly: 'Please select a state',
            },
          },
          zipCode: {
            any: {
              empty: 'Please enter a 5 or 9 digit zip code',
              required: 'Please enter a 5 or 9 digit zip code',
            },
            string: {
              regex: {
                base: 'Please enter a 5 or 9 digit zip code',
              },
            },
          },
        },
        cardAccount: {
          printName: {
            array: {
              min: 'Please enter the recipient’s name',
            },
          },
          googlePlaceId: {
            any: {
              empty: 'Please enter the address',
              required: 'Please enter the address',
            },
          },
          addressLine1: {
            string: {
              min: 'Please enter a delivery address',
            },
          },
          city: {
            any: {
              empty: 'Please enter a city',
              required: 'Please select a city',
            },
          },
          state: {
            any: {
              empty: 'Please select a state',
              required: 'Please select a state',
              allowOnly: 'Please select a state',
            },
          },
          zipCode: {
            string: {
              regex: {
                base: 'Please enter a 5 or 9 digit zip code',
              },
            },
          },
        },
      },
      companyInfo: {
        companyName: {
          any: {
            base: 'Please enter the company name',
            empty: 'Please enter the company name',
            required: 'Please enter the company name',
          },
          string: {
            min: 'Company name should be at least 3 characters',
            max: 'Noooo, that’s just too long',
            base: 'Please enter the company name',
          },
        },
        phone: {
          string: {
            pattern: {
              base: 'Please enter a valid phone number',
            },
            base: 'Please enter phone number',
            invalid: 'Please enter a valid phone number',
          },
          any: {
            empty: 'Please enter your phone number',
            required: 'Please enter your phone number',
          },
        },
        contactFirstName: {
          any: {
            empty: 'Please enter your first name',
            required: 'Please enter your first name',
          },
          string: {
            pattern: {
              base: 'Must only contain letters (A-Z)',
            },
            min: 'First name should be at least 2 characters',
            max: 'Noooo, that’s just too long',
            base: 'Please enter your first name',
          },
        },
        contactLastName: {
          any: {
            empty: 'Please enter your last name',
            required: 'Please enter your last name',
          },
          string: {
            pattern: {
              base: 'Must only contain letters (A-Z)',
            },
            min: 'Last name should be at least 2 characters',
            max: 'Noooo, that’s just too long',
            base: 'Please enter your last name',
          },
        },
        googlePlaceId: {
          any: {
            empty: 'Please enter the address of your business',
            required: 'Please enter the address of your business',
          },
          string: {
            base: 'Please enter the address of your business',
          },
        },
        zipCode: {
          any: {
            empty: 'Please enter a ZIP code',
            required: 'Please enter a ZIP code',
          },
          string: {
            regex: {
              base: 'Please enter a valid ZIP code',
            },
            base: 'Please enter the company’s address',
          },
        },
        legalCompanyName: {
          any: {
            empty: 'Please enter the legal name of your business',
            required: 'Please enter the legal name of your business',
          },
          string: {
            min: 'Company name should be at least 3 characters',
            max: 'Noooo, that’s just too long',
            base: 'Please enter the legal name of your business',
          },
        },
        legalGooglePlaceId: {
          any: {
            empty: 'Please enter the address of your business',
            required: 'Please enter the address of your business',
          },
          string: {
            base: 'Please enter the address of your business',
          },
        },
        legalZipCode: {
          any: {
            empty: 'Please enter a ZIP code',
            required: 'Please enter a ZIP code',
          },
          string: {
            base: 'Please enter a valid ZIP code',
            regex: {
              base: 'Please enter a valid ZIP code',
            },
          },
        },
        taxIdType: {
          any: {
            empty: 'Please select the tax ID type',
            required: 'Please select the tax ID type',
          },
          string: {
            base: 'Please select the tax ID type',
          },
        },
        businessType: {
          required: 'Please select the business type',
        },
        companyIndustry: {
          required: 'Please select the industry type',
        },
        dateOfBirth: {
          required: 'Please enter your date of birth',
          notCorrectDate: 'Enter a valid date of birth',
        },
        taxId: {
          any: {
            empty: 'Please enter the tax ID number (EIN, SSN or ITIN)',
            required: 'Please enter the tax ID number (EIN, SSN or ITIN)',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter the tax ID number (EIN, SSN or ITIN)',
            regex: {
              base: 'The tax ID you entered is invalid',
            },
          },
        },
        addressLine1: {
          any: {
            empty: 'Please enter the address',
            required: 'Please enter the address',
          },
        },
        legalAddressLine1: {
          any: {
            empty: 'Please enter the address',
            required: 'Please enter the address',
          },
        },
        city: {
          any: {
            empty: 'Please enter the city',
            required: 'Please enter the city',
          },
        },
        state: {
          any: {
            empty: 'Please select the state',
            required: 'Please enter the state',
          },
        },
        companyOwnerInfo: {
          phone: {
            string: {
              base: 'Enter the owner’s phone number',
            },
            any: {
              empty: 'Enter the owner’s phone number',
              required: 'Enter the owner’s phone number',
            },
          },
          contactFirstName: {
            any: {
              empty: 'Enter the owner’s first name',
              required: 'Enter the owner’s first name',
            },
            string: {
              max: 'Noooo, that’s just too long',
              base: 'Enter the owner’s first name',
            },
          },
          contactLastName: {
            any: {
              empty: 'Enter the owner’s last name',
              required: 'Enter the owner’s last name',
            },
            string: {
              max: 'Noooo, that’s just too long',
              base: 'Enter the owner’s last name',
            },
          },
        },
      },
      fundingSourceBank: {
        accountType: {
          string: {
            any: {
              empty: 'Please enter account type',
              required: 'Please enter account type',
            },
          },
        },
        routingNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like a routing number, should be 9 digits',
            },
            invalidChecksum: 'The routing number you have entered is invalid',
          },
        },
        accountNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like an account number, should be from 6 to 17 digits',
            },
          },
        },
        alreadyDefined:
          'You’ve already saved these bank account details. To continue, remove it from {vendorName} delivery methods or use other bank account details',
      },
      guest: {
        totalAmount: {
          any: {
            empty: 'Please enter an amount to pay',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Please enter an amount to pay',
            max: 'That’s too much!',
          },
          custom: {
            nopay: 'We’re sorry, You can not transfer funds to yourself',
          },
        },
        invoiceNumber: {
          any: {
            empty: 'Please enter an invoice number',
          },
          string: {
            base: 'Please enter an invoice number',
            max: 'That’s too long!',
          },
        },
        email: {
          string: {
            email: 'Please enter your email, make sure it’s valid',
          },
        },
        businessName: {
          any: {
            empty: 'Please enter the name of your business',
            required: 'Please enter the name of your business',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter the name of your business',
          },
        },
      },
      user: {
        dateOfBirth: {
          any: {
            empty: 'Please enter your date of birth',
            required: 'Please enter your date of birth',
          },
          date: {
            notCorrectDate: 'Enter a valid date of birth',
            notCorrectAge: 'You must be at least 18 years old to continue',
            base: 'Please enter your date of birth',
          },
        },
        firstName: {
          any: {
            empty: 'Please enter your name',
            required: 'Please enter your name',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter your name',
          },
        },
        lastName: {
          any: {
            empty: 'Please enter your name',
            required: 'Please enter your name',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter your name',
          },
        },
      },
      userOrganization: {
        approvalAmountThreshold: {
          number: {
            base: 'Please enter amount',
            max: 'This limit can’t be higher than your own',
          },
        },
      },
      batchPayment: {
        fundingSourceId: {
          number: {
            base: 'Payment method missing',
          },
        },
      },
    },
    user: {
      role: {
        owner: 'Owner',
        admin: 'Admin',
        contributor: 'Contributor',
        accountant: 'Accountant',
        deleted: 'Deleted',
        accountingFirmAdmin: 'Firm admin',
        pending: 'Pending',
        roleDescription: {
          accountant:
            'Accountants and bookkeepers can use this role to manage bills and payments, or sync your QuickBooks and Melio accounts. Admin approval is optional and can be set for scheduled payments that exceed the amount set below.',
          contributor:
            'Use this role for team members that require limited permissions. Contributors can add or modify any bills and payments. Admin approval is optional and can be set for scheduled payments that exceed the amount below.',
          admin:
            'Admins have full access to your Melio account. This includes vendors, bank details, cards, payments and settings. Admins can also approve payments added and scheduled by Contributors.',
        },
      },
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      editDialog: {
        title: 'Edit user details',
        userRoleSection: 'User’s role',
        userDetailsSection: 'User’s details',
        approvalAmountDescription: 'Require admin approval for any payment that exceeds:',
        approvalAmountInputLabel: 'Enter amount',
        submit: 'Save',
        cancel: 'Cancel',
      },
      deleteDialog: {
        title: 'Are you sure you want to delete {fullName}?',
        description: 'You can always re-invite this user to join your company again.',
        confirm: 'Yes, delete this user',
      },
    },
    batchPayment: {
      billsAlreadyScheduled: {
        title: 'Payments already scheduled',
        description:
          'You have already scheduled payments for the selected bills. Due to a sync issue, they may appear as unpaid in QuickBooks.',
        done: 'Done',
      },
      noPaymentMethod: 'Select payment method',
      deliveryDayPlaceholder: 'Not finalized',
      title: 'Schedule payments ({billsCount})',
      amountTitle: 'TOTAL PAYMENT AMOUNT',
      datePlaceholder: 'Select date',
      methodPlaceholder: 'Set method',
      deliveryPlaceholder: 'Set delivery',
      feesHeader: ' in fees',
      buttons: {
        cancel: 'Cancel',
        scheduleAllPayments: 'Schedule payments',
      },
      success: {
        title: 'Payments scheduled!',
        subTitle: 'You have successfully scheduled {totalPayments} {br} with a total of {total}',
        total: '{totalPayedBills} {totalPayedBills, plural, one {payment} other {payments}}',
        buttonLabel: 'I’m done here',
        notes:
          'These bills have been marked as paid and now have Bill Payment records. We’ve also sent a summary of these transactions to your email. Remember, each payment will be processed according to its own deduction date.',
        viewQBDashboard: 'View my Online Bill Payments',
        backQBDashboard: 'Back to my Online Bill Payments',
        notification:
          'The bills associated with these payments are now marked as {br}<sb>paid</sb> and have a bill payment in QuickBooks. Note that each {br}payment is processed according to its deduction date. ',
        notificationTitle: 'What’s next?',
        syncFailedNotification:
          'While your payments were successfully scheduled, some bills may not be marked as paid in QuickBooks due to a sync issue. Each payment is processed according to its deduction date.',
        syncFailedNotificationTitle: 'Important note',
      },
      failed: {
        title: 'Sorry, something went wrong',
        subTitle:
          'It seems something went wrong and no payments have been scheduled. Please retry or email us at {email}',
        buttonLabel: 'Ok. I’ll check back later',
        titleSome: 'Some of your payments were scheduled!',
        subTitleSeveral:
          'You have successfully scheduled {totalPayments} {br} amounting to {total}. However, {vendorsCount} {br} of the {vendorsCount, plural, one {payment} other {payments}} to {vendors} {vendorsCount, plural, one {has} other {have}} not been scheduled. Please chat with us below or email {email} so that we can help you schedule these payments.',
        totalPayments: '{totalPayedBills}',
        vendors: '{vendorsName}',
      },
      paymentListTitle: {
        vendor: 'Vendor',
        deductionDate: 'Deduction date',
        deliveryDate: 'Delivery date',
        paymentMethod: 'Payment method',
        deliveryMethod: 'Delivery method',
        deliverySpeed: 'Delivery Speed',
        amount: 'Amount',
        achLabel: '...{digits}',
        bank: 'Bank',
        check: 'Check',
      },
      sidePanel: {
        total: 'total',
        paymentAmount: 'Payment amount',
        payTo: 'pay to',
        payFrom: 'pay from',
        vendor: 'Vendor',
        vendorReceives: 'vendor receives',
        amountSourceError: 'Payment amount can be equal or lower than bill open balance (<private>{max}</private>)',
        fundingSourceError: 'Please add y our payment method.',
        deductionDateError: 'Please select a deduction date.',
        deliveryMethodError: 'Please add your vendor’s delivery method.',
        dueDate: 'Due date',
        invoice: 'Invoice #',
        memoBank: 'Note to vendor',
        memoCheck: 'Memo to vendor',
        memoPlaceholder: 'Account ID#, payment description',
        noInvoiceNumber: 'No invoice number',
        setDeductionDate: 'Funds deduction date',
        setDeductionDateEmpty: 'Set deduction date',
        dateValue: '{date}',
        estimateDateBank: '{date} by 8 PM local time',
        estimatePayments: 'Estimated payment delivery',
        deliveryDate: 'Delivery Date',
        buttons: {
          cancel: 'Close',
          saveAndContinue: 'Save and continue',
          saveAndComplete: 'Save and complete',
        },
      },
      completeLegalInfo: {
        action: 'Complete and save',
      },
      notifications: {
        error: {
          missingDetails:
            '{numOfErrors, plural, one {One} other {<red>#</red>}} of your bills {numOfErrors, plural, one {is} other {are}} missing payment details. Please complete {numOfErrors, plural, one {} other {them}} to continue.',
        },
        warning: {
          filtered: {
            title: 'Some bills has been filtered out because they cannot be scheduled',
          },
          limit: {
            title: 'Batch payments can only schedule {paymentLimit} payments at a time',
            subtitle:
              'Listed below are the first {paymentLimit} bills of the {originalNumOfBills} you previously selected',
          },
        },
        success: {
          detailsAdded: 'All missing details have been added',
        },
      },
      fast: {
        ach: 'Fast ACH',
        check: 'Fast Check',
        initialLabel: 'Not finalized',
      },
      date: {
        today: 'Today',
        tomorrow: 'Tomorrow',
      },
    },
    contractors: {
      title: 'It seems you’re trying to pay a contractor',
      subTitle: 'Paying contractors can be done through the contractors page.',
      buttonLabel: 'Take me there',
    },
    selectMethods: {
      fundingSourcePlaceholder: 'Select payment method',
      deliveryMethodPlaceholder: 'Select how vendor receives',
      selectedDeliveryMethodLabel: '{displayName}',
    },
    common: {
      rawValue: '{value}',
    },
    toasts: {
      undo: 'Undo',
      copied: 'Value copied to clipboard',
    },
    debitFee: {
      modal: {
        title: 'Debit card now has a fee',
        description:
          "As of July 7, paying with a debit card will incur a 2.9% processing fee.{br}{br}This won't affect payments already scheduled.",
        confirm: 'Got it',
        decline: 'Change payment method',
      },
    },
    checkFee: {
      modal: {
        title: 'Check Protection',
        description: 'Bill Pay helps keep your checks secure from issue date until they’re delivered to your vendor.',
        confirm: 'Got it',
        title1: 'Securing your info',
        text1: 'Your sensitive details are never on the check, helping protect you from fraud and check forgery.',
        title2: 'Continuous monitoring',
        text2: 'Checks are actively tracked, and you get updated when they’re sent and deposited.',
        title3: 'End-to-end handling',
        text3:
          'Bill Pay prints and sends bounce-proof checks to your vendor, and can handle any potential issues. Voiding checks is free.',
      },
    },
    billingFee: {
      addMethod: {
        title: 'Choose a payment method to use for check fees ',
        methodChangingSubtitle: 'The method you choose will be used for any remaining and future check fees.',
        options: {
          emptyMethod: {
            bankAccount: 'Use a bank account to cover paper check fees.',
            debitCard: 'Use a debit card to cover paper check fees.',
            creditCard: 'Use a credit card to cover paper check fees.',
          },
          bankAccount: 'Use this bank account to cover paper check fees.',
          debitCard: 'Use this debit card to cover paper check fees.',
          creditCard: 'Use this credit card to cover paper check fees.',
        },
      },
      success: {
        methods: {
          bankAccount: 'bank account',
          creditCard: 'credit card',
          debitCard: 'debit card',
        },
        methodChangedTitle: 'Payment method changed',
        buttonLabel: 'Done',
        title: 'Payment method added for check fees',
        sourceChangedText:
          'Your paper check fees will now be paid using the <b>{method}</b> ending in <b>{digits}</b>.',
        verifiedSourceText:
          'Your paper check fees will now be billed separately using the <b>{method}</b> ending in <b>{digits}</b>.',
        nonVerifiedSourceText:
          'Check fees will be billed to this payment method (<b>{method}</b> ending in <b>{digits}</b>) once verified. Until then, fees will continue being billed to the method used for the payment.',
      },
    },
    amexVerification: {
      modal: {
        title: 'Verify {vendorName}’s industry to pay with AMEX',
        description:
          'AMEX payments are allowed for approved industries only. Find and select your vendor’s industry below or, please choose another payment method.',
        learnMore: 'Learn more.',
        submit: 'Save and continue',
        batchSubmit: 'Save',
        form: {
          industry: {
            label: 'Approved categories',
            placeholder: 'Select vendor’s industry category',
            empty: 'Select vendor’s industry category',
          },
          code: {
            label: 'Approved sub-categories',
            placeholder: 'Select vendor’s industry sub-category',
            empty: 'Select a sub-category',
          },
          fallbackOption: 'Don’t see your vendor’s industry? <link>Close and change payment method</link>',
          emptyState: {
            message:
              'No results found.{br}{br}Please try again in a few minutes or{br}<link>close and change payment method.</link>',
          },
        },
      },
      toast: '{vendorName}’s industry has been verified.',
    },
    vendor: {
      pushToDebit: {
        selectDeliveryMethod: {
          title: 'Choose how to get your{br}instant payment',
          text:
            'Get your <b>{amount}</b> payment from {companyName} instantly instead of waiting {deliveryType, select, check {5-7} ach {3} other {}} business days.',
          deliveryOptions: {
            debitCard: {
              name: 'Debit card',
              description: 'Sent to your bank account.',
              feeHint: '1% fee',
            },
            virtualCard: {
              name: 'Single-use virtual card',
              description: 'Sent to your email. POS Terminal or card processor required. <link>Tell me more</link>',
              feeHint: 'Processing fees may apply depending on your provider',
            },
          },
        },
        addDebitCard: {
          title: 'Instantly receive this payment to your bank account',
          text:
            'Enter your debit card details to receive this payment to its associated bank account within minutes. 1% fee.',
          submitHint: 'This will only apply to this payment',
          submitHintMobile: `By continuing you agree to our
          <TermsOfServiceLink>terms of service</TermsOfServiceLink> and
          <PrivacyPolicyLink>privacy policy</PrivacyPolicyLink> which enable {companyName} to deposit their payments to your bank account.`,
          paymentInfoMessage: 'sent you an online payment of',
          submit: 'Link my card',
          validationError: {
            title: 'Credit cards are not supported',
            text: 'You can only use debit cards to receive instant payments to your bank account.',
            button: 'Close',
          },
          support: {
            termsOfService: 'Terms of Service',
            privacyPolicy: 'Privacy Policy',
          },
          cardHolderAddress: {
            submit: 'Accept payment',
            submitHint:
              'All your account information will remain secure and hidden from <b>{companyName}</b> and other businesses.',
            title: 'Add your cardholder details',
          },
          success: {
            title: 'Success!',
            text: `Your <b>{amount}</b> payment from {companyName} {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} is being sent to your bank account via debit card.`,
            vendorReceive: {
              title: 'YOU’LL RECEIVE',
              transferTo: 'Bank transfer to',
              debitCard: 'Debit card...{digits}',
              deliveryOn: 'Delivery on',
              deliveryTime: 'Within 30 minutes',
            },
          },
          completed: {
            title: 'Payment has been processed',
            text: `A <sb>{amount}</sb> payment from <sb>{companyName}</sb> {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} was sent to your account ending ...{digits}`,
          },
        },
        virtualCard: {
          learnMore: {
            title: 'What’s a single-use virtual card?',
            description: 'It’s a virtual credit card sent via email to process in a POS terminal.',
            advantages: {
              first: {
                title: 'Easy to use, more control',
                text:
                  'Use your own POS terminal or processing device for instant payment and immediate reconciliation.',
              },
              second: {
                title: 'Keep your info secure',
                text:
                  'The card details are sent to your email, so you don’t have to share any bank or address info to get paid.',
              },
              third: {
                title: 'Stay flexible',
                text: 'You can always change to receive payment as an an ACH transfer instead.',
              },
            },
            submit: 'Get a single-use card',
            submitHintMobile: 'Processing fees may apply',
            back: 'Back',
            submitHint: 'Processing fees may apply depending on your provider',
          },
          confirm: {
            title: 'Get this and future payments by single-use virtual card',
            deliveryETA: '<b>Payment ETA:</b> Up to 30 minutes',
            submit: 'Confirm and get the card',
          },
          success: {
            text: `Your <b>{amount}</b> payment {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} will be sent to <b>{vendorEmail}</b> as a single-use virtual card, as will all future payments from <b>{organizationName}</b>.`,
            deliveryEta: 'Up to 30 minutes',
          },
          completed: {
            title: 'Payment has been processed',
            text: `A <sb>{amount}</sb> payment from <sb>{companyName}</sb> {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} was sent to you as a Single-Use Card`,
          },
          notCleared: {
            text: `On <sb>{deliveryEta}</sb>, your <sb>{amount}</sb> payment {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} was sent to <sb>{vendorEmail}</sb> as a single-use virtual card.`,
            vendorReceiveTitle: 'YOU RECEIVED',
          },
        },
        offerExpired: {
          check: {
            title: 'Offer has expired',
            text: `This <b>{amount}</b> payment from <b>{companyName}</b> {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} is already on its way to you.`,
            vendorReceive: {
              title: 'YOU’ll RECEIVE',
              paperCheckTo: 'Paper check to',
              deliveryETA: 'Estimated payment delivery',
            },
          },
        },
        paymentBlocked: {
          title: 'Payment couldn’t be processed',
          description:
            'There was an issue processing the payment sent by {companyName}. You can reach out to {companyEmail} for further details.{br}{br}If you need any help we’re available at {supportEmail}',
        },
        paymentCanceled: {
          title: 'Payment was cancelled',
          description:
            '{companyName} has canceled their payment. You can reach out to {companyEmail} for additional info or to request they resend.{br}{br}If you need any help we’re available at {supportEmail}',
        },
        checkDeposited: {
          title: 'Your check has already been deposited',
          text: `This <sb>{amount}</sb> payment from <sb>{companyName}</sb> {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} has already been deposited.`,
          paymentDetailsTitle: 'Payment details',
          paperCheckTo: 'Paper check to',
          depositedDate: 'Check was deposited on',
        },
        achDeposited: {
          title: 'Payment was deposited to your bank account',
          text: `This <sb>{amount}</sb> payment from <sb>{companyName}</sb> {invoiceNumberType, select,
              bulkPayment {for <sb>multiple invoices</sb>}
              regularPayment {for <sb>invoice {invoiceNumber}</sb>}
              empty {}} has already been deposited.`,
          paymentDetailsTitle: 'Payment details',
          bankTransfer: 'Bank transfer',
          bankAccount: 'Bank account ...{digits}',
          depositedDate: 'Delivered on',
        },
        invalidToken: `Sorry but it seems like this link isn’t valid anymore. If you need a new link or have questions email us at: {email}`,
      },
      unilateral: {
        shiftVirtualCardToAch: {
          successPage: {
            title: 'Success!',
            text: 'Your <b>{amount}</b> payment from <b>{companyName}</b> is being sent to your bank account.',
            bankTransfer: 'Bank transfer to',
          },
          newBankAccount: {
            info: `By continuing, you agree to the Bill Pay powered by Melio {termsOfService}
              and {privacyPolicy}, and consent to Intuit and its subsidiaries'
              use of your Bill Pay data subject to our {privacyStatement}.`,
            termsOfService: 'terms of service',
            privacyPolicy: 'privacy policy',
            privacyStatement: 'privacy statement',
          },
          existingBankAccount: {
            title: 'Payment will be sent as a bank transfer (ACH)',
            submit: 'Send payment to this account',
            needHelp: 'Need to update your bank account details?{br}Contact support at {supportLink}',
          },
        },
        virtualCardDetails: {
          sent: 'has sent you an online payment using QuickBooks Desktop',
          estimate:
            'The card is valid until <b>{date}</b>, and can only be used in your POS terminal to receive the total payment amount.',
          issuerAddress: ISSUER_ADDRESS,
          issuerAddressHint: 'Note: Some processing systems require an address.',
          zipCode: ISSUER_ZIP_CODE,
          footerNote:
            'Having trouble processing the card? You can <link>contact support</link> for assistance or to change how you receive this payment.',
          previewCard: {
            validThru: 'VALID{br}THRU',
            cvc: 'CVC',
          },
          labels: {
            cardNumber: 'Card number',
            expiryDate: 'Expiry date',
            cvc: 'CVC',
            zipCode: 'Zip code',
            issuerAddress: 'Card issuer address',
          },
          notifications: {
            cardNumber: 'Card number copied',
            expiryDate: 'Expiry date copied',
            cvc: 'CVC copied',
            zipCode: 'Zip code copied',
            issuerAddress: 'Card issuer address copied',
          },
          processCard: {
            sectionTitle: 'How to use the card',
            first: {
              title: 'Enter the card details into your POS terminal',
              subtitle: 'Make sure you enter the exact details and amount.',
            },
            second: {
              title: 'Process the card',
              subtitle: 'Use your POS terminal or processing system.',
            },
            third: {
              title: 'Receive payment instantly',
              subtitle: 'Get paid instantly, as soon as you process the card.',
            },
          },
          posTerminal: {
            title: 'What’s a POS terminal?',
            text:
              'A point of sale (POS) terminal is a device or software used to process card payments for credit, debit and single-use cards.',
            getCardDetails: 'View card details',
            receiveBankTransferInstead: 'Receive bank transfer instead',
          },
          paymentCanceled: {
            title: 'This payment has been cancelled',
            text:
              '<b>{payorName}</b> has cancelled this payment. You can email them at {link} and ask them to resend payment.',
            helpInfo: 'Can’t accept cards right now? {contactSupport}',
          },
          cardExpired: {
            title: 'This card expired on {endDate}',
            text: 'Recover {payorName}’s <b>{amount}</b> payment by issuing a new single-use card.',
            submit: 'Issue a new card',
          },
          newDeliveryMethodForPayment: {
            title: 'New delivery method for this payment',
            text:
              '<b>{payorName}</b> has updated the delivery method for this payment. The card can no longer be processed.',
            vendorReceive: {
              bankAccount: 'Bank account ...{digits}',
            },
          },
          newDeliveryMethodProcessed: {
            title: 'Payment was deposited to your bank account',
            receivedPayment: 'YOU RECEIVED A',
            deliveryOn: 'Delivery on ',
          },
          virtualCardProcessed: {
            title: 'Payment was cleared',
            text: 'Your business already processed this single-use card on {date}.',
            cardData: '{email} (...{digits})',
            processedOn: 'Processed on',
          },
        },
        payor: '<b>{payorName}</b>{br}sent you an online payment of',
        invoiceNumber: 'For invoice no.',
        invoiceNote: 'Included note:',
        methodTitle: '<b>How do you want to receive this payment?</b>',
        deliveryMethods: {
          virtualCard: {
            title: 'Single-use virtual card',
            text: '3 business days. Card processor required.',
            hint: 'What’s a single-use virtual card?',
            reviewAndConfirm: {
              agreement:
                'If confirmed, future payments from <b>{payorName}</b> will also be sent to you as single-use virtual cards.',
              title: 'Receive payment by single-use virtual card',
              text:
                'We’ll send the card to your email. Enter the card details into your POS terminal to process the payment. (Processing fees may apply)',
              paymentDetails: 'Payment details',
              amount: '<b>Amount</b>: {amount}',
              deliverTo: '<b>Deliver to</b>: {vendorEmail}',
              changeEmail: 'Want to change this email? {link}',
              contactTo: 'Contact {payorName}',
              paymentETA: '<b>Payment ETA</b>: {date}',
              whyPOS: 'Why do I need a POS terminal?',
              posNote: 'important',
              descriptionPOS:
                'A POS terminal, a card processing device, or software is needed to process the card and only for the total payment amount.',
              confirm: 'Confirm and receive card',
              changeMethod: 'Change delivery method',
            },
            learnMore: {
              title: 'What’s a single-use virtual card?',
              text:
                'It’s a virtual card sent to your email as a payment. The card details can be used in a POS terminal to process it like any other card payment.',
              howItWorksTitle: 'How does it work?',
              howItWorksText:
                'Enter the card details and total amount into your POS terminal, card processing device or software to get the payment instantly.',
              confirm: 'Receive a single-use card',
            },
          },
          ACH: {
            title: 'Bank transfer (ACH)',
            text: '3 business days.',
            actionHint:
              'Future payments from <b>{payorName}</b> will also arrive via ACH bank transfer method.{br}Fees may apply according to your card processing provider.',
          },
          paperCheck: {
            title: 'Paper check',
            text: '5-7 business days.',
            actionHint:
              'Future payments from <b>{payorName}</b> will also arrive via paper check transfer method.{br}Fees may apply according to your card processing provider.',
          },
        },
        virtualCardSuccess: {
          title: 'Success!',
          text:
            'On <b>{date}</b>, Your <b>{amount}</b> payment will be sent to <b>{vendorEmail}</b> as a single-use virtual card, as will all future payments from <b>{companyName}</b>.',
          vendorReceive: {
            title: 'YOU’LL RECEIVE',
            text: 'Single-use card to',
            deliveryETA: 'Delivery ETA',
            deliveryTimeHelperText: 'By 8PM local time',
          },
        },
      },
    },
    linkCard: {
      button: 'Save my card and continue',
      cardNumberTitle: 'Card number',
      cardNumberPlaceholder: '13-19 digits',
      cardExpirationDateTitle: 'Valid through',
      cardExpirationDatePlaceholder: 'MM/YY',
      cardSecurityCodeTitle: 'CVV',
      cardSecurityCodePlaceholder: '3-4 digits',
      errors: {
        cardNumber: {
          empty: 'Please enter a card number',
          invalid: 'This card number is not valid',
        },
        cardExpirationDate: {
          empty: 'Please enter expiration date',
          invalid: 'This expiration date is not valid',
        },
        cardSecurityCode: {
          empty: 'Please enter the security code',
          invalid: 'Minimum of 3 digits required',
        },
      },
    },
    vendorEmail: {
      label: 'Vendor’s business email',
      placeholder: 'Email addresss',
      errors: {
        empty: 'Please enter vendor’s email',
      },
    },
    mtl: {
      risk: {
        validations: {
          Invalid: 'Please enter the correct value',
          AddressLine1ContainsPOBox: 'Enter a valid physical address (P.O. Boxes are not accepted)',
          AddressLine1Missing: 'Enter a street number, street name, city, and state.',
          BusinessIndustryMissing: 'Select an industry',
          BusinessIndustryMustBeUserProvided: 'Business industry must be user provided',
          BusinessIndustryMustHaveAtLeastOneNaicsCodeOrIndustryText:
            'A description or 6-digit NAICS code classifying your industry is required',
          BusinessTypeMissing: 'A business type is required',
          BusinessTypeNotAllowedValue: 'BusinessTypeNotAllowedValue',
          CityMissing: 'Enter a city',
          CompanyNameMissing: 'Enter a company name',
          CompanyNameMustBe3CharsOrMore: 'Must be at least 3 characters',
          CompanyNameMustHaveAtLeast2CharsAlphanumeric: 'Must be at least 2 characters',
          ContactEmailFormatInvalid: 'Enter a valid email address',
          ContactEmailMissing: 'Enter an email address',
          ContactFirstNameMissing: 'Enter a first name',
          ContactFirstNameMustAlphabeticSpaceHyphenDotComma: 'Must only contain letters (A-Z)',
          ContactFirstNameMustBe2CharsOrMore: 'Must be at least 2 characters',
          ContactLastNameMissing: 'Enter a last name',
          ContactLastNameMustAlphabeticSpaceHyphenDotComma: 'Must only contain letters (A-Z).',
          ContactLastNameMustBe2CharsOrMore: 'Must be at least 2 characters',
          ContactPhoneInvalidFormat: 'Invalid format',
          ContactPhoneMissing: 'Enter a business phone number',
          CountryCodeMissing: 'Enter a country code',
          CountryCodeMustBeUsVariants: 'Enter a valid US country code',
          LegalAddressLine1Missing: 'Enter a street number, street name, city, and state.',
          LegalCityMissing: 'Enter a city',
          LegalCompanyNameMissing: 'Enter a legal business name.',
          LegalCompanyNameMustBe3CharsOrMore: 'Must be at least 3 characters',
          LegalCountryCodeMissing: 'Enter a country code',
          LegalCountryCodeMustBeUsVariants: 'Legal country code must be US variants',
          LegalStateMissing: 'Enter a state',
          LegalStateMustBe2Chars: 'Must be at least 2 characters',
          LegalZipCodeMissing: 'Enter a ZIP code',
          OrganizationUserAgeMustBeAbove18: 'You must be at least 18 years old to continue',
          OrganizationUserAgeMustBeBelow120: 'Enter a valid date of birth',
          OrganizationUserDobMissing: 'Enter a date of birth',
          StateMissing: 'Enter a state',
          StateMustBe2Chars: 'Must be at least 2 characters',
          StateNotExists: 'Enter a valid State',
          TaxIdMissing: 'Select a Tax ID',
          TaxIdMustBe9Digits: 'The Tax ID number must have 9 digits',
          TaxIdTypeEinMissing: 'Enter an Employee Identification Number',
          TaxIdTypeEinMustBe9Digits: 'Enter a valid 9 digit Employee Identification Number',
          TaxIdTypeItinInvalidBusinessType: 'Invalid business type for ITIN tax ID type',
          TaxIdTypeItinInvalidTaxIdPrefix: 'Enter a valid 9 digit International Taxpayer Identification Number',
          TaxIdTypeItinMissing: 'Enter an International Taxpayer Identification Number',
          TaxIdTypeItinMustBe9Digits: 'Enter a valid 9 digit International Taxpayer Identification Number',
          TaxIdTypeMissing: 'Select a Tax ID type',
          TaxIdTypeNotAllowedValue: 'Not allowed value',
          TaxIdTypeSsnInvalidBusinessType: 'Invalid business type for SSN tax ID type',
          TaxIdTypeSsnMissing: 'Enter a Social Security Number',
          TaxIdTypeSsnMustBe9Digits: 'Enter a valid 9 digit Social Security Number',
          ZipCodeMissing: 'Enter a ZIP code',
        },
      },
    },
  },
};
