export const sortAndGroupVendors = (vendors) =>
  vendors
    .sort((a, b) => a.companyName.localeCompare(b.companyName))
    .reduce((alphaSortObj, contactObj) => {
      const firstLetter = contactObj.companyName.substr(0, 1).toLowerCase();
      if (!alphaSortObj[firstLetter]) {
        alphaSortObj[firstLetter] = [];
      }

      alphaSortObj[firstLetter].push(contactObj);
      return alphaSortObj;
    }, {});
