import React, { useMemo } from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';
import { DashboardColumnsEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import { DashboardPaymentMethodCell } from '../common-cells/DashboardPaymentMethodCell/DashboardPaymentMethodCell';
import { DashboardPaidActionCell } from '../actions-cells/actions-paid/DashboardPaidActionsCell';
import { useDashboardTableVariant, DASHBOARD_TABLE_VARIANT } from '../hooks/useDashboardTableVariant';
import { DashboardVendorNameCell } from '../common-cells/DashboardVendorNameCell/DashboardVendorNameCell';
import { DashboardInvoiceNumberCell } from '../common-cells/DashboardInvoiceNumberCell';
import { DashboardDueDateCell } from '../common-cells/DashboardDueDateCell';
import { DashboardPaidStatusCell } from './DashboardPaidStatusCell';
import { DashboardPaidOnCell } from './DashboardPaidOnCell';
import { DashboardDeliveryMethodCell } from '../common-cells/DashboardDeliveryMethodCell';
import { DashboardAmountCell } from '../common-cells/DashboardAmountCell';
import { ColumnItem } from '../../types';

type ResponseType = [ColumnItem<DashboardListItemType>[]];

export function usePaidColumns(): ResponseType {
  const dashboardVariant = useDashboardTableVariant();
  const paidColumns = useMemo(() => {
    let columns = [
      {
        Header: <MIFormattedText label="paymentDashboard.columns.vendor.title" />,
        accessor: DashboardColumnsEnum.VendorName,
        Cell: DashboardVendorNameCell,
        width: '12.8rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.invoiceNumber.title" />,
        accessor: DashboardColumnsEnum.InvoiceNumber,
        Cell: DashboardInvoiceNumberCell,
        width: '12.5rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.dueDate.title" />,
        accessor: DashboardColumnsEnum.DueDate,
        Cell: DashboardDueDateCell,
        width: '12.5rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.paidStatus.title" />,
        accessor: DashboardColumnsEnum.Status,
        Cell: DashboardPaidStatusCell,
        width: '12.5rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.paidOn.title" />,
        accessor: DashboardColumnsEnum.DeliveryEta,
        Cell: DashboardPaidOnCell,
        width: '12.5rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.paymentMethod.title" />,
        accessor: DashboardColumnsEnum.FundingType,
        Cell: DashboardPaymentMethodCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.deliveryMethod.title" />,
        accessor: DashboardColumnsEnum.DeliveryType,
        Cell: DashboardDeliveryMethodCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.amount.title" />,
        accessor: DashboardColumnsEnum.PaymentAmount,
        Cell: DashboardAmountCell,
        isNumeric: true,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.actions.title" />,
        accessor: DashboardColumnsEnum.Actions,
        width: '12.8rem',
        Cell: DashboardPaidActionCell,
        isNumeric: true,
        isOrderingDisabled: true,
      },
    ];

    if (dashboardVariant === DASHBOARD_TABLE_VARIANT.LIMITED) {
      columns = columns.filter(
        ({ accessor }) => ![DashboardColumnsEnum.FundingType, DashboardColumnsEnum.DeliveryType].includes(accessor)
      );
    }

    return columns;
  }, [dashboardVariant]);

  return [paidColumns];
}
