import React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { withNavigator } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';
import AreaLoader from 'src/components/common/AreaLoader';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import useVirtualDeliveryMethodState from './hooks/useVirtualDeliveryMethodState';

type Props = {
  locationState: Record<string, any>;
  navigate: (arg0: string) => void;
  vendorId: string;
};

const QBOVirtualDeliveryMethodPageContainer = ({ navigate, vendorId }: Props) => {
  const site = useSiteContext();
  const {
    virtualDeliveryMethodVM,
    submit,
    vendor,
    isFormProcessing,
    isVendorLoading,
    goExit,
  } = useVirtualDeliveryMethodState({
    vendorId,
    navigate,
    shouldIgnorePhone: true,
  });

  if (isVendorLoading) {
    return <AreaLoader />;
  }

  const vendorCompanyName = vendor?.companyName || vendor?.printName;
  return (
    <QBDTStepLayout
      title="vendors.deliveryMethods.virtual.title"
      titleValues={{ vendor: vendorCompanyName }}
      subtitle="vendors.deliveryMethods.virtual.subtitle"
      subTitleValues={{ vendor: vendorCompanyName }}
      goExit={goExit}
      onNext={submit}
      nextLabel="vendors.deliveryMethods.virtual.done"
      isLoading={isFormProcessing}
      innerSize={50}
      footer={
        <site.components.WizardElements.SimpleTextFooter>
          <MIFormattedText label="vendors.deliveryMethods.virtual.footer" values={{ vendor: vendorCompanyName }} />
        </site.components.WizardElements.SimpleTextFooter>
      }
    >
      <FormContainer>
        <WizardTextInputField
          label="vendors.deliveryMethods.virtual.email"
          model={virtualDeliveryMethodVM.virtualAccount.email}
          required
          type="email"
          autoFocus
          autocomplete="username email"
        />
      </FormContainer>
    </QBDTStepLayout>
  );
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;

export default compose(withNavigator())(QBOVirtualDeliveryMethodPageContainer);
