import { useModal } from 'src/helpers/react/useModal';
import { DeletePaymentModal } from '../components/DeletePaymentModal';

export const useCancelPayment = () => {
  const [cancelPaymentModal, openPaymentCancelModal] = useModal(DeletePaymentModal, {});

  return {
    openPaymentCancelModal,
    cancelPaymentModal,
  };
};
