import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import authStore from 'src/modules/auth/auth-store';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { getProfile, getCompanyInfo } from 'src/redux/user/selectors';
import { isLoginFromForest } from 'src/utils/user-utils';
import analytics from 'src/services/analytics';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { QBDTRouter } from 'src/billpay/qbdt/pages/QBDTRouter';
import { buildRoutes } from './route';
import { OrgsRouter } from './orgs/OrgsRouter';
import { VendorsRouter } from './vendor/VendorsRouter';
import { MicroDepositsVerifyRouter } from './funding-sources/MicroDepositsVerifyRouter';
import locations from './locations';

type Props = {
  history: any;
};

export const Router = ({ history }: Props) => {
  const site = useSiteContext();
  const userAuthType = useSelector(authStore.selectors.userAuthType);
  const companyInfo = useSelector(getCompanyInfo);
  const profile = useSelector(getProfile);

  useEffect(
    () =>
      history.listen(() => {
        if (history.action === 'POP') {
          analytics.trackAction('history-pop');
        }
      }),
    [history]
  );

  useEffect(() => {
    const onUnload = () => {
      analytics.trackAction('page-left');
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  useEffect(() => {
    if (!isLoginFromForest()) {
      analytics.identify(profile, undefined, {
        isLoggedInAs: false,
        companyInfo,
      });
    }
  }, [profile, companyInfo]);

  const routes = buildRoutes(site, userAuthType);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <SmartRoute path={locations.qbdt.base}>
          <QBDTRouter />
        </SmartRoute>
        <SmartRoute path={locations.orgs.index}>
          <OrgsRouter orgsRoutes={routes.orgsRoutes} />
        </SmartRoute>
        <SmartRoute path={locations.vendor.base} allowFor={['unauthenticated', 'authenticated']}>
          <VendorsRouter />
        </SmartRoute>
        <SmartRoute path={locations.microDepositsVerify.base}>
          <MicroDepositsVerifyRouter />
        </SmartRoute>
        {routes.publicRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Switch>
    </ConnectedRouter>
  );
};
