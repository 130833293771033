import React, { useEffect } from 'react';
import { useSiteContext } from 'src/hoc/withSiteContext';
import styled from 'styled-components';
import { ReactComponent as ActionBanned } from 'src/images/general/action-banned-icon.svg';
import { useParams } from 'react-router-dom';
import analytics from 'src/services/analytics';
import {
  microDepositsEventAction,
  microDepositsEventPage,
  microDepositsEventStatuses,
} from 'src/pages/funding-sources/consts';
import { StyledQBDTFooterContainer, StyledQBDTSuccessLayout } from 'src/pages/funding-sources/components/common';

export function MicroDepositsBlockedPageNew() {
  const site = useSiteContext();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    analytics.track(microDepositsEventPage, microDepositsEventAction, {
      fundingSourceId: id,
      status: microDepositsEventStatuses.ACCOUNT_BLOCKED,
    });
  }, []);

  return (
    <StyledQBDTSuccessLayout
      hideHeader
      illustration={<ErrorIcon />}
      title="fundingSources.microDeposits.limitReached.title"
      text="fundingSources.microDeposits.limitReached.subtitle"
      FooterComponent={<StyledQBDTFooterContainer />}
      textValues={{
        supportEmail: site.config.support.email,
      }}
    />
  );
}

const ErrorIcon = styled(ActionBanned)`
  margin-bottom: 4rem;
`;
