import { RecordOf } from 'immutable';

import {
  BeginRegularPayBillFlowType,
  SelectNewDeliveryMethodType,
  CreatePaymentType,
  UpdatePaymentType,
  PaymentFeeInfo,
} from './types';
import { BillType, PaymentType, DeliveryMethodType, AccountLimitations } from '../../utils/types';
import {
  BEGIN_REGULAR_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
  SELECT_FUNDING_SOURCE,
  SELECT_PAYMENT_DATES,
  UPDATE_PAYMENT_MEMO,
  END_PAY_BILL_FLOW,
  SELECT_DELIVERY_METHOD,
  ADD_NEW_DELIVERY_METHOD,
  SELECT_NEW_DELIVERY_METHOD,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_ERROR,
  FETCH_BILL,
  FETCH_BILL_SUCCESS,
  FETCH_BILL_FAILED,
  SET_URL_TO_BACK,
  RESET_ERROR,
  SET_FEE,
  UPDATE_BILL,
  RETRY_FAILED_PAYMENT,
  RETRY_FAILED_PAYMENT_SUCCESS,
  RETRY_FAILED_PAYMENT_ERROR,
  SET_FAQ_PANEL,
  SET_COMPLIANCE_LIMITATIONS,
} from './actionTypes';

export const beginRegularPayBillFlowSuccessAction = (bill: RecordOf<BillType>, payment: RecordOf<PaymentType>) => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  bill,
  payment,
});

export const beginRegularPayBillFlowFailedAction = () => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
});

export type BeginRegularPayBillFlowActionProps = {
  id: string;
  paymentId: string;
  redirectUrl?: string;
  exitUrl?: string;
};

export const beginRegularPayBillFlowAction = ({
  id,
  paymentId,
  redirectUrl,
  exitUrl,
}: BeginRegularPayBillFlowActionProps): BeginRegularPayBillFlowType => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW,
  id,
  paymentId,
  redirectUrl,
  exitUrl,
});

export const endPayBillFlowAction = () => ({
  type: END_PAY_BILL_FLOW,
});

export const selectFundingSourceAction = (id: number | null | undefined) => ({
  type: SELECT_FUNDING_SOURCE,
  id,
});

export const selectPaymentDatesAction = (
  scheduledDate: Date,
  deliveryEta: Date,
  maxDeliveryEta: Date,
  deliveryPreference?: string
) => ({
  type: SELECT_PAYMENT_DATES,
  scheduledDate,
  deliveryEta,
  maxDeliveryEta,
  deliveryPreference,
});

export const setFeeAction = (payload: PaymentFeeInfo) => ({
  type: SET_FEE,
  payload,
});

export const selectDeliveryMethodAction = (deliveryMethod?: DeliveryMethodType) => ({
  type: SELECT_DELIVERY_METHOD,
  deliveryMethod,
});

export const addNewDeliveryMethodAction = (deliveryMethod: DeliveryMethodType) => ({
  type: ADD_NEW_DELIVERY_METHOD,
  deliveryMethod,
});

export const selectNewDeliveryMethodAction = (deliveryMethod: DeliveryMethodType): SelectNewDeliveryMethodType => ({
  type: SELECT_NEW_DELIVERY_METHOD,
  deliveryMethod,
});

export const updatePaymentMemoAction = (memo: string) => ({
  type: UPDATE_PAYMENT_MEMO,
  memo,
});

export const updateBillAction = (goodsReceived: boolean) => ({
  type: UPDATE_BILL,
  goodsReceived,
});

export const fetchBillAction = (id: string) => ({
  type: FETCH_BILL,
  id,
});

export const fetchBillSuccessAction = (bill: RecordOf<BillType>) => ({
  type: FETCH_BILL_SUCCESS,
  bill,
});

export const fetchBillFailedAction = () => ({
  type: FETCH_BILL_FAILED,
});

export const createPaymentSuccessAction = (payment: RecordOf<PaymentType>) => ({
  type: CREATE_PAYMENT_SUCCESS,
  payment,
});

export const createPaymentErrorAction = (errorCode: string | null) => ({
  type: CREATE_PAYMENT_ERROR,
  errorCode,
});

export const createPaymentAction = (resolve: () => void, reject: (error: any) => void): CreatePaymentType => ({
  type: CREATE_PAYMENT,
  resolve,
  reject,
});

export const updatePaymentSuccessAction = (payment: RecordOf<PaymentType>) => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payment,
});

export const updatePaymentErrorAction = (errorCode: string | null) => ({
  type: UPDATE_PAYMENT_ERROR,
  errorCode,
});

export const updatePaymentAction = (resolve: () => void, reject: (error: any) => void): UpdatePaymentType => ({
  type: UPDATE_PAYMENT,
  resolve,
  reject,
});

export const retryFailedPaymentSuccessAction = (payment: RecordOf<PaymentType>) => ({
  type: RETRY_FAILED_PAYMENT_SUCCESS,
  payment,
});

export const retryFailedPaymentErrorAction = (errorCode: string | null) => ({
  type: RETRY_FAILED_PAYMENT_ERROR,
  errorCode,
});

export const retryFailedPaymentAction = (resolve: () => void, reject: (error: any) => void): CreatePaymentType => ({
  type: RETRY_FAILED_PAYMENT,
  resolve,
  reject,
});

export const resetErrorAction = () => ({
  type: RESET_ERROR,
});
export const setUrlToBackAction = (urlToBack: string): any => ({
  type: SET_URL_TO_BACK,
  urlToBack,
});

export const setFaqPanel = (faqPanel: { isOpen: boolean; focusedQuestion?: number }): any => ({
  type: SET_FAQ_PANEL,
  faqPanel,
});

export const setComplianceLimitations = (payload: AccountLimitations) => ({
  type: SET_COMPLIANCE_LIMITATIONS,
  payload,
});
