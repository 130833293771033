import React from 'react';
import styled from 'styled-components';
import { InlineTextInputField } from 'src/ui/form/InlineTextInputField';
import { FormRow } from 'src/ui/form/FormElements';

type Props = {
  submit: any;
  achModel: any;
};

const NewAchDeliveryMethodForm = ({ submit, achModel }: Props) => (
  <FormContainer onSubmit={submit}>
    <RowContainer>
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.routingNumber"
        model={achModel.routingNumber}
        required
        type="tel"
        autoFocus
      />
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.accountNumber"
        model={achModel.accountNumber}
        required
        type="tel"
      />
    </RowContainer>
    <button type="submit" hidden />
  </FormContainer>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem;
`;

const RowContainer = styled(FormRow)`
  margin-top: 2rem;
  align-items: flex-start;
`;

export default NewAchDeliveryMethodForm;
