import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { getSite, SiteProvider } from 'src/context/SiteContext';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { setupStore } from '../../redux/store';
import { TranslationProvider } from '../../services/translation-service';

const history = createBrowserHistory();

type Props = {
  children: React.ReactNode;
};

const SiteAwareProviders = withSiteContext()(({ site, children }) => {
  const { store, persistor, intl } = setupStore(site, history);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <TranslationProvider value={intl}>{children}</TranslationProvider>
      </PersistGate>
    </Provider>
  );
});

const AppProviders = ({ children }: Props) => (
  <SiteProvider site={getSite()}>
    <SiteAwareProviders>{children}</SiteAwareProviders>
  </SiteProvider>
);

export { history, AppProviders };
