import { Badge } from '@melio/billpay-design-system';
import React from 'react';
import { MIFormattedText } from 'src/utils/formatting';
import { UnpaidBillDashboardStatusesEnum } from 'src/billpay/qbdt/pages/dashboard/consts';
import { DashboardListItemType } from 'src/billpay/qbdt/pages/dashboard/types';

type Props = {
  item: DashboardListItemType;
};

export const DashboardUnpaidStatusCell = ({ item }: Props) => {
  const { statusInDashboard } = item.metadata;
  if (statusInDashboard === UnpaidBillDashboardStatusesEnum.Failed) {
    return (
      <Badge variant="error">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.failed" />
      </Badge>
    );
  }

  if (statusInDashboard === UnpaidBillDashboardStatusesEnum.Overdue) {
    return (
      <Badge variant="errorOutlined">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.overdue" />
      </Badge>
    );
  }

  if (statusInDashboard === UnpaidBillDashboardStatusesEnum.DueInOneWeek) {
    return (
      <Badge variant="warning">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.dueInOneWeek" />
      </Badge>
    );
  }

  if (statusInDashboard === UnpaidBillDashboardStatusesEnum.Open) {
    return (
      <Badge variant="lightGray">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.dueLater" />
      </Badge>
    );
  }

  return null;
};
