const baseUrl = '/quickbooks-desktop/orgs/:orgId/vendors/:vendorId/delivery-method';

const editBaseUrl = '/quickbooks-desktop/orgs/:orgId/vendors/:vendorId/delivery-method/:deliveryMethodId';
const LOCATIONS = {
  base: [baseUrl],
  edit: {
    ach: `${editBaseUrl}/ach`,
    check: `${editBaseUrl}/check/name`,
    checkAddress: `${editBaseUrl}/check/address`,
  },
  virtual: `${baseUrl}/virtual/new`,
  ach: `${baseUrl}/ach/new`,
  check: `${baseUrl}/check/new/name`,
  checkAddress: `${baseUrl}/check/new/address`,
};

export default LOCATIONS;
