import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { CONSTS } from 'src/utils/consts';
import { useForm } from 'src/ui/form';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { WizardPasswordInputField } from 'src/ui/form/WizardPasswordInputField';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { MIFormattedText } from 'src/utils/formatting';
import { QBDTStepLayout } from 'src/billpay/qbdt/components/QBDTStepLayout';
import * as WizardElements from 'src/billpay/qbdt/components/layout/WizardElement';

const ViewManualBankAccountPage = () => {
  const { goBack } = useHistory();
  const params = useParams<{ orgId: string; id: string }>();
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(params.id));
  const model = useMemo(() => pick(fundingSource?.bankAccount, ['routingNumber', 'accountNumber']), [fundingSource]);
  const [bankAccountVM] = useForm(model, { submit: async () => noop });

  return (
    <QBDTStepLayout
      title="onboarding.fundingSources.bank.manually.view.title"
      subtitle="onboarding.fundingSources.bank.manually.view.subtitle"
      goExit={goBack}
      onSubmit={goBack}
      nextLabel="progress.prev"
      hideHeader
      fullWidthCTA
      footer={
        <WizardElements.SimpleTextFooterSlim>
          <MIFormattedText label="onboarding.fundingSources.bank.manually.saveFooter" />
        </WizardElements.SimpleTextFooterSlim>
      }
    >
      <WizardFormContainer>
        <WizardTextInputField
          model={bankAccountVM.routingNumber}
          label="onboarding.fundingSources.bank.manually.routingNumber"
          type="tel"
          autoFocus
          required
          disabled
          privateData
        />

        <WizardPasswordInputField
          model={bankAccountVM.accountNumber}
          label="onboarding.fundingSources.bank.manually.accountNumber"
          required
          pattern={CONSTS.INPUT_PATTERNS.NUMBERS}
          inputMode="numeric"
          disabled
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </QBDTStepLayout>
  );
};

export default ViewManualBankAccountPage;

const HiddenInput = styled.input`
  display: none;
`;
