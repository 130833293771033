import { useRef } from 'react';
import { CardChangeEvent } from '@basis-theory/basis-theory-js/types/elements/events';
import { Brand } from '@basis-theory/basis-theory-js/types/elements';
import { BasisTheoryCardsBrands } from 'src/billpay/qbdt/components/basis-theory/brands-logos/consts';
import { BasisTheoryCardBrandType } from 'src/billpay/qbdt/components/basis-theory/brands-logos/types';

type Props = {
  onChange(event: Omit<CardChangeEvent, 'cardBrand'>): void;
  onCardBrandChange(cardBrand: BasisTheoryCardBrandType): void;
};

export const useHandleCardNumberChange = ({ onChange, onCardBrandChange }: Props) => {
  const cardBrandRef = useRef<BasisTheoryCardBrandType>(BasisTheoryCardsBrands.unknown);

  const isSupportedByBasisTheory = (cardBrand: Brand) =>
    Object.values(BasisTheoryCardsBrands).includes(cardBrand as BasisTheoryCardBrandType);

  const handleChange = ({ cardBrand, ...event }: CardChangeEvent) => {
    onChange({ ...event });

    if (cardBrand !== cardBrandRef.current && isSupportedByBasisTheory(cardBrand)) {
      cardBrandRef.current = cardBrand as BasisTheoryCardBrandType;
      onCardBrandChange(cardBrand as BasisTheoryCardBrandType);
    }
  };

  return {
    handleChange,
  };
};
