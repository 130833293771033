import React, { useState } from 'react';
import { Box } from '@melio/billpay-design-system';
import {
  useDashboardTableVariant,
  DASHBOARD_TABLE_VARIANT,
} from 'src/billpay/qbdt/pages/dashboard/components/DashboardTable/columns/hooks/useDashboardTableVariant';
import NotificationCard from 'src/components/qbo/QBONotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/utils/consts';

type Props = {
  hidden: boolean;
};

const DashboardTableVariantNotification = ({ hidden }: Props) => {
  const dashboardVariant = useDashboardTableVariant();
  const [closed, setClosed] = useState<boolean>(false);

  if (dashboardVariant === DASHBOARD_TABLE_VARIANT.FULL || closed || hidden) {
    return null;
  }

  const handleCloseClick = () => {
    setClosed(true);
  };

  return (
    <Box w="full" pt="ds.lg" px="ds.xl" boxSizing="border-box">
      <NotificationCard
        type={NOTIFICATION_CARD_TYPES.INFO}
        onClose={handleCloseClick}
        subtitle={{
          label: 'paymentDashboard.notifications.smallWidth.message',
        }}
      />
    </Box>
  );
};

export { DashboardTableVariantNotification };
