import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { featureFlags } from '@melio/shared-web';
import { isAfter } from 'date-fns';
import { Box } from '@melio/billpay-design-system';
import { CSSObject } from '@chakra-ui/system';

import { getUTCDate } from 'src/utils/dates';
import organizationStore from 'src/modules/organizations/organizations-store';
import { FeatureFlags } from 'src/utils/feature-flags';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { setFaqPanel } from 'src/redux/payBillWizard/actions';
import { feeType } from 'src/billpay/qbdt/hooks/useCheckFee/types';

/**
 * Shows notification after applied fee in confirm page
 */
type Props = {
  fee: feeType;
  orgId: string;
  styles?: CSSObject;
};
const NotificationAppliedFee = ({ fee, orgId, styles }: Props) => {
  const [limitModalDate]: any = featureFlags.useFeature(FeatureFlags.CheckFeesLimitDate);
  const dispatch = useDispatch();
  const organization = useSelector(organizationStore.selectors.byId(orgId));

  const openLearnMore = () => {
    dispatch(setFaqPanel({ isOpen: true, focusedQuestion: 16 }));
  };

  const limitDate = !!limitModalDate && new Date(limitModalDate); // utc already from ff
  const createdDate = getUTCDate(organization.createdAt); // org created date
  const isAfterLimitDate = createdDate && limitDate && isAfter(createdDate, limitDate);

  let values: Record<string, any> = {
    learnMoreLink: (
      <LearnMoreLink onClick={openLearnMore}>
        <MIFormattedText label="deliveryMethods.notDefinedInfo.check.learnMoreLink" />
      </LearnMoreLink>
    ),
    fee,
  };

  const prefix = 'deliveryMethods.notDefinedInfo.check.appliedFee.notification';
  let text = {
    title: `${prefix}.before.title`,
    body: `${prefix}.before.body`,
  };

  if (isAfterLimitDate) {
    text = {
      title: `${prefix}.after.title`,
      body: `${prefix}.after.body`,
    };

    values = { fee };
  }

  return (
    <Box mb="ds.xl" sx={styles}>
      <StyledCard
        type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
        title={{
          label: text.title,
        }}
        subtitle={{
          label: text.body,
          values,
        }}
      />
    </Box>
  );
};

export { NotificationAppliedFee };

const StyledCard = styled(QBONotificationCard)`
  .notification-title {
    padding-bottom: 0.4rem;
  }
  a {
    cursor: pointer;
  }
`;

const LearnMoreLink = styled.a`
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
`;
