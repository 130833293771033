import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import paymentStore from 'src/modules/payments/payment-store';
import { SET_PROFILE, SET_COMPANY_INFO } from 'src/redux/user/actionTypes';
import { OrganizationRestApi } from 'src/services/api/organizations';
import isEmpty from 'lodash/isEmpty';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { assignPaymentRequestToOrgSlice, getOrgAssignedToPaymentRequestSlice } from './multi-orgs';
import { activitySlice, updateNewCompanySlice } from './organizations-slice';

const name = 'organizations';

const persistConfig = {
  whitelist: ['create'],
};

const organizationStore = createRestfulSlice({
  name,
  schemaName: 'companyInfo',
  persistConfig,
  api: OrganizationRestApi,
  extraReducers: {
    [SET_PROFILE](state, payload) {
      const { organizations } = payload;
      (organizations || []).forEach((org) => {
        state.byId[org.id] = org;
      });
    },
    [SET_COMPANY_INFO](state, payload) {
      state.byId[payload.companyInfo.id] = {
        ...state.byId[payload.companyInfo.id],
        ...payload.companyInfo.toJS(),
      };
    },
    [paymentStore.actions.fetchPaymentDetailsWithToken.success](state, { payload }) {
      const { organization } = payload.payment;
      state.byId[organization.id] = organization;
    },
    [paymentStore?.actions.fetchEmailToVendorDetails.success](state: any, { payload }) {
      const { organization } = payload.payment;
      state.byId[organization.id] = organization;
    },
    [deliveryMethodsStore.actions.fetchUnilateralRequestDetails.success](state: any, { payload }) {
      const { organization } = payload;
      state.byId[organization.id] = organization;
    },
  },
  selectors: {
    byId: (id) => (state) => state[name].byId[id],
    getNewCompanyInfo: (state) =>
      isEmpty(state[name].create?.company) ? { email: '', companyName: '' } : state[name].create?.company,
  },
  slices: {
    activitySlice,
    updateNewCompanySlice,
    assignPaymentRequestToOrg: assignPaymentRequestToOrgSlice,
    getOrgAssignedToPaymentRequest: getOrgAssignedToPaymentRequestSlice,
  },
});

export function getOrganizationStoreActions(dispatch: any) {
  return {
    getActivitySummary: (params?: { noPaid?: boolean }) => dispatch(organizationStore.actions.activitySlice(params)),
    updateNewCompanyInfo: (params: any) => dispatch(updateNewCompanySlice.actions.update(params)),
    create: (params: any) => dispatch(organizationStore.actions.create(params)),
  };
}

export default organizationStore;
