import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import organizationApi from 'src/services/api/organizations';
import { logger } from 'src/services/loggers';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { setComplianceLimitations } from 'src/redux/payBillWizard/actions';
import { getComplianceLimitations } from 'src/redux/payBillWizard/selectors';

export const useComplianceLimitations = () => {
  const orgId = useSelector(getOrgId);
  const limitations = useSelector(getComplianceLimitations);
  const dispatch = useDispatch();

  const [getComplianceLimitationsData, , isComplianceLimitationsLoading] = useApi(
    organizationApi.getComplianceLimitations
  );

  useEffect(() => {
    if (limitations?.isCompliance) {
      return;
    }

    loadComplianceLimitations();
  }, []);

  const loadComplianceLimitations = async () => {
    try {
      const data = await getComplianceLimitationsData(orgId);
      dispatch(setComplianceLimitations(data));
    } catch (error) {
      logger.error('organizationApi.getComplianceLimitations(): failed to load limitations', error);
    }
  };

  return {
    limitations,
    isComplianceLimitationsLoading,
  };
};
