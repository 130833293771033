import { Flex, Tag } from '@melio/billpay-design-system';
import React, { useMemo } from 'react';
import { chakra } from 'src/theme/ds';
import { MIFormattedText } from 'src/utils/formatting';
import { ExtendedSelectOption } from 'src/utils/types';
import { FiltersChangeInfo, MenuFilterItem } from '../types';
import { getLabelFromOption } from '../utils';

type SelectedOptionItem = Pick<MenuFilterItem, 'fieldName' | 'options'> &
  Pick<ExtendedSelectOption, 'label' | 'values'>;

type Props = {
  menuItems: MenuFilterItem[];
  onChange: (change: FiltersChangeInfo) => void;
};

export const DashboardFilterSelectedOptions = ({ menuItems, onChange }: Props) => {
  const handleClearFilter = (fieldName: string, options: ExtendedSelectOption[], filterLabel: string) => {
    const optionsWithClearedValue = options.map((option) => {
      const isFilterToRemove = getLabelFromOption(option) === filterLabel;

      return {
        ...option,
        value: isFilterToRemove ? false : option.value,
        selected: isFilterToRemove ? false : option.selected,
      };
    });

    onChange({
      fieldName,
      options: optionsWithClearedValue,
    });
  };

  const handleClearAll = () => onChange({ fieldName: null });

  const selectedFilterOptions = useMemo(
    () =>
      menuItems.reduce((acc, { fieldName, options }) => {
        const selectedOptions: SelectedOptionItem[] = options
          .filter(({ value }) => value)
          .map(({ label, values }) => ({
            fieldName,
            options,
            label,
            values,
          }));

        return [...acc, ...selectedOptions];
      }, [] as SelectedOptionItem[]),
    [menuItems]
  );

  if (!selectedFilterOptions.length) {
    return null;
  }

  return (
    <TagsContainer>
      {selectedFilterOptions.map(({ label, values, fieldName, options }) => {
        const dynamicLabel = getLabelFromOption({ label, values });

        return (
          <Tag key={dynamicLabel} style={{ margin: 0 }}>
            <Tag.TagLabel>
              <MIFormattedText label={label} values={values} />
            </Tag.TagLabel>
            <Tag.TagCloseButton onClick={() => handleClearFilter(fieldName, options, dynamicLabel)} />
          </Tag>
        );
      })}
      <ClearLabel onClick={handleClearAll}>
        <MIFormattedText label="paymentDashboard.filters.clearLabelMessage" />
      </ClearLabel>
    </TagsContainer>
  );
};

const ClearLabel = chakra('div', {
  baseStyle: {
    minW: '5.5rem',
    p: '0.6rem 0.4rem',
    color: 'ds.blue.100',
    cursor: 'pointer',
    textStyle: 'ds.body2',
  },
});

const TagsContainer = chakra(Flex, {
  baseStyle: {
    flexWrap: 'wrap',
    gap: 'ds.md',
    zIndex: '1',
  },
});
