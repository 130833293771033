import { Record } from 'immutable';
import { PaymentType } from '../../utils/types';

export const PaymentRecord: Record.Factory<PaymentType> = Record(
  {
    id: '',
    status: '',
    organizationId: '',
    amount: NaN,
    balance: NaN,
    billId: '',
    bill: {
      id: '',
      invoiceNumber: '',
    },
    bills: [],
    vendorId: '',
    vendor: {
      id: '',
      companyName: '',
    },
    fundingSourceId: NaN,
    fundingSource: {
      displayName: '',
    },
    deliveryMethodId: '',
    deliveryMethod: {
      id: '',
      deliveryType: null,
    },
    currency: '',
    originId: '',
    scheduledDate: null,
    deliveryEta: null,
    maxDeliveryEta: null,
    actualStartDate: '',
    deliveryCompleteDate: '',
    deliverStatus: '',
    note: null,
    manual: false,
    createdAt: new Date(),
    createdById: null,
    createOrigin: '',
    transactions: [],
    approvalDecisionById: NaN,
    approvalDecisionDate: new Date(),
    approvalDecisionReason: '',
    approvalDecisionStatus: '',
    riskStatus: null,
    paymentApprovalActions: [],
    deliveryPreference: null,
    originDeliveryPreference: null,
    metadata: {
      canUserRetry: false,
      canUserContactSupport: false,
      failureMessage: '',
      isDeletable: false,
    },
    checkTracks: [],
    intuitAccountId: null,
    virtualCards: null,
    payBillFlowUUID: '',
  } as any,
  'PaymentRecord'
);
