import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/billpay/qbdt/pages/locations';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { dashboardStore } from 'src/billpay/qbdt/pages/dashboard/redux-store/dashboard-store';
import { DashboardMenuAction } from '../types';
import { DashboardActionItemsEnum } from '../consts';

type Params = {
  billId: string;
  paymentId?: string;
};

export const useViewPaymentMenuAction = ({ billId, paymentId }: Params) => {
  const orgId = useSelector(getOrgId);
  const location = useLocation();
  const history = useHistory();
  const dashboardActions = useStoreActions(dashboardStore);

  const goViewPayment = useCallback(() => {
    const exitUrl = `${location.pathname}${location.search}`;

    dashboardActions.viewPaymentUrls.setExitUrl(exitUrl);

    // TODO: dashboard - add sync step before go to view payment page
    const viewPaymentUrl = generatePath(locations.view.base, {
      orgId,
      billId,
      paymentId,
    });

    history.push(viewPaymentUrl);
  }, [location.pathname, location.search, dashboardActions.viewPaymentUrls, orgId, billId, paymentId, history]);

  const viewPaymentMenuAction: DashboardMenuAction = {
    id: DashboardActionItemsEnum.View,
    onClick: goViewPayment,
    label: 'paymentDashboard.columns.actions.view',
  };

  return {
    viewPaymentMenuAction,
    goViewPayment,
  };
};
