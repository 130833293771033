import React from 'react';
import { Icon, Flex } from '@melio/billpay-design-system';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { FundingSourcesList } from './FundingSourcesList/FundingSourcesList';
import { DeliveryMethodsList } from './DeliveryMethodsList/DeliveryMethodsList';

export const FlowScheme = () => {
  const arrowContainer = (
    <Flex justify="center" align="center" w="3.3rem" h="3.3rem" mt="3.72rem">
      <Icon as={ArrowIcon} w="full" h="full" />
    </Flex>
  );

  return (
    <Flex justify="space-between" w="full" mt="ds.2xl">
      <FundingSourcesList />
      {arrowContainer}
      <DeliveryMethodsList />
    </Flex>
  );
};
