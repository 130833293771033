import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Redirect, generatePath, useParams, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { QBDTStepLayout } from 'src/billpay/qbdt/components/QBDTStepLayout';
import { MIFormattedText } from 'src/utils/formatting';
import { getUrlToBack } from 'src/redux/payBillWizard/selectors';
import analytics from 'src/services/analytics';
import { setUrlToBackAction } from 'src/redux/payBillWizard/actions';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import locations from './locations';
import PaymentMethodsSettingsPageContainer from './PaymentMethodsSettingsPageContainer';
import { BillingSettingsPageContainer } from './BillingSettingsPageContainer';
import { VendorSettingsRouter } from './vendors/VendorSettingsRouter';
import { AccountSettingsPageContainer } from './AccountSettingsPageContainer';
import { BILLING_ITEMS_DEFAULT_SEARCH_PARAMS } from './consts';

export function QBDTSettingsRouter() {
  const params = useParams();
  const history = useHistory();
  const urlToBack = useSelector(getUrlToBack);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const billingSearchQuery = new URLSearchParams(BILLING_ITEMS_DEFAULT_SEARCH_PARAMS).toString();

  function goExit() {
    analytics.track('qbo-settings', 'exit');
    history.push(urlToBack, state);
    dispatch(setUrlToBackAction(''));
  }

  return (
    <React.Fragment>
      <Close onClick={goExit} />
      <QBDTStepLayout headerLabel="qbo.settings.title" innerSize={75} contentWrapperMode="settings" hideHeader>
        <SettingsContainer>
          <SettingsTitle>
            <MIFormattedText label="qbo.settings.title" />
          </SettingsTitle>
          <SettingsCard>
            <SettingsNavigationContainer>
              <StyledNavLink to={locations.paymentMethods} preserveState>
                <MIFormattedText label="settings.paymentMethods.title" />
              </StyledNavLink>
              <StyledNavLink
                to={{
                  pathname: locations.billing,
                  search: `?${billingSearchQuery}`,
                }}
                preserveState
              >
                <MIFormattedText label="settings.billing.title" />
              </StyledNavLink>
              <StyledNavLink data-testId="vendors-settings-tab" to={locations.vendors} preserveState>
                <MIFormattedText label="settings.vendors.title" />
              </StyledNavLink>
              <StyledNavLink to={locations.account} preserveState>
                <MIFormattedText label="settings.account.title" />
              </StyledNavLink>
            </SettingsNavigationContainer>
            <Switch>
              <SmartRoute path={locations.paymentMethods}>
                <PaymentMethodsSettingsPageContainer />
              </SmartRoute>
              <SmartRoute path={locations.billing}>
                <BillingSettingsPageContainer />
              </SmartRoute>
              <SmartRoute path={locations.vendors}>
                <VendorSettingsRouter />
              </SmartRoute>
              <SmartRoute path={locations.account}>
                <AccountSettingsPageContainer />
              </SmartRoute>
              <Redirect
                to={{
                  pathname: generatePath(locations.paymentMethods, params),
                  state,
                }}
              />
            </Switch>
          </SettingsCard>
        </SettingsContainer>
      </QBDTStepLayout>
    </React.Fragment>
  );
}

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 1.4rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 1;
  color: ${(props) => props.theme.text.color.label};
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin: 4rem 0 4rem 0;
`;

const SettingsNavigationContainer = styled.div`
  margin: 0 3.6rem;
  border-bottom: 0.2rem solid rgba(212, 215, 220, 1);
  display: flex;
  justify-content: space-between;
`;

const SettingsCard = styled.div`
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 0.9rem;
  box-shadow: none;
  box-sizing: border-box;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin-top: 1rem;
`;

const SettingsTitle = styled.h2`
  margin: 0;
  font-size: 2.3rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: ${(props) => props.theme.text.color.main};
  line-height: 3.1rem;
`;

const StyledNavLink = styled(SmartLink)`
  color: rgba(51, 51, 51, 1);
  flex-direction: row;
  height: 2.8rem;
  margin: 2.2rem 1.6rem -0.2rem 0;
  text-decoration: none;
  &.active {
    border-bottom: 0.4rem solid ${(props) => props.theme.colors.primary.active};
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
  ${(props) => props.theme.text.fontType.medium};
`;
