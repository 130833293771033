import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { CONSTS } from 'src/utils/consts';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';

type Props = {
  onClose: () => void;
  billsCount: number;
};

export const BillsExceedAmountLimitCard = ({ billsCount, onClose }: Props) => (
  <Box mt="ds.xl">
    <QBONotificationCard
      type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
      onClose={onClose}
      title={{
        label: 'regularBatchPayments.notifications.billsExceedAmountLimitTitle',
        values: {
          count: billsCount,
        },
      }}
      subtitle={{
        label: 'regularBatchPayments.notifications.billsExceedAmountLimitSubTitle',
      }}
    />
  </Box>
);
