import React from 'react';
import { Record } from 'immutable';
import { MIFormattedText, getFormattedAddress } from 'src/utils/formatting';
import { addressDefaults } from 'src/records/settings.record';
import {
  DeliveryMethodType,
  VendorType,
  BankType,
  CheckType,
  OptionalDeliveryMethodsType,
  AddressType,
} from '../../utils/types';
import { BANK_ACCOUNT_TYPE, DELIVERY_TYPE, VERIFIED_STATUS } from '../../utils/consts';
import { getAccountNumber4digits } from '../../utils/bank-account';

export const DeliveryMethodRecord = Record<DeliveryMethodType>(
  {
    id: '',
    logo: '',
    deliveryType: DELIVERY_TYPE.ACH,
    paperCheck: null,
    bankAccount: null,
    plaidAccount: null,
    hasScheduledPayments: false,
    isFilledByVendor: false,
    isVerified: false,
    verifiedStatus: VERIFIED_STATUS.NOT_VERIFIED,
    createdById: '',
    updatedAt: null,
    cardAccountId: null,
    intuitAccountId: null,
    virtualCardAccount: null,
    cardAccount: null,
    getDisplayName(vendorName = '') {
      return getDeliveryMethodDisplayName({ deliveryMethod: this, vendorName });
    },
    getDeliveryInfo(vendorName = '', isRPPSDeliveryMethod?: boolean) {
      return getDeliveryMethodInfo({
        deliveryMethod: this,
        vendorName,
        isRPPSDeliveryMethod,
      });
    },
  },
  'DeliveryMethodRecord'
);

export const getDeliveryMethodDisplayName = ({
  deliveryMethod,
  vendorName = '',
  isSharedVendor,
}: {
  deliveryMethod: DeliveryMethodType;
  vendorName: string;
  isSharedVendor?: boolean;
}): string => {
  if (isSharedVendor) {
    return vendorName ?? 'Melio Network';
  }

  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);
  if (deliveryMethod?.deliveryType === DELIVERY_TYPE.ACH && accountNumber4digits) {
    return `${vendorName} (...${accountNumber4digits})`;
  }

  if (deliveryMethod?.paperCheck) {
    const { addressLine1, addressLine2, city, state, zipCode, countryCode } = deliveryMethod.paperCheck;
    const address = `${addressLine1}${
      addressLine2 ? `, ${addressLine2}` : ''
    }, ${city}, ${state}, ${zipCode}, ${countryCode}`;
    return `${deliveryMethod.paperCheck.printName}  | ${address}`;
  }

  return '';
};

export const getDeliveryMethodInfo = ({
  deliveryMethod,
  vendorName,
  isSharedVendor,
  isRPPSDeliveryMethod,
}: {
  deliveryMethod: DeliveryMethodType;
  vendorName: string;
  isSharedVendor?: boolean;
  isRPPSDeliveryMethod?: boolean;
}): string | JSX.Element => {
  if (isSharedVendor) {
    return vendorName || 'Melio Network';
  }

  if (isRPPSDeliveryMethod) {
    return <MIFormattedText label="deliveryMethods.rpps" />;
  }

  if (deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
    return deliveryMethod.virtualCardAccount?.accountEmail || '';
  }

  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);
  if (deliveryMethod?.deliveryType === DELIVERY_TYPE.ACH && accountNumber4digits) {
    return `${vendorName} (...${accountNumber4digits})`;
  }

  if (deliveryMethod?.paperCheck) {
    return getFormattedAddress(deliveryMethod?.paperCheck);
  }

  if (deliveryMethod?.deliveryType === DELIVERY_TYPE.CARD) {
    return `${vendorName} (...${deliveryMethod.cardAccount?.card4digits})`;
  }

  return '';
};

export const getDeliveryMethodByType = (type: OptionalDeliveryMethodsType, deliveryMethods: DeliveryMethodType[]) => {
  let deliveryMethod: DeliveryMethodType | undefined;
  if (deliveryMethods) {
    deliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === type);
  }

  if (!deliveryMethod) {
    return DeliveryMethodRecord({ id: '', deliveryType: type });
  }

  return DeliveryMethodRecord(deliveryMethod);
};

export const VendorRecord = Record<VendorType>(
  {
    id: '',
    origin: '',
    originId: '',
    companyName: '',
    accountIdentifier: '',
    address: { ...addressDefaults },
    deliveryMethods: [],
    updatedById: '',
    createdAt: undefined,
    createdById: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    paymentRequestId: '',
    ownedById: null,
    isOwned: false,
    managedBy: null,
    isEditable: false,
    handle: '',
    hasDeliveryMethods: false,
    vendorTrusted: false,
    getDeliveryMethodByType(type: OptionalDeliveryMethodsType) {
      return getDeliveryMethodByType(type, this.deliveryMethods);
    },
    getDeliveryMethodById(id: string) {
      return DeliveryMethodRecord(this.deliveryMethods.find((dm) => dm.id === id));
    },
  },
  'VendorRecord'
);

export const BankRecord = Record<BankType>(
  {
    accountType: BANK_ACCOUNT_TYPE.CHECKING,
    routingNumber: '',
    accountNumber: '',
    intuitAccountId: '',
    isBlocked: false,
    accountNumber4digits: '',
  },
  'BankRecord'
);

export const CheckRecord = Record<CheckType>(
  {
    ...addressDefaults,
    printName: '',
    isAddressSuggestionIgnored: null,
  },
  'CheckRecord'
);

export const AddressRecord = Record<AddressType>(
  {
    ...addressDefaults,
  },
  'AddressRecord'
);
