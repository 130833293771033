/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree, EventMappingType } from 'src/services/analytics/types';
import batchPaymentsMapping from 'src/billpay/qbdt/pages/batch-pay/analytics/event-mapping';

const QBDT_ENTRY_MAPPING: Record<any, EventMappingType> = {
  'qbdt-entry-phase': [
    'qbdt-entry',
    '${phase}',
    {
      orgId: 'orgId',
      errorMessage: 'errorMessage',
      appcenterUrl: 'appcenterUrl',
      response: 'response',
      entry: 'entry',
    },
  ],
  'qbdt-entry': [
    'qbdt-entry',
    'entrypoint',
    {
      entrypoint: 'entrypoint',
      numberOfBills: 'numberOfBills',
    },
  ],
  'qbo.firstTimeNew.button': ['qbdt-entry', 'confirm-clicked'],
  'error-page': [
    'qbdt-entry',
    'error',
    {
      title: 'title',
      subtitle: 'subtitle',
      errorMessage: 'errorMessage',
    },
  ],
};

const MAPPINGS: EventMappingsTree = {
  '/quickbooks-desktop/orgs/*/settings/payment-methods': {
    'toggle-actions-menu': ['settings-payment-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/quickbooks-desktop/orgs/*/settings/receiving-methods': {
    'toggle-actions-menu': ['settings-receiving-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/quickbooks-desktop/orgs/*/settings/vendors/*': {
    'toggle-actions-menu': ['settings-vendors-delivery-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'qbo-view-vendors-actions': ['settings-vendors', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/quickbooks-desktop/orgs/*/settings/account': {},
  '/quickbooks-desktop/orgs/*/bills/pay/batch-success': {
    'batchPayment.success.buttonLabel': ['pay-bills-batch-success', 'done'],
    'support@qbdtusers.meliopayments.com': ['pay-bills-batch-success', 'support-email'],
  },
  '/quickbooks-desktop/orgs/*/bills/pay/batch-complete-legal-info': {
    ...mapApiSlice('[USER] SET_COMPANY_INFO', 'complete-legal-info', 'continue'),
    ...mapApiSlice('[PAYMENTS] SEND_BATCH_PAYMENTS', 'pay-bills-batch-payments', 'schedule-batch-payments', {
      failure: {
        paid: 'payload.paid.length',
        payments: 'meta.identifier.payments.length',
      },
    }),
  },
  '/quickbooks-desktop/orgs/*/bills/pay/batch/add-first-funding-source': {
    'page.view': ['pay-bills-batch-payments', 'add-first-funding-source'],
  },
  '/quickbooks-desktop/orgs/*/funding-source/bank/manual': {
    'form-validation-error': [
      'bank-add-microdeposit',
      'bank-add-microdeposit-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'go.exit': ['bank-add-microdeposit', 'funding-source-added-manually-close'],
    'funding-source-added-manually': ['bank-add-microdeposit', 'funding-source-added-manually', { billIds: 'billIds' }],
    'funding-source-added-manually-success': [
      'bank-add-microdeposit',
      'funding-source-added-manually-success',
      { billIds: 'billIds' },
    ],
    'PlaidFailedUseDifferentPaymentMethod-Selected': [
      'bank-add-microdeposit',
      'PlaidFailedUseDifferentPaymentMethod-Selected',
      { billIds: 'billIds' },
    ],
  },
  '/quickbooks-desktop/orgs/*/funding-source/link/account': {
    'qbdt-account-connected': [
      'qbdt-account',
      'payment-account-connected',
      { accountType: 'type', fundingSourceId: 'fundingSourceId' },
    ],
  },
  '/quickbooks-desktop/orgs/*/bills/*/pay/funding': {
    'page.view': ['pay-bill', 'add-funding'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/payment/*/edit/funding': {
    'page.view': ['pay-bill', 'edit-funding'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/pay/delivery-method': {
    'page.view': ['pay-bill', 'add-delivery'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/payment/*/edit/delivery-method': {
    'page.view': ['pay-bill', 'edit-delivery-method'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/pay/date': {
    'page.view': ['pay-bill', 'date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/payment/*/edit/date': {
    'page.view': ['pay-bill', 'edit-date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/pay/memo': {
    'page.view': ['pay-bill', 'memo'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/payment/*/edit/memo': {
    'page.view': ['pay-bill', 'edit-memo'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/pay/confirm': {
    'page.view': ['pay-bill', 'confirm'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/payment/*/edit/confirm': {
    'page.view': ['pay-bill', 'edit-confirm'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/pay/success': {
    'page.view': ['pay-bill', 'success'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/pay/complete-legal-info': {
    'page.view': ['pay-bill', 'complete-legal-info'],
  },
  '/quickbooks-desktop/orgs/*/vendors/*/delivery-method/virtual/new': {
    ...mapApiSlice('[DELIVERYMETHODS] CREATE', 'virtual-delivery-method', 'create-delivery-method'),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE', 'virtual-delivery-method', 'update-delivery-method'),
    'go.exit': ['virtual-delivery-method', 'exit'],
  },
  '/quickbooks-desktop/orgs/*/bills/*/payment/*/view': {
    'page.view': ['bills', 'single-qbo-bill'],
    'bills.form.paymentActivity.actions.cancel': ['bill', 'delete-single-payment'],
    'payments.form.deleteDialog.cancelText': ['bill', 'delete-payment-canceled'],
  },
  '/quickbooks-desktop/orgs/*/sync/no-data': {
    'page.view': ['sync', 'no-data'],
  },
  '/quickbooks-desktop/entry/*/pay': {
    'page.view': ['qbdt-entry-page', 'pay'],
    'closing-date-present': ['qbdt-entry-page', 'closing-date-present', { closingDate: 'closingDate' }],
    'closing-date-missed': ['qbdt-entry-page', 'closing-date-missed'],
    ...QBDT_ENTRY_MAPPING,
  },
  '/quickbooks-desktop/entry/*/sync': {
    'page.view': ['qbdt-entry-page', 'sync'],
    ...QBDT_ENTRY_MAPPING,
    'sync-success': ['qbdt-entry-page', 'sync-success'],
    'end-payments-sync': [
      'qbdt-sync',
      'end-payments-sync',
      {
        internalPaymentsSyncDuration: 'internalPaymentsSyncDuration',
        internalPaymentsCount: 'internalPaymentsCount',
        failedToSyncInternalPaymentsCount: 'failedToSyncInternalPaymentsCount',
        syncedInternalPaymentsCount: 'syncedInternalPaymentsCount',
        regularPaymentsSyncDuration: 'regularPaymentsSyncDuration',
        regularPaymentsCount: 'regularPaymentsCount',
        failedToSyncRegularPaymentsCount: 'failedToSyncRegularPaymentsCount',
        syncedRegularPaymentsCount: 'syncedRegularPaymentsCount',
      },
    ],
    'internal-payment-sync-failed': [
      'qbdt-sync',
      'internal-payment-sync-failed',
      {
        errorMessage: 'errorMessage',
        paymentId: 'paymentId',
      },
    ],
    'regular-payment-sync-failed': [
      'qbdt-sync',
      'regular-payment-sync-failed',
      {
        errorMessage: 'errorMessage',
        paymentId: 'paymentId',
      },
    ],
    'end-modified-bills-sync': [
      'qbdt-entry-page',
      'end-modified-bills-sync',
      {
        totalCount: 'totalCount',
        duration: 'duration',
        failedToSyncBillsCount: 'failedToSyncBillsCount',
        syncedBillsCount: 'syncedBillsCount',
      },
    ],
    'bill-sync-failed': [
      'qbdt-sync',
      'bill-sync-failed',
      {
        errorMessage: 'errorMessage',
        txnId: 'txnId',
      },
    ],
  },
  '/quickbooks-desktop/entry/*/view-payment': {
    'page.view': ['qbdt-entry-page', 'view-payment'],
    ...QBDT_ENTRY_MAPPING,
    'track-entity-params': [
      'qbdt-entry-page',
      'view-payment-entity-type',
      {
        externalEntityId: 'externalEntityId',
        externalEntityType: 'externalEntityType',
      },
    ],
    'payment-external-reference-missing': [
      'qbdt-entry-page',
      'view-payment-external-reference-missing',
      {
        externalEntityId: 'externalEntityId',
      },
    ],
    'payment-external-reference-restored': [
      'qbdt-entry-page',
      'view-payment-external-reference-restored',
      {
        externalEntityId: 'externalEntityId',
        paymentId: 'paymentId',
      },
    ],
  },
  '/quickbooks-desktop/entry/*/auth-error': {
    ...QBDT_ENTRY_MAPPING,
  },
  ...batchPaymentsMapping,
};

export default MAPPINGS;
