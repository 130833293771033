import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/redux/user/selectors';
import analytics from 'src/services/analytics';
import locations from 'src/billpay/qbdt/pages/locations';
import { getPartialBillId } from 'src/utils/bills';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useViewPaymentContext } from '../../context/ViewPaymentContext';

const eventPage = 'bill';

export const useRetryPaymentDelivery = () => {
  const [historyPush] = useHistoryWithOrgId();
  const { bill, payment } = useViewPaymentContext();

  const orgId = useSelector(getOrgId);

  const deliveryAchEditUrl = generatePath(locations.edit.deliveryMethodAch, {
    orgId,
    billId: bill.id,
    paymentId: payment.id,
  });

  const exitUrl = generatePath(locations.view.base, {
    orgId,
    billId: bill.id,
    paymentId: payment.id,
  });

  const onRetryPaymentDelivery = () => {
    analytics.track(eventPage, 'retry-payment-delivery', {
      billId: bill.id,
      partialBillId: getPartialBillId(bill),
      paymentId: payment.id,
      deliveryMethodId: payment.deliveryMethodId,
    });

    historyPush({
      path: deliveryAchEditUrl,
      state: {
        exitUrl,
      },
    });
  };

  return { onRetryPaymentDelivery };
};
