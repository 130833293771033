import chunk from 'lodash/chunk';
import { logger } from 'src/services/loggers';
import {
  batchDeleteBills as batchDeleteBillsFromMelio,
  getUnpaidBillsExternalIds as getUnpaidBillsExternalIdsFromMelio,
} from 'src/services/api/qbdt';
import organizationPreferencesApi from 'src/services/api/organizationPreferences';
import { OrganizationPreferencesType } from 'src/utils/types';
import {
  getBillForUnpaidRecheck as getBillFromQBDT,
  getBillsForUnpaidRecheck as getBillsFromQBDT,
} from '../entities/bill';

const UnpaidBillsChunkSize = 20;
const MaxBillsToSyncCount = 1000;

export async function removeIncorrectlySyncedUnpaidPaidBill({
  orgId,
  organizationPreferences,
}: {
  orgId: number;
  organizationPreferences: OrganizationPreferencesType;
}) {
  if (organizationPreferences.qbdtUnpaidBillsChecked === 'true') {
    return;
  }

  try {
    const { unpaidBillsExternalIds } = await getUnpaidBillsExternalIdsFromMelio(orgId);
    if (unpaidBillsExternalIds.length > MaxBillsToSyncCount) {
      logger.warn(
        `recheckUnpaidBillsSync.removeIncorrectlySyncedUnpaidPaidBill(): a lot of bills to sync, orgId: ${orgId}, billsCount: ${unpaidBillsExternalIds.length}`
      );
    }

    const chunkOfUnpaidBills = chunk<string>(unpaidBillsExternalIds, UnpaidBillsChunkSize);
    for (const txnIds of chunkOfUnpaidBills) {
      // eslint-disable-next-line no-await-in-loop
      const paidBillsTxnIds = await getQBDTBillsTnxIdsPaidInQBDT(txnIds);
      if (paidBillsTxnIds.length > 0) {
        // eslint-disable-next-line no-await-in-loop
        await batchDeleteBillsFromMelio(orgId, paidBillsTxnIds);
      }
    }

    await organizationPreferencesApi.updateOrganizationPreference(String(orgId), 'qbdtUnpaidBillsChecked', 'true');
  } catch (e: any) {
    logger.error(
      `recheckUnpaidBillsSync.removeIncorrectlySyncedUnpaidPaidBill(): failed to recheck bills, orgId: ${orgId}, errorMessage: ${e?.message}`
    );
  }
}

async function getQBDTBillsTnxIdsPaidInQBDT(billTxnIds: string[]): Promise<string[]> {
  try {
    const qbdtBills = await getBillsFromQBDT(billTxnIds);
    return qbdtBills.filter((qbdtBill) => qbdtBill.IsPaid).map((qbdtBill) => qbdtBill.TxnID);
  } catch (err) {
    const paidQBDTBillsTxnIds: string[] = [];
    for (const billTxnId of billTxnIds) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const qbdtBill = await getBillFromQBDT(billTxnId);
        if (qbdtBill.IsPaid) {
          paidQBDTBillsTxnIds.push(billTxnId);
        }
      } catch (e) {
        paidQBDTBillsTxnIds.push(billTxnId);
      }
    }

    return paidQBDTBillsTxnIds;
  }
}
