import React from 'react';
import { featureFlags } from '@melio/shared-web';

import config from 'src/config';
import SiteIcon from 'src/components/layout/SiteIcon';
import SiteTitle from 'src/components/layout/SiteTitle';
import { ToastContainer, Bounce } from 'react-toastify';
import { AppProviders, history } from './components/layout/AppProviders';
import ErrorBoundary from './pages/general/ErrorBoundary';
import { Router } from './pages/Router';
import Segment from './components/common-app/Segment';
import EnvRibbon from './components/common/EnvRibbon';
import { PreloadFonts } from './billpay/qbdt/components/PreloadFonts';

const App = () => (
  <AppProviders>
    <featureFlags.FeatureProvider>
      <EnvRibbon envName={config.env_ribbon_label || process.env.REACT_APP_ENV} />
      <SiteIcon />
      <SiteTitle />
      <PreloadFonts />
      <ErrorBoundary>
        <Segment />
        <Router history={history} />
      </ErrorBoundary>
      <ToastContainer
        transition={Bounce}
        closeButton={false}
        className="MIToastContainer"
        toastClassName="MIToastInner"
      />
    </featureFlags.FeatureProvider>
  </AppProviders>
);

export default App;
