import { CARD_PROVIDERS, CARD_TYPES } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';

export const isEligibleToReceiveVirtualCard = (fundingSource?: Partial<AccountType>): boolean => {
  const UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD = [CARD_PROVIDERS.VISA, CARD_PROVIDERS.AMEX];

  const blockedToVirtualCard =
    fundingSource?.cardAccount?.cardType === CARD_TYPES.CREDIT &&
    fundingSource?.cardAccount?.network &&
    UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD.includes(fundingSource?.cardAccount?.network as CARD_PROVIDERS);

  return !blockedToVirtualCard;
};
