import React, { PropsWithChildren, ReactNode } from 'react';
import { DEFAULT_VISIBILITY_DELAY, useDelayVisibility } from './hooks/useDelayedVisibility';
import { DEFAULT_MINIMUM_VISIBILITY_TIME, useMinimumVisibilityTime } from './hooks/useMinimumVisibilityTime';

type Props = {
  isLoading: boolean;
  renderLoader: () => ReactNode;
  delay?: number; // in ms
  minimumVisibilityTime?: number; // in ms
};

export const ViewWithDelayedLoader = ({
  isLoading,
  renderLoader,
  children,
  delay = DEFAULT_VISIBILITY_DELAY,
  minimumVisibilityTime = DEFAULT_MINIMUM_VISIBILITY_TIME,
}: PropsWithChildren<Props>) => {
  const delayedLoaderVisible = useDelayVisibility({
    visible: isLoading,
    delay,
  });

  const minimumTimeVisible = useMinimumVisibilityTime({
    minimumVisibilityTime,
    visible: delayedLoaderVisible,
  });

  return <>{minimumTimeVisible ? renderLoader() : children}</>;
};
