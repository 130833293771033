import { EffectObject, useEffectReducer } from 'use-effect-reducer';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import mapValues from 'lodash/mapValues';
import { useDispatch } from 'react-redux';
import { ActionCreator, createAction } from '@reduxjs/toolkit';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { AuthQuery, CheckStatus, qbdtCheckAuthentication, QBDTCheckResponse } from 'src/services/api/qbdt';
import { startSession, getCompanyFileGuid, syncValidatedCompanyInfo } from 'src/billpay/qbdt/services/qbdt';
import { ORGANIZATION_CREATE_ORIGIN } from 'src/utils/consts';
import locations from 'src/billpay/qbdt/pages/locations';
import analytics from 'src/services/analytics';

export enum AuthPhase {
  IDLE = 'idle',
  CHECKING = 'checking',
  INITIALIZE_USER = 'initialize-user',
  AUTHENTICATED = 'authenticated',
  REQUIRES_CONFIRMATION = 'requires-confirmation',
  REQUIRES_AUTH_WITH_APPCENTER = 'requires-auth-with-appcenter',
  REQUIRES_EMAIL_VERIFICATION = 'requires-email-verification',
  ERROR = 'error',
}
type EmptyAuthState = {
  phase: AuthPhase.IDLE | AuthPhase.CHECKING;
};

type InitializeUserState = {
  phase: AuthPhase.INITIALIZE_USER;
  response?: AuthSuccessPayload;
  orgId: string;
};

type AuthenticatedAuthState = {
  phase: AuthPhase.AUTHENTICATED;
  response?: AuthSuccessPayload;
  orgId?: string;
};
type RequiresAuthWithAppcenterState = {
  phase: AuthPhase.REQUIRES_CONFIRMATION | AuthPhase.REQUIRES_AUTH_WITH_APPCENTER;
  appcenterUrl: string;
};
type ErrorAuthState = {
  phase: AuthPhase.ERROR;
  errorMessage: string;
};

type RequiresEmailVerificationAuthState = {
  phase: AuthPhase.REQUIRES_EMAIL_VERIFICATION;
};

export type AuthState =
  | EmptyAuthState
  | InitializeUserState
  | AuthenticatedAuthState
  | RequiresAuthWithAppcenterState
  | ErrorAuthState
  | RequiresEmailVerificationAuthState;

type CheckMatchParams = { orgId: string; companyInfoMissing: boolean };
const startCheck = createAction<void, 'CHECK_START'>('CHECK_START');
const checkMatch = createAction<CheckMatchParams, 'CHECK_MATCH'>('CHECK_MATCH');
const checkUnknown = createAction<{ authUrl: string }, 'CHECK_UNKNOWN'>('CHECK_UNKNOWN');
const checkKnown = createAction<{ authUrl: string }, 'CHECK_KNOWN'>('CHECK_KNOWN');
const checkReauth = createAction<{ authUrl: string }, 'CHECK_REAUTH'>('CHECK_REAUTH');
const checkReject = createAction<void, 'CHECK_REJECT'>('CHECK_REJECT');
const checkError = createAction<{ error: string }, 'CHECK_ERROR'>('CHECK_ERROR');

const emailVerificationRequired = createAction<void, 'EMAIL_VERIFICATION_REQUIRED'>('EMAIL_VERIFICATION_REQUIRED');

const userInitialized = createAction<void, 'USER_INITIALIZED'>('USER_INITIALIZED');
const userConfirmed = createAction<void, 'USER_CONFIRMED'>('USER_CONFIRMED');

export type AuthSuccessPayload = {
  orgId: string;
  organizationCreated?: boolean;
  userOrganizationCreated?: boolean;
  companyInfoMissing?: boolean;
  userCreated?: boolean;
};
export type AuthFailurePayload = {
  error: string;
};
const authSuccess = createAction<AuthSuccessPayload, 'AUTH_SUCCESS'>('AUTH_SUCCESS');
const authError = createAction<AuthFailurePayload, 'AUTH_ERROR'>('AUTH_ERROR');

type CheckActionType =
  | ReturnType<typeof startCheck>
  | ReturnType<typeof checkMatch>
  | ReturnType<typeof checkUnknown>
  | ReturnType<typeof checkKnown>
  | ReturnType<typeof checkReauth>
  | ReturnType<typeof checkReject>
  | ReturnType<typeof checkError>;

type AuthActionType = ReturnType<typeof authSuccess> | ReturnType<typeof authError>;

type ActionType =
  | CheckActionType
  | AuthActionType
  | ReturnType<typeof userInitialized>
  | ReturnType<typeof userConfirmed>
  | ReturnType<typeof emailVerificationRequired>;

export type EventType = ActionType['type'];

export class AuthStateTransitionError extends Error {
  constructor(phase: AuthPhase, event: EventType) {
    super(`Illegal action type ${event} on state ${phase}`);
  }
}
function qboAuthReducer(state: AuthState, action: ActionType, exec): AuthState {
  const { phase } = state;
  if (state.phase === AuthPhase.IDLE) {
    if (startCheck.match(action)) {
      return { phase: AuthPhase.CHECKING };
    }
  } else if (state.phase === AuthPhase.CHECKING) {
    if (checkMatch.match(action)) {
      exec(startQbdtSession());
      exec(
        checkAndInitAction({
          orgId: action.payload.orgId,
          companyInfoMissing: !!action.payload.companyInfoMissing,
        })
      );
      return { phase: AuthPhase.INITIALIZE_USER, orgId: action.payload.orgId };
    } else if (checkUnknown.match(action) || checkReauth.match(action)) {
      return {
        phase: AuthPhase.REQUIRES_CONFIRMATION,
        appcenterUrl: action.payload.authUrl,
      };
    } else if (checkKnown.match(action)) {
      return {
        phase: AuthPhase.REQUIRES_AUTH_WITH_APPCENTER,
        appcenterUrl: action.payload.authUrl,
      };
    } else if (checkReject.match(action)) {
      return {
        phase: AuthPhase.ERROR,
        errorMessage: 'Internal issue - MQL unvalidated',
      };
    } else if (checkError.match(action)) {
      return { phase: AuthPhase.ERROR, errorMessage: action.payload.error };
    }
  } else if (state.phase === AuthPhase.INITIALIZE_USER) {
    if (userInitialized.match(action)) {
      return {
        phase: AuthPhase.AUTHENTICATED,
        orgId: state.orgId,
        response: state.response,
      };
    }

    if (emailVerificationRequired.match(action)) {
      return {
        phase: AuthPhase.REQUIRES_EMAIL_VERIFICATION,
      };
    }
  } else if (state.phase === AuthPhase.REQUIRES_CONFIRMATION) {
    if (userConfirmed.match(action)) {
      return {
        phase: AuthPhase.REQUIRES_AUTH_WITH_APPCENTER,
        appcenterUrl: state.appcenterUrl,
      };
    }
  } else if (state.phase === AuthPhase.REQUIRES_AUTH_WITH_APPCENTER) {
    if (authSuccess.match(action)) {
      exec(startQbdtSession());
      exec(
        checkAndInitAction({
          orgId: action.payload.orgId,
          companyInfoMissing: !!action.payload.companyInfoMissing,
        })
      );
      return {
        phase: AuthPhase.INITIALIZE_USER,
        orgId: action.payload.orgId,
        response: action.payload,
      };
    } else if (authError.match(action)) {
      return { phase: AuthPhase.ERROR, errorMessage: action.payload.error };
    }
  } else if (state.phase === AuthPhase.REQUIRES_EMAIL_VERIFICATION) {
    if (authSuccess.match(action)) {
      return {
        phase: AuthPhase.AUTHENTICATED,
      };
    }
  }

  if (state.phase === AuthPhase.AUTHENTICATED) {
    // final state;
  }

  throw new AuthStateTransitionError(phase, action.type);
}

function getActionForCheckStatus(payload: QBDTCheckResponse) {
  switch (payload.status) {
    case CheckStatus.KNOWN:
      return checkKnown({ authUrl: payload.authUrl });
    case CheckStatus.MATCHED:
      return checkMatch({
        orgId: payload.orgId,
        companyInfoMissing: payload.companyInfoMissing,
      });
    case CheckStatus.UNKNOWN:
      return checkUnknown({ authUrl: payload.authUrl });
    case CheckStatus.REAUTH:
      return checkReauth({ authUrl: payload.authUrl });
    case CheckStatus.REJECTED:
      return checkReject();
    default:
      throw new Error(`bad payload status ${(payload as any)?.status}`);
  }
}

const checkQBOAction = createAction<{ authQuery: AuthQuery }, 'checkQBO'>('checkQBO');
const checkAndInitAction = createAction<{ orgId: string; companyInfoMissing: boolean }, 'checkAndInit'>('checkAndInit');
const updateCompanyInfo = createAction<{ orgId: string }, 'updateCompanyInfo'>('updateCompanyInfo');
const startQbdtSession = createAction<void, 'startQbdtSession'>('startQbdtSession');

function checkQBO(state: AuthState, effect: EffectObject<AuthState, ActionType>, dispatch) {
  dispatch(startCheck());
  const payload = effect.payload as ReturnType<typeof checkQBOAction>['payload'];
  async function runCheckQBO() {
    try {
      const { response } = await getCompanyFileGuid();
      const intuitReturn = window.location.href;
      const intuitErrorReturn = `${window.location.origin}${generatePath(locations.entry.authError, {
        realmId: payload.authQuery.realmId,
      })}`;
      const qbdtCheckResponse = await qbdtCheckAuthentication({
        authQuery: {
          ...payload.authQuery,
          companyFileGuid: response.companyguid,
          intuitReturn,
          intuitErrorReturn,
        },
      });
      dispatch(getActionForCheckStatus(qbdtCheckResponse));
    } catch (error: any) {
      dispatch(checkError({ error: error.message }));
    }
  }

  runCheckQBO();
}

function useDispatchers<Actions extends Record<string, ActionCreator<any>>>(dispatch, actions: Actions) {
  const dispatchers = useMemo(() => mapValues(actions, (action) => (...args) => dispatch(action(...args))), [
    dispatch,
    actions,
  ]);
  return dispatchers as {
    [K in keyof Actions]: Actions[K] extends (...args: infer P) => any ? (...k: P) => void : null;
  };
}

function onUpdateCompanyInfo(state: AuthState, effect: EffectObject<AuthState, ActionType>) {
  if (updateCompanyInfo.match(effect)) {
    syncValidatedCompanyInfo(parseInt(effect.payload.orgId, 10));
  }
}

async function onCheckAndInit({ orgId, companyInfoMissing, reduxDispatch, dispatch }) {
  if (companyInfoMissing) {
    try {
      syncValidatedCompanyInfo(orgId);
    } catch (e) {
      analytics.track('qbdt-auth', 'updated-company-info-failed');
    }
  }

  try {
    const checkAndInitPromise = new Promise<void>((resolve, reject) => {
      reduxDispatch(
        checkAndInitUserAction(
          {
            orgId,
            origins: [
              ORGANIZATION_CREATE_ORIGIN.QBDT_INTEGRATION,
              ORGANIZATION_CREATE_ORIGIN.QBDT_MAC,
              ORGANIZATION_CREATE_ORIGIN.QBDT_WINDOWS,
            ],
          },
          resolve,
          reject
        )
      );
    });
    const initResult: any = await checkAndInitPromise;
    if (initResult.user?.isEmailVerified) {
      dispatch(userInitialized());
    } else {
      dispatch(emailVerificationRequired());
    }
  } catch (e) {
    dispatch(userInitialized());
  }
}

function onStartQbdtSession(state: AuthState, effect: EffectObject<AuthState, ActionType>) {
  if (startQbdtSession.match(effect)) {
    startSession();
  }
}
export function useQbdtOAuth(authQuery: AuthQuery) {
  const reduxDispatch = useDispatch();
  const [authState, dispatch] = useEffectReducer(
    qboAuthReducer,
    (exec) => {
      exec(checkQBOAction({ authQuery }));
      return {
        phase: AuthPhase.IDLE as const,
      };
    },
    {
      checkQBO,
      checkAndInit: (state: AuthState, effect: EffectObject<AuthState, ActionType>) => {
        const orgId = parseInt(effect.payload.orgId, 10);
        const { companyInfoMissing } = effect.payload;
        onCheckAndInit({ orgId, companyInfoMissing, reduxDispatch, dispatch });
      },
      updateCompanyInfo: onUpdateCompanyInfo,
      startQbdtSession: onStartQbdtSession,
    }
  );
  const dispatchers = useDispatchers(dispatch, {
    userConfirmed,
    authSuccess,
    authError,
  });
  return [authState, dispatchers] as const;
}
