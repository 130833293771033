import React from 'react';
import styled from 'styled-components';
import MIButton from 'src/components/common/MIButton';
import { CONSTS } from 'src/utils/consts';

type Props = {
  onDone: () => any;
  onCancel: () => any;
  doneLabel: string;
  cancelLabel: string;
  className?: string;
  titleLabel?: string;
  isDisabled?: boolean;
  isProcessing?: boolean;
};

const QBDTFloatedButtons = ({ className, onDone, doneLabel, isDisabled, isProcessing }: Props) => (
  <Container className={className}>
    <SaveButtonWrapper>
      <MIButton
        size={CONSTS.BUTTON_SIZE.SMALL}
        variant={CONSTS.BUTTON_VARIANT.PRIMARY}
        onClick={onDone}
        label={doneLabel}
        disabled={isDisabled}
        isProcessing={isProcessing}
      />
    </SaveButtonWrapper>
  </Container>
);

const SaveButtonWrapper = styled.div``;
const Container = styled.div`
  height: 6rem;
  width: 100%;
  background-color: white;
  box-shadow: 0 -0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1011;
  box-sizing: border-box;
  display: flex;
  padding: 0 2.3rem;
`;

export default QBDTFloatedButtons;
