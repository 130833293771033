const getName = ({ method, url, code }) => [method, url && url.replace(/\d+/g, '_'), code].join(':');

export class ServerError extends Error {
  name: string;
  code: number | string | null;
  error?: string | null;
  url: string | null;
  method: string | null;
  requestData: any;
  responseData: any;

  constructor({ error, message, code, url, data, params, method }) {
    super(error ?? message);
    this.name = this.constructor.name || getName({ method, url, code });
    this.message = error ?? message;
    this.code = code;
    this.url = url;
    this.method = method;
    this.error = error;
    this.requestData = params;
    this.responseData = data;
  }
}
