import React from 'react';
import { RecordOf } from 'immutable';
import { MultiLineSelectBox } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/utils/formatting';
import { DeliveryMethodType } from 'src/utils/types';
import { getDeliveryMethodInfo } from 'src/pages/vendor/records';

type Props = {
  onClick: () => void;
  vendorName: string;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

export const ACHDeliveryMethodOption = ({ onClick, deliveryMethod, vendorName }: Props) => (
  <MultiLineSelectBox onClick={onClick} data-testid="ach-delivery-method-option">
    <MultiLineSelectBox.TopContentWrapper>
      <MultiLineSelectBox.Icon name="icon-bank-icon" />
      <MultiLineSelectBox.HeadingWrapper>
        <MultiLineSelectBox.Title>
          <MIFormattedText label="bills.pay.virtualCardRecovery.achOption.title" />
        </MultiLineSelectBox.Title>
        <MultiLineSelectBox.Description>
          <MIFormattedText label="bills.pay.virtualCardRecovery.achOption.subtitle" />
        </MultiLineSelectBox.Description>
      </MultiLineSelectBox.HeadingWrapper>
    </MultiLineSelectBox.TopContentWrapper>

    <MultiLineSelectBox.Separator />

    <MultiLineSelectBox.Info data-testid="ach-delivery-method-option-info">
      {deliveryMethod.id ? (
        getDeliveryMethodInfo({
          vendorName,
          deliveryMethod,
        })
      ) : (
        <MIFormattedText label="bills.pay.virtualCardRecovery.achOption.info" />
      )}
    </MultiLineSelectBox.Info>
  </MultiLineSelectBox>
);
