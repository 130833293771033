import { MIFormattedText } from 'src/utils/formatting';
import { Text } from '@melio/billpay-design-system';
import React from 'react';

type Props = {
  label: string;
  values?: Record<string, any>;
};

export const ContentTitle = ({ label, values }: Props) => (
  <Text m="0" textStyle="ds.h6" color="ds.gray.100" align="center">
    <MIFormattedText label={label} values={values} />
  </Text>
);
