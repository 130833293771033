import React, { ReactNode } from 'react';
import { CONSTS } from 'src/utils/consts';
import MIEnhancedDropDown from 'src/components/common/DropDown/MIEnhancedDropDown';
import { OptionType } from 'src/components/common/DropDown/MIDropDown';
import { MITextInput } from 'src/components/common/MITextInput';
import { Expandable } from 'src/utils/types';
import { ModelViewField } from './use-form';

export type WizardSelectFieldProps = {
  model?: ModelViewField<string>;
  value?: string;
  label: string;
  labelValues?: Record<string, any>;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (change: Expandable<{ value: string }>) => any;
  options: { label: string; value: string }[];
  errorMessage?: string;
  viewOnly?: boolean;
  createLabel?: string;
  allowCustom?: boolean;
  placeholder?: string;
  renderOption?: (option: OptionType, searchText?: string | null) => ReactNode | null;
  privateData?: boolean;
};

export function WizardNewSelectField(props: WizardSelectFieldProps) {
  const {
    model,
    id,
    label,
    labelValues,
    options,
    value,
    viewOnly,
    disabled,
    onChange,
    required,
    privateData,
    ...rest
  } = props;
  const innerId = id || model?.id;
  const innerOnChange = onChange || model?.onChange;
  if (viewOnly) {
    return (
      <MITextInput
        label={label}
        labelValues={labelValues}
        value={model?.value || value}
        id={innerId}
        size={CONSTS.TEXT_INPUT_SIZE.WIZARD}
        required={required}
        viewOnly
        disabled={disabled}
        privateData={privateData}
      />
    );
  }

  return (
    <MIEnhancedDropDown
      {...rest}
      label={label}
      labelValues={labelValues}
      id={innerId}
      onChange={innerOnChange}
      size={CONSTS.TEXT_INPUT_SIZE.WIZARD}
      options={options}
      value={model?.value || value}
      required={required}
      errorMessage={model?.error}
      privateData={privateData}
    />
  );
}
