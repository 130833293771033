import React from 'react';
import { Flex, Link, Text } from '@melio/billpay-design-system';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';

export const UserSupport = () => {
  const { isDesktop } = useBreak();
  const { config } = useSiteContext();
  const supportEmail = config.support.email;
  const { privacyPolicy, termsOfService } = config.agreementLinks;
  return (
    <Flex direction="column" gap="ds.md" align="center" justify="center" mt="ds.xl" mb="ds.2xl">
      <Flex gap="ds.xl">
        <Text textStyle="ds.body3Semi" m="0" color="ds.gray.200">
          <MIFormattedText label="vendors.addDeliveryMethodByLink.support" />
        </Text>
        <Link href={`mailto:${supportEmail}`} textStyle="ds.body3" color="ds.gray.200" textDecoration="underline">
          {supportEmail}
        </Link>
      </Flex>
      {isDesktop && (
        <Flex w="100%" justify="center" gap="ds.md">
          <Link
            href={privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
            color="ds.gray.200"
            textStyle="ds.body3"
            textDecoration="none"
          >
            <MIFormattedText label="vendor.pushToDebit.addDebitCard.support.privacyPolicy" />
          </Link>
          <Text textStyle="ds.body3" m="0" color="ds.gray.200">
            |
          </Text>
          <Link
            href={termsOfService}
            target="_blank"
            rel="noopener noreferrer"
            color="ds.gray.200"
            textStyle="ds.body3"
            textDecoration="none"
          >
            <MIFormattedText label="vendor.pushToDebit.addDebitCard.support.termsOfService" />
          </Link>
        </Flex>
      )}
    </Flex>
  );
};
