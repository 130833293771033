import qboSite from 'src/sites/qboSite';
import merge from 'lodash/merge';
import { CONSTS } from 'src/utils/consts';
import enTranslations from 'src/locale/en-US.qbdt';
import { qbdt } from '../config';

export const qbdtWindowsSite = merge({}, qboSite, {
  name: 'qbdt',
  config: qbdt,
  createOrigin: {
    pay: {
      payment: CONSTS.PAYMENT_CREATE_FLOW_ORIGIN.QBDT_WINDOWS,
      payor: CONSTS.PAYMENT_CREATE_FLOW_ORIGIN.QBDT_WINDOWS,
    },
  },
  messages: {
    'en-US': enTranslations['en-US'],
  },
  registrationOrigin: CONSTS.REGISTRATION_ORIGIN.QBDT_WINDOWS,
  components: {},
});
