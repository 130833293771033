import React, { useEffect, useMemo, useState } from 'react';
import { Text, Flex } from '@melio/billpay-design-system';
import set from 'lodash/fp/set';
import isEmpty from 'lodash/isEmpty';
import { VendorFlowLayout, VendorFlowContentCard, Footer } from 'src/pages/vendor/components/VendorFlowLayout';
import {
  UnilateralDeliveryMethodType,
  UnilateralPaymentActions,
  UnilateralPaymentState,
} from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { ModelView, useForm } from 'src/ui/form';
import { Divider } from 'src/pages/vendor/virtual-delivery-method/components/Divider';
import NewDeliveryMethodForm from 'src/pages/vendor/components/NewDeliveryMethodForm';
import { CONSTS, DELIVERY_TYPE } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';
import { MIFormattedText } from 'src/utils/formatting';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { CheckType } from 'src/utils/types';
import { convertPaperCheck } from 'src/utils/address';
import QBOWhitePageAddressContainer from 'src/pages/vendor/virtual-delivery-method/components/QBOWhitePageAddressContainer';
import { ContentTitle } from 'src/pages/vendor/virtual-delivery-method/components/ContentTitle';
import { vendorUnilateralAnalytics } from 'src/pages/vendor/virtual-delivery-method/utils/analytics';
import { PaymentInfo } from '../components/PaymentInfo';

type Props = {
  state: UnilateralPaymentState;
  actions: UnilateralPaymentActions;
  deliveryMethod: UnilateralDeliveryMethodType;
};

export const AddPaperCheckDeliveryMethodPage = ({ state, actions, deliveryMethod }: Props) => {
  const [deliveryMethodMV, deliveryMethodMVActions] = useForm(deliveryMethod, {
    submit: (value) => {
      const { deliveryType, ...rest } = value;
      if (deliveryType) {
        return onSubmit({ deliveryType, ...rest }, false);
      }

      return Promise.reject(new Error('Delivery type not selected'));
    },
    onChange: ({ key, value, modelState }) => {
      let newState = modelState;
      if (key === 'paperCheck.printName' && value) {
        const isInvalid = CONSTS.REGEX.CHECK_PRINT_NAME.test(value);
        const formattedValue = value.replace(CONSTS.REGEX.CHECK_PRINT_NAME, '');
        if (isInvalid) {
          const { setError } = deliveryMethodMV.paperCheck.printName;
          setError && setError('inputErrors.deliveryMethodCheck.printName.any.invalidChar');
        }

        newState = set('paperCheck.printName', formattedValue, modelState);
      }

      return newState;
    },
  });

  const [whitePageAddressView, setWhitePageAddressView] = useState(false);

  const { isDeliveryMethodProcessing, organization, payment } = state;

  const { onChange, onSubmit } = actions;

  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );

  const paperCheck = useMemo<CheckType>(() => convertPaperCheck(deliveryMethodMV.paperCheck as ModelView<CheckType>), [
    deliveryMethodMV.paperCheck,
  ]);

  useEffect(() => {
    setWhitePageAddressView(
      whitePageAddress &&
        !isEmpty(whitePageAddress) &&
        ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid)
    );
    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit({ deliveryType: CONSTS.DELIVERY_TYPE.CHECK, paperCheck }, true);
    }
  }, [whitePageAddress]);

  useEffect(() => {
    onChange({ id: 'deliveryType', value: 'check' });
  }, []);

  if (whitePageAddressView) {
    return (
      <QBOWhitePageAddressContainer
        setWhitePageAddressView={setWhitePageAddressView}
        isLoading={isDeliveryMethodProcessing || isAddressLoading}
        whitePageAddress={whitePageAddress}
        submit={onSubmit}
        model={deliveryMethodMV.paperCheck}
      />
    );
  }

  const trackGoBackAnalytics = () => {
    vendorUnilateralAnalytics.trackGoBack({
      paymentId: payment?.id,
      vendorId: payment?.vendorId,
      vendorEmail: payment?.vendor?.contactEmail,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
    });
  };

  return (
    <VendorFlowLayout>
      <VendorFlowContentCard showBack trackAnalyticsOnGoBack={trackGoBackAnalytics}>
        <PaymentInfo companyName={organization?.companyName} payment={payment} />
        <Divider />
        <Flex direction="column">
          <ContentTitle label="vendors.addDeliveryMethodByLink.checkTitle" />
          <NewDeliveryMethodForm
            submit={deliveryMethodMVActions.submit}
            selectedDeliveryMethod={DELIVERY_TYPE.CHECK}
            achModel={deliveryMethodMV.bankAccount}
            checkModel={deliveryMethodMV.paperCheck}
          />
          <MIButton
            label="vendors.addDeliveryMethodByLink.submitCTA"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={deliveryMethodMVActions.submit}
            isProcessing={isDeliveryMethodProcessing}
            fullWidth
          />
          <Text textStyle="ds.body3" color="ds.gray.100" align="center">
            <MIFormattedText
              label="vendor.unilateral.deliveryMethods.paperCheck.actionHint"
              values={{
                payorName: organization?.companyName || '',
              }}
            />
          </Text>
        </Flex>
      </VendorFlowContentCard>
      <Footer />
    </VendorFlowLayout>
  );
};
