import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import profileStore from 'src/modules/profile/profile-store';
import { CONSTS } from 'src/utils/consts';
import {
  canVerifyManualBankAccount,
  getModifyFundingSourceOptions,
  isBankAccountBlocked,
  isManualBankAccountNotVerified,
} from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';
import MIInlineLink from 'src/components/common/MIInlineLink';
import { zendeskService } from 'src/services/zendesk';
import MILink from 'src/components/common/MILink';
import analytics from 'src/services/analytics';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick?: (event: any) => void;
  method: RecordOf<AccountType>;
  isSelected: boolean;
  optionComponent: any;
  onVerifyClicked?: (id: number) => void;
  disabled: boolean;
  modifyActions: Record<string, any>;
  isSettings?: boolean;
  isBillingFee?: boolean;
};

function getImages(fundingSource) {
  const hasLogo = fundingSource.logo !== '' && fundingSource.logo !== CONSTS.FUNDING_LOGO.DEFAULT;
  const bankLogo = hasLogo ? `data:image/jpeg;base64,${fundingSource.logo}` : '';

  return {
    icon: bankLogo ? '' : 'icon-bank-icon',
    imageSrc: bankLogo,
  };
}

function getEmptyMethodDescription({ isSettings, isBillingFee }: { isBillingFee: boolean; isSettings: boolean }) {
  if (isBillingFee) {
    return 'billingFee.addMethod.options.emptyMethod.bankAccount';
  }

  return isSettings
    ? 'onboarding.fundingSources.options.bank.settingsDescription'
    : 'onboarding.fundingSources.options.bank.description';
}

function getDescription({
  fundingSource,
  isBillingFee,
}: {
  fundingSource: RecordOf<AccountType>;
  isBillingFee?: boolean;
}) {
  if (isManualBankAccountNotVerified(fundingSource)) {
    if (isBankAccountBlocked(fundingSource)) {
      return 'settings.paymentMethods.microDepositsBlocked';
    }

    return 'settings.paymentMethods.notVerifiedAccountHintWithAction';
  }

  return isBillingFee ? 'billingFee.addMethod.options.bankAccount' : 'bills.pay.fundingSource.achDescription';
}

const openSupportChat = () => {
  analytics.track('qbo', 'support-open-start-chat');
  zendeskService.show();
};

export function getDescriptionValues(fundingSource, onVerifyClicked) {
  if (onVerifyClicked && canVerifyManualBankAccount(fundingSource)) {
    return {
      link: (
        <VerifyAccountLink
          testId={`verify-funding-source-${fundingSource.id}`}
          label="settings.paymentMethods.verifyAccountHint"
          onClick={(event) => {
            event.preventDefault();
            onVerifyClicked && onVerifyClicked(fundingSource.id);
          }}
        />
      ),
    };
  }

  return {
    link: '',
    chatWithUs: <ChatLink to={openSupportChat} label="settings.paymentMethods.microDepositsBlockedContactLabel" />,
  };
}

function AchPaymentMethod({
  onClick,
  method,
  isSelected,
  onVerifyClicked,
  optionComponent: OptionComponent,
  disabled,
  modifyActions,
  isSettings,
  isBillingFee = false,
}: Props) {
  const permissions = useSelector(profileStore.selectors.getPermissions);

  const emptyMethodDescription = getEmptyMethodDescription({
    isBillingFee,
    isSettings: !!isSettings,
  });

  if (!method) {
    return (
      <OptionComponent
        id="ach"
        key="ach-empty"
        label="onboarding.fundingSources.options.bank.label"
        description={emptyMethodDescription}
        icon="icon-bank-icon"
        onClick={onClick}
        isEmptyList
      />
    );
  }

  const { icon, imageSrc } = getImages(method);
  const description = getDescription({
    fundingSource: method,
    isBillingFee,
  });
  const descriptionValues = getDescriptionValues(method, onVerifyClicked);
  const actionOptions = getModifyFundingSourceOptions(permissions, modifyActions, disabled, method.origin, false);

  return (
    <OptionComponent
      id={method.id}
      disabled={disabled}
      onTopDescription={method.nickname}
      label="bills.pay.fundingSource.achLabel"
      labelValues={{
        ...method.getNameParts(),
      }}
      description={description}
      descriptionValues={descriptionValues}
      isSelected={isSelected}
      icon={icon}
      imageSrc={imageSrc}
      onClick={onClick}
      actionOptions={actionOptions}
    />
  );
}

const VerifyAccountLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;

const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.colors.primary.opaque};
  text-decoration: none;
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.normal};
  border: none;
  font-size: ${(props) => props.theme.text.size.linkHint};
`;

function AchPaymentSelectedMethod({ method }: { method: RecordOf<AccountType> }) {
  const { icon, imageSrc } = getImages(method);

  return (
    <SelectedMethod
      icon={icon}
      imageSrc={imageSrc}
      title="bills.form.paymentActivity.scheduledBill.scheduleMethodAch"
      label="bills.pay.fundingSource.achLabel"
      labelValues={{
        ...method.getNameParts(),
      }}
    />
  );
}

AchPaymentMethod.methodType = CONSTS.FUNDING_TYPE.ACH;
AchPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherBank';
AchPaymentMethod.SelectedMethod = AchPaymentSelectedMethod;
AchPaymentMethod.isDisabled = isBankAccountBlocked;
AchPaymentMethod.showAddAnotherOption = true;

export default AchPaymentMethod;
