import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { ViewPaymentPage } from 'src/billpay/qbdt/pages/view/ViewPaymentPage';

export function ViewPaymentRouter() {
  const match = useRouteMatch<{ billId: string; paymentId: string }>();
  const { billId, paymentId } = match.params;

  return (
    <Switch>
      <SmartRoute path={match.path} exact>
        <ViewPaymentPage billId={billId} paymentId={paymentId} />
      </SmartRoute>
    </Switch>
  );
}
