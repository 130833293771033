import React from 'react';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { BlockedUserPageContainer } from 'src/pages/quickbooks/BlockedUserPageContainer';
import locations from 'src/utils/locations';
import analytics from 'src/services/analytics';
import BillNotFound from './BillNotFound';

export const qboPublicRoutes = [
  {
    path: locations.QuickBooks.blocked.url(),
    exact: true,
    component: withSiteContext()((cProps: any) => {
      analytics.page('qbo-blocked', 'blocked-user');
      return <BlockedUserPageContainer {...cProps.match.params} />;
    }),
  },
];

export const qboPrivateRoutes = [
  {
    path: locations.QuickBooks.billNotFound.url(),
    exact: true,
    component: (cProps: any) => {
      analytics.page('bill-error', 'not-paid-by-melio');
      return <BillNotFound {...cProps.match.params} />;
    },
  },
];

export const qboBillRoutes = [];
