import React, { useState } from 'react';
import { Box, Flex, Link } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import analytics from 'src/services/analytics';
import { DeliveryMethodType } from 'src/utils/types';
import { format } from 'date-fns';
import { DATE_FORMATS } from 'src/utils/date-fns';
import { LearnMoreModal } from './LearnMoreModal';

type Props = {
  deliveryMethod: DeliveryMethodType;
};

export const VirtualCardDeliveryMethodItem = ({ deliveryMethod }: Props) => {
  const [learnMoreModalVisible, setLearnMoreModalVisible] = useState(false);
  const goLearnMore = () => {
    analytics.trackAction('VirtualCardLearnMore-Clicked');
    setLearnMoreModalVisible(true);
  };

  const createdDate = format(
    new Date(deliveryMethod.createdAt || new Date()),
    DATE_FORMATS.monthShortWithLongDateAndYear
  );
  const handleCloseModal = () => {
    setLearnMoreModalVisible(false);
  };
  return (
    <>
      {learnMoreModalVisible && <LearnMoreModal onClose={handleCloseModal} />}
      <Flex
        border="1px"
        borderColor="ds.gray.400"
        borderRadius="ds.lg"
        boxSizing="border-box"
        width="full"
        px="ds.xl"
        py="ds.lg"
        direction="column"
        data-testid="virtual-card-delivery-method-container"
      >
        <Flex align="center">
          <Box ml="ds.sm">
            <Mastercard width="2.4rem" height="2.4rem" />
          </Box>
          <Flex ml="ds.lg" direction="column">
            <Box textStyle="ds.body1Semi" color="ds.gray.100">
              <MIFormattedText label="vendors.deliveryMethods.virtualCard.title" />
            </Box>
            <Box textStyle="ds.body3" color="ds.gray.300">
              <MIFormattedText label="vendors.deliveryMethods.virtualCard.description" />
            </Box>
          </Flex>
        </Flex>
        <Box width="100%" height="1px" backgroundColor="ds.gray.500" my="ds.lg" />
        <Box textStyle="ds.body3" color="ds.gray.100" data-testid="virtual-card-details-text">
          <MIFormattedText
            label="vendors.deliveryMethods.virtualCard.cardDetails"
            values={{
              learnMoreLink: (...chunks) => (
                <Box as={Link} onClick={goLearnMore} cursor="pointer">
                  {React.Children.toArray(chunks)}
                </Box>
              ),
              createdDate,
              email: deliveryMethod.virtualCardAccount?.accountEmail || '',
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
