import React, { useEffect, useState } from 'react';
import analytics from 'src/services/analytics';
import companyInfoApi from 'src/services/api/organizations';
import { isValidationOk } from '@melio/sizzers-js-common';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc/index';
import { CompanyInfoRecord } from 'src/records/settings.record';
import { UpdateIndustryRequestData, industryApi } from 'src/services/api/industry';
import { calculateComplianceAnalyticsFields, isAfterGracePeriod } from 'src/utils/compliance-utils';
import { ComplianceValidationErrors, validateBusinessType, validateIndustryType } from 'src/utils/compliance-validator';
import { BusinessType } from 'src/utils/types';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { getComplianceLimitations } from 'src/redux/payBillWizard/selectors';
import { OnSelectNaicsCode } from 'src/billpay/qbdt/components/CompanyIndustryAutocomplete';
import { PayBillProps, withPayBillData } from '../hoc/withPayBillData';
import { QBDTPayBillCompleteComplianceInfoPage } from './components/QBDTPayBillCompleteComplianceInfoPage';
import { useOnPaymentSubmit } from '../hooks/useOnPaymentSubmit';

const eventPage = 'pay-bill';

const QBDTPayCompleteComplianceInfoPageContainer = ({ goExit, onNext }: PayBillProps) => {
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const { onSubmit } = useOnPaymentSubmit({ onNext });
  const limitations = useSelector(getComplianceLimitations);
  const isAfterComplianceGracePeriod = isAfterGracePeriod(limitations);

  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedBusinessType, setSelectedBusinessType] = useState<BusinessType | null>(null);
  const [{ naicsCode, industryText }, setNaicsData] = useState<UpdateIndustryRequestData>({
    naicsCode: null,
    industryText: '',
  });

  useEffect(() => {
    analytics.track('Organization', 'View', {
      PageName: 'action-required-to-reactivate-bill-pay',
      Flow: 'payment',
      Intent: 'pay',
      ...calculateComplianceAnalyticsFields(limitations),
    });
  });

  const onResetNaicsCode = () => {
    setNaicsData({ naicsCode: null, industryText: undefined });
  };

  const onSelectNaicsCode: OnSelectNaicsCode = (naicsData) => {
    setNaicsData(naicsData);
  };

  const onSelectBusinessType = (value: BusinessType) => {
    setSelectedBusinessType(value);
  };

  const setCompanyInfo = (companyInfo) => {
    dispatch(setCompanyInfoAction(companyInfo));
  };

  const handleOnSubmit = async () => {
    analytics.track('Organization', 'Click', {
      PageName: 'action-required-to-reactivate-bill-pay',
      Flow: 'payment',
      Intent: 'submit-details',
      Cta: isAfterComplianceGracePeriod ? 'save-and-continue' : 'save-and-schedule-my-payment',
      ...calculateComplianceAnalyticsFields(limitations),
    });
    const dataToUpdate = companyInfo.merge({
      businessType: selectedBusinessType,
    });
    const eventName = 'complete-compliance-info';
    const validationErrors: ComplianceValidationErrors = {
      ...validateBusinessType(selectedBusinessType),
      ...validateIndustryType(naicsCode),
    };

    setValidationErrors(validationErrors);
    setIsPaymentProcessing(true);
    if (isValidationOk(validationErrors)) {
      analytics.track(eventPage, `${eventName}-continue-success`, undefined, {
        integrations: { Salesforce: true },
      });
      try {
        const [{ companyInfo: updatedCompanyInfo }] = await Promise.all([
          companyInfoApi.updateCompanyInfo(orgId, dataToUpdate),
          industryApi.updateIndustry(orgId, { naicsCode, industryText }),
        ]);
        const companyInfoRecord = CompanyInfoRecord(updatedCompanyInfo);
        setCompanyInfo(companyInfoRecord);
        await onSubmit();
      } catch (error: any) {
        setIsPaymentProcessing(false);
      }
    } else {
      analytics.track(eventPage, `${eventPage}-validation-error`, validationErrors);
      setIsPaymentProcessing(false);
    }
  };

  const handleOnSkip = async () => {
    analytics.track('Organization', 'Click', {
      PageName: 'action-required-to-reactivate-bill-pay',
      Flow: 'payment',
      Intent: 'skip-details-submission',
      Cta: 'skip-and-schedule-my-payment',
      ...calculateComplianceAnalyticsFields(limitations),
    });

    await onSubmit();
  };

  return (
    <QBDTPayBillCompleteComplianceInfoPage
      isLoading={isPaymentProcessing}
      businessType={selectedBusinessType}
      validationErrors={validationErrors}
      isAfterComplianceGracePeriod={isAfterComplianceGracePeriod}
      goExit={goExit}
      handleOnSkip={handleOnSkip}
      handleOnSubmit={handleOnSubmit}
      onSelectBusinessType={onSelectBusinessType}
      onSelectNaicsCode={onSelectNaicsCode}
      onResetNaicsCode={onResetNaicsCode}
    />
  );
};

export default compose(withNavigator(), withPayBillData())(QBDTPayCompleteComplianceInfoPageContainer);
