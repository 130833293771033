import { REGISTRATION_ORIGIN } from 'src/utils/consts';
import analytics from '../analytics';
import { postRequest, fetchRequest } from './api';

type BasePayloadType = {
  email: string;
  password: string;
};

export type RegisterPayloadType = {
  emailVerificationPath: string;
  registrationOrigin: REGISTRATION_ORIGIN.LANDING | REGISTRATION_ORIGIN.LANDING_NO_VERIFICATION;
  registrationFlow: string;
  referringVendor?: string;
} & BasePayloadType;

const API = {
  CHECK: '/auth/check',
  LOGOUT: '/auth/logout',
  RESEND_EMAIL_VERIFICATION: '/auth/resend-email-verification',
  RESEND_EMAIL_VERIFICATION_CODE: '/auth/resend-email-verification-code',
  RESOLVE_EMAIL_VERIFICATION_CODE: '/auth/resolve-email-verification-code',
  VERIFY_EMAIL: '/auth/email-verification',
};

export default {
  check() {
    const url = API.CHECK;
    return fetchRequest(url, null, { isBypassThrowError: true });
  },

  resendEmailVerificationCode(email) {
    const url = API.RESEND_EMAIL_VERIFICATION_CODE;
    return postRequest(url, { email });
  },

  resolveEmailVerificationCode(email, code) {
    const url = API.RESOLVE_EMAIL_VERIFICATION_CODE;
    return postRequest(url, { email, code });
  },

  verifyEmail({ token, email }) {
    const url = API.VERIFY_EMAIL;
    return postRequest(url, { token, email });
  },

  async logout() {
    const url = API.LOGOUT;
    const res = await postRequest(url);

    analytics.reset(true);

    return res;
  },
};
