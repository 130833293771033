import React, { useMemo, useCallback, useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { getValidationErrors } from '@melio/sizzers-js-common';
import { Link, VStack } from '@melio/billpay-design-system';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useApi } from 'src/hoc/useApi';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';
import { MIFormattedText } from 'src/utils/formatting';
import ValidationError from 'src/ui/ValidationError';
import { CONSTS } from 'src/utils/consts';
import { BankType, DeliveryMethodType } from 'src/utils/types';
import { withPreservedStateNavigator } from 'src/hoc';
import analytics from 'src/services/analytics';
import api from 'src/services/api/financialAccounts';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { getOrgId } from 'src/redux/user/selectors';
import vendorsApi from 'src/services/api/vendors';
import { pushNotification } from 'src/services/notifications/notificationService';
import useRefreshFundingSources from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { useForm } from 'src/ui/form';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { WizardPasswordInputField } from 'src/ui/form/WizardPasswordInputField';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import { generatePath, useHistory } from 'react-router-dom';
import locations from 'src/billpay/qbdt/pages/locations';
import { catchAsync } from 'src/utils/async';
import { QBDTStepLayout, SimpleTextFooterSlim } from 'src/billpay/qbdt/components';

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  locationState: Record<string, any>;
  selectFundingSourceForBatchFlow: (any) => void;
};

const SetManuallyBankAccountPageContainer = ({
  navigateToExitWithPreservedState,
  navigate,
  locationState,
  selectFundingSourceForBatchFlow,
}: Props) => {
  const intl = useIntl();
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const history = useHistory();
  const billIds = locationState?.preservedState?.billIds || [];

  const [requestMicroDeposit, , isLoading] = useApi<[string, { bank: any }], any>(api.requestMicroDeposit);

  const [getVendorsDeliveryMethods, , isValidating] = useApi<[string], any>(vendorsApi.getVendorsDeliveryMethods);

  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  const model = useMemo(
    () => ({
      routingNumber: '',
      accountNumber: '',
    }),
    []
  );

  useEffect(() => {
    if (locationState?.cantFindPlaidAccount) {
      analytics.page('add-funding-source', 'bank-cant-find', {
        billIds,
      });

      return;
    }

    analytics.page('add-funding-source', 'bank-manually', {
      billIds,
    });
  }, []);

  const goExit = () => {
    navigateToExitWithPreservedState(locationState);
  };

  const submitManualBankAccount = async (value) => {
    const bank = { ...value, accountType: CONSTS.BANK_ACCOUNT_TYPE.CHECKING };
    const validationErrors = await validateBankAccount(bank);

    if (isEmpty(validationErrors)) {
      analytics.trackAction('funding-source-added-manually', {
        billIds,
      });
      const [err, data] = await catchAsync(requestMicroDeposit(orgId, { bank }));

      if (err) {
        pushNotification({
          type: CONSTS.NOTIFICATION_VARIANT.ERROR,
          msg: `server.${err.code}`,
        });

        return;
      }

      if (data?.fundingSource) {
        await onMicroDepositCreated(data.fundingSource);
      }
    } else {
      throw new ValidationError({ validationErrors });
    }
  };

  const onMicroDepositCreated = useCallback(
    async (fundingSource) => {
      const origin = locationState?.preservedState?.origin || '';
      await refreshFundingSources();
      analytics.setTraits({ [CONSTS.DB_ANALYTICS_TRAITS.ADDED_FUNDING]: true });
      analytics.setFundingSourceTraits();
      analytics.trackAction('funding-source-added-manually-success', {
        billIds,
      });
      analytics.trackMqlEvent('added-funding', 'mql');
      analytics.trackMqlEvent('added-funding-manual', 'mql');

      if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS) {
        await selectFundingSourceForBatchFlow({
          newFundingSourceId: fundingSource.id,
        });
      }

      if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL) {
        dispatch(selectFundingSourceAction(fundingSource.id));
      }

      navigate(generatePath(locations.fundingSource.linkAccount, { orgId }), false, {
        ...locationState,
        fundingSourceId: fundingSource.id,
        origin,
      });
    },
    [refreshFundingSources, dispatch, locationState, navigate, billIds]
  );

  const validateBankAccount = async (bank: BankType) => {
    const { routingNumber, accountNumber } = bank;
    const fieldsToValidation = ['accountType', 'routingNumber', 'accountNumber', 'accountNumberConfirmation'];
    const syncValidationErrors = getValidationErrors('fundingSourceBank', bank, fieldsToValidation);
    if (!isEmpty(syncValidationErrors)) {
      return syncValidationErrors;
    }

    const { vendorsDeliveryMethods } = await getVendorsDeliveryMethods(orgId);
    const deliveryMethod = vendorsDeliveryMethods.find((deliveryMethodType: DeliveryMethodType) => {
      const { bankAccount } = deliveryMethodType;
      return bankAccount?.routingNumber === routingNumber && bankAccount?.accountNumber === accountNumber;
    });

    if (deliveryMethod?.vendor) {
      // TODO: add the possiblity to pass values in error messages for useForm
      return {
        accountNumber: intl.formatMessage(
          { id: 'inputErrors.fundingSourceBank.alreadyDefined' },
          {
            vendorName: checkApostropheForPluralPossessive(deliveryMethod.vendor.companyName),
          }
        ),
      };
    }

    return {};
  };

  const [bankAccountVM, { submit }] = useForm(model, {
    submit: submitManualBankAccount,
  });

  const goToFundingSourcePage = () => {
    const origin = locationState?.preservedState?.origin || '';

    analytics.trackAction('PlaidFailedUseDifferentPaymentMethod-Selected', { billIds });

    if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS_FIRST_FS) {
      history.push(generatePath(locationState?.exitUrl, { orgId }), {
        numberOfBills: locationState?.preservedState?.numberOfBills,
        totalAmount: locationState?.preservedState?.totalAmount,
        isBillsOverLimit: locationState?.preservedState?.isBillsOverLimit,
        billsOverLimitAmount: locationState?.preservedState?.billsOverLimitAmount,
        redirectUrl: locationState?.redirectUrl,
      });
      return;
    }

    history.push(generatePath(locationState?.exitUrl, { orgId }), {
      redirectUrl: locationState?.redirectUrl,
    });
  };

  return (
    <QBDTStepLayout
      title="onboarding.fundingSources.bank.manually.connectBankTitle"
      subtitle="onboarding.fundingSources.bank.manually.connectBankSubTitle"
      goExit={goExit}
      onSubmit={submit}
      nextLabel="onboarding.fundingSources.bank.manually.completeAndSave"
      isLoading={isLoading || isFundingSourcesRefreshing || isValidating}
      footer={
        <VStack spacing="ds.xl" w="full">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link textStyle="ds.body1Semi" onClick={goToFundingSourcePage}>
            <MIFormattedText label="onboarding.fundingSources.bank.manually.useDifferentPaymentMethod" />
          </Link>
          <SimpleTextFooterSlim>
            <MIFormattedText label="onboarding.fundingSources.bank.manually.saveFooter" />
          </SimpleTextFooterSlim>
        </VStack>
      }
      hideHeader
    >
      <WizardFormContainer>
        <WizardTextInputField
          model={bankAccountVM.routingNumber}
          label="onboarding.fundingSources.bank.manually.routingNumber"
          type="tel"
          autoFocus
          required
          privateData
        />

        <WizardPasswordInputField
          model={bankAccountVM.accountNumber}
          label="onboarding.fundingSources.bank.manually.accountNumber"
          required
          shouldShowValue
          pattern={CONSTS.INPUT_PATTERNS.NUMBERS}
          inputMode="numeric"
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </QBDTStepLayout>
  );
};

export default compose(withPreservedStateNavigator())(SetManuallyBankAccountPageContainer);

const HiddenInput = styled.input`
  display: none;
`;
