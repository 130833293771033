import React, { PropsWithChildren } from 'react';
import { CSSObject } from '@chakra-ui/react';
import { Box, HStack, VStack } from '@melio/billpay-design-system';
import { MIFormattedText, MIFormattedDate, MIFormattedCurrency } from 'src/utils/formatting';
import { BillInfo } from './types';
import { EMPTY_INVOICE_NUMBER } from '../consts';

const MAX_VISIBLE_ROW_NUMBER = 8;

type CellProps = {
  style: CSSObject;
};

const Cell = ({ style, children }: PropsWithChildren<CellProps>) => (
  <Box sx={{ ...regularCellStyle, ...style }}>{children}</Box>
);

type HeaderCellProps = { style: CSSObject; label: string };

const HeaderCell = ({ style, label }: HeaderCellProps) => (
  <Box sx={{ ...headerCellStyle, ...style }}>
    <MIFormattedText label={label} />
  </Box>
);

type HeaderProps = { total: number };

const Header = ({ total }: HeaderProps) => (
  <HStack h="ds.lg" spacing="ds.lg">
    <HeaderCell style={invoiceNumberCellStyle} label="bills.view.columns.invoiceNumber" />
    <HeaderCell
      style={total > MAX_VISIBLE_ROW_NUMBER ? dueDateCellSmallStyle : dueDateCellStyle}
      label="bills.view.columns.dueDate"
    />
    <HeaderCell style={amountCellStyle} label="bills.view.columns.amount" />
  </HStack>
);

type RowProps = { value: BillInfo; total: number };

const Row = ({ value, total }: RowProps) => (
  <HStack minH="3.2rem" spacing="ds.lg">
    <Cell style={invoiceNumberCellStyle}>{value.invoiceNumber || EMPTY_INVOICE_NUMBER}</Cell>
    <Cell style={total > MAX_VISIBLE_ROW_NUMBER ? dueDateCellSmallStyle : dueDateCellStyle}>
      <MIFormattedDate date={value.dueDate} />
    </Cell>
    <Cell style={amountCellStyle}>
      <MIFormattedCurrency value={value.totalAmount} />
    </Cell>
  </HStack>
);

const RowDivider = () => <Box w="full" h="0.1rem" minH="0.1rem" bgColor="ds.gray.700" />;

type BodyProps = { children: React.ReactNode };

const Body = ({ children }: BodyProps) => (
  <VStack divider={<RowDivider />} maxH="26.8rem" overflowY="auto" alignItems="flex-start">
    {children}
  </VStack>
);

type BillsInfoTableProps = { data: BillInfo[] };

export const BillsInfoTable = ({ data }: BillsInfoTableProps) => (
  <Box w="full">
    <Header total={data.length} />
    <Body>
      {data.map((value, index) => (
        <Row key={`${index}-${value.invoiceNumber}`} value={value} total={data.length} />
      ))}
    </Body>
  </Box>
);

const regularCellStyle: CSSObject = {
  textStyle: 'ds.body3',
  color: 'ds.gray.100',
};

const headerCellStyle = {
  textStyle: 'ds.body3',
  fontWeight: 'ds.medium',
  color: 'ds.gray.300',
};

const invoiceNumberCellStyle: CSSObject = {
  w: '8.4rem',
};

const dueDateCellStyle: CSSObject = {
  w: '9.8rem',
};

const dueDateCellSmallStyle: CSSObject = {
  w: '8.6rem',
};

const amountCellStyle: CSSObject = {
  w: '6.4rem',
  textAlign: 'right',
};
