import { logger } from 'src/services/loggers';
import {
  Adapter,
  getSelectedBillsResponse,
  reportPaymentSummaryResponse,
  CompanyFileGuidResponse,
  BaseResponse,
} from './types';

declare global {
  interface Window {
    browserInstance?: string;
    analytics: any;
    qbdt?: QBDTBridge;
  }
}

type QBDTBridge = {
  getSelectedBills(browserInstance: string): string;
  reportPaymentSummary(browserInstance: string, jsonString: string): string;
  executeSDKRequest(browserInstance: string, xmlString: string): string;
  initialize(browserInstance: string): string;
  getCompanyFileGuid(browserInstance: string): string;
  getApiVersion(): string;
  closeWindow(browserInstance): string;
};

export const BROWSER_INTSTANCE_CAN_NOT_BE_LOADED_ERROR = 'could not retrieve browserInstance';
export const BRIDGE_INTSTANCE_CAN_NOT_BE_LOADED_ERROR = 'could not retrieve bridge';

async function browserInstance() {
  // We're trying to get browserInstance for 40 seconds
  for (let i = 0; i < 4000; i += 1) {
    if (window.browserInstance) {
      if (i > 1000) {
        logger.warn(`browserInstance.wait(): wait time: ${i * 10}ms`);
      }

      return window.browserInstance;
    }

    // eslint-disable-next-line no-await-in-loop
    await new Promise((res) => setTimeout(res, 10));
  }

  throw new Error(BROWSER_INTSTANCE_CAN_NOT_BE_LOADED_ERROR);
}

async function bridgeInstance() {
  for (let i = 0; i < 3000; i += 1) {
    if (window.qbdt) {
      if (i > 0) {
        logger.warn(`bridgeInstance.wait(): wait time: ${i * 10}ms`);
      }

      return window.qbdt;
    }

    // eslint-disable-next-line no-await-in-loop
    await new Promise((res) => setTimeout(res, 10));
  }

  throw new Error(BRIDGE_INTSTANCE_CAN_NOT_BE_LOADED_ERROR);
}

export default {
  async getSelectedBills() {
    const bridge = await bridgeInstance();
    const response = bridge.getSelectedBills(await browserInstance());

    return JSON.parse(response) as getSelectedBillsResponse;
  },

  async reportPaymentSummary(summary) {
    const bridge = await bridgeInstance();
    const response = bridge.reportPaymentSummary(await browserInstance(), JSON.stringify(summary));

    return JSON.parse(response) as reportPaymentSummaryResponse;
  },

  async initialize() {
    const bridge = await bridgeInstance();
    const response = bridge.initialize(await browserInstance());

    return JSON.parse(response) as BaseResponse;
  },

  async getCompanyFileGuid() {
    const bridge = await bridgeInstance();
    const response = bridge.getCompanyFileGuid(await browserInstance());
    return JSON.parse(response) as CompanyFileGuidResponse;
  },

  async executeSDKRequest(data): Promise<string> {
    const bridge = await bridgeInstance();
    const result = bridge.executeSDKRequest(await browserInstance(), data);

    return result;
  },

  async getApiVersion() {
    const bridge = await bridgeInstance();
    return bridge.getApiVersion();
  },
  async setCanClose(value) {
    const result = JSON.stringify(value);
    (window as any).canCloseHostWindow = function () {
      return result;
    };
  },
  async macWindowClose() {
    const bridge = await bridgeInstance();
    bridge.closeWindow(await browserInstance());
  },
} as Adapter;
