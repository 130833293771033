import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { RecordOf } from 'immutable';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import analytics from 'src/services/analytics';
import { generatePath, useHistory } from 'react-router-dom';
import userApi from 'src/services/api/user';
import companyInfoApi from 'src/services/api/organizations';
import { CompanyInfoRecord } from 'src/records/settings.record';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import locations from 'src/billpay/qbdt/pages/locations';
import { AccountLimitations, CompanyInfoType, FieldType, GoogleCombinedAddressType } from 'src/utils/types';
import {
  convertLegalAddressToGoogleAddress,
  convertToServerLegalAddress,
  convertToServerAddress,
} from 'src/utils/address';
import { getCompanyInfo, getOrgId, getProfile } from 'src/redux/user/selectors';
import { CONSTS } from 'src/utils/consts';
import { catchAsync } from 'src/utils/async';
import { calculateComplianceAnalyticsFields } from 'src/utils/compliance-utils';
import { DATE_FORMATS } from 'src/utils/date-fns';
import {
  validateBusinessType,
  validateCompanyBusinessAddress,
  validateContactFirstName,
  validateContactLastName,
  validateContactPhone,
  validateDateOfBirth,
  validateIndustryType,
  validateLegalCompanyName,
  validateTaxId,
  validateTaxIdType,
} from 'src/utils/compliance-validator';
import { UpdateIndustryRequestData, industryApi } from 'src/services/api/industry';
import { getComplianceLimitations } from 'src/redux/payBillWizard/selectors';
import QBOPayBillCompleteLegalInfoPage from './components/PayBillCompleteLegalInfoPage';
import { useOnBatchExit } from './hooks/useOnBatchExit';
import { useScheduleBatchPayments } from './hooks/useScheduleBatchPayments';
import { OnSelectNaicsCode } from '../../components/CompanyIndustryAutocomplete';

export const PayBatchCompleteLegalInfoPageContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const companyInfo = useSelector(getCompanyInfo);
  const profile = useSelector(getProfile);
  const orgId = useSelector(getOrgId);
  const limitations: AccountLimitations = useSelector(getComplianceLimitations);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    legalAddress: convertLegalAddressToGoogleAddress(companyInfo),
    legalCompanyName: companyInfo.legalCompanyName,
    taxId: companyInfo.taxId,
    taxIdType: companyInfo.taxIdType,
    contactFirstName: companyInfo.contactFirstName,
    contactLastName: companyInfo.contactLastName,
    phone: companyInfo.phone,
    companyAddress: convertLegalAddressToGoogleAddress(companyInfo),
    openLegalAddressInput: false,
    businessType: companyInfo.businessType,
  });
  const [{ naicsCode, industryText }, setNaicsData] = useState<UpdateIndustryRequestData>({
    naicsCode: null,
    industryText: '',
  });
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(
    profile?.dateOfBirth
      ? format(new Date(profile.dateOfBirth), DATE_FORMATS.monthShortWithLongDateAndYearDashed)
      : undefined
  );

  useEffect(() => {
    analytics.track('Organization', 'View', {
      PageName: 'one-last-thing',
      Flow: 'batch-payment',
      Intent: 'pay',
      ...calculateComplianceAnalyticsFields(limitations),
    });
  });

  const onResetNaicsCode = () => {
    setNaicsData({ naicsCode: null, industryText: undefined });
  };

  const onSelectNaicsCode: OnSelectNaicsCode = (naicsData) => {
    setNaicsData(naicsData);
  };

  const setCompanyInfo = (companyInfo: RecordOf<CompanyInfoType>) => {
    dispatch(setCompanyInfoAction(companyInfo));
  };

  const { onBatchExit } = useOnBatchExit();

  const { scheduleBatchPayments } = useScheduleBatchPayments();

  const onLegalInfoChange = ({ id, value }: FieldType) => {
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onAddressChange = (address: GoogleCombinedAddressType, type = CONSTS.ADDRESS_NAME_TYPE.LEGAL_ADDRESS) => {
    setState((prevState) => ({ ...prevState, [type]: address }));
  };

  const updateCompanyInfo = async () => {
    const {
      legalAddress,
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      companyAddress,
      phone,
      openLegalAddressInput,
      businessType,
    } = state;
    const inputFields = [
      'legalCompanyName',
      'taxId',
      'addressLine1',
      'city',
      'zipCode',
      'taxIdType',
      'contactFirstName',
      'contactLastName',
      'phone',
    ];
    const inputFieldsToValidate = openLegalAddressInput
      ? [...inputFields, 'legalZipCode', 'legalAddressLine1', 'legalCity']
      : inputFields;
    const address = openLegalAddressInput ? legalAddress : companyAddress;
    const dateOfBirthValue = dateOfBirth?.toString();
    const parsedDate = dateOfBirthValue
      ? parse(dateOfBirthValue, DATE_FORMATS.monthShortWithLongDateAndYearDashed, new Date())
      : null;

    const dataToUpdate = companyInfo.merge({
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName: contactFirstName || '',
      contactLastName: contactLastName || '',
      phone: phone || '',
      businessType: businessType || '',
      ...convertToServerAddress(companyAddress),
      ...convertToServerLegalAddress(address),
    });
    let validationErrors = getValidationErrors('companyInfo', dataToUpdate, inputFieldsToValidate);

    validationErrors = {
      ...validateBusinessType(businessType),
      ...validateIndustryType(naicsCode),
      ...validateDateOfBirth(parsedDate),
      ...validateContactFirstName(contactFirstName),
      ...validateContactLastName(contactLastName),
      ...validateContactPhone(phone),
      ...validateLegalCompanyName(legalCompanyName),
      ...validateTaxIdType(taxIdType),
      ...validateTaxId(businessType, taxIdType, taxId),
      ...validateCompanyBusinessAddress(companyAddress, false),
    };

    if (openLegalAddressInput) {
      validationErrors = {
        ...validationErrors,
        ...validateCompanyBusinessAddress(legalAddress, true),
      };
    }

    setValidationErrors(validationErrors);
    setIsLoading(true);

    if (!isValidationOk(validationErrors)) {
      analytics.track('Organization', 'Status', {
        PageName: 'one-last-thing',
        Flow: 'batch-payment',
        Intent: 'submit-details',
        Status: 'failure',
        ValidationErrors: { ...validationErrors },
      });
      setIsLoading(false);

      return {
        hasValidationErrors: true,
      };
    }

    const [{ companyInfo: updatedCompanyInfo }] = await Promise.all([
      companyInfoApi.updateCompanyInfo(orgId, dataToUpdate),
      industryApi.updateIndustry(orgId, { naicsCode, industryText }),
      userApi.update({ id: profile.id, dateOfBirth: parsedDate?.toISOString() }),
    ]);
    const companyInfoRecord = CompanyInfoRecord(updatedCompanyInfo);
    setCompanyInfo(companyInfoRecord as any);

    return undefined;
  };

  const handleOnSubmit = async () => {
    analytics.track('Organization', 'Click', {
      PageName: 'one-last-thing',
      Flow: 'batch-payment',
      Intent: 'submit-details',
      Cta: 'save-and-schedule-my-payment',
      ...calculateComplianceAnalyticsFields(limitations),
    });
    const [updateCompanyInfoError, updateCompanyInfoResult] = await catchAsync(updateCompanyInfo());

    if (updateCompanyInfoError) {
      history.push(generatePath(locations.batchPay.batchError, { orgId }));

      return;
    }

    if (updateCompanyInfoResult?.hasValidationErrors) {
      return;
    }

    analytics.track('Organization', 'Status', {
      PageName: 'one-last-thing',
      Flow: 'batch-payment',
      Intent: 'submit-details',
      Status: 'success',
    });

    await scheduleBatchPayments({
      onNoPaymentsScheduled: () => {
        setIsLoading(false);
      },
    });
  };

  const {
    legalAddress,
    legalCompanyName,
    taxId,
    taxIdType,
    contactFirstName,
    contactLastName,
    companyAddress,
    phone,
    businessType,
    openLegalAddressInput,
  } = state;

  return (
    <QBOPayBillCompleteLegalInfoPage
      legalCompanyName={legalCompanyName}
      companyName={companyInfo.companyName}
      taxId={taxId}
      taxIdType={taxIdType}
      address={legalAddress}
      contactFirstName={contactFirstName}
      contactLastName={contactLastName}
      phone={phone}
      dateOfBirth={dateOfBirth}
      businessType={businessType}
      companyAddress={companyAddress}
      openLegalAddressInput={openLegalAddressInput}
      goExit={onBatchExit}
      onAddressChange={onAddressChange}
      validationErrors={validationErrors}
      onLegalInfoChange={onLegalInfoChange}
      handleOnSubmit={handleOnSubmit}
      onSelectNaicsCode={onSelectNaicsCode}
      onResetNaicsCode={onResetNaicsCode}
      onDateOfBirthChange={setDateOfBirth}
      isLoading={isLoading}
    />
  );
};
