import React, { useEffect } from 'react';
import styled from 'styled-components';

import analytics from 'src/services/analytics';
import { devices } from 'src/theme/AppDevices';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { ModalContentContainer } from 'src/components/common/ModalMessage/ModalMessage.styles';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';
import MIButton from 'src/components/common/MIButton';
import { ReactComponent as ProtectIcon } from 'src/images/icons/protect.svg';
import { ReactComponent as VerifiedIcon } from 'src/images/icons/v.svg';

type Props = {
  onClose: () => void;
};

// Check protection static modal
const Modal = ({ onClose }: Props) => {
  useEffect(() => {
    analytics.track('LearnMoreModal', 'Viewed', { infoType: 'check-fee' });
  });

  const onModalClose = () => {
    onClose();
    analytics.track('LearnMoreModal', 'Closed', { infoType: 'check-fee' });
  };

  const onModalConfirm = () => {
    onClose();
    analytics.track('LearnMoreModal', 'Checked', { infoType: 'check-fee' });
  };

  return (
    <StyledModal
      alignLeft
      titleComponent={
        <StyledTitle>
          <ProtectIcon />
          <MIFormattedText label="checkFee.modal.title" />
        </StyledTitle>
      }
      contentSubTitle={
        <>
          <StyledSubtitle>
            <MIFormattedText label="checkFee.modal.description" />
          </StyledSubtitle>

          {[1, 2, 3].map((item) => (
            <StyledItem key={item}>
              <VerifiedIcon />
              <div>
                <div className="title">
                  <MIFormattedText label={`checkFee.modal.title${item}`} />
                </div>
                <div className="text">
                  <MIFormattedText label={`checkFee.modal.text${item}`} />
                </div>
              </div>
            </StyledItem>
          ))}
        </>
      }
      buttonComponent={
        <ButtonsContainer>
          <StyledButton
            label="checkFee.modal.confirm"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={onModalConfirm}
          />
        </ButtonsContainer>
      }
      onCloseClick={onModalClose}
    />
  );
};

export { Modal };

const StyledModal = styled(ModalMessage)`
  ${ModalContentContainer} {
    padding: 4rem;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #393a3d;
`;
const StyledSubtitle = styled.div`
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

const StyledItem = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1.2rem;
  flex-grow: 0;
  line-height: 2.1rem;

  svg {
    flex-shrink: 0;
    padding-top: 0.5rem;
  }
  .title {
    color: #000;
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 0.4rem;
  }
  .text {
    font-size: 1.4rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(MIButton)`
  @media ${devices.mobile} {
    height: 4.8rem;
  }
`;
