import { useEffect, useRef } from 'react';
import analytics from 'src/services/analytics';
import { DashboardTabStatusEnum, TABS } from '../consts';

type Params = {
  status: DashboardTabStatusEnum;
  setStatus: (index: DashboardTabStatusEnum) => void;
};

export const useOnChangeTab = ({ status, setStatus }: Params) => {
  const currentTabIndex = useRef<number | null>(null);

  useEffect(() => {
    currentTabIndex.current = TABS.indexOf(status);
  }, [status]);

  const onChangeTab = (statusIndex: number) => {
    if (currentTabIndex.current !== statusIndex) {
      // prevent double triggering of onChangeTab event
      const newTabStatus = TABS[statusIndex];
      analytics.trackAction(`tab-event-${newTabStatus}`);
      setStatus(TABS[statusIndex]);
    }

    currentTabIndex.current = statusIndex;
  };

  return { onChangeTab };
};
