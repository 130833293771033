import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Flex, Switch, Tooltip } from '@melio/billpay-design-system';
import * as CONSTS from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { getDeliveryOption } from 'src/utils/delivery-methods';
import { DeliveryOptionType } from 'src/utils/types';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types/store-types';
import { useDeliverySpeed } from 'src/billpay/qbdt/pages/batch-pay/table/components/DeliverySpeedCell/useDeliverySpeed';
import { useIsTruncated } from 'src/helpers/react/useIsTruncated';
import moment from 'src/utils/moment-business-days';
import useGetValidFundingSources from '../../hooks/useGetValidFundingSources';

type Props = {
  batchItemId: string;
  amount: number;
  payment: BatchPaymentType;
  deliveryOptions?: DeliveryOptionType[];
};

export const DeliverySpeedCell = ({ batchItemId, payment, amount, deliveryOptions = [] }: Props) => {
  const intl = useIntl();
  const { fundingSourceId, deliveryMethod, deliveryPreference } = payment;
  const deliveryMethodType = deliveryMethod?.deliveryType;

  const [validFundingSources] = useGetValidFundingSources();
  const fundingSource = fundingSourceId ? validFundingSources.find(({ id }) => id === fundingSourceId) : null;

  const fundingSourceType =
    fundingSource?.fundingType === CONSTS.FUNDING_TYPE.ACH
      ? fundingSource?.fundingType
      : fundingSource?.cardAccount.cardType;

  const [isFastDelivery, isOptionDisabled, onChange] = useDeliverySpeed(
    batchItemId,
    deliveryPreference,
    deliveryMethodType,
    deliveryOptions,
    payment
  );

  const currentDeliveryOption = getDeliveryOption(deliveryPreference || deliveryMethodType, deliveryOptions);
  const deliveryType = currentDeliveryOption?.type;
  const deliveryDate = currentDeliveryOption?.deliveryDate;
  const scheduledDate = currentDeliveryOption?.scheduledDate;

  const notAvailable = !deliveryType || !fundingSource;

  const fastSpeedLabel = useMemo(
    () =>
      deliveryMethodType === CONSTS.DELIVERY_TYPE.ACH
        ? 'regularBatchPayments.cells.deliverySpeed.fastAch'
        : 'regularBatchPayments.cells.deliverySpeed.fastCheck',
    [deliveryMethodType]
  );

  const deliveryDurationSpeedLabel = useMemo(() => {
    if (notAvailable)
      return intl.formatMessage({
        id: 'regularBatchPayments.cells.deliverySpeed.notAvailable',
      });

    if (deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
      if (fundingSourceType === CONSTS.FUNDING_TYPE.ACH) {
        return amount >= CONSTS.RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD
          ? intl.formatMessage({
              id: 'bills.pay.date.deliveryOptions.subTitle.achOver100K',
            })
          : intl.formatMessage({
              id: 'bills.pay.date.deliveryOptions.subTitle.achBelow100K',
            });
      }

      const businessDaysNumber =
        deliveryDate && scheduledDate ? moment(scheduledDate).businessDiff(moment(deliveryDate)) : 0;

      if (
        (fundingSourceType === CONSTS.CARD_TYPES.DEBIT || fundingSourceType === CONSTS.CARD_TYPES.CREDIT) &&
        businessDaysNumber > 1
      ) {
        return intl.formatMessage({ id: 'bills.pay.date.deliveryOptions.subTitle.achCard' }, { n: businessDaysNumber });
      }

      if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT) {
        return intl.formatMessage({
          id: 'bills.pay.date.deliveryOptions.subTitle.achDebit',
        });
      }

      if (fundingSourceType === CONSTS.CARD_TYPES.CREDIT) {
        return intl.formatMessage({
          id: 'bills.pay.date.deliveryOptions.subTitle.achCredit',
        });
      }
    }

    if (deliveryType === CONSTS.DELIVERY_TYPE.RPPS) {
      if (fundingSourceType === CONSTS.FUNDING_TYPE.ACH) {
        return intl.formatMessage({
          id: 'bills.pay.date.deliveryOptions.subTitle.rppsAch',
        });
      } else if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT || fundingSourceType === CONSTS.CARD_TYPES.CREDIT) {
        return intl.formatMessage({
          id: 'bills.pay.date.deliveryOptions.subTitle.rppsCreditOrDebit',
        });
      }
    }

    if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
      if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT || fundingSourceType === CONSTS.CARD_TYPES.CREDIT) {
        return intl.formatMessage({
          id: 'bills.pay.date.deliveryOptions.subTitle.virtualCardCreditOrDebit',
        });
      }

      return intl.formatMessage({
        id: 'bills.pay.date.deliveryOptions.subTitle.virtualCardACH',
      });
    }

    return intl.formatMessage({
      id: `bills.pay.date.deliveryOptions.subTitle.${deliveryType}`,
    });
  }, [deliveryType, notAvailable, fundingSourceType, amount, scheduledDate, deliveryDate]);

  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();

  return useMemo(
    () => (
      <Box role="cell" w="100%">
        {!isOptionDisabled ? (
          <Tooltip
            isDisabled={isFastDelivery}
            placement="bottom"
            label={
              <MIFormattedText
                label={
                  deliveryMethodType === CONSTS.DELIVERY_TYPE.CHECK
                    ? 'regularBatchPayments.tooltips.fastCheckTooltip'
                    : 'regularBatchPayments.tooltips.fastAchTooltip'
                }
              />
            }
          >
            <Flex align="center">
              <MIFormattedText label={fastSpeedLabel} />
              <Switch isChecked={isFastDelivery} onChange={onChange} />
            </Flex>
          </Tooltip>
        ) : (
          <Tooltip isDisabled={!isTruncated} label={deliveryDurationSpeedLabel} placement="top">
            <Box color={notAvailable ? 'ds.gray.200' : 'ds.gray.100'} ref={ref} isTruncated>
              {deliveryDurationSpeedLabel}
            </Box>
          </Tooltip>
        )}
      </Box>
    ),
    [isOptionDisabled, isFastDelivery, isTruncated, deliveryDurationSpeedLabel, fastSpeedLabel]
  );
};
