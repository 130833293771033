import React from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from 'src/utils/formatting';
import { qbo } from 'src/config';
import { CONSTS } from 'src/utils/consts';
import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { close } from 'src/billpay/qbdt/services/qbdt';

export const BlockedUserPageContainer = () => {
  const companyInfo = useSelector(getCompanyInfo).toJS();
  const showSupportEmail = companyInfo.kyb === CONSTS.KYB_TYPE.POLICY;

  const site = useSiteContext();
  return (
    <site.components.Dialog
      type={CONSTS.DIALOG_TYPE.CONFIRM}
      variant={CONSTS.DIALOG_VARIANTS.SUCCESS}
      title="qbo.blockedUser.title"
      subtitle="qbo.blockedUser.subTitle"
      subtitleValues={{
        tos: (
          <Link href={qbo.agreementLinks.termsOfService}>
            <MIFormattedText label="qbo.blockedUser.termsOfService" />
          </Link>
        ),
        support: showSupportEmail && (
          <MIFormattedText
            label="qbo.blockedUser.contactDetails"
            values={{
              supportEmail: <Link href={`mailto:${qbo.compliance.email}`}>{qbo.compliance.email}</Link>,
            }}
          />
        ),
      }}
      okButtonText="qbo.blockedUser.dismiss"
      onOkAction={close}
      onCancelAction={close}
      showCancel={false}
    />
  );
};

const Link = styled.a`
  text-decoration: none;
  color: rgb(0, 119, 197);

  &:hover {
    text-decoration: underline;
  }
`;
