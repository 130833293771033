import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CONSTS, BUTTONS_ROW_POSITION } from 'src/utils/consts';
import analytics from 'src/services/analytics';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MicroDepositActions, MicroDepositState, RESOLUTIONS } from 'src/pages/settings/hooks/microDepositsCommon';
import SuccessIllustration from 'src/images/qbo/success-check.svg';
import { zendeskService } from 'src/services/zendesk';
import MILink from 'src/components/common/MILink';
import { VerifyMicroDepositsDialog } from './VerifyMicroDepositsDialog';

type Props = {
  onVerifyFinished: (isSuccess?: boolean) => void;
  eventPage: string;
  dialogSuccessTitle: string;
  dialogSuccessSubtitle: string;
} & MicroDepositState &
  MicroDepositActions;

const openSupportChat = () => {
  analytics.track('micro-deposits-unable-to-verify', 'support-open-start-chat');
  zendeskService.show();
};

function bindEvents(onVerifyFinished: (isSuccess?: boolean) => void, eventPage: string, dismiss: () => void) {
  return {
    cancel: () => {
      analytics.track(eventPage, 'cancel-dialog');
      dismiss();
      onVerifyFinished();
    },

    dismissFailed: () => {
      analytics.track(eventPage, 'dismiss-failed-dialog');
      dismiss();
      onVerifyFinished();
    },

    dismissSuccess: () => {
      analytics.track(eventPage, 'dismiss-success-dialog');
      dismiss();
      onVerifyFinished(true);
    },
  };
}

export default function VerifyMicroDeposits(props: Props) {
  const { onVerifyFinished, dismiss, eventPage, resolution, dialogSuccessTitle, dialogSuccessSubtitle } = props;
  const dismissEvents = useMemo(() => bindEvents(onVerifyFinished, eventPage, dismiss), [
    onVerifyFinished,
    dismiss,
    eventPage,
  ]);
  const companyInfo = useSelector(getCompanyInfo);
  const companyName = companyInfo?.companyName;

  const site = useSiteContext();
  switch (resolution) {
    case RESOLUTIONS.SUCCESS:
      return (
        <site.components.Dialog
          type={CONSTS.DIALOG_TYPE.ALERT}
          variant={CONSTS.DIALOG_VARIANTS.SUCCESS}
          title={dialogSuccessTitle}
          subtitle={dialogSuccessSubtitle}
          cancelButtonText="settings.microDeposits.verifyDialogSuccess.ok"
          onCancelAction={dismissEvents.dismissSuccess}
          buttonsRowPosition={BUTTONS_ROW_POSITION.RIGHT}
          minHeight="36.8rem"
          image={SuccessIllustration}
        />
      );
    case RESOLUTIONS.SUPPORT:
      return (
        <site.components.Dialog
          type={CONSTS.DIALOG_TYPE.ALERT}
          variant={CONSTS.DIALOG_VARIANTS.ERROR}
          title="settings.microDeposits.verifyDialogLimitReached.title"
          subtitle="settings.microDeposits.verifyDialogLimitReached.subtitle"
          subtitleValues={{
            supportPhone: (
              <SupportLink href={`tel:${site.config.support.phone}`}>{site.config.support.phone}</SupportLink>
            ),
            supportEmail: (
              <SupportLink href={`mailto:${site.config.support.email}`}>{site.config.support.email}</SupportLink>
            ),
            chatWithUs: (
              <ChatLink to={openSupportChat} label="settings.microDeposits.verifyDialogLimitReached.chatWithUs" />
            ),
            br: <br />,
          }}
          cancelButtonText="settings.microDeposits.verifyDialogLimitReached.dismiss"
          onCancelAction={dismissEvents.dismissFailed}
        />
      );
    default:
      return <VerifyMicroDepositsDialog {...props} companyName={companyName} dismiss={dismissEvents.cancel} />;
  }
}

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.colors.primary.opaque};
  text-decoration: none;
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;
