import vendorsApi from 'src/services/api/vendors';
import pick from 'lodash/pick';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { name } from './vendors-store';

const stateName = 'vendorPaymentPreferences';

type VendorPaymentPreferencesType = {
  blockCCPayments: boolean;
  blockPayments: boolean;
};

export type CheckVendorPaymentPreferencesType = {
  id: number;
  orgId: string;
};

type VendorPaymentPreferencesPayload = {
  vendorPaymentPreferences: { preferences: VendorPaymentPreferencesType };
} & CheckVendorPaymentPreferencesType;

const checkVendorPaymentPreferences = createApiCallSlice<VendorPaymentPreferencesPayload, any>({
  name: '[VENDORS] CHECK_ENABLE_CC_PAYMENTS',
  api: vendorsApi.checkVendorPaymentPreferences,
  initialState: {
    [stateName]: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state[stateName][action.payload.id] = { loading: true, error: null };
    },
    [ON_SUCCESS]: (state, action) => {
      state[stateName][action.meta.identifier.id] = {
        ...{
          preferences: pick(action.payload, ['blockCCPayments', 'blockPayments']),
        },
        loading: false,
        error: null,
      };
    },
    [ON_FAILURE]: (state, action) => {
      state[stateName][action.payload.id] = {
        loading: false,
        error: action.error,
      };
    },
  },

  selectors: {
    item: (state, vendorId) => state[name][stateName][vendorId]?.preferences,
    loading: (state, vendorId) => state[name][stateName][vendorId]?.loading,
  },
});

export default checkVendorPaymentPreferences;
