import React from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { AccountType, PaymentExpeditedByEnum } from 'src/utils/types';
import {
  isPaymentFailed,
  isPaymentDeclined,
  getFailedPaymentDate,
  isVirtualCardPaymentExpired,
  isPaymentScheduled,
} from 'src/utils/payments';
import { DELIVERY_TYPE, FAILED_PAYMENT_TYPE, PAYMENT_COLLECT_STATUS } from 'src/utils/consts';
import { AccountRecord } from 'src/records/settings.record';
import { Flex, VStack } from '@melio/billpay-design-system';
import { chakra } from 'src/theme/ds';
import { DeliveryMethodRecord } from 'src/records/vendor';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import PaymentProcessingBlockedNotificationCard from 'src/billpay/qbdt/pages/bill/components/PaymentProcessingBlockedNotificationCard';
import {
  PaymentInfoDividerSizeEnum,
  PaymentInfoSectionDivider,
} from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PaymentInfoSectionDivider';
import { TotalAmountSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/TotalAmountSection/TotalAmountSection';
import { PayToSection } from 'src/billpay/qbdt/modules/view-and-confirm-pages/sections/PayToSection/PayToSection';
import { isVendorRequest } from 'src/utils/bills';
import { usePaymentFee } from './hooks/usePaymentFee';
import { FailedPaymentInfoSection } from './sections/FailedPaymentInfoSection';
import { PayFromInfoSection } from './sections/ViewPayFromSection/ViewPayFromSection';
import { VendorInfoSection } from './sections/ViewVendorInfoSection/ViewVendorInfoSection';
import { FailedPaymentDateSection } from './sections/FailedPaymentDateSection';
import { ViewMemoSection } from './sections/ViewMemoSection';
import PaymentFee from '../../pay/components/PaymentFee';
import { useViewPaymentContext } from '../context/ViewPaymentContext';
import { FailedPaymentNotificationCard } from '../components/failed-payment-notifications/FailedPaymentNotificationCard';
import { PaymentStatus } from './PaymentStatus/PaymentStatus';

export const BillPaymentInfoCard = () => {
  const { bill, payment } = useViewPaymentContext();

  const paymentFailed = isPaymentFailed(payment);
  const paymentDeclined = isPaymentDeclined(payment);
  const paymentFailedDate = getFailedPaymentDate(payment);
  const isFailedToDeliver = payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;
  const isFailedToCollect = payment?.collectStatus === PAYMENT_COLLECT_STATUS.FAILED;

  const fundingSource = AccountRecord(
    useSelector(fundingSourcesStore.selectors.byId(payment.fundingSourceId)) || payment.fundingSource
  ) as RecordOf<AccountType>;

  const deliveryMethod = DeliveryMethodRecord(payment.deliveryMethod);
  const isDirectPayment = deliveryMethod.deliveryType === DELIVERY_TYPE.RPPS;

  const { fee } = usePaymentFee({
    payment,
    fundingSource,
  });

  const companyName = bill.vendor?.companyName || '';
  const totalAmount = payment.amount as number;
  const { internalBill: isInternalBill } = bill;

  const isSharedVendor = isVendorRequest(bill);
  const shouldShowFee = !isInternalBill && !paymentFailed && fee;

  const billsInfo = payment.bills?.map((bill) => ({
    invoiceNumber: bill.invoiceNumber,
    dueDate: bill.dueDate,
    totalAmount: bill.totalAmount,
  }));

  const isFailedToCollectOrDeliver = isFailedToDeliver || isFailedToCollect;

  const isVirtualCardExpired = isVirtualCardPaymentExpired(payment);
  const shouldShowVendorInfoSection = (!paymentFailed && !isSharedVendor) || isVirtualCardExpired;

  return (
    <CardContainer>
      <PaymentStatus />
      <PaymentInfoContainer spacing="5" alignItems="initial">
        {paymentFailed && isFailedToCollectOrDeliver && !paymentDeclined && (
          <FailedPaymentNotificationCard bill={bill} payment={payment} />
        )}
        {isPaymentScheduled(payment) && <PaymentProcessingBlockedNotificationCard payment={payment} bill={bill} />}
        <TotalAmountSection totalAmount={totalAmount} />
        <PaymentInfoSectionDivider size={PaymentInfoDividerSizeEnum.Lg} />
        <PayToSection companyName={companyName} billsInfo={billsInfo} />
        <PaymentInfoSectionDivider />
        {paymentFailed && (
          <>
            <FailedPaymentInfoSection bill={bill} fundingSource={fundingSource} />
            <PaymentInfoSectionDivider />
            {paymentFailedDate && !isVirtualCardExpired && (
              <FailedPaymentDateSection paymentFailedDate={paymentFailedDate} />
            )}
          </>
        )}
        {!paymentFailed && (
          <>
            <PayFromInfoSection
              bill={bill}
              payment={payment}
              fundingSource={fundingSource}
              deliveryMethod={deliveryMethod}
            />
            <PaymentInfoSectionDivider />
          </>
        )}
        {shouldShowVendorInfoSection && (
          <>
            <VendorInfoSection
              bill={bill}
              payment={payment}
              fundingSource={fundingSource}
              deliveryMethod={deliveryMethod}
            />
            <PaymentInfoSectionDivider />
          </>
        )}
        {!isDirectPayment && <ViewMemoSection payment={payment} />}
        {shouldShowFee && (
          <>
            <PaymentInfoSectionDivider size={PaymentInfoDividerSizeEnum.Lg} />
            {fee && <PaymentFee isExpeditedByPayee={payment.expeditedBy === PaymentExpeditedByEnum.PAYEE} fee={fee} />}
          </>
        )}
      </PaymentInfoContainer>
    </CardContainer>
  );
};

const PaymentInfoContainer = chakra(VStack, {
  baseStyle: {
    p: '5',
  },
});

const CardContainer = chakra(Flex, {
  baseStyle: {
    h: 'full',
    flexDir: 'column',
    rounded: 'ds.lg',
    bgColor: 'ds.white',
    boxShadow: '0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1)', // TODO: replace with boxShadow from DS
  },
});
