import * as React from 'react';
import get from 'lodash/get';
import { compose } from 'recompose';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { withPreservedStateNavigator } from 'src/hoc';
import analytics from 'src/services/analytics';
import api from 'src/services/api/financialAccounts';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { WizardFormRow } from 'src/components/layout/WizardElements';
import { isSandboxIndicator } from 'src/utils/funding-sources';
import { GlobalState } from 'src/redux/types';
import US_STATES from 'src/utils/us-states';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { loadFundingSourcesAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { generatePath } from 'react-router-dom';
import locations from 'src/billpay/qbdt/pages/locations';
import { QBDTStepLayout } from 'src/billpay/qbdt/components';
import CardFormInput from './components/CardFormInput';

type LocationState = {
  token: string;
  digits: string;
  cardBin: string;
  expiration: string;
  goExit: () => void;
  fundingSource?: RecordOf<AccountType>;
  preservedState?: Record<string, any>;
  origin: string;
  newFundingSourceId?: boolean;
};

type MapStateToProps = {
  orgId: string;
};

type MapDispatchToProps = {
  selectFundingSourceForPayBillFlow: (id: number) => void;
  refreshFundingSources: () => Promise<void>;
};

type Props = {
  locationState: LocationState;
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  site: any;
  selectFundingSourceForBatchFlow: (any) => null;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  errorCode: string | null | undefined;
  successDialog: boolean;
  isLoading: boolean;
  id?: number | null;
  cardAccount:
    | {
        firstName: string;
        lastName: string;
        card4digits: string;
        network: string;
        cardType: string;
      }
    | null
    | undefined;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  submitValidation: Record<string, any>;
};

const fieldsValidationFlag = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
};

const eventPage = 'add-card';

class SetCardAccountsHolderPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      errorCode: null,
      successDialog: false,
      cardAccount: null,
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      id: null,
      submitValidation: fieldsValidationFlag,
    };
  }

  onDone = () => {
    const { firstName, lastName, address, city, state, zipCode } = this.state;
    const validationObj = ['firstName', 'lastName', 'address', 'city', 'state', 'zipCode'].reduce((obj, s) => {
      if (Object.prototype.hasOwnProperty.call(this.state, s) && !this.state[s]) obj[s] = true;

      return obj;
    }, {});
    if (Object.keys(validationObj).length) {
      this.setState({
        submitValidation: Object.assign({}, fieldsValidationFlag, validationObj),
      });
      analytics.track(eventPage, 'add-card-account-submit-validation-error', this.state.submitValidation);
      return false;
    }

    analytics.track(eventPage, 'add-card-account-submit');
    this.setState({ isLoading: true });

    const { refreshFundingSources, locationState, orgId } = this.props;
    const { token, expiration, digits, cardBin } = locationState;

    return api
      .addCardAccount(
        orgId,
        {
          token,
          expiration,
          card4digits: digits,
          firstName,
          lastName,
          line1: address,
          city,
          state,
          zipcode: zipCode,
          cardBin,
        },
        { catchCall: true }
      )
      .then(({ accounts, code }) => {
        const success = code === CONSTS.SERVER_RESPONSE_CODES.OK;
        const cardAccount = get(accounts, '0.cardAccount', null);

        if (success) {
          const cardTypeEventLabel = cardAccount ? cardAccount.cardType.toLowerCase() : null;
          analytics.track(eventPage, 'done-adding-new-card');
          analytics.trackMqlEvent('added-funding', 'mql');
          if (cardTypeEventLabel) {
            analytics.trackMqlEvent(`added-funding-${cardTypeEventLabel}`, 'mql');
          }

          analytics.setTraits({
            [CONSTS.DB_ANALYTICS_TRAITS.ADDED_FUNDING]: true,
          });
          analytics.setFundingSourceTraits();
        } else {
          analytics.track(eventPage, 'adding-new-card-error', { code });
        }

        this.setState(
          {
            id: get(accounts, '0.id'),
            cardAccount,
            firstName: null,
            lastName: null,
            address: null,
            city: null,
            state: null,
            zipCode: null,
            errorCode: code,
            isLoading: false,
            submitValidation: fieldsValidationFlag,
          },
          () => {
            if (success) {
              setTimeout(this.onCloseSuccessModal, CONSTS.TIMEOUTS.CLOSE_MODAL);
            }
          }
        );
        return refreshFundingSources();
      })
      .catch((err) => {
        analytics.track(eventPage, 'failed-adding-new-card');
        return this.setState({ errorCode: err, isLoading: false });
      });
  };
  onCloseErrorModal = () => {
    this.setState({
      errorCode: null,
    });
  };
  onCloseSuccessModal = () => this.goNext();
  getCardLabel = (type) =>
    type === CONSTS.CARD_TYPES.CREDIT
      ? 'onboarding.fundingSources.card.successNoteCredit'
      : 'onboarding.fundingSources.card.successNoteDebit';

  goPrev = () => {
    this.props.navigate(generatePath(locations.fundingSource.card, { orgId: this.props.orgId }));
  };

  goExit = () => {
    this.props.navigateToExitWithPreservedState(this.props.locationState);
  };

  goNext = async () => {
    const {
      locationState,
      selectFundingSourceForPayBillFlow,
      navigate,
      orgId,
      selectFundingSourceForBatchFlow,
    } = this.props;
    const { id } = this.state;
    const origin = get(locationState, 'preservedState.origin', '');

    if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS) {
      await selectFundingSourceForBatchFlow({
        newFundingSourceId: id,
      });
    }

    if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL && id) {
      selectFundingSourceForPayBillFlow(id);
    }

    navigate(generatePath(locations.fundingSource.linkAccount, { orgId }), false, {
      ...locationState,
      fundingSourceId: id,
      origin,
    });
  };

  returnVal = ({ value, id }: { value: string; id: string }) => {
    if (!Object.prototype.hasOwnProperty.call(this.state, id)) return;

    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
      submitValidation: Object.assign({}, this.state.submitValidation, {
        [id]: false,
      }),
    }));
  };

  render() {
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      zipCode,
      submitValidation,
      isLoading,
      cardAccount,
      errorCode,
    } = this.state;
    const { site } = this.props;
    const successSubtitle = 'common.rawValue';
    const successSubtitleValues = {
      value: cardAccount
        ? `${cardAccount.network}
           (${cardAccount.card4digits})\n${cardAccount.firstName} ${cardAccount.lastName}`
        : '',
    };
    const successTitle = cardAccount ? this.getCardLabel(cardAccount.cardType.toLowerCase()) : '';
    const isSandboxIndicatorShown = isSandboxIndicator(site);

    return (
      <React.Fragment>
        <QBDTStepLayout
          title="onboarding.fundingSources.card.title"
          onPrev={this.goPrev}
          goExit={this.goExit}
          onSubmit={this.onDone}
          nextLabel="onboarding.fundingSources.card.save"
          isLoading={isLoading}
          hideHeader
          isSandboxIndicatorShown={isSandboxIndicatorShown}
        >
          {errorCode === CONSTS.SERVER_RESPONSE_CODES.OK && (
            <site.components.Dialog
              type={CONSTS.DIALOG_TYPE.ALERT}
              variant={CONSTS.DIALOG_VARIANTS.SUCCESS}
              title={successTitle}
              subtitleValues={successSubtitleValues}
              subtitle={successSubtitle}
              onCancelAction={this.onCloseSuccessModal}
            />
          )}
          {errorCode && errorCode !== CONSTS.SERVER_RESPONSE_CODES.OK && (
            <site.components.Dialog
              type={CONSTS.DIALOG_TYPE.ALERT}
              variant={CONSTS.DIALOG_VARIANTS.ERROR}
              title="onboarding.fundingSources.card.notAddedTitle"
              subtitle={`server.${errorCode}`}
              onCancelAction={this.onCloseErrorModal}
              cancelButtonText="onboarding.fundingSources.card.errorButton"
            />
          )}
          <div className={FULL_STORY_MASK_RULE_CLASS}>
            <WizardFormRow>
              <CardFormInput
                value={firstName}
                label="onboarding.fundingSources.card.firstName.label"
                required
                id="firstName"
                placeHolder=""
                errorMessage="onboarding.fundingSources.card.firstName.errorMessage"
                validationTest="string"
                returnVal={this.returnVal}
                submitValidation={submitValidation.firstName}
                autoFocus
              />
              <CardFormInput
                value={lastName}
                label="onboarding.fundingSources.card.lastName.label"
                required
                id="lastName"
                placeHolder=""
                errorMessage="onboarding.fundingSources.card.lastName.errorMessage"
                validationTest="string"
                returnVal={this.returnVal}
                submitValidation={submitValidation.lastName}
              />
            </WizardFormRow>
            <CardFormInput
              value={address}
              label="onboarding.fundingSources.card.address.label"
              id="address"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.address.errorMessage"
              validationTest="string"
              wizardColumn={false}
              returnVal={this.returnVal}
              required
              submitValidation={submitValidation.address}
            />
            <WizardFormRow>
              <CardFormInput
                value={city}
                label="onboarding.fundingSources.card.city.label"
                id="city"
                placeHolder=""
                errorMessage="onboarding.fundingSources.card.city.errorMessage"
                validationTest="string"
                required
                returnVal={this.returnVal}
                submitValidation={submitValidation.city}
              />
              <MISingleSelect
                id="state"
                value={state}
                label="onboarding.fundingSources.card.state.label"
                options={US_STATES}
                onChange={this.returnVal}
                placeholder=""
                errorMessage={submitValidation.state ? 'onboarding.fundingSources.card.state.errorMessage' : ''}
                required
                flavor={CONSTS.SINGLE_SELECT_FLAVOR.DEFAULT}
              />
            </WizardFormRow>
            <CardFormInput
              value={zipCode}
              label="onboarding.fundingSources.card.zipcode.label"
              required
              id="zipCode"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.zipcode.errorMessage"
              validationTest="string"
              wizardColumn={false}
              returnVal={this.returnVal}
              submitValidation={submitValidation.zipCode}
            />
          </div>
        </QBDTStepLayout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectFundingSourceForPayBillFlow(id: number) {
    dispatch(selectFundingSourceAction(id));
  },
  refreshFundingSources() {
    return new Promise((resolve, reject) => {
      dispatch(loadFundingSourcesAction(resolve, reject));
    });
  },
});

export default compose(
  withPreservedStateNavigator(),
  withSiteContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(SetCardAccountsHolderPageContainer);
