const payBaseUrl = '/quickbooks-desktop/orgs/:orgId/bills/:billId/pay';
const editBaseUrl = '/quickbooks-desktop/orgs/:orgId/bills/:billId/payment/:paymentId/edit';

const payLocations = {
  base: payBaseUrl,
  deliveryMethod: `${payBaseUrl}/delivery-method`,
  date: `${payBaseUrl}/date`,
  funding: `${payBaseUrl}/funding`,
  fastPayment: `${payBaseUrl}/fast-payment`,
  confirm: `${payBaseUrl}/confirm`,
  memo: `${payBaseUrl}/memo`,
  success: `${payBaseUrl}/success`,
  expedite: `${payBaseUrl}/expedite`,
  completeLegalInfo: `${payBaseUrl}/complete-legal-info`,
  completeComplianceInfo: `${payBaseUrl}/complete-compliance-info`,
  error: `${payBaseUrl}/error`,
};

const editLocations = {
  base: editBaseUrl,
  deliveryMethod: `${editBaseUrl}/delivery-method`,
  deliveryMethodAch: `${editBaseUrl}/delivery-method-ach`,
  date: `${editBaseUrl}/date`,
  funding: `${editBaseUrl}/funding`,
  fastPayment: `${editBaseUrl}/fast-payment`,
  confirm: `${editBaseUrl}/confirm`,
  memo: `${editBaseUrl}/memo`,
  success: `${editBaseUrl}/success`,
  expedite: `${editBaseUrl}/expedite`,
  completeLegalInfo: `${editBaseUrl}/complete-legal-info`,
  completeComplianceInfo: `${payBaseUrl}/complete-compliance-info`,
  error: `${editBaseUrl}/error`,
  virtualCardRecovery: `${editBaseUrl}/virtual-card-recovery`,
};

export { payLocations, editLocations };
