import filter from 'lodash/filter';
import { call, put, select } from 'redux-saga/effects';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { syncQbdtBillsToMelio } from 'src/billpay/qbdt/services/qbdt';
import { getOrgId } from 'src/redux/user/selectors';
import { getOrganizationPreferences } from 'src/redux/organization/selectors';
import { dashboardSelectionSlice } from './dashboard-selection-slice';
import { dashboardListItemsSlice } from './dashboard-list-items-slice';
import { dashboardListName, name } from './consts';
import { dashboardSyncBillsSlice } from './dashboard-sync-bills-slice';
import { DashboardSyncBillsStatus } from '../sync/types';
import { viewPaymentUrlsSlice } from './view-payment-urls-slice';

export const dashboardStore = createRestfulSlice({
  name,
  api: {},
  slices: {
    list: dashboardListItemsSlice,
    dashboardSelection: dashboardSelectionSlice,
    syncBills: dashboardSyncBillsSlice,
    viewPaymentUrls: viewPaymentUrlsSlice,
  },
  selectors: {
    byId: (id) => (state) => state[name]?.byId[id],
    getByIds: (ids) => (state) => filter(state[name].byId, (item) => ids.includes(item.id)),
  },
  listHashFunc: () => dashboardListName,
  extraSagas: {
    *[dashboardSyncBillsSlice.actions.startSync.type]() {
      const orgId = yield select(getOrgId);
      const syncStatus = yield select(dashboardSyncBillsSlice.selectors.getStatus);
      const organizationPreferences = yield select(getOrganizationPreferences);

      if (syncStatus !== DashboardSyncBillsStatus.None) {
        return;
      }

      yield put(dashboardSyncBillsSlice.actions.setStatus(DashboardSyncBillsStatus.Running));

      try {
        yield call(syncQbdtBillsToMelio, {
          orgId,
          organizationPreferences,
        });
      } catch (e) {
        // TODO: dashboard - add analytics event here
      } finally {
        yield put(dashboardSyncBillsSlice.actions.setStatus(DashboardSyncBillsStatus.Done));
      }
    },
  },
});
