import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import paymentsApi, { GetDeliveryTimeParamsForBatch } from 'src/services/api/payments';
import {
  updatePayment,
  updatePaymentFundingSource,
  updatePaymentScheduledDate,
} from 'src/modules/regular-batch-payments/utils';
import { actionName } from './consts';

export const BATCH_UPDATE_DELIVERY_OPTIONS = `[${actionName}] UPDATE PAYMENT DELIVERY OPTIONS`;

type BillOptionUpdatePayload = GetDeliveryTimeParamsForBatch & {
  batchItemId: number;
};

export const regularBatchUpdateDeliveryOptionsSlice = createApiCallSlice<BillOptionUpdatePayload, any, any>({
  name: BATCH_UPDATE_DELIVERY_OPTIONS,
  initialState: {
    byId: {},
  },
  api: paymentsApi.getDeliveryTime,
  reducers: {
    [ON_REQUEST](state, action) {
      state.byId[action.payload.batchItemId] = {
        ...state.byId[action.payload.batchItemId],
        loading: true,
        loadingDates: true,
        error: undefined,
      };
      updatePaymentFundingSource(state, action);
      updatePaymentScheduledDate(state, action);
    },
    [ON_SUCCESS](state, action) {
      const { batchItemId } = action.meta.identifier as BillOptionUpdatePayload;

      state.byId[batchItemId] = {
        ...state.byId[batchItemId],
        isScheduledDateSelectedByUser: true,
        loadingDates: false,
        loading: false,
      };
      updatePayment(state, action);
    },
    [ON_FAILURE](state, action) {
      const { batchItemId } = action.meta.identifier as BillOptionUpdatePayload;

      state.byId[batchItemId] = {
        ...state.byId[batchItemId],
        loading: false,
        loadingDates: false,
        error: action.error,
      };
    },
  },
});
