import React from 'react';
import { HStack } from '@melio/billpay-design-system';
import analytics from 'src/services/analytics';
import PaginationPrevious from './components/PaginationPrevious';
import PaginationNext from './components/PaginationNext';
import PaginationPage from './components/PaginationPage';
import { getVisiblePages } from './utils';
import { PaginationInfo } from './components/PaginationInfo';

type Props = {
  pageIndex: number;
  pageCount: number;
  itemFromIndex: number;
  itemToIndex: number;
  totalCount: number;
  goToPage: (page: number) => void;
};
const eventPage = 'pagination';

export const DashboardPagination = ({
  pageIndex,
  pageCount,
  goToPage,
  itemFromIndex,
  itemToIndex,
  totalCount,
}: Props) => {
  const visiblePages = getVisiblePages(pageIndex, pageCount);
  const goToPreviousPage = () => {
    analytics.trackAction(`${eventPage}-previous-page`, {
      page: pageIndex - 1,
    });
    goToPage(pageIndex - 1);
  };
  const goToNextPage = () => {
    analytics.trackAction(`${eventPage}-next-page`, { page: pageIndex + 1 });
    goToPage(pageIndex + 1);
  };
  return (
    <HStack spacing="ds.lg" alignItems="center">
      <PaginationInfo
        {...{
          itemFromIndex,
          itemToIndex,
          totalCount,
        }}
      />
      <HStack spacing="ds.sm">
        <PaginationPrevious canPreviousPage={pageIndex > 0} goPreviousPage={goToPreviousPage} />
        {visiblePages.map((index) => (
          <PaginationPage
            key={index}
            page={index}
            isSelected={index === pageIndex}
            onClick={() => {
              analytics.trackAction(`${eventPage}-go-page`, { page: index });
              goToPage(index);
            }}
          />
        ))}
        <PaginationNext canNextPage={pageIndex < pageCount - 1} goToNextPage={goToNextPage} />
      </HStack>
    </HStack>
  );
};
