import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@melio/billpay-design-system';
import { CONSTS } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import batchPaymentsLocation from 'src/billpay/qbdt/pages/batch-pay/locations';
import analytics from 'src/services/analytics';
import { close } from 'src/billpay/qbdt/services/qbdt';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { useCreditCardBenefits } from 'src/billpay/qbdt/hooks/useCreditCardBenefits';
import { useDebitFee } from 'src/billpay/qbdt/hooks/useDebitFee';
import QBOMISecurityDetails from 'src/components/common/QBOMISecurityDetails';
import { useGoAddFundingSourceOfType } from './hooks/useGoAddFundingSourceOfType';
import { BATCH_PAGE_EVENT } from '../analytics/event-mapping';
import { StyledQBDTStepLayout } from './styles';
import { useBatchSettings } from '../hooks/useBatchSettings';

export const AddFundingSourcePage = () => {
  const history = useHistory();
  const [redirectUrl] = useLocationState('redirectUrl', null);
  const [batchItemId] = useLocationState('batchItemId', null);
  const [applyToAllBills] = useLocationState('applyToAllBills', false);
  const [closeAppOnExit] = useLocationState('closeAppOnExit', false);
  const { batchSettings } = useBatchSettings();
  const { billIds } = batchSettings;

  const debitFee = useDebitFee();

  const { goAddFundingSourceOfType } = useGoAddFundingSourceOfType({
    initialLocationState: {
      preservedState: {
        batchItemId,
        origin: CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BATCH_PAY_BILLS,
        closeAppOnExit: false,
        applyToAllBills,
        billIds,
      },
      redirectUrl,
      exitUrl: batchPaymentsLocation.addFundingSource,
    },
  });

  const { CreditCardBenefitsModal, openCreditCardBenefitsModal } = useCreditCardBenefits({
    eventPage: BATCH_PAGE_EVENT,
    onAddFundingSource: goAddFundingSourceOfType,
  });

  const goExit = () => {
    analytics.track(BATCH_PAGE_EVENT, 'exit');

    if (closeAppOnExit) {
      close();
      return;
    }

    history.push(batchPaymentsLocation.batch);
  };

  return (
    <StyledQBDTStepLayout
      title="bills.pay.fundingSource.title"
      subtitle="bills.pay.fundingSource.subtitle"
      goExit={goExit}
      onPrev={goExit}
      footer={
        <Box mt="0">
          <QBOMISecurityDetails showIconCC />
        </Box>
      }
    >
      <FundingSourcesListLayout
        fundingSources={[]}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}} // removal of this function will cause change in the ui
        onAddMethod={goAddFundingSourceOfType}
        onViewBenefitsClick={openCreditCardBenefitsModal}
        debitFee={debitFee.fee}
      />
      {CreditCardBenefitsModal}
    </StyledQBDTStepLayout>
  );
};
