import React from 'react';
import { Box, Menu } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/utils/formatting';

type Props = {
  onClick: () => void;
  testId?: string;
};

export const AddFundingSourceMenuItem = ({ onClick, testId = '' }: Props) => (
  <Menu.MenuItem as={Box} w="auto" onClick={onClick} data-testid={testId} color="ds.blue.200">
    <MIFormattedText label="regularBatchPayments.addAnotherPaymentMethod" />
  </Menu.MenuItem>
);
